import styled from 'styled-components/macro';

const PortalContainer = styled.div<any>`
  height: 100%;
  display: flex;
  flex-direction: ${props => (props.showDesktopLayout ? 'row' : 'column')};
`;

const PortalView = styled.div`
  & * {
    box-sizing: border-box;
  }
  & {
    --radius: 12px;
    --juni-body: #4b6b7c;
    --juni-card: #fafdff;
    --juni-body-size: 0.9rem;
    --juni-primary: #245a78;
    --juni-subtitle: #96b8cc;
    --juni-card-border: 3px solid #e8f7ff;
    --juni-border: 1px solid hsl(194, 40%, 93%);
    --juni-shadow: 0 12px 20px -5px hsl(201deg 63% 90%);
    width: 100%;
  }
  background: #edf2fa;
  & > * {
    padding: 1rem;
    max-width: 1220px;
    overflow: hidden;
    margin: 0 auto;
  }
  @media (min-width: 921px) {
    & > * {
      padding: 1rem 2rem;
    }
  }
`;

export { PortalContainer, PortalView };
