import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'core-components';
import {
  CalendarIcon,
  GroupIcon,
  HandbookIcon,
  LockIcon,
  PaperIcon,
  PlaygroundIcon,
} from 'components/Icons';

import Nav from './Nav';
import NavIcon from './NavIcon';
import { NavLeftButtonLink } from './NavLeftButton';
import { NavLeftButtonProps } from './types';

import ClubsButton from './ClubsButton';
import PlaygroundButton from './PlaygroundButton';

interface InstructorNavProps {
  fullWidth?: boolean;
  isAdmin?: boolean;
  noShadow?: boolean;
  pathname: string;
  homePaths: string[];
  isClubsEnabled?: boolean;
}

const HandbookButton: FC<NavLeftButtonProps> = ({ pathname }) => {
  const active = pathname.includes('/teacher/handbook');

  return (
    <NavLeftButtonLink
      active={active}
      icon={<HandbookIcon active={active} />}
      link="/teacher/handbook"
      text="Handbook"
    />
  );
};

const CommunityButton: FC<NavLeftButtonProps> = ({ pathname }) => {
  const active = pathname.includes('/teacher/community');

  return (
    <NavLeftButtonLink
      active={active}
      icon={<GroupIcon />}
      link="/teacher/community"
      text="Community"
    />
  );
};

const AdminToolsButton: FC<NavLeftButtonProps> = ({ pathname }) => {
  const active = pathname.includes(`admin`);

  return (
    <NavLeftButtonLink
      active={active}
      icon={<LockIcon active={active} />}
      link="/admin"
      text="Admin Tools"
    />
  );
};

const InstructorNav: FC<InstructorNavProps> = ({
  fullWidth,
  isAdmin,
  noShadow,
  pathname,
  homePaths,
  isClubsEnabled,
}) => {
  const navLeftLinks = (
    <>
      <HandbookButton pathname={pathname} />
      <ClubsButton
        baseRoute="/teacher"
        pathname={pathname}
        isClubsEnabled={isClubsEnabled}
      />
      <CommunityButton pathname={pathname} />
      {isAdmin ? (
        <AdminToolsButton pathname={pathname} />
      ) : (
        <PlaygroundButton pathname={pathname} baseRoute="/teacher" />
      )}
    </>
  );

  const coreSettingsButtonOptions = [
    <Link to="/teacher/schedule_changes" key="/teacher/schedule_changes">
      <NavIcon icon={<CalendarIcon style={{ fill: '#3C6176' }} />} menuItem />
      Schedule Changes
    </Link>,
    <Link to="/teacher/timesheet" key="/teacher/timesheet">
      <NavIcon icon={<PaperIcon style={{ fill: '#3C6176' }} />} menuItem />
      Timesheet
    </Link>,
    <Link to="/teacher/account" key="/teacher/account">
      <NavIcon icon={<Icon.Account className="icon p-2" />} menuItem />
      Account
    </Link>,
    <Link to="/logout" key="dropdown-nav-link-logout">
      <NavIcon icon={<Icon.Logout className="icon p-2" />} menuItem />
      Logout
    </Link>,
  ].filter(item => item);

  const adminSettingsButtonOptions = [
    <Link to="/teacher/playground" key="/teacher/playground">
      <NavIcon icon={<PlaygroundIcon />} menuItem />
      Playground
    </Link>,
    ...coreSettingsButtonOptions,
  ];

  return (
    <Nav
      fullWidth={fullWidth}
      navLeftLinks={navLeftLinks}
      noShadow={noShadow}
      settingsButtonOptions={
        isAdmin ? adminSettingsButtonOptions : coreSettingsButtonOptions
      }
      baseRoute="/teacher"
      pathname={pathname}
      isAdmin={isAdmin}
      homePaths={homePaths}
    />
  );
};
InstructorNav.defaultProps = {
  fullWidth: false,
  noShadow: false,
};

export default InstructorNav;
