import React, { useCallback } from 'react';
import classNames from 'classnames';
import useClickAwayListener from 'hooks/useClickAwayListener';
import useCombinedRef from 'hooks/useCombinedRef';

export interface PopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Fired on click-away (could also have a close button within eventually).
   * TODO - this should probably include the escape key as well(?)
   * For a small performance optimization, keep the same reference to onClose
   * in between renders (useCallback).  New references re-register DOM events.
   */
  onClose: () => void;
  /**
   * Optionally add additional refs to elements where click away's should be ignored.
   */
  additionalRefs?: React.RefObject<any>[];
}

/**
 * Small utility component to manage a Popover.
 * Provides some base styles and provides a click away listener for close.
 */
const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  ({ onClose, additionalRefs, children, ...rest }, ref) => {
    const combinedRef = useCombinedRef<HTMLDivElement>(ref);
    const handleClickAway = useCallback(onClose, [onClose]);
    useClickAwayListener(combinedRef, handleClickAway, additionalRefs);

    return (
      <div
        {...rest}
        ref={combinedRef}
        className={classNames('bg-white p-2 rounded-xl shadow-3', rest.className)}
      >
        {children}
      </div>
    );
  },
);

export default Popover;
