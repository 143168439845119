import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.css';

/**
 * Wraps a simple JSX table element with custom ui-table styles.
 */
const Table = ({ className, fixed, children, striped }) => (
  <table
    className={classNames(
      { 'ui-table': true, 'ui-fixed': fixed, 'ui-striped': striped },
      className,
    )}
  >
    {children}
  </table>
);

Table.propTypes = {
  className: PropTypes.string,
  fixed: PropTypes.bool,
  children: PropTypes.node,
  striped: PropTypes.bool,
};

export default Table;
