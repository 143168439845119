import React from 'react';
import BadgeContainer from './BadgeContainer';

const InProgress: React.FC = () => (
  <BadgeContainer>
    <svg
      style={{
        background: '#E6EDF0',
        borderRadius: '50%',
      }}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2708 16.4001C32.2708 25.2367 25.1073 32.4001 16.2708 32.4001C7.4342 32.4001 0.270752 25.2367 0.270752 16.4001C0.270752 7.56359 7.4342 0.400146 16.2708 0.400146C25.1073 0.400146 32.2708 7.56359 32.2708 16.4001ZM16.2708 30.4001C24.0027 30.4001 30.2708 24.1321 30.2708 16.4001C30.2708 8.66816 24.0027 2.40015 16.2708 2.40015C8.53877 2.40015 2.27075 8.66816 2.27075 16.4001C2.27075 24.1321 8.53877 30.4001 16.2708 30.4001Z"
        fill="#BEC6CA"
      />
      <path
        d="M18.272 16.3C18.272 17.5549 17.2548 18.5721 16 18.5721C14.7452 18.5721 13.728 17.5549 13.728 16.3C13.728 15.0452 14.7452 14.028 16 14.028C17.2548 14.028 18.272 15.0452 18.272 16.3Z"
        fill="#BEC6CA"
      />
      <path
        d="M25.0882 16.3C25.0882 17.5549 24.071 18.5721 22.8162 18.5721C21.5613 18.5721 20.5441 17.5549 20.5441 16.3C20.5441 15.0452 21.5613 14.028 22.8162 14.028C24.071 14.028 25.0882 15.0452 25.0882 16.3Z"
        fill="#BEC6CA"
      />
      <path
        d="M11.4559 16.3C11.4559 17.5549 10.4387 18.5721 9.18385 18.5721C7.92903 18.5721 6.9118 17.5549 6.9118 16.3C6.9118 15.0452 7.92903 14.028 9.18385 14.028C10.4387 14.028 11.4559 15.0452 11.4559 16.3Z"
        fill="#BEC6CA"
      />
    </svg>
  </BadgeContainer>
);

export default InProgress;
