import styled from 'styled-components/macro';

const StyledInstructionsPanelContainer = styled.div`
  padding: 0 16px;
  height: 100%;
`;

const StyledInstructionsPanelHeader = styled.header`
  border-bottom: 1px solid hsl(208, 36%, 87%);
  box-sizing: border-box;
  max-width: 100%;
`;

const StyledInstructionsPanelTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-family: 'Gotham', sans-serif;
  padding: 16px 0;
  line-height: 24px;
`;

const StyledInstructionsPanelData = styled.article`
  width: 100%;
  height: calc(100% - 52px);
  overflow-y: auto;
  padding-top: 16px;
  box-sizing: border-box;
`;

export {
  StyledInstructionsPanelHeader,
  StyledInstructionsPanelTitle,
  StyledInstructionsPanelContainer,
  StyledInstructionsPanelData,
};
