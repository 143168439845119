import React, { FC, useRef } from 'react';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

import { projectTypeToRenderInfo } from 'constants/project_types';
import { DISPLAYABLE_JUNIVERSE_TAGS } from 'constants/juniverse_tags';
import { StudentProject } from 'generated/graphql';

import { TagIcon } from 'components/PortalIcons';
import { mongoIDToDateStr } from 'utils/timeUtils';

import { UserTypeTag } from 'components/brand-assets';
import {
  HeaderDate,
  HeaderLeft,
  ProjectByLine,
  ProjectCardLayout,
  ProjectIcon,
  ProjectImgContentContainer,
  ProjectTitle,
} from './styles';
import JuniversePreviewVideo from './JuniversePreviewVideo';

interface FeaturedProjectProps extends RouteComponentProps {
  project: StudentProject;
  linksOpenInSameTab?: boolean;
  hideCreatorBadge?: boolean;
}
const FeaturedProject: FC<FeaturedProjectProps> = ({
  history,
  project,
  linksOpenInSameTab,
  hideCreatorBadge,
}) => {
  const projectByLineRef = useRef<HTMLDivElement>(null);
  const {
    _id,
    attachedUsermediaUrls,
    creator,
    description,
    juniverseTags,
    projectType,
    thumbnailUrl,
    title,
  } = project || {};

  const { username, type, isGuest } = creator || {};
  const [videoUrl] = attachedUsermediaUrls || [];

  /* Header date should reflect actual featured date: when a card has been featured */
  const headerDate = moment(mongoIDToDateStr(_id) || '').fromNow();

  const visibleTags = juniverseTags?.filter(tag => DISPLAYABLE_JUNIVERSE_TAGS[tag]);

  const projectTypeName = projectType ? _.camelCase(projectType) : 'unknown';

  const projectIcon = projectTypeToRenderInfo(projectTypeName).icon;

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'seconds',
      ss: '%ss',
      m: 'a minute',
      mm: '%dm',
      h: 'an hour',
      hh: '%dh',
      d: 'a day',
      dd: '%dd',
      M: '%dmo',
      MM: '%dmo',
      y: 'a year',
      yy: '%dY',
    },
  });

  // Below logic allows for:
  // 1. no action if user selects text
  // 2. go to project if user clicks card
  // 3. go to user profile instead if user clicks on username inside card
  // 4. in my feed, open links in new window; in juniverse, use same window
  // Using history/Link from react router is blazing fast compared to opening
  // a new link the ol' fashioned way
  const urlBase =
    process.env.REACT_APP_API_URL === 'http://localhost:3001'
      ? 'http://localhost:3000'
      : 'https://app.junilearning.com';
  const handlePageOpen = (link: string) => {
    if (linksOpenInSameTab) {
      history.push(link);
    } else {
      window.open(`${urlBase}${link}`);
    }
  };
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.type !== 'click') return;
    if (window.getSelection()?.toString()) return;

    if (projectByLineRef?.current?.contains(e.target as Node)) {
      handlePageOpen(`/juniverse/users/${encodeURIComponent(username || '')}`);
    } else {
      handlePageOpen(`/juniverse/projects/${_id}${window.location.search}`);
    }
  };

  const viewCount = project?.viewCount;
  const viewCountString = viewCount
    ? ` • ${viewCount} view${viewCount > 1 ? 's' : ''}`
    : '';

  return project && creator ? (
    <ProjectCardLayout onClick={handleClick}>
      <div className="flex flex-row justify-between pb-1.5">
        <HeaderLeft className="flex flex-row overflow-hidden">
          <ProjectIcon className="icon__container mt-1.5 mr-5 flex-shrink-0">
            <img src={projectIcon} alt="" />
          </ProjectIcon>
          <div className="flex flex-col overflow-hidden">
            <ProjectTitle>{title}</ProjectTitle>
            <div className="flex flex-row justify-left overflow-hidden leading-5 pt-0.5">
              <ProjectByLine
                // @agrippa is not sure what it means to overwrite className of a styled component
                className="mr-3"
                ref={projectByLineRef}
              >
                by {username}
                {viewCountString}
              </ProjectByLine>
              {hideCreatorBadge ? null : (
                <UserTypeTag
                  className="-mt-0.5"
                  userType={isGuest ? 'GUEST' : type}
                />
              )}
            </div>
          </div>
        </HeaderLeft>
        <HeaderDate>{headerDate}</HeaderDate>
      </div>
      <div className="text-sm leading-6 my-1.5" style={{ color: '#4d6c7d' }}>
        {description}
      </div>
      {visibleTags?.length ? (
        <div className="flex flex-row flex-wrap font-semibold uppercase mt-0">
          {visibleTags.map((tag: string) => {
            const { color, key, name } = DISPLAYABLE_JUNIVERSE_TAGS[tag];
            return (
              <div
                className="flex flex-row items-center whitespace-nowrap text-sm mr-4"
                style={{ color }}
                key={key}
              >
                <TagIcon fill={color} />
                {name}
              </div>
            );
          })}
        </div>
      ) : null}
      {videoUrl ? (
        <JuniversePreviewVideo src={videoUrl} />
      ) : (
        <ProjectImgContentContainer>
          <img src={thumbnailUrl || projectIcon} alt="" />
        </ProjectImgContentContainer>
      )}
    </ProjectCardLayout>
  ) : null;
};

export default withRouter(FeaturedProject);
