import React from 'react';
import './spinner_v2.css';

interface SpinnerDotProps {
  index: number;
}

interface SpinnerProps {
  size?: number;
}

const SpinnerDot = ({ index }: SpinnerDotProps) => (
  <div className={`spinner-dot-container-${index} spinner-dot-container`}>
    <div>
      <div className="spinner-dot-left" />
      <div className={`spinner-dot-${index} spinner-dot`} />
      <div className="spinner-dot-right" />
    </div>
    <div />
  </div>
);

const SpinnerV2 = ({ size }: SpinnerProps) => (
  <div className="spinner-v2-wrapper">
    <div
      className="spinner-v2"
      style={size ? { width: `${size}px`, height: `${size}px` } : {}}
    >
      <SpinnerDot index={1} />
      <SpinnerDot index={2} />
      <SpinnerDot index={3} />
      <SpinnerDot index={4} />
      <SpinnerDot index={5} />
      <SpinnerDot index={6} />
      <SpinnerDot index={7} />
      <SpinnerDot index={8} />
      <SpinnerDot index={9} />
      <SpinnerDot index={10} />
      <SpinnerDot index={11} />
      <SpinnerDot index={12} />
    </div>
  </div>
);

export default SpinnerV2;
