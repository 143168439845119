/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-did-update-set-state */
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { noop } from 'lodash';

import './jide_sidebar.css';
import classNames from 'classnames';
import Button from 'core-components/NewButton';
import { Icon } from 'core-components';
import { JideSidebarWrapper, JideSidebarContent, JideSidebarTitle } from './styles';
import SidebarPill from './SidebarPill';
import LoadingSpinner from '../LoadingSpinner';

// MAIN SIDEBAR CODE
const JideSidebar = props => {
  const isTabEnabled = tab => props.enabledTabs.includes(tab);
  const { sidebarExpanded, activeSidebarTab } = props;

  const renderSidebarTabContent = () => {
    switch (activeSidebarTab) {
      case JideSidebar.TAB_FILES: {
        return (
          <JideSidebarFiles
            files={props.files}
            filename={props.filename}
            activeSidebarTab={props.activeSidebarTab}
            selectFile={props.selectFile}
            parseFileType={props.parseFileType}
            createFile={props.createFile}
            newHorizons={props.newHorizons}
          />
        );
      }
      case JideSidebar.TAB_USERS: {
        return (
          <JideSidebarUsers
            users={props.users}
            activeSidebarTab={props.activeSidebarTab}
            newHorizons={props.newHorizons}
          />
        );
      }
      case JideSidebar.TAB_NETWORK: {
        return (
          <JideSidebarNetwork
            network={props.network}
            activeSidebarTab={props.activeSidebarTab}
            hardReset={props.hardReset}
            newHorizons={props.newHorizons}
          />
        );
      }
      case JideSidebar.TAB_VERSIONS: {
        return (
          <JideSidebarVersioning
            files={props.filesVersions}
            filename={props.filename}
            activeSidebarTab={props.activeSidebarTab}
            selectFile={props.selectFile}
            parseFileType={props.parseFileType}
            isPreview={props.isPreview}
            isFileRestorable={
              props.files &&
              props.filesVersions &&
              props.files[props.filename] &&
              props.files[props.filename].isText &&
              !props.filesVersions[props.filename]
            }
            newHorizons={props.newHorizons}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  if (props.newHorizons) {
    return (
      <JideSidebarWrapper sidebarExpanded={sidebarExpanded}>
        {!!activeSidebarTab && (
          <JideSidebarTitle>{activeSidebarTab}</JideSidebarTitle>
        )}
        {renderSidebarTabContent()}
      </JideSidebarWrapper>
    );
  }

  return (
    <div className={`jsb${props.sidebarExpanded ? ' jsb-expanded' : ' jsb-closed'}`}>
      <JideSidebarBar
        fullScreenMode={props.fullScreenMode}
        toggleFullScreenMode={props.toggleFullScreenMode}
      />
      <div className="jsb-main">
        <div className="jsb-tabs">
          {isTabEnabled(JideSidebar.TAB_FILES) ? (
            <JideSidebarTab
              activeSidebarTab={props.activeSidebarTab}
              setSidebarTab={props.setSidebarTab}
              sidebarTabName={JideSidebar.TAB_FILES}
              sidebarTabIcon="copy"
              isDisabled={props.isDisabled}
            />
          ) : null}
          {isTabEnabled(JideSidebar.TAB_USERS) &&
          ['student', 'teacher'].includes(props.jideUser.type) ? (
            <JideSidebarTab
              activeSidebarTab={props.activeSidebarTab}
              setSidebarTab={props.setSidebarTab}
              sidebarTabName={JideSidebar.TAB_USERS}
              sidebarTabIcon="user-friends"
              isDisabled={props.isDisabled}
            />
          ) : null}
          {isTabEnabled(JideSidebar.TAB_VERSIONS) &&
          (props.hasEditPermissions ||
            ['teacher', 'admin'].includes(props.jideUser.type)) ? (
            <JideSidebarTab
              activeSidebarTab={props.activeSidebarTab}
              setSidebarTab={props.setSidebarTab}
              sidebarTabName={JideSidebar.TAB_VERSIONS}
              sidebarTabIcon="history"
              isDisabled={
                props.isDisabled ||
                (props.connectionStatus && props.connectionStatus !== 'connected') ||
                props.isSyncing
              }
            />
          ) : null}
          {isTabEnabled(JideSidebar.TAB_NETWORK) &&
          ['student', 'teacher'].includes(props.jideUser.type) ? (
            <JideSidebarTab
              activeSidebarTab={props.activeSidebarTab}
              setSidebarTab={props.setSidebarTab}
              sidebarTabName={JideSidebar.TAB_NETWORK}
              sidebarTabIcon="server"
              isDisabled={props.isDisabled}
            />
          ) : null}
          {/* <JideSidebarTab
            activeSidebarTab={props.activeSidebarTab}
            setSidebarTab={props.setSidebarTab}
            sidebarTabName={JideSidebar.TAB_NETWORK} # TAB_NETWORK is not defined yet, when you uncomment this line, add it at the end of this file.
            sidebarTabIcon={'sliders-h'}
            isDisabled={props.isDisabled}
          /> */}
        </div>
        <div className="jsb-content">
          <div className="jsb-content-wrapper">
            <JideSidebarFiles
              files={props.files}
              filename={props.filename}
              activeSidebarTab={props.activeSidebarTab}
              selectFile={props.selectFile}
              parseFileType={props.parseFileType}
              createFile={props.createFile}
            />
            <JideSidebarUsers
              users={props.users}
              activeSidebarTab={props.activeSidebarTab}
            />
            <JideSidebarVersioning
              files={props.filesVersions}
              filename={props.filename}
              activeSidebarTab={props.activeSidebarTab}
              selectFile={props.selectFile}
              parseFileType={props.parseFileType}
              isPreview={props.isPreview}
              isFileRestorable={
                props.files &&
                props.filesVersions &&
                props.files[props.filename] &&
                props.files[props.filename].isText &&
                !props.filesVersions[props.filename]
              }
            />
            <JideSidebarNetwork
              network={props.network}
              activeSidebarTab={props.activeSidebarTab}
              hardReset={props.hardReset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SIDEBAR SECTIONS
const JideSidebarBar = ({ fullScreenMode, toggleFullScreenMode }) => {
  const iconValue = fullScreenMode ? 'compress' : 'expand';
  return (
    <div
      className={`jsb-bar ${fullScreenMode ? 'jsb-fullscreen' : ''}`}
      onClick={toggleFullScreenMode}
    >
      <FontAwesomeIcon icon={['fas', iconValue]} />
      {fullScreenMode && <div className="jsb-bar-text">Exit Fullscreen</div>}
    </div>
  );
};

const JideSidebarFiles = ({
  files,
  filename,
  activeSidebarTab,
  selectFile,
  parseFileType,
  createFile,
  newHorizons,
}) => {
  const fileNames = (files && Object.keys(files)) || [];
  fileNames.sort((a, b) => {
    const mainFiles = ['main.py', 'Main.java', 'main.cpp'];
    if (mainFiles.includes(a) && !mainFiles.includes(b)) {
      return -1;
    }
    if (!mainFiles.includes(a) && mainFiles.includes(b)) {
      return 1;
    }
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  });

  const fileCards = fileNames.map(f => (
    <JideSidebarFile
      activeFilename={filename}
      filename={f}
      label={f}
      sublabel={files[f]?.size}
      title={f}
      key={f}
      selectFile={selectFile}
      parseFileType={parseFileType}
    />
  ));

  if (newHorizons) {
    const fileCards = fileNames.map(f => (
      <SidebarPill
        label={f}
        onClick={() => selectFile(f)}
        selected={filename === f}
        renderIcon={props => <Icon.File {...props} />}
      />
    ));
    return (
      <JideSidebarContent className="overflow-hidden">
        <div className="flex-grow overflow-auto min-h-0">{fileCards}</div>
        <JideSidebarFileMgmt
          createFile={createFile}
          selectFile={selectFile}
          filename={filename}
          files={files}
          newHorizons
        />
      </JideSidebarContent>
    );
  }

  return (
    <div
      className={`jsb-content-pane${
        activeSidebarTab === JideSidebar.TAB_FILES
          ? ' jsb-content-active'
          : ' jsb-content-inactive'
      }`}
    >
      <div className="jsb-content-pane-inner jsb-files">
        <JideSidebarHeader text="FILES" />
        <div className="jsb-content-scrollable">{fileCards}</div>
        <JideSidebarFileMgmt
          createFile={createFile}
          selectFile={selectFile}
          filename={filename}
          files={files}
        />
      </div>
    </div>
  );
};

const JideSidebarUsers = ({ users, activeSidebarTab, newHorizons }) => {
  if (newHorizons) {
    return (
      <JideSidebarContent>
        <SidebarPill
          label={users && users.user && users.user.name}
          renderIcon={props => <Icon.User {...props} />}
        />
      </JideSidebarContent>
    );
  }

  return (
    <div
      className={`jsb-content-pane${
        activeSidebarTab === JideSidebar.TAB_USERS
          ? ' jsb-content-active'
          : ' jsb-content-inactive'
      }`}
    >
      <div className="jsb-content-pane-inner jsb-users">
        <JideSidebarHeader text="USERS" />
        <JideSidebarIconText
          text={users && users.user && users.user.name}
          icon="user"
        />
      </div>
    </div>
  );
};

const JideSidebarVersioning = ({
  files,
  filename,
  activeSidebarTab,
  selectFile,
  parseFileType,
  isFileRestorable,
  isPreview,
  newHorizons,
}) => {
  const fileNames = (files && Object.keys(files)) || [];

  if (newHorizons) {
    const filePanels = fileNames.map(f => {
      const label = moment.utc(files[f].lastModified).fromNow();
      return (
        <SidebarPill
          selected={filename === f}
          label={label}
          sublabel={files[f].name}
          key={files[f].lastModified}
          onClick={() => selectFile(f)}
          renderIcon={props => <Icon.File className="mt-0.5" {...props} />}
        />
      );
    });
    return (
      <JideSidebarContent className="overflow-auto">
        {files ? (
          <div>{filePanels}</div>
        ) : (
          <div className="relative flex justify-center items-center h-full">
            <LoadingSpinner />
          </div>
        )}
      </JideSidebarContent>
    );
  }

  const fileCards = fileNames.map(f => {
    const label = moment.utc(files[f].lastModified).fromNow();
    const formatted = moment.utc(files[f].lastModified).toDate().toLocaleString();
    return (
      <JideSidebarFile
        activeFilename={filename}
        label={label}
        sublabel={
          <>
            <div className="jsb-file-filename">{files[f].name}</div>
            {files[f].size}
          </>
        }
        filename={`${files[f].versionId}-${files[f].name}`}
        key={files[f].lastModified}
        title={formatted}
        selectFile={selectFile}
        parseFileType={parseFileType}
      />
    );
  });

  return (
    <div
      className={`jsb-content-pane jsb-content-double${
        activeSidebarTab === JideSidebar.TAB_VERSIONS
          ? ' jsb-content-active'
          : ' jsb-content-inactive'
      }`}
    >
      <div className="jsb-content-pane-inner jsb-files">
        <JideSidebarHeader text="VERSIONS" />
        <div className="jsb-content-scrollable">
          {files !== null ? (
            isFileRestorable || isPreview ? (
              files.error ? (
                <div className="jsb-content-pane-notice">
                  Error loading version history - please contact
                  support@learnwithjuni.com with the name of this project if it
                  persists.
                </div>
              ) : (
                fileCards
              )
            ) : (
              <div className="jsb-content-pane-notice">
                This file type can't be restored.
              </div>
            )
          ) : (
            <div className="jsb-content-pane-notice jsb-content-pane-loading">
              <FontAwesomeIcon icon={['fas', 'spinner']} spin />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const JideSidebarNetwork = ({
  network,
  activeSidebarTab,
  hardReset,
  newHorizons,
}) => {
  const [resetInProgress, setResetInProgress] = useState(false);
  const hardResetWrapper = async () => {
    if (resetInProgress) return;
    setResetInProgress(true);
    const success = await hardReset('sidebar');
    if (!success) setResetInProgress(false);
  };

  if (newHorizons) {
    return (
      <JideSidebarContent>
        <div>
          <SidebarPill
            label={`${network && network.subdomain} / ${
              network && network.serverName
            }`}
          />
          <SidebarPill label={network && network.port} />
        </div>
        <Button
          intent="error"
          renderIconLeft={props => <Icon.Power {...props} />}
          onClick={hardResetWrapper}
        >
          Hard Reset
        </Button>
      </JideSidebarContent>
    );
  }

  return (
    <div
      className={`jsb-content-pane${
        activeSidebarTab === JideSidebar.TAB_NETWORK
          ? ' jsb-content-active'
          : ' jsb-content-inactive'
      }`}
    >
      <div className="jsb-content-pane-inner jsb-network">
        <JideSidebarHeader text="NETWORK" />
        <JideSidebarText
          text={`${network && network.subdomain} / ${network && network.serverName}`}
        />
        <JideSidebarText text={network && network.port} />
        <JideSidebarHardReset hardReset={hardReset} />
      </div>
    </div>
  );
};

// SIDEBAR COMPONENTS
const JideSidebarTab = ({
  activeSidebarTab,
  setSidebarTab,
  sidebarTabName,
  sidebarTabIcon,
  isDisabled,
}) => (
  <div
    className={`jsb-tab${isDisabled ? ' jsb-tab-disabled' : ''}${
      activeSidebarTab === sidebarTabName && !isDisabled
        ? ' jsb-tab-active'
        : ' jsb-tab-inactive'
    }`}
    onClick={() => (!isDisabled ? setSidebarTab(sidebarTabName) : null)}
  >
    <FontAwesomeIcon icon={['fas', sidebarTabIcon]} />
  </div>
);

const JideSidebarFile = ({
  activeFilename,
  filename,
  label,
  sublabel,
  title,
  selectFile,
  parseFileType,
}) => {
  // Should match up with Python component's parseFileType method
  const fileIconLookupTable = {
    'code/python': 'file-code',
    'code/java': 'file-code',
    'code/cpp': 'file-code',
    csv: 'file-csv',
    zip: 'file-archive',
    pdf: 'file-pdf',
    video: 'file-video',
    audio: 'file-audio',
    image: 'file-image',
    text: 'file-alt',
    unknown: 'file',
  };
  const fileIcon = fileIconLookupTable[parseFileType(filename)] || 'file';

  return (
    <div
      className={`jsb-content-card jsb-file${
        filename === activeFilename ? ' jsb-file-active' : ' jsb-file-inactive'
      }`}
      onClick={() => selectFile(filename)}
      title={title}
      role="button"
      tabIndex={0}
    >
      <div className="jsb-file-left">
        <FontAwesomeIcon icon={['fas', fileIcon]} />
      </div>
      <div className="jsb-file-mid">
        <div className="jsb-file-mid-upper">{label}</div>
        <div className="jsb-file-mid-lower">{sublabel}</div>
        {/* TODO: add savedToS3 status as colored dot */}
      </div>
      <div className="jsb-file-right">
        {/* <FontAwesomeIcon icon={['fas', 'trash-alt']} /> */}
      </div>
    </div>
  );
};

class JideSidebarFileMgmt extends Component {
  state = {
    activeButton: null,
    inputVal: '',
    newIsWaiting: false,
    renameIsWaiting: false,
    duplicateIsWaiting: false,
    uniqueFilename: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.fileName !== this.props.fileName) {
      this.setState({ inputVal: '', activeButton: null, isWaiting: false });
    }
  }

  onInputKeyDown = e => {
    console.log('keydown', e.key, e.keyCode);
    if (e.key === 'Enter') {
      return this.onSubmit();
    }
    if (e.key === 'Escape') {
      return this.setState({ activeButton: null, inputVal: '' });
    }
  };
  onInputChangeHandler = e => {
    const val = e.target.value;
    this.setState({
      inputVal: val,
      uniqueFilename: this.props.files && !(val in this.props.files),
    });
  };

  onClickNew = () => {
    if (this.state.newIsWaiting) return;

    if (this.state.activeButton === 'NEW FILE') {
      this.setState({ activeButton: null, inputVal: '' });
    } else {
      this.setState({ activeButton: 'NEW FILE', inputVal: '' });
    }
  };

  onClickRename = () => {
    this.setState({ activeButton: null, inputVal: '' });

    // if (this.state.renameIsWaiting) return;

    // if (this.state.activeButton == 'RENAME FILE') {
    //   this.setState({activeButton: null, inputVal: ''});
    // } else {
    //   this.setState({activeButton: 'RENAME FILE', inputVal: ''});
    // }
  };

  onClickDelete = () => {
    this.setState({ activeButton: null, inputVal: '' });
  };

  onClickDuplicate = () => {
    this.setState({ activeButton: null, inputVal: '' });
  };

  onSubmit = () => {
    if (
      !this.state.activeButton ||
      !this.state.inputVal ||
      !this.props.files ||
      !this.state.uniqueFilename
    ) {
      return;
    }

    if (this.state.activeButton === 'NEW FILE') {
      const newFilename = this.state.inputVal;
      this.setState({ activeButton: null, inputVal: '', newIsWaiting: true }, () => {
        this.props.createFile(newFilename).finally(() => {
          this.setState({ newIsWaiting: false }, () => {
            if (this.props.files && newFilename in this.props.files) {
              this.props.selectFile(newFilename);
            }
          });
        });
      });
    } else if (this.state.activeButton === 'RENAME FILE') {
      return null;
    }
  };

  render() {
    const ab = this.state.activeButton;
    const { activeButton, newIsWaiting, inputVal } = this?.state || {};

    if (this.props.newHorizons) {
      return (
        <div className="flex-col px-2.5 py-0">
          {activeButton && (
            <input
              className={classNames(
                'bg-transparent',
                'py-2.5 px-3',
                'mb-4',
                'font-graphik',
                'border border-solid border-j-dark-200 rounded-lg',
                !this.state.uniqueFilename ? 'text-j-pink-700' : 'text-j-dark-200',
              )}
              type="text"
              value={this.state.inputVal}
              onKeyDown={this.onInputKeyDown}
              onChange={this.onInputChangeHandler}
              spellCheck={false}
              placeholder="File Name"
              autoFocus
            />
          )}
          <div className="flex">
            <Button
              onClick={
                newIsWaiting ? noop : activeButton ? this.onSubmit : this.onClickNew
              }
              disabled={
                activeButton && (!this.state.uniqueFilename || inputVal.length < 1)
              }
              fullWidth={newIsWaiting}
              renderIconLeft={() =>
                !activeButton &&
                (newIsWaiting ? (
                  <Icon.Loader className="animate-spin w-full" />
                ) : (
                  <Icon.Plus className="mr-1.5" />
                ))
              }
            >
              {!newIsWaiting && (activeButton ? 'Create' : 'New File')}
            </Button>
            {activeButton && (
              <div
                className={classNames(
                  'flex items-center',
                  'font-graphik text-sm text-white ',
                  'hover:bg-j-dark-700',
                  'py-2 px-4',
                  'cursor-pointer',
                  'rounded-lg',
                  'ml-1.5',
                )}
                role="button"
                tabIndex={0}
                onClick={() => this.setState({ activeButton: false })}
              >
                Cancel
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="jsb-file-mgmt">
        {ab ? (
          <div className="jsb-file-mgmt-top">
            <div className="jsb-content-header-small">{ab}</div>
            <input
              className={!this.state.uniqueFilename ? 'jsb-invalid' : 'jsb-valid'}
              type="text"
              value={this.state.inputVal}
              onKeyDown={this.onInputKeyDown}
              onChange={this.onInputChangeHandler}
              spellCheck={false}
              placeholder="Enter filename and press Enter..."
              autoFocus
            />
          </div>
        ) : null}

        <div className="jsb-file-mgmt-bottom">
          <div
            className={`jsb-file-mgmt-button${
              ab === 'NEW FILE' ? ' jsb-active' : ''
            }`}
            onClick={this.onClickNew}
            title="NEW FILE"
          >
            {this.state.newIsWaiting ? (
              <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
            ) : ab === 'NEW FILE' ? (
              <FontAwesomeIcon icon={['fas', 'times']} />
            ) : (
              <FontAwesomeIcon icon={['fas', 'plus']} />
            )}
          </div>
          {/* <div
            className={
              'jsb-file-mgmt-button' +
              (ab == 'RENAME FILE' ? ' jsb-active' : '')
            }
            onClick={this.onClickRename}
          >
            <FontAwesomeIcon icon={['fas', 'pen']} />
          </div>
          <div
            className={'jsb-file-mgmt-button'}
            onClick={this.onClickDuplicate}
          >
            <FontAwesomeIcon icon={['fas', 'copy']} />
          </div>
          <div className={'jsb-file-mgmt-button'} onClick={this.onClickDelete}>
            <FontAwesomeIcon icon={['fas', 'trash-alt']} />
          </div> */}
        </div>
      </div>
    );
  }
}

const JideSidebarHeader = ({ text }) => (
  <div className="jsb-content-header">{text}</div>
);

const JideSidebarIconText = ({ text, icon }) => (
  <div className="jsb-content-card jsb-icontext">
    <div className="jsb-icontext-left">
      <FontAwesomeIcon icon={['fas', icon]} />
    </div>
    <div className="jsb-icontext-right">{text}</div>
  </div>
);

const JideSidebarText = ({ text }) => (
  <div className="jsb-content-card jsb-text">{text}</div>
);

const JideSidebarHardReset = ({ hardReset }) => {
  const [icon, setIcon] = useState('power-off');
  const hardResetWrapper = async () => {
    if (icon !== 'power-off') return;
    setIcon('spinner');
    const success = await hardReset('sidebar');
    if (!success) setIcon('power-off');
  };
  return (
    <div
      className="jsb-hardreset"
      onClick={hardResetWrapper}
      title="Hard Reset REPL Instance: This will force-disconnect the REPL client and refresh the page. Click only if your client is stuck or if instructed to do so."
    >
      <div className="jsb-hardreset-top">
        <FontAwesomeIcon icon={['fas', icon]} pulse={icon === 'spinner'} />
      </div>
      <div className="jsb-hardreset-bottom">HARD RESET</div>
    </div>
  );
};

JideSidebar.TAB_FILES = 'files';
JideSidebar.TAB_USERS = 'users';
JideSidebar.TAB_VERSIONS = 'versions';
JideSidebar.TAB_NETWORK = 'network';

JideSidebar.defaultProps = {
  enabledTabs: [
    JideSidebar.TAB_FILES,
    JideSidebar.TAB_USERS,
    JideSidebar.TAB_VERSIONS,
    JideSidebar.TAB_NETWORK,
  ],
};

JideSidebar.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  sidebarExpanded: PropTypes.bool,
  fullScreenMode: PropTypes.bool,
  toggleFullScreenMode: PropTypes.func.isRequired,
  activeSidebarTab: PropTypes.string,
  setSidebarTab: PropTypes.func.isRequired,
  selectFile: PropTypes.func.isRequired,
  parseFileType: PropTypes.func.isRequired,
  files: PropTypes.shape({}),
  filesVersions: PropTypes.shape({}),
  filename: PropTypes.string,
  users: PropTypes.shape({}),
  network: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  isPreview: PropTypes.bool,
  hasEditPermissions: PropTypes.bool,
  enabledTabs: PropTypes.arrayOf(PropTypes.string),
  createFile: PropTypes.func,
  newHorizons: PropTypes.bool,
};
export default JideSidebar;
