/* deprecated. use a custom look up table instead. */
export const ERROR_MESSAGES = {
  'no-parent-on-appointment':
    'The Acuity appointment for this class does not have the parent entered correctly. Please check the Acuity appointment first and then resubmit this request.',
  'no-student-on-appointment':
    'The Acuity appointment for this class does not have the student first name entered correctly. Please check the Acuity appointment first and then resubmit this request.',
  'no-instructor-on-appointment':
    'The Acuity appointment for this class does not have an instructor associated with the calendar. Please check the Acuity appointment first and then resubmit this request.',
  'no-instructor-calendar-id':
    'We could not find your calendar ID. Please Slack Juni HQ.',
  'invalid-appointment':
    'There was an issue with the class. Please Slack Juni HQ this message!',
  'duplicate-rescheduling-request-instructor':
    'A rescheduling request has already been submitted for this class - you should be able to see it listed.',
  'duplicate-rescheduling-request-admin':
    'A rescheduling request has already been submitted for this class - please edit the existing one if needed.',
  'no-request-user':
    'We could not find the user associated with the request. Please try logging out and logging back in.',
  'no-request-instructor':
    'We could not find the instructor for this request. Please Slack Juni HQ.',
  'no-request-parent':
    'We could not find the parent for this request. Please Slack Juni HQ.',
  'network-error':
    'We had a problem communicating with the Juni server. Please try again later.',
  'invite-parent-already-registered': 'This person is already a Juni member.',
  'invite-email-already-registered':
    "You've already sent an invitation to this email address.",
};

export const GENERIC_ERROR_MESSAGE =
  'Please try again or contact Juni HQ if it continues.';
