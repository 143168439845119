import React, { useEffect, useState, useMemo } from 'react';
import { shuffle, keyBy, pick } from 'lodash';
import {
  useEnglishAnswerSubmissionsForStudentProjectQuery,
  useUpsertEnglishAnswerSubmissionMutation,
  EnglishAnswerSubmissionFragment,
} from 'generated/graphql';
import { Chevron } from 'components/Icons';
import { ErrorableLoading } from 'components/ui';
import styled from 'styled-components/macro';
import { Problems } from 'components/jide';
import EnglishProblem from '../EnglishProblem';

const ANSWER_SUBMISSION_ERROR_MSG = `Oops! It looks like your answer didn't submit properly. Please try again!`;

interface IEnglishProblem {
  _id: string;
  text: string;
  problemType: 'instructional' | 'multiple_choice' | 'plain_text';
  mcAnswerOptions: { _id: string; answerChoiceText: string }[];
  mcCorrectAnswer: { _id: string; solutionText: string }[];
}

const EnglishProblems: React.FC<{
  problems: IEnglishProblem[];
  studentId: string;
  moduleSectionId: string;
  newHorizons?: boolean;
  moduleQuestionSet?: boolean;
  renderJideWidgets?: () => React.ReactNode;
}> = ({
  problems,
  studentId,
  moduleSectionId,
  newHorizons = false,
  moduleQuestionSet = undefined,
  renderJideWidgets,
}) => {
  const [curProblemIndex, setCurProblemIndex] = useState<number>(0);
  const [answerSubmissionsByProblemId, setAnswerSubmissionsByProblemId] = useState<
    Record<string, EnglishAnswerSubmissionFragment>
  >({});

  const { data, error, refetch } = useEnglishAnswerSubmissionsForStudentProjectQuery(
    {
      variables: {
        studentId,
        moduleSectionId,
      },
    },
  );

  const [
    upsertAnswerSubmission,
    { loading: isSubmitting },
  ] = useUpsertEnglishAnswerSubmissionMutation({ errorPolicy: 'all' });

  const answerSubmissions = data?.englishAnswerSubmissionsForStudentProject.items;
  useEffect(() => {
    if (answerSubmissions) {
      setAnswerSubmissionsByProblemId(keyBy(answerSubmissions, 'problemId'));
    }
  }, [answerSubmissions]);

  const problemsWithShuffledAnswerChoices = useMemo(
    () =>
      problems.map(problem => ({
        ...problem,
        mcAnswerOptions: shuffle(problem.mcAnswerOptions),
      })),
    [problems],
  );

  if (!data) {
    return <ErrorableLoading error={error} />;
  }

  const switchToProblem = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newProblemIndex = parseInt(e.currentTarget.value, 10);
    setCurProblemIndex(newProblemIndex);
  };

  const submitAnswer = async (problemId: string, submittedValues: string[]) => {
    if (isSubmitting) return;
    const updatedAnswerSubmission = {
      studentId,
      moduleSectionId,
      problemId,
      submittedValues,
    };
    const problem = problems.find(problem => problem._id === problemId);
    const cachedProblem = problem
      ? pick(problem, ['problemType', 'mcAnswerOptions', 'mcCorrectAnswer', 'text'])
      : undefined;
    try {
      const res = await upsertAnswerSubmission({
        variables: {
          input: {
            ...updatedAnswerSubmission,
            cachedProblem,
          },
        },
      });
      if (res.data?.upsertEnglishAnswerSubmission.success) {
        setAnswerSubmissionsByProblemId(submissionsByProblemId => ({
          ...submissionsByProblemId,
          [problemId]: updatedAnswerSubmission,
        }));
        refetch();
      } else {
        alert(ANSWER_SUBMISSION_ERROR_MSG);
      }
    } catch (err) {
      console.log(err.response || err);
      alert(ANSWER_SUBMISSION_ERROR_MSG);
    }
  };

  const numProblems = problems.length;
  const prevProblemDisabled = curProblemIndex === 0;
  const nextProblemDisabled = curProblemIndex === problems.length - 1;
  const curProblem = problemsWithShuffledAnswerChoices[curProblemIndex];
  const curAnswerSubmission = answerSubmissionsByProblemId[curProblem._id];

  if (newHorizons) {
    return (
      <Problems
        label="Question"
        curProblemIndex={curProblemIndex}
        numProblems={numProblems}
        onProblemIndexChanged={setCurProblemIndex}
        isSubmitting={isSubmitting}
        fullScreen={moduleQuestionSet}
        renderJideWidgets={renderJideWidgets}
      >
        <EnglishProblem
          key={`${curProblem._id}-${studentId}`}
          {...curProblem}
          answerSubmission={curAnswerSubmission}
          handleSubmitAnswer={submitAnswer}
          isSubmitting={isSubmitting}
          newHorizons
          moduleQuestionSet={moduleQuestionSet}
          first={curProblemIndex === 0}
          last={curProblemIndex === numProblems - 1}
          onInstructionalProblemNextClick={() =>
            setCurProblemIndex(curProblemIndex + 1)
          }
        />
      </Problems>
    );
  }

  return (
    <StyledEnglishProblems>
      <StyledProblemHeader>
        <h3>Questions</h3>
        <StyledNavControls>
          <StyledNavButton
            title="Previous problem"
            value={curProblemIndex === 0 ? 0 : curProblemIndex - 1}
            onClick={switchToProblem}
            disabled={prevProblemDisabled || isSubmitting}
          >
            <Chevron orientation="left" />
          </StyledNavButton>
          {curProblemIndex + 1} / {numProblems}
          <StyledNavButton
            title="Next problem"
            value={
              curProblemIndex >= numProblems - 1
                ? numProblems - 1
                : curProblemIndex + 1
            }
            onClick={switchToProblem}
            disabled={nextProblemDisabled || isSubmitting}
          >
            <Chevron orientation="right" />
          </StyledNavButton>
        </StyledNavControls>
      </StyledProblemHeader>
      <EnglishProblem
        key={`${curProblem._id}-${studentId}`}
        {...curProblem}
        answerSubmission={curAnswerSubmission}
        handleSubmitAnswer={submitAnswer}
        isSubmitting={isSubmitting}
      />
    </StyledEnglishProblems>
  );
};

const StyledEnglishProblems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;
const StyledProblemHeader = styled.div`
  margin: 0 1.25rem;
  padding: 1.25rem 0;
  border-bottom: 2px solid hsl(198, 100%, 93%);
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  align-items: center;
  > h3 {
    margin: 0;
    margin-right: 1rem;
    font-size: 18px;
    color: hsl(206, 39%, 33%);
  }
`;
const StyledNavControls = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: hsl(202, 33%, 35%);
  font-weight: bold;
  font-size: 14px;
`;
const StyledNavButton = styled.button`
  background: hsl(201, 100%, 92%);
  border: none;
  padding: 8px;
  &:first-child {
    margin-right: 1rem;
    .icon {
      left: -1px;
    }
  }
  &:last-child {
    margin-left: 1rem;
    .icon {
      left: 1px;
    }
  }
  .icon {
    position: relative;
    padding: 0;
    display: block;
    height: 10px;
    width: 10px;
    polyline {
      stroke: hsl(206, 39%, 33%);
    }
  }
  &:disabled {
    border: none;
    icon polyline {
      stroke: #ccc;
    }
  }
`;

export default EnglishProblems;
