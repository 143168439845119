import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.css';

const FormGroup = ({ className, title, children }) => (
  <div className={classNames('ui-form-group', 'ui-vertical-spacing', className)}>
    <div className="ui-title">{title}</div>
    {children}
  </div>
);

FormGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default FormGroup;
