import React, { FC } from 'react';
import { TagIcon } from 'components/PortalIcons';

interface LabelTagProps {
  name: string;
  // TODO: update to tailwind color
  color: string;
}

const LabelTag: FC<LabelTagProps> = ({ color, name }) => (
  <div
    className="flex flex-row items-center whitespace-nowrap text-sm mr-4 uppercase font-semibold"
    style={{ color }}
  >
    <TagIcon fill={color} />
    {name}
  </div>
);

export default LabelTag;
