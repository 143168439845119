import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Layout from 'components/Layout';

const NotAuthorizedErrorPage: React.FC = () => (
  <Layout takeFullViewHeight>
    <div className="flex flex-1 flex-col items-center justify-center">
      <h1 className="text-xl mt-0 mb-6">
        Sorry, you are not allowed to view this page.
      </h1>
      <Link
        className={classNames(
          'flex',
          'items-center',
          'bg-juni-pink-500',
          'hover:bg-juni-pink-600',
          'text-white',
          'no-underline',
          'font-bold',
          'uppercase',
          'rounded-lg',
          'p-4',
        )}
        to="/"
      >
        Return Home
      </Link>
    </div>
  </Layout>
);

export default NotAuthorizedErrorPage;
