import React, { FC } from 'react';
import { NewModalWindow } from 'core-components';
import Button from 'core-components/NewButton';
import { ModalButtonsFooter } from 'core-components/NewModalWindow/NewModalWindow';
import { JuniAd } from 'generated/graphql';

interface WhatsNewPopupTemplateProps {
  juniAd?: JuniAd | null;
  onJuniAdAction: (target: string) => void;
  closeModal: () => void;
  isOpen: boolean;
}

/**
 * Renders a "What's New" popup serving a JuniAd.
 * https://www.notion.so/junilearning/Learner-Portal-Juni-Ads-What-s-New-RFD-f3d330efc2204f5eb2f8bfacd39c7d8f
 */
const WhatsNewPopupTemplate: FC<WhatsNewPopupTemplateProps> = ({
  juniAd,
  onJuniAdAction,
  closeModal,
  isOpen,
}) => {
  /**
   * Renders 0-2 buttons centered in the modal footer.
   */
  const renderFooter = () => {
    const primaryClickthroughUrl = juniAd?.primaryAction?.clickthroughUrl;
    const secondaryClickthroughUrl = juniAd?.secondaryAction?.clickthroughUrl;

    return (
      <>
        {primaryClickthroughUrl && (
          <ModalButtonsFooter
            primary={
              <Button
                onClick={() => {
                  onJuniAdAction(primaryClickthroughUrl);
                }}
              >
                <div className="font-medium">{juniAd?.primaryAction?.label}</div>
              </Button>
            }
            secondary={
              // secondary action is omitted if undefined.
              secondaryClickthroughUrl && (
                <Button
                  onClick={() => {
                    onJuniAdAction(secondaryClickthroughUrl);
                  }}
                  variant="secondary"
                  className="mx-5"
                >
                  <div className="font-medium">{juniAd?.secondaryAction?.label}</div>
                </Button>
              )
            }
            justifyBetweenButtons={false}
          />
        )}
      </>
    );
  };

  return (
    <NewModalWindow
      closeModal={closeModal}
      isOpen={isOpen}
      showCloseIcon
      title="What's New At Juni"
      renderFooter={renderFooter}
      contentMaxHeight="80vh"
      contentPadding="0"
    >
      <div className="flex-col w-full">
        <div className="flex justify-center">
          {juniAd?.imageS3Url && (
            <img
              src={juniAd.imageS3Url}
              width="100%"
              alt={`${juniAd.title} banner`}
            />
          )}
        </div>
        <div className="p-5">
          <div className="text-j-dark-600 font-medium text-xl text-center leading-relaxed">
            {juniAd?.title}
          </div>
          <div className="text-j-dark-400 text-sm leading-normal text-center mx-10 mt-2">
            {juniAd?.copy}
          </div>
        </div>
      </div>
    </NewModalWindow>
  );
};

export default WhatsNewPopupTemplate;
