import React, { FC, useState } from 'react';
import { Message, NewCard as Card, PopoverDropdown } from 'core-components';
import { JUNI_LINKS } from 'constants/links';
import { buildEmailLink } from 'utils/social_share/social_share';
import SocialShareButton from 'app/learner/SocialShareButton';
import { UserReport } from 'generated/graphql';
import { ApolloError } from '@apollo/client';
import { Table } from './styles';

interface QuickStatsCardProps {
  userReportId: string;
  studentFirstName: string;
  quickStats?: UserReport;
  error?: ApolloError;
}

const QuickStatsCard: FC<QuickStatsCardProps> = ({
  userReportId,
  studentFirstName,
  quickStats,
  error,
}) => {
  const {
    projectViewsThisMonth,
    projectViewsAllTime,
    projectsPublishedAllTime,
    projectsPublishedThisMonth,
    projectsWorkedOnAllTime,
    projectsWorkedOnThisMonth,
  } = quickStats || {};

  const RECAP_PAGE_LINK = `${JUNI_LINKS.APP}/recap/${userReportId}`;
  const SUBJECT_TEXT = `${studentFirstName} is working on some cool things in Juni classes.`;
  const BODY_TEXT = `Check it out!`;
  const SOCIAL_SHARE_TEXT = `${SUBJECT_TEXT} ${BODY_TEXT}`;

  const [showAllTimeDefinition, setShowAllTimeDefinition] = useState<boolean>(false);

  return (
    <Card shadow="1">
      <div className="flex justify-between items-start">
        <div className="font-graphik text-j-dark-600 text-lg sm:text-4xl font-medium mb-8">
          {`Wow, what a week! Look at what ${studentFirstName} accomplished.`}
        </div>
        <SocialShareButton
          url={RECAP_PAGE_LINK}
          socialShareCopy={SOCIAL_SHARE_TEXT}
          emailSubject={SUBJECT_TEXT}
          emailBody={`${BODY_TEXT}\n\n${buildEmailLink(RECAP_PAGE_LINK)}`}
          shareContent="student_recap_page_link"
          size="medium"
          variant="secondary"
        />
      </div>
      <>
        {error ? (
          <Message status="error">{error.message}</Message>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Quick Stats</th>
                <th>Month</th>
                <th>
                  <PopoverDropdown
                    open={showAllTimeDefinition}
                    onCloseDropdown={() =>
                      setShowAllTimeDefinition(!showAllTimeDefinition)
                    }
                    dropdownPosition="bottom-right"
                    renderDropdownChildren={() => (
                      <div className="normal-case">
                        All time start days vary by metric. Published Projects
                        tracking started April 2020, project views June 2021, and
                        projects worked on May 2021.
                      </div>
                    )}
                  >
                    <div
                      className="cursor-pointer"
                      onMouseEnter={() => setShowAllTimeDefinition(true)}
                      onMouseLeave={() => setShowAllTimeDefinition(false)}
                    >
                      All Time *
                    </div>
                  </PopoverDropdown>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Projects worked on</td>
                <td>{projectsWorkedOnThisMonth}</td>
                <td>{projectsWorkedOnAllTime}</td>
              </tr>
              <tr>
                <td>Projects Published </td>
                <td>{projectsPublishedThisMonth}</td>
                <td>{projectsPublishedAllTime}</td>
              </tr>
              <tr>
                <td>Project views</td>
                <td>{projectViewsThisMonth}</td>
                <td>{projectViewsAllTime}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </>
    </Card>
  );
};

export default QuickStatsCard;
