import React, { FC } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';

import { TailwindColor } from 'theme/types';
import * as styles from './styles';
import { Size } from './types';

export interface BadgeIconProps {
  className?: string;
}
export interface BadgeProps {
  /**
   * Specifies the background color of the badge, styled with Tailwind colors.
   */
  backgroundColor?: TailwindColor;
  /**
   * Specifies the size of the badge.
   * Defaults to regular.
   */
  size?: Size;
  /**
   * Specifies the color of the badge text and outline (if applicable).
   */
  color?: TailwindColor;
  /**
   * Applies an outline to the badge. Outline is the same color as the text color.
   * Defaults to false.
   */
  outline?: boolean;
  /**
   * Add icon before the badge text.
   * The rendered icon needs to render these props, and be able to derive text color from the CSS className (eg currentColor).
   */
  renderIconLeft?: (props: BadgeIconProps) => React.ReactNode;
  /**
   * Add an icon after button text.
   * The rendered icon needs to render these props, and be able to derive text color from the CSS className (eg currentColor).
   */
  renderIconRight?: (props: BadgeIconProps) => React.ReactNode;
  /**
   * Add an inverted colored section to the left side of the Badge,
   * contents via render prop.
   */
  renderSectionIconLeft?: (props: { className: string }) => React.ReactNode;
  /**
   * Specifies the data-cy attribute (used by Cypress testing) of the badge element.
   */
  ['data-cy']?: string;
}

const Badge: FC<BadgeProps> = ({
  backgroundColor = 'transparent',
  children,
  color,
  size = 'regular',
  outline,
  renderIconLeft = noop,
  renderIconRight = noop,
  renderSectionIconLeft,
}) => (
  <div
    className={classNames(
      {
        [`text-${color}`]: !!color,
        [`border border-solid border-${color}`]: outline,
      },
      styles.fontStyles[size],
      'uppercase',
      `bg-${backgroundColor}`,
      'inline-flex',
      'items-center',
      'rounded-md',
      'font-graphik',
      'font-medium',
    )}
  >
    {!!renderSectionIconLeft && (
      <div
        // invert the text and bg colors
        className={classNames(
          'rounded-l-lg',
          styles.paddingStyles[size],
          `text-${backgroundColor}`,
          {
            [`bg-${color}`]: !!color,
          },
        )}
      >
        {renderSectionIconLeft({ className: styles.sectionIconStyles[size] })}
      </div>
    )}
    <div
      className={classNames('inline-flex items-center', styles.paddingStyles[size])}
    >
      {renderIconLeft({
        className: `mr-1 ${styles.iconStyles[size]}`,
      })}
      {children}
      {renderIconRight({
        className: `ml-1 ${styles.iconStyles[size]}`,
      })}
    </div>
  </div>
);

export default Badge;
