const COMPUTER_SCIENCE_QUOTES = [
  `A good programmer is someone who looks both ways before crossing a one-way street.`,
  `If debugging is the process of removing software bugs, then programming must be the process of putting them in.`,
  `In order to understand recursion, you must first understand recursion.`,
  `Programming is dividing an impossible task into small, possible tasks.`,
];

const MATHEMATICS_QUOTES = [
  `Mathematics compares the most diverse phenomena and discovers the secret analogies that unite them.`,
  `Mathematics is the gate and key to the sciences.`,
  `The moving power of mathematical invention is not reasoning but imagination.`,
  `The study of mathematics, like the Nile, begins in minuteness but ends in magnificence.`,
];

const ENGLISH_QUOTES = [
  `If the story you would like to read has not been written, you must write it.`,
  `It takes a heap of sense to write good nonsense.`,
  `Keep always two books in your pocket: one to read, and one to write in.`,
  `Never put off until tomorrow the book that you can read today.`,
];

export default [
  `Always do that which you cannot do, in order that you may learn how to do it.`,
  `Explain what you learn to someone else. To teach is to learn twice.`,
  `Learn something new every day so that you may never cease to grow.`,
  `Make today the day to learn something new.`,
  `Try to learn something about everything and everything about something.`,
  `What we learn joyfully we never forget.`,
  `What we strive to learn defines us far more than what we already know.`,
  ...COMPUTER_SCIENCE_QUOTES,
  ...MATHEMATICS_QUOTES,
  ...ENGLISH_QUOTES,
];
