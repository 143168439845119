import juniAxios from 'services/axios';

export async function getUnlockedCourseDataForStudents(studentIds) {
  const courseInfo = (
    await juniAxios.get(`/courses/unlocked_only/${studentIds.join(',')}`)
  ).data.data;
  return courseInfo;
}

export async function getUnlockedCourseDataForLoggedInParent() {
  const courseAndJideStudentInfo = (await juniAxios.get(`/courses/unlocked_only`))
    .data.data;
  return courseAndJideStudentInfo;
}

export async function getInitNavIdLookupPublic(initNav) {
  const preppedInitNav = prepInitNav(initNav);
  const idLookup = await juniAxios
    .get(`/courses/public/initnav`, {
      params: {
        ...preppedInitNav,
      },
    })
    .then(res => res.data.idLookup);
  return childIdsToObjects(idLookup);
}

export async function getInitNavIdLookupInstructor(initNav) {
  const preppedInitNav = prepInitNav(initNav);
  const idLookup = await juniAxios
    .get('/courses/instructor/initnav', { params: { ...preppedInitNav } })
    .then(res => res.data.idLookup);
  return childIdsToObjects(idLookup);
}

// PRIVATE METHODS
const childIdsToObjects = idLookup => {
  for (const k of Object.keys(idLookup)) {
    if (idLookup[k] && idLookup[k].children) {
      const newChildren = idLookup[k].children.map(c => idLookup[c]);
      idLookup[k].children = newChildren;
    }
  }
  return idLookup;
};
const prepInitNav = initNav => ({
  studentId: initNav.student,
  courseId: initNav.course,
  moduleId: initNav.module,
  projectId: initNav.project,
});

// TODO MOVE API_TRIAL INTO HERE?
