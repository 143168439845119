import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import remark from 'remark';
import { HelpCenterArticle } from 'generated/graphql';

// Consider syncing changes to this file with
// src/app/instructor/InstructorHandbook/useFuse.ts

// there's no declaration file for this
// and compiler ignores me when i tell it to expect that via @ts-expect-error
// eslint-disable-next-line @typescript-eslint/no-var-requires
const stripHTML = require('remark-strip-html');

const processor = remark().use(stripHTML).freeze();
const processSections = (articles: HelpCenterArticle[]): StrippedArticle[] =>
  articles.map(article => ({
    ...article,
    stripped: processor
      .processSync(
        article.body
          // Remove style tag and everything in between. My stupid html stripper doesn't.
          .replace(/<style>.*<\/style>/gs, ''),
      )
      .toString()
      // Collapse repeated newlines + adjacent whitespace into just one newline.
      .replace(/\n+\s*/g, '\n'),
  }));

interface StrippedArticle extends HelpCenterArticle {
  stripped: string;
}

export type FuseSearchResults = Fuse.FuseResult<StrippedArticle>[];

const useFuse = (articles: HelpCenterArticle[], searchInput: string) => {
  const [fuse, setFuse] = useState<Fuse<StrippedArticle> | null>(null);
  const [searchResults, setSearchResults] = useState<FuseSearchResults | null>(null);

  useEffect(() => {
    if (articles) {
      const newFuse = new Fuse(processSections(articles), {
        keys: ['stripped', 'title'],
        includeMatches: true,
        minMatchCharLength: 3,
        threshold: 0.2,
        distance: 99999999,
        findAllMatches: false,
        ignoreFieldNorm: true,
        // useExtendedSearch: true,
      });
      setFuse(newFuse);
    }
  }, [articles]);

  // may want to debounce me
  useEffect(() => {
    if (fuse) {
      const results = fuse.search(searchInput);
      setSearchResults(results);
    }
  }, [searchInput, fuse]);

  return searchResults;
};

export default useFuse;
