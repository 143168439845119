/**
 * Adapted from https://usehooks.com/useOnClickOutside/
 */
import React, { useEffect } from 'react';

const useClickAwayListener = (
  ref: React.RefObject<any>,
  handler: (event: Event) => void,
  // optionally add additional refs where click away's should be ignored
  additionalRefs?: React.RefObject<any>[],
) => {
  useEffect(
    () => {
      const listener = (event: Event) => {
        // Do nothing if clicking ref's element or descendent elements
        // If the ref isn't current we don't know yet (this shouldn't happen in practice)
        const targetInRef = (r: React.RefObject<any>) =>
          !r.current || r.current.contains(event.target);

        const refs = [ref, ...(additionalRefs || [])];

        if (refs.some(targetInRef)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // function on every render that will cause this effect ...
    // callback/cleanup to run every render. It's not a big deal ...
    // but to optimize you can wrap handler in useCallback before ...
    // passing it into this hook.
    [ref, handler, additionalRefs],
  );
};

export default useClickAwayListener;
