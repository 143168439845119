import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import useDimensions from 'hooks/dimensions';

interface NavMenuButtonProps {
  children: React.ReactNode[] | React.ReactNode;
  dropdownOptions: React.ReactNode[];
  hoverState?: boolean;
  navLeft?: boolean;
  paddedBottom?: boolean;
  settingsButton?: boolean;
  doNotOpen?: boolean;
}

const MenuButtonWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  &:hover {
    transform: ${props => props.hoverState && 'scale(1.0125)'};
    transition: ${props => props.hoverState && 'transform .2s ease'};
  }
`;

const DropdownWrapper = styled.div<any>`
  cursor: pointer;
  position: absolute;
  top: ${props => (props.paddedBottom ? 'calc(100% + 24px)' : 'calc(100% + 10px)')};
  right: ${props => !props.navLeft && '0'};
  border-radius: 10px;
  box-shadow: 0px 10px 90px -10px rgba(25, 83, 121, 0.3);
  background: white;
  display: ${props => (props.open ? 'flex' : 'none')};
  margin-right: ${props => props.addMargin && '10px'};
  z-index: 1;
`;

const Caret = styled.div<any>`
  position: absolute;
  right: ${props => !props.navLeft && '10px'};
  left: ${props => props.navLeft && '10px'};
  width: 22.27px;
  height: 22.27px;
  background: white;
  box-shadow: 0px 10px 90px -10px rgba(25, 83, 121, 0.3);
  border-radius: 4px;
  transform: matrix(0.71, 0.85, -0.58, 0.71, 0, 0);
`;

const DropdownOptions = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const DropdownOption = styled.div`
  & > * {
    letter-spacing: 0.25px;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: #413e75;
    border-top: 2px solid #e0ddef;
    padding: 12px 16px 12px 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &:first-child > * {
    border-top: none;
  }

  &:hover {
    background: #f9f8fc;
  }
`;

const NavMenuButton: FC<NavMenuButtonProps> = ({
  children,
  dropdownOptions,
  navLeft,
  paddedBottom,
  settingsButton,
  hoverState,
  doNotOpen,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { width } = useDimensions();

  const mobileView = width <= 920;

  useEffect(() => {
    let isMounted = true;
    const handleClose = () => {
      if (open && isMounted) {
        setOpen(false);
      }
    };
    window.addEventListener('blur', handleClose);
    document.addEventListener('click', handleClose);

    return () => {
      isMounted = false;
      document.removeEventListener('click', handleClose);
      window.removeEventListener('blur', handleClose);
    };
  }, [open, setOpen, dropdownRef]);

  const canOpen = !open && !doNotOpen;

  return (
    <>
      <MenuButtonWrapper
        hoverState={hoverState}
        onClick={() => canOpen && setOpen(true)}
      >
        {children}
      </MenuButtonWrapper>
      <DropdownWrapper
        ref={dropdownRef}
        open={open}
        paddedBottom={paddedBottom}
        navLeft={navLeft}
        addMargin={mobileView && settingsButton}
      >
        <Caret navLeft={navLeft} />
        <DropdownOptions>
          {dropdownOptions.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <DropdownOption key={`dropdown-option-${i}`}>{option}</DropdownOption>
          ))}
        </DropdownOptions>
      </DropdownWrapper>
    </>
  );
};

export default NavMenuButton;
