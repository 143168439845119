import React, { useRef, useState, useLayoutEffect } from 'react';
import Dropdown from '../Dropdown';

// vertical spacing between the input "anchor element" and the Dropdown.
const VERTICAL_SPACING = '1rem';
// used to calculate a max-width to prevent the positioned Dropdown from overflowing
// the edge of the viewport.
const VIEWPORT_PADDING = '1rem';

const positions = ['bottom-left', 'bottom-right', 'top-left', 'top-right'] as const;
type Position = typeof positions[number];

export interface PopoverDropdownProps {
  /**
   * Controls whether the Dropdown is open.
   */
  open: boolean;
  /**
   * Callback to
   */
  onCloseDropdown: () => void;
  /**
   * Position of the drop-down relative to the Select.
   * Default bottom-left.
   */
  dropdownPosition?: Position;
  /**
   * True to size the dropdown the same width as the "anchor" children element.
   * Default false.
   */
  fullWidth?: boolean;
  /**
   * Render prop for rendering the Dropdown's children.
   */
  renderDropdownChildren: () => React.ReactNode | React.ReactNode[];
  /**
   * Wrapped "anchor" HTMLElement.
   * Must be able to pass and accept a ref.
   */
  children: React.ReactElement;
}

/**
 * Wraps an anchor element (children) that accepts a ref prop,
 * and conditionally renders a relatively positioned Dropdown.
 */
const PopoverDropdown: React.FC<PopoverDropdownProps> = ({
  open,
  onCloseDropdown,
  dropdownPosition = 'bottom-left',
  fullWidth = false,
  renderDropdownChildren,
  children,
}) => {
  const [positioning, setPositioning] = useState<React.CSSProperties>({});
  const ref = useRef<HTMLElement>(null);

  // works with any arbitrary HTML DOM node "anchor element"
  useLayoutEffect(() => {
    if (open && ref.current) {
      // measure and calculate dropdown positioning each time at open time.
      // this isn't perfect as an element can change size while open which isn't accounted for yet
      // note - check out Popper.js before going too much further!
      const positioning: React.CSSProperties = {};

      const horizontalProperty =
        dropdownPosition === 'bottom-left' || dropdownPosition === 'top-left'
          ? 'left'
          : 'right';
      const verticalProperty =
        dropdownPosition === 'bottom-left' || dropdownPosition === 'bottom-right'
          ? 'top'
          : 'bottom';

      positioning[horizontalProperty] = 0;
      const { height, left, right } = ref.current.getBoundingClientRect();
      positioning[verticalProperty] = `calc(${height}px + ${VERTICAL_SPACING})`;

      if (fullWidth) {
        positioning.left = 0;
        positioning.right = 0;
        setPositioning(positioning);
        return;
      }

      if (dropdownPosition === 'top-right' || dropdownPosition === 'bottom-right') {
        // prevent horizontal viewport overflow on the left
        positioning.maxWidth = `calc(${right}px - ${VIEWPORT_PADDING})`;
      } else {
        // prevent horizontal viewport overflow on the right
        positioning.maxWidth = `calc(${window.innerWidth}px - ${VIEWPORT_PADDING} - ${left}px)`;
      }

      setPositioning(positioning);
    }
  }, [open, dropdownPosition, fullWidth, ref]);

  return (
    <div className="relative">
      {React.cloneElement(children, { ref })}
      {open && (
        <Dropdown
          onClose={onCloseDropdown}
          additionalRefs={[ref]}
          style={positioning}
        >
          {renderDropdownChildren()}
        </Dropdown>
      )}
    </div>
  );
};

export default PopoverDropdown;
