import React from 'react';
import { TailwindSpace } from 'theme/types';

interface ContentProps {
  /**
   * Padding to apply to the content below the media banner.
   * Default 6.
   */
  padding?: TailwindSpace;
}

const Content: React.FC<ContentProps> = ({ padding = '6', children }) => (
  <div className={`p-${padding}`}>{children}</div>
);

export default Content;
