import React, { FC } from 'react';
import { NewCard as Card } from 'core-components';
import Button from 'core-components/NewButton';
import { RecapStudentInfo } from 'generated/graphql';

import astronaut from 'images/astronaut.svg';
import { StudentInfoContent } from './styles';

const StudentInfoCard: FC<RecapStudentInfo> = ({
  firstName,
  lastName,
  username,
}) => (
  <Card shadow="1">
    <StudentInfoContent>
      <img
        src={astronaut}
        alt="Juni Student"
        className="block mt-0 mx-auto mb-2 rounded-full w-3/5"
      />
      <div className="flex flex-col items-center">
        <div className="font-graphik font-medium text-j-dark-600 text-xl text-center">
          {`${firstName} ${lastName}`}
        </div>
        {username && (
          <div className="font-graphik text-j-dark-600 text-base text-center">{`@${username}`}</div>
        )}
      </div>
      {username && (
        <Button href={`/juniverse/users/${username}`} fullWidth>
          View My Profile
        </Button>
      )}
    </StudentInfoContent>
  </Card>
);

export default StudentInfoCard;
