import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { Card, Button } from 'core-components';
import { Message } from 'components/ui';
import SpinnerV2 from 'components/SpinnerV2';

import { useStatus } from 'hooks/status';

import {
  useRegisterJuniClubInvitationEmailClickMutation,
  JuniClubInvitationType,
  JuniClubType,
  GuestInvitationType,
} from 'generated/graphql';
import GuestSignupForm from './GuestSignupForm';
import clubsBanner from './assets/clubs_banner.png';

const url =
  process.env.REACT_APP_API_URL === 'http://localhost:3001'
    ? 'http://localhost:3000'
    : 'https://app.junilearning.com';

interface GuestSignupCardProps {
  history: RouteComponentProps['history'];
  // TODO: update w proper type
  match: any;
  loadUserData: () => void;
}

interface GuestSignupHomeProps {
  history: RouteComponentProps['history'];
  pathname: string;
  clubDescription?: string | null;
}

interface GuestSignupBannerProps {
  thumbnailUrl?: string;
}

const GuestSignupCardWrapper = styled(Card)`
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2);
  border: 3px solid #9ddcff;
  background: #fcfeff;
  position: relative;
  max-width: 600px;
`;

const ClubIcon = styled.img.attrs({
  className:
    'absolute border-4 border-solid border-juni-blue-200 rounded-full overflow-hidden',
})`
  width: 100px;
  height: 100px;
  bottom: -45px;
`;

const GuestSignupCardBanner: FC<GuestSignupBannerProps> = ({ thumbnailUrl }) => (
  <div className="relative flex justify-center items-center">
    <img src={clubsBanner} alt="" />
    {thumbnailUrl && <ClubIcon src={thumbnailUrl} alt="" />}
  </div>
);

const GuestSignupHome: FC<GuestSignupHomeProps> = ({
  history,
  pathname,
  clubDescription,
}) => (
  <>
    <div className="text-blue-gray-500">
      {clubDescription}
      <br />
      <br />
      <i>
        Juni Learning is an online academy and community for kids ages 7-18. Juni
        Clubs enables kids to safely collaborate with like-minded peers to spark
        inspiration while engaging in our 5-star rated experience.
      </i>
    </div>
    <Button
      data-cy="guest-registration-signup-button"
      onClick={() => history.push(`${pathname}?birthdate`)}
      hasArrowIcon
    >
      Create My Account
    </Button>
    <a
      className="text-blue-gray-800 font-bold underline text-sm cursor-pointer"
      href="https://app.junilearning.com"
    >
      I already have a Juni Account
    </a>
  </>
);

const GuestSignupCard: FC<GuestSignupCardProps> = ({
  history,
  match,
  loadUserData,
}) => {
  const { pathname } = history?.location;
  const params = queryString.parse(history.location.search);
  const { clubInvitationId } = match?.params;

  const [registerEmailClick] = useRegisterJuniClubInvitationEmailClickMutation({
    onError: ({ graphQLErrors, networkError }) => {
      const err = graphQLErrors[0] || networkError;
      setError(err.message);
      const expiredInvite = err?.extensions?.expiredGuestInvite;
      setIsExpiredGuestInvite(expiredInvite);
    },
    onCompleted: data => {
      const {
        juniClubInvitation: clubInvitationData,
        clubInfo: clubInfoData,
        guestInvitation: guestInvitationData,
      } = data?.registerJuniClubInvitationEmailClick || {};

      // if guestInvitationId is null assume user already has an account
      if (clubInvitationData && !clubInvitationData.guestInvitationId) {
        history.push('/');
      }
      setGuestInvitation(guestInvitationData || {});
      setClubInvitation(clubInvitationData || {});
      setClubInfo(clubInfoData);
    },
    variables: {
      input: { juniClubInvitationId: clubInvitationId },
    },
  });

  const [
    clubInvitation,
    setClubInvitation,
  ] = useState<JuniClubInvitationType | null>(null);
  const [clubInfo, setClubInfo] = useState<JuniClubType | null>(null);
  const [guestInvitation, setGuestInvitation] = useState<GuestInvitationType | null>(
    null,
  );
  const [isExpiredGuestInvite, setIsExpiredGuestInvite] = useState<boolean>(false);

  const [status, { setError }] = useStatus();

  const showBirthDate = params && 'birthdate' in params;
  const showRegistration = params && 'register' in params;

  const showForm = showBirthDate || showRegistration;

  const fetchClubInvitation = useCallback(async () => {
    await registerEmailClick();
  }, [registerEmailClick]);

  useEffect(() => {
    fetchClubInvitation();
  }, [fetchClubInvitation]);

  const hasError = status?.status === 'error';
  const isLoading = !hasError && (!clubInvitation || !clubInfo);

  return (
    <GuestSignupCardWrapper
      className="rounded-xl mx-2 sm:mx-8 border-4"
      bannerHeight={!isLoading ? '72' : '0'}
      bannerContent={
        <GuestSignupCardBanner
          thumbnailUrl={clubInfo?.coverPhoto?.replace('_full', '_thumbnail')}
        />
      }
      contentClassName="flex flex-col justify-center text-center pt-3 px-7 sm:px-14 pb-12"
      spaceContent
      boxShadow
    >
      {isLoading ? (
        <SpinnerV2 />
      ) : (
        <>
          {hasError ? (
            <>
              <Message status={status?.status}>
                <div className="break-words">{status?.message}</div>
              </Message>
              {isExpiredGuestInvite && (
                <a href={url} className="w-full">
                  <Button className="w-full" hasArrowIcon>
                    Continue to Login
                  </Button>
                </a>
              )}
            </>
          ) : (
            <>
              <div className="text-blue-gray-800 text-2xl">
                {showForm
                  ? 'Create your account'
                  : `Hi ${clubInvitation?.inviteeName}, ready to join ${clubInfo?.displayName}?`}
              </div>
              {showForm ? (
                <GuestSignupForm
                  history={history}
                  showRegistration={showRegistration}
                  studentEmail={clubInvitation?.inviteeEmail}
                  studentName={clubInvitation?.inviteeName}
                  guestInvitationId={clubInvitation?.guestInvitationId}
                  isParentInvite={guestInvitation?.isParentInvite}
                  loadUserData={loadUserData}
                />
              ) : (
                <GuestSignupHome
                  history={history}
                  pathname={pathname}
                  clubDescription={clubInfo?.description}
                />
              )}
            </>
          )}
        </>
      )}
    </GuestSignupCardWrapper>
  );
};

export default GuestSignupCard;
