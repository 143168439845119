import { Icon } from 'core-components';

import { ProjectType } from 'generated/graphql';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import { TailwindColor } from 'theme/types';

export const JUNIVERSE_PROJECT_TYPE_MAP = {
  [ProjectType.Scratch]: 'scratch',
  [ProjectType.PythonTurtle]: 'pythonTurtle',
  [ProjectType.Python]: 'python',
  [ProjectType.Java]: 'java',
  [ProjectType.Cpp]: 'cpp',
  [ProjectType.Whiteboard]: 'whiteboard',
  [ProjectType.Math]: 'math',
  [ProjectType.MathWhiteboard]: 'math_whiteboard',
  [ProjectType.EnglishWriting]: 'english_writing',
  [ProjectType.EnglishWhiteboard]: 'english_whiteboard',
  [ProjectType.InvestingWhiteboard]: 'investing_whiteboard',
  [ProjectType.InvestingWriting]: 'investing_writing',
  [ProjectType.Js]: 'js',
};

// New horizons project data map: Jul 20, 2021
// Will be used across Juniverse, Homepage, and Profiles
const PROJECT_TYPE_TO_RENDER_INFO: {
  [key: string]: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    displayName: string;
    color?: TailwindColor;
    iconBgColor?: TailwindColor;
  };
} = {
  math: {
    icon: Icon.Percent,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS],
    color: 'j-purple-500',
    iconBgColor: 'j-purple-500',
  },
  math_whiteboard: {
    icon: Icon.Percent,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS],
    color: 'j-purple-500',
    iconBgColor: 'j-purple-500',
  },
  whiteboard: {
    icon: Icon.Percent,
    displayName: 'Whiteboard',
    color: 'j-purple-500',
    iconBgColor: 'j-purple-500',
  },
  english_whiteboard: {
    icon: Icon.Writing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH],
    color: 'j-purple-500',
    iconBgColor: 'j-pink-500',
  },
  english_writing: {
    icon: Icon.Writing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH],
    color: 'j-purple-500',
    iconBgColor: 'j-pink-500',
  },
  englishWriting: {
    icon: Icon.Writing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH],
    color: 'j-purple-500',
    iconBgColor: 'j-pink-500',
  },
  investing: {
    icon: Icon.Investing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING],
    color: 'j-pink-500',
    iconBgColor: 'j-pink-500',
  },
  investing_whiteboard: {
    icon: Icon.Investing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING],
    color: 'j-purple-500',
    iconBgColor: 'j-purple-500',
  },
  investingWhiteboard: {
    icon: Icon.Investing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING],
    color: 'j-purple-500',
    iconBgColor: 'j-purple-500',
  },
  investing_writing: {
    icon: Icon.Writing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING],
    color: 'j-pink-500',
    iconBgColor: 'j-pink-500',
  },
  investingWriting: {
    icon: Icon.Writing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING],
    color: 'j-pink-500',
    iconBgColor: 'j-pink-500',
  },
  english: {
    icon: Icon.Writing,
    displayName: SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH],
    color: 'j-pink-500',
    iconBgColor: 'j-pink-500',
  },
  scratch: {
    icon: Icon.Scratch,
    displayName: 'Scratch',
    color: 'j-orange-400',
    iconBgColor: 'j-orange-500',
  },
  pythonTurtle: {
    icon: Icon.Turtle,
    displayName: 'Python with Turtle',
    color: 'j-green-400',
    iconBgColor: 'j-green-500',
  },
  python: {
    icon: Icon.Python,
    displayName: 'Python',
    color: 'j-dark-500',
    iconBgColor: 'j-dark-500',
  },
  java: {
    icon: Icon.Java,
    displayName: 'Java',
    color: 'j-pink-600',
    iconBgColor: 'j-pink-600',
  },
  web: {
    // TODO: talk to design, find correct icon for old web projects
    icon: Icon.Writing,
    displayName: 'HTML',
    color: 'j-dark-500',
    iconBgColor: 'j-pink-500',
  },
  js: {
    // TODO: talk to design, find correct icon for old js projects
    icon: Icon.Javascript,
    displayName: 'JavaScript',
    color: 'j-dark-500',
    iconBgColor: 'j-yellow-400',
  },
  cpp: {
    icon: Icon.Cpp,
    displayName: 'C++',
    color: 'j-dark-500',
    iconBgColor: 'j-blue-500',
  },
  science_project: {
    icon: Icon.Atom,
    displayName: 'Science',
    color: 'j-green-500',
    iconBgColor: 'j-green-500',
  },
  unknown: {
    icon: Icon.QuestionMark,
    displayName: 'Unknown',
    color: 'j-dark-100',
    iconBgColor: 'j-dark-400',
  },
};

export const projectTypeToRenderInfo = (projectType?: string | null) => {
  if (!projectType) return PROJECT_TYPE_TO_RENDER_INFO.unknown;
  return (
    PROJECT_TYPE_TO_RENDER_INFO[projectType] || PROJECT_TYPE_TO_RENDER_INFO.unknown
  );
};
