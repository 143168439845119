import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { reduceBy, inc, propOr } from 'ramda';

import { Student } from 'models';
import { NewCard as Card, NewButton as Button } from 'core-components';
import useClubStore from 'app/clubs/stores/ClubStore';
import JuniSpinner from 'components/JuniSpinner';
import { JuniClubMembershipInfoType } from 'generated/graphql';
import { JuniClubLookupType } from 'app/clubs/MyClubsTypes';

interface Props {
  student: Student;
}

const ExtracurricularsAtAGlance: React.FC<Props> = ({ student }) => {
  const { _id: studentId } = student;
  const {
    isClubsLoading,
    juniClubs,
    juniClubMembershipInfos: membershipInfos,
  } = useClubStore();

  const clubMembershipCounts = reduceBy(
    acc => inc(acc),
    0,
    propOr('none', 'juniClubId'),
    Object.values(membershipInfos).filter(m => m.isActive),
  );

  const studentMemberships = Object.values(membershipInfos).filter(
    membership => membership.clientUUID === studentId && membership.isActive,
  );
  const clubsJoined = studentMemberships.length;

  return (
    <Card shadow="1">
      <div className="mb-4 text-xl font-medium text-j-dark-600">
        Extracurricular Activities
      </div>
      <Card.Divider />
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 items-start gap-4 text-j-dark-600">
        <div className="flex flex-col gap-4 text-base">
          <div className="font-medium">Clubs</div>
          <div className="text-j-dark-300">
            {student.firstName} is a member of {clubsJoined} different club
            {clubsJoined === 1 ? '' : 's'}
          </div>
          <div>
            <Link
              to={`/learner/${studentId}/my_clubs`}
              target="_blank"
              className="no-underline"
            >
              <Button variant="secondary" size="small">
                {clubsJoined === 0 ? 'Check out clubs here!' : 'View all'}
              </Button>
            </Link>
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-col gap-4">
            {isClubsLoading && <JuniSpinner size={80} />}
            {studentMemberships
              ?.sort((m1, m2) => {
                const m1Status = m1.membershipStatus === 'moderator' ? -1 : 1;
                const m2Status = m2.membershipStatus === 'moderator' ? -1 : 1;
                return m1Status - m2Status;
              })
              .slice(0, 5)
              .map(studentMembership => (
                <ClubCard
                  key={studentMembership._id}
                  studentId={studentId}
                  studentMembership={studentMembership}
                  juniClubs={juniClubs}
                  clubMembershipCounts={clubMembershipCounts}
                />
              ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

interface ClubCardProps {
  studentId: string;
  studentMembership: JuniClubMembershipInfoType;
  juniClubs: JuniClubLookupType;
  clubMembershipCounts: { [index: string]: number };
}

const ClubCard: FC<ClubCardProps> = ({
  studentId,
  studentMembership,
  juniClubs,
  clubMembershipCounts,
}) => {
  const clubDisplayName = juniClubs[studentMembership.juniClubId].displayName;
  const isModerator = studentMembership.membershipStatus === 'moderator';
  const clubMembershipCount = clubMembershipCounts[studentMembership.juniClubId];

  return (
    <Link
      key={studentMembership._id}
      to={`/learner/${studentId}/club_page/${studentMembership.juniClubId}/general`}
      className="no-underline text-j-dark-600"
      target="_blank"
    >
      <div
        key={studentMembership._id}
        className="flex flex-col gap-2 p-4 border border-solid border-j-dark-100 rounded-lg"
      >
        <div className="flex items-center gap-2">
          <span className="text-base font-medium">{clubDisplayName}</span>
          {isModerator && (
            <span className="px-2 py-1 rounded-md bg-j-blue-100 font-medium text-2xs text-j-blue-600">
              CLUB LEAD
            </span>
          )}
        </div>
        <div className="text-base text-j-dark-300">
          {clubMembershipCount} member
          {clubMembershipCount === 1 ? '' : 's'}
        </div>
      </div>
    </Link>
  );
};

export default ExtracurricularsAtAGlance;
