import React from 'react';
import Popover from '../Popover';
import Option from './Option';

type Statics = {
  Option: typeof Option;
};

/**
 * A special-purposed kind of Popover with outer styling.
 * It is expected that positioning gets passed in as a style object.
 * Covered responsibilities: positioning, outer styling - content padding.
 * Enhancements - max height/width and scrolling, virtualizing long lists.
 */
const Dropdown: React.FC<React.ComponentProps<typeof Popover>> & Statics = ({
  children,
  ...props
}) => (
  <Popover
    {...props}
    className="absolute px-3 py-6 box-border z-20"
    role="listbox"
    tabIndex={-1}
  >
    {children}
  </Popover>
);

Dropdown.Option = Option;

export default Dropdown;
