import React from 'react';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { InputField } from 'components/ui';
import SpinnerV2 from 'components/SpinnerV2';
import { useSendJuniClubInvitationMutation } from 'generated/graphql';

import {
  AddFriendButton,
  CloseButton,
  CloseIcon,
  InputWrapper,
  InviteLabel,
  InviteMessage,
  SubmitButton,
  OuterWrapper,
  RowWrapper,
  ResponsiveRowWrapper,
} from './styles';

interface Friend {
  name: string;
  email: string;
  id: string;
  submitted: boolean;
  backendError: string;
}

interface FriendList {
  friends: Friend[];
}

const validationSchema = Yup.object().shape({
  friends: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
    }),
  ),
});

interface FriendInviterProps {
  juniClubId: string;
  inviterStudentId?: string;
  inviterInstructorUserId?: string;
}

const FriendInviter: React.FC<FriendInviterProps> = ({
  juniClubId,
  inviterStudentId,
  inviterInstructorUserId,
}) => {
  const initialValues = {
    friends: [
      {
        name: '',
        email: '',
        // used as React rendering key.
        id: _.uniqueId('friend_'),
        submitted: false,
        backendError: '',
      },
    ],
  };
  const addFriend = (
    values: FriendList,
    setValues: (values: FriendList) => void,
  ) => {
    const friends = [
      ...values.friends,
      {
        name: '',
        email: '',
        id: _.uniqueId('friend_'),
        submitted: false,
        backendError: '',
      },
    ];
    setValues({ ...values, friends });
  };
  const removeFriend = (
    friend: Friend,
    values: FriendList,
    setValues: (values: FriendList) => void,
  ) => {
    if (values.friends.length === 1) {
      setValues(initialValues);
    } else {
      const friends = values.friends.filter(f => f.id !== friend.id);
      setValues({ ...values, friends });
    }
  };
  const setSubmittedValue = (
    friend: Friend,
    isSubmitted: boolean,
    values: FriendList,
    setValues: (values: FriendList) => void,
  ) => {
    const friends = [...values.friends];
    const i = _.findIndex(friends, f => f.id === friend.id);
    friends[i].submitted = isSubmitted;
    setValues({ ...values, friends });
  };
  const setFriendError = (
    friend: Friend,
    error: string,
    values: FriendList,
    setValues: (values: FriendList) => void,
  ) => {
    const friends = [...values.friends];
    const i = friends.findIndex(f => f.id === friend.id);
    friends[i].backendError = error;
    setValues({ ...values, friends });
  };
  const [sendJuniClubInvitation] = useSendJuniClubInvitationMutation();
  const handleSubmit = async (
    values: FriendList,
    actions: FormikHelpers<FriendList>,
  ) => {
    actions.setSubmitting(true);
    // TODO
    // 1) UX note: when implemeting: reference  https://github.com/JuniLearning/juni-app-frontend/pull/921/files#r589090085
    // JProft note on bulk submit invitations errors.
    // 2) Handle instructor invites
    // 3) Add handling to backend to handle duplicated sent invites.
    await Promise.all(
      values.friends.map(async (friend: Friend) => {
        try {
          await sendJuniClubInvitation({
            variables: {
              input: {
                inviteeEmail: friend.email,
                inviteeName: friend.name,
                juniClubId,
                inviterStudentId,
                inviterInstructorUserId,
              },
            },
          });
          setSubmittedValue(friend, true, values, actions.setValues);
        } catch (err) {
          const customErrors = err.graphQLErrors?.[0]?.extensions;
          const errorMsg =
            (customErrors && customErrors.inviteeAlreadyMember) ||
            customErrors.inviteeInvitedRecently
              ? err.message
              : 'Our server had an error.';
          setFriendError(friend, errorMsg, values, actions.setValues);
        }
      }),
    );
    actions.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, setTouched, isSubmitting }) => (
        <Form className="flex flex-col items-center">
          <FieldArray name="friends">
            {() =>
              values.friends.map((friend, i) => {
                const friendSubmitted = friend.submitted;
                const { backendError } = friend;
                return (
                  <OuterWrapper key={friend.id}>
                    <ResponsiveRowWrapper className="pt-2">
                      <InputWrapper>
                        <InviteLabel>Friend's Name</InviteLabel>
                        <InputField.Formik
                          placeholder="Name..."
                          name={`friends.${i}.name`}
                          type="text"
                          disabled={friendSubmitted}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InviteLabel>Friend's Email Address</InviteLabel>
                        <InputField.Formik
                          placeholder="Email..."
                          name={`friends.${i}.email`}
                          type="text"
                          disabled={friendSubmitted}
                        />
                      </InputWrapper>
                    </ResponsiveRowWrapper>
                    <RowWrapper>
                      {friendSubmitted ? (
                        <InviteMessage status="success">
                          Invitation sent! Tell your friend to check their email for
                          more information on how to create an account.
                        </InviteMessage>
                      ) : null}
                      {!friendSubmitted && backendError.length > 0 ? (
                        <InviteMessage status="error">{backendError}</InviteMessage>
                      ) : null}
                    </RowWrapper>
                    <CloseButton
                      type="button"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.currentTarget.blur();
                        setTouched({});
                        removeFriend(friend, values, setValues);
                      }}
                    >
                      <CloseIcon />
                    </CloseButton>
                  </OuterWrapper>
                );
              })
            }
          </FieldArray>
          <ResponsiveRowWrapper>
            <AddFriendButton onClick={() => addFriend(values, setValues)}>
              + Add Another Friend
            </AddFriendButton>
            {/* TODO: Get correct spinner behaviour on loading button! */}
            <SubmitButton hasArrowIcon type="submit">
              {isSubmitting ? <SpinnerV2 /> : 'Send Invites'}
            </SubmitButton>
          </ResponsiveRowWrapper>
        </Form>
      )}
    </Formik>
  );
};
export default FriendInviter;
