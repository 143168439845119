import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.css';

const SelectField = ({
  className,
  disabled,
  label,
  field,
  form,
  options = [],
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  error,
}) => {
  const {
    name: fieldName,
    value: fieldValue,
    onChange: fieldChange,
    onBlur: fieldBlur,
  } = field || {};

  const handleChange = useCallback(
    e => {
      fieldChange?.(e);
      onChange?.(e.target.value, e.target.name);
    },
    [fieldChange, onChange],
  );

  const handleBlur = useCallback(
    e => {
      fieldBlur?.(e);
      onBlur?.(e.target.value, e.target.name);
    },
    [fieldBlur, onBlur],
  );

  const fullOptions = placeholder
    ? [{ label: placeholder, value: '' }, ...(options || [])]
    : options;

  const meta = form && form.getFieldMeta(fieldName);
  const errorText = meta ? meta.touched && meta.error : error;

  return (
    <label className={classNames('ui-select-field', 'ui-field', className)}>
      <div className="ui-field-label">{label}</div>
      <select
        disabled={disabled}
        name={field ? fieldName : name}
        value={(field ? fieldValue : value) || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {fullOptions.map(
          option =>
            (option.isOptgroup && (
              <optgroup key={option.label} label={option.label}>
                {option.options.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled || false}
                  >
                    {option.label}
                  </option>
                ))}
              </optgroup>
            )) || (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled || false}
              >
                {option.label}
              </option>
            ),
        )}
      </select>
      {errorText && <div className="ui-field-error">{errorText}</div>}
    </label>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array, // accepts array of {label:string, value:string}
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.node,
};

export default SelectField;
