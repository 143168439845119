import React, { useRef, useState, useLayoutEffect } from 'react';
import useClickOutside from 'hooks/outside';
import { useRemoveMessage } from 'app/clubs/stores/ClubStoreHelpers';
import { MsgType } from 'app/clubs/MyClubsTypes';
import styled from 'styled-components/macro';
import { Option, OptionsMenu, OptionText } from '../ClubOptions/ClubOptions';

const REMOVAL_CONFIRMATION_MESSAGE =
  'Are you sure you wish to permanently remove this message for violating the Juni Code of Conduct?';
const DELETION_CONFIRMATION_MESSAGE =
  'Are you sure you wish to permanently delete this message?';

interface ChatMessageOptionsProps {
  message: MsgType;
  studentId?: string;
  instructorUserId?: string;
  canModerate?: boolean;
  canDelete?: boolean;
  scrollableContainerRef?: React.RefObject<HTMLDivElement>;
}
const DropButton = styled.div.attrs({
  className:
    'flex items-center rounded-sm p-1 ml-2 h-4 cursor-pointer hover:opacity-70',
})`
  color: #c8c8c8;
  background-color: #ededed;
`;
const ChatMessageOptions: React.FC<ChatMessageOptionsProps> = ({
  message,
  studentId,
  instructorUserId,
  canModerate,
  canDelete,
  scrollableContainerRef,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const removeMessage = useRemoveMessage();
  const [menuTop, setMenuTop] = useState('0');
  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  useClickOutside(menuButtonRef, () => {
    setMenuOpen(false);
  });
  useLayoutEffect(() => {
    const menuHeight = menuRef.current?.offsetHeight || 0;
    const menuButtonHeight = menuButtonRef.current?.offsetHeight || 0;
    const bottomDifference =
      (scrollableContainerRef?.current?.getBoundingClientRect().bottom || 0) -
      (menuButtonRef.current?.getBoundingClientRect().bottom || 0);
    setMenuTop(
      bottomDifference < menuHeight ? `-${menuHeight}px` : `${menuButtonHeight}px`,
    );
  }, [scrollableContainerRef, menuOpen]);

  const handleRemoveOrDelete = async ({
    shouldDelete,
  }: {
    shouldDelete: boolean;
  }) => {
    if (submitting) {
      return;
    }
    const confirmationMessage = shouldDelete
      ? DELETION_CONFIRMATION_MESSAGE
      : REMOVAL_CONFIRMATION_MESSAGE;
    if (window.confirm(confirmationMessage)) {
      const imageUrls =
        message.payload && message.payload.msgImageUrls
          ? message.payload.msgImageUrls
          : null;
      try {
        setSubmitting(true);
        await removeMessage({
          studentId,
          instructorUserId,
          juniClubId: message.juniClubId,
          channelName: message.juniClubChannel,
          msgId: message.msgId,
          senderUUID: shouldDelete ? message.senderUUID : undefined,
          shouldDelete,
          imageUrls,
        });
      } catch (e) {
        setSubmitting(false);
        console.error(e);
      }
    }
  };
  return (
    <div className="relative">
      <DropButton
        ref={menuButtonRef}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        ...
      </DropButton>
      {menuOpen ? (
        <OptionsMenu ref={menuRef} top={menuTop} visible={menuOpen}>
          {canModerate && (
            <Option onClick={() => handleRemoveOrDelete({ shouldDelete: false })}>
              <OptionText>Moderate</OptionText>
            </Option>
          )}

          {canDelete && (
            <Option onClick={() => handleRemoveOrDelete({ shouldDelete: true })}>
              <OptionText>Delete</OptionText>
            </Option>
          )}
        </OptionsMenu>
      ) : null}
    </div>
  );
};

export default ChatMessageOptions;
