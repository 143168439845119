import styled from 'styled-components/macro';

// todo fix me and remove !important
export const ProjectEditorContainer = styled.div<any>`
  display: flex;
  height: initial !important;
  flex-direction: column !important;
  z-index: 10;
  ${props =>
    props.negativeSpacing
      ? `
        margin: 0 0 -11rem -1.5rem;
      `
      : `margin: 3rem -17rem 0 -19rem;`}

  padding: .75rem;
  align-items: stretch !important;
  justify-content: center;
  margin-left: auto;
  background: white;
  position: absolute;
  align-self: flex-end;
  justify-self: flex-end;
  border-radius: 0.625rem;
  border: 0.125rem solid #e1f0f8;
  width: 18.75rem;
  ${props => (props.negativeSpacing ? `max-height: 12rem;` : ``)}
  z-index: 999;

  & .header {
    color: #3c6176;
    font-size: 1rem;
    font-weight: 600;
  }

  & .project-header {
    color: #3c6176;
    font-size: 1.125rem;
    margin: 0;
    letter-spacing: 0;
    font-weight: bold;
  }

  & input {
    margin: 1rem 0;
  }

  &:after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    border: 0.125rem solid #e1f0f8;
    background: white;
    top: -0.5rem;
    width: 0.75rem;
    height: 0.75rem;
    ${props => (props.leftAlignIcon ? `margin-left: 0rem;` : `margin-left: 1rem;`)}

    border-bottom: 0rem solid transparent;
    border-right: 0rem solid transparent;
    border-radius: 0.1875rem;
  }

  @media (max-width: 600px) {
    width: 90%;
    left: 0;
    right: 0;
    ${props =>
      props.negativeSpacing
        ? `
          margin: 0 -20rem -11rem;
        `
        : `margin: 3rem auto 0;`}

    &:after {
      ${props =>
        props.negativeSpacing ? `margin-left: 4rem;` : `margin-left: 24rem;`}
    }
  }
`;

export const Error = styled.div`
  color: #bb2222;
  margin: 0.4rem 0;
`;
