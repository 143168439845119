import moment from 'moment-timezone';
import _ from 'lodash';

import { DAYS_OF_THE_WEEK } from 'constants/dates';

export const START_HOUR = 7;
export const END_HOUR = 19;
const MIN_RECOMMENDED_AGE = 5;
export const NUM_AVAILABILITIES = 3;

// courses that students can sign up for, pending instructor approval
export const COURSES_REQUIRING_INSTRUCTOR_APPROVAL = [
  'usaco_bronze_on_demand',
  'usaco_bronze',
  'usaco_silver',
  'usaco_gold',
];

export const PRIORITY_LEVELS: { [key: number]: string } = {
  1: 'First',
  2: 'Second',
  3: 'Third',
};

export const createStartDatesArray = (
  day: string,
  hour: string,
  timezone: string,
  minStartDate?: moment.Moment,
) => {
  const datesSixWeeksFromSelectedDay: string[] = [];
  if (!DAYS_OF_THE_WEEK.includes(day)) return datesSixWeeksFromSelectedDay;

  const dateToday = moment().tz(timezone);
  const hourInt = parseInt(hour, 10);
  const selectedDateTimePassed = moment().tz(timezone).day(day).isBefore(dateToday);

  const earliestStartDate = moment()
    .tz(timezone)
    .day(day)
    .set('hour', hourInt)
    // if datetime passed add 2 weeks to allow 7 days min
    .add(selectedDateTimePassed ? 2 : 1, 'week');

  if (minStartDate && earliestStartDate.isBefore(minStartDate)) {
    const weeksToAdd = Math.ceil(
      minStartDate.diff(earliestStartDate, 'weeks', true),
    );
    earliestStartDate.add(weeksToAdd, 'weeks');
  }

  return _.range(DAYS_OF_THE_WEEK.length).map(index =>
    earliestStartDate.clone().add(index, 'week').format('dddd, MMMM Do, YYYY'),
  );
};

export const createTimesArray = () =>
  _.range(START_HOUR, END_HOUR).map(currHour =>
    moment().set('hour', currHour).startOf('hour').format('hh:mm A'),
  );

export const generateYears = (startYear: number, endYear: number) =>
  _.range(startYear, endYear - MIN_RECOMMENDED_AGE + 1);
