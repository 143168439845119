import React, { useRef, useEffect, useState } from 'react';

const Annotation: React.FC<{
  annotation: string;
  annotationTop: number;
  highlightColor: string;
  entityKey: string;
  setAnnotationBottom: (entityKey: string, bottom: number) => void;
  colorMap: Record<string, { solidValue: string }>;
}> = ({
  annotation,
  annotationTop,
  highlightColor,
  entityKey,
  setAnnotationBottom,
  colorMap,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [bottom, setBottom] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!container?.current) return;
    const curBottom =
      container.current.getBoundingClientRect().bottom + window.scrollY;
    if (curBottom === bottom) return;
    setBottom(curBottom);
    setAnnotationBottom(entityKey, curBottom);
  }, [bottom, setAnnotationBottom, entityKey]);

  return (
    <div
      ref={container}
      className="absolute mx-6 font-graphik"
      style={{ top: annotationTop }}
    >
      <div className="flex items-center">
        <div
          className="w-3 h-3 rounded-full"
          style={{ backgroundColor: colorMap[highlightColor].solidValue }}
        />
        <div className="ml-3 text-sm leading-6 text-j-dark-600 font-semibold">
          You wrote:
        </div>
      </div>
      <div className="mt-1 text-xs leading-5 text-j-dark-400">{annotation}</div>
    </div>
  );
};

export default Annotation;
