import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RecordOpenButton } from '..';

const JideRecordButton = ({ setRecordingMode }) => (
  <div>
    <RecordOpenButton onClick={() => setRecordingMode(true)}>
      <span className="widget__title">Record</span>
      <FontAwesomeIcon icon={['fas', 'video']} />
    </RecordOpenButton>
  </div>
);

export default JideRecordButton;
