import { FC } from 'react';
import { isValid } from 'date-fns';

import InputField from 'app/signup_session/components/InputField';

import WarningBlock from 'app/signup_session/components/WarningBlock';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import { BaseMembershipInfoForm, HEADER_CLASSES } from './ParentForm';
import { LocalStudentState } from '../StudentInfo';

interface StudentFormProps extends BaseMembershipInfoForm {
  localStudentState: LocalStudentState;
  onLocalStudentChange: (newData: Partial<LocalStudentState>) => void;
  ageIsOutsideOfRange: boolean;
  hideTitle?: boolean;
}

const StudentForm: FC<StudentFormProps> = ({
  touchedFields,
  localStudentState,
  onLocalStudentChange,
  addToTouchedFields,
  ageIsOutsideOfRange,
  hideTitle,
}) => {
  const { flags } = useSignupContext();

  const getBirthDateValidationError = (): string | undefined => {
    if (!touchedFields.includes('birthdate')) {
      return undefined;
    }

    const { birthdate } = localStudentState;

    if (!birthdate) {
      return 'This field is required';
    }

    if (!isValid(new Date(birthdate))) {
      return 'Please input a valid date';
    }

    return undefined;
  };

  const labelTextStyles = 'text-j-dark-600 whitespace-nowrap font-medium text-sm';

  return (
    <>
      {!hideTitle && <h3 className={HEADER_CLASSES}>Student Details</h3>}
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        value={localStudentState.name ?? ''}
        id="student-name"
        label="Student Full Name"
        onBlur={() => addToTouchedFields('student-name')}
        onChange={e => onLocalStudentChange({ name: e.target.value })}
        validationError={
          touchedFields.includes('student-name') && !localStudentState.name
            ? 'This field is required'
            : undefined
        }
      />
      {!flags.isBootcampSignup && !flags.isOnDemandSignup && (
        <>
          <InputField
            labelTextStyles={labelTextStyles}
            value={localStudentState.birthdate}
            type="date"
            id="birthdate"
            textRight="MM/DD/YYYY"
            label="Date of Birth"
            onBlur={() => addToTouchedFields('birthdate')}
            onChange={e => {
              onLocalStudentChange({
                birthdate: e.target.value,
              });
            }}
            validationError={getBirthDateValidationError()}
          />
          {ageIsOutsideOfRange && (
            <WarningBlock>
              We currently don’t teach kids outside of ages 7-18. Please check back
              later, as we’re always expanding our offerings!
            </WarningBlock>
          )}
        </>
      )}
    </>
  );
};

export default StudentForm;
