import { useRef, useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import moment from 'moment';

import {
  createMultipartUpload,
  completeMultipartUpload,
  deleteUsermedia,
  updateUsermedia,
  getProjectUsermedia,
} from 'services/usermedia';

const ONE_MB = 1048576;
const PART_SIZE_MB = 5;
const MAX_RECORDING_LENGTH = 600;
const CODEC = 'video/webm;codecs=h264';

// This custom hook is responsible for all logic behind recording and uploading.
// This involves taking the customStream created by the streamBuilder, recording it
// with MediaRecorder from the Browser API, and simulataneously performing
// a multi-part upload from the frontend directly to S3
const useRecorderUploader = ({
  jideUser,
  idLookup,
  activeNav,
  combinedStreamRef,
  setSaveRecVideoUrl,
}) => {
  // Refs
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const chunksSizeRef = useRef(0);
  const uploadPartsRef = useRef([]);
  const uploadPartsIdxRef = useRef(0);
  const s3UploadIdRef = useRef(null);
  const allBlobsUrlRef = useRef(null);
  const usermediaIdRef = useRef(null);
  const intervalRef = useRef(null);

  const userTypeRef = useRef(undefined);
  const studentIdRef = useRef(undefined);
  const teacherUserIdRef = useRef(undefined);
  const projectIdRef = useRef(undefined);
  const customProjectIdRef = useRef(undefined);

  // States
  // usePrev utility fn to get prevState when using useState in functional components
  const usePrev = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const [isRecording, setIsRecording] = useState(false);
  const prevIsRecording = usePrev(isRecording);
  const [overallUploadProgress, setOverallUploadProgress] = useState(0);
  const [recordingStart, setRecordingStart] = useState(null);
  const [recordingEnd, setRecordingEnd] = useState(null);
  const [recordingLength, setRecordingLength] = useState(null);
  const [recorderUploaderInitialized, setRecorderUploaderInitialized] = useState(
    false,
  );

  const project = idLookup[activeNav.project];
  const resetVars = useCallback(() => {
    // Reset refs
    mediaRecorderRef.current = null;
    chunksRef.current = [];
    chunksSizeRef.current = 0;
    uploadPartsRef.current = [];
    uploadPartsIdxRef.current = 0;
    s3UploadIdRef.current = null;
    if (allBlobsUrlRef.current) window.URL.revokeObjectURL(allBlobsUrlRef.current);
    allBlobsUrlRef.current = null;
    usermediaIdRef.current = null;
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = null;

    if (jideUser.type === 'student') {
      userTypeRef.current = 'learner';
      studentIdRef.current = jideUser._id;
    } else {
      userTypeRef.current = 'instructor';
      teacherUserIdRef.current = jideUser._id;
    }
    if (project?.properties.isCustomProject) {
      customProjectIdRef.current = project?.id;
    } else {
      projectIdRef.current = project?.id;
    }

    // Reset states
    setIsRecording(false);
    setOverallUploadProgress(0);
    setRecordingStart(null);
    setRecordingEnd(null);
    setRecordingLength(null);

    // Mark recorderUploader as initialized
    setRecorderUploaderInitialized(true);
  }, [
    jideUser._id,
    jideUser.type,
    project?.id,
    project?.properties.isCustomProject,
  ]);

  // Recording-Related
  const startRecording = async () => {
    resetVars();

    // Ask server to use S3 API to create a multipart upload and get the presigned
    // upload urls, upload ID, and id of the usermedia DB object created
    const { success, urls, uploadId, usermediaId } = await createMultipartUpload({
      userType: userTypeRef.current,
      studentId: studentIdRef.current,
      teacherUserId: teacherUserIdRef.current,
      projectId: projectIdRef.current,
      customProjectId: customProjectIdRef.current,
    });
    if (!success) return;

    // Store info in refs
    // uploadParts keeps track of data and metadata of each part of the
    // multipart upload in an ordered array that can be updated independently
    usermediaIdRef.current = usermediaId;
    s3UploadIdRef.current = uploadId;
    uploadPartsRef.current = urls.map(url => ({
      url,
      blob: null,
      size: null,
      progress: null,
      etag: null,
      promise: true,
    }));

    // Create a new MediaRecorder object and store in ref
    // NOTE: firefox seems to have issues playing vp9 encoded by chrome,
    // and Safari doesn't support webM at all. However, vp9 is better than vp8
    // and can easily and quickly be converted to mp4, whereas vp8
    // takes a long time
    const options = {
      mimeType: CODEC,
      videoBitsPerSecond: 5000000,
    };
    mediaRecorderRef.current = new MediaRecorder(combinedStreamRef.current, options);

    // init MediaRecorder ondataavailable event listener
    mediaRecorderRef.current.ondataavailable = e => {
      chunksRef.current.push(e.data); // add video blob data to temp array of chunks
      chunksSizeRef.current += e.data.size; // keep track of size of blob data in temp array
      console.log(`${chunksSizeRef.current / ONE_MB} MB`);
      if (
        uploadPartsIdxRef.current < uploadPartsRef.current.length - 1 &&
        chunksSizeRef.current / ONE_MB >= PART_SIZE_MB
      ) {
        // if part size limit reached, initiate upload of latest part
        // unless you're on the final part, which can be of any size
        performUpload();
      }
    };

    // Set starting and ending timestamps
    setRecordingStart(moment.utc().format());
    setRecordingEnd(moment.utc().format());

    // Set event listeners so that the behavior is consistent
    // even if recording is stopped for other reasons (e.g. user stops sharing)
    mediaRecorderRef.current.onstart = onStartRecording;
    mediaRecorderRef.current.onstop = onStopRecording;

    mediaRecorderRef.current.onerror = e => {
      console.error('ERROR', e);
    };

    // Start MediaRecorder recording of the combinedStream
    // (input val specifies interval of ondataavailable event in ms)
    mediaRecorderRef.current.start(1000 * 5);
    setIsRecording(true);
  };
  // Update timestamp every second once you start recording
  const onStartRecording = () => {
    intervalRef.current = setInterval(() => {
      setRecordingEnd(moment.utc().format());
    }, 1000);
  };

  // Instead of putting logic into stopRecording, have it trigger the
  // built-in onstop event so that behavior is consistent no matter
  // what causes the recording to stop
  const stopRecording = () => {
    const state = mediaRecorderRef.current?.state;
    if (state && state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
  };
  // Logic for when the recording is stopped for any reason
  const onStopRecording = async () => {
    setIsRecording(false);

    clearInterval(intervalRef.current);
    intervalRef.current = null;

    // init upload of last part, however full it is
    await performUpload();

    // stitch together blob video data from all parts into one video locally
    // and create local URL so it can be shown in a preview on the
    // saveRecording modal. variable is reassigned since allBlobs can be large
    let allBlobs = uploadPartsRef.current.filter(up => up.blob).map(up => up.blob);
    allBlobs = new Blob(allBlobs, { type: CODEC });
    allBlobsUrlRef.current = window.URL.createObjectURL(allBlobs);
    setSaveRecVideoUrl(allBlobsUrlRef.current);

    // To Automatically Download video for local testing
    // let a = document.createElement('a');
    // document.body.appendChild(a);
    // a.style = 'display: none';
    // a.href = allBlobsUrl.current;
    // a.download = 'test.webm';
    // a.click();

    // Create array of uploading promises of all parts
    // and wait for them all to finish using Promise.all
    const uploadPartsPromises = uploadPartsRef.current
      .filter(up => up.blob)
      .map(up => up.promise);
    const responses = await Promise.all(uploadPartsPromises);
    const parts = responses.map((r, i) =>
      // S3 API requires us to keep track of ETag and PartNumbers
      // of each part of the multipart upload.
      // Etag is returned in the header of the response when uploading
      // to the presigned URL in S3. Must be set as part of the
      // S3 bucket policy otherwise it won't be returned
      ({ ETag: r.headers.etag, PartNumber: i + 1 }),
    );

    // Once all parts have been uploaded, give Juni Server the metadata.
    // It will contact the S3 API and tell it the upload is complete.
    // S3 will then stitch the parts together and make the video available
    await completeMultipartUpload({
      userType: userTypeRef.current,
      studentId: studentIdRef.current,
      usermediaId: usermediaIdRef.current,
      uploadId: s3UploadIdRef.current,
      parts,
    });
    // set upload progress to a full 100%
    setOverallUploadProgress(1.0);
  };

  // Video Upload-Related
  const performUpload = async () => {
    // Upload video to S3 directly from frontend using
    // presigned URL
    const axios = Axios.create();
    delete axios.defaults.headers.put['Content-Type'];
    // for above, see https://www.altostra.com/blog/multipart-uploads-with-s3-presigned-url

    // Each uploadPart is filled up sequentially.
    // Move marker to next part and we'll begin uploading current one
    const idx = uploadPartsIdxRef.current;
    uploadPartsIdxRef.current += 1;

    // Get blob data from the temp chunks array and encode as webm,
    // then reset the temp chunks array
    const blob = new Blob(chunksRef.current, { type: CODEC });
    chunksRef.current = [];
    chunksSizeRef.current = 0;

    // Put encoded blob into the uploadParts array
    uploadPartsRef.current[idx].blob = blob;

    // Initialize a callback for when axios
    // uploadProgress event is triggered in order to update
    // uploadProgress percentage
    const config = {
      onUploadProgress: progressEvent => {
        uploadPartsRef.current[idx].progress =
          progressEvent.loaded / progressEvent.total;
        updateOverallProgress();
      },
    };

    // Start upload to S3
    uploadPartsRef.current[idx].promise = axios
      .put(uploadPartsRef.current[idx].url, blob, config)
      .catch(err => console.error(err));
    return true;
  };

  // Calculate overall upload progress of all parts by calculating
  // a weighted average of all progresses
  const updateOverallProgress = () => {
    const inProgressParts = uploadPartsRef.current.filter(up => up.blob);
    const totalUploaded = inProgressParts
      .map(up => up.blob.size * up.progress)
      .reduce((a, b) => a + b, 0);
    const k = 1;
    const total =
      inProgressParts.map(up => up.blob.size).reduce((a, b) => a + b, 0) + k;
    setOverallUploadProgress(totalUploaded / total);
  };

  const cleanup = useCallback(
    () => () => {
      if (isRecording) stopRecording();
    },
    [isRecording],
  );

  // Effects
  useEffect(resetVars, [resetVars]);
  useEffect(() => {
    const d = moment
      .duration(moment.utc(recordingEnd).diff(moment.utc(recordingStart)))
      .asSeconds();
    setRecordingLength(d);
    if (d >= MAX_RECORDING_LENGTH) {
      stopRecording();
    }
  }, [recordingEnd, recordingStart]);
  useEffect(cleanup, [cleanup]);

  // Services
  // usermedia Juni Server API frontend interfaces
  // This is a natural place for them since the hook already has all the
  // relevant data in refs
  // discard the video currently being uploaded
  const discardVideo = async () => {
    await deleteUsermedia({
      userType: userTypeRef.current,
      studentId: studentIdRef.current,
      usermediaId: usermediaIdRef.current,
    });
  };

  // save video metadata in DB for video currently being uploaded
  const saveVideo = async (title, description) => {
    await updateUsermedia({
      userType: userTypeRef.current,
      studentId: studentIdRef.current,
      usermediaId: usermediaIdRef.current,
      title,
      description,
      originalLength: recordingLength,
    });
  };

  // get all videos for currently selected jide project
  const getProjectVideos = () =>
    // returns promise
    getProjectUsermedia({
      userType: userTypeRef.current,
      studentId: studentIdRef.current,
      teacherUserId: teacherUserIdRef.current,
      projectId: projectIdRef.current,
      customProjectId: customProjectIdRef.current,
    });
  // Export utility functions and important state variables
  return {
    recorderUploaderInitialized,
    isRecording,
    prevIsRecording,
    overallUploadProgress,
    recordingLength,
    startRecording,
    stopRecording,
    discardVideo,
    saveVideo,
    getProjectVideos,
  };
};

export default useRecorderUploader;
