import { ReferralCode, Parent } from 'models';
import generateRest from '../generateRest';

interface AnnotatedReferralCode extends ReferralCode {
  parent?: Parent;
}

const ReferralCodeService = generateRest<AnnotatedReferralCode>(
  'join/referral_codes',
  { noEnvelope: true },
);

export default ReferralCodeService;
