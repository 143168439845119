import React, { FC } from 'react';
import { Student } from 'generated/graphql';
import _ from 'lodash';

import { projectTypeToRenderInfo } from 'constants/project_types';
import { NavLink } from 'react-router-dom';
import { Panel } from 'core-components';
import { buildPlaygroundLink, buildNonPlaygroundLink } from 'app/playground/utils';
import { useCourseNameToBasicMetadata } from 'hooks/course';

interface ProjectCardV2Props {
  projectId: string;
  student: Student;
  idLookup: any;
}
const ProjectCardV2: FC<ProjectCardV2Props> = ({ projectId, student, idLookup }) => {
  const courseNameToBasicMetadata = useCourseNameToBasicMetadata();

  if (!idLookup) return null;
  const rawProject = idLookup[projectId];
  if (!rawProject) return null;

  const courseId = rawProject?.properties?.courseId;
  const course = courseId ? idLookup[courseId] : undefined;
  if (!rawProject.isPlayground && !course) return null;

  const projectType = rawProject.isPlayground
    ? rawProject.projectType || 'unknown'
    : courseNameToBasicMetadata(course?.properties?.name).defaultJideEnv;

  const project = {
    ..._.omit(rawProject, ['properties']),
    ...(rawProject.properties || {}),
    projectType,
    courseDisplayName: course?.properties?.displayName,
  };

  const projectLink = project.isPlayground
    ? buildPlaygroundLink({
        projectId,
        projectType,
        projectName: project.displayName,
        userId: student._id,
      })
    : buildNonPlaygroundLink({
        userId: student._id,
        projectId,
        courseType: course?.properties?.courseType,
      });
  const renderInfo = projectTypeToRenderInfo(project.projectType);
  const upperLine =
    project.isPlayground || project.isCustomProject
      ? project.displayName
      : (project.displayName || ':').split(':')[1];
  const lowerLine = project.isPlayground
    ? 'Playground'
    : project.isCustomProject
    ? project.courseDisplayName || 'Custom Project'
    : (project.displayName || ':').split(':')[0];

  return (
    <NavLink className="no-underline" to={projectLink}>
      <Panel
        backgroundColor="transparent"
        borderColor="j-dark-100"
        padding="4"
        className="flex border items-start text-sm text-j-dark-600 mb-2"
      >
        <div className="flex flex-col mr-3 flex-shrink-0 pt-1">
          <div
            className={`flex text-white items-center justify-center rounded-lg bg-${renderInfo.color} w-8 h-8 text-xs`}
          >
            {React.createElement(renderInfo.icon, { className: 'w-4 h-4' })}
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          <span className="font-semibold mb-1">{upperLine}</span>
          <span className="text-xs text-j-dark-400">{lowerLine}</span>
        </div>
      </Panel>
    </NavLink>
  );
};

export default ProjectCardV2;
