import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import WhatsNewPopupTemplate from 'app/admin/manage-customers/ManageAnnouncement/JuniAdsManager/templates/WhatsNewPopupTemplate';
import { JuniAd, useLoadPopupQuery } from 'generated/graphql';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

/**
 * Renders a "What's New" popup serving a JuniAd.
 * https://www.notion.so/junilearning/Learner-Portal-Juni-Ads-What-s-New-RFD-f3d330efc2204f5eb2f8bfacd39c7d8f
 */
const Popups = () => {
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [juniAd, setJuniAd] = useState<JuniAd | undefined>();
  const JUNI_AD_PROPERTY = 'HOMEPAGE_POPUP';

  const { data, loading } = useLoadPopupQuery({
    variables: { property: JUNI_AD_PROPERTY },
  });

  const getJuniAdLog = (juniAd?: JuniAd, action?: string) => {
    if (!juniAd) return {};
    return {
      juniAdId: juniAd._id,
      juniAdTitle: juniAd.title,
      juniAdProperty: JUNI_AD_PROPERTY,
      juniAdAction: action,
    };
  };

  useEffect(() => {
    if (!loading && data && data?.serveJuniAd?.items?.length > 0) {
      const servedAd = _.first(data?.serveJuniAd?.items) as JuniAd;
      setJuniAd(servedAd);
      setShowPopupModal(true);
      JuniAnalytics.track('juni_ad_served', getJuniAdLog(servedAd));
    }
  }, [loading, data, setShowPopupModal, setJuniAd]);

  /**
   * Click on the background or the 'x' hides the modal and is logged.
   */
  const closeModal = () => {
    JuniAnalytics.track('juni_ad_dismissed', getJuniAdLog(juniAd));
    setShowPopupModal(false);
  };

  /**
   * Buttons with clickthrough actions open the page in a new tab and are logged.
   */
  const juniAdAction = (target: string) => {
    // TODO(andrewito): V2 Logging to prevent reshowing juniAds that are actioned.
    JuniAnalytics.track('juni_ad_clicked', getJuniAdLog(juniAd, target));
    window.open(target, '_blank');
    setShowPopupModal(false);
  };

  return (
    <WhatsNewPopupTemplate
      closeModal={closeModal}
      isOpen={showPopupModal}
      juniAd={juniAd}
      onJuniAdAction={juniAdAction}
    ></WhatsNewPopupTemplate>
  );
};

export default Popups;
