import React, { useState } from 'react';
import { JuniClubType } from 'generated/graphql';
import classNames from 'classnames';

import { TailwindColor } from 'theme/types';
import { Card } from 'core-components';
import { Link } from 'react-router-dom';
import {
  JuniClubMembershipInfoPlusBadgeType,
  UnreadMsgLookupType,
} from 'app/clubs/MyClubsTypes';
import { getClubLink } from 'app/clubs/helpers';
import NotificationsBadge from 'components/NotificationsBadge';
import { MAX_CHANNELS_PER_CLUB } from 'constants/clubs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBullhorn, faHashtag } from '@fortawesome/free-solid-svg-icons';
import CreateChannelModal from '../CreateChannelModal';
import EditChannel from '../EditChannels';

interface ChannelLinkProps {
  channelName: string;
  active: boolean;
  notifications: number;
  colorTheme: JuniClubType['colorTheme'];
  url: string;
  readOnly: boolean;
  juniClubId: string;
  studentIdParam?: string;
  instructorUserIdParam?: string;
  canEditChannels: boolean;
}
const ChannelLink: React.FC<ChannelLinkProps> = ({
  channelName,
  notifications,
  colorTheme,
  active,
  url,
  readOnly,
  juniClubId,
  studentIdParam,
  instructorUserIdParam,
  canEditChannels,
}) => (
  <div className="first:pt-4 last:pb-4" style={{ minWidth: '6rem' }}>
    <div className="flex w-full">
      <Link
        key={channelName}
        to={url}
        className={classNames(
          'whitespace-nowrap',
          'overflow-hidden',
          'cursor-pointer',
          'my-0.5',
          'text-white',
          'no-underline',
          { 'hover:opacity-70': !active },
          { 'font-bold': active },
          'flex-grow',
          'flex-row',
          'flex',
        )}
      >
        <div className="pr-4">
          {readOnly ? (
            <FontAwesomeIcon icon={faBullhorn} opacity="80%" size="xs" />
          ) : (
            <FontAwesomeIcon icon={faHashtag} opacity="80%" size="sm" />
          )}
        </div>
        <div className="overflow-hidden overflow-ellipsis">{channelName}</div>
        {!active && (
          <div className="flex flex-grow justify-end items-center">
            <NotificationsBadge
              numNotifications={notifications}
              backgroundColor={`${colorTheme}-medium` as TailwindColor}
            />
          </div>
        )}
      </Link>

      {canEditChannels && (
        <EditChannel
          studentIdParam={studentIdParam}
          instructorUserIdParam={instructorUserIdParam}
          isChannelReadOnly={readOnly}
          channelName={channelName}
          juniClubId={juniClubId}
          showButton={active}
        />
      )}
    </div>
  </div>
);

interface ClubChannelsProps {
  studentIdParam?: string;
  instructorUserIdParam?: string;
  channelNameParam: string;
  currentClubState: JuniClubType;
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
  unreadMessages: UnreadMsgLookupType;
}
const ClubChannels: React.FC<ClubChannelsProps> = ({
  studentIdParam,
  instructorUserIdParam,
  channelNameParam,
  currentClubState,
  currentClubMemberState,
  unreadMessages,
}) => {
  const [openChannelCreate, setOpenChannelCreate] = useState(false);

  const baseColor = currentClubState.colorTheme || '';
  const currentClubChannelsState = currentClubState.channels || [];
  const badgeName = currentClubMemberState?.badgeInfo?.name;
  const canEditChannels = !!(
    badgeName && ['leader', 'advisor', 'moderator'].includes(badgeName)
  );
  const canCreateChannels = !!(
    canEditChannels && currentClubChannelsState.length < MAX_CHANNELS_PER_CLUB
  );
  // TODO: perhaps move this and similar logics into src/app/clubs/helpers.ts

  const unreadMessagesForClub = unreadMessages[currentClubState._id] || {};

  return (
    <>
      <CreateChannelModal
        juniClubId={currentClubState._id}
        studentId={studentIdParam}
        instructorUserId={instructorUserIdParam}
        isOpen={openChannelCreate}
        handleClose={() => setOpenChannelCreate(false)}
      />
      <Card
        padding="0"
        borderWidth="0"
        boxShadow={false}
        className={`flex bg-${baseColor}-light mr-6 mb-6 h-2/5 shadow-xl`}
        contentClassName="flex flex-col w-full"
        spaceContent={false}
      >
        <div className="flex items-center justify-between bg-opacity-10 bg-white px-4 py-2.5">
          <h4 className="p-0 m-0 text-sm text-white opacity-70">CHANNELS</h4>
          {canCreateChannels && (
            <button
              className="bg-transparent hover:shadow-none hover:opacity-60 opacity-80 p-0"
              onClick={() => {
                setOpenChannelCreate(true);
              }}
              title="Create a Channel"
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
            </button>
          )}
        </div>
        <div className="px-4 flex flex-col flex-grow overflow-y-auto">
          {currentClubChannelsState
            .filter(channel => !channel.archivedAt)
            .sort(channel => (channel.readOnly ? -1 : 1))
            .map(channel => (
              <ChannelLink
                key={channel.displayName}
                active={channel.displayName === channelNameParam}
                channelName={channel.displayName || ''}
                instructorUserIdParam={instructorUserIdParam}
                studentIdParam={studentIdParam}
                readOnly={channel.readOnly || false}
                juniClubId={currentClubState._id}
                canEditChannels={canEditChannels}
                notifications={
                  (channel.displayName &&
                    unreadMessagesForClub[channel.displayName]) ||
                  0
                }
                colorTheme={currentClubState.colorTheme}
                url={getClubLink({
                  studentId: studentIdParam,
                  juniClubId: currentClubState._id,
                  channelName: channel.displayName,
                })}
              />
            ))}
        </div>
      </Card>
    </>
  );
};

export default ClubChannels;
