export const INSTRUCTOR_ADMIN_PERMISSIONS = [
  'computer_science_curriculum_developer',
  'mathematics_curriculum_developer',
  'english_curriculum_developer',
  'investing_curriculum_developer',
  'session_note_reviewer',
  'instructor_achievements',
];

export const INSTRUCTOR_ADMIN_ROLES = ['juniverse_moderator'];
