/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import JuniSpinner from 'components/JuniSpinner';
import {
  JRSModalLine,
  JRSBackButton,
  JRSNextButton,
  JRSVideo,
} from './subcomponents';

import '../styles/jide_rec_studio.css';

// This modal shows the user the locally cached version of their recording
// as a playable preview, and gives them the option to title and save the recording
// or discard it and start over
const JRSSaveRec = ({
  setMode,
  saveRecVideoUrl,
  discardVideo,
  saveVideo,
  cleanupStreamBuilder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [titleValue, setTitleValue] = useState('');

  const titleChangeHandler = e => {
    setTitleValue(e.target.value);
  };

  return (
    <div className="jrs-modal jrs-saverec">
      {isLoading ? (
        <div className="jrs-modal-spinner-container">
          <div className="jrs-modal-spinner">
            <JuniSpinner />
          </div>
        </div>
      ) : null}

      <div className="jrs-modal-header jrs-row">
        <div className="jrs-modal-heading1">Save Recording</div>
        <div
          className="jrs-modal-x"
          onClick={async () => {
            setIsLoading(true);
            await discardVideo();
            setIsLoading(false);
            setMode('record');
          }}
        >
          <div className="jrs-modal-x-inner">
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
      </div>

      <div className="jrs-modal-section jrs-column">
        <JRSVideo src={saveRecVideoUrl} />
      </div>

      <div className="jrs-modal-section jrs-section jrs-column">
        <JRSModalLine boldText="Recording Title" />
        <input
          className="jrs-modal-input"
          type="text"
          value={titleValue}
          onChange={titleChangeHandler}
          spellCheck={false}
          autoFocus
        />

        <div className="jrs-modal-subsection jrs-buttons jrs-row">
          <JRSBackButton
            onClick={async () => {
              setIsLoading(true);
              await discardVideo();
              setIsLoading(false);
              setMode('record');
            }}
            text="Discard Video and Record Again"
            enabled
            red
          />
          <JRSNextButton
            onClick={async () => {
              setIsLoading(true);
              await saveVideo(titleValue);
              cleanupStreamBuilder();
              setIsLoading(false);
              setMode('uploadProgress');
            }}
            text="Save Recording to My Account"
            enabled={titleValue.length > 0}
          />
        </div>
      </div>
    </div>
  );
};

export default JRSSaveRec;
