import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';

export const CS_COURSE_CATALOG = {
  scratch_level_1: {
    suggestedAge: '8-11',
    description: [
      `In this course, we introduce computer science fundamentals using Scratch's block-based programming environment. This course focuses on **event listeners, loops, conditional statements, user input, Cartesian coordinates, variables, and message broadcasting**. Read about why Scratch is the [best beginning programming language for kids](https://junilearning.com/blog/scratch-beginning-coding-language-for-kids/).`,
      `After completing this course, students are able to design and program their own games in Scratch, and are ready to advance to Scratch Level 2.`,
    ],
  },
  scratch_level_2: {
    suggestedAge: '9-12',
    description: [
      `This course is for students who have completed Scratch Level 1 or equivalent. In this course, we expand upon the concepts introduced in Scratch Level 1 and introduce more advanced ideas, including **nested loops, complex conditionals, cloning, string and mathematical operators, lists, and functions**.`,
      `After completing this course, students have full mastery of the Scratch environment, and are ready to advance to Python Level 1.`,
    ],
  },
  python_level_1: {
    suggestedAge: '11-18',
    description: [
      `In this course, students learn Python basics with the Turtle programming library, with an emphasis on programmatically generating graphic art. This course dives into Python fundamentals: **loops, variables, functions, conditional statements, and event listeners**.`,
      `By the end of this course, students have built complex computer-generated art and games in Python, and are ready to advance to Python Level 2.`,
    ],
  },
  python_graphics_superstar: {
    suggestedAge: '11-18',
    description: [
      `In this course, students learn Python basics with the Turtle programming library, with an emphasis on programmatically generating graphic art. This course dives into Python fundamentals: **loops, variables, functions, conditional statements, and event listeners**.`,
      `By the end of this course, students have built complex computer-generated art and games in Python, and are ready to advance to Python Level 2.`,
    ],
  },
  python_problem_solver: {
    suggestedAge: '12-18',
    description: [
      `In this course, students dive deeper into Python fundamentals with **loops, variables, conditional statements, and functions**, while also introducing more advanced topics such as **lists, sets, dictionaries, and error checking**. Students practice integrating these concepts in building a series of complex projects.`,
      `This course also exposes students to several real-life applications for programming, including in music, ciphers, and predictive algorithms.`,
    ],
  },
  python_level_3: {
    suggestedAge: '14-18',
    description: [
      `This course briefly reviews Python fundamentals and focuses on advanced topics in Python: **recursion, searching and sorting algorithms, and big-O notation**. The master project for this course requires students to rely on their analytical reasoning skills to recognize patterns, outline solutions to problems, and systematically develop algorithms that can handle rigorous testing.`,
      `Students who excel in this course have a strong math background (Algebra 2 or higher recommended).`,
    ],
  },
  pygame: {
    suggestedAge: '11-18',
    description: [
      `This course is designed with an emphasis on developing more sophisticated graphics-based games in Python, using the PyGame library. We cover key concepts that are foundational to game design, including **gravity, platforms, projectiles, and enemy AI**. This course also covers basic object-oriented programming in Python.`,
      `The prerequisite for enrolling in this course is completion of Python Level 1 or equivalent. Students must have exposure to variables, loops, conditional statements, functions, and lists. After completing this course, students will be ready to learn more advanced non-graphics-based computer science skills in Python Level 2.`,
    ],
  },
  python_data_science: {
    suggestedAge: '12-18',
    description: [
      `In this course, we build upon students’ Python knowledge to introduce Data Science, an interdisciplinary field that uses programming and statistics to draw insights from valuable datasets. Students learn how to **read and clean data** from a variety of sources including APIs, **build visualizations**, and **answer key research questions about datasets**. Students explore data from a variety of fields, including space, healthcare, and business, while learning real-world tools like the Python Pandas library and working with Colab Notebooks. At the end of this course, students perform an independent exploratory data analysis on a topic of their choosing.`,
      `The prerequisite for this course is completion of Python Level 2 or equivalent.`,
    ],
  },
  java_level_1: {
    suggestedAge: '12-18',
    description: [
      `In this course, students learn the fundamentals of Java, focusing on **variable types, conditionals, loops, methods, and one- and two-dimensional arrays**. This course also incorporates programmatically generating graphic art with Java.`,
      `By the end of this course, students are ready to advance to Java Level 2, which focuses on object-oriented programming. It is highly recommended that students complete our Python Level 1 and/or Python Level 2 courses before enrolling in Java Level 1.`,
    ],
  },
  java_level_2: {
    suggestedAge: '14-18',
    description: [
      `This course focuses on on object-oriented programming in Java, covering **classes & objects, subclasses, and inheritance**. Students have the opportunity to build console-based projects and graphics-based games utilizing these principles.`,
      `By the end of this course, students have a solid grasp of Java fundamentals and are well-prepared to enroll in either Java Level 3 for more advanced Java topics, or in an accelerated version of AP Computer Science A.`,
    ],
  },
  java_level_3: {
    suggestedAge: '14-18',
    description: [
      `In this course, students briefly review Java fundamentals and focus on advanced topics in Java: **recursion, searching and sorting algorithms, big-O notation, and advanced data structures**. The data structures discussed include linked lists, stacks, queues, priority queues, maps, trees, and graphs.`,
      `This course goes beyond the scope of AP Computer Science A and covers material typically seen in a college-level data structures and algorithms course in Java. Students who excel in this course have a strong math background (Algebra 2 or higher recommended).`,
    ],
  },
  ap_computer_science_a: {
    suggestedAge: '14-18',
    description: [
      `This course guides students through learning Java, with an emphasis on object-oriented programming. Students build a variety of complex projects while learning about **objects, classes, scope, and inheritance**. This course also covers basic **searching and sorting algorithms in Java**.`,
      `This course prepares students for the AP Computer Science A exam, which is administered in May of each year. Aspects of the course are specifically focused on AP test preparation, so students who do not plan to take the AP exam should enroll in our Java Levels 1, 2, and 3 course sequence instead.`,
    ],
  },
  ai_level_1: {
    suggestedAge: '14-18',
    description: [
      `In this course, we set a foundation for studying artificial intelligence and machine learning through concepts like **object-oriented programming, graphs, trees, and searching**. This course focuses more heavily on AI concepts including **formulating search problems, adversarial games, uninformed searching, and informed searching**. Students will use these skills to build programs that solve complex problems and puzzles.`,
      `This course is taught in Python, and the prerequisite for enrolling in this course is completion of Python Level 3.`,
    ],
  },
  ai_level_2: {
    suggestedAge: '14-18',
    description: [
      `In this course, we cover specific techniques for performing machine learning, examining both **classification** and **regression**, including tactics such as **neural networks** and **image classification**. This course focuses more heavily on utilizing the concepts learned in AI Level 1, and applying them to **real data sets**, in order to draw real conclusions.`,
    ],
  },
  cpp_level_1: {
    suggestedAge: '14-18',
    description: [
      `In this course, students learn the fundamentals of C++, focusing on **variable types, loops, control statements, functions, and one- and two-dimensional arrays**. These topics are introduced at an accelerated pace, as students should already have familiarity with these topics in Java. Then, this course covers intermediate topics such as **object-oriented programming, memory manipulation, and dynamic arrays**. By the end of the course, students will have a solid understanding of the essential components of C-based programming languages, and be prepared to learn more advanced data structures and concepts in C++.`,
      `The prerequisite for enrolling in this course is completion of Java Level 2, AP Computer Science A, or equivalent.`,
    ],
  },
  javascript_level_1: {
    suggestedAge: '12-18',
    description: [
      `In this course, students learn **JavaScript, HTML, and CSS** basics to create their own websites. This course emphasizes JavaScript fundamentals, including **variables, operators, loops, and conditional statements**.`,
      `This course is geared toward students who enjoy art and design. By the end of this course, students have created their own web portfolios including interactive mini-projects, such as e-commerce or arcade splash pages and SVG graphics animated with JavaScript.`,
    ],
  },
  javascript_level_2: {
    suggestedAge: '14-18',
    description: [
      `In this course, students dive deeper into JavaScript with **functions, arrays, and object-oriented programming**. Students apply their learning toward programming classic arcade games like Asteroids and Breakout.`,
      `This course also covers important concepts for building full-stack web applications, including **APIs and databases (SQL and NoSQL)**. By the end of this course, students have developed their own complex web applications, such as a news aggregator that fetches articles from an API and displays a customized feed to the user.`,
    ],
  },
  usaco_bronze: {
    description: [
      `Students in our USACO ([USA Computing Olympiad](http://www.usaco.org/)) Training Program participate in the four online contests held per year. All students start in the Bronze division and can be promoted to upper divisions based on their contest scores.`,
      `Students who start in our USACO Bronze course should have excelled in our Python Level 3 or AP Computer Science A course. Thus, the bulk of preparation for Bronze is adjusting to the format of USACO-style problems and learning how to tackle and debug problems with mostly brute force or greedy algorithm approaches. Depending on the student, we also spend time on reading comprehension for complex problems and time management for test taking. Our USACO Bronze course is offered in Python or Java.`,
      `Please [contact us](https://junilearning.com/contact) regarding the tuition for this program.`,
    ],
  },
  usaco_silver: {
    description: [
      `Our USACO Silver course covers these algorithms, techniques, and data structures:`,
      `- Graphs and trees\n- Stacks, queues, and priority queues\n- Binary search\n- Depth-first search and breadth-first search\n- Floodfill\n- Sliding window\n- Prefix sums`,
      `Additional emphasis is placed on time and memory complexity analysis to ensure that our programs run within the time and memory bounds for each test case.`,
      `Please [contact us](https://junilearning.com/contact) regarding the tuition for this program.`,
    ],
  },
  usaco_gold: {
    description: [
      `Our USACO Gold course covers these algorithms, techniques, and data structures:`,
      `- Dynamic programming\n- Dijkstra's, Prim's, and Kruskal's algorithms\n- Minimum spanning trees\n- Disjoint sets\n- Binary indexed trees`,
      `Student who are able to pass the Gold level of USACO are generally working at an advanced undergraduate level with computer science algorithms.`,
      `Please [contact us](https://junilearning.com/contact) regarding the tuition for this program.`,
    ],
  },
};

export const MATH_COURSE_CATALOG = {
  math_foundations_a: {
    suggestedAge: '7-9',
    description: [
      `This is an elementary-level course designed with 2nd grade math standards in mind. The course develops foundational skills in addition, subtraction, multiplication, and division, through hands-on activities designed to help students discover math.`,
      `Early Elementary A covers **Addition and Subtraction within 100 and within 1,000**, **Intuitive Thinking with Multiplication and Division**, and **Data and Measurement**. After this course, students will continue to build their foundational knowledge of math in Early Elementary B.`,
    ],
  },
  math_foundations_b: {
    suggestedAge: '7-9',
    description: [
      `This is an elementary-level course designed with 3rd grade standards in mind. The course strengthens students’ intuition in arithmetic, fractions, and geometric shapes, through interactive activities and projects. This course is designed to build on students’ prior understanding from Early Elementary A or equivalent.`,
      `Early Elementary B covers **Foundations of Multiplication and Division**, **Patterns in Arithmetic**, **Multiplication and Area**, **Fractions**, and **Geometry and Measurement**. After this course, students will continue their in-depth knowledge of math in Late Elementary A.`,
    ],
  },
  math_applications_a: {
    suggestedAge: '9-11',
    description: [
      `This is an elementary-level course designed with 4th grade standards in mind. The course advances students’ critical thinking in multi-digit multiplication and division, fractions, and geometry by applying their new skills to real-life quantitative problems.`,
      `Late Elementary A covers **Arithmetic Review**, **Fluency of Multiplication and Division**, and **Geometry**. It’s designed to build on students’ prior understanding from Early Elementary B or equivalent. After this course, students will continue their exploration of math in the Late Elementary B course.`,
    ],
  },
  math_applications_b: {
    suggestedAge: '9-11',
    description: [
      `This is an elementary-level course designed with 5th grade standards in mind. The course drives mastery of fractions, decimals, units and coordinates through applying their new skills to problems inspired by the real world.`,
      `Late Elementary B covers **Fractions**, **Decimals**, **Unit Conversions**, **Whole Number Operations**, and **Patterns and the Coordinate Plane**. This course is designed to build on students’ learnings from Late Elementary A or equivalent. After this course, students will continue their investigation of math in Pre-Algebra A.`,
    ],
  },
  pre_algebra_a_v2: {
    suggestedAge: '10-13',
    description: [
      `Pre-Algebra is an honors-level middle school course that prepares students for the study of Algebra 1. This course is suitable for mathematically advanced fifth and sixth graders, in addition to seventh and eighth graders who would like to build a strong foundation in Pre-Algebra concepts.`,
      `Pre-Algebra A covers four broad topics: **arithmetic review; fractions, decimals, percents, and ratios; arithmetic expressions; and exponents and radicals**. The practice problems provided in this course range from fundamentals practice to complex word problems from previous AMC8 and MathCounts contests.`,
    ],
  },
  pre_algebra_b_v2: {
    suggestedAge: '10-13',
    description: [
      `Pre-Algebra is an honors-level middle school course that prepares students for the study of Algebra 1. This course is suitable for mathematically advanced fifth and sixth graders, in addition to seventh and eighth graders who would like to build a strong foundation in Pre-Algebra concepts.`,
      `Pre-Algebra B is meant to be taken directly after Pre-Algebra A. It covers four broad topics: **data and statistics, geometry, number theory, and probability and counting**. The practice problems provided in this course range from fundamentals practice to complex word problems from previous AMC8 and MathCounts contests.`,
    ],
  },
  algebra_1_a: {
    suggestedAge: '12-15',
    description: [
      `Algebra 1 sets the foundation of algebra, focusing on working with linear and quadratic equations while building appropriate problem-solving skills. This course is suitable for students who have completed a Pre-Algebra course or equivalent, and after successful completion of Algebra 1, students are prepared to move onto Geometry.`,
      `Algebra 1A covers three broad topics: **solving linear equations, graphing linear equations, and solving systems of linear equations**. The practice problems provided in this course range from fundamentals practice to complex word problems.`,
    ],
  },
  algebra_1_b: {
    suggestedAge: '12-15',
    description: [
      `Algebra 1 sets the foundation of algebra, focusing on working with linear and quadratic equations while building appropriate problem-solving skills. This course is suitable for students who have completed a Pre-Algebra course or equivalent, and after successful completion of Algebra 1, students are prepared to move onto Geometry.`,
      `Algebra 1B is meant to be taken directly after Algebra 1A. It covers four broad topics: **manipulating polynomials, solving quadratic equations, graphing quadratic equations, and functions**. The practice problems provided in this course range from fundamentals practice to complex word problems.`,
    ],
  },
  geometry_a: {
    suggestedAge: '13-16',
    description: [
      `Geometry is a proof-based, high school level geometry course that emphasizes geometric concepts in two and three dimensions as they apply to points, lines, planes, circles, and polygons. This course is suitable for students who have completed an Algebra 1 course or equivalent, and after successful completion of Geometry, students are prepared to move onto Algebra 2.`,
      `Geometry A covers five broad topics: **geometry foundations, lines and angles, triangles, triangle dissection, and trigonometry**. The practice problems provided in this course range from fundamentals practice to complex proof-based problems.`,
    ],
  },
  geometry_b: {
    suggestedAge: '13-16',
    description: [
      `Geometry is a proof-based, high school level geometry course that emphasizes geometric concepts in two and three dimensions as they apply to points, lines, planes, circles, and polygons. This course is suitable for students who have completed an Algebra 1 course or equivalent, and after successful completion of Geometry, students are prepared to move onto Algebra 2.`,
      `Geometry B is meant to be taken directly after Geometry A. It covers four broad topics: **quadrilaterals and polygons, circles, transformations, and three-dimensional shapes**. The practice problems provided in this course range from fundamentals practice to complex proof-based problems.`,
    ],
  },
  algebra_2_a: {
    suggestedAge: '14-17',
    description: [
      `Algebra 2 is a high school level algebra course that builds fluency in understanding and analyzing complex functions. Students study the applications of different function types, including exponential, rational, and trigonometric functions, using these to model and draw conclusions about real-life situations. This course is suitable for students who have completed courses in Algebra 1 and Geometry or equivalent. After successful completion of Algebra 2, students are prepared to move onto Pre-Calculus & Trigonometry.`,
      `Algebra 2A covers four broad topics: **quadratic functions, polynomials, rational functions, and radical and piecewise functions**.`,
    ],
  },
  algebra_2_b: {
    suggestedAge: '14-17',
    description: [
      `Algebra 2 is a high school level algebra course that builds fluency in understanding and analyzing complex functions. Students study the applications of different function types, including exponential, rational, and trigonometric functions, using these to model and draw conclusions about real-life situations. This course is suitable for students who have completed courses in Algebra 1 and Geometry or equivalent. After successful completion of Algebra 2, students are prepared to move onto Pre-Calculus & Trigonometry.`,
      `Algebra 2B is meant to be taken directly after Algebra 2A. It covers five broad topics: **exponents and logarithms, sequences, matrices, probability and statistics, and trigonometry**.`,
    ],
  },
  pre_calc_trig_a: {
    suggestedAge: '15-18',
    description: [
      `Pre-Calculus & Trigonometry is a high school level course that prepares students for success in their studies of calculus. The course is designed to build on students' prior understanding of Algebra and Geometry to learn rigorous concepts such as the binomial theorem, conic sections, trigonometry, and a brief introduction to calculus. This course is suitable for students who have completed courses in Algebra 2 and Geometry or equivalent. After successful completion of Pre-Calculus & Trigonometry, students will be prepared to move onto AP Calculus.`,
      `Pre-Calculus & Trigonometry A covers four broad topics: **polynomials; piecewise, rational, and logarithmic functions; sums, sequences, the binomial theorem; and conic functions**.`,
    ],
  },
  pre_calc_trig_b: {
    suggestedAge: '15-18',
    description: [
      `Pre-Calculus & Trigonometry is a high school level course that prepares students for success in their studies of calculus. The course is designed to build on students' prior understanding of Algebra and Geometry to learn rigorous concepts such as the binomial theorem, conic sections, trigonometry, and a brief introduction to calculus. This course is suitable for students who have completed courses in Algebra 2 and Geometry or equivalent. After successful completion of Pre-Calculus & Trigonometry, students will be prepared to move onto AP Calculus.`,
      `Pre-Calculus & Trigonometry B is meant to be taken directly after Pre-Calculus & Trigonometry A. It covers five broad topics: **trigonometry, applications of trigonometry, linear algebra, probability, and introduction to calculus**.`,
    ],
  },
  ap_calculus: {
    suggestedAge: '15-18',
    description: [
      `AP Calculus is a rigorous high-school level course that is designed to **build students’ in-depth understanding of calculus**. The course is aligned with College Board standards and is designed to prepare students for the **AP Calculus AB or BC exam**.`,
      `This course is suitable for students who are taking AP Calculus concurrently in school or are studying for the exam independently. We require that students have completed Pre-Calculus / Trigonometry as a prerequisite to this course. Due to the length and rigor of this course, we strongly recommend all students enroll in this course with **two classes per week** or more.`,
      `For students who are taking AP Calculus AB, the course will cover: **Limits and Continuity, Differentiation, Applications of Derivatives, Integration and Accumulation of Change, and Differential Equations**. For students who are taking AP Calculus BC, this course will also cover **Applications of Integrations, Parametric Equations, Polar Coordinates, and Vector-Valued Functions**.`,
    ],
  },
};

export const ENGLISH_COURSE_CATALOG = {
  english_early_elementary_a: {
    suggestedAge: '7-9',
    description: [
      `In this playful, discovery-based course, 2nd and 3rd grade students
                  will unlock the reading skills they already possess with
                  personalized guidance from their instructor. Based on the Reading
                  Literature, Reading Informational Text, and Language categories of
                  Common Core State Standards for 2nd and 3rd grade English/Language
                  Arts, this course enables students to build an authentic,
                  personalized understanding of literacy concepts through games,
                  visual arts, and storytelling activities.`,
      `Students will deliver a kid-friendly news report using Juni's Recording Studio to discover how to **identify the central idea of a nonfiction text**, storyboard a movie on an interactive whiteboard to discover **how to parse the moral of a fictional story**, and draw idioms to discover how to **identify figurative language**.`,
    ],
  },
  english_early_elementary_b: {
    suggestedAge: '7-9',
    description: [
      `In this course, 2nd and 3rd grade students will discover and master writing and presentation skills so that they can author and present an original picture book! At the end of the course, students will be able to order a printed copy of their picture book and practice presenting their work. Along the way, students will play vocabulary games to discover parts of speech, solve riddles to learn the rules of capitalization and punctuation, practice opinion writing and narrative writing, and learn strong presentation skills. Based on the Writing, Language, and   Speaking/Listening categories of Common Core State Standards for 2nd and 3rd grade English/Language Arts, this course enables students to acquire an authentic, personalized understanding of writing, language, and presentation concepts while they build the skills necessary to author and present their own books.`,
      `In Juni’s discovery-based, elementary courses, instructors guide students through carefully designed activities that allow students to make their own connections and draw their own maps of new concepts, adding suggestions and correcting misconceptions as needed. Students then take their discoveries and apply them to comprehensive projects that push them to become experts in each literacy concept they discover. Paired together, Early Elementary A: Discovering the Joy of Reading and Early Elementary B: Write Your Own Picture Book cover foundational skills in reading, writing, language, and speaking in a memorable, student-centered course sequence.`,
    ],
  },
  english_core_middle_school_a: {
    suggestedAge: '11-14',
    description: [
      `This course challenges students to master middle school level skills in **reading and analyzing literature**, based on the Reading Literature category of Common Core State Standards for 6th-8th grade English/Language Arts. As students develop mastery of reading comprehension and analytical writing skills, they practice **writing short pieces of original fiction** by employing the same elements of craft that they are learning to notice in their reading and analysis.`,
      `Each of Juni's core ${
        SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH]
      } courses is designed with flexible text sets that our curriculum team curates and authors in-house to suit a range of reading levels and student interests. With **32+ projects and 90+ short texts** that students can choose, Middle School A provides students with frequent and diverse opportunities to practice and master the skills of reading and analyzing literature.`,
    ],
  },
  english_core_middle_school_b: {
    suggestedAge: '11-14',
    description: [
      `This course challenges students to master middle school level skills in writing, both analytical and creative, based on the Writing category of Common Core State Standards for 6th-8th grade English/Language Arts. The first half of the course is a deep dive into **analytical, arguable writing about works of fiction**, including modules about writing claims and counterclaims, integrating and analyzing evidence, and revision. The second half of the course is a deep dive into **creative, fiction writing**, including modules about character development, generating conflict, structuring plot, and manipulating point of view. With **24+ projects**, by the end of this course, students will be able to write a compelling and precise arguable paragraph and an original short story.`,
    ],
  },
  english_core_middle_school_c: {
    suggestedAge: '11-14',
    description: [
      `Grammar doesn’t have to be boring; **by weaving grammar instruction into fun writing projects**, this course introduces students to the joys of writing both creatively and correctly. The curriculum challenges students to master middle school level skills in grammar and mechanics, based on the Language category of Common Core State Standards for 6th-8th grade English/Language Arts. As students develop mastery of language skills, they will apply those skills to writing **original, personal narrative essays**, making visible to each student the purpose of learning correct grammar and mechanics. Topics include **parts of speech; sentence types; correct punctuation of phrases and clauses; and correct usage of commas, dashes, parentheses, and ellipses**.`,
    ],
  },
  novel_writing: {
    suggestedAge: '11-18',
    description: [
      `In this ambitious, **project-based, creative writing elective**, aspiring authors will **write an original novel** or long form work of their choice. Writing a novel is no small feat for most adults, but at Juni we believe that with the right instruction and coaching, middle and high school students are up to the challenge!`,
      `Through the ongoing project of writing an original novel, students build fundamental creative writing skills. At the beginning of the course, each student sets a word count goal with their instructor. Students expand their creativity by drafting scenes of their novel in almost every session with their instructors, and they practice accountability by hitting word count targets before moving onto subsequent modules. Along the way, students learn and apply the foundational elements of novel writing, including **character development, narrative structure, setting description, plot creation, and dialogue craft**. This course is open to middle and high school students.`,
    ],
  },
  public_speaking: {
    suggestedAge: '9-14',
    description: [
      `In this project-based elective course, students will master public speaking techniques to effectively share their ideas and amplify their creativity. Students will give a toast in honor of a family member, write and deliver a keynote speech, develop a personal narrative into a radio pitch, respectfully debate an instructor, perform a short stand-up comedy routine, and give their own TED-Ed Student Talk. Students will rigorously revise speech outlines and first draft performances with their instructors, and they will have the opportunity to practice speaking for an authentic audience by pitching an audio story to a radio show and submitting their own TED talk to the TED-Ed Student Talks YouTube channel. Along the way, students will learn how organization, tone, eye contact, body language, rhetoric, and visual aids can make any speaker inspirational and effective.`,
    ],
  },
};

export const INVESTING_COURSE_CATALOG = {
  investing_stock_market: {
    suggestedAge: '9-18',
    description: [
      'Students are immersed in a virtual stock market simulation with a mock $100,000!',
    ],
  },
  personal_finance: {
    suggestedAge: '9-18',
    description: [
      'Students learn skills such as budgeting, negotiating, and building credit that will support their journey to their desired future!',
    ],
  },
  entrepreneurship: {
    suggestedAge: '9-18',
    description: [
      'Students design and pitch their own business plan, with a chance at the end to compete in the Juni Entrepreneur Competition!',
    ],
  },
};

export interface CourseCatalog {
  [key: string]: {
    suggestedAge?: string;
    description: string[];
  };
}

export const COURSE_CATALOG: CourseCatalog = {
  ...CS_COURSE_CATALOG,
  ...MATH_COURSE_CATALOG,
  ...ENGLISH_COURSE_CATALOG,
  ...INVESTING_COURSE_CATALOG,
};
