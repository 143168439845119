import React from 'react';
import classNames from 'classnames';
import { StylesMap } from 'components/jide/types';

export interface ToolSelectButtonProps {
  onClick: () => void;
  /**
   * Optionally provide a title, also used as aria-label.
   */
  title?: string;
  /**
   * If this tool is currently selected.
   */
  selected?: boolean;
  /**
   * Enables additional styling.
   */
  className?: string;
  /**
   * Default false.
   */
  disabled?: boolean;
  /**
   * Specifies whether the button is in light or dark mode.
   * Defaults to light mode.
   */
  darkMode?: boolean;
}

const buttonStatesStyles: StylesMap = {
  light:
    'focus:bg-transparent focus:border-j-blue-300 active:bg-j-gray-300 hover:bg-j-gray-200',
  dark:
    'focus:bg-transparent focus:border-j-blue-300 active:bg-j-dark-600 hover:bg-j-dark-700 hover:text-j-dark-100',
};

const ToolSelectButton: React.FC<ToolSelectButtonProps> = ({
  title,
  selected = false,
  onClick,
  className,
  children,
  disabled = false,
  darkMode = false,
}) => (
  <div
    title={title}
    role="button"
    tabIndex={0}
    className={classNames(
      'flex items-center justify-center p-2 rounded-lg border-2 border-solid border-transparent',
      className,
      {
        [`${
          darkMode ? 'bg-j-dark-500 text-j-dark-100' : 'bg-j-purple-100'
        } cursor-pointer`]: selected,
        [`${
          darkMode ? 'text-j-dark-300' : 'text-j-dark-400'
        } cursor-pointer`]: !disabled,
        [`${
          darkMode ? 'text-j-dark-500' : 'text-j-dark-200'
        } cursor-default`]: disabled,
        [buttonStatesStyles[darkMode ? 'dark' : 'light']]: !disabled,
      },
    )}
    onClick={() => {
      if (!disabled) onClick();
    }}
    aria-disabled={disabled}
    aria-label={title}
  >
    {children}
  </div>
);

export default ToolSelectButton;
