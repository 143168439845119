import React, { FC } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';

interface OutputTabIconProps {
  className?: string;
}

interface OutputTabProps {
  renderIcon: (props: OutputTabIconProps) => React.ReactNode;
  onClick: () => void;
  selected?: boolean;
  hasError?: boolean;
}

const OutputTab: FC<OutputTabProps> = ({
  renderIcon,
  onClick,
  children,
  selected,
  hasError,
}) => (
  <div
    className={classNames(
      'flex items-center',
      'font-graphik font-medium',
      'py-3 px-4',
      'box-border',
      'cursor-pointer',
      'rounded-md',
      hasError ? 'text-j-pink-700' : 'text-j-dark-600',
      {
        /* TODO: Figure out hover state */
        'hover:bg-j-purple-100': !selected && !hasError,
        'hover:bg-j-pink-100': !selected && hasError,
        'bg-j-purple-100': selected && !hasError,
        'bg-j-pink-100': selected && hasError,
      },
      'mr-1',
    )}
    onClick={onClick || noop}
  >
    {renderIcon({ className: 'mr-2 w-5 h-5' })}
    {children}
  </div>
);

export default OutputTab;
