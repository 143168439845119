import React, { useContext } from 'react';
import { Instructor, User } from 'models';

type LoadedUser = User & { instructor?: Instructor };
interface UserContext {
  user?: LoadedUser;
  isLoadingUser?: boolean;
  isLoadingUserError?: any;
  loadUserData?: any;
  authToken?: string;
  impersonationUserId?: string;
  isImpersonationMode?: boolean;
  setAuthenticatedUser: (authToken: string) => void;
  deauthenticateUser: () => void;
}
const UserContext = React.createContext<UserContext>({
  user: undefined,
  isLoadingUser: undefined,
  isLoadingUserError: undefined,
  loadUserData: undefined,
  authToken: undefined,
  impersonationUserId: undefined,
  isImpersonationMode: undefined,
  setAuthenticatedUser: () => undefined,
  deauthenticateUser: () => undefined,
});

export const useUserContext = () => useContext(UserContext);

export default UserContext;
