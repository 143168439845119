import { MutableRefObject, useCallback, useRef } from 'react';
import { differenceInMilliseconds, subSeconds } from 'date-fns';
import bigInt from 'big-integer';
import useClubStore, { ClubStoreType } from 'app/clubs/stores/ClubStore';
import {
  useRefreshPubNubCredentialsMutation,
  useMyClubsStateMutation,
  useRemoveViolatingMessageMutation,
  useDeleteMessageMutation,
  useUpdateLastReadTimetokenMutation,
  useCheckMessageContentMutation,
  useJoinLeaveClubMutation,
  LastReadTimetokensType,
  JuniCommunityEventType,
  useDeleteS3ObjectMutation,
  UploadDestination,
  useSendImageUploadSlackMessageMutation,
  useCheckImageContentMutation,
  CheckImageContentPayload,
} from 'generated/graphql';
import {
  MsgType,
  PubnubObjectType,
  JuniClubMembershipInfoPlusBadgeType,
  UnreadMsgLookupType,
  FetchMessageType,
} from 'app/clubs/MyClubsTypes';
import PubNub, {
  MessageEvent,
  SignalEvent,
  MessageActionEvent,
  MessageCountsResponse,
} from 'pubnub';
import _ from 'lodash';
import { mergeSortedArrays } from 'utils/arrays';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { getClubUserBadgeInfo } from '../helpers';

// NOTE: IMPORTANT: DO NOT USE PUBNUB CLIENT OUTSIDE OF THIS FILE!
// USE ONLY THE HOOKS DEFINED IN HERE

// This is the special hidden channel that reports realtime updates of the MyClubs DB State
export const PUBNUB_UPDATES_CHANNEL = '_updates';
const PUBNUB_SIGNALS_CHANNEL = '_signals';
const PUBNUB_USERCHANNELGROUP_SUFFIX = '_userChannelGroup';
export const PUBNUB_VIOLATION_STRING =
  '_This message was removed for violating Juni’s Code of Conduct_';
export const PUBNUB_DELETED_STRING = '_This message was deleted_';
export const PUBNUB_NUM_MESSAGES_TO_FETCH = 25;

const pubnubCompare = (oldClient: PubnubObjectType, newClient: PubnubObjectType) =>
  oldClient.clientUUID === newClient.clientUUID;

// convert epoch nanosecond timetoken to millisecond and then to Date
const timetokenToTimestamp = (timetoken: string) =>
  new Date(Number(bigInt(timetoken).divide(10000)));

export const transformPubNubMessage = (
  msg: FetchMessageType | MessageEvent,
): MsgType => {
  const pubnubChannel = msg.channel;
  const [juniClubId, juniClubChannel] = pubnubChannel.split('.');
  const timestamp = timetokenToTimestamp(String(msg.timetoken));
  // for some reason it's uuid on historical messages and publisher on live ones
  const senderUUID =
    ('uuid' in msg && msg.uuid) ||
    ('publisher' in msg && msg.publisher) ||
    undefined;
  const removed = !!('actions' in msg && msg.actions?.removed);
  const deleted = !!('actions' in msg && msg.actions?.deleted);

  // This information will be attached to the message no matter what.
  const messageMeta = {
    msgId: String(msg.timetoken), // nanosecond Unix timetoken used as an ID
    juniClubId,
    juniClubChannel,
    timestamp,
    senderUUID,
    removed,
    deleted,
  };

  // If a message has been removed or deleted, strip out the user supplied content
  // and replace it with generic violation or deletion strings
  if (removed || deleted) {
    const violationText = removed ? PUBNUB_VIOLATION_STRING : PUBNUB_DELETED_STRING;
    return { payload: { msgText: violationText, msgImageUrls: [] }, ...messageMeta };
  }

  // If a message is for the updates channel, put the update information into
  // the updateFields
  if (juniClubChannel === PUBNUB_UPDATES_CHANNEL) {
    const updateField = msg.message.updateField || msg.message;
    return { payload: { updateField }, ...messageMeta };
  }

  // This is a backwards compatibility thing, since we moved from string
  // payloads to object payloads
  const normalPayload = msg.message.msgImageUrls
    ? msg.message
    : { msgText: msg.message, msgImageUrls: [] };
  return { payload: normalPayload, ...messageMeta };
};

const checkFetchMessageRateLimit = (
  recentCallsRef: MutableRefObject<Date[]>,
  isDisabledRef: MutableRefObject<boolean>,
) => {
  recentCallsRef.current.push(new Date());
  const twoSecondsAgo = subSeconds(new Date(), 2);
  recentCallsRef.current = recentCallsRef.current.filter(
    timestamp => timestamp >= twoSecondsAgo,
  );
  if (recentCallsRef.current.length >= 10) {
    isDisabledRef.current = true;
    throw new Error('pubnubFetchMessagesRaw RATE LIMIT >10 calls in <2 sec');
  }
};

export const usePubnubTime = () => {
  const { pubnubClient } = useClubStore(state => state.pubnub, pubnubCompare);

  const pubnubTime = useCallback(async () => {
    const backupTT = Number(bigInt(Date.now()).times(10000));
    if (!pubnubClient) return backupTT;

    // NOTE: this timetoken trick is to get around the fact that the subscribe call
    // returns recent cached messages otherwise (due to the channel group subscription).
    // Had a long convo with Pubnub support staff about this issue.
    // Their rec was that this workaround would work, but we should use pubnub.time() here
    // instead. https://support.pubnub.com/hc/en-us/articles/360051973611-How-do-I-synchronize-multiple-devices-
    const timeResponse = await pubnubClient.time().catch(err => {
      console.error(err);
      return null;
    });
    const tt = (timeResponse && timeResponse.timetoken) || backupTT;

    return tt;
  }, [pubnubClient]);

  return pubnubTime;
};

export const useUpdateMyClubsState = () => {
  const set = useClubStore(state => state.set);
  const [myClubsStateMutation] = useMyClubsStateMutation();

  interface updateMyClubsStateArgs {
    studentId?: string;
    instructorUserId?: string;
    specificClubs?: string[];
    includeLastReadTimetokens?: boolean;
  }
  const updateMyClubsState = useCallback(
    async ({
      studentId,
      instructorUserId,
      specificClubs,
      includeLastReadTimetokens,
    }: updateMyClubsStateArgs): Promise<LastReadTimetokensType[]> => {
      console.log('clubs: update my clubs state');
      const { data: dataMyClubsState } = await myClubsStateMutation({
        variables: {
          studentId,
          instructorUserId,
          specificClubs,
          includeLastReadTimetokens,
        },
      });
      const {
        juniClubs,
        juniClubMembershipInfos,
        lastReadTimetokens,
        juniCommunityEvents,
      } = dataMyClubsState?.myClubsState || {};
      set((state: ClubStoreType) => {
        if (juniClubs) {
          const clubsById = _.keyBy(juniClubs, '_id');
          if (specificClubs) {
            state.juniClubs = { ...state.juniClubs, ...clubsById };
          } else {
            state.juniClubs = clubsById;
          }
        }
        if (juniClubMembershipInfos) {
          const membershipInfosById = _.keyBy(juniClubMembershipInfos, '_id');
          if (specificClubs) {
            state.juniClubMembershipInfos = {
              ...state.juniClubMembershipInfos,
              ...membershipInfosById,
            };
          } else {
            state.juniClubMembershipInfos = membershipInfosById;
          }
        }
        if (juniCommunityEvents) {
          const clubIdsToEvents = _.keyBy(juniCommunityEvents, '_id');
          if (specificClubs) {
            // TODO: populate on joins and leaves
            state.juniClubsEvents = {
              ...state.juniClubsEvents,
              ...clubIdsToEvents,
            };
          } else {
            // Initialization
            state.juniClubsEvents = clubIdsToEvents;
          }
        }
      });
      return lastReadTimetokens || [];
    },
    [set, myClubsStateMutation],
  );

  return updateMyClubsState;
};

export const useProcessMessages = () => {
  const set = useClubStore(state => state.set);

  const handleUpdates = useCallback(
    (msgs: MsgType[]) => {
      msgs.forEach(msg => {
        const { payload } = msg;
        const { updateField } = payload;
        if (!payload || !updateField || !updateField._id) return;
        switch (updateField.type) {
          case 'JuniClub':
            set((state: ClubStoreType) => {
              const juniClub = updateField;
              state.juniClubs[juniClub._id] = juniClub;
            });
            break;
          case 'JuniClubMembershipInfo':
            set((state: ClubStoreType) => {
              const juniClubMembershipInfo = updateField;
              state.juniClubMembershipInfos[
                juniClubMembershipInfo._id
              ] = juniClubMembershipInfo;
            });
            break;
          case 'JuniCommunityEvent':
            set((state: ClubStoreType) => {
              const juniCommunityEvent = updateField as JuniCommunityEventType;
              state.juniClubsEvents[juniCommunityEvent._id] = juniCommunityEvent;
            });
            break;
          default:
            console.error(`ERROR: MyClubsLoader/handleUpdate:`, msg);
            break;
        }
      });
    },
    [set],
  );

  const handleMessages = useCallback(
    (msgs: MsgType[]) => {
      // NOTE: we sort by msgId since it's a stringified nanosecond timestamp
      // NOTE: might be faster to sort first then use sortedUniqBy but we want to preserve order
      // in case of edited messages
      set((state: ClubStoreType) => {
        msgs.sort((a, b) => (a.msgId < b.msgId ? -1 : 1));
        state.sortedMessages = mergeSortedArrays(
          state.sortedMessages,
          msgs,
          'msgId',
        );
        state.sortedMessages = _.sortedUniqBy(state.sortedMessages, 'msgId');
      });
    },
    [set],
  );

  const processMessages = useCallback(
    (mInput: MessageEvent[] | FetchMessageType[]) => {
      const mArray = Array.isArray(mInput) ? mInput : [mInput];

      // TODO: type of m should be inferrable but for some reason it causes error in senderUUID line
      // TODO: confirm that m.uuid is always available in historical messages
      // TODO: should we send (and then parse here) usernames as a backup?
      const msgs = mArray.map(transformPubNubMessage);
      // TODO: needs to be done to remove cached messages that are autosent by pubnub
      // but ideally we don't want to be doing this at all once we figure out how to
      // disable cache

      const [updateMsgs, otherMsgs] = _.partition(
        msgs,
        m => m.juniClubChannel === PUBNUB_UPDATES_CHANNEL,
      );
      handleUpdates(updateMsgs);
      handleMessages(otherMsgs);
    },
    [handleMessages, handleUpdates],
  );

  return processMessages;
};

export const useProcessSignals = () => {
  const set = useClubStore(state => state.set);

  const processSignals = useCallback(
    (s: SignalEvent) => {
      const inboundChannel = s.channel;
      if (!inboundChannel.includes('.')) return;
      const [juniClubId, signalsChannel] = inboundChannel.split('.');

      const payload = s.message;
      if (!payload.includes(':')) return;
      const [signalCommand, signalInfo] = payload.split(':');

      if (
        !juniClubId ||
        !signalsChannel ||
        signalsChannel !== PUBNUB_SIGNALS_CHANNEL ||
        !signalInfo
      ) {
        return;
      }

      // will be used for unread messages soon
      switch (signalCommand) {
        case 'ch': {
          set((state: ClubStoreType) => {
            const channelName = signalInfo;
            // Ensure unread is zero if user is currently on this channel in the UX
            if (
              juniClubId === state.currentChatChannel.juniClubId &&
              channelName === state.currentChatChannel.channelName
            ) {
              return;
            }

            if (!(juniClubId in state.unreadMessages)) {
              state.unreadMessages[juniClubId] = {};
            }
            if (channelName in state.unreadMessages[juniClubId]) {
              state.unreadMessages[juniClubId][channelName] += 1;
            } else {
              state.unreadMessages[juniClubId][channelName] = 1;
            }
          });
          break;
        }
        default:
          break;
      }
    },
    [set],
  );

  return processSignals;
};

export const useProcessMessageAction = () => {
  const set = useClubStore(state => state.set);

  // should match server/app/pubnub/utils/pubnub_server.js:pubnubRemoveMessage logic
  // TODO: since we want to hide deleted messages from the UI entirely, we should
  // consider perhaps just filtering these out from the ClubStore in the future. Need
  // to think through consequences of that though
  const processMessageAction = useCallback(
    (ma: MessageActionEvent) => {
      const msgId = ma.data.messageTimetoken;
      const { type } = ma.data;

      switch (type) {
        case 'removed': {
          set((state: ClubStoreType) => {
            state.sortedMessages = state.sortedMessages.map(m =>
              m.msgId === msgId
                ? {
                    ...m,
                    removed: true,
                    payload: { msgText: PUBNUB_VIOLATION_STRING, msgImageUrls: [] },
                  }
                : m,
            );
          });
          break;
        }
        case 'deleted': {
          set((state: ClubStoreType) => {
            state.sortedMessages = state.sortedMessages.map(m =>
              m.msgId === msgId
                ? {
                    ...m,
                    deleted: true,
                    payload: { msgText: PUBNUB_DELETED_STRING, msgImageUrls: [] },
                  }
                : m,
            );
          });
          break;
        }
        default:
          break;
      }
    },
    [set],
  );

  return processMessageAction;
};

type RefreshPubnubCredsParams = {
  studentIdParam?: string | undefined;
  instructorUserIdParam?: string | undefined;
};
type RefreshPubnubCredsCallback = (
  params: RefreshPubnubCredsParams,
) => Promise<boolean>;
export const useRefreshPubnubCreds = (): [
  RefreshPubnubCredsCallback,
  () => void,
] => {
  const set = useClubStore(state => state.set);
  const { pubnubClient } = useClubStore(state => state.pubnub, pubnubCompare);
  const pubnubTime = usePubnubTime();
  const processMessages = useProcessMessages();
  const processSignals = useProcessSignals();
  const processMessageAction = useProcessMessageAction();
  const updateMyClubsState = useUpdateMyClubsState();
  const [refreshPubNubCredentialsMutation] = useRefreshPubNubCredentialsMutation();
  const setTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const refreshPubnubCreds = useCallback(
    async ({
      studentIdParam,
      instructorUserIdParam,
    }: RefreshPubnubCredsParams): Promise<boolean> => {
      set((state: ClubStoreType) => {
        state.isClubsLoading = true;
      });

      console.log(
        'clubs: start refreshCreds',
        studentIdParam,
        instructorUserIdParam,
      );
      if (!studentIdParam && !instructorUserIdParam) return false;
      let dataRefreshCreds: any;
      try {
        const { data: pubnubData } = await refreshPubNubCredentialsMutation({
          variables: {
            studentId: studentIdParam,
            instructorUserId: instructorUserIdParam,
          },
        });
        dataRefreshCreds = pubnubData;
      } catch (e) {
        set((state: ClubStoreType) => {
          state.isClubsLoading = false;
        });
        return false;
      }
      const { authKey, clientUUID, expiresAt, publishKey, subscribeKey } =
        dataRefreshCreds?.refreshPubNubCredentials || {};
      if (!(authKey && clientUUID && expiresAt && publishKey && subscribeKey)) {
        return false;
      }

      if (pubnubClient) {
        console.log('clubs: stopping prev messaging client');
        pubnubClient.unsubscribeAll();
        pubnubClient.stop();
      }

      // NOTE: we don't reset clubs and membership infos here because of the behavior of
      // useUpdateMyClubsState's specificClubs input - not refreshing makes the student
      // transitions snappier
      set((state: ClubStoreType) => {
        state.sortedMessages = [];
        state.lastPreviewMessageFetchAt = undefined;
        state.previewMessagesByClub = {};
        state.pubnub = {
          clientUUID: null,
          pubnubClient: null,
        };
      });
      const newPubnubClient = new PubNub({
        authKey,
        uuid: clientUUID,
        publishKey,
        subscribeKey,
      });
      if (!newPubnubClient) return false;

      // Set up behavior when receiving messages
      newPubnubClient.addListener({
        message: m => {
          setTimeout(() => {
            processMessages([m]);
          }, 150);
        },
        signal: processSignals,
        messageAction: processMessageAction,
      });
      console.log('clubs: add listener');

      // TODO: only do this if user is member of at least one club if it will save API calls
      // must work if they start out with no clubs and then join one during session
      const channelGroups = clientUUID
        ? [`${clientUUID}${PUBNUB_USERCHANNELGROUP_SUFFIX}`]
        : undefined;
      const tt = await pubnubTime();
      newPubnubClient.subscribe({
        channelGroups,
        withPresence: false,
        timetoken: tt,
      });
      console.log('clubs: subscribe to channelgroups');
      const lastReadTimetokens = await updateMyClubsState({
        studentId: studentIdParam,
        instructorUserId: instructorUserIdParam,
        includeLastReadTimetokens: true,
      }).catch((err): LastReadTimetokensType[] => {
        console.error('clubs: update my clubs state ERROR', err);
        return [];
      });

      // Pubnub's messageCounts api call only allows a max of 100 channels, but users
      // can have a max of 3000 so we need to chunk this up into separate calls
      if (lastReadTimetokens.length) {
        const results = await Promise.all(
          _.chunk(lastReadTimetokens, 100).map(lrtChunk =>
            newPubnubClient
              .messageCounts({
                channels: lrtChunk.map(lrt => lrt.channelName),
                channelTimetokens: lrtChunk.map(lrt => lrt.timetoken),
              })
              .catch(err => {
                console.error(err);
                return null;
              }),
          ),
        );

        // and then just iterate over the results of the chunking
        let allChannels: MessageCountsResponse['channels'] = {};
        results.forEach(result => {
          if (!result) return;
          allChannels = { ...allChannels, ...result.channels };
        });
        console.log('clubs: message counts');
        const counts: UnreadMsgLookupType = {};
        _.keys(allChannels).forEach(c => {
          if (!c.includes('.')) return;
          const [juniClubId, channelName] = c.split('.');
          if (!juniClubId.length || !channelName.length) return;
          const msgCount = allChannels[c];
          if (!(juniClubId in counts)) {
            counts[juniClubId] = {};
          }
          counts[juniClubId][channelName] = msgCount;
        });
        set((state: ClubStoreType) => {
          state.unreadMessages = counts;
        });
      }

      console.log('clubs: setting new messaging client in store');
      set((state: ClubStoreType) => {
        state.pubnub = {
          clientUUID,
          pubnubClient: newPubnubClient,
        };
      });

      // Set up setTimeout to refresh creds
      // TODO: do we need to resubscribe to everything on setTimeout expiring and pubnub
      // client authkey being updated?
      // TODO: upon this timeout executing, the portal will briefly refresh on its own
      // in order to connect to pubnub with the new authkey credentials. Small edge case here
      // would be that if someone has the chat window open, and they have a bunch of text
      // in the composer they haven't sent, it will disappear.
      // the todo would be - cache the message in localstorage or something and load it back
      // in afterwards perhaps
      const ttl = differenceInMilliseconds(new Date(expiresAt), new Date());
      if (setTimeoutRef.current) clearTimeout(setTimeoutRef.current);
      setTimeoutRef.current = setTimeout(() => {
        refreshPubnubCreds({ studentIdParam, instructorUserIdParam });
      }, ttl - 1000 * 60 * 30);

      set((state: ClubStoreType) => {
        state.isClubsLoading = false;
      });

      return true;
    },
    [
      processMessageAction,
      processMessages,
      processSignals,
      pubnubClient,
      pubnubTime,
      refreshPubNubCredentialsMutation,
      set,
      updateMyClubsState,
    ],
  );

  const cleanupPubnubCreds = useCallback((): void => {
    console.log('clubs: TRY cleaning up messaging client');
    if (pubnubClient) {
      console.log('clubs: cleaning up messaging client');
      pubnubClient.unsubscribeAll();
      pubnubClient.stop();
    }
  }, [pubnubClient]);

  return [refreshPubnubCreds, cleanupPubnubCreds];
};

export const usePubnubSubscribe = () => {
  const { pubnubClient } = useClubStore(state => state.pubnub, pubnubCompare);
  const pubnubTime = usePubnubTime();

  const pubnubSubscribe = useCallback(
    async ({
      juniClubId,
      channelName,
      prevJuniClubId,
      prevChannelName,
    }: {
      juniClubId?: string;
      channelName?: string;
      prevJuniClubId?: string;
      prevChannelName?: string;
    }): Promise<boolean> => {
      if (!pubnubClient) return false;

      if (prevJuniClubId && prevChannelName) {
        await pubnubClient.unsubscribe({
          channels: [`${prevJuniClubId}.${prevChannelName}`],
        });
      }

      if (juniClubId && channelName) {
        const tt = await pubnubTime();
        await pubnubClient.subscribe({
          channels: [`${juniClubId}.${channelName}`],
          withPresence: false,
          timetoken: tt,
        });
      }

      return true;
    },
    [pubnubClient, pubnubTime],
  );

  return pubnubSubscribe;
};

export const usePubnubFetchMessages = () => {
  const { pubnubClient, clientUUID } = useClubStore(
    state => state.pubnub,
    pubnubCompare,
  );
  const unreadMessages = useClubStore(state => state.unreadMessages);
  const sortedMessages = useClubStore(state => state.sortedMessages);
  const processMessages = useProcessMessages();
  const recentCallsRef = useRef<Date[]>([]);
  const isDisabledRef = useRef<boolean>(false);

  // TODO: fetchmessages should accept includeUUID but it does not
  // need to be sure that uuids are returned
  const pubnubFetchMessages = useCallback(
    async ({
      currentClientUUID,
      juniClubId,
      channelName,
      count,
      start,
      end,
    }): Promise<{ success: boolean; lastMsgTimetoken: string | undefined }> => {
      if (isDisabledRef.current) {
        console.log('clubs: fetch messages warning - disabled');
        return { success: false, lastMsgTimetoken: undefined };
      }

      if (!pubnubClient || (currentClientUUID && clientUUID !== currentClientUUID)) {
        console.log('clubs: fetch messages warning - missing args');
        return { success: false, lastMsgTimetoken: undefined };
      }
      const channelUnreadMessages =
        unreadMessages[juniClubId] && unreadMessages[juniClubId][channelName];
      if (end && !channelUnreadMessages) {
        const sortedChannelMessages = sortedMessages.filter(
          m => m.juniClubId === juniClubId && m.juniClubChannel === channelName,
        );
        const lastMsg = _.last(sortedChannelMessages);
        return { success: true, lastMsgTimetoken: lastMsg?.msgId };
      }

      checkFetchMessageRateLimit(recentCallsRef, isDisabledRef);

      // TODO: for some reason there's a bug where this acts like it's fetching history
      // for multiple channels and doesn't let the count go above 25
      //
      // The cause for this behavior is requesting the message actions. My
      // guess is that inside the pubnub blackbox, actions are handled as a
      // separate channel.
      const response = await pubnubClient.fetchMessages({
        channels: [`${juniClubId}.${channelName}`],
        count: !count && !start && !end ? PUBNUB_NUM_MESSAGES_TO_FETCH : undefined,
        start,
        end,
        includeMessageActions: true,
      });
      if (!response) return { success: false, lastMsgTimetoken: undefined };
      const messages: FetchMessageType[] = _.flatten(_.values(response.channels));
      processMessages(messages);

      const lastMsgTimetoken = _.max(messages.map(m => String(m.timetoken)));
      return { success: true, lastMsgTimetoken };
    },
    [pubnubClient, clientUUID, unreadMessages, processMessages, sortedMessages],
  );

  return pubnubFetchMessages;
};

// Wrapper for if you need to call fetchMessages directly
export const usePubnubFetchMessagesRaw = () => {
  const { pubnubClient, clientUUID } = useClubStore(
    state => state.pubnub,
    pubnubCompare,
  );
  const recentCallsRef = useRef<Date[]>([]);
  const isDisabledRef = useRef<boolean>(false);

  const pubnubFetchMessagesRaw = useCallback(
    async ({
      currentClientUUID,
      channels,
      count,
    }: {
      currentClientUUID?: string;
      channels: string[];
      count: number;
    }): Promise<MsgType[]> => {
      if (isDisabledRef.current) {
        console.log('clubs: fetch messages raw warning - disabled');
        return [];
      }

      if (
        !pubnubClient ||
        (currentClientUUID && clientUUID !== currentClientUUID) ||
        !channels.length
      ) {
        console.log('clubs: fetch messages raw warning - missing args');
        return [];
      }

      checkFetchMessageRateLimit(recentCallsRef, isDisabledRef);

      const response = await pubnubClient.fetchMessages({
        channels,
        count,
        includeMessageActions: true,
      });
      if (!response) return [];
      const messages = _.flatten(_.values(response.channels));

      return messages.map(transformPubNubMessage);
    },
    [clientUUID, pubnubClient],
  );

  return pubnubFetchMessagesRaw;
};

export type CheckMessageReturnType = {
  isFlagged?: boolean;
  moderationErrorMessage?: string;
};

export const useCheckImageContent = () => {
  const [checkImageContentMutation] = useCheckImageContentMutation();

  const checkImageContent = useCallback(
    async ({
      studentId,
      instructorUserId,
      imageUrl,
    }: {
      studentId?: string;
      instructorUserId?: string;
      imageUrl: string;
    }): Promise<CheckImageContentPayload> => {
      if (!imageUrl) {
        return {};
      }
      try {
        const { data } = await checkImageContentMutation({
          variables: {
            studentId,
            instructorUserId,
            imageUrl,
          },
        });
        const { success, violations } = data?.checkImageContent || {};
        return { success, violations };
      } catch (e) {
        // TODO: we're just consuming sightengine failures, add UX to display to user
        console.log(e);
        return {};
      }
    },
    [checkImageContentMutation],
  );

  return checkImageContent;
};

export const useCheckMesssageTextContent = () => {
  // Not sure how we should go about changing the contract here. Doesn't look like it's used elsewhere, seems like it would be kind of a PITA to change though
  const [checkMessageContentMutation] = useCheckMessageContentMutation();

  const checkMessageContent = useCallback(
    async ({
      studentId,
      instructorUserId,
      msgText,
    }: {
      studentId?: string;
      instructorUserId?: string;
      msgText?: string;
    }): Promise<CheckMessageReturnType> => {
      if (!msgText) {
        return {};
      }
      const { data } = await checkMessageContentMutation({
        variables: {
          studentId,
          instructorUserId,
          message: msgText,
        },
      });
      const { success, moderationErrorMessage } = data?.checkMessageContent || {};
      if (success && !!moderationErrorMessage) {
        return { isFlagged: true, moderationErrorMessage };
      }
      return {};
    },
    [checkMessageContentMutation],
  );

  return checkMessageContent;
};

const imageUploadUrlStripper = /(?:.*\/\/[^/]*\/)(clubs\/.*)/;
export const useDeleteClubImageUploads = () => {
  const [deleteS3ObjectMutation] = useDeleteS3ObjectMutation();
  const deleteClubImageUploads = useCallback(
    async (images: string[]) => {
      await Promise.all(
        images.map(async image => {
          const matches = image.match(imageUploadUrlStripper);
          const object = matches && matches[1];
          if (object) {
            await deleteS3ObjectMutation({
              variables: {
                input: {
                  destination: UploadDestination.ClubUserImages,
                  object,
                },
              },
            });
          }
        }),
      );
    },
    [deleteS3ObjectMutation],
  );
  return deleteClubImageUploads;
};
// TODO: should we also try to send username as a backup?
export const usePubnubPublishMessage = () => {
  const set = useClubStore(state => state.set);
  const { pubnubClient } = useClubStore(state => state.pubnub, pubnubCompare);
  const checkMessageTextContent = useCheckMesssageTextContent();
  const checkImageContent = useCheckImageContent();
  const [sendSlackImageUploadMessage] = useSendImageUploadSlackMessageMutation();

  const pubnubPublishMessage = useCallback(
    async ({
      studentId,
      instructorUserId,
      juniClubId,
      channelName,
      message,
    }: {
      studentId?: string;
      instructorUserId?: string;
      juniClubId: string;
      channelName: string;
      message: { msgText?: string; msgImageUrls: string[] };
    }): Promise<void> => {
      if (!pubnubClient || (!studentId && !instructorUserId)) return;

      // add temp message to clubstore
      const tempMsgId = `${String(Date.now())}0000`;
      const tempMsg: MsgType = {
        msgId: tempMsgId,
        juniClubId,
        juniClubChannel: channelName,
        timestamp: new Date(),
        senderUUID: studentId || instructorUserId,
        payload: message,
        isMsgIdTemporary: true,
      };
      set((state: ClubStoreType) => {
        state.sortedMessages.push(tempMsg);
      });

      // checkMessageContent
      const { isFlagged, moderationErrorMessage } = await checkMessageTextContent({
        studentId,
        instructorUserId,
        msgText: message.msgText,
      });

      // check image Urls
      const imageUrls = message.msgImageUrls;
      const imageErrors: CheckImageContentPayload[] = await Promise.all(
        imageUrls.map(async imageUrl =>
          checkImageContent({
            studentId,
            instructorUserId,
            imageUrl,
          }),
        ),
      );
      // TODO: move this error checking to ChatMessageComposerPreview thumbnail state
      // Reasons: better UX due to slow sightengine checking on images, and inability to identify
      // which violated sightengine moderation errors.
      const imageFlagged = imageErrors.find(imageError => !imageError.success);

      // send message sent event with moderation feedback to snowflake.
      JuniAnalytics.track('chat_publish_message', {
        juniClubId,
        channelName,
        messageLength: message.msgText ? message.msgText.length : 0,
        imagesSent: imageUrls.length,
        imageFlagged,
        isFlagged,
        moderationErrorMessage,
      });

      // if autoflagged, update message in clubstore
      if (isFlagged || imageFlagged) {
        const imageErrorMsg = `One or more of your images has been flagged with the following violations: ${_.uniq(
          imageErrors.flatMap(imageError => imageError.violations),
        ).join(',')}.`;
        set((state: ClubStoreType) => {
          state.sortedMessages = state.sortedMessages.map(msg => {
            if (msg.msgId === tempMsgId) {
              msg.isFlagged = true;
              msg.flaggedReason = moderationErrorMessage || imageErrorMsg;
            }
            return msg;
          });
        });
        return;
      }

      const publishResult = await pubnubClient.publish({
        channel: `${juniClubId}.${channelName}`,
        message,
      });

      if (message?.msgImageUrls?.length) {
        try {
          sendSlackImageUploadMessage({
            variables: {
              input: {
                msgId: String(publishResult.timetoken),
                juniClubId,
                channelName,
                studentId,
                instructorUserId,
                msgText: message.msgText,
                imageUrls: message.msgImageUrls,
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (publishResult?.timetoken) {
        set((state: ClubStoreType) => {
          state.sortedMessages = state.sortedMessages.map(msg => {
            if (msg.msgId === tempMsgId) {
              msg.msgId = String(publishResult.timetoken);
              msg.isMsgIdTemporary = false;
            }
            return msg;
          });
        });
        // Add in a small delay to stagger the arrival of both messages
        setTimeout(() => {
          pubnubClient.signal({
            channel: `${juniClubId}.${PUBNUB_SIGNALS_CHANNEL}`,
            message: `ch:${channelName}`,
          });
        }, 200);
      } else {
        set((state: ClubStoreType) => {
          state.sortedMessages = state.sortedMessages.map(msg => {
            if (msg.msgId === tempMsgId) {
              msg.isFailed = true;
            }
            return msg;
          });
        });
      }
    },
    [
      checkMessageTextContent,
      checkImageContent,
      sendSlackImageUploadMessage,
      pubnubClient,
      set,
    ],
  );

  return pubnubPublishMessage;
};

export const useClubMemberLookup = ({
  juniClubId,
  studentId,
  instructorUserId,
}: {
  juniClubId: string;
  studentId?: string;
  instructorUserId?: string;
}): [
  _.Dictionary<JuniClubMembershipInfoPlusBadgeType>,
  JuniClubMembershipInfoPlusBadgeType | undefined,
] => {
  const allMembers = useClubStore(state => state.juniClubMembershipInfos);
  const clubMembers = _.values(allMembers)
    .filter(m => m.juniClubId === juniClubId)
    .map(m => ({ ...m, badgeInfo: getClubUserBadgeInfo(m) }));
  const clientUUIDToClubMemberState = _.keyBy(clubMembers, 'clientUUID');

  const clientUUID = studentId || instructorUserId;
  const currentClubMemberState = clientUUID
    ? clientUUIDToClubMemberState[clientUUID]
    : undefined;

  return [clientUUIDToClubMemberState, currentClubMemberState];
};

// Use to Moderate or Delete message
export const useRemoveMessage = () => {
  const { pubnubClient } = useClubStore(state => state.pubnub, pubnubCompare);
  const [removeViolatingMessageMutation] = useRemoveViolatingMessageMutation();
  const [deleteMessageMutation] = useDeleteMessageMutation();

  const removeMessage = useCallback(
    async ({
      studentId,
      instructorUserId,
      juniClubId,
      channelName,
      msgId,
      imageUrls,
      senderUUID,
      shouldDelete,
    }: {
      studentId?: string;
      instructorUserId?: string;
      juniClubId: string;
      channelName: string;
      msgId: string;
      imageUrls?: string[];
      senderUUID?: string;
      shouldDelete?: boolean;
    }) => {
      if (!pubnubClient) return;
      console.log(
        'clubs: remove message',
        `${juniClubId}.${channelName}`,
        msgId,
        shouldDelete,
      );

      const vars = {
        studentId,
        instructorUserId,
        juniClubId,
        channelName,
        msgId,
        imageUrls,
      };
      if (shouldDelete) {
        await deleteMessageMutation({
          variables: {
            ...vars,
            senderUUID,
          },
        });
      } else {
        await removeViolatingMessageMutation({
          variables: vars,
        });
      }
    },
    [deleteMessageMutation, pubnubClient, removeViolatingMessageMutation],
  );

  return removeMessage;
};

export const useUpdateLastReadTimetoken = () => {
  const set = useClubStore(state => state.set);
  const [updateLastReadTimetokenMutation] = useUpdateLastReadTimetokenMutation();

  const updateLastReadTimetoken = useCallback(
    async ({
      studentId,
      instructorUserId,
      juniClubId,
      channelName,
      timetoken,
    }: {
      studentId?: string;
      instructorUserId?: string;
      juniClubId?: string;
      channelName?: string;
      timetoken?: string;
    }): Promise<boolean> => {
      if (!juniClubId || !channelName || !timetoken) return false;

      // TODO: change this to only update if new messages came in while channel was focused
      const { data: success } = await updateLastReadTimetokenMutation({
        variables: {
          studentId,
          instructorUserId,
          juniClubId,
          channelName,
          timetoken,
        },
      });
      set((state: ClubStoreType) => {
        if (!(juniClubId in state.unreadMessages)) return;
        if (!(channelName in state.unreadMessages[juniClubId])) return;
        state.unreadMessages[juniClubId][channelName] = 0;
      });

      return !!success;
    },
    [set, updateLastReadTimetokenMutation],
  );

  return updateLastReadTimetoken;
};

export const useSetCurrentChatChannel = () => {
  const set = useClubStore(state => state.set);

  const setCurrentChatChannel = useCallback(
    ({
      juniClubId,
      channelName,
    }: {
      juniClubId?: string;
      channelName?: string;
    }): void => {
      set((state: ClubStoreType) => {
        state.currentChatChannel = { juniClubId, channelName };
      });
    },
    [set],
  );

  return setCurrentChatChannel;
};

interface LeaveClubParams {
  studentId?: string;
  instructorUserId?: string;
  juniClubId: string;
}

export const useLeaveClub = () => {
  const { set } = useClubStore();
  const [submitLeave] = useJoinLeaveClubMutation();
  const updateMyClubsState = useUpdateMyClubsState();

  const leaveClub = useCallback(
    async ({ studentId, instructorUserId, juniClubId }: LeaveClubParams) => {
      set((state: ClubStoreType) => {
        state.leavingClubId = juniClubId;
      });

      try {
        await submitLeave({
          variables: {
            studentId,
            instructorUserId,
            juniClubId,
            isActive: false,
          },
        });
        await updateMyClubsState({ studentId, instructorUserId });
      } finally {
        set((state: ClubStoreType) => {
          state.leavingClubId = undefined;
        });
      }
    },
    [set, submitLeave, updateMyClubsState],
  );

  return leaveClub;
};
