import React, { FC } from 'react';
import classNames from 'classnames';

const Radio: FC<{ selected?: boolean }> = ({ selected = false }) => {
  const outerStyles = classNames(
    'border border-solid rounded-full w-4 h-4 flex justify-center items-center relative',
    {
      'border-j-purple-300 bg-white': !selected,
      'border-j-purple-600 bg-j-purple-600': selected,
    },
  );

  const innerStyles = classNames('w-2 h-2 rounded-full', {
    'bg-transparent': !selected,
    'bg-white': selected,
  });

  return (
    <div className={outerStyles}>
      <div className={innerStyles} />
    </div>
  );
};

export default Radio;
