import classNames from 'classnames';
import styled from 'styled-components/macro';

interface StyledJideEnvLayoutProps {
  /**
   * Opt-in to fullscreen mode.
   * Default false.
   */
  fullScreen?: boolean;
}

export const StyledJideEnvLayout = styled.div<StyledJideEnvLayoutProps>`
  ${({ fullScreen }) =>
    fullScreen &&
    `position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px 10vmax rgba(0, 0, 0, 0.75);
  animation: grow 0.25s ease-out;`}
`;

interface StyledClassName {
  /**
   * Optional classnames to add styling.
   */
  className?: string;
}

// the ratio should be 60/40 between the main content and right side content,
// up to 1440px, at which point the right side content maxes out at 576px.
// However, for jide environments with the canvas present the ratio
// should be 50/50 to allow more room on the right side content to render
// larger images.
export const StyledMainContent = styled.div.attrs<StyledClassName>(
  ({ className }) => ({
    className: classNames('flex', className),
  }),
)<any>`
  flex: ${props => (props.withCanvas ? '1' : '6')};
`;

export const StyledSideContent = styled.div.attrs<StyledClassName>(
  ({ className }) => ({
    className: classNames('flex flex-col h-full bg-white', className),
  }),
)<any>`
  flex: ${props => (props.withCanvas ? '1' : '4')};
  ${props =>
    !props.withCanvas &&
    `
    @media (min-width: 768px) {
      max-width: 576px;
    }
  `}
`;
