import {
  ORDERED_SUBJECTS,
  SUBJECT_DISPLAY_NAMES,
  SUBJECT_TO_METADATA_KEY,
} from 'constants/subjects';
import {
  CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME,
  CORE_PRICES,
  ASYNC_PLAN_PRODUCT,
} from 'constants/subscription_plans';
import { useRecommendedAges } from 'hooks/course';
import { Icon, NewButton, Select } from 'core-components';
import _ from 'lodash';
import * as R from 'ramda';
import { useState } from 'react';
import { getShortDisplayName } from 'utils/courses';
import { COURSES_REQUIRING_INSTRUCTOR_APPROVAL } from 'utils/enrollment';
import { extractItemsFromMetadataNew, hasAsync } from 'utils/stripe';
import LearnerPortalModal from '../LearnerPortalModal';

const collateCoursesBySubject = courses => R.groupBy(x => x.subject.name, courses);

const PricingDisplay = ({ metadataNew, formValues, hasAsyncProduct }) => {
  const items = extractItemsFromMetadataNew(metadataNew);
  if (hasAsyncProduct || formValues.addAsync) {
    items.push(_.pick(ASYNC_PLAN_PRODUCT, ['key', 'price']));
  }
  const totalPrice = items.reduce((total, item) => total + item.price, 0);
  return (
    <div className="flex flex-col">
      <div className="w-3/5 text-lg text-j-dark-600 font-medium mb-2">Pricing</div>
      {items.map(({ key, frequency, price }) => {
        let displayName = '';
        if (key === ASYNC_PLAN_PRODUCT.key) {
          displayName = ASYNC_PLAN_PRODUCT.displayName;
        } else {
          const category =
            key !== 'core_weeklyFrequency'
              ? CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[key]
              : '';
          const frequencyStr =
            frequency === 0.5 ? '2x per month' : `${frequency}x per week`;
          displayName = `${category} ${frequencyStr}`;
        }
        return (
          <div
            className={`w-full flex flex-row justify-between text-sm ${
              items.length === 1 ? 'text-j-dark-600' : 'text-j-dark-400'
            } mb-1`}
          >
            <div>{displayName}</div>
            <div className="w-2/5">{`$${price.toLocaleString()}/mo`}</div>
          </div>
        );
      })}

      {items.length > 1 && (
        <div className="w-full flex flex-row justify-between text-sm font-medium text-j-dark-600 ">
          <div>Total</div>
          <div className="w-2/5">{`$${totalPrice.toLocaleString()}/mo`}</div>
        </div>
      )}
    </div>
  );
};

const CourseAddComponent = ({
  courses,
  metadataOld,
  metadataNew,
  updateMetadataNew,
  formValues,
  updateFormValue,
  hasAsyncProduct,
}) => {
  const recommendedAges = useRecommendedAges();

  const currentCourses = _.flatMap(metadataOld, state => state.courses);
  const [isAddingSubject, setIsAddingSubject] = useState(false);
  const approvedCourses = courses.filter(
    course =>
      !COURSES_REQUIRING_INSTRUCTOR_APPROVAL.includes(course.name) &&
      course.isAcceptingEnrollment &&
      course.schedulingFormat === 'private',
  );
  const coursesBySubject = collateCoursesBySubject(approvedCourses);
  // only allow addition of subjects that the student is not currently enrolled in or has not already selected
  const subjectsToDisplay = ORDERED_SUBJECTS.filter(subject => {
    const coursesOld = metadataOld[SUBJECT_TO_METADATA_KEY[subject]]?.courses || [];
    const coursesNew = metadataNew[SUBJECT_TO_METADATA_KEY[subject]]?.courses || [];
    return (
      _.intersection(
        _.map(coursesBySubject[subject], 'name'),
        _.difference(coursesNew, coursesOld),
      ).length === 0
    );
  });

  const maxWeeklyFrequency = _.max(_.map(_.keys(CORE_PRICES), key => Number(key)));
  const totalSelectedFrequency = _.sum(
    _.map(_.values(metadataNew), metadata =>
      metadata.frequency >= 1 ? metadata.frequency : 0,
    ),
  );

  const courseSelectOptions = _.flatten(
    _.map(subjectsToDisplay, subject =>
      // subject section header
      [
        {
          label: `${SUBJECT_DISPLAY_NAMES[subject]} Courses`,
          value: subject,
          disabled: true,
        },
      ].concat(
        // courses for subject
        coursesBySubject[subject]
          .filter(
            course =>
              !currentCourses.includes(course.name) && course.isAcceptingEnrollment,
          )
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map(course => ({
            value: `${subject}%${course.name}`,
            label: recommendedAges[course.name]?.displayString || course.name,
          })),
      ),
    ),
  );

  if (!hasAsyncProduct && !formValues.addAsync) {
    courseSelectOptions.push(
      {
        label: 'On Demand',
        value: 'on_demand_label',
        disabled: true,
      },
      {
        label: ASYNC_PLAN_PRODUCT.displayName,
        value: ASYNC_PLAN_PRODUCT.key,
      },
    );
  }

  const addCourseButtonsEnabled =
    totalSelectedFrequency < maxWeeklyFrequency && subjectsToDisplay.length > 0;
  return (
    <div className="w-3/5 mb-5">
      {isAddingSubject ? (
        <Select
          disabled={!addCourseButtonsEnabled}
          size="small"
          dropdownPosition="bottom-left"
          placeholder="Select a new course"
          options={courseSelectOptions}
          fullWidth
          onChange={value => {
            setIsAddingSubject(false);
            if (value === ASYNC_PLAN_PRODUCT.key) {
              updateFormValue(true, 'addAsync');
            } else {
              const [subject, course] = value.split('%');
              const { frequency = 0, courses: currentCourses = [] } =
                metadataOld[SUBJECT_TO_METADATA_KEY[subject]] || {};
              updateMetadataNew({
                ...metadataNew,
                [SUBJECT_TO_METADATA_KEY[subject]]: {
                  frequency: Math.floor(frequency + 1),
                  courses: [...currentCourses, course],
                },
              });
            }
          }}
        />
      ) : (
        <NewButton
          disabled={!addCourseButtonsEnabled}
          onClick={() => {
            setIsAddingSubject(true);
          }}
          renderIconLeft={props => <Icon.Plus {...props} />}
        >
          Add a course
        </NewButton>
      )}
    </div>
  );
};

const CourseFrequencyUpdater = ({
  updateMetadataNew,
  metadataNew,
  metadataOld,
  courses,
}) =>
  _.map(_.toPairs(metadataNew), ([key, newState]) => {
    const selectedCourses = courses.filter(course =>
      newState.courses.includes(course.name),
    );
    const maxWeeklyFrequency = _.max(_.map(_.keys(CORE_PRICES), key => Number(key)));
    // for the purposes of selection, ignore any biweekly frequency
    const totalSelectedFrequency = _.sum(
      _.map(_.values(metadataNew), metadata =>
        metadata.frequency >= 1 ? metadata.frequency : 0,
      ),
    );
    const incrementButtonEnabled = totalSelectedFrequency < maxWeeklyFrequency;
    const decrementButtonEnabled =
      !metadataOld[key] || metadataNew[key].frequency > metadataOld[key].frequency;
    return (
      <div className="flex flex-row w-full mb-2" key={key}>
        <div className="flex w-3/5 items-center">
          <div className="pr-6">
            {`${
              CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[key]
            }: ${selectedCourses
              .map(course => getShortDisplayName(course))
              .join(', ')}`}
          </div>
        </div>
        <div className="flex flex-row w-2/5">
          <div className="flex mr-3 w-5/12 items-center">
            {newState.frequency === 0.5
              ? '2x per month'
              : `${Number(newState.frequency)}x per week`}
          </div>
          <div className="flex w-7/12 items-center">
            <NewButton
              variant="minimal"
              size="small"
              disabled={!incrementButtonEnabled}
              onClick={() => {
                const newFrequency =
                  metadataNew[key].frequency >= 1
                    ? metadataNew[key].frequency + 1
                    : 1;
                updateMetadataNew({
                  ...metadataNew,
                  [key]: {
                    ...metadataNew[key],
                    frequency: newFrequency,
                  },
                });
              }}
            >
              +
            </NewButton>
            /
            <NewButton
              variant="minimal"
              size="small"
              disabled={!decrementButtonEnabled}
              onClick={() => {
                if (metadataNew[key].frequency === 0.5) {
                  updateMetadataNew(_.omit(metadataNew, key));
                  return;
                }
                const newFrequency =
                  metadataNew[key].frequency > 1
                    ? metadataNew[key].frequency - 1
                    : 0.5;
                updateMetadataNew({
                  ...metadataNew,
                  [key]: {
                    ...metadataNew[key],
                    frequency: newFrequency,
                  },
                });
              }}
            >
              -
            </NewButton>
          </div>
        </div>
      </div>
    );
  });

const CourseSelectorModalV2 = ({
  formValues,
  formState,
  updateFormState,
  updateFormValue,
  coursesQuery,
}) => {
  const { currentStudent, subscription, addAsync } = formValues;
  const hasAsyncProduct = hasAsync(subscription);

  const courses = coursesQuery?.data?.getCourses;

  const studentCurrentCourses = currentStudent.hasMultipleTracks
    ? currentStudent.tracks
    : [currentStudent.track];

  // e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
  const courseMetadataObj = _.pickBy(
    subscription ? subscription.metadata : {}, // subscription does not exist for new students
    (value, key) =>
      value && Object.keys(CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME).includes(key),
  );

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const [metadataNew, updateMetadataNew] = useState(_.cloneDeep(metadataOld));

  const subscriptionHasChanged = _.some(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );
  const nextButtonDisabled = !addAsync && !subscriptionHasChanged;
  return (
    <LearnerPortalModal
      title={formState.updateFlow}
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            updateFormValue(metadataNew, 'metadataNew');
            // skip to payment check modal for async-only changes
            const nextModal = subscriptionHasChanged
              ? 'schedule_select'
              : 'payment_check';
            updateFormState(nextModal, 'modal');
          }}
          disabled={nextButtonDisabled}
        >
          <div className="font-medium">Next</div>
        </NewButton>
      }
    >
      <div className="flex w-full justify-center">
        <div className="flex flex-col w-full">
          <div className="w-full flex flex-row justify-between text-sm text-j-dark-600 mb-2">
            <div className="w-3/5 text-lg font-medium">Subject: Course(s)</div>
            <div className="w-2/5 text-lg font-medium">Frequency</div>
          </div>
          <div className="flex flex-col justify-between text-sm text-j-dark-600 mb-2">
            <CourseFrequencyUpdater
              courses={courses}
              metadataOld={metadataOld}
              metadataNew={metadataNew}
              updateMetadataNew={updateMetadataNew}
            />
            {(hasAsyncProduct || addAsync) && (
              <div className="flex flex-row w-full mb-2">
                <div className="flex w-3/5">{ASYNC_PLAN_PRODUCT.displayName}</div>
                <div className="flex flex-row w-1/6">N/A</div>
              </div>
            )}
          </div>
          <div>
            <CourseAddComponent
              courses={courses}
              metadataOld={metadataOld}
              metadataNew={metadataNew}
              formValues={formValues}
              updateFormValue={updateFormValue}
              updateMetadataNew={updateMetadataNew}
              hasAsyncProduct={hasAsyncProduct}
            />
          </div>
          <div>
            <PricingDisplay
              hasAsyncProduct={hasAsyncProduct}
              formValues={formValues}
              metadataNew={metadataNew}
            />
          </div>
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default CourseSelectorModalV2;
