import React, { ReactNode } from 'react';
import * as R from 'ramda';
import { HelpCenterContent } from 'generated/graphql';
import { RouteComponentProps } from 'react-router-dom';
import CategoryIcon from './icons/CategoryIcon';

interface Props extends RouteComponentProps {
  helpCenterContent: HelpCenterContent;
  baseUrl: string;
  searchHeader: ReactNode;
}

const Home: React.FC<Props> = ({
  helpCenterContent,
  baseUrl,
  searchHeader,
  history,
}) => {
  const { categories } = helpCenterContent;

  return (
    <div>
      {searchHeader}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 auto-rows-max font-graphik px-10 pt-8">
        {R.sortBy(R.prop('position'))(categories).map(category => (
          <div
            key={category.id}
            className="p-6 bg-white rounded-xl shadow hover:shadow-2 transition cursor-pointer"
            onClick={() => history.push(`${baseUrl}/categories/${category.id}`)}
          >
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="w-5 h-5 p-1 flex items-center justify-center rounded-lg mr-2 bg-j-green-100">
                  <div className="w-4 h-4">
                    <CategoryIcon categoryId={category.id} />
                  </div>
                </div>
                <div className="text-base text-j-dark-500">{category.name}</div>
              </div>
              <div className="text-sm mt-3 font-normal text-j-dark-300">
                {category.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Home;
