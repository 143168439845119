import React from 'react';

import { NewButton as Button } from 'core-components';
import ReactMarkdown from 'react-markdown';
import { ParentCornerLiveAnnouncement } from 'generated/graphql';
import _ from 'lodash';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

interface Props {
  data: ParentCornerLiveAnnouncement[] | undefined;
}

const Announcements: React.FC<Props> = ({ data }) => {
  const announcementsInOrder = _.orderBy(data, ['order']);
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
      {announcementsInOrder &&
        announcementsInOrder.map(announcement => (
          <div key={announcement.title} className="p-6 rounded-lg bg-j-dark-900">
            <div>
              {/* Use our MarkdownText component if we figure out how to support links with custom labels */}
              <ReactMarkdown className="text-base text-white font-medium">
                {announcement.title.trim()}
              </ReactMarkdown>
              <ReactMarkdown
                className="pt-2 pb-4 text-base text-j-dark-300"
                renderers={{
                  link: ({ href, children }) => (
                    <a
                      href={href}
                      className="text-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {children}
                    </a>
                  ),
                }}
              >
                {announcement.body.trim()}
              </ReactMarkdown>

              {announcement.callsToAction && (
                <div className="flex gap-4">
                  {announcement.callsToAction.map(cta => (
                    <Button
                      key={cta.label}
                      onClick={() => {
                        JuniAnalytics.track('announcements_button_clicked', {
                          label: announcement.title,
                          buttonLabel: cta.label,
                          destinationUrl: cta.url,
                        });
                        window.open(cta.url, '_blank');
                      }}
                    >
                      <ReactMarkdown
                        renderers={{
                          paragraph: ({ children }) => (
                            <span className="font-medium text-white">
                              {children}
                            </span>
                          ),
                        }}
                      >
                        {cta.label.trim()}
                      </ReactMarkdown>
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Announcements;
