// check if some path (can be exact string or regex) in pathsArr matches the given path
export const somePathMatches = (path, pathsArr) => {
  for (let i = 0; i < pathsArr.length; i += 1) {
    if (
      (typeof pathsArr[i] === 'string' && path === pathsArr[i]) ||
      path.match(pathsArr[i])
    ) {
      return true;
    }
  }
  return false;
};
