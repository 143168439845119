import React from 'react';
import PropTypes from 'prop-types';

import { ClockIcon } from 'components/Icons';

import './video_duration.css';

const VideoDuration = ({ className, duration }) => {
  const seconds = duration % 60;
  const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;
  return (
    <div className={`video-duration ${className || ''}`}>
      <ClockIcon />
      <div className="duration-text">
        {Math.floor(duration / 60)}:{secondsDisplay}
      </div>
    </div>
  );
};
VideoDuration.propTypes = {
  className: '',
};
VideoDuration.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number.isRequired,
};
export default VideoDuration;
