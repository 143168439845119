import React, { FC } from 'react';

import { CALENDLY_LINKS, CHECKOUT_PATHS } from 'constants/signup_sessions';
import phone from 'images/phone.svg';

import { JuniLogo } from 'components/brand-assets';
import classNames from 'classnames';
import { CheckoutFlowPaths, SessionFlags } from '../types';
import CheckoutStepper from './CheckoutStepper';

type Theme = 'light' | 'dark';

const SINGLE_COURSE_STEPS = [
  CHECKOUT_PATHS.parentInfo,
  CHECKOUT_PATHS.starterCourse,
  CHECKOUT_PATHS.checkout,
];

const BOOTCAMP_COURSE_STEPS = [
  CHECKOUT_PATHS.parentInfo,
  CHECKOUT_PATHS.studentInfo,
  CHECKOUT_PATHS.checkout,
];

const MULTI_COURSE_STEPS = [
  CHECKOUT_PATHS.parentInfo,
  CHECKOUT_PATHS.studentInfo,
  CHECKOUT_PATHS.courseFrequency,
  CHECKOUT_PATHS.checkout,
];

function getStepPrefix(flags: SessionFlags, activeStep?: CheckoutFlowPaths) {
  if (!flags.isInDcfExperimentTreatmentGroup) return '';

  switch (activeStep) {
    case 'starter-course':
      return 'Sign Up – ';
    case 'payment':
      return 'Sign Up – ';
    default:
      return '';
  }
}

const CheckoutFlowWrapper: FC<{
  children: React.ReactNode;
  activeStep?: CheckoutFlowPaths;
  theme?: Theme;
  hideBanner?: boolean;
  hideStepper?: boolean;
  hideMobileStepper?: boolean;
  flags: SessionFlags;
}> = ({
  children,
  activeStep,
  theme = 'dark',
  hideBanner,
  hideStepper,
  hideMobileStepper,
  flags,
}) => {
  const steps = flags.multiSubject
    ? MULTI_COURSE_STEPS
    : flags.isBootcampSignup
    ? BOOTCAMP_COURSE_STEPS
    : SINGLE_COURSE_STEPS;
  const currentIndex = (activeStep ? steps.indexOf(activeStep) : -1) + 1;

  const bannerStyles = classNames(
    'flex flex-row items-center py-4 px-6 md:px-12 z-20',
    {
      'bg-juni-dark-900': theme === 'dark',
      'bg-juni-gray-100 sm:bg-white': theme === 'light',
      'justify-center': theme === 'light' && (hideStepper || currentIndex === 0),
    },
  );

  const logoStyles = classNames('w-18', {
    'hidden sm:block absolute':
      theme === 'light' && !hideStepper && currentIndex > 0,
  });
  return (
    <div className="min-h-screen flex flex-col">
      {!hideBanner && (
        // style is border bottom j-gray-300 :(
        <div className={bannerStyles} style={{ borderBottom: '1px solid #E5EDF4' }}>
          <div className={logoStyles}>
            <a href="https://junilearning.com" target="_blank" rel="noreferrer">
              <JuniLogo color={theme === 'dark' ? 'light' : 'dark'} />
            </a>
          </div>
          {!hideStepper && theme === 'light' && currentIndex > 0 && (
            <div className="text-j-dark-600 font-semibold text-xs w-full grow text-center flex items-center justify-center h-11 uppercase">
              <p>{`${getStepPrefix(flags, activeStep)}Step ${currentIndex} of ${
                steps.length
              }`}</p>
            </div>
          )}
          {theme === 'dark' && (
            <div className="ml-auto">
              <a
                className="text-white flex items-center no-underline hover:underline"
                href={CALENDLY_LINKS.salesTeam}
                target="_blank"
                rel="noreferrer"
              >
                <img src={phone} alt="" className="md:mr-4" />
                <span className="hidden text-sm md:flex">
                  Need help? Talk to a Juni Advisor.
                </span>
              </a>
            </div>
          )}
        </div>
      )}
      {!hideStepper && theme === 'dark' && (
        <div className={hideMobileStepper ? 'hidden sm:block' : ''}>
          <CheckoutStepper activeStep={activeStep} />
        </div>
      )}
      <div
        className={`
            bg-juni-light-100
            pb-8
            max-h-full
          `}
      >
        <div className="mx-auto container max-w-screen-xl sm:py-8 z-1">
          {children}
        </div>
      </div>
    </div>
  );
};
export default CheckoutFlowWrapper;
