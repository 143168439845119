import * as R from 'ramda';
import { SignupData, StudentData } from '../types';

const findStudentById = R.curry(
  (
    studentId = '',
    signupData: Partial<SignupData> | { [key: string]: any },
  ): Partial<StudentData> | undefined =>
    signupData?.students?.find((student: StudentData) => student._id === studentId),
);

export default findStudentById;
