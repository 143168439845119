import React, { useState } from 'react';
import { differenceInYears, startOfToday, format } from 'date-fns';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

import isBetween from 'utils/isBetween';
import { Input, NewButton, Select } from 'core-components';
import WarningBlock from 'app/signup_session/components/WarningBlock';

import { GRADE_LEVELS } from 'constants/grade_level';
import { FOREIGN_TIMEZONES, US_TIMEZONES } from 'constants/timezones';
import { AGES_TAUGHT } from 'constants/student_ages';

import LearnerPortalModal from '../LearnerPortalModal';

const StudentCreationModal = ({
  formState,
  formValues,
  updateFormState,
  updateFormValue,
}) => {
  const updateStudent = (newValue, field) => {
    updateFormValue(
      {
        ...formValues.currentStudent,
        [field]: newValue,
      },
      'currentStudent',
    );
  };
  const [touchedFields, setTouchedFields] = useState([]);
  const addToTouchedFields = keyName =>
    setTouchedFields(
      touchedFields.includes(keyName)
        ? [...touchedFields]
        : [...touchedFields, keyName],
    );
  const { currentStudent } = formValues;
  const isNextEnabled =
    currentStudent.firstName &&
    currentStudent.lastName &&
    currentStudent.grade &&
    currentStudent.timezone &&
    currentStudent.birthdate;

  const studentAge = currentStudent.birthdate
    ? differenceInYears(new Date(), new Date(currentStudent.birthdate))
    : undefined;
  const ageIsOutsideOfRange =
    touchedFields.includes('birthdate') &&
    studentAge !== undefined &&
    !isBetween(AGES_TAUGHT.min, AGES_TAUGHT.max, studentAge);

  return (
    <LearnerPortalModal
      title="New Student"
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton
          disabled={!isNextEnabled}
          onClick={() => {
            const modalName = 'course_select';
            JuniAnalytics.track(`${formState.modal}_button_clicked`, {
              funnel: 'subscription_upgrade_downgrade',
              sourceModal: formState.modal,
              destinationModal: modalName,
              actionFlow: formState.updateFlow,
            });
            updateFormState(modalName, 'modal');
          }}
        >
          <div className="font-medium">Next</div>
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 w-full gap-2">
        <div>First Name</div>
        <div>
          <Input
            fullWidth
            size="xsmall"
            placeholder="e.g. Grace"
            value={currentStudent.firstName}
            onChange={event => updateStudent(event.target.value, 'firstName')}
          />
        </div>
        <div>Last Name</div>
        <div>
          <Input
            fullWidth
            size="xsmall"
            placeholder="e.g. Hopper"
            value={currentStudent.lastName}
            onChange={event => updateStudent(event.target.value, 'lastName')}
          />
        </div>
        <div>Birth Date</div>
        <div className="flex flex-col gap-2 w-full">
          <Input
            type="date"
            id="birthdate"
            label="Date of Birth"
            size="xsmall"
            value={currentStudent.birthdate}
            max={format(startOfToday(), 'yyyy-MM-dd')}
            fullWidth
            onBlur={() => addToTouchedFields('birthdate')}
            onChange={e => updateStudent(e.target.value, 'birthdate')}
          />
          {ageIsOutsideOfRange && (
            <WarningBlock>
              Our courses are designed for kids ages 7-18. Please contact us to
              discuss your child's enrollment.
            </WarningBlock>
          )}
        </div>
        <div>Grade</div>
        <div>
          <Select
            fullWidth
            size="xsmall"
            selected={currentStudent.grade}
            onChange={selected => updateStudent(selected, 'grade')}
            placeholder="Select Grade"
            options={GRADE_LEVELS.map(grade => ({
              value: grade.value,
              label: grade.displayName,
            }))}
          />
        </div>
        <div>Timezone</div>
        <div>
          <Select
            fullWidth
            size="xsmall"
            selected={currentStudent.timezone}
            onChange={selected => updateStudent(selected, 'timezone')}
            placeholder="Select Timezone"
            options={US_TIMEZONES.concat(FOREIGN_TIMEZONES).map(tz => ({
              value: tz.value,
              label: tz.displayName,
            }))}
          />
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default StudentCreationModal;
