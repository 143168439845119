import React from 'react';
import styled from 'styled-components/macro';
import scratchInstructions from 'images/scratch-share.png';
import { InputField } from 'components/ui';
import ActionButton from 'components/ActionButton';
import classNames from 'classnames';
import { Arrow } from 'components/Icons';

const PublishInstructionsContainer = styled.div`
  text-align: left;
`;

const Header = styled.div`
  padding: 1rem 1.25rem;
  margin: 0;
  & .scratch-project-header {
    color: #335875;
    margin-top: 0;
  }
  & .scratch-project-desc {
    color: #30628a;
    font-size: 0.875rem;
    letter-spacing: -0.0075rem;
    line-height: 1.7;
  }
`;
const Body = styled.div`
  background: #f8fdff;
  border-top: 2px solid #e1f0f8;
  padding: 1rem 1.25rem;
  margin: 0;

  & .error input {
    border-color: #bb2222;
  }
`;
const Error = styled.div`
  color: #bb2222;
  margin: 0.4rem 0;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & .scratch-env-action-button {
    width: 22rem;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
const BodyHeader = styled.h4`
  font-size: 1rem;
  color: #325265;
  margin: 0;
`;

const ListItem = styled.div`
  color: #30628a;
  font-size: 0.875rem;
  letter-spacing: -0.0075rem;
  line-height: 1.7;
  margin: 0.5rem 0;
  & .url-field {
    margin-top: 1rem;
  }

  & .external__link {
    text-decoration: none;
    font-weight: bold;
    color: #00a5ff;
  }
`;

const ScratchInstructions = styled.div`
  width: 70%;
  margin: 0.5rem 0;

  & img {
    width: 100%;
    border-radius: 0.5rem;
  }
`;

const PublishProject: React.FC<{
  urlDisplayErrMsg?: string;
  initialDisplayName: string;
  showError?: boolean | '';
  newProjectUrl: string;
  scratchUrlStatus?: number;
  valid: boolean;
  onUrlUpdate: (value: string, name: string) => void;
  onSave: () => void;
}> = ({
  urlDisplayErrMsg,
  initialDisplayName,
  showError,
  newProjectUrl,
  scratchUrlStatus,
  valid,
  onUrlUpdate,
  onSave,
}) => (
  <PublishInstructionsContainer>
    <Header>
      <h2 className="scratch-project-header">{initialDisplayName}</h2>
      <p className="scratch-project-desc">
        Because Scratch projects are created on https://scratch.mit.edu, in order to
        add your Scratch project here, we simply need to paste in your project link!
      </p>
    </Header>
    <Body>
      <BodyHeader>How to add your project link:</BodyHeader>

      <div className="instructions-list">
        <ListItem>
          <span className="number">1. </span>
          Find your saved project in your Scratch account under My Stuff:{' '}
          <a
            className="external__link"
            href="https://scratch.mit.edu/mystuff"
            target="__blank"
          >
            https://scratch.mit.edu/mystuff
          </a>
        </ListItem>
        <ListItem>
          <span className="number">2. </span>
          Click "Share" on the project in the navigation bar:
          <ScratchInstructions>
            <img src={scratchInstructions} alt="share-instructions.png" />
          </ScratchInstructions>
        </ListItem>
        <ListItem>
          <span className="number">3. </span>
          Copy the URL from the address bar and paste below:
          <InputField
            value={newProjectUrl}
            onChange={onUrlUpdate}
            className={classNames({
              'url-field': true,
              error: showError,
            })}
          />
          {showError && <Error>{urlDisplayErrMsg}</Error>}
          {scratchUrlStatus === 404 && (
            <Error>
              Oops! This project hasn't been published in Scratch yet. Click the
              orange Share button at the top of your project.
            </Error>
          )}
        </ListItem>
      </div>

      <ButtonContainer>
        <ActionButton
          onClick={onSave}
          disabled={!valid}
          className="scratch-env-action-button"
        >
          <span>Save this project</span> <Arrow orientation="right" />
        </ActionButton>
      </ButtonContainer>
    </Body>
  </PublishInstructionsContainer>
);

export default PublishProject;
