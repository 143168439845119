import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';

import './index.css';

const RadioGroup = ({ className, label, name, children }) => {
  const fieldsetClass = classNames(
    { 'ui-radio-group': true, 'ui-has-label': !!label },
    'ui-vertical-spacing-small',
    className,
  );
  return (
    <fieldset className={fieldsetClass}>
      {label && <legend className="ui-field-label">{label}</legend>}
      <ErrorMessage component="div" name={name} className="ui-field-error" />
      {children}
    </fieldset>
  );
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
};

export default RadioGroup;
