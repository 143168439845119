/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { QueryResult } from '@apollo/client';
import { NewButton } from 'core-components';
import {
  LoadPaymentHistoryByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  useGetCoursesQuery,
  useLoadAvailabilitiesByStudentIdsQuery,
  useLoadDefaultPaymentInformationByParentIdQuery,
  useLoadPaymentHistoryByParentIdQuery,
  useLoadProductsByStripeIdsQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
  useLoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { Parent } from 'models';
import UserContext from 'modules/UserContext';
import React, { useContext, useState } from 'react';
import { FormStateModal } from './modals';
import { FormState, FormValues } from './modals/types';
import {
  AccountDetailsSection,
  CodeOfConductSection,
  PreferencesSection,
} from './sections';

export const UPDATE_FLOWS: { [key: string]: string } = {
  NEW_CLASS: 'New Course',
  NEW_STUDENT: 'New Student',
  REENGAGE_CHURN: 'New Subscription',
  REDUCE_COURSES: 'Reduce Courses',
  REDUCE_FREQUENCY: 'Reduce Class Frequency',
  CANCEL: 'Cancel Subscription',
};

export const DEFAULT_STUDENT_VALUES = {
  _id: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  grade: '',
  email: '',
  age: '',
  track: '',
  notes: '',
  parentId: '',
  completedTracks: [],
  hasMultipleTracks: false,
  receivesSessionNotes: false,
  tracks: [],
  isJuniExplorer: false,
  username: '',
};

const DEFAULT_FORM_VALUES: FormValues = {
  // general fields
  subscription: undefined,
  addAsync: false,
  // upgrade fields
  currentStudent: undefined,
  couponId: undefined,
  // downgrade fields
  finalSession: undefined,
  cancellationReasons: [],
  otherNotes: undefined,
  additionalNotes: undefined,
  selectedCoursesToRemove: undefined,
  classFrequenciesToReduce: undefined,
  followupDate: undefined,
  effectiveDate: undefined,
};

const DEFAULT_FORM_STATE: FormState = {
  modal: '',
  updateFlow: '',
};

interface LearnerAccountProps {
  parent: Parent;
  impersonationMode?: boolean;
}

const LearnerAccount = ({ parent, impersonationMode }: LearnerAccountProps) => {
  const { user } = useContext(UserContext);
  const isGuest = user?.isGuest || Boolean(impersonationMode && parent.isGuest);

  const ACCOUNT_TABS: { [key: string]: string } = {
    ACCOUNT_DETAILS: 'Account Details',
    ...(!isGuest ? { PREFERENCES: 'Preferences' } : {}), // only show Preference tab for non-guests
    CODE_OF_CONDUCT: 'Code of Conduct',
  };

  const [activeTab, setActiveTab] = useState(ACCOUNT_TABS.ACCOUNT_DETAILS);

  // modal state
  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);

  const updateFormState = (newValue: any, field: string) => {
    setFormState(values => ({ ...values, [field]: newValue }));
  };

  const updateFormValue = (newValue: any, field: string) => {
    setFormValues(values => ({ ...values, [field]: newValue }));
  };

  const resetForm = () => {
    setFormState(DEFAULT_FORM_STATE);
    setFormValues(DEFAULT_FORM_VALUES);
  };

  const coursesQuery = useGetCoursesQuery({
    skip: isGuest,
  });

  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: parent._id },
    skip: isGuest,
  });

  const subscriptionsQuery = useLoadStripeSubscriptionsByParentIdQuery({
    variables: { id: parent._id },
    skip: isGuest,
  });

  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];

  const paymentsQuery: QueryResult<LoadPaymentHistoryByParentIdQuery> = useLoadPaymentHistoryByParentIdQuery(
    {
      variables: { id: parent._id },
      skip: isGuest,
    },
  );

  const studentsQuery: QueryResult<LoadStudentsByParentIdsQuery> = useLoadStudentsByParentIdsQuery(
    {
      variables: { ids: [parent._id.toString()] },
    },
  );

  const availabilityQuery = useLoadAvailabilitiesByStudentIdsQuery({
    variables: {
      ids:
        studentsQuery.data?.studentsByParentIds?.items.map(studentObj =>
          studentObj._id.toString(),
        ) || [],
    },
    skip:
      !studentsQuery.data ||
      !studentsQuery.data?.studentsByParentIds?.items.length ||
      isGuest,
  });

  const productIds = subscriptions.reduce(
    (productIds, subscription) =>
      productIds.concat(
        subscription.items.data.map(
          subscriptionItem => subscriptionItem.plan.product || '',
        ),
      ),
    [] as string[],
  );
  const productsQuery = useLoadProductsByStripeIdsQuery({
    variables: { ids: _.uniq(productIds) },
    skip: subscriptions.length === 0,
  });

  let TabbedComponent;
  switch (activeTab) {
    case ACCOUNT_TABS.ACCOUNT_DETAILS: {
      TabbedComponent = (
        <AccountDetailsSection
          isGuest={isGuest}
          parent={parent}
          cardQuery={cardQuery}
          paymentsQuery={paymentsQuery}
          subscriptionsQuery={subscriptionsQuery}
          studentsQuery={studentsQuery}
          updateFormValue={updateFormValue}
          updateFormState={updateFormState}
          resetForm={resetForm}
        />
      );
      break;
    }
    case ACCOUNT_TABS.PREFERENCES: {
      TabbedComponent = <PreferencesSection />;
      break;
    }
    case ACCOUNT_TABS.CODE_OF_CONDUCT: {
      TabbedComponent = <CodeOfConductSection />;
      break;
    }
    default: {
      TabbedComponent = <div></div>;
      break;
    }
  }

  return (
    <div className="py-10 px-20 text-j-dark-600 flex flex-row justify-center">
      <div className="flex flex-col w-5/6">
        <div className="text-3xl font-bold mb-4">My Account</div>
        <div className="flex flex-row mb-2 gap-x-2">
          {_.map(_.toPairs(ACCOUNT_TABS), ([key, displayName]) => (
            <NewButton
              className="text-j-dark-600"
              key={key}
              disabled={activeTab === displayName}
              variant="minimal"
              onClick={() => setActiveTab(ACCOUNT_TABS[key])}
            >
              {displayName}
            </NewButton>
          ))}
        </div>
        <div>{TabbedComponent}</div>
        <FormStateModal
          cardQuery={cardQuery}
          subscriptionsQuery={subscriptionsQuery}
          paymentsQuery={paymentsQuery}
          productsQuery={productsQuery}
          availabilityQuery={availabilityQuery}
          studentsQuery={studentsQuery}
          coursesQuery={coursesQuery}
          parent={parent}
          formState={formState}
          formValues={formValues}
          updateFormState={updateFormState}
          updateFormValue={updateFormValue}
          resetForm={resetForm}
        />
      </div>
    </div>
  );
};

export default LearnerAccount;
