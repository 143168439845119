import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import Auth from 'modules/Auth';
import { errorLink } from './errors';

const API_URL = process.env.REACT_APP_API_URL;
if (API_URL === undefined) {
  throw new Error('env variable REACT_APP_API_URL must be defined');
}

function createApolloClient() {
  const httpLink = createHttpLink({
    uri: `${API_URL}/graphql`,
  });
  const authLink = setContext((_, { headers }) => {
    const token = Auth.getToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const inMemoryCache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          studentProjects: {
            keyArgs: ['query'],
            merge(existing, incoming) {
              // simple merge policy without any sophisticated logic
              // if we need to do more complex pagination, we'll need to improve this
              return existing
                ? {
                    ...incoming,
                    items: [...existing.items, ...incoming.items],
                  }
                : incoming;
            },
          },
          studentProjectById(existingData, { args, toReference }) {
            return existingData !== undefined
              ? existingData
              : toReference({ __typename: 'StudentProject', id: args?.id });
          },
          openChallengeById(existingData, { args, toReference }) {
            return existingData !== undefined
              ? existingData
              : toReference({ __typename: 'OpenChallenge', id: args?.id });
          },
        },
      },
    },
  });
  return new ApolloClient({
    cache: inMemoryCache,
    link: authLink.concat(errorLink.concat(httpLink)),
  });
}

const apolloClient = createApolloClient();

export default apolloClient;
