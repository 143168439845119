// for instructor's rescheduling requests (created by instructors or admins)

export const MAX_DAYS_IN_ADVANCE = 90;
export const MAX_APPT_OPTIONS_TO_FETCH = 1000;

// for recurring reschedule requests created / edited by admins

/**
 * We really just want the last 14 days worth of appointments, but we round up to 15 here so we can
 * avoid dealing with timezone issues. The result is that we guarantee that we'll fetch the
 * past 14 days worth of appointments, but occasionally may get appointments from 15 days ago too.
 */
export const NUM_PAST_DAYS_TO_FETCH_APPT_OPTIONS = 15;

// for parent-managed rescheduling

// this needs to match or be greater than our Acuity setting for 'canceling or changing appointments' found here: https://secure.acuityscheduling.com/preferences.php?tab=calendars&hnav=restrictions
export const MIN_HOURS_IN_ADVANCE_FOR_PARENT_RESCHEDULING = 22;
