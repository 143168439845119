import React from 'react';
import styled from 'styled-components/macro';
import { Chevron } from 'components/Icons';

const BackButtonContainer = styled.button`
  background: #8272d9;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: none;
  margin-left: 0.45rem;
  display: flex;
  align-self: center;
  align-items: center;
  transition-duration: 0.2s;
  padding: 3px 11px 3px 4px;
  transform: translateY(-3px);
  & .icon {
    background: #7566c9;
    border-radius: 3px;
    padding: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    margin: 0 0.5rem 0 0;
  }
  & .icon.chevron polyline {
    stroke: white;
  }

  & .text-container {
    width: 100%;
    text-align: center;
    letter-spacing: 0px;
    transform: translateY(-1px);
    padding-top: 1px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const BackButton: React.FC<React.HTMLProps<HTMLButtonElement>> = props => (
  <BackButtonContainer onClick={(e: any) => props.onClick?.(e)}>
    <div className="icon-container">
      <Chevron orientation="left" />
    </div>
    <span className="text-container">{props.children}</span>
  </BackButtonContainer>
);

export default BackButton;
