import React, { useEffect, useState, useCallback } from 'react';
import { JuniIDE } from 'components/jide';
import CustomProjectService from 'services/custom_projects';
import { Loading } from 'components/ui';
import { COURSE_TYPES } from 'constants/subjects';
import { IUpdateProjectNameParams, IValidationResponse } from './models';
import UnauthorizedMessage from './UnauthorizedMessage';
import { basePlaygroundRoute } from './utils';
import { SUPPORTED_PLAYGROUND_PROJECTS } from './Playground';

interface IPlaygroundProjectWrapper {
  idKey: string;
  projectName: string;
  projectId: string;
  projectType: string;
  jideUser: {
    _id: string;
    type: string;
    firstName: string;
    lastName: string;
    username?: string;
  };
  returnToPlayground: () => void;
  updateProjectName: (
    params: IUpdateProjectNameParams,
  ) => Promise<IValidationResponse | undefined>;
  setHideNavBar: () => void;
}
const PlaygroundProjectWrapper: React.FC<IPlaygroundProjectWrapper> = props => {
  const [projectAuth, setProjectAuth] = useState<undefined | boolean>(undefined);
  const idLookup: {
    [id: string]: {
      children: Array<string>;
      displayName: string;
      id: string;
      properties: {
        isCustomProject: boolean;
        isProject: boolean;
        studentId: string;
      };
      type: string;
    };
  } = {
    [props.projectId]: {
      children: [],
      displayName: props.projectName,
      id: props.projectId,
      properties: {
        isCustomProject: true,
        isProject: true,
        studentId: props.jideUser._id,
      },
      type: 'project',
    },
  };

  const checkThatUserOwnsProject = useCallback(() => {
    const getProjects = async () => {
      const existingProjects = await CustomProjectService.list({
        _id: props.projectId,
        [props.idKey]: props.jideUser._id,
        displayName: props.projectName,
      });

      if (
        existingProjects.filter(
          project =>
            project._id === props.projectId &&
            project.displayName.trim() === props.projectName.trim(),
        ).length > 0 &&
        SUPPORTED_PLAYGROUND_PROJECTS.includes(props.projectType)
      ) {
        setProjectAuth(true);
      } else {
        setProjectAuth(false);
      }
    };

    getProjects();
  }, [
    props.idKey,
    props.jideUser._id,
    props.projectId,
    props.projectName,
    props.projectType,
  ]);

  useEffect(() => {
    checkThatUserOwnsProject();
  }, [
    checkThatUserOwnsProject,
    props.projectId,
    props.projectType,
    props.projectName,
  ]);

  if (projectAuth === undefined) {
    return <Loading />;
  }
  if (projectAuth === false) {
    return (
      <UnauthorizedMessage
        playgroundLink={basePlaygroundRoute(
          props.jideUser._id,
          props.jideUser.type === 'student' ? 'learner' : 'teacher',
        )}
      />
    );
  }

  return (
    <JuniIDE
      courseTypes={[COURSE_TYPES.COMPUTER_SCIENCE, COURSE_TYPES.MATHEMATICS]}
      initNav={{
        // todo change student key to 'user', or add separate 'teacher' key'. As is, it's too coupled to the idea that only students create projects.
        student: props.jideUser._id,
        course: 'playground',
        module: 'playground',
        project: props.projectId,
      }}
      jideUser={props.jideUser}
      idLookup={idLookup}
      saveRecentProjectHistory
      playgroundProject={{
        // This ternary defaults all whiteboard projects to the math environment until we create a dedicated env for whiteboards
        projectType: props.projectType === 'whiteboard' ? 'math' : props.projectType,
        userType: props.jideUser.type,
        projectId: props.projectId,
      }}
      returnToPlayground={props.returnToPlayground}
      updateProjectName={props.updateProjectName}
      setHideNavBar={props.setHideNavBar}
    />
  );
};

export default PlaygroundProjectWrapper;
