import React, { FC, useState } from 'react';
import { Input, NewButton as Button } from 'core-components';
import { validateCoupon } from 'services/payment';
import SpinnerV2 from 'components/SpinnerV2';
import { StripeCoupon } from 'generated/graphql';
import { STRIPE_BOOTCAMP_PRODUCT_KEY } from 'constants/subscription_plans';

interface CouponFieldProps {
  isBootcampSignup: boolean;
  setCoupon: React.Dispatch<React.SetStateAction<StripeCoupon | undefined>>;
}

const CouponField: FC<CouponFieldProps> = ({ setCoupon, isBootcampSignup }) => {
  const [couponInput, setCouponInput] = useState('');
  const [couponError, setCouponError] = useState<string | undefined>(undefined);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  return (
    <div className="flex flex-col gap-2 mb-4">
      <div className="text-md text-j-dark-400">Coupon</div>
      <div className="flex flex-row">
        <div className="mr-2 w-full">
          <Input
            size="xsmall"
            fullWidth
            placeholder="Coupon code (case sensitive)"
            name="coupon"
            value={couponInput}
            message={couponError}
            valid={couponError ? false : undefined}
            onChange={event => setCouponInput(event.target.value)}
          />
        </div>
        <Button
          size="xsmall"
          onClick={async () => {
            setIsLoadingCoupon(true);
            setCouponError(undefined);
            try {
              const coupon = await validateCoupon(couponInput);
              if (
                (coupon.applies_to?.products?.includes(
                  STRIPE_BOOTCAMP_PRODUCT_KEY,
                ) &&
                  !isBootcampSignup) ||
                (!coupon.applies_to?.products?.includes(
                  STRIPE_BOOTCAMP_PRODUCT_KEY,
                ) &&
                  isBootcampSignup)
              ) {
                setCouponError('This coupon cannot be applied to this product.');
              } else {
                setCoupon(coupon);
              }
            } catch (error) {
              setCouponError(`Could not find coupon: ${couponInput}`);
            }
            setIsLoadingCoupon(false);
          }}
        >
          {isLoadingCoupon ? <SpinnerV2 /> : 'Apply'}
        </Button>
      </div>
    </div>
  );
};

export default CouponField;
