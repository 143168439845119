import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import SpinnerV2 from 'components/SpinnerV2';
import { InputField } from 'components/ui';
import { Button, ModalWindow } from 'core-components';

import { setUsername } from 'services/juniverse';
import { parseError } from 'utils/errors';
import { validateUsername } from 'utils/validation';

interface UsernameFormValue {
  username: string;
}

const validate = (values: UsernameFormValue) => {
  const { username } = values;
  if (validateUsername(username)) {
    const errors = { username: validateUsername(username) };
    return errors;
  }
  return {};
};

const submitUsername = async (
  desiredUsername: string,
  userType: string,
  userId: string,
) => {
  const { success, error } = await setUsername(userId, desiredUsername, userType);
  const errorMessage = error
    ? parseError(error, `Sorry, that didn't work.`)
    : 'Oops! Something went wrong. Please try again later.';
  if (error || !success) {
    return {
      sucess: false,
      error: errorMessage,
    };
  }
  return { success: true, error: '' };
};

interface UsernameCreatorProps {
  studentId?: string;
  instructorUserId?: string;
  usernameCallback: any;
  closeModal: () => void;
  isOpen: boolean;
}

const UsernameCreator: React.FC<UsernameCreatorProps> = ({
  studentId,
  instructorUserId,
  closeModal,
  isOpen,
  usernameCallback,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const id = studentId || instructorUserId;

  if (!id) {
    return null;
  }

  const handleCreateUsername = async (
    values: UsernameFormValue,
    { setErrors }: { setErrors: any },
  ) => {
    setSubmitting(true);
    const { username } = values;
    const { success, error } = await submitUsername(
      username,
      studentId ? 'student' : 'teacher',
      id,
    );
    if (!success) {
      setErrors({ username: error });
    } else {
      usernameCallback(username);
    }
    setSubmitting(false);
  };

  return (
    <ModalWindow
      closeModal={closeModal}
      isOpen={isOpen}
      description="Let's get started by creating your username. This will be your username for all Juni Clubs that you join, as well as your Juni profile!"
      title="Create your username"
      maxWidth="36rem"
    >
      <Formik
        initialValues={{ username: '' }}
        onSubmit={handleCreateUsername}
        validate={validate}
      >
        <Form className="flex flex-col">
          <label className="font-semibold text-sm leading-9">
            Create a username
          </label>
          <div className="pt-1">
            <InputField.Formik name="username" placeholder="Your username..." />
          </div>
          <div className="flex justify-end pt-5">
            <Button hasArrowIcon type="submit" className="w-80">
              {submitting ? <SpinnerV2 /> : 'Set username!'}
            </Button>
          </div>
        </Form>
      </Formik>
    </ModalWindow>
  );
};

export default UsernameCreator;
