import { Icon } from 'core-components';
import { JUNI_LINKS } from './links';

export const SUBJECTS = {
  COMPUTER_SCIENCE: 'computer_science',
  MATHEMATICS: 'mathematics',
  ENGLISH: 'english',
  INVESTING: 'investing',
  SCIENCE: 'science',
} as const;

export const SUBJECT_DISPLAY_NAMES = {
  [SUBJECTS.COMPUTER_SCIENCE]: 'Computer Science',
  [SUBJECTS.MATHEMATICS]: 'Mathematics',
  [SUBJECTS.ENGLISH]: 'English',
  [SUBJECTS.INVESTING]: 'Investing & Entrepreneurship',
  [SUBJECTS.SCIENCE]: 'Science',
} as const;

// Temporarily creates an alternate lookup table for places
// we want to show "Science, Investing, and Entrepreneurship"
// for the jan2022 release
export const SUBJECT_DISPLAY_NAMES_TEMP = {
  [SUBJECTS.COMPUTER_SCIENCE]: 'Computer Science',
  [SUBJECTS.MATHEMATICS]: 'Mathematics',
  [SUBJECTS.ENGLISH]: 'English',
  [SUBJECTS.INVESTING]: 'Investing, and Entrepreneurship',
  [SUBJECTS.SCIENCE]: 'Science',
} as const;

export const ORDERED_SUBJECTS = [
  SUBJECTS.COMPUTER_SCIENCE,
  SUBJECTS.MATHEMATICS,
  SUBJECTS.ENGLISH,
  SUBJECTS.INVESTING,
  SUBJECTS.SCIENCE,
] as const;

// TODO not adding science to ORDERED_SUBJECTS because that will make it appear in several selection lists
export type SUBJECT_TYPE = typeof ORDERED_SUBJECTS[number] | typeof SUBJECTS.SCIENCE;

export const WEBSITE_SUBJECT_LINKS: { [subject in SUBJECT_TYPE]: string } = {
  [SUBJECTS.COMPUTER_SCIENCE]: `${JUNI_LINKS.MARKETING}/coding-for-kids/`,
  [SUBJECTS.MATHEMATICS]: `${JUNI_LINKS.MARKETING}/math-courses/`,
  [SUBJECTS.ENGLISH]: `${JUNI_LINKS.MARKETING}/communications-for-kids/`,
  [SUBJECTS.INVESTING]: `${JUNI_LINKS.MARKETING}/investing-entrepreneurship/`,
  [SUBJECTS.SCIENCE]: `${JUNI_LINKS.MARKETING}/science-for-kids/`,
} as const;

// Ultimate goal is to replace all 'courseType' fields / variables with 'subject' fields which use the above subject strings as the values.
// For now, the keys in this COURSE_TYPES mapping must identically match the keys used in the SUBJECTS mapping above
export const COURSE_TYPES = {
  COMPUTER_SCIENCE: 'code',
  MATHEMATICS: 'math',
  ENGLISH: 'english',
  INVESTING: 'investing',
  SCIENCE: 'science',
} as const;
export const SCIENCE_COURSE_TYPE = 'science'; // jan2022 temporary var

export const SUBJECT_COURSE_TYPES: { [subject in SUBJECT_TYPE]: string } = {
  [SUBJECTS.COMPUTER_SCIENCE]: COURSE_TYPES.COMPUTER_SCIENCE,
  [SUBJECTS.MATHEMATICS]: COURSE_TYPES.MATHEMATICS,
  [SUBJECTS.ENGLISH]: COURSE_TYPES.ENGLISH,
  [SUBJECTS.INVESTING]: COURSE_TYPES.INVESTING,
  [SUBJECTS.SCIENCE]: COURSE_TYPES.SCIENCE,
} as const;

export const COURSE_TYPE_SUBJECTS = {
  [COURSE_TYPES.COMPUTER_SCIENCE]: SUBJECTS.COMPUTER_SCIENCE,
  [COURSE_TYPES.MATHEMATICS]: SUBJECTS.MATHEMATICS,
  [COURSE_TYPES.ENGLISH]: SUBJECTS.ENGLISH,
  [COURSE_TYPES.INVESTING]: SUBJECTS.INVESTING,
  [COURSE_TYPES.SCIENCE]: SUBJECTS.SCIENCE,
} as const;

export const SUBJECT_TO_METADATA_KEY: {
  [subject in SUBJECT_TYPE | 'science']: string;
} = {
  [SUBJECTS.COMPUTER_SCIENCE]: 'cs_weeklyFrequency',
  [SUBJECTS.MATHEMATICS]: 'math_weeklyFrequency',
  [SUBJECTS.ENGLISH]: 'english_weeklyFrequency',
  [SUBJECTS.INVESTING]: 'investing_weeklyFrequency',
  [SUBJECTS.SCIENCE]: 'science_weeklyFrequency',
};

export const COURSE_TYPE_TO_ICON: {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  [COURSE_TYPES.COMPUTER_SCIENCE]: Icon.Code,
  [COURSE_TYPES.MATHEMATICS]: Icon.Math,
  [COURSE_TYPES.ENGLISH]: Icon.English,
  [COURSE_TYPES.INVESTING]: Icon.Investing,
  [SCIENCE_COURSE_TYPE]: Icon.Atom, // jan2022 temporary var
};

// TODO: deprecate once subjects have icons in DB
export const CURRICULUM_COLLECTION_LOOKUP = {
  CurriculumCourse: ['computer_science_curriculum_editor', Icon.Code],
  MathCurriculumCourse: ['mathematics_curriculum_editor', Icon.Math],
  EnglishCurriculumCourse: ['english_curriculum_editor', Icon.English],
  InvestingCurriculumCourse: ['investing_curriculum_editor', Icon.Investing],
  unknown: ['', Icon.QuestionMark],
};
