import eventTypes from './eventTypes';
import postStudentEvent from './postStudentEvent';
import getStudentEvents from './getStudentEvents';

const {
  JIDE_PROJECT_SAVED,
  JIDE_PROJECT_SHARED,
  VIDEO_STARTED,
  VIDEO_PAUSED,
  VIDEO_ENDED,
  WHITEBOARD_EDITED,
  WHITEBOARD_SAVED,
  WHITEBOARD_SKIPPED,
} = eventTypes;

const postJideProjectSaved = data => {
  const { studentId, courseId, projectId, isCustomProject } = data;

  const corePayload = { studentId, courseId };

  const payload = isCustomProject
    ? {
        ...corePayload,
        customProjectId: projectId,
      }
    : {
        ...corePayload,
        projectId,
      };

  postStudentEvent(payload, JIDE_PROJECT_SAVED);
};

const postJideProjectShared = data => {
  const { studentId, courseId, projectId, isCustomProject, isProjectPublic } = data;

  const corePayload = {
    studentId,
    courseId,
    isProjectPublic,
  };

  const payload = isCustomProject
    ? {
        ...corePayload,
        customProjectId: projectId,
      }
    : {
        ...corePayload,
        projectId,
      };

  postStudentEvent(payload, JIDE_PROJECT_SHARED);
};

const postVideoStarted = data => postStudentEvent(data, VIDEO_STARTED);

const postVideoPaused = data => postStudentEvent(data, VIDEO_PAUSED);

const postVideoEnded = data => postStudentEvent(data, VIDEO_ENDED);

const postWhiteboardEdited = data => postStudentEvent(data, WHITEBOARD_EDITED);

const postWhiteboardSaved = data => postStudentEvent(data, WHITEBOARD_SAVED);

const postWhiteboardSkipped = data => postStudentEvent(data, WHITEBOARD_SKIPPED);

export {
  getStudentEvents,
  postJideProjectSaved,
  postJideProjectShared,
  postVideoStarted,
  postVideoPaused,
  postVideoEnded,
  postWhiteboardEdited,
  postWhiteboardSaved,
  postWhiteboardSkipped,
};
