import React, { useState, FC } from 'react';
import styled from 'styled-components';
import useClickOutside from 'hooks/outside';
import { JuniClubType } from 'generated/graphql';
import { Button } from 'core-components';
import { PencilIcon, LeaveIcon } from 'components/Icons';
import { JuniClubMembershipInfoPlusBadgeType } from 'app/clubs/MyClubsTypes';
import LeaveClubModal from '../LeaveClubModal';
import ClubCreator from '../ClubCreator';

export const OptionsMenu = styled.div.attrs({
  className: 'absolute bg-white flex flex-col rounded-lg z-50',
})<any>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  top: ${props => (props.top ? props.top : '2.75rem')};
  right: ${props => (props.right ? props.right : '0')};
  border: 2px solid #e3f3fc;
`;

export const Option = styled.div.attrs({
  className: 'text-black cursor-pointer p-2 flex flex-row items-center',
})`
  color: #ad3f3f;
  :hover {
    background-color: #e3f3fc;
  }
  :not(:last-child) {
    border-bottom: 2px solid #e3f3fc;
  }
`;
export const OptionText = styled.span.attrs({
  className: 'text-left px-4 font-bold whitespace-nowrap',
})``;

interface ClubOptionsProps {
  juniClub: JuniClubType;
  studentId?: string;
  instructorUserId?: string;
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
}
const ClubOptions: FC<ClubOptionsProps> = ({
  juniClub,
  studentId,
  instructorUserId,
  currentClubMemberState,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const badgeName = currentClubMemberState?.badgeInfo.name;
  const canModifyClub =
    badgeName && ['advisor', 'moderator', 'leader'].includes(badgeName);
  useClickOutside(menuRef, () => {
    setMenuOpen(false);
  });
  return (
    <div className="relative" ref={menuRef}>
      <LeaveClubModal
        isOpen={leaveOpen}
        handleClose={() => setLeaveOpen(false)}
        studentId={studentId}
        instructorUserId={instructorUserId}
        juniClub={juniClub}
      />
      <ClubCreator
        studentId={studentId}
        instructorUserId={instructorUserId}
        modalOpen={editOpen}
        handleClose={() => {
          setEditOpen(false);
        }}
        requiresRefresh={false}
        juniClub={juniClub}
      />
      <Button variant="transparent" onClick={() => setMenuOpen(!menuOpen)}>
        ...
      </Button>
      <OptionsMenu visible={menuOpen}>
        <Option onClick={() => setLeaveOpen(true)}>
          <LeaveIcon />
          <OptionText>Leave Club</OptionText>
        </Option>
        {canModifyClub && (
          <Option onClick={() => setEditOpen(true)}>
            <PencilIcon style={{ fill: '#ad3f3f', padding: 0 }} />
            <OptionText>Edit Club</OptionText>
          </Option>
        )}
      </OptionsMenu>
    </div>
  );
};

export default ClubOptions;
