import { DiscountData } from '../types';

export const calculateDiscountedPriceWithCoupon = (
  fullPriceInCents: number,
  selectedDiscount?: DiscountData,
) =>
  Math.round(
    Math.max(
      selectedDiscount
        ? selectedDiscount.discountType === 'percent_off'
          ? fullPriceInCents * (1 - selectedDiscount.discount / 100)
          : fullPriceInCents - selectedDiscount.discount
        : fullPriceInCents,
      0,
    ),
  );
