import React from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';

export interface AnnotationsBarHeaderProps {
  /**
   * Callback for the Close icon click event.
   */
  onClose: () => void;
  /**
   * Optional styling.
   */
  className?: string;
  /**
   * Optional inline styling
   */
  style?: React.CSSProperties;
}

const AnnotationsBarHeader: React.FC<AnnotationsBarHeaderProps> = ({
  onClose,
  className,
  style = {},
}) => (
  <div
    className={classNames(
      className,
      'flex justify-between items-center px-6 py-5 box-border',
      'bg-j-gray-200',
      'border-0 border-solid border-j-dark-100 border-r border-b',
      'font-graphik text-j-dark-600',
    )}
    style={style}
  >
    <div className="text-sm leading6 font-semibold">Comments</div>
    <Icon.Times onClick={onClose} className="cursor-pointer" />
  </div>
);

export default AnnotationsBarHeader;
