import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'core-components';
import { validateClubName, validateClubDescription } from 'utils/validation';
import _ from 'lodash';
import classNames from 'classnames';
import { ColorType } from 'app/clubs/MyClubsTypes';
import { ViewModalProps } from './ClubCreator';
import { ColorSelector } from './ColorSelector';

import {
  ForcedWidth,
  ButtonRow,
  ResponsiveRowWrapper,
  DetailsFormWrapper,
  StyledError,
  Label,
} from './styles';

export interface ClubDetailsFormValues {
  clubName: string;
  description: string;
  color: string;
}

/* A helper function to allow the ColorSelector to be tied into the ClubDetails Formik state. */
const updateFormikColor = (
  values: ClubDetailsFormValues,
  setValues: (values: ClubDetailsFormValues, shouldValidate?: boolean) => void,
  color: ColorType,
) => {
  setValues({ ...values, color });
};
interface ClubDetailsProps extends ViewModalProps {
  changeColor: React.Dispatch<React.SetStateAction<ColorType | undefined>>;
  setClubDetails: React.Dispatch<React.SetStateAction<ClubDetailsFormValues>>;
  initialClubValues: ClubDetailsFormValues;
}
export const ClubDetails: React.FC<ClubDetailsProps> = ({
  changeColor,
  setClubDetails,
  visible,
  changeViewState,
  initialClubValues,
  juniClub,
}) => {
  const validate = (values: ClubDetailsFormValues) => {
    const clubNameError = validateClubName(values.clubName);
    const descriptionError = validateClubDescription(values.description);
    const colorError = !values.color ? 'Please select a color.' : '';
    setClubDetails(values);
    if (clubNameError || descriptionError || colorError) {
      return {
        clubName: clubNameError,
        description: descriptionError,
        color: colorError,
      };
    }
    return {};
  };

  const handleSubmit = async (values: ClubDetailsFormValues) => {
    changeViewState('next');
    setClubDetails(values);
  };
  return (
    <div style={{ display: !visible ? 'none' : '' }}>
      <DetailsFormWrapper>
        <Formik
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={initialClubValues}
        >
          {({ setValues, values, errors, touched }) => (
            <Form>
              <ForcedWidth />
              <ResponsiveRowWrapper>
                <div className="w-full mr-5">
                  <Label className="ui-field-label">Club Name</Label>
                  <Field
                    className={classNames('juni-field h3', {
                      'juni-field-error': errors.clubName && touched.clubName,
                    })}
                    name="clubName"
                    placeholder="Enter a name for this club..."
                    disabled={!!juniClub}
                  />
                  {errors.clubName && touched.clubName ? (
                    <ErrorMessage name="clubName">
                      {msg => <StyledError>{msg}</StyledError>}
                    </ErrorMessage>
                  ) : undefined}
                </div>
                <div className="w-full">
                  <Label className="ui-field-label">Club Color</Label>
                  <ColorSelector
                    changeColor={changeColor}
                    updateFormikColor={_.curry(updateFormikColor)(values)(setValues)}
                    currentColor={values.color as ColorType}
                    error={errors.color || ''}
                    touched={touched.color || false}
                  />
                  {errors.color && touched.color ? (
                    <ErrorMessage name="color">
                      {msg => <StyledError>{msg}</StyledError>}
                    </ErrorMessage>
                  ) : undefined}
                </div>
              </ResponsiveRowWrapper>
              <div>
                <Label className="ui-field-label">Description</Label>
                <Field
                  as="textarea"
                  rows={10}
                  className={classNames('w-full juni-textarea', {
                    'juni-textarea-error': errors.description && touched.description,
                  })}
                  name="description"
                  placeholder="Enter a description for this club..."
                />
                {errors.description && touched.description ? (
                  <ErrorMessage name="description">
                    {msg => <StyledError>{msg}</StyledError>}
                  </ErrorMessage>
                ) : undefined}
              </div>
              <ButtonRow oneButton>
                <Button type="submit">Next</Button>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </DetailsFormWrapper>
    </div>
  );
};
