import React, { FC } from 'react';
import classNames from 'classnames';
import { NewButton as Button } from 'core-components';
import noProjectImg from './no_project.svg';

interface JideEnvEmptyStateProps {
  title?: string;
  description?: string;
}

const JideEnvEmptyState: FC<JideEnvEmptyStateProps> = ({
  title = 'This project cannot be displayed here!',
  description = "If you're the owner, login to view this project in the Juni portal.",
}) => (
  <div
    className={classNames(
      'flex flex-col items-center justify-center',
      'w-full h-full',
      'text-center font-graphik',
      'px-8 box-border',
    )}
  >
    <img
      src={noProjectImg}
      className="w-24 h-24 rounded-full bg-j-gray-200"
      alt="project is not renderable placeholder"
    />
    <div className="max-w-lg">
      <div className="pt-6 pb-3 font-medium text-j-dark-600 text-lg">{title}</div>
      <div className="pb-3 font-normal text-juni-dark-300 ">{description}</div>
      <Button href="/">Login</Button>
    </div>
  </div>
);

export default JideEnvEmptyState;
