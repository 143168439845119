import React from 'react';
import classNames from 'classnames';
import { TailwindHeight } from 'theme/types';

interface ImageProps {
  /**
   * Pass as either a tailwind height string, or a number for exact pixels.
   */
  height: TailwindHeight | number;
  /**
   * src of an img.
   */
  src: string;
  /**
   * alt tag on an image, or title of an iframe video.
   */
  alt?: string;
}

const Image: React.FC<ImageProps> = ({ height, src, alt }) => {
  const heightInPixels = Number.isFinite(height);

  return (
    <div style={{ height: heightInPixels ? height : undefined }}>
      <img
        width="100%"
        height="100%"
        src={src}
        alt={alt}
        className={classNames('object-cover', 'rounded-t-lg', {
          [`h-${height}`]: !heightInPixels && !!height,
        })}
      />
    </div>
  );
};

export default Image;
