import React, { useCallback } from 'react';
import {
  useCreateAndInitializeEnglishProjectMutation,
  useEnglishProjectsQuery,
  CreateAndInitializeEnglishProjectInput,
} from 'generated/graphql';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import JideReadingWriting from './JideReadingWriting';

const FAILED_PROJECT_INITIALIZATION_MSG = `Oops! Something went wrong while trying to initialize your ${
  SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH]
} project. Please refresh the page to try again.`;

interface IProps {
  jideUser: { _id: string; type: string; firstName: string; lastName: string };
  tab: any;
  activeNav: any;
  modalViewMode: boolean;
  shiftKeyIsPressed: boolean;
  idLookup: any;
  recordingMode: any;
  setRecordingMode: any;
  juniverseProjectData: any;
  newHorizons: boolean;
  playgroundProject?: { projectId: string; projectType: string; userType: string };
  schedulingFormat: string;
}

const JideEnvEnglish: React.FC<IProps> = props => {
  const { idLookup, tab, modalViewMode, jideUser } = props;
  const moduleSection = idLookup[tab.tabNav.project];

  const {
    data: englishProjectsData,
    loading: englishProjectsLoading,
    error: englishProjectsError,
    refetch: refetchEnglishProjects,
  } = useEnglishProjectsQuery({
    variables: {
      studentId:
        jideUser.type === 'public' || (modalViewMode && jideUser.type === 'teacher')
          ? tab.tabNav.student
          : jideUser._id,
      moduleSectionId: moduleSection.id,
    },
    fetchPolicy: 'no-cache',
  });
  const englishProjects = englishProjectsData?.englishProjects.items;

  const [
    createAndInitializeEnglishProject,
    { loading: isCreatingNewProject },
  ] = useCreateAndInitializeEnglishProjectMutation({
    errorPolicy: 'all',
  });

  const getDataForNewProject: (
    referenceTextSetId?: string,
    writingPromptId?: string,
  ) => CreateAndInitializeEnglishProjectInput = useCallback(
    (referenceTextSetId, writingPromptId) => {
      const newEnglishProjectData: CreateAndInitializeEnglishProjectInput = {
        projectType: moduleSection.type,
        studentId: jideUser._id,
        moduleSectionId: moduleSection.id,
      };
      if (referenceTextSetId)
        newEnglishProjectData.referenceTextSetId = referenceTextSetId;
      if (writingPromptId) newEnglishProjectData.writingPromptId = writingPromptId;
      return newEnglishProjectData;
    },
    [jideUser._id, moduleSection.id, moduleSection.type],
  );

  const createProject = async (
    referenceTextSetId?: string,
    writingPromptId?: string,
  ) => {
    const newEnglishProjectData = getDataForNewProject(
      referenceTextSetId,
      writingPromptId,
    );
    const createRes = await createAndInitializeEnglishProject({
      variables: { input: newEnglishProjectData },
    });
    if (createRes.errors) {
      console.log(createRes.errors);
      alert(FAILED_PROJECT_INITIALIZATION_MSG);
      return;
    }
    if (createRes.data?.createAndInitializeEnglishProject.englishProject) {
      await refetchEnglishProjects();
    }
  };

  const createAndInitializeProject = () => {
    const newEnglishProjectData = getDataForNewProject(
      undefined,
      moduleSection?.properties.writingPromptId,
    );
    return createAndInitializeEnglishProject({
      variables: { input: newEnglishProjectData },
    }).then(async createRes => {
      if (createRes.errors) {
        console.log(createRes.errors);
        alert(FAILED_PROJECT_INITIALIZATION_MSG);
        throw createRes.errors;
      }
    });
  };

  return (
    <JideReadingWriting
      {...props}
      projects={englishProjects}
      projectsData={englishProjectsData}
      projectsLoading={englishProjectsLoading}
      projectsError={englishProjectsError}
      refetchProjects={refetchEnglishProjects}
      isCreatingNewProject={isCreatingNewProject}
      createProject={createProject}
      createAndInitializeProject={createAndInitializeProject}
    />
  );
};

export default JideEnvEnglish;
