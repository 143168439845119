import React from 'react';
import PropTypes from 'prop-types';

import './modal_v2.css';
import { Remove } from '../Icons';

const ModalV2 = ({
  className,
  closeModal,
  coverNav,
  show,
  children,
  withExitButton,
}) => {
  const stopPropagation = e => {
    e.stopPropagation();
  };
  return (
    <div
      className={`modal-v2-container ${className} ${coverNav ? 'cover-nav' : ''} ${
        !show ? 'hidden' : ''
      }`}
      onClick={closeModal}
    >
      <div className="modal-content" onClick={stopPropagation}>
        {withExitButton && (
          <div className="remove-icon" onClick={closeModal}>
            <Remove />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
ModalV2.defaultProps = {
  className: '',
  show: false,
  coverNav: true,
  withExitButton: false,
};
ModalV2.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  coverNav: PropTypes.bool,
  withExitButton: PropTypes.bool,
};
export default ModalV2;
