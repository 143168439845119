import React from 'react';
import FilterCheckbox from '../FilterCheckbox';
import SelectDropdown, { SelectDropdownProps, Option } from '../SelectDropdown';

export interface SelectButtonDropdownProps<T>
  extends Omit<SelectDropdownProps<T>, 'children'> {
  /*
  Button content
  */
  buttonText: string;
}

const SelectCheckboxDropdown = <T extends unknown>({
  buttonText,
  ...props
}: SelectButtonDropdownProps<T>) => (
  <>
    <SelectDropdown<T> {...props} buttonContent={buttonText}>
      {(options: Option<T>[], handleSelect: (value: T) => void, selected: T[]) => (
        <div className="w-full grid grid-cols-3 content-center justify-items-center justify-center">
          {options.map(({ value, label }) => (
            <FilterCheckbox
              key={String(value)}
              onClick={() => handleSelect(value)}
              selected={selected.some(v => v === value)}
              width="2.75rem"
              height="2.75rem"
              className="m-2"
            >
              {label}
            </FilterCheckbox>
          ))}
        </div>
      )}
    </SelectDropdown>
  </>
);

export default SelectCheckboxDropdown;
