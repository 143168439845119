import React, { useState, useEffect } from 'react';
import { EnglishAnswerSubmissionFragment } from 'generated/graphql';
import { noop } from 'lodash';
import { Arrow, CheckmarkIcon, XIcon } from 'components/Icons';
import styled from 'styled-components/macro';
import { ActivityInstructions, SelectionBox } from 'components/jide';
import { NewButton, TextArea } from 'core-components';
import classNames from 'classnames';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const newHorizonsTopSpacing = 'mt-6';

const EnglishProblem: React.FC<{
  _id: string;
  text: string;
  problemType: 'instructional' | 'multiple_choice' | 'plain_text';
  mcAnswerOptions: { answerChoiceText: string }[];
  mcCorrectAnswer: { solutionText: string }[];
  answerSubmission?: EnglishAnswerSubmissionFragment;
  handleSubmitAnswer: (
    problemId: string,
    submittedValues: string[],
  ) => Promise<void>;
  isSubmitting: boolean;
  newHorizons?: boolean;
  moduleQuestionSet?: boolean;
  first?: boolean;
  last?: boolean;
  onInstructionalProblemNextClick?: () => void;
}> = ({
  _id,
  text,
  problemType,
  mcAnswerOptions,
  mcCorrectAnswer,
  answerSubmission,
  handleSubmitAnswer,
  isSubmitting,
  newHorizons = false,
  moduleQuestionSet = undefined,
  first = undefined,
  last = undefined,
  onInstructionalProblemNextClick = noop,
}) => {
  const [mcAnswerSelection, setMcAnswerSelection] = useState<string[]>([]);
  const [shortResponse, setShortResponse] = useState<string>(
    problemType === 'plain_text' &&
      answerSubmission?.submittedValues &&
      answerSubmission.submittedValues.length > 0
      ? answerSubmission.submittedValues[0] || ''
      : '',
  );
  const [showCorrectness, setShowCorrectness] = useState<boolean>(
    problemType === 'multiple_choice' && !!answerSubmission,
  );

  useEffect(() => {
    const submittedValues = answerSubmission?.submittedValues;
    if (problemType === 'plain_text') {
      if (submittedValues && submittedValues.length > 0) {
        setShortResponse(submittedValues[0]);
      }
    } else if (problemType === 'multiple_choice') {
      if (submittedValues) {
        setMcAnswerSelection(submittedValues);
        setShowCorrectness(true);
      }
    }
  }, [answerSubmission, problemType]);

  const submittedValues = answerSubmission?.submittedValues;
  const correctAnswers: string[] = mcCorrectAnswer.map(
    answer => answer.solutionText,
  );
  let isCorrect = false;
  let mcAnswerSelectionMatchesSubmission = false;
  if (
    problemType === 'multiple_choice' &&
    submittedValues &&
    submittedValues.length > 0
  ) {
    if (mcCorrectAnswer.length === 0) {
      // any single answer choice is considered correct if no correct answers are set on this problem
      isCorrect = true;
    } else {
      isCorrect =
        submittedValues.length === mcCorrectAnswer.length &&
        !submittedValues.some(value => !correctAnswers.includes(value));
    }
    mcAnswerSelectionMatchesSubmission =
      submittedValues.length === mcAnswerSelection.length &&
      !submittedValues.some(value => !mcAnswerSelection.includes(value));
  }

  const canSelectMultiple = mcCorrectAnswer.length > 1;
  const selectChoice = (choiceText: string) => {
    if (!canSelectMultiple) {
      setMcAnswerSelection([choiceText]);
    } else {
      setMcAnswerSelection(curSelection => {
        if (curSelection.includes(choiceText)) {
          return curSelection.filter(choice => choice !== choiceText);
        }
        return [...curSelection, choiceText];
      });
    }
    setShowCorrectness(false);
  };
  const handleSelectChoice = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isCorrect) return;
    selectChoice(e.currentTarget.value);
  };
  const handleChangeShortResponse = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    setShortResponse(value);
  };
  const onSubmitAnswer = () => {
    if (isSubmitting) return;
    if (problemType === 'plain_text') {
      handleSubmitAnswer(_id, [shortResponse]);
    } else if (problemType === 'multiple_choice' && !isCorrect) {
      handleSubmitAnswer(_id, mcAnswerSelection);
    } else if (problemType === 'instructional') {
      onInstructionalProblemNextClick();
    }
  };

  if (newHorizons) {
    let submitBtnText;
    if (problemType === 'plain_text') {
      submitBtnText = 'Save';
    } else if (problemType === 'multiple_choice') {
      submitBtnText = 'Submit';
    } else if (
      problemType === 'instructional' &&
      moduleQuestionSet &&
      first &&
      !last
    ) {
      // the one special case where the button navigates to the next question.
      submitBtnText = 'Get Started';
    }

    let submitBtnDisabled = isSubmitting;
    if (problemType === 'plain_text' && !isSubmitting) {
      submitBtnDisabled = shortResponse === answerSubmission?.submittedValues[0];
    } else if (problemType === 'multiple_choice' && !isSubmitting) {
      submitBtnDisabled =
        mcAnswerSelection.length === 0 ||
        mcAnswerSelectionMatchesSubmission ||
        isCorrect;
    }

    return (
      <>
        <ActivityInstructions
          instructions={text}
          horizontalPadding="0"
          fullHeight={false}
        />
        {problemType === 'plain_text' && (
          <div className={newHorizonsTopSpacing}>
            <TextArea
              rows={6}
              value={shortResponse}
              onChange={handleChangeShortResponse}
              fullWidth
            />
          </div>
        )}
        {problemType === 'multiple_choice' && (
          <div className={newHorizonsTopSpacing}>
            {mcAnswerOptions.map((opt, i) => {
              const selected = mcAnswerSelection.includes(opt.answerChoiceText);
              let renderSubtextMessage;
              let intent: React.ComponentProps<typeof SelectionBox>['intent'];

              if (showCorrectness && selected) {
                const optionCorrect =
                  correctAnswers.length === 0 ||
                  correctAnswers.includes(opt.answerChoiceText);

                if (optionCorrect) {
                  intent = 'success';
                  renderSubtextMessage = () => 'Correct, well done!';
                } else {
                  intent = 'error';
                  renderSubtextMessage = () => 'Incorrect. Try Again!';
                }
              }

              return (
                <SelectionBox
                  key={`${opt.answerChoiceText}-${_id}`}
                  selected={selected}
                  onClick={() => selectChoice(opt.answerChoiceText)}
                  disabled={isCorrect}
                  className={classNames({ 'mt-4': i > 0 })}
                  intent={intent}
                  renderLeftBadgeContent={() => ALPHABET[i % ALPHABET.length]}
                  renderSubtextMessage={renderSubtextMessage}
                >
                  {opt.answerChoiceText}
                </SelectionBox>
              );
            })}
          </div>
        )}
        {!!submitBtnText && (
          <div className={newHorizonsTopSpacing}>
            <NewButton onClick={onSubmitAnswer} disabled={submitBtnDisabled}>
              {submitBtnText}
            </NewButton>
          </div>
        )}
      </>
    );
  }

  return (
    <StyledEnglishProblem>
      <StyledProblemInstructions
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      {problemType === 'plain_text' && (
        <>
          <StyledShortAnswerTextarea
            rows={6}
            value={shortResponse}
            onChange={handleChangeShortResponse}
          />
          <StyledSubmitButton
            onClick={onSubmitAnswer}
            disabled={
              isSubmitting || shortResponse === answerSubmission?.submittedValues[0]
            }
          >
            Save
            <Arrow orientation="right" />
          </StyledSubmitButton>
        </>
      )}
      {problemType === 'multiple_choice' && (
        <>
          <StyledAnswerOptionsContainer>
            {mcAnswerOptions.map((opt, i) => (
              <StyledMCAnswerChoice
                key={`${opt.answerChoiceText}-${_id}`}
                selected={mcAnswerSelection.includes(opt.answerChoiceText)}
                onClick={handleSelectChoice}
                value={opt.answerChoiceText}
                showCorrectness={showCorrectness}
                isCorrect={
                  correctAnswers.length === 0 ||
                  correctAnswers.includes(opt.answerChoiceText)
                }
                disabled={
                  isCorrect ||
                  (mcAnswerSelection.includes(opt.answerChoiceText) &&
                    !canSelectMultiple)
                }
              >
                <div className="letter">{ALPHABET[i % ALPHABET.length]}</div>
                {opt.answerChoiceText}
              </StyledMCAnswerChoice>
            ))}
          </StyledAnswerOptionsContainer>
          <StyledCorrectnessAndSubmitBtnContainer>
            {!!answerSubmission &&
              showCorrectness &&
              problemType === 'multiple_choice' && (
                <StyledCorrectness isCorrect={isCorrect}>
                  {isCorrect ? <CheckmarkIcon /> : <XIcon />}
                  {isCorrect ? 'Correct!' : 'Incorrect. Try Again!'}
                </StyledCorrectness>
              )}
            <StyledSubmitButton
              onClick={onSubmitAnswer}
              disabled={
                isSubmitting ||
                mcAnswerSelection.length === 0 ||
                mcAnswerSelectionMatchesSubmission ||
                isCorrect
              }
            >
              Submit
              <Arrow orientation="right" />
            </StyledSubmitButton>
          </StyledCorrectnessAndSubmitBtnContainer>
        </>
      )}
    </StyledEnglishProblem>
  );
};

const StyledEnglishProblem = styled.div`
  padding: 1.25rem;
  font-size: 14px;
  color: hsl(202, 33%, 35%);
  flex: 1;
  overflow-y: auto;
  min-height: 0px;
`;
const StyledProblemInstructions = styled.div`
  pre {
    white-space: pre-wrap;
  }
`;
const StyledShortAnswerTextarea = styled.textarea`
  border: 2px solid hsl(201, 100%, 50%);
  background: hsl(205, 100%, 97%);
  padding: 12px;
  margin: 1.25rem 0;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
`;
const StyledAnswerOptionsContainer = styled.div`
  margin-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 2px solid hsl(198, 100%, 93%);
`;
const StyledMCAnswerChoice = styled.button<any>`
  display: flex;
  align-items: center;
  text-transform: none;
  background: hsl(201, 100%, 100%);
  &:disabled {
    background: hsl(201, 100%, 100%);
  }
  color: hsl(202, 33%, 35%);
  border: 2px solid
    ${props => (props.selected ? 'hsl(201, 100%, 50%)' : 'hsl(201, 70%, 89%)')};
  &:disabled {
    color: hsl(202, 33%, 35%);
    border: 2px solid
      ${props => (props.selected ? 'hsl(201, 100%, 50%)' : 'hsl(201, 70%, 89%)')};
  }
  font-size: 14px;
  font-weight: ${props => (props.selected ? 'bold' : '500')};
  border-radius: 8px;
  padding: 0.75rem;
  &:not(:first-child) {
    margin-top: 0.75rem;
  }
  width: 100%;
  .letter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    box-sizing: border-box;
    background: hsl(201, 100%, 92%);
    padding: 6px;
    border-radius: 8px;
    color: hsl(201, 100%, 50%);
    margin-right: 0.75rem;
    font-weight: bold;
  }
  ${props => {
    if (!props.showCorrectness || !props.selected) return '';
    const correctnessColor = props.isCorrect
      ? 'hsl(117, 91%, 37%)'
      : 'hsl(0, 87%, 46%)';
    return `
      border: 2px solid ${correctnessColor};
      color: ${correctnessColor};
      &:disabled {
        border: 2px solid ${correctnessColor};
        color: ${correctnessColor};
      }
      .letter {
        color: ${correctnessColor};
        background: ${props.isCorrect ? 'hsl(120, 51%, 87%)' : 'hsl(359, 69%, 89%)'};
      }
    `;
  }}
`;
const StyledCorrectnessAndSubmitBtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
`;
const StyledSubmitButton = styled.button`
  text-transform: none;
  margin-left: auto;
  font-weight: bold;
  font-family: Open Sans, sans-serif;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 6px 6px 1.25rem;
  border-radius: 8px;
  border: 2px solid hsla(0, 0%, 100%, 0.15);
  .icon {
    margin-left: 1.25rem;
    height: 14px;
    width: 14px;
    padding: 4px;
    background: hsl(201, 100%, 57%);
    border-radius: 6px;
    path {
      fill: #fff;
    }
  }
  &:disabled {
    .icon {
      background: #ccc;
    }
  }
`;
const StyledCorrectness = styled.div<any>`
  display: flex;
  align-items: center;
  color: ${props => (props.isCorrect ? 'hsl(117, 91%, 37%)' : 'hsl(202, 33%, 35%)')};
  font-weight: bold;
  margin-right: 0.75rem;
  .icon {
    margin-right: 0.75rem;
    padding-left: 0;
    path {
      stroke: hsl(0, 87%, 46%);
    }
    polyline {
      stroke: hsl(117, 91%, 37%);
    }
  }
`;

export default EnglishProblem;
