import React, { FC } from 'react';
import useDimensions from 'hooks/dimensions';
import classNames from 'classnames';
import { StarIcon } from 'components/Icons';
import GuestEnrollButton from 'app/learner/components/GuestEnrollButton';

interface IGuestEnrollmentBanner {
  studentId?: string;
}

const GuestEnrollmentBanner: FC<IGuestEnrollmentBanner> = ({ studentId }) => {
  const { width } = useDimensions();
  const mobileView = width <= 920;
  return (
    <div className="w-full flex flex-end">
      <div className="bg-deep-purple-400 w-full flex flex-row font-semibold text-white justify-between flex-wrap py-3 px-3">
        <div className="flex flex-row items-center">
          <div className="flex w-7 h-7 bg-white rounded-md bg-opacity-10">
            <StarIcon white />
          </div>
          <div
            data-cy="guest-enrollment-banner-text"
            className={classNames('ml-2.5', mobileView ? 'leading-5' : 'leading-7')}
          >
            You’re currently logged in as a Guest! To access all account features,
            enroll in a Juni course.
          </div>
        </div>
        <GuestEnrollButton
          className={classNames({ 'mt-3': mobileView })}
          width={mobileView ? 'full' : 'auto'}
          studentId={studentId}
          variant="transparent"
        />
      </div>
    </div>
  );
};

export default GuestEnrollmentBanner;
