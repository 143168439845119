import juniAxios from 'services/axios';

export async function getTeacherSessions(studentId) {
  const teacherSessions = (
    await juniAxios.get(`/learner/teacher_sessions/${studentId}`)
  ).data.data;
  return teacherSessions;
}

export async function getCompletedTeacherSessions(studentId) {
  const teacherSessions = (
    await juniAxios.get(`/learner/teacher_sessions/${studentId}?completed=true`)
  ).data.data;
  return teacherSessions;
}

export async function deleteTeacherSessionLog(acuityId) {
  const deletedSessionLog = await juniAxios.delete(
    `/admin/teacher_sessions/${acuityId}`,
  );
  return deletedSessionLog;
}
