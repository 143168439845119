import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import * as R from 'ramda';
import { compareAsc } from 'date-fns';

import UserContext from 'modules/UserContext';
import { useLoadTruncatedInstructorNamesQuery } from 'generated/graphql';

import student_wave from 'images/student_wave.svg';
import { GuestAccountCTA } from 'app/learner/zero-state';

import { AcuityAppointment, AdditionalClass } from 'models';

import { getAdditionalClassesForLoggedInParent } from 'services/learner/additional_classes';
import { getParentsUpcomingSessions } from 'services/learner/sessions';

import { getLearnerSessionActionType, sessionHasEnded } from 'utils/acuity';

import JuniSpinner from 'components/JuniSpinner';
import PortalSection from 'components/PortalSection';

import * as InstructorAdditionalClassesApi from 'services/instructor/additional_classes';
import { Message } from 'core-components';
import GuestEnrollButton from 'app/learner/components/GuestEnrollButton';

import { MyCalendarProps } from './types';
import { MyCalendarLayout } from './styles';
import CalendarCard from './CalendarCard';
import ScheduleCard from './ScheduleCard';

const MyCalendar: FC<MyCalendarProps> = ({ parent, student, impersonationMode }) => {
  const { user } = useContext(UserContext);
  const isGuest = user?.isGuest || (impersonationMode && parent.isGuest);
  const [upcomingSessions, setUpcomingSessions] = useState<Array<AcuityAppointment>>(
    [],
  );
  const [isLoadingUpcomingSessions, setIsLoadingUpcomingSessions] = useState<
    boolean
  >(!isGuest);

  const [upcomingSessionsError, setUpcomingSessionError] = useState<boolean>(false);

  const [additionalClasses, setAdditionalClasses] = useState<Array<AdditionalClass>>(
    [],
  );
  const [isLoadingAdditionalClasses, setIsLoadingAdditionalClasses] = useState<
    boolean
  >(!isGuest);
  const [additionalClassesError, setAdditionalClassesError] = useState<boolean>(
    false,
  );

  const [isLoadingStudent, setIsLoadingStudent] = useState<boolean>(false);

  const { _id: currStudentId } = student;

  const getUpcomingSessions = useCallback(async () => {
    setUpcomingSessionError(false);
    try {
      const upcomingSessions = await getParentsUpcomingSessions(parent.email);
      setUpcomingSessions(upcomingSessions);
      setIsLoadingUpcomingSessions(false);
    } catch (e) {
      setUpcomingSessionError(true);
      setIsLoadingUpcomingSessions(false);
    }
  }, [parent.email]);

  const getAdditionalClasses = useCallback(async () => {
    setAdditionalClassesError(false);
    try {
      const additionalClasses = impersonationMode
        ? await InstructorAdditionalClassesApi.list({ parentId: parent._id })
        : await getAdditionalClassesForLoggedInParent();
      setAdditionalClasses(additionalClasses);
      setIsLoadingAdditionalClasses(false);
    } catch (e) {
      setIsLoadingAdditionalClasses(false);
      setAdditionalClassesError(true);
    }
  }, [impersonationMode, parent]);

  useEffect(() => {
    if (!isGuest) {
      getAdditionalClasses();
      getUpcomingSessions();
    }
  }, [getAdditionalClasses, getUpcomingSessions, isGuest]);

  const curStudentMakeupClasses = additionalClasses
    .filter(c => c.isMakeupClass && c.isRedeemable && c.studentId === currStudentId)
    .sort((a, b) =>
      compareAsc(
        new Date(a.originalClassDatetime),
        new Date(b.originalClassDatetime),
      ),
    );

  const curStudentRedeemableClassPackClasses = additionalClasses.filter(
    (c: AdditionalClass) =>
      !c.isMakeupClass && c.studentId === currStudentId && c.isRedeemable,
  );

  const curStudentUpcomingSessions = upcomingSessions.filter(session => {
    const sessionActionType = getLearnerSessionActionType(
      session,
      student.firstName,
      parent.hasMultipleChildren,
    );
    // upcomingSessions contains all sessions scheduled for today's date or up to 180 days from now
    // => need to filter out sessions that have already ended today
    return !sessionHasEnded(session) && sessionActionType ? session : null;
  });

  const uniqueCalendarIDs = R.uniq([
    ...curStudentUpcomingSessions.map(({ calendarID }) => `${calendarID}`),
    ...R.pluck('originalClassAcuityInstructorId', curStudentMakeupClasses),
  ]);
  const {
    data: nameData,
    loading: truncatedInstructorNamesLoading,
  } = useLoadTruncatedInstructorNamesQuery({
    variables: { ids: uniqueCalendarIDs, idFieldName: 'acuityCalendarId' },
    skip: isGuest,
  });
  const items = nameData?.truncatedInstructorNames.items || [];
  const instructorNameLookup = R.mergeAll(
    items.map(({ id, name }) => ({ [id]: name })),
  );

  useEffect(() => {
    setIsLoadingStudent(true);
    setTimeout(() => {
      setIsLoadingStudent(false);
    }, 500);
  }, [student._id]);

  const isLoading =
    truncatedInstructorNamesLoading ||
    isLoadingUpcomingSessions ||
    isLoadingAdditionalClasses ||
    isLoadingStudent;

  const upcomingSchedDescription = (
    <div className="text-j-dark-300">
      Rescheduling for all classes must be completed at least 24 hours before the
      session. For special circumstances, please email{' '}
      <a
        className="text-j-blue-600 no-underline"
        href="mailto:support@learnwithjuni.com"
      >
        support@learnwithjuni.com
      </a>
      .
    </div>
  );

  const parentAdvisorCheckinDescription = (
    <div className="text-j-dark-300">
      Schedule a chat with one of our parent advisors to talk about your student’s
      progress & goals, customize their learning plan, or learn about new Juni
      courses!
      <div className="italic mt-4">
        For any questions regarding scheduling logistics or live class issues please
        reach out to{' '}
        <a
          className="text-j-blue-600 no-underline"
          href="mailto:support@learnwithjuni.com"
        >
          support@learnwithjuni.com
        </a>
        .
      </div>
    </div>
  );

  if (isLoading) {
    return <JuniSpinner size={100} />;
  }

  return (
    <MyCalendarLayout>
      <div>
        <div className="text-j-dark-600 font-bold" style={{ fontSize: '1.8rem' }}>
          My Calendar
        </div>
        {isGuest ? (
          <GuestAccountCTA
            image={student_wave}
            headerText="No classes scheduled yet"
            descriptionText="Enroll in a Juni course to start weekly 1:1 classes with an instructor!"
            primaryButton={<GuestEnrollButton studentId={student._id} />}
            secondaryButton={null}
          />
        ) : (
          <>
            <PortalSection>
              <CalendarCard
                title="Upcoming Classes"
                renderDescription={() => upcomingSchedDescription}
              >
                {upcomingSessionsError ? (
                  <Message status="error">Error loading upcoming sessions</Message>
                ) : (
                  <ScheduleCard
                    classes={curStudentUpcomingSessions}
                    // We're passing this argument alongside the classes
                    // argument so that we don't have any unexpected behavior
                    // when we inevitably remove this argument
                    // - june
                    upcomingSessions={curStudentUpcomingSessions}
                    type="upcoming"
                    student={student}
                    parent={parent}
                    refreshUpcomingSessions={getUpcomingSessions}
                    refreshAdditionalClasses={getAdditionalClasses}
                    instructorNameLookup={instructorNameLookup}
                  />
                )}
              </CalendarCard>
            </PortalSection>
            <PortalSection>
              <CalendarCard title="Makeup Classes">
                {additionalClassesError ? (
                  <Message status="error">Error loading makeup classes</Message>
                ) : (
                  <ScheduleCard
                    classes={curStudentMakeupClasses}
                    upcomingSessions={curStudentUpcomingSessions}
                    type="makeup"
                    student={student}
                    parent={parent}
                    refreshUpcomingSessions={getUpcomingSessions}
                    refreshAdditionalClasses={getAdditionalClasses}
                    instructorNameLookup={instructorNameLookup}
                  />
                )}
              </CalendarCard>
            </PortalSection>
            <PortalSection>
              <CalendarCard title="Class Pack Classes">
                {additionalClassesError ? (
                  <Message status="error">Error loading class pack classes</Message>
                ) : (
                  <ScheduleCard
                    classes={curStudentRedeemableClassPackClasses}
                    // This is an actual tragedy
                    upcomingSessions={curStudentUpcomingSessions}
                    type="class_pack"
                    student={student}
                    parent={parent}
                    refreshUpcomingSessions={getUpcomingSessions}
                    refreshAdditionalClasses={getAdditionalClasses}
                  />
                )}
              </CalendarCard>
            </PortalSection>
            <PortalSection>
              <CalendarCard
                title="Parent Advisor Check-in"
                renderDescription={() => parentAdvisorCheckinDescription}
              >
                {/* This card uses an empty classes list because "advisor_checkin" type does not use classes. */}
                <ScheduleCard
                  type="advisor_checkin"
                  classes={[]}
                  student={student}
                  parent={parent}
                />
              </CalendarCard>
            </PortalSection>
          </>
        )}
      </div>
    </MyCalendarLayout>
  );
};

export default MyCalendar;
