import React, { useCallback } from 'react';
import classNames from 'classnames';
import Select, {
  Props as SelectProps,
  OptionTypeBase,
  ValueType,
} from 'react-select';
import { useField } from 'formik';
import * as R from 'ramda';

// typings are weird for some reason so have to re-insert props
interface Props<OptionType extends OptionTypeBase> {
  className?: string;
  label?: string;
  onChange?: (value: OptionType['value'], name?: string) => void;
  onChangeMulti?: (value: OptionType['value'][], name?: string) => void;
  value?: OptionType['value'] | OptionType['value'][];
  isMulti?: boolean;
  error?: string;
  options?: OptionType[];
  onBlur?: SelectProps['onBlur'];
  name?: SelectProps['name'];
  isDisabled?: SelectProps['isDisabled'];
  placeholder?: SelectProps['placeholder'];
  isClearable?: SelectProps['isClearable'];
  isLoading?: SelectProps['isLoading'];
  styles?: SelectProps['styles'];
}

interface FormikProps<OptionType extends OptionTypeBase>
  extends Omit<Props<OptionType>, 'error' | 'value'> {
  name: string;
}

function ReactSelectField<
  OptionType extends OptionTypeBase = { label: string; value: string }
>({
  className,
  label,
  onChange,
  onChangeMulti,
  error,
  value,
  styles,
  ...rest
}: Props<OptionType>) {
  const selectOptions = rest.options || [];
  const flattenedOptions = R.flatten(
    selectOptions.map(o => [o.options ? o.options : o]),
  );

  const handleChange = useCallback(
    (option: ValueType<OptionType>) => {
      if (Array.isArray(option)) {
        if (onChangeMulti) {
          onChangeMulti(
            option.map(o => o.value),
            rest.name,
          );
        }
      } else if (onChange) {
        // awkward casting required because of https://github.com/microsoft/TypeScript/issues/17002
        onChange((option as OptionType)?.value, rest.name);
      }
    },
    [onChange, onChangeMulti, rest.name],
  );

  const selectValue = Array.isArray(value)
    ? flattenedOptions.filter(o => value.includes(o.value))
    : flattenedOptions.find((option: OptionType) => option.value === value) || null;

  return (
    <label className={classNames('ui-field', className)}>
      {label && <div className="ui-field-label">{label}</div>}
      {error && <div className="ui-field-error">{error}</div>}
      <Select
        {...rest}
        value={selectValue}
        onChange={handleChange}
        options={selectOptions}
        styles={styles || {}}
      />
    </label>
  );
}

function FormikReactSelectField<
  OptionType extends OptionTypeBase = { label: string; value: string }
>(props: FormikProps<OptionType>) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value: OptionType['value'], name?: string): void => {
    helpers.setValue(value);
    if (props.onChange) props.onChange(value, name);
  };
  const handleChangeMulti = (value: OptionType['value'][], name?: string): void => {
    helpers.setValue(value);
    if (props.onChangeMulti) props.onChangeMulti(value, name);
  };
  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    field.onBlur(e);
    if (props.onBlur) props.onBlur(e);
  };
  return (
    <ReactSelectField
      {...props}
      error={meta.touched ? meta.error : undefined}
      onChange={handleChange}
      onChangeMulti={handleChangeMulti}
      onBlur={handleBlur}
      value={field.value}
    />
  );
}

ReactSelectField.Formik = FormikReactSelectField;

export default ReactSelectField;
