import React, { FC } from 'react';
import classNames from 'classnames';
import styled from 'styled-components/macro';

import { juniPurple } from 'theme/old-colors';

import Radio from './Radio';

const OptionContainer = styled.div<{ active: boolean }>`
  &:hover {
    .option__container {
      background: ${props => (props.active ? '' : juniPurple[100])};
    }
  }
`;

const SingleSelect: FC<{
  addBorder?: boolean;
  value?: string | number;
  options: Array<{
    label: string;
    value: string | number;
    subLabel?: string | React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
  }>;
  onSelect: (selected: string | number) => void;
}> = ({ addBorder = false, value, options, onSelect }) => (
  <div
    className={classNames(
      `
    grid
    my-8
    gap-y-4
  `,
      {
        'xl:grid-cols-2 xl:gap-x-8': options.length === 2,
      },
    )}
  >
    {options.map((option, index) => (
      <OptionContainer
        role="button"
        tabIndex={index}
        onClick={() => onSelect(option.value)}
        key={option.value}
        className="flex items-center flex-grow w-full cursor-pointer"
        active={value === option.value}
      >
        <div className="mr-4">
          <Radio selected={option.value === value} />
        </div>
        <div
          className={classNames(
            `
            option__container
            flex
            items-center
            w-full
            xl:w-auto
            space-x-6
            p-4
            border
            border-solid
            border-transparent
            rounded-lg`,
            {
              'border-blue-gray-200': addBorder && value !== option.value,
              'border-juni-purple-500': value === option.value,
              'text-blue-gray-500': value !== option.value,
            },
          )}
        >
          {option.icon}
          <div className="flex flex-col items-start justify-center">
            <span className={`font-bold text-sm ${option.className ?? ''}`}>
              {option.label}
            </span>
            {option.subLabel && (
              <span className="text-xs mt-2">{option.subLabel}</span>
            )}
          </div>
        </div>
      </OptionContainer>
    ))}
  </div>
);

export default SingleSelect;
