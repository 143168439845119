import React from 'react';
import classNames from 'classnames';
import { NewCard as Card, ProgressBar } from 'core-components';
import IconComponent from 'core-components/Icon/IconComponent';
import { RecapActiveCoursesProgress } from 'generated/graphql';

const ActiveCourseCard: React.FC<RecapActiveCoursesProgress> = ({
  displayName,
  numModulesCompleted,
  totalNumModules,
  iconName,
  primaryColor,
}) => {
  const splitDisplayName = displayName.split(':');

  const header = splitDisplayName[0];
  const subheader = splitDisplayName[1];

  return (
    <Card
      className="font-graphik border-solid border border-j-dark-100 flex flex-col"
      backgroundColor="white"
    >
      <div className="flex flex-col flex-1 mb-4">
        <div
          className={classNames(
            `flex text-white items-center justify-center`,
            `rounded-lg bg-${primaryColor} w-12 h-12 text-xs p-4 box-border`,
            'mb-3',
          )}
        >
          <IconComponent iconName={iconName} />
        </div>
        <div className="text-j-dark-600 text-lg font-medium mb-1">{header}</div>
        <div className="text-j-dark-400 text-sm">{subheader}</div>
      </div>
      <div>
        <div>
          <div className="flex mb-2 justify-between text-j-dark-300 uppercase font-medium ">
            <div className="text-xs ">Modules completed</div>
            <div className="flex text-xs">
              <div className="text-j-purple-800 mr-0.5 font-medium">
                {numModulesCompleted}
              </div>{' '}
              /<div>{totalNumModules}</div>
            </div>
          </div>
          <ProgressBar
            height="1"
            percent={(numModulesCompleted / totalNumModules) * 100}
            borderRadius="md"
          />
        </div>
      </div>
    </Card>
  );
};

export default ActiveCourseCard;
