import React, { FC } from 'react';
import classNames from 'classnames';
import { Popover } from 'core-components';
import JideNextSection from './JideNextSection';

interface JideNextWidgetProps {
  nextSection: React.ComponentProps<typeof JideNextSection>['nextSection'];
  onClose: React.ComponentProps<typeof Popover>['onClose'];
}

const JideNextWidget: FC<JideNextWidgetProps> = ({ nextSection, onClose }) => (
  <Popover
    className={classNames(
      'absolute bottom-20 right-2 flex flex-col w-96 px-4 pt-3',
      'space-y-2 text-sm border border-solid border-j-dark-100 overflow-y-auto',
    )}
    style={{ maxHeight: 'calc(100vh - 180px)' }}
    onClose={onClose}
  >
    <JideNextSection nextSection={nextSection} />
  </Popover>
);

export default JideNextWidget;
