import _ from 'lodash';
import * as R from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getStudentMathAnswerAttemptsForModuleSection } from 'services/api/math_answer_attempt.js';
import { addWhiteboardPage } from 'services/whiteboards';

import {
  postWhiteboardEdited,
  postWhiteboardSaved,
  postWhiteboardSkipped,
} from 'services/user-events/student-events';
import {
  JideMathProblem,
  JideWidgets,
  Footer,
  JideEnvLayout,
  ActivityInstructions,
  Problems,
} from 'components/jide';
import InstructionsPanel from 'components/InstructionsPanel';
import Whiteboard from 'components/Whiteboard';
import { Chevron } from 'components/Icons';

import './jide_env_math.css';

const PUBLISHABLE_TYPES = ['concept', 'activity'];
const WHITEBOARD_TYPES = ['concept', 'activity'];

class JideEnvMath extends Component {
  state = {
    curProblemIndex: 0,
    answerAttempts: [],
    pageData: {},
    studentId: '',
    projectId: '',
    studentEventPayload: {},
  };

  componentDidMount() {
    const { tab } = this.props;
    this.isPlayground = this.props.tab.tabNav.course === 'playground';

    if (tab.tabNav.project) {
      const projectId = tab.tabNav.project;
      const moduleSection = this.props.idLookup[projectId];
      const { displayName, properties } = moduleSection || {};
      const { courseId, moduleId } = properties || {};
      const currentModule = this.props.idLookup[moduleId];

      if (tab.tabNav.student) {
        const studentId = tab.tabNav.student;
        const problemSetName = currentModule
          ? `${currentModule.properties.name} ${displayName}`
          : `${displayName}`;

        const studentEventPayload = {
          studentId,
          courseId: courseId || tab.tabNav.course,
          problemSetName,
        };

        this.setState({ studentId, projectId, studentEventPayload });
        if (!this.isPlayground) {
          this.fetchAnswerAttemptsForModuleSection(studentId, projectId);
        }
      } else {
        this.setState({ projectId });
      }
    }
  }

  onFetchPageData = (rootBoardId, numPages, curPageIndex) =>
    new Promise(resolve => {
      this.setState(
        prevState => ({
          pageData: {
            ...prevState.pageData,
            [rootBoardId]: {
              numPages,
              curPageIndex:
                curPageIndex != null
                  ? curPageIndex
                  : prevState.pageData[rootBoardId]
                  ? prevState.pageData[rootBoardId].curPageIndex || 0
                  : 0,
            },
          },
        }),
        () => {
          resolve();
        },
      );
    });

  fetchAnswerAttemptsForModuleSection = async (studentId, projectId) =>
    getStudentMathAnswerAttemptsForModuleSection(studentId, projectId)
      .then(answerAttempts => {
        this.setState({ answerAttempts });
      })
      .catch(err => {
        console.error(err);
      });

  switchToProblemIndex = newProblemIndex => {
    const { jideUser } = this.props;

    if (!!jideUser.type && jideUser.type === 'student') {
      postWhiteboardSkipped(this.state.studentEventPayload);
    }

    this.setState({ curProblemIndex: newProblemIndex });
  };

  switchToProblem = e => {
    const newProblemIndex = parseInt(e.currentTarget.value, 10);
    this.switchToProblemIndex(newProblemIndex);
  };

  onSubmitAnswer = updatedAnswerAttempt => {
    const { jideUser } = this.props;

    if (!!jideUser.type && jideUser.type === 'student') {
      postWhiteboardSaved(this.state.studentEventPayload);
    }

    this.setState(prevState => {
      const updateIndex = prevState.answerAttempts.findIndex(
        attempt => attempt._id === updatedAnswerAttempt._id,
      );
      const updatedAnswerAttempts = [...prevState.answerAttempts];
      if (updateIndex !== -1) {
        updatedAnswerAttempts[updateIndex] = updatedAnswerAttempt;
      } else {
        updatedAnswerAttempts.push(updatedAnswerAttempt);
      }
      return { answerAttempts: updatedAnswerAttempts };
    });
  };

  onWhiteboardEdit = () => {
    const { jideUser } = this.props;

    if (!!jideUser.type && jideUser.type === 'student') {
      postWhiteboardEdited(this.state.studentEventPayload);
    }
  };

  nextPage = boardId => {
    const rootBoardId = boardId.substring(0, boardId.indexOf('/page'));
    if (
      this.state.pageData[rootBoardId].numPages >
      this.state.pageData[rootBoardId].curPageIndex + 1
    ) {
      this.setState(prevState => ({
        pageData: {
          ...prevState.pageData,
          [rootBoardId]: {
            numPages: prevState.pageData[rootBoardId].numPages,
            curPageIndex: prevState.pageData[rootBoardId].curPageIndex + 1,
          },
        },
      }));
    }
  };

  prevPage = boardId => {
    const rootBoardId = boardId.substring(0, boardId.indexOf('/page'));
    if (this.state.pageData[rootBoardId].curPageIndex > 0) {
      this.setState(prevState => ({
        pageData: {
          ...prevState.pageData,
          [rootBoardId]: {
            numPages: prevState.pageData[rootBoardId].numPages,
            curPageIndex: prevState.pageData[rootBoardId].curPageIndex - 1,
          },
        },
      }));
    }
  };

  addPage = async boardId => {
    const rootBoardId = boardId.substring(0, boardId.indexOf('/page'));
    if (this.state.studentId.length) {
      // if student is associated with this whiteboard,
      // save incremented numPages to database
      return addWhiteboardPage(boardId).then(data => {
        this.setState(prevState => ({
          pageData: {
            ...prevState.pageData,
            [rootBoardId]: {
              numPages: data.numPages,
              curPageIndex: data.numPages - 1,
            },
          },
        }));
        return data.numPages;
      });
    }
    // if no student is associated with this whiteboard,
    // only increment numPages in state
    this.setState(prevState => ({
      pageData: {
        ...prevState.pageData,
        [rootBoardId]: {
          numPages: prevState.pageData[rootBoardId].numPages + 1,
          curPageIndex: prevState.pageData[rootBoardId].numPages,
        },
      },
    }));
  };

  getProblemMetadata = projectId => {
    const projectInformation = this.props.idLookup[projectId];
    const problems = projectInformation?.properties?.problems;
    const numProblems = problems?.length || 0;

    return {
      projectInformation,
      numProblems,
    };
  };

  inferProjectType = projectId => {
    const { projectInformation, numProblems } = this.getProblemMetadata(projectId);

    if (this.isPlayground) {
      return 'playground';
    }

    if (projectInformation?.properties.sectionType === 'concept') {
      return 'concept';
    }

    if (
      projectInformation?.properties.sectionType === 'problem_set' &&
      numProblems > 0
    ) {
      return 'problem_set';
    }

    if (projectInformation?.properties.sectionType === 'activity') {
      return 'activity';
    }

    return 'unknown';
  };

  populateDefaultBoardType = (boardId, defaultWhiteboardProps) => {
    const { pageData } = this.state;
    const curPageIndex = pageData[boardId]?.curPageIndex || 0;
    return R.map(
      i => (
        <Whiteboard
          id={`${boardId}/page-${i}`}
          key={`${boardId}/page-${i}`}
          hidden={i !== curPageIndex}
          pageIndex={curPageIndex}
          numPages={pageData[boardId]?.numPages || 1}
          {...defaultWhiteboardProps}
        />
      ),
      [...Array(pageData[boardId]?.numPages || 1).keys()],
    );
  };

  populatePSetBoardType = (boardId, defaultWhiteboardProps) => {
    const { curProblemIndex, pageData, projectId } = this.state;
    const { projectInformation, numProblems } = this.getProblemMetadata(projectId);

    return R.pipe(
      R.map(pIndex => {
        const problem = projectInformation.properties.problems[pIndex];
        const rootProblemBoardId = `${boardId}/problem-${problem._id}`;
        const numPages = pageData[rootProblemBoardId]?.numPages || 1;
        const curPageIndex = pageData[rootProblemBoardId]?.curPageIndex || 0;

        return [...Array(numPages).keys()].map(i => (
          <Whiteboard
            id={`${rootProblemBoardId}/page-${i}`}
            key={`${rootProblemBoardId}/page-${i}`}
            hidden={pIndex !== curProblemIndex || i !== curPageIndex}
            pageIndex={curPageIndex}
            numPages={numPages}
            {...defaultWhiteboardProps}
          />
        ));
      }),
      R.flatten(),
    )([...Array(numProblems).keys()]);
  };

  getPopulatedBoards = (showRightPanel, jideWidgets) => {
    const { modalViewMode, shiftKeyIsPressed } = this.props;
    const { studentId, projectId } = this.state;
    const { projectInformation } = this.getProblemMetadata(projectId);
    let prefix =
      this.props.jideUser.type === 'public'
        ? this.props.jideUser.createdByUserType
        : this.props.jideUser.type;

    // createdByUserType is now 'instructor'. Files are still named with the original label 'teacher'.
    if (prefix === 'instructor') {
      prefix = 'teacher';
    }

    // TODO: the line below is a temporary workaround
    if (this.props?.tab?.tabNav?.module !== 'playground') prefix = 'student';

    const rootBoardId = `${studentId.length ? `${prefix}-${studentId}` : ''}`;
    const projectType = this.inferProjectType(projectId);
    const defaultWhiteboardProps = {
      onFetchPageData: this.onFetchPageData,
      withSaving: !!studentId.length,
      nextPage: this.nextPage,
      prevPage: this.prevPage,
      addPage: this.addPage,
      isInModal: modalViewMode,
      shiftKeyIsPressed,
      handleWhiteboardEdit: _.throttle(this.onWhiteboardEdit, 10000),
      jideUser: this.props.jideUser,
      juniverseProjectData: this.props.juniverseProjectData,
      newHorizons: this.props.newHorizons,
      fullScreen: !showRightPanel,
      jideWidgets: !showRightPanel ? jideWidgets : undefined,
    };

    const projectTypes = projectInformation
      ? {
          playground: {
            boardId: `${rootBoardId}/playground-${projectInformation.id}`,
            populate: this.populateDefaultBoardType,
          },
          concept: {
            boardId: `${rootBoardId}/concept-${projectInformation.id}`,
            populate: this.populateDefaultBoardType,
          },
          problem_set: {
            boardId: `${rootBoardId}/pset-${projectInformation.id}`,
            populate: this.populatePSetBoardType,
          },
          activity: {
            boardId: `${rootBoardId}/activity-${projectInformation.id}`,
            populate: this.populateDefaultBoardType,
          },
        }
      : {};

    const { boardId, populate } = projectTypes[projectType] || {};
    return populate?.(boardId, defaultWhiteboardProps) || [];
  };

  render() {
    const { modalViewMode, newHorizons } = this.props;
    const { curProblemIndex, answerAttempts, studentId, projectId } = this.state;
    const { projectInformation, numProblems } = this.getProblemMetadata(projectId);

    const prevProblemDisabled = curProblemIndex === 0;
    const nextProblemDisabled =
      !projectInformation || curProblemIndex === numProblems - 1;
    const projectType = this.inferProjectType(projectId);
    const showRightPanel =
      !this.props.juniverseProjectData &&
      projectInformation &&
      !['concept', 'playground'].includes(projectType);
    const isPublishable = PUBLISHABLE_TYPES.includes(
      projectInformation?.properties.sectionType,
    );
    const isWhiteboard = WHITEBOARD_TYPES.includes(
      projectInformation?.properties.sectionType,
    );
    const jideWidgets = (
      <JideWidgets
        environmentType={isPublishable && isWhiteboard ? 'math_whiteboard' : 'math'}
        showVideosWidget={this.props.showVideosWidget}
        activeNav={this.props.activeNav}
        idLookup={this.props.idLookup}
        isLoading={this.props.isLoading}
        learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
        tab={this.props.tab}
        jideUser={this.props.jideUser}
        isCustomProject
        hideShareWidget={!isPublishable && !this.props.playgroundProject}
        isPlayground={!!this.props.playgroundProject}
        recordingMode={this.props.recordingMode}
        setRecordingMode={this.props.setRecordingMode}
        newHorizons
        schedulingFormat={this.props.schedulingFormat}
      />
    );
    const boards = this.getPopulatedBoards(showRightPanel, jideWidgets);
    const isActivity = projectType === 'activity';
    const instructions = projectInformation?.properties?.instructions;

    if (newHorizons) {
      return (
        <JideEnvLayout>
          <JideEnvLayout.MainContent>{boards}</JideEnvLayout.MainContent>
          {showRightPanel && (
            <JideEnvLayout.SideContent>
              <div className="h-full overflow-y-auto">
                {numProblems > 0 && (
                  <Problems
                    label="Problem"
                    curProblemIndex={curProblemIndex}
                    curProblemDifficulty={
                      projectInformation.properties.problems[curProblemIndex]
                        .difficulty
                    }
                    numProblems={numProblems}
                    onProblemIndexChanged={this.switchToProblemIndex}
                  >
                    <JideMathProblem
                      problem={
                        projectInformation.properties.problems[curProblemIndex]
                      }
                      answerAttempt={answerAttempts.find(
                        attempt =>
                          attempt.problemId ===
                          projectInformation.properties.problems[curProblemIndex]
                            ._id,
                      )}
                      onSubmitAnswer={this.onSubmitAnswer}
                      studentId={studentId}
                      moduleSectionId={projectId}
                      modalViewMode={modalViewMode}
                      newHorizons
                    />
                  </Problems>
                )}
                {isActivity && (
                  <>
                    <div className="px-6 py-4 text-xl leading-8 text-j-dark-600 border-0 border-solid border-j-purple-200 border-b">
                      Instructions
                    </div>
                    <ActivityInstructions
                      instructions={instructions}
                      marginTop="6"
                    />
                  </>
                )}
              </div>
              <Footer className="justify-end">{jideWidgets}</Footer>
            </JideEnvLayout.SideContent>
          )}
        </JideEnvLayout>
      );
    }

    return (
      <div className="jide-math-env">
        {projectInformation && <div className="left-panel">{boards}</div>}
        {showRightPanel && (
          <div className="right-panel">
            <div className="right-panel-header">
              {numProblems > 0 && (
                <div>
                  <h3 className="right-panel-title">
                    Problem #{curProblemIndex + 1}
                  </h3>
                  {projectInformation.properties.problems[curProblemIndex]
                    .difficulty === 'challenge' && (
                    <div className="header-tag-v2">Challenge</div>
                  )}
                  <div className="math-problem-nav">
                    <button
                      className="whiteboard-control icon-button"
                      title="Previous problem"
                      value={curProblemIndex === 0 ? 0 : curProblemIndex - 1}
                      onClick={this.switchToProblem}
                      disabled={prevProblemDisabled}
                    >
                      <Chevron orientation="left" />
                    </button>
                    Problem {numProblems > 0 ? curProblemIndex + 1 : '-'} of{' '}
                    {numProblems > 0 ? numProblems : '-'}
                    <button
                      className="whiteboard-control icon-button"
                      title="Next problem"
                      value={
                        numProblems > 0
                          ? curProblemIndex >= numProblems - 1
                            ? numProblems - 1
                            : curProblemIndex + 1
                          : 0
                      }
                      onClick={this.switchToProblem}
                      disabled={nextProblemDisabled}
                    >
                      <Chevron orientation="right" />
                    </button>
                  </div>
                </div>
              )}
            </div>
            {numProblems > 0 && (
              <JideMathProblem
                problem={projectInformation.properties.problems[curProblemIndex]}
                answerAttempt={answerAttempts.find(
                  attempt =>
                    attempt.problemId ===
                    projectInformation.properties.problems[curProblemIndex]._id,
                )}
                onSubmitAnswer={this.onSubmitAnswer}
                studentId={studentId}
                moduleSectionId={projectId}
                modalViewMode={modalViewMode}
              />
            )}

            {isActivity && <InstructionsPanel instructions={instructions} />}
          </div>
        )}
        <JideWidgets
          environmentType={
            isPublishable && isWhiteboard ? 'math_whiteboard' : 'math'
          }
          showVideosWidget={this.props.showVideosWidget}
          activeNav={this.props.activeNav}
          idLookup={this.props.idLookup}
          isLoading={this.props.isLoading}
          learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
          tab={this.props.tab}
          jideUser={this.props.jideUser}
          isCustomProject
          hideShareWidget={!isPublishable && !this.props.playgroundProject}
          isPlayground={!!this.props.playgroundProject}
          recordingMode={this.props.recordingMode}
          setRecordingMode={this.props.setRecordingMode}
        />
      </div>
    );
  }
}
JideEnvMath.defaultProps = {
  modalViewMode: false,
  shiftKeyIsPressed: false,
  newHorizons: false,
};
JideEnvMath.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  tab: PropTypes.shape({}).isRequired,
  modalViewMode: PropTypes.bool,
  shiftKeyIsPressed: PropTypes.bool,
  idLookup: PropTypes.shape({}).isRequired,
  showVideosWidget: PropTypes.bool,
  recordingMode: PropTypes.bool,
  setRecordingMode: PropTypes.func,
  newHorizons: PropTypes.bool,
};
export default JideEnvMath;
