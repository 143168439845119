import { IMeta } from 'react-dropzone-uploader';

/**
 * Returns the file extension.
 * Uses the filename instead of type because type is browser dependent and inconsistent.
 * @param filename
 */
export const getExtension = (filename: string) => {
  const ext = filename.split('.').pop();
  return `.${ext}`;
};

export const getError = (meta: IMeta) => {
  switch (meta.status) {
    case 'error_file_size':
      return 'File is not the correct size.';
    case 'error_validation':
      return meta.validationError.toString() as string;
    case 'error_upload_params':
    case 'error_upload':
    case 'exception_upload':
      return 'Sorry, an unexpected error occurred uploading. Please try again.';
    case 'aborted':
      return 'Upload aborted.';
    case 'rejected_file_type':
      return 'File type not supported.';
    default:
      return null;
  }
};
