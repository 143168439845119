import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { validateUsername, validateJuniverseProjectTitle } from 'utils/validation';
import { parseError } from 'utils/errors';

import {
  studentProjectLookup,
  setUsername,
  upsertStudentProject,
} from 'services/juniverse';
import { postJideProjectShared } from 'services/user-events/student-events';
import { Arrow } from 'components/Icons';
import WarningBlock from 'components/WarningBlock/WarningBlock';
import LinkCopier from 'components/LinkCopier';

import { getProjectUsermedia } from 'services/usermedia';
import { parseMoment } from 'constants/timezones';
import { JRSModalPreview } from 'components/jide';

import { NewButton, Icon } from 'core-components';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

import JuniSpinner from 'components/JuniSpinner';
import 'components/jide/JideRecStudio/styles/jide_rec_studio.css';
import 'components/jide/JideRecStudio/styles/view_recordings.css';
import './jide_share_button.css';
import { ShareButton } from '..';

class JideShareButton extends Component {
  state = {
    lastFetch: null,
    isExpanded: false,
    isLoading: false,
    isSubmitLoading: false,
    desiredUsername: '',
    usernameErrorMsg: null,
    titleErrorMsg: null,
    studentProjectId: null,
    viewKey: null,
    title: null,
    description: null,
    isPublic: null,
    juniverseTags: null,
    likes: null,
    runs: null,
    username: null,
    canCodeBePublic: null,
    unsavedChanges: false,
    projectVideos: [],
    attachedVideos: [],
    useDefaultTitle: false,
    unlistedProjectPageLink: null,
  };
  containerRef = React.createRef();

  showButton() {
    return this.props.isPlayground === true && this.props.jideUser.type !== 'public'
      ? true
      : ['student'].includes(this.props.jideUser.type);
  }

  componentDidMount() {
    if (this.showButton()) {
      document.addEventListener('click', this.closeDropdownsOnClickOutside, false);
    }
  }

  componentWillUnmount() {
    if (this.showButton()) {
      document.removeEventListener(
        'click',
        this.closeDropdownsOnClickOutside,
        false,
      );
    }
  }
  closeDropdownsOnClickOutside = e => {
    if (!this.containerRef.current) return;
    if (
      this.containerRef.current !== e.target &&
      !this.containerRef.current.contains(e.target)
    ) {
      this.closeWidget();
    }
  };
  closeWidget = cb => {
    if (this.props.toggleSiblingWidgets && this.state.isExpanded) {
      this.props.toggleSiblingWidgets();
    }
    this.setState({ isExpanded: false }, cb);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isExpanded && this.state.isExpanded) {
      const shouldFetch =
        !this.state.isLoading &&
        (!this.state.lastFetch ||
          moment
            .duration(moment.utc().diff(moment.utc(this.state.lastFetch)))
            .asSeconds() >= 10);
      if (shouldFetch) this.fetchStudentProjectDetails();
    }
  }

  toggleModal = () => {
    if (this.props.toggleSiblingWidgets) {
      this.props.toggleSiblingWidgets();
    }
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  fetchStudentProjectDetails = () => {
    const { jideUser, tab, isCustomProject, environmentType } = this.props;
    const instructorUserId = jideUser.type === 'teacher' ? jideUser._id : undefined;
    const studentId = jideUser.type === 'student' ? jideUser._id : undefined;

    this.setState({ isLoading: true }, async () => {
      const resp = await studentProjectLookup({
        instructorUserId,
        studentId,
        courseId: tab.tabNav.course,
        moduleId: tab.tabNav.module,
        projectId: tab.tabNav.project,
        playgroundProjectType: environmentType,
      });

      const projectVids = await getProjectUsermedia({
        userType: jideUser.type,
        studentId,
        teacherUserId: instructorUserId,
        projectId: isCustomProject ? undefined : tab.tabNav.project,
        customProjectId: isCustomProject ? tab.tabNav.project : undefined,
      })
        .then(res => {
          if (!res.success || !res.usermedias) return [];
          return res.usermedias;
        })
        .catch(err => {
          console.error(err);
          return [];
        });
      const sortedProjectVids = projectVids.sort((a, b) =>
        moment.utc(a.createdAt) > moment.utc(b.createdAt) ? -1 : 1,
      );

      this.setState({
        isLoading: false,
        lastFetch: moment.utc(),
        studentProjectId: resp._id,
        viewKey: resp.viewKey,
        title: resp.title,
        description: resp.description,
        isPublic: resp.isPublic,
        juniverseTags: resp.juniverseTags,
        likes: resp.likes,
        runs: resp.runs,
        username: resp.username,
        canCodeBePublic: resp.canCodeBePublic,
        useDefaultTitle: !resp.title,
        unsavedChanges: false,
        projectVideos: sortedProjectVids,
        attachedVideos: resp.attachedUsermedia || [],
        featuredProject: resp.featuredProject,
        unlistedProjectPageLink: resp.unlistedProjectPageLink,
      });
    });
  };

  desiredUsernameOnChange = e => {
    const val = e.target.value;
    this.setState({
      desiredUsername: val,
      unsavedChanges: !!val,
    });
  };

  submitUsername = () => {
    const usernameErrorMsg = validateUsername(this.state.desiredUsername);
    const isClickable =
      this.state.unsavedChanges && !this.state.isSubmitLoading && !usernameErrorMsg;
    if (!isClickable) {
      this.setState({ usernameErrorMsg });
      return;
    }

    this.setState({ isSubmitLoading: true }, async () => {
      const { success, error } = await setUsername(
        this.props.jideUser._id,
        this.state.desiredUsername,
        this.props.jideUser.type,
      );
      const errorMessage = error
        ? parseError(error, `Sorry, that didn't work.`)
        : 'Oops! Something went wrong. Please try again later.';
      this.setState(
        {
          usernameErrorMsg: success ? null : errorMessage,
          isSubmitLoading: false,
        },
        () => {
          if (success) this.fetchStudentProjectDetails();
        },
      );
    });
  };

  titleOnChange = e => {
    const val = e.target.value;
    this.setState({
      title: val,
      unsavedChanges: true,
      useDefaultTitle: false,
    });
  };

  saveStudentProject = ({ isPublic }) => {
    const title = this.state.useDefaultTitle
      ? this.props.defaultTitle
      : this.state.title;

    const titleErrorMsg = validateJuniverseProjectTitle(title);
    const isClickable =
      (isPublic ? true : this.state.unsavedChanges) &&
      !this.state.isSubmitLoading &&
      !titleErrorMsg;

    if (!isClickable) {
      this.setState({ titleErrorMsg });
      return;
    }

    this.setState({ isSubmitLoading: true }, async () => {
      const updateParams = {
        userId: this.props.jideUser._id,
        courseId: this.props.tab.tabNav.course,
        moduleId: this.props.tab.tabNav.module,
        projectId: this.props.tab.tabNav.project,
        title,
        isPublic,
        userType: this.props.jideUser.type,
        projectType: this.props.environmentType,
        attachedUsermedia: this.state.attachedVideos,
      };

      const { success, error } = await upsertStudentProject(updateParams);
      const errorMessage = error
        ? parseError(error, `Sorry, that didn't work.`)
        : 'Oops! Something went wrong. Please try again later.';

      this.setState(
        {
          titleErrorMsg: success ? null : errorMessage,
          isSubmitLoading: false,
        },
        () => {
          if (success) {
            const { isCustomProject, jideUser, tab } = this.props;
            const { student, project, course } = tab.tabNav || {};

            if (!!jideUser.type && jideUser.type === 'student') {
              postJideProjectShared({
                studentId: student,
                projectId: project,
                courseId: course,
                isProjectPublic: updateParams.isPublic,
                isCustomProject,
              });
            }

            this.fetchStudentProjectDetails();
          }
        },
      );
    });
  };

  completeUnpublish = () => {
    this.setState({ unsavedChanges: true }, () => {
      this.saveStudentProject({ isPublic: false });
    });
  };

  // TODO: in the future, we may want to enable sharing of multiple videos
  // hence the commented out line below
  toggleAttachedVideo = pvId => {
    if (this.state.attachedVideos.includes(pvId)) {
      this.setState(prevState => ({
        attachedVideos: prevState.attachedVideos.filter(av => av !== pvId),
        unsavedChanges: true,
      }));
    } else {
      this.setState({
        // attachedVideos: [...this.state.attachedVideos, pvId],
        attachedVideos: [pvId],
        unsavedChanges: true,
      });
    }
  };

  switchToRecStudio = () => {
    this.closeWidget(() => {
      this.props.setRecordingMode(true);
    });
  };

  render() {
    if (!this.showButton()) return null;
    return (
      <div
        ref={this.containerRef}
        className="jideshare"
        id={`jideshare-${this.props.tab.tabId}`}
      >
        {this.props.newHorizons ? (
          <NewButton
            variant="secondary"
            size="small"
            renderIconLeft={props => <Icon.ArrowUpRight {...props} />}
            onClick={this.toggleModal}
            data-cy="jide-share-btn"
          >
            Share
          </NewButton>
        ) : (
          <ShareButton
            className={`jideshare-button${
              this.state.isExpanded ? ' jideshare-expanded' : ''
            }`}
            onClick={this.toggleModal}
          >
            <span className="widget__title">Share</span>
            <div className="widget-icon-container">
              <FontAwesomeIcon icon={['fas', 'share-square']} />
            </div>
          </ShareButton>
        )}
        {this.state.isExpanded ? (
          <div className="jideshare-modal">
            {this.state.isLoading ? (
              <div className="jideshare-spinner">
                <JuniSpinner size={60} />
              </div>
            ) : !this.state.username ? (
              <JideSharePickUsername
                desiredUsername={this.state.desiredUsername}
                desiredUsernameOnChange={this.desiredUsernameOnChange}
                usernameErrorMsg={this.state.usernameErrorMsg}
                submitUsername={this.submitUsername}
                isSubmitLoading={this.state.isSubmitLoading}
                unsavedChanges={this.state.unsavedChanges}
              />
            ) : (
              <JideShareStudentProject
                studentProjectId={this.state.studentProjectId}
                viewKey={this.state.viewKey}
                title={
                  this.state.useDefaultTitle &&
                  !this.state.title &&
                  !this.state.isPublic &&
                  this.props.isCustomProject
                    ? this.props.defaultTitle
                    : this.state.title
                }
                description={this.state.description}
                isPublic={this.state.isPublic}
                juniverseTags={this.state.juniverseTags}
                likes={this.state.likes}
                runs={this.state.runs}
                username={this.state.username}
                canCodeBePublic={this.state.canCodeBePublic}
                titleOnChange={this.titleOnChange}
                titleErrorMsg={this.state.titleErrorMsg}
                isSubmitLoading={this.state.isSubmitLoading}
                saveStudentProject={() =>
                  this.saveStudentProject({
                    isPublic: this.state.isPublic ? this.state.isPublic : true,
                  })
                }
                completeUnpublish={this.completeUnpublish}
                unsavedChanges={this.state.unsavedChanges}
                projectVideos={this.state.projectVideos}
                attachedVideos={this.state.attachedVideos}
                toggleAttachedVideo={this.toggleAttachedVideo}
                switchToRecStudio={this.switchToRecStudio}
                featuredProject={this.state.featuredProject}
                unlistedProjectPageLink={this.state.unlistedProjectPageLink}
                jideUser={this.props.jideUser}
                tab={this.props.tab}
                isCustomProject={this.props.isCustomProject}
                environmentType={this.props.environmentType}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const JideSharePickUsername = ({
  desiredUsername,
  desiredUsernameOnChange,
  usernameErrorMsg,
  submitUsername,
  isSubmitLoading,
  unsavedChanges,
}) => (
  <div className="jideshare-pick-username">
    <JideShareUsernameContainer username={undefined} header="Publish this project" />
    <div className="bottom">
      <span className="username-instructions">
        Create a username for your Juni profile
      </span>
      <span className="username-instructions-subtext">
        Your public Juni profile will feature all of the projects you publish!
      </span>
      <JideShareInputTextBox
        value={desiredUsername}
        onChange={desiredUsernameOnChange}
        errorMsg={usernameErrorMsg}
      />

      <JideShareSubmitButton
        onClick={submitUsername}
        isSubmitLoading={isSubmitLoading}
        unsavedChanges={unsavedChanges}
        username={undefined}
        studentProjectId={undefined}
      />
    </div>
  </div>
);

const JideShareStudentProject = ({
  studentProjectId,
  viewKey,
  title,
  isPublic,
  username,
  titleOnChange,
  titleErrorMsg,
  isSubmitLoading,
  saveStudentProject,
  unsavedChanges,
  completeUnpublish,
  projectVideos,
  attachedVideos,
  toggleAttachedVideo,
  switchToRecStudio,
  featuredProject,
  unlistedProjectPageLink,
  jideUser,
  tab,
  isCustomProject,
  environmentType,
}) => {
  const [isUnpublishing, toggleUnpublishProject] = useState(false);

  const handleCopyLink = () => {
    JuniAnalytics.track(`jide_share_v2_private_link_copied`, {
      username,
      instructorUserId: jideUser.type === 'teacher' ? jideUser._id : undefined,
      studentId: jideUser.type === 'student' ? jideUser._id : undefined,
      courseId: tab.tabNav.course,
      moduleId: tab.tabNav.module,
      projectId: tab.tabNav.project,
      playgroundProjectType: environmentType,
      isCustomProject,
    });
  };

  const attachRecordingString = !projectVideos.length
    ? 'Create and Attach a Recording'
    : !attachedVideos.length
    ? 'Click to Attach a Recording'
    : `Attach Recording (${attachedVideos.length} attached)`;
  return (
    <>
      {/* 
          ? why are we setting display: to 'flex' || 'none' instead of dynamically rendering?
          With dynamically rendering, if you click on a button that will immediately after not be rendered
          (i.e. clicking a button to change the view), the closeDropdownsOnClickOutside will think that you
          clicked outside of the container (because the area you clicked is not contained by the container 
          anymore since it was not rendered).

          Instead, sing some css trickery to trigger the unpublish flow.
          
          If you have a better idea for this handling, please fix :) */}
      <div style={{ display: isUnpublishing && isPublic ? 'flex' : 'none' }}>
        <WarningBlock
          cancelWarning={() => toggleUnpublishProject(false)}
          completeWarning={completeUnpublish}
          headerText="Are you sure you wish to unpublish this project?"
          subHeaderText={`People who you've shared this project with will no longer have access
          to it.`}
          primaryActionText="Unpublish"
        />
      </div>
      <div
        style={{ display: isUnpublishing ? 'none' : 'flex' }}
        className="jideshare-sp"
      >
        {featuredProject && (
          <div className="jideshare-sp-featured-section">
            <p>
              <strong>Congratulations!</strong> Your project has been featured by
              Juni. We took a snapshot of your published project on{' '}
              {parseMoment(featuredProject.createdAt).format('L')}. Click{' '}
              <Link
                to={`/juniverse/student_project/${featuredProject._id}`}
                target="_blank"
              >
                here
              </Link>{' '}
              to see your featured project, and{' '}
              <a href="mailto:support@junilearning.com">email</a> us with any
              questions!
            </p>
          </div>
        )}
        {isPublic && studentProjectId && !unsavedChanges && (
          <div className="jideshare-sp-share-link">
            <JideShareLargeText text="Your project is published!" />
            <JideShareLinkButtons
              studentProjectId={studentProjectId}
              viewKey={viewKey}
            />
          </div>
        )}
        <div className="jideshare-sp-upper">
          <JideShareUsernameContainer
            username={username}
            header={isPublic ? 'Edit this project' : 'Publish this project'}
          />
          <div className="project-name__container">
            <p>Project Name</p>
            <JideShareInputTextBox
              value={title}
              onChange={titleOnChange}
              errorMsg={titleErrorMsg}
            />
          </div>

          <div className="jideshare-rec-container">
            <p>{attachRecordingString}</p>
            <JideShareProjectRecordings
              projectVideos={projectVideos}
              attachedVideos={attachedVideos}
              toggleAttachedVideo={toggleAttachedVideo}
              switchToRecStudio={switchToRecStudio}
            />
          </div>

          {!isPublic && (
            <div className="flex flex-col w-full px-4 py-2">
              <div className="font-semibold mb-2">Copy Private Link</div>
              <LinkCopier
                referralCode={unlistedProjectPageLink}
                onCopy={handleCopyLink}
                skipLowerCase
              />
            </div>
          )}

          <div className="jide-submit__container">
            <JideShareSubmitButton
              onClick={saveStudentProject}
              isSubmitLoading={isSubmitLoading}
              unsavedChanges={isPublic ? unsavedChanges : true}
              username={username}
              studentProjectId={studentProjectId}
              isPublic={isPublic}
            />
            <button
              style={{ display: isPublic ? 'block' : 'none' }}
              onClick={() => toggleUnpublishProject(!isUnpublishing)}
              id="unpublish"
            >
              Unpublish this project
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const JideShareProjectRecordings = ({
  projectVideos,
  attachedVideos,
  toggleAttachedVideo,
  switchToRecStudio,
}) => {
  const previewElems = projectVideos.map(pv => (
    <JRSModalPreview
      key={pv._id}
      onClick={() => {
        toggleAttachedVideo(pv._id);
      }}
      selected={attachedVideos.includes(pv._id)}
      src={pv.s3Url}
      title={pv.title}
      createdAt={pv.createdAt}
      originalLength={pv.originalLength}
    />
  ));

  const createNew = (
    <div
      key="createNew"
      className="jideshare-rec-new jrs-modal-preview"
      onClick={switchToRecStudio}
    >
      <div className="jideshare-rec-new-upper">
        <FontAwesomeIcon icon={['fas', 'plus']} />
      </div>
      <div className="jideshare-rec-new-lower">Create New</div>
    </div>
  );

  return (
    <div className={`jideshare-rec${projectVideos.length ? '' : '-initial'}`}>
      {[createNew, ...previewElems]}
    </div>
  );
};

const JideShareLargeText = ({ text }) => (
  <div className="jideshare-large-text">{text}</div>
);

const JideShareInputTextBox = ({ value, onChange, errorMsg }) => (
  <div className="jideshare-input-text-box-wrapper mb-2">
    <div className="jideshare-input-text-box-errormsg">{errorMsg}</div>
    <input
      className="jideshare-input-text-box"
      type="text"
      value={value}
      onChange={onChange}
      spellCheck={false}
    />
  </div>
);

const JideShareUsernameContainer = ({ username, header }) => (
  <div className="username__container">
    <div className="top">
      <h2 className="username-header">{header}</h2>
      {username && (
        <Link
          to={`/juniverse/users/${username}`}
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <div className="username-group">
            <span className="username">
              Your username: <strong>{username}</strong>
            </span>
            <span className="profile-link">
              Visit your profile <FontAwesomeIcon icon={['fas', 'angle-right']} />
            </span>
          </div>
        </Link>
      )}
    </div>
    <p>
      Get an easy link to share with family & friends (no login required), or add the
      project to your profile to get more views!
    </p>
  </div>
);

const JideShareSubmitButton = ({
  onClick,
  isSubmitLoading,
  unsavedChanges,
  username,
  studentProjectId,
  isPublic,
}) => {
  const text = isSubmitLoading ? (
    <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
  ) : !username ? (
    'Submit Username'
  ) : unsavedChanges && (!studentProjectId || !isPublic) ? (
    'Add to Public Profile'
  ) : unsavedChanges && studentProjectId ? (
    'Save Changes'
  ) : (
    'Saved'
  );
  return (
    <div
      className={`jideshare-submit-button${
        unsavedChanges ? ' jideshare-unsaved' : ' jideshare-saved'
      }`}
      onClick={() => {
        if (unsavedChanges) onClick();
      }}
    >
      <span>{text}</span>
      <Arrow orientation="right" className="jideshare__icons" />
    </div>
  );
};

const JideShareLinkButtons = ({ studentProjectId }) => {
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [copyButtonIcon, setCopyButtonIcon] = useState('copy');
  const link = `/juniverse/projects/${studentProjectId}`;
  const fullLink = `${window.location.origin}${link}`;
  return (
    <div className="jideshare-link">
      <input className="jideshare-link-left" value={fullLink} readOnly />
      <div className="jideshare-link-right">
        <button
          className="jideshare__button"
          onClick={() => {
            const originalText = copyButtonText;
            const originalIcon = copyButtonIcon;
            setCopyButtonText('Copied!');
            setCopyButtonIcon('check');
            setTimeout(() => {
              setCopyButtonText(originalText);
              setCopyButtonIcon(originalIcon);
            }, 2000);
          }}
        >
          <CopyToClipboard text={fullLink} options={{ format: 'text/plain' }}>
            <div className="jideshare-link-button">
              <span>{copyButtonText}</span>
              <div className="jideshare__icons icon">
                <FontAwesomeIcon icon={['fas', copyButtonIcon]} />
              </div>
            </div>
          </CopyToClipboard>
        </button>
        <Link className="jideshare__button" to={link} target="_blank">
          <div className="jideshare-link-button">
            <span>Visit</span>
            <Arrow orientation="right" className="jideshare__icons" />
          </div>
        </Link>
      </div>
    </div>
  );
};

JideShareButton.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  tab: PropTypes.shape({}).isRequired,
  isCustomProject: PropTypes.bool,
  toggleSiblingWidgets: PropTypes.func,
  defaultTitle: PropTypes.string,
};
export default JideShareButton;
