import React from 'react';
import { Redirect } from 'react-router-dom';

import { ROADMAP_BUNDLE_GROUPS } from 'constants/tracks';
import { Student } from 'models';

import { CourseDetail, Roadmap } from './components';

interface LearnerRoadmapProps {
  roadmapLinkBase: string;
  subjectName?: string;
  courseName?: string;
  student: Student;
}

const LearnerRoadmap: React.FC<LearnerRoadmapProps> = ({
  roadmapLinkBase,
  subjectName,
  courseName,
  student,
}) => {
  const filteredBundleGroups = subjectName
    ? ROADMAP_BUNDLE_GROUPS.filter(group => group.subject === subjectName)
    : [];
  if (subjectName) {
    if (!filteredBundleGroups.length) {
      return <Redirect to={roadmapLinkBase} />;
    }
    if (courseName) {
      return (
        <CourseDetail
          roadmapLinkBase={roadmapLinkBase}
          subjectName={subjectName}
          courseName={courseName}
          studentId={student._id}
          studentFirstName={student.firstName}
        />
      );
    }
  }
  const bundleGroups = filteredBundleGroups.length
    ? filteredBundleGroups
    : ROADMAP_BUNDLE_GROUPS;
  return (
    <Roadmap
      studentId={student._id}
      roadmapLinkBase={roadmapLinkBase}
      bundleGroups={bundleGroups}
      studentCompletedTracks={student.completedTracks || []}
      studentInProgressTracks={[
        ...(student.track ? [student.track] : []),
        ...(student.tracks || []),
      ]}
    />
  );
};

export default LearnerRoadmap;
