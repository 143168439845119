import React from 'react';

interface ClubMessagesZeroStateProps {
  channelName: string;
}

const ClubMessagesZeroState: React.FC<ClubMessagesZeroStateProps> = ({
  channelName,
}) => (
  <div className="flex flex-col justify-center items-center p-4 text-center text-blue-gray-600">
    <div className="text-3xl">
      <span>
        Welcome to <b>#{channelName}</b>
      </span>
    </div>
    <div className="mt-5 mb-5">
      No messages have been posted yet. <br /> Start the discussion below!
    </div>
  </div>
);

export default ClubMessagesZeroState;
