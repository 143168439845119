import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './index.module.css';
import { Icon } from '..';

export interface MessageProps {
  /**
   * Child elements that this component can wrap, usually containing the message text.
   */
  children?: React.ReactNode;
  /**
   * Specific type of message to display.
   */
  status?: 'success' | 'error' | 'warning' | 'info';
  /**
   * Optional prop enabling additional styling.
   */
  className?: string;
}

function getIcon(status: string) {
  switch (status) {
    case 'error':
      return <Icon.Error className={styles.icon} fill="#BB2222" />;
    case 'success':
      return <Icon.Success className={styles.icon} fill="#103f0d" />;
    case 'warning':
      return <Icon.Warning className={styles.icon} fill="#584114" />;
    case 'info':
      return <Icon.Info className={styles.icon} fill="#6e6e6e" />;
    default:
      return null;
  }
}

/**
 * Component that displays messages, often dependent on results
 * from API calls.
 */
const Message: FC<MessageProps> = ({ children, className, status }) => {
  if (!status) return null;
  const icon = getIcon(status);
  return (
    <div
      className={classNames(
        {
          [styles.message]: true,
          [styles.success]: status === 'success',
          [styles.error]: status === 'error',
          [styles.warning]: status === 'warning',
          [styles.info]: status === 'info',
          [styles.withIcon]: !!Icon,
        },
        className,
      )}
    >
      {icon}
      {children}
    </div>
  );
};

export default Message;
