import React, { useEffect } from 'react';

import CuteProgressBar from 'components/CuteProgressBar';
import { JRSModalLine } from './subcomponents';

import '../styles/jide_rec_studio.css';

// This modal shows the upload progress by reading from the overallUploadProgress
// state var from recorderUploader. It is used to render the CuteProgressBar
// component, plus some warning messages
const JRSUploadProgress = ({ setMode, overallUploadProgress }) => {
  useEffect(() => {
    if (overallUploadProgress >= 1.0) {
      setMode('viewRecordings');
    }
  }, [overallUploadProgress, setMode]);

  const pct = Math.floor(overallUploadProgress * 100);
  return (
    <div className="jrs-modal jrs-uploadprogress">
      <div className="jrs-modal-section jrs-column">
        <div className="jrs-modal-heading2">SAVING YOUR RECORDING</div>
        <CuteProgressBar pct={pct} />
        <JRSModalLine
          icon="hourglass-half"
          boldText="Do not close page or navigate away!"
          text="Doing so may prevent your recording from being properly saved"
        />
      </div>
    </div>
  );
};

export default JRSUploadProgress;
