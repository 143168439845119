import { DCF_DEFAULT_ENTRY_POINT } from 'constants/links';
import { REFERRAL_DISCOUNT_DATA } from 'constants/signup_sessions';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import useReferralCode from '../useReferralCode';
import useSignupContext from '../useSignupContext';

const useReferralCTA = (code?: string) => {
  const { signupData, setSignupSession } = useSignupContext();
  const promises: Array<Promise<any>> = [];

  const referralCodeFromSession = signupData.discountCodes?.find(
    code => code.isReferral,
  );

  const {
    isLoading,
    isValid,
    referrerName,
    referralCode: discountCode,
  } = useReferralCode(code ?? referralCodeFromSession?.discountCode);

  const onClaim = async ({
    componentName,
    referralCode,
  }: {
    componentName: string;
    referralCode?: string;
  }) => {
    promises.push(
      JuniAnalytics.trackWithDelay(`${componentName}_clicked`, {
        referralCode,
        eventClass: 'start_trial_button',
      }),
    );

    // add it to the session if it's valid
    if (isValid) {
      promises.push(
        setSignupSession({
          discountCodes: referralCodeFromSession
            ? (signupData.discountCodes ?? []).map(discount =>
                discount.isReferral
                  ? {
                      ...REFERRAL_DISCOUNT_DATA,
                      discountCode,
                      referrerName,
                    }
                  : discount,
              )
            : [
                ...(signupData.discountCodes ?? []),
                {
                  ...REFERRAL_DISCOUNT_DATA,
                  discountCode,
                  referrerName,
                },
              ],
        }),
      );
    }

    // wait for _both_ the track call and the signup session api call to complete
    await Promise.all(promises);
    window.location.href = `${DCF_DEFAULT_ENTRY_POINT}${
      window.location.search.length > 1 ? `&${window.location.search.slice(1)}` : ''
    }`;
  };

  return {
    isLoading,
    isValid,
    referrerName,
    referralCode: discountCode,
    onClaim,
  };
};

export default useReferralCTA;
