import styled from 'styled-components/macro';

export const FilterDropdownWrapper = styled.div<any>`
  cursor: pointer;
  background: #fff;
  margin-left: auto;
  border-radius: 5px;
  ${props =>
    props.dropdownOpen
      ? `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;`
      : ''}

  .icon {
    vertical-align: middle;
  }
  :hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }
`;

export const SessionTypeList = styled.ul`
  position: absolute;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 24px 20px rgba(136, 142, 146, 0.28);
  border-radius: 10px;
  margin: 0;
  padding: 0;
  right: 0;
  border-top-right-radius: 0;
  list-style-type: none;

  li {
    border-bottom: 1px solid hsl(194, 39%, 85%);
  }
  li:first-child, li:first-child button {
    border-top-left-radius: inherit;
  }
  li:last-child, li:last-child button {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-bottom: none;
  }

  button {
    padding: 1rem;
    width: 100%;
    background: none;
    color: #00a5ff;
    font-size: 0.8rem;
    text-transform: none;
    text-align: right;
    font-family 'Open Sans', sans-serif;
    font-weight: 600;
  }
  button:hover {
    background: #00a5ff;
    color: #fff;
  }
`;
