import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.css';

type CapWidthSizes = 'medium' | 'large' | 'x-large' | 'xx-large';

export interface LayoutProps {
  title?: string;
  className?: string;
  contentClass?: string;
  children?: React.ReactNode;
  capWidth?: boolean;
  capWidthSize?: CapWidthSizes;
  fullHeight?: boolean;
}

const Layout: FC<LayoutProps> = ({
  /**
   * Title of the view.
   */
  title,
  /**
   * Optional prop enabling additional styling.
   */
  className,
  /**
   * Class name for component's wrapped content.
   */
  contentClass,
  /**
   * Child elements that this component can wrap, usually containing a page view.
   */
  children,
  /**
   * Toggles whether or not the layout has a max-width property automatically applied.
   */
  capWidth,
  /**
   * Size of layout's max-width property.
   */
  capWidthSize = 'medium',
  /**
   * Toggles full height.
   */
  fullHeight,
}) => (
  <div
    className={classNames(
      { 'ui-layout': true, 'ui-full-height-layout': fullHeight },
      className,
    )}
  >
    <div
      className={classNames(
        {
          'ui-layout-content': true,
          'ui-full-height-content': true,
          'ui-cap-width': capWidth,
        },
        capWidth && `ui-cap-width-${capWidthSize}`,
        contentClass,
      )}
    >
      {title && <h1 className="ui-layout-title">{title}</h1>}
      {children}
    </div>
  </div>
);

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  capWidth: PropTypes.bool,
  capWidthSize: PropTypes.oneOf(['medium', 'large', 'x-large', 'xx-large']),
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  contentClass: PropTypes.string,
};

export default Layout;
