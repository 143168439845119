import React, { FC } from 'react';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'constants/timezones';

import { Chevron } from 'components/Icons';
import { CalendarIcon } from 'components/PortalIcons';
import { OpenChallengeFragment } from 'generated/graphql';

import {
  ActionLink,
  ActionLinkPlaceholder,
  CalendarIconWrapper,
  ChallengeCardFooter,
  ChallengeCardHeader,
  ChallengeCardWrapper,
  ChallengeDatePairWrapper,
  ChallengeDateWrapper,
  ChallengeDescription,
  ChallengeDetailsWrapper,
  ChallengeImageBanner,
  ChallengeTitle,
} from './styles';

const getFormattedDateString = (date: string) =>
  moment(date).tz(DEFAULT_TIMEZONE).format('M/D/YYYY');

interface ChallengeDateProps {
  heading: string;
  date: string;
}

const ChallengeDate: FC<ChallengeDateProps> = ({ heading, date }) => (
  <ChallengeDateWrapper>
    <CalendarIconWrapper>
      <CalendarIcon />
    </CalendarIconWrapper>
    <span>
      {heading}: <b>{date}</b>
    </span>
  </ChallengeDateWrapper>
);

interface ChallengeLinkProps {
  href: string;
  linkText: string;
}

const ChallengeLink: FC<ChallengeLinkProps> = ({ href, linkText }) => (
  <ActionLink href={href} target="_blank" rel="noopener noreferrer">
    <span>{linkText}</span> <Chevron orientation="right" />
  </ActionLink>
);

interface ChallengeCardProps {
  challenge: OpenChallengeFragment;
}

const ChallengeCard: FC<ChallengeCardProps> = ({ challenge }) => {
  const {
    title,
    description,
    openDate,
    registrationDeadline,
    submissionDeadline,
    thumbnailUrl,
    registrationLink,
    resultsLink,
  } = challenge;
  const openDateString = getFormattedDateString(openDate);
  const registrationDeadlineString = getFormattedDateString(registrationDeadline);
  const submissionDeadlineString = getFormattedDateString(submissionDeadline);
  const isNotOpenYet = moment().isBefore(openDate);
  const isClosedForRegistration = moment().isAfter(registrationDeadline);
  const isClosedForSubmission = moment().isAfter(submissionDeadline);
  return (
    <ChallengeCardWrapper>
      <ChallengeCardHeader>
        <ChallengeImageBanner>
          <img src={thumbnailUrl || ''} alt="" />
        </ChallengeImageBanner>
        <ChallengeTitle>{title}</ChallengeTitle>
        <ChallengeDescription>{description}</ChallengeDescription>
      </ChallengeCardHeader>
      <ChallengeCardFooter>
        {isNotOpenYet ? (
          <>
            <ChallengeDate
              heading="This challenge opens for registration on"
              date={openDateString}
            />
            <ActionLinkPlaceholder>
              Registration will open soon!
            </ActionLinkPlaceholder>
          </>
        ) : isClosedForSubmission ? (
          <>
            <ChallengeDate
              heading="This challenge ended on"
              date={submissionDeadlineString}
            />
            {resultsLink ? (
              <ChallengeLink
                href={resultsLink}
                linkText="View Results for this Challenge"
              />
            ) : (
              <ActionLinkPlaceholder>
                Results will be available soon!
              </ActionLinkPlaceholder>
            )}
          </>
        ) : (
          <ChallengeDetailsWrapper>
            <p>Challenge Details</p>
            <ChallengeDatePairWrapper>
              <ChallengeDate
                heading="Registration Deadline"
                date={registrationDeadlineString}
              />
              <ChallengeDate
                heading="Entry Deadline"
                date={submissionDeadlineString}
              />
            </ChallengeDatePairWrapper>
            {isClosedForRegistration ? (
              <ActionLinkPlaceholder>
                Results will be available after the entry deadline!
              </ActionLinkPlaceholder>
            ) : (
              <ChallengeLink
                href={registrationLink}
                linkText="Register for this Challenge"
              />
            )}
          </ChallengeDetailsWrapper>
        )}
      </ChallengeCardFooter>
    </ChallengeCardWrapper>
  );
};

export default ChallengeCard;
