import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.module.css';

export enum TooltipPosition {
  Below,
  Right,
}

export interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  position?: TooltipPosition;
}

/**
 * Wraps an element with a tooltip.
 */
const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  className,
  contentClassName,
  position = TooltipPosition.Below,
}) => (
  <div className={classNames(styles.tooltip, className)}>
    {children}
    <div
      className={classNames(
        styles.tooltipContent,
        {
          [styles.below]: position === TooltipPosition.Below,
          [styles.right]: position === TooltipPosition.Right,
        },
        contentClassName,
      )}
    >
      {content}
    </div>
  </div>
);

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
};

export default Tooltip;
