import { StateMachine, ActionObject, State } from 'xstate';
import defaultRouter from './machines/defaultNavigation';

export type Context = Record<string, any>;

export const ROUTE_EVENT = {
  SUBMIT: { type: 'SUBMIT' },
  LOAD: { type: 'LOAD' },
  BACK: { type: 'BACK' },
  EDIT: { type: 'EDIT' },
};

export const ROUTER_TYPE = {
  DEFAULT: defaultRouter.id,
};

export type RouteEvent =
  | typeof ROUTE_EVENT.SUBMIT
  | typeof ROUTE_EVENT.LOAD
  | typeof ROUTE_EVENT.BACK
  | typeof ROUTE_EVENT.EDIT;

export type Router = StateMachine<
  Context,
  any,
  RouteEvent,
  {
    value: any;
    context: Context;
  },
  ActionObject<Context, RouteEvent>
>;

export type RouterState = State<
  Context,
  RouteEvent,
  any,
  {
    value: any;
    context: Context;
  }
>;
