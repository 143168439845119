import juniAxios from 'services/axios';

export async function getPageCount(boardId) {
  const pageIndex = boardId.indexOf('/page');
  const rootBoardId = pageIndex !== -1 ? boardId.substring(0, pageIndex) : boardId;
  const pageCount = (
    await juniAxios.get(
      `/api_whiteboards/page_count/${encodeURIComponent(rootBoardId)}`,
    )
  ).data;
  return pageCount;
}

export const initWhiteboardInfo = async (boardId, studentId) => {
  const pageIndex = boardId.indexOf('/page');
  const rootBoardId = pageIndex !== -1 ? boardId.substring(0, pageIndex) : boardId;
  const payload = {
    rootBoardId,
    studentId,
  };
  return juniAxios.post(`/api_whiteboards/`, payload).then(res => res.data);
};

export const addWhiteboardPage = async boardId => {
  const pageIndex = boardId.indexOf('/page');
  const rootBoardId = pageIndex !== -1 ? boardId.substring(0, pageIndex) : boardId;
  return juniAxios
    .post(`/api_whiteboards/add_page/${encodeURIComponent(rootBoardId)}`, {})
    .then(res => res.data);
};
