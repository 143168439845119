import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'core-components';

import styled from 'styled-components/macro';
import EmojiPicker from 'components/EmojiPicker';
import { MAX_CHARACTERS_CLUB_MESSAGE } from 'utils/validation';

import useDimensions from 'hooks/dimensions';
import useClickOutside from 'hooks/outside';
import { logError } from 'utils/errors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';
import classNames from 'classnames';
import { blueGray } from 'theme/old-colors';
import { useDeleteS3ObjectMutation, UploadDestination } from 'generated/graphql';
import { IMeta } from 'react-dropzone-uploader';
import SpinnerV2 from 'components/SpinnerV2';
import ChatImageUpload from './ChatImageUpload';

// Important note: To avoid overflow, the ChatMessageComposer resizes the textarea
// whenever there is a change in the message inputted by the user or the dimensions
// of the viewport. If you make any adjustments to the layout of this component,
// ensure that you update the height computation in the effect hook as needed.

const LINE_HEIGHT_IN_PX = 24;
const MAX_LINES_IN_TEXTAREA = 16;

const TEXT_AREA_WRAPPER_PADDING_IN_PX = 10;
const TEXT_AREA_WRAPPER_BORDER_WIDTH_IN_PX = 1;

const TEXT_AREA_WRAPPER_OFFSET =
  (TEXT_AREA_WRAPPER_PADDING_IN_PX + TEXT_AREA_WRAPPER_BORDER_WIDTH_IN_PX) * 2;

const CLUB_IMAGE_UPLOAD_REGEX = /(?:.*\/\/[^/]*\/)(clubs\/.*)/;

const DeleteThumbnailIcon = styled(FontAwesomeIcon)`
  color: ${blueGray[200]};
  :hover {
    color: ${blueGray[400]};
  }
`;

interface DownloadMeta extends IMeta {
  downloadUrl?: string;
}
interface ChatMessageComposerThumbnailProps {
  meta: DownloadMeta;
  progress?: number;
  onDelete: (id: string) => void;
}

const ChatMessageComposerThumbnail: React.FC<ChatMessageComposerThumbnailProps> = ({
  meta,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    onDelete(meta.id);
  };
  return (
    <div className="col-span-1 relative">
      <div className="absolute right-0 top-0">
        <DeleteThumbnailIcon icon={faTimesCircle} size="lg" onClick={handleClick} />
      </div>
      <div className="pt-3 pr-3">
        {meta.percent < 100 || loading ? (
          <div className="object-center">
            <SpinnerV2 size={25} />
          </div>
        ) : (
          <img
            className="object-contain max-h-24 h-auto w-full"
            src={meta.previewUrl}
            alt={meta.name}
          />
        )}
      </div>
    </div>
  );
};

interface ChatMessageComposerProps {
  chatContainerRef: React.RefObject<HTMLDivElement>;
  placeholder: string;
  sendMessage: (message: string, imageUrls: string[]) => void;
  disableImageUpload?: boolean;
}

const ChatMessageComposer: React.FC<ChatMessageComposerProps> = ({
  chatContainerRef,
  placeholder,
  sendMessage,
  disableImageUpload,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imagesForUpload, setImagesForUpload] = useState(
    {} as { [k: string]: DownloadMeta },
  );

  const { height, width } = useDimensions();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const composerToolBarRef = useRef<HTMLDivElement>(null);
  const composerStatusBarRef = useRef<HTMLDivElement>(null);
  const emojiPickerWrapperRef = useRef<HTMLDivElement>(null);
  const [deleteClubImageUploads] = useDeleteS3ObjectMutation();

  const deleteUploadFromDraft = useCallback(
    async imageId => {
      imagesForUpload[imageId].status = 'aborted';
      const imageUrl = imagesForUpload[imageId].downloadUrl;
      const match = imageUrl && imageUrl.match(CLUB_IMAGE_UPLOAD_REGEX);
      const key = match && match[1];
      try {
        if (key) {
          await deleteClubImageUploads({
            variables: {
              input: {
                destination: UploadDestination.ClubUserImages,
                object: key,
              },
            },
          });
        }
      } catch (err) {
        logError(err);
      }
      setImagesForUpload(_.omit(imagesForUpload, [imageId]));
    },
    [deleteClubImageUploads, setImagesForUpload, imagesForUpload],
  );

  useClickOutside(emojiPickerWrapperRef, () => {
    setShowEmojiPicker(false);
  });

  const handleNewUpload = (url: string, meta: IMeta) => {
    setImagesForUpload({ ...imagesForUpload, [meta.id]: { ...meta } });
  };
  useEffect(() => {
    const { current } = textAreaRef;
    if (!current) {
      return;
    }
    const [chatContainerHeight, composerToolBarHeight, composerStatusBarHeight] = [
      chatContainerRef,
      composerToolBarRef,
      composerStatusBarRef,
    ].map(ref => ref.current?.getBoundingClientRect().height || 0);
    const borderAndPaddingOffset =
      TEXT_AREA_WRAPPER_OFFSET +
      (chatContainerRef.current?.offsetHeight || 0) -
      (chatContainerRef.current?.clientHeight || 0);
    const roomLeftInChatContainer =
      chatContainerHeight -
      composerToolBarHeight -
      composerStatusBarHeight * 2 -
      borderAndPaddingOffset;
    current.style.height = 'inherit';
    current.style.height = `${Math.max(
      LINE_HEIGHT_IN_PX,
      Math.min(
        current.scrollHeight,
        roomLeftInChatContainer,
        LINE_HEIGHT_IN_PX * MAX_LINES_IN_TEXTAREA,
      ),
    )}px`;
  }, [chatContainerRef, message, height, width]);

  const trimmedMessage = message.trim();
  const isBlank = trimmedMessage.length === 0;
  const numExcessCharacters = trimmedMessage.length - MAX_CHARACTERS_CLUB_MESSAGE;
  const imageUrls = _.map(imagesForUpload, imgMeta => imgMeta.downloadUrl || '');
  const finishedUploads = _.filter(
    imagesForUpload,
    imgMeta => imgMeta.status === 'removed',
  );
  const canSendMessage = Boolean(
    (!isBlank && numExcessCharacters <= 0) ||
      (imageUrls.length && imageUrls.length === finishedUploads.length),
  );

  // TODO: add proper debouncing
  const handleSendMessage = () => {
    if (canSendMessage && !isDisabled) {
      setIsDisabled(true);
      sendMessage(trimmedMessage, imageUrls);
      setImagesForUpload({});
      setMessage('');
      setTimeout(() => {
        setIsDisabled(false);
      }, 500);
    }
  };

  return (
    <div className="px-6">
      <div
        className={classNames(
          'relative',
          'bottom-0',
          'rounded-t',
          'border-solid',
          'border-blue-gray-200',
          'focus-within:border-juni-blue-500',
        )}
        style={{
          borderWidth: `${TEXT_AREA_WRAPPER_BORDER_WIDTH_IN_PX}px`,
          padding: `${TEXT_AREA_WRAPPER_PADDING_IN_PX}px`,
        }}
      >
        <div className="grid grid-cols-10 mb-1 max-h-28">
          {_.map(imagesForUpload, (value, key) => (
            <ChatMessageComposerThumbnail
              meta={value}
              onDelete={deleteUploadFromDraft}
              key={key}
            />
          ))}
        </div>
        <textarea
          ref={textAreaRef}
          className={classNames(
            'w-full',
            'leading-6',
            'text-base',
            'font-sans',
            'resize-none',
            'outline-none',
            'border-none',
            'm-0',
            'p-0',
          )}
          rows={1}
          value={message}
          placeholder={placeholder}
          onChange={e => setMessage(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              handleSendMessage();
              e.preventDefault();
            }
          }}
        />
      </div>
      <div
        ref={composerToolBarRef}
        className={classNames(
          'p-1',
          'flex',
          'flex-row',
          'items-center',
          'justify-between',
          'rounded-b',
          'border-t-0',
          'border',
          'border-solid',
          'border-blue-gray-200',
        )}
      >
        <div
          className={classNames(
            { invisible: isBlank },
            'pl-1',
            'space-x-2',
            'text-xs',
            'text-blue-gray-400',
          )}
        >
          <b>**bold**</b>
          <i>_italics_</i>
          <span>~~strike~~</span>
          <code>`code`</code>
          <code>```preformatted```</code>
          <span>{'>'}quote</span>
        </div>
        <div className="flex">
          <div ref={emojiPickerWrapperRef} className="relative">
            {showEmojiPicker && (
              <div className="absolute bottom-8 right-0">
                <EmojiPicker
                  onEmojiClick={({ detail }) =>
                    setMessage(
                      prevMessage => `${prevMessage}${detail.unicode || ''}`,
                    )
                  }
                />
              </div>
            )}
            <Button
              size="small"
              variant="transparent"
              title="Pick Emoji"
              onClick={() => setShowEmojiPicker(state => !state)}
            >
              <FontAwesomeIcon icon={faSmile} color={blueGray[800]} size="lg" />
            </Button>
          </div>
          {!disableImageUpload && (
            <div className="relative pr-1">
              <ChatImageUpload
                onChange={handleNewUpload}
                disabled={Object.keys(imagesForUpload).length >= 10}
              />
            </div>
          )}
          <Button
            disabled={!canSendMessage}
            size="small"
            variant={!canSendMessage ? 'transparent' : 'primary'}
            title="Send Message"
            onClick={handleSendMessage}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              color={!canSendMessage ? blueGray[200] : 'white'}
              size="lg"
            />
          </Button>
        </div>
      </div>
      <div
        ref={composerStatusBarRef}
        className={classNames(
          {
            invisible: isBlank,
            'text-blue-gray-400': canSendMessage,
            'text-red-600': !canSendMessage,
          },
          'flex',
          'pr-1',
          'pt-1',
          'pb-1.5',
          'space-x-3',
          'justify-end',
          'text-xs',
        )}
      >
        {canSendMessage ? (
          <>
            <span>
              <b>Enter</b> to send
            </span>
            <span>
              <b>Shift + Enter</b> to add a new line
            </span>
          </>
        ) : (
          <span>
            <b>
              {numExcessCharacters} character{numExcessCharacters !== 1 ? 's' : ''}{' '}
              over the limit
            </b>
          </span>
        )}
      </div>
    </div>
  );
};

export default ChatMessageComposer;
