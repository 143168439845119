import React, { useState } from 'react';

import { useParentCornerInstructorCardsForStudentQuery } from 'generated/graphql';
import { Student } from 'models';
import { NewButton as Button, NewCard as Card } from 'core-components';
import { ErrorableLoading, ReactSelectField } from 'components/ui';
import { Link } from 'react-router-dom';

interface Props {
  student: Student;
}

const Instructors: React.FC<Props> = ({ student }) => {
  const { _id: studentId } = student;

  const [selectedInstructorIndex, setSelectedInstructorIndex] = useState(0);

  const {
    data: instructorsForStudentData,
    loading: loadingInstructorsForStudent,
    error: errorInstructorsForStudent,
  } = useParentCornerInstructorCardsForStudentQuery({ variables: { studentId } });
  const instructors =
    instructorsForStudentData?.parentCornerInstructorCardsForStudent.items || [];

  const Header = () => (
    <>
      <div className="mb-4 text-xl font-medium text-j-dark-600">
        {student.firstName}'s Instructors
      </div>
      <Card.Divider />
    </>
  );

  if (loadingInstructorsForStudent || errorInstructorsForStudent)
    return (
      <Card shadow="1">
        <Header />
        <ErrorableLoading error={errorInstructorsForStudent} />
      </Card>
    );

  return (
    <Card shadow="1">
      <Header />
      <div className="flex flex-col gap-6 mt-8 text-j-dark-600">
        {instructors.length > 0 &&
          instructors
            .slice(selectedInstructorIndex, selectedInstructorIndex + 1)
            .map(instructor => (
              <div key={instructor._id} className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <div className="md:w-64">
                    {instructors.length > 1 && (
                      <ReactSelectField
                        options={instructors.map((instructor, i) => ({
                          value: i,
                          label: `${instructor.firstName} ${instructor.lastName}`,
                        }))}
                        onChange={setSelectedInstructorIndex}
                        value={selectedInstructorIndex}
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            padding: '0.5rem 0',
                          }),
                          singleValue: (provided: any) => ({
                            ...provided,
                            fontSize: '1.125rem',
                            fontWeight: '500',
                            color: 'rgb(41, 37, 99)',
                          }),
                        }}
                      />
                    )}
                    {instructors.length === 1 && (
                      <div className="text-lg font-medium">
                        {`${instructor.firstName} ${instructor.lastName}`}
                      </div>
                    )}
                  </div>
                  <div className="text-base">{instructor.bio}</div>
                </div>

                {instructor.achievements.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <div className="text-lg font-medium">Recent Achievements</div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      {instructor.achievements.slice(0, 2).map(achievement => (
                        <div
                          key={achievement._id}
                          className="flex gap-4 p-4 rounded-lg bg-j-gray-200"
                        >
                          <div>
                            <img
                              className="bg-j-gray-400 rounded-lg p-2 w-12"
                              src={achievement.thumbnailUrl}
                              alt={achievement.name}
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <div className="text-sm font-medium">
                              {achievement.name}
                            </div>
                            <div className="text-xs">
                              {achievement.externalDescription}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-4">
                  <div className="text-lg font-medium">
                    Give feedback to {instructor.firstName}
                  </div>
                  <Link
                    to={`/learner/${studentId}/session_notes`}
                    target="_blank"
                    className="no-underline"
                  >
                    <Button variant="secondary" size="small">
                      Click Here!
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
        {instructors.length === 0 && (
          <div>
            Instructors will appear here once they have taught a class with{' '}
            {student.firstName}.
          </div>
        )}
      </div>
    </Card>
  );
};

export default Instructors;
