import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

import './index.css';

const SortableItemComponent = SortableElement(({ value, renderValue }) => (
  <div className="ui-sortable-list-item">{renderValue(value)}</div>
));

const SortableListComponent = SortableContainer(
  ({ className, items, renderValue, getKeyFromValue }) => (
    <div className={classNames('ui-sortable-list', className)}>
      {items.map((value, index) => (
        <SortableItemComponent
          key={getKeyFromValue(value)}
          helperClass="ui-sortable-list-dragged-item"
          lockAxis
          index={index}
          value={value}
          renderValue={renderValue}
        />
      ))}
    </div>
  ),
);

const SortableList = ({
  className,
  items,
  renderValue = val => val,
  getKeyFromValue = val => val,
  onSortEnd,
}) => (
  <SortableListComponent
    helperClass="ui-dragging"
    className={className}
    items={items}
    renderValue={renderValue}
    getKeyFromValue={getKeyFromValue}
    onSortEnd={onSortEnd}
  />
);

SortableList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  renderValue: PropTypes.func,
  getKeyFromValue: PropTypes.func,
  onSortEnd: PropTypes.func,
};

export default SortableList;
