import styled from 'styled-components/macro';
import { Button } from 'components/ui';

export const OpenButton = styled(Button)`
  display: block;
  margin: 1.5rem auto 0;
  text-transform: none !important;
  :hover {
    text-decoration: none !important;
  }
`;

export const SubmitButton = styled(Button)`
  display: block;
  max-width: 10rem;
  margin-left: auto;
  padding-left: 2.5rem !important;
  font-size: 0.9rem !important;
`;

export const BackButton = styled(Button)`
  display: block;
  margin: 1rem auto 0;
  padding-left: 3.5rem;
  font-size: 0.9rem;
`;

export const FeedbackTextArea = styled.textarea`
  border: 2px solid #00a5ff;
  box-sizing: border-box;
  box-shadow: inset 0px 4.28837px 14.5805px rgba(41, 95, 124, 0.05);
  border-radius: 8px;
  padding: 1rem;
  font-family: Open Sans, sans-serif;
  font-size: 0.9rem;
  width: 100%;
`;

export const CheckmarkWrapper = styled.div`
  text-align: center;
  img {
    border: 2px solid #d1eaf7;
    background: #f3fbfd;
    border-radius: 50%;
    width: 50%;
    max-width: 6rem;
    min-width: 4rem;
    padding: 2rem;
  }
`;

export const CompletedFeedbackWrapper = styled.div`
  margin-top: 1rem;
  background: #f8fdff;
  border: 2px solid #d1eaf7;
  border-radius: 8px;
  padding: 1rem;
  hr {
    border: 1px solid #d1eaf7;
    margin: 1rem 0;
  }
`;

export const AuthorAndTimestamp = styled.div`
  display: flex;
  font-weight: 600;
  margin-bottom: 0.5rem;
  span:first-child {
    color: #4d6c7d;
  }
  span:last-child {
    flex: 1;
    text-align: right;
    padding-left: 0.5rem;
    color: #adc2ce;
  }
`;
