/* Grab date, convert to ISO, purge out delimiters */
export const dateToGCalURLFormat = (date: Date) => {
  const dateString = date.toISOString();
  const cleanDate = dateString.replace(/[.:-]/g, '');
  return cleanDate;
};
export const getGoogleCalLink = (
  title: string,
  startDateLocal: Date,
  endDateLocal: Date,
  description: string,
  eventLink: string,
) => {
  const evtParams = {
    action: 'TEMPLATE',
    dates: `${dateToGCalURLFormat(startDateLocal)}/${dateToGCalURLFormat(
      endDateLocal,
    )}`,
    text: title,
    location: eventLink,
    details: description,
  };
  const urlArgs = new URLSearchParams(evtParams);
  return `https://calendar.google.com/calendar/event?${urlArgs.toString()}`;
};
