import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { PhoneIcon } from 'components/Icons';
import UserContext from 'modules/UserContext';
import GuestEnrollButton from '../components/GuestEnrollButton';

const StyledButton = styled(GuestEnrollButton)`
  font-size: 12px;
  font-family: 'Gotham', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #204a60;
  padding: 9px 80px 9px 50px;
  border-color: #204a60;
  border-radius: 6px;
  &:hover {
    background-color: #204a60 !important;
  }
  margin: 0px;
  div.icon {
    width: 12px !important;
    background-color: #416577;
    border-radius: 4px;
  }
`;

const UpsellButtons = ({ studentId, subject }) => {
  const { user } = useContext(UserContext);
  // TODO(andrewito): Move Calendly Advisor Url to shared util.
  const CALENDLY_ADVISOR_URL = `https://calendly.com/juniaccounts/juni-meeting`;
  return (
    <div className="buttons">
      <a
        href={`${CALENDLY_ADVISOR_URL}?utm_source=learner_portal_${subject}`}
        className="button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Book Call</span>
        <PhoneIcon />
      </a>
      {user.isGuest && <StyledButton emphasizeText studentId={studentId} />}
    </div>
  );
};

export default UpsellButtons;
