import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { SignupSessionProps, UserType } from 'app/signup_session/types';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { EMAIL_REGEX } from 'constants/signup_sessions';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import UserContext from 'modules/UserContext';
import { NewButton as Button, Card } from 'core-components';
import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import navStates from 'app/signup_session/navigation/states';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import { getContentForCampaign } from 'app/signup_session/campaign_content';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { NAV_VERSIONS } from 'app/signup_session/navigation/constants';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import { getCourseFormatFromBundleName } from 'app/signup_session/lib/getCourseFormatFromBundleName';
import ParentForm from './Forms/ParentForm';

export interface LocalParentState {
  name: string;
  email: string;
  phone: string;
}

const PARENT_INFO_CONTENT = {
  parentAndStudentInfoTitle: 'Get started with your trial',
  promoOfferForWarning: 'free trials',
};

const ParentInfo: FC<SignupSessionProps> = ({ history, location }) => {
  const LOGIN_SCREEN = `/?redirectTo=${location.pathname + location.search}`;
  const { user } = useContext(UserContext);
  const { signupData, setSignupSession, fullStory, flags } = useSignupContext();
  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const { getNextPage, routerType, multiSubjectSignup } = useNavRouter();
  const addToTouchedFields = (keyName: string) =>
    setTouchedFields(
      touchedFields.includes(keyName)
        ? [...touchedFields]
        : [...touchedFields, keyName],
    );
  const signupService = makeSignupSessionsService(signupData._id);
  // if we are using the new funnel, use the first name field for the full name
  const existingName = `${signupData.firstName ?? ''} ${
    signupData.lastName ?? ''
  }`.trim();

  const [localParentState, setLocalParentState] = useState<LocalParentState>({
    name: existingName,
    email: signupData.email ?? '',
    phone: signupData.phone ?? '',
  });

  const onLocalParentChange = (newData: Partial<typeof localParentState>) =>
    setLocalParentState({ ...localParentState, ...newData });

  const [existingUserType, setExistingUserType] = useState<UserType>(undefined);

  const phoneIsValid = parsePhoneNumberFromString(
    localParentState.phone,
    'US',
  )?.isValid();

  useEffect(() => {
    const fetchGuestAccountAndSetState = async () => {
      if (user?.isGuest && !signupData.invitationLookupId) {
        const guestAccount = await signupService.detectExistingUser(user.email);
        if (guestAccount.data) {
          setSignupSession({
            invitationLookupId: guestAccount.data.invitationLookupId,
          });
        }
      }
    };
    fetchGuestAccountAndSetState();
  }, [
    setSignupSession,
    signupData.invitationLookupId,
    signupService,
    user?.email,
    user?.isGuest,
  ]);

  const emailIsValid = EMAIL_REGEX.test(localParentState.email);

  const CONTENT = getContentForCampaign(PARENT_INFO_CONTENT, signupData?.campaign);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!localParentState.email || !localParentState.phone) {
      return;
    }

    const fetchUserIfExists = async (): Promise<
      | {
          userType: UserType;
          invitationLookupId?: string;
          inviteeStudentId: string;
        }
      | undefined
    > => {
      try {
        const existingUserResponse = await signupService.detectExistingUser(
          localParentState.email,
        );

        if (!existingUserResponse?.data) {
          throw new Error('Missing response body');
        }

        const {
          userType,
          invitationLookupId,
          inviteeStudentId,
        } = existingUserResponse.data;

        return {
          userType,
          invitationLookupId,
          inviteeStudentId,
        };
      } catch (e) {
        console.error(e);
        alert(
          'We are having trouble connecting to our database. Please try again in a few moments.',
        );
      }
    };

    const existingUser = await fetchUserIfExists();

    setExistingUserType(existingUser?.userType);

    // if we the user type is set, and the user is not logged in as a guest
    if (
      (!signupData.invitationLookupId &&
        existingUser?.userType !== undefined &&
        !user?.isGuest) ||
      (existingUser?.userType && existingUser?.userType !== 'guest')
    ) {
      return;
    }

    const email = localParentState.email.trim();
    const phone = parsePhoneNumberFromString(localParentState.phone, 'US')
      ?.number as string;
    const splitName = localParentState.name.split(' ');
    const firstName = splitName[0].trim();
    const lastName = splitName.slice(1).join(' ').trim();
    setSignupSession({
      invitationLookupId: existingUser?.invitationLookupId,
      firstName,
      lastName,
      email,
      phone,
    });

    fullStory?.setUserVars({
      email,
    });

    const activeStudent = signupData.students?.[0];

    JuniAnalytics.track('dcf_lead_submit', {
      funnelName: 'dcf',
      funnelVersion: NAV_VERSIONS[routerType],
      isMultiSubjectSignup: multiSubjectSignup,
      selectedCourses: getStudentBundleSelections(activeStudent?.bundle)
        .map(selection => selection?.courseName)
        .filter(courseName => courseName),
      courseFormat: getCourseFormatFromBundleName(activeStudent?.bundle?.bundleName),
    });

    window.qp?.('track', 'GenerateLead');

    history.push(
      getNextPage(navStates.signup.parentInfo, ROUTE_EVENT.SUBMIT, {
        signupData,
        shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
        search: location.search,
      }),
    );
  };

  const parentProps = {
    touchedFields,
    localParentState,
    onLocalParentChange,
    addToTouchedFields,
    setTouchedFields,
    existingUserType,
    setExistingUserType,
    loginScreenURL: LOGIN_SCREEN,
    phoneIsValid,
    emailIsValid,
    promoOffer: CONTENT.promoOfferForWarning,
    location,
  };

  return (
    <div className="flex flex-col-reverse justify-center items-center">
      <Card
        borderWidth="0"
        className="w-full max-w-screen-xs sm:w-3/5 sm:rounded-lg"
        noRounding
      >
        <div>
          <form noValidate onSubmit={onSubmit}>
            <ParentForm {...parentProps} hideTitle />
            <p className="text-sm text-blue-gray-500 mt-0">
              By continuing, you agree to our{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://junilearning.com/docs/Juni_Learning_Terms_of_Use.pdf"
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://junilearning.com/docs/Juni_Learning_Privacy_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </p>
            <Button
              variant="primary"
              className="w-full"
              disabled={
                Object.values(localParentState).some(val => !val) ||
                !phoneIsValid ||
                !emailIsValid ||
                (existingUserType && existingUserType !== 'guest') ||
                (user && !user?.roles?.includes('admin') && !user?.isGuest)
              }
              type="submit"
            >
              {flags.isInDcfExperimentTreatmentGroup && flags.isPrivateOneOnOne
                ? 'Schedule Your Free Class'
                : 'Continue'}
            </Button>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default ParentInfo;
