import styled from 'styled-components/macro';
import { HEIGHT as FOOTER_HEIGHT } from 'components/jide/Footer';
import { RICH_TEXT_LINE_HEIGHT } from './constants';

const StyledRichTextAnnotator = styled.div<any>`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  ${props => (props.isWritingProject ? 'max-width: 900px;' : '')}
  .rdw-editor-toolbar {
    margin-top: 1rem;
  }
`;
const StyledToolsColumn = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem 1rem;
  background: ${props => (props.isWritingProject ? 'none' : '#fff')};
  border-right: ${props =>
    props.isWritingProject ? 'none' : '2px solid hsl(198, 100%, 93%)'};
`;
const StyledAnnotatorToolButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 2px solid hsl(201, 70%, 89%);
`;
const StyledToolButton = styled.button<any>`
  min-height: 30px;
  min-width: 30px;
  padding: 0.5rem;
  background: ${props =>
    props.isActive && props.isWritingProject ? '#fff' : 'hsl(197, 100%, 98%)'};
  border: 2px solid
    ${props => (props.isActive ? 'hsl(201, 100%, 50%)' : 'hsl(197, 100%, 80%)')};
  border-radius: 8px;
  font-size: 16px;
  color: ${props => (props.isActive ? 'hsl(201, 100%, 50%)' : 'hsl(206, 39%, 33%)')};
  align-self: ${props => (props.isWritingProject ? 'flex-end' : 'center')};
  &:not(:first-child) {
    margin-top: 12px;
  }
  path {
    stroke: currentColor;
    stroke-width: 50px;
    fill: none;
  }
`;
const StyledModeSelectButton = styled(StyledToolButton)`
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: Open Sans, sans-serif;
  .icon {
    padding: 0;
    margin-left: 1rem;
  }
  .icon polyline {
    stroke: ${props =>
      props.isActive ? 'hsl(201, 100%, 50%)' : 'hsl(206, 39%, 33%)'};
  }
`;
const StyledScrollableArea = styled.div`
  overflow: scroll;
  display: flex;
  flex: 1;
`;
const StyledAnnotationsAndTextsContainer = styled.div<any>`
  display: flex;
  flex: 1;
  margin: ${props => (props.isWritingProject ? '1.25rem 0.75rem 0 0' : '0')};
`;
const StyledMainContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const StyledPromptCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid hsl(201, 70%, 89%);
  margin-bottom: 2rem;
  .prompt-card-header {
    padding: 1rem 2rem;
    background: #fff;
    border-bottom: 2px solid hsl(201, 70%, 89%);
    > h1 {
      margin: 0;
      color: hsl(206, 39%, 33%);
      font-size: 1.5rem;
    }
  }
  .prompt-content-wrapper {
    line-height: ${RICH_TEXT_LINE_HEIGHT}px;
    font-size: 14px;
    color: hsl(202, 33%, 35%);
    padding: 0 2rem;
    background: hsl(207, 100%, 98%);
  }
`;
const StyledRichTextContainer = styled.div<any>`
  background: #fff;
  color: hsl(206, 39%, 33%);
  line-height: ${RICH_TEXT_LINE_HEIGHT}px;
  font-size: 14px;
  padding: ${props => (props.isInWriteMode ? '2rem' : '1rem 2rem')};
  flex: ${props => (props.isInWriteMode ? '1' : '0')};
  border-left: ${props =>
    props.isInAnnotationsMode ? '2px solid hsl(198, 100%, 93%)' : 'none'};
  ${props => (props.isWritingProject ? 'border: 2px solid hsl(201, 70%, 89%);' : '')}
  border-radius: ${props => (props.isWritingProject ? '8px' : '0px')};
  margin-bottom: ${props => (props.isWritingProject ? '1.25rem' : '0')};
  ${props => (props.isLoading ? 'display: flex;' : '')}
  > h1.content-title {
    margin: 1rem 0;
  }
`;
const StyledEditorContainer = styled.div`
  position: relative;
`;
const StyledErrorMessage = styled.div`
  color: hsl(0, 72%, 49%);
`;
const StyledAnnotationsContainer = styled.div<any>`
  width: ${props => (props.isExpanded ? '242px' : '0px')};
  min-width: ${props => (props.isExpanded ? '242px' : '0px')};
  min-height: 100%;
  background: ${props => (props.isWritingProject ? 'none' : '#fff')};
`;
const StyledWritingSubmissionInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1.75rem;
  font-family: Open Sans, sans-serif;
  border: none;
  color: hsl(206, 39%, 33%);
  padding: 0;
  width: 100%;
  &::placeholder {
    color: hsl(205, 19%, 66%);
  }
`;
const StyledSaveStatusContainer = styled.div<any>`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 2px solid hsl(201, 70%, 89%);
  width: 100%;
  align-self: ${props => (props.isWritingProject ? 'flex-end' : 'center')};
`;
const StyledWordCountContainer = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 2px solid hsl(201, 70%, 89%);
  width: 100%;
`;
const StyledWordCount = styled.div`
  width: 108px;
  font-size: 14px;
  text-align: left;
`;

// This is positioned against the whole editor inclusive of the custom toolbar
// using pixels for positioning because the Footer height is in pixels.
const WordCount = styled.div.attrs({
  className:
    'absolute px-2 py-1 font-graphik text-j-dark-400 text-xs leading-none uppercase bg-j-gray-200 border border-solid border-j-dark-200 rounded-md z-10',
})`
  left: 24px;
  bottom: ${FOOTER_HEIGHT + 24}px;
`;

export {
  StyledRichTextAnnotator,
  StyledToolsColumn,
  StyledAnnotatorToolButtons,
  StyledToolButton,
  StyledModeSelectButton,
  StyledScrollableArea,
  StyledAnnotationsAndTextsContainer,
  StyledMainContentContainer,
  StyledPromptCard,
  StyledRichTextContainer,
  StyledEditorContainer,
  StyledErrorMessage,
  StyledAnnotationsContainer,
  StyledWritingSubmissionInput,
  StyledSaveStatusContainer,
  StyledWordCountContainer,
  StyledWordCount,
  // New Horizons below...
  WordCount,
};
