import styled from 'styled-components';
import { jBlue, jDark, jGreen, jPink, jYellow } from 'theme/colors';
import { Sizes, Variants, Intents } from './types';

export const sizeStyles: { [size in Sizes]: string } = {
  xsmall: 'px-2 py-1',
  small: 'px-4 py-1',
  medium: 'px-4 py-2',
  large: 'px-4 py-3',
};

export const iconSizeStyles: { [size in Sizes]: string } = {
  xsmall: 'p-1',
  small: 'p-2',
  medium: 'p-3',
  large: 'p-4',
};

type IntentsMap = { [intent in Intents]: string };

// font - replace with Typography variants? (PR#1560)
export const textBaseClasses =
  'font-graphik text-sm leading-6 font-medium whitespace-nowrap tracking-normal normal-case no-underline';

// text and icon colors - text color stays the same across focus, active, hover states.
//   exception - variant: primary always white
// __note: not capturing disabled state text colors in here__
export const contentColors: { [variant in Variants]: IntentsMap } = {
  primary: {
    default: 'text-white',
    success: 'text-white',
    warning: 'text-j-yellow-500',
    info: 'text-white',
    error: 'text-white',
  },
  secondary: {
    default: 'text-j-dark-600',
    success: 'text-j-green-600 active:text-j-green-700',
    // TODO - fill the rest of these in...
    warning: 'text-j-yellow-500',
    info: 'text-j-blue-600',
    error: 'text-j-pink-700',
  },
  minimal: {
    default: 'text-j-dark-600',
    success: 'text-j-green-600',
    // TODO - fill the rest of these in...
    warning: 'text-j-yellow-500',
    info: 'text-j-blue-600',
    error: 'text-j-pink-700',
  },
};

export const getContentColorClasses = (
  variant: Variants,
  intent: Intents,
  disabled: boolean,
) => {
  if (!disabled || variant === 'primary') {
    return contentColors[variant][intent];
  }

  return variant === 'secondary' ? 'text-j-dark-100' : 'text-j-dark-200';
};

export const bgColors: { [variant in Variants]: IntentsMap } = {
  primary: {
    default:
      'bg-j-purple-600 hover:bg-j-purple-700 active:bg-j-purple-800 disabled:bg-j-purple-200',
    success:
      'bg-j-green-400 hover:bg-j-green-500 active:bg-j-green-600 disabled:bg-j-dark-100',
    // TODO - fill the rest of these in...
    warning: 'bg-j-yellow-400',
    info:
      'bg-j-blue-500 hover:bg-j-blue-600 focus:bg-j-blue-600 disabled:bg-j-dark-100',
    error:
      'bg-j-pink-600 focus:bg-j-pink-700 hover:bg-j-pink-700 active:bg-j-pink-800 disabled:bg-j-dark-100',
  },
  secondary: {
    default: 'bg-white disabled:bg-white',
    success: 'bg-white disabled:bg-white',
    warning: 'bg-white disabled:bg-white',
    info: 'bg-white disabled:bg-white',
    error: 'bg-white disabled:bg-white',
  },
  minimal: {
    default:
      'bg-transparent focus:bg-j-gray-100 hover:bg-j-gray-100 active:bg-j-gray-300 disabled:bg-transparent',
    success:
      'bg-transparent focus:bg-j-green-100 hover:bg-j-gray-100 active:bg-j-gray-200 disabled:bg-transparent',
    // TODO - fill the rest of these in...
    warning: '',
    info: '',
    error: '',
  },
};

export const bordersBaseClasses = 'border-2 border-solid rounded-lg';

// and box-shadow's for double border effect on secondary variant focus states
export const borders: { [variant in Variants]: IntentsMap } = {
  primary: {
    default:
      'border-transparent focus:border-j-purple-300 disabled:border-transparent disabled:border-2',
    success:
      'border-transparent focus:border-j-green-200 disabled:border-transparent disabled:border-2',
    // TODO - fill the rest of these in...
    warning: '',
    info:
      'border-transparent focus:border-j-blue-300 disabled:border-transparent disabled:border-2',
    error:
      'border-transparent focus:border-j-pink-300 disabled:border-transparent disabled:border-2',
  },
  secondary: {
    default:
      'border-j-purple-200 hover:border-j-purple-300 focus:border-j-purple-300 active:border-j-purple-400 disabled:border-j-dark-100 disabled:border-2',
    success:
      'border-j-green-200 hover:border-j-green-400 focus:border-j-green-300 active:border-j-green-400 disabled:border-j-dark-100 disabled:border-2',
    // TODO - fill the rest of these in...
    warning: '',
    info: '',
    error:
      'border-j-pink-200 hover:border-j-pink-400 focus:border-j-pink-300 active:border-j-pink-400',
  },
  minimal: {
    default:
      'border-transparent focus:border-j-blue-300 disabled:border-transparent',
    success:
      'border-transparent focus:border-j-green-300 disabled:border-transparent',
    // TODO - fill the rest of these in...
    warning: '',
    info: '',
    error: '',
  },
};

// double border effect for secondary button focus states:
const secondaryFocusColors: { [intent in Intents]: string } = {
  default: jDark[100],
  success: jGreen[100],
  warning: jYellow[100],
  info: jBlue[100],
  error: jPink[100],
};

interface StyledButtonProps {
  variant: Variants;
  intent: Intents;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ variant, intent }) =>
    variant === 'secondary' &&
    `
    &:focus {
      box-shadow: 0 0 0 2px ${secondaryFocusColors[intent]};
    }
  `}
`;
