import React from 'react';
import BadgeContainer from './BadgeContainer';

const PlaygroundBadge: React.FC = () => (
  <BadgeContainer>
    <svg
      style={{
        background: '#F0EEFF',
        borderRadius: '50%',
      }}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6084 16.7001C32.6084 25.5366 25.445 32.7001 16.6084 32.7001C7.77184 32.7001 0.608398 25.5366 0.608398 16.7001C0.608398 7.86352 7.77184 0.700073 16.6084 0.700073C25.445 0.700073 32.6084 7.86352 32.6084 16.7001ZM16.6084 30.7001C24.3404 30.7001 30.6084 24.4321 30.6084 16.7001C30.6084 8.96809 24.3404 2.70007 16.6084 2.70007C8.87641 2.70007 2.6084 8.96809 2.6084 16.7001C2.6084 24.4321 8.87641 30.7001 16.6084 30.7001Z"
        fill="#8B79F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2933 5.76854L16.2106 10.6818L16.914 11.8358C17.6038 11.605 18.3323 11.4848 19.0767 11.4848C22.8475 11.4848 25.8999 14.5489 25.8999 18.3233C25.8999 22.0976 22.8475 25.1617 19.0767 25.1617C15.8314 25.1617 13.1189 22.8926 12.4252 19.854H4.60859L13.2933 5.76854ZM14.4996 19.854C15.1382 21.7769 16.9479 23.1617 19.0767 23.1617C21.738 23.1617 23.8999 20.9979 23.8999 18.3233C23.8999 15.6486 21.738 13.4848 19.0767 13.4848C18.6932 13.4848 18.3168 13.5297 17.9532 13.6166L21.6567 19.854H14.4996ZM8.26689 17.854H18.1432L14.4968 11.7128L13.2534 9.67292L8.26689 17.854Z"
        fill="#8B79F0"
      />
    </svg>
  </BadgeContainer>
);

export default PlaygroundBadge;
