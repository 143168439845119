import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { useGetOpenChallengesQuery, OpenChallengeFragment } from 'generated/graphql';

import JuniSpinner from 'components/JuniSpinner';
import { PortalComponentLayout } from 'components/PortalLayout';
import { Card, Message } from 'components/ui';

import ChallengeCard from './ChallengeCard';

const OpenChallengesColumn = styled.div`
  max-width: 850px;

  > * {
    margin-bottom: 20px !important;
  }

  p {
    line-height: 1.8em;
  }

  p + p {
    margin-top: -10px !important;
  }

  @media only screen and (max-width: 600px) {
    & > * {
      max-width: 90vw;
      margin-left: 0px;
    }
  }
`;

const OpenChallenges: FC = () => {
  const { data, loading, error } = useGetOpenChallengesQuery();

  const challenges = data?.openChallenges.items;

  return loading ? (
    <JuniSpinner size={null} />
  ) : (
    <PortalComponentLayout title="Juni Open Challenges">
      <OpenChallengesColumn>
        <p>
          Our Open Challenges allow students to showcase their skills and creativity
          by competing with students across the nation! Check out ongoing
          competitions and winners of our past challenges.
        </p>
        {error ? (
          <Card>
            <Card.Content>
              <Message status="error">
                Oops, something went wrong! Try refreshing the page.
              </Message>
            </Card.Content>
          </Card>
        ) : challenges?.length ? (
          challenges.map((challenge: OpenChallengeFragment) => (
            <ChallengeCard challenge={challenge} key={challenge._id} />
          ))
        ) : (
          <Card>
            <Card.Content>
              <Message status="warning">
                No challenges found. Check back later!
              </Message>
            </Card.Content>
          </Card>
        )}
      </OpenChallengesColumn>
    </PortalComponentLayout>
  );
};

export default OpenChallenges;
