import defaultRouter, {
  defaultOrder,
  navVersion as defaultNavVersion,
} from './machines/defaultNavigation';

export const createNavOrderMap = (navOrder: string[], startIndex: number) =>
  navOrder.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: navOrder.indexOf(curr) + startIndex,
    }),
    {},
  );

export const ORDINALS: { [id: string]: { [stepName: string]: number } } = {
  [defaultRouter.id]: createNavOrderMap(defaultOrder, 2),
};

export const NAV_VERSIONS: { [id: string]: number } = {
  [defaultRouter.id]: defaultNavVersion,
};
