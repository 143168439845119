const RESTRICTED_DATES: { [key: number]: number[] } = {
  1: [1], // [month]: [dayOfTheMonth to be restricted]
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [24, 25, 31],
};

export const isSchedulingRestricted = (date: Date) => {
  const adjustedMonth = date.getMonth() + 1;
  const dayOfTheMonth = date.getDate();

  return RESTRICTED_DATES[adjustedMonth].includes(dayOfTheMonth);
};
