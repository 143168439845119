export function buildIdLookupTable(courses, students, playgroundProjects) {
  const idLookupTable = {};
  for (const course of courses) {
    idLookupTable[course.id] = course;
    idLookupTable[course.properties.name] = course;
    for (const mod of course.children) {
      idLookupTable[mod.id] = mod;
      for (const project of mod.children) {
        idLookupTable[project.id] = project;
      }
      for (const video of mod.properties.videos || []) {
        if (video && typeof video !== 'string') {
          idLookupTable[video._id] = video;
        }
      }
    }
  }

  for (const student of students) {
    student.children = student.children
      .map(courseId => idLookupTable[courseId])
      .filter(v => v);
    idLookupTable[student.id] = student;
  }

  for (const playgroundProject of playgroundProjects) {
    idLookupTable[playgroundProject._id] = playgroundProject;
  }

  return idLookupTable;
}
