import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AsYouType } from 'libphonenumber-js';

import InputField from 'app/signup_session/components/InputField';
import WarningBlock from 'app/signup_session/components/WarningBlock';

import { CALENDLY_LINKS } from 'constants/signup_sessions';

import UserContext from 'modules/UserContext';
import { JUNI_EMAILS } from 'constants/contact_information';
import { UserType } from 'app/signup_session/types';
import queryString from 'query-string';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import { LocalParentState } from '../ParentInfo';

export interface BaseMembershipInfoForm {
  touchedFields: string[];
  addToTouchedFields: (keyName: string) => void;
  setTouchedFields: (fields: string[]) => void;
  existingUserType: UserType;
  setExistingUserType: (userType: UserType) => void;
}

interface ParentFormProps extends BaseMembershipInfoForm {
  localParentState: LocalParentState;
  onLocalParentChange: (newData: Partial<LocalParentState>) => void;
  loginScreenURL: string;
  phoneIsValid?: boolean;
  emailIsValid?: boolean;
  hideTitle?: boolean;
  promoOffer?: string;
  location: any;
}

export const HEADER_CLASSES = `font-semibold my-2`;

const ParentForm: FC<ParentFormProps> = ({
  touchedFields,
  localParentState,
  onLocalParentChange,
  addToTouchedFields,
  setTouchedFields,
  existingUserType,
  setExistingUserType,
  loginScreenURL,
  phoneIsValid,
  emailIsValid,
  hideTitle,
  promoOffer = 'free trials',
  location,
}) => {
  const { flags } = useSignupContext();
  const { user } = useContext(UserContext);
  const labelTextStyles = 'text-j-dark-600 whitespace-nowrap font-medium text-sm';
  const parsed = queryString.parse(location.search);
  return (
    <>
      {!hideTitle && <h3 className={HEADER_CLASSES}>Parent Details</h3>}
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        value={localParentState.name}
        id="parent-name"
        label="Parent Full Name"
        onBlur={() => addToTouchedFields('parent-name')}
        onChange={e => onLocalParentChange({ name: e.target.value })}
        validationError={
          touchedFields.includes('parent-name') && !localParentState.name
            ? 'This field is required'
            : undefined
        }
      />
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        placeholder="juni@example.com"
        type="email"
        id="email"
        label="Email"
        value={localParentState.email}
        onBlur={() => addToTouchedFields('email')}
        onChange={e => {
          setExistingUserType(undefined);
          setTouchedFields(touchedFields.filter(x => x !== 'email'));
          onLocalParentChange({ email: e.target.value });
        }}
        validationError={
          touchedFields.includes('email') && !emailIsValid
            ? 'Please enter a valid email'
            : undefined
        }
      />
      {existingUserType &&
        existingUserType !== 'guest' &&
        ((flags.isBootcampSignup && parsed.courseOfferingId) ||
        (flags.isOnDemandSignup && parsed.courses) ? (
          <WarningBlock>
            We recognize this email! Please{' '}
            <Link to={`/?redirectTo=/signup2/redirect${location.search}`}>
              log in
            </Link>{' '}
            to complete this transaction.
          </WarningBlock>
        ) : (
          <WarningBlock>
            We only offer {flags.isOnDemandSignup ? 'one free week' : promoOffer} for
            new Juni Members. If you believe this is a mistake or need more help,
            please{' '}
            <a href={CALENDLY_LINKS.salesTeam} target="_blank" rel="noreferrer">
              contact our team.
            </a>
          </WarningBlock>
        ))}
      {user && !user?.roles?.includes('admin') && !user?.isGuest && (
        <WarningBlock>
          Hi {user.firstName}, we only offer {promoOffer} for new Juni Members. You
          can adjust your membership in your{' '}
          <Link to="/learner">Learner Portal</Link>, or{' '}
          <a href={`mailto:${JUNI_EMAILS.support}`}>contact our team</a> for more
          help.
        </WarningBlock>
      )}
      {existingUserType === 'guest' && !user && (
        <WarningBlock>
          We recognize this email! Please <Link to={loginScreenURL}>log in</Link> to
          upgrade this guest account.
        </WarningBlock>
      )}
      <InputField
        labelTextStyles={labelTextStyles}
        inputClassName="fs-mask"
        placeholder="000-000-0000"
        type="tel"
        id="phone"
        label="Phone Number"
        value={new AsYouType('US').input(localParentState.phone)}
        onChange={e => {
          const newFormattedValue = new AsYouType('US').input(e.target.value);

          // If the user presses backspace in a string like "(702)", attempting to
          // remove the final ")", the automatic formatter will re-insert that character.
          // So we handle that edge case here by manually erasing it.
          const isDeleting = e.target.value.length < localParentState.phone.length;
          if (isDeleting && newFormattedValue === localParentState.phone) {
            onLocalParentChange({ phone: e.target.value.slice(0, -1) });
          } else {
            onLocalParentChange({ phone: newFormattedValue });
          }
        }}
        onBlur={() => addToTouchedFields('phone')}
        validationError={
          touchedFields.includes('phone') && !phoneIsValid
            ? 'Please enter a valid phone number'
            : undefined
        }
      />
    </>
  );
};

export default ParentForm;
