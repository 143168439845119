import React from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
  richTextState: string;
}

const RichTextViewer: React.FC<Props> = ({ richTextState }) => {
  const editorState = EditorState.createWithContent(
    convertFromRaw(JSON.parse(richTextState)),
  );
  return <Editor editorState={editorState} readOnly toolbarHidden />;
};

export default RichTextViewer;
