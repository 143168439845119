import { COURSE_FORMAT } from 'constants/signup_sessions';
import { checkIsBootcampSignup } from '../lib/checkIsBootcampSignup';
import { Context } from './types';

// guards for use in navigation state machines
export const guards = {
  shouldSkipCourseFrequency: (context: Context) => context.shouldSkipCourseFrequency,
  shouldViewCourseFrequency: (context: Context) =>
    context.shouldSkipCourseFrequency === false,
  // if there is no bundle associated with a student, they have no course preferences selected
  hasNoCourseFrequency: (context: Context) =>
    !context.signupData?.students?.[0]?.bundle,
  noPayment: (context: Context) => !context.signupData?.submittedPayment,
  shouldCreateAccount: (context: Context) =>
    !context.signupData?.accountCreated &&
    context.signupData?.invitationCode &&
    context.user?.userType !== 'admin',
  notApprovedRoute: (context: Context) => context.isNotApprovedRoute,
  onboardingIsComplete: (context: Context) => context.onboardingIsComplete,
  isBootcampSignup: (context: Context) => {
    const activeStudent = context.signupData?.students?.[0];
    return checkIsBootcampSignup(activeStudent?.bundle);
  },
  isOnDemandSignup: (context: Context) =>
    context.signupData?.students?.[0].bundle?.bundleName === COURSE_FORMAT.onDemand,
};
