import React from 'react';
import { StudentProject } from 'generated/graphql';
import { projectTypeToRenderInfo } from 'constants/project_types';
import { mongoIDToDateStr } from 'utils/timeUtils';
import { parseISO, formatDistanceToNowStrict } from 'date-fns';
import _ from 'lodash';
import { NewCard, Icon } from 'core-components';
import { NavLink } from 'react-router-dom';
import { JUNI_LINKS } from 'constants/links';
import { buildEmailLink } from 'utils/social_share/social_share';
import SocialShareButton from 'app/learner/SocialShareButton';

export interface JuniverseProjectCardProps extends StudentProject {
  /**
   * Regular is the full version of the card.
   * Minimal presents a smaller version, not including the names header and description.
   * Default regular.
   */
  variant?: 'regular' | 'minimal';
  /**
   * By default, only the thumbnail and titles link to the project page.
   * Set to true to override that and make the whole card a clickable link.
   */
  cardIsLink?: boolean;
  /**
   * By default, links open in new tab.  Set to false to override.
   */
  linksOpenInNewTab?: boolean;
}

export const JuniverseProjectCard: React.FC<JuniverseProjectCardProps> = ({
  _id,
  projectType,
  title,
  description,
  moduleSection,
  thumbnailUrl,
  creator,
  viewCount,
  variant = 'regular',
  cardIsLink = false,
  linksOpenInNewTab = true,
}) => {
  const projectTypeKey = projectType?.includes('TURTLE')
    ? 'pythonTurtle'
    : _.lowerCase(projectType || 'unknown').replace(/ /g, '_');
  const projectTypeName = projectType
    ? _.startCase(_.lowerCase(projectType.replace(/_/g, ' ')))
    : 'Juniverse Project';

  const { icon, iconBgColor } = projectTypeToRenderInfo(projectTypeKey);
  const thumbnailSrc = thumbnailUrl
    ? thumbnailUrl.replace('_full', '_thumbnail')
    : undefined;

  const createdAt = mongoIDToDateStr(_id);
  const timeAgo = createdAt
    ? `${formatDistanceToNowStrict(parseISO(createdAt))} ago`
    : '';
  const juniverseProjectLink = `${JUNI_LINKS.APP}/juniverse/projects/${_id}`;

  const card = (
    <NewCard>
      {variant === 'regular' && (
        <div className="flex items-center mb-4 font-graphik">
          <div className="flex flex-shrink-0 mr-4 items-center justify-center rounded-full w-12 h-12 text-j-purple-600 border-solid bg-j-purple-200 border-2 border-j-purple-400">
            {React.createElement(icon)}
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="text-j-dark-600 tracking-wide font-medium mr-3 text-sm">
              {creator?.displayName}
            </div>
            <NavLink
              to={`/juniverse/users/${creator?.username}`}
              target={linksOpenInNewTab ? '_blank' : undefined}
              className="no-underline"
            >
              <div className="text-sm text-j-dark-300">
                {`@${creator?.username}`}
              </div>
            </NavLink>
          </div>
          <div className="text-sm text-j-dark-300 ml-auto hidden md:flex">
            {timeAgo}
          </div>
        </div>
      )}

      {variant === 'regular' && !!description && (
        <div className="font-graphik text-j-dark-600 text-sm mb-4 px-1">
          {description}
        </div>
      )}

      {variant === 'regular' && (
        <NavLink
          to={`/juniverse/projects/${String(_id)}`}
          target={linksOpenInNewTab ? '_blank' : undefined}
          className="no-underline"
        >
          <div className="mb-6 flex flex-col font-graphik no-underline overflow-hidden box-border border-2 border-solid border-j-purple-200 rounded-xl">
            {/* its expected that a thumbnail exists here, but just in case */}
            {!!thumbnailUrl && (
              <img
                className="flex w-full border-0 border-b-2 border-solid border-j-purple-200"
                src={thumbnailSrc}
                alt={`${title} thumbnail`}
              />
            )}
            <div className="flex flex-col p-4 pb-3">
              <div className="text-lg font-medium mb-1">{title}</div>
              <div className="text-sm text-j-dark-300">{projectTypeName}</div>
            </div>
          </div>
        </NavLink>
      )}
      {variant === 'minimal' && (
        <>
          <div className="flex justify-between items-start font-graphik">
            <div
              className={`w-18 h-18 flex justify-center items-center rounded-lg text-white bg-${
                iconBgColor || 'j-purple-600'
              }`}
            >
              {React.createElement(icon, { className: 'w-8 h-8' })}
            </div>
            <div className="text-sm text-j-dark-300">{timeAgo}</div>
          </div>
          <div className="mt-3 font-graphik text-lg text-j-dark-600 font-medium truncate">
            {title}
          </div>
          <div className="mt-1 font-graphik text-sm text-j-dark-300 truncate">
            {projectTypeName}{' '}
            {moduleSection?.displayName ? ` / ${moduleSection?.displayName}` : ''}
          </div>
          {!!thumbnailUrl && (
            <img
              className="block my-3 w-full h-72 md:h-48 object-cover object-top rounded-lg"
              src={thumbnailSrc}
              alt={`${title} thumbnail`}
            />
          )}
          {!thumbnailUrl && (
            <div className="py-4">
              <NewCard.Divider />
            </div>
          )}
        </>
      )}

      <div className="flex justify-between items-center font-graphik">
        <div className="flex text-j-dark-300 text-sm leading-5">
          <div className="flex" title="View Count">
            <div className="flex items-center mr-2">
              <Icon.Eye className="w-5 h-5" />
            </div>
            <div className="">{viewCount?.toLocaleString() || 0}</div>
          </div>
        </div>
        {/* Stop click here if the entire card is itself a link  */}
        <div onClick={e => (cardIsLink ? e.preventDefault() : _.noop)}>
          <SocialShareButton
            url={juniverseProjectLink}
            socialShareCopy="Check out my project I made with Juni Learning"
            emailSubject="My project with Juni Learning!"
            emailBody={`Check out my project I made with Juni Learning:\n\n${buildEmailLink(
              juniverseProjectLink,
            )}`}
            shareContent="juniverse_project_link"
          />
        </div>
      </div>
    </NewCard>
  );

  if (cardIsLink) {
    return (
      <NavLink
        to={`/juniverse/projects/${String(_id)}`}
        target={linksOpenInNewTab ? '_blank' : undefined}
        className="no-underline"
      >
        {card}
      </NavLink>
    );
  }

  return card;
};

export default JuniverseProjectCard;
