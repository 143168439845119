/*
 * Adapted from https://gist.github.com/aravindvcyber/7648f901714d08bb6cededd668f83447
 */

import styled, { keyframes, css } from 'styled-components';
import { jDark, jPurple } from 'theme/colors';

const colors = [jDark[600], jPurple[600], jDark[600], jPurple[600]];

export interface LoadingSpinnerProps {
  /**
   * Size in any valid CSS height.
   * Numbers will be treated as pixels.
   * Default 0.5rem.
   */
  size?: React.CSSProperties['height'];
}

const rotated = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const before = (size: LoadingSpinnerProps['size']) => keyframes`
  0% {
    width: 5;
    box-shadow: calc(${size} * 2) -${size} ${colors[0]}, calc(-1 * (${size} * 2)) ${size} ${colors[2]};
  }
  50% {
    width: 5;
    box-shadow: 0 -${size} ${colors[0]}, 0 ${size} ${colors[2]};
  }
  100% {
    box-shadow: calc(${size} * 2) -${size} ${colors[0]}, calc(-1 * (${size} * 2)) ${size} ${colors[2]};
  }
`;

const after = (size: LoadingSpinnerProps['size']) => keyframes`
  0% {
    height: 5;
    box-shadow: ${size} calc(${size} * 2) ${colors[1]}, -${size} calc(-1 * (${size} * 2)) ${colors[3]};
  }
  50% {
    height: 5;
    box-shadow: ${size} 0 ${colors[1]}, -${size} 0 ${colors[3]};
  }
  100% {
    box-shadow: ${size} calc(${size} * 2) ${colors[1]}, -${size} calc(-1 * (${size} * 2)) ${colors[3]};
  }
`;

/**
 * Slack style loading spinner.
 * Positioned and centered relative to its closest positioned ancestor.
 */
const LoadingSpinner = styled.div.attrs<LoadingSpinnerProps>({
  className: 'absolute',
})<LoadingSpinnerProps>`
  ${({ size: sizeProp = '0.5rem' }) => {
    const size = Number.isFinite(sizeProp) ? `${sizeProp}px` : sizeProp;

    return css`
      width: 5;
      height: 5;
      animation: ${rotated} 2s infinite;
      top: calc(50% - (${size} / 2));
      left: calc(50% - (${size} / 2));

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: ${size};
        height: ${size};
        border-radius: calc(${size} / 2);
        transform: translate(-50%, -50%);
      }

      &:before {
        animation: ${before(size)} 2s infinite;
      }
      &:after {
        animation: ${after(size)} 2s infinite;
      }
    `;
  }}
`;

export default LoadingSpinner;
