import React from 'react';
import PropTypes from 'prop-types';

import { CheckmarkIcon, EllipsisIcon, SimpleLockIcon } from 'components/Icons';

import './status_icon.css';

const StatusIcon = ({ status, className }) => {
  const classNames = `status-icon ${status.replace(/_/g, '-')} ${className || ''}`;
  switch (status) {
    case 'completed':
      return <CheckmarkIcon className={classNames} />;
    case 'in_progress':
      return <EllipsisIcon className={classNames} />;
    case 'locked':
      return <SimpleLockIcon className={classNames} />;
    default:
      return null;
  }
};
StatusIcon.defaultProps = {
  className: '',
  status: '',
};
StatusIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
};
export default StatusIcon;
