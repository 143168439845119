import React, { useState } from 'react';
import classNames from 'classnames';
import { juniLight } from 'theme/old-colors';
import { TailwindColor } from 'theme/types';
import { JuniClubType } from 'generated/graphql';
import NotificationsBadge from 'components/NotificationsBadge';

const X_OFFSET = 70;
const Y_OFFSET = 10;

interface ClubSelectorOptionProps {
  active: boolean;
  clubName: string;
  clubThumbnailUrl: string;
  notifications: number;
  colorTheme: JuniClubType['colorTheme'];
}

const ClubSelectorOption: React.FC<ClubSelectorOptionProps> = ({
  active,
  clubName,
  clubThumbnailUrl,
  notifications,
  colorTheme,
}) => {
  // The x and y state variables are used to set
  // the fixed position of the club name tooltip.
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  return (
    <div
      className={classNames(
        'group',
        'bg-center',
        'bg-cover',
        'relative',
        'rounded-xl',
        'mr-1.5',
        'mb-4',
        'w-14',
        'h-14',
      )}
      onMouseEnter={(e: any) => {
        setX(e.currentTarget.getBoundingClientRect().x + X_OFFSET);
        setY(e.currentTarget.getBoundingClientRect().y + Y_OFFSET);
      }}
      style={{
        backgroundImage: `url(${clubThumbnailUrl})`,
        boxShadow: active ? `0 0 0 2px ${juniLight[100]}` : undefined,
      }}
    >
      <div
        className={classNames(
          'group-hover:visible',
          'invisible',
          'pointer-events-none',
          'z-50',
          'fixed',
          'rounded-md',
          'bg-white',
          'text-blue-gray-800',
          'text-sm',
          'font-bold',
          'whitespace-nowrap',
          'px-4',
          'py-2',
        )}
        style={{ top: y, left: x }}
      >
        <div
          className="w-0 h-0 absolute"
          style={{
            top: '2px',
            left: '-30px',
            borderWidth: '16px',
            borderStyle: 'solid',
            borderColor: 'transparent white transparent transparent',
          }}
        />
        {clubName}
      </div>
      <NotificationsBadge
        numNotifications={notifications}
        backgroundColor={`${colorTheme}-light` as TailwindColor}
        hasTopRightPositioning
      />
    </div>
  );
};

export default ClubSelectorOption;
