import React, { FC } from 'react';

import { PortalNavProps } from '../../types';
import {
  PortalSidebar,
  WelcomeText,
  WelcomeWrapper,
  LinkText,
  DesktopLink,
  DesktopButton,
} from './desktop_styles';

interface OptionProps {
  isActive: boolean;
  path: string;
  setImpersonatedPath?: PortalNavProps['setImpersonatedPath'];
}

const Option: FC<OptionProps> = ({
  isActive,
  path,
  setImpersonatedPath,
  children,
}) =>
  setImpersonatedPath ? (
    <DesktopButton
      key={path}
      active={isActive}
      onClick={() => setImpersonatedPath(path)}
    >
      {children}
    </DesktopButton>
  ) : (
    <DesktopLink key={path} to={path} $active={isActive}>
      {children}
    </DesktopLink>
  );

const PortalNavDesktop: FC<PortalNavProps> = ({
  options,
  activeOption,
  userFirstName,
  setImpersonatedPath,
}) => (
  <PortalSidebar>
    <WelcomeWrapper>
      <WelcomeText>{`Welcome, ${userFirstName}!`}</WelcomeText>
    </WelcomeWrapper>
    {options.map(option => {
      const { icon, name, path } = option;
      const isActive = path === activeOption.path;
      return (
        <Option
          key={path}
          path={path}
          isActive={isActive}
          setImpersonatedPath={setImpersonatedPath}
        >
          {icon}
          <LinkText active={isActive}>{name}</LinkText>
        </Option>
      );
    })}
  </PortalSidebar>
);

export default PortalNavDesktop;
