// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as R from 'ramda';
import { BundleData } from '../types';

const countIncompleteSchedulingPreferences = (
  bundleData: Partial<BundleData>,
): number =>
  R.pipe(
    R.filter(
      selection =>
        !selection.schedulingPreferences ||
        selection.schedulingPreferences.length < selection.weeklyClasses * 3 ||
        selection.schedulingPreferences.filter(pref => pref.datetime).length <
          selection.weeklyClasses * 3,
    ),
    R.keys,
    R.length,
  )(bundleData.selections ?? {});

export default countIncompleteSchedulingPreferences;
