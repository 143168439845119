import { useEffect } from 'react';

// To lock scrolling on the <body>, we hide overflow and add a right margin equal to
// the width of the scrollbar to prevent the content from shifting in the background.
// We assume that the initial left/right <body> margins are 0 (as is set in app.css).

const lock = () => {
  Object.assign(document.body.style, {
    overflow: 'hidden',
    marginRight: `${window.innerWidth - document.body.clientWidth}px`,
  });
};

const unlock = () => {
  ['overflow', 'margin-right'].forEach(property =>
    document.body.style.removeProperty(property),
  );
};

const useScrollLock = (shouldLock: boolean) => {
  useEffect(() => {
    if (shouldLock) {
      lock();
    } else {
      unlock();
    }
    return () => unlock();
  }, [shouldLock]);
};

export default useScrollLock;
