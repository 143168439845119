import React, { TextareaHTMLAttributes } from 'react';
import { InputSharedProps } from './types';
import InputBase from './InputBase';

export type TextAreaProps = InputSharedProps &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'>;

const TextArea = React.forwardRef<HTMLDivElement, TextAreaProps>((props, ref) => (
  <InputBase {...props} ref={ref} inputComponent="textarea" />
));

export default TextArea;
