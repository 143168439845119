import React from 'react';
import { Badge, Icon } from 'core-components';

const userTypes = ['STUDENT', 'INSTRUCTOR', 'GUEST'] as const;
type UserType = typeof userTypes[number];

type UserTypeRenderPropsMap = {
  [type in UserType]: {
    label: string;
    color: React.ComponentProps<typeof Badge>['color'];
    lightColor: React.ComponentProps<typeof Badge>['backgroundColor'];
    showJuniLogo: boolean;
    renderIconLeft?: React.ComponentProps<typeof Badge>['renderIconLeft'];
  };
};
const renderProps: UserTypeRenderPropsMap = {
  STUDENT: {
    label: 'Juni Student',
    color: 'j-pink-700',
    lightColor: 'j-pink-100',
    showJuniLogo: false,
    renderIconLeft: props => <Icon.Atom {...props} />,
  },
  INSTRUCTOR: {
    label: 'Instructor',
    color: 'j-purple-600',
    lightColor: 'j-purple-100',
    showJuniLogo: true,
  },
  GUEST: {
    label: 'Guest',
    color: 'j-orange-500',
    lightColor: 'j-orange-100',
    showJuniLogo: true,
  },
};

export interface UserTypeBadgeProps {
  userType: UserType;
  /**
   * Default medium.
   */
  size?: React.ComponentProps<typeof Badge>['size'];
}

const UserTypeBadge: React.FC<UserTypeBadgeProps> = ({
  userType,
  size = 'medium',
}) => {
  const { label, color, lightColor, showJuniLogo, renderIconLeft } = renderProps[
    userType
  ];

  return (
    <Badge
      color={color}
      backgroundColor={lightColor}
      size={size}
      renderIconLeft={renderIconLeft}
      renderSectionIconLeft={
        showJuniLogo ? props => <Icon.JuniLogo {...props} /> : undefined
      }
    >
      {label}
    </Badge>
  );
};

export default UserTypeBadge;
