import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { useHistory } from 'react-router-dom';
import { getClubLink } from 'app/clubs/helpers';
import { useUpdateMyClubsState } from 'app/clubs/stores/ClubStoreHelpers';

import { Button, ModalWindow } from 'core-components';
import { InputField } from 'components/ui';
import { Formik, Form } from 'formik';
import { useCreateClubChannelMutation } from 'generated/graphql';

import { parseError } from 'utils/errors';
import { validateChannelName } from 'utils/validation';
import SpinnerV2 from 'components/SpinnerV2';

const InviteLabel = styled.label.attrs({
  className: 'font-semibold text-sm leading-9',
})`
  color: #4d6c7d;
`;
interface ChannelValue {
  channelName: string;
}

const validate = (values: ChannelValue) => {
  const { channelName } = values;
  if (validateChannelName(channelName)) {
    const errors = { channelName: validateChannelName(channelName) };
    return errors;
  }
  return {};
};

interface CreateChannelModalProps {
  isOpen: boolean;
  handleClose: () => void;
  juniClubId: string;
  studentId?: string;
  instructorUserId?: string;
}
const CreateChannelModal: React.FC<CreateChannelModalProps> = ({
  isOpen,
  handleClose,
  juniClubId,
  studentId,
  instructorUserId,
}) => {
  const history = useHistory();
  const updateMyClubsState = useUpdateMyClubsState();
  const [createChannel] = useCreateClubChannelMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (
    values: ChannelValue,
    { setFieldError }: { setFieldError: (field: string, errorMsg: string) => void },
  ) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const { channelName } = values;
      await createChannel({
        variables: {
          input: {
            juniClubId,
            studentId,
            instructorUserId,
            channelName,
          },
        },
      });
      await updateMyClubsState({ studentId, instructorUserId });
      handleClose();
      history.push(getClubLink({ studentId, juniClubId, channelName }));
    } catch (err) {
      setFieldError('channelName', parseError(err, `Sorry, that didn't work.`));
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <ModalWindow isOpen={isOpen} closeModal={handleClose} title="Create a Channel">
      <Formik
        initialValues={{ channelName: '' }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        <Form>
          <InviteLabel>Name your channel</InviteLabel>
          <InputField.Formik
            className="pb-2"
            name="channelName"
            placeholder="Channel name..."
          />
          <Button width="full" type="submit" hasArrowIcon className="mt-4">
            {isSubmitting ? <SpinnerV2 /> : 'Create Channel'}
          </Button>
        </Form>
      </Formik>
    </ModalWindow>
  );
};
export default CreateChannelModal;
