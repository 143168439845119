import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import juniAxios from 'services/axios';
import Auth from 'modules/Auth';

import { parseError } from 'utils/errors';
import { Button } from 'core-components';
import AuthLayout from '../AuthLayout';

import './login.css';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

class Login extends Component {
  state = {
    email: '',
    password: '',
    errorMessage: '',
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ errorMessage: '' });

    juniAxios
      .post(
        `/auth/login`,
        {
          email: this.state.email,
          password: this.state.password,
          userType: this.props.userType,
        },
        config,
      )
      .then(res => {
        Auth.authenticateUser(res.data.token);
        return this.props.checkAuth();
      })
      .then(() => {
        const { redirectTo } = queryString.parse(window.location.search);

        if (this.props.userType === 'teacher') {
          this.props.history.push(redirectTo ?? '/teacher');
        } else {
          this.props.history.push(redirectTo ?? '/learner');
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          console.error(err.response || err);
          this.setState({ errorMessage: err.response.data.message });
        } else {
          this.setState({
            errorMessage: parseError(err),
          });
        }
      });
  };
  render() {
    return (
      <AuthLayout>
        <div className="auth-form" id="login">
          <h1>
            Sign into your{' '}
            {this.props.userType === 'teacher' ? 'Instructor' : 'Juni'} account
          </h1>
          <hr />
          <form onSubmit={e => this.handleSubmit(e)}>
            {this.state.errorMessage ? (
              <div className="form-error-wrap">
                <div className="form-error">{this.state.errorMessage}</div>
              </div>
            ) : null}
            <div className={`form-field${this.state.errorMessage ? ' error' : ''}`}>
              <div className="form-field-icon">
                <span className="icon-mail" />
              </div>
              <input
                type="text"
                name="email"
                value={this.state.email}
                onChange={e => this.handleChange(e)}
                placeholder="Enter your email"
              />
            </div>
            <div className={`form-field${this.state.errorMessage ? ' error' : ''}`}>
              <div className="form-field-icon">
                <span className="icon-lock" />
              </div>
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={e => this.handleChange(e)}
                placeholder="Enter your password"
              />
            </div>
            <div className="btn-group">
              <Link to="/forgot_password" className="form-reset-link">
                <small>Forgot your password?</small>
              </Link>
              <Button type="submit" variant="pink" hasArrowIcon>
                Log in
              </Button>
            </div>
          </form>
        </div>
      </AuthLayout>
    );
  }
}
Login.propTypes = {
  userType: PropTypes.string.isRequired,
};

export default withRouter(Login);
