import React, { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { getApolloErrorInfo } from 'services/apollo/errors';
import Message from '../Message';
import Loading from '../Loading';
import PageError from '../PageError';

export interface ErrorableLoadingProps {
  /**
   * Specifies if there is an error present.
   */
  error?: Error | string | null;
  /**
   * Specifies if the error will render as a PageError component. If false, a Message component renders instead.
   */
  pageError?: boolean;
}

function getErrorMessage(error: Error | string): string {
  if (typeof error === 'string') {
    return error;
  }
  if (error instanceof ApolloError) {
    const { message } = getApolloErrorInfo(error);
    return `Sorry, we could not load the data. ${message}`;
  }
  return error.message;
}

/**
 * This is something of a "meta-component" that wraps the `Loading`, `Message`,
 *  and `PageError` components.
 *
 * The idea is that the `Loading` component is displayed if there's no error present.
 * If there is an error, it displays either a `Message` or a `PageError`.
 */
const ErrorableLoading: FC<ErrorableLoadingProps> = ({ error, pageError }) => {
  if (error) {
    const message = getErrorMessage(error);

    if (pageError) {
      return <PageError>{message}</PageError>;
    }
    return <Message status="error">{message}</Message>;
  }
  return <Loading />;
};

export default ErrorableLoading;
