import React from 'react';
import DividerComponent from '../Divider';
import CardContext, { useCardContext } from './CardContext';

const getNegativeMarginStyle = (
  padding: React.ContextType<typeof CardContext>['padding'],
) => {
  if (!Array.isArray(padding)) {
    return `-mx-${padding}`;
  }
  switch (padding.length) {
    case 1:
      return `-mx-${padding[0]}`;
    case 2:
    case 3:
      return `-mx-${padding[1]}`;
    case 4:
      return `-mr-${padding[1]} -ml-${padding[3]}`;
    default:
      return '';
  }
};

/**
 * Divider only meant for use on a Card (applies Card padding as negative margin to divider).
 */
const Divider: React.FC = () => {
  const { padding } = useCardContext();
  const negativeMarginStyle = getNegativeMarginStyle(padding);
  return <DividerComponent className={negativeMarginStyle} />;
};

export default Divider;
