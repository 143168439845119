import { Course } from 'generated/graphql';

export const sortTypes = ['Recommended', 'Bestsellers', 'Newly Added'] as const;
export type SORT_TYPE = typeof sortTypes[number];

/**
 * This is currently utilized across all sortTypes, so call it the base sort.
 * Total enrollments, secondarily alpha on display name.
 */
const baseSort = (a: Course, b: Course) => {
  // 1) Total enrollments DESC
  if (a.statistics?.totalEnrollments !== b.statistics?.totalEnrollments) {
    return (
      (b.statistics?.totalEnrollments ?? 0) - (a.statistics?.totalEnrollments ?? 0)
    );
  }

  // 2) Alpha on display name.
  return a.displayName.localeCompare(b.displayName);
};

/**
 * Sort comparator functions for each type of sorting option.
 */
export const sortFunctions: {
  [sortType in SORT_TYPE]: (a: Course, b: Course) => number;
} = {
  Recommended: (a: Course, b: Course) => {
    // a course that has a recommendation rank goes higher than one that does not
    if (a.recommendationRank === null && b.recommendationRank !== null) {
      // sort b before a
      return 1;
    }
    if (a.recommendationRank !== null && b.recommendationRank === null) {
      // sort a before b
      return -1;
    }

    if (a.recommendationRank === b.recommendationRank) {
      return baseSort(a, b);
    }

    // both are numbers (TS isn't picking that up) and are not equal by here
    return (a.recommendationRank as number) - (b.recommendationRank as number);
  },
  Bestsellers: (a: Course, b: Course) => baseSort(a, b),
  'Newly Added': (a: Course, b: Course) => {
    const aNewlyAdded = a.tags.find(
      t => t.type === 'info' && t.value === 'Newly Added',
    );
    const bNewlyAdded = b.tags.find(
      t => t.type === 'info' && t.value === 'Newly Added',
    );
    if (aNewlyAdded && !bNewlyAdded) {
      // sort a before b
      return -1;
    }
    if (!aNewlyAdded && bNewlyAdded) {
      // sort b before a
      return 1;
    }
    if (aNewlyAdded && bNewlyAdded) {
      // newest first
      return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
    }

    return baseSort(a, b);
  },
};
