import pencilBase64 from 'images/base64/pencil.js';

class PencilTool {
  constructor(board) {
    this.board = board;
    this.activePathId = '';
    this.lastSendTime = 0;
    this.cachedPathData = {};
    this.activePathElem = null;
    this.name = 'pencil';
    this.eventHandlers = {
      mousedown: this.beginDrawing,
      mousemove: this.draw,
      mouseup: this.finishDrawing,
      mouseleave: this.finishDrawing,
      touchstart: this.beginDrawing,
      touchmove: this.draw,
      touchleave: this.finishDrawing,
      touchend: this.finishDrawing,
      touchcancel: this.finishDrawing,
    };
    this.customCursor = `url("${pencilBase64}") 0 11, crosshair`;
  }

  handleWsMsg = data => {
    if (data.type === 'line') {
      this.newSVGPath(data.id, data.color, data.x, data.y, true);
    } else if (data.type === 'line_point') {
      let parent =
        this.activePathElem && this.activePathElem.id === data.parentId
          ? this.activePathElem
          : this.board.canvas.getElementById(data.parentId);
      if (!parent) {
        parent = this.newSVGPath(
          data.parentId,
          data.color || 'black',
          data.x,
          data.y,
          true,
        );
      }
      this.appendToSVGPath(parent, data.x, data.y, true);
    }
  };

  beginDrawing = (e, x, y) => {
    e.preventDefault();
    this.activePathId = this.board.generateUniqueID('line');
    this.activePathElem = this.newSVGPath(this.activePathId, this.board.color, x, y);
  };

  draw = (e, x, y) => {
    const msSinceLastSend = performance.now() - this.lastSendTime;
    if (msSinceLastSend > 60 && this.activePathId) {
      let path = null;
      if (this.activePathElem && this.activePathId === this.activePathElem.id) {
        path = this.activePathElem;
      } else {
        path = this.board.canvas.getElementById(this.activePathId);
      }
      if (!path) {
        this.activePathElem = this.newSVGPath(this.activePathId, this.board.color);
        path = this.activePathElem;
      }
      this.lastSendTime = performance.now();
      this.appendToSVGPath(path, x, y);
    }
  };

  finishDrawing = (e, x, y) => {
    this.draw(e, x, y);
    if (this.activePathId) {
      this.board.appendToEditHistory({
        type: 'add',
        elem: this.board.canvas.getElementById(this.activePathId),
      });
    }
    this.activePathId = '';
  };

  newSVGPath = (id, color, x, y, noBroadcast) => {
    const path = this.board.newSVGChild('path');
    path.style.stroke = color || 'black';
    path.id = id;
    const pathData = path.getPathData();
    this.cachedPathData[path.id] = pathData;

    // Start the line with a moveTo statement
    pathData.push({ type: 'M', values: [x, y] });

    // In order to always show at least a dot when the user clicks but does not move the cursor,
    // we have to add a second point making a curve between the first point and itself
    pathData.push({
      type: 'C',
      values: [x, y, x, y, x, y],
    });
    path.setPathData(pathData);
    this.board.appendChild(path);

    if (!noBroadcast) {
      this.board.broadcast({
        type: 'line',
        id,
        color,
        x,
        y,
        tool: 'pencil',
      });
    }
    return path;
  };

  appendToSVGPath = (path, x, y, noBroadcast) => {
    if (!this.cachedPathData[path.id]) {
      this.cachedPathData[path.id] = path.getPathData();
    }
    const pathData = this.cachedPathData[path.id];

    /* 
      All svg path commands in pathData other than the first move command should be curve commands with the following structure:
        {
          type: 'C',
          values: [cx1, cy1, cx2, cy2, x, y]
        },
      where:
        (cx1, cy1) = the 1st control point
          - determines the slope of the curve as it leaves the previous curve's endpoint
          - slope is the slope of the line between the previous point's endpoint and this 1st control point

        (cx2, cy2) = the 2nd control point
          - determines the slope of the curve as it approaches the current endpoint (x,y)
          - slope is the slope of the line between this 2nd control point and the endpoint

        (x, y) = the curve's endpoint coordinates

      Find more details here: 
      https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths#Curve_commands
    */

    // Last command in the path
    const lastCommand = pathData[pathData.length - 1].values;
    const lastPoint = lastCommand.slice(lastCommand.length - 2);
    const [lastX, lastY] = lastPoint;

    // Previous (next to last) command in the path
    const prevCommand = pathData[pathData.length - 2].values;
    const prevPoint = prevCommand.slice(prevCommand.length - 2);
    const [prevX, prevY] = prevPoint;

    // The endpoint coordinates of the new curve command to append to the path
    const newX = x;
    const newY = y;

    // Difference between the previous (next to last) point and new point in each dimension
    const prevToNewXDiff = newX - prevX;
    const prevToNewYDiff = newY - prevY;

    // Distance between the previous point and new point
    const distPrevNew = Math.hypot(prevToNewXDiff, prevToNewYDiff);

    if (distPrevNew === 0) {
      // Don't need to add to the curve if the previous point is the same as the new point
      return;
    }

    // Distance between the last point and previous point
    const distLastPrev = Math.hypot(lastX - prevX, lastY - prevY);

    // Distance between the last point and new point
    const distLastNew = Math.hypot(lastX - newX, lastY - newY);

    // Ratio of distance(previous point, last point) and distance(previous point, new point)
    const rLastPrev = distLastPrev / distPrevNew;

    // Ratio of distance(last point, new point) and distance(previous point, new point)
    const rLastNew = distLastNew / distPrevNew;

    // Curve direction and magnitude for each dimension
    const curveDirAndMagX = prevToNewXDiff * 0.3;
    const curveDirAndMagY = prevToNewYDiff * 0.3;

    // Adjust the 2nd control point of the last curve command to smooth out the corner
    lastCommand[2] = lastX - rLastPrev * curveDirAndMagX;
    lastCommand[3] = lastY - rLastPrev * curveDirAndMagY;

    // Coordinates of the 1st control point of the new curve command
    const cx1 = lastX + rLastNew * curveDirAndMagX;
    const cy1 = lastY + rLastNew * curveDirAndMagY;

    // The 2nd control point of the new point is set to be the same as the endpoint so that the slope approaching the endpoint
    // is determined by the 1st control point.
    // If or when another point is added, the 2nd control point of this curve command will be adjusted accordingly.
    pathData.push({
      type: 'C',
      values: [cx1, cy1, x, y, x, y],
    });

    path.setPathData(pathData);
    if (!noBroadcast) {
      this.board.broadcast({
        type: 'line_point',
        parentId: this.activePathId,
        x,
        y,
        tool: 'pencil',
      });
    }
  };
}

export default PencilTool;
