import React from 'react';

const JuniIconSquare = () => (
  <svg
    style={{ display: 'block' }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1000 1000"
  >
    <defs>
      <path id="prefix__a" d="M0 0h1002.33v1000H0z" />
    </defs>
    <clipPath id="prefix__b">
      <use xlinkHref="#prefix__a" overflow="visible" />
    </clipPath>
    <path
      d="M480.82 500.02c0-276.15 223.85-500.03 500-500.03s500.01 223.88 500.01 500.03c0 276.14-223.86 499.98-500.01 499.98s-500-223.84-500-499.98"
      clipPath="url(#prefix__b)"
      fill="#6256a5"
    />
    <path
      d="M-519.22 500.02C-519.22 223.87-295.36-.01-19.2-.01c276.15 0 500.02 223.88 500.02 500.03C480.83 776.16 256.96 1000-19.2 1000c-276.16 0-500.02-223.84-500.02-499.98"
      clipPath="url(#prefix__b)"
      fill="#292763"
    />
  </svg>
);

export default JuniIconSquare;
