import React, { FC, FormEvent, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';

import { Card } from 'core-components';

import makeCheckoutService from 'services/signupSessions/checkoutService';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';

import { ValidationError } from 'app/signup_session/components/InputField';

import { SignupData, SignupSessionProps } from 'app/signup_session/types';
import CheckoutContext from 'app/signup_session/hooks/useCheckoutContext/CheckoutContext';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import PAYMENT_ERROR_COPY from 'constants/stripe_error_copy';

import derivePlanIdFromBundleData from 'app/signup_session/lib/derivePlanIdFromBundleData';

import guessTimezoneValue from 'utils/guessTimezoneValue';

import updateBundleWithOnboarding from 'app/signup_session/lib/updateBundleWithOnboarding';

import { AFFILIATE_CONVERSION_TAGS } from 'constants/affiliate_conversion_tags';
import AffiliateContext from 'modules/AffiliatesContext';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import navStates from 'app/signup_session/navigation/states';
import { NAV_VERSIONS, ORDINALS } from 'app/signup_session/navigation/constants';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import { getCourseFormatFromBundleName } from 'app/signup_session/lib/getCourseFormatFromBundleName';
import { makePriceBeforeCoupon } from 'app/signup_session/lib';
import PaymentForm from './PaymentForm';

const defaultErrorMessage =
  'An error occurred while processing your card. Try again in a little bit.';

const Checkout: FC<SignupSessionProps> = ({ history, location }) => {
  const { signupData, setSignupSession, fullStory, flags } = useSignupContext();
  const activeStudent = signupData.students?.[0];
  const bundleSelections = getStudentBundleSelections(activeStudent?.bundle);
  const { getNextPage, hasNextPage, routerType } = useNavRouter();

  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);

  const [discountIsValid, setDiscountIsValid] = useState(true);
  const [referralCodeValid, setReferralCodeValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [emailExists, setEmailExists] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    {
      field: string;
      message: string;
    }[]
  >([]);
  const [stripeError, setStripeError] = useState('');

  const resetErrors = () => {
    setStripeError('');
    setErrorMessage('');
    setEmailExists(false);
    setValidationErrors([]);
  };

  const stripe = useStripe();
  const elements = useElements();

  const { trackAffiliateConversion } = useContext(AffiliateContext);

  if (hasNextPage(navStates.signup.checkout, ROUTE_EVENT.LOAD, { signupData })) {
    return (
      <Redirect
        to={getNextPage(navStates.signup.checkout, ROUTE_EVENT.LOAD, { signupData })}
      />
    );
  }

  const sendConfirmationEmail = async (
    last4?: string,
  ): Promise<Partial<SignupData>> => {
    try {
      const res = await makeCheckoutService().sendConfirmationEmail(
        signupData._id!,
        last4,
      );
      return {
        confirmationSent: res?.data?.result === 'ok',
      };
    } catch (err) {
      return {};
    }
  };

  const calculateChargedPrice = () => {
    const bootcampPrice = bundleSelections[0]?.price
      ? bundleSelections[0].price / 100
      : undefined;
    const subscriptionPrice = activeStudent?.bundle
      ? makePriceBeforeCoupon(activeStudent.bundle.selections)
      : undefined;
    return bootcampPrice ?? subscriptionPrice;
  };

  const handleSubmit = async (e: FormEvent) => {
    if (paymentIsProcessing) return;
    setPaymentIsProcessing(true);
    e.preventDefault();

    await setSignupSession?.({
      students: (signupData.students ?? []).map(student => ({
        ...student,
        timezone: student.timezone ?? guessTimezoneValue(),
        bundle: {
          ...student.bundle!,
          stripePlanId: derivePlanIdFromBundleData(student.bundle!),
        },
      })),
    });

    if (!stripe || !elements || !signupData) {
      setPaymentIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setPaymentIsProcessing(false);
      return;
    }
    // get the payment intent token that we will send to the server
    const stripeResponse = await stripe.createToken(cardElement);
    // set local stripe error, if it exists
    setStripeError(stripeResponse?.error?.message ?? '');
    // if the response doesn't have a complete token, return out
    if (!stripeResponse?.token?.id) {
      setPaymentIsProcessing(false);
      return;
    }

    setPaymentIsProcessing(true);

    try {
      const checkoutRes = await makeCheckoutService().handleCheckout(
        {
          signupSessionId: signupData._id!,
          stripeToken: stripeResponse.token,
        },
        flags.isBootcampSignup,
      );

      if (checkoutRes.data.validationErrors) {
        setValidationErrors(checkoutRes.data.validationErrors);
        return;
      }

      if (!checkoutRes?.data?.success) {
        setPaymentIsProcessing(false);
        setErrorMessage(defaultErrorMessage);
        return;
      }

      if (checkoutRes.data.success === true && checkoutRes.data.data) {
        resetErrors();

        // update session with response from payment handler
        const setSignupRes = await setSignupSession({
          mongoParentId: checkoutRes.data?.data?.parent._id,
          submittedPayment: true,
          stripeData: {
            stripeCustomerId: checkoutRes.data?.data?.paymentMethod?.customer,
            last4: checkoutRes.data?.data?.paymentMethod?.last4,
          },
          invitationCode: checkoutRes.data?.data?.inviteCode,
          students:
            signupData.students
              ?.map(student => ({
                ...student,
                mongoStudentId: checkoutRes.data.data?.students.find(
                  (_student: any) =>
                    _student.firstName?.trim() === student.firstName?.trim() &&
                    _student.lastName?.trim() === student.lastName?.trim(),
                )?._id,
              }))
              .map(student => ({
                ...student,
                bundle: updateBundleWithOnboarding(
                  student.bundle!,
                  checkoutRes.data.data?.onboardingTickets ?? [],
                ),
              })) ?? signupData.students,
        });

        if (flags.isBootcampSignup || flags.isOnDemandSignup) {
          const sendConfirmationRes = await sendConfirmationEmail();
          setSignupSession({
            ...setSignupRes?.data?.data, // the signupData in state won't be updated from the above call yet, so we use the data retured by the API as its up to date
            ...sendConfirmationRes,
            completedEnrollment: true,
          });
        }

        fullStory?.setUserVars({
          parentId: checkoutRes.data?.data?.parent._id,
          studentIds: checkoutRes.data?.data?.students.map(student => student._id),
        });
        /**
         * Directly assigning stripeCustomerId as we don't have an updated signupData
         */
        JuniAnalytics.track('SubmitCheckoutForm', {
          signupSessionId: signupData._id,
          mongoParentId: signupData.mongoParentId,
          stripeCustomerId: checkoutRes.data?.data?.paymentMethod?.customer,
          bundleNames: signupData.students?.map(x => x.bundle?.bundleName),
          closeLeadId: signupData.closeLeadId,
          invitationLookupId: signupData.invitationLookupId,
          discountCodes: signupData.discountCodes,
          ordinal: ORDINALS[routerType][navStates.signup.checkout],
          funnelVersion: NAV_VERSIONS[routerType],
          funnelName: 'dcf',
          isMultiSubjectSignup: flags.multiSubject,
          selectedCourses: getStudentBundleSelections(activeStudent?.bundle)
            .map(selection => selection?.courseName)
            .filter(courseName => courseName),
          courseFormat: getCourseFormatFromBundleName(
            activeStudent?.bundle?.bundleName,
          ),
          value: calculateChargedPrice(),
        });

        window.rdt?.('track', 'SignUp');
        window.qp?.('track', 'CompleteRegistration');
        window.ttq?.track('Subscribe');
        window.lintrk?.('track', { conversion_id: 5692052 });

        if (trackAffiliateConversion) {
          const { stripeCustomerId } = checkoutRes.data.data?.parent ?? {};
          if (checkoutRes.data.data?.subscriptions) {
            checkoutRes.data.data?.subscriptions.forEach(({ id }) =>
              trackAffiliateConversion(
                AFFILIATE_CONVERSION_TAGS.FREE_TRIAL,
                id ?? '',
                stripeCustomerId ?? '',
              ),
            );
          }
          if (checkoutRes.data.data?.stripeCharge) {
            trackAffiliateConversion(
              AFFILIATE_CONVERSION_TAGS.FREE_TRIAL,
              checkoutRes.data.data?.stripeCharge.id ?? '',
              stripeCustomerId ?? '',
            );
          }
        }

        history.push(
          getNextPage(navStates.signup.checkout, ROUTE_EVENT.SUBMIT, { signupData }),
        );
      }
    } catch (e) {
      setPaymentIsProcessing(false);
      if (!e?.response?.data) {
        console.error(e);
        setErrorMessage(defaultErrorMessage);
        return;
      }
      const errorData = e.response.data;

      if (errorData.validationErrors) {
        setValidationErrors(errorData.validationErrors);
        return;
      }

      if (e.response.status === 409) {
        setEmailExists(true);
        return;
      }
      setEmailExists(false);
      if (errorData.error) {
        if (errorData.error === 'unknown') {
          setErrorMessage(defaultErrorMessage);
          return;
        }

        if (errorData.field === 'Stripe') {
          const stripeError = errorData.error as StripeError;

          setStripeError(
            stripeError.code && PAYMENT_ERROR_COPY[stripeError.code]?.copy
              ? PAYMENT_ERROR_COPY[stripeError.code].copy
              : stripeError.message ?? defaultErrorMessage,
          );

          return;
        }

        setErrorMessage(errorData.error);
        return;
      }

      setErrorMessage(defaultErrorMessage);
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        discountIsValid,
        setDiscountIsValid,
        referralCodeValid,
        setReferralCodeValid,
      }}
    >
      <div className="flex flex-col-reverse">
        <form onSubmit={handleSubmit} className="flex items-center justify-center">
          <Card
            borderWidth="0"
            className="w-full sm:max-w-screen-xs sm:w-3/5 sm:rounded-lg"
            noRounding
          >
            <div>
              {validationErrors.map(error => (
                <ValidationError key={error.field}>{error.message}</ValidationError>
              ))}
              <PaymentForm
                stripeError={stripeError}
                errorMessage={errorMessage}
                emailExists={emailExists}
                paymentIsProcessing={paymentIsProcessing}
                campaign={signupData?.campaign}
                onBack={() =>
                  history.push(
                    getNextPage(navStates.signup.checkout, ROUTE_EVENT.BACK, {
                      signupData,
                      search: location.search,
                      shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
                    }),
                  )
                }
              />
            </div>
          </Card>
        </form>
      </div>
    </CheckoutContext.Provider>
  );
};
export default Checkout;
