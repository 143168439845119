import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { Panel } from 'core-components';
import classNames from 'classnames';

const PrimaryContent = styled.div.attrs((props: any) => ({
  className: classNames(props.className, 'flex flex-col'),
}))<any>`
  > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

interface SchedulePanelProps {
  renderActionItem: () => React.ReactNode;
  renderHeader: () => React.ReactNode;
  renderSubheader: () => React.ReactNode;
  className?: string;
  actionItemClassName?: string;
}

const SchedulePanel: FC<SchedulePanelProps> = ({
  className,
  actionItemClassName,
  renderHeader,
  renderSubheader,
  renderActionItem,
}) => (
  <Panel
    className={classNames(
      'flex flex-col xs:flex-row xs:items-center',
      'font-graphik text-sm',
      'rounded-lg',
      className,
    )}
    padding={['5', '6']}
  >
    <PrimaryContent>
      {renderHeader()}
      {renderSubheader()}
    </PrimaryContent>
    <div className={classNames('mt-2 xs:ml-auto xs:mt-0', actionItemClassName)}>
      {renderActionItem()}
    </div>
  </Panel>
);

export default SchedulePanel;
