import { RouteComponentProps } from 'react-router-dom';
import { CustomProject } from 'models';
import { IBadgeProperties } from 'components/ProjectCard/models';

export enum ProjectTypes {
  scratch = 'scratch',
  pythonTurtle = 'pythonTurtle',
  math = 'math',
  whiteboard = 'whiteboard',
  java = 'java',
  python = 'python',
  web = 'web',
  js = 'js',
  cpp = 'cpp',
  investing = 'investing',
  english_writing = 'english_writing',
  english = 'english',
  unknown = 'unknown',
}

export interface IPlaygroundProps {
  currentUser: {
    email: string;
    firstName: string;
    lastName: string;
    permissions?: Array<string>;
    roles: Array<string>;
    userType: string;
    _id: string;
    activeStudentId?: string;
    username?: string;
  };
  match: RouteComponentProps['match'];
  projects?: Array<IPlaygroundProjectForView>;
  idLookup?: any;
  setHideNavBar: () => void;
}

export interface IPlaygroundProject {
  projectType: string;
  userType: string;
  projectId: string;
}

export interface IUpdateProjectNameParams {
  projectId: string;
  desiredProjectName: string;
}

export interface IValidationResponse {
  valid: boolean;
  msg?: string;
}

export interface IPlaygroundProjectForView extends CustomProject {
  properties?: IBadgeProperties;
}

export interface IInitialFilterState {
  [key: string]: {
    visible: boolean;
  };
}

export interface IFilterAction {
  type: string;
  payload: {
    key: string;
    visible: boolean;
  };
}

export enum ProjectsViewStates {
  playgroundProjects,
  customProjects,
}
