import { SCREEN_SIZES } from 'constants/screenSizes';
import { useState, useEffect } from 'react';

export interface WindowSize {
  width: number | undefined;
  height: number | undefined;
  size: string | undefined;
}

export enum ScreenSizes {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
    size: undefined,
  });

  useEffect(() => {
    const getDeviceFromSize = (size: number): string | undefined => {
      if (size <= SCREEN_SIZES.mobile.max) {
        return ScreenSizes.mobile;
      }
      if (size > SCREEN_SIZES.mobile.max && size <= SCREEN_SIZES.tablet.max) {
        return ScreenSizes.tablet;
      }
      if (size > SCREEN_SIZES.tablet.max) {
        return ScreenSizes.desktop;
      }
      return undefined;
    };

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        size: getDeviceFromSize(window.innerWidth),
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
