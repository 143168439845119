import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { NewButton, Icon, RadioGroup } from 'core-components';
import React, { useState } from 'react';
import { DEFAULT_STUDENT_VALUES, UPDATE_FLOWS } from '../../LearnerAccount';
import LearnerPortalModal from '../LearnerPortalModal';

const StudentSelectorModal = ({
  formState,
  cardQuery,
  studentsQuery,
  subscriptionsQuery,
  updateFormValue,
  updateFormState,
}) => {
  const [currentStudentId, setCurrentStudentId] = useState('');
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudent = currentStudentId
    ? students.find(student => student._id.toString() === currentStudentId)
    : {};

  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];

  const activeStudentIds = subscriptions
    .filter(subscription => subscription.status !== 'canceled')
    .reduce(
      (ids, subscription) =>
        ids.concat(...(subscription?.metadata?.studentIds?.split(',') || [])),
      [],
    );

  const inactiveStudents = students.filter(
    student => !activeStudentIds.includes(student._id.toString()),
  );
  const card = cardQuery?.data?.defaultPaymentInformationByParentId || {};

  return (
    <LearnerPortalModal
      title="New Subscription"
      updateFormState={updateFormState}
      formState={formState}
      renderPrimaryButton={
        <NewButton
          disabled={!currentStudent._id}
          onClick={() => {
            const nextModal = 'course_select';
            const { modal: currentModal } = formState;
            const updateFlow = UPDATE_FLOWS.REENGAGE_CHURN;

            JuniAnalytics.track(`${currentModal}_button_clicked`, {
              funnel: 'subscription_upgrade_downgrade',
              sourceModal: currentModal,
              destinationModal: nextModal,
              actionFlow: updateFlow,
            });

            updateFormState(nextModal, 'modal');
            updateFormState(updateFlow, 'updateFlow');
            updateFormValue(currentStudent, 'currentStudent');
          }}
        >
          <div className="font-medium">Next</div>
        </NewButton>
      }
    >
      {_.isEmpty(card) ? (
        <div className="my-2.5 text-base">
          To make changes to this subscription, please add a payment method.
        </div>
      ) : (
        <div className="flex flex-col text-j-dark-600 w-full">
          <div className="flex flex-row justify-between">
            <div className="text-md font-medium leading-10">
              Choose a student to re-enroll or add new
            </div>
            <div className="flex">
              <NewButton
                onClick={() => {
                  const nextModal = 'student_create';
                  const { modal: currentModal } = formState;
                  const updateFlow = UPDATE_FLOWS.NEW_STUDENT;

                  JuniAnalytics.track(`${currentModal}_button_clicked`, {
                    funnel: 'subscription_upgrade_downgrade',
                    sourceModal: currentModal,
                    destinationModal: nextModal,
                    actionFlow: updateFlow,
                  });

                  updateFormState(nextModal, 'modal');
                  updateFormState(updateFlow, 'updateFlow');
                  updateFormValue(DEFAULT_STUDENT_VALUES, 'currentStudent');
                }}
                renderIconLeft={props => <Icon.Plus {...props} />}
                variant="secondary"
                size="small"
              >
                Add New
              </NewButton>
            </div>
          </div>
          <RadioGroup
            name="student"
            orientation="vertical"
            options={inactiveStudents.map(student => ({
              value: student._id.toString(),
              label: `${student.firstName} ${student.lastName}`,
            }))}
            onChange={selected => {
              setCurrentStudentId(selected);
            }}
            selectedValue={currentStudentId}
          />
        </div>
      )}
    </LearnerPortalModal>
  );
};

export default StudentSelectorModal;
