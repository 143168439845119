import styled from 'styled-components/macro';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CheckedField } from 'components/ui';

export const MyClubsBackground = styled.div.attrs({
  className: 'bg-juni-light-100',
})`
  width: 100%;
  flex-grow: 1;
`;

export const MyClubsContainer = styled.div.attrs({
  className: 'flex flex-col max-w-screen-xl mx-auto box-border px-4 pb-10',
})``;

export const Header = styled.div.attrs({
  className: 'flex flex-col w-full mb-6 md:flex-row',
})``;

export const HeaderLeft = styled.div.attrs({ className: 'flex flex-col flex-1' })``;

export const HeaderRight = styled.h1.attrs({ className: 'flex flex-col' })``;

export const Title = styled.h1.attrs({ className: 'pb-4 mb-0' })`
  color: #335875;
`;

export const SubTitle = styled.div.attrs({ className: 'pr-10' })`
  font-size: 16px;
  font-weight: 600;
`;

export const MyClubsContent = styled.div.attrs({
  className: 'flex flex-col md:flex-row',
})``;

export const LeftColumn = styled.div.attrs({
  className: 'flex flex-col mr-8 md:w-1/3',
})`
  flex-shrink: 0;
`;

export const YourClubs = styled.div.attrs({ className: 'flex flex-col' })``;

export const MainColumn = styled.div.attrs({ className: 'flex flex-col' })`
  flex-grow: 1;
`;

export const MainColumnHeader = styled.div.attrs({
  className: 'flex flex-col items-center mb-6 md:flex-row',
})`
  @media (min-width: 768px) {
    height: 42px;
  }
`;

export const SearchBox = styled.div.attrs({ className: 'flex ml-3' })`
  input {
    box-sizing: border-box;
    background-color: #fbfeff;
    border: 2px solid #d1eaf7;
    border-radius: 20px;
    font-size: 14px;
    width: 16em;
    height: 100%;
    color: #335875;
    font-weight: 600;
    font-family: 'Open Sans';
    padding: 0px 12px;

    ::placeholder {
      color: #a1becd;
    }
  }
  @media (min-width: 768px) {
    height: 42px;
  }
`;

export const SearchAndTagResults = styled.div.attrs({
  className: 'grid grid-cols-1 md:grid-cols-2 gap-8',
})``;

export const MyClubsTabButton = styled(Link).attrs((props: any) => ({
  className: classNames(
    'flex items-center',
    'rounded-3xl',
    'cursor-pointer ',
    'py-2 px-2.5 mr-3',
    `${
      props.isActive ? 'text-juni-blue-500' : 'text-blue-gray-500'
    }  text-sm font-semibold`,
    'my-2 sm:my-0',
  ),
}))<any>`
  text-decoration: none;
  border: 2px solid ${props => (props?.isActive ? '#00a5ff' : '#d1eaf7')};
  background-color: ${props => (props?.isActive ? '#fbfeff' : 'transparent')};

  > span {
    margin-left: 0.5rem;
    white-space: nowrap;
  }
`;

export const MyClubsFilterSection = styled.div.attrs({
  className: 'flex flex-col sm:ml-auto sm:flex-row',
})``;

export const EventsCalendarDatePickerWrapper = styled.div.attrs({
  className: 'text-sm box-border sm:ml-3 my-2 sm:my-0 h-10',
})`
  & .react-datepicker__input-container input::placeholder {
    color: #a1becd;
  }
  & .react-datepicker__input-container input {
    background-color: #fbfeff;
    border: 2px solid #d1eaf7;
    color: #335875;
    font-family: Open Sans;
    font-weight: 600;
  }
  button.react-datepicker__close-icon:hover {
    box-shadow: none;
    color: red;
  }
  button.react-datepicker__close-icon,
  .react-datepicker__close-icon::after {
    height: 30px;
    width: 30px;
    margin-top: -0.25rem;
  }
  .react-datepicker__close-icon::after {
    background-color: transparent;
    font-size: 25px;
    color: hsl(0, 0%, 80%);
    transition: opacity 0.5s ease-in-out;
  }
  .react-datepicker__close-icon:hover::after {
    color: hsl(0, 0%, 60%);
    transition: opacity 0.5s ease-in-out;
  }
`;

export const EventsCalendarPortalSectionWrapper = styled.div`
  > div {
    margin-top: 0;
  }
`;

export const EventsHeader = styled.div.attrs({
  className: 'text-xs font-semibold text-white pt-2 pb-5 opacity-70 uppercase',
})`
  :after {
    background-color: #fff;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 70%;
    right: 0;
    margin-left: 0.5rem;
    opacity: 0.5;
  }
`;

export const EventsCalendarCheckedField = styled(CheckedField)`
  background-color: #fbfeff;
  border: 2px solid #d1eaf7;
  color: #335875;
  font-family: Open Sans;
  font-weight: 600;
  padding: 0.5rem;
  padding-right: 1rem;
`;
