import SpinnerV2 from 'components/SpinnerV2';
import { Button, Message, ModalWindow } from 'core-components';
import { useUpdateJuniCommunityEventMutation } from 'generated/graphql';
import React, { useState } from 'react';

interface EventDeleteModalProps {
  modalOpen: boolean;
  onClose: () => void;
  eventId?: string;
  eventName?: string;
  instructorUserId?: string;
  studentId?: string;
}
const EventDeleteModal: React.FC<EventDeleteModalProps> = ({
  modalOpen,
  onClose,
  instructorUserId,
  eventName,
  eventId,
}) => {
  const [updateEvent] = useUpdateJuniCommunityEventMutation();
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      updateEvent({
        variables: {
          input: {
            eventId,
            instructorUserId,
            isActive: false,
          },
        },
      });
      setSubmitting(false);
      onClose();
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      setSubmissionError('Something went wrong.');
    }
  };
  return (
    <ModalWindow
      title="Confirm Event Deletion"
      isOpen={modalOpen}
      closeModal={onClose}
    >
      <div>
        Are you sure you want to delete <strong>{eventName}</strong>?
      </div>
      <div>
        {submissionError && <Message status="error">{submissionError}</Message>}
      </div>
      <div className="flex flex-wrap -m-1">
        <Button
          hasArrowIcon
          className="flex-grow m-1"
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          hasArrowIcon
          className="flex-grow m-1"
          onClick={handleSubmit}
          disabled={submitting}
        >
          {submitting ? <SpinnerV2 /> : 'Delete'}
        </Button>
      </div>
    </ModalWindow>
  );
};
export default EventDeleteModal;
