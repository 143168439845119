import { MouseOrTouchEvent } from 'react-select/src/Select';
import { SIDE_DIRECTIONS } from './tool_util';
import TextTool from './text_tool';

interface NewTextData {
  elemId: string;
  id: string;
  x: string;
  y: string;
  width: string;
  height: string;
  color?: string;
  text?: string;
}

// build the subtree of elements that will represent the text input
const initializeNewText = (data: NewTextData, textTool: TextTool) => {
  const foreignObject = textTool.board.newSVGChild(
    'foreignObject',
  ) as SVGForeignObjectElement;
  foreignObject.id = data.elemId || data.id;
  foreignObject.classList.add('foreignObject');
  foreignObject.setAttribute('x', data.x);
  foreignObject.setAttribute('y', data.y);
  foreignObject.setAttribute('width', data.width);
  foreignObject.setAttribute('height', data.height);
  textTool.board.appendChild(foreignObject);

  const resizers = document.createElement('div');
  resizers.id = `${foreignObject.id}_resizers`;
  resizers.className = 'resizers';
  foreignObject.appendChild(resizers);

  // create corner handle
  const southeastHandle = document.createElement('div');
  southeastHandle.id = `${foreignObject.id}_se-handle`;
  southeastHandle.className = 'corner-handle se-handle';
  southeastHandle.addEventListener('mousedown', () => {
    window.addEventListener('mousemove', textTool.handleTextInputResize);
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', textTool.handleTextInputResize);
    });
  });
  southeastHandle.addEventListener('touchstart', () => {
    window.addEventListener('touchmove', textTool.handleTextInputResize, {
      passive: false,
    });
    window.addEventListener('touchend', () => {
      window.removeEventListener('touchmove', textTool.handleTextInputResize);
    });
  });
  resizers.appendChild(southeastHandle);

  // create side handles
  SIDE_DIRECTIONS.forEach(side => {
    const handle = document.createElement('div');
    handle.id = `${foreignObject.id}_${side}-handle`;
    handle.className = `side-handle ${side}-handle`;
    handle.addEventListener('mousedown', e => {
      textTool.cursorOffsetFromForeignObject = getCursorOffsetFromForeignObject(
        e,
        textTool.foreignObject,
        textTool.board.canvas,
      );
      window.addEventListener('mousemove', textTool.handleTextInputMove);
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', textTool.handleTextInputMove);
      });
    });
    handle.addEventListener('touchstart', e => {
      textTool.cursorOffsetFromForeignObject = getCursorOffsetFromForeignObject(
        e,
        textTool.foreignObject,
        textTool.board.canvas,
      );
      window.addEventListener('touchmove', textTool.handleTextInputMove, {
        passive: false,
      });
      window.addEventListener('touchend', () => {
        window.removeEventListener('touchmove', textTool.handleTextInputMove);
      });
    });
    resizers.appendChild(handle);
  });

  const textContainer = document.createElement('div');
  textContainer.className = 'text-container';
  textContainer.id = `${foreignObject.id}_container`;
  resizers.appendChild(textContainer);

  const text = document.createElement('div');
  text.className = 'text-input';
  if (!textTool.board.readOnly) {
    text.setAttribute('contentEditable', 'true');
  }
  text.id = `${foreignObject.id}_input`;
  text.style.color = data.color || 'black';
  if (data.text) text.innerText = data.text;
  text.addEventListener('paste', getPlainText);
  textContainer.appendChild(text);

  return { foreignObject, text };
};

// get cursor position relative to another element
const getCursorPos = (
  evt: MouseEvent | TouchEvent,
  elem: HTMLElement | SVGElement,
) => {
  const basis = elem.getBoundingClientRect();
  const position = { x: 0, y: 0 };
  if (evt instanceof MouseEvent) {
    position.x = evt.clientX - basis.left;
    position.y = evt.clientY - basis.top;
  } else if (evt instanceof TouchEvent) {
    position.x = evt.changedTouches[0].clientX - basis.left;
    position.y = evt.changedTouches[0].clientY - basis.top;
  }
  return position;
};

// get cursor position relative to foreignObject
const getCursorOffsetFromForeignObject = (
  e: MouseEvent | TouchEvent,
  foreignObject: SVGForeignObjectElement,
  canvas: SVGElement,
) => {
  const cursorPosition = getCursorPos(e, canvas);
  const foreignObjectPos = {
    x: foreignObject.x.baseVal.value,
    y: foreignObject.y.baseVal.value,
  };

  return {
    x: cursorPosition.x - foreignObjectPos.x,
    y: cursorPosition.y - foreignObjectPos.y,
  };
};

const getPlainText = (e: ClipboardEvent) => {
  e.preventDefault();
  const text = e.clipboardData ? e.clipboardData.getData('text/plain').trim() : '';
  document.execCommand('insertText', false, text);
};

const getEventTarget = (e: MouseOrTouchEvent) =>
  e.target instanceof HTMLElement
    ? (e.target as HTMLElement)
    : e.target instanceof SVGElement
    ? (e.target as SVGElement)
    : undefined;

const getBaseId = (id: string | undefined) => {
  if (!id) return undefined;
  return id.indexOf('_') === -1 ? id : id.substring(0, id.lastIndexOf('_'));
};

const isDifferentTarget = (
  input: HTMLDivElement | undefined,
  eventTarget: HTMLElement | SVGElement,
) => {
  const inputBaseId = getBaseId(input?.id);
  const targetBaseId = getBaseId(eventTarget.id);
  return inputBaseId !== targetBaseId;
};

const isValidTextTarget = (id: string) =>
  id.endsWith('_input') || id.endsWith('_container') || id.endsWith('_resizers');

const getTextInputFromEventTarget = (target: HTMLElement | SVGElement) => {
  if (target.id.endsWith('_input')) return target as HTMLDivElement;
  if (target.id.endsWith('_container')) return target.firstChild as HTMLDivElement;
  if (target.id.endsWith('_resizers'))
    return target.lastChild?.firstChild as HTMLDivElement;
};

export {
  getCursorPos,
  initializeNewText,
  getEventTarget,
  isDifferentTarget,
  isValidTextTarget,
  getTextInputFromEventTarget,
};
