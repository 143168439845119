import React, { FC } from 'react';
import ReactModal from 'react-modal';

import useScrollLock from 'hooks/scrollLock';
import { TailwindSpace } from 'theme/types';

import {
  ScrollableContent,
  CloseButton,
  CloseButtonIcon,
  REACT_MODAL_CONTENT_STYLES,
  REACT_MODAL_OVERLAY_STYLES,
  ModalHeader,
  ModalFooter,
} from 'core-components/NewModalWindow/styles';
import classNames from 'classnames';

ReactModal.setAppElement('#root');

export interface ModalWindowProps {
  /**
   * Function that closes the modal. Not set to be manipulable in Storybook.
   */
  closeModal: () => void;
  /**
   * Variable that determines if the modal is open or not. Not set to be manipulable in Storybook.
   */
  isOpen: boolean;
  /**
   * Title for the modal header
   */
  title?: string;
  /**
   * Description for the modal header
   */
  description?: string;
  /**
   * CSS `max-width` property for modal.
   */
  maxWidth?: string;
  /**
   * Content rendered inside the footer section. Ideally should be ModalTextFooter or ModalButtonsFooter
   */
  renderFooter?: () => React.ReactNode;
  /**
   * Content inside modal
   */
  children?: React.ReactNode;
  /**
   * Padding for the modal content. Styled with Tailwind scale
   */
  contentPadding?: TailwindSpace | TailwindSpace[];
  /**
   * Styles for the modal content.
   */
  contentMaxHeight?: string;
  /**
   * Determines whether close icon is visible.
   * Defaults to true and only applies when Modal has a header section.
   */
  showCloseIcon?: boolean;
  /**
   * Content in a secondary header directly below the main header
   */
  secondaryHeader?: () => React.ReactNode;
}

/**
 * Divider for ModalWindow sections
 */
const Divider: FC = () => <hr className={`border-0 bg-j-purple-100 h-px my-0 `} />;

interface ModalTextFooterProps {
  title?: string;
  description?: string;
}

export const ModalTextFooter: FC<ModalTextFooterProps> = ({
  title,
  description,
}) => (
  <div className="flex flex-col flex-wrap sm:flex-row font-graphik text-base w-full">
    <div className="text-j-dark-600 font-medium mr-3">{title}</div>
    <div className="text-j-dark-300">{description}</div>
  </div>
);

interface ModalButtonsFooterProps {
  /**
   * The button used to help users move through tasks in a user flow.
   * Some examples of primary actions are: Next, Complete, and Start.
   */
  primary: React.ReactNode;
  /**
   * Alternative to the primary actions often used to help users go back to a previous state.
   * Some examples are: Back, Edit, and Cancel.
   * Always accompanied by a primary action button.
   */
  secondary?: React.ReactNode;
  /**
   * When there are two buttons, uses justify-between to place buttons on left/right sides.
   * Defaults to true. Justify-centers both buttons when false.
   */
  justifyBetweenButtons?: boolean;
}

export const ModalButtonsFooter: FC<ModalButtonsFooterProps> = ({
  primary,
  secondary,
  justifyBetweenButtons = true,
}) => {
  const doubleButton = !!primary && !!secondary;
  return (
    <div
      className={classNames(
        'flex sm:flex-row',
        'w-full',
        `${
          doubleButton && justifyBetweenButtons
            ? 'justify-between'
            : 'justify-center'
        }`,
      )}
    >
      {secondary}
      {primary}
    </div>
  );
};

const ModalWindow: FC<ModalWindowProps> = ({
  children,
  closeModal,
  isOpen,
  title,
  description,
  maxWidth,
  renderFooter,
  contentPadding = '5',
  contentMaxHeight,
  showCloseIcon = true,
  secondaryHeader,
}) => {
  useScrollLock(isOpen);

  const hasHeader = !!title || !!description;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...REACT_MODAL_CONTENT_STYLES,
          maxWidth: maxWidth || REACT_MODAL_CONTENT_STYLES.maxWidth,
          width: hasHeader || renderFooter ? '100%' : 'auto',
        },
        overlay: REACT_MODAL_OVERLAY_STYLES,
      }}
    >
      {hasHeader && (
        <>
          <ModalHeader>
            <div className="flex flex-col flex-wrap sm:flex-row sm:items-center w-full">
              <div className="text-j-dark-600 font-medium font-graphik text-xl sm:mr-4">
                {title}
              </div>
              <div className="text-base text-j-dark-300 font-graphik">
                {description}
              </div>
            </div>
            {showCloseIcon && (
              <div className="ml-auto">
                <CloseButton aria-label="Close" onClick={closeModal}>
                  <CloseButtonIcon />
                </CloseButton>
              </div>
            )}
          </ModalHeader>
          <Divider />
          {secondaryHeader && (
            <>
              {secondaryHeader()}
              <Divider />
            </>
          )}
        </>
      )}
      <ScrollableContent
        withHeader={hasHeader}
        withFooter={!!renderFooter}
        style={{ maxHeight: contentMaxHeight }}
      >
        <div className={classNames('flex font-graphik', `p-${contentPadding}`)}>
          {children}
        </div>
      </ScrollableContent>
      {renderFooter && (
        <>
          <Divider />
          <ModalFooter>{renderFooter()}</ModalFooter>
        </>
      )}
    </ReactModal>
  );
};

export default ModalWindow;
