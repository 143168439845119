import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Dropzone, {
  IDropzoneProps,
  IMeta,
  IPreviewProps,
  MethodValue,
} from 'react-dropzone-uploader';

import { useField } from 'formik';

import { useRequestUploadUrlMutation, UploadDestination } from 'generated/graphql';
import CuteProgressBar from 'components/CuteProgressBar';
import { logError } from 'utils/errors';
import Button from '../Button';
import styles from './ImageUploadField.module.css';
import { getError, getExtension } from './shared';

interface DownloadMeta extends IMeta {
  downloadUrl: string;
}

interface Props {
  label?: string;
  secondaryLabel?: string;
  className?: string;
  name?: string;
  onChange?: (value: string, name?: string, meta?: IMeta) => void;
  uploadDestination: UploadDestination;
  filename?: string;
  value?: string;
  error?: string;
  canCancel?: boolean;
}

interface FormikProps extends Omit<Props, 'error'> {
  name: string;
}

type IImageUploadField = React.FC<Props> & {
  Formik: React.FC<FormikProps>;
};

const PreviewComponent: React.FC<IPreviewProps> = ({
  meta,
  canRestart,
  canRemove,
  canCancel,
  fileWithMeta,
}) => {
  const { percent, status } = meta;
  const error = getError(meta);

  if (error) {
    return (
      <div className="ui-row ui-horizontal-spacing">
        <div className={styles.uploadError}>{error}</div>
        {canRestart && <Button onClick={fileWithMeta.restart}>Retry</Button>}
        {canRemove && <Button onClick={fileWithMeta.remove}>Clear</Button>}
      </div>
    );
  }

  return (
    <div className="ui-vertical-spacing">
      <div className={styles.title}>Uploading...</div>
      <div className="ui-row ui-horizontal-spacing">
        <CuteProgressBar
          className={styles.progressBar}
          pct={status === 'done' ? 100 : percent}
        />
        {canCancel && (
          <Button
            onClick={() => {
              fileWithMeta.cancel();
              fileWithMeta.remove();
            }}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

const ImageUploadField: IImageUploadField = ({
  uploadDestination,
  className,
  label,
  secondaryLabel,
  name,
  value,
  filename,
  canCancel,
  onChange,
  error,
}) => {
  const [requestUploadUrl] = useRequestUploadUrlMutation();
  const [isEditing, setIsEditing] = useState(false);

  const getUploadParams = useCallback<
    NonNullable<IDropzoneProps['getUploadParams']>
  >(
    async ({ file, meta }) => {
      try {
        const result = await requestUploadUrl({
          variables: {
            input: {
              destination: uploadDestination,
              filename: filename
                ? `${filename}/image${getExtension(meta.name)}`
                : meta.name,
            },
          },
        });

        if (!result.data) {
          throw new Error('No data received from server');
        }
        const { uploadUrl, downloadUrl } = result.data.requestUploadUrl;
        if (!downloadUrl) {
          throw new Error('No download URL returned from server');
        }
        return {
          body: file,
          url: uploadUrl,
          meta: { downloadUrl },
          method: 'PUT' as MethodValue,
        };
      } catch (err) {
        logError(err);
        return { url: '' };
      }
    },
    [requestUploadUrl, uploadDestination, filename],
  );

  const handleChangeStatus = useCallback<
    NonNullable<IDropzoneProps['onChangeStatus']>
  >(
    ({ meta, remove }, status) => {
      if (status === 'done' && onChange) {
        onChange((meta as DownloadMeta).downloadUrl, name, meta);
        // remove it so more can be uploaded if needed
        remove();
        setIsEditing(false);
      }
    },
    [name, onChange],
  );

  return (
    <div className={classNames('ui-field', 'w-full', className)}>
      {label && <div className="ui-field-label">{label}</div>}
      {error && <div className="ui-field-error">{error}</div>}
      {!isEditing && value ? (
        <div className={styles.imageView}>
          <img src={value} alt="Uploaded file" />
          <Button
            className={styles.editPhoto}
            tertiary
            onClick={() => setIsEditing(true)}
          >
            Edit Photo
          </Button>
        </div>
      ) : (
        <>
          <Dropzone
            classNames={{
              dropzone: styles.dropzone,
              inputLabel: styles.inputLabel,
              input: styles.input,
            }}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            accept="image/*"
            inputContent={(files, extra) => (
              <div key="input" className="ui-vertical-spacing">
                <p>
                  {!extra.active
                    ? 'Drag and drop your photo here or click to upload'
                    : 'Drop to upload!'}
                </p>
                {secondaryLabel && (
                  <p className="text-sm text-center">{secondaryLabel}</p>
                )}
              </div>
            )}
            inputWithFilesContent={null}
            PreviewComponent={PreviewComponent}
            canCancel={canCancel}
          />
          {value && (
            <Button secondary onClick={() => setIsEditing(false)}>
              Cancel Edit
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const FormikImageUploadField: React.FC<FormikProps> = props => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value: string, name?: string): void => {
    helpers.setValue(value);
    if (props.onChange) props.onChange(value, name);
  };

  return (
    <ImageUploadField
      {...props}
      error={meta.touched ? meta.error : undefined}
      onChange={handleChange}
      value={field.value}
    />
  );
};

ImageUploadField.Formik = FormikImageUploadField;

export default ImageUploadField;
