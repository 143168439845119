import { COURSE_TYPES } from 'constants/subjects';

const buildNonPlaygroundLink = ({
  userId,
  projectId,
  courseType,
}: {
  userId: string;
  projectId: string;
  courseType: typeof COURSE_TYPES[keyof typeof COURSE_TYPES]; // "code" | "math" | "english" | "investing"
}): string => `/learner/${userId}/my_${courseType}/project/${projectId}`;

export default buildNonPlaygroundLink;
