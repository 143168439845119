import { Parent } from 'models';

import { QueryResult } from '@apollo/client';
import {
  LoadPaymentHistoryByParentIdQuery,
  LoadDefaultPaymentInformationByParentIdQuery,
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import { NewCard, NewButton, Icon } from 'core-components';
import {
  PaymentHistoryCard,
  PaymentInformationCard,
  PersonalInformationCard,
  SubscriptionsCard,
} from '../cards';

interface AccountDetailsSectionProps {
  isGuest: boolean;
  parent: Parent;
  paymentsQuery: QueryResult<LoadPaymentHistoryByParentIdQuery>;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  resetForm: () => void;
}

const AccountDetailsSection = ({
  isGuest,
  parent,
  cardQuery,
  paymentsQuery,
  subscriptionsQuery,
  studentsQuery,
  updateFormValue,
  updateFormState,
  resetForm,
}: AccountDetailsSectionProps) => (
  <div className="flex flex-col lg:flex-row gap-6">
    <div className="flex flex-col gap-6 w-full lg:w-1/2">
      <PersonalInformationCard isGuest={isGuest} parent={parent} />
      {!isGuest && (
        <>
          <PaymentInformationCard
            cardQuery={cardQuery}
            updateFormState={updateFormState}
          />
          <PaymentHistoryCard paymentsQuery={paymentsQuery} />
        </>
      )}
    </div>
    <div className="w-full lg:w-1/2">
      <NewCard padding="0" className="bg-j-dark-600 mb-6">
        <div className="p-6 flex flex-row space-between">
          <div className="flex flex-col gap-2">
            <div className="font-bold text-white">Create a new subscription</div>
            <div className="text-j-dark-200 mr-4">
              Add a new student to your account, or re-enroll a student who
              previously worked with Juni!
            </div>
          </div>
          <div className="py-4">
            <NewButton
              className="px-3 py-2"
              renderIconLeft={props => <Icon.Plus {...props} />}
              size="xsmall"
              onClick={() => {
                resetForm();

                const modalName = 'student_select_or_create';
                // JuniAnalytics.track(`learner_account_button_clicked`, {
                //   funnel: 'subscription_upgrade_downgrade',
                //   destinationModal: modalName,
                // });
                updateFormState(modalName, 'modal');
              }}
            >
              Create New
            </NewButton>
          </div>
        </div>
      </NewCard>
      {!isGuest && (
        <SubscriptionsCard
          subscriptionsQuery={subscriptionsQuery}
          studentsQuery={studentsQuery}
          updateFormValue={updateFormValue}
          updateFormState={updateFormState}
          resetForm={resetForm}
        />
      )}
    </div>
  </div>
);

export default AccountDetailsSection;
