import create, { SetState } from 'zustand';
import produce from 'immer';
import { devtools } from 'zustand/middleware';
import {
  MsgType,
  JuniClubLookupType,
  JuniClubEventLookupType,
  JuniClubMembershipInfoLookupType,
  PubnubObjectType,
  UnreadMsgLookupType,
  CurrentChatChannelLookupType,
} from 'app/clubs/MyClubsTypes';

// Important to understand how the types are set up within the ClubStore state
// juniClubs and juniClubMembershipInfos are "dictionaries" or "lookup objects"
//  as opposed to arrays - this is because we look up a specific club or membership
//  more often than listing all the clubs or memberships
// Meanwhile, sortedMessages is a sorted array of all the messages received by the PubNub client.
//  This includes both realtime messages and ones fetched from history. Note that they're not
//  the raw messages that come in - they have been parsed by the handler function in MyClubsLoader
// pubnub will contain the pubnub client instance which has to be initialized with information
//  from the Juni Server. All other components in the React app can pull and use the pubnub
//  client instance from this store. This is a cleaner alternative to using the pubnub-react package
export type ClubStoreType = {
  juniClubs: JuniClubLookupType;
  juniClubMembershipInfos: JuniClubMembershipInfoLookupType;
  juniClubsEvents: JuniClubEventLookupType;
  sortedMessages: MsgType[];
  unreadMessages: UnreadMsgLookupType;
  pubnub: PubnubObjectType;
  isClubsLoading: boolean;
  leavingClubId?: string;
  currentChatChannel: CurrentChatChannelLookupType;
  lastPreviewMessageFetchAt?: Date;
  previewMessagesByClub: Record<string, MsgType[]>;
  set: (x: any) => void;
};

// This is where we initialize the Zustand Club Store
// This includes modifying the set function so that it's wrapped in Immer
// as recommended in the Zustand documentation:
// https://github.com/pmndrs/zustand#sick-of-reducers-and-changing-nested-state-use-immer
// NOTE: if you add new terms here, see if you need toupdate src/app/clubs/stores/ClubStoreHelpers.ts:refreshPubnubCreds
const useClubStore = create(
  devtools((set: SetState<ClubStoreType>) => ({
    juniClubs: {},
    juniClubMembershipInfos: {},
    juniClubsEvents: {},
    sortedMessages: [],
    unreadMessages: {},
    pubnub: {},
    isClubsLoading: true,
    currentChatChannel: {}, // DO NOT USE outside of the Chat Component!
    lastPreviewMessageFetchAt: undefined, // Used for caching the Club preview messages in HomepageMainFeed.tsx
    previewMessagesByClub: {},
    set: (fn: any) => set(produce(fn)),
  })),
);

export default useClubStore;
