import axios from 'axios';
import baseUrl from '../baseUrl';

const getStudentEvents = async studentId => {
  const { data } = await axios.get(`${baseUrl}/student_events/${studentId}`);

  return data || [];
};

export default getStudentEvents;
