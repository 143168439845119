import { useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { MARKETING_SITE_LINKS } from 'constants/links';
import {
  BUNDLE_COMBINATIONS,
  COUPON_DISALLOW_LIST,
  COURSE_FORMAT,
} from 'constants/signup_sessions';
import * as R from 'ramda';
import discountCodeService from 'services/signupSessions/discountCodeService';
import {
  CourseSchedulingFormat,
  useGetCoursesQuery,
  useGetCourseOfferingByIdQuery,
} from 'generated/graphql';
import { DiscountData, SignupSessionProps, SubjectName } from '../types';
import findStudentById from '../lib/findStudentById';
import makeCourseBundlesMap from '../lib/makeCourseBundlesMap';
import parseDCFQuerystringParams from '../lib/parseDCFQuerystringParams';
import sumWeeklyClasses from '../lib/sumWeeklyClasses';
import updateStudentById from '../lib/updateStudentById';
import useSignupContext from './useSignupContext';
import updateSelectedDiscountData from '../lib/updateSelectedDiscountData';
import { getStudentBundleSelections } from '../lib/getStudentBundleSelections';

const listEquals = (list1: any[], list2: any[]): boolean =>
  list1.length === list2.length && list1.every(item => list2.includes(item));

const useParseSignupQueryParams = ({ location, history }: SignupSessionProps) => {
  const { signupData, setSignupSession, activeStudentId } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData);
  const bundleSelections = getStudentBundleSelections(student?.bundle);
  const queryParams = queryString.parse(window.location.search);

  const {
    weeklyClasses = 1,
    subjects: querySubjects,
    discountCode,
    courses,
    courseOfferingId,
    campaign,
  } = parseDCFQuerystringParams(student, window.location.search);

  const {
    data: offeringData,
    loading: offeringLoading,
  } = useGetCourseOfferingByIdQuery({
    variables: {
      id: courseOfferingId,
    },
    skip: !courseOfferingId,
  });

  // if the course provided is from the URL param, it is a course slug. If from signupData, it is a course name
  const courseQueryInput = courses?.[0] // course is manually provided in URL
    ? { slugs: [courses[0]] }
    : bundleSelections?.[0]?.courseName && // course AND SUBJECT aren't provided so we look up stored course.
      !queryParams.subjects && // If subject or offering ID is provided in URL it should override selected course.
      !queryParams.courseOfferingId
    ? { names: [bundleSelections[0].courseName] }
    : undefined;

  const { data: courseData, loading: courseLoading } = useGetCoursesQuery({
    variables: {
      input: courseQueryInput,
    },
    skip: !courseQueryInput,
  });

  const loading = courseLoading || offeringLoading;

  const selectedOffering = offeringData?.getCourseOffering;
  const selectedCourse = courseData?.getCourses?.[0] ?? selectedOffering?.course;
  const courseFormat = selectedCourse?.schedulingFormat;

  const subjects = useMemo(
    () =>
      selectedCourse
        ? ([selectedCourse.subject.displayName] as SubjectName[])
        : querySubjects,
    [querySubjects, selectedCourse],
  );

  // if the subject in the URL doesn't match the subject of the selected course, replace it
  if (subjects && querySubjects && !listEquals(subjects, querySubjects)) {
    queryParams.subjects = subjects as string[];
    queryParams.weeklyClasses = weeklyClasses.toString();
    history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
  }

  const getBundleName = (
    courseFormat?: CourseSchedulingFormat,
    weeklyClasses?: number,
  ) => {
    if (courseFormat === COURSE_FORMAT.onDemand) {
      return COURSE_FORMAT.onDemand;
    }
    if (courseFormat === COURSE_FORMAT.group) {
      return COURSE_FORMAT.group;
    }
    const { bundleName } =
      BUNDLE_COMBINATIONS.find(bundle =>
        bundle.weeklyClassTotal // the bundle has an explicitly defined weeklyClass set
          ? bundle.weeklyClassTotal === weeklyClasses // find the weeklyClass requested in the URL
          : bundle.weeklyClassMin // the bundle has a _minimum_ rather than _total_ weekly class property
          ? weeklyClasses! >= bundle.weeklyClassMin // innovator plan has a _minimum_ weekly class definition, so anything over that value falls under this case
          : undefined,
      ) ?? {};
    return bundleName;
  };

  const bundleName = getBundleName(courseFormat, weeklyClasses);

  const bundleData = bundleName
    ? makeCourseBundlesMap(
        bundleName,
        subjects,
        selectedOffering?.totalPriceInCents
          ? selectedOffering?.totalPriceInCents
          : undefined,
        courseOfferingId,
      )
    : undefined;

  useEffect(() => {
    if (
      !loading &&
      (!bundleName ||
        !student ||
        (bundleData &&
          (!bundleData?.subjects ||
            bundleData?.subjects.every(subject => !subject) ||
            bundleData?.weeklyClasses === undefined)))
    ) {
      window.location.href = MARKETING_SITE_LINKS.TUITION_PAGE;
    }
  }, [bundleData, bundleName, loading, student]);

  const signupDataCourses = bundleSelections?.map(
    bundleData => bundleData?.courseName,
  );
  const anySignupDataCourses =
    signupDataCourses.length > 0 && signupDataCourses.some(course => course);

  // if the courses in signupData and the courses in URL params do not match, AND there exists a course in
  // URL params OR there exists a course in signupData that was set by the course explorer, update the course
  // TODO: @ihiltonv - fix bug where if the user selects a course on the starter course page, then goes back
  // and selects the same course from the course explorer, the signupsession doesn't update to show that they
  // came from the course explorer.
  const shouldUpdateSelectedCourse =
    !listEquals(
      bundleSelections?.map(bundleData => bundleData?.courseName),
      [selectedCourse?.name],
    ) &&
    (selectedCourse ||
      // make sure that we only update courses set in course explorer, do not overwrite courses set by StarterCourse
      (anySignupDataCourses &&
        signupData.coursePlacement?.method === 'course-explorer'));

  useEffect(() => {
    if (
      !loading &&
      !bundleData?.courseOfferingId &&
      weeklyClasses !== undefined &&
      weeklyClasses >= 1 &&
      bundleData?.subjects &&
      bundleName &&
      bundleData &&
      (bundleName !== student?.bundle?.bundleName ||
        !listEquals(
          Object.keys(student?.bundle?.selections ?? {}),
          (bundleData?.subjects || []).map(subject => subject.subjectName),
        ) ||
        shouldUpdateSelectedCourse)
    ) {
      setSignupSession(
        updateStudentById(
          activeStudentId,
          {
            bundle: {
              bundleName,
              isCourseNameProvided: !!selectedCourse?.name,
              selections: R.reduce(
                (accumulator, subject) =>
                  R.assoc(subject.subjectName, {
                    subject: subject.subjectName,
                    weeklyClasses:
                      bundleName === COURSE_FORMAT.onDemand
                        ? bundleData.weeklyClasses
                        : weeklyClasses! > 1 && bundleData?.subjects?.length === 1
                        ? 2
                        : 1,
                    courseName: selectedCourse?.name || undefined,
                  })(accumulator),
                {},
                bundleData?.subjects ?? [],
              ),
            },
          },
          {
            ...signupData,
            coursePlacement: {
              ...signupData.coursePlacement,
              method: selectedCourse ? 'course-explorer' : undefined,
            },
          },
        ) ?? {},
      );
    }
  }, [
    activeStudentId,
    bundleData,
    bundleName,
    shouldUpdateSelectedCourse,
    loading,
    selectedCourse,
    setSignupSession,
    signupData,
    student?.bundle?.bundleName,
    student?.bundle?.selections,
    weeklyClasses,
  ]);

  useEffect(() => {
    if (
      loading ||
      bundleData?.courseOfferingId ||
      !bundleName ||
      !student ||
      weeklyClasses === undefined
    )
      return;
    const currentSelectionKeys = Object.keys(student.bundle?.selections ?? {});
    const currentWeeklyClassesSum = sumWeeklyClasses(
      student.bundle?.selections ?? {},
    );

    if (
      weeklyClasses < 1 &&
      subjects &&
      (currentSelectionKeys[0] !== subjects[0] ||
        currentSelectionKeys.length > 1 ||
        currentWeeklyClassesSum > 0.5 ||
        shouldUpdateSelectedCourse)
    ) {
      setSignupSession(
        updateStudentById(
          activeStudentId,
          {
            bundle: {
              bundleName,
              isCourseNameProvided: !!selectedCourse?.name,
              selections: {
                [subjects[0]]: {
                  subject: subjects[0],
                  weeklyClasses: 0.5,
                  courseName: selectedCourse?.name || undefined,
                },
              },
            },
          },
          {
            ...signupData,
            coursePlacement: {
              ...signupData.coursePlacement,
              method: selectedCourse ? 'course-explorer' : undefined,
            },
          },
        ) ?? {},
      );
    }
  }, [
    activeStudentId,
    bundleName,
    shouldUpdateSelectedCourse,
    loading,
    setSignupSession,
    signupData,
    student,
    subjects,
    weeklyClasses,
    selectedCourse,
    bundleData?.courseOfferingId,
  ]);

  useEffect(() => {
    if (
      loading ||
      !bundleData?.courseOfferingId ||
      !bundleName ||
      !student ||
      !selectedCourse
    ) {
      return;
    }
    const signupDataSelection = bundleSelections[0];

    if (
      subjects &&
      (selectedCourse?.name !== signupDataSelection?.courseName ||
        bundleData.courseOfferingId !== signupDataSelection.courseOfferingId ||
        bundleData.basePrice !== signupDataSelection.price ||
        bundleData.bundleName !== student.bundle?.bundleName)
    ) {
      setSignupSession(
        updateStudentById(
          activeStudentId,
          {
            bundle: {
              bundleName,
              isCourseNameProvided: !!selectedCourse?.name,
              selections: {
                [subjects[0]]: {
                  subject: subjects[0],
                  weeklyClasses: bundleData.weeklyClasses,
                  courseName: selectedCourse?.name || undefined,
                  price: bundleData.basePrice,
                  courseOfferingId: bundleData.courseOfferingId,
                },
              },
            },
          },
          {
            ...signupData,
            coursePlacement: {
              ...signupData.coursePlacement,
              method: selectedCourse ? 'course-explorer' : undefined,
            },
          },
        ) ?? {},
      );
    }
  }, [
    activeStudentId,
    bundleData,
    bundleName,
    bundleSelections,
    loading,
    selectedCourse,
    setSignupSession,
    signupData,
    student,
    subjects,
  ]);

  // TODO Kyle - determine if everything needs a campaign parm for marketing
  useEffect(() => {
    if (campaign && campaign !== signupData?.campaign) {
      setSignupSession({ campaign });
    } else if (!campaign && !signupData?.campaign) {
      setSignupSession({ campaign: '1fs' });
    }
  }, [campaign, signupData?.campaign, setSignupSession, courseFormat]);

  useEffect(() => {
    const addDiscountCodeToSessionIfValid = async (code: string) => {
      const res = await discountCodeService.validateCouponCode(code);
      if (res?.data?.error) return;
      const stripeResponse = res?.data?.data;
      if (!stripeResponse || !stripeResponse.valid) return;
      const discountToAdd: DiscountData = {
        discountCode: code,
        discount: stripeResponse.amount_off ?? stripeResponse.percent_off,
        discountType: stripeResponse.amount_off ? 'amount_off' : 'percent_off',
        duration: stripeResponse.duration,
        durationInMonths: stripeResponse.duration_in_months,
      };
      setSignupSession({
        discountCodes: updateSelectedDiscountData(discountToAdd, [
          ...(signupData?.discountCodes ?? []),
          { ...discountToAdd, isSelected: true },
        ]),
      });
    };

    const code = (discountCode || '').trim();
    if (!code) return;
    if (signupData.discountCodes?.find(data => data.discountCode === code)) return;
    if (COUPON_DISALLOW_LIST.includes(code)) return;
    addDiscountCodeToSessionIfValid(code);
  }, [discountCode, signupData, setSignupSession]);

  return bundleData;
};

export default useParseSignupQueryParams;
