import React from 'react';
import { Button } from 'core-components';
import { Link } from 'react-router-dom';
import { ViewModalProps } from './ClubCreator';
import { ForcedWidth, ButtonRow } from './styles';
import { CodeOfConduct } from '../ConductModal/CodeOfConduct';

interface SuccessModalProps extends ViewModalProps {
  requiresRefresh: boolean;
  clubUrl: string;
}

const ClubLink: React.FC<SuccessModalProps> = ({
  requiresRefresh,
  clubUrl,
  children,
}) =>
  requiresRefresh ? (
    <a href={clubUrl}>{children}</a>
  ) : (
    <Link to={clubUrl}>{children}</Link>
  );

export const ClubSuccess: React.FC<SuccessModalProps> = (
  props: SuccessModalProps,
) => (
  <div style={{ display: !props.visible ? 'none' : '' }}>
    <ForcedWidth>
      <CodeOfConduct />
    </ForcedWidth>
    <ButtonRow oneButton>
      <ClubLink {...props}>
        <Button data-cy="agree-terms">Agree & Go to Club!</Button>
      </ClubLink>
    </ButtonRow>
  </div>
);
