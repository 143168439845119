import React from 'react';
import styled from 'styled-components/macro';

const TooltipContainer = styled.div<any>`
  position: absolute;
  background: ${props => (props.lightBg === true ? '#fff' : '#00a5ff')};
  border: ${props =>
    props.lightBg === true
      ? '0.125rem solid #00A5FF'
      : '0.125rem solid rgba(255, 255, 255, 0.2)'};
  box-shadow: 0rem 0.25rem 0.75rem rgba(0, 137, 211, 0.4);
  border-radius: 0.375rem;
  color: ${props => (props.lightBg === true ? 'hsl(208, 100%, 11%)' : 'white')};
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.125rem;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: 600;
  padding: 0.35rem 0.75rem;
  z-index: 10;
  right: -0.25rem;
  top: calc(100% + 0.65rem);
  &:after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    border: 0.125rem solid
      ${props => (props.lightBg ? '#00a5ff' : 'rgba(255, 255, 255, 0.2)')};
    background: ${props => (props.lightBg === true ? '#fff' : '#00a5ff')};
    top: -0.4rem;
    right: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 0rem solid transparent;
    border-right: 0rem solid transparent;
    border-radius: 0.1875rem;
  }
`;

const BadgeTooltip: React.FC<{
  tooltipContent: JSX.Element | string;
  lightBg?: boolean;
}> = ({ tooltipContent, lightBg }) => (
  <TooltipContainer lightBg={lightBg}>{tooltipContent}</TooltipContainer>
);

export default BadgeTooltip;
