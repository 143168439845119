import React from 'react';
import Card from 'core-components/Card';

export interface SearchZeroStateProps {
  searchQuery: string;
}
const SearchZeroState: React.FC<SearchZeroStateProps> = ({ searchQuery }) => (
  <Card className="bg-juni-blue-50">
    <div className="flex flex-col justify-center items-center">
      {/* <div className="rounded-full w-20 h-20 flex justify-center items-center m-0 bg-juni-blue-100">
        <img src={NeutralFace} className="w-12 h-12" alt="" />
      </div> */}
      <h3 className="m-0 mt-5"> {`No results found for "${searchQuery}"`}</h3>
      <p className="m-0 mt-1 mb-4"> Refine your search or try again. </p>
    </div>
  </Card>
);

export default SearchZeroState;
