import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { parseError } from 'utils/errors';

const EditableCheckbox = ({ className, value, label, name, save, disabled }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = useCallback(
    async event => {
      setIsSaving(true);
      try {
        setError(null);
        await save(event.target.checked, name);
      } catch (err) {
        setError(parseError(err));
      } finally {
        setIsSaving(false);
      }
    },
    [name, save],
  );

  return (
    <div className={classNames('ui-editable-checkbox', className)}>
      {isSaving ? (
        <em>Saving...</em>
      ) : (
        <label>
          <input
            type="checkbox"
            checked={value || false}
            onChange={handleChange}
            disabled={disabled || false}
          />
          {label}
        </label>
      )}
      {error && <div className="ui-field-error">{error}</div>}
    </div>
  );
};

EditableCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  save: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default EditableCheckbox;
