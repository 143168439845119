import React from 'react';
import { SettingsIcon } from 'components/Icons';
import styled from 'styled-components/macro';
import BadgeContainer from './BadgeContainer';

const SettingsBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  .icon {
    width: 0.15rem;
    height: 0.15rem;
    path {
      fill: #adc2ce;
    }
  }
  &:hover {
    .icon path {
      fill: #00a5ff;
    }
  }
`;

const SettingsBadge: React.FC = () => (
  <BadgeContainer>
    <SettingsBadgeContainer>
      <SettingsIcon customFill />
    </SettingsBadgeContainer>
  </BadgeContainer>
);

export default SettingsBadge;
