import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Message } from 'core-components';

const intent = ['success', 'error'] as const;
type Intent = typeof intent[number];
export interface SelectionBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The currently selected state.
   */
  selected: boolean;
  /**
   * Selection event.
   */
  onClick: React.HTMLAttributes<HTMLDivElement>['onClick'];
  /**
   * Default false.
   */
  disabled?: boolean;
  /**
   * Optional title applied to the title and aria-label attributes.
   */
  title?: string;
  /**
   * The state of the selection box.
   * Default undefined is the base state.
   */
  intent?: Intent;
  /**
   * Render prop for optional badge content left of children main text.
   */
  renderLeftBadgeContent?: () => React.ReactNode;
  /**
   * Optional subtext message below main children content.
   */
  renderSubtextMessage?: () => React.ReactNode;
}

const SelectionBox: React.FC<SelectionBoxProps> = ({
  selected,
  onClick,
  disabled = false,
  title,
  intent,
  renderLeftBadgeContent,
  renderSubtextMessage,
  children,
  ...rest
}) => (
  <div
    {...rest}
    onClick={disabled ? noop : onClick}
    className={classNames(
      'p-4 pl-0 rounded-lg',
      disabled ? 'cursor-default' : 'cursor-pointer',
      'font-graphik',
      selected || intent !== undefined ? 'border-2' : 'border',
      'border-solid',
      {
        'text-j-dark-600': !intent && !disabled,
        'border-j-purple-300 hover:border-j-purple-500':
          !selected && !intent && !disabled,
        'border-j-purple-600 bg-j-purple-100': selected && !intent && !disabled,
        'text-j-pink-700 border-j-pink-700 bg-j-pink-100': intent === 'error',
        'text-j-green-700 border-j-green-600 bg-j-green-100': intent === 'success',
        'text-j-dark-200 border-j-purple-200': !intent && disabled && !selected,
      },
      rest.className,
    )}
    role="button"
    tabIndex={0}
    aria-disabled={disabled}
    aria-label={title}
    title={title}
  >
    <div className="flex items-center">
      {!!renderLeftBadgeContent && (
        <div
          className={classNames(
            'flex justify-center items-center',
            'ml-4 rounded-lg',
            'text-sm',
            {
              'bg-j-purple-100': !selected && !intent,
              'bg-j-purple-200': selected && !intent,
              'bg-j-green-200': intent === 'success',
              'bg-j-pink-200': intent === 'error',
              'text-j-purple-400': disabled && !intent && !selected,
            },
          )}
          // have to use min-width to avoid it condensing with wrapping text
          style={{ minWidth: '2rem', minHeight: '2rem' }}
        >
          {renderLeftBadgeContent()}
        </div>
      )}
      <div className="ml-4">{children}</div>
    </div>
    {!!renderSubtextMessage && (
      <div
        className={classNames(
          'flex items-center',
          renderLeftBadgeContent ? 'ml-16' : 'ml-4',
        )}
      >
        <Message
          status={intent === 'success' ? 'success' : 'error'}
          fullWidth={false}
          className={classNames(
            'mt-3',
            intent === 'success' ? 'bg-j-green-200' : 'bg-j-pink-200',
          )}
        >
          {renderSubtextMessage()}
        </Message>
      </div>
    )}
  </div>
);

export default SelectionBox;
