import React, { FC } from 'react';
import { Card } from 'core-components';

interface EventsCalendarZeroStateProps {
  datesQuery?: string;
  hasResultsWithoutFilter?: boolean;
}

const EventsCalendarZeroState: FC<EventsCalendarZeroStateProps> = ({
  datesQuery,
  hasResultsWithoutFilter,
}) => {
  const nextTwoWeeks = datesQuery?.includes('two weeks');
  return (
    <Card className="bg-juni-blue-50">
      <div className="flex flex-col justify-center items-center">
        <h3 className="m-0 mt-5">{`No ${
          nextTwoWeeks ? 'upcoming' : ''
        } events found ${!nextTwoWeeks ? `for ${datesQuery}` : ''}`}</h3>
        <p className="m-0 mt-1 mb-4">
          {`Try searching for a ${nextTwoWeeks ? 'future' : 'different'} date ${
            hasResultsWithoutFilter ? 'or remove filters' : ''
          } to see more events!`}
        </p>
      </div>
    </Card>
  );
};

export default EventsCalendarZeroState;
