import React, { FC } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

interface Props
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'label'> {
  label?: React.ReactNode;
  className?: string;
  onChange?: (value: string, name: string) => void;
  error?: string;
  dataCy?: string;
}

interface FormikProps extends Omit<Props, 'error' | 'value'> {
  name: string;
  dataCy?: string;
}

type IInputField = FC<Props> & {
  Formik: FC<FormikProps>;
};

const InputField: IInputField = ({
  className,
  dataCy,
  label,
  error,
  onChange,
  ...rest
}) => (
  <label className={classNames('ui-field', className)}>
    {label && <div className="ui-field-label">{label}</div>}
    <input
      className={classNames({
        'ui-input': true,
        'ui-input-error': !!error,
      })}
      {...rest}
      onChange={e => onChange && onChange(e.target.value, e.target.name)}
      data-cy={dataCy}
    />
    {error && <div className="ui-field-error">{error}</div>}
  </label>
);

const FormikInputField: FC<FormikProps> = props => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value: string, name: string): void => {
    helpers.setValue(value);
    if (props.onChange) props.onChange(value, name);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <InputField
      {...props}
      error={meta.touched ? meta.error : undefined}
      onChange={handleChange}
      onBlur={handleBlur}
      value={field.value}
    />
  );
};

InputField.Formik = FormikInputField;

export default InputField;
