import React, { FC, useState, useCallback } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { Moment } from 'moment-timezone';

import { parseError } from 'utils/errors';
import { parseMoment, SHORT_DATE_FORMAT } from 'constants/timezones';
import Button from '../Button';

import './index.css';

interface Props {
  className?: string;
  value?: string;
  name: string;
  save: (name: string, value: string) => Promise<any>;
  popOut: boolean;
}

const EditableDatePicker: FC<Props> = ({ className, value, name, save, popOut }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
    setError(null);
  }, []);

  const handleChange = useCallback(
    async (value: Moment) => {
      setIsSaving(true);
      try {
        await save(value.format(), name);
        setIsEditing(false);
      } catch (err) {
        setError(parseError(err));
      } finally {
        setIsSaving(false);
      }
    },
    [name, save],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, []);
  const momentValue = value ? parseMoment(value) : null;

  return (
    <div
      className={classNames(
        {
          'ui-editable-date-picker': true,
          'ui-pop-out': popOut,
        },
        className,
      )}
    >
      {isEditing ? (
        <div className="ui-editor">
          {isSaving ? (
            <em>Saving...</em>
          ) : (
            <DatePicker
              selected={momentValue}
              disabled={isSaving}
              onChange={handleChange}
              className="ui-input"
              utcOffset={(momentValue || parseMoment()).utcOffset() / 60}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            />
          )}
          {error && <div className="ui-field-error">{error}</div>}
          <div className="ui-button-row">
            <Button disabled={isSaving} onClick={handleCancel} small>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <>
          <span>{momentValue && momentValue.format(SHORT_DATE_FORMAT)}</span>
          <div className="ui-button-row">
            <Button onClick={handleEdit} small>
              Edit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditableDatePicker;
