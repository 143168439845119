import React, { FC } from 'react';
import { Icon } from 'core-components';

// This utility component allows you to dynamically and safely import an Icon with just a string.
// If the Icon library doesn't have the icon it will return a default
const IconComponent: FC<{ iconName?: string | null; size?: number }> = ({
  iconName,
  size = 20,
}) => {
  let iconElement = Icon.QuestionMark; // default icon to return

  const iconObject: { [key: string]: typeof iconElement } = { ...Icon }; // lil trick that makes this work
  if (iconName && Object.keys(iconObject).includes(iconName)) {
    iconElement = iconObject[iconName];
  }
  return React.createElement(iconElement, { height: `${size}`, width: `${size}` });
};

export default IconComponent;
