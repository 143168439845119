import React from 'react';
import styled from 'styled-components/macro';
import PlaygroundIcon from './PlaygroundIcon';
import { dropdownOptions } from './PlaygroundQuickstart';

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #d1eaf7;
  border-radius: 0.5rem;
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
  }

  & .empty-state-left__container {
    border-radius: 0.5rem 0 0 0.5rem;
    width: 40%;
    background: #fafcfd;
    border-right: 2px solid #d1eaf7;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      padding: 0 2rem;
    }
    @media (max-width: 650px) {
      width: 100%;
      border-bottom: 2px solid #d1eaf7;
      border-right: none;
      padding: 2rem 0;
    }
  }

  & .empty-state-right__container {
    border-radius: 0 0.5rem 0.5rem 0;
    background: white;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 650px) {
      padding: 2rem 0;
      width: 100%;
      align-items: center;
    }

    & .heading {
      margin: 0;
      padding: 0 0 0.5rem 0;
      color: #4d6c7d;
      font-size: 1.3rem;
      width: 90%;
    }

    & .playground-description {
      color: #4d6c7d;
      width: 90%;
      line-height: 1.5rem;
    }
  }
`;

const commaSeparatedProjectTypeLabels = dropdownOptions
  .map(
    ({ label }, i) =>
      `${i === dropdownOptions.length - 1 && i > 0 ? 'or ' : ''}${label}`,
  )
  .join(dropdownOptions.length > 2 ? ', ' : ' ');

const PlaygroundEmptyState: React.FC = () => (
  <EmptyStateContainer>
    <div className="empty-state-left__container">
      <PlaygroundIcon />
    </div>
    <div className="empty-state-right__container">
      <h2 className="heading">What are playground projects?</h2>
      <p className="playground-description">
        At Juni, Playground projects start as blank projects, where you choose the
        type of project you want to create ({commaSeparatedProjectTypeLabels}). You
        get full rein to build as creatively as you’d like, to showcase your skills!
        Once you’re done, publish your project to share your work with the community.
      </p>
    </div>
  </EmptyStateContainer>
);

export default PlaygroundEmptyState;
