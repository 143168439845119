import React, { FC } from 'react';
import classNames from 'classnames';

import { Arrow } from 'components/Icons';
import { TailwindWidth } from 'theme/types';

import {
  primaryTheme,
  secondaryTheme,
  transparentTheme,
  pinkTheme,
  pinkSecondaryTheme,
} from './themes';

export interface ButtonProps {
  /**
   * Specifies the size of the button.
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Specifies the variant of the button.
   */
  variant?: 'primary' | 'secondary' | 'transparent' | 'pink' | 'pinkSecondary';
  /**
   * Displays an arrow icon on the right of the button.
   */
  hasArrowIcon?: boolean;
  /**
   * Specifies whether the icon should be on the left or right.
   */
  iconOnLeft?: boolean;
  /**
   * Specifies the orientation of the icon.
   */
  iconOrientation?: 'up' | 'down' | 'left' | 'right';
  /**
   * Specifies whether or not the button is disabled.
   */
  disabled?: boolean;
  /**
   * Specifies the width of the button.
   */
  width?: TailwindWidth;
  /**
   * Specifies the title attribute of the button.
   */
  title?: string;
  /**
   * Specifies if this is a normal button or a form submit button.
   */
  type?: 'button' | 'submit';
  /**
   * Enables additional styling.
   */
  className?: string;
  /**
   * Specifies a click handler function for the button.
   */
  onClick?: () => void;
  /**
   * Specifies a URL that this button (semantically an anchor element) should go to.
   * The destination will open in a new tab. Not intended for intra-app navigation.
   */
  href?: string;
  /**
   * Specifies the data-cy attribute (used by Cypress testing) of the button element.
   */
  ['data-cy']?: string;
}

const themeStyles = {
  primary: primaryTheme,
  secondary: secondaryTheme,
  transparent: transparentTheme,
  pink: pinkTheme,
  pinkSecondary: pinkSecondaryTheme,
};

const sizeStyles = {
  small: 'px-3 py-1.5 rounded text-xs',
  medium: 'px-4 py-2 rounded-lg text-sm',
  large: 'px-6 py-3 rounded-xl text-sm',
};

const iconSizeStyles = {
  small: 'h-5 w-5',
  medium: 'h-6 w-6',
  large: 'h-7 w-7',
};

const paddingLeftIcon = {
  small: 'pl-10',
  medium: 'pl-12',
  large: 'pl-14',
};

const paddingRightIcon = {
  small: 'pr-10',
  medium: 'pr-12',
  large: 'pr-14',
};

const Button: FC<ButtonProps> = ({
  className,
  children,
  onClick,
  href,
  title,
  hasArrowIcon = false,
  iconOnLeft = false,
  iconOrientation = 'right',
  size = 'medium',
  type = 'button',
  variant = 'primary',
  width = 'auto',
  disabled = false,
  'data-cy': cypressTag,
}) => {
  const styles = themeStyles[variant];
  const buttonStyles = styles.button;
  const iconStyles = (disabled && styles.disabledIcon) || styles.icon;
  const paddingStyle = iconOnLeft ? paddingLeftIcon[size] : paddingRightIcon[size];
  const iconPositionStyle = iconOnLeft ? 'left-2' : 'right-2';
  const isEnabledLink = !!(href && !disabled);
  const Element = isEnabledLink ? 'a' : 'button';
  const elementSpecificProps = isEnabledLink
    ? {
        href,
        rel: 'noreferrer',
        target: '_blank',
      }
    : { disabled, onClick, type };
  return (
    <Element
      data-cy={cypressTag}
      title={title}
      className={classNames(
        {
          [paddingStyle]: hasArrowIcon,
          'cursor-default hover:shadow-none': disabled,
          [`w-${width}`]: true,
          [buttonStyles]: true,
          [sizeStyles[size]]: true,
        },
        'group',
        'relative',
        'font-sans',
        'whitespace-nowrap',
        'overflow-hidden',
        'overflow-ellipsis',
        'leading-normal',
        'tracking-normal',
        'normal-case',
        'no-underline',
        'text-center',
        'inline-block',
        'border-solid',
        'border-2',
        'box-border',
        'transition',
        'disabled:border-solid',
        'disabled:border-2',
        'disabled:box-border',
        className,
      )}
      {...elementSpecificProps}
    >
      {!iconOnLeft && children}
      {hasArrowIcon && (
        <div
          className={classNames(
            {
              [iconStyles]: !!iconStyles,
              [iconSizeStyles[size]]: true,
              [iconPositionStyle]: true,
            },
            'flex',
            'justify-center',
            'items-center',
            'fill-current',
            'rounded',
            'box-border',
            'absolute',
            'transform',
            'transition',
            'top-1/2',
            '-translate-y-1/2',
          )}
        >
          <Arrow orientation={iconOrientation} />
        </div>
      )}
      {iconOnLeft && children}
    </Element>
  );
};

export default Button;
