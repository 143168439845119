import React, { useState } from 'react';
import { Button, ModalWindow } from 'core-components';
import { PencilIcon, TrashIcon } from 'components/Icons';
import {
  OptionsMenu,
  Option,
  OptionText,
} from 'components/clubs/ClubOptions/ClubOptions';
import useClickOutside from 'hooks/outside';
import { EventEditor } from 'components/clubs';
import { JuniCommunityEventType } from 'generated/graphql';
import EventDeleteModal from '../EventDeleteModal';

interface EventCardOptions {
  instructorUserId?: string;
  juniClubId?: string;
  juniClubCoverUrl?: string;
  juniCommunityEvent?: JuniCommunityEventType;
}

const EventCardOptions: React.FC<EventCardOptions> = ({
  instructorUserId,
  juniClubId,
  juniClubCoverUrl,
  juniCommunityEvent,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useClickOutside(menuRef, () => {
    setMenuOpen(false);
  });

  // TODO: add edit and delete event onClick handler
  return (
    <>
      <ModalWindow
        title="Edit Event"
        isOpen={editOpen}
        closeModal={() => setEditOpen(false)}
      >
        <EventEditor
          onClose={() => setEditOpen(false)}
          instructorUserId={instructorUserId}
          juniClubId={juniClubId}
          juniClubCoverUrl={juniClubCoverUrl}
          juniCommunityEvent={juniCommunityEvent}
        />
      </ModalWindow>
      <EventDeleteModal
        onClose={() => setDeleteOpen(false)}
        modalOpen={deleteOpen}
        eventId={juniCommunityEvent?._id}
        eventName={juniCommunityEvent?.title}
        instructorUserId={instructorUserId}
      />
      <div className="relative" ref={menuRef}>
        <Button variant="transparent" size="small" onClick={() => setMenuOpen(true)}>
          ...
        </Button>
        <OptionsMenu visible={menuOpen}>
          <Option
            onClick={() => {
              setMenuOpen(false);
              setEditOpen(true);
            }}
          >
            <PencilIcon style={{ fill: '#ad3f3f', padding: 0 }} />
            <OptionText>Edit Event</OptionText>
          </Option>
          <Option
            onClick={() => {
              setMenuOpen(false);
              setDeleteOpen(true);
            }}
          >
            <TrashIcon style={{ fill: '#ad3f3f', padding: 0 }} />
            <OptionText>Delete Event</OptionText>
          </Option>
        </OptionsMenu>
      </div>
    </>
  );
};

export default EventCardOptions;
