import React from 'react';
import classNames from 'classnames';
import { JuniUserClub, StudentProjectCreatorType } from 'generated/graphql';
import { Badge, Icon, Tooltip } from 'core-components';
import { Link } from 'react-router-dom';
import { getClubLink } from 'app/clubs/helpers';

const maybeGetBadgeLabel = (
  membershipStatus: JuniUserClub['membershipStatus'],
  userType?: StudentProjectCreatorType,
) => {
  if (
    userType === StudentProjectCreatorType.Student &&
    membershipStatus === 'moderator'
  ) {
    return 'leader';
  }
  if (
    userType === StudentProjectCreatorType.Instructor &&
    membershipStatus === 'moderator'
  ) {
    return 'advisor';
  }

  return undefined;
};

const Linkable: React.FC<{ link?: string }> = ({ link, children }) => {
  if (link) {
    return (
      <Link to={link} className="no-underline">
        {children}
      </Link>
    );
  }

  return <>{children}</>;
};

interface ClubProps {
  club: JuniUserClub;
  /**
   * Only undefined if the user is not a Student or Instructor (currently this is only Guest).
   */
  userType?: StudentProjectCreatorType;
  /**
   * If available.
   */
  activeStudentId?: string;
}

const Club: React.FC<ClubProps> = ({ club, userType, activeStudentId }) => {
  const {
    _id,
    displayName,
    memberCount,
    membershipStatus,
    description,
    coverPhoto,
  } = club;
  const badgeLabel = maybeGetBadgeLabel(membershipStatus, userType);
  const clubLink = activeStudentId
    ? getClubLink({ studentId: activeStudentId, juniClubId: _id })
    : undefined;

  return (
    <div className="flex justify-between align-top font-graphik">
      <div className="flex items-start">
        <div
          className={classNames(
            'flex justify-center items-center h-12 w-12 box-border rounded-lg',
            {
              'bg-j-purple-200 text-j-purple-600 border border-solid border-j-purple-400': !coverPhoto,
            },
          )}
          style={{ minWidth: '3rem' }} // avoids getting collapsed on
        >
          <Linkable link={clubLink}>
            {coverPhoto ? (
              <img
                src={coverPhoto.replace('_full', '_thumbnail')}
                alt={`${displayName} thumbnail`}
                className="w-full h-full rounded-lg"
              />
            ) : (
              <Icon.Trophy className="w-auto h-4" />
            )}
          </Linkable>
        </div>
        <div className="ml-3">
          <Tooltip placement="bottom" title={description} variant="minimal">
            <Linkable link={clubLink}>
              <div className="text-base text-j-dark-600 font-medium">
                {displayName}
              </div>
            </Linkable>
          </Tooltip>
          <div className="mt-1 text-xs leading-5 text-j-dark-400">
            {memberCount} member{memberCount !== 1 ? 's' : ''}
          </div>
        </div>
      </div>
      {!!badgeLabel && (
        <div className="ml-2 whitespace-nowrap">
          <Badge backgroundColor="j-blue-200" color="j-blue-600">
            {badgeLabel}
          </Badge>
        </div>
      )}
    </div>
  );
};

export default Club;
