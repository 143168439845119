import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const PortalSidebar = styled.div.attrs({ className: 'bg-juni-dark-900' })`
  width: 260px;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5.55665px rgba(46, 74, 90, 0.25);
`;

const OptionWrapper = css`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const WelcomeText = styled.div`
  color: white;
  font-size: 0.9rem;
  padding: 0 20px;
`;

const DesktopLink = styled(Link)<any>`
  ${OptionWrapper}
  background: ${props =>
    props.$active && 'linear-gradient(180deg, #EB7480 0%, #E75564 100%)'};
  text-decoration: none;
  box-sizing: border-box;
  justify-content: space-between;
`;

const DesktopButton = styled.div<any>`
  ${OptionWrapper}
  background: ${props =>
    props.active && 'linear-gradient(180deg, #EB7480 0%, #E75564 100%)'};
  text-decoration: none;
  display: flex;
  justify-content: space-between; 
  cursor: pointer;
`;

const LinkText = styled(WelcomeText)<any>`
  font-weight: ${props => (props.active ? '800' : '600')};
  font-family: Graphik;
`;

const WelcomeWrapper = styled.div`
  ${OptionWrapper}
  text-align: right;
`;

export {
  PortalSidebar,
  WelcomeText,
  WelcomeWrapper,
  LinkText,
  DesktopLink,
  DesktopButton,
};
