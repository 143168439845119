import React, { FC, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { subWeeks, addWeeks, parseISO, formatISO } from 'date-fns';
import { AcuityAppointment, AdditionalClass } from 'models';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import {
  isTimeTurnerStageEnabled,
  TimeTurnerStage,
} from 'app/feature_flags/time-turner-deploy-stage-flags';

import AnimateHeight from 'react-animate-height';
import Button from 'core-components/NewButton/Button';
import { Message } from 'core-components';
import {
  LearnerClassSchedulerWidgetV2,
  LearnerClassSchedulerWidget,
} from 'app/learner/class-scheduling-widgets/LearnerClassSchedulerWidget';
import LearnerClassReschedulerWidget from 'app/learner/class-scheduling-widgets/LearnerClassReschedulerWidget';
import ScheduleCardRow from './ScheduleCardRow';
import CalendlyWidget from './CalendlyWidget';

import { isAdditionalClass, ScheduleCardProps } from './types';

const DEFAULT_NUM_ROWS = 3;
const CALENDLY_PARENT_ADVISOR_URL = 'https://calendly.com/juniaccounts/juni-meeting';

const identifyRecurringSessions = (sessions: AcuityAppointment[]) => {
  const sessionsByDatetime: Record<string, AcuityAppointment[]> = _.groupBy(
    sessions,
    session => formatISO(parseISO(session.datetime)),
  );

  // session is recurring if there is another session one week earlier or later
  return _.keyBy(
    sessions
      .filter(
        session =>
          sessionsByDatetime[formatISO(subWeeks(parseISO(session.datetime), 1))] ||
          sessionsByDatetime[formatISO(addWeeks(parseISO(session.datetime), 1))],
      )
      .flat(),
    s => s.id,
  );
};

interface AppointmentWithCalendar extends AcuityAppointment {
  calendar: string;
}

const getFirstNameAndLastInitial = (name: string) => {
  const parts = name.split(' ');
  if (parts.length < 2) return name;
  return `${parts[0]} ${parts[parts.length - 1].slice(0, 1)}.`;
};

const getZeroStateMsg = (classType: string) => (
  <Message status="info">{`No ${classType.replace(
    '_',
    ' ',
  )} classes available`}</Message>
);

const ScheduleCard: FC<ScheduleCardProps> = ({
  type,
  student,
  parent,
  classes,
  upcomingSessions,
  refreshUpcomingSessions,
  refreshAdditionalClasses,
  instructorNameLookup,
}) => {
  let scheduleCardRowHtml: any[] = [];
  const [showClassSchedulerModal, setShowClassSchedulerModal] = useState<boolean>(
    false,
  );
  const [
    showParentAdvisorCalendlyModal,
    setShowParentAdvisorCalendlyModal,
  ] = useState<boolean>(false);
  const [calendlyParentAdvisorUrl, setCalendlyParentAdvisorUrl] = useState<string>(
    CALENDLY_PARENT_ADVISOR_URL,
  );

  const [selectedClass, setSelectedClass] = useState<
    AcuityAppointment | AdditionalClass | null
  >(null);
  const [isSelectedClassRecurring, setIsSelectedClassRecurring] = useState(false);

  const [viewAll, setViewAll] = useState<boolean>(false);

  const recurringSessions = identifyRecurringSessions(
    type === 'upcoming' ? (classes as AcuityAppointment[]) : [],
  );

  const toggleViewAll = () => {
    setViewAll(!viewAll);
  };

  const openSchedulingWidget = (classId: string | number) => {
    setShowClassSchedulerModal(true);
    const selectedClass = classes.find(c =>
      isAdditionalClass(c) ? c._id === classId : c.id === classId,
    );
    if (selectedClass) {
      if (type === 'upcoming') {
        setIsSelectedClassRecurring(
          !!recurringSessions[(selectedClass as AcuityAppointment).id],
        );
      }
      setSelectedClass(selectedClass);
    }
  };

  const handleClickCalendlyDialog = () => {
    JuniAnalytics.track('schedule_parent_advisor_checkin_clicked');
    if (parent) {
      // Phone number is either "" or an 11 digit.
      let phoneNumber = String(parent.phone || '');
      phoneNumber = phoneNumber.length === 10 ? `1${phoneNumber}` : phoneNumber;

      const params = [
        `name=${parent.firstName} ${parent.lastName}`,
        `email=${parent.email}`,
        `a1=${phoneNumber}`,
        `a2=1`,
      ];
      setCalendlyParentAdvisorUrl(
        `${CALENDLY_PARENT_ADVISOR_URL}?${params.join('&')}`,
      );
    }
    setShowParentAdvisorCalendlyModal(true);
  };

  const closeClassSchedulerModal = useCallback(() => {
    setShowClassSchedulerModal(false);
    setSelectedClass(null);
    setIsSelectedClassRecurring(false);
    if (refreshAdditionalClasses) {
      refreshAdditionalClasses();
    }
    if (refreshUpcomingSessions) {
      refreshUpcomingSessions();
    }
  }, [refreshUpcomingSessions, refreshAdditionalClasses]);

  useEffect(() => {
    closeClassSchedulerModal();
  }, [closeClassSchedulerModal, student._id]);

  switch (type) {
    case 'upcoming':
      scheduleCardRowHtml = classes.map(session => (
        <ScheduleCardRow
          key={`${type}-${(session as AcuityAppointment).id}`}
          session={session}
          type={type}
          student={student}
          parent={parent}
          instructorName={
            instructorNameLookup?.[`${(session as AcuityAppointment).calendarID}`]
          }
          onClickSchedule={openSchedulingWidget}
        />
      ));
      break;
    case 'makeup':
      scheduleCardRowHtml = classes
        .map(session => {
          if (isAdditionalClass(session) && session.appointmentTypeID) {
            return (
              <ScheduleCardRow
                key={`${type}-${session._id}`}
                session={session}
                type={type}
                instructorName={
                  instructorNameLookup?.[session.originalClassAcuityInstructorId]
                }
                onClickSchedule={openSchedulingWidget}
              />
            );
          }
          return null;
        })
        .filter(v => v);
      break;
    case 'class_pack':
      // eslint-disable-next-line no-case-declarations
      const classPackClassesByAcuityApptType = classes.reduce<
        Record<number, AdditionalClass[]>
      >((agg, cur) => {
        if (isAdditionalClass(cur)) {
          const { appointmentTypeID } = cur;
          if (!appointmentTypeID) return agg;
          // eslint-disable-next-line no-prototype-builtins
          return agg && agg.hasOwnProperty(appointmentTypeID.toString())
            ? {
                ...agg,
                [appointmentTypeID]: [...agg[appointmentTypeID], cur],
              }
            : { ...agg, [appointmentTypeID]: [cur] };
        }
        return agg;
      }, {});
      scheduleCardRowHtml = Object.keys(
        classPackClassesByAcuityApptType,
      ).map(appointmentTypeID => (
        <ScheduleCardRow
          key={`${appointmentTypeID}`}
          session={classPackClassesByAcuityApptType[parseInt(appointmentTypeID, 10)]}
          type={type}
          onClickSchedule={openSchedulingWidget}
        />
      ));
      break;
    case 'advisor_checkin':
      scheduleCardRowHtml.push(
        <ScheduleCardRow
          key="advisor_checkin"
          type={type}
          onClickSchedule={handleClickCalendlyDialog}
        />,
      );
      break;
    default:
      break;
  }

  const { firstName: studentFirstName, _id: studentId } = student;

  const hasMoreClassesToShow = scheduleCardRowHtml.length > DEFAULT_NUM_ROWS;

  return (
    <div>
      {selectedClass &&
        isAdditionalClass(selectedClass) &&
        (isTimeTurnerStageEnabled(TimeTurnerStage.RespectAvailability) ? (
          <LearnerClassSchedulerWidgetV2
            appointmentTypeID={selectedClass.appointmentTypeID}
            additionalClassId={selectedClass._id}
            timezone={selectedClass.originalClassTimezone}
            instructorAcuityId={parseInt(
              selectedClass.originalClassAcuityInstructorId,
              10,
            )}
            isMakeupClass={selectedClass.isMakeupClass}
            studentFirstName={studentFirstName}
            studentId={studentId}
            onFinish={closeClassSchedulerModal}
            parent={parent}
            refreshUpcomingSessions={refreshUpcomingSessions!}
            refreshAdditionalClasses={refreshAdditionalClasses!}
            isOpen={showClassSchedulerModal}
            closeModal={closeClassSchedulerModal}
            upcomingClasses={upcomingSessions}
          />
        ) : (
          <LearnerClassSchedulerWidget
            appointmentTypeID={selectedClass.appointmentTypeID}
            additionalClassId={selectedClass._id}
            timezone={selectedClass.originalClassTimezone}
            instructorAcuityId={parseInt(
              selectedClass.originalClassAcuityInstructorId,
              10,
            )}
            isMakeupClass={selectedClass.isMakeupClass}
            studentFirstName={studentFirstName}
            studentId={studentId}
            onFinish={closeClassSchedulerModal}
            parent={parent}
            refreshUpcomingSessions={refreshUpcomingSessions!}
            refreshAdditionalClasses={refreshAdditionalClasses!}
            isOpen={showClassSchedulerModal}
            closeModal={closeClassSchedulerModal}
          />
        ))}
      {isTimeTurnerStageEnabled(TimeTurnerStage.RespectAvailability) &&
        selectedClass &&
        !isAdditionalClass(selectedClass) && (
          <LearnerClassReschedulerWidget
            acuityAppointmentId={selectedClass.id}
            appointmentTypeID={selectedClass.appointmentTypeID}
            timezone={selectedClass.timezone}
            instructorAcuityId={selectedClass.calendarID}
            instructorName={
              instructorNameLookup?.[selectedClass.calendarID] ||
              getFirstNameAndLastInitial(
                (selectedClass as AppointmentWithCalendar).calendar,
              )
            }
            appointmentDatetime={selectedClass.datetime}
            studentFirstName={studentFirstName}
            studentId={studentId}
            onFinish={closeClassSchedulerModal}
            parent={parent}
            refreshUpcomingSessions={refreshUpcomingSessions!}
            isRecurring={isSelectedClassRecurring}
            isOpen={showClassSchedulerModal}
            closeModal={closeClassSchedulerModal}
            upcomingClasses={
              upcomingSessions &&
              upcomingSessions.filter(session => session.id !== selectedClass.id)
            }
          />
        )}
      {classes.length > 0 || type === 'advisor_checkin' ? (
        <>
          <CalendlyWidget
            title="Parent Advisor Check-in"
            calendlyUrl={calendlyParentAdvisorUrl}
            isOpen={showParentAdvisorCalendlyModal}
            closeModal={() => {
              setShowParentAdvisorCalendlyModal(false);
            }}
            contentHeight="80vh"
          />
          {scheduleCardRowHtml.slice(0, DEFAULT_NUM_ROWS)}
          {hasMoreClassesToShow && (
            <AnimateHeight
              className="drop-down-classes"
              duration={500}
              height={viewAll ? 'auto' : 0}
            >
              {scheduleCardRowHtml.slice(DEFAULT_NUM_ROWS)}
            </AnimateHeight>
          )}
          {hasMoreClassesToShow && (
            <div className={viewAll ? 'mt-4' : ''}>
              <Button fullWidth variant="secondary" onClick={toggleViewAll}>{`View ${
                viewAll ? 'Less' : 'All'
              }`}</Button>
            </div>
          )}
        </>
      ) : (
        getZeroStateMsg(type)
      )}
    </div>
  );
};

export default ScheduleCard;
