import moment from 'moment';

const humanizeTimeAgo = (timestamp?: string) => {
  if (!timestamp) return null;
  const duration = moment.duration(moment.utc().diff(moment.utc(timestamp)));
  const seconds = Math.max(Math.floor(duration.asSeconds()), 0);
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  }
  return `${duration.humanize()} ago`;
};

export default humanizeTimeAgo;
