import styled from 'styled-components/macro';

// NOTE: This component contains legacy styles that modify internal classes associated with our InputField and Form Group components.
// This is generally not a pattern we suggest using when writing new code; it's only utilized here to preserve existing styles.

const FormWrapper = styled.div`
  .ui-form-group:first-child {
    margin-top: 0;
  }

  .ui-field-error {
    color: #a73434;
    font-size: 1em;
  }

  .ui-field-error + input {
    border: 2px solid #da0f0f;
    background: #ffe9e8;
    color: #da0f0f;
  }

  .ui-title {
    color: #335875;
    font-size: 1.125rem;
  }

  .submit {
    display: flex;
    flex-direction: row-reverse;
  }

  button {
    justify-self: end;
    padding: 0.5rem 3rem;
    font-size: 0.9rem;
    :focus {
      background-color: hsl(201, 100%, 60%);
      border: 2px solid rgba(255, 255, 255, 0.75);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    }
  }

  .success input {
    border: 2px solid hsl(157, 55%, 69%);
    background: hsl(157, 100%, 95%);
    color: hsl(157, 23%, 29%);
  }

  .success button {
    border: 2px solid hsl(157, 55%, 69%);
    background: hsl(157, 55%, 69%);
  }

  .spinner-v2 {
    margin: 0 1rem;
  }
`;

export { FormWrapper };
