import classNames from 'classnames';
import React from 'react';
import { TailwindSpace } from 'theme/types';
import { StyledContent } from './styles';

interface ActivityInstructionsProps {
  /**
   * The raw html to set.
   */
  instructions: string;
  /**
   * The horizontal padding space to apply.
   * Default 6.
   */
  horizontalPadding?: TailwindSpace;
  /**
   * Optional top margin space to apply.
   * Default 0.
   */
  marginTop?: TailwindSpace;
  /**
   * Give the content full height 100%.
   * Default true.
   */
  fullHeight?: boolean;
  /**
   * Indicate project difficulty level.
   * Specific to supplementary coding projects.
   */
  difficultyLevel?: string;
}

const ActivityInstructions: React.FC<ActivityInstructionsProps> = ({
  instructions,
  horizontalPadding = '6',
  marginTop,
  fullHeight = true,
  difficultyLevel,
}) => {
  const instructionsToShow = difficultyLevel
    ? `
    <div
      class='mt-4 bg-j-blue-100 border border-solid border-j-blue-600 rounded-md w-max py-1 px-2 text-xs text-j-blue-600 uppercase font-medium'
    >
      ${difficultyLevel}
    </div> ${instructions}`
    : instructions;
  return (
    <StyledContent
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: instructionsToShow }}
      className={classNames(
        `px-${horizontalPadding} font-graphik text-base text-j-dark-600 overflow-y-auto`,
        {
          'h-full': fullHeight,
          [`mt-${marginTop}`]: marginTop !== undefined,
        },
      )}
    />
  );
};

export default ActivityInstructions;
