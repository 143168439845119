import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Select, { components } from 'react-select';

import { ClubTagOptionsType } from 'constants/clubs';
import { TagIcon } from 'components/Icons';

export interface MyClubsTagSelectorProps {
  selectedTag?: string | null;
  handleTagSelect: (tag: string) => void;
  clubTags: ClubTagOptionsType[];
}
const OPTION_SELECTED_COLOR = '#37B9FF';
const OPTION_HOVER_COLOR = '#E9F7FF';

const MyClubsTagSelector: FC<MyClubsTagSelectorProps> = ({
  selectedTag,
  handleTagSelect,
  clubTags,
}) => {
  const getSelectedTagDetails = (selectedName?: string | null) => {
    const foundTag = clubTags.find(tag => tag.value === selectedName);
    if (foundTag) return foundTag;
    return null;
  };
  const selectedTagInfo = getSelectedTagDetails(selectedTag);
  // TODO : update w/ proper type
  const handleTagChange = (selected: any) => {
    handleTagSelect(selected?.value);
  };

  const OptionTagWrapper = styled.div<any>`
    display: flex;
    align-items: center;
    color: ${props => (props.color && !props.isSelected ? props.color : '#fff')};

    svg {
      margin-right: 0.5rem;
    }

    > svg path {
      fill: ${props => (props.color && !props.isSelected ? props.color : '#fff')};
    }
  `;

  const OptionTagText = styled.div.attrs(({ className }) => ({
    className,
  }))<any>`
    width: 90%;
  `;

  const { Option, SingleValue } = components;
  const OptionWithIcon = (props: any) => (
    <Option {...props}>
      <OptionTagWrapper
        color={props.data.color}
        isSelected={props.data.value === selectedTag}
      >
        <TagIcon />
        <OptionTagText>{props.data.label}</OptionTagText>
      </OptionTagWrapper>
    </Option>
  );

  const SingleValueInputWithIcon = (props: any) => (
    <SingleValue {...props}>
      <OptionTagWrapper color={getSelectedTagDetails(selectedTag)?.color}>
        <TagIcon />
        <OptionTagText className="overflow-hidden whitespace-nowrap overflow-ellipsis">
          {props.data.label}
        </OptionTagText>
      </OptionTagWrapper>
    </SingleValue>
  );

  const selectCustomStyles = {
    control: (styles: any) => ({
      ...styles,
      width: '200px',
      height: '40px',
      boxShadow: 'none',
      borderRadius: '1rem',
      border: '2px solid #d1eaf7',
      cursor: 'pointer',
      backgroundColor: '#fbfeff',
    }),
    option: (styles: any, { data }: any) => ({
      ...styles,
      color: data.color,
      textTransform: 'uppercase',
      padding: '.75rem',
      fontWeight: 600,
      fontSize: '14px',
      backgroundColor: data.value === selectedTag ? OPTION_SELECTED_COLOR : '#fff',
      '&:hover': {
        backgroundColor:
          data.value === selectedTag ? OPTION_SELECTED_COLOR : OPTION_HOVER_COLOR,
      },
      '&:last-child': {
        borderBottom: '0',
      },
      cursor: 'pointer',
      borderBottom: '2px solid rgb(206, 230, 243)',
    }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      textTransform: 'uppercase',
      color: data.color,
      fontWeight: 600,
      fontSize: '14px',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      fontSize: '14px',
      color: '#a1becd',
      fontWeight: 600,
    }),
  };

  return (
    <div>
      <Select
        styles={selectCustomStyles}
        components={{
          Option: OptionWithIcon,
          SingleValue: SingleValueInputWithIcon,
        }}
        value={selectedTagInfo}
        options={clubTags}
        isClearable
        onChange={handleTagChange}
        placeholder="Find by tag..."
      />
    </div>
  );
};

export default MyClubsTagSelector;
