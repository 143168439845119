import { useCallback, useContext } from 'react';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ORDINALS, NAV_VERSIONS } from 'app/signup_session/navigation/constants';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import { getCourseFormatFromBundleName } from 'app/signup_session/lib/getCourseFormatFromBundleName';
import { Context, RouteEvent, ROUTE_EVENT } from '../../navigation/types';
import { routes } from '../../navigation/routes';
import NavStateContext from './NavStateContext';

const useNavRouter = (): {
  getNextPage: (currentPath: string, event: RouteEvent, context: Context) => string;
  hasNextPage: (currentPath: string, event: RouteEvent, context: Context) => boolean;
  routerType: string;
  multiSubjectSignup: boolean;
} => {
  const { router, multiSubjectSignup } = useContext(NavStateContext);

  if (!router) {
    throw new Error('router context must be provided for useNavRouter');
  }

  const hasNextPage = useCallback(
    (currentPath: string, event: RouteEvent, context: Context): boolean => {
      const nextState = router.transition(currentPath, event, context);
      const stateValue = nextState?.value as string;
      const getNextRoute = routes[stateValue];
      // if the redirect path matches the current path, no need to redirect
      if (
        context.locationPath &&
        getNextRoute(context) === `${context.locationPath}${context.search || ''}`
      ) {
        return false;
      }
      return nextState?.changed || false;
    },
    [router],
  );

  const getNextPage = useCallback(
    (currentPath: string, event: RouteEvent, context: Context): string => {
      const nextState = router.transition(currentPath, event, context);
      const stateValue = nextState?.value as string;
      const getNextRoute = routes[stateValue];

      const activeStudent = context?.signupData?.students?.[0];

      JuniAnalytics.track(stateValue.replaceAll('-', '_'), {
        funnelName: 'dcf',
        stepName: stateValue.replaceAll('-', '_'),
        ordinal: ORDINALS[router.id][stateValue],
        funnelVersion: NAV_VERSIONS[router.id],
        routerEventType: event.type, // 'SUBMIT', 'LOAD', 'EDIT', or 'BACK'
        isMultiSubjectSignup: multiSubjectSignup,
        courseNames: getStudentBundleSelections(activeStudent?.bundle)
          .map(selection => selection?.courseName)
          .filter(courseName => courseName),
        courseFormat: getCourseFormatFromBundleName(
          activeStudent?.bundle?.bundleName,
        ),
      });

      if ([ROUTE_EVENT.BACK, ROUTE_EVENT.SUBMIT].includes(event)) {
        window.scrollTo({ top: 0 });
      }

      return getNextRoute(context);
    },
    [multiSubjectSignup, router],
  );

  return { getNextPage, hasNextPage, routerType: router.id, multiSubjectSignup };
};

export default useNavRouter;
