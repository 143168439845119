import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SUBJECTS } from 'constants/subjects';
import { getParentsPastSessions } from 'services/learner/sessions';
import { getCompletedTeacherSessions } from 'services/learner/teacher_sessions';
import SessionNotes from 'components/SessionNotes';
import JuniSpinner from 'components/JuniSpinner';

interface SessionNotesHomeProps extends RouteComponentProps {
  userId: string;
  students: any[];
  parent: any;
  idLookup: any;
  moduleSectionProgresses?: any[];
  isLoadingCourseData: boolean;
}

const SessionNotesHome: React.FC<SessionNotesHomeProps> = ({
  history,
  location,
  match,
  userId,
  students,
  parent,
  idLookup,
  moduleSectionProgresses,
  isLoadingCourseData,
}) => {
  const [pastSessions, setPastSessions] = useState([]);
  const [isLoadingPastSessions, setIsLoadingPastSessions] = useState(false);
  const [completedTeacherSessions, setCompletedTeacherSessions] = useState([]);
  const [isLoadingTeacherSessions, setIsLoadingTeacherSessions] = useState(false);

  const isLoadingCourseDataOrSessions =
    isLoadingCourseData || isLoadingPastSessions || isLoadingTeacherSessions;

  // Get current student details
  const currentStudent = students[0];
  const {
    firstName = '',
    tracks = [],
    completedTracks = [],
    track = '',
  } = currentStudent;

  // Create nestedStructureCourses prop for SessionNotes
  const curStudentActiveCourseNames = [
    track,
    ...tracks.filter((t: any) => t !== currentStudent.track),
  ].filter(name => name);
  const curStudentCompletedCourseNames = completedTracks.filter((name: any) => name);
  const curStudentCompletedCourses = curStudentCompletedCourseNames.map(
    (name: string | number) => idLookup[name],
  );
  const curStudentActiveCourses = curStudentActiveCourseNames.map(
    name => idLookup[name],
  );
  const curStudentCourses = [
    ...curStudentActiveCourses,
    ...curStudentCompletedCourses,
  ];
  const nestedStructureCourses = curStudentCourses.filter(
    c => c?.subject !== SUBJECTS.COMPUTER_SCIENCE,
  );

  // Load Parent Sessions in order to pass down as SessionNotes prop
  const doGetParentsPastSessions = useCallback(async () => {
    setIsLoadingPastSessions(true);
    try {
      const sessions = await getParentsPastSessions(parent.email);
      setPastSessions(sessions);
    } catch (err) {
      console.error(err);
    }
    setIsLoadingPastSessions(false);
  }, [parent.email]);
  useEffect(() => {
    doGetParentsPastSessions();
  }, [doGetParentsPastSessions]);

  // Load Teacher Sessions in order to pass down as SessionNotes prop
  const doGetCompletedTeacherSessions = useCallback(async () => {
    setIsLoadingTeacherSessions(true);
    try {
      const sessions = await getCompletedTeacherSessions(currentStudent._id);
      setCompletedTeacherSessions(sessions);
    } catch (err) {
      console.error(err);
    }
    setIsLoadingTeacherSessions(false);
  }, [currentStudent._id]);
  useEffect(() => {
    doGetCompletedTeacherSessions();
  }, [doGetCompletedTeacherSessions]);

  return (
    <div className="flex flex-col items-center p-8">
      <div className="flex flex-col max-w-7xl w-full">
        <div className="text-3xl font-graphik font-medium text-j-dark-600">
          Session Notes
        </div>
        {isLoadingCourseDataOrSessions || !moduleSectionProgresses ? (
          <JuniSpinner size={60} />
        ) : (
          <SessionNotes
            history={history}
            location={location}
            match={match}
            userId={userId}
            isLoadingCourseData={isLoadingCourseDataOrSessions}
            studentFirstName={firstName}
            sessions={pastSessions}
            teacherSessions={completedTeacherSessions}
            getTeacherSessions={doGetCompletedTeacherSessions}
            moduleSectionProgresses={moduleSectionProgresses}
            idLookup={idLookup}
            showByDefault={!currentStudent.isJuniExplorer}
            nestedStructureCourses={nestedStructureCourses}
            student={currentStudent}
            hideHeader
          />
        )}
      </div>
    </div>
  );
};

export default SessionNotesHome;
