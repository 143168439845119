import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Prompt } from 'react-router-dom';

import { deleteUsermedia, updateUsermedia } from 'services/usermedia';

import JRSIntro from './modals/intro';
import JRSDisplayMediaRequest from './modals/displayMediaRequest';
import JRSSaveRec from './modals/saveRec';
import JRSUploadProgress from './modals/uploadProgress';
import JRSUserMediaRequest from './modals/userMediaRequest';
import { JRSViewRecordings } from './modals/viewRecordings';
import JRSBar from './bar';
import useStreamBuilder from './hooks/streamBuilder';
import useRecorderUploader from './hooks/recorderUploader';

import './styles/jide_rec_studio.css';

const UNLOAD_MESSAGE =
  'You are currently recording or uploading! If you navigate away your recording may be lost.';

// Main component for the Recording Studio that instantiates custom hook logic,
// subcomponents, and the logic that ties them together
const JideRecStudio = ({
  jideUser,
  idLookup,
  activeNav,
  setRecordingMode,
  onTabStartedRecording,
  onTabStoppedRecording,
  setHideNavBar,
}) => {
  // Refs
  const userVideoRef = useRef(null);

  // States
  const [mode, setMode] = useState('intro');
  const [isExpanded, setIsExpanded] = useState(false);
  const [saveRecVideoUrl, setSaveRecVideoUrl] = useState(null);

  // Utility functions
  const closeStream = useCallback(stream => {
    if (!stream) return null;
    stream.getTracks().forEach(t => {
      t.stop();
      stream.removeTrack(t);
    });
    return null;
  }, []);

  // Custom Hooks
  // Including state vars and useful methods that can be read or invoked from
  // within modal elements
  const {
    mics,
    cameras,
    activeMic,
    activeCamera,
    isUsableMic,
    isUsableCamera,
    noVideoDeviceId,
    combinedStreamRef,
    combinedStreamFlag,
    setActiveMic,
    setActiveCamera,
    refreshDevices,
    buildDisplayStream,
    cleanupStreamBuilder,
  } = useStreamBuilder({ closeStream, userVideoRef });

  const {
    recorderUploaderInitialized,
    isRecording,
    prevIsRecording,
    overallUploadProgress,
    recordingLength,
    startRecording,
    stopRecording,
    discardVideo,
    saveVideo,
    getProjectVideos,
  } = useRecorderUploader({
    jideUser,
    idLookup,
    activeNav,
    combinedStreamRef,
    combinedStreamFlag,
    setSaveRecVideoUrl,
    closeStream,
  });

  const preventUnloadIfRecording = useCallback(
    e => {
      if (
        isRecording ||
        (overallUploadProgress > 0 && overallUploadProgress < 1.0)
      ) {
        e.preventDefault();
        // Note: many browsers won't display this message.
        e.returnValue = UNLOAD_MESSAGE;
      } else {
        delete e.returnValue;
      }
    },
    [isRecording, overallUploadProgress],
  );

  // Initialization
  // Change the page title to all caps when in RecStudio to
  // make it easy to differentiate during getDisplayMedia prompt
  const onMount = () => {
    document.title = 'JUNI RECORDING STUDIO';
  };
  // Depending on what mode we're in, a different modal will be active
  const modeLookup = {
    intro: JRSIntro,
    userMediaRequest: JRSUserMediaRequest,
    displayMediaRequest: JRSDisplayMediaRequest,
    viewRecordings: JRSViewRecordings,
    saveRec: JRSSaveRec,
    uploadProgress: JRSUploadProgress,
    record: null,
  };
  const onModeSwitch = () => {
    if (mode === 'close') {
      // if close mode is triggered, close recording studio altogether
      return setRecordingMode(false);
    }
    // show recording bar when in record mode, otherwise hide
    if (mode === 'record') {
      setHideNavBar(true);
      return setIsExpanded(true);
    }
    setHideNavBar(false);
    return setIsExpanded(false);
  };
  const onUnmount = useCallback(
    () => () => {
      setHideNavBar(false);
      document.title = 'Juni Learning';
    },
    [setHideNavBar],
  );

  const onRecordingChange = () => {
    if (!prevIsRecording && isRecording) {
      // if recording started, trigger JIDE callback
      onTabStartedRecording();
    } else if (prevIsRecording && !isRecording) {
      // if recording ended, trigger JIDE callback
      onTabStoppedRecording();
    }

    // If recording stopped, show "saveRec" modal
    if (prevIsRecording && !isRecording) {
      return setMode('saveRec');
    }

    // If CombinedStream is not available in record mode, go back
    // to intro modal in setup flow (e.g. if user manually revokes access)
    if (!isRecording && mode === 'record' && !combinedStreamRef.current) {
      return setMode('intro');
    }
  };

  // Effects
  useEffect(onMount, []);
  useEffect(onModeSwitch, [mode, setHideNavBar, setRecordingMode]);
  useEffect(onRecordingChange, [
    combinedStreamFlag,
    combinedStreamRef,
    prevIsRecording,
    isRecording,
    mode,
    onTabStartedRecording,
    onTabStoppedRecording,
  ]);
  useEffect(onUnmount, [onUnmount]);
  useEffect(() => {
    window.addEventListener('beforeunload', preventUnloadIfRecording);

    return () =>
      window.removeEventListener('beforeunload', preventUnloadIfRecording);
  }, [preventUnloadIfRecording]);

  // Render
  const JRSModal = modeLookup[mode];
  return (
    <div className="jrs">
      <Prompt
        when={
          isRecording || (overallUploadProgress > 0 && overallUploadProgress < 1.0)
        }
        message={UNLOAD_MESSAGE}
      />
      {JRSModal ? (
        <div className="jrs-modal-container">
          <JRSModal
            jideUser={jideUser}
            setMode={setMode}
            refreshDevices={refreshDevices}
            buildDisplayStream={buildDisplayStream}
            setIsExpanded={setIsExpanded}
            saveRecVideoUrl={saveRecVideoUrl}
            overallUploadProgress={overallUploadProgress}
            discardVideo={discardVideo}
            deleteVideo={deleteUsermedia}
            saveVideo={saveVideo}
            updateVideo={updateUsermedia}
            getProjectVideos={getProjectVideos}
            cleanupStreamBuilder={cleanupStreamBuilder}
            recorderUploaderInitialized={recorderUploaderInitialized}
            mics={mics}
            cameras={cameras}
            resetActiveDevices={() => {
              setActiveMic(null);
              setActiveCamera(null);
            }}
          />
        </div>
      ) : null}
      <JRSBar
        isExpanded={isExpanded}
        isRecording={isRecording}
        userVideoRef={userVideoRef}
        mics={mics}
        cameras={cameras}
        activeMic={activeMic}
        activeCamera={activeCamera}
        isUsableMic={isUsableMic}
        isUsableCamera={isUsableCamera}
        noVideoDeviceId={noVideoDeviceId}
        recordingLength={recordingLength}
        setActiveMic={setActiveMic}
        setActiveCamera={setActiveCamera}
        startRecording={startRecording}
        stopRecording={stopRecording}
        setMode={setMode}
      />
    </div>
  );
};

export default JideRecStudio;
