import React from 'react';
import styled from 'styled-components/macro';

import noActivityLogo from 'images/no-activity-logo.png';
import ActionButton from 'components/ActionButton';
import { Chevron } from 'components/Icons';

const IconCircle = styled.div`
  width: ${props => (props.instructorView ? '150px' : '100px')};
  height: ${props => (props.instructorView ? '150px' : '100px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f3f9ff;
  margin: 20px 0;
  border-radius: 50%;
`;

const NoActivityIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoActivityImage = styled.img`
  width: 45%;
  display: block;
  margin: 50px 35px;
`;

const NoActivityPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  line-height: 1.8em;
  border-radius: var(--radius, 8px);
  border: var(--juni-card-border);
  background-color: ${props =>
    props.instructorView ? '#fff' : 'var(--juni-card, hsl(207deg 82% 98%))'};
  margin: ${props => !props.embedded && '0 0 1.7rem 0'};
  box-shadow: ${props =>
    props.embedded ? 'var(--juni-shadow)' : '0px 10px 30px hsl(208, 46%, 78%)'};

  & .action-button {
    margin-top: 1em;
  }
`;

const NoActivityText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: var(--juni-border);
  padding: 12px 0 16px;
  background: ${props => props.instructorView && '#f5faff'};

  & p {
    color: var(--juni-body, currentColor);
    margin: 0.5rem 0;
    padding: 0px 15px;
    text-align: center;
    font-size: 0.8rem;
    width: 70%;
    line-height: ${props => props.instructorView && '1.5rem'};
  }
  & > h1 {
    margin: 0;
    font-size: 22px;
  }
  & > h2 {
    margin: 0;
    font-size: 0.8rem;
    color: var(--juni-primary);
  }
`;

const NoActivityCard = ({
  embedded,
  title,
  description,
  actionButton,
  logo,
  smallTitle,
  instructorView,
}) => (
  <NoActivityPanel embedded={embedded} instructorView={instructorView}>
    <NoActivityIcon>
      <IconCircle instructorView={instructorView}>
        <NoActivityImage src={logo} alt="no-activity-logo" />
      </IconCircle>
    </NoActivityIcon>
    <NoActivityText instructorView={instructorView}>
      {smallTitle ? <h2>{title}</h2> : <h1>{title}</h1>}
      <p>{description}</p>
      {actionButton && (
        <ActionButton
          onClick={() => actionButton.onClick()}
          icon={<Chevron lightblue orientation="right" />}
        >
          Add an Internal Note
        </ActionButton>
      )}
    </NoActivityText>
  </NoActivityPanel>
);

NoActivityCard.defaultProps = {
  logo: noActivityLogo,
  smallTitle: false,
};

export default NoActivityCard;
