/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import * as R from 'ramda';
import { OnboardingTicketStatus, SignupData } from '../types';

const pickAllOnboardingStatuses = (
  signupData: Partial<SignupData>,
): Array<OnboardingTicketStatus> =>
  R.pipe(
    R.map(
      R.pipe(
        R.prop('bundle'),
        R.prop('selections'),
        R.pluck('onboardingTicketStatus'),
        R.values,
        R.uniq,
      ),
    ),
    R.flatten,
  )(signupData.students ?? []);

export default pickAllOnboardingStatuses;
