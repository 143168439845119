// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CORE_PRICING_PLANS } from 'constants/subscription_plans';
import * as R from 'ramda';
import { BundleData, PlanId } from '../types';

export const sumWeeklyClasses = (selections: BundleData['selections']): number =>
  R.sum(R.map(([, bundle]) => bundle?.weeklyClasses, R.toPairs(selections)));

const derivePlanIdFromBundleData = (studentBundle: BundleData): PlanId | undefined =>
  R.pipe(
    R.find(plan =>
      // Select the appropriate plan based on the number of weekly classes
      R.equals(sumWeeklyClasses(studentBundle.selections), plan.weeklyFrequency),
    ),
    R.prop('planId'),
  )(CORE_PRICING_PLANS) as PlanId | undefined;

export default derivePlanIdFromBundleData;
