import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import juniAxios from 'services/axios';
import { IconPlaceholder } from 'components/Icons';
import Spinner from 'components/Spinner';

import lockIcon from './images/lock.svg';
import amex from './images/amex.png';
import visa from './images/visa.png';
import mastercard from './images/mastercard.png';

import './learner-payment-info.css';

class LearnerPaymentInfo extends Component {
  state = {
    isLoading: false,
    errors: {},
    showConfirmationMessage: false,
  };
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (!this.node) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }
    this.props.onHide?.();
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ errors: {}, isLoading: true });

    const parentName = `${this.props.parent.firstName} ${this.props.parent.lastName}`;
    this.props.stripe.createToken({ name: parentName }).then(result => {
      if (result.error) {
        this.setState({
          errors: { card: result.error.message },
          isLoading: false,
        });
      } else {
        juniAxios
          .post(`/api/update_payment_info`, {
            userId: this.props.parent.userId,
            stripeToken: result.token,
          })
          .then(() => {
            this.props.getPaymentInfo();
            this.setState({ isLoading: false, showConfirmationMessage: true });
          })
          .catch(err => {
            console.log(err.response);
            this.setState({
              errors: err.response.data.errors,
              isLoading: false,
            });
          });
      }
    });
  };
  render() {
    return (
      <div className={`payment-modal ${this.props.isVisible ? '' : 'hidden'}`}>
        <div
          className="payment-modal-inner"
          ref={node => {
            this.node = node;
            return node;
          }}
        >
          <IconPlaceholder
            style={{
              alignSelf: 'flex-start',
              color: '#ccc',
              marginLeft: 4,
              userSelect: 'none',
              cursor: 'pointer',
            }}
            onClick={() => this.props.onHide()}
          >
            x
          </IconPlaceholder>
          {this.state.showConfirmationMessage ? (
            <div className="form-container">
              <div className="form-title">You're all set!</div>
              <div className="form-subtitle-2">
                Your payment information has been updated.
                <div className="button" onClick={() => this.props.onHide()}>
                  Return Home
                </div>
              </div>
            </div>
          ) : (
            <div className="form-container">
              <form onSubmit={e => this.handleSubmit(e)}>
                <div className="form-title">Edit your payment information</div>
                <div className="form-error">{this.state.errors.errorMessage}</div>
                <div
                  className="form-field-description"
                  style={{ marginBottom: '30px' }}
                >
                  If you'd like to change your primary form of payment, please enter
                  your new payment information. If your billing address is outside
                  the US, enter 00000 for the zip code.
                </div>
                <div className="form-secure-payment">
                  <img src={lockIcon} alt="lock_icon" />
                  Secure Payment
                  <div className="form-secure-payment-cards">
                    <img src={visa} alt="visa" />
                    <img src={mastercard} alt="mastercard" />
                    <img src={amex} alt="amex" />
                  </div>
                </div>
                <div className="form-error">{this.state.errors.card}</div>
                <div className="stripe-field">
                  <CardElement
                    style={{
                      base: {
                        fontSize: '12px',
                      },
                    }}
                  />
                </div>
                <div className="button" onClick={e => this.handleSubmit(e)}>
                  {this.state.isLoading ? <Spinner /> : null}
                  Submit
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}
LearnerPaymentInfo.defaultProps = {
  isVisible: false,
};
LearnerPaymentInfo.propTypes = {
  isVisible: PropTypes.bool,
  getPaymentInfo: PropTypes.func.isRequired,
};
export default injectStripe(LearnerPaymentInfo);
