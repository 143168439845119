const PAYMENT_ERROR_COPY: {
  [key: string]: {
    copy: string;
  };
} = {
  expired_card: {
    copy: 'Please use a card with a valid expiration date.',
  },
  incorrect_cvc: {
    copy: 'Please enter a valid card security code.',
  },
  invalid_cvc: {
    copy: 'Please enter a valid card security code.',
  },
  incorrect_number: {
    copy: 'Please enter a valid card number.',
  },
  invalid_expiry_month: {
    copy: 'Please enter a valid expiration month.',
  },
  invalid_expiry_year: {
    copy: 'Please enter a valid expiration year.',
  },
};

export default PAYMENT_ERROR_COPY;
