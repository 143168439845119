import { CLUB_INTERNAL_TAGS, WELCOME_CLUB_NAME } from 'constants/clubs';
import { JuniClubType, JuniClubMemberUserCategoryType } from 'generated/graphql';
import _ from 'lodash';
import queryString from 'query-string';

export type ClubUserBadgeName =
  | 'banned'
  | 'guest'
  | 'student'
  | 'leader'
  | 'instructor'
  | 'advisor'
  | 'moderator'
  | 'default';

export interface ClubUserBadgeMetadata {
  color: string;
  displayName: string;
}

export interface ClubUserBadgeInfo extends ClubUserBadgeMetadata {
  name: string;
}

const CLUB_USER_BADGE_LOOKUP: {
  [key in ClubUserBadgeName]: ClubUserBadgeMetadata;
} = {
  instructor: {
    color: 'juni-purple',
    displayName: 'Instructor',
  },
  student: {
    color: 'juni-purple',
    displayName: 'Student',
  },
  moderator: {
    color: 'juni-purple',
    displayName: 'Moderator',
  },
  guest: {
    color: 'juni-purple',
    displayName: 'Guest',
  },
  leader: {
    color: 'juni-purple',
    displayName: 'Leader',
  },
  advisor: {
    color: 'juni-purple',
    displayName: 'Advisor',
  },
  banned: {
    color: 'black',
    displayName: 'Member',
  },
  default: {
    color: 'black',
    displayName: 'Member',
  },
};

// TODO: update this when we update membershipStatus to be 'normal', 'privileged', 'banned'
const getClubUserBadgeName = ({
  userCategory,
  membershipStatus,
}: {
  userCategory?: JuniClubMemberUserCategoryType | null;
  membershipStatus?: string | null;
}): ClubUserBadgeName => {
  if (membershipStatus === 'banned') return 'banned';
  if (userCategory === 'guest') return 'guest';
  if (userCategory === 'student') {
    return membershipStatus === 'moderator' ? 'leader' : 'student';
  }
  if (userCategory === 'instructor') {
    return membershipStatus === 'moderator' ? 'advisor' : 'instructor';
  }
  if (userCategory === 'instructorMod' || userCategory === 'admin') {
    return 'moderator';
  }
  return 'default';
};

export const getClubUserBadgeInfo = ({
  userCategory,
  membershipStatus,
}: {
  userCategory?: JuniClubMemberUserCategoryType | null;
  membershipStatus?: string | null;
}): ClubUserBadgeInfo => {
  const name = getClubUserBadgeName({ userCategory, membershipStatus });
  return { name, ...CLUB_USER_BADGE_LOOKUP[name] };
};

export const getClubLink = ({
  studentId,
  juniClubId,
  channelName,
}: {
  studentId?: string;
  juniClubId: string;
  channelName?: string | null;
}) =>
  studentId
    ? `/learner/${studentId}/club_page/${juniClubId}/${channelName || 'general'}`
    : `/teacher/club_page/${juniClubId}/${channelName || 'general'}`;

export interface MyClubsQueryParams {
  /**
   * Optionally pass a club to auto-prompt joining.
   */
  joinClubId?: string;
}

export const getMyClubsLink = ({
  studentId,
  queryParams = {},
}: {
  studentId?: string;
  queryParams?: MyClubsQueryParams;
}) => {
  const search = queryString.stringify(queryParams);
  const urlQuery = search ? `?${search}` : '';

  return studentId
    ? `/learner/${studentId}/my_clubs${urlQuery}`
    : `/teacher/my_clubs${urlQuery}`;
};

export const sortClubs = (juniClubs: JuniClubType[]) =>
  // Sort first by whether the club is the "Welcome" club
  // and second by case-insensitive alphabetical order.
  _.sortBy(juniClubs, [
    club => !(club.displayName === WELCOME_CLUB_NAME),
    club => club.displayName.toLocaleLowerCase(),
  ]);
export const sortTags = (tags: string[]) =>
  _.sortBy(tags, [
    tag => !!CLUB_INTERNAL_TAGS.find(internalTag => internalTag.value === tag),
    tag => tag.toLocaleLowerCase(),
  ]);
