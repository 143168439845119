/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import * as R from 'ramda';
import { BundleData, OnboardingTicket } from '../types';
import derivePlanIdFromBundleData from './derivePlanIdFromBundleData';

const updateBundleWithOnboarding = (
  bundle: BundleData,
  onboardingTickets: OnboardingTicket[],
): BundleData => ({
  ...bundle,
  stripePlanId: derivePlanIdFromBundleData(bundle),
  selections: R.map(selection => ({
    ...selection,
    onboardingTicketStatus: onboardingTickets.find(
      ticket => ticket.subject === selection.subject,
    )?.status,
    onboardingTicketId: onboardingTickets.find(
      ticket => ticket.subject === selection.subject,
    )?._id,
  }))(bundle.selections),
});

export default updateBundleWithOnboarding;
