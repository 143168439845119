import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO Date string */
  DateTime: string;
  /** A standard Mongo Object ID */
  ObjectId: any;
};

/** Auto-generated input object for the deleteS3Object mutation */
export type DeleteS3ObjectInput = {
  destination: UploadDestination;
  object: Scalars['String'];
};

/** Auto-generated payload object for the deleteS3Object mutation */
export type DeleteS3ObjectPayload = {
  __typename?: 'DeleteS3ObjectPayload';
  success?: Maybe<Scalars['Boolean']>;
};



export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type Instructor = {
  __typename?: 'Instructor';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** Whether the instructor is associated with an active user */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Whether the instructor is excluded from timesheet generation, e.g. salaried */
  excludeFromHourlyPayroll?: Maybe<Scalars['Boolean']>;
  acuityCalendarId?: Maybe<Scalars['String']>;
  /** Whether the instructor has self reported hours (enum of none, some, all) */
  selfReportedHours?: Maybe<Scalars['String']>;
  /** Whether the user is connected with Nylas */
  nylasConnected?: Maybe<Scalars['Boolean']>;
  nylas?: Maybe<NylasSubdocument>;
  /** The user's connected calendars and whether they are meant to be checked for conflicts */
  nylasBusyCalendarOptions: Array<NylasBusyCalendarOption>;
  availability?: Maybe<Array<CachedNylasBlockOrPseudoAcuityApptBlock>>;
};


export type InstructorAvailabilityArgs = {
  excludeBefore?: Maybe<Scalars['DateTime']>;
  excludeAfter?: Maybe<Scalars['DateTime']>;
};

export type InstructorsPayload = {
  __typename?: 'InstructorsPayload';
  items: Array<Instructor>;
};

export type IdAndName = {
  __typename?: 'IdAndName';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Auto-generated payload object for the truncatedInstructorNames query */
export type TruncatedInstructorNamesPayload = {
  __typename?: 'TruncatedInstructorNamesPayload';
  items: Array<IdAndName>;
};

export enum LessonPaceType {
  InDepth = 'in_depth',
  Standard = 'standard',
  FastPaced = 'fast_paced'
}

export enum HomeworkLoadType {
  None = 'none',
  ThirtyMinutes = 'thirty_minutes',
  SixtyMinutes = 'sixty_minutes'
}

export type CertificationTracks = {
  __typename?: 'CertificationTracks';
  name?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
};

export type LearningPreferences = {
  __typename?: 'LearningPreferences';
  _id: Scalars['ObjectId'];
  subject: Scalars['String'];
  lessonPace: LessonPaceType;
  homeworkLoad: HomeworkLoadType;
  noteForInstructor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Student = {
  __typename?: 'Student';
  _id: Scalars['ObjectId'];
  username?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  track?: Maybe<Scalars['String']>;
  hasMultipleTracks?: Maybe<Scalars['Boolean']>;
  tracks?: Maybe<Array<Scalars['String']>>;
  completedTracks?: Maybe<Array<Scalars['String']>>;
  certificationTracks?: Maybe<Array<Maybe<CertificationTracks>>>;
  learningPreferences?: Maybe<Array<LearningPreferences>>;
};

/** Auto-generated input object for the createStudent mutation */
export type CreateStudentInput = {
  parentId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthdate: Scalars['String'];
  courses: Array<Scalars['String']>;
  availabilities: Array<Scalars['String']>;
  grade: Scalars['String'];
  timezone: Scalars['String'];
};

/** Auto-generated payload object for the createStudent mutation */
export type CreateStudentPayload = {
  __typename?: 'CreateStudentPayload';
  student?: Maybe<Student>;
};

/** Auto-generated input object for the prependStudentNotes mutation */
export type PrependStudentNotesInput = {
  studentId: Scalars['String'];
  parentId: Scalars['String'];
  notes: Scalars['String'];
};

/** Auto-generated payload object for the prependStudentNotes mutation */
export type PrependStudentNotesPayload = {
  __typename?: 'PrependStudentNotesPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type LearningPreferencesInput = {
  subject: Scalars['String'];
  lessonPace?: Maybe<LessonPaceType>;
  homeworkLoad?: Maybe<HomeworkLoadType>;
  noteForInstructor?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the updateLearningPreferences mutation */
export type UpdateLearningPreferencesInput = {
  studentId: Scalars['String'];
  learningPreferences: LearningPreferencesInput;
};

/** Auto-generated payload object for the updateLearningPreferences mutation */
export type UpdateLearningPreferencesPayload = {
  __typename?: 'UpdateLearningPreferencesPayload';
  updatedLearningPreference?: Maybe<LearningPreferences>;
};

export type Parent = {
  __typename?: 'Parent';
  _id: Scalars['ObjectId'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  hasMultipleChildren?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the updateEmail mutation */
export type UpdateEmailInput = {
  instructorId?: Maybe<Scalars['ObjectId']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  email: Scalars['String'];
};

/** Auto-generated payload object for the updateEmail mutation */
export type UpdateEmailPayload = {
  __typename?: 'UpdateEmailPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the updateName mutation */
export type UpdateNameInput = {
  instructorId: Scalars['ObjectId'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Auto-generated payload object for the updateName mutation */
export type UpdateNamePayload = {
  __typename?: 'UpdateNamePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type AcuityAppointmentNotification = {
  __typename?: 'AcuityAppointmentNotification';
  appointmentId?: Maybe<Scalars['Int']>;
  instructorId?: Maybe<Scalars['ObjectId']>;
  instructorCalendarId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  datetime?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  notifyFirstClassShadow?: Maybe<Scalars['Boolean']>;
  notifyStudentTransitionShadow?: Maybe<Scalars['Boolean']>;
  notifySubstituteSession?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated input object for the upsertAcuityAppointmentNotification mutation */
export type UpsertAcuityAppointmentNotificationInput = {
  appointmentId: Scalars['Int'];
  notifyFirstClassShadow?: Maybe<Scalars['Boolean']>;
  notifyStudentTransitionShadow?: Maybe<Scalars['Boolean']>;
  notifySubstituteSession?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the upsertAcuityAppointmentNotification mutation */
export type UpsertAcuityAppointmentNotificationPayload = {
  __typename?: 'UpsertAcuityAppointmentNotificationPayload';
  appointmentId?: Maybe<Scalars['Int']>;
  instructorId?: Maybe<Scalars['ObjectId']>;
  instructorCalendarId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  datetime?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  notifyFirstClassShadow?: Maybe<Scalars['Boolean']>;
  notifyStudentTransitionShadow?: Maybe<Scalars['Boolean']>;
  notifySubstituteSession?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum CancelStudentAppointmentResult {
  Ok = 'OK',
  Error = 'ERROR'
}

export type CancelStudentAppointmentPayload = {
  __typename?: 'CancelStudentAppointmentPayload';
  appointmentId?: Maybe<Scalars['Int']>;
  result: CancelStudentAppointmentResult;
  error?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the cancelStudentAppointments mutation */
export type CancelStudentAppointmentsInput = {
  appointmentIds: Array<Scalars['Int']>;
  studentId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the cancelStudentAppointments mutation */
export type CancelStudentAppointmentsPayload = {
  __typename?: 'CancelStudentAppointmentsPayload';
  appointments: Array<CancelStudentAppointmentPayload>;
};

export enum RescheduleStudentAppointmentResult {
  Ok = 'OK',
  Error = 'ERROR'
}

export type RescheduleStudentAppointmentPayload = {
  __typename?: 'RescheduleStudentAppointmentPayload';
  result: RescheduleStudentAppointmentResult;
  error?: Maybe<Scalars['String']>;
  appointment: AcuityAppointment;
};

export type RescheduleStudentAppointmentInput = {
  appointmentId: Scalars['Int'];
  calendarID: Scalars['Int'];
  datetime?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the rescheduleStudentAppointments mutation */
export type RescheduleStudentAppointmentsInput = {
  studentId: Scalars['ObjectId'];
  appointments: Array<RescheduleStudentAppointmentInput>;
};

/** Auto-generated payload object for the rescheduleStudentAppointments mutation */
export type RescheduleStudentAppointmentsPayload = {
  __typename?: 'RescheduleStudentAppointmentsPayload';
  appointments: Array<RescheduleStudentAppointmentPayload>;
};

export enum ScheduleStudentAppointmentResult {
  Ok = 'OK',
  Error = 'ERROR'
}

export type AcuityAppointment = {
  __typename?: 'AcuityAppointment';
  id: Scalars['Int'];
  appointmentTypeID: Scalars['Int'];
  calendarID: Scalars['Int'];
  datetime: Scalars['String'];
  timezone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  calendar?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  datetimeCreated?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  scheduledBy?: Maybe<Scalars['String']>;
  studentName?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canClientCancel?: Maybe<Scalars['Boolean']>;
  canClientReschedule?: Maybe<Scalars['Boolean']>;
};

export type ScheduleStudentAppointmentInput = {
  appointmentTypeID: Scalars['Int'];
  calendarID: Scalars['Int'];
  datetime: Scalars['DateTime'];
  timezone: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
};

export type ScheduleStudentAppointmentPayload = {
  __typename?: 'ScheduleStudentAppointmentPayload';
  result: ScheduleStudentAppointmentResult;
  error?: Maybe<Scalars['String']>;
  appointment?: Maybe<AcuityAppointment>;
};

/** Auto-generated input object for the scheduleStudentAppointments mutation */
export type ScheduleStudentAppointmentsInput = {
  studentId: Scalars['ObjectId'];
  appointments: Array<ScheduleStudentAppointmentInput>;
};

/** Auto-generated payload object for the scheduleStudentAppointments mutation */
export type ScheduleStudentAppointmentsPayload = {
  __typename?: 'ScheduleStudentAppointmentsPayload';
  appointments: Array<ScheduleStudentAppointmentPayload>;
};

export type EditedRichText = {
  __typename?: 'EditedRichText';
  _id: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  originalReferenceTextId?: Maybe<Scalars['ObjectId']>;
  content: Scalars['String'];
  originalReferenceText?: Maybe<ReferenceText>;
  updatedAt: Scalars['DateTime'];
};

/** Auto-generated input object for the createEditedRichText mutation */
export type CreateEditedRichTextInput = {
  content: Scalars['String'];
  originalReferenceTextId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the createEditedRichText mutation */
export type CreateEditedRichTextPayload = {
  __typename?: 'CreateEditedRichTextPayload';
  editedRichText: EditedRichText;
};

/** Auto-generated input object for the updateEditedRichText mutation */
export type UpdateEditedRichTextInput = {
  id: Scalars['ObjectId'];
  content?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateEditedRichText mutation */
export type UpdateEditedRichTextPayload = {
  __typename?: 'UpdateEditedRichTextPayload';
  editedRichText: EditedRichText;
};

export enum EnglishProjectType {
  ReadingProject = 'reading_project',
  WritingProject = 'writing_project'
}

export type EnglishProject = {
  __typename?: 'EnglishProject';
  _id: Scalars['ObjectId'];
  projectType: EnglishProjectType;
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
  annotatedTextIds: Array<Scalars['ObjectId']>;
  editedTextId?: Maybe<Scalars['ObjectId']>;
  isCustomProject?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the englishProjects query */
export type EnglishProjectsPayload = {
  __typename?: 'EnglishProjectsPayload';
  items: Array<EnglishProject>;
};

/** Auto-generated input object for the createAndInitializeEnglishProject mutation */
export type CreateAndInitializeEnglishProjectInput = {
  projectType: Scalars['String'];
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  isCustomProject?: Maybe<Scalars['Boolean']>;
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the createAndInitializeEnglishProject mutation */
export type CreateAndInitializeEnglishProjectPayload = {
  __typename?: 'CreateAndInitializeEnglishProjectPayload';
  englishProject: EnglishProject;
};

export enum InvestingProjectType {
  ReadingProject = 'reading_project',
  WritingProject = 'writing_project'
}

export type InvestingProject = {
  __typename?: 'InvestingProject';
  _id: Scalars['ObjectId'];
  projectType: InvestingProjectType;
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
  annotatedTextIds: Array<Scalars['ObjectId']>;
  editedTextId?: Maybe<Scalars['ObjectId']>;
  isCustomProject?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the investingProjects query */
export type InvestingProjectsPayload = {
  __typename?: 'InvestingProjectsPayload';
  items: Array<InvestingProject>;
};

/** Auto-generated input object for the createAndInitializeInvestingProject mutation */
export type CreateAndInitializeInvestingProjectInput = {
  projectType: Scalars['String'];
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  isCustomProject?: Maybe<Scalars['Boolean']>;
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the createAndInitializeInvestingProject mutation */
export type CreateAndInitializeInvestingProjectPayload = {
  __typename?: 'CreateAndInitializeInvestingProjectPayload';
  investingProject: InvestingProject;
};

export type Experiment = {
  __typename?: 'Experiment';
  _id: Scalars['ObjectId'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  participantType?: Maybe<Scalars['String']>;
  treatmentKey?: Maybe<Scalars['String']>;
  treatments?: Maybe<Array<ExperimentTreatment>>;
  usePopulations?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the queryExperiments query */
export type QueryExperimentsPayload = {
  __typename?: 'QueryExperimentsPayload';
  items: Array<Experiment>;
};

/** Auto-generated input object for the findAndAllocateExperimentsByParticipantId mutation */
export type FindAndAllocateExperimentsByParticipantIdInput = {
  participantId: Scalars['String'];
  participantType: Scalars['String'];
};

/** Auto-generated payload object for the findAndAllocateExperimentsByParticipantId mutation */
export type FindAndAllocateExperimentsByParticipantIdPayload = {
  __typename?: 'FindAndAllocateExperimentsByParticipantIdPayload';
  experiments?: Maybe<Array<Experiment>>;
};

/** Auto-generated input object for the createExperiment mutation */
export type CreateExperimentInput = {
  key: Scalars['String'];
  title: Scalars['String'];
  target: Scalars['String'];
  status: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  participantType: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  treatments: Array<ExperimentTreatmentInput>;
};

/** Auto-generated payload object for the createExperiment mutation */
export type CreateExperimentPayload = {
  __typename?: 'CreateExperimentPayload';
  experiment?: Maybe<Experiment>;
};

/** Auto-generated input object for the updateExperiment mutation */
export type UpdateExperimentInput = {
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  participantType?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated payload object for the updateExperiment mutation */
export type UpdateExperimentPayload = {
  __typename?: 'UpdateExperimentPayload';
  experiment?: Maybe<Experiment>;
};

export type ExperimentTreatment = {
  __typename?: 'ExperimentTreatment';
  key?: Maybe<Scalars['String']>;
  isControl?: Maybe<Scalars['Boolean']>;
  percentAllocation?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type ExperimentTreatmentInput = {
  key: Scalars['String'];
  isControl: Scalars['Boolean'];
  percentAllocation: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the addTreatmentsToExperiment mutation */
export type AddTreatmentsToExperimentInput = {
  experimentKey: Scalars['String'];
  treatments?: Maybe<Array<ExperimentTreatmentInput>>;
};

/** Auto-generated payload object for the addTreatmentsToExperiment mutation */
export type AddTreatmentsToExperimentPayload = {
  __typename?: 'AddTreatmentsToExperimentPayload';
  experiment?: Maybe<Experiment>;
};

/** Auto-generated input object for the removeTreatmentsFromExperiment mutation */
export type RemoveTreatmentsFromExperimentInput = {
  key: Scalars['String'];
  treatmentKeys: Array<Scalars['String']>;
};

/** Auto-generated payload object for the removeTreatmentsFromExperiment mutation */
export type RemoveTreatmentsFromExperimentPayload = {
  __typename?: 'RemoveTreatmentsFromExperimentPayload';
  experiment?: Maybe<Experiment>;
};

/** Auto-generated input object for the updateTreatmentsInExperiment mutation */
export type UpdateTreatmentsInExperimentInput = {
  key: Scalars['String'];
  treatments?: Maybe<Array<ExperimentTreatmentInput>>;
};

/** Auto-generated payload object for the updateTreatmentsInExperiment mutation */
export type UpdateTreatmentsInExperimentPayload = {
  __typename?: 'UpdateTreatmentsInExperimentPayload';
  experiment?: Maybe<Experiment>;
};

export type Announcement = {
  __typename?: 'Announcement';
  _id: Scalars['ObjectId'];
  backgroundImageURL: Scalars['String'];
  title: Scalars['String'];
  blurb: Scalars['String'];
  description: Scalars['String'];
};

/** Auto-generated payload object for the queryAnnouncements query */
export type QueryAnnouncementsPayload = {
  __typename?: 'QueryAnnouncementsPayload';
  items: Array<Announcement>;
};

export type EnglishAnswerSubmission = {
  __typename?: 'EnglishAnswerSubmission';
  _id: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  problemId: Scalars['ObjectId'];
  submittedValues: Array<Scalars['String']>;
  numSubmissionAttempts: Scalars['Int'];
  cachedProblem?: Maybe<CachedEnglishProblem>;
};

export enum CachedEnglishProblemType {
  MultipleChoice = 'multiple_choice',
  PlainText = 'plain_text'
}

export type CachedEnglishProblem = {
  __typename?: 'CachedEnglishProblem';
  _id: Scalars['ObjectId'];
  problemType: CachedEnglishProblemType;
  mcAnswerOptions: Array<EnglishMultipleChoiceAnswerOption>;
  mcCorrectAnswer: Array<EnglishMultipleChoiceCorrectAnswer>;
  text: Scalars['String'];
};

/** Auto-generated payload object for the englishAnswerSubmissionsForStudentProject query */
export type EnglishAnswerSubmissionsForStudentProjectPayload = {
  __typename?: 'EnglishAnswerSubmissionsForStudentProjectPayload';
  items: Array<EnglishAnswerSubmission>;
};

/** Auto-generated input object for the upsertEnglishAnswerSubmission mutation */
export type UpsertEnglishAnswerSubmissionInput = {
  studentId: Scalars['ObjectId'];
  problemId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  submittedValues: Array<Scalars['String']>;
  cachedProblem?: Maybe<CachedEnglishProblemInput>;
};

/** Auto-generated payload object for the upsertEnglishAnswerSubmission mutation */
export type UpsertEnglishAnswerSubmissionPayload = {
  __typename?: 'UpsertEnglishAnswerSubmissionPayload';
  success: Scalars['Boolean'];
};

export type CachedEnglishProblemInput = {
  problemType: Scalars['String'];
  mcAnswerOptions?: Maybe<Array<EnglishMcAnswerOptionInput>>;
  mcCorrectAnswer?: Maybe<Array<EnglishMcCorrectAnswerInput>>;
  text: Scalars['String'];
};

export type InvestingAnswerSubmission = {
  __typename?: 'InvestingAnswerSubmission';
  _id: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  problemId: Scalars['ObjectId'];
  submittedValues: Array<Scalars['String']>;
  numSubmissionAttempts: Scalars['Int'];
  cachedProblem?: Maybe<CachedInvestingProblem>;
};

export enum CachedInvestingProblemType {
  MultipleChoice = 'multiple_choice',
  PlainText = 'plain_text'
}

export type CachedInvestingProblem = {
  __typename?: 'CachedInvestingProblem';
  _id: Scalars['ObjectId'];
  problemType: CachedInvestingProblemType;
  mcAnswerOptions: Array<InvestingMultipleChoiceAnswerOption>;
  mcCorrectAnswer: Array<InvestingMultipleChoiceCorrectAnswer>;
  text: Scalars['String'];
};

/** Auto-generated payload object for the investingAnswerSubmissionsForStudentProject query */
export type InvestingAnswerSubmissionsForStudentProjectPayload = {
  __typename?: 'InvestingAnswerSubmissionsForStudentProjectPayload';
  items: Array<InvestingAnswerSubmission>;
};

/** Auto-generated input object for the upsertInvestingAnswerSubmission mutation */
export type UpsertInvestingAnswerSubmissionInput = {
  studentId: Scalars['ObjectId'];
  problemId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  submittedValues: Array<Scalars['String']>;
  cachedProblem?: Maybe<CachedInvestingProblemInput>;
};

/** Auto-generated payload object for the upsertInvestingAnswerSubmission mutation */
export type UpsertInvestingAnswerSubmissionPayload = {
  __typename?: 'UpsertInvestingAnswerSubmissionPayload';
  success: Scalars['Boolean'];
};

export type CachedInvestingProblemInput = {
  problemType: Scalars['String'];
  mcAnswerOptions?: Maybe<Array<InvestingMcAnswerOptionInput>>;
  mcCorrectAnswer?: Maybe<Array<InvestingMcCorrectAnswerInput>>;
  text: Scalars['String'];
};

/** Auto-generated input object for the startImpersonation mutation */
export type StartImpersonationInput = {
  userIdToImpersonate: Scalars['ObjectId'];
};

/** Auto-generated payload object for the startImpersonation mutation */
export type StartImpersonationPayload = {
  __typename?: 'StartImpersonationPayload';
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

/** Auto-generated payload object for the stopImpersonation mutation */
export type StopImpersonationPayload = {
  __typename?: 'StopImpersonationPayload';
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export type PayrollCycle = {
  __typename?: 'PayrollCycle';
  _id: Scalars['ObjectId'];
  /** First date of the payroll cycle */
  startDate: Scalars['DateTime'];
  /** Last date of the payroll cycle */
  endDate: Scalars['DateTime'];
  /** Date timesheets should be released */
  releaseDate: Scalars['DateTime'];
};

export type ICourse = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  displayNameMajor?: Maybe<Scalars['String']>;
  displayNameMinor?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type StudentCourseProgress = {
  __typename?: 'StudentCourseProgress';
  course: ICourse;
  isComplete: Scalars['Boolean'];
};

export type ModuleTopic = {
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type Module = {
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type ModuleSection = {
  _id: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
};

export type ModuleSectionBullet = {
  _id: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
};

export type Prompt = {
  __typename?: 'Prompt';
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
};

/** Auto-generated input object for the createPrompt mutation */
export type CreatePromptInput = {
  content: Scalars['String'];
};

/** Auto-generated payload object for the createPrompt mutation */
export type CreatePromptPayload = {
  __typename?: 'CreatePromptPayload';
  prompt: Prompt;
};

/** Auto-generated input object for the updatePrompt mutation */
export type UpdatePromptInput = {
  promptId: Scalars['ObjectId'];
  content: Scalars['String'];
};

/** Auto-generated payload object for the updatePrompt mutation */
export type UpdatePromptPayload = {
  __typename?: 'UpdatePromptPayload';
  prompt: Prompt;
};

/** Auto-generated input object for the deletePrompt mutation */
export type DeletePromptInput = {
  promptId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deletePrompt mutation */
export type DeletePromptPayload = {
  __typename?: 'DeletePromptPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ReferenceTextSet = {
  __typename?: 'ReferenceTextSet';
  _id: Scalars['ObjectId'];
  subject: Scalars['String'];
  title: Scalars['String'];
  adminDisplayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grades: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  referenceTextIds: Array<Scalars['ObjectId']>;
  referenceTexts?: Maybe<Array<ReferenceText>>;
};

/** Auto-generated payload object for the referenceTextSets query */
export type ReferenceTextSetsPayload = {
  __typename?: 'ReferenceTextSetsPayload';
  items: Array<ReferenceTextSet>;
};

/** Auto-generated payload object for the referenceTextSetsByIds query */
export type ReferenceTextSetsByIdsPayload = {
  __typename?: 'ReferenceTextSetsByIdsPayload';
  items: Array<ReferenceTextSet>;
};

/** Auto-generated input object for the createReferenceTextSet mutation */
export type CreateReferenceTextSetInput = {
  title: Scalars['String'];
  adminDisplayName?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createReferenceTextSet mutation */
export type CreateReferenceTextSetPayload = {
  __typename?: 'CreateReferenceTextSetPayload';
  referenceTextSet: ReferenceTextSet;
};

/** Auto-generated input object for the updateReferenceTextSet mutation */
export type UpdateReferenceTextSetInput = {
  id: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  adminDisplayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Scalars['String']>>;
  referenceTextId?: Maybe<Scalars['ObjectId']>;
  referenceTextIdToRemove?: Maybe<Scalars['ObjectId']>;
  referenceTextIds?: Maybe<Array<Scalars['String']>>;
};

/** Auto-generated payload object for the updateReferenceTextSet mutation */
export type UpdateReferenceTextSetPayload = {
  __typename?: 'UpdateReferenceTextSetPayload';
  referenceTextSet: ReferenceTextSet;
};

export type ReferenceText = {
  __typename?: 'ReferenceText';
  _id: Scalars['ObjectId'];
  subject: Scalars['String'];
  title: Scalars['String'];
  adminDisplayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  isSampleSolution: Scalars['Boolean'];
};

/** Auto-generated payload object for the referenceTexts query */
export type ReferenceTextsPayload = {
  __typename?: 'ReferenceTextsPayload';
  items: Array<ReferenceText>;
};

/** Auto-generated input object for the createReferenceText mutation */
export type CreateReferenceTextInput = {
  title: Scalars['String'];
  subject: Scalars['String'];
  adminDisplayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content: Scalars['String'];
};

/** Auto-generated payload object for the createReferenceText mutation */
export type CreateReferenceTextPayload = {
  __typename?: 'CreateReferenceTextPayload';
  referenceText: ReferenceText;
};

/** Auto-generated input object for the updateReferenceText mutation */
export type UpdateReferenceTextInput = {
  id: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  adminDisplayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateReferenceText mutation */
export type UpdateReferenceTextPayload = {
  __typename?: 'UpdateReferenceTextPayload';
  referenceText: ReferenceText;
};

export type ComputerScienceCourse = ICourse & {
  __typename?: 'ComputerScienceCourse';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  displayNameMajor?: Maybe<Scalars['String']>;
  displayNameMinor?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type ComputerScienceModule = Module & {
  __typename?: 'ComputerScienceModule';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type ComputerScienceModuleSection = ModuleSection & {
  __typename?: 'ComputerScienceModuleSection';
  _id: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  isProject?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the computerScienceModuleSections query */
export type ComputerScienceModuleSectionsPayload = {
  __typename?: 'ComputerScienceModuleSectionsPayload';
  items: Array<ComputerScienceModuleSection>;
};

export type EnglishCourse = ICourse & {
  __typename?: 'EnglishCourse';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  displayNameMajor?: Maybe<Scalars['String']>;
  displayNameMinor?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  abbreviation?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  moduleTopics: Array<EnglishModuleTopic>;
};

export type EnglishModuleTopic = ModuleTopic & {
  __typename?: 'EnglishModuleTopic';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  modules: Array<EnglishModule>;
};

export type EnglishModule = Module & {
  __typename?: 'EnglishModule';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTrialModule?: Maybe<Scalars['Boolean']>;
  moduleSections: Array<EnglishModuleSection>;
  videos: Array<EnglishVideo>;
};

export type EnglishVideo = {
  __typename?: 'EnglishVideo';
  _id: Scalars['ObjectId'];
  videoId: Scalars['ObjectId'];
};

export type CreateEnglishModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  isTrialModule?: Maybe<Scalars['Boolean']>;
};

export type CreateEnglishModulePayload = {
  __typename?: 'CreateEnglishModulePayload';
  englishCourse: EnglishCourse;
};

export type UpdateEnglishModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTrialModule?: Maybe<Scalars['Boolean']>;
  moduleSections?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateEnglishModulePayload = {
  __typename?: 'UpdateEnglishModulePayload';
  englishCourse: EnglishCourse;
};

export type DeleteEnglishModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
};

export type DeleteEnglishModulePayload = {
  __typename?: 'DeleteEnglishModulePayload';
  englishCourse: EnglishCourse;
};

export type EnglishModuleSection = ModuleSection & {
  __typename?: 'EnglishModuleSection';
  _id: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  sectionType: EnglishModuleSectionType;
  referenceTextSetOptions: Array<EnglishReferenceTextSetOption>;
  moduleSectionBullets: Array<EnglishModuleSectionBullet>;
  instructions?: Maybe<Scalars['String']>;
};

export enum EnglishModuleSectionType {
  ReadingProject = 'reading_project',
  WritingProject = 'writing_project',
  Concept = 'concept',
  Normal = 'normal',
  QuestionSet = 'question_set',
  Activity = 'activity'
}

export type EnglishSampleSolution = {
  __typename?: 'EnglishSampleSolution';
  _id: Scalars['ObjectId'];
  referenceTextId: Scalars['ObjectId'];
  solutionLevel?: Maybe<Scalars['Int']>;
};

export type EnglishReferenceTextSetOption = {
  __typename?: 'EnglishReferenceTextSetOption';
  _id: Scalars['ObjectId'];
  referenceTextSetId: Scalars['ObjectId'];
  sampleSolutions: Array<EnglishSampleSolution>;
};

/** Auto-generated input object for the createEnglishModuleSection mutation */
export type CreateEnglishModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionType: Scalars['String'];
  displayName: Scalars['String'];
  writingPromptId?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createEnglishModuleSection mutation */
export type CreateEnglishModuleSectionPayload = {
  __typename?: 'CreateEnglishModuleSectionPayload';
  englishCourse: EnglishCourse;
};

/** Auto-generated input object for the updateEnglishModuleSection mutation */
export type UpdateEnglishModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
  moduleSectionBullets?: Maybe<Array<Scalars['ObjectId']>>;
  referenceTextSetOptions?: Maybe<Array<Scalars['ObjectId']>>;
  instructions?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateEnglishModuleSection mutation */
export type UpdateEnglishModuleSectionPayload = {
  __typename?: 'UpdateEnglishModuleSectionPayload';
  englishCourse: EnglishCourse;
};

/** Auto-generated input object for the deleteEnglishModuleSection mutation */
export type DeleteEnglishModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteEnglishModuleSection mutation */
export type DeleteEnglishModuleSectionPayload = {
  __typename?: 'DeleteEnglishModuleSectionPayload';
  englishCourse: EnglishCourse;
};

/** Auto-generated input object for the deleteEnglishReferenceTextSetOption mutation */
export type DeleteEnglishReferenceTextSetOptionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  referenceTextSetOptionId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteEnglishReferenceTextSetOption mutation */
export type DeleteEnglishReferenceTextSetOptionPayload = {
  __typename?: 'DeleteEnglishReferenceTextSetOptionPayload';
  englishCourse: EnglishCourse;
};

export type EnglishModuleSectionBullet = ModuleSectionBullet & {
  __typename?: 'EnglishModuleSectionBullet';
  _id: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  problemType?: Maybe<EnglishProblemType>;
  solutionExplanation?: Maybe<Scalars['String']>;
  mcAnswerOptions: Array<EnglishMultipleChoiceAnswerOption>;
  mcCorrectAnswer: Array<EnglishMultipleChoiceCorrectAnswer>;
  sampleSolutions: Array<EnglishSampleSolution>;
};

export enum EnglishProblemType {
  MultipleChoice = 'multiple_choice',
  PlainText = 'plain_text',
  Instructional = 'instructional'
}

export type EnglishMultipleChoiceAnswerOption = {
  __typename?: 'EnglishMultipleChoiceAnswerOption';
  _id: Scalars['ObjectId'];
  answerChoiceText: Scalars['String'];
};

export type EnglishMultipleChoiceCorrectAnswer = {
  __typename?: 'EnglishMultipleChoiceCorrectAnswer';
  _id: Scalars['ObjectId'];
  solutionText: Scalars['String'];
};

/** Auto-generated input object for the createEnglishModuleSectionBullet mutation */
export type CreateEnglishModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  text: Scalars['String'];
  problemType?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createEnglishModuleSectionBullet mutation */
export type CreateEnglishModuleSectionBulletPayload = {
  __typename?: 'CreateEnglishModuleSectionBulletPayload';
  englishCourse: EnglishCourse;
};

export type EnglishMcAnswerOptionInput = {
  _id: Scalars['ObjectId'];
  answerChoiceText: Scalars['String'];
};

export type EnglishMcCorrectAnswerInput = {
  _id: Scalars['ObjectId'];
  solutionText: Scalars['String'];
};

/** Auto-generated input object for the updateEnglishModuleSectionBullet mutation */
export type UpdateEnglishModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  bulletId: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  problemType?: Maybe<Scalars['String']>;
  solutionExplanation?: Maybe<Scalars['String']>;
  answerChoiceText?: Maybe<Scalars['String']>;
  solutionText?: Maybe<Scalars['String']>;
  mcAnswerOptions?: Maybe<Array<EnglishMcAnswerOptionInput>>;
  mcCorrectAnswer?: Maybe<Array<EnglishMcCorrectAnswerInput>>;
};

/** Auto-generated payload object for the updateEnglishModuleSectionBullet mutation */
export type UpdateEnglishModuleSectionBulletPayload = {
  __typename?: 'UpdateEnglishModuleSectionBulletPayload';
  englishCourse: EnglishCourse;
};

/** Auto-generated input object for the deleteEnglishModuleSectionBullet mutation */
export type DeleteEnglishModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  bulletId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteEnglishModuleSectionBullet mutation */
export type DeleteEnglishModuleSectionBulletPayload = {
  __typename?: 'DeleteEnglishModuleSectionBulletPayload';
  englishCourse: EnglishCourse;
};

export type CreateEnglishCourseInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
};

export type CreateEnglishCoursePayload = {
  __typename?: 'CreateEnglishCoursePayload';
  englishCourse: EnglishCourse;
};

export type UpdateEnglishCourseInput = {
  courseId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  moduleTopics?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateEnglishCoursePayload = {
  __typename?: 'UpdateEnglishCoursePayload';
  englishCourse: EnglishCourse;
};

export type DeleteEnglishCourseInput = {
  courseId: Scalars['ObjectId'];
};

export type DeleteEnglishCoursePayload = {
  __typename?: 'DeleteEnglishCoursePayload';
  englishCourse: EnglishCourse;
};

export type CreateEnglishModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreateEnglishModuleTopicPayload = {
  __typename?: 'CreateEnglishModuleTopicPayload';
  englishCourse: EnglishCourse;
};

export type UpdateEnglishModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateEnglishModuleTopicPayload = {
  __typename?: 'UpdateEnglishModuleTopicPayload';
  englishCourse: EnglishCourse;
};

export type DeleteEnglishModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
};

export type DeleteEnglishModuleTopicPayload = {
  __typename?: 'DeleteEnglishModuleTopicPayload';
  englishCourse: EnglishCourse;
};

export type MathCourse = ICourse & {
  __typename?: 'MathCourse';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  displayNameMajor?: Maybe<Scalars['String']>;
  displayNameMinor?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  abbreviation?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  moduleTopics: Array<MathModuleTopic>;
};

export type MathModuleTopic = ModuleTopic & {
  __typename?: 'MathModuleTopic';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  modules: Array<MathModule>;
};

export type MathModule = Module & {
  __typename?: 'MathModule';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTrialModule?: Maybe<Scalars['Boolean']>;
  moduleSections: Array<MathModuleSection>;
};

export type CreateMathModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  isTrialModule?: Maybe<Scalars['Boolean']>;
};

export type CreateMathModulePayload = {
  __typename?: 'CreateMathModulePayload';
  mathCourse: MathCourse;
};

export type UpdateMathModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTrialModule?: Maybe<Scalars['Boolean']>;
  moduleSections?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateMathModulePayload = {
  __typename?: 'UpdateMathModulePayload';
  mathCourse: MathCourse;
};

export type DeleteMathModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
};

export type DeleteMathModulePayload = {
  __typename?: 'DeleteMathModulePayload';
  mathCourse: MathCourse;
};

export type MathModuleSection = ModuleSection & {
  __typename?: 'MathModuleSection';
  _id: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  sectionType: MathModuleSectionType;
  moduleSectionBullets: Array<MathModuleSectionBullet>;
  instructions?: Maybe<Scalars['String']>;
};

export enum MathModuleSectionType {
  ProblemSet = 'problem_set',
  Concept = 'concept',
  Normal = 'normal',
  Activity = 'activity'
}

/** Auto-generated input object for the createMathModuleSection mutation */
export type CreateMathModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionType: Scalars['String'];
  displayName: Scalars['String'];
  isSupplemental?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createMathModuleSection mutation */
export type CreateMathModuleSectionPayload = {
  __typename?: 'CreateMathModuleSectionPayload';
  mathCourse: MathCourse;
};

/** Auto-generated input object for the updateMathModuleSection mutation */
export type UpdateMathModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  moduleSectionBullets?: Maybe<Array<Scalars['ObjectId']>>;
  instructions?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateMathModuleSection mutation */
export type UpdateMathModuleSectionPayload = {
  __typename?: 'UpdateMathModuleSectionPayload';
  mathCourse: MathCourse;
};

/** Auto-generated input object for the deleteMathModuleSection mutation */
export type DeleteMathModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteMathModuleSection mutation */
export type DeleteMathModuleSectionPayload = {
  __typename?: 'DeleteMathModuleSectionPayload';
  mathCourse: MathCourse;
};

export type MathModuleSectionBullet = ModuleSectionBullet & {
  __typename?: 'MathModuleSectionBullet';
  _id: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  isProblem?: Maybe<Scalars['Boolean']>;
  hasExpectedSolution?: Maybe<Scalars['Boolean']>;
  difficulty?: Maybe<Scalars['String']>;
  solutionExplanation?: Maybe<Scalars['String']>;
  solutions: Array<MathSolution>;
};

export type MathSolution = {
  __typename?: 'MathSolution';
  _id: Scalars['ObjectId'];
  solutionText?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the createMathModuleSectionBullet mutation */
export type CreateMathModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  text: Scalars['String'];
  isProblem?: Maybe<Scalars['Boolean']>;
  solutionExplanation?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  hasExpectedSolution?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the createMathModuleSectionBullet mutation */
export type CreateMathModuleSectionBulletPayload = {
  __typename?: 'CreateMathModuleSectionBulletPayload';
  mathCourse: MathCourse;
};

export type MathSolutionInput = {
  _id: Scalars['ObjectId'];
  solutionText: Scalars['String'];
};

/** Auto-generated input object for the updateMathModuleSectionBullet mutation */
export type UpdateMathModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  bulletId: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  isProblem?: Maybe<Scalars['Boolean']>;
  hasExpectedSolution?: Maybe<Scalars['Boolean']>;
  solutionExplanation?: Maybe<Scalars['String']>;
  solutionText?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  solutions?: Maybe<Array<MathSolutionInput>>;
};

/** Auto-generated payload object for the updateMathModuleSectionBullet mutation */
export type UpdateMathModuleSectionBulletPayload = {
  __typename?: 'UpdateMathModuleSectionBulletPayload';
  mathCourse: MathCourse;
};

/** Auto-generated input object for the deleteMathModuleSectionBullet mutation */
export type DeleteMathModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  bulletId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteMathModuleSectionBullet mutation */
export type DeleteMathModuleSectionBulletPayload = {
  __typename?: 'DeleteMathModuleSectionBulletPayload';
  mathCourse: MathCourse;
};

export type CreateMathCourseInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
};

export type CreateMathCoursePayload = {
  __typename?: 'CreateMathCoursePayload';
  mathCourse: MathCourse;
};

export type UpdateMathCourseInput = {
  courseId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  moduleTopics?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateMathCoursePayload = {
  __typename?: 'UpdateMathCoursePayload';
  mathCourse: MathCourse;
};

export type DeleteMathCourseInput = {
  courseId: Scalars['ObjectId'];
};

export type DeleteMathCoursePayload = {
  __typename?: 'DeleteMathCoursePayload';
  mathCourse: MathCourse;
};

export type CreateMathModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreateMathModuleTopicPayload = {
  __typename?: 'CreateMathModuleTopicPayload';
  mathCourse: MathCourse;
};

export type UpdateMathModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateMathModuleTopicPayload = {
  __typename?: 'UpdateMathModuleTopicPayload';
  mathCourse: MathCourse;
};

export type DeleteMathModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
};

export type DeleteMathModuleTopicPayload = {
  __typename?: 'DeleteMathModuleTopicPayload';
  mathCourse: MathCourse;
};

export type InvestingCourse = ICourse & {
  __typename?: 'InvestingCourse';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  displayNameMajor?: Maybe<Scalars['String']>;
  displayNameMinor?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  abbreviation?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  moduleTopics: Array<InvestingModuleTopic>;
};

export type InvestingModuleTopic = ModuleTopic & {
  __typename?: 'InvestingModuleTopic';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  modules: Array<InvestingModule>;
};

export type InvestingModule = Module & {
  __typename?: 'InvestingModule';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTrialModule?: Maybe<Scalars['Boolean']>;
  moduleSections: Array<InvestingModuleSection>;
  videos: Array<InvestingVideo>;
};

export type InvestingVideo = {
  __typename?: 'InvestingVideo';
  _id: Scalars['ObjectId'];
  videoId: Scalars['ObjectId'];
};

export type CreateInvestingModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  isTrialModule?: Maybe<Scalars['Boolean']>;
};

export type CreateInvestingModulePayload = {
  __typename?: 'CreateInvestingModulePayload';
  investingCourse: InvestingCourse;
};

export type UpdateInvestingModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTrialModule?: Maybe<Scalars['Boolean']>;
  moduleSections?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateInvestingModulePayload = {
  __typename?: 'UpdateInvestingModulePayload';
  investingCourse: InvestingCourse;
};

export type DeleteInvestingModuleInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
};

export type DeleteInvestingModulePayload = {
  __typename?: 'DeleteInvestingModulePayload';
  investingCourse: InvestingCourse;
};

export type InvestingModuleSection = ModuleSection & {
  __typename?: 'InvestingModuleSection';
  _id: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  sectionType: InvestingModuleSectionType;
  referenceTextSetOptions: Array<InvestingReferenceTextSetOption>;
  moduleSectionBullets: Array<InvestingModuleSectionBullet>;
  instructions?: Maybe<Scalars['String']>;
};

export enum InvestingModuleSectionType {
  ReadingProject = 'reading_project',
  WritingProject = 'writing_project',
  Concept = 'concept',
  Normal = 'normal',
  QuestionSet = 'question_set',
  Activity = 'activity'
}

export type InvestingSampleSolution = {
  __typename?: 'InvestingSampleSolution';
  _id: Scalars['ObjectId'];
  referenceTextId: Scalars['ObjectId'];
  solutionLevel?: Maybe<Scalars['Int']>;
};

export type InvestingReferenceTextSetOption = {
  __typename?: 'InvestingReferenceTextSetOption';
  _id: Scalars['ObjectId'];
  referenceTextSetId: Scalars['ObjectId'];
  sampleSolutions: Array<InvestingSampleSolution>;
};

/** Auto-generated input object for the createInvestingModuleSection mutation */
export type CreateInvestingModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionType: Scalars['String'];
  displayName: Scalars['String'];
  writingPromptId?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createInvestingModuleSection mutation */
export type CreateInvestingModuleSectionPayload = {
  __typename?: 'CreateInvestingModuleSectionPayload';
  investingCourse: InvestingCourse;
};

/** Auto-generated input object for the updateInvestingModuleSection mutation */
export type UpdateInvestingModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  displayName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<Scalars['String']>;
  isSupplemental?: Maybe<Scalars['Boolean']>;
  writingPromptId?: Maybe<Scalars['ObjectId']>;
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
  moduleSectionBullets?: Maybe<Array<Scalars['ObjectId']>>;
  referenceTextSetOptions?: Maybe<Array<Scalars['ObjectId']>>;
  instructions?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateInvestingModuleSection mutation */
export type UpdateInvestingModuleSectionPayload = {
  __typename?: 'UpdateInvestingModuleSectionPayload';
  investingCourse: InvestingCourse;
};

/** Auto-generated input object for the deleteInvestingModuleSection mutation */
export type DeleteInvestingModuleSectionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteInvestingModuleSection mutation */
export type DeleteInvestingModuleSectionPayload = {
  __typename?: 'DeleteInvestingModuleSectionPayload';
  investingCourse: InvestingCourse;
};

/** Auto-generated input object for the deleteInvestingReferenceTextSetOption mutation */
export type DeleteInvestingReferenceTextSetOptionInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  referenceTextSetOptionId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteInvestingReferenceTextSetOption mutation */
export type DeleteInvestingReferenceTextSetOptionPayload = {
  __typename?: 'DeleteInvestingReferenceTextSetOptionPayload';
  investingCourse: InvestingCourse;
};

export type InvestingModuleSectionBullet = ModuleSectionBullet & {
  __typename?: 'InvestingModuleSectionBullet';
  _id: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  problemType?: Maybe<InvestingProblemType>;
  solutionExplanation?: Maybe<Scalars['String']>;
  mcAnswerOptions: Array<InvestingMultipleChoiceAnswerOption>;
  mcCorrectAnswer: Array<InvestingMultipleChoiceCorrectAnswer>;
  sampleSolutions: Array<InvestingSampleSolution>;
};

export enum InvestingProblemType {
  MultipleChoice = 'multiple_choice',
  PlainText = 'plain_text',
  Instructional = 'instructional'
}

export type InvestingMultipleChoiceAnswerOption = {
  __typename?: 'InvestingMultipleChoiceAnswerOption';
  _id: Scalars['ObjectId'];
  answerChoiceText: Scalars['String'];
};

export type InvestingMultipleChoiceCorrectAnswer = {
  __typename?: 'InvestingMultipleChoiceCorrectAnswer';
  _id: Scalars['ObjectId'];
  solutionText: Scalars['String'];
};

/** Auto-generated input object for the createInvestingModuleSectionBullet mutation */
export type CreateInvestingModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  text: Scalars['String'];
  problemType?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createInvestingModuleSectionBullet mutation */
export type CreateInvestingModuleSectionBulletPayload = {
  __typename?: 'CreateInvestingModuleSectionBulletPayload';
  investingCourse: InvestingCourse;
};

export type InvestingMcAnswerOptionInput = {
  _id: Scalars['ObjectId'];
  answerChoiceText: Scalars['String'];
};

export type InvestingMcCorrectAnswerInput = {
  _id: Scalars['ObjectId'];
  solutionText: Scalars['String'];
};

/** Auto-generated input object for the updateInvestingModuleSectionBullet mutation */
export type UpdateInvestingModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  bulletId: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  problemType?: Maybe<Scalars['String']>;
  solutionExplanation?: Maybe<Scalars['String']>;
  answerChoiceText?: Maybe<Scalars['String']>;
  solutionText?: Maybe<Scalars['String']>;
  mcAnswerOptions?: Maybe<Array<InvestingMcAnswerOptionInput>>;
  mcCorrectAnswer?: Maybe<Array<InvestingMcCorrectAnswerInput>>;
};

/** Auto-generated payload object for the updateInvestingModuleSectionBullet mutation */
export type UpdateInvestingModuleSectionBulletPayload = {
  __typename?: 'UpdateInvestingModuleSectionBulletPayload';
  investingCourse: InvestingCourse;
};

/** Auto-generated input object for the deleteInvestingModuleSectionBullet mutation */
export type DeleteInvestingModuleSectionBulletInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  bulletId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteInvestingModuleSectionBullet mutation */
export type DeleteInvestingModuleSectionBulletPayload = {
  __typename?: 'DeleteInvestingModuleSectionBulletPayload';
  investingCourse: InvestingCourse;
};

export type CreateInvestingCourseInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  subjectId?: Maybe<Scalars['ObjectId']>;
};

export type CreateInvestingCoursePayload = {
  __typename?: 'CreateInvestingCoursePayload';
  investingCourse: InvestingCourse;
};

export type UpdateInvestingCourseInput = {
  courseId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  moduleTopics?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateInvestingCoursePayload = {
  __typename?: 'UpdateInvestingCoursePayload';
  investingCourse: InvestingCourse;
};

export type DeleteInvestingCourseInput = {
  courseId: Scalars['ObjectId'];
};

export type DeleteInvestingCoursePayload = {
  __typename?: 'DeleteInvestingCoursePayload';
  investingCourse: InvestingCourse;
};

export type CreateInvestingModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreateInvestingModuleTopicPayload = {
  __typename?: 'CreateInvestingModuleTopicPayload';
  investingCourse: InvestingCourse;
};

export type UpdateInvestingModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Scalars['ObjectId']>>;
};

export type UpdateInvestingModuleTopicPayload = {
  __typename?: 'UpdateInvestingModuleTopicPayload';
  investingCourse: InvestingCourse;
};

export type DeleteInvestingModuleTopicInput = {
  courseId: Scalars['ObjectId'];
  topicId: Scalars['ObjectId'];
};

export type DeleteInvestingModuleTopicPayload = {
  __typename?: 'DeleteInvestingModuleTopicPayload';
  investingCourse: InvestingCourse;
};

export type NestedRubricItem = {
  __typename?: 'NestedRubricItem';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  sortKey: Scalars['Int'];
  subitems?: Maybe<Array<NestedRubricItem>>;
};

export type CourseRubric = {
  __typename?: 'CourseRubric';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  isActive: Scalars['Boolean'];
  items?: Maybe<Array<NestedRubricItem>>;
};

export type RubricItemInput = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  sortKey: Scalars['Int'];
  subitemIds?: Maybe<Array<Scalars['ObjectId']>>;
};

/** Auto-generated input object for the updateCourseRubric mutation */
export type UpdateCourseRubricInput = {
  courseId: Scalars['ObjectId'];
  isActive: Scalars['Boolean'];
  rubricItems?: Maybe<Array<RubricItemInput>>;
  rootSubitemIds?: Maybe<Array<Scalars['ObjectId']>>;
};

/** Auto-generated payload object for the updateCourseRubric mutation */
export type UpdateCourseRubricPayload = {
  __typename?: 'UpdateCourseRubricPayload';
  courseRubric: CourseRubric;
};

export type CourseRubricProgress = {
  __typename?: 'CourseRubricProgress';
  courseId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  progresses?: Maybe<Array<LearningTargetProgress>>;
  rubric?: Maybe<CourseRubric>;
};

export type LearningTargetProgress = {
  __typename?: 'LearningTargetProgress';
  targetId: Scalars['ObjectId'];
  level: Scalars['Int'];
};

export type LearningTargetProgressInput = {
  targetId: Scalars['ObjectId'];
  level: Scalars['Int'];
};

export type CourseRubricProgressInput = {
  courseId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

/** Auto-generated input object for the updateCourseRubricProgress mutation */
export type UpdateCourseRubricProgressInput = {
  courseId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  updaterUserId: Scalars['ObjectId'];
  progresses: Array<LearningTargetProgressInput>;
};

/** Auto-generated payload object for the updateCourseRubricProgress mutation */
export type UpdateCourseRubricProgressPayload = {
  __typename?: 'UpdateCourseRubricProgressPayload';
  progressUpdates: Array<LearningTargetProgress>;
};

export type CourseRubricForLearnerInput = {
  courseName: Scalars['String'];
  subjectName: Scalars['String'];
  studentId: Scalars['String'];
};

export type Subject = {
  __typename?: 'Subject';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  iconName?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Course = {
  __typename?: 'Course';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  subjectId: Scalars['ObjectId'];
  subject: Subject;
  isAcceptingEnrollment: Scalars['Boolean'];
  curriculumId?: Maybe<Scalars['ObjectId']>;
  curriculumCollection?: Maybe<CurriculumCollection>;
  headerImage?: Maybe<CourseImage>;
  /** @deprecated Use iconName instead */
  courseIconName?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  /** @deprecated Use primaryColor instead */
  coursePrimaryColor?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  minRecommendedAge?: Maybe<Scalars['Int']>;
  maxRecommendedAge?: Maybe<Scalars['Int']>;
  minDurationDays?: Maybe<Scalars['Int']>;
  maxDurationDays?: Maybe<Scalars['Int']>;
  durationHours?: Maybe<Scalars['Int']>;
  durationDisplayScale?: Maybe<Scalars['String']>;
  formattedDuration?: Maybe<Scalars['String']>;
  creatorUserId?: Maybe<Scalars['ObjectId']>;
  schedulingFormat: CourseSchedulingFormat;
  isMarketplaceCourse: Scalars['Boolean'];
  formatDisplayName: Scalars['String'];
  courseDescription?: Maybe<Scalars['String']>;
  secondaryDescription?: Maybe<Scalars['String']>;
  prereqs?: Maybe<Array<Scalars['String']>>;
  syllabus?: Maybe<Scalars['String']>;
  homeworkInformation?: Maybe<Scalars['String']>;
  learningTargets?: Maybe<CourseRubric>;
  recommendationRank?: Maybe<Scalars['Int']>;
  tagIds?: Maybe<Array<Scalars['ObjectId']>>;
  tags: Array<Tag>;
  statistics?: Maybe<CourseStatistics>;
  deprecatedInFavorOfCourseId?: Maybe<Scalars['ObjectId']>;
  defaultJideEnv?: Maybe<Scalars['String']>;
  offerings: Array<CourseOffering>;
  suggestedCourseIds?: Maybe<Array<Scalars['ObjectId']>>;
  suggestedCourses: Array<Course>;
  nextRecommendedCourseIds?: Maybe<Array<Scalars['ObjectId']>>;
  nextRecommendedCourses: Array<Course>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum CurriculumCollection {
  CurriculumCourse = 'CurriculumCourse',
  MathCurriculumCourse = 'MathCurriculumCourse',
  EnglishCurriculumCourse = 'EnglishCurriculumCourse',
  InvestingCurriculumCourse = 'InvestingCurriculumCourse'
}

export type CourseImage = {
  __typename?: 'CourseImage';
  original: Scalars['String'];
};

export type CourseStatistics = {
  __typename?: 'CourseStatistics';
  ratingDistribution?: Maybe<Array<Scalars['Int']>>;
  ratingAverage?: Maybe<Scalars['Float']>;
  ratingTotal?: Maybe<Scalars['Int']>;
  ratingScale?: Maybe<Scalars['Int']>;
  ratingCount?: Maybe<Scalars['Int']>;
  totalEnrollments?: Maybe<Scalars['Int']>;
  activeEnrollments?: Maybe<Scalars['Int']>;
  weeklyHoursAvailable?: Maybe<Scalars['Int']>;
  weeklyInstructorsAvailable?: Maybe<Scalars['Int']>;
};

export enum CourseSchedulingFormat {
  Private = 'private',
  Group = 'group',
  OnDemand = 'on_demand'
}

export type QueryCourseInput = {
  ids?: Maybe<Array<Scalars['ObjectId']>>;
  names?: Maybe<Array<Scalars['String']>>;
  curriculumIds?: Maybe<Array<Scalars['ObjectId']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
  isAcceptingEnrollment?: Maybe<Scalars['Boolean']>;
  schedulingFormat?: Maybe<Scalars['String']>;
};

export type CourseOffering = {
  __typename?: 'CourseOffering';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  course: Course;
  courseName: Scalars['String'];
  instructorId: Scalars['ObjectId'];
  totalSeats: Scalars['Int'];
  numSeatsAvailable: Scalars['Int'];
  schedule: Array<CourseOfferingScheduleItem>;
  enrollmentEndDate: Scalars['DateTime'];
  totalPriceInCents: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CourseOfferingScheduleItem = {
  __typename?: 'CourseOfferingScheduleItem';
  _id: Scalars['ObjectId'];
  placeholderAcuityApptId: Scalars['Int'];
  startTime: Scalars['DateTime'];
  duration: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
};

export enum TagType {
  Topic = 'topic',
  Info = 'info'
}

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ObjectId'];
  type: TagType;
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CourseEnrollment = {
  __typename?: 'CourseEnrollment';
  _id: Scalars['ObjectId'];
  courseId: Scalars['ObjectId'];
  courseOfferingId?: Maybe<Scalars['ObjectId']>;
  studentId: Scalars['ObjectId'];
  schedule?: Maybe<Array<CourseEnrollmentScheduleItem>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CourseEnrollmentScheduleItem = {
  __typename?: 'CourseEnrollmentScheduleItem';
  acuityApptId?: Maybe<Scalars['Int']>;
  _id: Scalars['ObjectId'];
  startTime: Scalars['DateTime'];
  duration: Scalars['Int'];
};

/** Auto-generated input object for the uncompleteStudentCourse mutation */
export type UncompleteStudentCourseInput = {
  studentId: Scalars['String'];
  course: Scalars['String'];
};

/** Auto-generated payload object for the uncompleteStudentCourse mutation */
export type UncompleteStudentCoursePayload = {
  __typename?: 'UncompleteStudentCoursePayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the completeStudentCourse mutation */
export type CompleteStudentCourseInput = {
  studentId: Scalars['String'];
  course: Scalars['String'];
  doNotRemoveFromCurrent?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the completeStudentCourse mutation */
export type CompleteStudentCoursePayload = {
  __typename?: 'CompleteStudentCoursePayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the removeStudentCourse mutation */
export type RemoveStudentCourseInput = {
  studentId: Scalars['String'];
  course: Scalars['String'];
};

/** Auto-generated payload object for the removeStudentCourse mutation */
export type RemoveStudentCoursePayload = {
  __typename?: 'RemoveStudentCoursePayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the addStudentCourse mutation */
export type AddStudentCourseInput = {
  studentId: Scalars['String'];
  course: Scalars['String'];
};

/** Auto-generated payload object for the addStudentCourse mutation */
export type AddStudentCoursePayload = {
  __typename?: 'AddStudentCoursePayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the updateStudentCourse mutation */
export type UpdateStudentCourseInput = {
  studentId: Scalars['String'];
  currentCourse: Scalars['String'];
  newCourse: Scalars['String'];
  completeCurrentCourse?: Maybe<Scalars['Boolean']>;
  executeAsAdmin?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the updateStudentCourse mutation */
export type UpdateStudentCoursePayload = {
  __typename?: 'UpdateStudentCoursePayload';
  success: Scalars['Boolean'];
};

export type CourseCompletionInfo = {
  __typename?: 'CourseCompletionInfo';
  studentId: Scalars['ObjectId'];
  courseName: Scalars['String'];
  completionDate?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the courseCompletionDates query */
export type CourseCompletionDatesPayload = {
  __typename?: 'CourseCompletionDatesPayload';
  items: Array<CourseCompletionInfo>;
};

export type StudentPotentialInstructor = {
  __typename?: 'StudentPotentialInstructor';
  _id?: Maybe<Scalars['ObjectId']>;
  instructorId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isNewInstructor?: Maybe<Scalars['Boolean']>;
  needsGroupTraining?: Maybe<Scalars['Boolean']>;
};

export enum StudentProposalStatus {
  Unsent = 'UNSENT',
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Accepted = 'ACCEPTED',
  AcceptedByOther = 'ACCEPTED_BY_OTHER',
  CanceledByJuni = 'CANCELED_BY_JUNI'
}

export enum StudentProposalTicketType {
  Onboarding = 'ONBOARDING',
  Handoff = 'HANDOFF'
}

export type StudentFirstClassDatetime = {
  __typename?: 'StudentFirstClassDatetime';
  _id: Scalars['String'];
  datetime?: Maybe<Scalars['DateTime']>;
};

export type StudentProposalStudent = {
  __typename?: 'StudentProposalStudent';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type StudentProposalTicket = {
  __typename?: 'StudentProposalTicket';
  _id: Scalars['ObjectId'];
  /** All students linked to the proposal */
  students?: Maybe<Array<StudentProposalStudent>>;
  courseNames: Array<Scalars['String']>;
  scheduleType?: Maybe<Scalars['String']>;
  ticketType: StudentProposalTicketType;
  studentProposalNotes?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  firstClassIsShadow?: Maybe<Scalars['Boolean']>;
  potentialFirstClassDatetimes?: Maybe<Array<StudentFirstClassDatetime>>;
  firstClassDatetimes?: Maybe<Array<StudentFirstClassDatetime>>;
  upcomingClassTimes?: Maybe<Array<Scalars['String']>>;
};

export type StudentProposal = {
  __typename?: 'StudentProposal';
  _id: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
  instructor?: Maybe<Instructor>;
  studentOnboardingId?: Maybe<Scalars['ObjectId']>;
  studentHandoffId?: Maybe<Scalars['ObjectId']>;
  /** When the proposal was accepted/declined */
  respondedAt?: Maybe<Scalars['DateTime']>;
  /** Onboarding/handoff ticket information */
  ticket?: Maybe<StudentProposalTicket>;
  status: StudentProposalStatus;
  /** When the proposal was scheduled to be sent at */
  scheduledSendDate?: Maybe<Scalars['DateTime']>;
  /** When the proposal was sent */
  sentAt?: Maybe<Scalars['DateTime']>;
  /** When the proposal was created */
  createdAt?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated payload object for the studentProposals query */
export type StudentProposalsPayload = {
  __typename?: 'StudentProposalsPayload';
  items: Array<StudentProposal>;
};

/** Auto-generated input object for the acceptStudentProposal mutation */
export type AcceptStudentProposalInput = {
  studentProposalId?: Maybe<Scalars['ObjectId']>;
  times?: Maybe<Array<Scalars['String']>>;
};

/** Auto-generated payload object for the acceptStudentProposal mutation */
export type AcceptStudentProposalPayload = {
  __typename?: 'AcceptStudentProposalPayload';
  studentProposal: StudentProposal;
};

/** Auto-generated input object for the declineStudentProposal mutation */
export type DeclineStudentProposalInput = {
  studentProposalId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the declineStudentProposal mutation */
export type DeclineStudentProposalPayload = {
  __typename?: 'DeclineStudentProposalPayload';
  studentProposal: StudentProposal;
};

export type StudentHandoff = {
  __typename?: 'StudentHandoff';
  _id: Scalars['ObjectId'];
  status?: Maybe<Scalars['String']>;
  potentialInstructors?: Maybe<Array<StudentPotentialInstructor>>;
};

/** Auto-generated input object for the sendHandoffStudentProposals mutation */
export type SendHandoffStudentProposalsInput = {
  handoffId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the sendHandoffStudentProposals mutation */
export type SendHandoffStudentProposalsPayload = {
  __typename?: 'SendHandoffStudentProposalsPayload';
  studentHandoff: StudentHandoff;
};

export type StudentOnboarding = {
  __typename?: 'StudentOnboarding';
  _id: Scalars['ObjectId'];
  status?: Maybe<Scalars['String']>;
  potentialInstructors?: Maybe<Array<StudentPotentialInstructor>>;
};

/** Auto-generated input object for the sendOnboardingStudentProposals mutation */
export type SendOnboardingStudentProposalsInput = {
  onboardingId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the sendOnboardingStudentProposals mutation */
export type SendOnboardingStudentProposalsPayload = {
  __typename?: 'SendOnboardingStudentProposalsPayload';
  studentOnboarding: StudentOnboarding;
};

export type OnboardingEmailTemplateValues = {
  parentFirstName: Scalars['String'];
  studentFirstName: Scalars['String'];
  parentEmail: Scalars['String'];
  firstClassDate: Scalars['String'];
  trialEndDate?: Maybe<Scalars['String']>;
  urlBase: Scalars['String'];
  accountSetupUrl: Scalars['String'];
  primaryEmailContent: Scalars['String'];
};

/** Auto-generated input object for the sendOnboardingEmail mutation */
export type SendOnboardingEmailInput = {
  parentEmail: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  courseNames?: Maybe<Array<Scalars['String']>>;
  origin?: Maybe<Scalars['String']>;
  templateValues: OnboardingEmailTemplateValues;
};

/** Auto-generated payload object for the sendOnboardingEmail mutation */
export type SendOnboardingEmailPayload = {
  __typename?: 'SendOnboardingEmailPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type HandoffEmailTemplateValues = {
  parentFirstName: Scalars['String'];
  primaryEmailContent: Scalars['String'];
};

/** Auto-generated input object for the sendHandoffEmail mutation */
export type SendHandoffEmailInput = {
  parentEmail: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateValues: HandoffEmailTemplateValues;
};

/** Auto-generated payload object for the sendHandoffEmail mutation */
export type SendHandoffEmailPayload = {
  __typename?: 'SendHandoffEmailPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ProfilePage = {
  __typename?: 'ProfilePage';
  username: Scalars['String'];
  type: Scalars['String'];
  profile?: Maybe<ProfileSchema>;
};

export type UserEditedImageInput = {
  url?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

/** Auto-generated input object for the updateUserProfile mutation */
export type UpdateUserProfileInput = {
  username: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  profileColor?: Maybe<Scalars['String']>;
  profileImage?: Maybe<UserEditedImageInput>;
  bannerImage?: Maybe<UserEditedImageInput>;
};

/** Auto-generated payload object for the updateUserProfile mutation */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  success?: Maybe<Scalars['Boolean']>;
  updatedProfile?: Maybe<ProfilePage>;
};

export enum ProjectType {
  Scratch = 'SCRATCH',
  PythonTurtle = 'PYTHON_TURTLE',
  Python = 'PYTHON',
  Java = 'JAVA',
  Cpp = 'CPP',
  Whiteboard = 'WHITEBOARD',
  Math = 'MATH',
  MathWhiteboard = 'MATH_WHITEBOARD',
  EnglishWriting = 'ENGLISH_WRITING',
  EnglishWhiteboard = 'ENGLISH_WHITEBOARD',
  InvestingWriting = 'INVESTING_WRITING',
  InvestingWhiteboard = 'INVESTING_WHITEBOARD',
  Js = 'JS'
}

export type CustomProject = {
  __typename?: 'CustomProject';
  _id: Scalars['ObjectId'];
  moduleId?: Maybe<Scalars['ObjectId']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorId?: Maybe<Scalars['ObjectId']>;
  displayName?: Maybe<Scalars['String']>;
  projectType?: Maybe<ProjectType>;
};

export enum StudentProjectCreatorType {
  Student = 'STUDENT',
  Instructor = 'INSTRUCTOR'
}

export type StudentProjectCreator = {
  __typename?: 'StudentProjectCreator';
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type: StudentProjectCreatorType;
  isGuest?: Maybe<Scalars['Boolean']>;
};

export type StudentProject = {
  __typename?: 'StudentProject';
  _id: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  student?: Maybe<Student>;
  teacherId?: Maybe<Scalars['ObjectId']>;
  instructor?: Maybe<Instructor>;
  courseId?: Maybe<Scalars['String']>;
  course?: Maybe<ICourse>;
  moduleId?: Maybe<Scalars['String']>;
  module?: Maybe<Module>;
  projectId?: Maybe<Scalars['ObjectId']>;
  moduleSection?: Maybe<ModuleSection>;
  customProject?: Maybe<CustomProject>;
  featuredProjectId?: Maybe<Scalars['ObjectId']>;
  featuredProject?: Maybe<StudentProject>;
  originalProjectId?: Maybe<Scalars['ObjectId']>;
  originalProject?: Maybe<StudentProject>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  juniverseTags?: Maybe<Array<Scalars['String']>>;
  attachedUsermedia?: Maybe<Array<Scalars['String']>>;
  attachedUsermediaUrls?: Maybe<Array<Scalars['String']>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isCustomProject?: Maybe<Scalars['Boolean']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<StudentProjectCreator>;
  projectType?: Maybe<ProjectType>;
  viewCount?: Maybe<Scalars['Int']>;
  isCodeHidden?: Maybe<Scalars['Boolean']>;
};

export enum StudentProjectFeaturedType {
  Juniverse = 'JUNIVERSE',
  MyFeed = 'MY_FEED'
}

/** Auto-generated payload object for the featuredStudentProjects query */
export type FeaturedStudentProjectsPayload = {
  __typename?: 'FeaturedStudentProjectsPayload';
  items: Array<Maybe<StudentProject>>;
};

/** Query for student projects */
export type StudentProjectQuery = {
  /** Free-form text query to filter the search results by */
  searchQuery?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  creatorType?: Maybe<Scalars['String']>;
  projectType?: Maybe<ProjectType>;
  isPlayground?: Maybe<Scalars['Boolean']>;
  hasVideo?: Maybe<Scalars['Boolean']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the studentProjects query */
export type StudentProjectsPayload = {
  __typename?: 'StudentProjectsPayload';
  items: Array<Maybe<StudentProject>>;
  pageInfo: PageInfo;
};

/** Update data for student project */
export type StudentProjectUpdateData = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  juniverseTags?: Maybe<Array<Scalars['String']>>;
  attachedUsermedia?: Maybe<Array<Scalars['String']>>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the updateStudentProject mutation */
export type UpdateStudentProjectInput = {
  id: Scalars['ObjectId'];
  data: StudentProjectUpdateData;
};

/** Auto-generated payload object for the updateStudentProject mutation */
export type UpdateStudentProjectPayload = {
  __typename?: 'UpdateStudentProjectPayload';
  project?: Maybe<StudentProject>;
};

/** Auto-generated input object for the featureStudentProject mutation */
export type FeatureStudentProjectInput = {
  originalProjectId: Scalars['ObjectId'];
  newData: StudentProjectUpdateData;
};

/** Auto-generated payload object for the featureStudentProject mutation */
export type FeatureStudentProjectPayload = {
  __typename?: 'FeatureStudentProjectPayload';
  featuredProject?: Maybe<StudentProject>;
};

/** Auto-generated input object for the unfeatureStudentProject mutation */
export type UnfeatureStudentProjectInput = {
  originalProjectId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the unfeatureStudentProject mutation */
export type UnfeatureStudentProjectPayload = {
  __typename?: 'UnfeatureStudentProjectPayload';
  originalProject?: Maybe<StudentProject>;
};

export type OpenChallenge = {
  __typename?: 'OpenChallenge';
  _id?: Maybe<Scalars['ObjectId']>;
  title: Scalars['String'];
  description: Scalars['String'];
  openDate: Scalars['DateTime'];
  registrationDeadline: Scalars['DateTime'];
  submissionDeadline: Scalars['DateTime'];
  thumbnailUrl: Scalars['String'];
  registrationLink: Scalars['String'];
  resultsLink: Scalars['String'];
};

/** Auto-generated payload object for the openChallenges query */
export type OpenChallengesPayload = {
  __typename?: 'OpenChallengesPayload';
  items: Array<OpenChallenge>;
};

export type OpenChallengeData = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  openDate?: Maybe<Scalars['DateTime']>;
  registrationDeadline?: Maybe<Scalars['DateTime']>;
  submissionDeadline?: Maybe<Scalars['DateTime']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  registrationLink?: Maybe<Scalars['String']>;
  resultsLink?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the createOpenChallenge mutation */
export type CreateOpenChallengeInput = {
  data: OpenChallengeData;
};

/** Auto-generated payload object for the createOpenChallenge mutation */
export type CreateOpenChallengePayload = {
  __typename?: 'CreateOpenChallengePayload';
  openChallenge?: Maybe<OpenChallenge>;
};

/** Auto-generated input object for the updateOpenChallenge mutation */
export type UpdateOpenChallengeInput = {
  id: Scalars['ObjectId'];
  data: OpenChallengeData;
};

/** Auto-generated payload object for the updateOpenChallenge mutation */
export type UpdateOpenChallengePayload = {
  __typename?: 'UpdateOpenChallengePayload';
  openChallenge?: Maybe<OpenChallenge>;
};

/** Auto-generated input object for the deleteOpenChallenge mutation */
export type DeleteOpenChallengeInput = {
  id: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteOpenChallenge mutation */
export type DeleteOpenChallengePayload = {
  __typename?: 'DeleteOpenChallengePayload';
  openChallenge?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the publishFeaturedProjectsToJuniverse mutation */
export type PublishFeaturedProjectsToJuniverseInput = {
  type?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the publishFeaturedProjectsToJuniverse mutation */
export type PublishFeaturedProjectsToJuniversePayload = {
  __typename?: 'PublishFeaturedProjectsToJuniversePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type JuniverseUserProfile = {
  __typename?: 'JuniverseUserProfile';
  _id: Scalars['ObjectId'];
  type: StudentProjectCreatorType;
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastInitial?: Maybe<Scalars['String']>;
  publicProjects: Array<StudentProject>;
  featuredProjects: Array<StudentProject>;
  courseProgresses: Array<StudentCourseProgress>;
  achievements?: Maybe<Array<InstructorAchievement>>;
  isGuest?: Maybe<Scalars['Boolean']>;
  projectViewsAllTime?: Maybe<Scalars['Int']>;
  projectsPublishedAllTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated input object for the recordProjectView mutation */
export type RecordProjectViewInput = {
  projectId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the recordProjectView mutation */
export type RecordProjectViewPayload = {
  __typename?: 'RecordProjectViewPayload';
  wasRecorded?: Maybe<Scalars['Boolean']>;
};

export type UpdateProjectShareSettingsQueryParamsInputType = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  courseId: Scalars['String'];
  moduleId: Scalars['String'];
  projectId: Scalars['String'];
  playgroundProjectType?: Maybe<Scalars['String']>;
  isCustomProject?: Maybe<Scalars['Boolean']>;
};

export type UpdateProjectShareSettingsUpdateParamsInputType = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  attachedUsermedia?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<Scalars['String']>;
};

export type UpdateProjectShareSettingsStudentProjectReturnType = {
  __typename?: 'UpdateProjectShareSettingsStudentProjectReturnType';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  attachedUsermedia?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<Scalars['String']>;
};

export enum UsermediaStatusEnumType {
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  Processing = 'processing',
  Processed = 'processed',
  ProcessingError = 'processingError',
  ToDelete = 'toDelete'
}

export type UpdateProjectShareSettingsUsermediaReturnType = {
  __typename?: 'UpdateProjectShareSettingsUsermediaReturnType';
  _id?: Maybe<Scalars['ObjectId']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<UsermediaStatusEnumType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  originalLength?: Maybe<Scalars['Int']>;
  s3Url?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the updateProjectShareSettings mutation */
export type UpdateProjectShareSettingsInput = {
  queryParams: UpdateProjectShareSettingsQueryParamsInputType;
  updateParams?: Maybe<UpdateProjectShareSettingsUpdateParamsInputType>;
};

/** Auto-generated payload object for the updateProjectShareSettings mutation */
export type UpdateProjectShareSettingsPayload = {
  __typename?: 'UpdateProjectShareSettingsPayload';
  studentProject?: Maybe<UpdateProjectShareSettingsStudentProjectReturnType>;
  usermedia?: Maybe<Array<UpdateProjectShareSettingsUsermediaReturnType>>;
  projectPageLink?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the sendSlackMessageToInstructor mutation */
export type SendSlackMessageToInstructorInput = {
  instructorId: Scalars['ObjectId'];
  message: Scalars['String'];
};

/** Auto-generated payload object for the sendSlackMessageToInstructor mutation */
export type SendSlackMessageToInstructorPayload = {
  __typename?: 'SendSlackMessageToInstructorPayload';
  sent?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the slackUserIdForInstructor mutation */
export type SlackUserIdForInstructorInput = {
  instructorId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the slackUserIdForInstructor mutation */
export type SlackUserIdForInstructorPayload = {
  __typename?: 'SlackUserIdForInstructorPayload';
  slackUserId?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the updateUserSmsConsent mutation */
export type UpdateUserSmsConsentInput = {
  consented: Scalars['Boolean'];
};

/** Auto-generated payload object for the updateUserSmsConsent mutation */
export type UpdateUserSmsConsentPayload = {
  __typename?: 'UpdateUserSmsConsentPayload';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the sendSmsMessageToParent mutation */
export type SendSmsMessageToParentInput = {
  parentId: Scalars['ObjectId'];
  message: Scalars['String'];
  ignoreSmsConsent?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the sendSmsMessageToParent mutation */
export type SendSmsMessageToParentPayload = {
  __typename?: 'SendSmsMessageToParentPayload';
  sent?: Maybe<Scalars['Boolean']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  created: Scalars['Int'];
  default_source?: Maybe<Scalars['String']>;
  delinquent: Scalars['Boolean'];
};

export type StripeCard = {
  __typename?: 'StripeCard';
  last4: Scalars['String'];
  network: Scalars['String'];
  brand: Scalars['String'];
};

export type StripePaymentMethodDetails = {
  __typename?: 'StripePaymentMethodDetails';
  card?: Maybe<StripeCard>;
};

export type StripeCharge = {
  __typename?: 'StripeCharge';
  id: Scalars['String'];
  amount: Scalars['Int'];
  amount_refunded?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  created: Scalars['Int'];
  currency: Scalars['String'];
  payment_method_details: StripePaymentMethodDetails;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['String'];
  object: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['String']>;
  exp_year?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionMetadata = {
  __typename?: 'StripeSubscriptionMetadata';
  studentIds?: Maybe<Scalars['String']>;
  cs_weeklyFrequency?: Maybe<Scalars['String']>;
  math_weeklyFrequency?: Maybe<Scalars['String']>;
  english_weeklyFrequency?: Maybe<Scalars['String']>;
  investing_weeklyFrequency?: Maybe<Scalars['String']>;
  science_weeklyFrequency?: Maybe<Scalars['String']>;
  usaco_weeklyFrequency?: Maybe<Scalars['String']>;
  juniJr_weeklyFrequency?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type StripePlan = {
  __typename?: 'StripePlan';
  id: Scalars['String'];
  object: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  amount_decimal?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  id: Scalars['String'];
  object: Scalars['String'];
  product: Scalars['String'];
  unit_amount?: Maybe<Scalars['Int']>;
  unit_amount_decimal?: Maybe<Scalars['Int']>;
};

export type StripeCoupon = {
  __typename?: 'StripeCoupon';
  id: Scalars['String'];
  object: Scalars['String'];
  amount_off?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  percent_off?: Maybe<Scalars['Int']>;
  percent_off_precise?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type StripeDiscount = {
  __typename?: 'StripeDiscount';
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  coupon?: Maybe<StripeCoupon>;
};

export type StripeSubscriptionItem = {
  __typename?: 'StripeSubscriptionItem';
  id: Scalars['String'];
  plan: StripePlan;
  price: StripePrice;
};

export type StripeSubscriptionItems = {
  __typename?: 'StripeSubscriptionItems';
  data: Array<StripeSubscriptionItem>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['String'];
  object: Scalars['String'];
  customer: Scalars['String'];
  metadata?: Maybe<StripeSubscriptionMetadata>;
  status: Scalars['String'];
  items: StripeSubscriptionItems;
  current_period_start: Scalars['Int'];
  current_period_end: Scalars['Int'];
  canceled_at?: Maybe<Scalars['Int']>;
  plan?: Maybe<StripePlan>;
  discount?: Maybe<StripeDiscount>;
};

/** Auto-generated payload object for the myUnpaidStripeCharges query */
export type MyUnpaidStripeChargesPayload = {
  __typename?: 'MyUnpaidStripeChargesPayload';
  items: Array<StripeCharge>;
};

/** Auto-generated payload object for the paymentHistoryByParentId query */
export type PaymentHistoryByParentIdPayload = {
  __typename?: 'PaymentHistoryByParentIdPayload';
  items: Array<StripeCharge>;
};

/** Auto-generated payload object for the stripeSubscriptionsByParentId query */
export type StripeSubscriptionsByParentIdPayload = {
  __typename?: 'StripeSubscriptionsByParentIdPayload';
  items: Array<StripeSubscription>;
};

/** Auto-generated payload object for the unpaidStripeChargesByStripeCustomerId query */
export type UnpaidStripeChargesByStripeCustomerIdPayload = {
  __typename?: 'UnpaidStripeChargesByStripeCustomerIdPayload';
  items: Array<StripeCharge>;
};

export type StripeProductScheduleFrequency = {
  __typename?: 'StripeProductScheduleFrequency';
  appointmentTypeIDs?: Maybe<Array<Scalars['Int']>>;
  weeklyFrequency: Scalars['Int'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  name: Scalars['String'];
  format: Scalars['String'];
  stripeId: Scalars['String'];
  scheduleFrequency: Array<StripeProductScheduleFrequency>;
};

/** Auto-generated payload object for the productsByStripeIds query */
export type ProductsByStripeIdsPayload = {
  __typename?: 'ProductsByStripeIdsPayload';
  items: Array<StripeProduct>;
};

export type AvailabilityInput = {
  day: Scalars['String'];
  availabilities: Array<Scalars['String']>;
  selectedAvailability: Scalars['String'];
  time: Scalars['String'];
};

export type MetadataDetail = {
  frequency: Scalars['Float'];
  courses: Array<Scalars['String']>;
  availabilities?: Maybe<Array<Scalars['String']>>;
};

export type MetadataInput = {
  cs_weeklyFrequency?: Maybe<MetadataDetail>;
  english_weeklyFrequency?: Maybe<MetadataDetail>;
  investing_weeklyFrequency?: Maybe<MetadataDetail>;
  juniJr_weeklyFrequency?: Maybe<MetadataDetail>;
  math_weeklyFrequency?: Maybe<MetadataDetail>;
  usaco_weeklyFrequency?: Maybe<MetadataDetail>;
  science_weeklyFrequency?: Maybe<MetadataDetail>;
};

/** Auto-generated input object for the createStripeSubscription mutation */
export type CreateStripeSubscriptionInput = {
  studentId: Scalars['String'];
  metadataNew: MetadataInput;
  subscriptionUpdateType: Scalars['String'];
  addAsync: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  startingDate?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['String']>;
  managingUserId?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createStripeSubscription mutation */
export type CreateStripeSubscriptionPayload = {
  __typename?: 'CreateStripeSubscriptionPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the cancelStripeSubscription mutation */
export type CancelStripeSubscriptionInput = {
  subscriptionId: Scalars['String'];
  cancellationReasons?: Maybe<Array<Scalars['String']>>;
  otherNotes?: Maybe<Scalars['String']>;
  additionalNotes?: Maybe<Scalars['String']>;
  followupDate?: Maybe<Scalars['String']>;
  finalSessionDatetime?: Maybe<Scalars['DateTime']>;
  finalSessionTimezone?: Maybe<Scalars['String']>;
  customerLogSubtype?: Maybe<Scalars['String']>;
  adminLogNotes?: Maybe<Array<Scalars['String']>>;
};

/** Auto-generated payload object for the cancelStripeSubscription mutation */
export type CancelStripeSubscriptionPayload = {
  __typename?: 'CancelStripeSubscriptionPayload';
  success: Scalars['Boolean'];
};

export type ClassFrequency = {
  subject?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
};

/** Auto-generated input object for the downgradeStripeSubscription mutation */
export type DowngradeStripeSubscriptionInput = {
  subscriptionId: Scalars['String'];
  downgradeOption?: Maybe<Scalars['String']>;
  coursesToRemove?: Maybe<Array<Scalars['String']>>;
  classFrequenciesToReduce?: Maybe<Array<ClassFrequency>>;
  effectiveDate?: Maybe<Scalars['String']>;
  additionalNotes?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the downgradeStripeSubscription mutation */
export type DowngradeStripeSubscriptionPayload = {
  __typename?: 'DowngradeStripeSubscriptionPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the purchaseBootcamp mutation */
export type PurchaseBootcampInput = {
  studentId: Scalars['ObjectId'];
  courseOfferingId: Scalars['ObjectId'];
  couponId?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the purchaseBootcamp mutation */
export type PurchaseBootcampPayload = {
  __typename?: 'PurchaseBootcampPayload';
  success: Scalars['Boolean'];
};

export enum GenderEnum {
  Female = 'female',
  Male = 'male',
  Other = 'other'
}

export enum CertificateEnum {
  Course = 'COURSE',
  Bundle = 'BUNDLE'
}

export type CertificationTracksInput = {
  name: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the updateStudent mutation */
export type UpdateStudentInput = {
  _id: Scalars['ObjectId'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  track?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  liveClassNotes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  hasMultipleTracks?: Maybe<Scalars['Boolean']>;
  receivesSessionNotes?: Maybe<Scalars['Boolean']>;
  isJuniExplorer?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<GenderEnum>;
  isBannedFromClubs?: Maybe<Scalars['Boolean']>;
  tracks?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedTracks?: Maybe<Array<Maybe<Scalars['String']>>>;
  certificationTracks?: Maybe<Array<Maybe<CertificationTracksInput>>>;
};

/** Auto-generated payload object for the updateStudent mutation */
export type UpdateStudentPayload = {
  __typename?: 'UpdateStudentPayload';
  student?: Maybe<Student>;
};

export type Availability = {
  __typename?: 'Availability';
  _id: Scalars['ObjectId'];
  studentId: Scalars['String'];
  availability?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the allStudentCompletedCourseParams query */
export type AllStudentCompletedCourseParamsPayload = {
  __typename?: 'AllStudentCompletedCourseParamsPayload';
  items: Array<AllStudentCompletedCourseParams>;
};

/** Auto-generated payload object for the studentCertificateParams query */
export type StudentCertificateParamsPayload = {
  __typename?: 'StudentCertificateParamsPayload';
  items: Array<StudentCertificateParams>;
};

/** Auto-generated payload object for the studentsByParentIds query */
export type StudentsByParentIdsPayload = {
  __typename?: 'StudentsByParentIdsPayload';
  items: Array<Student>;
};

/** Auto-generated payload object for the availabilitiesByStudentIds query */
export type AvailabilitiesByStudentIdsPayload = {
  __typename?: 'AvailabilitiesByStudentIdsPayload';
  items: Array<Availability>;
};

export type StudentCompletedCourseParams = {
  __typename?: 'StudentCompletedCourseParams';
  params: Scalars['String'];
};

export type AllStudentCompletedCourseParams = {
  __typename?: 'AllStudentCompletedCourseParams';
  courseName: Scalars['String'];
  params: Scalars['String'];
};

export type StudentCertificateParams = {
  __typename?: 'StudentCertificateParams';
  courseName?: Maybe<Scalars['String']>;
  bundleName?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['String']>;
  certificateName?: Maybe<Scalars['String']>;
  certificateType?: Maybe<Scalars['String']>;
};

export type StudentCompletedBundleData = {
  __typename?: 'StudentCompletedBundleData';
  studentId: Scalars['ObjectId'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  bundleName: Scalars['String'];
  bundleDisplayName?: Maybe<Scalars['String']>;
  bundleCategory?: Maybe<Scalars['String']>;
  bundleCategoryBadge?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['String']>;
};

export type StudentCompletedCourseData = {
  __typename?: 'StudentCompletedCourseData';
  studentId: Scalars['ObjectId'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  courseId: Scalars['String'];
  courseName: Scalars['String'];
  courseDisplayName: Scalars['String'];
  courseSubject: Scalars['String'];
  masterProjectModuleId?: Maybe<Scalars['String']>;
  masterProjectProjectId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['String']>;
  referralCode: Scalars['String'];
  parentFirstName: Scalars['String'];
  isStudentParentAccount: Scalars['Boolean'];
  encryptedStudentAndProject?: Maybe<Scalars['String']>;
};

export enum UploadDestination {
  StudentProjectThumbnails = 'STUDENT_PROJECT_THUMBNAILS',
  OpenChallengeThumbnails = 'OPEN_CHALLENGE_THUMBNAILS',
  InstructorAchievementThumbnails = 'INSTRUCTOR_ACHIEVEMENT_THUMBNAILS',
  WhiteboardUserImages = 'WHITEBOARD_USER_IMAGES',
  ClubUserImages = 'CLUB_USER_IMAGES',
  JuniAdImages = 'JUNI_AD_IMAGES',
  CourseHeaderImages = 'COURSE_HEADER_IMAGES'
}

/** Auto-generated input object for the requestUploadUrl mutation */
export type RequestUploadUrlInput = {
  filename: Scalars['String'];
  destination: UploadDestination;
};

/** Auto-generated payload object for the requestUploadUrl mutation */
export type RequestUploadUrlPayload = {
  __typename?: 'RequestUploadUrlPayload';
  uploadUrl: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
};

export type AvailabilityByDay = {
  __typename?: 'AvailabilityByDay';
  monday?: Maybe<Scalars['String']>;
  tuesday?: Maybe<Scalars['String']>;
  wednesday?: Maybe<Scalars['String']>;
  thursday?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['String']>;
  saturday?: Maybe<Scalars['String']>;
  sunday?: Maybe<Scalars['String']>;
};

export type AvailabilityByDayInput = {
  monday?: Maybe<Scalars['String']>;
  tuesday?: Maybe<Scalars['String']>;
  wednesday?: Maybe<Scalars['String']>;
  thursday?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['String']>;
  saturday?: Maybe<Scalars['String']>;
  sunday?: Maybe<Scalars['String']>;
};

export type RecurringAvailabilityUpdateRequest = {
  __typename?: 'RecurringAvailabilityUpdateRequest';
  _id: Scalars['ObjectId'];
  instructorId: Scalars['ObjectId'];
  dateEffective: Scalars['DateTime'];
  reason: Scalars['String'];
  availabilityByDay: AvailabilityByDay;
  additionalNotes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** closedTimestamp of the associated InstructorLog */
  requestCompletedAt?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated input object for the createRecurringAvailabilityUpdateRequest mutation */
export type CreateRecurringAvailabilityUpdateRequestInput = {
  dateEffective: Scalars['DateTime'];
  reason: Scalars['String'];
  availabilityByDay: AvailabilityByDayInput;
  additionalNotes?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createRecurringAvailabilityUpdateRequest mutation */
export type CreateRecurringAvailabilityUpdateRequestPayload = {
  __typename?: 'CreateRecurringAvailabilityUpdateRequestPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated payload object for the myRecurringAvailabilityUpdateRequests query */
export type MyRecurringAvailabilityUpdateRequestsPayload = {
  __typename?: 'MyRecurringAvailabilityUpdateRequestsPayload';
  items: Array<RecurringAvailabilityUpdateRequest>;
};

export type RecurringRescheduleRequest = {
  __typename?: 'RecurringRescheduleRequest';
  _id: Scalars['ObjectId'];
  instructorId: Scalars['ObjectId'];
  parentId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  createdByUserId: Scalars['ObjectId'];
  ownerUserId?: Maybe<Scalars['ObjectId']>;
  /** The first name of the associated student */
  studentFirstName?: Maybe<Scalars['String']>;
  /** The full name of the associated instructor */
  instructorFullName?: Maybe<Scalars['String']>;
  /** The associated parent */
  parent?: Maybe<Parent>;
  reason?: Maybe<Scalars['String']>;
  additionalNotesFromInstructor?: Maybe<Scalars['String']>;
  newRecurringScheduleDetailsPt?: Maybe<Scalars['String']>;
  hasDiscussedNewTimeWithParent: Scalars['Boolean'];
  lastTeachableSessionAcuityApptId: Scalars['Int'];
  lastTeachableSessionAcuityCalendarId: Scalars['Int'];
  lastTeachableSessionApptTypeId: Scalars['Int'];
  lastTeachableSessionDatetime: Scalars['DateTime'];
  internalNotes?: Maybe<Scalars['String']>;
  isAcuityUpdated: Scalars['Boolean'];
  emailSentAt?: Maybe<Scalars['DateTime']>;
  requestCompletedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dueDate?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated input object for the createMyRecurringRescheduleRequest mutation */
export type CreateMyRecurringRescheduleRequestInput = {
  lastTeachableSessionAcuityApptId: Scalars['Int'];
  lastTeachableSessionDatetime: Scalars['String'];
  reason: Scalars['String'];
  hasDiscussedNewTimeWithParent: Scalars['Boolean'];
  additionalNotesFromInstructor?: Maybe<Scalars['String']>;
  newRecurringScheduleDetailsPt?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createMyRecurringRescheduleRequest mutation */
export type CreateMyRecurringRescheduleRequestPayload = {
  __typename?: 'CreateMyRecurringRescheduleRequestPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the createRecurringRescheduleRequest mutation */
export type CreateRecurringRescheduleRequestInput = {
  instructorAcuityCalendarId: Scalars['Int'];
  lastTeachableSessionAcuityApptId: Scalars['Int'];
  lastTeachableSessionDatetime: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  hasDiscussedNewTimeWithParent: Scalars['Boolean'];
  newRecurringScheduleDetailsPt?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createRecurringRescheduleRequest mutation */
export type CreateRecurringRescheduleRequestPayload = {
  __typename?: 'CreateRecurringRescheduleRequestPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the updateRecurringRescheduleRequest mutation */
export type UpdateRecurringRescheduleRequestInput = {
  id: Scalars['ObjectId'];
  lastTeachableSessionAcuityApptId?: Maybe<Scalars['Int']>;
  lastTeachableSessionDatetime?: Maybe<Scalars['String']>;
  additionalNotesFromInstructor?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  hasDiscussedNewTimeWithParent?: Maybe<Scalars['Boolean']>;
  newRecurringScheduleDetailsPt?: Maybe<Scalars['String']>;
  isAcuityUpdated?: Maybe<Scalars['Boolean']>;
  emailSent?: Maybe<Scalars['Boolean']>;
  requestCompleted?: Maybe<Scalars['Boolean']>;
  ownerUserId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the updateRecurringRescheduleRequest mutation */
export type UpdateRecurringRescheduleRequestPayload = {
  __typename?: 'UpdateRecurringRescheduleRequestPayload';
  success: Scalars['Boolean'];
};

export type UpdateMyRecurringRescheduleRequestInput = {
  id: Scalars['ObjectId'];
  newRecurringScheduleDetailsPt: Scalars['String'];
};

export type UpdateMyRecurringRescheduleRequestPayload = {
  __typename?: 'UpdateMyRecurringRescheduleRequestPayload';
  recurringRescheduleRequest: RecurringRescheduleRequest;
};

/** Auto-generated payload object for the myRecurringRescheduleRequests query */
export type MyRecurringRescheduleRequestsPayload = {
  __typename?: 'MyRecurringRescheduleRequestsPayload';
  items: Array<RecurringRescheduleRequest>;
};

/** Auto-generated payload object for the recurringRescheduleRequests query */
export type RecurringRescheduleRequestsPayload = {
  __typename?: 'RecurringRescheduleRequestsPayload';
  items: Array<RecurringRescheduleRequest>;
};

/** Auto-generated payload object for the upcomingClassAppointmentsForInstructor query */
export type UpcomingClassAppointmentsForInstructorPayload = {
  __typename?: 'UpcomingClassAppointmentsForInstructorPayload';
  items: Array<AcuityAppointment>;
};

export type RecurringScheduleAppointments = {
  __typename?: 'RecurringScheduleAppointments';
  past: Array<AcuityAppointment>;
  current: Array<AcuityAppointment>;
  upcoming: Array<AcuityAppointment>;
};

export type InstructorRecurringSchedule = {
  __typename?: 'InstructorRecurringSchedule';
  parentId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  /** The associated parent */
  parent: Parent;
  /** The associated student */
  student?: Maybe<Student>;
  numSessionsCompletedTogether: Scalars['Int'];
  appointments: RecurringScheduleAppointments;
};

/** Auto-generated payload object for the recurringSchedulesForInstructor query */
export type RecurringSchedulesForInstructorPayload = {
  __typename?: 'RecurringSchedulesForInstructorPayload';
  items: Array<InstructorRecurringSchedule>;
};

export enum InstructorUnavailablePreference {
  NewInstructor = 'new_instructor',
  ExploreOtherTimes = 'explore_other_times'
}

export type CreateParentRecurringRescheduleRequestInput = {
  acuityAppointmentId: Scalars['String'];
  proposedDatetime?: Maybe<Scalars['String']>;
  proposedCustomDatetimes?: Maybe<Array<Scalars['String']>>;
  instructorUnavailablePreference: InstructorUnavailablePreference;
};

export type CreateParentRecurringRescheduleRequestPayload = {
  __typename?: 'CreateParentRecurringRescheduleRequestPayload';
  success: Scalars['Boolean'];
};

export type InstructorOnboardingTask = {
  __typename?: 'InstructorOnboardingTask';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  isRequired: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  acuityApptId?: Maybe<Scalars['Int']>;
  acuityApptDatetime?: Maybe<Scalars['DateTime']>;
  acuityApptCalendarId?: Maybe<Scalars['Int']>;
};

export type InstructorOnboarding = {
  __typename?: 'InstructorOnboarding';
  _id: Scalars['ObjectId'];
  instructorId: Scalars['ObjectId'];
  instructorLogId?: Maybe<Scalars['ObjectId']>;
  status: Scalars['String'];
  subject: Scalars['String'];
  referringInstructorId?: Maybe<Scalars['ObjectId']>;
  referralPayoutDate?: Maybe<Scalars['DateTime']>;
  referralPayoutDataEntered?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  idealStartDate?: Maybe<Scalars['DateTime']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  firstClassCourseId?: Maybe<Scalars['ObjectId']>;
  firstClassStudentId?: Maybe<Scalars['ObjectId']>;
  notes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  tasks: Array<InstructorOnboardingTask>;
};

export type InstructorOnboardingQueryInput = {
  instructorId?: Maybe<Scalars['ObjectId']>;
  statuses?: Maybe<Array<Scalars['String']>>;
  subject?: Maybe<Scalars['String']>;
  onboardingSessionDate?: Maybe<Scalars['String']>;
  instructorTrainingDate?: Maybe<Scalars['String']>;
  preClassRehearsalDate?: Maybe<Scalars['String']>;
  firstClassShadowDate?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the instructorOnboardings query */
export type InstructorOnboardingsPayload = {
  __typename?: 'InstructorOnboardingsPayload';
  items: Array<Maybe<InstructorOnboarding>>;
};

/** Auto-generated input object for the createInstructorOnboarding mutation */
export type CreateInstructorOnboardingInput = {
  instructorId: Scalars['ObjectId'];
  instructorLogId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the createInstructorOnboarding mutation */
export type CreateInstructorOnboardingPayload = {
  __typename?: 'CreateInstructorOnboardingPayload';
  instructorOnboarding: InstructorOnboarding;
};

/** Auto-generated input object for the updateInstructorOnboarding mutation */
export type UpdateInstructorOnboardingInput = {
  id: Scalars['ObjectId'];
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  referringInstructorId?: Maybe<Scalars['ObjectId']>;
  referralPayoutDate?: Maybe<Scalars['DateTime']>;
  referralPayoutDataEntered?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  idealStartDate?: Maybe<Scalars['DateTime']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  firstClassCourseId?: Maybe<Scalars['ObjectId']>;
  firstClassStudentId?: Maybe<Scalars['ObjectId']>;
  notes?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateInstructorOnboarding mutation */
export type UpdateInstructorOnboardingPayload = {
  __typename?: 'UpdateInstructorOnboardingPayload';
  instructorOnboarding: InstructorOnboarding;
};

/** Auto-generated input object for the updateInstructorOnboardingTask mutation */
export type UpdateInstructorOnboardingTaskInput = {
  onboardingId: Scalars['ObjectId'];
  taskId: Scalars['ObjectId'];
  completed?: Maybe<Scalars['Boolean']>;
  acuityApptId?: Maybe<Scalars['Int']>;
  acuityApptDatetime?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated payload object for the updateInstructorOnboardingTask mutation */
export type UpdateInstructorOnboardingTaskPayload = {
  __typename?: 'UpdateInstructorOnboardingTaskPayload';
  instructorOnboarding: InstructorOnboarding;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  id: Scalars['String'];
  name: Scalars['String'];
  calendarIDs: Array<Scalars['String']>;
};

/** Auto-generated payload object for the appointmentTypes query */
export type AppointmentTypesPayload = {
  __typename?: 'AppointmentTypesPayload';
  items: Array<AppointmentType>;
};

/** Auto-generated input object for the scheduleInstructorOnboardingAppt mutation */
export type ScheduleInstructorOnboardingApptInput = {
  instructorId: Scalars['ObjectId'];
  appointmentTypeID: Scalars['Int'];
  calendarID: Scalars['Int'];
  datetime: Scalars['DateTime'];
  timezone: Scalars['String'];
  onboardingId: Scalars['ObjectId'];
  taskId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the scheduleInstructorOnboardingAppt mutation */
export type ScheduleInstructorOnboardingApptPayload = {
  __typename?: 'ScheduleInstructorOnboardingApptPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the rescheduleInstructorOnboardingAppt mutation */
export type RescheduleInstructorOnboardingApptInput = {
  appointmentId: Scalars['Int'];
  calendarID: Scalars['Int'];
  datetime: Scalars['DateTime'];
  timezone: Scalars['String'];
  onboardingId: Scalars['ObjectId'];
  taskId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the rescheduleInstructorOnboardingAppt mutation */
export type RescheduleInstructorOnboardingApptPayload = {
  __typename?: 'RescheduleInstructorOnboardingApptPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the cancelInstructorOnboardingAppt mutation */
export type CancelInstructorOnboardingApptInput = {
  appointmentId: Scalars['Int'];
  onboardingId: Scalars['ObjectId'];
  taskId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the cancelInstructorOnboardingAppt mutation */
export type CancelInstructorOnboardingApptPayload = {
  __typename?: 'CancelInstructorOnboardingApptPayload';
  success: Scalars['Boolean'];
};

export type DashboardSessionInfo = {
  __typename?: 'DashboardSessionInfo';
  id: Scalars['ObjectId'];
  teacherSessionId: Scalars['ObjectId'];
  sessionTime: Scalars['String'];
  notes: Scalars['String'];
  originalNotes?: Maybe<Scalars['String']>;
  usesMarkdown?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  wasMissed: Scalars['Boolean'];
  noteNeedsMinorRevision?: Maybe<Scalars['Boolean']>;
  noteNeedsMajorRevision?: Maybe<Scalars['Boolean']>;
  instructorNeedsAdditionalSupport?: Maybe<Scalars['Boolean']>;
  appointmentTypeID?: Maybe<Scalars['Int']>;
  studentFirstName: Scalars['String'];
  studentLastName?: Maybe<Scalars['String']>;
  studentReceivesSessionNotes?: Maybe<Scalars['Boolean']>;
  studentEmail?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  parentFirstName?: Maybe<Scalars['String']>;
  parentEmail: Scalars['String'];
  teacherFirstName?: Maybe<Scalars['String']>;
  teacherLastName?: Maybe<Scalars['String']>;
  currCycleWeek: Scalars['Int'];
  sent?: Maybe<Scalars['Boolean']>;
  isParentDigest?: Maybe<Scalars['Boolean']>;
};

export type DashboardPreviewEmail = {
  __typename?: 'DashboardPreviewEmail';
  html: Scalars['String'];
};

/** Auto-generated input object for the getPostSessionDashboardEmailHtml mutation */
export type GetPostSessionDashboardEmailHtmlInput = {
  teacherSessionId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the getPostSessionDashboardEmailHtml mutation */
export type GetPostSessionDashboardEmailHtmlPayload = {
  __typename?: 'GetPostSessionDashboardEmailHtmlPayload';
  dashboardPreviewEmail: DashboardPreviewEmail;
};

/** Auto-generated payload object for the unsentDashboardSessionInfos query */
export type UnsentDashboardSessionInfosPayload = {
  __typename?: 'UnsentDashboardSessionInfosPayload';
  items: Array<DashboardSessionInfo>;
};

/** Auto-generated input object for the updateTeacherSessionForPostSessionEmail mutation */
export type UpdateTeacherSessionForPostSessionEmailInput = {
  id: Scalars['ObjectId'];
  notes?: Maybe<Scalars['String']>;
  noteNeedsMinorRevision?: Maybe<Scalars['Boolean']>;
  noteNeedsMajorRevision?: Maybe<Scalars['Boolean']>;
  instructorNeedsAdditionalSupport?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the updateTeacherSessionForPostSessionEmail mutation */
export type UpdateTeacherSessionForPostSessionEmailPayload = {
  __typename?: 'UpdateTeacherSessionForPostSessionEmailPayload';
  dashboardSessionInfo: DashboardSessionInfo;
};

/** Auto-generated input object for the sendPostSessionEmail mutation */
export type SendPostSessionEmailInput = {
  teacherSessionId: Scalars['ObjectId'];
  html: Scalars['String'];
};

/** Auto-generated payload object for the sendPostSessionEmail mutation */
export type SendPostSessionEmailPayload = {
  __typename?: 'SendPostSessionEmailPayload';
  dashboardSessionInfo: DashboardSessionInfo;
};

export type InstructorAchievement = {
  __typename?: 'InstructorAchievement';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  /** Badge description visibile to instructors/parent/hq */
  externalDescription: Scalars['String'];
  /** Describes requirements/guidelines on granting the badge */
  internalDescription: Scalars['String'];
  /** Determines if badge is viewable by parents */
  external?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the instructorAchievements query */
export type InstructorAchievementsPayload = {
  __typename?: 'InstructorAchievementsPayload';
  items: Array<InstructorAchievement>;
};

/** Auto-generated payload object for the instructorAchievementsByInstructorId query */
export type InstructorAchievementsByInstructorIdPayload = {
  __typename?: 'InstructorAchievementsByInstructorIdPayload';
  items: Array<InstructorAchievement>;
};

export type InstructorAchievementData = {
  name?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  externalDescription?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the createInstructorAchievement mutation */
export type CreateInstructorAchievementInput = {
  data: InstructorAchievementData;
};

/** Auto-generated payload object for the createInstructorAchievement mutation */
export type CreateInstructorAchievementPayload = {
  __typename?: 'CreateInstructorAchievementPayload';
  instructorAchievement?: Maybe<InstructorAchievement>;
};

/** Auto-generated input object for the updateInstructorAchievement mutation */
export type UpdateInstructorAchievementInput = {
  id: Scalars['ObjectId'];
  data?: Maybe<InstructorAchievementData>;
};

/** Auto-generated payload object for the updateInstructorAchievement mutation */
export type UpdateInstructorAchievementPayload = {
  __typename?: 'UpdateInstructorAchievementPayload';
  instructorAchievement?: Maybe<InstructorAchievement>;
};

/** Auto-generated input object for the deleteInstructorAchievement mutation */
export type DeleteInstructorAchievementInput = {
  id: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteInstructorAchievement mutation */
export type DeleteInstructorAchievementPayload = {
  __typename?: 'DeleteInstructorAchievementPayload';
  instructorAchievement?: Maybe<InstructorAchievement>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the grantInstructorAchievement mutation */
export type GrantInstructorAchievementInput = {
  instructorId: Scalars['ObjectId'];
  achievementId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the grantInstructorAchievement mutation */
export type GrantInstructorAchievementPayload = {
  __typename?: 'GrantInstructorAchievementPayload';
  instructor?: Maybe<Instructor>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InstructorFilterInput = {
  iapLevels?: Maybe<Array<Scalars['String']>>;
  genders?: Maybe<Array<Gender>>;
  isAcceptingStudents?: Maybe<Scalars['Boolean']>;
  isAcceptingTrialStudents?: Maybe<Scalars['Boolean']>;
  isGroupInstructor?: Maybe<Scalars['Boolean']>;
  isHandoffEligible?: Maybe<Scalars['Boolean']>;
  hasSpecialNeedsExperience?: Maybe<Scalars['Boolean']>;
  isGroupTrainer?: Maybe<Scalars['Boolean']>;
  isInstrSuccessShadower?: Maybe<Scalars['Boolean']>;
  isInstructorSuccessShiftSpecialist?: Maybe<Scalars['Boolean']>;
  isInterviewScreener?: Maybe<Scalars['Boolean']>;
  isMockInterviewer?: Maybe<Scalars['Boolean']>;
  isPreClassTrainer?: Maybe<Scalars['Boolean']>;
  isSubstituteInstructor?: Maybe<Scalars['Boolean']>;
  isTeamInstructor?: Maybe<Scalars['Boolean']>;
  isTeamTrainer?: Maybe<Scalars['Boolean']>;
  isTrialInstructor?: Maybe<Scalars['Boolean']>;
  ageRanges?: Maybe<Array<AgeRange>>;
};

export type DateRangeInput = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type InstructorMatchInput = {
  instructorFilter: InstructorFilterInput;
  availabilityDuration?: Maybe<Scalars['Int']>;
  dateTimeRanges: Array<DateRangeInput>;
  courses?: Maybe<Array<Scalars['ObjectId']>>;
};

export type InstructorMatch = {
  __typename?: 'InstructorMatch';
  _id: Scalars['ObjectId'];
  username?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  ageRange5to7?: Maybe<Scalars['Int']>;
  ageRange8to10?: Maybe<Scalars['Int']>;
  ageRange11to13?: Maybe<Scalars['Int']>;
  ageRange14to16?: Maybe<Scalars['Int']>;
  ageRange17up?: Maybe<Scalars['Int']>;
  energyLevel?: Maybe<Scalars['Int']>;
  emotionalTone?: Maybe<Scalars['Int']>;
  confidence?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  courseIds: Array<Scalars['String']>;
  acuityCalendarId: Scalars['String'];
  /** Whether the instructor has self reported hours (enum of none, some, all) */
  selfReportedHours?: Maybe<Scalars['String']>;
  /** Instructor availability slots */
  availability: Array<CachedNylasBlock>;
  instructorLoad?: Maybe<Array<InstructorLoad>>;
  proposalHistory?: Maybe<StudentProposalsHistory>;
  courses: Array<InstructorQualifiedCourse>;
};

export type InstructorMatchPayload = {
  __typename?: 'InstructorMatchPayload';
  items: Array<InstructorMatch>;
};

export type SessionFeedbackPayload = {
  __typename?: 'SessionFeedbackPayload';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  feedback: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  teacherSessionId: Scalars['ObjectId'];
  notes: Scalars['String'];
  sessionTime: Scalars['String'];
  studentId: Scalars['ObjectId'];
  studentFirstName: Scalars['String'];
  studentLastName: Scalars['String'];
};

/** Auto-generated payload object for the sessionFeedbackForInstructor query */
export type SessionFeedbackForInstructorPayload = {
  __typename?: 'SessionFeedbackForInstructorPayload';
  items: Array<SessionFeedbackPayload>;
};

/** Auto-generated input object for the createSessionFeedback mutation */
export type CreateSessionFeedbackInput = {
  teacherSessionId: Scalars['ObjectId'];
  feedback: Scalars['String'];
};

/** Auto-generated payload object for the createSessionFeedback mutation */
export type CreateSessionFeedbackPayload = {
  __typename?: 'CreateSessionFeedbackPayload';
  sessionFeedback: SessionFeedbackPayload;
};

/** Auto-generated input object for the completeSessionFeedback mutation */
export type CompleteSessionFeedbackInput = {
  sessionFeedbackId: Scalars['ObjectId'];
  response?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the completeSessionFeedback mutation */
export type CompleteSessionFeedbackPayload = {
  __typename?: 'CompleteSessionFeedbackPayload';
  sessionFeedback: SessionFeedbackPayload;
};

export type JuniAdCampaignCooldown = {
  __typename?: 'JuniAdCampaignCooldown';
  duration?: Maybe<Scalars['String']>;
  timeUnit?: Maybe<Scalars['String']>;
};

export type JuniAdCampaign = {
  __typename?: 'JuniAdCampaign';
  _id: Scalars['ObjectId'];
  juniAdIds: Array<Scalars['ObjectId']>;
  campaignName: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  maxViews?: Maybe<Scalars['Int']>;
  cooldown?: Maybe<JuniAdCampaignCooldown>;
  archivedDate?: Maybe<Scalars['DateTime']>;
  juniAds?: Maybe<Array<JuniAd>>;
};

export type JuniAdAction = {
  __typename?: 'JuniAdAction';
  label?: Maybe<Scalars['String']>;
  clickthroughUrl?: Maybe<Scalars['String']>;
};

export type JuniAd = {
  __typename?: 'JuniAd';
  _id: Scalars['ObjectId'];
  juniAdProperty: Scalars['String'];
  juniAdTemplate: Scalars['String'];
  title: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  imageS3Url?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  primaryAction?: Maybe<JuniAdAction>;
  secondaryAction?: Maybe<JuniAdAction>;
};

/** Auto-generated payload object for the juniAdsAndCampaigns query */
export type JuniAdsAndCampaignsPayload = {
  __typename?: 'JuniAdsAndCampaignsPayload';
  items: Array<JuniAdCampaign>;
};

/** Auto-generated payload object for the serveJuniAd query */
export type ServeJuniAdPayload = {
  __typename?: 'ServeJuniAdPayload';
  items: Array<JuniAd>;
};

/** Auto-generated input object for the upsertJuniAdAndCampaign mutation */
export type UpsertJuniAdAndCampaignInput = {
  juniAdProperty: Scalars['String'];
  juniAdTemplate: Scalars['String'];
  title: Scalars['String'];
  version: Scalars['Int'];
  imageS3Url?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  primaryActionLabel?: Maybe<Scalars['String']>;
  primaryActionClickthroughUrl?: Maybe<Scalars['String']>;
  secondaryActionLabel?: Maybe<Scalars['String']>;
  secondaryActionClickthroughUrl?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  juniAdIds?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  maxViews?: Maybe<Scalars['Int']>;
  cooldownCount?: Maybe<Scalars['Int']>;
  cooldownTimeUnit?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the upsertJuniAdAndCampaign mutation */
export type UpsertJuniAdAndCampaignPayload = {
  __typename?: 'UpsertJuniAdAndCampaignPayload';
  juniAdCampaign: JuniAdCampaign;
};

/** Auto-generated input object for the archiveJuniAdCampaign mutation */
export type ArchiveJuniAdCampaignInput = {
  _id: Scalars['ObjectId'];
};

/** Auto-generated payload object for the archiveJuniAdCampaign mutation */
export type ArchiveJuniAdCampaignPayload = {
  __typename?: 'ArchiveJuniAdCampaignPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the sendJuniClubInvitation mutation */
export type SendJuniClubInvitationInput = {
  inviterStudentId?: Maybe<Scalars['ObjectId']>;
  inviterInstructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  inviteeEmail: Scalars['String'];
  inviteeName: Scalars['String'];
};

/** Auto-generated payload object for the sendJuniClubInvitation mutation */
export type SendJuniClubInvitationPayload = {
  __typename?: 'SendJuniClubInvitationPayload';
  juniClubInvitation?: Maybe<JuniClubInvitationType>;
};

/** Auto-generated input object for the registerJuniClubInvitationEmailClick mutation */
export type RegisterJuniClubInvitationEmailClickInput = {
  juniClubInvitationId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the registerJuniClubInvitationEmailClick mutation */
export type RegisterJuniClubInvitationEmailClickPayload = {
  __typename?: 'RegisterJuniClubInvitationEmailClickPayload';
  juniClubInvitation?: Maybe<JuniClubInvitationType>;
  clubInfo: JuniClubType;
  guestInvitation?: Maybe<GuestInvitationType>;
};

/** Auto-generated input object for the acceptRejectJuniClubInvitation mutation */
export type AcceptRejectJuniClubInvitationInput = {
  juniClubInvitationId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  accept: Scalars['Boolean'];
};

/** Auto-generated payload object for the acceptRejectJuniClubInvitation mutation */
export type AcceptRejectJuniClubInvitationPayload = {
  __typename?: 'AcceptRejectJuniClubInvitationPayload';
  accept: Scalars['Boolean'];
  success: Scalars['Boolean'];
};

/** Auto-generated input object for the submitGuestAccountRegistration mutation */
export type SubmitGuestAccountRegistrationInput = {
  guestInvitationId: Scalars['ObjectId'];
  birthDate: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

/** Auto-generated payload object for the submitGuestAccountRegistration mutation */
export type SubmitGuestAccountRegistrationPayload = {
  __typename?: 'SubmitGuestAccountRegistrationPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the registerCtaBannerClick mutation */
export type RegisterCtaBannerClickInput = {
  guestStudentId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the registerCtaBannerClick mutation */
export type RegisterCtaBannerClickPayload = {
  __typename?: 'RegisterCtaBannerClickPayload';
  guestInvitation?: Maybe<GuestInvitationType>;
};

/** Auto-generated input object for the sendParentRegistrationEmail mutation */
export type SendParentRegistrationEmailInput = {
  guestInvitationId: Scalars['ObjectId'];
  parentEmail: Scalars['String'];
  studentName: Scalars['String'];
};

/** Auto-generated payload object for the sendParentRegistrationEmail mutation */
export type SendParentRegistrationEmailPayload = {
  __typename?: 'SendParentRegistrationEmailPayload';
  success: Scalars['Boolean'];
};

/** Auto-generated payload object for the getClubInvitations query */
export type GetClubInvitationsPayload = {
  __typename?: 'GetClubInvitationsPayload';
  items: Array<JuniClubInvitationType>;
};

/** Auto-generated input object for the createClub mutation */
export type CreateClubInput = {
  displayName: Scalars['String'];
  description: Scalars['String'];
  colorTheme: Scalars['String'];
  coverPhoto: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the createClub mutation */
export type CreateClubPayload = {
  __typename?: 'CreateClubPayload';
  juniClub?: Maybe<JuniClubType>;
  juniClubMembership?: Maybe<JuniClubMembershipType>;
};

/** Auto-generated input object for the updateClubMetadata mutation */
export type UpdateClubMetadataInput = {
  juniClubId: Scalars['ObjectId'];
  tags?: Maybe<Array<Scalars['String']>>;
};

/** Auto-generated payload object for the updateClubMetadata mutation */
export type UpdateClubMetadataPayload = {
  __typename?: 'UpdateClubMetadataPayload';
  juniClub?: Maybe<JuniClubType>;
};

/** Auto-generated input object for the updateClub mutation */
export type UpdateClubInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  description?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the updateClub mutation */
export type UpdateClubPayload = {
  __typename?: 'UpdateClubPayload';
  juniClub?: Maybe<JuniClubType>;
};

/** Auto-generated input object for the createChannel mutation */
export type CreateChannelInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  channelName: Scalars['String'];
};

/** Auto-generated payload object for the createChannel mutation */
export type CreateChannelPayload = {
  __typename?: 'CreateChannelPayload';
  juniClub?: Maybe<JuniClubType>;
};

/** Auto-generated input object for the editChannel mutation */
export type EditChannelInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  channelName: Scalars['String'];
  readOnly?: Maybe<Scalars['Boolean']>;
  shouldArchive?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the editChannel mutation */
export type EditChannelPayload = {
  __typename?: 'EditChannelPayload';
  juniClub?: Maybe<JuniClubType>;
};

/** Auto-generated input object for the archiveChannel mutation */
export type ArchiveChannelInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  channelName: Scalars['String'];
};

/** Auto-generated payload object for the archiveChannel mutation */
export type ArchiveChannelPayload = {
  __typename?: 'ArchiveChannelPayload';
  juniClub?: Maybe<JuniClubType>;
};

/** Auto-generated input object for the removeViolatingMessage mutation */
export type RemoveViolatingMessageInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  channelName: Scalars['String'];
  msgId: Scalars['String'];
};

/** Auto-generated payload object for the removeViolatingMessage mutation */
export type RemoveViolatingMessagePayload = {
  __typename?: 'RemoveViolatingMessagePayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the deleteMessage mutation */
export type DeleteMessageInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  channelName: Scalars['String'];
  msgId: Scalars['String'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  senderUUID?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the deleteMessage mutation */
export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the updateMembershipStatus mutation */
export type UpdateMembershipStatusInput = {
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  memberUserCategory: Scalars['String'];
  memberClientUUID: Scalars['String'];
  newMembershipStatus: Scalars['String'];
};

/** Auto-generated payload object for the updateMembershipStatus mutation */
export type UpdateMembershipStatusPayload = {
  __typename?: 'UpdateMembershipStatusPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the checkImageContent mutation */
export type CheckImageContentInput = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  imageUrl: Scalars['String'];
};

/** Auto-generated payload object for the checkImageContent mutation */
export type CheckImageContentPayload = {
  __typename?: 'CheckImageContentPayload';
  success?: Maybe<Scalars['Boolean']>;
  violations?: Maybe<Array<Scalars['String']>>;
};

/** Auto-generated input object for the checkMessageContent mutation */
export type CheckMessageContentInput = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  message: Scalars['String'];
};

/** Auto-generated payload object for the checkMessageContent mutation */
export type CheckMessageContentPayload = {
  __typename?: 'CheckMessageContentPayload';
  success?: Maybe<Scalars['Boolean']>;
  moderationErrorMessage?: Maybe<Scalars['String']>;
};

/** Auto-generated input object for the sendImageUploadSlackMessage mutation */
export type SendImageUploadSlackMessageInput = {
  juniClubId: Scalars['ObjectId'];
  channelName: Scalars['String'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  msgText?: Maybe<Scalars['String']>;
  msgId: Scalars['String'];
};

/** Auto-generated payload object for the sendImageUploadSlackMessage mutation */
export type SendImageUploadSlackMessagePayload = {
  __typename?: 'SendImageUploadSlackMessagePayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the joinLeaveClub mutation */
export type JoinLeaveClubInput = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  isActive: Scalars['Boolean'];
};

/** Auto-generated payload object for the joinLeaveClub mutation */
export type JoinLeaveClubPayload = {
  __typename?: 'JoinLeaveClubPayload';
  juniClubMembership?: Maybe<JuniClubMembershipType>;
};

/** Auto-generated payload object for the getUserClubMemberships query */
export type GetUserClubMembershipsPayload = {
  __typename?: 'GetUserClubMembershipsPayload';
  items: Array<JuniClubType>;
};

/** Auto-generated payload object for the getUserClubsByUsername query */
export type GetUserClubsByUsernamePayload = {
  __typename?: 'GetUserClubsByUsernamePayload';
  items: Array<JuniUserClub>;
};

/** Auto-generated payload object for the getJuniClubUsers query */
export type GetJuniClubUsersPayload = {
  __typename?: 'GetJuniClubUsersPayload';
  items: Array<JuniClubMemberType>;
};

/** Auto-generated payload object for the getJuniClubBySearchString query */
export type GetJuniClubBySearchStringPayload = {
  __typename?: 'GetJuniClubBySearchStringPayload';
  items: Array<JuniClubType>;
};

/** Auto-generated payload object for the getJuniClubsByTag query */
export type GetJuniClubsByTagPayload = {
  __typename?: 'GetJuniClubsByTagPayload';
  items: Array<JuniClubType>;
};

/** Auto-generated payload object for the getJuniClubsByIds query */
export type GetJuniClubsByIdsPayload = {
  __typename?: 'GetJuniClubsByIdsPayload';
  items: Array<JuniClubType>;
};

export enum JuniClubMemberUserCategoryType {
  Guest = 'guest',
  Student = 'student',
  Instructor = 'instructor',
  InstructorMod = 'instructorMod',
  Admin = 'admin'
}

export type JuniClubMembershipType = {
  __typename?: 'JuniClubMembershipType';
  _id?: Maybe<Scalars['ObjectId']>;
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId?: Maybe<Scalars['ObjectId']>;
  isActive?: Maybe<Scalars['Boolean']>;
  membershipStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JuniClubChannelType = {
  __typename?: 'JuniClubChannelType';
  _id?: Maybe<Scalars['ObjectId']>;
  displayName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  readOnly?: Maybe<Scalars['Boolean']>;
};

export type JuniClubType = {
  __typename?: 'JuniClubType';
  _id?: Maybe<Scalars['ObjectId']>;
  displayName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  channels?: Maybe<Array<JuniClubChannelType>>;
  colorTheme?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  creatorStudentId?: Maybe<Scalars['ObjectId']>;
  creatorInstructorUserId?: Maybe<Scalars['ObjectId']>;
  isJuniModerated?: Maybe<Scalars['Boolean']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  memberCount?: Maybe<Scalars['Int']>;
};

export type JuniClubInvitationType = {
  __typename?: 'JuniClubInvitationType';
  _id?: Maybe<Scalars['ObjectId']>;
  inviterStudentId?: Maybe<Scalars['ObjectId']>;
  inviterInstructorUserId?: Maybe<Scalars['ObjectId']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviteeName?: Maybe<Scalars['String']>;
  inviteeStudentId?: Maybe<Scalars['ObjectId']>;
  inviteeInstructorUserId?: Maybe<Scalars['ObjectId']>;
  inviteeParentId?: Maybe<Scalars['ObjectId']>;
  inviteeUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId?: Maybe<Scalars['ObjectId']>;
  guestInvitationId?: Maybe<Scalars['ObjectId']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  inviterName?: Maybe<Scalars['String']>;
};

export type GuestInvitationType = {
  __typename?: 'GuestInvitationType';
  _id?: Maybe<Scalars['ObjectId']>;
  inviterStudentId?: Maybe<Scalars['ObjectId']>;
  inviterInstructorUserId?: Maybe<Scalars['ObjectId']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviteeName?: Maybe<Scalars['String']>;
  inviteeStudentId?: Maybe<Scalars['ObjectId']>;
  invitationLookupId?: Maybe<Scalars['String']>;
  clubInvitationId?: Maybe<Scalars['ObjectId']>;
  lastSentAt?: Maybe<Scalars['DateTime']>;
  emailClickedAt?: Maybe<Array<Scalars['DateTime']>>;
  registrationCompletedAt?: Maybe<Scalars['DateTime']>;
  reminderEmailSentAt?: Maybe<Scalars['DateTime']>;
  enrollClickedAt?: Maybe<Array<Scalars['DateTime']>>;
  enrollCompletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isParentInvite?: Maybe<Scalars['Boolean']>;
};

export type JuniClubMemberType = {
  __typename?: 'JuniClubMemberType';
  firstName?: Maybe<Scalars['String']>;
  lastInitial?: Maybe<Scalars['String']>;
  userCategory?: Maybe<JuniClubMemberUserCategoryType>;
  username?: Maybe<Scalars['String']>;
  membershipStatus?: Maybe<Scalars['String']>;
};

export type JuniClubMembershipInfoType = {
  __typename?: 'JuniClubMembershipInfoType';
  _id?: Maybe<Scalars['ObjectId']>;
  firstName?: Maybe<Scalars['String']>;
  lastInitial?: Maybe<Scalars['String']>;
  userCategory?: Maybe<JuniClubMemberUserCategoryType>;
  membershipStatus?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  clientUUID?: Maybe<Scalars['String']>;
  juniClubId?: Maybe<Scalars['ObjectId']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type LastReadTimetokensType = {
  __typename?: 'LastReadTimetokensType';
  channelName: Scalars['String'];
  timetoken: Scalars['String'];
};

export type JuniUserClub = {
  __typename?: 'JuniUserClub';
  _id: Scalars['ObjectId'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  coverPhoto: Scalars['String'];
  membershipStatus?: Maybe<Scalars['String']>;
  memberCount: Scalars['Int'];
};

/** Auto-generated input object for the myClubsState mutation */
export type MyClubsStateInput = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  specificClubs?: Maybe<Array<Scalars['ObjectId']>>;
  includeLastReadTimetokens?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the myClubsState mutation */
export type MyClubsStatePayload = {
  __typename?: 'MyClubsStatePayload';
  juniClubs?: Maybe<Array<JuniClubType>>;
  juniClubMembershipInfos?: Maybe<Array<JuniClubMembershipInfoType>>;
  lastReadTimetokens?: Maybe<Array<LastReadTimetokensType>>;
  juniCommunityEvents?: Maybe<Array<JuniCommunityEventType>>;
};

/** Auto-generated input object for the updateLastReadTimetoken mutation */
export type UpdateLastReadTimetokenInput = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  channelName: Scalars['String'];
  timetoken: Scalars['String'];
};

/** Auto-generated payload object for the updateLastReadTimetoken mutation */
export type UpdateLastReadTimetokenPayload = {
  __typename?: 'UpdateLastReadTimetokenPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the refreshPubNubCredentials mutation */
export type RefreshPubNubCredentialsInput = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
};

/** Auto-generated payload object for the refreshPubNubCredentials mutation */
export type RefreshPubNubCredentialsPayload = {
  __typename?: 'RefreshPubNubCredentialsPayload';
  publishKey: Scalars['String'];
  subscribeKey: Scalars['String'];
  authKey: Scalars['String'];
  clientUUID: Scalars['String'];
  expiresAt: Scalars['DateTime'];
};

export type ParentCornerInstructor = {
  __typename?: 'ParentCornerInstructor';
  _id: Scalars['ObjectId'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  bio: Scalars['String'];
  achievements: Array<ParentCornerInstructorAchievement>;
};

export type ParentCornerInstructorAchievement = {
  __typename?: 'ParentCornerInstructorAchievement';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  /** Badge description visible to instructors/parent/hq */
  externalDescription: Scalars['String'];
};

/** Auto-generated payload object for the parentCornerInstructorCardsForStudent query */
export type ParentCornerInstructorCardsForStudentPayload = {
  __typename?: 'ParentCornerInstructorCardsForStudentPayload';
  items: Array<ParentCornerInstructor>;
};

export type ParentCornerLiveAnnouncement = {
  __typename?: 'ParentCornerLiveAnnouncement';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  body: Scalars['String'];
  callsToAction: Array<ParentCornerAnnouncementCallToAction>;
  order: Scalars['Int'];
};

export type ParentCornerAnnouncementCallToAction = {
  __typename?: 'ParentCornerAnnouncementCallToAction';
  _id: Scalars['ObjectId'];
  label: Scalars['String'];
  url: Scalars['String'];
};

/** Auto-generated payload object for the parentCornerLiveAnnouncements query */
export type ParentCornerLiveAnnouncementsPayload = {
  __typename?: 'ParentCornerLiveAnnouncementsPayload';
  items: Array<ParentCornerLiveAnnouncement>;
};

export type CallsToActionInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  label: Scalars['String'];
  url: Scalars['String'];
};

export type ParentCornerAnnouncementInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  title: Scalars['String'];
  body: Scalars['String'];
  callsToAction: Array<CallsToActionInput>;
  order?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated input object for the updateParentCornerAnnouncements mutation */
export type UpdateParentCornerAnnouncementsInput = {
  announcements?: Maybe<Array<ParentCornerAnnouncementInput>>;
};

/** Auto-generated payload object for the updateParentCornerAnnouncements mutation */
export type UpdateParentCornerAnnouncementsPayload = {
  __typename?: 'UpdateParentCornerAnnouncementsPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type HelpCenterCategory = {
  __typename?: 'HelpCenterCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Int'];
};

export type HelpCenterSection = {
  __typename?: 'HelpCenterSection';
  id: Scalars['String'];
  category_id: Scalars['String'];
  parent_section_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Int'];
};

export type HelpCenterArticle = {
  __typename?: 'HelpCenterArticle';
  id: Scalars['String'];
  section_id: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
  position: Scalars['Int'];
  user_segment_id?: Maybe<Scalars['String']>;
};

export type HelpCenterContent = {
  __typename?: 'HelpCenterContent';
  categories: Array<HelpCenterCategory>;
  sections: Array<HelpCenterSection>;
  articles: Array<HelpCenterArticle>;
};

/** Auto-generated input object for the createJuniCommunityEvent mutation */
export type CreateJuniCommunityEventInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  eventLink?: Maybe<Scalars['String']>;
  juniClubIds?: Maybe<Array<Scalars['ObjectId']>>;
  creatorInstructorUserId?: Maybe<Scalars['ObjectId']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

/** Auto-generated payload object for the createJuniCommunityEvent mutation */
export type CreateJuniCommunityEventPayload = {
  __typename?: 'CreateJuniCommunityEventPayload';
  juniCommunityEvent?: Maybe<JuniCommunityEventType>;
};

/** Auto-generated input object for the updateJuniCommunityEvent mutation */
export type UpdateJuniCommunityEventInput = {
  eventId: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  eventLink?: Maybe<Scalars['String']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the updateJuniCommunityEvent mutation */
export type UpdateJuniCommunityEventPayload = {
  __typename?: 'UpdateJuniCommunityEventPayload';
  juniCommunityEvent?: Maybe<JuniCommunityEventType>;
};

/** Auto-generated payload object for the getCommunityEventsByStartDates query */
export type GetCommunityEventsByStartDatesPayload = {
  __typename?: 'GetCommunityEventsByStartDatesPayload';
  items: Array<JuniCommunityEventType>;
};

export type JuniCommunityEventType = {
  __typename?: 'JuniCommunityEventType';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  description: Scalars['String'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  eventLink?: Maybe<Scalars['String']>;
  juniClubIds: Array<Scalars['ObjectId']>;
  creatorInstructorUserId?: Maybe<Scalars['ObjectId']>;
  thumbnailUrl: Scalars['String'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  juniClubs?: Maybe<Array<JuniClubType>>;
};

export enum ScheduleFrequency {
  None = 'none',
  Weekly = 'weekly'
}

export type CreateCourseOfferingForCourseInput = {
  courseId?: Maybe<Scalars['ObjectId']>;
  totalSeats: Scalars['Int'];
  totalPriceInCents: Scalars['Int'];
  scheduleFrequency: ScheduleFrequency;
  sessionsCount: Scalars['Int'];
  sessionLength: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  instructorId: Scalars['ObjectId'];
  startTime: Scalars['DateTime'];
};

export type CreateBootcampCourseInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
  homeworkInformation?: Maybe<Scalars['Boolean']>;
  creatorUserId: Scalars['ObjectId'];
  schedulingFormat: CourseSchedulingFormat;
  courseDescription: Scalars['String'];
  subjectId: Scalars['ObjectId'];
  offerings: Array<CreateCourseOfferingForCourseInput>;
  headerImage: Scalars['String'];
};

export type CreateBootcampCoursePayload = {
  __typename?: 'CreateBootcampCoursePayload';
  name: Scalars['String'];
  _id: Scalars['ObjectId'];
};

export type UpdateCourseOfferingInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  totalSeats: Scalars['Int'];
  totalPriceInCents: Scalars['Int'];
  courseId?: Maybe<Scalars['ObjectId']>;
  scheduleFrequency: ScheduleFrequency;
  sessionsCount: Scalars['Int'];
  sessionLength: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  instructorId: Scalars['ObjectId'];
};

export type UpdateBootcampCourseInput = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  homeworkInformation?: Maybe<Scalars['Boolean']>;
  creatorUserId: Scalars['ObjectId'];
  schedulingFormat: CourseSchedulingFormat;
  courseDescription: Scalars['String'];
  subjectId: Scalars['ObjectId'];
  offerings: Array<UpdateCourseOfferingInput>;
};

export type UpdateBootcampCoursePayload = {
  __typename?: 'UpdateBootcampCoursePayload';
  success: Scalars['Boolean'];
};

export type EnableEnrollmentInput = {
  courseId: Scalars['ObjectId'];
};

export type EnableEnrollmentPayload = {
  __typename?: 'EnableEnrollmentPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DisableEnrollmentInput = {
  courseId: Scalars['ObjectId'];
};

export type DisableEnrollmentPayload = {
  __typename?: 'DisableEnrollmentPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type CourseOfferingScheduleInput = {
  startTime: Scalars['DateTime'];
  appointmentTypeId: Scalars['Int'];
};

export type CreateCourseOfferingInput = {
  courseId: Scalars['ObjectId'];
  totalSeats: Scalars['Int'];
  totalPriceInCents: Scalars['Int'];
  schedule: Array<CourseOfferingScheduleInput>;
  instructorId: Scalars['ObjectId'];
};

export type CreateCourseOfferingPayload = {
  __typename?: 'CreateCourseOfferingPayload';
  success: Scalars['Boolean'];
};

export type BillingCustomerLogInput = {
  instructorId?: Maybe<Scalars['ObjectId']>;
  text: Scalars['String'];
};

/** Auto-generated input object for the adjustBilling mutation */
export type AdjustBillingInput = {
  parentId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  subscriptionId: Scalars['String'];
  apptIdsToCancel?: Maybe<Array<Scalars['String']>>;
  isFutureBill: Scalars['Boolean'];
  billDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  logSubtype: Scalars['String'];
  logEntries: Array<BillingCustomerLogInput>;
};

/** Auto-generated payload object for the adjustBilling mutation */
export type AdjustBillingPayload = {
  __typename?: 'AdjustBillingPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ReferralInvite = {
  __typename?: 'ReferralInvite';
  _id: Scalars['ObjectId'];
  referralCode: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  status: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  reminderLastSentAt?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated payload object for the getReferralInvites query */
export type GetReferralInvitesPayload = {
  __typename?: 'GetReferralInvitesPayload';
  items: Array<ReferralInvite>;
};

/** Auto-generated input object for the sendReferralInvite mutation */
export type SendReferralInviteInput = {
  email: Scalars['String'];
};

/** Auto-generated payload object for the sendReferralInvite mutation */
export type SendReferralInvitePayload = {
  __typename?: 'SendReferralInvitePayload';
  referralInvite: ReferralInvite;
};

/** Auto-generated input object for the sendReferralReminderEmail mutation */
export type SendReferralReminderEmailInput = {
  email: Scalars['String'];
};

/** Auto-generated payload object for the sendReferralReminderEmail mutation */
export type SendReferralReminderEmailPayload = {
  __typename?: 'SendReferralReminderEmailPayload';
  updatedInvite: ReferralInvite;
};

export type ReferralRedemption = {
  __typename?: 'ReferralRedemption';
  _id: Scalars['ObjectId'];
  referralCodeId: Scalars['ObjectId'];
  email: Scalars['String'];
  redeemedByParentId: Scalars['ObjectId'];
  status: Scalars['String'];
  customerLogId?: Maybe<Scalars['ObjectId']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Possible referral redemption statuses */
export enum ReferralRedemptionStatusEnum {
  CreditGranted = 'credit_granted',
  Registered = 'registered',
  AwaitingCredit = 'awaiting_credit'
}

export type UpdateReferralRedemptionInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  status?: Maybe<ReferralRedemptionStatusEnum>;
};

/** Auto-generated payload object for the getReferralRedemptions query */
export type GetReferralRedemptionsPayload = {
  __typename?: 'GetReferralRedemptionsPayload';
  items: Array<ReferralRedemption>;
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  _id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StudentRecapPayload = {
  __typename?: 'StudentRecapPayload';
  recentProjects: Array<RecentProject>;
  userInfo: StudentRecap;
  userReport: UserReport;
};

export type UserReport = {
  __typename?: 'UserReport';
  projectViewsAllTime: Scalars['Int'];
  projectViewsThisMonth: Scalars['Int'];
  projectsPublishedAllTime: Scalars['Int'];
  projectsPublishedThisMonth: Scalars['Int'];
  projectsWorkedOnAllTime: Scalars['Int'];
  projectsWorkedOnThisMonth: Scalars['Int'];
};

export type LatestProject = {
  __typename?: 'LatestProject';
  title: Scalars['String'];
  _id: Scalars['String'];
  projectType?: Maybe<Scalars['String']>;
  lastUpdatedAt: Scalars['DateTime'];
  viewCount?: Maybe<Scalars['Int']>;
};

export type RecentProject = {
  __typename?: 'RecentProject';
  projectName: Scalars['String'];
  courseName: Scalars['String'];
  subjectName: Scalars['String'];
  publicProjectUrl: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  sectionType?: Maybe<Scalars['String']>;
};

export type RecapActiveCoursesProgress = {
  __typename?: 'RecapActiveCoursesProgress';
  displayName: Scalars['String'];
  numModulesCompleted: Scalars['Int'];
  totalNumModules: Scalars['Int'];
  iconName: Scalars['String'];
  primaryColor: Scalars['String'];
};

export type RecapStudentInfo = {
  __typename?: 'RecapStudentInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  activeCoursesProgress?: Maybe<Array<RecapActiveCoursesProgress>>;
};

export type StudentRecap = {
  __typename?: 'StudentRecap';
  studentInfo?: Maybe<RecapStudentInfo>;
  referralCode: Scalars['String'];
  parentFirstName: Scalars['String'];
};

export type UnlistedProjectDataType = {
  __typename?: 'UnlistedProjectDataType';
  studentId?: Maybe<Scalars['String']>;
  instructorUserId?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  playgroundProjectType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastInitial?: Maybe<Scalars['String']>;
  parentFirstName?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  courseDisplayName?: Maybe<Scalars['String']>;
  moduleDisplayName?: Maybe<Scalars['String']>;
  projectDisplayName?: Maybe<Scalars['String']>;
  encryptedStudentAndProject?: Maybe<Scalars['String']>;
  studentProjectId?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type NoteFromHq = {
  __typename?: 'NoteFromHQ';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  text: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

/** Auto-generated payload object for the notesFromHQ query */
export type NotesFromHqPayload = {
  __typename?: 'NotesFromHQPayload';
  items: Array<NoteFromHq>;
};

/** Auto-generated payload object for the notesFromHQByDate query */
export type NotesFromHqByDatePayload = {
  __typename?: 'NotesFromHQByDatePayload';
  items: Array<NoteFromHq>;
};

/** Auto-generated input object for the upsertNotesFromHQ mutation */
export type UpsertNotesFromHqInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  title: Scalars['String'];
  text: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

/** Auto-generated payload object for the upsertNotesFromHQ mutation */
export type UpsertNotesFromHqPayload = {
  __typename?: 'UpsertNotesFromHQPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated input object for the deleteNoteFromHQ mutation */
export type DeleteNoteFromHqInput = {
  noteId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the deleteNoteFromHQ mutation */
export type DeleteNoteFromHqPayload = {
  __typename?: 'DeleteNoteFromHQPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Auto-generated payload object for the queryModuleProgresses query */
export type QueryModuleProgressesPayload = {
  __typename?: 'QueryModuleProgressesPayload';
  items: Array<ModuleProgress>;
};

/** Auto-generated input object for the markOnDemandModuleSectionInProgress mutation */
export type MarkOnDemandModuleSectionInProgressInput = {
  studentId: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  sectionId: Scalars['ObjectId'];
  courseCurriculumId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the markOnDemandModuleSectionInProgress mutation */
export type MarkOnDemandModuleSectionInProgressPayload = {
  __typename?: 'MarkOnDemandModuleSectionInProgressPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ModuleProgress = {
  __typename?: 'ModuleProgress';
  _id: Scalars['ObjectId'];
  moduleId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  isInProgress?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

/** Auto-generated input object for the submitNylasCode mutation */
export type SubmitNylasCodeInput = {
  code: Scalars['String'];
};

/** Auto-generated payload object for the submitNylasCode mutation */
export type SubmitNylasCodePayload = {
  __typename?: 'SubmitNylasCodePayload';
  instructor?: Maybe<Instructor>;
};

/** Auto-generated input object for the setNylasCalendarWatched mutation */
export type SetNylasCalendarWatchedInput = {
  calendarId: Scalars['String'];
  watched: Scalars['Boolean'];
};

/** Auto-generated payload object for the setNylasCalendarWatched mutation */
export type SetNylasCalendarWatchedPayload = {
  __typename?: 'SetNylasCalendarWatchedPayload';
  instructor?: Maybe<Instructor>;
};

/** Auto-generated input object for the syncCalendars mutation */
export type SyncCalendarsInput = {
  /** Calendar sync dateRange start */
  start: Scalars['DateTime'];
  /** Calendar sync dateRange end */
  end: Scalars['DateTime'];
  userId: Scalars['ObjectId'];
};

/** Auto-generated payload object for the syncCalendars mutation */
export type SyncCalendarsPayload = {
  __typename?: 'SyncCalendarsPayload';
  success: Scalars['Boolean'];
};

export type CachedNylasBlock = {
  __typename?: 'CachedNylasBlock';
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  nylasEventId: Scalars['String'];
  acuityApptId?: Maybe<Scalars['String']>;
  nylasCalendarType: NylasCalendarType;
  instructor: Scalars['ObjectId'];
  _id: Scalars['ObjectId'];
};

export type CachedNylasBlockOrPseudoAcuityApptBlock = {
  __typename?: 'CachedNylasBlockOrPseudoAcuityApptBlock';
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  nylasEventId?: Maybe<Scalars['String']>;
  acuityApptId?: Maybe<Scalars['String']>;
  nylasCalendarType: NylasCalendarType;
  instructor: Scalars['ObjectId'];
  _id?: Maybe<Scalars['ObjectId']>;
};

/** Payload object for the scheduleClassPack mutation */
export type ScheduleAdditionalClassPayload = {
  __typename?: 'ScheduleAdditionalClassPayload';
  additionalClassId: Scalars['ObjectId'];
  selectedAppointmentSlot: Scalars['DateTime'];
};

/** Input object for the scheduleClassPack mutation */
export type ScheduleAdditionalClassInput = {
  additionalClassId: Scalars['ObjectId'];
  selectedAppointmentSlot: Scalars['DateTime'];
};

/** Payload object for the rescheduleClass mutation */
export type RescheduleClassPayload = {
  __typename?: 'RescheduleClassPayload';
  success: Scalars['Boolean'];
};

/** Input object for the rescheduleClass mutation */
export type RescheduleClassInput = {
  acuityAppointmentId: Scalars['Int'];
  selectedAppointmentSlot: Scalars['DateTime'];
};

export type NylasSubdocument = {
  __typename?: 'NylasSubdocument';
  availabilityCalendarId?: Maybe<Scalars['String']>;
  accessToken: Scalars['String'];
  busyCalendars: Array<Scalars['String']>;
};

export type NylasBusyCalendarOption = {
  __typename?: 'NylasBusyCalendarOption';
  id: Scalars['String'];
  name: Scalars['String'];
  watched: Scalars['Boolean'];
};

export type ProfileSchema = {
  __typename?: 'ProfileSchema';
  bio?: Maybe<Scalars['String']>;
  profileColor?: Maybe<Scalars['String']>;
  profileImage?: Maybe<UserEditedImage>;
  bannerImage?: Maybe<UserEditedImage>;
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  Other = 'other'
}

export enum AgeRange {
  AgeRange5to7 = 'ageRange5to7',
  AgeRange8to10 = 'ageRange8to10',
  AgeRange11to13 = 'ageRange11to13',
  AgeRange14to16 = 'ageRange14to16',
  AgeRange17up = 'ageRange17up'
}

export type InstructorLoad = {
  __typename?: 'InstructorLoad';
  date: Scalars['DateTime'];
  hours: InstructorLoadHours;
};

export type StudentProposalsHistory = {
  __typename?: 'StudentProposalsHistory';
  pending?: Maybe<Scalars['Int']>;
  declined?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Int']>;
  acceptedByOther?: Maybe<Scalars['Int']>;
};

export type InstructorQualifiedCourse = {
  __typename?: 'InstructorQualifiedCourse';
  isQualified: Scalars['Int'];
  instructorId?: Maybe<Scalars['String']>;
  courseId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  moduleProgress: Array<ModuleProgressList>;
};

export type DisconnectNylasPayload = {
  __typename?: 'disconnectNylasPayload';
  instructor?: Maybe<Instructor>;
};

export enum NylasCalendarType {
  Busy = 'busy',
  Availability = 'availability'
}

export type UserEditedImage = {
  __typename?: 'UserEditedImage';
  url?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type InstructorLoadHours = {
  __typename?: 'InstructorLoadHours';
  recurring: Scalars['Float'];
  trial: Scalars['Float'];
};

export type ModuleProgressList = {
  __typename?: 'ModuleProgressList';
  id?: Maybe<Scalars['String']>;
  status: CurriculumProgressStatus;
};

export enum CurriculumProgressStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieves list of static URLs from a given s3 bucket and folder */
  staticS3BucketUrls?: Maybe<Array<Scalars['String']>>;
  /** Gets a list of instructors sorted by name */
  instructors: InstructorsPayload;
  /** Return the instructor record of the authorized user */
  findMeInstructor: Instructor;
  /** Given an array of IDs for instructors, retrieves the first name and last initial for each one */
  truncatedInstructorNames: TruncatedInstructorNamesPayload;
  instructor?: Maybe<Instructor>;
  /** Gets a Acuity Appointment Notification */
  acuityAppointmentNotification?: Maybe<AcuityAppointmentNotification>;
  /** Gets a single edited rich text */
  editedRichTextById?: Maybe<EditedRichText>;
  /** Gets a list of english projects */
  englishProjects: EnglishProjectsPayload;
  /** Gets a list of investing projects */
  investingProjects: InvestingProjectsPayload;
  queryExperiments: QueryExperimentsPayload;
  experiment?: Maybe<Experiment>;
  /** Retrieves a list of recent announcements */
  queryAnnouncements: QueryAnnouncementsPayload;
  /** Gets a student's English Answer Submissions for a specified project (module section) */
  englishAnswerSubmissionsForStudentProject: EnglishAnswerSubmissionsForStudentProjectPayload;
  /** Gets a student's Investing Answer Submissions for a specified project (module section) */
  investingAnswerSubmissionsForStudentProject: InvestingAnswerSubmissionsForStudentProjectPayload;
  /** Gets a list of payroll cycles */
  payrollCycles: Array<PayrollCycle>;
  /** Gets a single prompt */
  promptById?: Maybe<Prompt>;
  /** Gets a list of all Reference Text Sets */
  referenceTextSets: ReferenceTextSetsPayload;
  /** Gets a list of Reference Text Sets by ids (multiple) */
  referenceTextSetsByIds: ReferenceTextSetsByIdsPayload;
  /** Gets a list of all Reference Texts */
  referenceTexts: ReferenceTextsPayload;
  /** Retrieves computer science module sections */
  computerScienceModuleSections: ComputerScienceModuleSectionsPayload;
  /** Gets a list of english courses */
  englishCourses: Array<EnglishCourse>;
  /** Gets a count of english courses */
  englishCoursesCount: Scalars['Int'];
  /** Gets a single english course */
  englishCourseById?: Maybe<EnglishCourse>;
  /** Gets a list of math courses */
  mathCourses: Array<MathCourse>;
  /** Gets a count of math courses */
  mathCoursesCount: Scalars['Int'];
  /** Gets a single math course */
  mathCourseById?: Maybe<MathCourse>;
  /** Gets a list of Investing courses */
  investingCourses: Array<InvestingCourse>;
  /** Gets a count of Investing courses */
  investingCoursesCount: Scalars['Int'];
  /** Gets a single Investing course */
  investingCourseById?: Maybe<InvestingCourse>;
  /** Retrieves the rubric, if one exists, for the given course */
  courseRubric?: Maybe<CourseRubric>;
  /** Retrieves the course rubric progress for the given course and student */
  courseRubricProgress?: Maybe<CourseRubricProgress>;
  /**
   * Retrieves the rubric, if one exists and is active, by course
   *     name and subject, as well as the given student's progress on the rubric
   */
  courseRubricForLearner?: Maybe<CourseRubricProgress>;
  /** Gets a list of subjects */
  getSubjects: Array<Subject>;
  /** Gets a single subject */
  getSubject?: Maybe<Subject>;
  /** Gets a list of courses */
  getCourses: Array<Course>;
  /** Gets a count of courses */
  coursesCount: Scalars['Int'];
  /** Gets a single course */
  getCourse?: Maybe<Course>;
  /** Gets a single course offering */
  getCourseOffering?: Maybe<CourseOffering>;
  /** Gets a list of course offerings from a given course */
  getCourseOfferings: Array<CourseOffering>;
  /** Gets the completion date of the course if present */
  courseCompletionDate: CourseCompletionInfo;
  /** Gets completion dates for a student */
  courseCompletionDates: CourseCompletionDatesPayload;
  /** Gets the list of student proposals for handoffs/onboardings */
  studentProposals: StudentProposalsPayload;
  /** Returns a compiled email for student onboarding */
  compileOnboardingEmail?: Maybe<Scalars['String']>;
  /** Returns a compiled email for student handoff */
  compileHandoffEmail?: Maybe<Scalars['String']>;
  /** Returns Information required to render Juniverse User Profile */
  profileByUsername?: Maybe<ProfilePage>;
  /** Gets the list of featured student projects */
  featuredStudentProjects: FeaturedStudentProjectsPayload;
  studentProjectById?: Maybe<StudentProject>;
  /** Gets the list of student projects in the Juniverse */
  studentProjects: StudentProjectsPayload;
  openChallengeById?: Maybe<OpenChallenge>;
  /** Gets the list of open challenges in Juniverse */
  openChallenges: OpenChallengesPayload;
  /** Returns Information required to render Juniverse User Profile */
  juniverseUserProfileByUsername?: Maybe<JuniverseUserProfile>;
  /** Indicates if the instructor is able to receive slack messages */
  instructorEnabledForSlackMessaging: Scalars['Boolean'];
  /** Gets the current user's SMS consent status */
  userSmsConsentStatus: Scalars['Boolean'];
  /** Gets the specified user's SMS consent status */
  userSmsConsentStatusById: Scalars['Boolean'];
  /** Retrieves the Stripe Customer object for the requesting user */
  myStripeCustomer?: Maybe<StripeCustomer>;
  /** Retrieves unpaid Stripe Charge objects for the requesting user */
  myUnpaidStripeCharges: MyUnpaidStripeChargesPayload;
  /** Retrieves the default Stripe payment method for the provided parent id */
  defaultPaymentInformationByParentId?: Maybe<StripePaymentMethod>;
  /** Retrieves Stripe payment history for the provided parent id */
  paymentHistoryByParentId: PaymentHistoryByParentIdPayload;
  /** Retrieves Stripe subscription history for the provided parent id */
  stripeSubscriptionsByParentId: StripeSubscriptionsByParentIdPayload;
  /** Retrieves the Stripe Customer object for the specified parent ID */
  stripeCustomerByParentId?: Maybe<StripeCustomer>;
  /** Retrieves unpaid Stripe Charge objects for the specified Stripe customer ID */
  unpaidStripeChargesByStripeCustomerId: UnpaidStripeChargesByStripeCustomerIdPayload;
  /** Retrieves Stripe product information */
  productsByStripeIds: ProductsByStripeIdsPayload;
  /** Gets a student based on an id. */
  studentById?: Maybe<Student>;
  studentCompletedCourseParams?: Maybe<StudentCompletedCourseParams>;
  allStudentCompletedCourseParams: AllStudentCompletedCourseParamsPayload;
  studentCertificateParams: StudentCertificateParamsPayload;
  /** Fetches public student and course completion data */
  studentCompletedBundleData?: Maybe<StudentCompletedBundleData>;
  /** Fetches public student and course completion data */
  studentCompletedCourseData?: Maybe<StudentCompletedCourseData>;
  /** Gets a list of students based on a list of parentId strings */
  studentsByParentIds: StudentsByParentIdsPayload;
  /** Gets a list of availabilities based on a list of studentId strings */
  availabilitiesByStudentIds: AvailabilitiesByStudentIdsPayload;
  /** Gets the requesting instructor's Recurring Availability Update Requests */
  myRecurringAvailabilityUpdateRequests: MyRecurringAvailabilityUpdateRequestsPayload;
  /** Gets the requesting instructor's Recurring Reschedule Requests */
  myRecurringRescheduleRequests: MyRecurringRescheduleRequestsPayload;
  /** Gets all Recurring Reschedule Requests */
  recurringRescheduleRequests: RecurringRescheduleRequestsPayload;
  /** Gets a Recurring Reschedule Request */
  recurringRescheduleRequest?: Maybe<RecurringRescheduleRequest>;
  /** Gets upcoming class appointments for an instructor by userId */
  upcomingClassAppointmentsForInstructor: UpcomingClassAppointmentsForInstructorPayload;
  /** Gets recurring schedule details for an instructor by instructorId */
  recurringSchedulesForInstructor: RecurringSchedulesForInstructorPayload;
  /** Query instructor onboardings */
  instructorOnboardings: InstructorOnboardingsPayload;
  /** Query instructor onboardings by id */
  instructorOnboardingById?: Maybe<InstructorOnboarding>;
  /** Query appointment types */
  appointmentTypes: AppointmentTypesPayload;
  /** Query unsent dashboard information from the last week */
  unsentDashboardSessionInfos: UnsentDashboardSessionInfosPayload;
  /** Gets the list of instructor badges */
  instructorAchievements: InstructorAchievementsPayload;
  instructorAchievementById?: Maybe<InstructorAchievement>;
  /** Gets the list of achievements associated with instructor id */
  instructorAchievementsByInstructorId: InstructorAchievementsByInstructorIdPayload;
  instructorMatches?: Maybe<InstructorMatchPayload>;
  /** Retrieves the session feedback and response, if either exist, associated with the given teacherSessionId */
  sessionFeedback?: Maybe<SessionFeedbackPayload>;
  /** Retrieves pending or recently-completed session feedback for an instructor */
  sessionFeedbackForInstructor: SessionFeedbackForInstructorPayload;
  /** Gets a list of Juni Ads and Campaigns */
  juniAdsAndCampaigns: JuniAdsAndCampaignsPayload;
  /** Gets the best active Juni Ad */
  serveJuniAd: ServeJuniAdPayload;
  /** Gets all valid club invitations for a user */
  getClubInvitations: GetClubInvitationsPayload;
  /** Get all active club memberships for a given user */
  getUserClubMemberships: GetUserClubMembershipsPayload;
  /**
   * Get all active clubs a given user is a member of, plus their membership status and club member count.
   *     Orders by membership status (moderator first), member count desc.
   */
  getUserClubsByUsername: GetUserClubsByUsernamePayload;
  /** Get all active users in a JuniClub */
  getJuniClubUsers: GetJuniClubUsersPayload;
  /** Retrieves top JuniClubs that fuzzy match search string */
  getJuniClubBySearchString: GetJuniClubBySearchStringPayload;
  /** Retrieves top JuniClubs that have tag */
  getJuniClubsByTag: GetJuniClubsByTagPayload;
  /** Grabs a list of juni clubs from a list of juni club ids */
  getJuniClubsByIds: GetJuniClubsByIdsPayload;
  getUsernameById?: Maybe<Scalars['String']>;
  /** Get all instructors that have had a past session and an upcoming session with a student */
  parentCornerInstructorCardsForStudent: ParentCornerInstructorCardsForStudentPayload;
  /** Retrieve all live parent corner announcements in order. */
  parentCornerLiveAnnouncements: ParentCornerLiveAnnouncementsPayload;
  /** Query for all help center content */
  queryHelpCenterContent: HelpCenterContent;
  /** Query article by id */
  queryHelpCenterArticleById: HelpCenterArticle;
  /** Given a list of dates in ISO string format returns a list of events for those dates sorted by startDateTime */
  getCommunityEventsByStartDates: GetCommunityEventsByStartDatesPayload;
  /** Given a user id, get all the referralInvites they have sent */
  getReferralInvites: GetReferralInvitesPayload;
  /** Returns compiled HTML of the referral email */
  getPreviewReferralEmail?: Maybe<Scalars['String']>;
  /** Gets a referral redemption from a mongo _id */
  getReferralRedemptionById: ReferralRedemption;
  /** Given a user id, get all the referral redemptions associated with their account */
  getReferralRedemptions: GetReferralRedemptionsPayload;
  /** Gets a users referral code */
  getReferralCode?: Maybe<ReferralCode>;
  /** Retrieves a list of dates representing the start time of available appointment slots */
  availableAppointmentSlots?: Maybe<Array<Scalars['DateTime']>>;
  /** Retrieves a list of dates representing the start time of recurringly available appointment slots */
  recurringlyAvailableAppointmentSlots?: Maybe<Array<Scalars['DateTime']>>;
  studentRecapByUserReportId: StudentRecapPayload;
  /** Fetches data required to show unlisted project on a public page */
  unlistedProjectData?: Maybe<UnlistedProjectDataType>;
  /** Retrieve last 6 months worth of Notes From HQ. */
  notesFromHQ: NotesFromHqPayload;
  /** Retrieve Notes From HQ by session date. */
  notesFromHQByDate: NotesFromHqByDatePayload;
  /** Gets a list of module progress. */
  queryModuleProgresses: QueryModuleProgressesPayload;
};


export type QueryStaticS3BucketUrlsArgs = {
  s3Bucket: Scalars['String'];
  folder: Scalars['String'];
};


export type QueryTruncatedInstructorNamesArgs = {
  ids: Array<Scalars['String']>;
  idFieldName: Scalars['String'];
};


export type QueryInstructorArgs = {
  email: Scalars['String'];
};


export type QueryAcuityAppointmentNotificationArgs = {
  appointmentId: Scalars['Int'];
};


export type QueryEditedRichTextByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryEnglishProjectsArgs = {
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
};


export type QueryInvestingProjectsArgs = {
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
};


export type QueryQueryExperimentsArgs = {
  status?: Maybe<Array<Scalars['String']>>;
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  target?: Maybe<Array<Scalars['String']>>;
  participantType?: Maybe<Array<Scalars['String']>>;
};


export type QueryExperimentArgs = {
  key?: Maybe<Scalars['String']>;
};


export type QueryEnglishAnswerSubmissionsForStudentProjectArgs = {
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
};


export type QueryInvestingAnswerSubmissionsForStudentProjectArgs = {
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
};


export type QueryPromptByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryReferenceTextSetsArgs = {
  subject?: Maybe<Scalars['String']>;
};


export type QueryReferenceTextSetsByIdsArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type QueryReferenceTextsArgs = {
  subject?: Maybe<Scalars['String']>;
};


export type QueryComputerScienceModuleSectionsArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type QueryEnglishCourseByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryMathCourseByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInvestingCourseByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryCourseRubricArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryCourseRubricProgressArgs = {
  input: CourseRubricProgressInput;
};


export type QueryCourseRubricForLearnerArgs = {
  input: CourseRubricForLearnerInput;
};


export type QueryGetSubjectArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetCoursesArgs = {
  input?: Maybe<QueryCourseInput>;
};


export type QueryCoursesCountArgs = {
  where?: Maybe<QueryCourseInput>;
};


export type QueryGetCourseArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  curriculumId?: Maybe<Scalars['ObjectId']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryGetCourseOfferingArgs = {
  id: Scalars['ObjectId'];
};


export type QueryGetCourseOfferingsArgs = {
  courseId: Scalars['ObjectId'];
};


export type QueryCourseCompletionDateArgs = {
  studentId: Scalars['ObjectId'];
  courseName: Scalars['String'];
};


export type QueryCourseCompletionDatesArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryStudentProposalsArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
  wasResolvedRecently?: Maybe<Scalars['Boolean']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  studentOnboardingId?: Maybe<Scalars['ObjectId']>;
  studentHandoffId?: Maybe<Scalars['ObjectId']>;
};


export type QueryCompileOnboardingEmailArgs = {
  courseNames?: Maybe<Array<Scalars['String']>>;
  origin?: Maybe<Scalars['String']>;
  templateValues: OnboardingEmailTemplateValues;
};


export type QueryCompileHandoffEmailArgs = {
  templateValues: HandoffEmailTemplateValues;
  parentEmail?: Maybe<Scalars['String']>;
};


export type QueryProfileByUsernameArgs = {
  username: Scalars['String'];
};


export type QueryFeaturedStudentProjectsArgs = {
  featuredType: StudentProjectFeaturedType;
};


export type QueryStudentProjectByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStudentProjectsArgs = {
  query?: Maybe<StudentProjectQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryOpenChallengeByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryJuniverseUserProfileByUsernameArgs = {
  username: Scalars['String'];
};


export type QueryInstructorEnabledForSlackMessagingArgs = {
  instructorId: Scalars['ObjectId'];
};


export type QueryUserSmsConsentStatusByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryDefaultPaymentInformationByParentIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryPaymentHistoryByParentIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStripeSubscriptionsByParentIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryStripeCustomerByParentIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUnpaidStripeChargesByStripeCustomerIdArgs = {
  id: Scalars['String'];
};


export type QueryProductsByStripeIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryStudentByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryStudentCompletedCourseParamsArgs = {
  studentId: Scalars['ObjectId'];
  courseName: Scalars['String'];
};


export type QueryAllStudentCompletedCourseParamsArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryStudentCertificateParamsArgs = {
  studentId: Scalars['ObjectId'];
  certificateType?: Maybe<CertificateEnum>;
};


export type QueryStudentCompletedBundleDataArgs = {
  params: Scalars['String'];
};


export type QueryStudentCompletedCourseDataArgs = {
  params: Scalars['String'];
};


export type QueryStudentsByParentIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryAvailabilitiesByStudentIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryRecurringRescheduleRequestsArgs = {
  completed?: Maybe<Scalars['Boolean']>;
};


export type QueryRecurringRescheduleRequestArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUpcomingClassAppointmentsForInstructorArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
  maxDays?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};


export type QueryRecurringSchedulesForInstructorArgs = {
  instructorId?: Maybe<Scalars['ObjectId']>;
};


export type QueryInstructorOnboardingsArgs = {
  input?: Maybe<InstructorOnboardingQueryInput>;
};


export type QueryInstructorOnboardingByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryUnsentDashboardSessionInfosArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryInstructorAchievementByIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInstructorAchievementsByInstructorIdArgs = {
  id: Scalars['ObjectId'];
};


export type QueryInstructorMatchesArgs = {
  input: InstructorMatchInput;
};


export type QuerySessionFeedbackArgs = {
  teacherSessionId: Scalars['ObjectId'];
};


export type QuerySessionFeedbackForInstructorArgs = {
  isComplete?: Maybe<Scalars['Boolean']>;
};


export type QueryJuniAdsAndCampaignsArgs = {
  juniAdCampaignIds?: Maybe<Array<Scalars['String']>>;
};


export type QueryServeJuniAdArgs = {
  juniAdProperty?: Maybe<Scalars['String']>;
};


export type QueryGetUserClubMembershipsArgs = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
};


export type QueryGetUserClubsByUsernameArgs = {
  username: Scalars['String'];
};


export type QueryGetJuniClubUsersArgs = {
  juniClubId: Scalars['ObjectId'];
};


export type QueryGetJuniClubBySearchStringArgs = {
  searchString: Scalars['String'];
};


export type QueryGetJuniClubsByTagArgs = {
  tag: Scalars['String'];
};


export type QueryGetJuniClubsByIdsArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type QueryGetUsernameByIdArgs = {
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
};


export type QueryParentCornerInstructorCardsForStudentArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryQueryHelpCenterArticleByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetCommunityEventsByStartDatesArgs = {
  startDates: Array<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};


export type QueryGetPreviewReferralEmailArgs = {
  isReminder: Scalars['Boolean'];
};


export type QueryGetReferralRedemptionByIdArgs = {
  _id: Scalars['ObjectId'];
};


export type QueryAvailableAppointmentSlotsArgs = {
  instructorId?: Maybe<Scalars['ObjectId']>;
  instructorAcuityId?: Maybe<Scalars['String']>;
  appointmentTypeId: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
  endDatetime: Scalars['DateTime'];
};


export type QueryRecurringlyAvailableAppointmentSlotsArgs = {
  instructorId?: Maybe<Scalars['ObjectId']>;
  instructorAcuityId?: Maybe<Scalars['String']>;
  appointmentTypeId: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
  endDatetime: Scalars['DateTime'];
  timezone: Scalars['String'];
};


export type QueryStudentRecapByUserReportIdArgs = {
  userReportId: Scalars['ObjectId'];
  numProjects?: Maybe<Scalars['Int']>;
};


export type QueryUnlistedProjectDataArgs = {
  params: Scalars['String'];
};


export type QueryNotesFromHqByDateArgs = {
  date: Scalars['DateTime'];
};


export type QueryQueryModuleProgressesArgs = {
  studentId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** deletes an s3 object */
  deleteS3Object: DeleteS3ObjectPayload;
  /** Create a new Student */
  createStudent: CreateStudentPayload;
  /** Prepend new notes to exisiting Student Notes. */
  prependStudentNotes: PrependStudentNotesPayload;
  /** Create new learning preferences or update any existing student learning preference */
  updateLearningPreferences: UpdateLearningPreferencesPayload;
  /** Updates an instructor or parent email */
  updateEmail: UpdateEmailPayload;
  /** Updates an instructor name */
  updateName: UpdateNamePayload;
  /** upserts a notification listener for an acuity appointment */
  upsertAcuityAppointmentNotification: UpsertAcuityAppointmentNotificationPayload;
  /** Cancels students acuity appointments */
  cancelStudentAppointments: CancelStudentAppointmentsPayload;
  /** Reschedules acuity appointment */
  rescheduleStudentAppointments: RescheduleStudentAppointmentsPayload;
  /** Schedules students acuity appointments */
  scheduleStudentAppointments: ScheduleStudentAppointmentsPayload;
  /** Creates a new edited rich text */
  createEditedRichText: CreateEditedRichTextPayload;
  /** Updates an existing edited rich text */
  updateEditedRichText: UpdateEditedRichTextPayload;
  /** Creates a new English Project and initializes its associated Edited Rich Texts */
  createAndInitializeEnglishProject: CreateAndInitializeEnglishProjectPayload;
  /** Creates a new Investing Project and initializes its associated Edited Rich Texts */
  createAndInitializeInvestingProject: CreateAndInitializeInvestingProjectPayload;
  findAndAllocateExperimentsByParticipantId: FindAndAllocateExperimentsByParticipantIdPayload;
  createExperiment: CreateExperimentPayload;
  updateExperiment: UpdateExperimentPayload;
  addTreatmentsToExperiment: AddTreatmentsToExperimentPayload;
  removeTreatmentsFromExperiment: RemoveTreatmentsFromExperimentPayload;
  updateTreatmentsInExperiment: UpdateTreatmentsInExperimentPayload;
  /** Upserts (creates or updates) an English Answer Submission */
  upsertEnglishAnswerSubmission: UpsertEnglishAnswerSubmissionPayload;
  /** Upserts (creates or updates) an Investing Answer Submission */
  upsertInvestingAnswerSubmission: UpsertInvestingAnswerSubmissionPayload;
  /** Starts impersonation */
  startImpersonation: StartImpersonationPayload;
  /** Stops impersonation */
  stopImpersonation: StopImpersonationPayload;
  /** Creates a new prompt */
  createPrompt: CreatePromptPayload;
  /** Updates an existing prompt */
  updatePrompt: UpdatePromptPayload;
  /** Deletes an existing prompt */
  deletePrompt: DeletePromptPayload;
  /** Creates a new Reference Text Set */
  createReferenceTextSet: CreateReferenceTextSetPayload;
  /** Updates an existing Reference Text Set */
  updateReferenceTextSet: UpdateReferenceTextSetPayload;
  /** Creates a new Reference Text */
  createReferenceText: CreateReferenceTextPayload;
  /** Updates an existing Reference Text */
  updateReferenceText: UpdateReferenceTextPayload;
  /** Creates an English Module for an existing English Course & Topic */
  createEnglishModule: CreateEnglishModulePayload;
  /** Updates an existing English Module */
  updateEnglishModule: UpdateEnglishModulePayload;
  /** Deletes an English Module Topic */
  deleteEnglishModule: DeleteEnglishModulePayload;
  /** Creates a new English Module Section for an existing Course > Topic > Module */
  createEnglishModuleSection: CreateEnglishModuleSectionPayload;
  /** Updates an existing English Module Section */
  updateEnglishModuleSection: UpdateEnglishModuleSectionPayload;
  /** Deletes an existing English Module Section */
  deleteEnglishModuleSection: DeleteEnglishModuleSectionPayload;
  /** Removes a Reference Text Set Option from an English Module Section */
  deleteEnglishReferenceTextSetOption: DeleteEnglishReferenceTextSetOptionPayload;
  /** Creates a new English Module Section Bullet for an existing Course > Topic > Module > Module Section */
  createEnglishModuleSectionBullet: CreateEnglishModuleSectionBulletPayload;
  /** Updates an existing English Module Section Bullet */
  updateEnglishModuleSectionBullet: UpdateEnglishModuleSectionBulletPayload;
  /** Deletes an existing English Module Section Bullet */
  deleteEnglishModuleSectionBullet: DeleteEnglishModuleSectionBulletPayload;
  /** Creates an English Course */
  createEnglishCourse: CreateEnglishCoursePayload;
  /** Updates an existing English Course */
  updateEnglishCourse: UpdateEnglishCoursePayload;
  /** Deletes an English Course */
  deleteEnglishCourse: DeleteEnglishCoursePayload;
  /** Creates an English Module Topic for an existing English Course */
  createEnglishModuleTopic: CreateEnglishModuleTopicPayload;
  /** Updates an existing English Module Topic */
  updateEnglishModuleTopic: UpdateEnglishModuleTopicPayload;
  /** Deletes an English Module Topic */
  deleteEnglishModuleTopic: DeleteEnglishModuleTopicPayload;
  /** Creates an Math Module for an existing Math Course & Topic */
  createMathModule: CreateMathModulePayload;
  /** Updates an existing Math Module */
  updateMathModule: UpdateMathModulePayload;
  /** Deletes an Math Module Topic */
  deleteMathModule: DeleteMathModulePayload;
  /** Creates a new Math Module Section for an existing Course > Topic > Module */
  createMathModuleSection: CreateMathModuleSectionPayload;
  /** Updates an existing Math Module Section */
  updateMathModuleSection: UpdateMathModuleSectionPayload;
  /** Deletes an existing Math Module Section */
  deleteMathModuleSection: DeleteMathModuleSectionPayload;
  /** Creates a new Math Module Section Bullet for an existing Course > Topic > Module > Module Section */
  createMathModuleSectionBullet: CreateMathModuleSectionBulletPayload;
  /** Updates an existing Math Module Section Bullet */
  updateMathModuleSectionBullet: UpdateMathModuleSectionBulletPayload;
  /** Deletes an existing Math Module Section Bullet */
  deleteMathModuleSectionBullet: DeleteMathModuleSectionBulletPayload;
  /** Creates an Math Course */
  createMathCourse: CreateMathCoursePayload;
  /** Updates an existing Math Course */
  updateMathCourse: UpdateMathCoursePayload;
  /** Deletes an Math Course */
  deleteMathCourse: DeleteMathCoursePayload;
  /** Creates an Math Module Topic for an existing Math Course */
  createMathModuleTopic: CreateMathModuleTopicPayload;
  /** Updates an existing Math Module Topic */
  updateMathModuleTopic: UpdateMathModuleTopicPayload;
  /** Deletes an Math Module Topic */
  deleteMathModuleTopic: DeleteMathModuleTopicPayload;
  /** Creates an Investing Module for an existing Investing Course & Topic */
  createInvestingModule: CreateInvestingModulePayload;
  /** Updates an existing Investing Module */
  updateInvestingModule: UpdateInvestingModulePayload;
  /** Deletes an Investing Module Topic */
  deleteInvestingModule: DeleteInvestingModulePayload;
  /** Creates a new Investing Module Section for an existing Course > Topic > Module */
  createInvestingModuleSection: CreateInvestingModuleSectionPayload;
  /** Updates an existing Investing Module Section */
  updateInvestingModuleSection: UpdateInvestingModuleSectionPayload;
  /** Deletes an existing Investing Module Section */
  deleteInvestingModuleSection: DeleteInvestingModuleSectionPayload;
  /** Removes a Reference Text Set Option from an Investing Module Section */
  deleteInvestingReferenceTextSetOption: DeleteInvestingReferenceTextSetOptionPayload;
  /** Creates a new Investing Module Section Bullet for an existing Course > Topic > Module > Module Section */
  createInvestingModuleSectionBullet: CreateInvestingModuleSectionBulletPayload;
  /** Updates an existing Investing Module Section Bullet */
  updateInvestingModuleSectionBullet: UpdateInvestingModuleSectionBulletPayload;
  /** Deletes an existing Investing Module Section Bullet */
  deleteInvestingModuleSectionBullet: DeleteInvestingModuleSectionBulletPayload;
  /** Creates an Investing Course */
  createInvestingCourse: CreateInvestingCoursePayload;
  /** Updates an existing Investing Course */
  updateInvestingCourse: UpdateInvestingCoursePayload;
  /** Deletes an Investing Course */
  deleteInvestingCourse: DeleteInvestingCoursePayload;
  /** Creates an Investing Module Topic for an existing Investing Course */
  createInvestingModuleTopic: CreateInvestingModuleTopicPayload;
  /** Updates an existing Investing Module Topic */
  updateInvestingModuleTopic: UpdateInvestingModuleTopicPayload;
  /** Deletes an Investing Module Topic */
  deleteInvestingModuleTopic: DeleteInvestingModuleTopicPayload;
  /** Updates the learning targets for a course rubric */
  updateCourseRubric: UpdateCourseRubricPayload;
  /** Updates student progress on the rubric for a given course */
  updateCourseRubricProgress: UpdateCourseRubricProgressPayload;
  /** Moves a completed course back to current courses */
  uncompleteStudentCourse: UncompleteStudentCoursePayload;
  /** Moves a current course to completed courses */
  completeStudentCourse: CompleteStudentCoursePayload;
  /** Removes a current course for the student */
  removeStudentCourse: RemoveStudentCoursePayload;
  /** Adds a new current course for the student */
  addStudentCourse: AddStudentCoursePayload;
  /** Updates a current course with a new one and can complete the course */
  updateStudentCourse: UpdateStudentCoursePayload;
  /** Accepts a student proposal from onboarding/handoff */
  acceptStudentProposal: AcceptStudentProposalPayload;
  /** Declines a student proposal from onboarding/handoff */
  declineStudentProposal: DeclineStudentProposalPayload;
  /** Sends student proposals for pending instructors in a handoff ticket */
  sendHandoffStudentProposals: SendHandoffStudentProposalsPayload;
  /** Sends student proposals for pending instructors in an onboarding ticket */
  sendOnboardingStudentProposals: SendOnboardingStudentProposalsPayload;
  /** Sends an email for student onboarding */
  sendOnboardingEmail: SendOnboardingEmailPayload;
  /** Sends an email for student handoff */
  sendHandoffEmail: SendHandoffEmailPayload;
  /** Update user profile */
  updateUserProfile: UpdateUserProfilePayload;
  /** Updates a student project with new details */
  updateStudentProject: UpdateStudentProjectPayload;
  /** Features a student project by forking the current state of the project */
  featureStudentProject: FeatureStudentProjectPayload;
  /** Deletes the featured project */
  unfeatureStudentProject: UnfeatureStudentProjectPayload;
  createOpenChallenge: CreateOpenChallengePayload;
  updateOpenChallenge: UpdateOpenChallengePayload;
  deleteOpenChallenge: DeleteOpenChallengePayload;
  /** publishes featured projects to juniverse homepage */
  publishFeaturedProjectsToJuniverse: PublishFeaturedProjectsToJuniversePayload;
  /** Records a view on a project */
  recordProjectView: RecordProjectViewPayload;
  /** Find and/or update project share settings described in StudentProject DB Object */
  updateProjectShareSettings: UpdateProjectShareSettingsPayload;
  /** Sends a slack message to an instructor HQ channel */
  sendSlackMessageToInstructor: SendSlackMessageToInstructorPayload;
  /** Gets a slack user id for an instructor */
  slackUserIdForInstructor: SlackUserIdForInstructorPayload;
  /** Updates the user's SMS consent */
  updateUserSmsConsent: UpdateUserSmsConsentPayload;
  /** Sends an sms message to a parent */
  sendSmsMessageToParent: SendSmsMessageToParentPayload;
  /** Create a new Stripe subscription */
  createStripeSubscription: CreateStripeSubscriptionPayload;
  /** Cancel an existing Stripe subscription */
  cancelStripeSubscription: CancelStripeSubscriptionPayload;
  /** Downgrade an existing Stripe subscription */
  downgradeStripeSubscription: DowngradeStripeSubscriptionPayload;
  /** Purchase a bootcamp product */
  purchaseBootcamp: PurchaseBootcampPayload;
  /** Updates all student fields for admins and parents. */
  updateStudent: UpdateStudentPayload;
  /** Requests a signed upload URL for S3 */
  requestUploadUrl: RequestUploadUrlPayload;
  /** Creates a recurring availability update request and related instructor log */
  createRecurringAvailabilityUpdateRequest: CreateRecurringAvailabilityUpdateRequestPayload;
  /** Creates a recurring reschedule request (for the requesting instructor) and related instructor / customer logs */
  createMyRecurringRescheduleRequest: CreateMyRecurringRescheduleRequestPayload;
  /** Creates a recurring reschedule request and related instructor / customer logs */
  createRecurringRescheduleRequest: CreateRecurringRescheduleRequestPayload;
  /** Updates a recurring reschedule request and related instructor / customer logs */
  updateRecurringRescheduleRequest: UpdateRecurringRescheduleRequestPayload;
  /** Updates a recurring reschedule request */
  updateMyRecurringRescheduleRequest: UpdateMyRecurringRescheduleRequestPayload;
  /** Creates a parent recurring reschedule request */
  CreateParentRecurringRescheduleRequest: CreateParentRecurringRescheduleRequestPayload;
  /** Creates a new InstructorOnboarding */
  createInstructorOnboarding: CreateInstructorOnboardingPayload;
  /** Updates an InstructorOnboarding */
  updateInstructorOnboarding: UpdateInstructorOnboardingPayload;
  /** Updates an InstructorOnboarding task */
  updateInstructorOnboardingTask: UpdateInstructorOnboardingTaskPayload;
  /** Schedules Instructors acuity appointment */
  scheduleInstructorOnboardingAppt: ScheduleInstructorOnboardingApptPayload;
  /** Reschedules acuity appointment */
  rescheduleInstructorOnboardingAppt: RescheduleInstructorOnboardingApptPayload;
  /** Cancels Instructors acuity appointment */
  cancelInstructorOnboardingAppt: CancelInstructorOnboardingApptPayload;
  /** Get the html data necessary for previewing and sending session notes. */
  getPostSessionDashboardEmailHtml: GetPostSessionDashboardEmailHtmlPayload;
  /** Updates a TeacherSession. */
  updateTeacherSessionForPostSessionEmail: UpdateTeacherSessionForPostSessionEmailPayload;
  /** Sends a post-session email */
  sendPostSessionEmail: SendPostSessionEmailPayload;
  createInstructorAchievement: CreateInstructorAchievementPayload;
  updateInstructorAchievement: UpdateInstructorAchievementPayload;
  deleteInstructorAchievement: DeleteInstructorAchievementPayload;
  grantInstructorAchievement: GrantInstructorAchievementPayload;
  /** Creates a new Session Feedback document */
  createSessionFeedback: CreateSessionFeedbackPayload;
  /** Completes a session feedback by setting a timestamp and the response (if any) */
  completeSessionFeedback: CompleteSessionFeedbackPayload;
  /** Updates or creates a new Juni Ad and Campaign */
  upsertJuniAdAndCampaign: UpsertJuniAdAndCampaignPayload;
  /** Archives (soft deletes) Juni Ad Campaign */
  archiveJuniAdCampaign: ArchiveJuniAdCampaignPayload;
  /** Emails a Juni Club Invitation to the desired email */
  sendJuniClubInvitation: SendJuniClubInvitationPayload;
  /** Looks up Juni Club Invitation and registers email click */
  registerJuniClubInvitationEmailClick: RegisterJuniClubInvitationEmailClickPayload;
  /** Accepts or Rejects Juni Club Invitation from invitation card and adds user to club */
  acceptRejectJuniClubInvitation: AcceptRejectJuniClubInvitationPayload;
  /** Executes upon user clicking submit on Guest Account Registration form */
  submitGuestAccountRegistration: SubmitGuestAccountRegistrationPayload;
  /** Executes when user clicks the CTA banner in their guest account to enroll with Juni */
  registerCtaBannerClick: RegisterCtaBannerClickPayload;
  /** Executes when student is under the age of 13 enters their parents email to enroll with a parent since they are not eligible for a guest account.  */
  sendParentRegistrationEmail: SendParentRegistrationEmailPayload;
  /** Create a new Club */
  createClub: CreateClubPayload;
  /** Endpoint for admins to update a clubs metadata, such as its tags */
  updateClubMetadata: UpdateClubMetadataPayload;
  /** Update an existing Club */
  updateClub: UpdateClubPayload;
  /** Create a channel in a Juni Club */
  createChannel: CreateChannelPayload;
  /** Edit a channel */
  editChannel: EditChannelPayload;
  /** Archive a channel in a Juni Club */
  archiveChannel: ArchiveChannelPayload;
  /** Remove a violating message */
  removeViolatingMessage: RemoveViolatingMessagePayload;
  /** Allows users to delete their sent messages and for moderators to delete any message */
  deleteMessage: DeleteMessagePayload;
  /** Update membership status */
  updateMembershipStatus: UpdateMembershipStatusPayload;
  /** Use automod to to check the images from the URLs */
  checkImageContent: CheckImageContentPayload;
  /** Use automod to check club message content if needed */
  checkMessageContent: CheckMessageContentPayload;
  /** Send an image upload notification to slack for moderation purposes */
  sendImageUploadSlackMessage: SendImageUploadSlackMessagePayload;
  /** Join or Leave a Club */
  joinLeaveClub: JoinLeaveClubPayload;
  /** Get state of Juni Clubs and all members */
  myClubsState: MyClubsStatePayload;
  /** Update last read timetoken for a Juni Club channel */
  updateLastReadTimetoken: UpdateLastReadTimetokenPayload;
  /** Authorize user with PubNub and return connection details */
  refreshPubNubCredentials: RefreshPubNubCredentialsPayload;
  /** Create new parent corner announcements and update any existing announcement */
  updateParentCornerAnnouncements: UpdateParentCornerAnnouncementsPayload;
  createJuniCommunityEvent: CreateJuniCommunityEventPayload;
  updateJuniCommunityEvent: UpdateJuniCommunityEventPayload;
  /** Create a Juni Bootcamp course */
  createBootcampCourse: CreateBootcampCoursePayload;
  /** Update metadata for a Juni Bootcamp course */
  updateBootcampCourse: UpdateBootcampCoursePayload;
  /** Set isAcceptingEnrollment to true for the course */
  enableEnrollment: EnableEnrollmentPayload;
  /** Set isAcceptingEnrollment to false for the course */
  disableEnrollment: DisableEnrollmentPayload;
  /** Create a Juni Bootcamp course offering */
  createCourseOffering: CreateCourseOfferingPayload;
  /** Adjust billing, cancel appointments, and create logs */
  adjustBilling: AdjustBillingPayload;
  /** Send Referrral Invite email and create a referral invite object */
  sendReferralInvite: SendReferralInvitePayload;
  /** Send Reminder email. */
  sendReferralReminderEmail: SendReferralReminderEmailPayload;
  /** Updates a referral redemption */
  updateReferralRedemption: ReferralRedemption;
  /** Create new notes from HQ and update any existing note */
  upsertNotesFromHQ: UpsertNotesFromHqPayload;
  /** Deletes an existing note from HQ */
  deleteNoteFromHQ: DeleteNoteFromHqPayload;
  /** Marks a module section as in progress and the related module for the specified student. */
  markOnDemandModuleSectionInProgress: MarkOnDemandModuleSectionInProgressPayload;
  /**
   * Takes a one-time code from Nylas auth on the frontend and gets an access_token, which is then saved. 
   *      * see https://docs.nylas.com/docs/hosted-authentication#server-side-code
   */
  submitNylasCode: SubmitNylasCodePayload;
  /** disconnects the user from Nylas */
  disconnectNylas?: Maybe<DisconnectNylasPayload>;
  /** Push or pull a calendar to/from the list of calendar ids to pull busy events from */
  setNylasCalendarWatched: SetNylasCalendarWatchedPayload;
  /** Sync all calendars for Instructor */
  syncCalendars: SyncCalendarsPayload;
  /** schedules an additional class (either a makeup class or a classpack class) */
  scheduleAdditionalClass: ScheduleAdditionalClassPayload;
  /** reschedules an acuity appointment in a time-turner-esque fashion */
  rescheduleClass: RescheduleClassPayload;
};


export type MutationDeleteS3ObjectArgs = {
  input: DeleteS3ObjectInput;
};


export type MutationCreateStudentArgs = {
  input: CreateStudentInput;
};


export type MutationPrependStudentNotesArgs = {
  input: PrependStudentNotesInput;
};


export type MutationUpdateLearningPreferencesArgs = {
  input: UpdateLearningPreferencesInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};


export type MutationUpdateNameArgs = {
  input: UpdateNameInput;
};


export type MutationUpsertAcuityAppointmentNotificationArgs = {
  input: UpsertAcuityAppointmentNotificationInput;
};


export type MutationCancelStudentAppointmentsArgs = {
  input: CancelStudentAppointmentsInput;
};


export type MutationRescheduleStudentAppointmentsArgs = {
  input: RescheduleStudentAppointmentsInput;
};


export type MutationScheduleStudentAppointmentsArgs = {
  input: ScheduleStudentAppointmentsInput;
};


export type MutationCreateEditedRichTextArgs = {
  input: CreateEditedRichTextInput;
};


export type MutationUpdateEditedRichTextArgs = {
  input: UpdateEditedRichTextInput;
};


export type MutationCreateAndInitializeEnglishProjectArgs = {
  input: CreateAndInitializeEnglishProjectInput;
};


export type MutationCreateAndInitializeInvestingProjectArgs = {
  input: CreateAndInitializeInvestingProjectInput;
};


export type MutationFindAndAllocateExperimentsByParticipantIdArgs = {
  input: FindAndAllocateExperimentsByParticipantIdInput;
};


export type MutationCreateExperimentArgs = {
  input: CreateExperimentInput;
};


export type MutationUpdateExperimentArgs = {
  input: UpdateExperimentInput;
};


export type MutationAddTreatmentsToExperimentArgs = {
  input: AddTreatmentsToExperimentInput;
};


export type MutationRemoveTreatmentsFromExperimentArgs = {
  input: RemoveTreatmentsFromExperimentInput;
};


export type MutationUpdateTreatmentsInExperimentArgs = {
  input: UpdateTreatmentsInExperimentInput;
};


export type MutationUpsertEnglishAnswerSubmissionArgs = {
  input: UpsertEnglishAnswerSubmissionInput;
};


export type MutationUpsertInvestingAnswerSubmissionArgs = {
  input: UpsertInvestingAnswerSubmissionInput;
};


export type MutationStartImpersonationArgs = {
  input: StartImpersonationInput;
};


export type MutationCreatePromptArgs = {
  input: CreatePromptInput;
};


export type MutationUpdatePromptArgs = {
  input: UpdatePromptInput;
};


export type MutationDeletePromptArgs = {
  input: DeletePromptInput;
};


export type MutationCreateReferenceTextSetArgs = {
  input: CreateReferenceTextSetInput;
};


export type MutationUpdateReferenceTextSetArgs = {
  input: UpdateReferenceTextSetInput;
};


export type MutationCreateReferenceTextArgs = {
  input: CreateReferenceTextInput;
};


export type MutationUpdateReferenceTextArgs = {
  input: UpdateReferenceTextInput;
};


export type MutationCreateEnglishModuleArgs = {
  input: CreateEnglishModuleInput;
};


export type MutationUpdateEnglishModuleArgs = {
  input: UpdateEnglishModuleInput;
};


export type MutationDeleteEnglishModuleArgs = {
  input: DeleteEnglishModuleInput;
};


export type MutationCreateEnglishModuleSectionArgs = {
  input: CreateEnglishModuleSectionInput;
};


export type MutationUpdateEnglishModuleSectionArgs = {
  input: UpdateEnglishModuleSectionInput;
};


export type MutationDeleteEnglishModuleSectionArgs = {
  input: DeleteEnglishModuleSectionInput;
};


export type MutationDeleteEnglishReferenceTextSetOptionArgs = {
  input: DeleteEnglishReferenceTextSetOptionInput;
};


export type MutationCreateEnglishModuleSectionBulletArgs = {
  input: CreateEnglishModuleSectionBulletInput;
};


export type MutationUpdateEnglishModuleSectionBulletArgs = {
  input: UpdateEnglishModuleSectionBulletInput;
};


export type MutationDeleteEnglishModuleSectionBulletArgs = {
  input: DeleteEnglishModuleSectionBulletInput;
};


export type MutationCreateEnglishCourseArgs = {
  input: CreateEnglishCourseInput;
};


export type MutationUpdateEnglishCourseArgs = {
  input: UpdateEnglishCourseInput;
};


export type MutationDeleteEnglishCourseArgs = {
  input: DeleteEnglishCourseInput;
};


export type MutationCreateEnglishModuleTopicArgs = {
  input: CreateEnglishModuleTopicInput;
};


export type MutationUpdateEnglishModuleTopicArgs = {
  input: UpdateEnglishModuleTopicInput;
};


export type MutationDeleteEnglishModuleTopicArgs = {
  input: DeleteEnglishModuleTopicInput;
};


export type MutationCreateMathModuleArgs = {
  input: CreateMathModuleInput;
};


export type MutationUpdateMathModuleArgs = {
  input: UpdateMathModuleInput;
};


export type MutationDeleteMathModuleArgs = {
  input: DeleteMathModuleInput;
};


export type MutationCreateMathModuleSectionArgs = {
  input: CreateMathModuleSectionInput;
};


export type MutationUpdateMathModuleSectionArgs = {
  input: UpdateMathModuleSectionInput;
};


export type MutationDeleteMathModuleSectionArgs = {
  input: DeleteMathModuleSectionInput;
};


export type MutationCreateMathModuleSectionBulletArgs = {
  input: CreateMathModuleSectionBulletInput;
};


export type MutationUpdateMathModuleSectionBulletArgs = {
  input: UpdateMathModuleSectionBulletInput;
};


export type MutationDeleteMathModuleSectionBulletArgs = {
  input: DeleteMathModuleSectionBulletInput;
};


export type MutationCreateMathCourseArgs = {
  input: CreateMathCourseInput;
};


export type MutationUpdateMathCourseArgs = {
  input: UpdateMathCourseInput;
};


export type MutationDeleteMathCourseArgs = {
  input: DeleteMathCourseInput;
};


export type MutationCreateMathModuleTopicArgs = {
  input: CreateMathModuleTopicInput;
};


export type MutationUpdateMathModuleTopicArgs = {
  input: UpdateMathModuleTopicInput;
};


export type MutationDeleteMathModuleTopicArgs = {
  input: DeleteMathModuleTopicInput;
};


export type MutationCreateInvestingModuleArgs = {
  input: CreateInvestingModuleInput;
};


export type MutationUpdateInvestingModuleArgs = {
  input: UpdateInvestingModuleInput;
};


export type MutationDeleteInvestingModuleArgs = {
  input: DeleteInvestingModuleInput;
};


export type MutationCreateInvestingModuleSectionArgs = {
  input: CreateInvestingModuleSectionInput;
};


export type MutationUpdateInvestingModuleSectionArgs = {
  input: UpdateInvestingModuleSectionInput;
};


export type MutationDeleteInvestingModuleSectionArgs = {
  input: DeleteInvestingModuleSectionInput;
};


export type MutationDeleteInvestingReferenceTextSetOptionArgs = {
  input: DeleteInvestingReferenceTextSetOptionInput;
};


export type MutationCreateInvestingModuleSectionBulletArgs = {
  input: CreateInvestingModuleSectionBulletInput;
};


export type MutationUpdateInvestingModuleSectionBulletArgs = {
  input: UpdateInvestingModuleSectionBulletInput;
};


export type MutationDeleteInvestingModuleSectionBulletArgs = {
  input: DeleteInvestingModuleSectionBulletInput;
};


export type MutationCreateInvestingCourseArgs = {
  input: CreateInvestingCourseInput;
};


export type MutationUpdateInvestingCourseArgs = {
  input: UpdateInvestingCourseInput;
};


export type MutationDeleteInvestingCourseArgs = {
  input: DeleteInvestingCourseInput;
};


export type MutationCreateInvestingModuleTopicArgs = {
  input: CreateInvestingModuleTopicInput;
};


export type MutationUpdateInvestingModuleTopicArgs = {
  input: UpdateInvestingModuleTopicInput;
};


export type MutationDeleteInvestingModuleTopicArgs = {
  input: DeleteInvestingModuleTopicInput;
};


export type MutationUpdateCourseRubricArgs = {
  input: UpdateCourseRubricInput;
};


export type MutationUpdateCourseRubricProgressArgs = {
  input: UpdateCourseRubricProgressInput;
};


export type MutationUncompleteStudentCourseArgs = {
  input: UncompleteStudentCourseInput;
};


export type MutationCompleteStudentCourseArgs = {
  input: CompleteStudentCourseInput;
};


export type MutationRemoveStudentCourseArgs = {
  input: RemoveStudentCourseInput;
};


export type MutationAddStudentCourseArgs = {
  input: AddStudentCourseInput;
};


export type MutationUpdateStudentCourseArgs = {
  input: UpdateStudentCourseInput;
};


export type MutationAcceptStudentProposalArgs = {
  input: AcceptStudentProposalInput;
};


export type MutationDeclineStudentProposalArgs = {
  input: DeclineStudentProposalInput;
};


export type MutationSendHandoffStudentProposalsArgs = {
  input: SendHandoffStudentProposalsInput;
};


export type MutationSendOnboardingStudentProposalsArgs = {
  input: SendOnboardingStudentProposalsInput;
};


export type MutationSendOnboardingEmailArgs = {
  input: SendOnboardingEmailInput;
};


export type MutationSendHandoffEmailArgs = {
  input: SendHandoffEmailInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateStudentProjectArgs = {
  input: UpdateStudentProjectInput;
};


export type MutationFeatureStudentProjectArgs = {
  input: FeatureStudentProjectInput;
};


export type MutationUnfeatureStudentProjectArgs = {
  input: UnfeatureStudentProjectInput;
};


export type MutationCreateOpenChallengeArgs = {
  input: CreateOpenChallengeInput;
};


export type MutationUpdateOpenChallengeArgs = {
  input: UpdateOpenChallengeInput;
};


export type MutationDeleteOpenChallengeArgs = {
  input: DeleteOpenChallengeInput;
};


export type MutationPublishFeaturedProjectsToJuniverseArgs = {
  input: PublishFeaturedProjectsToJuniverseInput;
};


export type MutationRecordProjectViewArgs = {
  input: RecordProjectViewInput;
};


export type MutationUpdateProjectShareSettingsArgs = {
  input: UpdateProjectShareSettingsInput;
};


export type MutationSendSlackMessageToInstructorArgs = {
  input: SendSlackMessageToInstructorInput;
};


export type MutationSlackUserIdForInstructorArgs = {
  input: SlackUserIdForInstructorInput;
};


export type MutationUpdateUserSmsConsentArgs = {
  input: UpdateUserSmsConsentInput;
};


export type MutationSendSmsMessageToParentArgs = {
  input: SendSmsMessageToParentInput;
};


export type MutationCreateStripeSubscriptionArgs = {
  input: CreateStripeSubscriptionInput;
};


export type MutationCancelStripeSubscriptionArgs = {
  input: CancelStripeSubscriptionInput;
};


export type MutationDowngradeStripeSubscriptionArgs = {
  input: DowngradeStripeSubscriptionInput;
};


export type MutationPurchaseBootcampArgs = {
  input: PurchaseBootcampInput;
};


export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};


export type MutationRequestUploadUrlArgs = {
  input: RequestUploadUrlInput;
};


export type MutationCreateRecurringAvailabilityUpdateRequestArgs = {
  input: CreateRecurringAvailabilityUpdateRequestInput;
};


export type MutationCreateMyRecurringRescheduleRequestArgs = {
  input: CreateMyRecurringRescheduleRequestInput;
};


export type MutationCreateRecurringRescheduleRequestArgs = {
  input: CreateRecurringRescheduleRequestInput;
};


export type MutationUpdateRecurringRescheduleRequestArgs = {
  input: UpdateRecurringRescheduleRequestInput;
};


export type MutationUpdateMyRecurringRescheduleRequestArgs = {
  input: UpdateMyRecurringRescheduleRequestInput;
};


export type MutationCreateParentRecurringRescheduleRequestArgs = {
  input: CreateParentRecurringRescheduleRequestInput;
};


export type MutationCreateInstructorOnboardingArgs = {
  input: CreateInstructorOnboardingInput;
};


export type MutationUpdateInstructorOnboardingArgs = {
  input: UpdateInstructorOnboardingInput;
};


export type MutationUpdateInstructorOnboardingTaskArgs = {
  input: UpdateInstructorOnboardingTaskInput;
};


export type MutationScheduleInstructorOnboardingApptArgs = {
  input: ScheduleInstructorOnboardingApptInput;
};


export type MutationRescheduleInstructorOnboardingApptArgs = {
  input: RescheduleInstructorOnboardingApptInput;
};


export type MutationCancelInstructorOnboardingApptArgs = {
  input: CancelInstructorOnboardingApptInput;
};


export type MutationGetPostSessionDashboardEmailHtmlArgs = {
  input: GetPostSessionDashboardEmailHtmlInput;
};


export type MutationUpdateTeacherSessionForPostSessionEmailArgs = {
  input: UpdateTeacherSessionForPostSessionEmailInput;
};


export type MutationSendPostSessionEmailArgs = {
  input: SendPostSessionEmailInput;
};


export type MutationCreateInstructorAchievementArgs = {
  input: CreateInstructorAchievementInput;
};


export type MutationUpdateInstructorAchievementArgs = {
  input: UpdateInstructorAchievementInput;
};


export type MutationDeleteInstructorAchievementArgs = {
  input: DeleteInstructorAchievementInput;
};


export type MutationGrantInstructorAchievementArgs = {
  input: GrantInstructorAchievementInput;
};


export type MutationCreateSessionFeedbackArgs = {
  input: CreateSessionFeedbackInput;
};


export type MutationCompleteSessionFeedbackArgs = {
  input: CompleteSessionFeedbackInput;
};


export type MutationUpsertJuniAdAndCampaignArgs = {
  input: UpsertJuniAdAndCampaignInput;
};


export type MutationArchiveJuniAdCampaignArgs = {
  input: ArchiveJuniAdCampaignInput;
};


export type MutationSendJuniClubInvitationArgs = {
  input: SendJuniClubInvitationInput;
};


export type MutationRegisterJuniClubInvitationEmailClickArgs = {
  input: RegisterJuniClubInvitationEmailClickInput;
};


export type MutationAcceptRejectJuniClubInvitationArgs = {
  input: AcceptRejectJuniClubInvitationInput;
};


export type MutationSubmitGuestAccountRegistrationArgs = {
  input: SubmitGuestAccountRegistrationInput;
};


export type MutationRegisterCtaBannerClickArgs = {
  input: RegisterCtaBannerClickInput;
};


export type MutationSendParentRegistrationEmailArgs = {
  input: SendParentRegistrationEmailInput;
};


export type MutationCreateClubArgs = {
  input: CreateClubInput;
};


export type MutationUpdateClubMetadataArgs = {
  input: UpdateClubMetadataInput;
};


export type MutationUpdateClubArgs = {
  input: UpdateClubInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationEditChannelArgs = {
  input: EditChannelInput;
};


export type MutationArchiveChannelArgs = {
  input: ArchiveChannelInput;
};


export type MutationRemoveViolatingMessageArgs = {
  input: RemoveViolatingMessageInput;
};


export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};


export type MutationUpdateMembershipStatusArgs = {
  input: UpdateMembershipStatusInput;
};


export type MutationCheckImageContentArgs = {
  input: CheckImageContentInput;
};


export type MutationCheckMessageContentArgs = {
  input: CheckMessageContentInput;
};


export type MutationSendImageUploadSlackMessageArgs = {
  input: SendImageUploadSlackMessageInput;
};


export type MutationJoinLeaveClubArgs = {
  input: JoinLeaveClubInput;
};


export type MutationMyClubsStateArgs = {
  input: MyClubsStateInput;
};


export type MutationUpdateLastReadTimetokenArgs = {
  input: UpdateLastReadTimetokenInput;
};


export type MutationRefreshPubNubCredentialsArgs = {
  input: RefreshPubNubCredentialsInput;
};


export type MutationUpdateParentCornerAnnouncementsArgs = {
  input: UpdateParentCornerAnnouncementsInput;
};


export type MutationCreateJuniCommunityEventArgs = {
  input: CreateJuniCommunityEventInput;
};


export type MutationUpdateJuniCommunityEventArgs = {
  input: UpdateJuniCommunityEventInput;
};


export type MutationCreateBootcampCourseArgs = {
  input: CreateBootcampCourseInput;
};


export type MutationUpdateBootcampCourseArgs = {
  input: UpdateBootcampCourseInput;
};


export type MutationEnableEnrollmentArgs = {
  input: EnableEnrollmentInput;
};


export type MutationDisableEnrollmentArgs = {
  input: DisableEnrollmentInput;
};


export type MutationCreateCourseOfferingArgs = {
  input: CreateCourseOfferingInput;
};


export type MutationAdjustBillingArgs = {
  input: AdjustBillingInput;
};


export type MutationSendReferralInviteArgs = {
  input: SendReferralInviteInput;
};


export type MutationSendReferralReminderEmailArgs = {
  input: SendReferralReminderEmailInput;
};


export type MutationUpdateReferralRedemptionArgs = {
  input: UpdateReferralRedemptionInput;
};


export type MutationUpsertNotesFromHqArgs = {
  input: UpsertNotesFromHqInput;
};


export type MutationDeleteNoteFromHqArgs = {
  input: DeleteNoteFromHqInput;
};


export type MutationMarkOnDemandModuleSectionInProgressArgs = {
  input: MarkOnDemandModuleSectionInProgressInput;
};


export type MutationSubmitNylasCodeArgs = {
  input: SubmitNylasCodeInput;
};


export type MutationSetNylasCalendarWatchedArgs = {
  input: SetNylasCalendarWatchedInput;
};


export type MutationSyncCalendarsArgs = {
  input: SyncCalendarsInput;
};


export type MutationScheduleAdditionalClassArgs = {
  input: ScheduleAdditionalClassInput;
};


export type MutationRescheduleClassArgs = {
  input: RescheduleClassInput;
};

export type CreateCourseOfferingMutationVariables = Exact<{
  input: CreateCourseOfferingInput;
}>;


export type CreateCourseOfferingMutation = (
  { __typename?: 'Mutation' }
  & { createCourseOffering: (
    { __typename?: 'CreateCourseOfferingPayload' }
    & Pick<CreateCourseOfferingPayload, 'success'>
  ) }
);

export type GetOfferingsByCourseIdQueryVariables = Exact<{
  courseId: Scalars['ObjectId'];
}>;


export type GetOfferingsByCourseIdQuery = (
  { __typename?: 'Query' }
  & { getCourse?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'displayName'>
    & CourseOfferingsListFragment
  )> }
);

export type GetOfferingsByCourseNameQueryVariables = Exact<{
  courseName: Scalars['String'];
}>;


export type GetOfferingsByCourseNameQuery = (
  { __typename?: 'Query' }
  & { getCourse?: Maybe<(
    { __typename?: 'Course' }
    & CourseOfferingsListFragment
  )> }
);

export type CourseOfferingsListFragment = (
  { __typename?: 'Course' }
  & { offerings: Array<(
    { __typename?: 'CourseOffering' }
    & CourseOfferingFragment
  )> }
);

export type CourseOfferingFragment = (
  { __typename?: 'CourseOffering' }
  & Pick<CourseOffering, '_id' | 'courseId' | 'courseName' | 'instructorId' | 'totalSeats' | 'numSeatsAvailable' | 'enrollmentEndDate' | 'totalPriceInCents'>
  & { schedule: Array<(
    { __typename?: 'CourseOfferingScheduleItem' }
    & Pick<CourseOfferingScheduleItem, 'startTime' | 'appointmentTypeId'>
  )> }
);

export type CreateBootcampCourseMutationVariables = Exact<{
  input: CreateBootcampCourseInput;
}>;


export type CreateBootcampCourseMutation = (
  { __typename?: 'Mutation' }
  & { createBootcampCourse: (
    { __typename?: 'CreateBootcampCoursePayload' }
    & Pick<CreateBootcampCoursePayload, 'name' | '_id'>
  ) }
);

export type UpdateBootcampCourseMutationVariables = Exact<{
  input: UpdateBootcampCourseInput;
}>;


export type UpdateBootcampCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateBootcampCourse: (
    { __typename?: 'UpdateBootcampCoursePayload' }
    & Pick<UpdateBootcampCoursePayload, 'success'>
  ) }
);

export type EnableEnrollmentMutationVariables = Exact<{
  input: EnableEnrollmentInput;
}>;


export type EnableEnrollmentMutation = (
  { __typename?: 'Mutation' }
  & { enableEnrollment: (
    { __typename?: 'EnableEnrollmentPayload' }
    & Pick<EnableEnrollmentPayload, 'success'>
  ) }
);

export type DisableEnrollmentMutationVariables = Exact<{
  input: DisableEnrollmentInput;
}>;


export type DisableEnrollmentMutation = (
  { __typename?: 'Mutation' }
  & { disableEnrollment: (
    { __typename?: 'DisableEnrollmentPayload' }
    & Pick<DisableEnrollmentPayload, 'success'>
  ) }
);

export type GetCourseByIdForCourseCreatorQueryVariables = Exact<{
  courseId: Scalars['ObjectId'];
}>;


export type GetCourseByIdForCourseCreatorQuery = (
  { __typename?: 'Query' }
  & { getCourse?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'displayName' | 'name' | 'homeworkInformation' | 'schedulingFormat' | 'courseDescription' | 'isAcceptingEnrollment'>
    & { headerImage?: Maybe<(
      { __typename?: 'CourseImage' }
      & Pick<CourseImage, 'original'>
    )>, subject: (
      { __typename?: 'Subject' }
      & Pick<Subject, '_id' | 'name' | 'displayName'>
    ), offerings: Array<(
      { __typename?: 'CourseOffering' }
      & Pick<CourseOffering, '_id' | 'courseId' | 'courseName' | 'instructorId' | 'totalSeats' | 'numSeatsAvailable' | 'enrollmentEndDate' | 'totalPriceInCents'>
      & { schedule: Array<(
        { __typename?: 'CourseOfferingScheduleItem' }
        & Pick<CourseOfferingScheduleItem, 'duration' | 'startTime' | 'placeholderAcuityApptId' | 'appointmentTypeId'>
      )> }
    )> }
  )> }
);

export type NestedRubricItemFragmentFragment = (
  { __typename?: 'NestedRubricItem' }
  & Pick<NestedRubricItem, '_id' | 'name' | 'sortKey'>
);

export type CourseRubricFragmentFragment = (
  { __typename?: 'CourseRubric' }
  & Pick<CourseRubric, '_id' | 'courseId' | 'isActive'>
  & { items?: Maybe<Array<(
    { __typename?: 'NestedRubricItem' }
    & { subitems?: Maybe<Array<(
      { __typename?: 'NestedRubricItem' }
      & { subitems?: Maybe<Array<(
        { __typename?: 'NestedRubricItem' }
        & { subitems?: Maybe<Array<(
          { __typename?: 'NestedRubricItem' }
          & NestedRubricItemFragmentFragment
        )>> }
        & NestedRubricItemFragmentFragment
      )>> }
      & NestedRubricItemFragmentFragment
    )>> }
    & NestedRubricItemFragmentFragment
  )>> }
);

export type LoadCourseRubricQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadCourseRubricQuery = (
  { __typename?: 'Query' }
  & { courseRubric?: Maybe<(
    { __typename?: 'CourseRubric' }
    & CourseRubricFragmentFragment
  )> }
);

export type SaveCourseRubricMutationVariables = Exact<{
  input: UpdateCourseRubricInput;
}>;


export type SaveCourseRubricMutation = (
  { __typename?: 'Mutation' }
  & { updateCourseRubric: (
    { __typename?: 'UpdateCourseRubricPayload' }
    & { courseRubric: (
      { __typename?: 'CourseRubric' }
      & CourseRubricFragmentFragment
    ) }
  ) }
);

export type EnglishCourseFragmentFragment = (
  { __typename?: 'EnglishCourse' }
  & Pick<EnglishCourse, '_id' | 'name' | 'displayName' | 'abbreviation' | 'order' | 'isActive'>
  & { moduleTopics: Array<(
    { __typename?: 'EnglishModuleTopic' }
    & Pick<EnglishModuleTopic, '_id' | 'name' | 'displayName'>
    & { modules: Array<(
      { __typename?: 'EnglishModule' }
      & Pick<EnglishModule, '_id' | 'name' | 'displayName' | 'isTrialModule'>
      & { moduleSections: Array<(
        { __typename?: 'EnglishModuleSection' }
        & Pick<EnglishModuleSection, '_id' | 'displayName' | 'sectionType' | 'isSupplemental' | 'writingPromptId' | 'instructions'>
        & { referenceTextSetOptions: Array<(
          { __typename?: 'EnglishReferenceTextSetOption' }
          & Pick<EnglishReferenceTextSetOption, '_id' | 'referenceTextSetId'>
        )>, moduleSectionBullets: Array<(
          { __typename?: 'EnglishModuleSectionBullet' }
          & Pick<EnglishModuleSectionBullet, '_id' | 'text' | 'problemType' | 'solutionExplanation'>
          & { mcAnswerOptions: Array<(
            { __typename?: 'EnglishMultipleChoiceAnswerOption' }
            & Pick<EnglishMultipleChoiceAnswerOption, '_id' | 'answerChoiceText'>
          )>, mcCorrectAnswer: Array<(
            { __typename?: 'EnglishMultipleChoiceCorrectAnswer' }
            & Pick<EnglishMultipleChoiceCorrectAnswer, '_id' | 'solutionText'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type LoadEnglishCourseByIdQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadEnglishCourseByIdQuery = (
  { __typename?: 'Query' }
  & { englishCourseById?: Maybe<(
    { __typename?: 'EnglishCourse' }
    & EnglishCourseFragmentFragment
  )> }
);

export type CountEnglishCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountEnglishCoursesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'englishCoursesCount'>
);

export type CreateEnglishCourseMutationVariables = Exact<{
  input: CreateEnglishCourseInput;
}>;


export type CreateEnglishCourseMutation = (
  { __typename?: 'Mutation' }
  & { createEnglishCourse: (
    { __typename?: 'CreateEnglishCoursePayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type UpdateEnglishCourseMutationVariables = Exact<{
  input: UpdateEnglishCourseInput;
}>;


export type UpdateEnglishCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateEnglishCourse: (
    { __typename?: 'UpdateEnglishCoursePayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type DeleteEnglishCourseMutationVariables = Exact<{
  input: DeleteEnglishCourseInput;
}>;


export type DeleteEnglishCourseMutation = (
  { __typename?: 'Mutation' }
  & { deleteEnglishCourse: (
    { __typename?: 'DeleteEnglishCoursePayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type CreateEnglishModuleTopicMutationVariables = Exact<{
  input: CreateEnglishModuleTopicInput;
}>;


export type CreateEnglishModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { createEnglishModuleTopic: (
    { __typename?: 'CreateEnglishModuleTopicPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type UpdateEnglishModuleTopicMutationVariables = Exact<{
  input: UpdateEnglishModuleTopicInput;
}>;


export type UpdateEnglishModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { updateEnglishModuleTopic: (
    { __typename?: 'UpdateEnglishModuleTopicPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type DeleteEnglishModuleTopicMutationVariables = Exact<{
  input: DeleteEnglishModuleTopicInput;
}>;


export type DeleteEnglishModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { deleteEnglishModuleTopic: (
    { __typename?: 'DeleteEnglishModuleTopicPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type CreateEnglishModuleMutationVariables = Exact<{
  input: CreateEnglishModuleInput;
}>;


export type CreateEnglishModuleMutation = (
  { __typename?: 'Mutation' }
  & { createEnglishModule: (
    { __typename?: 'CreateEnglishModulePayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type UpdateEnglishModuleMutationVariables = Exact<{
  input: UpdateEnglishModuleInput;
}>;


export type UpdateEnglishModuleMutation = (
  { __typename?: 'Mutation' }
  & { updateEnglishModule: (
    { __typename?: 'UpdateEnglishModulePayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type DeleteEnglishModuleMutationVariables = Exact<{
  input: DeleteEnglishModuleInput;
}>;


export type DeleteEnglishModuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteEnglishModule: (
    { __typename?: 'DeleteEnglishModulePayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type CreateEnglishModuleSectionMutationVariables = Exact<{
  input: CreateEnglishModuleSectionInput;
}>;


export type CreateEnglishModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { createEnglishModuleSection: (
    { __typename?: 'CreateEnglishModuleSectionPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type UpdateEnglishModuleSectionMutationVariables = Exact<{
  input: UpdateEnglishModuleSectionInput;
}>;


export type UpdateEnglishModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateEnglishModuleSection: (
    { __typename?: 'UpdateEnglishModuleSectionPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type DeleteEnglishModuleSectionMutationVariables = Exact<{
  input: DeleteEnglishModuleSectionInput;
}>;


export type DeleteEnglishModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteEnglishModuleSection: (
    { __typename?: 'DeleteEnglishModuleSectionPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type DeleteEnglishReferenceTextSetOptionMutationVariables = Exact<{
  input: DeleteEnglishReferenceTextSetOptionInput;
}>;


export type DeleteEnglishReferenceTextSetOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteEnglishReferenceTextSetOption: (
    { __typename?: 'DeleteEnglishReferenceTextSetOptionPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type CreateEnglishModuleSectionBulletMutationVariables = Exact<{
  input: CreateEnglishModuleSectionBulletInput;
}>;


export type CreateEnglishModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { createEnglishModuleSectionBullet: (
    { __typename?: 'CreateEnglishModuleSectionBulletPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type UpdateEnglishModuleSectionBulletMutationVariables = Exact<{
  input: UpdateEnglishModuleSectionBulletInput;
}>;


export type UpdateEnglishModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { updateEnglishModuleSectionBullet: (
    { __typename?: 'UpdateEnglishModuleSectionBulletPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type DeleteEnglishModuleSectionBulletMutationVariables = Exact<{
  input: DeleteEnglishModuleSectionBulletInput;
}>;


export type DeleteEnglishModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { deleteEnglishModuleSectionBullet: (
    { __typename?: 'DeleteEnglishModuleSectionBulletPayload' }
    & { englishCourse: (
      { __typename?: 'EnglishCourse' }
      & EnglishCourseFragmentFragment
    ) }
  ) }
);

export type InvestingCourseFragmentFragment = (
  { __typename?: 'InvestingCourse' }
  & Pick<InvestingCourse, '_id' | 'name' | 'displayName' | 'abbreviation' | 'order' | 'isActive'>
  & { moduleTopics: Array<(
    { __typename?: 'InvestingModuleTopic' }
    & Pick<InvestingModuleTopic, '_id' | 'name' | 'displayName'>
    & { modules: Array<(
      { __typename?: 'InvestingModule' }
      & Pick<InvestingModule, '_id' | 'name' | 'displayName' | 'isTrialModule'>
      & { moduleSections: Array<(
        { __typename?: 'InvestingModuleSection' }
        & Pick<InvestingModuleSection, '_id' | 'displayName' | 'sectionType' | 'isSupplemental' | 'writingPromptId' | 'instructions'>
        & { referenceTextSetOptions: Array<(
          { __typename?: 'InvestingReferenceTextSetOption' }
          & Pick<InvestingReferenceTextSetOption, '_id' | 'referenceTextSetId'>
        )>, moduleSectionBullets: Array<(
          { __typename?: 'InvestingModuleSectionBullet' }
          & Pick<InvestingModuleSectionBullet, '_id' | 'text' | 'problemType' | 'solutionExplanation'>
          & { mcAnswerOptions: Array<(
            { __typename?: 'InvestingMultipleChoiceAnswerOption' }
            & Pick<InvestingMultipleChoiceAnswerOption, '_id' | 'answerChoiceText'>
          )>, mcCorrectAnswer: Array<(
            { __typename?: 'InvestingMultipleChoiceCorrectAnswer' }
            & Pick<InvestingMultipleChoiceCorrectAnswer, '_id' | 'solutionText'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type LoadInvestingCourseByIdQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadInvestingCourseByIdQuery = (
  { __typename?: 'Query' }
  & { investingCourseById?: Maybe<(
    { __typename?: 'InvestingCourse' }
    & InvestingCourseFragmentFragment
  )> }
);

export type CountInvestingCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountInvestingCoursesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'investingCoursesCount'>
);

export type CreateInvestingCourseMutationVariables = Exact<{
  input: CreateInvestingCourseInput;
}>;


export type CreateInvestingCourseMutation = (
  { __typename?: 'Mutation' }
  & { createInvestingCourse: (
    { __typename?: 'CreateInvestingCoursePayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type UpdateInvestingCourseMutationVariables = Exact<{
  input: UpdateInvestingCourseInput;
}>;


export type UpdateInvestingCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateInvestingCourse: (
    { __typename?: 'UpdateInvestingCoursePayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type DeleteInvestingCourseMutationVariables = Exact<{
  input: DeleteInvestingCourseInput;
}>;


export type DeleteInvestingCourseMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestingCourse: (
    { __typename?: 'DeleteInvestingCoursePayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type CreateInvestingModuleTopicMutationVariables = Exact<{
  input: CreateInvestingModuleTopicInput;
}>;


export type CreateInvestingModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { createInvestingModuleTopic: (
    { __typename?: 'CreateInvestingModuleTopicPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type UpdateInvestingModuleTopicMutationVariables = Exact<{
  input: UpdateInvestingModuleTopicInput;
}>;


export type UpdateInvestingModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { updateInvestingModuleTopic: (
    { __typename?: 'UpdateInvestingModuleTopicPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type DeleteInvestingModuleTopicMutationVariables = Exact<{
  input: DeleteInvestingModuleTopicInput;
}>;


export type DeleteInvestingModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestingModuleTopic: (
    { __typename?: 'DeleteInvestingModuleTopicPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type CreateInvestingModuleMutationVariables = Exact<{
  input: CreateInvestingModuleInput;
}>;


export type CreateInvestingModuleMutation = (
  { __typename?: 'Mutation' }
  & { createInvestingModule: (
    { __typename?: 'CreateInvestingModulePayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type UpdateInvestingModuleMutationVariables = Exact<{
  input: UpdateInvestingModuleInput;
}>;


export type UpdateInvestingModuleMutation = (
  { __typename?: 'Mutation' }
  & { updateInvestingModule: (
    { __typename?: 'UpdateInvestingModulePayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type DeleteInvestingModuleMutationVariables = Exact<{
  input: DeleteInvestingModuleInput;
}>;


export type DeleteInvestingModuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestingModule: (
    { __typename?: 'DeleteInvestingModulePayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type CreateInvestingModuleSectionMutationVariables = Exact<{
  input: CreateInvestingModuleSectionInput;
}>;


export type CreateInvestingModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { createInvestingModuleSection: (
    { __typename?: 'CreateInvestingModuleSectionPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type UpdateInvestingModuleSectionMutationVariables = Exact<{
  input: UpdateInvestingModuleSectionInput;
}>;


export type UpdateInvestingModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateInvestingModuleSection: (
    { __typename?: 'UpdateInvestingModuleSectionPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type DeleteInvestingModuleSectionMutationVariables = Exact<{
  input: DeleteInvestingModuleSectionInput;
}>;


export type DeleteInvestingModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestingModuleSection: (
    { __typename?: 'DeleteInvestingModuleSectionPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type DeleteInvestingReferenceTextSetOptionMutationVariables = Exact<{
  input: DeleteInvestingReferenceTextSetOptionInput;
}>;


export type DeleteInvestingReferenceTextSetOptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestingReferenceTextSetOption: (
    { __typename?: 'DeleteInvestingReferenceTextSetOptionPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type CreateInvestingModuleSectionBulletMutationVariables = Exact<{
  input: CreateInvestingModuleSectionBulletInput;
}>;


export type CreateInvestingModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { createInvestingModuleSectionBullet: (
    { __typename?: 'CreateInvestingModuleSectionBulletPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type UpdateInvestingModuleSectionBulletMutationVariables = Exact<{
  input: UpdateInvestingModuleSectionBulletInput;
}>;


export type UpdateInvestingModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { updateInvestingModuleSectionBullet: (
    { __typename?: 'UpdateInvestingModuleSectionBulletPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type DeleteInvestingModuleSectionBulletMutationVariables = Exact<{
  input: DeleteInvestingModuleSectionBulletInput;
}>;


export type DeleteInvestingModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestingModuleSectionBullet: (
    { __typename?: 'DeleteInvestingModuleSectionBulletPayload' }
    & { investingCourse: (
      { __typename?: 'InvestingCourse' }
      & InvestingCourseFragmentFragment
    ) }
  ) }
);

export type MathCourseFragment = (
  { __typename?: 'MathCourse' }
  & Pick<MathCourse, '_id' | 'name' | 'displayName' | 'abbreviation' | 'order' | 'isActive'>
  & { moduleTopics: Array<(
    { __typename?: 'MathModuleTopic' }
    & Pick<MathModuleTopic, '_id' | 'name' | 'displayName'>
    & { modules: Array<(
      { __typename?: 'MathModule' }
      & Pick<MathModule, '_id' | 'name' | 'displayName' | 'isTrialModule'>
      & { moduleSections: Array<(
        { __typename?: 'MathModuleSection' }
        & Pick<MathModuleSection, '_id' | 'displayName' | 'sectionType' | 'isSupplemental' | 'instructions'>
        & { moduleSectionBullets: Array<(
          { __typename?: 'MathModuleSectionBullet' }
          & Pick<MathModuleSectionBullet, '_id' | 'text' | 'isProblem' | 'difficulty' | 'hasExpectedSolution' | 'solutionExplanation'>
          & { solutions: Array<(
            { __typename?: 'MathSolution' }
            & Pick<MathSolution, '_id' | 'solutionText'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type LoadMathCourseByIdQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadMathCourseByIdQuery = (
  { __typename?: 'Query' }
  & { mathCourseById?: Maybe<(
    { __typename?: 'MathCourse' }
    & MathCourseFragment
  )> }
);

export type CountMathCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountMathCoursesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'mathCoursesCount'>
);

export type CreateMathCourseMutationVariables = Exact<{
  input: CreateMathCourseInput;
}>;


export type CreateMathCourseMutation = (
  { __typename?: 'Mutation' }
  & { createMathCourse: (
    { __typename?: 'CreateMathCoursePayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type UpdateMathCourseMutationVariables = Exact<{
  input: UpdateMathCourseInput;
}>;


export type UpdateMathCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateMathCourse: (
    { __typename?: 'UpdateMathCoursePayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type DeleteMathCourseMutationVariables = Exact<{
  input: DeleteMathCourseInput;
}>;


export type DeleteMathCourseMutation = (
  { __typename?: 'Mutation' }
  & { deleteMathCourse: (
    { __typename?: 'DeleteMathCoursePayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type CreateMathModuleTopicMutationVariables = Exact<{
  input: CreateMathModuleTopicInput;
}>;


export type CreateMathModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { createMathModuleTopic: (
    { __typename?: 'CreateMathModuleTopicPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type UpdateMathModuleTopicMutationVariables = Exact<{
  input: UpdateMathModuleTopicInput;
}>;


export type UpdateMathModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { updateMathModuleTopic: (
    { __typename?: 'UpdateMathModuleTopicPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type DeleteMathModuleTopicMutationVariables = Exact<{
  input: DeleteMathModuleTopicInput;
}>;


export type DeleteMathModuleTopicMutation = (
  { __typename?: 'Mutation' }
  & { deleteMathModuleTopic: (
    { __typename?: 'DeleteMathModuleTopicPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type CreateMathModuleMutationVariables = Exact<{
  input: CreateMathModuleInput;
}>;


export type CreateMathModuleMutation = (
  { __typename?: 'Mutation' }
  & { createMathModule: (
    { __typename?: 'CreateMathModulePayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type UpdateMathModuleMutationVariables = Exact<{
  input: UpdateMathModuleInput;
}>;


export type UpdateMathModuleMutation = (
  { __typename?: 'Mutation' }
  & { updateMathModule: (
    { __typename?: 'UpdateMathModulePayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type DeleteMathModuleMutationVariables = Exact<{
  input: DeleteMathModuleInput;
}>;


export type DeleteMathModuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteMathModule: (
    { __typename?: 'DeleteMathModulePayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type CreateMathModuleSectionMutationVariables = Exact<{
  input: CreateMathModuleSectionInput;
}>;


export type CreateMathModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { createMathModuleSection: (
    { __typename?: 'CreateMathModuleSectionPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type UpdateMathModuleSectionMutationVariables = Exact<{
  input: UpdateMathModuleSectionInput;
}>;


export type UpdateMathModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { updateMathModuleSection: (
    { __typename?: 'UpdateMathModuleSectionPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type DeleteMathModuleSectionMutationVariables = Exact<{
  input: DeleteMathModuleSectionInput;
}>;


export type DeleteMathModuleSectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteMathModuleSection: (
    { __typename?: 'DeleteMathModuleSectionPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type CreateMathModuleSectionBulletMutationVariables = Exact<{
  input: CreateMathModuleSectionBulletInput;
}>;


export type CreateMathModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { createMathModuleSectionBullet: (
    { __typename?: 'CreateMathModuleSectionBulletPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type UpdateMathModuleSectionBulletMutationVariables = Exact<{
  input: UpdateMathModuleSectionBulletInput;
}>;


export type UpdateMathModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { updateMathModuleSectionBullet: (
    { __typename?: 'UpdateMathModuleSectionBulletPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type DeleteMathModuleSectionBulletMutationVariables = Exact<{
  input: DeleteMathModuleSectionBulletInput;
}>;


export type DeleteMathModuleSectionBulletMutation = (
  { __typename?: 'Mutation' }
  & { deleteMathModuleSectionBullet: (
    { __typename?: 'DeleteMathModuleSectionBulletPayload' }
    & { mathCourse: (
      { __typename?: 'MathCourse' }
      & MathCourseFragment
    ) }
  ) }
);

export type PromptFragmentFragment = (
  { __typename?: 'Prompt' }
  & Pick<Prompt, '_id' | 'content'>
);

export type LoadPromptByIdQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadPromptByIdQuery = (
  { __typename?: 'Query' }
  & { promptById?: Maybe<(
    { __typename?: 'Prompt' }
    & PromptFragmentFragment
  )> }
);

export type CreatePromptMutationVariables = Exact<{
  input: CreatePromptInput;
}>;


export type CreatePromptMutation = (
  { __typename?: 'Mutation' }
  & { createPrompt: (
    { __typename?: 'CreatePromptPayload' }
    & { prompt: (
      { __typename?: 'Prompt' }
      & PromptFragmentFragment
    ) }
  ) }
);

export type UpdatePromptMutationVariables = Exact<{
  input: UpdatePromptInput;
}>;


export type UpdatePromptMutation = (
  { __typename?: 'Mutation' }
  & { updatePrompt: (
    { __typename?: 'UpdatePromptPayload' }
    & { prompt: (
      { __typename?: 'Prompt' }
      & PromptFragmentFragment
    ) }
  ) }
);

export type DeletePromptMutationVariables = Exact<{
  input: DeletePromptInput;
}>;


export type DeletePromptMutation = (
  { __typename?: 'Mutation' }
  & { deletePrompt: (
    { __typename?: 'DeletePromptPayload' }
    & Pick<DeletePromptPayload, 'success'>
  ) }
);

export type RefTextFragment = (
  { __typename?: 'ReferenceText' }
  & Pick<ReferenceText, '_id' | 'subject' | 'title' | 'adminDisplayName' | 'description' | 'content' | 'isSampleSolution'>
);

export type LoadReferenceTextsQueryVariables = Exact<{
  subject?: Maybe<Scalars['String']>;
}>;


export type LoadReferenceTextsQuery = (
  { __typename?: 'Query' }
  & { referenceTexts: (
    { __typename?: 'ReferenceTextsPayload' }
    & { items: Array<(
      { __typename?: 'ReferenceText' }
      & RefTextFragment
    )> }
  ) }
);

export type CreateReferenceTextMutationVariables = Exact<{
  input: CreateReferenceTextInput;
}>;


export type CreateReferenceTextMutation = (
  { __typename?: 'Mutation' }
  & { createReferenceText: (
    { __typename?: 'CreateReferenceTextPayload' }
    & { referenceText: (
      { __typename?: 'ReferenceText' }
      & RefTextFragment
    ) }
  ) }
);

export type UpdateReferenceTextMutationVariables = Exact<{
  input: UpdateReferenceTextInput;
}>;


export type UpdateReferenceTextMutation = (
  { __typename?: 'Mutation' }
  & { updateReferenceText: (
    { __typename?: 'UpdateReferenceTextPayload' }
    & { referenceText: (
      { __typename?: 'ReferenceText' }
      & RefTextFragment
    ) }
  ) }
);

export type TextSetFragment = (
  { __typename?: 'ReferenceTextSet' }
  & Pick<ReferenceTextSet, '_id' | 'subject' | 'title' | 'adminDisplayName' | 'description' | 'grades' | 'tags' | 'referenceTextIds'>
  & { referenceTexts?: Maybe<Array<(
    { __typename?: 'ReferenceText' }
    & Pick<ReferenceText, '_id' | 'title' | 'adminDisplayName' | 'description' | 'content' | 'isSampleSolution'>
  )>> }
);

export type LoadReferenceTextSetsQueryVariables = Exact<{
  subject?: Maybe<Scalars['String']>;
}>;


export type LoadReferenceTextSetsQuery = (
  { __typename?: 'Query' }
  & { referenceTextSets: (
    { __typename?: 'ReferenceTextSetsPayload' }
    & { items: Array<(
      { __typename?: 'ReferenceTextSet' }
      & TextSetFragment
    )> }
  ) }
);

export type LoadReferenceTextSetsByIdsQueryVariables = Exact<{
  input: Array<Scalars['ObjectId']>;
}>;


export type LoadReferenceTextSetsByIdsQuery = (
  { __typename?: 'Query' }
  & { referenceTextSetsByIds: (
    { __typename?: 'ReferenceTextSetsByIdsPayload' }
    & { items: Array<(
      { __typename?: 'ReferenceTextSet' }
      & TextSetFragment
    )> }
  ) }
);

export type CreateReferenceTextSetMutationVariables = Exact<{
  input: CreateReferenceTextSetInput;
}>;


export type CreateReferenceTextSetMutation = (
  { __typename?: 'Mutation' }
  & { createReferenceTextSet: (
    { __typename?: 'CreateReferenceTextSetPayload' }
    & { referenceTextSet: (
      { __typename?: 'ReferenceTextSet' }
      & TextSetFragment
    ) }
  ) }
);

export type UpdateReferenceTextSetMutationVariables = Exact<{
  input: UpdateReferenceTextSetInput;
}>;


export type UpdateReferenceTextSetMutation = (
  { __typename?: 'Mutation' }
  & { updateReferenceTextSet: (
    { __typename?: 'UpdateReferenceTextSetPayload' }
    & { referenceTextSet: (
      { __typename?: 'ReferenceTextSet' }
      & TextSetFragment
    ) }
  ) }
);

export type GetSubjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubjectsQuery = (
  { __typename?: 'Query' }
  & { getSubjects: Array<(
    { __typename?: 'Subject' }
    & GetSubjectsReturnFragment
  )> }
);

export type GetSubjectsReturnFragment = (
  { __typename?: 'Subject' }
  & Pick<Subject, '_id' | 'name' | 'displayName' | 'iconName' | 'primaryColor' | 'createdAt' | 'updatedAt'>
);

export type ExperimentDetailsFragment = (
  { __typename?: 'Experiment' }
  & Pick<Experiment, '_id' | 'key' | 'title' | 'status' | 'target' | 'description' | 'participantType'>
  & { treatments?: Maybe<Array<(
    { __typename?: 'ExperimentTreatment' }
    & Pick<ExperimentTreatment, 'key' | 'isControl' | 'percentAllocation' | 'description'>
  )>> }
);

export type CreateExperimentMutationVariables = Exact<{
  experiment: CreateExperimentInput;
}>;


export type CreateExperimentMutation = (
  { __typename?: 'Mutation' }
  & { createExperiment: (
    { __typename?: 'CreateExperimentPayload' }
    & { experiment?: Maybe<(
      { __typename?: 'Experiment' }
      & ExperimentDetailsFragment
    )> }
  ) }
);

export type QueryExperimentsQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  target?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  participantType?: Maybe<Array<Scalars['String']>>;
}>;


export type QueryExperimentsQuery = (
  { __typename?: 'Query' }
  & { queryExperiments: (
    { __typename?: 'QueryExperimentsPayload' }
    & { items: Array<(
      { __typename?: 'Experiment' }
      & Pick<Experiment, '_id' | 'key' | 'title' | 'status' | 'target' | 'participantType'>
      & { treatments?: Maybe<Array<(
        { __typename?: 'ExperimentTreatment' }
        & Pick<ExperimentTreatment, 'key' | 'isControl'>
      )>> }
    )> }
  ) }
);

export type GetExperimentQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetExperimentQuery = (
  { __typename?: 'Query' }
  & { experiment?: Maybe<(
    { __typename?: 'Experiment' }
    & ExperimentDetailsFragment
  )> }
);

export type UpdateExperimentMutationVariables = Exact<{
  experiment: UpdateExperimentInput;
}>;


export type UpdateExperimentMutation = (
  { __typename?: 'Mutation' }
  & { updateExperiment: (
    { __typename?: 'UpdateExperimentPayload' }
    & { experiment?: Maybe<(
      { __typename?: 'Experiment' }
      & ExperimentDetailsFragment
    )> }
  ) }
);

export type AddTreatmentsToExperimentMutationVariables = Exact<{
  experiment: AddTreatmentsToExperimentInput;
}>;


export type AddTreatmentsToExperimentMutation = (
  { __typename?: 'Mutation' }
  & { addTreatmentsToExperiment: (
    { __typename?: 'AddTreatmentsToExperimentPayload' }
    & { experiment?: Maybe<(
      { __typename?: 'Experiment' }
      & ExperimentDetailsFragment
    )> }
  ) }
);

export type UpdateTreatmentsInExperimentMutationVariables = Exact<{
  experiment: UpdateTreatmentsInExperimentInput;
}>;


export type UpdateTreatmentsInExperimentMutation = (
  { __typename?: 'Mutation' }
  & { updateTreatmentsInExperiment: (
    { __typename?: 'UpdateTreatmentsInExperimentPayload' }
    & { experiment?: Maybe<(
      { __typename?: 'Experiment' }
      & ExperimentDetailsFragment
    )> }
  ) }
);

export type RemoveTreatmentsFromExperimentMutationVariables = Exact<{
  experiment: RemoveTreatmentsFromExperimentInput;
}>;


export type RemoveTreatmentsFromExperimentMutation = (
  { __typename?: 'Mutation' }
  & { removeTreatmentsFromExperiment: (
    { __typename?: 'RemoveTreatmentsFromExperimentPayload' }
    & { experiment?: Maybe<(
      { __typename?: 'Experiment' }
      & ExperimentDetailsFragment
    )> }
  ) }
);

export type LoadAcuityAppointmentNotificationQueryVariables = Exact<{
  appointmentId: Scalars['Int'];
}>;


export type LoadAcuityAppointmentNotificationQuery = (
  { __typename?: 'Query' }
  & { acuityAppointmentNotification?: Maybe<(
    { __typename?: 'AcuityAppointmentNotification' }
    & Pick<AcuityAppointmentNotification, 'appointmentId' | 'notifyFirstClassShadow' | 'notifyStudentTransitionShadow' | 'notifySubstituteSession'>
  )> }
);

export type UpsertAcuityAppointmentNotificationMutationVariables = Exact<{
  input: UpsertAcuityAppointmentNotificationInput;
}>;


export type UpsertAcuityAppointmentNotificationMutation = (
  { __typename?: 'Mutation' }
  & { upsertAcuityAppointmentNotification: (
    { __typename?: 'UpsertAcuityAppointmentNotificationPayload' }
    & Pick<UpsertAcuityAppointmentNotificationPayload, 'appointmentId' | 'notifyFirstClassShadow' | 'notifyStudentTransitionShadow' | 'notifySubstituteSession'>
  ) }
);

export type CancelStudentAppointmentsMutationVariables = Exact<{
  input: CancelStudentAppointmentsInput;
}>;


export type CancelStudentAppointmentsMutation = (
  { __typename?: 'Mutation' }
  & { cancelStudentAppointments: (
    { __typename?: 'CancelStudentAppointmentsPayload' }
    & { appointments: Array<(
      { __typename?: 'CancelStudentAppointmentPayload' }
      & Pick<CancelStudentAppointmentPayload, 'result'>
    )> }
  ) }
);

export type ScheduleStudentAppointmentsMutationVariables = Exact<{
  input: ScheduleStudentAppointmentsInput;
}>;


export type ScheduleStudentAppointmentsMutation = (
  { __typename?: 'Mutation' }
  & { scheduleStudentAppointments: (
    { __typename?: 'ScheduleStudentAppointmentsPayload' }
    & { appointments: Array<(
      { __typename?: 'ScheduleStudentAppointmentPayload' }
      & Pick<ScheduleStudentAppointmentPayload, 'result'>
    )> }
  ) }
);

export type RescheduleStudentAppointmentsMutationVariables = Exact<{
  input: RescheduleStudentAppointmentsInput;
}>;


export type RescheduleStudentAppointmentsMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleStudentAppointments: (
    { __typename?: 'RescheduleStudentAppointmentsPayload' }
    & { appointments: Array<(
      { __typename?: 'RescheduleStudentAppointmentPayload' }
      & Pick<RescheduleStudentAppointmentPayload, 'result'>
    )> }
  ) }
);

export type FeaturedStudentProjectDetailsFragment = (
  { __typename?: 'StudentProject' }
  & Pick<StudentProject, '_id' | 'title' | 'description' | 'juniverseTags' | 'attachedUsermedia' | 'thumbnailUrl'>
);

export type StudentProjectRowFragment = (
  { __typename?: 'StudentProject' }
  & Pick<StudentProject, '_id' | 'courseId' | 'moduleId' | 'title' | 'description' | 'isPublic' | 'isCustomProject' | 'lastUpdatedAt' | 'createdAt' | 'projectType' | 'juniverseTags' | 'attachedUsermedia' | 'originalProjectId' | 'thumbnailUrl' | 'viewCount'>
  & { student?: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, '_id' | 'firstName' | 'lastName' | 'username'>
  )>, instructor?: Maybe<(
    { __typename?: 'Instructor' }
    & Pick<Instructor, '_id' | 'firstName' | 'lastName' | 'username'>
  )>, course?: Maybe<(
    { __typename?: 'ComputerScienceCourse' }
    & Pick<ComputerScienceCourse, '_id' | 'displayName' | 'name'>
  ) | (
    { __typename?: 'EnglishCourse' }
    & Pick<EnglishCourse, '_id' | 'displayName' | 'name'>
  ) | (
    { __typename?: 'MathCourse' }
    & Pick<MathCourse, '_id' | 'displayName' | 'name'>
  ) | (
    { __typename?: 'InvestingCourse' }
    & Pick<InvestingCourse, '_id' | 'displayName' | 'name'>
  )>, module?: Maybe<(
    { __typename?: 'ComputerScienceModule' }
    & Pick<ComputerScienceModule, '_id' | 'name'>
  ) | (
    { __typename?: 'EnglishModule' }
    & Pick<EnglishModule, '_id' | 'name'>
  ) | (
    { __typename?: 'MathModule' }
    & Pick<MathModule, '_id' | 'name'>
  ) | (
    { __typename?: 'InvestingModule' }
    & Pick<InvestingModule, '_id' | 'name'>
  )>, moduleSection?: Maybe<(
    { __typename?: 'ComputerScienceModuleSection' }
    & Pick<ComputerScienceModuleSection, '_id' | 'displayName'>
  ) | (
    { __typename?: 'EnglishModuleSection' }
    & Pick<EnglishModuleSection, '_id' | 'displayName'>
  ) | (
    { __typename?: 'MathModuleSection' }
    & Pick<MathModuleSection, '_id' | 'displayName'>
  ) | (
    { __typename?: 'InvestingModuleSection' }
    & Pick<InvestingModuleSection, '_id' | 'displayName'>
  )>, customProject?: Maybe<(
    { __typename?: 'CustomProject' }
    & Pick<CustomProject, '_id' | 'displayName'>
  )>, featuredProject?: Maybe<(
    { __typename?: 'StudentProject' }
    & FeaturedStudentProjectDetailsFragment
  )> }
);

export type GetStudentProjectByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetStudentProjectByIdQuery = (
  { __typename?: 'Query' }
  & { studentProjectById?: Maybe<(
    { __typename?: 'StudentProject' }
    & StudentProjectRowFragment
  )> }
);

export type GetStudentProjectsQueryVariables = Exact<{
  query?: Maybe<StudentProjectQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetStudentProjectsQuery = (
  { __typename?: 'Query' }
  & { studentProjects: (
    { __typename?: 'StudentProjectsPayload' }
    & { items: Array<Maybe<(
      { __typename?: 'StudentProject' }
      & StudentProjectRowFragment
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type FeatureStudentProjectMutationVariables = Exact<{
  originalProjectId: Scalars['ObjectId'];
  newData: StudentProjectUpdateData;
}>;


export type FeatureStudentProjectMutation = (
  { __typename?: 'Mutation' }
  & { featureStudentProject: (
    { __typename?: 'FeatureStudentProjectPayload' }
    & { featuredProject?: Maybe<(
      { __typename?: 'StudentProject' }
      & { originalProject?: Maybe<(
        { __typename?: 'StudentProject' }
        & Pick<StudentProject, '_id' | 'featuredProjectId'>
        & { featuredProject?: Maybe<(
          { __typename?: 'StudentProject' }
          & Pick<StudentProject, '_id'>
        )> }
      )> }
      & FeaturedStudentProjectDetailsFragment
    )> }
  ) }
);

export type UpdateFeaturedStudentProjectMutationVariables = Exact<{
  id: Scalars['ObjectId'];
  data: StudentProjectUpdateData;
}>;


export type UpdateFeaturedStudentProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateStudentProject: (
    { __typename?: 'UpdateStudentProjectPayload' }
    & { project?: Maybe<(
      { __typename?: 'StudentProject' }
      & FeaturedStudentProjectDetailsFragment
    )> }
  ) }
);

export type UnfeatureStudentProjectMutationVariables = Exact<{
  originalProjectId: Scalars['ObjectId'];
}>;


export type UnfeatureStudentProjectMutation = (
  { __typename?: 'Mutation' }
  & { unfeatureStudentProject: (
    { __typename?: 'UnfeatureStudentProjectPayload' }
    & { originalProject?: Maybe<(
      { __typename?: 'StudentProject' }
      & StudentProjectRowFragment
    )> }
  ) }
);

export type PublishFeaturedProjectsToJuniverseMutationVariables = Exact<{
  input: PublishFeaturedProjectsToJuniverseInput;
}>;


export type PublishFeaturedProjectsToJuniverseMutation = (
  { __typename?: 'Mutation' }
  & { publishFeaturedProjectsToJuniverse: (
    { __typename?: 'PublishFeaturedProjectsToJuniversePayload' }
    & Pick<PublishFeaturedProjectsToJuniversePayload, 'success'>
  ) }
);

export type JuniAdsAndCampaignsQueryVariables = Exact<{ [key: string]: never; }>;


export type JuniAdsAndCampaignsQuery = (
  { __typename?: 'Query' }
  & { juniAdsAndCampaigns: (
    { __typename?: 'JuniAdsAndCampaignsPayload' }
    & { items: Array<(
      { __typename?: 'JuniAdCampaign' }
      & Pick<JuniAdCampaign, '_id' | 'campaignName' | 'startDate' | 'endDate' | 'priority' | 'maxViews' | 'archivedDate' | 'juniAdIds'>
      & { cooldown?: Maybe<(
        { __typename?: 'JuniAdCampaignCooldown' }
        & Pick<JuniAdCampaignCooldown, 'duration' | 'timeUnit'>
      )>, juniAds?: Maybe<Array<(
        { __typename?: 'JuniAd' }
        & Pick<JuniAd, 'juniAdProperty' | 'title' | 'version' | 'imageS3Url' | 'copy'>
        & { primaryAction?: Maybe<(
          { __typename?: 'JuniAdAction' }
          & Pick<JuniAdAction, 'label' | 'clickthroughUrl'>
        )>, secondaryAction?: Maybe<(
          { __typename?: 'JuniAdAction' }
          & Pick<JuniAdAction, 'label' | 'clickthroughUrl'>
        )> }
      )>> }
    )> }
  ) }
);

export type UpsertJuniAdAndCampaignMutationVariables = Exact<{
  input: UpsertJuniAdAndCampaignInput;
}>;


export type UpsertJuniAdAndCampaignMutation = (
  { __typename?: 'Mutation' }
  & { upsertJuniAdAndCampaign: (
    { __typename?: 'UpsertJuniAdAndCampaignPayload' }
    & { juniAdCampaign: (
      { __typename?: 'JuniAdCampaign' }
      & Pick<JuniAdCampaign, '_id'>
    ) }
  ) }
);

export type ArchiveJuniAdCampaignMutationVariables = Exact<{
  input: ArchiveJuniAdCampaignInput;
}>;


export type ArchiveJuniAdCampaignMutation = (
  { __typename?: 'Mutation' }
  & { archiveJuniAdCampaign: (
    { __typename?: 'ArchiveJuniAdCampaignPayload' }
    & Pick<ArchiveJuniAdCampaignPayload, 'success'>
  ) }
);

export type NotesFromHqQueryVariables = Exact<{ [key: string]: never; }>;


export type NotesFromHqQuery = (
  { __typename?: 'Query' }
  & { notesFromHQ: (
    { __typename?: 'NotesFromHQPayload' }
    & { items: Array<(
      { __typename?: 'NoteFromHQ' }
      & Pick<NoteFromHq, '_id' | 'title' | 'text' | 'startDate' | 'endDate'>
    )> }
  ) }
);

export type UpsertNotesFromHqMutationVariables = Exact<{
  input: UpsertNotesFromHqInput;
}>;


export type UpsertNotesFromHqMutation = (
  { __typename?: 'Mutation' }
  & { upsertNotesFromHQ: (
    { __typename?: 'UpsertNotesFromHQPayload' }
    & Pick<UpsertNotesFromHqPayload, 'success'>
  ) }
);

export type DeleteNoteFromHqMutationVariables = Exact<{
  input: DeleteNoteFromHqInput;
}>;


export type DeleteNoteFromHqMutation = (
  { __typename?: 'Mutation' }
  & { deleteNoteFromHQ: (
    { __typename?: 'DeleteNoteFromHQPayload' }
    & Pick<DeleteNoteFromHqPayload, 'success'>
  ) }
);

export type ParentCornerLiveAnnouncementsQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentCornerLiveAnnouncementsQuery = (
  { __typename?: 'Query' }
  & { parentCornerLiveAnnouncements: (
    { __typename?: 'ParentCornerLiveAnnouncementsPayload' }
    & { items: Array<(
      { __typename?: 'ParentCornerLiveAnnouncement' }
      & Pick<ParentCornerLiveAnnouncement, '_id' | 'title' | 'body' | 'order'>
      & { callsToAction: Array<(
        { __typename?: 'ParentCornerAnnouncementCallToAction' }
        & Pick<ParentCornerAnnouncementCallToAction, '_id' | 'label' | 'url'>
      )> }
    )> }
  ) }
);

export type UpdateParentCornerAnnouncementsMutationVariables = Exact<{
  input: UpdateParentCornerAnnouncementsInput;
}>;


export type UpdateParentCornerAnnouncementsMutation = (
  { __typename?: 'Mutation' }
  & { updateParentCornerAnnouncements: (
    { __typename?: 'UpdateParentCornerAnnouncementsPayload' }
    & Pick<UpdateParentCornerAnnouncementsPayload, 'success'>
  ) }
);

export type OpenChallengeFragment = (
  { __typename?: 'OpenChallenge' }
  & Pick<OpenChallenge, '_id' | 'title' | 'description' | 'openDate' | 'registrationDeadline' | 'submissionDeadline' | 'thumbnailUrl' | 'registrationLink' | 'resultsLink'>
);

export type GetOpenChallengesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenChallengesQuery = (
  { __typename?: 'Query' }
  & { openChallenges: (
    { __typename?: 'OpenChallengesPayload' }
    & { items: Array<(
      { __typename?: 'OpenChallenge' }
      & OpenChallengeFragment
    )> }
  ) }
);

export type GetOpenChallengeByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetOpenChallengeByIdQuery = (
  { __typename?: 'Query' }
  & { openChallengeById?: Maybe<(
    { __typename?: 'OpenChallenge' }
    & OpenChallengeFragment
  )> }
);

export type CreateOpenChallengeMutationVariables = Exact<{
  input: CreateOpenChallengeInput;
}>;


export type CreateOpenChallengeMutation = (
  { __typename?: 'Mutation' }
  & { createOpenChallenge: (
    { __typename?: 'CreateOpenChallengePayload' }
    & { openChallenge?: Maybe<(
      { __typename?: 'OpenChallenge' }
      & OpenChallengeFragment
    )> }
  ) }
);

export type UpdateOpenChallengeMutationVariables = Exact<{
  input: UpdateOpenChallengeInput;
}>;


export type UpdateOpenChallengeMutation = (
  { __typename?: 'Mutation' }
  & { updateOpenChallenge: (
    { __typename?: 'UpdateOpenChallengePayload' }
    & { openChallenge?: Maybe<(
      { __typename?: 'OpenChallenge' }
      & OpenChallengeFragment
    )> }
  ) }
);

export type DeleteOpenChallengeMutationVariables = Exact<{
  input: DeleteOpenChallengeInput;
}>;


export type DeleteOpenChallengeMutation = (
  { __typename?: 'Mutation' }
  & { deleteOpenChallenge: (
    { __typename?: 'DeleteOpenChallengePayload' }
    & Pick<DeleteOpenChallengePayload, 'success'>
  ) }
);

export type DashboardSessionInfoFragmentFragment = (
  { __typename?: 'DashboardSessionInfo' }
  & Pick<DashboardSessionInfo, 'id' | 'teacherSessionId' | 'sessionTime' | 'notes' | 'originalNotes' | 'usesMarkdown' | 'timezone' | 'wasMissed' | 'noteNeedsMinorRevision' | 'noteNeedsMajorRevision' | 'instructorNeedsAdditionalSupport' | 'appointmentTypeID' | 'studentFirstName' | 'studentLastName' | 'studentReceivesSessionNotes' | 'studentEmail' | 'studentId' | 'parentFirstName' | 'parentEmail' | 'teacherFirstName' | 'teacherLastName' | 'currCycleWeek' | 'sent'>
);

export type GetUnsentDashboardSessionInfosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnsentDashboardSessionInfosQuery = (
  { __typename?: 'Query' }
  & { unsentDashboardSessionInfos: (
    { __typename?: 'UnsentDashboardSessionInfosPayload' }
    & { items: Array<(
      { __typename?: 'DashboardSessionInfo' }
      & DashboardSessionInfoFragmentFragment
    )> }
  ) }
);

export type UpdateTeacherSessionForPostSessionEmailMutationVariables = Exact<{
  input: UpdateTeacherSessionForPostSessionEmailInput;
}>;


export type UpdateTeacherSessionForPostSessionEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateTeacherSessionForPostSessionEmail: (
    { __typename?: 'UpdateTeacherSessionForPostSessionEmailPayload' }
    & { dashboardSessionInfo: (
      { __typename?: 'DashboardSessionInfo' }
      & DashboardSessionInfoFragmentFragment
    ) }
  ) }
);

export type SendPostSessionEmailMutationVariables = Exact<{
  input: SendPostSessionEmailInput;
}>;


export type SendPostSessionEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendPostSessionEmail: (
    { __typename?: 'SendPostSessionEmailPayload' }
    & { dashboardSessionInfo: (
      { __typename?: 'DashboardSessionInfo' }
      & DashboardSessionInfoFragmentFragment
    ) }
  ) }
);

export type GetPostSessionDashboardEmailHtmlMutationVariables = Exact<{
  input: GetPostSessionDashboardEmailHtmlInput;
}>;


export type GetPostSessionDashboardEmailHtmlMutation = (
  { __typename?: 'Mutation' }
  & { getPostSessionDashboardEmailHtml: (
    { __typename?: 'GetPostSessionDashboardEmailHtmlPayload' }
    & { dashboardPreviewEmail: (
      { __typename?: 'DashboardPreviewEmail' }
      & Pick<DashboardPreviewEmail, 'html'>
    ) }
  ) }
);

export type CompileHandoffEmailQueryVariables = Exact<{
  templateValues: HandoffEmailTemplateValues;
  parentEmail?: Maybe<Scalars['String']>;
}>;


export type CompileHandoffEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'compileHandoffEmail'>
);

export type SendHandoffEmailMutationVariables = Exact<{
  input: SendHandoffEmailInput;
}>;


export type SendHandoffEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendHandoffEmail: (
    { __typename?: 'SendHandoffEmailPayload' }
    & Pick<SendHandoffEmailPayload, 'success'>
  ) }
);

export type SendHandoffStudentProposalsMutationVariables = Exact<{
  handoffId?: Maybe<Scalars['ObjectId']>;
}>;


export type SendHandoffStudentProposalsMutation = (
  { __typename?: 'Mutation' }
  & { sendHandoffStudentProposals: (
    { __typename?: 'SendHandoffStudentProposalsPayload' }
    & { studentHandoff: (
      { __typename?: 'StudentHandoff' }
      & Pick<StudentHandoff, '_id' | 'status'>
      & { potentialInstructors?: Maybe<Array<(
        { __typename?: 'StudentPotentialInstructor' }
        & Pick<StudentPotentialInstructor, '_id' | 'instructorId' | 'status' | 'isNewInstructor' | 'needsGroupTraining'>
      )>> }
    ) }
  ) }
);

export type CompileOnboardingEmailQueryVariables = Exact<{
  templateValues: OnboardingEmailTemplateValues;
  origin: Scalars['String'];
  courseNames?: Maybe<Array<Scalars['String']>>;
}>;


export type CompileOnboardingEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'compileOnboardingEmail'>
);

export type SendOnboardingEmailMutationVariables = Exact<{
  input: SendOnboardingEmailInput;
}>;


export type SendOnboardingEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendOnboardingEmail: (
    { __typename?: 'SendOnboardingEmailPayload' }
    & Pick<SendOnboardingEmailPayload, 'success'>
  ) }
);

export type SendOnboardingStudentProposalsMutationVariables = Exact<{
  onboardingId?: Maybe<Scalars['ObjectId']>;
}>;


export type SendOnboardingStudentProposalsMutation = (
  { __typename?: 'Mutation' }
  & { sendOnboardingStudentProposals: (
    { __typename?: 'SendOnboardingStudentProposalsPayload' }
    & { studentOnboarding: (
      { __typename?: 'StudentOnboarding' }
      & Pick<StudentOnboarding, '_id' | 'status'>
      & { potentialInstructors?: Maybe<Array<(
        { __typename?: 'StudentPotentialInstructor' }
        & Pick<StudentPotentialInstructor, '_id' | 'instructorId' | 'status' | 'isNewInstructor' | 'needsGroupTraining'>
      )>> }
    ) }
  ) }
);

export type GetStudentProposalsByTicketIdQueryVariables = Exact<{
  onboardingId?: Maybe<Scalars['ObjectId']>;
  handoffId?: Maybe<Scalars['ObjectId']>;
}>;


export type GetStudentProposalsByTicketIdQuery = (
  { __typename?: 'Query' }
  & { studentProposals: (
    { __typename?: 'StudentProposalsPayload' }
    & { items: Array<(
      { __typename?: 'StudentProposal' }
      & Pick<StudentProposal, '_id' | 'sentAt' | 'createdAt' | 'status' | 'scheduledSendDate'>
      & { instructor?: Maybe<(
        { __typename?: 'Instructor' }
        & Pick<Instructor, '_id' | 'acuityCalendarId'>
      )> }
    )> }
  ) }
);

export type InstructorAchievementFragment = (
  { __typename?: 'InstructorAchievement' }
  & Pick<InstructorAchievement, '_id' | 'name' | 'thumbnailUrl' | 'externalDescription' | 'internalDescription' | 'external' | 'startDate' | 'endDate' | 'isActive'>
);

export type InstructorFragment = (
  { __typename?: 'Instructor' }
  & Pick<Instructor, '_id'>
);

export type CreateInstructorAchievementMutationVariables = Exact<{
  input: CreateInstructorAchievementInput;
}>;


export type CreateInstructorAchievementMutation = (
  { __typename?: 'Mutation' }
  & { createInstructorAchievement: (
    { __typename?: 'CreateInstructorAchievementPayload' }
    & { instructorAchievement?: Maybe<(
      { __typename?: 'InstructorAchievement' }
      & InstructorAchievementFragment
    )> }
  ) }
);

export type UpdateInstructorAchievementMutationVariables = Exact<{
  input: UpdateInstructorAchievementInput;
}>;


export type UpdateInstructorAchievementMutation = (
  { __typename?: 'Mutation' }
  & { updateInstructorAchievement: (
    { __typename?: 'UpdateInstructorAchievementPayload' }
    & { instructorAchievement?: Maybe<(
      { __typename?: 'InstructorAchievement' }
      & InstructorAchievementFragment
    )> }
  ) }
);

export type DeleteInstructorAchievementMutationVariables = Exact<{
  input: DeleteInstructorAchievementInput;
}>;


export type DeleteInstructorAchievementMutation = (
  { __typename?: 'Mutation' }
  & { deleteInstructorAchievement: (
    { __typename?: 'DeleteInstructorAchievementPayload' }
    & Pick<DeleteInstructorAchievementPayload, 'success'>
    & { instructorAchievement?: Maybe<(
      { __typename?: 'InstructorAchievement' }
      & InstructorAchievementFragment
    )> }
  ) }
);

export type GrantInstructorAchievementMutationVariables = Exact<{
  input: GrantInstructorAchievementInput;
}>;


export type GrantInstructorAchievementMutation = (
  { __typename?: 'Mutation' }
  & { grantInstructorAchievement: (
    { __typename?: 'GrantInstructorAchievementPayload' }
    & Pick<GrantInstructorAchievementPayload, 'success'>
    & { instructor?: Maybe<(
      { __typename?: 'Instructor' }
      & InstructorFragment
    )> }
  ) }
);

export type GetInstructorAchievementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInstructorAchievementsQuery = (
  { __typename?: 'Query' }
  & { instructorAchievements: (
    { __typename?: 'InstructorAchievementsPayload' }
    & { items: Array<(
      { __typename?: 'InstructorAchievement' }
      & InstructorAchievementFragment
    )> }
  ) }
);

export type GetInstructorAchievementByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetInstructorAchievementByIdQuery = (
  { __typename?: 'Query' }
  & { instructorAchievementById?: Maybe<(
    { __typename?: 'InstructorAchievement' }
    & InstructorAchievementFragment
  )> }
);

export type GetInstructorAchievementsByInstructorIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetInstructorAchievementsByInstructorIdQuery = (
  { __typename?: 'Query' }
  & { instructorAchievementsByInstructorId: (
    { __typename?: 'InstructorAchievementsByInstructorIdPayload' }
    & { items: Array<(
      { __typename?: 'InstructorAchievement' }
      & InstructorAchievementFragment
    )> }
  ) }
);

export type InstructorRecurringScheduleFragment = (
  { __typename?: 'InstructorRecurringSchedule' }
  & Pick<InstructorRecurringSchedule, 'parentId' | 'studentId' | 'numSessionsCompletedTogether'>
  & { parent: (
    { __typename?: 'Parent' }
    & Pick<Parent, 'firstName' | 'lastName'>
  ), student?: Maybe<(
    { __typename?: 'Student' }
    & Pick<Student, 'firstName'>
  )>, appointments: (
    { __typename?: 'RecurringScheduleAppointments' }
    & { past: Array<(
      { __typename?: 'AcuityAppointment' }
      & AcuityAppointmentFragment
    )>, current: Array<(
      { __typename?: 'AcuityAppointment' }
      & AcuityAppointmentFragment
    )>, upcoming: Array<(
      { __typename?: 'AcuityAppointment' }
      & AcuityAppointmentFragment
    )> }
  ) }
);

export type LoadRecurringSchedulesForInstructorQueryVariables = Exact<{
  instructorId?: Maybe<Scalars['ObjectId']>;
}>;


export type LoadRecurringSchedulesForInstructorQuery = (
  { __typename?: 'Query' }
  & { recurringSchedulesForInstructor: (
    { __typename?: 'RecurringSchedulesForInstructorPayload' }
    & { items: Array<(
      { __typename?: 'InstructorRecurringSchedule' }
      & InstructorRecurringScheduleFragment
    )> }
  ) }
);

export type AdminDashRecurringRescheduleRequestFragment = (
  { __typename: 'RecurringRescheduleRequest' }
  & Pick<RecurringRescheduleRequest, '_id' | 'instructorId' | 'parentId' | 'studentId' | 'createdByUserId' | 'ownerUserId' | 'reason' | 'additionalNotesFromInstructor' | 'newRecurringScheduleDetailsPt' | 'hasDiscussedNewTimeWithParent' | 'lastTeachableSessionAcuityApptId' | 'lastTeachableSessionAcuityCalendarId' | 'lastTeachableSessionApptTypeId' | 'lastTeachableSessionDatetime' | 'studentFirstName' | 'instructorFullName' | 'internalNotes' | 'isAcuityUpdated' | 'emailSentAt' | 'requestCompletedAt' | 'createdAt' | 'updatedAt' | 'dueDate'>
  & { parent?: Maybe<(
    { __typename?: 'Parent' }
    & Pick<Parent, 'phone' | 'email' | 'firstName' | 'lastName' | 'hasMultipleChildren'>
  )> }
);

export type LoadRecurringRescheduleRequestsQueryVariables = Exact<{
  completed?: Maybe<Scalars['Boolean']>;
}>;


export type LoadRecurringRescheduleRequestsQuery = (
  { __typename?: 'Query' }
  & { recurringRescheduleRequests: (
    { __typename?: 'RecurringRescheduleRequestsPayload' }
    & { items: Array<(
      { __typename?: 'RecurringRescheduleRequest' }
      & AdminDashRecurringRescheduleRequestFragment
    )> }
  ) }
);

export type LoadRecurringRescheduleRequestByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type LoadRecurringRescheduleRequestByIdQuery = (
  { __typename?: 'Query' }
  & { recurringRescheduleRequest?: Maybe<(
    { __typename?: 'RecurringRescheduleRequest' }
    & AdminDashRecurringRescheduleRequestFragment
  )> }
);

export type CreateRecurringRescheduleRequestMutationVariables = Exact<{
  input: CreateRecurringRescheduleRequestInput;
}>;


export type CreateRecurringRescheduleRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRecurringRescheduleRequest: (
    { __typename?: 'CreateRecurringRescheduleRequestPayload' }
    & Pick<CreateRecurringRescheduleRequestPayload, 'success'>
  ) }
);

export type UpdateRecurringRescheduleRequestMutationVariables = Exact<{
  input: UpdateRecurringRescheduleRequestInput;
}>;


export type UpdateRecurringRescheduleRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateRecurringRescheduleRequest: (
    { __typename?: 'UpdateRecurringRescheduleRequestPayload' }
    & Pick<UpdateRecurringRescheduleRequestPayload, 'success'>
  ) }
);

export type CancelInstructorOnboardingApptMutationVariables = Exact<{
  input: CancelInstructorOnboardingApptInput;
}>;


export type CancelInstructorOnboardingApptMutation = (
  { __typename?: 'Mutation' }
  & { cancelInstructorOnboardingAppt: (
    { __typename?: 'CancelInstructorOnboardingApptPayload' }
    & Pick<CancelInstructorOnboardingApptPayload, 'success'>
  ) }
);

export type ScheduleInstructorOnboardingApptMutationVariables = Exact<{
  input: ScheduleInstructorOnboardingApptInput;
}>;


export type ScheduleInstructorOnboardingApptMutation = (
  { __typename?: 'Mutation' }
  & { scheduleInstructorOnboardingAppt: (
    { __typename?: 'ScheduleInstructorOnboardingApptPayload' }
    & Pick<ScheduleInstructorOnboardingApptPayload, 'success'>
  ) }
);

export type RescheduleInstructorOnboardingApptMutationVariables = Exact<{
  input: RescheduleInstructorOnboardingApptInput;
}>;


export type RescheduleInstructorOnboardingApptMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleInstructorOnboardingAppt: (
    { __typename?: 'RescheduleInstructorOnboardingApptPayload' }
    & Pick<RescheduleInstructorOnboardingApptPayload, 'success'>
  ) }
);

export type InstructorOnboardingRowFragment = (
  { __typename?: 'InstructorOnboarding' }
  & Pick<InstructorOnboarding, '_id' | 'instructorId' | 'instructorLogId' | 'subject' | 'status' | 'referringInstructorId' | 'referralPayoutDate' | 'referralPayoutDataEntered' | 'timezone' | 'idealStartDate' | 'onboardingCompletedAt' | 'firstClassCourseId' | 'firstClassStudentId' | 'notes' | 'createdAt' | 'completedAt'>
  & { tasks: Array<(
    { __typename?: 'InstructorOnboardingTask' }
    & Pick<InstructorOnboardingTask, '_id' | 'name' | 'isRequired' | 'completedAt' | 'acuityApptId' | 'acuityApptDatetime' | 'acuityApptCalendarId'>
  )> }
);

export type GetInstructorOnboardingsQueryVariables = Exact<{
  input?: Maybe<InstructorOnboardingQueryInput>;
}>;


export type GetInstructorOnboardingsQuery = (
  { __typename?: 'Query' }
  & { instructorOnboardings: (
    { __typename?: 'InstructorOnboardingsPayload' }
    & { items: Array<Maybe<(
      { __typename?: 'InstructorOnboarding' }
      & InstructorOnboardingRowFragment
    )>> }
  ) }
);

export type GetInstructorOnboardingByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetInstructorOnboardingByIdQuery = (
  { __typename?: 'Query' }
  & { instructorOnboardingById?: Maybe<(
    { __typename?: 'InstructorOnboarding' }
    & InstructorOnboardingRowFragment
  )> }
);

export type GetAppointmentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppointmentTypesQuery = (
  { __typename?: 'Query' }
  & { appointmentTypes: (
    { __typename?: 'AppointmentTypesPayload' }
    & { items: Array<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'name' | 'calendarIDs'>
    )> }
  ) }
);

export type UpdateInstructorOnboardingMutationVariables = Exact<{
  input: UpdateInstructorOnboardingInput;
}>;


export type UpdateInstructorOnboardingMutation = (
  { __typename?: 'Mutation' }
  & { updateInstructorOnboarding: (
    { __typename?: 'UpdateInstructorOnboardingPayload' }
    & { instructorOnboarding: (
      { __typename?: 'InstructorOnboarding' }
      & InstructorOnboardingRowFragment
    ) }
  ) }
);

export type UpdateInstructorOnboardingTaskMutationVariables = Exact<{
  input: UpdateInstructorOnboardingTaskInput;
}>;


export type UpdateInstructorOnboardingTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateInstructorOnboardingTask: (
    { __typename?: 'UpdateInstructorOnboardingTaskPayload' }
    & { instructorOnboarding: (
      { __typename?: 'InstructorOnboarding' }
      & InstructorOnboardingRowFragment
    ) }
  ) }
);

export type PayrollInstructorFragment = (
  { __typename?: 'Instructor' }
  & Pick<Instructor, '_id' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'excludeFromHourlyPayroll' | 'acuityCalendarId' | 'selfReportedHours'>
);

export type LoadPayrollInstructorsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadPayrollInstructorsQuery = (
  { __typename?: 'Query' }
  & { instructors: (
    { __typename?: 'InstructorsPayload' }
    & { items: Array<(
      { __typename?: 'Instructor' }
      & PayrollInstructorFragment
    )> }
  ) }
);

export type LoadPayrollCyclesQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadPayrollCyclesQuery = (
  { __typename?: 'Query' }
  & { payrollCycles: Array<(
    { __typename?: 'PayrollCycle' }
    & Pick<PayrollCycle, '_id' | 'startDate' | 'endDate' | 'releaseDate'>
  )> }
);

export type StartImpersonationMutationVariables = Exact<{
  input: StartImpersonationInput;
}>;


export type StartImpersonationMutation = (
  { __typename?: 'Mutation' }
  & { startImpersonation: (
    { __typename?: 'StartImpersonationPayload' }
    & Pick<StartImpersonationPayload, 'success' | 'token'>
  ) }
);

export type StopImpersonationMutationVariables = Exact<{ [key: string]: never; }>;


export type StopImpersonationMutation = (
  { __typename?: 'Mutation' }
  & { stopImpersonation: (
    { __typename?: 'StopImpersonationPayload' }
    & Pick<StopImpersonationPayload, 'success' | 'token'>
  ) }
);

export type InstructorEnabledForSlackMessagingQueryVariables = Exact<{
  instructorId: Scalars['ObjectId'];
}>;


export type InstructorEnabledForSlackMessagingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'instructorEnabledForSlackMessaging'>
);

export type SlackUserIdForInstructorMutationVariables = Exact<{
  input: SlackUserIdForInstructorInput;
}>;


export type SlackUserIdForInstructorMutation = (
  { __typename?: 'Mutation' }
  & { slackUserIdForInstructor: (
    { __typename?: 'SlackUserIdForInstructorPayload' }
    & Pick<SlackUserIdForInstructorPayload, 'slackUserId'>
  ) }
);

export type SendSlackMessageToInstructorMutationVariables = Exact<{
  input: SendSlackMessageToInstructorInput;
}>;


export type SendSlackMessageToInstructorMutation = (
  { __typename?: 'Mutation' }
  & { sendSlackMessageToInstructor: (
    { __typename?: 'SendSlackMessageToInstructorPayload' }
    & Pick<SendSlackMessageToInstructorPayload, 'sent'>
  ) }
);

export type UserSmsConsentStatusByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type UserSmsConsentStatusByIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userSmsConsentStatusById'>
);

export type SendSmsMessageToParentMutationVariables = Exact<{
  input: SendSmsMessageToParentInput;
}>;


export type SendSmsMessageToParentMutation = (
  { __typename?: 'Mutation' }
  & { sendSmsMessageToParent: (
    { __typename?: 'SendSmsMessageToParentPayload' }
    & Pick<SendSmsMessageToParentPayload, 'sent'>
  ) }
);

export type JuniClubMemberTypeFragment = (
  { __typename?: 'JuniClubMemberType' }
  & Pick<JuniClubMemberType, 'firstName' | 'lastInitial' | 'userCategory' | 'membershipStatus' | 'username'>
);

export type GetJuniClubUsersQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetJuniClubUsersQuery = (
  { __typename?: 'Query' }
  & { getJuniClubUsers: (
    { __typename?: 'GetJuniClubUsersPayload' }
    & { items: Array<(
      { __typename?: 'JuniClubMemberType' }
      & JuniClubMemberTypeFragment
    )> }
  ) }
);

export type GetCommunityEventsByStartDatesQueryVariables = Exact<{
  startDates: Array<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
}>;


export type GetCommunityEventsByStartDatesQuery = (
  { __typename?: 'Query' }
  & { getCommunityEventsByStartDates: (
    { __typename?: 'GetCommunityEventsByStartDatesPayload' }
    & { items: Array<(
      { __typename?: 'JuniCommunityEventType' }
      & Pick<JuniCommunityEventType, '_id' | 'title' | 'description' | 'thumbnailUrl' | 'startDateTime' | 'endDateTime' | 'archivedAt' | 'juniClubIds' | 'eventLink'>
      & { juniClubs?: Maybe<Array<(
        { __typename?: 'JuniClubType' }
        & JuniClubFragment
      )>> }
    )> }
  ) }
);

export type JuniClubFragment = (
  { __typename?: 'JuniClubType' }
  & Pick<JuniClubType, '_id' | 'displayName' | 'description' | 'tags' | 'colorTheme' | 'coverPhoto' | 'logo' | 'creatorStudentId' | 'creatorInstructorUserId' | 'isJuniModerated' | 'archivedAt' | 'createdAt' | 'updatedAt' | 'memberCount'>
  & { channels?: Maybe<Array<(
    { __typename?: 'JuniClubChannelType' }
    & Pick<JuniClubChannelType, '_id' | 'displayName' | 'createdAt' | 'archivedAt'>
  )>> }
);

export type GetJuniClubBySearchStringQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type GetJuniClubBySearchStringQuery = (
  { __typename?: 'Query' }
  & { getJuniClubBySearchString: (
    { __typename?: 'GetJuniClubBySearchStringPayload' }
    & { items: Array<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id' | 'displayName' | 'description' | 'tags' | 'colorTheme' | 'coverPhoto' | 'logo' | 'creatorStudentId' | 'creatorInstructorUserId' | 'isJuniModerated' | 'archivedAt' | 'createdAt' | 'updatedAt' | 'memberCount'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, '_id' | 'displayName' | 'createdAt' | 'archivedAt'>
      )>> }
    )> }
  ) }
);

export type GetJuniClubsByTagQueryVariables = Exact<{
  tag: Scalars['String'];
}>;


export type GetJuniClubsByTagQuery = (
  { __typename?: 'Query' }
  & { getJuniClubsByTag: (
    { __typename?: 'GetJuniClubsByTagPayload' }
    & { items: Array<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id' | 'displayName' | 'description' | 'tags' | 'colorTheme' | 'coverPhoto' | 'logo' | 'creatorStudentId' | 'creatorInstructorUserId' | 'isJuniModerated' | 'archivedAt' | 'createdAt' | 'updatedAt' | 'memberCount'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, '_id' | 'displayName' | 'createdAt' | 'archivedAt'>
      )>> }
    )> }
  ) }
);

export type JoinLeaveClubMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  isActive: Scalars['Boolean'];
}>;


export type JoinLeaveClubMutation = (
  { __typename?: 'Mutation' }
  & { joinLeaveClub: (
    { __typename?: 'JoinLeaveClubPayload' }
    & { juniClubMembership?: Maybe<(
      { __typename?: 'JuniClubMembershipType' }
      & Pick<JuniClubMembershipType, '_id'>
    )> }
  ) }
);

export type GetUsernameByIdQueryVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
}>;


export type GetUsernameByIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsernameById'>
);

export type UpdateMembershipStatusMutationVariables = Exact<{
  juniClubId: Scalars['ObjectId'];
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  memberUserCategory: Scalars['String'];
  memberClientUUID: Scalars['String'];
  newMembershipStatus: Scalars['String'];
}>;


export type UpdateMembershipStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateMembershipStatus: (
    { __typename?: 'UpdateMembershipStatusPayload' }
    & Pick<UpdateMembershipStatusPayload, 'success'>
  ) }
);

export type SendImageUploadSlackMessageMutationVariables = Exact<{
  input: SendImageUploadSlackMessageInput;
}>;


export type SendImageUploadSlackMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendImageUploadSlackMessage: (
    { __typename?: 'SendImageUploadSlackMessagePayload' }
    & Pick<SendImageUploadSlackMessagePayload, 'success'>
  ) }
);

export type RefreshPubNubCredentialsMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
}>;


export type RefreshPubNubCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { refreshPubNubCredentials: (
    { __typename?: 'RefreshPubNubCredentialsPayload' }
    & Pick<RefreshPubNubCredentialsPayload, 'publishKey' | 'subscribeKey' | 'authKey' | 'clientUUID' | 'expiresAt'>
  ) }
);

export type MyClubsStateMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  specificClubs?: Maybe<Array<Scalars['ObjectId']>>;
  includeLastReadTimetokens?: Maybe<Scalars['Boolean']>;
}>;


export type MyClubsStateMutation = (
  { __typename?: 'Mutation' }
  & { myClubsState: (
    { __typename?: 'MyClubsStatePayload' }
    & { juniClubs?: Maybe<Array<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id' | 'displayName' | 'description' | 'tags' | 'colorTheme' | 'coverPhoto' | 'logo' | 'creatorInstructorUserId' | 'isJuniModerated' | 'archivedAt' | 'createdAt' | 'updatedAt'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, 'displayName' | 'createdAt' | 'archivedAt' | 'readOnly'>
      )>> }
    )>>, juniClubMembershipInfos?: Maybe<Array<(
      { __typename?: 'JuniClubMembershipInfoType' }
      & Pick<JuniClubMembershipInfoType, '_id' | 'firstName' | 'lastInitial' | 'userCategory' | 'membershipStatus' | 'username' | 'clientUUID' | 'juniClubId' | 'isActive'>
    )>>, lastReadTimetokens?: Maybe<Array<(
      { __typename?: 'LastReadTimetokensType' }
      & Pick<LastReadTimetokensType, 'channelName' | 'timetoken'>
    )>>, juniCommunityEvents?: Maybe<Array<(
      { __typename?: 'JuniCommunityEventType' }
      & Pick<JuniCommunityEventType, '_id' | 'title' | 'description' | 'startDateTime' | 'endDateTime' | 'juniClubIds' | 'creatorInstructorUserId' | 'thumbnailUrl' | 'archivedAt' | 'eventLink'>
    )>> }
  ) }
);

export type RemoveViolatingMessageMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  channelName: Scalars['String'];
  msgId: Scalars['String'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
}>;


export type RemoveViolatingMessageMutation = (
  { __typename?: 'Mutation' }
  & { removeViolatingMessage: (
    { __typename?: 'RemoveViolatingMessagePayload' }
    & Pick<RemoveViolatingMessagePayload, 'success'>
  ) }
);

export type DeleteMessageMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  channelName: Scalars['String'];
  msgId: Scalars['String'];
  senderUUID?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage: (
    { __typename?: 'DeleteMessagePayload' }
    & Pick<DeleteMessagePayload, 'success'>
  ) }
);

export type CheckMessageContentMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  message: Scalars['String'];
}>;


export type CheckMessageContentMutation = (
  { __typename?: 'Mutation' }
  & { checkMessageContent: (
    { __typename?: 'CheckMessageContentPayload' }
    & Pick<CheckMessageContentPayload, 'success' | 'moderationErrorMessage'>
  ) }
);

export type CheckImageContentMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  imageUrl: Scalars['String'];
}>;


export type CheckImageContentMutation = (
  { __typename?: 'Mutation' }
  & { checkImageContent: (
    { __typename?: 'CheckImageContentPayload' }
    & Pick<CheckImageContentPayload, 'success' | 'violations'>
  ) }
);

export type UpdateLastReadTimetokenMutationVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorUserId?: Maybe<Scalars['ObjectId']>;
  juniClubId: Scalars['ObjectId'];
  channelName: Scalars['String'];
  timetoken: Scalars['String'];
}>;


export type UpdateLastReadTimetokenMutation = (
  { __typename?: 'Mutation' }
  & { updateLastReadTimetoken: (
    { __typename?: 'UpdateLastReadTimetokenPayload' }
    & Pick<UpdateLastReadTimetokenPayload, 'success'>
  ) }
);

export type GetFeaturedMyFeedProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturedMyFeedProjectsQuery = (
  { __typename?: 'Query' }
  & { featuredStudentProjects: (
    { __typename?: 'FeaturedStudentProjectsPayload' }
    & { items: Array<Maybe<(
      { __typename?: 'StudentProject' }
      & Pick<StudentProject, '_id' | 'projectType' | 'lastUpdatedAt' | 'title' | 'description' | 'juniverseTags' | 'thumbnailUrl' | 'attachedUsermediaUrls' | 'viewCount'>
      & { creator?: Maybe<(
        { __typename?: 'StudentProjectCreator' }
        & Pick<StudentProjectCreator, 'username' | 'displayName' | 'type' | 'isGuest'>
      )> }
    )>> }
  ) }
);

export type SubmitNylasCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type SubmitNylasCodeMutation = (
  { __typename?: 'Mutation' }
  & { submitNylasCode: (
    { __typename?: 'SubmitNylasCodePayload' }
    & { instructor?: Maybe<(
      { __typename?: 'Instructor' }
      & Pick<Instructor, '_id' | 'nylasConnected'>
      & { nylas?: Maybe<(
        { __typename?: 'NylasSubdocument' }
        & Pick<NylasSubdocument, 'availabilityCalendarId'>
      )> }
    )> }
  ) }
);

export type GetNylasConnectedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNylasConnectedQuery = (
  { __typename?: 'Query' }
  & { findMeInstructor: (
    { __typename?: 'Instructor' }
    & Pick<Instructor, '_id' | 'nylasConnected'>
    & { nylas?: Maybe<(
      { __typename?: 'NylasSubdocument' }
      & Pick<NylasSubdocument, 'availabilityCalendarId'>
    )> }
  ) }
);

export type NylasCalendarSelectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type NylasCalendarSelectionsQuery = (
  { __typename?: 'Query' }
  & { findMeInstructor: (
    { __typename?: 'Instructor' }
    & Pick<Instructor, '_id'>
    & { nylasBusyCalendarOptions: Array<(
      { __typename?: 'NylasBusyCalendarOption' }
      & Pick<NylasBusyCalendarOption, 'name' | 'id' | 'watched'>
    )> }
  ) }
);

export type SetNylasCalendarWatchedMutationVariables = Exact<{
  watched: Scalars['Boolean'];
  id: Scalars['String'];
}>;


export type SetNylasCalendarWatchedMutation = (
  { __typename?: 'Mutation' }
  & { setNylasCalendarWatched: (
    { __typename?: 'SetNylasCalendarWatchedPayload' }
    & { instructor?: Maybe<(
      { __typename?: 'Instructor' }
      & Pick<Instructor, '_id'>
      & { nylasBusyCalendarOptions: Array<(
        { __typename?: 'NylasBusyCalendarOption' }
        & Pick<NylasBusyCalendarOption, 'name' | 'id' | 'watched'>
      )> }
    )> }
  ) }
);

export type DisconnectNylasMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectNylasMutation = (
  { __typename?: 'Mutation' }
  & { disconnectNylas?: Maybe<(
    { __typename?: 'disconnectNylasPayload' }
    & { instructor?: Maybe<(
      { __typename?: 'Instructor' }
      & Pick<Instructor, '_id' | 'nylasConnected'>
      & { nylasBusyCalendarOptions: Array<(
        { __typename?: 'NylasBusyCalendarOption' }
        & Pick<NylasBusyCalendarOption, 'name' | 'id' | 'watched'>
      )> }
    )> }
  )> }
);

export type SyncCalendarsMutationVariables = Exact<{
  userId: Scalars['ObjectId'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type SyncCalendarsMutation = (
  { __typename?: 'Mutation' }
  & { syncCalendars: (
    { __typename?: 'SyncCalendarsPayload' }
    & Pick<SyncCalendarsPayload, 'success'>
  ) }
);

export type BaseStudentProposalFragment = (
  { __typename?: 'StudentProposal' }
  & Pick<StudentProposal, '_id'>
  & { ticket?: Maybe<(
    { __typename?: 'StudentProposalTicket' }
    & Pick<StudentProposalTicket, '_id' | 'courseNames' | 'scheduleType' | 'ticketType' | 'studentProposalNotes'>
    & { students?: Maybe<Array<(
      { __typename?: 'StudentProposalStudent' }
      & Pick<StudentProposalStudent, '_id' | 'name'>
    )>> }
  )> }
);

export type CurrentStudentProposalFragment = (
  { __typename?: 'StudentProposal' }
  & Pick<StudentProposal, '_id'>
  & { ticket?: Maybe<(
    { __typename?: 'StudentProposalTicket' }
    & Pick<StudentProposalTicket, 'firstClassIsShadow' | 'upcomingClassTimes'>
    & { potentialFirstClassDatetimes?: Maybe<Array<(
      { __typename?: 'StudentFirstClassDatetime' }
      & Pick<StudentFirstClassDatetime, '_id' | 'datetime'>
    )>> }
  )> }
  & BaseStudentProposalFragment
);

export type PastStudentProposalFragment = (
  { __typename?: 'StudentProposal' }
  & Pick<StudentProposal, '_id' | 'status'>
  & { ticket?: Maybe<(
    { __typename?: 'StudentProposalTicket' }
    & { firstClassDatetimes?: Maybe<Array<(
      { __typename?: 'StudentFirstClassDatetime' }
      & Pick<StudentFirstClassDatetime, '_id' | 'datetime'>
    )>> }
  )> }
  & BaseStudentProposalFragment
);

export type GetCurrentStudentProposalsQueryVariables = Exact<{
  userId?: Maybe<Scalars['ObjectId']>;
}>;


export type GetCurrentStudentProposalsQuery = (
  { __typename?: 'Query' }
  & { studentProposals: (
    { __typename?: 'StudentProposalsPayload' }
    & { items: Array<(
      { __typename?: 'StudentProposal' }
      & CurrentStudentProposalFragment
    )> }
  ) }
);

export type GetPastStudentProposalsQueryVariables = Exact<{
  userId?: Maybe<Scalars['ObjectId']>;
}>;


export type GetPastStudentProposalsQuery = (
  { __typename?: 'Query' }
  & { studentProposals: (
    { __typename?: 'StudentProposalsPayload' }
    & { items: Array<(
      { __typename?: 'StudentProposal' }
      & PastStudentProposalFragment
    )> }
  ) }
);

export type AcceptStudentProposalMutationVariables = Exact<{
  input: AcceptStudentProposalInput;
}>;


export type AcceptStudentProposalMutation = (
  { __typename?: 'Mutation' }
  & { acceptStudentProposal: (
    { __typename?: 'AcceptStudentProposalPayload' }
    & { studentProposal: (
      { __typename?: 'StudentProposal' }
      & Pick<StudentProposal, '_id'>
    ) }
  ) }
);

export type DeclineStudentProposalMutationVariables = Exact<{
  input: DeclineStudentProposalInput;
}>;


export type DeclineStudentProposalMutation = (
  { __typename?: 'Mutation' }
  & { declineStudentProposal: (
    { __typename?: 'DeclineStudentProposalPayload' }
    & { studentProposal: (
      { __typename?: 'StudentProposal' }
      & Pick<StudentProposal, '_id'>
    ) }
  ) }
);

export type AcuityAppointmentFragment = (
  { __typename?: 'AcuityAppointment' }
  & Pick<AcuityAppointment, 'appointmentTypeID' | 'calendar' | 'calendarID' | 'canceled' | 'canClientCancel' | 'canClientReschedule' | 'date' | 'datetime' | 'datetimeCreated' | 'duration' | 'email' | 'firstName' | 'id' | 'lastName' | 'phone' | 'scheduledBy' | 'time' | 'timezone' | 'type' | 'studentName'>
);

export type LoadUpcomingClassAppointmentsForInstructorQueryVariables = Exact<{
  userId?: Maybe<Scalars['ObjectId']>;
  maxDays?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}>;


export type LoadUpcomingClassAppointmentsForInstructorQuery = (
  { __typename?: 'Query' }
  & { upcomingClassAppointmentsForInstructor: (
    { __typename?: 'UpcomingClassAppointmentsForInstructorPayload' }
    & { items: Array<(
      { __typename?: 'AcuityAppointment' }
      & AcuityAppointmentFragment
    )> }
  ) }
);

export type RecurringAvailabilityUpdateRequestFragment = (
  { __typename: 'RecurringAvailabilityUpdateRequest' }
  & Pick<RecurringAvailabilityUpdateRequest, '_id' | 'dateEffective' | 'reason' | 'additionalNotes' | 'instructorId' | 'createdAt' | 'updatedAt' | 'requestCompletedAt'>
  & { availabilityByDay: (
    { __typename?: 'AvailabilityByDay' }
    & Pick<AvailabilityByDay, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
  ) }
);

export type CreateRecurringAvailabilityUpdateRequestMutationVariables = Exact<{
  input: CreateRecurringAvailabilityUpdateRequestInput;
}>;


export type CreateRecurringAvailabilityUpdateRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRecurringAvailabilityUpdateRequest: (
    { __typename?: 'CreateRecurringAvailabilityUpdateRequestPayload' }
    & Pick<CreateRecurringAvailabilityUpdateRequestPayload, 'success'>
  ) }
);

export type LoadMyRecurringAvailabilityUpdateRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadMyRecurringAvailabilityUpdateRequestsQuery = (
  { __typename?: 'Query' }
  & { myRecurringAvailabilityUpdateRequests: (
    { __typename?: 'MyRecurringAvailabilityUpdateRequestsPayload' }
    & { items: Array<(
      { __typename?: 'RecurringAvailabilityUpdateRequest' }
      & RecurringAvailabilityUpdateRequestFragment
    )> }
  ) }
);

export type RecurringRescheduleRequestFragment = (
  { __typename: 'RecurringRescheduleRequest' }
  & Pick<RecurringRescheduleRequest, '_id' | 'instructorId' | 'parentId' | 'studentId' | 'createdByUserId' | 'reason' | 'additionalNotesFromInstructor' | 'newRecurringScheduleDetailsPt' | 'hasDiscussedNewTimeWithParent' | 'lastTeachableSessionAcuityApptId' | 'lastTeachableSessionAcuityCalendarId' | 'lastTeachableSessionApptTypeId' | 'lastTeachableSessionDatetime' | 'studentFirstName' | 'requestCompletedAt' | 'createdAt' | 'updatedAt'>
);

export type CreateMyRecurringRescheduleRequestMutationVariables = Exact<{
  input: CreateMyRecurringRescheduleRequestInput;
}>;


export type CreateMyRecurringRescheduleRequestMutation = (
  { __typename?: 'Mutation' }
  & { createMyRecurringRescheduleRequest: (
    { __typename?: 'CreateMyRecurringRescheduleRequestPayload' }
    & Pick<CreateMyRecurringRescheduleRequestPayload, 'success'>
  ) }
);

export type UpdateMyRecurringRescheduleRequestMutationVariables = Exact<{
  input: UpdateMyRecurringRescheduleRequestInput;
}>;


export type UpdateMyRecurringRescheduleRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateMyRecurringRescheduleRequest: (
    { __typename?: 'UpdateMyRecurringRescheduleRequestPayload' }
    & { recurringRescheduleRequest: (
      { __typename?: 'RecurringRescheduleRequest' }
      & RecurringRescheduleRequestFragment
    ) }
  ) }
);

export type LoadMyRecurringRescheduleRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadMyRecurringRescheduleRequestsQuery = (
  { __typename?: 'Query' }
  & { myRecurringRescheduleRequests: (
    { __typename?: 'MyRecurringRescheduleRequestsPayload' }
    & { items: Array<(
      { __typename?: 'RecurringRescheduleRequest' }
      & RecurringRescheduleRequestFragment
    )> }
  ) }
);

export type StudentCompletedBundleDataQueryVariables = Exact<{
  params: Scalars['String'];
}>;


export type StudentCompletedBundleDataQuery = (
  { __typename?: 'Query' }
  & { studentCompletedBundleData?: Maybe<(
    { __typename?: 'StudentCompletedBundleData' }
    & Pick<StudentCompletedBundleData, 'studentId' | 'firstName' | 'lastName' | 'username' | 'bundleName' | 'bundleDisplayName' | 'bundleCategory' | 'bundleCategoryBadge' | 'completionDate'>
  )> }
);

export type StudentCompletedCourseParamsQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
  courseName: Scalars['String'];
}>;


export type StudentCompletedCourseParamsQuery = (
  { __typename?: 'Query' }
  & { studentCompletedCourseParams?: Maybe<(
    { __typename?: 'StudentCompletedCourseParams' }
    & Pick<StudentCompletedCourseParams, 'params'>
  )> }
);

export type StudentCompletedCourseDataQueryVariables = Exact<{
  params: Scalars['String'];
}>;


export type StudentCompletedCourseDataQuery = (
  { __typename?: 'Query' }
  & { studentCompletedCourseData?: Maybe<(
    { __typename?: 'StudentCompletedCourseData' }
    & Pick<StudentCompletedCourseData, 'studentId' | 'firstName' | 'lastName' | 'username' | 'courseId' | 'courseName' | 'courseDisplayName' | 'courseSubject' | 'masterProjectModuleId' | 'masterProjectProjectId' | 'completionDate' | 'referralCode' | 'parentFirstName' | 'isStudentParentAccount' | 'encryptedStudentAndProject'>
  )> }
);

export type CourseCompletionDateQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
  courseName: Scalars['String'];
}>;


export type CourseCompletionDateQuery = (
  { __typename?: 'Query' }
  & { courseCompletionDate: (
    { __typename?: 'CourseCompletionInfo' }
    & Pick<CourseCompletionInfo, 'completionDate'>
  ) }
);

export type LoadProductsByStripeIdQueryVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type LoadProductsByStripeIdQuery = (
  { __typename?: 'Query' }
  & { productsByStripeIds: (
    { __typename?: 'ProductsByStripeIdsPayload' }
    & { items: Array<(
      { __typename?: 'StripeProduct' }
      & Pick<StripeProduct, 'name' | 'format' | 'stripeId'>
      & { scheduleFrequency: Array<(
        { __typename?: 'StripeProductScheduleFrequency' }
        & Pick<StripeProductScheduleFrequency, 'appointmentTypeIDs' | 'weeklyFrequency'>
      )> }
    )> }
  ) }
);

export type LoadDefaultPaymentInformationByParentIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type LoadDefaultPaymentInformationByParentIdQuery = (
  { __typename?: 'Query' }
  & { defaultPaymentInformationByParentId?: Maybe<(
    { __typename?: 'StripePaymentMethod' }
    & Pick<StripePaymentMethod, 'object' | 'brand' | 'last4' | 'exp_month' | 'exp_year'>
  )> }
);

export type LoadPaymentHistoryByParentIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type LoadPaymentHistoryByParentIdQuery = (
  { __typename?: 'Query' }
  & { paymentHistoryByParentId: (
    { __typename?: 'PaymentHistoryByParentIdPayload' }
    & { items: Array<(
      { __typename?: 'StripeCharge' }
      & StripeChargeFragment
    )> }
  ) }
);

export type LoadStripeSubscriptionsByParentIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type LoadStripeSubscriptionsByParentIdQuery = (
  { __typename?: 'Query' }
  & { stripeSubscriptionsByParentId: (
    { __typename?: 'StripeSubscriptionsByParentIdPayload' }
    & { items: Array<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'id' | 'object' | 'customer' | 'status' | 'current_period_start' | 'current_period_end' | 'canceled_at'>
      & { metadata?: Maybe<(
        { __typename?: 'StripeSubscriptionMetadata' }
        & Pick<StripeSubscriptionMetadata, 'studentIds' | 'cs_weeklyFrequency' | 'math_weeklyFrequency' | 'english_weeklyFrequency' | 'investing_weeklyFrequency' | 'science_weeklyFrequency' | 'usaco_weeklyFrequency' | 'juniJr_weeklyFrequency' | 'version'>
      )>, items: (
        { __typename?: 'StripeSubscriptionItems' }
        & { data: Array<(
          { __typename?: 'StripeSubscriptionItem' }
          & Pick<StripeSubscriptionItem, 'id'>
          & { plan: (
            { __typename?: 'StripePlan' }
            & StripePlanFragment
          ), price: (
            { __typename?: 'StripePrice' }
            & StripePriceFragment
          ) }
        )> }
      ), plan?: Maybe<(
        { __typename?: 'StripePlan' }
        & StripePlanFragment
      )>, discount?: Maybe<(
        { __typename?: 'StripeDiscount' }
        & Pick<StripeDiscount, 'id'>
        & { coupon?: Maybe<(
          { __typename?: 'StripeCoupon' }
          & Pick<StripeCoupon, 'object' | 'id' | 'amount_off' | 'duration' | 'percent_off' | 'percent_off_precise' | 'created' | 'valid' | 'currency'>
        )> }
      )> }
    )> }
  ) }
);

export type CreateStripeSubscriptionMutationVariables = Exact<{
  input: CreateStripeSubscriptionInput;
}>;


export type CreateStripeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createStripeSubscription: (
    { __typename?: 'CreateStripeSubscriptionPayload' }
    & Pick<CreateStripeSubscriptionPayload, 'success'>
  ) }
);

export type CancelStripeSubscriptionMutationVariables = Exact<{
  input: CancelStripeSubscriptionInput;
}>;


export type CancelStripeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelStripeSubscription: (
    { __typename?: 'CancelStripeSubscriptionPayload' }
    & Pick<CancelStripeSubscriptionPayload, 'success'>
  ) }
);

export type DowngradeStripeSubscriptionMutationVariables = Exact<{
  input: DowngradeStripeSubscriptionInput;
}>;


export type DowngradeStripeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { downgradeStripeSubscription: (
    { __typename?: 'DowngradeStripeSubscriptionPayload' }
    & Pick<DowngradeStripeSubscriptionPayload, 'success'>
  ) }
);

export type PurchaseBootcampMutationVariables = Exact<{
  input: PurchaseBootcampInput;
}>;


export type PurchaseBootcampMutation = (
  { __typename?: 'Mutation' }
  & { purchaseBootcamp: (
    { __typename?: 'PurchaseBootcampPayload' }
    & Pick<PurchaseBootcampPayload, 'success'>
  ) }
);

export type StudentFragmentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, '_id' | 'firstName' | 'lastName' | 'username' | 'hasMultipleTracks' | 'tracks' | 'track' | 'completedTracks'>
);

export type LoadCertificationTracksByStudentQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type LoadCertificationTracksByStudentQuery = (
  { __typename?: 'Query' }
  & { studentById?: Maybe<(
    { __typename?: 'Student' }
    & { certificationTracks?: Maybe<Array<Maybe<(
      { __typename?: 'CertificationTracks' }
      & Pick<CertificationTracks, 'name' | 'isFavorite'>
    )>>> }
  )> }
);

export type LoadStudentsByParentIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type LoadStudentsByParentIdsQuery = (
  { __typename?: 'Query' }
  & { studentsByParentIds: (
    { __typename?: 'StudentsByParentIdsPayload' }
    & { items: Array<(
      { __typename?: 'Student' }
      & StudentFragmentFragment
    )> }
  ) }
);

export type LoadAvailabilitiesByStudentIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type LoadAvailabilitiesByStudentIdsQuery = (
  { __typename?: 'Query' }
  & { availabilitiesByStudentIds: (
    { __typename?: 'AvailabilitiesByStudentIdsPayload' }
    & { items: Array<(
      { __typename?: 'Availability' }
      & Pick<Availability, '_id' | 'availability' | 'studentId' | 'timezone'>
    )> }
  ) }
);

export type LoadProductsByStripeIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type LoadProductsByStripeIdsQuery = (
  { __typename?: 'Query' }
  & { productsByStripeIds: (
    { __typename?: 'ProductsByStripeIdsPayload' }
    & { items: Array<(
      { __typename?: 'StripeProduct' }
      & Pick<StripeProduct, 'name' | 'format' | 'stripeId'>
      & { scheduleFrequency: Array<(
        { __typename?: 'StripeProductScheduleFrequency' }
        & Pick<StripeProductScheduleFrequency, 'appointmentTypeIDs' | 'weeklyFrequency'>
      )> }
    )> }
  ) }
);

export type CreateStudentMutationVariables = Exact<{
  input: CreateStudentInput;
}>;


export type CreateStudentMutation = (
  { __typename?: 'Mutation' }
  & { createStudent: (
    { __typename?: 'CreateStudentPayload' }
    & { student?: Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, '_id'>
    )> }
  ) }
);

export type PrependStudentNotesMutationVariables = Exact<{
  input: PrependStudentNotesInput;
}>;


export type PrependStudentNotesMutation = (
  { __typename?: 'Mutation' }
  & { prependStudentNotes: (
    { __typename?: 'PrependStudentNotesPayload' }
    & Pick<PrependStudentNotesPayload, 'success'>
  ) }
);

export type UpdateLearningPreferencesMutationVariables = Exact<{
  input: UpdateLearningPreferencesInput;
}>;


export type UpdateLearningPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateLearningPreferences: (
    { __typename?: 'UpdateLearningPreferencesPayload' }
    & { updatedLearningPreference?: Maybe<(
      { __typename?: 'LearningPreferences' }
      & Pick<LearningPreferences, 'subject' | 'lessonPace' | 'homeworkLoad' | 'noteForInstructor' | 'updatedAt'>
    )> }
  ) }
);

export type UpdateCertificationTracksMutationVariables = Exact<{
  input: UpdateStudentInput;
}>;


export type UpdateCertificationTracksMutation = (
  { __typename?: 'Mutation' }
  & { updateStudent: (
    { __typename?: 'UpdateStudentPayload' }
    & { student?: Maybe<(
      { __typename?: 'Student' }
      & { certificationTracks?: Maybe<Array<Maybe<(
        { __typename?: 'CertificationTracks' }
        & Pick<CertificationTracks, 'name' | 'isFavorite'>
      )>>> }
    )> }
  ) }
);

export type LoadCourseRubricForLearnerQueryVariables = Exact<{
  input: CourseRubricForLearnerInput;
}>;


export type LoadCourseRubricForLearnerQuery = (
  { __typename?: 'Query' }
  & { courseRubricForLearner?: Maybe<(
    { __typename?: 'CourseRubricProgress' }
    & CourseRubricProgressFragmentFragment
  )> }
);

export type StudentBundleCertificateParamsQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
}>;


export type StudentBundleCertificateParamsQuery = (
  { __typename?: 'Query' }
  & { studentCertificateParams: (
    { __typename?: 'StudentCertificateParamsPayload' }
    & { items: Array<(
      { __typename?: 'StudentCertificateParams' }
      & Pick<StudentCertificateParams, 'bundleName' | 'params'>
    )> }
  ) }
);

export type StudentCertificateParamsQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
}>;


export type StudentCertificateParamsQuery = (
  { __typename?: 'Query' }
  & { studentCertificateParams: (
    { __typename?: 'StudentCertificateParamsPayload' }
    & { items: Array<(
      { __typename?: 'StudentCertificateParams' }
      & Pick<StudentCertificateParams, 'courseName' | 'params'>
    )> }
  ) }
);

export type GetAnnouncementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnnouncementsQuery = (
  { __typename?: 'Query' }
  & { queryAnnouncements: (
    { __typename?: 'QueryAnnouncementsPayload' }
    & { items: Array<(
      { __typename?: 'Announcement' }
      & Pick<Announcement, '_id' | 'backgroundImageURL' | 'title' | 'blurb' | 'description'>
    )> }
  ) }
);

export type LoadPopupQueryVariables = Exact<{
  property: Scalars['String'];
}>;


export type LoadPopupQuery = (
  { __typename?: 'Query' }
  & { serveJuniAd: (
    { __typename?: 'ServeJuniAdPayload' }
    & { items: Array<(
      { __typename?: 'JuniAd' }
      & Pick<JuniAd, '_id' | 'title' | 'imageS3Url' | 'copy'>
      & { primaryAction?: Maybe<(
        { __typename?: 'JuniAdAction' }
        & Pick<JuniAdAction, 'label' | 'clickthroughUrl'>
      )>, secondaryAction?: Maybe<(
        { __typename?: 'JuniAdAction' }
        & Pick<JuniAdAction, 'label' | 'clickthroughUrl'>
      )> }
    )> }
  ) }
);

export type StripeCustomerFragment = (
  { __typename?: 'StripeCustomer' }
  & Pick<StripeCustomer, 'id' | 'name' | 'created' | 'default_source' | 'delinquent'>
);

export type StripeChargeFragment = (
  { __typename?: 'StripeCharge' }
  & Pick<StripeCharge, 'id' | 'amount' | 'amount_refunded' | 'description' | 'paid' | 'created' | 'currency'>
  & { payment_method_details: (
    { __typename?: 'StripePaymentMethodDetails' }
    & { card?: Maybe<(
      { __typename?: 'StripeCard' }
      & Pick<StripeCard, 'last4' | 'network' | 'brand'>
    )> }
  ) }
);

export type StripePlanFragment = (
  { __typename?: 'StripePlan' }
  & Pick<StripePlan, 'id' | 'object' | 'amount' | 'amount_decimal' | 'currency' | 'name' | 'product'>
);

export type StripePriceFragment = (
  { __typename?: 'StripePrice' }
  & Pick<StripePrice, 'id' | 'object' | 'product' | 'unit_amount' | 'unit_amount_decimal'>
);

export type LoadMyStripeCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadMyStripeCustomerQuery = (
  { __typename?: 'Query' }
  & { myStripeCustomer?: Maybe<(
    { __typename?: 'StripeCustomer' }
    & StripeCustomerFragment
  )> }
);

export type LoadMyUnpaidStripeChargesQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadMyUnpaidStripeChargesQuery = (
  { __typename?: 'Query' }
  & { myUnpaidStripeCharges: (
    { __typename?: 'MyUnpaidStripeChargesPayload' }
    & { items: Array<(
      { __typename?: 'StripeCharge' }
      & StripeChargeFragment
    )> }
  ) }
);

export type LoadStripeCustomerByParentIdQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadStripeCustomerByParentIdQuery = (
  { __typename?: 'Query' }
  & { stripeCustomerByParentId?: Maybe<(
    { __typename?: 'StripeCustomer' }
    & StripeCustomerFragment
  )> }
);

export type LoadUnpaidStripeChargesByStripeCustomerIdQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type LoadUnpaidStripeChargesByStripeCustomerIdQuery = (
  { __typename?: 'Query' }
  & { unpaidStripeChargesByStripeCustomerId: (
    { __typename?: 'UnpaidStripeChargesByStripeCustomerIdPayload' }
    & { items: Array<(
      { __typename?: 'StripeCharge' }
      & StripeChargeFragment
    )> }
  ) }
);

export type GetReferralInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralInvitesQuery = (
  { __typename?: 'Query' }
  & { getReferralInvites: (
    { __typename?: 'GetReferralInvitesPayload' }
    & { items: Array<(
      { __typename?: 'ReferralInvite' }
      & Pick<ReferralInvite, '_id' | 'referralCode' | 'name' | 'email' | 'source' | 'status' | 'createdAt' | 'reminderLastSentAt'>
    )> }
  ) }
);

export type GetPreviewReferralEmailQueryVariables = Exact<{
  isReminder: Scalars['Boolean'];
}>;


export type GetPreviewReferralEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPreviewReferralEmail'>
);

export type GetReferralRedemptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralRedemptionsQuery = (
  { __typename?: 'Query' }
  & { getReferralRedemptions: (
    { __typename?: 'GetReferralRedemptionsPayload' }
    & { items: Array<(
      { __typename?: 'ReferralRedemption' }
      & Pick<ReferralRedemption, '_id' | 'referralCodeId' | 'email' | 'redeemedByParentId' | 'customerLogId' | 'status' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetReferralCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralCodeQuery = (
  { __typename?: 'Query' }
  & { getReferralCode?: Maybe<(
    { __typename?: 'ReferralCode' }
    & Pick<ReferralCode, '_id' | 'name' | 'email' | 'code' | 'createdAt' | 'updatedAt'>
  )> }
);

export type SendReferralInviteMutationVariables = Exact<{
  input: SendReferralInviteInput;
}>;


export type SendReferralInviteMutation = (
  { __typename?: 'Mutation' }
  & { sendReferralInvite: (
    { __typename?: 'SendReferralInvitePayload' }
    & { referralInvite: (
      { __typename?: 'ReferralInvite' }
      & Pick<ReferralInvite, '_id' | 'referralCode' | 'name' | 'email' | 'source' | 'status' | 'createdAt' | 'reminderLastSentAt'>
    ) }
  ) }
);

export type SendReferralReminderEmailMutationVariables = Exact<{
  input: SendReferralReminderEmailInput;
}>;


export type SendReferralReminderEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendReferralReminderEmail: (
    { __typename?: 'SendReferralReminderEmailPayload' }
    & { updatedInvite: (
      { __typename?: 'ReferralInvite' }
      & Pick<ReferralInvite, '_id' | 'referralCode' | 'name' | 'email' | 'source' | 'status' | 'createdAt' | 'reminderLastSentAt'>
    ) }
  ) }
);

export type GetRecapPageDataQueryVariables = Exact<{
  userReportId: Scalars['ObjectId'];
}>;


export type GetRecapPageDataQuery = (
  { __typename?: 'Query' }
  & { studentRecapByUserReportId: (
    { __typename?: 'StudentRecapPayload' }
    & { userInfo: (
      { __typename?: 'StudentRecap' }
      & Pick<StudentRecap, 'referralCode' | 'parentFirstName'>
      & { studentInfo?: Maybe<(
        { __typename?: 'RecapStudentInfo' }
        & Pick<RecapStudentInfo, 'firstName' | 'lastName' | 'username'>
        & { activeCoursesProgress?: Maybe<Array<(
          { __typename?: 'RecapActiveCoursesProgress' }
          & Pick<RecapActiveCoursesProgress, 'displayName' | 'numModulesCompleted' | 'totalNumModules' | 'iconName' | 'primaryColor'>
        )>> }
      )> }
    ), userReport: (
      { __typename?: 'UserReport' }
      & Pick<UserReport, 'projectViewsThisMonth' | 'projectViewsAllTime' | 'projectsPublishedAllTime' | 'projectsPublishedThisMonth' | 'projectsWorkedOnAllTime' | 'projectsWorkedOnThisMonth'>
    ), recentProjects: Array<(
      { __typename?: 'RecentProject' }
      & Pick<RecentProject, 'projectName' | 'updatedAt' | 'courseName' | 'subjectName' | 'publicProjectUrl' | 'sectionType'>
    )> }
  ) }
);

export type RescheduleClassMutationVariables = Exact<{
  input: RescheduleClassInput;
}>;


export type RescheduleClassMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleClass: (
    { __typename?: 'RescheduleClassPayload' }
    & Pick<RescheduleClassPayload, 'success'>
  ) }
);

export type ScheduleAdditionalClassMutationVariables = Exact<{
  input: ScheduleAdditionalClassInput;
}>;


export type ScheduleAdditionalClassMutation = (
  { __typename?: 'Mutation' }
  & { scheduleAdditionalClass: (
    { __typename?: 'ScheduleAdditionalClassPayload' }
    & Pick<ScheduleAdditionalClassPayload, 'additionalClassId' | 'selectedAppointmentSlot'>
  ) }
);

export type GetAvailableAppointmentSlotsQueryVariables = Exact<{
  instructorId?: Maybe<Scalars['ObjectId']>;
  instructorAcuityId?: Maybe<Scalars['String']>;
  appointmentTypeId: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
  endDatetime: Scalars['DateTime'];
}>;


export type GetAvailableAppointmentSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'availableAppointmentSlots'>
);

export type GetRecurringlyAvailableAppointmentSlotsQueryVariables = Exact<{
  instructorId?: Maybe<Scalars['ObjectId']>;
  instructorAcuityId?: Maybe<Scalars['String']>;
  appointmentTypeId: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
  endDatetime: Scalars['DateTime'];
  timezone: Scalars['String'];
}>;


export type GetRecurringlyAvailableAppointmentSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'recurringlyAvailableAppointmentSlots'>
);

export type CreateParentRecurringRescheduleRequestMutationVariables = Exact<{
  input: CreateParentRecurringRescheduleRequestInput;
}>;


export type CreateParentRecurringRescheduleRequestMutation = (
  { __typename?: 'Mutation' }
  & { CreateParentRecurringRescheduleRequest: (
    { __typename?: 'CreateParentRecurringRescheduleRequestPayload' }
    & Pick<CreateParentRecurringRescheduleRequestPayload, 'success'>
  ) }
);

export type RecordProjectViewMutationVariables = Exact<{
  projectId: Scalars['ObjectId'];
}>;


export type RecordProjectViewMutation = (
  { __typename?: 'Mutation' }
  & { recordProjectView: (
    { __typename?: 'RecordProjectViewPayload' }
    & Pick<RecordProjectViewPayload, 'wasRecorded'>
  ) }
);

export type GetUserClubsByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetUserClubsByUsernameQuery = (
  { __typename?: 'Query' }
  & { getUserClubsByUsername: (
    { __typename?: 'GetUserClubsByUsernamePayload' }
    & { items: Array<(
      { __typename?: 'JuniUserClub' }
      & Pick<JuniUserClub, '_id' | 'displayName' | 'description' | 'coverPhoto' | 'membershipStatus' | 'memberCount'>
    )> }
  ) }
);

export type GetJuniverseUserProfileByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetJuniverseUserProfileByUsernameQuery = (
  { __typename?: 'Query' }
  & { juniverseUserProfileByUsername?: Maybe<(
    { __typename?: 'JuniverseUserProfile' }
    & Pick<JuniverseUserProfile, '_id' | 'type' | 'username' | 'firstName' | 'lastInitial' | 'isGuest' | 'projectViewsAllTime' | 'projectsPublishedAllTime' | 'createdAt'>
    & { publicProjects: Array<(
      { __typename?: 'StudentProject' }
      & Pick<StudentProject, '_id' | 'title' | 'description' | 'thumbnailUrl' | 'juniverseTags' | 'attachedUsermediaUrls' | 'projectType' | 'lastUpdatedAt' | 'viewCount'>
      & { moduleSection?: Maybe<(
        { __typename?: 'ComputerScienceModuleSection' }
        & Pick<ComputerScienceModuleSection, '_id' | 'displayName'>
      ) | (
        { __typename?: 'EnglishModuleSection' }
        & Pick<EnglishModuleSection, '_id' | 'displayName'>
      ) | (
        { __typename?: 'MathModuleSection' }
        & Pick<MathModuleSection, '_id' | 'displayName'>
      ) | (
        { __typename?: 'InvestingModuleSection' }
        & Pick<InvestingModuleSection, '_id' | 'displayName'>
      )>, creator?: Maybe<(
        { __typename?: 'StudentProjectCreator' }
        & Pick<StudentProjectCreator, 'username' | 'type'>
      )> }
    )>, featuredProjects: Array<(
      { __typename?: 'StudentProject' }
      & Pick<StudentProject, '_id' | 'title' | 'description' | 'thumbnailUrl' | 'juniverseTags' | 'attachedUsermediaUrls' | 'projectType' | 'lastUpdatedAt' | 'viewCount'>
      & { moduleSection?: Maybe<(
        { __typename?: 'ComputerScienceModuleSection' }
        & Pick<ComputerScienceModuleSection, '_id' | 'displayName'>
      ) | (
        { __typename?: 'EnglishModuleSection' }
        & Pick<EnglishModuleSection, '_id' | 'displayName'>
      ) | (
        { __typename?: 'MathModuleSection' }
        & Pick<MathModuleSection, '_id' | 'displayName'>
      ) | (
        { __typename?: 'InvestingModuleSection' }
        & Pick<InvestingModuleSection, '_id' | 'displayName'>
      )>, creator?: Maybe<(
        { __typename?: 'StudentProjectCreator' }
        & Pick<StudentProjectCreator, 'username' | 'type'>
      )> }
    )>, courseProgresses: Array<(
      { __typename?: 'StudentCourseProgress' }
      & Pick<StudentCourseProgress, 'isComplete'>
      & { course: (
        { __typename?: 'ComputerScienceCourse' }
        & Pick<ComputerScienceCourse, '_id' | 'name' | 'displayNameMajor' | 'displayNameMinor' | 'subject'>
      ) | (
        { __typename?: 'EnglishCourse' }
        & Pick<EnglishCourse, '_id' | 'name' | 'displayNameMajor' | 'displayNameMinor' | 'subject'>
      ) | (
        { __typename?: 'MathCourse' }
        & Pick<MathCourse, '_id' | 'name' | 'displayNameMajor' | 'displayNameMinor' | 'subject'>
      ) | (
        { __typename?: 'InvestingCourse' }
        & Pick<InvestingCourse, '_id' | 'name' | 'displayNameMajor' | 'displayNameMinor' | 'subject'>
      ) }
    )>, achievements?: Maybe<Array<(
      { __typename?: 'InstructorAchievement' }
      & Pick<InstructorAchievement, 'name' | 'thumbnailUrl' | 'externalDescription'>
    )>> }
  )> }
);

export type CourseCompletionDatesQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
}>;


export type CourseCompletionDatesQuery = (
  { __typename?: 'Query' }
  & { courseCompletionDates: (
    { __typename?: 'CourseCompletionDatesPayload' }
    & { items: Array<(
      { __typename?: 'CourseCompletionInfo' }
      & Pick<CourseCompletionInfo, 'courseName' | 'completionDate'>
    )> }
  ) }
);

export type SubjectFragment = (
  { __typename?: 'Subject' }
  & Pick<Subject, '_id' | 'name' | 'displayName'>
);

export type ParentCornerInstructorCardsForStudentQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
}>;


export type ParentCornerInstructorCardsForStudentQuery = (
  { __typename?: 'Query' }
  & { parentCornerInstructorCardsForStudent: (
    { __typename?: 'ParentCornerInstructorCardsForStudentPayload' }
    & { items: Array<(
      { __typename?: 'ParentCornerInstructor' }
      & Pick<ParentCornerInstructor, '_id' | 'firstName' | 'lastName' | 'bio'>
      & { achievements: Array<(
        { __typename?: 'ParentCornerInstructorAchievement' }
        & Pick<ParentCornerInstructorAchievement, '_id' | 'name' | 'thumbnailUrl' | 'externalDescription'>
      )> }
    )> }
  ) }
);

export type HelpCenterCategoryFragmentFragment = (
  { __typename?: 'HelpCenterCategory' }
  & Pick<HelpCenterCategory, 'id' | 'name' | 'description' | 'position'>
);

export type HelpCenterSectionFragmentFragment = (
  { __typename?: 'HelpCenterSection' }
  & Pick<HelpCenterSection, 'id' | 'category_id' | 'parent_section_id' | 'name' | 'description' | 'position'>
);

export type HelpCenterArticleFragmentFragment = (
  { __typename?: 'HelpCenterArticle' }
  & Pick<HelpCenterArticle, 'id' | 'section_id' | 'title' | 'body' | 'position' | 'user_segment_id'>
);

export type GetHelpCenterContentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHelpCenterContentQuery = (
  { __typename?: 'Query' }
  & { queryHelpCenterContent: (
    { __typename?: 'HelpCenterContent' }
    & { categories: Array<(
      { __typename?: 'HelpCenterCategory' }
      & HelpCenterCategoryFragmentFragment
    )>, sections: Array<(
      { __typename?: 'HelpCenterSection' }
      & HelpCenterSectionFragmentFragment
    )>, articles: Array<(
      { __typename?: 'HelpCenterArticle' }
      & HelpCenterArticleFragmentFragment
    )> }
  ) }
);

export type GetHelpCenterArticleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetHelpCenterArticleByIdQuery = (
  { __typename?: 'Query' }
  & { queryHelpCenterArticleById: (
    { __typename?: 'HelpCenterArticle' }
    & HelpCenterArticleFragmentFragment
  ) }
);

export type UnlistedProjectDataQueryVariables = Exact<{
  params: Scalars['String'];
}>;


export type UnlistedProjectDataQuery = (
  { __typename?: 'Query' }
  & { unlistedProjectData?: Maybe<(
    { __typename?: 'UnlistedProjectDataType' }
    & Pick<UnlistedProjectDataType, 'studentId' | 'instructorUserId' | 'courseId' | 'moduleId' | 'projectId' | 'playgroundProjectType' | 'firstName' | 'lastInitial' | 'parentFirstName' | 'referralCode' | 'courseDisplayName' | 'moduleDisplayName' | 'projectDisplayName' | 'encryptedStudentAndProject' | 'studentProjectId' | 'isPublic'>
  )> }
);

export type GetUserClubMembershipsQueryVariables = Exact<{
  studentId?: Maybe<Scalars['ObjectId']>;
  instructorId?: Maybe<Scalars['ObjectId']>;
}>;


export type GetUserClubMembershipsQuery = (
  { __typename?: 'Query' }
  & { getUserClubMemberships: (
    { __typename?: 'GetUserClubMembershipsPayload' }
    & { items: Array<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id' | 'displayName' | 'description' | 'creatorStudentId' | 'creatorInstructorUserId' | 'memberCount' | 'coverPhoto'>
    )> }
  ) }
);

export type GetUserProfileQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { profileByUsername?: Maybe<(
    { __typename?: 'ProfilePage' }
    & Pick<ProfilePage, 'type'>
    & { profile?: Maybe<(
      { __typename?: 'ProfileSchema' }
      & Pick<ProfileSchema, 'bio' | 'profileColor'>
      & { profileImage?: Maybe<(
        { __typename?: 'UserEditedImage' }
        & Pick<UserEditedImage, 'url'>
      )> }
    )> }
  )> }
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'UpdateUserProfilePayload' }
    & Pick<UpdateUserProfilePayload, 'success'>
    & { updatedProfile?: Maybe<(
      { __typename?: 'ProfilePage' }
      & Pick<ProfilePage, 'username'>
      & { profile?: Maybe<(
        { __typename?: 'ProfileSchema' }
        & Pick<ProfileSchema, 'bio' | 'profileColor'>
        & { profileImage?: Maybe<(
          { __typename?: 'UserEditedImage' }
          & Pick<UserEditedImage, 'url'>
        )> }
      )> }
    )> }
  ) }
);

export type GetCoursesForCourseSelectionQueryVariables = Exact<{
  input?: Maybe<QueryCourseInput>;
}>;


export type GetCoursesForCourseSelectionQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'Course' }
    & GetCoursesForCourseSelectionReturnFragment
  )> }
);

export type GetCoursesForCourseSelectionReturnFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'name' | 'displayName'>
  & { subject: (
    { __typename?: 'Subject' }
    & Pick<Subject, '_id' | 'displayName'>
  ) }
);

export type GetCourseByNameQueryVariables = Exact<{
  courseName: Scalars['String'];
}>;


export type GetCourseByNameQuery = (
  { __typename?: 'Query' }
  & { getCourse?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'displayName' | 'name' | 'homeworkInformation' | 'schedulingFormat' | 'courseDescription' | 'isAcceptingEnrollment'>
    & { subject: (
      { __typename?: 'Subject' }
      & Pick<Subject, '_id' | 'name' | 'displayName'>
    ), offerings: Array<(
      { __typename?: 'CourseOffering' }
      & Pick<CourseOffering, '_id' | 'courseId' | 'courseName' | 'instructorId' | 'totalSeats' | 'numSeatsAvailable' | 'enrollmentEndDate' | 'totalPriceInCents'>
      & { schedule: Array<(
        { __typename?: 'CourseOfferingScheduleItem' }
        & Pick<CourseOfferingScheduleItem, 'duration' | 'startTime' | 'placeholderAcuityApptId' | 'appointmentTypeId'>
      )> }
    )> }
  )> }
);

export type GetCourseOfferingByIdQueryVariables = Exact<{
  id: Scalars['ObjectId'];
}>;


export type GetCourseOfferingByIdQuery = (
  { __typename?: 'Query' }
  & { getCourseOffering?: Maybe<(
    { __typename?: 'CourseOffering' }
    & Pick<CourseOffering, 'totalPriceInCents'>
    & { course: (
      { __typename?: 'Course' }
      & Pick<Course, 'displayName' | 'name' | 'schedulingFormat'>
      & { subject: (
        { __typename?: 'Subject' }
        & Pick<Subject, 'name' | 'displayName'>
      ) }
    ) }
  )> }
);

export type RegisterJuniClubInvitationEmailClickMutationVariables = Exact<{
  input: RegisterJuniClubInvitationEmailClickInput;
}>;


export type RegisterJuniClubInvitationEmailClickMutation = (
  { __typename?: 'Mutation' }
  & { registerJuniClubInvitationEmailClick: (
    { __typename?: 'RegisterJuniClubInvitationEmailClickPayload' }
    & { juniClubInvitation?: Maybe<(
      { __typename?: 'JuniClubInvitationType' }
      & Pick<JuniClubInvitationType, '_id' | 'guestInvitationId' | 'inviteeStudentId' | 'inviteeEmail' | 'inviteeName' | 'juniClubId'>
    )>, clubInfo: (
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, 'displayName' | 'description' | 'coverPhoto'>
    ), guestInvitation?: Maybe<(
      { __typename?: 'GuestInvitationType' }
      & Pick<GuestInvitationType, 'isParentInvite'>
    )> }
  ) }
);

export type SubmitGuestAccountRegistrationMutationVariables = Exact<{
  input: SubmitGuestAccountRegistrationInput;
}>;


export type SubmitGuestAccountRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { submitGuestAccountRegistration: (
    { __typename?: 'SubmitGuestAccountRegistrationPayload' }
    & Pick<SubmitGuestAccountRegistrationPayload, 'success'>
  ) }
);

export type RegisterCtaBannerClickMutationVariables = Exact<{
  input: RegisterCtaBannerClickInput;
}>;


export type RegisterCtaBannerClickMutation = (
  { __typename?: 'Mutation' }
  & { registerCtaBannerClick: (
    { __typename?: 'RegisterCtaBannerClickPayload' }
    & { guestInvitation?: Maybe<(
      { __typename?: 'GuestInvitationType' }
      & Pick<GuestInvitationType, '_id' | 'invitationLookupId'>
    )> }
  ) }
);

export type SendParentRegistrationEmailMutationVariables = Exact<{
  input: SendParentRegistrationEmailInput;
}>;


export type SendParentRegistrationEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendParentRegistrationEmail: (
    { __typename?: 'SendParentRegistrationEmailPayload' }
    & Pick<SendParentRegistrationEmailPayload, 'success'>
  ) }
);

export type GetInstructorAvailabilityQueryVariables = Exact<{
  email: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type GetInstructorAvailabilityQuery = (
  { __typename?: 'Query' }
  & { instructor?: Maybe<(
    { __typename?: 'Instructor' }
    & { availability?: Maybe<Array<(
      { __typename?: 'CachedNylasBlockOrPseudoAcuityApptBlock' }
      & Pick<CachedNylasBlockOrPseudoAcuityApptBlock, '_id' | 'start' | 'end' | 'nylasCalendarType' | 'acuityApptId'>
    )>> }
  )> }
);

export type UpdateReferralRedemptionMutationVariables = Exact<{
  input: UpdateReferralRedemptionInput;
}>;


export type UpdateReferralRedemptionMutation = (
  { __typename?: 'Mutation' }
  & { updateReferralRedemption: (
    { __typename?: 'ReferralRedemption' }
    & Pick<ReferralRedemption, '_id'>
  ) }
);

export type GetReferralRedemptionByIdQueryVariables = Exact<{
  referralRedemptionId: Scalars['ObjectId'];
}>;


export type GetReferralRedemptionByIdQuery = (
  { __typename?: 'Query' }
  & { getReferralRedemptionById: (
    { __typename?: 'ReferralRedemption' }
    & Pick<ReferralRedemption, '_id' | 'status'>
  ) }
);

export type PartialEditedRichTextFragment = (
  { __typename?: 'EditedRichText' }
  & Pick<EditedRichText, '_id' | 'content' | 'title' | 'originalReferenceTextId' | 'updatedAt'>
  & { originalReferenceText?: Maybe<(
    { __typename?: 'ReferenceText' }
    & Pick<ReferenceText, 'title' | 'description'>
  )> }
);

export type LoadEditedRichTextByIdQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type LoadEditedRichTextByIdQuery = (
  { __typename?: 'Query' }
  & { editedRichTextById?: Maybe<(
    { __typename?: 'EditedRichText' }
    & PartialEditedRichTextFragment
  )> }
);

export type CreateEditedRichTextMutationVariables = Exact<{
  input: CreateEditedRichTextInput;
}>;


export type CreateEditedRichTextMutation = (
  { __typename?: 'Mutation' }
  & { createEditedRichText: (
    { __typename?: 'CreateEditedRichTextPayload' }
    & { editedRichText: (
      { __typename?: 'EditedRichText' }
      & PartialEditedRichTextFragment
    ) }
  ) }
);

export type UpdateEditedRichTextMutationVariables = Exact<{
  input: UpdateEditedRichTextInput;
}>;


export type UpdateEditedRichTextMutation = (
  { __typename?: 'Mutation' }
  & { updateEditedRichText: (
    { __typename?: 'UpdateEditedRichTextPayload' }
    & { editedRichText: (
      { __typename?: 'EditedRichText' }
      & PartialEditedRichTextFragment
    ) }
  ) }
);

export type LearningTargetProgressFragmentFragment = (
  { __typename?: 'LearningTargetProgress' }
  & Pick<LearningTargetProgress, 'targetId' | 'level'>
);

export type CourseRubricProgressFragmentFragment = (
  { __typename?: 'CourseRubricProgress' }
  & Pick<CourseRubricProgress, 'courseId' | 'studentId'>
  & { progresses?: Maybe<Array<(
    { __typename?: 'LearningTargetProgress' }
    & LearningTargetProgressFragmentFragment
  )>>, rubric?: Maybe<(
    { __typename?: 'CourseRubric' }
    & CourseRubricFragmentFragment
  )> }
);

export type LoadCourseRubricProgressQueryVariables = Exact<{
  input: CourseRubricProgressInput;
}>;


export type LoadCourseRubricProgressQuery = (
  { __typename?: 'Query' }
  & { courseRubricProgress?: Maybe<(
    { __typename?: 'CourseRubricProgress' }
    & CourseRubricProgressFragmentFragment
  )> }
);

export type SaveCourseRubricProgressMutationVariables = Exact<{
  input: UpdateCourseRubricProgressInput;
}>;


export type SaveCourseRubricProgressMutation = (
  { __typename?: 'Mutation' }
  & { updateCourseRubricProgress: (
    { __typename?: 'UpdateCourseRubricProgressPayload' }
    & { progressUpdates: Array<(
      { __typename?: 'LearningTargetProgress' }
      & LearningTargetProgressFragmentFragment
    )> }
  ) }
);

export type LoadComputerScienceModuleSectionsQueryVariables = Exact<{
  ids: Array<Scalars['ObjectId']>;
}>;


export type LoadComputerScienceModuleSectionsQuery = (
  { __typename?: 'Query' }
  & { computerScienceModuleSections: (
    { __typename?: 'ComputerScienceModuleSectionsPayload' }
    & { items: Array<(
      { __typename?: 'ComputerScienceModuleSection' }
      & Pick<ComputerScienceModuleSection, '_id' | 'displayName' | 'isProject'>
    )> }
  ) }
);

export type SessionFeedbackBaseFragment = (
  { __typename?: 'SessionFeedbackPayload' }
  & Pick<SessionFeedbackPayload, '_id' | 'completedAt' | 'createdAt' | 'feedback' | 'response' | 'teacherSessionId'>
);

export type SessionFeedbackAugmentedFragment = (
  { __typename?: 'SessionFeedbackPayload' }
  & Pick<SessionFeedbackPayload, 'notes' | 'sessionTime' | 'studentId' | 'studentFirstName' | 'studentLastName'>
  & SessionFeedbackBaseFragment
);

export type LoadSessionFeedbackQueryVariables = Exact<{
  teacherSessionId: Scalars['ObjectId'];
}>;


export type LoadSessionFeedbackQuery = (
  { __typename?: 'Query' }
  & { sessionFeedback?: Maybe<(
    { __typename?: 'SessionFeedbackPayload' }
    & SessionFeedbackBaseFragment
  )> }
);

export type CreateSessionFeedbackMutationVariables = Exact<{
  input: CreateSessionFeedbackInput;
}>;


export type CreateSessionFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { createSessionFeedback: (
    { __typename?: 'CreateSessionFeedbackPayload' }
    & { sessionFeedback: (
      { __typename?: 'SessionFeedbackPayload' }
      & SessionFeedbackBaseFragment
    ) }
  ) }
);

export type LoadSessionFeedbackForInstructorQueryVariables = Exact<{
  isComplete?: Maybe<Scalars['Boolean']>;
}>;


export type LoadSessionFeedbackForInstructorQuery = (
  { __typename?: 'Query' }
  & { sessionFeedbackForInstructor: (
    { __typename?: 'SessionFeedbackForInstructorPayload' }
    & { items: Array<(
      { __typename?: 'SessionFeedbackPayload' }
      & SessionFeedbackAugmentedFragment
    )> }
  ) }
);

export type CompleteSessionFeedbackMutationVariables = Exact<{
  input: CompleteSessionFeedbackInput;
}>;


export type CompleteSessionFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { completeSessionFeedback: (
    { __typename?: 'CompleteSessionFeedbackPayload' }
    & { sessionFeedback: (
      { __typename?: 'SessionFeedbackPayload' }
      & SessionFeedbackBaseFragment
    ) }
  ) }
);

export type NotesFromHqByDateQueryVariables = Exact<{
  date: Scalars['DateTime'];
}>;


export type NotesFromHqByDateQuery = (
  { __typename?: 'Query' }
  & { notesFromHQByDate: (
    { __typename?: 'NotesFromHQByDatePayload' }
    & { items: Array<(
      { __typename?: 'NoteFromHQ' }
      & Pick<NoteFromHq, '_id' | 'title' | 'text' | 'startDate' | 'endDate'>
    )> }
  ) }
);

export type ModuleProgressQueryVariables = Exact<{
  studentId?: Maybe<Scalars['String']>;
}>;


export type ModuleProgressQuery = (
  { __typename?: 'Query' }
  & { queryModuleProgresses: (
    { __typename?: 'QueryModuleProgressesPayload' }
    & { items: Array<(
      { __typename?: 'ModuleProgress' }
      & Pick<ModuleProgress, '_id' | 'moduleId' | 'studentId' | 'isCompleted' | 'isInProgress' | 'timestamp'>
    )> }
  ) }
);

export type GetUserSmsConsentStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSmsConsentStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userSmsConsentStatus'>
);

export type UpdateUserSmsConsentMutationVariables = Exact<{
  input: UpdateUserSmsConsentInput;
}>;


export type UpdateUserSmsConsentMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSmsConsent: (
    { __typename?: 'UpdateUserSmsConsentPayload' }
    & Pick<UpdateUserSmsConsentPayload, 'ok'>
  ) }
);

export type AdjustBillingMutationVariables = Exact<{
  input: AdjustBillingInput;
}>;


export type AdjustBillingMutation = (
  { __typename?: 'Mutation' }
  & { adjustBilling: (
    { __typename?: 'AdjustBillingPayload' }
    & Pick<AdjustBillingPayload, 'success'>
  ) }
);

export type GetBillingAdjustmentInstructorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBillingAdjustmentInstructorsQuery = (
  { __typename?: 'Query' }
  & { instructors: (
    { __typename?: 'InstructorsPayload' }
    & { items: Array<(
      { __typename?: 'Instructor' }
      & Pick<Instructor, '_id' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type GetInstructorMatchesQueryVariables = Exact<{
  input: InstructorMatchInput;
}>;


export type GetInstructorMatchesQuery = (
  { __typename?: 'Query' }
  & { instructorMatches?: Maybe<(
    { __typename?: 'InstructorMatchPayload' }
    & { items: Array<(
      { __typename?: 'InstructorMatch' }
      & Pick<InstructorMatch, '_id' | 'userId' | 'firstName' | 'lastName' | 'courseIds' | 'ageRange5to7' | 'ageRange8to10' | 'ageRange11to13' | 'ageRange14to16' | 'ageRange17up' | 'acuityCalendarId' | 'notes' | 'energyLevel' | 'emotionalTone' | 'confidence' | 'bio' | 'school'>
      & { availability: Array<(
        { __typename?: 'CachedNylasBlock' }
        & Pick<CachedNylasBlock, 'start' | 'end'>
      )>, instructorLoad?: Maybe<Array<(
        { __typename?: 'InstructorLoad' }
        & Pick<InstructorLoad, 'date'>
        & { hours: (
          { __typename?: 'InstructorLoadHours' }
          & Pick<InstructorLoadHours, 'trial' | 'recurring'>
        ) }
      )>>, proposalHistory?: Maybe<(
        { __typename?: 'StudentProposalsHistory' }
        & Pick<StudentProposalsHistory, 'pending' | 'declined' | 'accepted' | 'acceptedByOther'>
      )>, courses: Array<(
        { __typename?: 'InstructorQualifiedCourse' }
        & Pick<InstructorQualifiedCourse, 'courseId' | 'isQualified'>
        & { moduleProgress: Array<(
          { __typename?: 'ModuleProgressList' }
          & Pick<ModuleProgressList, 'status'>
        )> }
      )> }
    )> }
  )> }
);

export type AddStudentCourseMutationVariables = Exact<{
  input: AddStudentCourseInput;
}>;


export type AddStudentCourseMutation = (
  { __typename?: 'Mutation' }
  & { addStudentCourse: (
    { __typename?: 'AddStudentCoursePayload' }
    & Pick<AddStudentCoursePayload, 'success'>
  ) }
);

export type UpdateStudentCourseMutationVariables = Exact<{
  input: UpdateStudentCourseInput;
}>;


export type UpdateStudentCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateStudentCourse: (
    { __typename?: 'UpdateStudentCoursePayload' }
    & Pick<UpdateStudentCoursePayload, 'success'>
  ) }
);

export type RemoveStudentCourseMutationVariables = Exact<{
  input: RemoveStudentCourseInput;
}>;


export type RemoveStudentCourseMutation = (
  { __typename?: 'Mutation' }
  & { removeStudentCourse: (
    { __typename?: 'RemoveStudentCoursePayload' }
    & Pick<RemoveStudentCoursePayload, 'success'>
  ) }
);

export type UncompleteStudentCourseMutationVariables = Exact<{
  input: UncompleteStudentCourseInput;
}>;


export type UncompleteStudentCourseMutation = (
  { __typename?: 'Mutation' }
  & { uncompleteStudentCourse: (
    { __typename?: 'UncompleteStudentCoursePayload' }
    & Pick<UncompleteStudentCoursePayload, 'success'>
  ) }
);

export type CompleteStudentCourseMutationVariables = Exact<{
  input: CompleteStudentCourseInput;
}>;


export type CompleteStudentCourseMutation = (
  { __typename?: 'Mutation' }
  & { completeStudentCourse: (
    { __typename?: 'CompleteStudentCoursePayload' }
    & Pick<CompleteStudentCoursePayload, 'success'>
  ) }
);

export type UpdateEmailMutationVariables = Exact<{
  input: UpdateEmailInput;
}>;


export type UpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateEmail: (
    { __typename?: 'UpdateEmailPayload' }
    & Pick<UpdateEmailPayload, 'success'>
  ) }
);

export type UpdateNameMutationVariables = Exact<{
  input: UpdateNameInput;
}>;


export type UpdateNameMutation = (
  { __typename?: 'Mutation' }
  & { updateName: (
    { __typename?: 'UpdateNamePayload' }
    & Pick<UpdateNamePayload, 'success'>
  ) }
);

export type DeleteS3ObjectMutationVariables = Exact<{
  input: DeleteS3ObjectInput;
}>;


export type DeleteS3ObjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteS3Object: (
    { __typename?: 'DeleteS3ObjectPayload' }
    & Pick<DeleteS3ObjectPayload, 'success'>
  ) }
);

export type CreateClubMutationVariables = Exact<{
  input: CreateClubInput;
}>;


export type CreateClubMutation = (
  { __typename?: 'Mutation' }
  & { createClub: (
    { __typename?: 'CreateClubPayload' }
    & { juniClub?: Maybe<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id'>
    )>, juniClubMembership?: Maybe<(
      { __typename?: 'JuniClubMembershipType' }
      & Pick<JuniClubMembershipType, 'studentId'>
    )> }
  ) }
);

export type UpdateClubMutationVariables = Exact<{
  input: UpdateClubInput;
}>;


export type UpdateClubMutation = (
  { __typename?: 'Mutation' }
  & { updateClub: (
    { __typename?: 'UpdateClubPayload' }
    & { juniClub?: Maybe<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id'>
    )> }
  ) }
);

export type StaticS3BucketUrlsQueryVariables = Exact<{
  s3Bucket: Scalars['String'];
  folder: Scalars['String'];
}>;


export type StaticS3BucketUrlsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'staticS3BucketUrls'>
);

export type UpdateClubMetadataMutationVariables = Exact<{
  input: UpdateClubMetadataInput;
}>;


export type UpdateClubMetadataMutation = (
  { __typename?: 'Mutation' }
  & { updateClubMetadata: (
    { __typename?: 'UpdateClubMetadataPayload' }
    & { juniClub?: Maybe<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id' | 'tags'>
    )> }
  ) }
);

export type GetJuniClubsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ObjectId']>;
}>;


export type GetJuniClubsByIdsQuery = (
  { __typename?: 'Query' }
  & { getJuniClubsByIds: (
    { __typename?: 'GetJuniClubsByIdsPayload' }
    & { items: Array<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id' | 'displayName' | 'description' | 'tags' | 'colorTheme' | 'coverPhoto' | 'logo' | 'creatorStudentId' | 'creatorInstructorUserId' | 'isJuniModerated' | 'archivedAt' | 'createdAt' | 'updatedAt'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, 'displayName'>
      )>> }
    )> }
  ) }
);

export type GetClubInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClubInvitationsQuery = (
  { __typename?: 'Query' }
  & { getClubInvitations: (
    { __typename?: 'GetClubInvitationsPayload' }
    & { items: Array<(
      { __typename?: 'JuniClubInvitationType' }
      & Pick<JuniClubInvitationType, '_id' | 'inviteeStudentId' | 'inviteeInstructorUserId' | 'inviteeParentId' | 'juniClubId' | 'inviterName' | 'acceptedAt' | 'rejectedAt'>
    )> }
  ) }
);

export type AcceptClubInvitationMutationVariables = Exact<{
  input: AcceptRejectJuniClubInvitationInput;
}>;


export type AcceptClubInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptRejectJuniClubInvitation: (
    { __typename?: 'AcceptRejectJuniClubInvitationPayload' }
    & Pick<AcceptRejectJuniClubInvitationPayload, 'success'>
  ) }
);

export type CreateClubChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;


export type CreateClubChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChannel: (
    { __typename?: 'CreateChannelPayload' }
    & { juniClub?: Maybe<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, 'displayName'>
      )>> }
    )> }
  ) }
);

export type ArchiveChannelMutationVariables = Exact<{
  input: ArchiveChannelInput;
}>;


export type ArchiveChannelMutation = (
  { __typename?: 'Mutation' }
  & { archiveChannel: (
    { __typename?: 'ArchiveChannelPayload' }
    & { juniClub?: Maybe<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, 'archivedAt'>
      )>> }
    )> }
  ) }
);

export type EditChannelMutationVariables = Exact<{
  input: EditChannelInput;
}>;


export type EditChannelMutation = (
  { __typename?: 'Mutation' }
  & { editChannel: (
    { __typename?: 'EditChannelPayload' }
    & { juniClub?: Maybe<(
      { __typename?: 'JuniClubType' }
      & Pick<JuniClubType, '_id'>
      & { channels?: Maybe<Array<(
        { __typename?: 'JuniClubChannelType' }
        & Pick<JuniClubChannelType, 'displayName' | 'readOnly'>
      )>> }
    )> }
  ) }
);

export type CreateJuniCommunityEventMutationVariables = Exact<{
  input: CreateJuniCommunityEventInput;
}>;


export type CreateJuniCommunityEventMutation = (
  { __typename?: 'Mutation' }
  & { createJuniCommunityEvent: (
    { __typename?: 'CreateJuniCommunityEventPayload' }
    & { juniCommunityEvent?: Maybe<(
      { __typename?: 'JuniCommunityEventType' }
      & Pick<JuniCommunityEventType, '_id'>
    )> }
  ) }
);

export type UpdateJuniCommunityEventMutationVariables = Exact<{
  input: UpdateJuniCommunityEventInput;
}>;


export type UpdateJuniCommunityEventMutation = (
  { __typename?: 'Mutation' }
  & { updateJuniCommunityEvent: (
    { __typename?: 'UpdateJuniCommunityEventPayload' }
    & { juniCommunityEvent?: Maybe<(
      { __typename?: 'JuniCommunityEventType' }
      & Pick<JuniCommunityEventType, '_id'>
    )> }
  ) }
);

export type SendJuniClubInvitationMutationVariables = Exact<{
  input: SendJuniClubInvitationInput;
}>;


export type SendJuniClubInvitationMutation = (
  { __typename?: 'Mutation' }
  & { sendJuniClubInvitation: (
    { __typename?: 'SendJuniClubInvitationPayload' }
    & { juniClubInvitation?: Maybe<(
      { __typename?: 'JuniClubInvitationType' }
      & Pick<JuniClubInvitationType, '_id' | 'guestInvitationId'>
    )> }
  ) }
);

export type MarkOnDemandModuleSectionInProgressMutationVariables = Exact<{
  input: MarkOnDemandModuleSectionInProgressInput;
}>;


export type MarkOnDemandModuleSectionInProgressMutation = (
  { __typename?: 'Mutation' }
  & { markOnDemandModuleSectionInProgress: (
    { __typename?: 'MarkOnDemandModuleSectionInProgressPayload' }
    & Pick<MarkOnDemandModuleSectionInProgressPayload, 'success'>
  ) }
);

export type UpdateProjectShareSettingsMutationVariables = Exact<{
  input: UpdateProjectShareSettingsInput;
}>;


export type UpdateProjectShareSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectShareSettings: (
    { __typename?: 'UpdateProjectShareSettingsPayload' }
    & Pick<UpdateProjectShareSettingsPayload, 'projectPageLink'>
    & { studentProject?: Maybe<(
      { __typename?: 'UpdateProjectShareSettingsStudentProjectReturnType' }
      & Pick<UpdateProjectShareSettingsStudentProjectReturnType, 'title' | 'description' | 'isPublic' | 'attachedUsermedia' | 'isCodeHidden' | 'projectType'>
    )>, usermedia?: Maybe<Array<(
      { __typename?: 'UpdateProjectShareSettingsUsermediaReturnType' }
      & Pick<UpdateProjectShareSettingsUsermediaReturnType, '_id' | 'title' | 'description' | 'status' | 'createdAt' | 'originalLength' | 's3Url'>
    )>> }
  ) }
);

export type EnglishAnswerSubmissionFragment = (
  { __typename?: 'EnglishAnswerSubmission' }
  & Pick<EnglishAnswerSubmission, 'studentId' | 'moduleSectionId' | 'problemId' | 'submittedValues'>
);

export type EnglishAnswerSubmissionsForStudentProjectQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
}>;


export type EnglishAnswerSubmissionsForStudentProjectQuery = (
  { __typename?: 'Query' }
  & { englishAnswerSubmissionsForStudentProject: (
    { __typename?: 'EnglishAnswerSubmissionsForStudentProjectPayload' }
    & { items: Array<(
      { __typename?: 'EnglishAnswerSubmission' }
      & EnglishAnswerSubmissionFragment
    )> }
  ) }
);

export type UpsertEnglishAnswerSubmissionMutationVariables = Exact<{
  input: UpsertEnglishAnswerSubmissionInput;
}>;


export type UpsertEnglishAnswerSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { upsertEnglishAnswerSubmission: (
    { __typename?: 'UpsertEnglishAnswerSubmissionPayload' }
    & Pick<UpsertEnglishAnswerSubmissionPayload, 'success'>
  ) }
);

export type EnglishProjectFragment = (
  { __typename?: 'EnglishProject' }
  & Pick<EnglishProject, '_id' | 'projectType' | 'studentId' | 'moduleSectionId' | 'writingPromptId' | 'referenceTextSetId' | 'annotatedTextIds' | 'editedTextId' | 'isCustomProject'>
);

export type EnglishProjectsQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
}>;


export type EnglishProjectsQuery = (
  { __typename?: 'Query' }
  & { englishProjects: (
    { __typename?: 'EnglishProjectsPayload' }
    & { items: Array<(
      { __typename?: 'EnglishProject' }
      & EnglishProjectFragment
    )> }
  ) }
);

export type CreateAndInitializeEnglishProjectMutationVariables = Exact<{
  input: CreateAndInitializeEnglishProjectInput;
}>;


export type CreateAndInitializeEnglishProjectMutation = (
  { __typename?: 'Mutation' }
  & { createAndInitializeEnglishProject: (
    { __typename?: 'CreateAndInitializeEnglishProjectPayload' }
    & { englishProject: (
      { __typename?: 'EnglishProject' }
      & EnglishProjectFragment
    ) }
  ) }
);

export type InvestingAnswerSubmissionFragment = (
  { __typename?: 'InvestingAnswerSubmission' }
  & Pick<InvestingAnswerSubmission, 'studentId' | 'moduleSectionId' | 'problemId' | 'submittedValues'>
);

export type InvestingAnswerSubmissionsForStudentProjectQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
}>;


export type InvestingAnswerSubmissionsForStudentProjectQuery = (
  { __typename?: 'Query' }
  & { investingAnswerSubmissionsForStudentProject: (
    { __typename?: 'InvestingAnswerSubmissionsForStudentProjectPayload' }
    & { items: Array<(
      { __typename?: 'InvestingAnswerSubmission' }
      & InvestingAnswerSubmissionFragment
    )> }
  ) }
);

export type UpsertInvestingAnswerSubmissionMutationVariables = Exact<{
  input: UpsertInvestingAnswerSubmissionInput;
}>;


export type UpsertInvestingAnswerSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { upsertInvestingAnswerSubmission: (
    { __typename?: 'UpsertInvestingAnswerSubmissionPayload' }
    & Pick<UpsertInvestingAnswerSubmissionPayload, 'success'>
  ) }
);

export type InvestingProjectFragment = (
  { __typename?: 'InvestingProject' }
  & Pick<InvestingProject, '_id' | 'projectType' | 'studentId' | 'moduleSectionId' | 'writingPromptId' | 'referenceTextSetId' | 'annotatedTextIds' | 'editedTextId' | 'isCustomProject'>
);

export type InvestingProjectsQueryVariables = Exact<{
  studentId: Scalars['ObjectId'];
  moduleSectionId: Scalars['ObjectId'];
  referenceTextSetId?: Maybe<Scalars['ObjectId']>;
}>;


export type InvestingProjectsQuery = (
  { __typename?: 'Query' }
  & { investingProjects: (
    { __typename?: 'InvestingProjectsPayload' }
    & { items: Array<(
      { __typename?: 'InvestingProject' }
      & InvestingProjectFragment
    )> }
  ) }
);

export type CreateAndInitializeInvestingProjectMutationVariables = Exact<{
  input: CreateAndInitializeInvestingProjectInput;
}>;


export type CreateAndInitializeInvestingProjectMutation = (
  { __typename?: 'Mutation' }
  & { createAndInitializeInvestingProject: (
    { __typename?: 'CreateAndInitializeInvestingProjectPayload' }
    & { investingProject: (
      { __typename?: 'InvestingProject' }
      & InvestingProjectFragment
    ) }
  ) }
);

export type GetReferenceTextSetsByIdsQueryVariables = Exact<{
  input: Array<Scalars['ObjectId']>;
}>;


export type GetReferenceTextSetsByIdsQuery = (
  { __typename?: 'Query' }
  & { referenceTextSetsByIds: (
    { __typename?: 'ReferenceTextSetsByIdsPayload' }
    & { items: Array<(
      { __typename?: 'ReferenceTextSet' }
      & TextSetFragment
    )> }
  ) }
);

export type RequestUploadUrlMutationVariables = Exact<{
  input: RequestUploadUrlInput;
}>;


export type RequestUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { requestUploadUrl: (
    { __typename?: 'RequestUploadUrlPayload' }
    & Pick<RequestUploadUrlPayload, 'uploadUrl' | 'downloadUrl'>
  ) }
);

export type GetCoursesQueryVariables = Exact<{
  input?: Maybe<QueryCourseInput>;
}>;


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'Course' }
    & GetCoursesReturnFragment
  )> }
);

export type GetCoursesReturnFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'name' | 'displayName' | 'slug' | 'abbreviation' | 'iconName' | 'primaryColor' | 'subjectId' | 'isAcceptingEnrollment' | 'curriculumId' | 'curriculumCollection' | 'order' | 'minRecommendedAge' | 'maxRecommendedAge' | 'creatorUserId' | 'schedulingFormat' | 'isMarketplaceCourse' | 'formatDisplayName' | 'courseDescription' | 'defaultJideEnv' | 'formattedDuration' | 'prereqs' | 'syllabus' | 'recommendationRank' | 'createdAt' | 'updatedAt'>
  & { headerImage?: Maybe<(
    { __typename?: 'CourseImage' }
    & Pick<CourseImage, 'original'>
  )>, subject: (
    { __typename?: 'Subject' }
    & Pick<Subject, '_id' | 'name' | 'displayName' | 'createdAt' | 'updatedAt'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, '_id' | 'type' | 'value' | 'createdAt' | 'updatedAt'>
  )>, statistics?: Maybe<(
    { __typename?: 'CourseStatistics' }
    & Pick<CourseStatistics, 'ratingTotal' | 'ratingScale' | 'ratingCount' | 'ratingDistribution' | 'totalEnrollments' | 'activeEnrollments'>
  )>, offerings: Array<(
    { __typename?: 'CourseOffering' }
    & CourseOfferingFieldsFragment
  )> }
);

export type GetCourseOfferingsQueryVariables = Exact<{
  input: Scalars['ObjectId'];
}>;


export type GetCourseOfferingsQuery = (
  { __typename?: 'Query' }
  & { getCourseOffering?: Maybe<(
    { __typename?: 'CourseOffering' }
    & CourseOfferingFieldsFragment
  )> }
);

export type CourseOfferingFieldsFragment = (
  { __typename?: 'CourseOffering' }
  & Pick<CourseOffering, '_id' | 'courseId' | 'courseName' | 'instructorId' | 'totalSeats' | 'numSeatsAvailable' | 'enrollmentEndDate' | 'totalPriceInCents' | 'createdAt' | 'updatedAt'>
  & { schedule: Array<(
    { __typename?: 'CourseOfferingScheduleItem' }
    & ScheduleFieldsFragment
  )> }
);

export type ScheduleFieldsFragment = (
  { __typename?: 'CourseOfferingScheduleItem' }
  & Pick<CourseOfferingScheduleItem, 'startTime'>
);

export type CountCoursesQueryVariables = Exact<{
  input?: Maybe<QueryCourseInput>;
}>;


export type CountCoursesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'coursesCount'>
);

export type GetRecommendedAgesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecommendedAgesQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'name' | 'displayName' | 'minRecommendedAge' | 'maxRecommendedAge'>
  )> }
);

export type GetCourseBasicMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCourseBasicMetadataQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'name' | 'displayName' | 'slug' | 'abbreviation' | 'iconName' | 'primaryColor' | 'isAcceptingEnrollment' | 'minRecommendedAge' | 'maxRecommendedAge' | 'schedulingFormat' | 'defaultJideEnv'>
    & { subject: (
      { __typename?: 'Subject' }
      & Pick<Subject, '_id' | 'name' | 'displayName'>
    ) }
  )> }
);

export type GetCourseServiceResultFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'name' | 'displayName' | 'curriculumId' | 'isAcceptingEnrollment'>
  & { subject: (
    { __typename?: 'Subject' }
    & Pick<Subject, '_id' | 'name' | 'displayName'>
  ) }
);

export type GetCourseServiceQueryVariables = Exact<{
  input?: Maybe<QueryCourseInput>;
}>;


export type GetCourseServiceQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'Course' }
    & GetCourseServiceResultFragment
  )> }
);

export type GetRecommendedCoursesResultFragment = (
  { __typename?: 'Course' }
  & Pick<Course, '_id' | 'name' | 'displayName'>
  & { nextRecommendedCourses: Array<(
    { __typename?: 'Course' }
    & Pick<Course, '_id' | 'name' | 'displayName' | 'courseDescription'>
  )> }
);

export type GetRecommendedCoursesQueryVariables = Exact<{
  input?: Maybe<QueryCourseInput>;
}>;


export type GetRecommendedCoursesQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'Course' }
    & GetRecommendedCoursesResultFragment
  )> }
);

export type LoadTruncatedInstructorNamesQueryVariables = Exact<{
  ids: Array<Scalars['String']>;
  idFieldName: Scalars['String'];
}>;


export type LoadTruncatedInstructorNamesQuery = (
  { __typename?: 'Query' }
  & { truncatedInstructorNames: (
    { __typename?: 'TruncatedInstructorNamesPayload' }
    & { items: Array<(
      { __typename?: 'IdAndName' }
      & Pick<IdAndName, 'id' | 'name'>
    )> }
  ) }
);

export const CourseOfferingFragmentDoc = gql`
    fragment CourseOffering on CourseOffering {
  _id
  courseId
  courseName
  instructorId
  totalSeats
  numSeatsAvailable
  schedule {
    startTime
    appointmentTypeId
  }
  enrollmentEndDate
  totalPriceInCents
}
    `;
export const CourseOfferingsListFragmentDoc = gql`
    fragment CourseOfferingsList on Course {
  offerings {
    ...CourseOffering
  }
}
    ${CourseOfferingFragmentDoc}`;
export const EnglishCourseFragmentFragmentDoc = gql`
    fragment EnglishCourseFragment on EnglishCourse {
  _id
  name
  displayName
  abbreviation
  order
  isActive
  moduleTopics {
    _id
    name
    displayName
    modules {
      _id
      name
      displayName
      isTrialModule
      moduleSections {
        _id
        displayName
        sectionType
        isSupplemental
        writingPromptId
        instructions
        referenceTextSetOptions {
          _id
          referenceTextSetId
        }
        moduleSectionBullets {
          _id
          text
          problemType
          solutionExplanation
          mcAnswerOptions {
            _id
            answerChoiceText
          }
          mcCorrectAnswer {
            _id
            solutionText
          }
        }
      }
    }
  }
}
    `;
export const InvestingCourseFragmentFragmentDoc = gql`
    fragment InvestingCourseFragment on InvestingCourse {
  _id
  name
  displayName
  abbreviation
  order
  isActive
  moduleTopics {
    _id
    name
    displayName
    modules {
      _id
      name
      displayName
      isTrialModule
      moduleSections {
        _id
        displayName
        sectionType
        isSupplemental
        writingPromptId
        instructions
        referenceTextSetOptions {
          _id
          referenceTextSetId
        }
        moduleSectionBullets {
          _id
          text
          problemType
          solutionExplanation
          mcAnswerOptions {
            _id
            answerChoiceText
          }
          mcCorrectAnswer {
            _id
            solutionText
          }
        }
      }
    }
  }
}
    `;
export const MathCourseFragmentDoc = gql`
    fragment MathCourse on MathCourse {
  _id
  name
  displayName
  abbreviation
  order
  isActive
  moduleTopics {
    _id
    name
    displayName
    modules {
      _id
      name
      displayName
      isTrialModule
      moduleSections {
        _id
        displayName
        sectionType
        isSupplemental
        instructions
        moduleSectionBullets {
          _id
          text
          isProblem
          difficulty
          hasExpectedSolution
          solutionExplanation
          solutions {
            _id
            solutionText
          }
        }
      }
    }
  }
}
    `;
export const PromptFragmentFragmentDoc = gql`
    fragment PromptFragment on Prompt {
  _id
  content
}
    `;
export const RefTextFragmentDoc = gql`
    fragment RefText on ReferenceText {
  _id
  subject
  title
  adminDisplayName
  description
  content
  isSampleSolution
}
    `;
export const TextSetFragmentDoc = gql`
    fragment TextSet on ReferenceTextSet {
  _id
  subject
  title
  adminDisplayName
  description
  grades
  tags
  referenceTextIds
  referenceTexts {
    _id
    title
    adminDisplayName
    description
    content
    isSampleSolution
  }
}
    `;
export const GetSubjectsReturnFragmentDoc = gql`
    fragment GetSubjectsReturn on Subject {
  _id
  name
  displayName
  iconName
  primaryColor
  createdAt
  updatedAt
}
    `;
export const ExperimentDetailsFragmentDoc = gql`
    fragment ExperimentDetails on Experiment {
  _id
  key
  title
  status
  target
  description
  participantType
  treatments {
    key
    isControl
    percentAllocation
    description
  }
}
    `;
export const FeaturedStudentProjectDetailsFragmentDoc = gql`
    fragment FeaturedStudentProjectDetails on StudentProject {
  _id
  title
  description
  juniverseTags
  attachedUsermedia
  thumbnailUrl
}
    `;
export const StudentProjectRowFragmentDoc = gql`
    fragment StudentProjectRow on StudentProject {
  _id
  student {
    _id
    firstName
    lastName
    username
  }
  instructor {
    _id
    firstName
    lastName
    username
  }
  courseId
  course {
    _id
    displayName
    name
  }
  moduleId
  module {
    _id
    name
  }
  moduleSection {
    _id
    displayName
  }
  customProject {
    _id
    displayName
  }
  title
  description
  isPublic
  isCustomProject
  lastUpdatedAt
  createdAt
  projectType
  featuredProject {
    ...FeaturedStudentProjectDetails
  }
  juniverseTags
  attachedUsermedia
  originalProjectId
  thumbnailUrl
  viewCount
}
    ${FeaturedStudentProjectDetailsFragmentDoc}`;
export const OpenChallengeFragmentDoc = gql`
    fragment OpenChallenge on OpenChallenge {
  _id
  title
  description
  openDate
  registrationDeadline
  submissionDeadline
  thumbnailUrl
  registrationLink
  resultsLink
}
    `;
export const DashboardSessionInfoFragmentFragmentDoc = gql`
    fragment DashboardSessionInfoFragment on DashboardSessionInfo {
  id
  teacherSessionId
  sessionTime
  notes
  originalNotes
  usesMarkdown
  timezone
  wasMissed
  noteNeedsMinorRevision
  noteNeedsMajorRevision
  instructorNeedsAdditionalSupport
  appointmentTypeID
  studentFirstName
  studentLastName
  studentReceivesSessionNotes
  studentEmail
  studentId
  parentFirstName
  parentEmail
  teacherFirstName
  teacherLastName
  currCycleWeek
  sent
}
    `;
export const InstructorAchievementFragmentDoc = gql`
    fragment InstructorAchievement on InstructorAchievement {
  _id
  name
  thumbnailUrl
  externalDescription
  internalDescription
  external
  startDate
  endDate
  isActive
}
    `;
export const InstructorFragmentDoc = gql`
    fragment Instructor on Instructor {
  _id
}
    `;
export const AcuityAppointmentFragmentDoc = gql`
    fragment AcuityAppointment on AcuityAppointment {
  appointmentTypeID
  calendar
  calendarID
  canceled
  canClientCancel
  canClientReschedule
  date
  datetime
  datetimeCreated
  duration
  email
  firstName
  id
  lastName
  phone
  scheduledBy
  time
  timezone
  type
  studentName
}
    `;
export const InstructorRecurringScheduleFragmentDoc = gql`
    fragment InstructorRecurringSchedule on InstructorRecurringSchedule {
  parentId
  studentId
  parent {
    firstName
    lastName
  }
  student {
    firstName
  }
  numSessionsCompletedTogether
  appointments {
    past {
      ...AcuityAppointment
    }
    current {
      ...AcuityAppointment
    }
    upcoming {
      ...AcuityAppointment
    }
  }
}
    ${AcuityAppointmentFragmentDoc}`;
export const AdminDashRecurringRescheduleRequestFragmentDoc = gql`
    fragment AdminDashRecurringRescheduleRequest on RecurringRescheduleRequest {
  _id
  instructorId
  parentId
  studentId
  createdByUserId
  ownerUserId
  reason
  additionalNotesFromInstructor
  newRecurringScheduleDetailsPt
  hasDiscussedNewTimeWithParent
  lastTeachableSessionAcuityApptId
  lastTeachableSessionAcuityCalendarId
  lastTeachableSessionApptTypeId
  lastTeachableSessionDatetime
  studentFirstName
  instructorFullName
  parent {
    phone
    email
    firstName
    lastName
    hasMultipleChildren
  }
  internalNotes
  isAcuityUpdated
  emailSentAt
  requestCompletedAt
  createdAt
  updatedAt
  dueDate
  __typename
}
    `;
export const InstructorOnboardingRowFragmentDoc = gql`
    fragment InstructorOnboardingRow on InstructorOnboarding {
  _id
  instructorId
  instructorLogId
  subject
  status
  referringInstructorId
  referralPayoutDate
  referralPayoutDataEntered
  timezone
  idealStartDate
  onboardingCompletedAt
  firstClassCourseId
  firstClassStudentId
  notes
  createdAt
  completedAt
  tasks {
    _id
    name
    isRequired
    completedAt
    acuityApptId
    acuityApptDatetime
    acuityApptCalendarId
  }
}
    `;
export const PayrollInstructorFragmentDoc = gql`
    fragment PayrollInstructor on Instructor {
  _id
  firstName
  lastName
  email
  isActive
  excludeFromHourlyPayroll
  acuityCalendarId
  selfReportedHours
}
    `;
export const JuniClubMemberTypeFragmentDoc = gql`
    fragment JuniClubMemberType on JuniClubMemberType {
  firstName
  lastInitial
  userCategory
  membershipStatus
  username
}
    `;
export const JuniClubFragmentDoc = gql`
    fragment JuniClub on JuniClubType {
  _id
  displayName
  description
  tags
  channels {
    _id
    displayName
    createdAt
    archivedAt
  }
  colorTheme
  coverPhoto
  logo
  creatorStudentId
  creatorInstructorUserId
  isJuniModerated
  archivedAt
  createdAt
  updatedAt
  memberCount
}
    `;
export const BaseStudentProposalFragmentDoc = gql`
    fragment BaseStudentProposal on StudentProposal {
  _id
  ticket {
    _id
    students {
      _id
      name
    }
    courseNames
    scheduleType
    ticketType
    studentProposalNotes
  }
}
    `;
export const CurrentStudentProposalFragmentDoc = gql`
    fragment CurrentStudentProposal on StudentProposal {
  _id
  ...BaseStudentProposal
  ticket {
    firstClassIsShadow
    upcomingClassTimes
    potentialFirstClassDatetimes {
      _id
      datetime
    }
  }
}
    ${BaseStudentProposalFragmentDoc}`;
export const PastStudentProposalFragmentDoc = gql`
    fragment PastStudentProposal on StudentProposal {
  _id
  status
  ...BaseStudentProposal
  ticket {
    firstClassDatetimes {
      _id
      datetime
    }
  }
}
    ${BaseStudentProposalFragmentDoc}`;
export const RecurringAvailabilityUpdateRequestFragmentDoc = gql`
    fragment RecurringAvailabilityUpdateRequest on RecurringAvailabilityUpdateRequest {
  _id
  dateEffective
  reason
  availabilityByDay {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
  additionalNotes
  instructorId
  createdAt
  updatedAt
  __typename
  requestCompletedAt
}
    `;
export const RecurringRescheduleRequestFragmentDoc = gql`
    fragment RecurringRescheduleRequest on RecurringRescheduleRequest {
  _id
  instructorId
  parentId
  studentId
  createdByUserId
  reason
  additionalNotesFromInstructor
  newRecurringScheduleDetailsPt
  hasDiscussedNewTimeWithParent
  lastTeachableSessionAcuityApptId
  lastTeachableSessionAcuityCalendarId
  lastTeachableSessionApptTypeId
  lastTeachableSessionDatetime
  studentFirstName
  requestCompletedAt
  createdAt
  updatedAt
  __typename
}
    `;
export const StudentFragmentFragmentDoc = gql`
    fragment StudentFragment on Student {
  _id
  firstName
  lastName
  username
  hasMultipleTracks
  tracks
  track
  completedTracks
}
    `;
export const StripeCustomerFragmentDoc = gql`
    fragment StripeCustomer on StripeCustomer {
  id
  name
  created
  default_source
  delinquent
}
    `;
export const StripeChargeFragmentDoc = gql`
    fragment StripeCharge on StripeCharge {
  id
  amount
  amount_refunded
  description
  paid
  created
  currency
  payment_method_details {
    card {
      last4
      network
      brand
    }
  }
}
    `;
export const StripePlanFragmentDoc = gql`
    fragment StripePlan on StripePlan {
  id
  object
  amount
  amount_decimal
  currency
  name
  product
}
    `;
export const StripePriceFragmentDoc = gql`
    fragment StripePrice on StripePrice {
  id
  object
  product
  unit_amount
  unit_amount_decimal
}
    `;
export const SubjectFragmentDoc = gql`
    fragment Subject on Subject {
  _id
  name
  displayName
}
    `;
export const HelpCenterCategoryFragmentFragmentDoc = gql`
    fragment HelpCenterCategoryFragment on HelpCenterCategory {
  id
  name
  description
  position
}
    `;
export const HelpCenterSectionFragmentFragmentDoc = gql`
    fragment HelpCenterSectionFragment on HelpCenterSection {
  id
  category_id
  parent_section_id
  name
  description
  position
}
    `;
export const HelpCenterArticleFragmentFragmentDoc = gql`
    fragment HelpCenterArticleFragment on HelpCenterArticle {
  id
  section_id
  title
  body
  position
  user_segment_id
}
    `;
export const GetCoursesForCourseSelectionReturnFragmentDoc = gql`
    fragment GetCoursesForCourseSelectionReturn on Course {
  _id
  name
  displayName
  subject {
    _id
    displayName
  }
}
    `;
export const PartialEditedRichTextFragmentDoc = gql`
    fragment PartialEditedRichText on EditedRichText {
  _id
  content
  title
  originalReferenceTextId
  originalReferenceText {
    title
    description
  }
  updatedAt
}
    `;
export const LearningTargetProgressFragmentFragmentDoc = gql`
    fragment LearningTargetProgressFragment on LearningTargetProgress {
  targetId
  level
}
    `;
export const NestedRubricItemFragmentFragmentDoc = gql`
    fragment NestedRubricItemFragment on NestedRubricItem {
  _id
  name
  sortKey
}
    `;
export const CourseRubricFragmentFragmentDoc = gql`
    fragment CourseRubricFragment on CourseRubric {
  _id
  courseId
  isActive
  items {
    ...NestedRubricItemFragment
    subitems {
      ...NestedRubricItemFragment
      subitems {
        ...NestedRubricItemFragment
        subitems {
          ...NestedRubricItemFragment
        }
      }
    }
  }
}
    ${NestedRubricItemFragmentFragmentDoc}`;
export const CourseRubricProgressFragmentFragmentDoc = gql`
    fragment CourseRubricProgressFragment on CourseRubricProgress {
  courseId
  studentId
  progresses {
    ...LearningTargetProgressFragment
  }
  rubric {
    ...CourseRubricFragment
  }
}
    ${LearningTargetProgressFragmentFragmentDoc}
${CourseRubricFragmentFragmentDoc}`;
export const SessionFeedbackBaseFragmentDoc = gql`
    fragment SessionFeedbackBase on SessionFeedbackPayload {
  _id
  completedAt
  createdAt
  feedback
  response
  teacherSessionId
}
    `;
export const SessionFeedbackAugmentedFragmentDoc = gql`
    fragment SessionFeedbackAugmented on SessionFeedbackPayload {
  ...SessionFeedbackBase
  notes
  sessionTime
  studentId
  studentFirstName
  studentLastName
}
    ${SessionFeedbackBaseFragmentDoc}`;
export const EnglishAnswerSubmissionFragmentDoc = gql`
    fragment EnglishAnswerSubmission on EnglishAnswerSubmission {
  studentId
  moduleSectionId
  problemId
  submittedValues
}
    `;
export const EnglishProjectFragmentDoc = gql`
    fragment EnglishProject on EnglishProject {
  _id
  projectType
  studentId
  moduleSectionId
  writingPromptId
  referenceTextSetId
  annotatedTextIds
  editedTextId
  isCustomProject
}
    `;
export const InvestingAnswerSubmissionFragmentDoc = gql`
    fragment InvestingAnswerSubmission on InvestingAnswerSubmission {
  studentId
  moduleSectionId
  problemId
  submittedValues
}
    `;
export const InvestingProjectFragmentDoc = gql`
    fragment InvestingProject on InvestingProject {
  _id
  projectType
  studentId
  moduleSectionId
  writingPromptId
  referenceTextSetId
  annotatedTextIds
  editedTextId
  isCustomProject
}
    `;
export const ScheduleFieldsFragmentDoc = gql`
    fragment ScheduleFields on CourseOfferingScheduleItem {
  startTime
}
    `;
export const CourseOfferingFieldsFragmentDoc = gql`
    fragment CourseOfferingFields on CourseOffering {
  _id
  courseId
  courseName
  instructorId
  totalSeats
  numSeatsAvailable
  schedule {
    ...ScheduleFields
  }
  enrollmentEndDate
  totalPriceInCents
  createdAt
  updatedAt
}
    ${ScheduleFieldsFragmentDoc}`;
export const GetCoursesReturnFragmentDoc = gql`
    fragment GetCoursesReturn on Course {
  _id
  name
  displayName
  slug
  abbreviation
  headerImage {
    original
  }
  iconName
  primaryColor
  subjectId
  subject {
    _id
    name
    displayName
    createdAt
    updatedAt
  }
  isAcceptingEnrollment
  curriculumId
  curriculumCollection
  order
  minRecommendedAge
  maxRecommendedAge
  creatorUserId
  schedulingFormat
  isMarketplaceCourse
  formatDisplayName
  tags {
    _id
    type
    value
    createdAt
    updatedAt
  }
  statistics {
    ratingTotal
    ratingScale
    ratingCount
    ratingDistribution
    totalEnrollments
    activeEnrollments
  }
  courseDescription
  defaultJideEnv
  formattedDuration
  prereqs
  syllabus
  recommendationRank
  createdAt
  updatedAt
  offerings {
    ...CourseOfferingFields
  }
}
    ${CourseOfferingFieldsFragmentDoc}`;
export const GetCourseServiceResultFragmentDoc = gql`
    fragment GetCourseServiceResult on Course {
  _id
  name
  displayName
  curriculumId
  subject {
    _id
    name
    displayName
  }
  isAcceptingEnrollment
}
    `;
export const GetRecommendedCoursesResultFragmentDoc = gql`
    fragment GetRecommendedCoursesResult on Course {
  _id
  name
  displayName
  nextRecommendedCourses {
    _id
    name
    displayName
    courseDescription
  }
}
    `;
export const CreateCourseOfferingDocument = gql`
    mutation CreateCourseOffering($input: CreateCourseOfferingInput!) {
  createCourseOffering(input: $input) {
    success
  }
}
    `;
export type CreateCourseOfferingMutationFn = ApolloReactCommon.MutationFunction<CreateCourseOfferingMutation, CreateCourseOfferingMutationVariables>;

/**
 * __useCreateCourseOfferingMutation__
 *
 * To run a mutation, you first call `useCreateCourseOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseOfferingMutation, { data, loading, error }] = useCreateCourseOfferingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseOfferingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseOfferingMutation, CreateCourseOfferingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCourseOfferingMutation, CreateCourseOfferingMutationVariables>(CreateCourseOfferingDocument, baseOptions);
      }
export type CreateCourseOfferingMutationHookResult = ReturnType<typeof useCreateCourseOfferingMutation>;
export type CreateCourseOfferingMutationResult = ApolloReactCommon.MutationResult<CreateCourseOfferingMutation>;
export type CreateCourseOfferingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseOfferingMutation, CreateCourseOfferingMutationVariables>;
export const GetOfferingsByCourseIdDocument = gql`
    query GetOfferingsByCourseId($courseId: ObjectId!) {
  getCourse(id: $courseId) {
    displayName
    ...CourseOfferingsList
  }
}
    ${CourseOfferingsListFragmentDoc}`;

/**
 * __useGetOfferingsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetOfferingsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingsByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetOfferingsByCourseIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfferingsByCourseIdQuery, GetOfferingsByCourseIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfferingsByCourseIdQuery, GetOfferingsByCourseIdQueryVariables>(GetOfferingsByCourseIdDocument, baseOptions);
      }
export function useGetOfferingsByCourseIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfferingsByCourseIdQuery, GetOfferingsByCourseIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfferingsByCourseIdQuery, GetOfferingsByCourseIdQueryVariables>(GetOfferingsByCourseIdDocument, baseOptions);
        }
export type GetOfferingsByCourseIdQueryHookResult = ReturnType<typeof useGetOfferingsByCourseIdQuery>;
export type GetOfferingsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetOfferingsByCourseIdLazyQuery>;
export type GetOfferingsByCourseIdQueryResult = ApolloReactCommon.QueryResult<GetOfferingsByCourseIdQuery, GetOfferingsByCourseIdQueryVariables>;
export const GetOfferingsByCourseNameDocument = gql`
    query GetOfferingsByCourseName($courseName: String!) {
  getCourse(name: $courseName) {
    ...CourseOfferingsList
  }
}
    ${CourseOfferingsListFragmentDoc}`;

/**
 * __useGetOfferingsByCourseNameQuery__
 *
 * To run a query within a React component, call `useGetOfferingsByCourseNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingsByCourseNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingsByCourseNameQuery({
 *   variables: {
 *      courseName: // value for 'courseName'
 *   },
 * });
 */
export function useGetOfferingsByCourseNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfferingsByCourseNameQuery, GetOfferingsByCourseNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfferingsByCourseNameQuery, GetOfferingsByCourseNameQueryVariables>(GetOfferingsByCourseNameDocument, baseOptions);
      }
export function useGetOfferingsByCourseNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfferingsByCourseNameQuery, GetOfferingsByCourseNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfferingsByCourseNameQuery, GetOfferingsByCourseNameQueryVariables>(GetOfferingsByCourseNameDocument, baseOptions);
        }
export type GetOfferingsByCourseNameQueryHookResult = ReturnType<typeof useGetOfferingsByCourseNameQuery>;
export type GetOfferingsByCourseNameLazyQueryHookResult = ReturnType<typeof useGetOfferingsByCourseNameLazyQuery>;
export type GetOfferingsByCourseNameQueryResult = ApolloReactCommon.QueryResult<GetOfferingsByCourseNameQuery, GetOfferingsByCourseNameQueryVariables>;
export const CreateBootcampCourseDocument = gql`
    mutation CreateBootcampCourse($input: CreateBootcampCourseInput!) {
  createBootcampCourse(input: $input) {
    name
    _id
  }
}
    `;
export type CreateBootcampCourseMutationFn = ApolloReactCommon.MutationFunction<CreateBootcampCourseMutation, CreateBootcampCourseMutationVariables>;

/**
 * __useCreateBootcampCourseMutation__
 *
 * To run a mutation, you first call `useCreateBootcampCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBootcampCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBootcampCourseMutation, { data, loading, error }] = useCreateBootcampCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBootcampCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBootcampCourseMutation, CreateBootcampCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBootcampCourseMutation, CreateBootcampCourseMutationVariables>(CreateBootcampCourseDocument, baseOptions);
      }
export type CreateBootcampCourseMutationHookResult = ReturnType<typeof useCreateBootcampCourseMutation>;
export type CreateBootcampCourseMutationResult = ApolloReactCommon.MutationResult<CreateBootcampCourseMutation>;
export type CreateBootcampCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBootcampCourseMutation, CreateBootcampCourseMutationVariables>;
export const UpdateBootcampCourseDocument = gql`
    mutation UpdateBootcampCourse($input: UpdateBootcampCourseInput!) {
  updateBootcampCourse(input: $input) {
    success
  }
}
    `;
export type UpdateBootcampCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateBootcampCourseMutation, UpdateBootcampCourseMutationVariables>;

/**
 * __useUpdateBootcampCourseMutation__
 *
 * To run a mutation, you first call `useUpdateBootcampCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBootcampCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBootcampCourseMutation, { data, loading, error }] = useUpdateBootcampCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBootcampCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBootcampCourseMutation, UpdateBootcampCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBootcampCourseMutation, UpdateBootcampCourseMutationVariables>(UpdateBootcampCourseDocument, baseOptions);
      }
export type UpdateBootcampCourseMutationHookResult = ReturnType<typeof useUpdateBootcampCourseMutation>;
export type UpdateBootcampCourseMutationResult = ApolloReactCommon.MutationResult<UpdateBootcampCourseMutation>;
export type UpdateBootcampCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBootcampCourseMutation, UpdateBootcampCourseMutationVariables>;
export const EnableEnrollmentDocument = gql`
    mutation EnableEnrollment($input: EnableEnrollmentInput!) {
  enableEnrollment(input: $input) {
    success
  }
}
    `;
export type EnableEnrollmentMutationFn = ApolloReactCommon.MutationFunction<EnableEnrollmentMutation, EnableEnrollmentMutationVariables>;

/**
 * __useEnableEnrollmentMutation__
 *
 * To run a mutation, you first call `useEnableEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableEnrollmentMutation, { data, loading, error }] = useEnableEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableEnrollmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableEnrollmentMutation, EnableEnrollmentMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableEnrollmentMutation, EnableEnrollmentMutationVariables>(EnableEnrollmentDocument, baseOptions);
      }
export type EnableEnrollmentMutationHookResult = ReturnType<typeof useEnableEnrollmentMutation>;
export type EnableEnrollmentMutationResult = ApolloReactCommon.MutationResult<EnableEnrollmentMutation>;
export type EnableEnrollmentMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableEnrollmentMutation, EnableEnrollmentMutationVariables>;
export const DisableEnrollmentDocument = gql`
    mutation DisableEnrollment($input: DisableEnrollmentInput!) {
  disableEnrollment(input: $input) {
    success
  }
}
    `;
export type DisableEnrollmentMutationFn = ApolloReactCommon.MutationFunction<DisableEnrollmentMutation, DisableEnrollmentMutationVariables>;

/**
 * __useDisableEnrollmentMutation__
 *
 * To run a mutation, you first call `useDisableEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableEnrollmentMutation, { data, loading, error }] = useDisableEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableEnrollmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableEnrollmentMutation, DisableEnrollmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableEnrollmentMutation, DisableEnrollmentMutationVariables>(DisableEnrollmentDocument, baseOptions);
      }
export type DisableEnrollmentMutationHookResult = ReturnType<typeof useDisableEnrollmentMutation>;
export type DisableEnrollmentMutationResult = ApolloReactCommon.MutationResult<DisableEnrollmentMutation>;
export type DisableEnrollmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableEnrollmentMutation, DisableEnrollmentMutationVariables>;
export const GetCourseByIdForCourseCreatorDocument = gql`
    query GetCourseByIdForCourseCreator($courseId: ObjectId!) {
  getCourse(id: $courseId) {
    _id
    displayName
    name
    homeworkInformation
    schedulingFormat
    courseDescription
    headerImage {
      original
    }
    subject {
      _id
      name
      displayName
    }
    offerings {
      _id
      courseId
      courseName
      instructorId
      totalSeats
      numSeatsAvailable
      schedule {
        duration
        startTime
        placeholderAcuityApptId
        appointmentTypeId
      }
      enrollmentEndDate
      totalPriceInCents
    }
    isAcceptingEnrollment
  }
}
    `;

/**
 * __useGetCourseByIdForCourseCreatorQuery__
 *
 * To run a query within a React component, call `useGetCourseByIdForCourseCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByIdForCourseCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByIdForCourseCreatorQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseByIdForCourseCreatorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseByIdForCourseCreatorQuery, GetCourseByIdForCourseCreatorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseByIdForCourseCreatorQuery, GetCourseByIdForCourseCreatorQueryVariables>(GetCourseByIdForCourseCreatorDocument, baseOptions);
      }
export function useGetCourseByIdForCourseCreatorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseByIdForCourseCreatorQuery, GetCourseByIdForCourseCreatorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseByIdForCourseCreatorQuery, GetCourseByIdForCourseCreatorQueryVariables>(GetCourseByIdForCourseCreatorDocument, baseOptions);
        }
export type GetCourseByIdForCourseCreatorQueryHookResult = ReturnType<typeof useGetCourseByIdForCourseCreatorQuery>;
export type GetCourseByIdForCourseCreatorLazyQueryHookResult = ReturnType<typeof useGetCourseByIdForCourseCreatorLazyQuery>;
export type GetCourseByIdForCourseCreatorQueryResult = ApolloReactCommon.QueryResult<GetCourseByIdForCourseCreatorQuery, GetCourseByIdForCourseCreatorQueryVariables>;
export const LoadCourseRubricDocument = gql`
    query LoadCourseRubric($input: ObjectId!) {
  courseRubric(courseId: $input) {
    ...CourseRubricFragment
  }
}
    ${CourseRubricFragmentFragmentDoc}`;

/**
 * __useLoadCourseRubricQuery__
 *
 * To run a query within a React component, call `useLoadCourseRubricQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCourseRubricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCourseRubricQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadCourseRubricQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadCourseRubricQuery, LoadCourseRubricQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadCourseRubricQuery, LoadCourseRubricQueryVariables>(LoadCourseRubricDocument, baseOptions);
      }
export function useLoadCourseRubricLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadCourseRubricQuery, LoadCourseRubricQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadCourseRubricQuery, LoadCourseRubricQueryVariables>(LoadCourseRubricDocument, baseOptions);
        }
export type LoadCourseRubricQueryHookResult = ReturnType<typeof useLoadCourseRubricQuery>;
export type LoadCourseRubricLazyQueryHookResult = ReturnType<typeof useLoadCourseRubricLazyQuery>;
export type LoadCourseRubricQueryResult = ApolloReactCommon.QueryResult<LoadCourseRubricQuery, LoadCourseRubricQueryVariables>;
export const SaveCourseRubricDocument = gql`
    mutation SaveCourseRubric($input: UpdateCourseRubricInput!) {
  updateCourseRubric(input: $input) {
    courseRubric {
      ...CourseRubricFragment
    }
  }
}
    ${CourseRubricFragmentFragmentDoc}`;
export type SaveCourseRubricMutationFn = ApolloReactCommon.MutationFunction<SaveCourseRubricMutation, SaveCourseRubricMutationVariables>;

/**
 * __useSaveCourseRubricMutation__
 *
 * To run a mutation, you first call `useSaveCourseRubricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCourseRubricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCourseRubricMutation, { data, loading, error }] = useSaveCourseRubricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCourseRubricMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveCourseRubricMutation, SaveCourseRubricMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveCourseRubricMutation, SaveCourseRubricMutationVariables>(SaveCourseRubricDocument, baseOptions);
      }
export type SaveCourseRubricMutationHookResult = ReturnType<typeof useSaveCourseRubricMutation>;
export type SaveCourseRubricMutationResult = ApolloReactCommon.MutationResult<SaveCourseRubricMutation>;
export type SaveCourseRubricMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveCourseRubricMutation, SaveCourseRubricMutationVariables>;
export const LoadEnglishCourseByIdDocument = gql`
    query LoadEnglishCourseById($input: ObjectId!) {
  englishCourseById(id: $input) {
    ...EnglishCourseFragment
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;

/**
 * __useLoadEnglishCourseByIdQuery__
 *
 * To run a query within a React component, call `useLoadEnglishCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEnglishCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEnglishCourseByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadEnglishCourseByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadEnglishCourseByIdQuery, LoadEnglishCourseByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadEnglishCourseByIdQuery, LoadEnglishCourseByIdQueryVariables>(LoadEnglishCourseByIdDocument, baseOptions);
      }
export function useLoadEnglishCourseByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadEnglishCourseByIdQuery, LoadEnglishCourseByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadEnglishCourseByIdQuery, LoadEnglishCourseByIdQueryVariables>(LoadEnglishCourseByIdDocument, baseOptions);
        }
export type LoadEnglishCourseByIdQueryHookResult = ReturnType<typeof useLoadEnglishCourseByIdQuery>;
export type LoadEnglishCourseByIdLazyQueryHookResult = ReturnType<typeof useLoadEnglishCourseByIdLazyQuery>;
export type LoadEnglishCourseByIdQueryResult = ApolloReactCommon.QueryResult<LoadEnglishCourseByIdQuery, LoadEnglishCourseByIdQueryVariables>;
export const CountEnglishCoursesDocument = gql`
    query CountEnglishCourses {
  englishCoursesCount
}
    `;

/**
 * __useCountEnglishCoursesQuery__
 *
 * To run a query within a React component, call `useCountEnglishCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountEnglishCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountEnglishCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountEnglishCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountEnglishCoursesQuery, CountEnglishCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountEnglishCoursesQuery, CountEnglishCoursesQueryVariables>(CountEnglishCoursesDocument, baseOptions);
      }
export function useCountEnglishCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountEnglishCoursesQuery, CountEnglishCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountEnglishCoursesQuery, CountEnglishCoursesQueryVariables>(CountEnglishCoursesDocument, baseOptions);
        }
export type CountEnglishCoursesQueryHookResult = ReturnType<typeof useCountEnglishCoursesQuery>;
export type CountEnglishCoursesLazyQueryHookResult = ReturnType<typeof useCountEnglishCoursesLazyQuery>;
export type CountEnglishCoursesQueryResult = ApolloReactCommon.QueryResult<CountEnglishCoursesQuery, CountEnglishCoursesQueryVariables>;
export const CreateEnglishCourseDocument = gql`
    mutation createEnglishCourse($input: CreateEnglishCourseInput!) {
  createEnglishCourse(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type CreateEnglishCourseMutationFn = ApolloReactCommon.MutationFunction<CreateEnglishCourseMutation, CreateEnglishCourseMutationVariables>;

/**
 * __useCreateEnglishCourseMutation__
 *
 * To run a mutation, you first call `useCreateEnglishCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnglishCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnglishCourseMutation, { data, loading, error }] = useCreateEnglishCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnglishCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEnglishCourseMutation, CreateEnglishCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEnglishCourseMutation, CreateEnglishCourseMutationVariables>(CreateEnglishCourseDocument, baseOptions);
      }
export type CreateEnglishCourseMutationHookResult = ReturnType<typeof useCreateEnglishCourseMutation>;
export type CreateEnglishCourseMutationResult = ApolloReactCommon.MutationResult<CreateEnglishCourseMutation>;
export type CreateEnglishCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEnglishCourseMutation, CreateEnglishCourseMutationVariables>;
export const UpdateEnglishCourseDocument = gql`
    mutation updateEnglishCourse($input: UpdateEnglishCourseInput!) {
  updateEnglishCourse(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type UpdateEnglishCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateEnglishCourseMutation, UpdateEnglishCourseMutationVariables>;

/**
 * __useUpdateEnglishCourseMutation__
 *
 * To run a mutation, you first call `useUpdateEnglishCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnglishCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnglishCourseMutation, { data, loading, error }] = useUpdateEnglishCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnglishCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEnglishCourseMutation, UpdateEnglishCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEnglishCourseMutation, UpdateEnglishCourseMutationVariables>(UpdateEnglishCourseDocument, baseOptions);
      }
export type UpdateEnglishCourseMutationHookResult = ReturnType<typeof useUpdateEnglishCourseMutation>;
export type UpdateEnglishCourseMutationResult = ApolloReactCommon.MutationResult<UpdateEnglishCourseMutation>;
export type UpdateEnglishCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEnglishCourseMutation, UpdateEnglishCourseMutationVariables>;
export const DeleteEnglishCourseDocument = gql`
    mutation deleteEnglishCourse($input: DeleteEnglishCourseInput!) {
  deleteEnglishCourse(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type DeleteEnglishCourseMutationFn = ApolloReactCommon.MutationFunction<DeleteEnglishCourseMutation, DeleteEnglishCourseMutationVariables>;

/**
 * __useDeleteEnglishCourseMutation__
 *
 * To run a mutation, you first call `useDeleteEnglishCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnglishCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnglishCourseMutation, { data, loading, error }] = useDeleteEnglishCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnglishCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEnglishCourseMutation, DeleteEnglishCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEnglishCourseMutation, DeleteEnglishCourseMutationVariables>(DeleteEnglishCourseDocument, baseOptions);
      }
export type DeleteEnglishCourseMutationHookResult = ReturnType<typeof useDeleteEnglishCourseMutation>;
export type DeleteEnglishCourseMutationResult = ApolloReactCommon.MutationResult<DeleteEnglishCourseMutation>;
export type DeleteEnglishCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEnglishCourseMutation, DeleteEnglishCourseMutationVariables>;
export const CreateEnglishModuleTopicDocument = gql`
    mutation createEnglishModuleTopic($input: CreateEnglishModuleTopicInput!) {
  createEnglishModuleTopic(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type CreateEnglishModuleTopicMutationFn = ApolloReactCommon.MutationFunction<CreateEnglishModuleTopicMutation, CreateEnglishModuleTopicMutationVariables>;

/**
 * __useCreateEnglishModuleTopicMutation__
 *
 * To run a mutation, you first call `useCreateEnglishModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnglishModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnglishModuleTopicMutation, { data, loading, error }] = useCreateEnglishModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnglishModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEnglishModuleTopicMutation, CreateEnglishModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEnglishModuleTopicMutation, CreateEnglishModuleTopicMutationVariables>(CreateEnglishModuleTopicDocument, baseOptions);
      }
export type CreateEnglishModuleTopicMutationHookResult = ReturnType<typeof useCreateEnglishModuleTopicMutation>;
export type CreateEnglishModuleTopicMutationResult = ApolloReactCommon.MutationResult<CreateEnglishModuleTopicMutation>;
export type CreateEnglishModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEnglishModuleTopicMutation, CreateEnglishModuleTopicMutationVariables>;
export const UpdateEnglishModuleTopicDocument = gql`
    mutation updateEnglishModuleTopic($input: UpdateEnglishModuleTopicInput!) {
  updateEnglishModuleTopic(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type UpdateEnglishModuleTopicMutationFn = ApolloReactCommon.MutationFunction<UpdateEnglishModuleTopicMutation, UpdateEnglishModuleTopicMutationVariables>;

/**
 * __useUpdateEnglishModuleTopicMutation__
 *
 * To run a mutation, you first call `useUpdateEnglishModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnglishModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnglishModuleTopicMutation, { data, loading, error }] = useUpdateEnglishModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnglishModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEnglishModuleTopicMutation, UpdateEnglishModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEnglishModuleTopicMutation, UpdateEnglishModuleTopicMutationVariables>(UpdateEnglishModuleTopicDocument, baseOptions);
      }
export type UpdateEnglishModuleTopicMutationHookResult = ReturnType<typeof useUpdateEnglishModuleTopicMutation>;
export type UpdateEnglishModuleTopicMutationResult = ApolloReactCommon.MutationResult<UpdateEnglishModuleTopicMutation>;
export type UpdateEnglishModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEnglishModuleTopicMutation, UpdateEnglishModuleTopicMutationVariables>;
export const DeleteEnglishModuleTopicDocument = gql`
    mutation deleteEnglishModuleTopic($input: DeleteEnglishModuleTopicInput!) {
  deleteEnglishModuleTopic(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type DeleteEnglishModuleTopicMutationFn = ApolloReactCommon.MutationFunction<DeleteEnglishModuleTopicMutation, DeleteEnglishModuleTopicMutationVariables>;

/**
 * __useDeleteEnglishModuleTopicMutation__
 *
 * To run a mutation, you first call `useDeleteEnglishModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnglishModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnglishModuleTopicMutation, { data, loading, error }] = useDeleteEnglishModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnglishModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEnglishModuleTopicMutation, DeleteEnglishModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEnglishModuleTopicMutation, DeleteEnglishModuleTopicMutationVariables>(DeleteEnglishModuleTopicDocument, baseOptions);
      }
export type DeleteEnglishModuleTopicMutationHookResult = ReturnType<typeof useDeleteEnglishModuleTopicMutation>;
export type DeleteEnglishModuleTopicMutationResult = ApolloReactCommon.MutationResult<DeleteEnglishModuleTopicMutation>;
export type DeleteEnglishModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEnglishModuleTopicMutation, DeleteEnglishModuleTopicMutationVariables>;
export const CreateEnglishModuleDocument = gql`
    mutation createEnglishModule($input: CreateEnglishModuleInput!) {
  createEnglishModule(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type CreateEnglishModuleMutationFn = ApolloReactCommon.MutationFunction<CreateEnglishModuleMutation, CreateEnglishModuleMutationVariables>;

/**
 * __useCreateEnglishModuleMutation__
 *
 * To run a mutation, you first call `useCreateEnglishModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnglishModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnglishModuleMutation, { data, loading, error }] = useCreateEnglishModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnglishModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEnglishModuleMutation, CreateEnglishModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEnglishModuleMutation, CreateEnglishModuleMutationVariables>(CreateEnglishModuleDocument, baseOptions);
      }
export type CreateEnglishModuleMutationHookResult = ReturnType<typeof useCreateEnglishModuleMutation>;
export type CreateEnglishModuleMutationResult = ApolloReactCommon.MutationResult<CreateEnglishModuleMutation>;
export type CreateEnglishModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEnglishModuleMutation, CreateEnglishModuleMutationVariables>;
export const UpdateEnglishModuleDocument = gql`
    mutation updateEnglishModule($input: UpdateEnglishModuleInput!) {
  updateEnglishModule(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type UpdateEnglishModuleMutationFn = ApolloReactCommon.MutationFunction<UpdateEnglishModuleMutation, UpdateEnglishModuleMutationVariables>;

/**
 * __useUpdateEnglishModuleMutation__
 *
 * To run a mutation, you first call `useUpdateEnglishModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnglishModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnglishModuleMutation, { data, loading, error }] = useUpdateEnglishModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnglishModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEnglishModuleMutation, UpdateEnglishModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEnglishModuleMutation, UpdateEnglishModuleMutationVariables>(UpdateEnglishModuleDocument, baseOptions);
      }
export type UpdateEnglishModuleMutationHookResult = ReturnType<typeof useUpdateEnglishModuleMutation>;
export type UpdateEnglishModuleMutationResult = ApolloReactCommon.MutationResult<UpdateEnglishModuleMutation>;
export type UpdateEnglishModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEnglishModuleMutation, UpdateEnglishModuleMutationVariables>;
export const DeleteEnglishModuleDocument = gql`
    mutation deleteEnglishModule($input: DeleteEnglishModuleInput!) {
  deleteEnglishModule(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type DeleteEnglishModuleMutationFn = ApolloReactCommon.MutationFunction<DeleteEnglishModuleMutation, DeleteEnglishModuleMutationVariables>;

/**
 * __useDeleteEnglishModuleMutation__
 *
 * To run a mutation, you first call `useDeleteEnglishModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnglishModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnglishModuleMutation, { data, loading, error }] = useDeleteEnglishModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnglishModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEnglishModuleMutation, DeleteEnglishModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEnglishModuleMutation, DeleteEnglishModuleMutationVariables>(DeleteEnglishModuleDocument, baseOptions);
      }
export type DeleteEnglishModuleMutationHookResult = ReturnType<typeof useDeleteEnglishModuleMutation>;
export type DeleteEnglishModuleMutationResult = ApolloReactCommon.MutationResult<DeleteEnglishModuleMutation>;
export type DeleteEnglishModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEnglishModuleMutation, DeleteEnglishModuleMutationVariables>;
export const CreateEnglishModuleSectionDocument = gql`
    mutation createEnglishModuleSection($input: CreateEnglishModuleSectionInput!) {
  createEnglishModuleSection(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type CreateEnglishModuleSectionMutationFn = ApolloReactCommon.MutationFunction<CreateEnglishModuleSectionMutation, CreateEnglishModuleSectionMutationVariables>;

/**
 * __useCreateEnglishModuleSectionMutation__
 *
 * To run a mutation, you first call `useCreateEnglishModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnglishModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnglishModuleSectionMutation, { data, loading, error }] = useCreateEnglishModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnglishModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEnglishModuleSectionMutation, CreateEnglishModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEnglishModuleSectionMutation, CreateEnglishModuleSectionMutationVariables>(CreateEnglishModuleSectionDocument, baseOptions);
      }
export type CreateEnglishModuleSectionMutationHookResult = ReturnType<typeof useCreateEnglishModuleSectionMutation>;
export type CreateEnglishModuleSectionMutationResult = ApolloReactCommon.MutationResult<CreateEnglishModuleSectionMutation>;
export type CreateEnglishModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEnglishModuleSectionMutation, CreateEnglishModuleSectionMutationVariables>;
export const UpdateEnglishModuleSectionDocument = gql`
    mutation updateEnglishModuleSection($input: UpdateEnglishModuleSectionInput!) {
  updateEnglishModuleSection(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type UpdateEnglishModuleSectionMutationFn = ApolloReactCommon.MutationFunction<UpdateEnglishModuleSectionMutation, UpdateEnglishModuleSectionMutationVariables>;

/**
 * __useUpdateEnglishModuleSectionMutation__
 *
 * To run a mutation, you first call `useUpdateEnglishModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnglishModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnglishModuleSectionMutation, { data, loading, error }] = useUpdateEnglishModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnglishModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEnglishModuleSectionMutation, UpdateEnglishModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEnglishModuleSectionMutation, UpdateEnglishModuleSectionMutationVariables>(UpdateEnglishModuleSectionDocument, baseOptions);
      }
export type UpdateEnglishModuleSectionMutationHookResult = ReturnType<typeof useUpdateEnglishModuleSectionMutation>;
export type UpdateEnglishModuleSectionMutationResult = ApolloReactCommon.MutationResult<UpdateEnglishModuleSectionMutation>;
export type UpdateEnglishModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEnglishModuleSectionMutation, UpdateEnglishModuleSectionMutationVariables>;
export const DeleteEnglishModuleSectionDocument = gql`
    mutation deleteEnglishModuleSection($input: DeleteEnglishModuleSectionInput!) {
  deleteEnglishModuleSection(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type DeleteEnglishModuleSectionMutationFn = ApolloReactCommon.MutationFunction<DeleteEnglishModuleSectionMutation, DeleteEnglishModuleSectionMutationVariables>;

/**
 * __useDeleteEnglishModuleSectionMutation__
 *
 * To run a mutation, you first call `useDeleteEnglishModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnglishModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnglishModuleSectionMutation, { data, loading, error }] = useDeleteEnglishModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnglishModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEnglishModuleSectionMutation, DeleteEnglishModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEnglishModuleSectionMutation, DeleteEnglishModuleSectionMutationVariables>(DeleteEnglishModuleSectionDocument, baseOptions);
      }
export type DeleteEnglishModuleSectionMutationHookResult = ReturnType<typeof useDeleteEnglishModuleSectionMutation>;
export type DeleteEnglishModuleSectionMutationResult = ApolloReactCommon.MutationResult<DeleteEnglishModuleSectionMutation>;
export type DeleteEnglishModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEnglishModuleSectionMutation, DeleteEnglishModuleSectionMutationVariables>;
export const DeleteEnglishReferenceTextSetOptionDocument = gql`
    mutation deleteEnglishReferenceTextSetOption($input: DeleteEnglishReferenceTextSetOptionInput!) {
  deleteEnglishReferenceTextSetOption(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type DeleteEnglishReferenceTextSetOptionMutationFn = ApolloReactCommon.MutationFunction<DeleteEnglishReferenceTextSetOptionMutation, DeleteEnglishReferenceTextSetOptionMutationVariables>;

/**
 * __useDeleteEnglishReferenceTextSetOptionMutation__
 *
 * To run a mutation, you first call `useDeleteEnglishReferenceTextSetOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnglishReferenceTextSetOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnglishReferenceTextSetOptionMutation, { data, loading, error }] = useDeleteEnglishReferenceTextSetOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnglishReferenceTextSetOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEnglishReferenceTextSetOptionMutation, DeleteEnglishReferenceTextSetOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEnglishReferenceTextSetOptionMutation, DeleteEnglishReferenceTextSetOptionMutationVariables>(DeleteEnglishReferenceTextSetOptionDocument, baseOptions);
      }
export type DeleteEnglishReferenceTextSetOptionMutationHookResult = ReturnType<typeof useDeleteEnglishReferenceTextSetOptionMutation>;
export type DeleteEnglishReferenceTextSetOptionMutationResult = ApolloReactCommon.MutationResult<DeleteEnglishReferenceTextSetOptionMutation>;
export type DeleteEnglishReferenceTextSetOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEnglishReferenceTextSetOptionMutation, DeleteEnglishReferenceTextSetOptionMutationVariables>;
export const CreateEnglishModuleSectionBulletDocument = gql`
    mutation createEnglishModuleSectionBullet($input: CreateEnglishModuleSectionBulletInput!) {
  createEnglishModuleSectionBullet(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type CreateEnglishModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<CreateEnglishModuleSectionBulletMutation, CreateEnglishModuleSectionBulletMutationVariables>;

/**
 * __useCreateEnglishModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useCreateEnglishModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnglishModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnglishModuleSectionBulletMutation, { data, loading, error }] = useCreateEnglishModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnglishModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEnglishModuleSectionBulletMutation, CreateEnglishModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEnglishModuleSectionBulletMutation, CreateEnglishModuleSectionBulletMutationVariables>(CreateEnglishModuleSectionBulletDocument, baseOptions);
      }
export type CreateEnglishModuleSectionBulletMutationHookResult = ReturnType<typeof useCreateEnglishModuleSectionBulletMutation>;
export type CreateEnglishModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<CreateEnglishModuleSectionBulletMutation>;
export type CreateEnglishModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEnglishModuleSectionBulletMutation, CreateEnglishModuleSectionBulletMutationVariables>;
export const UpdateEnglishModuleSectionBulletDocument = gql`
    mutation updateEnglishModuleSectionBullet($input: UpdateEnglishModuleSectionBulletInput!) {
  updateEnglishModuleSectionBullet(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type UpdateEnglishModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<UpdateEnglishModuleSectionBulletMutation, UpdateEnglishModuleSectionBulletMutationVariables>;

/**
 * __useUpdateEnglishModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useUpdateEnglishModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnglishModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnglishModuleSectionBulletMutation, { data, loading, error }] = useUpdateEnglishModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnglishModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEnglishModuleSectionBulletMutation, UpdateEnglishModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEnglishModuleSectionBulletMutation, UpdateEnglishModuleSectionBulletMutationVariables>(UpdateEnglishModuleSectionBulletDocument, baseOptions);
      }
export type UpdateEnglishModuleSectionBulletMutationHookResult = ReturnType<typeof useUpdateEnglishModuleSectionBulletMutation>;
export type UpdateEnglishModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<UpdateEnglishModuleSectionBulletMutation>;
export type UpdateEnglishModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEnglishModuleSectionBulletMutation, UpdateEnglishModuleSectionBulletMutationVariables>;
export const DeleteEnglishModuleSectionBulletDocument = gql`
    mutation deleteEnglishModuleSectionBullet($input: DeleteEnglishModuleSectionBulletInput!) {
  deleteEnglishModuleSectionBullet(input: $input) {
    englishCourse {
      ...EnglishCourseFragment
    }
  }
}
    ${EnglishCourseFragmentFragmentDoc}`;
export type DeleteEnglishModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<DeleteEnglishModuleSectionBulletMutation, DeleteEnglishModuleSectionBulletMutationVariables>;

/**
 * __useDeleteEnglishModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useDeleteEnglishModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnglishModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnglishModuleSectionBulletMutation, { data, loading, error }] = useDeleteEnglishModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEnglishModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEnglishModuleSectionBulletMutation, DeleteEnglishModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEnglishModuleSectionBulletMutation, DeleteEnglishModuleSectionBulletMutationVariables>(DeleteEnglishModuleSectionBulletDocument, baseOptions);
      }
export type DeleteEnglishModuleSectionBulletMutationHookResult = ReturnType<typeof useDeleteEnglishModuleSectionBulletMutation>;
export type DeleteEnglishModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<DeleteEnglishModuleSectionBulletMutation>;
export type DeleteEnglishModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEnglishModuleSectionBulletMutation, DeleteEnglishModuleSectionBulletMutationVariables>;
export const LoadInvestingCourseByIdDocument = gql`
    query LoadInvestingCourseById($input: ObjectId!) {
  investingCourseById(id: $input) {
    ...InvestingCourseFragment
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;

/**
 * __useLoadInvestingCourseByIdQuery__
 *
 * To run a query within a React component, call `useLoadInvestingCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadInvestingCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadInvestingCourseByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadInvestingCourseByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadInvestingCourseByIdQuery, LoadInvestingCourseByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadInvestingCourseByIdQuery, LoadInvestingCourseByIdQueryVariables>(LoadInvestingCourseByIdDocument, baseOptions);
      }
export function useLoadInvestingCourseByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadInvestingCourseByIdQuery, LoadInvestingCourseByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadInvestingCourseByIdQuery, LoadInvestingCourseByIdQueryVariables>(LoadInvestingCourseByIdDocument, baseOptions);
        }
export type LoadInvestingCourseByIdQueryHookResult = ReturnType<typeof useLoadInvestingCourseByIdQuery>;
export type LoadInvestingCourseByIdLazyQueryHookResult = ReturnType<typeof useLoadInvestingCourseByIdLazyQuery>;
export type LoadInvestingCourseByIdQueryResult = ApolloReactCommon.QueryResult<LoadInvestingCourseByIdQuery, LoadInvestingCourseByIdQueryVariables>;
export const CountInvestingCoursesDocument = gql`
    query CountInvestingCourses {
  investingCoursesCount
}
    `;

/**
 * __useCountInvestingCoursesQuery__
 *
 * To run a query within a React component, call `useCountInvestingCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountInvestingCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountInvestingCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountInvestingCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountInvestingCoursesQuery, CountInvestingCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountInvestingCoursesQuery, CountInvestingCoursesQueryVariables>(CountInvestingCoursesDocument, baseOptions);
      }
export function useCountInvestingCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountInvestingCoursesQuery, CountInvestingCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountInvestingCoursesQuery, CountInvestingCoursesQueryVariables>(CountInvestingCoursesDocument, baseOptions);
        }
export type CountInvestingCoursesQueryHookResult = ReturnType<typeof useCountInvestingCoursesQuery>;
export type CountInvestingCoursesLazyQueryHookResult = ReturnType<typeof useCountInvestingCoursesLazyQuery>;
export type CountInvestingCoursesQueryResult = ApolloReactCommon.QueryResult<CountInvestingCoursesQuery, CountInvestingCoursesQueryVariables>;
export const CreateInvestingCourseDocument = gql`
    mutation createInvestingCourse($input: CreateInvestingCourseInput!) {
  createInvestingCourse(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type CreateInvestingCourseMutationFn = ApolloReactCommon.MutationFunction<CreateInvestingCourseMutation, CreateInvestingCourseMutationVariables>;

/**
 * __useCreateInvestingCourseMutation__
 *
 * To run a mutation, you first call `useCreateInvestingCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestingCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestingCourseMutation, { data, loading, error }] = useCreateInvestingCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvestingCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvestingCourseMutation, CreateInvestingCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvestingCourseMutation, CreateInvestingCourseMutationVariables>(CreateInvestingCourseDocument, baseOptions);
      }
export type CreateInvestingCourseMutationHookResult = ReturnType<typeof useCreateInvestingCourseMutation>;
export type CreateInvestingCourseMutationResult = ApolloReactCommon.MutationResult<CreateInvestingCourseMutation>;
export type CreateInvestingCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvestingCourseMutation, CreateInvestingCourseMutationVariables>;
export const UpdateInvestingCourseDocument = gql`
    mutation updateInvestingCourse($input: UpdateInvestingCourseInput!) {
  updateInvestingCourse(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type UpdateInvestingCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateInvestingCourseMutation, UpdateInvestingCourseMutationVariables>;

/**
 * __useUpdateInvestingCourseMutation__
 *
 * To run a mutation, you first call `useUpdateInvestingCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestingCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestingCourseMutation, { data, loading, error }] = useUpdateInvestingCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestingCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvestingCourseMutation, UpdateInvestingCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvestingCourseMutation, UpdateInvestingCourseMutationVariables>(UpdateInvestingCourseDocument, baseOptions);
      }
export type UpdateInvestingCourseMutationHookResult = ReturnType<typeof useUpdateInvestingCourseMutation>;
export type UpdateInvestingCourseMutationResult = ApolloReactCommon.MutationResult<UpdateInvestingCourseMutation>;
export type UpdateInvestingCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvestingCourseMutation, UpdateInvestingCourseMutationVariables>;
export const DeleteInvestingCourseDocument = gql`
    mutation deleteInvestingCourse($input: DeleteInvestingCourseInput!) {
  deleteInvestingCourse(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type DeleteInvestingCourseMutationFn = ApolloReactCommon.MutationFunction<DeleteInvestingCourseMutation, DeleteInvestingCourseMutationVariables>;

/**
 * __useDeleteInvestingCourseMutation__
 *
 * To run a mutation, you first call `useDeleteInvestingCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestingCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestingCourseMutation, { data, loading, error }] = useDeleteInvestingCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvestingCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvestingCourseMutation, DeleteInvestingCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvestingCourseMutation, DeleteInvestingCourseMutationVariables>(DeleteInvestingCourseDocument, baseOptions);
      }
export type DeleteInvestingCourseMutationHookResult = ReturnType<typeof useDeleteInvestingCourseMutation>;
export type DeleteInvestingCourseMutationResult = ApolloReactCommon.MutationResult<DeleteInvestingCourseMutation>;
export type DeleteInvestingCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvestingCourseMutation, DeleteInvestingCourseMutationVariables>;
export const CreateInvestingModuleTopicDocument = gql`
    mutation createInvestingModuleTopic($input: CreateInvestingModuleTopicInput!) {
  createInvestingModuleTopic(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type CreateInvestingModuleTopicMutationFn = ApolloReactCommon.MutationFunction<CreateInvestingModuleTopicMutation, CreateInvestingModuleTopicMutationVariables>;

/**
 * __useCreateInvestingModuleTopicMutation__
 *
 * To run a mutation, you first call `useCreateInvestingModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestingModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestingModuleTopicMutation, { data, loading, error }] = useCreateInvestingModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvestingModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvestingModuleTopicMutation, CreateInvestingModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvestingModuleTopicMutation, CreateInvestingModuleTopicMutationVariables>(CreateInvestingModuleTopicDocument, baseOptions);
      }
export type CreateInvestingModuleTopicMutationHookResult = ReturnType<typeof useCreateInvestingModuleTopicMutation>;
export type CreateInvestingModuleTopicMutationResult = ApolloReactCommon.MutationResult<CreateInvestingModuleTopicMutation>;
export type CreateInvestingModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvestingModuleTopicMutation, CreateInvestingModuleTopicMutationVariables>;
export const UpdateInvestingModuleTopicDocument = gql`
    mutation updateInvestingModuleTopic($input: UpdateInvestingModuleTopicInput!) {
  updateInvestingModuleTopic(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type UpdateInvestingModuleTopicMutationFn = ApolloReactCommon.MutationFunction<UpdateInvestingModuleTopicMutation, UpdateInvestingModuleTopicMutationVariables>;

/**
 * __useUpdateInvestingModuleTopicMutation__
 *
 * To run a mutation, you first call `useUpdateInvestingModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestingModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestingModuleTopicMutation, { data, loading, error }] = useUpdateInvestingModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestingModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvestingModuleTopicMutation, UpdateInvestingModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvestingModuleTopicMutation, UpdateInvestingModuleTopicMutationVariables>(UpdateInvestingModuleTopicDocument, baseOptions);
      }
export type UpdateInvestingModuleTopicMutationHookResult = ReturnType<typeof useUpdateInvestingModuleTopicMutation>;
export type UpdateInvestingModuleTopicMutationResult = ApolloReactCommon.MutationResult<UpdateInvestingModuleTopicMutation>;
export type UpdateInvestingModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvestingModuleTopicMutation, UpdateInvestingModuleTopicMutationVariables>;
export const DeleteInvestingModuleTopicDocument = gql`
    mutation deleteInvestingModuleTopic($input: DeleteInvestingModuleTopicInput!) {
  deleteInvestingModuleTopic(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type DeleteInvestingModuleTopicMutationFn = ApolloReactCommon.MutationFunction<DeleteInvestingModuleTopicMutation, DeleteInvestingModuleTopicMutationVariables>;

/**
 * __useDeleteInvestingModuleTopicMutation__
 *
 * To run a mutation, you first call `useDeleteInvestingModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestingModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestingModuleTopicMutation, { data, loading, error }] = useDeleteInvestingModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvestingModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvestingModuleTopicMutation, DeleteInvestingModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvestingModuleTopicMutation, DeleteInvestingModuleTopicMutationVariables>(DeleteInvestingModuleTopicDocument, baseOptions);
      }
export type DeleteInvestingModuleTopicMutationHookResult = ReturnType<typeof useDeleteInvestingModuleTopicMutation>;
export type DeleteInvestingModuleTopicMutationResult = ApolloReactCommon.MutationResult<DeleteInvestingModuleTopicMutation>;
export type DeleteInvestingModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvestingModuleTopicMutation, DeleteInvestingModuleTopicMutationVariables>;
export const CreateInvestingModuleDocument = gql`
    mutation createInvestingModule($input: CreateInvestingModuleInput!) {
  createInvestingModule(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type CreateInvestingModuleMutationFn = ApolloReactCommon.MutationFunction<CreateInvestingModuleMutation, CreateInvestingModuleMutationVariables>;

/**
 * __useCreateInvestingModuleMutation__
 *
 * To run a mutation, you first call `useCreateInvestingModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestingModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestingModuleMutation, { data, loading, error }] = useCreateInvestingModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvestingModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvestingModuleMutation, CreateInvestingModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvestingModuleMutation, CreateInvestingModuleMutationVariables>(CreateInvestingModuleDocument, baseOptions);
      }
export type CreateInvestingModuleMutationHookResult = ReturnType<typeof useCreateInvestingModuleMutation>;
export type CreateInvestingModuleMutationResult = ApolloReactCommon.MutationResult<CreateInvestingModuleMutation>;
export type CreateInvestingModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvestingModuleMutation, CreateInvestingModuleMutationVariables>;
export const UpdateInvestingModuleDocument = gql`
    mutation updateInvestingModule($input: UpdateInvestingModuleInput!) {
  updateInvestingModule(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type UpdateInvestingModuleMutationFn = ApolloReactCommon.MutationFunction<UpdateInvestingModuleMutation, UpdateInvestingModuleMutationVariables>;

/**
 * __useUpdateInvestingModuleMutation__
 *
 * To run a mutation, you first call `useUpdateInvestingModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestingModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestingModuleMutation, { data, loading, error }] = useUpdateInvestingModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestingModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvestingModuleMutation, UpdateInvestingModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvestingModuleMutation, UpdateInvestingModuleMutationVariables>(UpdateInvestingModuleDocument, baseOptions);
      }
export type UpdateInvestingModuleMutationHookResult = ReturnType<typeof useUpdateInvestingModuleMutation>;
export type UpdateInvestingModuleMutationResult = ApolloReactCommon.MutationResult<UpdateInvestingModuleMutation>;
export type UpdateInvestingModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvestingModuleMutation, UpdateInvestingModuleMutationVariables>;
export const DeleteInvestingModuleDocument = gql`
    mutation deleteInvestingModule($input: DeleteInvestingModuleInput!) {
  deleteInvestingModule(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type DeleteInvestingModuleMutationFn = ApolloReactCommon.MutationFunction<DeleteInvestingModuleMutation, DeleteInvestingModuleMutationVariables>;

/**
 * __useDeleteInvestingModuleMutation__
 *
 * To run a mutation, you first call `useDeleteInvestingModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestingModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestingModuleMutation, { data, loading, error }] = useDeleteInvestingModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvestingModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvestingModuleMutation, DeleteInvestingModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvestingModuleMutation, DeleteInvestingModuleMutationVariables>(DeleteInvestingModuleDocument, baseOptions);
      }
export type DeleteInvestingModuleMutationHookResult = ReturnType<typeof useDeleteInvestingModuleMutation>;
export type DeleteInvestingModuleMutationResult = ApolloReactCommon.MutationResult<DeleteInvestingModuleMutation>;
export type DeleteInvestingModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvestingModuleMutation, DeleteInvestingModuleMutationVariables>;
export const CreateInvestingModuleSectionDocument = gql`
    mutation createInvestingModuleSection($input: CreateInvestingModuleSectionInput!) {
  createInvestingModuleSection(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type CreateInvestingModuleSectionMutationFn = ApolloReactCommon.MutationFunction<CreateInvestingModuleSectionMutation, CreateInvestingModuleSectionMutationVariables>;

/**
 * __useCreateInvestingModuleSectionMutation__
 *
 * To run a mutation, you first call `useCreateInvestingModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestingModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestingModuleSectionMutation, { data, loading, error }] = useCreateInvestingModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvestingModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvestingModuleSectionMutation, CreateInvestingModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvestingModuleSectionMutation, CreateInvestingModuleSectionMutationVariables>(CreateInvestingModuleSectionDocument, baseOptions);
      }
export type CreateInvestingModuleSectionMutationHookResult = ReturnType<typeof useCreateInvestingModuleSectionMutation>;
export type CreateInvestingModuleSectionMutationResult = ApolloReactCommon.MutationResult<CreateInvestingModuleSectionMutation>;
export type CreateInvestingModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvestingModuleSectionMutation, CreateInvestingModuleSectionMutationVariables>;
export const UpdateInvestingModuleSectionDocument = gql`
    mutation updateInvestingModuleSection($input: UpdateInvestingModuleSectionInput!) {
  updateInvestingModuleSection(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type UpdateInvestingModuleSectionMutationFn = ApolloReactCommon.MutationFunction<UpdateInvestingModuleSectionMutation, UpdateInvestingModuleSectionMutationVariables>;

/**
 * __useUpdateInvestingModuleSectionMutation__
 *
 * To run a mutation, you first call `useUpdateInvestingModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestingModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestingModuleSectionMutation, { data, loading, error }] = useUpdateInvestingModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestingModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvestingModuleSectionMutation, UpdateInvestingModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvestingModuleSectionMutation, UpdateInvestingModuleSectionMutationVariables>(UpdateInvestingModuleSectionDocument, baseOptions);
      }
export type UpdateInvestingModuleSectionMutationHookResult = ReturnType<typeof useUpdateInvestingModuleSectionMutation>;
export type UpdateInvestingModuleSectionMutationResult = ApolloReactCommon.MutationResult<UpdateInvestingModuleSectionMutation>;
export type UpdateInvestingModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvestingModuleSectionMutation, UpdateInvestingModuleSectionMutationVariables>;
export const DeleteInvestingModuleSectionDocument = gql`
    mutation deleteInvestingModuleSection($input: DeleteInvestingModuleSectionInput!) {
  deleteInvestingModuleSection(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type DeleteInvestingModuleSectionMutationFn = ApolloReactCommon.MutationFunction<DeleteInvestingModuleSectionMutation, DeleteInvestingModuleSectionMutationVariables>;

/**
 * __useDeleteInvestingModuleSectionMutation__
 *
 * To run a mutation, you first call `useDeleteInvestingModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestingModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestingModuleSectionMutation, { data, loading, error }] = useDeleteInvestingModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvestingModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvestingModuleSectionMutation, DeleteInvestingModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvestingModuleSectionMutation, DeleteInvestingModuleSectionMutationVariables>(DeleteInvestingModuleSectionDocument, baseOptions);
      }
export type DeleteInvestingModuleSectionMutationHookResult = ReturnType<typeof useDeleteInvestingModuleSectionMutation>;
export type DeleteInvestingModuleSectionMutationResult = ApolloReactCommon.MutationResult<DeleteInvestingModuleSectionMutation>;
export type DeleteInvestingModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvestingModuleSectionMutation, DeleteInvestingModuleSectionMutationVariables>;
export const DeleteInvestingReferenceTextSetOptionDocument = gql`
    mutation deleteInvestingReferenceTextSetOption($input: DeleteInvestingReferenceTextSetOptionInput!) {
  deleteInvestingReferenceTextSetOption(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type DeleteInvestingReferenceTextSetOptionMutationFn = ApolloReactCommon.MutationFunction<DeleteInvestingReferenceTextSetOptionMutation, DeleteInvestingReferenceTextSetOptionMutationVariables>;

/**
 * __useDeleteInvestingReferenceTextSetOptionMutation__
 *
 * To run a mutation, you first call `useDeleteInvestingReferenceTextSetOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestingReferenceTextSetOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestingReferenceTextSetOptionMutation, { data, loading, error }] = useDeleteInvestingReferenceTextSetOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvestingReferenceTextSetOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvestingReferenceTextSetOptionMutation, DeleteInvestingReferenceTextSetOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvestingReferenceTextSetOptionMutation, DeleteInvestingReferenceTextSetOptionMutationVariables>(DeleteInvestingReferenceTextSetOptionDocument, baseOptions);
      }
export type DeleteInvestingReferenceTextSetOptionMutationHookResult = ReturnType<typeof useDeleteInvestingReferenceTextSetOptionMutation>;
export type DeleteInvestingReferenceTextSetOptionMutationResult = ApolloReactCommon.MutationResult<DeleteInvestingReferenceTextSetOptionMutation>;
export type DeleteInvestingReferenceTextSetOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvestingReferenceTextSetOptionMutation, DeleteInvestingReferenceTextSetOptionMutationVariables>;
export const CreateInvestingModuleSectionBulletDocument = gql`
    mutation createInvestingModuleSectionBullet($input: CreateInvestingModuleSectionBulletInput!) {
  createInvestingModuleSectionBullet(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type CreateInvestingModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<CreateInvestingModuleSectionBulletMutation, CreateInvestingModuleSectionBulletMutationVariables>;

/**
 * __useCreateInvestingModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useCreateInvestingModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestingModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestingModuleSectionBulletMutation, { data, loading, error }] = useCreateInvestingModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvestingModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvestingModuleSectionBulletMutation, CreateInvestingModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvestingModuleSectionBulletMutation, CreateInvestingModuleSectionBulletMutationVariables>(CreateInvestingModuleSectionBulletDocument, baseOptions);
      }
export type CreateInvestingModuleSectionBulletMutationHookResult = ReturnType<typeof useCreateInvestingModuleSectionBulletMutation>;
export type CreateInvestingModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<CreateInvestingModuleSectionBulletMutation>;
export type CreateInvestingModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvestingModuleSectionBulletMutation, CreateInvestingModuleSectionBulletMutationVariables>;
export const UpdateInvestingModuleSectionBulletDocument = gql`
    mutation updateInvestingModuleSectionBullet($input: UpdateInvestingModuleSectionBulletInput!) {
  updateInvestingModuleSectionBullet(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type UpdateInvestingModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<UpdateInvestingModuleSectionBulletMutation, UpdateInvestingModuleSectionBulletMutationVariables>;

/**
 * __useUpdateInvestingModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useUpdateInvestingModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestingModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestingModuleSectionBulletMutation, { data, loading, error }] = useUpdateInvestingModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestingModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvestingModuleSectionBulletMutation, UpdateInvestingModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvestingModuleSectionBulletMutation, UpdateInvestingModuleSectionBulletMutationVariables>(UpdateInvestingModuleSectionBulletDocument, baseOptions);
      }
export type UpdateInvestingModuleSectionBulletMutationHookResult = ReturnType<typeof useUpdateInvestingModuleSectionBulletMutation>;
export type UpdateInvestingModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<UpdateInvestingModuleSectionBulletMutation>;
export type UpdateInvestingModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvestingModuleSectionBulletMutation, UpdateInvestingModuleSectionBulletMutationVariables>;
export const DeleteInvestingModuleSectionBulletDocument = gql`
    mutation deleteInvestingModuleSectionBullet($input: DeleteInvestingModuleSectionBulletInput!) {
  deleteInvestingModuleSectionBullet(input: $input) {
    investingCourse {
      ...InvestingCourseFragment
    }
  }
}
    ${InvestingCourseFragmentFragmentDoc}`;
export type DeleteInvestingModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<DeleteInvestingModuleSectionBulletMutation, DeleteInvestingModuleSectionBulletMutationVariables>;

/**
 * __useDeleteInvestingModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useDeleteInvestingModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestingModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestingModuleSectionBulletMutation, { data, loading, error }] = useDeleteInvestingModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvestingModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvestingModuleSectionBulletMutation, DeleteInvestingModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvestingModuleSectionBulletMutation, DeleteInvestingModuleSectionBulletMutationVariables>(DeleteInvestingModuleSectionBulletDocument, baseOptions);
      }
export type DeleteInvestingModuleSectionBulletMutationHookResult = ReturnType<typeof useDeleteInvestingModuleSectionBulletMutation>;
export type DeleteInvestingModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<DeleteInvestingModuleSectionBulletMutation>;
export type DeleteInvestingModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvestingModuleSectionBulletMutation, DeleteInvestingModuleSectionBulletMutationVariables>;
export const LoadMathCourseByIdDocument = gql`
    query LoadMathCourseById($input: ObjectId!) {
  mathCourseById(id: $input) {
    ...MathCourse
  }
}
    ${MathCourseFragmentDoc}`;

/**
 * __useLoadMathCourseByIdQuery__
 *
 * To run a query within a React component, call `useLoadMathCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMathCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMathCourseByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadMathCourseByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadMathCourseByIdQuery, LoadMathCourseByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadMathCourseByIdQuery, LoadMathCourseByIdQueryVariables>(LoadMathCourseByIdDocument, baseOptions);
      }
export function useLoadMathCourseByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadMathCourseByIdQuery, LoadMathCourseByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadMathCourseByIdQuery, LoadMathCourseByIdQueryVariables>(LoadMathCourseByIdDocument, baseOptions);
        }
export type LoadMathCourseByIdQueryHookResult = ReturnType<typeof useLoadMathCourseByIdQuery>;
export type LoadMathCourseByIdLazyQueryHookResult = ReturnType<typeof useLoadMathCourseByIdLazyQuery>;
export type LoadMathCourseByIdQueryResult = ApolloReactCommon.QueryResult<LoadMathCourseByIdQuery, LoadMathCourseByIdQueryVariables>;
export const CountMathCoursesDocument = gql`
    query CountMathCourses {
  mathCoursesCount
}
    `;

/**
 * __useCountMathCoursesQuery__
 *
 * To run a query within a React component, call `useCountMathCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountMathCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMathCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountMathCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountMathCoursesQuery, CountMathCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountMathCoursesQuery, CountMathCoursesQueryVariables>(CountMathCoursesDocument, baseOptions);
      }
export function useCountMathCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountMathCoursesQuery, CountMathCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountMathCoursesQuery, CountMathCoursesQueryVariables>(CountMathCoursesDocument, baseOptions);
        }
export type CountMathCoursesQueryHookResult = ReturnType<typeof useCountMathCoursesQuery>;
export type CountMathCoursesLazyQueryHookResult = ReturnType<typeof useCountMathCoursesLazyQuery>;
export type CountMathCoursesQueryResult = ApolloReactCommon.QueryResult<CountMathCoursesQuery, CountMathCoursesQueryVariables>;
export const CreateMathCourseDocument = gql`
    mutation createMathCourse($input: CreateMathCourseInput!) {
  createMathCourse(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type CreateMathCourseMutationFn = ApolloReactCommon.MutationFunction<CreateMathCourseMutation, CreateMathCourseMutationVariables>;

/**
 * __useCreateMathCourseMutation__
 *
 * To run a mutation, you first call `useCreateMathCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMathCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMathCourseMutation, { data, loading, error }] = useCreateMathCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMathCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMathCourseMutation, CreateMathCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMathCourseMutation, CreateMathCourseMutationVariables>(CreateMathCourseDocument, baseOptions);
      }
export type CreateMathCourseMutationHookResult = ReturnType<typeof useCreateMathCourseMutation>;
export type CreateMathCourseMutationResult = ApolloReactCommon.MutationResult<CreateMathCourseMutation>;
export type CreateMathCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMathCourseMutation, CreateMathCourseMutationVariables>;
export const UpdateMathCourseDocument = gql`
    mutation updateMathCourse($input: UpdateMathCourseInput!) {
  updateMathCourse(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type UpdateMathCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateMathCourseMutation, UpdateMathCourseMutationVariables>;

/**
 * __useUpdateMathCourseMutation__
 *
 * To run a mutation, you first call `useUpdateMathCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMathCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMathCourseMutation, { data, loading, error }] = useUpdateMathCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMathCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMathCourseMutation, UpdateMathCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMathCourseMutation, UpdateMathCourseMutationVariables>(UpdateMathCourseDocument, baseOptions);
      }
export type UpdateMathCourseMutationHookResult = ReturnType<typeof useUpdateMathCourseMutation>;
export type UpdateMathCourseMutationResult = ApolloReactCommon.MutationResult<UpdateMathCourseMutation>;
export type UpdateMathCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMathCourseMutation, UpdateMathCourseMutationVariables>;
export const DeleteMathCourseDocument = gql`
    mutation deleteMathCourse($input: DeleteMathCourseInput!) {
  deleteMathCourse(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type DeleteMathCourseMutationFn = ApolloReactCommon.MutationFunction<DeleteMathCourseMutation, DeleteMathCourseMutationVariables>;

/**
 * __useDeleteMathCourseMutation__
 *
 * To run a mutation, you first call `useDeleteMathCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMathCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMathCourseMutation, { data, loading, error }] = useDeleteMathCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMathCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMathCourseMutation, DeleteMathCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMathCourseMutation, DeleteMathCourseMutationVariables>(DeleteMathCourseDocument, baseOptions);
      }
export type DeleteMathCourseMutationHookResult = ReturnType<typeof useDeleteMathCourseMutation>;
export type DeleteMathCourseMutationResult = ApolloReactCommon.MutationResult<DeleteMathCourseMutation>;
export type DeleteMathCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMathCourseMutation, DeleteMathCourseMutationVariables>;
export const CreateMathModuleTopicDocument = gql`
    mutation createMathModuleTopic($input: CreateMathModuleTopicInput!) {
  createMathModuleTopic(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type CreateMathModuleTopicMutationFn = ApolloReactCommon.MutationFunction<CreateMathModuleTopicMutation, CreateMathModuleTopicMutationVariables>;

/**
 * __useCreateMathModuleTopicMutation__
 *
 * To run a mutation, you first call `useCreateMathModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMathModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMathModuleTopicMutation, { data, loading, error }] = useCreateMathModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMathModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMathModuleTopicMutation, CreateMathModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMathModuleTopicMutation, CreateMathModuleTopicMutationVariables>(CreateMathModuleTopicDocument, baseOptions);
      }
export type CreateMathModuleTopicMutationHookResult = ReturnType<typeof useCreateMathModuleTopicMutation>;
export type CreateMathModuleTopicMutationResult = ApolloReactCommon.MutationResult<CreateMathModuleTopicMutation>;
export type CreateMathModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMathModuleTopicMutation, CreateMathModuleTopicMutationVariables>;
export const UpdateMathModuleTopicDocument = gql`
    mutation updateMathModuleTopic($input: UpdateMathModuleTopicInput!) {
  updateMathModuleTopic(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type UpdateMathModuleTopicMutationFn = ApolloReactCommon.MutationFunction<UpdateMathModuleTopicMutation, UpdateMathModuleTopicMutationVariables>;

/**
 * __useUpdateMathModuleTopicMutation__
 *
 * To run a mutation, you first call `useUpdateMathModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMathModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMathModuleTopicMutation, { data, loading, error }] = useUpdateMathModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMathModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMathModuleTopicMutation, UpdateMathModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMathModuleTopicMutation, UpdateMathModuleTopicMutationVariables>(UpdateMathModuleTopicDocument, baseOptions);
      }
export type UpdateMathModuleTopicMutationHookResult = ReturnType<typeof useUpdateMathModuleTopicMutation>;
export type UpdateMathModuleTopicMutationResult = ApolloReactCommon.MutationResult<UpdateMathModuleTopicMutation>;
export type UpdateMathModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMathModuleTopicMutation, UpdateMathModuleTopicMutationVariables>;
export const DeleteMathModuleTopicDocument = gql`
    mutation deleteMathModuleTopic($input: DeleteMathModuleTopicInput!) {
  deleteMathModuleTopic(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type DeleteMathModuleTopicMutationFn = ApolloReactCommon.MutationFunction<DeleteMathModuleTopicMutation, DeleteMathModuleTopicMutationVariables>;

/**
 * __useDeleteMathModuleTopicMutation__
 *
 * To run a mutation, you first call `useDeleteMathModuleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMathModuleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMathModuleTopicMutation, { data, loading, error }] = useDeleteMathModuleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMathModuleTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMathModuleTopicMutation, DeleteMathModuleTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMathModuleTopicMutation, DeleteMathModuleTopicMutationVariables>(DeleteMathModuleTopicDocument, baseOptions);
      }
export type DeleteMathModuleTopicMutationHookResult = ReturnType<typeof useDeleteMathModuleTopicMutation>;
export type DeleteMathModuleTopicMutationResult = ApolloReactCommon.MutationResult<DeleteMathModuleTopicMutation>;
export type DeleteMathModuleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMathModuleTopicMutation, DeleteMathModuleTopicMutationVariables>;
export const CreateMathModuleDocument = gql`
    mutation createMathModule($input: CreateMathModuleInput!) {
  createMathModule(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type CreateMathModuleMutationFn = ApolloReactCommon.MutationFunction<CreateMathModuleMutation, CreateMathModuleMutationVariables>;

/**
 * __useCreateMathModuleMutation__
 *
 * To run a mutation, you first call `useCreateMathModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMathModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMathModuleMutation, { data, loading, error }] = useCreateMathModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMathModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMathModuleMutation, CreateMathModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMathModuleMutation, CreateMathModuleMutationVariables>(CreateMathModuleDocument, baseOptions);
      }
export type CreateMathModuleMutationHookResult = ReturnType<typeof useCreateMathModuleMutation>;
export type CreateMathModuleMutationResult = ApolloReactCommon.MutationResult<CreateMathModuleMutation>;
export type CreateMathModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMathModuleMutation, CreateMathModuleMutationVariables>;
export const UpdateMathModuleDocument = gql`
    mutation updateMathModule($input: UpdateMathModuleInput!) {
  updateMathModule(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type UpdateMathModuleMutationFn = ApolloReactCommon.MutationFunction<UpdateMathModuleMutation, UpdateMathModuleMutationVariables>;

/**
 * __useUpdateMathModuleMutation__
 *
 * To run a mutation, you first call `useUpdateMathModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMathModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMathModuleMutation, { data, loading, error }] = useUpdateMathModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMathModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMathModuleMutation, UpdateMathModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMathModuleMutation, UpdateMathModuleMutationVariables>(UpdateMathModuleDocument, baseOptions);
      }
export type UpdateMathModuleMutationHookResult = ReturnType<typeof useUpdateMathModuleMutation>;
export type UpdateMathModuleMutationResult = ApolloReactCommon.MutationResult<UpdateMathModuleMutation>;
export type UpdateMathModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMathModuleMutation, UpdateMathModuleMutationVariables>;
export const DeleteMathModuleDocument = gql`
    mutation deleteMathModule($input: DeleteMathModuleInput!) {
  deleteMathModule(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type DeleteMathModuleMutationFn = ApolloReactCommon.MutationFunction<DeleteMathModuleMutation, DeleteMathModuleMutationVariables>;

/**
 * __useDeleteMathModuleMutation__
 *
 * To run a mutation, you first call `useDeleteMathModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMathModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMathModuleMutation, { data, loading, error }] = useDeleteMathModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMathModuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMathModuleMutation, DeleteMathModuleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMathModuleMutation, DeleteMathModuleMutationVariables>(DeleteMathModuleDocument, baseOptions);
      }
export type DeleteMathModuleMutationHookResult = ReturnType<typeof useDeleteMathModuleMutation>;
export type DeleteMathModuleMutationResult = ApolloReactCommon.MutationResult<DeleteMathModuleMutation>;
export type DeleteMathModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMathModuleMutation, DeleteMathModuleMutationVariables>;
export const CreateMathModuleSectionDocument = gql`
    mutation createMathModuleSection($input: CreateMathModuleSectionInput!) {
  createMathModuleSection(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type CreateMathModuleSectionMutationFn = ApolloReactCommon.MutationFunction<CreateMathModuleSectionMutation, CreateMathModuleSectionMutationVariables>;

/**
 * __useCreateMathModuleSectionMutation__
 *
 * To run a mutation, you first call `useCreateMathModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMathModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMathModuleSectionMutation, { data, loading, error }] = useCreateMathModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMathModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMathModuleSectionMutation, CreateMathModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMathModuleSectionMutation, CreateMathModuleSectionMutationVariables>(CreateMathModuleSectionDocument, baseOptions);
      }
export type CreateMathModuleSectionMutationHookResult = ReturnType<typeof useCreateMathModuleSectionMutation>;
export type CreateMathModuleSectionMutationResult = ApolloReactCommon.MutationResult<CreateMathModuleSectionMutation>;
export type CreateMathModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMathModuleSectionMutation, CreateMathModuleSectionMutationVariables>;
export const UpdateMathModuleSectionDocument = gql`
    mutation updateMathModuleSection($input: UpdateMathModuleSectionInput!) {
  updateMathModuleSection(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type UpdateMathModuleSectionMutationFn = ApolloReactCommon.MutationFunction<UpdateMathModuleSectionMutation, UpdateMathModuleSectionMutationVariables>;

/**
 * __useUpdateMathModuleSectionMutation__
 *
 * To run a mutation, you first call `useUpdateMathModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMathModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMathModuleSectionMutation, { data, loading, error }] = useUpdateMathModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMathModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMathModuleSectionMutation, UpdateMathModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMathModuleSectionMutation, UpdateMathModuleSectionMutationVariables>(UpdateMathModuleSectionDocument, baseOptions);
      }
export type UpdateMathModuleSectionMutationHookResult = ReturnType<typeof useUpdateMathModuleSectionMutation>;
export type UpdateMathModuleSectionMutationResult = ApolloReactCommon.MutationResult<UpdateMathModuleSectionMutation>;
export type UpdateMathModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMathModuleSectionMutation, UpdateMathModuleSectionMutationVariables>;
export const DeleteMathModuleSectionDocument = gql`
    mutation deleteMathModuleSection($input: DeleteMathModuleSectionInput!) {
  deleteMathModuleSection(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type DeleteMathModuleSectionMutationFn = ApolloReactCommon.MutationFunction<DeleteMathModuleSectionMutation, DeleteMathModuleSectionMutationVariables>;

/**
 * __useDeleteMathModuleSectionMutation__
 *
 * To run a mutation, you first call `useDeleteMathModuleSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMathModuleSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMathModuleSectionMutation, { data, loading, error }] = useDeleteMathModuleSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMathModuleSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMathModuleSectionMutation, DeleteMathModuleSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMathModuleSectionMutation, DeleteMathModuleSectionMutationVariables>(DeleteMathModuleSectionDocument, baseOptions);
      }
export type DeleteMathModuleSectionMutationHookResult = ReturnType<typeof useDeleteMathModuleSectionMutation>;
export type DeleteMathModuleSectionMutationResult = ApolloReactCommon.MutationResult<DeleteMathModuleSectionMutation>;
export type DeleteMathModuleSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMathModuleSectionMutation, DeleteMathModuleSectionMutationVariables>;
export const CreateMathModuleSectionBulletDocument = gql`
    mutation createMathModuleSectionBullet($input: CreateMathModuleSectionBulletInput!) {
  createMathModuleSectionBullet(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type CreateMathModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<CreateMathModuleSectionBulletMutation, CreateMathModuleSectionBulletMutationVariables>;

/**
 * __useCreateMathModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useCreateMathModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMathModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMathModuleSectionBulletMutation, { data, loading, error }] = useCreateMathModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMathModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMathModuleSectionBulletMutation, CreateMathModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMathModuleSectionBulletMutation, CreateMathModuleSectionBulletMutationVariables>(CreateMathModuleSectionBulletDocument, baseOptions);
      }
export type CreateMathModuleSectionBulletMutationHookResult = ReturnType<typeof useCreateMathModuleSectionBulletMutation>;
export type CreateMathModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<CreateMathModuleSectionBulletMutation>;
export type CreateMathModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMathModuleSectionBulletMutation, CreateMathModuleSectionBulletMutationVariables>;
export const UpdateMathModuleSectionBulletDocument = gql`
    mutation updateMathModuleSectionBullet($input: UpdateMathModuleSectionBulletInput!) {
  updateMathModuleSectionBullet(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type UpdateMathModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<UpdateMathModuleSectionBulletMutation, UpdateMathModuleSectionBulletMutationVariables>;

/**
 * __useUpdateMathModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useUpdateMathModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMathModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMathModuleSectionBulletMutation, { data, loading, error }] = useUpdateMathModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMathModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMathModuleSectionBulletMutation, UpdateMathModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMathModuleSectionBulletMutation, UpdateMathModuleSectionBulletMutationVariables>(UpdateMathModuleSectionBulletDocument, baseOptions);
      }
export type UpdateMathModuleSectionBulletMutationHookResult = ReturnType<typeof useUpdateMathModuleSectionBulletMutation>;
export type UpdateMathModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<UpdateMathModuleSectionBulletMutation>;
export type UpdateMathModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMathModuleSectionBulletMutation, UpdateMathModuleSectionBulletMutationVariables>;
export const DeleteMathModuleSectionBulletDocument = gql`
    mutation deleteMathModuleSectionBullet($input: DeleteMathModuleSectionBulletInput!) {
  deleteMathModuleSectionBullet(input: $input) {
    mathCourse {
      ...MathCourse
    }
  }
}
    ${MathCourseFragmentDoc}`;
export type DeleteMathModuleSectionBulletMutationFn = ApolloReactCommon.MutationFunction<DeleteMathModuleSectionBulletMutation, DeleteMathModuleSectionBulletMutationVariables>;

/**
 * __useDeleteMathModuleSectionBulletMutation__
 *
 * To run a mutation, you first call `useDeleteMathModuleSectionBulletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMathModuleSectionBulletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMathModuleSectionBulletMutation, { data, loading, error }] = useDeleteMathModuleSectionBulletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMathModuleSectionBulletMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMathModuleSectionBulletMutation, DeleteMathModuleSectionBulletMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMathModuleSectionBulletMutation, DeleteMathModuleSectionBulletMutationVariables>(DeleteMathModuleSectionBulletDocument, baseOptions);
      }
export type DeleteMathModuleSectionBulletMutationHookResult = ReturnType<typeof useDeleteMathModuleSectionBulletMutation>;
export type DeleteMathModuleSectionBulletMutationResult = ApolloReactCommon.MutationResult<DeleteMathModuleSectionBulletMutation>;
export type DeleteMathModuleSectionBulletMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMathModuleSectionBulletMutation, DeleteMathModuleSectionBulletMutationVariables>;
export const LoadPromptByIdDocument = gql`
    query LoadPromptById($input: ObjectId!) {
  promptById(id: $input) {
    ...PromptFragment
  }
}
    ${PromptFragmentFragmentDoc}`;

/**
 * __useLoadPromptByIdQuery__
 *
 * To run a query within a React component, call `useLoadPromptByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPromptByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPromptByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadPromptByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadPromptByIdQuery, LoadPromptByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadPromptByIdQuery, LoadPromptByIdQueryVariables>(LoadPromptByIdDocument, baseOptions);
      }
export function useLoadPromptByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadPromptByIdQuery, LoadPromptByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadPromptByIdQuery, LoadPromptByIdQueryVariables>(LoadPromptByIdDocument, baseOptions);
        }
export type LoadPromptByIdQueryHookResult = ReturnType<typeof useLoadPromptByIdQuery>;
export type LoadPromptByIdLazyQueryHookResult = ReturnType<typeof useLoadPromptByIdLazyQuery>;
export type LoadPromptByIdQueryResult = ApolloReactCommon.QueryResult<LoadPromptByIdQuery, LoadPromptByIdQueryVariables>;
export const CreatePromptDocument = gql`
    mutation createPrompt($input: CreatePromptInput!) {
  createPrompt(input: $input) {
    prompt {
      ...PromptFragment
    }
  }
}
    ${PromptFragmentFragmentDoc}`;
export type CreatePromptMutationFn = ApolloReactCommon.MutationFunction<CreatePromptMutation, CreatePromptMutationVariables>;

/**
 * __useCreatePromptMutation__
 *
 * To run a mutation, you first call `useCreatePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromptMutation, { data, loading, error }] = useCreatePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePromptMutation, CreatePromptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePromptMutation, CreatePromptMutationVariables>(CreatePromptDocument, baseOptions);
      }
export type CreatePromptMutationHookResult = ReturnType<typeof useCreatePromptMutation>;
export type CreatePromptMutationResult = ApolloReactCommon.MutationResult<CreatePromptMutation>;
export type CreatePromptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePromptMutation, CreatePromptMutationVariables>;
export const UpdatePromptDocument = gql`
    mutation updatePrompt($input: UpdatePromptInput!) {
  updatePrompt(input: $input) {
    prompt {
      ...PromptFragment
    }
  }
}
    ${PromptFragmentFragmentDoc}`;
export type UpdatePromptMutationFn = ApolloReactCommon.MutationFunction<UpdatePromptMutation, UpdatePromptMutationVariables>;

/**
 * __useUpdatePromptMutation__
 *
 * To run a mutation, you first call `useUpdatePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromptMutation, { data, loading, error }] = useUpdatePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePromptMutation, UpdatePromptMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePromptMutation, UpdatePromptMutationVariables>(UpdatePromptDocument, baseOptions);
      }
export type UpdatePromptMutationHookResult = ReturnType<typeof useUpdatePromptMutation>;
export type UpdatePromptMutationResult = ApolloReactCommon.MutationResult<UpdatePromptMutation>;
export type UpdatePromptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePromptMutation, UpdatePromptMutationVariables>;
export const DeletePromptDocument = gql`
    mutation deletePrompt($input: DeletePromptInput!) {
  deletePrompt(input: $input) {
    success
  }
}
    `;
export type DeletePromptMutationFn = ApolloReactCommon.MutationFunction<DeletePromptMutation, DeletePromptMutationVariables>;

/**
 * __useDeletePromptMutation__
 *
 * To run a mutation, you first call `useDeletePromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromptMutation, { data, loading, error }] = useDeletePromptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePromptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePromptMutation, DeletePromptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePromptMutation, DeletePromptMutationVariables>(DeletePromptDocument, baseOptions);
      }
export type DeletePromptMutationHookResult = ReturnType<typeof useDeletePromptMutation>;
export type DeletePromptMutationResult = ApolloReactCommon.MutationResult<DeletePromptMutation>;
export type DeletePromptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePromptMutation, DeletePromptMutationVariables>;
export const LoadReferenceTextsDocument = gql`
    query LoadReferenceTexts($subject: String) {
  referenceTexts(subject: $subject) {
    items {
      ...RefText
    }
  }
}
    ${RefTextFragmentDoc}`;

/**
 * __useLoadReferenceTextsQuery__
 *
 * To run a query within a React component, call `useLoadReferenceTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadReferenceTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadReferenceTextsQuery({
 *   variables: {
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useLoadReferenceTextsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadReferenceTextsQuery, LoadReferenceTextsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadReferenceTextsQuery, LoadReferenceTextsQueryVariables>(LoadReferenceTextsDocument, baseOptions);
      }
export function useLoadReferenceTextsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadReferenceTextsQuery, LoadReferenceTextsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadReferenceTextsQuery, LoadReferenceTextsQueryVariables>(LoadReferenceTextsDocument, baseOptions);
        }
export type LoadReferenceTextsQueryHookResult = ReturnType<typeof useLoadReferenceTextsQuery>;
export type LoadReferenceTextsLazyQueryHookResult = ReturnType<typeof useLoadReferenceTextsLazyQuery>;
export type LoadReferenceTextsQueryResult = ApolloReactCommon.QueryResult<LoadReferenceTextsQuery, LoadReferenceTextsQueryVariables>;
export const CreateReferenceTextDocument = gql`
    mutation createReferenceText($input: CreateReferenceTextInput!) {
  createReferenceText(input: $input) {
    referenceText {
      ...RefText
    }
  }
}
    ${RefTextFragmentDoc}`;
export type CreateReferenceTextMutationFn = ApolloReactCommon.MutationFunction<CreateReferenceTextMutation, CreateReferenceTextMutationVariables>;

/**
 * __useCreateReferenceTextMutation__
 *
 * To run a mutation, you first call `useCreateReferenceTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceTextMutation, { data, loading, error }] = useCreateReferenceTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferenceTextMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReferenceTextMutation, CreateReferenceTextMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReferenceTextMutation, CreateReferenceTextMutationVariables>(CreateReferenceTextDocument, baseOptions);
      }
export type CreateReferenceTextMutationHookResult = ReturnType<typeof useCreateReferenceTextMutation>;
export type CreateReferenceTextMutationResult = ApolloReactCommon.MutationResult<CreateReferenceTextMutation>;
export type CreateReferenceTextMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReferenceTextMutation, CreateReferenceTextMutationVariables>;
export const UpdateReferenceTextDocument = gql`
    mutation updateReferenceText($input: UpdateReferenceTextInput!) {
  updateReferenceText(input: $input) {
    referenceText {
      ...RefText
    }
  }
}
    ${RefTextFragmentDoc}`;
export type UpdateReferenceTextMutationFn = ApolloReactCommon.MutationFunction<UpdateReferenceTextMutation, UpdateReferenceTextMutationVariables>;

/**
 * __useUpdateReferenceTextMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceTextMutation, { data, loading, error }] = useUpdateReferenceTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferenceTextMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferenceTextMutation, UpdateReferenceTextMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReferenceTextMutation, UpdateReferenceTextMutationVariables>(UpdateReferenceTextDocument, baseOptions);
      }
export type UpdateReferenceTextMutationHookResult = ReturnType<typeof useUpdateReferenceTextMutation>;
export type UpdateReferenceTextMutationResult = ApolloReactCommon.MutationResult<UpdateReferenceTextMutation>;
export type UpdateReferenceTextMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferenceTextMutation, UpdateReferenceTextMutationVariables>;
export const LoadReferenceTextSetsDocument = gql`
    query LoadReferenceTextSets($subject: String) {
  referenceTextSets(subject: $subject) {
    items {
      ...TextSet
    }
  }
}
    ${TextSetFragmentDoc}`;

/**
 * __useLoadReferenceTextSetsQuery__
 *
 * To run a query within a React component, call `useLoadReferenceTextSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadReferenceTextSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadReferenceTextSetsQuery({
 *   variables: {
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useLoadReferenceTextSetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadReferenceTextSetsQuery, LoadReferenceTextSetsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadReferenceTextSetsQuery, LoadReferenceTextSetsQueryVariables>(LoadReferenceTextSetsDocument, baseOptions);
      }
export function useLoadReferenceTextSetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadReferenceTextSetsQuery, LoadReferenceTextSetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadReferenceTextSetsQuery, LoadReferenceTextSetsQueryVariables>(LoadReferenceTextSetsDocument, baseOptions);
        }
export type LoadReferenceTextSetsQueryHookResult = ReturnType<typeof useLoadReferenceTextSetsQuery>;
export type LoadReferenceTextSetsLazyQueryHookResult = ReturnType<typeof useLoadReferenceTextSetsLazyQuery>;
export type LoadReferenceTextSetsQueryResult = ApolloReactCommon.QueryResult<LoadReferenceTextSetsQuery, LoadReferenceTextSetsQueryVariables>;
export const LoadReferenceTextSetsByIdsDocument = gql`
    query LoadReferenceTextSetsByIds($input: [ObjectId!]!) {
  referenceTextSetsByIds(ids: $input) {
    items {
      ...TextSet
    }
  }
}
    ${TextSetFragmentDoc}`;

/**
 * __useLoadReferenceTextSetsByIdsQuery__
 *
 * To run a query within a React component, call `useLoadReferenceTextSetsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadReferenceTextSetsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadReferenceTextSetsByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadReferenceTextSetsByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadReferenceTextSetsByIdsQuery, LoadReferenceTextSetsByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadReferenceTextSetsByIdsQuery, LoadReferenceTextSetsByIdsQueryVariables>(LoadReferenceTextSetsByIdsDocument, baseOptions);
      }
export function useLoadReferenceTextSetsByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadReferenceTextSetsByIdsQuery, LoadReferenceTextSetsByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadReferenceTextSetsByIdsQuery, LoadReferenceTextSetsByIdsQueryVariables>(LoadReferenceTextSetsByIdsDocument, baseOptions);
        }
export type LoadReferenceTextSetsByIdsQueryHookResult = ReturnType<typeof useLoadReferenceTextSetsByIdsQuery>;
export type LoadReferenceTextSetsByIdsLazyQueryHookResult = ReturnType<typeof useLoadReferenceTextSetsByIdsLazyQuery>;
export type LoadReferenceTextSetsByIdsQueryResult = ApolloReactCommon.QueryResult<LoadReferenceTextSetsByIdsQuery, LoadReferenceTextSetsByIdsQueryVariables>;
export const CreateReferenceTextSetDocument = gql`
    mutation createReferenceTextSet($input: CreateReferenceTextSetInput!) {
  createReferenceTextSet(input: $input) {
    referenceTextSet {
      ...TextSet
    }
  }
}
    ${TextSetFragmentDoc}`;
export type CreateReferenceTextSetMutationFn = ApolloReactCommon.MutationFunction<CreateReferenceTextSetMutation, CreateReferenceTextSetMutationVariables>;

/**
 * __useCreateReferenceTextSetMutation__
 *
 * To run a mutation, you first call `useCreateReferenceTextSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceTextSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceTextSetMutation, { data, loading, error }] = useCreateReferenceTextSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferenceTextSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReferenceTextSetMutation, CreateReferenceTextSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReferenceTextSetMutation, CreateReferenceTextSetMutationVariables>(CreateReferenceTextSetDocument, baseOptions);
      }
export type CreateReferenceTextSetMutationHookResult = ReturnType<typeof useCreateReferenceTextSetMutation>;
export type CreateReferenceTextSetMutationResult = ApolloReactCommon.MutationResult<CreateReferenceTextSetMutation>;
export type CreateReferenceTextSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReferenceTextSetMutation, CreateReferenceTextSetMutationVariables>;
export const UpdateReferenceTextSetDocument = gql`
    mutation updateReferenceTextSet($input: UpdateReferenceTextSetInput!) {
  updateReferenceTextSet(input: $input) {
    referenceTextSet {
      ...TextSet
    }
  }
}
    ${TextSetFragmentDoc}`;
export type UpdateReferenceTextSetMutationFn = ApolloReactCommon.MutationFunction<UpdateReferenceTextSetMutation, UpdateReferenceTextSetMutationVariables>;

/**
 * __useUpdateReferenceTextSetMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceTextSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceTextSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceTextSetMutation, { data, loading, error }] = useUpdateReferenceTextSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferenceTextSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferenceTextSetMutation, UpdateReferenceTextSetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReferenceTextSetMutation, UpdateReferenceTextSetMutationVariables>(UpdateReferenceTextSetDocument, baseOptions);
      }
export type UpdateReferenceTextSetMutationHookResult = ReturnType<typeof useUpdateReferenceTextSetMutation>;
export type UpdateReferenceTextSetMutationResult = ApolloReactCommon.MutationResult<UpdateReferenceTextSetMutation>;
export type UpdateReferenceTextSetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferenceTextSetMutation, UpdateReferenceTextSetMutationVariables>;
export const GetSubjectsDocument = gql`
    query getSubjects {
  getSubjects {
    ...GetSubjectsReturn
  }
}
    ${GetSubjectsReturnFragmentDoc}`;

/**
 * __useGetSubjectsQuery__
 *
 * To run a query within a React component, call `useGetSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, baseOptions);
      }
export function useGetSubjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, baseOptions);
        }
export type GetSubjectsQueryHookResult = ReturnType<typeof useGetSubjectsQuery>;
export type GetSubjectsLazyQueryHookResult = ReturnType<typeof useGetSubjectsLazyQuery>;
export type GetSubjectsQueryResult = ApolloReactCommon.QueryResult<GetSubjectsQuery, GetSubjectsQueryVariables>;
export const CreateExperimentDocument = gql`
    mutation CreateExperiment($experiment: CreateExperimentInput!) {
  createExperiment(input: $experiment) {
    experiment {
      ...ExperimentDetails
    }
  }
}
    ${ExperimentDetailsFragmentDoc}`;
export type CreateExperimentMutationFn = ApolloReactCommon.MutationFunction<CreateExperimentMutation, CreateExperimentMutationVariables>;

/**
 * __useCreateExperimentMutation__
 *
 * To run a mutation, you first call `useCreateExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperimentMutation, { data, loading, error }] = useCreateExperimentMutation({
 *   variables: {
 *      experiment: // value for 'experiment'
 *   },
 * });
 */
export function useCreateExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExperimentMutation, CreateExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExperimentMutation, CreateExperimentMutationVariables>(CreateExperimentDocument, baseOptions);
      }
export type CreateExperimentMutationHookResult = ReturnType<typeof useCreateExperimentMutation>;
export type CreateExperimentMutationResult = ApolloReactCommon.MutationResult<CreateExperimentMutation>;
export type CreateExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExperimentMutation, CreateExperimentMutationVariables>;
export const QueryExperimentsDocument = gql`
    query QueryExperiments($key: String, $title: String, $target: [String!], $status: [String!], $participantType: [String!]) {
  queryExperiments(
    key: $key
    title: $title
    target: $target
    status: $status
    participantType: $participantType
  ) {
    items {
      _id
      key
      title
      status
      target
      participantType
      treatments {
        key
        isControl
      }
    }
  }
}
    `;

/**
 * __useQueryExperimentsQuery__
 *
 * To run a query within a React component, call `useQueryExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryExperimentsQuery({
 *   variables: {
 *      key: // value for 'key'
 *      title: // value for 'title'
 *      target: // value for 'target'
 *      status: // value for 'status'
 *      participantType: // value for 'participantType'
 *   },
 * });
 */
export function useQueryExperimentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueryExperimentsQuery, QueryExperimentsQueryVariables>) {
        return ApolloReactHooks.useQuery<QueryExperimentsQuery, QueryExperimentsQueryVariables>(QueryExperimentsDocument, baseOptions);
      }
export function useQueryExperimentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueryExperimentsQuery, QueryExperimentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueryExperimentsQuery, QueryExperimentsQueryVariables>(QueryExperimentsDocument, baseOptions);
        }
export type QueryExperimentsQueryHookResult = ReturnType<typeof useQueryExperimentsQuery>;
export type QueryExperimentsLazyQueryHookResult = ReturnType<typeof useQueryExperimentsLazyQuery>;
export type QueryExperimentsQueryResult = ApolloReactCommon.QueryResult<QueryExperimentsQuery, QueryExperimentsQueryVariables>;
export const GetExperimentDocument = gql`
    query GetExperiment($key: String!) {
  experiment(key: $key) {
    ...ExperimentDetails
  }
}
    ${ExperimentDetailsFragmentDoc}`;

/**
 * __useGetExperimentQuery__
 *
 * To run a query within a React component, call `useGetExperimentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperimentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperimentQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetExperimentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExperimentQuery, GetExperimentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExperimentQuery, GetExperimentQueryVariables>(GetExperimentDocument, baseOptions);
      }
export function useGetExperimentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExperimentQuery, GetExperimentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExperimentQuery, GetExperimentQueryVariables>(GetExperimentDocument, baseOptions);
        }
export type GetExperimentQueryHookResult = ReturnType<typeof useGetExperimentQuery>;
export type GetExperimentLazyQueryHookResult = ReturnType<typeof useGetExperimentLazyQuery>;
export type GetExperimentQueryResult = ApolloReactCommon.QueryResult<GetExperimentQuery, GetExperimentQueryVariables>;
export const UpdateExperimentDocument = gql`
    mutation UpdateExperiment($experiment: UpdateExperimentInput!) {
  updateExperiment(input: $experiment) {
    experiment {
      ...ExperimentDetails
    }
  }
}
    ${ExperimentDetailsFragmentDoc}`;
export type UpdateExperimentMutationFn = ApolloReactCommon.MutationFunction<UpdateExperimentMutation, UpdateExperimentMutationVariables>;

/**
 * __useUpdateExperimentMutation__
 *
 * To run a mutation, you first call `useUpdateExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperimentMutation, { data, loading, error }] = useUpdateExperimentMutation({
 *   variables: {
 *      experiment: // value for 'experiment'
 *   },
 * });
 */
export function useUpdateExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExperimentMutation, UpdateExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExperimentMutation, UpdateExperimentMutationVariables>(UpdateExperimentDocument, baseOptions);
      }
export type UpdateExperimentMutationHookResult = ReturnType<typeof useUpdateExperimentMutation>;
export type UpdateExperimentMutationResult = ApolloReactCommon.MutationResult<UpdateExperimentMutation>;
export type UpdateExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExperimentMutation, UpdateExperimentMutationVariables>;
export const AddTreatmentsToExperimentDocument = gql`
    mutation AddTreatmentsToExperiment($experiment: AddTreatmentsToExperimentInput!) {
  addTreatmentsToExperiment(input: $experiment) {
    experiment {
      ...ExperimentDetails
    }
  }
}
    ${ExperimentDetailsFragmentDoc}`;
export type AddTreatmentsToExperimentMutationFn = ApolloReactCommon.MutationFunction<AddTreatmentsToExperimentMutation, AddTreatmentsToExperimentMutationVariables>;

/**
 * __useAddTreatmentsToExperimentMutation__
 *
 * To run a mutation, you first call `useAddTreatmentsToExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTreatmentsToExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTreatmentsToExperimentMutation, { data, loading, error }] = useAddTreatmentsToExperimentMutation({
 *   variables: {
 *      experiment: // value for 'experiment'
 *   },
 * });
 */
export function useAddTreatmentsToExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTreatmentsToExperimentMutation, AddTreatmentsToExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTreatmentsToExperimentMutation, AddTreatmentsToExperimentMutationVariables>(AddTreatmentsToExperimentDocument, baseOptions);
      }
export type AddTreatmentsToExperimentMutationHookResult = ReturnType<typeof useAddTreatmentsToExperimentMutation>;
export type AddTreatmentsToExperimentMutationResult = ApolloReactCommon.MutationResult<AddTreatmentsToExperimentMutation>;
export type AddTreatmentsToExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTreatmentsToExperimentMutation, AddTreatmentsToExperimentMutationVariables>;
export const UpdateTreatmentsInExperimentDocument = gql`
    mutation UpdateTreatmentsInExperiment($experiment: UpdateTreatmentsInExperimentInput!) {
  updateTreatmentsInExperiment(input: $experiment) {
    experiment {
      ...ExperimentDetails
    }
  }
}
    ${ExperimentDetailsFragmentDoc}`;
export type UpdateTreatmentsInExperimentMutationFn = ApolloReactCommon.MutationFunction<UpdateTreatmentsInExperimentMutation, UpdateTreatmentsInExperimentMutationVariables>;

/**
 * __useUpdateTreatmentsInExperimentMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentsInExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentsInExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentsInExperimentMutation, { data, loading, error }] = useUpdateTreatmentsInExperimentMutation({
 *   variables: {
 *      experiment: // value for 'experiment'
 *   },
 * });
 */
export function useUpdateTreatmentsInExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTreatmentsInExperimentMutation, UpdateTreatmentsInExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTreatmentsInExperimentMutation, UpdateTreatmentsInExperimentMutationVariables>(UpdateTreatmentsInExperimentDocument, baseOptions);
      }
export type UpdateTreatmentsInExperimentMutationHookResult = ReturnType<typeof useUpdateTreatmentsInExperimentMutation>;
export type UpdateTreatmentsInExperimentMutationResult = ApolloReactCommon.MutationResult<UpdateTreatmentsInExperimentMutation>;
export type UpdateTreatmentsInExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTreatmentsInExperimentMutation, UpdateTreatmentsInExperimentMutationVariables>;
export const RemoveTreatmentsFromExperimentDocument = gql`
    mutation RemoveTreatmentsFromExperiment($experiment: RemoveTreatmentsFromExperimentInput!) {
  removeTreatmentsFromExperiment(input: $experiment) {
    experiment {
      ...ExperimentDetails
    }
  }
}
    ${ExperimentDetailsFragmentDoc}`;
export type RemoveTreatmentsFromExperimentMutationFn = ApolloReactCommon.MutationFunction<RemoveTreatmentsFromExperimentMutation, RemoveTreatmentsFromExperimentMutationVariables>;

/**
 * __useRemoveTreatmentsFromExperimentMutation__
 *
 * To run a mutation, you first call `useRemoveTreatmentsFromExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTreatmentsFromExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTreatmentsFromExperimentMutation, { data, loading, error }] = useRemoveTreatmentsFromExperimentMutation({
 *   variables: {
 *      experiment: // value for 'experiment'
 *   },
 * });
 */
export function useRemoveTreatmentsFromExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTreatmentsFromExperimentMutation, RemoveTreatmentsFromExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTreatmentsFromExperimentMutation, RemoveTreatmentsFromExperimentMutationVariables>(RemoveTreatmentsFromExperimentDocument, baseOptions);
      }
export type RemoveTreatmentsFromExperimentMutationHookResult = ReturnType<typeof useRemoveTreatmentsFromExperimentMutation>;
export type RemoveTreatmentsFromExperimentMutationResult = ApolloReactCommon.MutationResult<RemoveTreatmentsFromExperimentMutation>;
export type RemoveTreatmentsFromExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTreatmentsFromExperimentMutation, RemoveTreatmentsFromExperimentMutationVariables>;
export const LoadAcuityAppointmentNotificationDocument = gql`
    query LoadAcuityAppointmentNotification($appointmentId: Int!) {
  acuityAppointmentNotification(appointmentId: $appointmentId) {
    appointmentId
    notifyFirstClassShadow
    notifyStudentTransitionShadow
    notifySubstituteSession
  }
}
    `;

/**
 * __useLoadAcuityAppointmentNotificationQuery__
 *
 * To run a query within a React component, call `useLoadAcuityAppointmentNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAcuityAppointmentNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAcuityAppointmentNotificationQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useLoadAcuityAppointmentNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadAcuityAppointmentNotificationQuery, LoadAcuityAppointmentNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadAcuityAppointmentNotificationQuery, LoadAcuityAppointmentNotificationQueryVariables>(LoadAcuityAppointmentNotificationDocument, baseOptions);
      }
export function useLoadAcuityAppointmentNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadAcuityAppointmentNotificationQuery, LoadAcuityAppointmentNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadAcuityAppointmentNotificationQuery, LoadAcuityAppointmentNotificationQueryVariables>(LoadAcuityAppointmentNotificationDocument, baseOptions);
        }
export type LoadAcuityAppointmentNotificationQueryHookResult = ReturnType<typeof useLoadAcuityAppointmentNotificationQuery>;
export type LoadAcuityAppointmentNotificationLazyQueryHookResult = ReturnType<typeof useLoadAcuityAppointmentNotificationLazyQuery>;
export type LoadAcuityAppointmentNotificationQueryResult = ApolloReactCommon.QueryResult<LoadAcuityAppointmentNotificationQuery, LoadAcuityAppointmentNotificationQueryVariables>;
export const UpsertAcuityAppointmentNotificationDocument = gql`
    mutation UpsertAcuityAppointmentNotification($input: UpsertAcuityAppointmentNotificationInput!) {
  upsertAcuityAppointmentNotification(input: $input) {
    appointmentId
    notifyFirstClassShadow
    notifyStudentTransitionShadow
    notifySubstituteSession
  }
}
    `;
export type UpsertAcuityAppointmentNotificationMutationFn = ApolloReactCommon.MutationFunction<UpsertAcuityAppointmentNotificationMutation, UpsertAcuityAppointmentNotificationMutationVariables>;

/**
 * __useUpsertAcuityAppointmentNotificationMutation__
 *
 * To run a mutation, you first call `useUpsertAcuityAppointmentNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAcuityAppointmentNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAcuityAppointmentNotificationMutation, { data, loading, error }] = useUpsertAcuityAppointmentNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAcuityAppointmentNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertAcuityAppointmentNotificationMutation, UpsertAcuityAppointmentNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertAcuityAppointmentNotificationMutation, UpsertAcuityAppointmentNotificationMutationVariables>(UpsertAcuityAppointmentNotificationDocument, baseOptions);
      }
export type UpsertAcuityAppointmentNotificationMutationHookResult = ReturnType<typeof useUpsertAcuityAppointmentNotificationMutation>;
export type UpsertAcuityAppointmentNotificationMutationResult = ApolloReactCommon.MutationResult<UpsertAcuityAppointmentNotificationMutation>;
export type UpsertAcuityAppointmentNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertAcuityAppointmentNotificationMutation, UpsertAcuityAppointmentNotificationMutationVariables>;
export const CancelStudentAppointmentsDocument = gql`
    mutation CancelStudentAppointments($input: CancelStudentAppointmentsInput!) {
  cancelStudentAppointments(input: $input) {
    appointments {
      result
    }
  }
}
    `;
export type CancelStudentAppointmentsMutationFn = ApolloReactCommon.MutationFunction<CancelStudentAppointmentsMutation, CancelStudentAppointmentsMutationVariables>;

/**
 * __useCancelStudentAppointmentsMutation__
 *
 * To run a mutation, you first call `useCancelStudentAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStudentAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStudentAppointmentsMutation, { data, loading, error }] = useCancelStudentAppointmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelStudentAppointmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelStudentAppointmentsMutation, CancelStudentAppointmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelStudentAppointmentsMutation, CancelStudentAppointmentsMutationVariables>(CancelStudentAppointmentsDocument, baseOptions);
      }
export type CancelStudentAppointmentsMutationHookResult = ReturnType<typeof useCancelStudentAppointmentsMutation>;
export type CancelStudentAppointmentsMutationResult = ApolloReactCommon.MutationResult<CancelStudentAppointmentsMutation>;
export type CancelStudentAppointmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelStudentAppointmentsMutation, CancelStudentAppointmentsMutationVariables>;
export const ScheduleStudentAppointmentsDocument = gql`
    mutation ScheduleStudentAppointments($input: ScheduleStudentAppointmentsInput!) {
  scheduleStudentAppointments(input: $input) {
    appointments {
      result
    }
  }
}
    `;
export type ScheduleStudentAppointmentsMutationFn = ApolloReactCommon.MutationFunction<ScheduleStudentAppointmentsMutation, ScheduleStudentAppointmentsMutationVariables>;

/**
 * __useScheduleStudentAppointmentsMutation__
 *
 * To run a mutation, you first call `useScheduleStudentAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleStudentAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleStudentAppointmentsMutation, { data, loading, error }] = useScheduleStudentAppointmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleStudentAppointmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleStudentAppointmentsMutation, ScheduleStudentAppointmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleStudentAppointmentsMutation, ScheduleStudentAppointmentsMutationVariables>(ScheduleStudentAppointmentsDocument, baseOptions);
      }
export type ScheduleStudentAppointmentsMutationHookResult = ReturnType<typeof useScheduleStudentAppointmentsMutation>;
export type ScheduleStudentAppointmentsMutationResult = ApolloReactCommon.MutationResult<ScheduleStudentAppointmentsMutation>;
export type ScheduleStudentAppointmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleStudentAppointmentsMutation, ScheduleStudentAppointmentsMutationVariables>;
export const RescheduleStudentAppointmentsDocument = gql`
    mutation RescheduleStudentAppointments($input: RescheduleStudentAppointmentsInput!) {
  rescheduleStudentAppointments(input: $input) {
    appointments {
      result
    }
  }
}
    `;
export type RescheduleStudentAppointmentsMutationFn = ApolloReactCommon.MutationFunction<RescheduleStudentAppointmentsMutation, RescheduleStudentAppointmentsMutationVariables>;

/**
 * __useRescheduleStudentAppointmentsMutation__
 *
 * To run a mutation, you first call `useRescheduleStudentAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleStudentAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleStudentAppointmentsMutation, { data, loading, error }] = useRescheduleStudentAppointmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleStudentAppointmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RescheduleStudentAppointmentsMutation, RescheduleStudentAppointmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<RescheduleStudentAppointmentsMutation, RescheduleStudentAppointmentsMutationVariables>(RescheduleStudentAppointmentsDocument, baseOptions);
      }
export type RescheduleStudentAppointmentsMutationHookResult = ReturnType<typeof useRescheduleStudentAppointmentsMutation>;
export type RescheduleStudentAppointmentsMutationResult = ApolloReactCommon.MutationResult<RescheduleStudentAppointmentsMutation>;
export type RescheduleStudentAppointmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<RescheduleStudentAppointmentsMutation, RescheduleStudentAppointmentsMutationVariables>;
export const GetStudentProjectByIdDocument = gql`
    query GetStudentProjectById($id: ObjectId!) {
  studentProjectById(id: $id) {
    ...StudentProjectRow
  }
}
    ${StudentProjectRowFragmentDoc}`;

/**
 * __useGetStudentProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetStudentProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudentProjectByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentProjectByIdQuery, GetStudentProjectByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentProjectByIdQuery, GetStudentProjectByIdQueryVariables>(GetStudentProjectByIdDocument, baseOptions);
      }
export function useGetStudentProjectByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentProjectByIdQuery, GetStudentProjectByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentProjectByIdQuery, GetStudentProjectByIdQueryVariables>(GetStudentProjectByIdDocument, baseOptions);
        }
export type GetStudentProjectByIdQueryHookResult = ReturnType<typeof useGetStudentProjectByIdQuery>;
export type GetStudentProjectByIdLazyQueryHookResult = ReturnType<typeof useGetStudentProjectByIdLazyQuery>;
export type GetStudentProjectByIdQueryResult = ApolloReactCommon.QueryResult<GetStudentProjectByIdQuery, GetStudentProjectByIdQueryVariables>;
export const GetStudentProjectsDocument = gql`
    query GetStudentProjects($query: StudentProjectQuery, $first: Int, $after: String) {
  studentProjects(query: $query, first: $first, after: $after) {
    items {
      ...StudentProjectRow
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${StudentProjectRowFragmentDoc}`;

/**
 * __useGetStudentProjectsQuery__
 *
 * To run a query within a React component, call `useGetStudentProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentProjectsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetStudentProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentProjectsQuery, GetStudentProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentProjectsQuery, GetStudentProjectsQueryVariables>(GetStudentProjectsDocument, baseOptions);
      }
export function useGetStudentProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentProjectsQuery, GetStudentProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentProjectsQuery, GetStudentProjectsQueryVariables>(GetStudentProjectsDocument, baseOptions);
        }
export type GetStudentProjectsQueryHookResult = ReturnType<typeof useGetStudentProjectsQuery>;
export type GetStudentProjectsLazyQueryHookResult = ReturnType<typeof useGetStudentProjectsLazyQuery>;
export type GetStudentProjectsQueryResult = ApolloReactCommon.QueryResult<GetStudentProjectsQuery, GetStudentProjectsQueryVariables>;
export const FeatureStudentProjectDocument = gql`
    mutation FeatureStudentProject($originalProjectId: ObjectId!, $newData: StudentProjectUpdateData!) {
  featureStudentProject(
    input: {originalProjectId: $originalProjectId, newData: $newData}
  ) {
    featuredProject {
      ...FeaturedStudentProjectDetails
      originalProject {
        _id
        featuredProjectId
        featuredProject {
          _id
        }
      }
    }
  }
}
    ${FeaturedStudentProjectDetailsFragmentDoc}`;
export type FeatureStudentProjectMutationFn = ApolloReactCommon.MutationFunction<FeatureStudentProjectMutation, FeatureStudentProjectMutationVariables>;

/**
 * __useFeatureStudentProjectMutation__
 *
 * To run a mutation, you first call `useFeatureStudentProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureStudentProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureStudentProjectMutation, { data, loading, error }] = useFeatureStudentProjectMutation({
 *   variables: {
 *      originalProjectId: // value for 'originalProjectId'
 *      newData: // value for 'newData'
 *   },
 * });
 */
export function useFeatureStudentProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FeatureStudentProjectMutation, FeatureStudentProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<FeatureStudentProjectMutation, FeatureStudentProjectMutationVariables>(FeatureStudentProjectDocument, baseOptions);
      }
export type FeatureStudentProjectMutationHookResult = ReturnType<typeof useFeatureStudentProjectMutation>;
export type FeatureStudentProjectMutationResult = ApolloReactCommon.MutationResult<FeatureStudentProjectMutation>;
export type FeatureStudentProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<FeatureStudentProjectMutation, FeatureStudentProjectMutationVariables>;
export const UpdateFeaturedStudentProjectDocument = gql`
    mutation UpdateFeaturedStudentProject($id: ObjectId!, $data: StudentProjectUpdateData!) {
  updateStudentProject(input: {id: $id, data: $data}) {
    project {
      ...FeaturedStudentProjectDetails
    }
  }
}
    ${FeaturedStudentProjectDetailsFragmentDoc}`;
export type UpdateFeaturedStudentProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateFeaturedStudentProjectMutation, UpdateFeaturedStudentProjectMutationVariables>;

/**
 * __useUpdateFeaturedStudentProjectMutation__
 *
 * To run a mutation, you first call `useUpdateFeaturedStudentProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeaturedStudentProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeaturedStudentProjectMutation, { data, loading, error }] = useUpdateFeaturedStudentProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFeaturedStudentProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFeaturedStudentProjectMutation, UpdateFeaturedStudentProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFeaturedStudentProjectMutation, UpdateFeaturedStudentProjectMutationVariables>(UpdateFeaturedStudentProjectDocument, baseOptions);
      }
export type UpdateFeaturedStudentProjectMutationHookResult = ReturnType<typeof useUpdateFeaturedStudentProjectMutation>;
export type UpdateFeaturedStudentProjectMutationResult = ApolloReactCommon.MutationResult<UpdateFeaturedStudentProjectMutation>;
export type UpdateFeaturedStudentProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFeaturedStudentProjectMutation, UpdateFeaturedStudentProjectMutationVariables>;
export const UnfeatureStudentProjectDocument = gql`
    mutation UnfeatureStudentProject($originalProjectId: ObjectId!) {
  unfeatureStudentProject(input: {originalProjectId: $originalProjectId}) {
    originalProject {
      ...StudentProjectRow
    }
  }
}
    ${StudentProjectRowFragmentDoc}`;
export type UnfeatureStudentProjectMutationFn = ApolloReactCommon.MutationFunction<UnfeatureStudentProjectMutation, UnfeatureStudentProjectMutationVariables>;

/**
 * __useUnfeatureStudentProjectMutation__
 *
 * To run a mutation, you first call `useUnfeatureStudentProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfeatureStudentProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfeatureStudentProjectMutation, { data, loading, error }] = useUnfeatureStudentProjectMutation({
 *   variables: {
 *      originalProjectId: // value for 'originalProjectId'
 *   },
 * });
 */
export function useUnfeatureStudentProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnfeatureStudentProjectMutation, UnfeatureStudentProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UnfeatureStudentProjectMutation, UnfeatureStudentProjectMutationVariables>(UnfeatureStudentProjectDocument, baseOptions);
      }
export type UnfeatureStudentProjectMutationHookResult = ReturnType<typeof useUnfeatureStudentProjectMutation>;
export type UnfeatureStudentProjectMutationResult = ApolloReactCommon.MutationResult<UnfeatureStudentProjectMutation>;
export type UnfeatureStudentProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UnfeatureStudentProjectMutation, UnfeatureStudentProjectMutationVariables>;
export const PublishFeaturedProjectsToJuniverseDocument = gql`
    mutation PublishFeaturedProjectsToJuniverse($input: PublishFeaturedProjectsToJuniverseInput!) {
  publishFeaturedProjectsToJuniverse(input: $input) {
    success
  }
}
    `;
export type PublishFeaturedProjectsToJuniverseMutationFn = ApolloReactCommon.MutationFunction<PublishFeaturedProjectsToJuniverseMutation, PublishFeaturedProjectsToJuniverseMutationVariables>;

/**
 * __usePublishFeaturedProjectsToJuniverseMutation__
 *
 * To run a mutation, you first call `usePublishFeaturedProjectsToJuniverseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishFeaturedProjectsToJuniverseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishFeaturedProjectsToJuniverseMutation, { data, loading, error }] = usePublishFeaturedProjectsToJuniverseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishFeaturedProjectsToJuniverseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishFeaturedProjectsToJuniverseMutation, PublishFeaturedProjectsToJuniverseMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishFeaturedProjectsToJuniverseMutation, PublishFeaturedProjectsToJuniverseMutationVariables>(PublishFeaturedProjectsToJuniverseDocument, baseOptions);
      }
export type PublishFeaturedProjectsToJuniverseMutationHookResult = ReturnType<typeof usePublishFeaturedProjectsToJuniverseMutation>;
export type PublishFeaturedProjectsToJuniverseMutationResult = ApolloReactCommon.MutationResult<PublishFeaturedProjectsToJuniverseMutation>;
export type PublishFeaturedProjectsToJuniverseMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishFeaturedProjectsToJuniverseMutation, PublishFeaturedProjectsToJuniverseMutationVariables>;
export const JuniAdsAndCampaignsDocument = gql`
    query juniAdsAndCampaigns {
  juniAdsAndCampaigns {
    items {
      _id
      campaignName
      startDate
      endDate
      priority
      maxViews
      cooldown {
        duration
        timeUnit
      }
      archivedDate
      juniAdIds
      juniAds {
        juniAdProperty
        title
        version
        imageS3Url
        copy
        primaryAction {
          label
          clickthroughUrl
        }
        secondaryAction {
          label
          clickthroughUrl
        }
      }
    }
  }
}
    `;

/**
 * __useJuniAdsAndCampaignsQuery__
 *
 * To run a query within a React component, call `useJuniAdsAndCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJuniAdsAndCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJuniAdsAndCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJuniAdsAndCampaignsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JuniAdsAndCampaignsQuery, JuniAdsAndCampaignsQueryVariables>) {
        return ApolloReactHooks.useQuery<JuniAdsAndCampaignsQuery, JuniAdsAndCampaignsQueryVariables>(JuniAdsAndCampaignsDocument, baseOptions);
      }
export function useJuniAdsAndCampaignsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JuniAdsAndCampaignsQuery, JuniAdsAndCampaignsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JuniAdsAndCampaignsQuery, JuniAdsAndCampaignsQueryVariables>(JuniAdsAndCampaignsDocument, baseOptions);
        }
export type JuniAdsAndCampaignsQueryHookResult = ReturnType<typeof useJuniAdsAndCampaignsQuery>;
export type JuniAdsAndCampaignsLazyQueryHookResult = ReturnType<typeof useJuniAdsAndCampaignsLazyQuery>;
export type JuniAdsAndCampaignsQueryResult = ApolloReactCommon.QueryResult<JuniAdsAndCampaignsQuery, JuniAdsAndCampaignsQueryVariables>;
export const UpsertJuniAdAndCampaignDocument = gql`
    mutation upsertJuniAdAndCampaign($input: UpsertJuniAdAndCampaignInput!) {
  upsertJuniAdAndCampaign(input: $input) {
    juniAdCampaign {
      _id
    }
  }
}
    `;
export type UpsertJuniAdAndCampaignMutationFn = ApolloReactCommon.MutationFunction<UpsertJuniAdAndCampaignMutation, UpsertJuniAdAndCampaignMutationVariables>;

/**
 * __useUpsertJuniAdAndCampaignMutation__
 *
 * To run a mutation, you first call `useUpsertJuniAdAndCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJuniAdAndCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJuniAdAndCampaignMutation, { data, loading, error }] = useUpsertJuniAdAndCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertJuniAdAndCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertJuniAdAndCampaignMutation, UpsertJuniAdAndCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertJuniAdAndCampaignMutation, UpsertJuniAdAndCampaignMutationVariables>(UpsertJuniAdAndCampaignDocument, baseOptions);
      }
export type UpsertJuniAdAndCampaignMutationHookResult = ReturnType<typeof useUpsertJuniAdAndCampaignMutation>;
export type UpsertJuniAdAndCampaignMutationResult = ApolloReactCommon.MutationResult<UpsertJuniAdAndCampaignMutation>;
export type UpsertJuniAdAndCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertJuniAdAndCampaignMutation, UpsertJuniAdAndCampaignMutationVariables>;
export const ArchiveJuniAdCampaignDocument = gql`
    mutation archiveJuniAdCampaign($input: ArchiveJuniAdCampaignInput!) {
  archiveJuniAdCampaign(input: $input) {
    success
  }
}
    `;
export type ArchiveJuniAdCampaignMutationFn = ApolloReactCommon.MutationFunction<ArchiveJuniAdCampaignMutation, ArchiveJuniAdCampaignMutationVariables>;

/**
 * __useArchiveJuniAdCampaignMutation__
 *
 * To run a mutation, you first call `useArchiveJuniAdCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveJuniAdCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveJuniAdCampaignMutation, { data, loading, error }] = useArchiveJuniAdCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveJuniAdCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveJuniAdCampaignMutation, ArchiveJuniAdCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveJuniAdCampaignMutation, ArchiveJuniAdCampaignMutationVariables>(ArchiveJuniAdCampaignDocument, baseOptions);
      }
export type ArchiveJuniAdCampaignMutationHookResult = ReturnType<typeof useArchiveJuniAdCampaignMutation>;
export type ArchiveJuniAdCampaignMutationResult = ApolloReactCommon.MutationResult<ArchiveJuniAdCampaignMutation>;
export type ArchiveJuniAdCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveJuniAdCampaignMutation, ArchiveJuniAdCampaignMutationVariables>;
export const NotesFromHqDocument = gql`
    query NotesFromHQ {
  notesFromHQ {
    items {
      _id
      title
      text
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useNotesFromHqQuery__
 *
 * To run a query within a React component, call `useNotesFromHqQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesFromHqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesFromHqQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotesFromHqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotesFromHqQuery, NotesFromHqQueryVariables>) {
        return ApolloReactHooks.useQuery<NotesFromHqQuery, NotesFromHqQueryVariables>(NotesFromHqDocument, baseOptions);
      }
export function useNotesFromHqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotesFromHqQuery, NotesFromHqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotesFromHqQuery, NotesFromHqQueryVariables>(NotesFromHqDocument, baseOptions);
        }
export type NotesFromHqQueryHookResult = ReturnType<typeof useNotesFromHqQuery>;
export type NotesFromHqLazyQueryHookResult = ReturnType<typeof useNotesFromHqLazyQuery>;
export type NotesFromHqQueryResult = ApolloReactCommon.QueryResult<NotesFromHqQuery, NotesFromHqQueryVariables>;
export const UpsertNotesFromHqDocument = gql`
    mutation UpsertNotesFromHQ($input: UpsertNotesFromHQInput!) {
  upsertNotesFromHQ(input: $input) {
    success
  }
}
    `;
export type UpsertNotesFromHqMutationFn = ApolloReactCommon.MutationFunction<UpsertNotesFromHqMutation, UpsertNotesFromHqMutationVariables>;

/**
 * __useUpsertNotesFromHqMutation__
 *
 * To run a mutation, you first call `useUpsertNotesFromHqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotesFromHqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotesFromHqMutation, { data, loading, error }] = useUpsertNotesFromHqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertNotesFromHqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertNotesFromHqMutation, UpsertNotesFromHqMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertNotesFromHqMutation, UpsertNotesFromHqMutationVariables>(UpsertNotesFromHqDocument, baseOptions);
      }
export type UpsertNotesFromHqMutationHookResult = ReturnType<typeof useUpsertNotesFromHqMutation>;
export type UpsertNotesFromHqMutationResult = ApolloReactCommon.MutationResult<UpsertNotesFromHqMutation>;
export type UpsertNotesFromHqMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertNotesFromHqMutation, UpsertNotesFromHqMutationVariables>;
export const DeleteNoteFromHqDocument = gql`
    mutation DeleteNoteFromHQ($input: DeleteNoteFromHQInput!) {
  deleteNoteFromHQ(input: $input) {
    success
  }
}
    `;
export type DeleteNoteFromHqMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteFromHqMutation, DeleteNoteFromHqMutationVariables>;

/**
 * __useDeleteNoteFromHqMutation__
 *
 * To run a mutation, you first call `useDeleteNoteFromHqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteFromHqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteFromHqMutation, { data, loading, error }] = useDeleteNoteFromHqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoteFromHqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteFromHqMutation, DeleteNoteFromHqMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteFromHqMutation, DeleteNoteFromHqMutationVariables>(DeleteNoteFromHqDocument, baseOptions);
      }
export type DeleteNoteFromHqMutationHookResult = ReturnType<typeof useDeleteNoteFromHqMutation>;
export type DeleteNoteFromHqMutationResult = ApolloReactCommon.MutationResult<DeleteNoteFromHqMutation>;
export type DeleteNoteFromHqMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteFromHqMutation, DeleteNoteFromHqMutationVariables>;
export const ParentCornerLiveAnnouncementsDocument = gql`
    query parentCornerLiveAnnouncements {
  parentCornerLiveAnnouncements {
    items {
      _id
      title
      body
      order
      callsToAction {
        _id
        label
        url
      }
    }
  }
}
    `;

/**
 * __useParentCornerLiveAnnouncementsQuery__
 *
 * To run a query within a React component, call `useParentCornerLiveAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentCornerLiveAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentCornerLiveAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentCornerLiveAnnouncementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParentCornerLiveAnnouncementsQuery, ParentCornerLiveAnnouncementsQueryVariables>) {
        return ApolloReactHooks.useQuery<ParentCornerLiveAnnouncementsQuery, ParentCornerLiveAnnouncementsQueryVariables>(ParentCornerLiveAnnouncementsDocument, baseOptions);
      }
export function useParentCornerLiveAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParentCornerLiveAnnouncementsQuery, ParentCornerLiveAnnouncementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParentCornerLiveAnnouncementsQuery, ParentCornerLiveAnnouncementsQueryVariables>(ParentCornerLiveAnnouncementsDocument, baseOptions);
        }
export type ParentCornerLiveAnnouncementsQueryHookResult = ReturnType<typeof useParentCornerLiveAnnouncementsQuery>;
export type ParentCornerLiveAnnouncementsLazyQueryHookResult = ReturnType<typeof useParentCornerLiveAnnouncementsLazyQuery>;
export type ParentCornerLiveAnnouncementsQueryResult = ApolloReactCommon.QueryResult<ParentCornerLiveAnnouncementsQuery, ParentCornerLiveAnnouncementsQueryVariables>;
export const UpdateParentCornerAnnouncementsDocument = gql`
    mutation UpdateParentCornerAnnouncements($input: UpdateParentCornerAnnouncementsInput!) {
  updateParentCornerAnnouncements(input: $input) {
    success
  }
}
    `;
export type UpdateParentCornerAnnouncementsMutationFn = ApolloReactCommon.MutationFunction<UpdateParentCornerAnnouncementsMutation, UpdateParentCornerAnnouncementsMutationVariables>;

/**
 * __useUpdateParentCornerAnnouncementsMutation__
 *
 * To run a mutation, you first call `useUpdateParentCornerAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParentCornerAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParentCornerAnnouncementsMutation, { data, loading, error }] = useUpdateParentCornerAnnouncementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParentCornerAnnouncementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateParentCornerAnnouncementsMutation, UpdateParentCornerAnnouncementsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateParentCornerAnnouncementsMutation, UpdateParentCornerAnnouncementsMutationVariables>(UpdateParentCornerAnnouncementsDocument, baseOptions);
      }
export type UpdateParentCornerAnnouncementsMutationHookResult = ReturnType<typeof useUpdateParentCornerAnnouncementsMutation>;
export type UpdateParentCornerAnnouncementsMutationResult = ApolloReactCommon.MutationResult<UpdateParentCornerAnnouncementsMutation>;
export type UpdateParentCornerAnnouncementsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateParentCornerAnnouncementsMutation, UpdateParentCornerAnnouncementsMutationVariables>;
export const GetOpenChallengesDocument = gql`
    query GetOpenChallenges {
  openChallenges {
    items {
      ...OpenChallenge
    }
  }
}
    ${OpenChallengeFragmentDoc}`;

/**
 * __useGetOpenChallengesQuery__
 *
 * To run a query within a React component, call `useGetOpenChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenChallengesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenChallengesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpenChallengesQuery, GetOpenChallengesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpenChallengesQuery, GetOpenChallengesQueryVariables>(GetOpenChallengesDocument, baseOptions);
      }
export function useGetOpenChallengesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpenChallengesQuery, GetOpenChallengesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpenChallengesQuery, GetOpenChallengesQueryVariables>(GetOpenChallengesDocument, baseOptions);
        }
export type GetOpenChallengesQueryHookResult = ReturnType<typeof useGetOpenChallengesQuery>;
export type GetOpenChallengesLazyQueryHookResult = ReturnType<typeof useGetOpenChallengesLazyQuery>;
export type GetOpenChallengesQueryResult = ApolloReactCommon.QueryResult<GetOpenChallengesQuery, GetOpenChallengesQueryVariables>;
export const GetOpenChallengeByIdDocument = gql`
    query GetOpenChallengeById($id: ObjectId!) {
  openChallengeById(id: $id) {
    ...OpenChallenge
  }
}
    ${OpenChallengeFragmentDoc}`;

/**
 * __useGetOpenChallengeByIdQuery__
 *
 * To run a query within a React component, call `useGetOpenChallengeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenChallengeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenChallengeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOpenChallengeByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpenChallengeByIdQuery, GetOpenChallengeByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpenChallengeByIdQuery, GetOpenChallengeByIdQueryVariables>(GetOpenChallengeByIdDocument, baseOptions);
      }
export function useGetOpenChallengeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpenChallengeByIdQuery, GetOpenChallengeByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpenChallengeByIdQuery, GetOpenChallengeByIdQueryVariables>(GetOpenChallengeByIdDocument, baseOptions);
        }
export type GetOpenChallengeByIdQueryHookResult = ReturnType<typeof useGetOpenChallengeByIdQuery>;
export type GetOpenChallengeByIdLazyQueryHookResult = ReturnType<typeof useGetOpenChallengeByIdLazyQuery>;
export type GetOpenChallengeByIdQueryResult = ApolloReactCommon.QueryResult<GetOpenChallengeByIdQuery, GetOpenChallengeByIdQueryVariables>;
export const CreateOpenChallengeDocument = gql`
    mutation CreateOpenChallenge($input: CreateOpenChallengeInput!) {
  createOpenChallenge(input: $input) {
    openChallenge {
      ...OpenChallenge
    }
  }
}
    ${OpenChallengeFragmentDoc}`;
export type CreateOpenChallengeMutationFn = ApolloReactCommon.MutationFunction<CreateOpenChallengeMutation, CreateOpenChallengeMutationVariables>;

/**
 * __useCreateOpenChallengeMutation__
 *
 * To run a mutation, you first call `useCreateOpenChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenChallengeMutation, { data, loading, error }] = useCreateOpenChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpenChallengeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOpenChallengeMutation, CreateOpenChallengeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOpenChallengeMutation, CreateOpenChallengeMutationVariables>(CreateOpenChallengeDocument, baseOptions);
      }
export type CreateOpenChallengeMutationHookResult = ReturnType<typeof useCreateOpenChallengeMutation>;
export type CreateOpenChallengeMutationResult = ApolloReactCommon.MutationResult<CreateOpenChallengeMutation>;
export type CreateOpenChallengeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOpenChallengeMutation, CreateOpenChallengeMutationVariables>;
export const UpdateOpenChallengeDocument = gql`
    mutation UpdateOpenChallenge($input: UpdateOpenChallengeInput!) {
  updateOpenChallenge(input: $input) {
    openChallenge {
      ...OpenChallenge
    }
  }
}
    ${OpenChallengeFragmentDoc}`;
export type UpdateOpenChallengeMutationFn = ApolloReactCommon.MutationFunction<UpdateOpenChallengeMutation, UpdateOpenChallengeMutationVariables>;

/**
 * __useUpdateOpenChallengeMutation__
 *
 * To run a mutation, you first call `useUpdateOpenChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpenChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpenChallengeMutation, { data, loading, error }] = useUpdateOpenChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpenChallengeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOpenChallengeMutation, UpdateOpenChallengeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOpenChallengeMutation, UpdateOpenChallengeMutationVariables>(UpdateOpenChallengeDocument, baseOptions);
      }
export type UpdateOpenChallengeMutationHookResult = ReturnType<typeof useUpdateOpenChallengeMutation>;
export type UpdateOpenChallengeMutationResult = ApolloReactCommon.MutationResult<UpdateOpenChallengeMutation>;
export type UpdateOpenChallengeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOpenChallengeMutation, UpdateOpenChallengeMutationVariables>;
export const DeleteOpenChallengeDocument = gql`
    mutation DeleteOpenChallenge($input: DeleteOpenChallengeInput!) {
  deleteOpenChallenge(input: $input) {
    success
  }
}
    `;
export type DeleteOpenChallengeMutationFn = ApolloReactCommon.MutationFunction<DeleteOpenChallengeMutation, DeleteOpenChallengeMutationVariables>;

/**
 * __useDeleteOpenChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteOpenChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpenChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpenChallengeMutation, { data, loading, error }] = useDeleteOpenChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOpenChallengeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOpenChallengeMutation, DeleteOpenChallengeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOpenChallengeMutation, DeleteOpenChallengeMutationVariables>(DeleteOpenChallengeDocument, baseOptions);
      }
export type DeleteOpenChallengeMutationHookResult = ReturnType<typeof useDeleteOpenChallengeMutation>;
export type DeleteOpenChallengeMutationResult = ApolloReactCommon.MutationResult<DeleteOpenChallengeMutation>;
export type DeleteOpenChallengeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOpenChallengeMutation, DeleteOpenChallengeMutationVariables>;
export const GetUnsentDashboardSessionInfosDocument = gql`
    query GetUnsentDashboardSessionInfos {
  unsentDashboardSessionInfos {
    items {
      ...DashboardSessionInfoFragment
    }
  }
}
    ${DashboardSessionInfoFragmentFragmentDoc}`;

/**
 * __useGetUnsentDashboardSessionInfosQuery__
 *
 * To run a query within a React component, call `useGetUnsentDashboardSessionInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsentDashboardSessionInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsentDashboardSessionInfosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnsentDashboardSessionInfosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnsentDashboardSessionInfosQuery, GetUnsentDashboardSessionInfosQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnsentDashboardSessionInfosQuery, GetUnsentDashboardSessionInfosQueryVariables>(GetUnsentDashboardSessionInfosDocument, baseOptions);
      }
export function useGetUnsentDashboardSessionInfosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnsentDashboardSessionInfosQuery, GetUnsentDashboardSessionInfosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnsentDashboardSessionInfosQuery, GetUnsentDashboardSessionInfosQueryVariables>(GetUnsentDashboardSessionInfosDocument, baseOptions);
        }
export type GetUnsentDashboardSessionInfosQueryHookResult = ReturnType<typeof useGetUnsentDashboardSessionInfosQuery>;
export type GetUnsentDashboardSessionInfosLazyQueryHookResult = ReturnType<typeof useGetUnsentDashboardSessionInfosLazyQuery>;
export type GetUnsentDashboardSessionInfosQueryResult = ApolloReactCommon.QueryResult<GetUnsentDashboardSessionInfosQuery, GetUnsentDashboardSessionInfosQueryVariables>;
export const UpdateTeacherSessionForPostSessionEmailDocument = gql`
    mutation UpdateTeacherSessionForPostSessionEmail($input: UpdateTeacherSessionForPostSessionEmailInput!) {
  updateTeacherSessionForPostSessionEmail(input: $input) {
    dashboardSessionInfo {
      ...DashboardSessionInfoFragment
    }
  }
}
    ${DashboardSessionInfoFragmentFragmentDoc}`;
export type UpdateTeacherSessionForPostSessionEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateTeacherSessionForPostSessionEmailMutation, UpdateTeacherSessionForPostSessionEmailMutationVariables>;

/**
 * __useUpdateTeacherSessionForPostSessionEmailMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherSessionForPostSessionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherSessionForPostSessionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherSessionForPostSessionEmailMutation, { data, loading, error }] = useUpdateTeacherSessionForPostSessionEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherSessionForPostSessionEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeacherSessionForPostSessionEmailMutation, UpdateTeacherSessionForPostSessionEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeacherSessionForPostSessionEmailMutation, UpdateTeacherSessionForPostSessionEmailMutationVariables>(UpdateTeacherSessionForPostSessionEmailDocument, baseOptions);
      }
export type UpdateTeacherSessionForPostSessionEmailMutationHookResult = ReturnType<typeof useUpdateTeacherSessionForPostSessionEmailMutation>;
export type UpdateTeacherSessionForPostSessionEmailMutationResult = ApolloReactCommon.MutationResult<UpdateTeacherSessionForPostSessionEmailMutation>;
export type UpdateTeacherSessionForPostSessionEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeacherSessionForPostSessionEmailMutation, UpdateTeacherSessionForPostSessionEmailMutationVariables>;
export const SendPostSessionEmailDocument = gql`
    mutation SendPostSessionEmail($input: SendPostSessionEmailInput!) {
  sendPostSessionEmail(input: $input) {
    dashboardSessionInfo {
      ...DashboardSessionInfoFragment
    }
  }
}
    ${DashboardSessionInfoFragmentFragmentDoc}`;
export type SendPostSessionEmailMutationFn = ApolloReactCommon.MutationFunction<SendPostSessionEmailMutation, SendPostSessionEmailMutationVariables>;

/**
 * __useSendPostSessionEmailMutation__
 *
 * To run a mutation, you first call `useSendPostSessionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPostSessionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPostSessionEmailMutation, { data, loading, error }] = useSendPostSessionEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPostSessionEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendPostSessionEmailMutation, SendPostSessionEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendPostSessionEmailMutation, SendPostSessionEmailMutationVariables>(SendPostSessionEmailDocument, baseOptions);
      }
export type SendPostSessionEmailMutationHookResult = ReturnType<typeof useSendPostSessionEmailMutation>;
export type SendPostSessionEmailMutationResult = ApolloReactCommon.MutationResult<SendPostSessionEmailMutation>;
export type SendPostSessionEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendPostSessionEmailMutation, SendPostSessionEmailMutationVariables>;
export const GetPostSessionDashboardEmailHtmlDocument = gql`
    mutation GetPostSessionDashboardEmailHtml($input: GetPostSessionDashboardEmailHtmlInput!) {
  getPostSessionDashboardEmailHtml(input: $input) {
    dashboardPreviewEmail {
      html
    }
  }
}
    `;
export type GetPostSessionDashboardEmailHtmlMutationFn = ApolloReactCommon.MutationFunction<GetPostSessionDashboardEmailHtmlMutation, GetPostSessionDashboardEmailHtmlMutationVariables>;

/**
 * __useGetPostSessionDashboardEmailHtmlMutation__
 *
 * To run a mutation, you first call `useGetPostSessionDashboardEmailHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPostSessionDashboardEmailHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPostSessionDashboardEmailHtmlMutation, { data, loading, error }] = useGetPostSessionDashboardEmailHtmlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPostSessionDashboardEmailHtmlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetPostSessionDashboardEmailHtmlMutation, GetPostSessionDashboardEmailHtmlMutationVariables>) {
        return ApolloReactHooks.useMutation<GetPostSessionDashboardEmailHtmlMutation, GetPostSessionDashboardEmailHtmlMutationVariables>(GetPostSessionDashboardEmailHtmlDocument, baseOptions);
      }
export type GetPostSessionDashboardEmailHtmlMutationHookResult = ReturnType<typeof useGetPostSessionDashboardEmailHtmlMutation>;
export type GetPostSessionDashboardEmailHtmlMutationResult = ApolloReactCommon.MutationResult<GetPostSessionDashboardEmailHtmlMutation>;
export type GetPostSessionDashboardEmailHtmlMutationOptions = ApolloReactCommon.BaseMutationOptions<GetPostSessionDashboardEmailHtmlMutation, GetPostSessionDashboardEmailHtmlMutationVariables>;
export const CompileHandoffEmailDocument = gql`
    query CompileHandoffEmail($templateValues: HandoffEmailTemplateValues!, $parentEmail: String) {
  compileHandoffEmail(templateValues: $templateValues, parentEmail: $parentEmail)
}
    `;

/**
 * __useCompileHandoffEmailQuery__
 *
 * To run a query within a React component, call `useCompileHandoffEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompileHandoffEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompileHandoffEmailQuery({
 *   variables: {
 *      templateValues: // value for 'templateValues'
 *      parentEmail: // value for 'parentEmail'
 *   },
 * });
 */
export function useCompileHandoffEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompileHandoffEmailQuery, CompileHandoffEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompileHandoffEmailQuery, CompileHandoffEmailQueryVariables>(CompileHandoffEmailDocument, baseOptions);
      }
export function useCompileHandoffEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompileHandoffEmailQuery, CompileHandoffEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompileHandoffEmailQuery, CompileHandoffEmailQueryVariables>(CompileHandoffEmailDocument, baseOptions);
        }
export type CompileHandoffEmailQueryHookResult = ReturnType<typeof useCompileHandoffEmailQuery>;
export type CompileHandoffEmailLazyQueryHookResult = ReturnType<typeof useCompileHandoffEmailLazyQuery>;
export type CompileHandoffEmailQueryResult = ApolloReactCommon.QueryResult<CompileHandoffEmailQuery, CompileHandoffEmailQueryVariables>;
export const SendHandoffEmailDocument = gql`
    mutation SendHandoffEmail($input: SendHandoffEmailInput!) {
  sendHandoffEmail(input: $input) {
    success
  }
}
    `;
export type SendHandoffEmailMutationFn = ApolloReactCommon.MutationFunction<SendHandoffEmailMutation, SendHandoffEmailMutationVariables>;

/**
 * __useSendHandoffEmailMutation__
 *
 * To run a mutation, you first call `useSendHandoffEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHandoffEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHandoffEmailMutation, { data, loading, error }] = useSendHandoffEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendHandoffEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendHandoffEmailMutation, SendHandoffEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendHandoffEmailMutation, SendHandoffEmailMutationVariables>(SendHandoffEmailDocument, baseOptions);
      }
export type SendHandoffEmailMutationHookResult = ReturnType<typeof useSendHandoffEmailMutation>;
export type SendHandoffEmailMutationResult = ApolloReactCommon.MutationResult<SendHandoffEmailMutation>;
export type SendHandoffEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendHandoffEmailMutation, SendHandoffEmailMutationVariables>;
export const SendHandoffStudentProposalsDocument = gql`
    mutation SendHandoffStudentProposals($handoffId: ObjectId) {
  sendHandoffStudentProposals(input: {handoffId: $handoffId}) {
    studentHandoff {
      _id
      status
      potentialInstructors {
        _id
        instructorId
        status
        isNewInstructor
        needsGroupTraining
      }
    }
  }
}
    `;
export type SendHandoffStudentProposalsMutationFn = ApolloReactCommon.MutationFunction<SendHandoffStudentProposalsMutation, SendHandoffStudentProposalsMutationVariables>;

/**
 * __useSendHandoffStudentProposalsMutation__
 *
 * To run a mutation, you first call `useSendHandoffStudentProposalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHandoffStudentProposalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHandoffStudentProposalsMutation, { data, loading, error }] = useSendHandoffStudentProposalsMutation({
 *   variables: {
 *      handoffId: // value for 'handoffId'
 *   },
 * });
 */
export function useSendHandoffStudentProposalsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendHandoffStudentProposalsMutation, SendHandoffStudentProposalsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendHandoffStudentProposalsMutation, SendHandoffStudentProposalsMutationVariables>(SendHandoffStudentProposalsDocument, baseOptions);
      }
export type SendHandoffStudentProposalsMutationHookResult = ReturnType<typeof useSendHandoffStudentProposalsMutation>;
export type SendHandoffStudentProposalsMutationResult = ApolloReactCommon.MutationResult<SendHandoffStudentProposalsMutation>;
export type SendHandoffStudentProposalsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendHandoffStudentProposalsMutation, SendHandoffStudentProposalsMutationVariables>;
export const CompileOnboardingEmailDocument = gql`
    query CompileOnboardingEmail($templateValues: OnboardingEmailTemplateValues!, $origin: String!, $courseNames: [String!]) {
  compileOnboardingEmail(
    templateValues: $templateValues
    origin: $origin
    courseNames: $courseNames
  )
}
    `;

/**
 * __useCompileOnboardingEmailQuery__
 *
 * To run a query within a React component, call `useCompileOnboardingEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompileOnboardingEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompileOnboardingEmailQuery({
 *   variables: {
 *      templateValues: // value for 'templateValues'
 *      origin: // value for 'origin'
 *      courseNames: // value for 'courseNames'
 *   },
 * });
 */
export function useCompileOnboardingEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompileOnboardingEmailQuery, CompileOnboardingEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompileOnboardingEmailQuery, CompileOnboardingEmailQueryVariables>(CompileOnboardingEmailDocument, baseOptions);
      }
export function useCompileOnboardingEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompileOnboardingEmailQuery, CompileOnboardingEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompileOnboardingEmailQuery, CompileOnboardingEmailQueryVariables>(CompileOnboardingEmailDocument, baseOptions);
        }
export type CompileOnboardingEmailQueryHookResult = ReturnType<typeof useCompileOnboardingEmailQuery>;
export type CompileOnboardingEmailLazyQueryHookResult = ReturnType<typeof useCompileOnboardingEmailLazyQuery>;
export type CompileOnboardingEmailQueryResult = ApolloReactCommon.QueryResult<CompileOnboardingEmailQuery, CompileOnboardingEmailQueryVariables>;
export const SendOnboardingEmailDocument = gql`
    mutation SendOnboardingEmail($input: SendOnboardingEmailInput!) {
  sendOnboardingEmail(input: $input) {
    success
  }
}
    `;
export type SendOnboardingEmailMutationFn = ApolloReactCommon.MutationFunction<SendOnboardingEmailMutation, SendOnboardingEmailMutationVariables>;

/**
 * __useSendOnboardingEmailMutation__
 *
 * To run a mutation, you first call `useSendOnboardingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOnboardingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOnboardingEmailMutation, { data, loading, error }] = useSendOnboardingEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOnboardingEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendOnboardingEmailMutation, SendOnboardingEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendOnboardingEmailMutation, SendOnboardingEmailMutationVariables>(SendOnboardingEmailDocument, baseOptions);
      }
export type SendOnboardingEmailMutationHookResult = ReturnType<typeof useSendOnboardingEmailMutation>;
export type SendOnboardingEmailMutationResult = ApolloReactCommon.MutationResult<SendOnboardingEmailMutation>;
export type SendOnboardingEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendOnboardingEmailMutation, SendOnboardingEmailMutationVariables>;
export const SendOnboardingStudentProposalsDocument = gql`
    mutation SendOnboardingStudentProposals($onboardingId: ObjectId) {
  sendOnboardingStudentProposals(input: {onboardingId: $onboardingId}) {
    studentOnboarding {
      _id
      status
      potentialInstructors {
        _id
        instructorId
        status
        isNewInstructor
        needsGroupTraining
      }
    }
  }
}
    `;
export type SendOnboardingStudentProposalsMutationFn = ApolloReactCommon.MutationFunction<SendOnboardingStudentProposalsMutation, SendOnboardingStudentProposalsMutationVariables>;

/**
 * __useSendOnboardingStudentProposalsMutation__
 *
 * To run a mutation, you first call `useSendOnboardingStudentProposalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOnboardingStudentProposalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOnboardingStudentProposalsMutation, { data, loading, error }] = useSendOnboardingStudentProposalsMutation({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *   },
 * });
 */
export function useSendOnboardingStudentProposalsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendOnboardingStudentProposalsMutation, SendOnboardingStudentProposalsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendOnboardingStudentProposalsMutation, SendOnboardingStudentProposalsMutationVariables>(SendOnboardingStudentProposalsDocument, baseOptions);
      }
export type SendOnboardingStudentProposalsMutationHookResult = ReturnType<typeof useSendOnboardingStudentProposalsMutation>;
export type SendOnboardingStudentProposalsMutationResult = ApolloReactCommon.MutationResult<SendOnboardingStudentProposalsMutation>;
export type SendOnboardingStudentProposalsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendOnboardingStudentProposalsMutation, SendOnboardingStudentProposalsMutationVariables>;
export const GetStudentProposalsByTicketIdDocument = gql`
    query GetStudentProposalsByTicketId($onboardingId: ObjectId, $handoffId: ObjectId) {
  studentProposals(
    studentOnboardingId: $onboardingId
    studentHandoffId: $handoffId
  ) {
    items {
      _id
      sentAt
      createdAt
      status
      scheduledSendDate
      instructor {
        _id
        acuityCalendarId
      }
    }
  }
}
    `;

/**
 * __useGetStudentProposalsByTicketIdQuery__
 *
 * To run a query within a React component, call `useGetStudentProposalsByTicketIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentProposalsByTicketIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentProposalsByTicketIdQuery({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *      handoffId: // value for 'handoffId'
 *   },
 * });
 */
export function useGetStudentProposalsByTicketIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentProposalsByTicketIdQuery, GetStudentProposalsByTicketIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentProposalsByTicketIdQuery, GetStudentProposalsByTicketIdQueryVariables>(GetStudentProposalsByTicketIdDocument, baseOptions);
      }
export function useGetStudentProposalsByTicketIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentProposalsByTicketIdQuery, GetStudentProposalsByTicketIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentProposalsByTicketIdQuery, GetStudentProposalsByTicketIdQueryVariables>(GetStudentProposalsByTicketIdDocument, baseOptions);
        }
export type GetStudentProposalsByTicketIdQueryHookResult = ReturnType<typeof useGetStudentProposalsByTicketIdQuery>;
export type GetStudentProposalsByTicketIdLazyQueryHookResult = ReturnType<typeof useGetStudentProposalsByTicketIdLazyQuery>;
export type GetStudentProposalsByTicketIdQueryResult = ApolloReactCommon.QueryResult<GetStudentProposalsByTicketIdQuery, GetStudentProposalsByTicketIdQueryVariables>;
export const CreateInstructorAchievementDocument = gql`
    mutation CreateInstructorAchievement($input: CreateInstructorAchievementInput!) {
  createInstructorAchievement(input: $input) {
    instructorAchievement {
      ...InstructorAchievement
    }
  }
}
    ${InstructorAchievementFragmentDoc}`;
export type CreateInstructorAchievementMutationFn = ApolloReactCommon.MutationFunction<CreateInstructorAchievementMutation, CreateInstructorAchievementMutationVariables>;

/**
 * __useCreateInstructorAchievementMutation__
 *
 * To run a mutation, you first call `useCreateInstructorAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstructorAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstructorAchievementMutation, { data, loading, error }] = useCreateInstructorAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstructorAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInstructorAchievementMutation, CreateInstructorAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInstructorAchievementMutation, CreateInstructorAchievementMutationVariables>(CreateInstructorAchievementDocument, baseOptions);
      }
export type CreateInstructorAchievementMutationHookResult = ReturnType<typeof useCreateInstructorAchievementMutation>;
export type CreateInstructorAchievementMutationResult = ApolloReactCommon.MutationResult<CreateInstructorAchievementMutation>;
export type CreateInstructorAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInstructorAchievementMutation, CreateInstructorAchievementMutationVariables>;
export const UpdateInstructorAchievementDocument = gql`
    mutation UpdateInstructorAchievement($input: UpdateInstructorAchievementInput!) {
  updateInstructorAchievement(input: $input) {
    instructorAchievement {
      ...InstructorAchievement
    }
  }
}
    ${InstructorAchievementFragmentDoc}`;
export type UpdateInstructorAchievementMutationFn = ApolloReactCommon.MutationFunction<UpdateInstructorAchievementMutation, UpdateInstructorAchievementMutationVariables>;

/**
 * __useUpdateInstructorAchievementMutation__
 *
 * To run a mutation, you first call `useUpdateInstructorAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructorAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructorAchievementMutation, { data, loading, error }] = useUpdateInstructorAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstructorAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInstructorAchievementMutation, UpdateInstructorAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInstructorAchievementMutation, UpdateInstructorAchievementMutationVariables>(UpdateInstructorAchievementDocument, baseOptions);
      }
export type UpdateInstructorAchievementMutationHookResult = ReturnType<typeof useUpdateInstructorAchievementMutation>;
export type UpdateInstructorAchievementMutationResult = ApolloReactCommon.MutationResult<UpdateInstructorAchievementMutation>;
export type UpdateInstructorAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInstructorAchievementMutation, UpdateInstructorAchievementMutationVariables>;
export const DeleteInstructorAchievementDocument = gql`
    mutation DeleteInstructorAchievement($input: DeleteInstructorAchievementInput!) {
  deleteInstructorAchievement(input: $input) {
    instructorAchievement {
      ...InstructorAchievement
    }
    success
  }
}
    ${InstructorAchievementFragmentDoc}`;
export type DeleteInstructorAchievementMutationFn = ApolloReactCommon.MutationFunction<DeleteInstructorAchievementMutation, DeleteInstructorAchievementMutationVariables>;

/**
 * __useDeleteInstructorAchievementMutation__
 *
 * To run a mutation, you first call `useDeleteInstructorAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstructorAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstructorAchievementMutation, { data, loading, error }] = useDeleteInstructorAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInstructorAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInstructorAchievementMutation, DeleteInstructorAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInstructorAchievementMutation, DeleteInstructorAchievementMutationVariables>(DeleteInstructorAchievementDocument, baseOptions);
      }
export type DeleteInstructorAchievementMutationHookResult = ReturnType<typeof useDeleteInstructorAchievementMutation>;
export type DeleteInstructorAchievementMutationResult = ApolloReactCommon.MutationResult<DeleteInstructorAchievementMutation>;
export type DeleteInstructorAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInstructorAchievementMutation, DeleteInstructorAchievementMutationVariables>;
export const GrantInstructorAchievementDocument = gql`
    mutation GrantInstructorAchievement($input: GrantInstructorAchievementInput!) {
  grantInstructorAchievement(input: $input) {
    instructor {
      ...Instructor
    }
    success
  }
}
    ${InstructorFragmentDoc}`;
export type GrantInstructorAchievementMutationFn = ApolloReactCommon.MutationFunction<GrantInstructorAchievementMutation, GrantInstructorAchievementMutationVariables>;

/**
 * __useGrantInstructorAchievementMutation__
 *
 * To run a mutation, you first call `useGrantInstructorAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantInstructorAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantInstructorAchievementMutation, { data, loading, error }] = useGrantInstructorAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantInstructorAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GrantInstructorAchievementMutation, GrantInstructorAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<GrantInstructorAchievementMutation, GrantInstructorAchievementMutationVariables>(GrantInstructorAchievementDocument, baseOptions);
      }
export type GrantInstructorAchievementMutationHookResult = ReturnType<typeof useGrantInstructorAchievementMutation>;
export type GrantInstructorAchievementMutationResult = ApolloReactCommon.MutationResult<GrantInstructorAchievementMutation>;
export type GrantInstructorAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<GrantInstructorAchievementMutation, GrantInstructorAchievementMutationVariables>;
export const GetInstructorAchievementsDocument = gql`
    query GetInstructorAchievements {
  instructorAchievements {
    items {
      ...InstructorAchievement
    }
  }
}
    ${InstructorAchievementFragmentDoc}`;

/**
 * __useGetInstructorAchievementsQuery__
 *
 * To run a query within a React component, call `useGetInstructorAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorAchievementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInstructorAchievementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorAchievementsQuery, GetInstructorAchievementsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorAchievementsQuery, GetInstructorAchievementsQueryVariables>(GetInstructorAchievementsDocument, baseOptions);
      }
export function useGetInstructorAchievementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorAchievementsQuery, GetInstructorAchievementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorAchievementsQuery, GetInstructorAchievementsQueryVariables>(GetInstructorAchievementsDocument, baseOptions);
        }
export type GetInstructorAchievementsQueryHookResult = ReturnType<typeof useGetInstructorAchievementsQuery>;
export type GetInstructorAchievementsLazyQueryHookResult = ReturnType<typeof useGetInstructorAchievementsLazyQuery>;
export type GetInstructorAchievementsQueryResult = ApolloReactCommon.QueryResult<GetInstructorAchievementsQuery, GetInstructorAchievementsQueryVariables>;
export const GetInstructorAchievementByIdDocument = gql`
    query GetInstructorAchievementById($id: ObjectId!) {
  instructorAchievementById(id: $id) {
    ...InstructorAchievement
  }
}
    ${InstructorAchievementFragmentDoc}`;

/**
 * __useGetInstructorAchievementByIdQuery__
 *
 * To run a query within a React component, call `useGetInstructorAchievementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorAchievementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorAchievementByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstructorAchievementByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorAchievementByIdQuery, GetInstructorAchievementByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorAchievementByIdQuery, GetInstructorAchievementByIdQueryVariables>(GetInstructorAchievementByIdDocument, baseOptions);
      }
export function useGetInstructorAchievementByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorAchievementByIdQuery, GetInstructorAchievementByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorAchievementByIdQuery, GetInstructorAchievementByIdQueryVariables>(GetInstructorAchievementByIdDocument, baseOptions);
        }
export type GetInstructorAchievementByIdQueryHookResult = ReturnType<typeof useGetInstructorAchievementByIdQuery>;
export type GetInstructorAchievementByIdLazyQueryHookResult = ReturnType<typeof useGetInstructorAchievementByIdLazyQuery>;
export type GetInstructorAchievementByIdQueryResult = ApolloReactCommon.QueryResult<GetInstructorAchievementByIdQuery, GetInstructorAchievementByIdQueryVariables>;
export const GetInstructorAchievementsByInstructorIdDocument = gql`
    query GetInstructorAchievementsByInstructorId($id: ObjectId!) {
  instructorAchievementsByInstructorId(id: $id) {
    items {
      ...InstructorAchievement
    }
  }
}
    ${InstructorAchievementFragmentDoc}`;

/**
 * __useGetInstructorAchievementsByInstructorIdQuery__
 *
 * To run a query within a React component, call `useGetInstructorAchievementsByInstructorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorAchievementsByInstructorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorAchievementsByInstructorIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstructorAchievementsByInstructorIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorAchievementsByInstructorIdQuery, GetInstructorAchievementsByInstructorIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorAchievementsByInstructorIdQuery, GetInstructorAchievementsByInstructorIdQueryVariables>(GetInstructorAchievementsByInstructorIdDocument, baseOptions);
      }
export function useGetInstructorAchievementsByInstructorIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorAchievementsByInstructorIdQuery, GetInstructorAchievementsByInstructorIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorAchievementsByInstructorIdQuery, GetInstructorAchievementsByInstructorIdQueryVariables>(GetInstructorAchievementsByInstructorIdDocument, baseOptions);
        }
export type GetInstructorAchievementsByInstructorIdQueryHookResult = ReturnType<typeof useGetInstructorAchievementsByInstructorIdQuery>;
export type GetInstructorAchievementsByInstructorIdLazyQueryHookResult = ReturnType<typeof useGetInstructorAchievementsByInstructorIdLazyQuery>;
export type GetInstructorAchievementsByInstructorIdQueryResult = ApolloReactCommon.QueryResult<GetInstructorAchievementsByInstructorIdQuery, GetInstructorAchievementsByInstructorIdQueryVariables>;
export const LoadRecurringSchedulesForInstructorDocument = gql`
    query LoadRecurringSchedulesForInstructor($instructorId: ObjectId) {
  recurringSchedulesForInstructor(instructorId: $instructorId) {
    items {
      ...InstructorRecurringSchedule
    }
  }
}
    ${InstructorRecurringScheduleFragmentDoc}`;

/**
 * __useLoadRecurringSchedulesForInstructorQuery__
 *
 * To run a query within a React component, call `useLoadRecurringSchedulesForInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRecurringSchedulesForInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRecurringSchedulesForInstructorQuery({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *   },
 * });
 */
export function useLoadRecurringSchedulesForInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadRecurringSchedulesForInstructorQuery, LoadRecurringSchedulesForInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadRecurringSchedulesForInstructorQuery, LoadRecurringSchedulesForInstructorQueryVariables>(LoadRecurringSchedulesForInstructorDocument, baseOptions);
      }
export function useLoadRecurringSchedulesForInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadRecurringSchedulesForInstructorQuery, LoadRecurringSchedulesForInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadRecurringSchedulesForInstructorQuery, LoadRecurringSchedulesForInstructorQueryVariables>(LoadRecurringSchedulesForInstructorDocument, baseOptions);
        }
export type LoadRecurringSchedulesForInstructorQueryHookResult = ReturnType<typeof useLoadRecurringSchedulesForInstructorQuery>;
export type LoadRecurringSchedulesForInstructorLazyQueryHookResult = ReturnType<typeof useLoadRecurringSchedulesForInstructorLazyQuery>;
export type LoadRecurringSchedulesForInstructorQueryResult = ApolloReactCommon.QueryResult<LoadRecurringSchedulesForInstructorQuery, LoadRecurringSchedulesForInstructorQueryVariables>;
export const LoadRecurringRescheduleRequestsDocument = gql`
    query LoadRecurringRescheduleRequests($completed: Boolean) {
  recurringRescheduleRequests(completed: $completed) {
    items {
      ...AdminDashRecurringRescheduleRequest
    }
  }
}
    ${AdminDashRecurringRescheduleRequestFragmentDoc}`;

/**
 * __useLoadRecurringRescheduleRequestsQuery__
 *
 * To run a query within a React component, call `useLoadRecurringRescheduleRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRecurringRescheduleRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRecurringRescheduleRequestsQuery({
 *   variables: {
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useLoadRecurringRescheduleRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadRecurringRescheduleRequestsQuery, LoadRecurringRescheduleRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadRecurringRescheduleRequestsQuery, LoadRecurringRescheduleRequestsQueryVariables>(LoadRecurringRescheduleRequestsDocument, baseOptions);
      }
export function useLoadRecurringRescheduleRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadRecurringRescheduleRequestsQuery, LoadRecurringRescheduleRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadRecurringRescheduleRequestsQuery, LoadRecurringRescheduleRequestsQueryVariables>(LoadRecurringRescheduleRequestsDocument, baseOptions);
        }
export type LoadRecurringRescheduleRequestsQueryHookResult = ReturnType<typeof useLoadRecurringRescheduleRequestsQuery>;
export type LoadRecurringRescheduleRequestsLazyQueryHookResult = ReturnType<typeof useLoadRecurringRescheduleRequestsLazyQuery>;
export type LoadRecurringRescheduleRequestsQueryResult = ApolloReactCommon.QueryResult<LoadRecurringRescheduleRequestsQuery, LoadRecurringRescheduleRequestsQueryVariables>;
export const LoadRecurringRescheduleRequestByIdDocument = gql`
    query LoadRecurringRescheduleRequestById($id: ObjectId!) {
  recurringRescheduleRequest(id: $id) {
    ...AdminDashRecurringRescheduleRequest
  }
}
    ${AdminDashRecurringRescheduleRequestFragmentDoc}`;

/**
 * __useLoadRecurringRescheduleRequestByIdQuery__
 *
 * To run a query within a React component, call `useLoadRecurringRescheduleRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRecurringRescheduleRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRecurringRescheduleRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadRecurringRescheduleRequestByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadRecurringRescheduleRequestByIdQuery, LoadRecurringRescheduleRequestByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadRecurringRescheduleRequestByIdQuery, LoadRecurringRescheduleRequestByIdQueryVariables>(LoadRecurringRescheduleRequestByIdDocument, baseOptions);
      }
export function useLoadRecurringRescheduleRequestByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadRecurringRescheduleRequestByIdQuery, LoadRecurringRescheduleRequestByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadRecurringRescheduleRequestByIdQuery, LoadRecurringRescheduleRequestByIdQueryVariables>(LoadRecurringRescheduleRequestByIdDocument, baseOptions);
        }
export type LoadRecurringRescheduleRequestByIdQueryHookResult = ReturnType<typeof useLoadRecurringRescheduleRequestByIdQuery>;
export type LoadRecurringRescheduleRequestByIdLazyQueryHookResult = ReturnType<typeof useLoadRecurringRescheduleRequestByIdLazyQuery>;
export type LoadRecurringRescheduleRequestByIdQueryResult = ApolloReactCommon.QueryResult<LoadRecurringRescheduleRequestByIdQuery, LoadRecurringRescheduleRequestByIdQueryVariables>;
export const CreateRecurringRescheduleRequestDocument = gql`
    mutation CreateRecurringRescheduleRequest($input: CreateRecurringRescheduleRequestInput!) {
  createRecurringRescheduleRequest(input: $input) {
    success
  }
}
    `;
export type CreateRecurringRescheduleRequestMutationFn = ApolloReactCommon.MutationFunction<CreateRecurringRescheduleRequestMutation, CreateRecurringRescheduleRequestMutationVariables>;

/**
 * __useCreateRecurringRescheduleRequestMutation__
 *
 * To run a mutation, you first call `useCreateRecurringRescheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringRescheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringRescheduleRequestMutation, { data, loading, error }] = useCreateRecurringRescheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecurringRescheduleRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecurringRescheduleRequestMutation, CreateRecurringRescheduleRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecurringRescheduleRequestMutation, CreateRecurringRescheduleRequestMutationVariables>(CreateRecurringRescheduleRequestDocument, baseOptions);
      }
export type CreateRecurringRescheduleRequestMutationHookResult = ReturnType<typeof useCreateRecurringRescheduleRequestMutation>;
export type CreateRecurringRescheduleRequestMutationResult = ApolloReactCommon.MutationResult<CreateRecurringRescheduleRequestMutation>;
export type CreateRecurringRescheduleRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRecurringRescheduleRequestMutation, CreateRecurringRescheduleRequestMutationVariables>;
export const UpdateRecurringRescheduleRequestDocument = gql`
    mutation UpdateRecurringRescheduleRequest($input: UpdateRecurringRescheduleRequestInput!) {
  updateRecurringRescheduleRequest(input: $input) {
    success
  }
}
    `;
export type UpdateRecurringRescheduleRequestMutationFn = ApolloReactCommon.MutationFunction<UpdateRecurringRescheduleRequestMutation, UpdateRecurringRescheduleRequestMutationVariables>;

/**
 * __useUpdateRecurringRescheduleRequestMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringRescheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringRescheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringRescheduleRequestMutation, { data, loading, error }] = useUpdateRecurringRescheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecurringRescheduleRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecurringRescheduleRequestMutation, UpdateRecurringRescheduleRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecurringRescheduleRequestMutation, UpdateRecurringRescheduleRequestMutationVariables>(UpdateRecurringRescheduleRequestDocument, baseOptions);
      }
export type UpdateRecurringRescheduleRequestMutationHookResult = ReturnType<typeof useUpdateRecurringRescheduleRequestMutation>;
export type UpdateRecurringRescheduleRequestMutationResult = ApolloReactCommon.MutationResult<UpdateRecurringRescheduleRequestMutation>;
export type UpdateRecurringRescheduleRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecurringRescheduleRequestMutation, UpdateRecurringRescheduleRequestMutationVariables>;
export const CancelInstructorOnboardingApptDocument = gql`
    mutation CancelInstructorOnboardingAppt($input: CancelInstructorOnboardingApptInput!) {
  cancelInstructorOnboardingAppt(input: $input) {
    success
  }
}
    `;
export type CancelInstructorOnboardingApptMutationFn = ApolloReactCommon.MutationFunction<CancelInstructorOnboardingApptMutation, CancelInstructorOnboardingApptMutationVariables>;

/**
 * __useCancelInstructorOnboardingApptMutation__
 *
 * To run a mutation, you first call `useCancelInstructorOnboardingApptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInstructorOnboardingApptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInstructorOnboardingApptMutation, { data, loading, error }] = useCancelInstructorOnboardingApptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelInstructorOnboardingApptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelInstructorOnboardingApptMutation, CancelInstructorOnboardingApptMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelInstructorOnboardingApptMutation, CancelInstructorOnboardingApptMutationVariables>(CancelInstructorOnboardingApptDocument, baseOptions);
      }
export type CancelInstructorOnboardingApptMutationHookResult = ReturnType<typeof useCancelInstructorOnboardingApptMutation>;
export type CancelInstructorOnboardingApptMutationResult = ApolloReactCommon.MutationResult<CancelInstructorOnboardingApptMutation>;
export type CancelInstructorOnboardingApptMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelInstructorOnboardingApptMutation, CancelInstructorOnboardingApptMutationVariables>;
export const ScheduleInstructorOnboardingApptDocument = gql`
    mutation ScheduleInstructorOnboardingAppt($input: ScheduleInstructorOnboardingApptInput!) {
  scheduleInstructorOnboardingAppt(input: $input) {
    success
  }
}
    `;
export type ScheduleInstructorOnboardingApptMutationFn = ApolloReactCommon.MutationFunction<ScheduleInstructorOnboardingApptMutation, ScheduleInstructorOnboardingApptMutationVariables>;

/**
 * __useScheduleInstructorOnboardingApptMutation__
 *
 * To run a mutation, you first call `useScheduleInstructorOnboardingApptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleInstructorOnboardingApptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleInstructorOnboardingApptMutation, { data, loading, error }] = useScheduleInstructorOnboardingApptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleInstructorOnboardingApptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleInstructorOnboardingApptMutation, ScheduleInstructorOnboardingApptMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleInstructorOnboardingApptMutation, ScheduleInstructorOnboardingApptMutationVariables>(ScheduleInstructorOnboardingApptDocument, baseOptions);
      }
export type ScheduleInstructorOnboardingApptMutationHookResult = ReturnType<typeof useScheduleInstructorOnboardingApptMutation>;
export type ScheduleInstructorOnboardingApptMutationResult = ApolloReactCommon.MutationResult<ScheduleInstructorOnboardingApptMutation>;
export type ScheduleInstructorOnboardingApptMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleInstructorOnboardingApptMutation, ScheduleInstructorOnboardingApptMutationVariables>;
export const RescheduleInstructorOnboardingApptDocument = gql`
    mutation RescheduleInstructorOnboardingAppt($input: RescheduleInstructorOnboardingApptInput!) {
  rescheduleInstructorOnboardingAppt(input: $input) {
    success
  }
}
    `;
export type RescheduleInstructorOnboardingApptMutationFn = ApolloReactCommon.MutationFunction<RescheduleInstructorOnboardingApptMutation, RescheduleInstructorOnboardingApptMutationVariables>;

/**
 * __useRescheduleInstructorOnboardingApptMutation__
 *
 * To run a mutation, you first call `useRescheduleInstructorOnboardingApptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleInstructorOnboardingApptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleInstructorOnboardingApptMutation, { data, loading, error }] = useRescheduleInstructorOnboardingApptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleInstructorOnboardingApptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RescheduleInstructorOnboardingApptMutation, RescheduleInstructorOnboardingApptMutationVariables>) {
        return ApolloReactHooks.useMutation<RescheduleInstructorOnboardingApptMutation, RescheduleInstructorOnboardingApptMutationVariables>(RescheduleInstructorOnboardingApptDocument, baseOptions);
      }
export type RescheduleInstructorOnboardingApptMutationHookResult = ReturnType<typeof useRescheduleInstructorOnboardingApptMutation>;
export type RescheduleInstructorOnboardingApptMutationResult = ApolloReactCommon.MutationResult<RescheduleInstructorOnboardingApptMutation>;
export type RescheduleInstructorOnboardingApptMutationOptions = ApolloReactCommon.BaseMutationOptions<RescheduleInstructorOnboardingApptMutation, RescheduleInstructorOnboardingApptMutationVariables>;
export const GetInstructorOnboardingsDocument = gql`
    query GetInstructorOnboardings($input: InstructorOnboardingQueryInput) {
  instructorOnboardings(input: $input) {
    items {
      ...InstructorOnboardingRow
    }
  }
}
    ${InstructorOnboardingRowFragmentDoc}`;

/**
 * __useGetInstructorOnboardingsQuery__
 *
 * To run a query within a React component, call `useGetInstructorOnboardingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorOnboardingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorOnboardingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInstructorOnboardingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorOnboardingsQuery, GetInstructorOnboardingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorOnboardingsQuery, GetInstructorOnboardingsQueryVariables>(GetInstructorOnboardingsDocument, baseOptions);
      }
export function useGetInstructorOnboardingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorOnboardingsQuery, GetInstructorOnboardingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorOnboardingsQuery, GetInstructorOnboardingsQueryVariables>(GetInstructorOnboardingsDocument, baseOptions);
        }
export type GetInstructorOnboardingsQueryHookResult = ReturnType<typeof useGetInstructorOnboardingsQuery>;
export type GetInstructorOnboardingsLazyQueryHookResult = ReturnType<typeof useGetInstructorOnboardingsLazyQuery>;
export type GetInstructorOnboardingsQueryResult = ApolloReactCommon.QueryResult<GetInstructorOnboardingsQuery, GetInstructorOnboardingsQueryVariables>;
export const GetInstructorOnboardingByIdDocument = gql`
    query GetInstructorOnboardingById($id: ObjectId!) {
  instructorOnboardingById(id: $id) {
    ...InstructorOnboardingRow
  }
}
    ${InstructorOnboardingRowFragmentDoc}`;

/**
 * __useGetInstructorOnboardingByIdQuery__
 *
 * To run a query within a React component, call `useGetInstructorOnboardingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorOnboardingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorOnboardingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstructorOnboardingByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorOnboardingByIdQuery, GetInstructorOnboardingByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorOnboardingByIdQuery, GetInstructorOnboardingByIdQueryVariables>(GetInstructorOnboardingByIdDocument, baseOptions);
      }
export function useGetInstructorOnboardingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorOnboardingByIdQuery, GetInstructorOnboardingByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorOnboardingByIdQuery, GetInstructorOnboardingByIdQueryVariables>(GetInstructorOnboardingByIdDocument, baseOptions);
        }
export type GetInstructorOnboardingByIdQueryHookResult = ReturnType<typeof useGetInstructorOnboardingByIdQuery>;
export type GetInstructorOnboardingByIdLazyQueryHookResult = ReturnType<typeof useGetInstructorOnboardingByIdLazyQuery>;
export type GetInstructorOnboardingByIdQueryResult = ApolloReactCommon.QueryResult<GetInstructorOnboardingByIdQuery, GetInstructorOnboardingByIdQueryVariables>;
export const GetAppointmentTypesDocument = gql`
    query GetAppointmentTypes {
  appointmentTypes {
    items {
      id
      name
      calendarIDs
    }
  }
}
    `;

/**
 * __useGetAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useGetAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>(GetAppointmentTypesDocument, baseOptions);
      }
export function useGetAppointmentTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>(GetAppointmentTypesDocument, baseOptions);
        }
export type GetAppointmentTypesQueryHookResult = ReturnType<typeof useGetAppointmentTypesQuery>;
export type GetAppointmentTypesLazyQueryHookResult = ReturnType<typeof useGetAppointmentTypesLazyQuery>;
export type GetAppointmentTypesQueryResult = ApolloReactCommon.QueryResult<GetAppointmentTypesQuery, GetAppointmentTypesQueryVariables>;
export const UpdateInstructorOnboardingDocument = gql`
    mutation updateInstructorOnboarding($input: UpdateInstructorOnboardingInput!) {
  updateInstructorOnboarding(input: $input) {
    instructorOnboarding {
      ...InstructorOnboardingRow
    }
  }
}
    ${InstructorOnboardingRowFragmentDoc}`;
export type UpdateInstructorOnboardingMutationFn = ApolloReactCommon.MutationFunction<UpdateInstructorOnboardingMutation, UpdateInstructorOnboardingMutationVariables>;

/**
 * __useUpdateInstructorOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateInstructorOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructorOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructorOnboardingMutation, { data, loading, error }] = useUpdateInstructorOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstructorOnboardingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInstructorOnboardingMutation, UpdateInstructorOnboardingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInstructorOnboardingMutation, UpdateInstructorOnboardingMutationVariables>(UpdateInstructorOnboardingDocument, baseOptions);
      }
export type UpdateInstructorOnboardingMutationHookResult = ReturnType<typeof useUpdateInstructorOnboardingMutation>;
export type UpdateInstructorOnboardingMutationResult = ApolloReactCommon.MutationResult<UpdateInstructorOnboardingMutation>;
export type UpdateInstructorOnboardingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInstructorOnboardingMutation, UpdateInstructorOnboardingMutationVariables>;
export const UpdateInstructorOnboardingTaskDocument = gql`
    mutation updateInstructorOnboardingTask($input: UpdateInstructorOnboardingTaskInput!) {
  updateInstructorOnboardingTask(input: $input) {
    instructorOnboarding {
      ...InstructorOnboardingRow
    }
  }
}
    ${InstructorOnboardingRowFragmentDoc}`;
export type UpdateInstructorOnboardingTaskMutationFn = ApolloReactCommon.MutationFunction<UpdateInstructorOnboardingTaskMutation, UpdateInstructorOnboardingTaskMutationVariables>;

/**
 * __useUpdateInstructorOnboardingTaskMutation__
 *
 * To run a mutation, you first call `useUpdateInstructorOnboardingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructorOnboardingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructorOnboardingTaskMutation, { data, loading, error }] = useUpdateInstructorOnboardingTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInstructorOnboardingTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInstructorOnboardingTaskMutation, UpdateInstructorOnboardingTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInstructorOnboardingTaskMutation, UpdateInstructorOnboardingTaskMutationVariables>(UpdateInstructorOnboardingTaskDocument, baseOptions);
      }
export type UpdateInstructorOnboardingTaskMutationHookResult = ReturnType<typeof useUpdateInstructorOnboardingTaskMutation>;
export type UpdateInstructorOnboardingTaskMutationResult = ApolloReactCommon.MutationResult<UpdateInstructorOnboardingTaskMutation>;
export type UpdateInstructorOnboardingTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInstructorOnboardingTaskMutation, UpdateInstructorOnboardingTaskMutationVariables>;
export const LoadPayrollInstructorsDocument = gql`
    query LoadPayrollInstructors {
  instructors {
    items {
      ...PayrollInstructor
    }
  }
}
    ${PayrollInstructorFragmentDoc}`;

/**
 * __useLoadPayrollInstructorsQuery__
 *
 * To run a query within a React component, call `useLoadPayrollInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPayrollInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPayrollInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadPayrollInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadPayrollInstructorsQuery, LoadPayrollInstructorsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadPayrollInstructorsQuery, LoadPayrollInstructorsQueryVariables>(LoadPayrollInstructorsDocument, baseOptions);
      }
export function useLoadPayrollInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadPayrollInstructorsQuery, LoadPayrollInstructorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadPayrollInstructorsQuery, LoadPayrollInstructorsQueryVariables>(LoadPayrollInstructorsDocument, baseOptions);
        }
export type LoadPayrollInstructorsQueryHookResult = ReturnType<typeof useLoadPayrollInstructorsQuery>;
export type LoadPayrollInstructorsLazyQueryHookResult = ReturnType<typeof useLoadPayrollInstructorsLazyQuery>;
export type LoadPayrollInstructorsQueryResult = ApolloReactCommon.QueryResult<LoadPayrollInstructorsQuery, LoadPayrollInstructorsQueryVariables>;
export const LoadPayrollCyclesDocument = gql`
    query LoadPayrollCycles {
  payrollCycles {
    _id
    startDate
    endDate
    releaseDate
  }
}
    `;

/**
 * __useLoadPayrollCyclesQuery__
 *
 * To run a query within a React component, call `useLoadPayrollCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPayrollCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPayrollCyclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadPayrollCyclesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadPayrollCyclesQuery, LoadPayrollCyclesQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadPayrollCyclesQuery, LoadPayrollCyclesQueryVariables>(LoadPayrollCyclesDocument, baseOptions);
      }
export function useLoadPayrollCyclesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadPayrollCyclesQuery, LoadPayrollCyclesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadPayrollCyclesQuery, LoadPayrollCyclesQueryVariables>(LoadPayrollCyclesDocument, baseOptions);
        }
export type LoadPayrollCyclesQueryHookResult = ReturnType<typeof useLoadPayrollCyclesQuery>;
export type LoadPayrollCyclesLazyQueryHookResult = ReturnType<typeof useLoadPayrollCyclesLazyQuery>;
export type LoadPayrollCyclesQueryResult = ApolloReactCommon.QueryResult<LoadPayrollCyclesQuery, LoadPayrollCyclesQueryVariables>;
export const StartImpersonationDocument = gql`
    mutation StartImpersonation($input: StartImpersonationInput!) {
  startImpersonation(input: $input) {
    success
    token
  }
}
    `;
export type StartImpersonationMutationFn = ApolloReactCommon.MutationFunction<StartImpersonationMutation, StartImpersonationMutationVariables>;

/**
 * __useStartImpersonationMutation__
 *
 * To run a mutation, you first call `useStartImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startImpersonationMutation, { data, loading, error }] = useStartImpersonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartImpersonationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartImpersonationMutation, StartImpersonationMutationVariables>) {
        return ApolloReactHooks.useMutation<StartImpersonationMutation, StartImpersonationMutationVariables>(StartImpersonationDocument, baseOptions);
      }
export type StartImpersonationMutationHookResult = ReturnType<typeof useStartImpersonationMutation>;
export type StartImpersonationMutationResult = ApolloReactCommon.MutationResult<StartImpersonationMutation>;
export type StartImpersonationMutationOptions = ApolloReactCommon.BaseMutationOptions<StartImpersonationMutation, StartImpersonationMutationVariables>;
export const StopImpersonationDocument = gql`
    mutation StopImpersonation {
  stopImpersonation {
    success
    token
  }
}
    `;
export type StopImpersonationMutationFn = ApolloReactCommon.MutationFunction<StopImpersonationMutation, StopImpersonationMutationVariables>;

/**
 * __useStopImpersonationMutation__
 *
 * To run a mutation, you first call `useStopImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopImpersonationMutation, { data, loading, error }] = useStopImpersonationMutation({
 *   variables: {
 *   },
 * });
 */
export function useStopImpersonationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopImpersonationMutation, StopImpersonationMutationVariables>) {
        return ApolloReactHooks.useMutation<StopImpersonationMutation, StopImpersonationMutationVariables>(StopImpersonationDocument, baseOptions);
      }
export type StopImpersonationMutationHookResult = ReturnType<typeof useStopImpersonationMutation>;
export type StopImpersonationMutationResult = ApolloReactCommon.MutationResult<StopImpersonationMutation>;
export type StopImpersonationMutationOptions = ApolloReactCommon.BaseMutationOptions<StopImpersonationMutation, StopImpersonationMutationVariables>;
export const InstructorEnabledForSlackMessagingDocument = gql`
    query InstructorEnabledForSlackMessaging($instructorId: ObjectId!) {
  instructorEnabledForSlackMessaging(instructorId: $instructorId)
}
    `;

/**
 * __useInstructorEnabledForSlackMessagingQuery__
 *
 * To run a query within a React component, call `useInstructorEnabledForSlackMessagingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructorEnabledForSlackMessagingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructorEnabledForSlackMessagingQuery({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *   },
 * });
 */
export function useInstructorEnabledForSlackMessagingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InstructorEnabledForSlackMessagingQuery, InstructorEnabledForSlackMessagingQueryVariables>) {
        return ApolloReactHooks.useQuery<InstructorEnabledForSlackMessagingQuery, InstructorEnabledForSlackMessagingQueryVariables>(InstructorEnabledForSlackMessagingDocument, baseOptions);
      }
export function useInstructorEnabledForSlackMessagingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InstructorEnabledForSlackMessagingQuery, InstructorEnabledForSlackMessagingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InstructorEnabledForSlackMessagingQuery, InstructorEnabledForSlackMessagingQueryVariables>(InstructorEnabledForSlackMessagingDocument, baseOptions);
        }
export type InstructorEnabledForSlackMessagingQueryHookResult = ReturnType<typeof useInstructorEnabledForSlackMessagingQuery>;
export type InstructorEnabledForSlackMessagingLazyQueryHookResult = ReturnType<typeof useInstructorEnabledForSlackMessagingLazyQuery>;
export type InstructorEnabledForSlackMessagingQueryResult = ApolloReactCommon.QueryResult<InstructorEnabledForSlackMessagingQuery, InstructorEnabledForSlackMessagingQueryVariables>;
export const SlackUserIdForInstructorDocument = gql`
    mutation SlackUserIdForInstructor($input: SlackUserIdForInstructorInput!) {
  slackUserIdForInstructor(input: $input) {
    slackUserId
  }
}
    `;
export type SlackUserIdForInstructorMutationFn = ApolloReactCommon.MutationFunction<SlackUserIdForInstructorMutation, SlackUserIdForInstructorMutationVariables>;

/**
 * __useSlackUserIdForInstructorMutation__
 *
 * To run a mutation, you first call `useSlackUserIdForInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlackUserIdForInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slackUserIdForInstructorMutation, { data, loading, error }] = useSlackUserIdForInstructorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSlackUserIdForInstructorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SlackUserIdForInstructorMutation, SlackUserIdForInstructorMutationVariables>) {
        return ApolloReactHooks.useMutation<SlackUserIdForInstructorMutation, SlackUserIdForInstructorMutationVariables>(SlackUserIdForInstructorDocument, baseOptions);
      }
export type SlackUserIdForInstructorMutationHookResult = ReturnType<typeof useSlackUserIdForInstructorMutation>;
export type SlackUserIdForInstructorMutationResult = ApolloReactCommon.MutationResult<SlackUserIdForInstructorMutation>;
export type SlackUserIdForInstructorMutationOptions = ApolloReactCommon.BaseMutationOptions<SlackUserIdForInstructorMutation, SlackUserIdForInstructorMutationVariables>;
export const SendSlackMessageToInstructorDocument = gql`
    mutation SendSlackMessageToInstructor($input: SendSlackMessageToInstructorInput!) {
  sendSlackMessageToInstructor(input: $input) {
    sent
  }
}
    `;
export type SendSlackMessageToInstructorMutationFn = ApolloReactCommon.MutationFunction<SendSlackMessageToInstructorMutation, SendSlackMessageToInstructorMutationVariables>;

/**
 * __useSendSlackMessageToInstructorMutation__
 *
 * To run a mutation, you first call `useSendSlackMessageToInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSlackMessageToInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSlackMessageToInstructorMutation, { data, loading, error }] = useSendSlackMessageToInstructorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSlackMessageToInstructorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSlackMessageToInstructorMutation, SendSlackMessageToInstructorMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSlackMessageToInstructorMutation, SendSlackMessageToInstructorMutationVariables>(SendSlackMessageToInstructorDocument, baseOptions);
      }
export type SendSlackMessageToInstructorMutationHookResult = ReturnType<typeof useSendSlackMessageToInstructorMutation>;
export type SendSlackMessageToInstructorMutationResult = ApolloReactCommon.MutationResult<SendSlackMessageToInstructorMutation>;
export type SendSlackMessageToInstructorMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSlackMessageToInstructorMutation, SendSlackMessageToInstructorMutationVariables>;
export const UserSmsConsentStatusByIdDocument = gql`
    query UserSmsConsentStatusById($id: ObjectId!) {
  userSmsConsentStatusById(id: $id)
}
    `;

/**
 * __useUserSmsConsentStatusByIdQuery__
 *
 * To run a query within a React component, call `useUserSmsConsentStatusByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSmsConsentStatusByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSmsConsentStatusByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSmsConsentStatusByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSmsConsentStatusByIdQuery, UserSmsConsentStatusByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSmsConsentStatusByIdQuery, UserSmsConsentStatusByIdQueryVariables>(UserSmsConsentStatusByIdDocument, baseOptions);
      }
export function useUserSmsConsentStatusByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSmsConsentStatusByIdQuery, UserSmsConsentStatusByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSmsConsentStatusByIdQuery, UserSmsConsentStatusByIdQueryVariables>(UserSmsConsentStatusByIdDocument, baseOptions);
        }
export type UserSmsConsentStatusByIdQueryHookResult = ReturnType<typeof useUserSmsConsentStatusByIdQuery>;
export type UserSmsConsentStatusByIdLazyQueryHookResult = ReturnType<typeof useUserSmsConsentStatusByIdLazyQuery>;
export type UserSmsConsentStatusByIdQueryResult = ApolloReactCommon.QueryResult<UserSmsConsentStatusByIdQuery, UserSmsConsentStatusByIdQueryVariables>;
export const SendSmsMessageToParentDocument = gql`
    mutation SendSmsMessageToParent($input: SendSmsMessageToParentInput!) {
  sendSmsMessageToParent(input: $input) {
    sent
  }
}
    `;
export type SendSmsMessageToParentMutationFn = ApolloReactCommon.MutationFunction<SendSmsMessageToParentMutation, SendSmsMessageToParentMutationVariables>;

/**
 * __useSendSmsMessageToParentMutation__
 *
 * To run a mutation, you first call `useSendSmsMessageToParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMessageToParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMessageToParentMutation, { data, loading, error }] = useSendSmsMessageToParentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSmsMessageToParentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSmsMessageToParentMutation, SendSmsMessageToParentMutationVariables>) {
        return ApolloReactHooks.useMutation<SendSmsMessageToParentMutation, SendSmsMessageToParentMutationVariables>(SendSmsMessageToParentDocument, baseOptions);
      }
export type SendSmsMessageToParentMutationHookResult = ReturnType<typeof useSendSmsMessageToParentMutation>;
export type SendSmsMessageToParentMutationResult = ApolloReactCommon.MutationResult<SendSmsMessageToParentMutation>;
export type SendSmsMessageToParentMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSmsMessageToParentMutation, SendSmsMessageToParentMutationVariables>;
export const GetJuniClubUsersDocument = gql`
    query GetJuniClubUsers($id: ObjectId!) {
  getJuniClubUsers(juniClubId: $id) {
    items {
      ...JuniClubMemberType
    }
  }
}
    ${JuniClubMemberTypeFragmentDoc}`;

/**
 * __useGetJuniClubUsersQuery__
 *
 * To run a query within a React component, call `useGetJuniClubUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuniClubUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuniClubUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJuniClubUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJuniClubUsersQuery, GetJuniClubUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJuniClubUsersQuery, GetJuniClubUsersQueryVariables>(GetJuniClubUsersDocument, baseOptions);
      }
export function useGetJuniClubUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJuniClubUsersQuery, GetJuniClubUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJuniClubUsersQuery, GetJuniClubUsersQueryVariables>(GetJuniClubUsersDocument, baseOptions);
        }
export type GetJuniClubUsersQueryHookResult = ReturnType<typeof useGetJuniClubUsersQuery>;
export type GetJuniClubUsersLazyQueryHookResult = ReturnType<typeof useGetJuniClubUsersLazyQuery>;
export type GetJuniClubUsersQueryResult = ApolloReactCommon.QueryResult<GetJuniClubUsersQuery, GetJuniClubUsersQueryVariables>;
export const GetCommunityEventsByStartDatesDocument = gql`
    query GetCommunityEventsByStartDates($startDates: [String!]!, $timezone: String) {
  getCommunityEventsByStartDates(startDates: $startDates, timezone: $timezone) {
    items {
      _id
      title
      description
      thumbnailUrl
      startDateTime
      endDateTime
      archivedAt
      juniClubIds
      eventLink
      juniClubs {
        ...JuniClub
      }
    }
  }
}
    ${JuniClubFragmentDoc}`;

/**
 * __useGetCommunityEventsByStartDatesQuery__
 *
 * To run a query within a React component, call `useGetCommunityEventsByStartDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityEventsByStartDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityEventsByStartDatesQuery({
 *   variables: {
 *      startDates: // value for 'startDates'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useGetCommunityEventsByStartDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCommunityEventsByStartDatesQuery, GetCommunityEventsByStartDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCommunityEventsByStartDatesQuery, GetCommunityEventsByStartDatesQueryVariables>(GetCommunityEventsByStartDatesDocument, baseOptions);
      }
export function useGetCommunityEventsByStartDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommunityEventsByStartDatesQuery, GetCommunityEventsByStartDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCommunityEventsByStartDatesQuery, GetCommunityEventsByStartDatesQueryVariables>(GetCommunityEventsByStartDatesDocument, baseOptions);
        }
export type GetCommunityEventsByStartDatesQueryHookResult = ReturnType<typeof useGetCommunityEventsByStartDatesQuery>;
export type GetCommunityEventsByStartDatesLazyQueryHookResult = ReturnType<typeof useGetCommunityEventsByStartDatesLazyQuery>;
export type GetCommunityEventsByStartDatesQueryResult = ApolloReactCommon.QueryResult<GetCommunityEventsByStartDatesQuery, GetCommunityEventsByStartDatesQueryVariables>;
export const GetJuniClubBySearchStringDocument = gql`
    query GetJuniClubBySearchString($searchString: String!) {
  getJuniClubBySearchString(searchString: $searchString) {
    items {
      _id
      displayName
      description
      tags
      channels {
        _id
        displayName
        createdAt
        archivedAt
      }
      colorTheme
      coverPhoto
      logo
      creatorStudentId
      creatorInstructorUserId
      isJuniModerated
      archivedAt
      createdAt
      updatedAt
      memberCount
    }
  }
}
    `;

/**
 * __useGetJuniClubBySearchStringQuery__
 *
 * To run a query within a React component, call `useGetJuniClubBySearchStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuniClubBySearchStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuniClubBySearchStringQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetJuniClubBySearchStringQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJuniClubBySearchStringQuery, GetJuniClubBySearchStringQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJuniClubBySearchStringQuery, GetJuniClubBySearchStringQueryVariables>(GetJuniClubBySearchStringDocument, baseOptions);
      }
export function useGetJuniClubBySearchStringLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJuniClubBySearchStringQuery, GetJuniClubBySearchStringQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJuniClubBySearchStringQuery, GetJuniClubBySearchStringQueryVariables>(GetJuniClubBySearchStringDocument, baseOptions);
        }
export type GetJuniClubBySearchStringQueryHookResult = ReturnType<typeof useGetJuniClubBySearchStringQuery>;
export type GetJuniClubBySearchStringLazyQueryHookResult = ReturnType<typeof useGetJuniClubBySearchStringLazyQuery>;
export type GetJuniClubBySearchStringQueryResult = ApolloReactCommon.QueryResult<GetJuniClubBySearchStringQuery, GetJuniClubBySearchStringQueryVariables>;
export const GetJuniClubsByTagDocument = gql`
    query GetJuniClubsByTag($tag: String!) {
  getJuniClubsByTag(tag: $tag) {
    items {
      _id
      displayName
      description
      tags
      channels {
        _id
        displayName
        createdAt
        archivedAt
      }
      colorTheme
      coverPhoto
      logo
      creatorStudentId
      creatorInstructorUserId
      isJuniModerated
      archivedAt
      createdAt
      updatedAt
      memberCount
    }
  }
}
    `;

/**
 * __useGetJuniClubsByTagQuery__
 *
 * To run a query within a React component, call `useGetJuniClubsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuniClubsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuniClubsByTagQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGetJuniClubsByTagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJuniClubsByTagQuery, GetJuniClubsByTagQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJuniClubsByTagQuery, GetJuniClubsByTagQueryVariables>(GetJuniClubsByTagDocument, baseOptions);
      }
export function useGetJuniClubsByTagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJuniClubsByTagQuery, GetJuniClubsByTagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJuniClubsByTagQuery, GetJuniClubsByTagQueryVariables>(GetJuniClubsByTagDocument, baseOptions);
        }
export type GetJuniClubsByTagQueryHookResult = ReturnType<typeof useGetJuniClubsByTagQuery>;
export type GetJuniClubsByTagLazyQueryHookResult = ReturnType<typeof useGetJuniClubsByTagLazyQuery>;
export type GetJuniClubsByTagQueryResult = ApolloReactCommon.QueryResult<GetJuniClubsByTagQuery, GetJuniClubsByTagQueryVariables>;
export const JoinLeaveClubDocument = gql`
    mutation JoinLeaveClub($studentId: ObjectId, $instructorUserId: ObjectId, $juniClubId: ObjectId!, $isActive: Boolean!) {
  joinLeaveClub(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, juniClubId: $juniClubId, isActive: $isActive}
  ) {
    juniClubMembership {
      _id
    }
  }
}
    `;
export type JoinLeaveClubMutationFn = ApolloReactCommon.MutationFunction<JoinLeaveClubMutation, JoinLeaveClubMutationVariables>;

/**
 * __useJoinLeaveClubMutation__
 *
 * To run a mutation, you first call `useJoinLeaveClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLeaveClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLeaveClubMutation, { data, loading, error }] = useJoinLeaveClubMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      juniClubId: // value for 'juniClubId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useJoinLeaveClubMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinLeaveClubMutation, JoinLeaveClubMutationVariables>) {
        return ApolloReactHooks.useMutation<JoinLeaveClubMutation, JoinLeaveClubMutationVariables>(JoinLeaveClubDocument, baseOptions);
      }
export type JoinLeaveClubMutationHookResult = ReturnType<typeof useJoinLeaveClubMutation>;
export type JoinLeaveClubMutationResult = ApolloReactCommon.MutationResult<JoinLeaveClubMutation>;
export type JoinLeaveClubMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinLeaveClubMutation, JoinLeaveClubMutationVariables>;
export const GetUsernameByIdDocument = gql`
    query GetUsernameById($studentId: ObjectId, $instructorUserId: ObjectId) {
  getUsernameById(studentId: $studentId, instructorUserId: $instructorUserId)
}
    `;

/**
 * __useGetUsernameByIdQuery__
 *
 * To run a query within a React component, call `useGetUsernameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsernameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsernameByIdQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *   },
 * });
 */
export function useGetUsernameByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>(GetUsernameByIdDocument, baseOptions);
      }
export function useGetUsernameByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>(GetUsernameByIdDocument, baseOptions);
        }
export type GetUsernameByIdQueryHookResult = ReturnType<typeof useGetUsernameByIdQuery>;
export type GetUsernameByIdLazyQueryHookResult = ReturnType<typeof useGetUsernameByIdLazyQuery>;
export type GetUsernameByIdQueryResult = ApolloReactCommon.QueryResult<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>;
export const UpdateMembershipStatusDocument = gql`
    mutation UpdateMembershipStatus($juniClubId: ObjectId!, $studentId: ObjectId, $instructorUserId: ObjectId, $memberUserCategory: String!, $memberClientUUID: String!, $newMembershipStatus: String!) {
  updateMembershipStatus(
    input: {juniClubId: $juniClubId, studentId: $studentId, instructorUserId: $instructorUserId, memberUserCategory: $memberUserCategory, memberClientUUID: $memberClientUUID, newMembershipStatus: $newMembershipStatus}
  ) {
    success
  }
}
    `;
export type UpdateMembershipStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>;

/**
 * __useUpdateMembershipStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipStatusMutation, { data, loading, error }] = useUpdateMembershipStatusMutation({
 *   variables: {
 *      juniClubId: // value for 'juniClubId'
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      memberUserCategory: // value for 'memberUserCategory'
 *      memberClientUUID: // value for 'memberClientUUID'
 *      newMembershipStatus: // value for 'newMembershipStatus'
 *   },
 * });
 */
export function useUpdateMembershipStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>(UpdateMembershipStatusDocument, baseOptions);
      }
export type UpdateMembershipStatusMutationHookResult = ReturnType<typeof useUpdateMembershipStatusMutation>;
export type UpdateMembershipStatusMutationResult = ApolloReactCommon.MutationResult<UpdateMembershipStatusMutation>;
export type UpdateMembershipStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>;
export const SendImageUploadSlackMessageDocument = gql`
    mutation sendImageUploadSlackMessage($input: SendImageUploadSlackMessageInput!) {
  sendImageUploadSlackMessage(input: $input) {
    success
  }
}
    `;
export type SendImageUploadSlackMessageMutationFn = ApolloReactCommon.MutationFunction<SendImageUploadSlackMessageMutation, SendImageUploadSlackMessageMutationVariables>;

/**
 * __useSendImageUploadSlackMessageMutation__
 *
 * To run a mutation, you first call `useSendImageUploadSlackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendImageUploadSlackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendImageUploadSlackMessageMutation, { data, loading, error }] = useSendImageUploadSlackMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendImageUploadSlackMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendImageUploadSlackMessageMutation, SendImageUploadSlackMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<SendImageUploadSlackMessageMutation, SendImageUploadSlackMessageMutationVariables>(SendImageUploadSlackMessageDocument, baseOptions);
      }
export type SendImageUploadSlackMessageMutationHookResult = ReturnType<typeof useSendImageUploadSlackMessageMutation>;
export type SendImageUploadSlackMessageMutationResult = ApolloReactCommon.MutationResult<SendImageUploadSlackMessageMutation>;
export type SendImageUploadSlackMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<SendImageUploadSlackMessageMutation, SendImageUploadSlackMessageMutationVariables>;
export const RefreshPubNubCredentialsDocument = gql`
    mutation RefreshPubNubCredentials($studentId: ObjectId, $instructorUserId: ObjectId) {
  refreshPubNubCredentials(
    input: {studentId: $studentId, instructorUserId: $instructorUserId}
  ) {
    publishKey
    subscribeKey
    authKey
    clientUUID
    expiresAt
  }
}
    `;
export type RefreshPubNubCredentialsMutationFn = ApolloReactCommon.MutationFunction<RefreshPubNubCredentialsMutation, RefreshPubNubCredentialsMutationVariables>;

/**
 * __useRefreshPubNubCredentialsMutation__
 *
 * To run a mutation, you first call `useRefreshPubNubCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPubNubCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPubNubCredentialsMutation, { data, loading, error }] = useRefreshPubNubCredentialsMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *   },
 * });
 */
export function useRefreshPubNubCredentialsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshPubNubCredentialsMutation, RefreshPubNubCredentialsMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshPubNubCredentialsMutation, RefreshPubNubCredentialsMutationVariables>(RefreshPubNubCredentialsDocument, baseOptions);
      }
export type RefreshPubNubCredentialsMutationHookResult = ReturnType<typeof useRefreshPubNubCredentialsMutation>;
export type RefreshPubNubCredentialsMutationResult = ApolloReactCommon.MutationResult<RefreshPubNubCredentialsMutation>;
export type RefreshPubNubCredentialsMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshPubNubCredentialsMutation, RefreshPubNubCredentialsMutationVariables>;
export const MyClubsStateDocument = gql`
    mutation MyClubsState($studentId: ObjectId, $instructorUserId: ObjectId, $specificClubs: [ObjectId!], $includeLastReadTimetokens: Boolean) {
  myClubsState(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, specificClubs: $specificClubs, includeLastReadTimetokens: $includeLastReadTimetokens}
  ) {
    juniClubs {
      _id
      displayName
      description
      tags
      channels {
        displayName
        createdAt
        archivedAt
        readOnly
      }
      colorTheme
      coverPhoto
      logo
      creatorInstructorUserId
      isJuniModerated
      archivedAt
      createdAt
      updatedAt
    }
    juniClubMembershipInfos {
      _id
      firstName
      lastInitial
      userCategory
      membershipStatus
      username
      clientUUID
      juniClubId
      isActive
    }
    lastReadTimetokens {
      channelName
      timetoken
    }
    juniCommunityEvents {
      _id
      title
      description
      startDateTime
      endDateTime
      juniClubIds
      creatorInstructorUserId
      thumbnailUrl
      archivedAt
      eventLink
    }
  }
}
    `;
export type MyClubsStateMutationFn = ApolloReactCommon.MutationFunction<MyClubsStateMutation, MyClubsStateMutationVariables>;

/**
 * __useMyClubsStateMutation__
 *
 * To run a mutation, you first call `useMyClubsStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyClubsStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myClubsStateMutation, { data, loading, error }] = useMyClubsStateMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      specificClubs: // value for 'specificClubs'
 *      includeLastReadTimetokens: // value for 'includeLastReadTimetokens'
 *   },
 * });
 */
export function useMyClubsStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MyClubsStateMutation, MyClubsStateMutationVariables>) {
        return ApolloReactHooks.useMutation<MyClubsStateMutation, MyClubsStateMutationVariables>(MyClubsStateDocument, baseOptions);
      }
export type MyClubsStateMutationHookResult = ReturnType<typeof useMyClubsStateMutation>;
export type MyClubsStateMutationResult = ApolloReactCommon.MutationResult<MyClubsStateMutation>;
export type MyClubsStateMutationOptions = ApolloReactCommon.BaseMutationOptions<MyClubsStateMutation, MyClubsStateMutationVariables>;
export const RemoveViolatingMessageDocument = gql`
    mutation RemoveViolatingMessage($studentId: ObjectId, $instructorUserId: ObjectId, $juniClubId: ObjectId!, $channelName: String!, $msgId: String!, $imageUrls: [String!]) {
  removeViolatingMessage(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, juniClubId: $juniClubId, channelName: $channelName, msgId: $msgId, imageUrls: $imageUrls}
  ) {
    success
  }
}
    `;
export type RemoveViolatingMessageMutationFn = ApolloReactCommon.MutationFunction<RemoveViolatingMessageMutation, RemoveViolatingMessageMutationVariables>;

/**
 * __useRemoveViolatingMessageMutation__
 *
 * To run a mutation, you first call `useRemoveViolatingMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveViolatingMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeViolatingMessageMutation, { data, loading, error }] = useRemoveViolatingMessageMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      juniClubId: // value for 'juniClubId'
 *      channelName: // value for 'channelName'
 *      msgId: // value for 'msgId'
 *      imageUrls: // value for 'imageUrls'
 *   },
 * });
 */
export function useRemoveViolatingMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveViolatingMessageMutation, RemoveViolatingMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveViolatingMessageMutation, RemoveViolatingMessageMutationVariables>(RemoveViolatingMessageDocument, baseOptions);
      }
export type RemoveViolatingMessageMutationHookResult = ReturnType<typeof useRemoveViolatingMessageMutation>;
export type RemoveViolatingMessageMutationResult = ApolloReactCommon.MutationResult<RemoveViolatingMessageMutation>;
export type RemoveViolatingMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveViolatingMessageMutation, RemoveViolatingMessageMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation DeleteMessage($studentId: ObjectId, $instructorUserId: ObjectId, $juniClubId: ObjectId!, $channelName: String!, $msgId: String!, $senderUUID: String, $imageUrls: [String!]) {
  deleteMessage(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, juniClubId: $juniClubId, channelName: $channelName, msgId: $msgId, senderUUID: $senderUUID, imageUrls: $imageUrls}
  ) {
    success
  }
}
    `;
export type DeleteMessageMutationFn = ApolloReactCommon.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      juniClubId: // value for 'juniClubId'
 *      channelName: // value for 'channelName'
 *      msgId: // value for 'msgId'
 *      senderUUID: // value for 'senderUUID'
 *      imageUrls: // value for 'imageUrls'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, baseOptions);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = ApolloReactCommon.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const CheckMessageContentDocument = gql`
    mutation CheckMessageContent($studentId: ObjectId, $instructorUserId: ObjectId, $message: String!) {
  checkMessageContent(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, message: $message}
  ) {
    success
    moderationErrorMessage
  }
}
    `;
export type CheckMessageContentMutationFn = ApolloReactCommon.MutationFunction<CheckMessageContentMutation, CheckMessageContentMutationVariables>;

/**
 * __useCheckMessageContentMutation__
 *
 * To run a mutation, you first call `useCheckMessageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckMessageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkMessageContentMutation, { data, loading, error }] = useCheckMessageContentMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCheckMessageContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckMessageContentMutation, CheckMessageContentMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckMessageContentMutation, CheckMessageContentMutationVariables>(CheckMessageContentDocument, baseOptions);
      }
export type CheckMessageContentMutationHookResult = ReturnType<typeof useCheckMessageContentMutation>;
export type CheckMessageContentMutationResult = ApolloReactCommon.MutationResult<CheckMessageContentMutation>;
export type CheckMessageContentMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckMessageContentMutation, CheckMessageContentMutationVariables>;
export const CheckImageContentDocument = gql`
    mutation CheckImageContent($studentId: ObjectId, $instructorUserId: ObjectId, $imageUrl: String!) {
  checkImageContent(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, imageUrl: $imageUrl}
  ) {
    success
    violations
  }
}
    `;
export type CheckImageContentMutationFn = ApolloReactCommon.MutationFunction<CheckImageContentMutation, CheckImageContentMutationVariables>;

/**
 * __useCheckImageContentMutation__
 *
 * To run a mutation, you first call `useCheckImageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckImageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkImageContentMutation, { data, loading, error }] = useCheckImageContentMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useCheckImageContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckImageContentMutation, CheckImageContentMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckImageContentMutation, CheckImageContentMutationVariables>(CheckImageContentDocument, baseOptions);
      }
export type CheckImageContentMutationHookResult = ReturnType<typeof useCheckImageContentMutation>;
export type CheckImageContentMutationResult = ApolloReactCommon.MutationResult<CheckImageContentMutation>;
export type CheckImageContentMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckImageContentMutation, CheckImageContentMutationVariables>;
export const UpdateLastReadTimetokenDocument = gql`
    mutation UpdateLastReadTimetoken($studentId: ObjectId, $instructorUserId: ObjectId, $juniClubId: ObjectId!, $channelName: String!, $timetoken: String!) {
  updateLastReadTimetoken(
    input: {studentId: $studentId, instructorUserId: $instructorUserId, juniClubId: $juniClubId, channelName: $channelName, timetoken: $timetoken}
  ) {
    success
  }
}
    `;
export type UpdateLastReadTimetokenMutationFn = ApolloReactCommon.MutationFunction<UpdateLastReadTimetokenMutation, UpdateLastReadTimetokenMutationVariables>;

/**
 * __useUpdateLastReadTimetokenMutation__
 *
 * To run a mutation, you first call `useUpdateLastReadTimetokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastReadTimetokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastReadTimetokenMutation, { data, loading, error }] = useUpdateLastReadTimetokenMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorUserId: // value for 'instructorUserId'
 *      juniClubId: // value for 'juniClubId'
 *      channelName: // value for 'channelName'
 *      timetoken: // value for 'timetoken'
 *   },
 * });
 */
export function useUpdateLastReadTimetokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLastReadTimetokenMutation, UpdateLastReadTimetokenMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLastReadTimetokenMutation, UpdateLastReadTimetokenMutationVariables>(UpdateLastReadTimetokenDocument, baseOptions);
      }
export type UpdateLastReadTimetokenMutationHookResult = ReturnType<typeof useUpdateLastReadTimetokenMutation>;
export type UpdateLastReadTimetokenMutationResult = ApolloReactCommon.MutationResult<UpdateLastReadTimetokenMutation>;
export type UpdateLastReadTimetokenMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLastReadTimetokenMutation, UpdateLastReadTimetokenMutationVariables>;
export const GetFeaturedMyFeedProjectsDocument = gql`
    query GetFeaturedMyFeedProjects {
  featuredStudentProjects(featuredType: MY_FEED) {
    items {
      _id
      projectType
      creator {
        username
        displayName
        type
        isGuest
      }
      lastUpdatedAt
      title
      description
      juniverseTags
      thumbnailUrl
      attachedUsermediaUrls
      viewCount
    }
  }
}
    `;

/**
 * __useGetFeaturedMyFeedProjectsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedMyFeedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedMyFeedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedMyFeedProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedMyFeedProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeaturedMyFeedProjectsQuery, GetFeaturedMyFeedProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeaturedMyFeedProjectsQuery, GetFeaturedMyFeedProjectsQueryVariables>(GetFeaturedMyFeedProjectsDocument, baseOptions);
      }
export function useGetFeaturedMyFeedProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeaturedMyFeedProjectsQuery, GetFeaturedMyFeedProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeaturedMyFeedProjectsQuery, GetFeaturedMyFeedProjectsQueryVariables>(GetFeaturedMyFeedProjectsDocument, baseOptions);
        }
export type GetFeaturedMyFeedProjectsQueryHookResult = ReturnType<typeof useGetFeaturedMyFeedProjectsQuery>;
export type GetFeaturedMyFeedProjectsLazyQueryHookResult = ReturnType<typeof useGetFeaturedMyFeedProjectsLazyQuery>;
export type GetFeaturedMyFeedProjectsQueryResult = ApolloReactCommon.QueryResult<GetFeaturedMyFeedProjectsQuery, GetFeaturedMyFeedProjectsQueryVariables>;
export const SubmitNylasCodeDocument = gql`
    mutation SubmitNylasCode($code: String!) {
  submitNylasCode(input: {code: $code}) {
    instructor {
      _id
      nylasConnected
      nylas {
        availabilityCalendarId
      }
    }
  }
}
    `;
export type SubmitNylasCodeMutationFn = ApolloReactCommon.MutationFunction<SubmitNylasCodeMutation, SubmitNylasCodeMutationVariables>;

/**
 * __useSubmitNylasCodeMutation__
 *
 * To run a mutation, you first call `useSubmitNylasCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNylasCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNylasCodeMutation, { data, loading, error }] = useSubmitNylasCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSubmitNylasCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitNylasCodeMutation, SubmitNylasCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitNylasCodeMutation, SubmitNylasCodeMutationVariables>(SubmitNylasCodeDocument, baseOptions);
      }
export type SubmitNylasCodeMutationHookResult = ReturnType<typeof useSubmitNylasCodeMutation>;
export type SubmitNylasCodeMutationResult = ApolloReactCommon.MutationResult<SubmitNylasCodeMutation>;
export type SubmitNylasCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitNylasCodeMutation, SubmitNylasCodeMutationVariables>;
export const GetNylasConnectedDocument = gql`
    query GetNylasConnected {
  findMeInstructor {
    _id
    nylasConnected
    nylas {
      availabilityCalendarId
    }
  }
}
    `;

/**
 * __useGetNylasConnectedQuery__
 *
 * To run a query within a React component, call `useGetNylasConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNylasConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNylasConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNylasConnectedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNylasConnectedQuery, GetNylasConnectedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNylasConnectedQuery, GetNylasConnectedQueryVariables>(GetNylasConnectedDocument, baseOptions);
      }
export function useGetNylasConnectedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNylasConnectedQuery, GetNylasConnectedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNylasConnectedQuery, GetNylasConnectedQueryVariables>(GetNylasConnectedDocument, baseOptions);
        }
export type GetNylasConnectedQueryHookResult = ReturnType<typeof useGetNylasConnectedQuery>;
export type GetNylasConnectedLazyQueryHookResult = ReturnType<typeof useGetNylasConnectedLazyQuery>;
export type GetNylasConnectedQueryResult = ApolloReactCommon.QueryResult<GetNylasConnectedQuery, GetNylasConnectedQueryVariables>;
export const NylasCalendarSelectionsDocument = gql`
    query NylasCalendarSelections {
  findMeInstructor {
    _id
    nylasBusyCalendarOptions {
      name
      id
      watched
    }
  }
}
    `;

/**
 * __useNylasCalendarSelectionsQuery__
 *
 * To run a query within a React component, call `useNylasCalendarSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNylasCalendarSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNylasCalendarSelectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNylasCalendarSelectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NylasCalendarSelectionsQuery, NylasCalendarSelectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<NylasCalendarSelectionsQuery, NylasCalendarSelectionsQueryVariables>(NylasCalendarSelectionsDocument, baseOptions);
      }
export function useNylasCalendarSelectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NylasCalendarSelectionsQuery, NylasCalendarSelectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NylasCalendarSelectionsQuery, NylasCalendarSelectionsQueryVariables>(NylasCalendarSelectionsDocument, baseOptions);
        }
export type NylasCalendarSelectionsQueryHookResult = ReturnType<typeof useNylasCalendarSelectionsQuery>;
export type NylasCalendarSelectionsLazyQueryHookResult = ReturnType<typeof useNylasCalendarSelectionsLazyQuery>;
export type NylasCalendarSelectionsQueryResult = ApolloReactCommon.QueryResult<NylasCalendarSelectionsQuery, NylasCalendarSelectionsQueryVariables>;
export const SetNylasCalendarWatchedDocument = gql`
    mutation setNylasCalendarWatched($watched: Boolean!, $id: String!) {
  setNylasCalendarWatched(input: {watched: $watched, calendarId: $id}) {
    instructor {
      _id
      nylasBusyCalendarOptions {
        name
        id
        watched
      }
    }
  }
}
    `;
export type SetNylasCalendarWatchedMutationFn = ApolloReactCommon.MutationFunction<SetNylasCalendarWatchedMutation, SetNylasCalendarWatchedMutationVariables>;

/**
 * __useSetNylasCalendarWatchedMutation__
 *
 * To run a mutation, you first call `useSetNylasCalendarWatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNylasCalendarWatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNylasCalendarWatchedMutation, { data, loading, error }] = useSetNylasCalendarWatchedMutation({
 *   variables: {
 *      watched: // value for 'watched'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetNylasCalendarWatchedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetNylasCalendarWatchedMutation, SetNylasCalendarWatchedMutationVariables>) {
        return ApolloReactHooks.useMutation<SetNylasCalendarWatchedMutation, SetNylasCalendarWatchedMutationVariables>(SetNylasCalendarWatchedDocument, baseOptions);
      }
export type SetNylasCalendarWatchedMutationHookResult = ReturnType<typeof useSetNylasCalendarWatchedMutation>;
export type SetNylasCalendarWatchedMutationResult = ApolloReactCommon.MutationResult<SetNylasCalendarWatchedMutation>;
export type SetNylasCalendarWatchedMutationOptions = ApolloReactCommon.BaseMutationOptions<SetNylasCalendarWatchedMutation, SetNylasCalendarWatchedMutationVariables>;
export const DisconnectNylasDocument = gql`
    mutation disconnectNylas {
  disconnectNylas {
    instructor {
      _id
      nylasConnected
      nylasBusyCalendarOptions {
        name
        id
        watched
      }
    }
  }
}
    `;
export type DisconnectNylasMutationFn = ApolloReactCommon.MutationFunction<DisconnectNylasMutation, DisconnectNylasMutationVariables>;

/**
 * __useDisconnectNylasMutation__
 *
 * To run a mutation, you first call `useDisconnectNylasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectNylasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectNylasMutation, { data, loading, error }] = useDisconnectNylasMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectNylasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisconnectNylasMutation, DisconnectNylasMutationVariables>) {
        return ApolloReactHooks.useMutation<DisconnectNylasMutation, DisconnectNylasMutationVariables>(DisconnectNylasDocument, baseOptions);
      }
export type DisconnectNylasMutationHookResult = ReturnType<typeof useDisconnectNylasMutation>;
export type DisconnectNylasMutationResult = ApolloReactCommon.MutationResult<DisconnectNylasMutation>;
export type DisconnectNylasMutationOptions = ApolloReactCommon.BaseMutationOptions<DisconnectNylasMutation, DisconnectNylasMutationVariables>;
export const SyncCalendarsDocument = gql`
    mutation syncCalendars($userId: ObjectId!, $start: DateTime!, $end: DateTime!) {
  syncCalendars(input: {userId: $userId, start: $start, end: $end}) {
    success
  }
}
    `;
export type SyncCalendarsMutationFn = ApolloReactCommon.MutationFunction<SyncCalendarsMutation, SyncCalendarsMutationVariables>;

/**
 * __useSyncCalendarsMutation__
 *
 * To run a mutation, you first call `useSyncCalendarsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCalendarsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCalendarsMutation, { data, loading, error }] = useSyncCalendarsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useSyncCalendarsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncCalendarsMutation, SyncCalendarsMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncCalendarsMutation, SyncCalendarsMutationVariables>(SyncCalendarsDocument, baseOptions);
      }
export type SyncCalendarsMutationHookResult = ReturnType<typeof useSyncCalendarsMutation>;
export type SyncCalendarsMutationResult = ApolloReactCommon.MutationResult<SyncCalendarsMutation>;
export type SyncCalendarsMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncCalendarsMutation, SyncCalendarsMutationVariables>;
export const GetCurrentStudentProposalsDocument = gql`
    query GetCurrentStudentProposals($userId: ObjectId) {
  studentProposals(userId: $userId, isCurrent: true) {
    items {
      ...CurrentStudentProposal
    }
  }
}
    ${CurrentStudentProposalFragmentDoc}`;

/**
 * __useGetCurrentStudentProposalsQuery__
 *
 * To run a query within a React component, call `useGetCurrentStudentProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentStudentProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentStudentProposalsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCurrentStudentProposalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentStudentProposalsQuery, GetCurrentStudentProposalsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentStudentProposalsQuery, GetCurrentStudentProposalsQueryVariables>(GetCurrentStudentProposalsDocument, baseOptions);
      }
export function useGetCurrentStudentProposalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentStudentProposalsQuery, GetCurrentStudentProposalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentStudentProposalsQuery, GetCurrentStudentProposalsQueryVariables>(GetCurrentStudentProposalsDocument, baseOptions);
        }
export type GetCurrentStudentProposalsQueryHookResult = ReturnType<typeof useGetCurrentStudentProposalsQuery>;
export type GetCurrentStudentProposalsLazyQueryHookResult = ReturnType<typeof useGetCurrentStudentProposalsLazyQuery>;
export type GetCurrentStudentProposalsQueryResult = ApolloReactCommon.QueryResult<GetCurrentStudentProposalsQuery, GetCurrentStudentProposalsQueryVariables>;
export const GetPastStudentProposalsDocument = gql`
    query GetPastStudentProposals($userId: ObjectId) {
  studentProposals(userId: $userId, isCurrent: false, wasResolvedRecently: true) {
    items {
      ...PastStudentProposal
    }
  }
}
    ${PastStudentProposalFragmentDoc}`;

/**
 * __useGetPastStudentProposalsQuery__
 *
 * To run a query within a React component, call `useGetPastStudentProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastStudentProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastStudentProposalsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPastStudentProposalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPastStudentProposalsQuery, GetPastStudentProposalsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPastStudentProposalsQuery, GetPastStudentProposalsQueryVariables>(GetPastStudentProposalsDocument, baseOptions);
      }
export function useGetPastStudentProposalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPastStudentProposalsQuery, GetPastStudentProposalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPastStudentProposalsQuery, GetPastStudentProposalsQueryVariables>(GetPastStudentProposalsDocument, baseOptions);
        }
export type GetPastStudentProposalsQueryHookResult = ReturnType<typeof useGetPastStudentProposalsQuery>;
export type GetPastStudentProposalsLazyQueryHookResult = ReturnType<typeof useGetPastStudentProposalsLazyQuery>;
export type GetPastStudentProposalsQueryResult = ApolloReactCommon.QueryResult<GetPastStudentProposalsQuery, GetPastStudentProposalsQueryVariables>;
export const AcceptStudentProposalDocument = gql`
    mutation AcceptStudentProposal($input: AcceptStudentProposalInput!) {
  acceptStudentProposal(input: $input) {
    studentProposal {
      _id
    }
  }
}
    `;
export type AcceptStudentProposalMutationFn = ApolloReactCommon.MutationFunction<AcceptStudentProposalMutation, AcceptStudentProposalMutationVariables>;

/**
 * __useAcceptStudentProposalMutation__
 *
 * To run a mutation, you first call `useAcceptStudentProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptStudentProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptStudentProposalMutation, { data, loading, error }] = useAcceptStudentProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptStudentProposalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptStudentProposalMutation, AcceptStudentProposalMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptStudentProposalMutation, AcceptStudentProposalMutationVariables>(AcceptStudentProposalDocument, baseOptions);
      }
export type AcceptStudentProposalMutationHookResult = ReturnType<typeof useAcceptStudentProposalMutation>;
export type AcceptStudentProposalMutationResult = ApolloReactCommon.MutationResult<AcceptStudentProposalMutation>;
export type AcceptStudentProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptStudentProposalMutation, AcceptStudentProposalMutationVariables>;
export const DeclineStudentProposalDocument = gql`
    mutation DeclineStudentProposal($input: DeclineStudentProposalInput!) {
  declineStudentProposal(input: $input) {
    studentProposal {
      _id
    }
  }
}
    `;
export type DeclineStudentProposalMutationFn = ApolloReactCommon.MutationFunction<DeclineStudentProposalMutation, DeclineStudentProposalMutationVariables>;

/**
 * __useDeclineStudentProposalMutation__
 *
 * To run a mutation, you first call `useDeclineStudentProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineStudentProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineStudentProposalMutation, { data, loading, error }] = useDeclineStudentProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineStudentProposalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineStudentProposalMutation, DeclineStudentProposalMutationVariables>) {
        return ApolloReactHooks.useMutation<DeclineStudentProposalMutation, DeclineStudentProposalMutationVariables>(DeclineStudentProposalDocument, baseOptions);
      }
export type DeclineStudentProposalMutationHookResult = ReturnType<typeof useDeclineStudentProposalMutation>;
export type DeclineStudentProposalMutationResult = ApolloReactCommon.MutationResult<DeclineStudentProposalMutation>;
export type DeclineStudentProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclineStudentProposalMutation, DeclineStudentProposalMutationVariables>;
export const LoadUpcomingClassAppointmentsForInstructorDocument = gql`
    query LoadUpcomingClassAppointmentsForInstructor($userId: ObjectId, $maxDays: Int, $max: Int) {
  upcomingClassAppointmentsForInstructor(
    userId: $userId
    maxDays: $maxDays
    max: $max
  ) {
    items {
      ...AcuityAppointment
    }
  }
}
    ${AcuityAppointmentFragmentDoc}`;

/**
 * __useLoadUpcomingClassAppointmentsForInstructorQuery__
 *
 * To run a query within a React component, call `useLoadUpcomingClassAppointmentsForInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUpcomingClassAppointmentsForInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUpcomingClassAppointmentsForInstructorQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      maxDays: // value for 'maxDays'
 *      max: // value for 'max'
 *   },
 * });
 */
export function useLoadUpcomingClassAppointmentsForInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadUpcomingClassAppointmentsForInstructorQuery, LoadUpcomingClassAppointmentsForInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadUpcomingClassAppointmentsForInstructorQuery, LoadUpcomingClassAppointmentsForInstructorQueryVariables>(LoadUpcomingClassAppointmentsForInstructorDocument, baseOptions);
      }
export function useLoadUpcomingClassAppointmentsForInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadUpcomingClassAppointmentsForInstructorQuery, LoadUpcomingClassAppointmentsForInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadUpcomingClassAppointmentsForInstructorQuery, LoadUpcomingClassAppointmentsForInstructorQueryVariables>(LoadUpcomingClassAppointmentsForInstructorDocument, baseOptions);
        }
export type LoadUpcomingClassAppointmentsForInstructorQueryHookResult = ReturnType<typeof useLoadUpcomingClassAppointmentsForInstructorQuery>;
export type LoadUpcomingClassAppointmentsForInstructorLazyQueryHookResult = ReturnType<typeof useLoadUpcomingClassAppointmentsForInstructorLazyQuery>;
export type LoadUpcomingClassAppointmentsForInstructorQueryResult = ApolloReactCommon.QueryResult<LoadUpcomingClassAppointmentsForInstructorQuery, LoadUpcomingClassAppointmentsForInstructorQueryVariables>;
export const CreateRecurringAvailabilityUpdateRequestDocument = gql`
    mutation createRecurringAvailabilityUpdateRequest($input: CreateRecurringAvailabilityUpdateRequestInput!) {
  createRecurringAvailabilityUpdateRequest(input: $input) {
    success
  }
}
    `;
export type CreateRecurringAvailabilityUpdateRequestMutationFn = ApolloReactCommon.MutationFunction<CreateRecurringAvailabilityUpdateRequestMutation, CreateRecurringAvailabilityUpdateRequestMutationVariables>;

/**
 * __useCreateRecurringAvailabilityUpdateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRecurringAvailabilityUpdateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringAvailabilityUpdateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringAvailabilityUpdateRequestMutation, { data, loading, error }] = useCreateRecurringAvailabilityUpdateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecurringAvailabilityUpdateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecurringAvailabilityUpdateRequestMutation, CreateRecurringAvailabilityUpdateRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecurringAvailabilityUpdateRequestMutation, CreateRecurringAvailabilityUpdateRequestMutationVariables>(CreateRecurringAvailabilityUpdateRequestDocument, baseOptions);
      }
export type CreateRecurringAvailabilityUpdateRequestMutationHookResult = ReturnType<typeof useCreateRecurringAvailabilityUpdateRequestMutation>;
export type CreateRecurringAvailabilityUpdateRequestMutationResult = ApolloReactCommon.MutationResult<CreateRecurringAvailabilityUpdateRequestMutation>;
export type CreateRecurringAvailabilityUpdateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRecurringAvailabilityUpdateRequestMutation, CreateRecurringAvailabilityUpdateRequestMutationVariables>;
export const LoadMyRecurringAvailabilityUpdateRequestsDocument = gql`
    query LoadMyRecurringAvailabilityUpdateRequests {
  myRecurringAvailabilityUpdateRequests {
    items {
      ...RecurringAvailabilityUpdateRequest
    }
  }
}
    ${RecurringAvailabilityUpdateRequestFragmentDoc}`;

/**
 * __useLoadMyRecurringAvailabilityUpdateRequestsQuery__
 *
 * To run a query within a React component, call `useLoadMyRecurringAvailabilityUpdateRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMyRecurringAvailabilityUpdateRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMyRecurringAvailabilityUpdateRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadMyRecurringAvailabilityUpdateRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadMyRecurringAvailabilityUpdateRequestsQuery, LoadMyRecurringAvailabilityUpdateRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadMyRecurringAvailabilityUpdateRequestsQuery, LoadMyRecurringAvailabilityUpdateRequestsQueryVariables>(LoadMyRecurringAvailabilityUpdateRequestsDocument, baseOptions);
      }
export function useLoadMyRecurringAvailabilityUpdateRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadMyRecurringAvailabilityUpdateRequestsQuery, LoadMyRecurringAvailabilityUpdateRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadMyRecurringAvailabilityUpdateRequestsQuery, LoadMyRecurringAvailabilityUpdateRequestsQueryVariables>(LoadMyRecurringAvailabilityUpdateRequestsDocument, baseOptions);
        }
export type LoadMyRecurringAvailabilityUpdateRequestsQueryHookResult = ReturnType<typeof useLoadMyRecurringAvailabilityUpdateRequestsQuery>;
export type LoadMyRecurringAvailabilityUpdateRequestsLazyQueryHookResult = ReturnType<typeof useLoadMyRecurringAvailabilityUpdateRequestsLazyQuery>;
export type LoadMyRecurringAvailabilityUpdateRequestsQueryResult = ApolloReactCommon.QueryResult<LoadMyRecurringAvailabilityUpdateRequestsQuery, LoadMyRecurringAvailabilityUpdateRequestsQueryVariables>;
export const CreateMyRecurringRescheduleRequestDocument = gql`
    mutation createMyRecurringRescheduleRequest($input: CreateMyRecurringRescheduleRequestInput!) {
  createMyRecurringRescheduleRequest(input: $input) {
    success
  }
}
    `;
export type CreateMyRecurringRescheduleRequestMutationFn = ApolloReactCommon.MutationFunction<CreateMyRecurringRescheduleRequestMutation, CreateMyRecurringRescheduleRequestMutationVariables>;

/**
 * __useCreateMyRecurringRescheduleRequestMutation__
 *
 * To run a mutation, you first call `useCreateMyRecurringRescheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyRecurringRescheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyRecurringRescheduleRequestMutation, { data, loading, error }] = useCreateMyRecurringRescheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMyRecurringRescheduleRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMyRecurringRescheduleRequestMutation, CreateMyRecurringRescheduleRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMyRecurringRescheduleRequestMutation, CreateMyRecurringRescheduleRequestMutationVariables>(CreateMyRecurringRescheduleRequestDocument, baseOptions);
      }
export type CreateMyRecurringRescheduleRequestMutationHookResult = ReturnType<typeof useCreateMyRecurringRescheduleRequestMutation>;
export type CreateMyRecurringRescheduleRequestMutationResult = ApolloReactCommon.MutationResult<CreateMyRecurringRescheduleRequestMutation>;
export type CreateMyRecurringRescheduleRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMyRecurringRescheduleRequestMutation, CreateMyRecurringRescheduleRequestMutationVariables>;
export const UpdateMyRecurringRescheduleRequestDocument = gql`
    mutation updateMyRecurringRescheduleRequest($input: UpdateMyRecurringRescheduleRequestInput!) {
  updateMyRecurringRescheduleRequest(input: $input) {
    recurringRescheduleRequest {
      ...RecurringRescheduleRequest
    }
  }
}
    ${RecurringRescheduleRequestFragmentDoc}`;
export type UpdateMyRecurringRescheduleRequestMutationFn = ApolloReactCommon.MutationFunction<UpdateMyRecurringRescheduleRequestMutation, UpdateMyRecurringRescheduleRequestMutationVariables>;

/**
 * __useUpdateMyRecurringRescheduleRequestMutation__
 *
 * To run a mutation, you first call `useUpdateMyRecurringRescheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyRecurringRescheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyRecurringRescheduleRequestMutation, { data, loading, error }] = useUpdateMyRecurringRescheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyRecurringRescheduleRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMyRecurringRescheduleRequestMutation, UpdateMyRecurringRescheduleRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMyRecurringRescheduleRequestMutation, UpdateMyRecurringRescheduleRequestMutationVariables>(UpdateMyRecurringRescheduleRequestDocument, baseOptions);
      }
export type UpdateMyRecurringRescheduleRequestMutationHookResult = ReturnType<typeof useUpdateMyRecurringRescheduleRequestMutation>;
export type UpdateMyRecurringRescheduleRequestMutationResult = ApolloReactCommon.MutationResult<UpdateMyRecurringRescheduleRequestMutation>;
export type UpdateMyRecurringRescheduleRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMyRecurringRescheduleRequestMutation, UpdateMyRecurringRescheduleRequestMutationVariables>;
export const LoadMyRecurringRescheduleRequestsDocument = gql`
    query LoadMyRecurringRescheduleRequests {
  myRecurringRescheduleRequests {
    items {
      ...RecurringRescheduleRequest
    }
  }
}
    ${RecurringRescheduleRequestFragmentDoc}`;

/**
 * __useLoadMyRecurringRescheduleRequestsQuery__
 *
 * To run a query within a React component, call `useLoadMyRecurringRescheduleRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMyRecurringRescheduleRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMyRecurringRescheduleRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadMyRecurringRescheduleRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadMyRecurringRescheduleRequestsQuery, LoadMyRecurringRescheduleRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadMyRecurringRescheduleRequestsQuery, LoadMyRecurringRescheduleRequestsQueryVariables>(LoadMyRecurringRescheduleRequestsDocument, baseOptions);
      }
export function useLoadMyRecurringRescheduleRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadMyRecurringRescheduleRequestsQuery, LoadMyRecurringRescheduleRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadMyRecurringRescheduleRequestsQuery, LoadMyRecurringRescheduleRequestsQueryVariables>(LoadMyRecurringRescheduleRequestsDocument, baseOptions);
        }
export type LoadMyRecurringRescheduleRequestsQueryHookResult = ReturnType<typeof useLoadMyRecurringRescheduleRequestsQuery>;
export type LoadMyRecurringRescheduleRequestsLazyQueryHookResult = ReturnType<typeof useLoadMyRecurringRescheduleRequestsLazyQuery>;
export type LoadMyRecurringRescheduleRequestsQueryResult = ApolloReactCommon.QueryResult<LoadMyRecurringRescheduleRequestsQuery, LoadMyRecurringRescheduleRequestsQueryVariables>;
export const StudentCompletedBundleDataDocument = gql`
    query StudentCompletedBundleData($params: String!) {
  studentCompletedBundleData(params: $params) {
    studentId
    firstName
    lastName
    username
    bundleName
    bundleDisplayName
    bundleCategory
    bundleCategoryBadge
    completionDate
  }
}
    `;

/**
 * __useStudentCompletedBundleDataQuery__
 *
 * To run a query within a React component, call `useStudentCompletedBundleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCompletedBundleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCompletedBundleDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStudentCompletedBundleDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentCompletedBundleDataQuery, StudentCompletedBundleDataQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentCompletedBundleDataQuery, StudentCompletedBundleDataQueryVariables>(StudentCompletedBundleDataDocument, baseOptions);
      }
export function useStudentCompletedBundleDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentCompletedBundleDataQuery, StudentCompletedBundleDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentCompletedBundleDataQuery, StudentCompletedBundleDataQueryVariables>(StudentCompletedBundleDataDocument, baseOptions);
        }
export type StudentCompletedBundleDataQueryHookResult = ReturnType<typeof useStudentCompletedBundleDataQuery>;
export type StudentCompletedBundleDataLazyQueryHookResult = ReturnType<typeof useStudentCompletedBundleDataLazyQuery>;
export type StudentCompletedBundleDataQueryResult = ApolloReactCommon.QueryResult<StudentCompletedBundleDataQuery, StudentCompletedBundleDataQueryVariables>;
export const StudentCompletedCourseParamsDocument = gql`
    query StudentCompletedCourseParams($studentId: ObjectId!, $courseName: String!) {
  studentCompletedCourseParams(studentId: $studentId, courseName: $courseName) {
    params
  }
}
    `;

/**
 * __useStudentCompletedCourseParamsQuery__
 *
 * To run a query within a React component, call `useStudentCompletedCourseParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCompletedCourseParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCompletedCourseParamsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      courseName: // value for 'courseName'
 *   },
 * });
 */
export function useStudentCompletedCourseParamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentCompletedCourseParamsQuery, StudentCompletedCourseParamsQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentCompletedCourseParamsQuery, StudentCompletedCourseParamsQueryVariables>(StudentCompletedCourseParamsDocument, baseOptions);
      }
export function useStudentCompletedCourseParamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentCompletedCourseParamsQuery, StudentCompletedCourseParamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentCompletedCourseParamsQuery, StudentCompletedCourseParamsQueryVariables>(StudentCompletedCourseParamsDocument, baseOptions);
        }
export type StudentCompletedCourseParamsQueryHookResult = ReturnType<typeof useStudentCompletedCourseParamsQuery>;
export type StudentCompletedCourseParamsLazyQueryHookResult = ReturnType<typeof useStudentCompletedCourseParamsLazyQuery>;
export type StudentCompletedCourseParamsQueryResult = ApolloReactCommon.QueryResult<StudentCompletedCourseParamsQuery, StudentCompletedCourseParamsQueryVariables>;
export const StudentCompletedCourseDataDocument = gql`
    query StudentCompletedCourseData($params: String!) {
  studentCompletedCourseData(params: $params) {
    studentId
    firstName
    lastName
    username
    courseId
    courseName
    courseDisplayName
    courseSubject
    masterProjectModuleId
    masterProjectProjectId
    completionDate
    referralCode
    parentFirstName
    isStudentParentAccount
    encryptedStudentAndProject
  }
}
    `;

/**
 * __useStudentCompletedCourseDataQuery__
 *
 * To run a query within a React component, call `useStudentCompletedCourseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCompletedCourseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCompletedCourseDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStudentCompletedCourseDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentCompletedCourseDataQuery, StudentCompletedCourseDataQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentCompletedCourseDataQuery, StudentCompletedCourseDataQueryVariables>(StudentCompletedCourseDataDocument, baseOptions);
      }
export function useStudentCompletedCourseDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentCompletedCourseDataQuery, StudentCompletedCourseDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentCompletedCourseDataQuery, StudentCompletedCourseDataQueryVariables>(StudentCompletedCourseDataDocument, baseOptions);
        }
export type StudentCompletedCourseDataQueryHookResult = ReturnType<typeof useStudentCompletedCourseDataQuery>;
export type StudentCompletedCourseDataLazyQueryHookResult = ReturnType<typeof useStudentCompletedCourseDataLazyQuery>;
export type StudentCompletedCourseDataQueryResult = ApolloReactCommon.QueryResult<StudentCompletedCourseDataQuery, StudentCompletedCourseDataQueryVariables>;
export const CourseCompletionDateDocument = gql`
    query CourseCompletionDate($studentId: ObjectId!, $courseName: String!) {
  courseCompletionDate(studentId: $studentId, courseName: $courseName) {
    completionDate
  }
}
    `;

/**
 * __useCourseCompletionDateQuery__
 *
 * To run a query within a React component, call `useCourseCompletionDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCompletionDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCompletionDateQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      courseName: // value for 'courseName'
 *   },
 * });
 */
export function useCourseCompletionDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseCompletionDateQuery, CourseCompletionDateQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseCompletionDateQuery, CourseCompletionDateQueryVariables>(CourseCompletionDateDocument, baseOptions);
      }
export function useCourseCompletionDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseCompletionDateQuery, CourseCompletionDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseCompletionDateQuery, CourseCompletionDateQueryVariables>(CourseCompletionDateDocument, baseOptions);
        }
export type CourseCompletionDateQueryHookResult = ReturnType<typeof useCourseCompletionDateQuery>;
export type CourseCompletionDateLazyQueryHookResult = ReturnType<typeof useCourseCompletionDateLazyQuery>;
export type CourseCompletionDateQueryResult = ApolloReactCommon.QueryResult<CourseCompletionDateQuery, CourseCompletionDateQueryVariables>;
export const LoadProductsByStripeIdDocument = gql`
    query LoadProductsByStripeId($ids: [String!]!) {
  productsByStripeIds(ids: $ids) {
    items {
      name
      format
      stripeId
      scheduleFrequency {
        appointmentTypeIDs
        weeklyFrequency
      }
    }
  }
}
    `;

/**
 * __useLoadProductsByStripeIdQuery__
 *
 * To run a query within a React component, call `useLoadProductsByStripeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadProductsByStripeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadProductsByStripeIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoadProductsByStripeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadProductsByStripeIdQuery, LoadProductsByStripeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadProductsByStripeIdQuery, LoadProductsByStripeIdQueryVariables>(LoadProductsByStripeIdDocument, baseOptions);
      }
export function useLoadProductsByStripeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadProductsByStripeIdQuery, LoadProductsByStripeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadProductsByStripeIdQuery, LoadProductsByStripeIdQueryVariables>(LoadProductsByStripeIdDocument, baseOptions);
        }
export type LoadProductsByStripeIdQueryHookResult = ReturnType<typeof useLoadProductsByStripeIdQuery>;
export type LoadProductsByStripeIdLazyQueryHookResult = ReturnType<typeof useLoadProductsByStripeIdLazyQuery>;
export type LoadProductsByStripeIdQueryResult = ApolloReactCommon.QueryResult<LoadProductsByStripeIdQuery, LoadProductsByStripeIdQueryVariables>;
export const LoadDefaultPaymentInformationByParentIdDocument = gql`
    query LoadDefaultPaymentInformationByParentId($id: ObjectId!) {
  defaultPaymentInformationByParentId(id: $id) {
    object
    brand
    last4
    exp_month
    exp_year
  }
}
    `;

/**
 * __useLoadDefaultPaymentInformationByParentIdQuery__
 *
 * To run a query within a React component, call `useLoadDefaultPaymentInformationByParentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDefaultPaymentInformationByParentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDefaultPaymentInformationByParentIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadDefaultPaymentInformationByParentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadDefaultPaymentInformationByParentIdQuery, LoadDefaultPaymentInformationByParentIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadDefaultPaymentInformationByParentIdQuery, LoadDefaultPaymentInformationByParentIdQueryVariables>(LoadDefaultPaymentInformationByParentIdDocument, baseOptions);
      }
export function useLoadDefaultPaymentInformationByParentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadDefaultPaymentInformationByParentIdQuery, LoadDefaultPaymentInformationByParentIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadDefaultPaymentInformationByParentIdQuery, LoadDefaultPaymentInformationByParentIdQueryVariables>(LoadDefaultPaymentInformationByParentIdDocument, baseOptions);
        }
export type LoadDefaultPaymentInformationByParentIdQueryHookResult = ReturnType<typeof useLoadDefaultPaymentInformationByParentIdQuery>;
export type LoadDefaultPaymentInformationByParentIdLazyQueryHookResult = ReturnType<typeof useLoadDefaultPaymentInformationByParentIdLazyQuery>;
export type LoadDefaultPaymentInformationByParentIdQueryResult = ApolloReactCommon.QueryResult<LoadDefaultPaymentInformationByParentIdQuery, LoadDefaultPaymentInformationByParentIdQueryVariables>;
export const LoadPaymentHistoryByParentIdDocument = gql`
    query LoadPaymentHistoryByParentId($id: ObjectId!) {
  paymentHistoryByParentId(id: $id) {
    items {
      ...StripeCharge
    }
  }
}
    ${StripeChargeFragmentDoc}`;

/**
 * __useLoadPaymentHistoryByParentIdQuery__
 *
 * To run a query within a React component, call `useLoadPaymentHistoryByParentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPaymentHistoryByParentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPaymentHistoryByParentIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadPaymentHistoryByParentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadPaymentHistoryByParentIdQuery, LoadPaymentHistoryByParentIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadPaymentHistoryByParentIdQuery, LoadPaymentHistoryByParentIdQueryVariables>(LoadPaymentHistoryByParentIdDocument, baseOptions);
      }
export function useLoadPaymentHistoryByParentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadPaymentHistoryByParentIdQuery, LoadPaymentHistoryByParentIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadPaymentHistoryByParentIdQuery, LoadPaymentHistoryByParentIdQueryVariables>(LoadPaymentHistoryByParentIdDocument, baseOptions);
        }
export type LoadPaymentHistoryByParentIdQueryHookResult = ReturnType<typeof useLoadPaymentHistoryByParentIdQuery>;
export type LoadPaymentHistoryByParentIdLazyQueryHookResult = ReturnType<typeof useLoadPaymentHistoryByParentIdLazyQuery>;
export type LoadPaymentHistoryByParentIdQueryResult = ApolloReactCommon.QueryResult<LoadPaymentHistoryByParentIdQuery, LoadPaymentHistoryByParentIdQueryVariables>;
export const LoadStripeSubscriptionsByParentIdDocument = gql`
    query LoadStripeSubscriptionsByParentId($id: ObjectId!) {
  stripeSubscriptionsByParentId(id: $id) {
    items {
      id
      object
      customer
      metadata {
        studentIds
        cs_weeklyFrequency
        math_weeklyFrequency
        english_weeklyFrequency
        investing_weeklyFrequency
        science_weeklyFrequency
        usaco_weeklyFrequency
        juniJr_weeklyFrequency
        version
      }
      status
      current_period_start
      current_period_end
      canceled_at
      items {
        data {
          id
          plan {
            ...StripePlan
          }
          price {
            ...StripePrice
          }
        }
      }
      plan {
        ...StripePlan
      }
      discount {
        id
        coupon {
          object
          id
          amount_off
          duration
          percent_off
          percent_off_precise
          created
          valid
          currency
        }
      }
    }
  }
}
    ${StripePlanFragmentDoc}
${StripePriceFragmentDoc}`;

/**
 * __useLoadStripeSubscriptionsByParentIdQuery__
 *
 * To run a query within a React component, call `useLoadStripeSubscriptionsByParentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStripeSubscriptionsByParentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStripeSubscriptionsByParentIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadStripeSubscriptionsByParentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadStripeSubscriptionsByParentIdQuery, LoadStripeSubscriptionsByParentIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadStripeSubscriptionsByParentIdQuery, LoadStripeSubscriptionsByParentIdQueryVariables>(LoadStripeSubscriptionsByParentIdDocument, baseOptions);
      }
export function useLoadStripeSubscriptionsByParentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadStripeSubscriptionsByParentIdQuery, LoadStripeSubscriptionsByParentIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadStripeSubscriptionsByParentIdQuery, LoadStripeSubscriptionsByParentIdQueryVariables>(LoadStripeSubscriptionsByParentIdDocument, baseOptions);
        }
export type LoadStripeSubscriptionsByParentIdQueryHookResult = ReturnType<typeof useLoadStripeSubscriptionsByParentIdQuery>;
export type LoadStripeSubscriptionsByParentIdLazyQueryHookResult = ReturnType<typeof useLoadStripeSubscriptionsByParentIdLazyQuery>;
export type LoadStripeSubscriptionsByParentIdQueryResult = ApolloReactCommon.QueryResult<LoadStripeSubscriptionsByParentIdQuery, LoadStripeSubscriptionsByParentIdQueryVariables>;
export const CreateStripeSubscriptionDocument = gql`
    mutation CreateStripeSubscription($input: CreateStripeSubscriptionInput!) {
  createStripeSubscription(input: $input) {
    success
  }
}
    `;
export type CreateStripeSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>;

/**
 * __useCreateStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSubscriptionMutation, { data, loading, error }] = useCreateStripeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>(CreateStripeSubscriptionDocument, baseOptions);
      }
export type CreateStripeSubscriptionMutationHookResult = ReturnType<typeof useCreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>;
export const CancelStripeSubscriptionDocument = gql`
    mutation CancelStripeSubscription($input: CancelStripeSubscriptionInput!) {
  cancelStripeSubscription(input: $input) {
    success
  }
}
    `;
export type CancelStripeSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>;

/**
 * __useCancelStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripeSubscriptionMutation, { data, loading, error }] = useCancelStripeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelStripeSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>(CancelStripeSubscriptionDocument, baseOptions);
      }
export type CancelStripeSubscriptionMutationHookResult = ReturnType<typeof useCancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>;
export const DowngradeStripeSubscriptionDocument = gql`
    mutation DowngradeStripeSubscription($input: DowngradeStripeSubscriptionInput!) {
  downgradeStripeSubscription(input: $input) {
    success
  }
}
    `;
export type DowngradeStripeSubscriptionMutationFn = ApolloReactCommon.MutationFunction<DowngradeStripeSubscriptionMutation, DowngradeStripeSubscriptionMutationVariables>;

/**
 * __useDowngradeStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useDowngradeStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDowngradeStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downgradeStripeSubscriptionMutation, { data, loading, error }] = useDowngradeStripeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDowngradeStripeSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DowngradeStripeSubscriptionMutation, DowngradeStripeSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DowngradeStripeSubscriptionMutation, DowngradeStripeSubscriptionMutationVariables>(DowngradeStripeSubscriptionDocument, baseOptions);
      }
export type DowngradeStripeSubscriptionMutationHookResult = ReturnType<typeof useDowngradeStripeSubscriptionMutation>;
export type DowngradeStripeSubscriptionMutationResult = ApolloReactCommon.MutationResult<DowngradeStripeSubscriptionMutation>;
export type DowngradeStripeSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DowngradeStripeSubscriptionMutation, DowngradeStripeSubscriptionMutationVariables>;
export const PurchaseBootcampDocument = gql`
    mutation PurchaseBootcamp($input: PurchaseBootcampInput!) {
  purchaseBootcamp(input: $input) {
    success
  }
}
    `;
export type PurchaseBootcampMutationFn = ApolloReactCommon.MutationFunction<PurchaseBootcampMutation, PurchaseBootcampMutationVariables>;

/**
 * __usePurchaseBootcampMutation__
 *
 * To run a mutation, you first call `usePurchaseBootcampMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseBootcampMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseBootcampMutation, { data, loading, error }] = usePurchaseBootcampMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseBootcampMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PurchaseBootcampMutation, PurchaseBootcampMutationVariables>) {
        return ApolloReactHooks.useMutation<PurchaseBootcampMutation, PurchaseBootcampMutationVariables>(PurchaseBootcampDocument, baseOptions);
      }
export type PurchaseBootcampMutationHookResult = ReturnType<typeof usePurchaseBootcampMutation>;
export type PurchaseBootcampMutationResult = ApolloReactCommon.MutationResult<PurchaseBootcampMutation>;
export type PurchaseBootcampMutationOptions = ApolloReactCommon.BaseMutationOptions<PurchaseBootcampMutation, PurchaseBootcampMutationVariables>;
export const LoadCertificationTracksByStudentDocument = gql`
    query LoadCertificationTracksByStudent($id: ObjectId!) {
  studentById(_id: $id) {
    certificationTracks {
      name
      isFavorite
    }
  }
}
    `;

/**
 * __useLoadCertificationTracksByStudentQuery__
 *
 * To run a query within a React component, call `useLoadCertificationTracksByStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCertificationTracksByStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCertificationTracksByStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadCertificationTracksByStudentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadCertificationTracksByStudentQuery, LoadCertificationTracksByStudentQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadCertificationTracksByStudentQuery, LoadCertificationTracksByStudentQueryVariables>(LoadCertificationTracksByStudentDocument, baseOptions);
      }
export function useLoadCertificationTracksByStudentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadCertificationTracksByStudentQuery, LoadCertificationTracksByStudentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadCertificationTracksByStudentQuery, LoadCertificationTracksByStudentQueryVariables>(LoadCertificationTracksByStudentDocument, baseOptions);
        }
export type LoadCertificationTracksByStudentQueryHookResult = ReturnType<typeof useLoadCertificationTracksByStudentQuery>;
export type LoadCertificationTracksByStudentLazyQueryHookResult = ReturnType<typeof useLoadCertificationTracksByStudentLazyQuery>;
export type LoadCertificationTracksByStudentQueryResult = ApolloReactCommon.QueryResult<LoadCertificationTracksByStudentQuery, LoadCertificationTracksByStudentQueryVariables>;
export const LoadStudentsByParentIdsDocument = gql`
    query LoadStudentsByParentIds($ids: [String!]!) {
  studentsByParentIds(ids: $ids) {
    items {
      ...StudentFragment
    }
  }
}
    ${StudentFragmentFragmentDoc}`;

/**
 * __useLoadStudentsByParentIdsQuery__
 *
 * To run a query within a React component, call `useLoadStudentsByParentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStudentsByParentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStudentsByParentIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoadStudentsByParentIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadStudentsByParentIdsQuery, LoadStudentsByParentIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadStudentsByParentIdsQuery, LoadStudentsByParentIdsQueryVariables>(LoadStudentsByParentIdsDocument, baseOptions);
      }
export function useLoadStudentsByParentIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadStudentsByParentIdsQuery, LoadStudentsByParentIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadStudentsByParentIdsQuery, LoadStudentsByParentIdsQueryVariables>(LoadStudentsByParentIdsDocument, baseOptions);
        }
export type LoadStudentsByParentIdsQueryHookResult = ReturnType<typeof useLoadStudentsByParentIdsQuery>;
export type LoadStudentsByParentIdsLazyQueryHookResult = ReturnType<typeof useLoadStudentsByParentIdsLazyQuery>;
export type LoadStudentsByParentIdsQueryResult = ApolloReactCommon.QueryResult<LoadStudentsByParentIdsQuery, LoadStudentsByParentIdsQueryVariables>;
export const LoadAvailabilitiesByStudentIdsDocument = gql`
    query LoadAvailabilitiesByStudentIds($ids: [String!]!) {
  availabilitiesByStudentIds(ids: $ids) {
    items {
      _id
      availability
      studentId
      timezone
    }
  }
}
    `;

/**
 * __useLoadAvailabilitiesByStudentIdsQuery__
 *
 * To run a query within a React component, call `useLoadAvailabilitiesByStudentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAvailabilitiesByStudentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAvailabilitiesByStudentIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoadAvailabilitiesByStudentIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadAvailabilitiesByStudentIdsQuery, LoadAvailabilitiesByStudentIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadAvailabilitiesByStudentIdsQuery, LoadAvailabilitiesByStudentIdsQueryVariables>(LoadAvailabilitiesByStudentIdsDocument, baseOptions);
      }
export function useLoadAvailabilitiesByStudentIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadAvailabilitiesByStudentIdsQuery, LoadAvailabilitiesByStudentIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadAvailabilitiesByStudentIdsQuery, LoadAvailabilitiesByStudentIdsQueryVariables>(LoadAvailabilitiesByStudentIdsDocument, baseOptions);
        }
export type LoadAvailabilitiesByStudentIdsQueryHookResult = ReturnType<typeof useLoadAvailabilitiesByStudentIdsQuery>;
export type LoadAvailabilitiesByStudentIdsLazyQueryHookResult = ReturnType<typeof useLoadAvailabilitiesByStudentIdsLazyQuery>;
export type LoadAvailabilitiesByStudentIdsQueryResult = ApolloReactCommon.QueryResult<LoadAvailabilitiesByStudentIdsQuery, LoadAvailabilitiesByStudentIdsQueryVariables>;
export const LoadProductsByStripeIdsDocument = gql`
    query LoadProductsByStripeIds($ids: [String!]!) {
  productsByStripeIds(ids: $ids) {
    items {
      name
      format
      stripeId
      scheduleFrequency {
        appointmentTypeIDs
        weeklyFrequency
      }
    }
  }
}
    `;

/**
 * __useLoadProductsByStripeIdsQuery__
 *
 * To run a query within a React component, call `useLoadProductsByStripeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadProductsByStripeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadProductsByStripeIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoadProductsByStripeIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadProductsByStripeIdsQuery, LoadProductsByStripeIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadProductsByStripeIdsQuery, LoadProductsByStripeIdsQueryVariables>(LoadProductsByStripeIdsDocument, baseOptions);
      }
export function useLoadProductsByStripeIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadProductsByStripeIdsQuery, LoadProductsByStripeIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadProductsByStripeIdsQuery, LoadProductsByStripeIdsQueryVariables>(LoadProductsByStripeIdsDocument, baseOptions);
        }
export type LoadProductsByStripeIdsQueryHookResult = ReturnType<typeof useLoadProductsByStripeIdsQuery>;
export type LoadProductsByStripeIdsLazyQueryHookResult = ReturnType<typeof useLoadProductsByStripeIdsLazyQuery>;
export type LoadProductsByStripeIdsQueryResult = ApolloReactCommon.QueryResult<LoadProductsByStripeIdsQuery, LoadProductsByStripeIdsQueryVariables>;
export const CreateStudentDocument = gql`
    mutation CreateStudent($input: CreateStudentInput!) {
  createStudent(input: $input) {
    student {
      _id
    }
  }
}
    `;
export type CreateStudentMutationFn = ApolloReactCommon.MutationFunction<CreateStudentMutation, CreateStudentMutationVariables>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStudentMutation, CreateStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStudentMutation, CreateStudentMutationVariables>(CreateStudentDocument, baseOptions);
      }
export type CreateStudentMutationHookResult = ReturnType<typeof useCreateStudentMutation>;
export type CreateStudentMutationResult = ApolloReactCommon.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStudentMutation, CreateStudentMutationVariables>;
export const PrependStudentNotesDocument = gql`
    mutation PrependStudentNotes($input: PrependStudentNotesInput!) {
  prependStudentNotes(input: $input) {
    success
  }
}
    `;
export type PrependStudentNotesMutationFn = ApolloReactCommon.MutationFunction<PrependStudentNotesMutation, PrependStudentNotesMutationVariables>;

/**
 * __usePrependStudentNotesMutation__
 *
 * To run a mutation, you first call `usePrependStudentNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrependStudentNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prependStudentNotesMutation, { data, loading, error }] = usePrependStudentNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrependStudentNotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrependStudentNotesMutation, PrependStudentNotesMutationVariables>) {
        return ApolloReactHooks.useMutation<PrependStudentNotesMutation, PrependStudentNotesMutationVariables>(PrependStudentNotesDocument, baseOptions);
      }
export type PrependStudentNotesMutationHookResult = ReturnType<typeof usePrependStudentNotesMutation>;
export type PrependStudentNotesMutationResult = ApolloReactCommon.MutationResult<PrependStudentNotesMutation>;
export type PrependStudentNotesMutationOptions = ApolloReactCommon.BaseMutationOptions<PrependStudentNotesMutation, PrependStudentNotesMutationVariables>;
export const UpdateLearningPreferencesDocument = gql`
    mutation UpdateLearningPreferences($input: UpdateLearningPreferencesInput!) {
  updateLearningPreferences(input: $input) {
    updatedLearningPreference {
      subject
      lessonPace
      homeworkLoad
      noteForInstructor
      updatedAt
    }
  }
}
    `;
export type UpdateLearningPreferencesMutationFn = ApolloReactCommon.MutationFunction<UpdateLearningPreferencesMutation, UpdateLearningPreferencesMutationVariables>;

/**
 * __useUpdateLearningPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateLearningPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningPreferencesMutation, { data, loading, error }] = useUpdateLearningPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLearningPreferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLearningPreferencesMutation, UpdateLearningPreferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLearningPreferencesMutation, UpdateLearningPreferencesMutationVariables>(UpdateLearningPreferencesDocument, baseOptions);
      }
export type UpdateLearningPreferencesMutationHookResult = ReturnType<typeof useUpdateLearningPreferencesMutation>;
export type UpdateLearningPreferencesMutationResult = ApolloReactCommon.MutationResult<UpdateLearningPreferencesMutation>;
export type UpdateLearningPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLearningPreferencesMutation, UpdateLearningPreferencesMutationVariables>;
export const UpdateCertificationTracksDocument = gql`
    mutation UpdateCertificationTracks($input: UpdateStudentInput!) {
  updateStudent(input: $input) {
    student {
      certificationTracks {
        name
        isFavorite
      }
    }
  }
}
    `;
export type UpdateCertificationTracksMutationFn = ApolloReactCommon.MutationFunction<UpdateCertificationTracksMutation, UpdateCertificationTracksMutationVariables>;

/**
 * __useUpdateCertificationTracksMutation__
 *
 * To run a mutation, you first call `useUpdateCertificationTracksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertificationTracksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertificationTracksMutation, { data, loading, error }] = useUpdateCertificationTracksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCertificationTracksMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCertificationTracksMutation, UpdateCertificationTracksMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCertificationTracksMutation, UpdateCertificationTracksMutationVariables>(UpdateCertificationTracksDocument, baseOptions);
      }
export type UpdateCertificationTracksMutationHookResult = ReturnType<typeof useUpdateCertificationTracksMutation>;
export type UpdateCertificationTracksMutationResult = ApolloReactCommon.MutationResult<UpdateCertificationTracksMutation>;
export type UpdateCertificationTracksMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCertificationTracksMutation, UpdateCertificationTracksMutationVariables>;
export const LoadCourseRubricForLearnerDocument = gql`
    query LoadCourseRubricForLearner($input: CourseRubricForLearnerInput!) {
  courseRubricForLearner(input: $input) {
    ...CourseRubricProgressFragment
  }
}
    ${CourseRubricProgressFragmentFragmentDoc}`;

/**
 * __useLoadCourseRubricForLearnerQuery__
 *
 * To run a query within a React component, call `useLoadCourseRubricForLearnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCourseRubricForLearnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCourseRubricForLearnerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadCourseRubricForLearnerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadCourseRubricForLearnerQuery, LoadCourseRubricForLearnerQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadCourseRubricForLearnerQuery, LoadCourseRubricForLearnerQueryVariables>(LoadCourseRubricForLearnerDocument, baseOptions);
      }
export function useLoadCourseRubricForLearnerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadCourseRubricForLearnerQuery, LoadCourseRubricForLearnerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadCourseRubricForLearnerQuery, LoadCourseRubricForLearnerQueryVariables>(LoadCourseRubricForLearnerDocument, baseOptions);
        }
export type LoadCourseRubricForLearnerQueryHookResult = ReturnType<typeof useLoadCourseRubricForLearnerQuery>;
export type LoadCourseRubricForLearnerLazyQueryHookResult = ReturnType<typeof useLoadCourseRubricForLearnerLazyQuery>;
export type LoadCourseRubricForLearnerQueryResult = ApolloReactCommon.QueryResult<LoadCourseRubricForLearnerQuery, LoadCourseRubricForLearnerQueryVariables>;
export const StudentBundleCertificateParamsDocument = gql`
    query StudentBundleCertificateParams($studentId: ObjectId!) {
  studentCertificateParams(studentId: $studentId, certificateType: BUNDLE) {
    items {
      bundleName
      params
    }
  }
}
    `;

/**
 * __useStudentBundleCertificateParamsQuery__
 *
 * To run a query within a React component, call `useStudentBundleCertificateParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentBundleCertificateParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentBundleCertificateParamsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useStudentBundleCertificateParamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentBundleCertificateParamsQuery, StudentBundleCertificateParamsQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentBundleCertificateParamsQuery, StudentBundleCertificateParamsQueryVariables>(StudentBundleCertificateParamsDocument, baseOptions);
      }
export function useStudentBundleCertificateParamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentBundleCertificateParamsQuery, StudentBundleCertificateParamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentBundleCertificateParamsQuery, StudentBundleCertificateParamsQueryVariables>(StudentBundleCertificateParamsDocument, baseOptions);
        }
export type StudentBundleCertificateParamsQueryHookResult = ReturnType<typeof useStudentBundleCertificateParamsQuery>;
export type StudentBundleCertificateParamsLazyQueryHookResult = ReturnType<typeof useStudentBundleCertificateParamsLazyQuery>;
export type StudentBundleCertificateParamsQueryResult = ApolloReactCommon.QueryResult<StudentBundleCertificateParamsQuery, StudentBundleCertificateParamsQueryVariables>;
export const StudentCertificateParamsDocument = gql`
    query StudentCertificateParams($studentId: ObjectId!) {
  studentCertificateParams(studentId: $studentId, certificateType: COURSE) {
    items {
      courseName
      params
    }
  }
}
    `;

/**
 * __useStudentCertificateParamsQuery__
 *
 * To run a query within a React component, call `useStudentCertificateParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCertificateParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCertificateParamsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useStudentCertificateParamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentCertificateParamsQuery, StudentCertificateParamsQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentCertificateParamsQuery, StudentCertificateParamsQueryVariables>(StudentCertificateParamsDocument, baseOptions);
      }
export function useStudentCertificateParamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentCertificateParamsQuery, StudentCertificateParamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentCertificateParamsQuery, StudentCertificateParamsQueryVariables>(StudentCertificateParamsDocument, baseOptions);
        }
export type StudentCertificateParamsQueryHookResult = ReturnType<typeof useStudentCertificateParamsQuery>;
export type StudentCertificateParamsLazyQueryHookResult = ReturnType<typeof useStudentCertificateParamsLazyQuery>;
export type StudentCertificateParamsQueryResult = ApolloReactCommon.QueryResult<StudentCertificateParamsQuery, StudentCertificateParamsQueryVariables>;
export const GetAnnouncementsDocument = gql`
    query GetAnnouncements {
  queryAnnouncements {
    items {
      _id
      backgroundImageURL
      title
      blurb
      description
    }
  }
}
    `;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnouncementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, baseOptions);
      }
export function useGetAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, baseOptions);
        }
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsQueryResult = ApolloReactCommon.QueryResult<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>;
export const LoadPopupDocument = gql`
    query LoadPopup($property: String!) {
  serveJuniAd(juniAdProperty: $property) {
    items {
      _id
      title
      imageS3Url
      copy
      primaryAction {
        label
        clickthroughUrl
      }
      secondaryAction {
        label
        clickthroughUrl
      }
    }
  }
}
    `;

/**
 * __useLoadPopupQuery__
 *
 * To run a query within a React component, call `useLoadPopupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPopupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPopupQuery({
 *   variables: {
 *      property: // value for 'property'
 *   },
 * });
 */
export function useLoadPopupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadPopupQuery, LoadPopupQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadPopupQuery, LoadPopupQueryVariables>(LoadPopupDocument, baseOptions);
      }
export function useLoadPopupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadPopupQuery, LoadPopupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadPopupQuery, LoadPopupQueryVariables>(LoadPopupDocument, baseOptions);
        }
export type LoadPopupQueryHookResult = ReturnType<typeof useLoadPopupQuery>;
export type LoadPopupLazyQueryHookResult = ReturnType<typeof useLoadPopupLazyQuery>;
export type LoadPopupQueryResult = ApolloReactCommon.QueryResult<LoadPopupQuery, LoadPopupQueryVariables>;
export const LoadMyStripeCustomerDocument = gql`
    query LoadMyStripeCustomer {
  myStripeCustomer {
    ...StripeCustomer
  }
}
    ${StripeCustomerFragmentDoc}`;

/**
 * __useLoadMyStripeCustomerQuery__
 *
 * To run a query within a React component, call `useLoadMyStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMyStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMyStripeCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadMyStripeCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadMyStripeCustomerQuery, LoadMyStripeCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadMyStripeCustomerQuery, LoadMyStripeCustomerQueryVariables>(LoadMyStripeCustomerDocument, baseOptions);
      }
export function useLoadMyStripeCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadMyStripeCustomerQuery, LoadMyStripeCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadMyStripeCustomerQuery, LoadMyStripeCustomerQueryVariables>(LoadMyStripeCustomerDocument, baseOptions);
        }
export type LoadMyStripeCustomerQueryHookResult = ReturnType<typeof useLoadMyStripeCustomerQuery>;
export type LoadMyStripeCustomerLazyQueryHookResult = ReturnType<typeof useLoadMyStripeCustomerLazyQuery>;
export type LoadMyStripeCustomerQueryResult = ApolloReactCommon.QueryResult<LoadMyStripeCustomerQuery, LoadMyStripeCustomerQueryVariables>;
export const LoadMyUnpaidStripeChargesDocument = gql`
    query LoadMyUnpaidStripeCharges {
  myUnpaidStripeCharges {
    items {
      ...StripeCharge
    }
  }
}
    ${StripeChargeFragmentDoc}`;

/**
 * __useLoadMyUnpaidStripeChargesQuery__
 *
 * To run a query within a React component, call `useLoadMyUnpaidStripeChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMyUnpaidStripeChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMyUnpaidStripeChargesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadMyUnpaidStripeChargesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadMyUnpaidStripeChargesQuery, LoadMyUnpaidStripeChargesQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadMyUnpaidStripeChargesQuery, LoadMyUnpaidStripeChargesQueryVariables>(LoadMyUnpaidStripeChargesDocument, baseOptions);
      }
export function useLoadMyUnpaidStripeChargesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadMyUnpaidStripeChargesQuery, LoadMyUnpaidStripeChargesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadMyUnpaidStripeChargesQuery, LoadMyUnpaidStripeChargesQueryVariables>(LoadMyUnpaidStripeChargesDocument, baseOptions);
        }
export type LoadMyUnpaidStripeChargesQueryHookResult = ReturnType<typeof useLoadMyUnpaidStripeChargesQuery>;
export type LoadMyUnpaidStripeChargesLazyQueryHookResult = ReturnType<typeof useLoadMyUnpaidStripeChargesLazyQuery>;
export type LoadMyUnpaidStripeChargesQueryResult = ApolloReactCommon.QueryResult<LoadMyUnpaidStripeChargesQuery, LoadMyUnpaidStripeChargesQueryVariables>;
export const LoadStripeCustomerByParentIdDocument = gql`
    query LoadStripeCustomerByParentId($input: ObjectId!) {
  stripeCustomerByParentId(id: $input) {
    ...StripeCustomer
  }
}
    ${StripeCustomerFragmentDoc}`;

/**
 * __useLoadStripeCustomerByParentIdQuery__
 *
 * To run a query within a React component, call `useLoadStripeCustomerByParentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStripeCustomerByParentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStripeCustomerByParentIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadStripeCustomerByParentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadStripeCustomerByParentIdQuery, LoadStripeCustomerByParentIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadStripeCustomerByParentIdQuery, LoadStripeCustomerByParentIdQueryVariables>(LoadStripeCustomerByParentIdDocument, baseOptions);
      }
export function useLoadStripeCustomerByParentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadStripeCustomerByParentIdQuery, LoadStripeCustomerByParentIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadStripeCustomerByParentIdQuery, LoadStripeCustomerByParentIdQueryVariables>(LoadStripeCustomerByParentIdDocument, baseOptions);
        }
export type LoadStripeCustomerByParentIdQueryHookResult = ReturnType<typeof useLoadStripeCustomerByParentIdQuery>;
export type LoadStripeCustomerByParentIdLazyQueryHookResult = ReturnType<typeof useLoadStripeCustomerByParentIdLazyQuery>;
export type LoadStripeCustomerByParentIdQueryResult = ApolloReactCommon.QueryResult<LoadStripeCustomerByParentIdQuery, LoadStripeCustomerByParentIdQueryVariables>;
export const LoadUnpaidStripeChargesByStripeCustomerIdDocument = gql`
    query LoadUnpaidStripeChargesByStripeCustomerId($input: String!) {
  unpaidStripeChargesByStripeCustomerId(id: $input) {
    items {
      ...StripeCharge
    }
  }
}
    ${StripeChargeFragmentDoc}`;

/**
 * __useLoadUnpaidStripeChargesByStripeCustomerIdQuery__
 *
 * To run a query within a React component, call `useLoadUnpaidStripeChargesByStripeCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUnpaidStripeChargesByStripeCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUnpaidStripeChargesByStripeCustomerIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadUnpaidStripeChargesByStripeCustomerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadUnpaidStripeChargesByStripeCustomerIdQuery, LoadUnpaidStripeChargesByStripeCustomerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadUnpaidStripeChargesByStripeCustomerIdQuery, LoadUnpaidStripeChargesByStripeCustomerIdQueryVariables>(LoadUnpaidStripeChargesByStripeCustomerIdDocument, baseOptions);
      }
export function useLoadUnpaidStripeChargesByStripeCustomerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadUnpaidStripeChargesByStripeCustomerIdQuery, LoadUnpaidStripeChargesByStripeCustomerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadUnpaidStripeChargesByStripeCustomerIdQuery, LoadUnpaidStripeChargesByStripeCustomerIdQueryVariables>(LoadUnpaidStripeChargesByStripeCustomerIdDocument, baseOptions);
        }
export type LoadUnpaidStripeChargesByStripeCustomerIdQueryHookResult = ReturnType<typeof useLoadUnpaidStripeChargesByStripeCustomerIdQuery>;
export type LoadUnpaidStripeChargesByStripeCustomerIdLazyQueryHookResult = ReturnType<typeof useLoadUnpaidStripeChargesByStripeCustomerIdLazyQuery>;
export type LoadUnpaidStripeChargesByStripeCustomerIdQueryResult = ApolloReactCommon.QueryResult<LoadUnpaidStripeChargesByStripeCustomerIdQuery, LoadUnpaidStripeChargesByStripeCustomerIdQueryVariables>;
export const GetReferralInvitesDocument = gql`
    query GetReferralInvites {
  getReferralInvites {
    items {
      _id
      referralCode
      name
      email
      source
      status
      createdAt
      reminderLastSentAt
    }
  }
}
    `;

/**
 * __useGetReferralInvitesQuery__
 *
 * To run a query within a React component, call `useGetReferralInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralInvitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferralInvitesQuery, GetReferralInvitesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReferralInvitesQuery, GetReferralInvitesQueryVariables>(GetReferralInvitesDocument, baseOptions);
      }
export function useGetReferralInvitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralInvitesQuery, GetReferralInvitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReferralInvitesQuery, GetReferralInvitesQueryVariables>(GetReferralInvitesDocument, baseOptions);
        }
export type GetReferralInvitesQueryHookResult = ReturnType<typeof useGetReferralInvitesQuery>;
export type GetReferralInvitesLazyQueryHookResult = ReturnType<typeof useGetReferralInvitesLazyQuery>;
export type GetReferralInvitesQueryResult = ApolloReactCommon.QueryResult<GetReferralInvitesQuery, GetReferralInvitesQueryVariables>;
export const GetPreviewReferralEmailDocument = gql`
    query GetPreviewReferralEmail($isReminder: Boolean!) {
  getPreviewReferralEmail(isReminder: $isReminder)
}
    `;

/**
 * __useGetPreviewReferralEmailQuery__
 *
 * To run a query within a React component, call `useGetPreviewReferralEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewReferralEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewReferralEmailQuery({
 *   variables: {
 *      isReminder: // value for 'isReminder'
 *   },
 * });
 */
export function useGetPreviewReferralEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPreviewReferralEmailQuery, GetPreviewReferralEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPreviewReferralEmailQuery, GetPreviewReferralEmailQueryVariables>(GetPreviewReferralEmailDocument, baseOptions);
      }
export function useGetPreviewReferralEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPreviewReferralEmailQuery, GetPreviewReferralEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPreviewReferralEmailQuery, GetPreviewReferralEmailQueryVariables>(GetPreviewReferralEmailDocument, baseOptions);
        }
export type GetPreviewReferralEmailQueryHookResult = ReturnType<typeof useGetPreviewReferralEmailQuery>;
export type GetPreviewReferralEmailLazyQueryHookResult = ReturnType<typeof useGetPreviewReferralEmailLazyQuery>;
export type GetPreviewReferralEmailQueryResult = ApolloReactCommon.QueryResult<GetPreviewReferralEmailQuery, GetPreviewReferralEmailQueryVariables>;
export const GetReferralRedemptionsDocument = gql`
    query GetReferralRedemptions {
  getReferralRedemptions {
    items {
      _id
      referralCodeId
      email
      redeemedByParentId
      customerLogId
      status
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetReferralRedemptionsQuery__
 *
 * To run a query within a React component, call `useGetReferralRedemptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralRedemptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralRedemptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralRedemptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferralRedemptionsQuery, GetReferralRedemptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReferralRedemptionsQuery, GetReferralRedemptionsQueryVariables>(GetReferralRedemptionsDocument, baseOptions);
      }
export function useGetReferralRedemptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralRedemptionsQuery, GetReferralRedemptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReferralRedemptionsQuery, GetReferralRedemptionsQueryVariables>(GetReferralRedemptionsDocument, baseOptions);
        }
export type GetReferralRedemptionsQueryHookResult = ReturnType<typeof useGetReferralRedemptionsQuery>;
export type GetReferralRedemptionsLazyQueryHookResult = ReturnType<typeof useGetReferralRedemptionsLazyQuery>;
export type GetReferralRedemptionsQueryResult = ApolloReactCommon.QueryResult<GetReferralRedemptionsQuery, GetReferralRedemptionsQueryVariables>;
export const GetReferralCodeDocument = gql`
    query GetReferralCode {
  getReferralCode {
    _id
    name
    email
    code
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetReferralCodeQuery__
 *
 * To run a query within a React component, call `useGetReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReferralCodeQuery, GetReferralCodeQueryVariables>(GetReferralCodeDocument, baseOptions);
      }
export function useGetReferralCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReferralCodeQuery, GetReferralCodeQueryVariables>(GetReferralCodeDocument, baseOptions);
        }
export type GetReferralCodeQueryHookResult = ReturnType<typeof useGetReferralCodeQuery>;
export type GetReferralCodeLazyQueryHookResult = ReturnType<typeof useGetReferralCodeLazyQuery>;
export type GetReferralCodeQueryResult = ApolloReactCommon.QueryResult<GetReferralCodeQuery, GetReferralCodeQueryVariables>;
export const SendReferralInviteDocument = gql`
    mutation SendReferralInvite($input: SendReferralInviteInput!) {
  sendReferralInvite(input: $input) {
    referralInvite {
      _id
      referralCode
      name
      email
      source
      status
      createdAt
      reminderLastSentAt
    }
  }
}
    `;
export type SendReferralInviteMutationFn = ApolloReactCommon.MutationFunction<SendReferralInviteMutation, SendReferralInviteMutationVariables>;

/**
 * __useSendReferralInviteMutation__
 *
 * To run a mutation, you first call `useSendReferralInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReferralInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReferralInviteMutation, { data, loading, error }] = useSendReferralInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendReferralInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendReferralInviteMutation, SendReferralInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<SendReferralInviteMutation, SendReferralInviteMutationVariables>(SendReferralInviteDocument, baseOptions);
      }
export type SendReferralInviteMutationHookResult = ReturnType<typeof useSendReferralInviteMutation>;
export type SendReferralInviteMutationResult = ApolloReactCommon.MutationResult<SendReferralInviteMutation>;
export type SendReferralInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<SendReferralInviteMutation, SendReferralInviteMutationVariables>;
export const SendReferralReminderEmailDocument = gql`
    mutation SendReferralReminderEmail($input: SendReferralReminderEmailInput!) {
  sendReferralReminderEmail(input: $input) {
    updatedInvite {
      _id
      referralCode
      name
      email
      source
      status
      createdAt
      reminderLastSentAt
    }
  }
}
    `;
export type SendReferralReminderEmailMutationFn = ApolloReactCommon.MutationFunction<SendReferralReminderEmailMutation, SendReferralReminderEmailMutationVariables>;

/**
 * __useSendReferralReminderEmailMutation__
 *
 * To run a mutation, you first call `useSendReferralReminderEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReferralReminderEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReferralReminderEmailMutation, { data, loading, error }] = useSendReferralReminderEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendReferralReminderEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendReferralReminderEmailMutation, SendReferralReminderEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendReferralReminderEmailMutation, SendReferralReminderEmailMutationVariables>(SendReferralReminderEmailDocument, baseOptions);
      }
export type SendReferralReminderEmailMutationHookResult = ReturnType<typeof useSendReferralReminderEmailMutation>;
export type SendReferralReminderEmailMutationResult = ApolloReactCommon.MutationResult<SendReferralReminderEmailMutation>;
export type SendReferralReminderEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendReferralReminderEmailMutation, SendReferralReminderEmailMutationVariables>;
export const GetRecapPageDataDocument = gql`
    query GetRecapPageData($userReportId: ObjectId!) {
  studentRecapByUserReportId(userReportId: $userReportId) {
    userInfo {
      studentInfo {
        firstName
        lastName
        username
        activeCoursesProgress {
          displayName
          numModulesCompleted
          totalNumModules
          iconName
          primaryColor
        }
      }
      referralCode
      parentFirstName
    }
    userReport {
      projectViewsThisMonth
      projectViewsAllTime
      projectsPublishedAllTime
      projectsPublishedThisMonth
      projectsWorkedOnAllTime
      projectsWorkedOnThisMonth
    }
    recentProjects {
      projectName
      updatedAt
      courseName
      subjectName
      publicProjectUrl
      sectionType
    }
  }
}
    `;

/**
 * __useGetRecapPageDataQuery__
 *
 * To run a query within a React component, call `useGetRecapPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecapPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecapPageDataQuery({
 *   variables: {
 *      userReportId: // value for 'userReportId'
 *   },
 * });
 */
export function useGetRecapPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecapPageDataQuery, GetRecapPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecapPageDataQuery, GetRecapPageDataQueryVariables>(GetRecapPageDataDocument, baseOptions);
      }
export function useGetRecapPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecapPageDataQuery, GetRecapPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecapPageDataQuery, GetRecapPageDataQueryVariables>(GetRecapPageDataDocument, baseOptions);
        }
export type GetRecapPageDataQueryHookResult = ReturnType<typeof useGetRecapPageDataQuery>;
export type GetRecapPageDataLazyQueryHookResult = ReturnType<typeof useGetRecapPageDataLazyQuery>;
export type GetRecapPageDataQueryResult = ApolloReactCommon.QueryResult<GetRecapPageDataQuery, GetRecapPageDataQueryVariables>;
export const RescheduleClassDocument = gql`
    mutation rescheduleClass($input: RescheduleClassInput!) {
  rescheduleClass(input: $input) {
    success
  }
}
    `;
export type RescheduleClassMutationFn = ApolloReactCommon.MutationFunction<RescheduleClassMutation, RescheduleClassMutationVariables>;

/**
 * __useRescheduleClassMutation__
 *
 * To run a mutation, you first call `useRescheduleClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleClassMutation, { data, loading, error }] = useRescheduleClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleClassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RescheduleClassMutation, RescheduleClassMutationVariables>) {
        return ApolloReactHooks.useMutation<RescheduleClassMutation, RescheduleClassMutationVariables>(RescheduleClassDocument, baseOptions);
      }
export type RescheduleClassMutationHookResult = ReturnType<typeof useRescheduleClassMutation>;
export type RescheduleClassMutationResult = ApolloReactCommon.MutationResult<RescheduleClassMutation>;
export type RescheduleClassMutationOptions = ApolloReactCommon.BaseMutationOptions<RescheduleClassMutation, RescheduleClassMutationVariables>;
export const ScheduleAdditionalClassDocument = gql`
    mutation ScheduleAdditionalClass($input: ScheduleAdditionalClassInput!) {
  scheduleAdditionalClass(input: $input) {
    additionalClassId
    selectedAppointmentSlot
  }
}
    `;
export type ScheduleAdditionalClassMutationFn = ApolloReactCommon.MutationFunction<ScheduleAdditionalClassMutation, ScheduleAdditionalClassMutationVariables>;

/**
 * __useScheduleAdditionalClassMutation__
 *
 * To run a mutation, you first call `useScheduleAdditionalClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleAdditionalClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleAdditionalClassMutation, { data, loading, error }] = useScheduleAdditionalClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleAdditionalClassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleAdditionalClassMutation, ScheduleAdditionalClassMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleAdditionalClassMutation, ScheduleAdditionalClassMutationVariables>(ScheduleAdditionalClassDocument, baseOptions);
      }
export type ScheduleAdditionalClassMutationHookResult = ReturnType<typeof useScheduleAdditionalClassMutation>;
export type ScheduleAdditionalClassMutationResult = ApolloReactCommon.MutationResult<ScheduleAdditionalClassMutation>;
export type ScheduleAdditionalClassMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleAdditionalClassMutation, ScheduleAdditionalClassMutationVariables>;
export const GetAvailableAppointmentSlotsDocument = gql`
    query GetAvailableAppointmentSlots($instructorId: ObjectId, $instructorAcuityId: String, $appointmentTypeId: Int!, $startDatetime: DateTime!, $endDatetime: DateTime!) {
  availableAppointmentSlots(
    instructorId: $instructorId
    instructorAcuityId: $instructorAcuityId
    appointmentTypeId: $appointmentTypeId
    startDatetime: $startDatetime
    endDatetime: $endDatetime
  )
}
    `;

/**
 * __useGetAvailableAppointmentSlotsQuery__
 *
 * To run a query within a React component, call `useGetAvailableAppointmentSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableAppointmentSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableAppointmentSlotsQuery({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *      instructorAcuityId: // value for 'instructorAcuityId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *      startDatetime: // value for 'startDatetime'
 *      endDatetime: // value for 'endDatetime'
 *   },
 * });
 */
export function useGetAvailableAppointmentSlotsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvailableAppointmentSlotsQuery, GetAvailableAppointmentSlotsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAvailableAppointmentSlotsQuery, GetAvailableAppointmentSlotsQueryVariables>(GetAvailableAppointmentSlotsDocument, baseOptions);
      }
export function useGetAvailableAppointmentSlotsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailableAppointmentSlotsQuery, GetAvailableAppointmentSlotsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAvailableAppointmentSlotsQuery, GetAvailableAppointmentSlotsQueryVariables>(GetAvailableAppointmentSlotsDocument, baseOptions);
        }
export type GetAvailableAppointmentSlotsQueryHookResult = ReturnType<typeof useGetAvailableAppointmentSlotsQuery>;
export type GetAvailableAppointmentSlotsLazyQueryHookResult = ReturnType<typeof useGetAvailableAppointmentSlotsLazyQuery>;
export type GetAvailableAppointmentSlotsQueryResult = ApolloReactCommon.QueryResult<GetAvailableAppointmentSlotsQuery, GetAvailableAppointmentSlotsQueryVariables>;
export const GetRecurringlyAvailableAppointmentSlotsDocument = gql`
    query GetRecurringlyAvailableAppointmentSlots($instructorId: ObjectId, $instructorAcuityId: String, $appointmentTypeId: Int!, $startDatetime: DateTime!, $endDatetime: DateTime!, $timezone: String!) {
  recurringlyAvailableAppointmentSlots(
    instructorId: $instructorId
    instructorAcuityId: $instructorAcuityId
    appointmentTypeId: $appointmentTypeId
    startDatetime: $startDatetime
    endDatetime: $endDatetime
    timezone: $timezone
  )
}
    `;

/**
 * __useGetRecurringlyAvailableAppointmentSlotsQuery__
 *
 * To run a query within a React component, call `useGetRecurringlyAvailableAppointmentSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringlyAvailableAppointmentSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringlyAvailableAppointmentSlotsQuery({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *      instructorAcuityId: // value for 'instructorAcuityId'
 *      appointmentTypeId: // value for 'appointmentTypeId'
 *      startDatetime: // value for 'startDatetime'
 *      endDatetime: // value for 'endDatetime'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useGetRecurringlyAvailableAppointmentSlotsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurringlyAvailableAppointmentSlotsQuery, GetRecurringlyAvailableAppointmentSlotsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurringlyAvailableAppointmentSlotsQuery, GetRecurringlyAvailableAppointmentSlotsQueryVariables>(GetRecurringlyAvailableAppointmentSlotsDocument, baseOptions);
      }
export function useGetRecurringlyAvailableAppointmentSlotsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurringlyAvailableAppointmentSlotsQuery, GetRecurringlyAvailableAppointmentSlotsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurringlyAvailableAppointmentSlotsQuery, GetRecurringlyAvailableAppointmentSlotsQueryVariables>(GetRecurringlyAvailableAppointmentSlotsDocument, baseOptions);
        }
export type GetRecurringlyAvailableAppointmentSlotsQueryHookResult = ReturnType<typeof useGetRecurringlyAvailableAppointmentSlotsQuery>;
export type GetRecurringlyAvailableAppointmentSlotsLazyQueryHookResult = ReturnType<typeof useGetRecurringlyAvailableAppointmentSlotsLazyQuery>;
export type GetRecurringlyAvailableAppointmentSlotsQueryResult = ApolloReactCommon.QueryResult<GetRecurringlyAvailableAppointmentSlotsQuery, GetRecurringlyAvailableAppointmentSlotsQueryVariables>;
export const CreateParentRecurringRescheduleRequestDocument = gql`
    mutation CreateParentRecurringRescheduleRequest($input: CreateParentRecurringRescheduleRequestInput!) {
  CreateParentRecurringRescheduleRequest(input: $input) {
    success
  }
}
    `;
export type CreateParentRecurringRescheduleRequestMutationFn = ApolloReactCommon.MutationFunction<CreateParentRecurringRescheduleRequestMutation, CreateParentRecurringRescheduleRequestMutationVariables>;

/**
 * __useCreateParentRecurringRescheduleRequestMutation__
 *
 * To run a mutation, you first call `useCreateParentRecurringRescheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParentRecurringRescheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParentRecurringRescheduleRequestMutation, { data, loading, error }] = useCreateParentRecurringRescheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParentRecurringRescheduleRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateParentRecurringRescheduleRequestMutation, CreateParentRecurringRescheduleRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateParentRecurringRescheduleRequestMutation, CreateParentRecurringRescheduleRequestMutationVariables>(CreateParentRecurringRescheduleRequestDocument, baseOptions);
      }
export type CreateParentRecurringRescheduleRequestMutationHookResult = ReturnType<typeof useCreateParentRecurringRescheduleRequestMutation>;
export type CreateParentRecurringRescheduleRequestMutationResult = ApolloReactCommon.MutationResult<CreateParentRecurringRescheduleRequestMutation>;
export type CreateParentRecurringRescheduleRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateParentRecurringRescheduleRequestMutation, CreateParentRecurringRescheduleRequestMutationVariables>;
export const RecordProjectViewDocument = gql`
    mutation recordProjectView($projectId: ObjectId!) {
  recordProjectView(input: {projectId: $projectId}) {
    wasRecorded
  }
}
    `;
export type RecordProjectViewMutationFn = ApolloReactCommon.MutationFunction<RecordProjectViewMutation, RecordProjectViewMutationVariables>;

/**
 * __useRecordProjectViewMutation__
 *
 * To run a mutation, you first call `useRecordProjectViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordProjectViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordProjectViewMutation, { data, loading, error }] = useRecordProjectViewMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRecordProjectViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecordProjectViewMutation, RecordProjectViewMutationVariables>) {
        return ApolloReactHooks.useMutation<RecordProjectViewMutation, RecordProjectViewMutationVariables>(RecordProjectViewDocument, baseOptions);
      }
export type RecordProjectViewMutationHookResult = ReturnType<typeof useRecordProjectViewMutation>;
export type RecordProjectViewMutationResult = ApolloReactCommon.MutationResult<RecordProjectViewMutation>;
export type RecordProjectViewMutationOptions = ApolloReactCommon.BaseMutationOptions<RecordProjectViewMutation, RecordProjectViewMutationVariables>;
export const GetUserClubsByUsernameDocument = gql`
    query GetUserClubsByUsername($username: String!) {
  getUserClubsByUsername(username: $username) {
    items {
      _id
      displayName
      description
      coverPhoto
      membershipStatus
      memberCount
    }
  }
}
    `;

/**
 * __useGetUserClubsByUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserClubsByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserClubsByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserClubsByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserClubsByUsernameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserClubsByUsernameQuery, GetUserClubsByUsernameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserClubsByUsernameQuery, GetUserClubsByUsernameQueryVariables>(GetUserClubsByUsernameDocument, baseOptions);
      }
export function useGetUserClubsByUsernameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserClubsByUsernameQuery, GetUserClubsByUsernameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserClubsByUsernameQuery, GetUserClubsByUsernameQueryVariables>(GetUserClubsByUsernameDocument, baseOptions);
        }
export type GetUserClubsByUsernameQueryHookResult = ReturnType<typeof useGetUserClubsByUsernameQuery>;
export type GetUserClubsByUsernameLazyQueryHookResult = ReturnType<typeof useGetUserClubsByUsernameLazyQuery>;
export type GetUserClubsByUsernameQueryResult = ApolloReactCommon.QueryResult<GetUserClubsByUsernameQuery, GetUserClubsByUsernameQueryVariables>;
export const GetJuniverseUserProfileByUsernameDocument = gql`
    query GetJuniverseUserProfileByUsername($username: String!) {
  juniverseUserProfileByUsername(username: $username) {
    _id
    type
    username
    firstName
    lastInitial
    publicProjects {
      _id
      title
      description
      moduleSection {
        _id
        displayName
      }
      thumbnailUrl
      juniverseTags
      attachedUsermediaUrls
      projectType
      lastUpdatedAt
      creator {
        username
        type
      }
      viewCount
    }
    featuredProjects {
      _id
      title
      description
      moduleSection {
        _id
        displayName
      }
      thumbnailUrl
      juniverseTags
      attachedUsermediaUrls
      projectType
      lastUpdatedAt
      creator {
        username
        type
      }
      viewCount
    }
    courseProgresses {
      course {
        _id
        name
        displayNameMajor
        displayNameMinor
        subject
      }
      isComplete
    }
    achievements {
      name
      thumbnailUrl
      externalDescription
    }
    isGuest
    projectViewsAllTime
    projectsPublishedAllTime
    createdAt
  }
}
    `;

/**
 * __useGetJuniverseUserProfileByUsernameQuery__
 *
 * To run a query within a React component, call `useGetJuniverseUserProfileByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuniverseUserProfileByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuniverseUserProfileByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetJuniverseUserProfileByUsernameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJuniverseUserProfileByUsernameQuery, GetJuniverseUserProfileByUsernameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJuniverseUserProfileByUsernameQuery, GetJuniverseUserProfileByUsernameQueryVariables>(GetJuniverseUserProfileByUsernameDocument, baseOptions);
      }
export function useGetJuniverseUserProfileByUsernameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJuniverseUserProfileByUsernameQuery, GetJuniverseUserProfileByUsernameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJuniverseUserProfileByUsernameQuery, GetJuniverseUserProfileByUsernameQueryVariables>(GetJuniverseUserProfileByUsernameDocument, baseOptions);
        }
export type GetJuniverseUserProfileByUsernameQueryHookResult = ReturnType<typeof useGetJuniverseUserProfileByUsernameQuery>;
export type GetJuniverseUserProfileByUsernameLazyQueryHookResult = ReturnType<typeof useGetJuniverseUserProfileByUsernameLazyQuery>;
export type GetJuniverseUserProfileByUsernameQueryResult = ApolloReactCommon.QueryResult<GetJuniverseUserProfileByUsernameQuery, GetJuniverseUserProfileByUsernameQueryVariables>;
export const CourseCompletionDatesDocument = gql`
    query CourseCompletionDates($studentId: ObjectId!) {
  courseCompletionDates(studentId: $studentId) {
    items {
      courseName
      completionDate
    }
  }
}
    `;

/**
 * __useCourseCompletionDatesQuery__
 *
 * To run a query within a React component, call `useCourseCompletionDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCompletionDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCompletionDatesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useCourseCompletionDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseCompletionDatesQuery, CourseCompletionDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseCompletionDatesQuery, CourseCompletionDatesQueryVariables>(CourseCompletionDatesDocument, baseOptions);
      }
export function useCourseCompletionDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseCompletionDatesQuery, CourseCompletionDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseCompletionDatesQuery, CourseCompletionDatesQueryVariables>(CourseCompletionDatesDocument, baseOptions);
        }
export type CourseCompletionDatesQueryHookResult = ReturnType<typeof useCourseCompletionDatesQuery>;
export type CourseCompletionDatesLazyQueryHookResult = ReturnType<typeof useCourseCompletionDatesLazyQuery>;
export type CourseCompletionDatesQueryResult = ApolloReactCommon.QueryResult<CourseCompletionDatesQuery, CourseCompletionDatesQueryVariables>;
export const ParentCornerInstructorCardsForStudentDocument = gql`
    query parentCornerInstructorCardsForStudent($studentId: ObjectId!) {
  parentCornerInstructorCardsForStudent(studentId: $studentId) {
    items {
      _id
      firstName
      lastName
      bio
      achievements {
        _id
        name
        thumbnailUrl
        externalDescription
      }
    }
  }
}
    `;

/**
 * __useParentCornerInstructorCardsForStudentQuery__
 *
 * To run a query within a React component, call `useParentCornerInstructorCardsForStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentCornerInstructorCardsForStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentCornerInstructorCardsForStudentQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useParentCornerInstructorCardsForStudentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParentCornerInstructorCardsForStudentQuery, ParentCornerInstructorCardsForStudentQueryVariables>) {
        return ApolloReactHooks.useQuery<ParentCornerInstructorCardsForStudentQuery, ParentCornerInstructorCardsForStudentQueryVariables>(ParentCornerInstructorCardsForStudentDocument, baseOptions);
      }
export function useParentCornerInstructorCardsForStudentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParentCornerInstructorCardsForStudentQuery, ParentCornerInstructorCardsForStudentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParentCornerInstructorCardsForStudentQuery, ParentCornerInstructorCardsForStudentQueryVariables>(ParentCornerInstructorCardsForStudentDocument, baseOptions);
        }
export type ParentCornerInstructorCardsForStudentQueryHookResult = ReturnType<typeof useParentCornerInstructorCardsForStudentQuery>;
export type ParentCornerInstructorCardsForStudentLazyQueryHookResult = ReturnType<typeof useParentCornerInstructorCardsForStudentLazyQuery>;
export type ParentCornerInstructorCardsForStudentQueryResult = ApolloReactCommon.QueryResult<ParentCornerInstructorCardsForStudentQuery, ParentCornerInstructorCardsForStudentQueryVariables>;
export const GetHelpCenterContentDocument = gql`
    query GetHelpCenterContent {
  queryHelpCenterContent {
    categories {
      ...HelpCenterCategoryFragment
    }
    sections {
      ...HelpCenterSectionFragment
    }
    articles {
      ...HelpCenterArticleFragment
    }
  }
}
    ${HelpCenterCategoryFragmentFragmentDoc}
${HelpCenterSectionFragmentFragmentDoc}
${HelpCenterArticleFragmentFragmentDoc}`;

/**
 * __useGetHelpCenterContentQuery__
 *
 * To run a query within a React component, call `useGetHelpCenterContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpCenterContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpCenterContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHelpCenterContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHelpCenterContentQuery, GetHelpCenterContentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHelpCenterContentQuery, GetHelpCenterContentQueryVariables>(GetHelpCenterContentDocument, baseOptions);
      }
export function useGetHelpCenterContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHelpCenterContentQuery, GetHelpCenterContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHelpCenterContentQuery, GetHelpCenterContentQueryVariables>(GetHelpCenterContentDocument, baseOptions);
        }
export type GetHelpCenterContentQueryHookResult = ReturnType<typeof useGetHelpCenterContentQuery>;
export type GetHelpCenterContentLazyQueryHookResult = ReturnType<typeof useGetHelpCenterContentLazyQuery>;
export type GetHelpCenterContentQueryResult = ApolloReactCommon.QueryResult<GetHelpCenterContentQuery, GetHelpCenterContentQueryVariables>;
export const GetHelpCenterArticleByIdDocument = gql`
    query GetHelpCenterArticleById($id: String!) {
  queryHelpCenterArticleById(id: $id) {
    ...HelpCenterArticleFragment
  }
}
    ${HelpCenterArticleFragmentFragmentDoc}`;

/**
 * __useGetHelpCenterArticleByIdQuery__
 *
 * To run a query within a React component, call `useGetHelpCenterArticleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpCenterArticleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpCenterArticleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHelpCenterArticleByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHelpCenterArticleByIdQuery, GetHelpCenterArticleByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHelpCenterArticleByIdQuery, GetHelpCenterArticleByIdQueryVariables>(GetHelpCenterArticleByIdDocument, baseOptions);
      }
export function useGetHelpCenterArticleByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHelpCenterArticleByIdQuery, GetHelpCenterArticleByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHelpCenterArticleByIdQuery, GetHelpCenterArticleByIdQueryVariables>(GetHelpCenterArticleByIdDocument, baseOptions);
        }
export type GetHelpCenterArticleByIdQueryHookResult = ReturnType<typeof useGetHelpCenterArticleByIdQuery>;
export type GetHelpCenterArticleByIdLazyQueryHookResult = ReturnType<typeof useGetHelpCenterArticleByIdLazyQuery>;
export type GetHelpCenterArticleByIdQueryResult = ApolloReactCommon.QueryResult<GetHelpCenterArticleByIdQuery, GetHelpCenterArticleByIdQueryVariables>;
export const UnlistedProjectDataDocument = gql`
    query UnlistedProjectData($params: String!) {
  unlistedProjectData(params: $params) {
    studentId
    instructorUserId
    courseId
    moduleId
    projectId
    playgroundProjectType
    firstName
    lastInitial
    parentFirstName
    referralCode
    courseDisplayName
    moduleDisplayName
    projectDisplayName
    encryptedStudentAndProject
    studentProjectId
    isPublic
  }
}
    `;

/**
 * __useUnlistedProjectDataQuery__
 *
 * To run a query within a React component, call `useUnlistedProjectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnlistedProjectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnlistedProjectDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUnlistedProjectDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnlistedProjectDataQuery, UnlistedProjectDataQueryVariables>) {
        return ApolloReactHooks.useQuery<UnlistedProjectDataQuery, UnlistedProjectDataQueryVariables>(UnlistedProjectDataDocument, baseOptions);
      }
export function useUnlistedProjectDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnlistedProjectDataQuery, UnlistedProjectDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnlistedProjectDataQuery, UnlistedProjectDataQueryVariables>(UnlistedProjectDataDocument, baseOptions);
        }
export type UnlistedProjectDataQueryHookResult = ReturnType<typeof useUnlistedProjectDataQuery>;
export type UnlistedProjectDataLazyQueryHookResult = ReturnType<typeof useUnlistedProjectDataLazyQuery>;
export type UnlistedProjectDataQueryResult = ApolloReactCommon.QueryResult<UnlistedProjectDataQuery, UnlistedProjectDataQueryVariables>;
export const GetUserClubMembershipsDocument = gql`
    query GetUserClubMemberships($studentId: ObjectId, $instructorId: ObjectId) {
  getUserClubMemberships(studentId: $studentId, instructorUserId: $instructorId) {
    items {
      _id
      displayName
      description
      creatorStudentId
      creatorInstructorUserId
      memberCount
      coverPhoto
    }
  }
}
    `;

/**
 * __useGetUserClubMembershipsQuery__
 *
 * To run a query within a React component, call `useGetUserClubMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserClubMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserClubMembershipsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      instructorId: // value for 'instructorId'
 *   },
 * });
 */
export function useGetUserClubMembershipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserClubMembershipsQuery, GetUserClubMembershipsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserClubMembershipsQuery, GetUserClubMembershipsQueryVariables>(GetUserClubMembershipsDocument, baseOptions);
      }
export function useGetUserClubMembershipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserClubMembershipsQuery, GetUserClubMembershipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserClubMembershipsQuery, GetUserClubMembershipsQueryVariables>(GetUserClubMembershipsDocument, baseOptions);
        }
export type GetUserClubMembershipsQueryHookResult = ReturnType<typeof useGetUserClubMembershipsQuery>;
export type GetUserClubMembershipsLazyQueryHookResult = ReturnType<typeof useGetUserClubMembershipsLazyQuery>;
export type GetUserClubMembershipsQueryResult = ApolloReactCommon.QueryResult<GetUserClubMembershipsQuery, GetUserClubMembershipsQueryVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile($username: String!) {
  profileByUsername(username: $username) {
    type
    profile {
      bio
      profileImage {
        url
      }
      profileColor
    }
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, baseOptions);
      }
export function useGetUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, baseOptions);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = ApolloReactCommon.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    success
    updatedProfile {
      username
      profile {
        bio
        profileColor
        profileImage {
          url
        }
      }
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const GetCoursesForCourseSelectionDocument = gql`
    query GetCoursesForCourseSelection($input: QueryCourseInput) {
  getCourses(input: $input) {
    ...GetCoursesForCourseSelectionReturn
  }
}
    ${GetCoursesForCourseSelectionReturnFragmentDoc}`;

/**
 * __useGetCoursesForCourseSelectionQuery__
 *
 * To run a query within a React component, call `useGetCoursesForCourseSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForCourseSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForCourseSelectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCoursesForCourseSelectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesForCourseSelectionQuery, GetCoursesForCourseSelectionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesForCourseSelectionQuery, GetCoursesForCourseSelectionQueryVariables>(GetCoursesForCourseSelectionDocument, baseOptions);
      }
export function useGetCoursesForCourseSelectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesForCourseSelectionQuery, GetCoursesForCourseSelectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesForCourseSelectionQuery, GetCoursesForCourseSelectionQueryVariables>(GetCoursesForCourseSelectionDocument, baseOptions);
        }
export type GetCoursesForCourseSelectionQueryHookResult = ReturnType<typeof useGetCoursesForCourseSelectionQuery>;
export type GetCoursesForCourseSelectionLazyQueryHookResult = ReturnType<typeof useGetCoursesForCourseSelectionLazyQuery>;
export type GetCoursesForCourseSelectionQueryResult = ApolloReactCommon.QueryResult<GetCoursesForCourseSelectionQuery, GetCoursesForCourseSelectionQueryVariables>;
export const GetCourseByNameDocument = gql`
    query GetCourseByName($courseName: String!) {
  getCourse(name: $courseName) {
    _id
    displayName
    name
    homeworkInformation
    schedulingFormat
    courseDescription
    subject {
      _id
      name
      displayName
    }
    offerings {
      _id
      courseId
      courseName
      instructorId
      totalSeats
      numSeatsAvailable
      schedule {
        duration
        startTime
        placeholderAcuityApptId
        appointmentTypeId
      }
      enrollmentEndDate
      totalPriceInCents
    }
    isAcceptingEnrollment
  }
}
    `;

/**
 * __useGetCourseByNameQuery__
 *
 * To run a query within a React component, call `useGetCourseByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByNameQuery({
 *   variables: {
 *      courseName: // value for 'courseName'
 *   },
 * });
 */
export function useGetCourseByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseByNameQuery, GetCourseByNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseByNameQuery, GetCourseByNameQueryVariables>(GetCourseByNameDocument, baseOptions);
      }
export function useGetCourseByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseByNameQuery, GetCourseByNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseByNameQuery, GetCourseByNameQueryVariables>(GetCourseByNameDocument, baseOptions);
        }
export type GetCourseByNameQueryHookResult = ReturnType<typeof useGetCourseByNameQuery>;
export type GetCourseByNameLazyQueryHookResult = ReturnType<typeof useGetCourseByNameLazyQuery>;
export type GetCourseByNameQueryResult = ApolloReactCommon.QueryResult<GetCourseByNameQuery, GetCourseByNameQueryVariables>;
export const GetCourseOfferingByIdDocument = gql`
    query GetCourseOfferingById($id: ObjectId!) {
  getCourseOffering(id: $id) {
    course {
      displayName
      name
      subject {
        name
        displayName
      }
      schedulingFormat
    }
    totalPriceInCents
  }
}
    `;

/**
 * __useGetCourseOfferingByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseOfferingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseOfferingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseOfferingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseOfferingByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>(GetCourseOfferingByIdDocument, baseOptions);
      }
export function useGetCourseOfferingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>(GetCourseOfferingByIdDocument, baseOptions);
        }
export type GetCourseOfferingByIdQueryHookResult = ReturnType<typeof useGetCourseOfferingByIdQuery>;
export type GetCourseOfferingByIdLazyQueryHookResult = ReturnType<typeof useGetCourseOfferingByIdLazyQuery>;
export type GetCourseOfferingByIdQueryResult = ApolloReactCommon.QueryResult<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>;
export const RegisterJuniClubInvitationEmailClickDocument = gql`
    mutation RegisterJuniClubInvitationEmailClick($input: RegisterJuniClubInvitationEmailClickInput!) {
  registerJuniClubInvitationEmailClick(input: $input) {
    juniClubInvitation {
      _id
      guestInvitationId
      inviteeStudentId
      inviteeEmail
      inviteeName
      juniClubId
    }
    clubInfo {
      displayName
      description
      coverPhoto
    }
    guestInvitation {
      isParentInvite
    }
  }
}
    `;
export type RegisterJuniClubInvitationEmailClickMutationFn = ApolloReactCommon.MutationFunction<RegisterJuniClubInvitationEmailClickMutation, RegisterJuniClubInvitationEmailClickMutationVariables>;

/**
 * __useRegisterJuniClubInvitationEmailClickMutation__
 *
 * To run a mutation, you first call `useRegisterJuniClubInvitationEmailClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterJuniClubInvitationEmailClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerJuniClubInvitationEmailClickMutation, { data, loading, error }] = useRegisterJuniClubInvitationEmailClickMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterJuniClubInvitationEmailClickMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterJuniClubInvitationEmailClickMutation, RegisterJuniClubInvitationEmailClickMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterJuniClubInvitationEmailClickMutation, RegisterJuniClubInvitationEmailClickMutationVariables>(RegisterJuniClubInvitationEmailClickDocument, baseOptions);
      }
export type RegisterJuniClubInvitationEmailClickMutationHookResult = ReturnType<typeof useRegisterJuniClubInvitationEmailClickMutation>;
export type RegisterJuniClubInvitationEmailClickMutationResult = ApolloReactCommon.MutationResult<RegisterJuniClubInvitationEmailClickMutation>;
export type RegisterJuniClubInvitationEmailClickMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterJuniClubInvitationEmailClickMutation, RegisterJuniClubInvitationEmailClickMutationVariables>;
export const SubmitGuestAccountRegistrationDocument = gql`
    mutation SubmitGuestAccountRegistration($input: SubmitGuestAccountRegistrationInput!) {
  submitGuestAccountRegistration(input: $input) {
    success
  }
}
    `;
export type SubmitGuestAccountRegistrationMutationFn = ApolloReactCommon.MutationFunction<SubmitGuestAccountRegistrationMutation, SubmitGuestAccountRegistrationMutationVariables>;

/**
 * __useSubmitGuestAccountRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitGuestAccountRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGuestAccountRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGuestAccountRegistrationMutation, { data, loading, error }] = useSubmitGuestAccountRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitGuestAccountRegistrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitGuestAccountRegistrationMutation, SubmitGuestAccountRegistrationMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitGuestAccountRegistrationMutation, SubmitGuestAccountRegistrationMutationVariables>(SubmitGuestAccountRegistrationDocument, baseOptions);
      }
export type SubmitGuestAccountRegistrationMutationHookResult = ReturnType<typeof useSubmitGuestAccountRegistrationMutation>;
export type SubmitGuestAccountRegistrationMutationResult = ApolloReactCommon.MutationResult<SubmitGuestAccountRegistrationMutation>;
export type SubmitGuestAccountRegistrationMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitGuestAccountRegistrationMutation, SubmitGuestAccountRegistrationMutationVariables>;
export const RegisterCtaBannerClickDocument = gql`
    mutation RegisterCtaBannerClick($input: RegisterCtaBannerClickInput!) {
  registerCtaBannerClick(input: $input) {
    guestInvitation {
      _id
      invitationLookupId
    }
  }
}
    `;
export type RegisterCtaBannerClickMutationFn = ApolloReactCommon.MutationFunction<RegisterCtaBannerClickMutation, RegisterCtaBannerClickMutationVariables>;

/**
 * __useRegisterCtaBannerClickMutation__
 *
 * To run a mutation, you first call `useRegisterCtaBannerClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCtaBannerClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCtaBannerClickMutation, { data, loading, error }] = useRegisterCtaBannerClickMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterCtaBannerClickMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterCtaBannerClickMutation, RegisterCtaBannerClickMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterCtaBannerClickMutation, RegisterCtaBannerClickMutationVariables>(RegisterCtaBannerClickDocument, baseOptions);
      }
export type RegisterCtaBannerClickMutationHookResult = ReturnType<typeof useRegisterCtaBannerClickMutation>;
export type RegisterCtaBannerClickMutationResult = ApolloReactCommon.MutationResult<RegisterCtaBannerClickMutation>;
export type RegisterCtaBannerClickMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterCtaBannerClickMutation, RegisterCtaBannerClickMutationVariables>;
export const SendParentRegistrationEmailDocument = gql`
    mutation sendParentRegistrationEmail($input: SendParentRegistrationEmailInput!) {
  sendParentRegistrationEmail(input: $input) {
    success
  }
}
    `;
export type SendParentRegistrationEmailMutationFn = ApolloReactCommon.MutationFunction<SendParentRegistrationEmailMutation, SendParentRegistrationEmailMutationVariables>;

/**
 * __useSendParentRegistrationEmailMutation__
 *
 * To run a mutation, you first call `useSendParentRegistrationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendParentRegistrationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendParentRegistrationEmailMutation, { data, loading, error }] = useSendParentRegistrationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendParentRegistrationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendParentRegistrationEmailMutation, SendParentRegistrationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendParentRegistrationEmailMutation, SendParentRegistrationEmailMutationVariables>(SendParentRegistrationEmailDocument, baseOptions);
      }
export type SendParentRegistrationEmailMutationHookResult = ReturnType<typeof useSendParentRegistrationEmailMutation>;
export type SendParentRegistrationEmailMutationResult = ApolloReactCommon.MutationResult<SendParentRegistrationEmailMutation>;
export type SendParentRegistrationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendParentRegistrationEmailMutation, SendParentRegistrationEmailMutationVariables>;
export const GetInstructorAvailabilityDocument = gql`
    query GetInstructorAvailability($email: String!, $start: DateTime!, $end: DateTime!) {
  instructor(email: $email) {
    availability(excludeBefore: $start, excludeAfter: $end) {
      _id
      start
      end
      nylasCalendarType
      acuityApptId
    }
  }
}
    `;

/**
 * __useGetInstructorAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetInstructorAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorAvailabilityQuery({
 *   variables: {
 *      email: // value for 'email'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetInstructorAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorAvailabilityQuery, GetInstructorAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorAvailabilityQuery, GetInstructorAvailabilityQueryVariables>(GetInstructorAvailabilityDocument, baseOptions);
      }
export function useGetInstructorAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorAvailabilityQuery, GetInstructorAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorAvailabilityQuery, GetInstructorAvailabilityQueryVariables>(GetInstructorAvailabilityDocument, baseOptions);
        }
export type GetInstructorAvailabilityQueryHookResult = ReturnType<typeof useGetInstructorAvailabilityQuery>;
export type GetInstructorAvailabilityLazyQueryHookResult = ReturnType<typeof useGetInstructorAvailabilityLazyQuery>;
export type GetInstructorAvailabilityQueryResult = ApolloReactCommon.QueryResult<GetInstructorAvailabilityQuery, GetInstructorAvailabilityQueryVariables>;
export const UpdateReferralRedemptionDocument = gql`
    mutation UpdateReferralRedemption($input: UpdateReferralRedemptionInput!) {
  updateReferralRedemption(input: $input) {
    _id
  }
}
    `;
export type UpdateReferralRedemptionMutationFn = ApolloReactCommon.MutationFunction<UpdateReferralRedemptionMutation, UpdateReferralRedemptionMutationVariables>;

/**
 * __useUpdateReferralRedemptionMutation__
 *
 * To run a mutation, you first call `useUpdateReferralRedemptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralRedemptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralRedemptionMutation, { data, loading, error }] = useUpdateReferralRedemptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralRedemptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferralRedemptionMutation, UpdateReferralRedemptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReferralRedemptionMutation, UpdateReferralRedemptionMutationVariables>(UpdateReferralRedemptionDocument, baseOptions);
      }
export type UpdateReferralRedemptionMutationHookResult = ReturnType<typeof useUpdateReferralRedemptionMutation>;
export type UpdateReferralRedemptionMutationResult = ApolloReactCommon.MutationResult<UpdateReferralRedemptionMutation>;
export type UpdateReferralRedemptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferralRedemptionMutation, UpdateReferralRedemptionMutationVariables>;
export const GetReferralRedemptionByIdDocument = gql`
    query GetReferralRedemptionById($referralRedemptionId: ObjectId!) {
  getReferralRedemptionById(_id: $referralRedemptionId) {
    _id
    status
  }
}
    `;

/**
 * __useGetReferralRedemptionByIdQuery__
 *
 * To run a query within a React component, call `useGetReferralRedemptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralRedemptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralRedemptionByIdQuery({
 *   variables: {
 *      referralRedemptionId: // value for 'referralRedemptionId'
 *   },
 * });
 */
export function useGetReferralRedemptionByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferralRedemptionByIdQuery, GetReferralRedemptionByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReferralRedemptionByIdQuery, GetReferralRedemptionByIdQueryVariables>(GetReferralRedemptionByIdDocument, baseOptions);
      }
export function useGetReferralRedemptionByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferralRedemptionByIdQuery, GetReferralRedemptionByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReferralRedemptionByIdQuery, GetReferralRedemptionByIdQueryVariables>(GetReferralRedemptionByIdDocument, baseOptions);
        }
export type GetReferralRedemptionByIdQueryHookResult = ReturnType<typeof useGetReferralRedemptionByIdQuery>;
export type GetReferralRedemptionByIdLazyQueryHookResult = ReturnType<typeof useGetReferralRedemptionByIdLazyQuery>;
export type GetReferralRedemptionByIdQueryResult = ApolloReactCommon.QueryResult<GetReferralRedemptionByIdQuery, GetReferralRedemptionByIdQueryVariables>;
export const LoadEditedRichTextByIdDocument = gql`
    query LoadEditedRichTextById($input: ObjectId!) {
  editedRichTextById(id: $input) {
    ...PartialEditedRichText
  }
}
    ${PartialEditedRichTextFragmentDoc}`;

/**
 * __useLoadEditedRichTextByIdQuery__
 *
 * To run a query within a React component, call `useLoadEditedRichTextByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditedRichTextByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditedRichTextByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadEditedRichTextByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadEditedRichTextByIdQuery, LoadEditedRichTextByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadEditedRichTextByIdQuery, LoadEditedRichTextByIdQueryVariables>(LoadEditedRichTextByIdDocument, baseOptions);
      }
export function useLoadEditedRichTextByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadEditedRichTextByIdQuery, LoadEditedRichTextByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadEditedRichTextByIdQuery, LoadEditedRichTextByIdQueryVariables>(LoadEditedRichTextByIdDocument, baseOptions);
        }
export type LoadEditedRichTextByIdQueryHookResult = ReturnType<typeof useLoadEditedRichTextByIdQuery>;
export type LoadEditedRichTextByIdLazyQueryHookResult = ReturnType<typeof useLoadEditedRichTextByIdLazyQuery>;
export type LoadEditedRichTextByIdQueryResult = ApolloReactCommon.QueryResult<LoadEditedRichTextByIdQuery, LoadEditedRichTextByIdQueryVariables>;
export const CreateEditedRichTextDocument = gql`
    mutation createEditedRichText($input: CreateEditedRichTextInput!) {
  createEditedRichText(input: $input) {
    editedRichText {
      ...PartialEditedRichText
    }
  }
}
    ${PartialEditedRichTextFragmentDoc}`;
export type CreateEditedRichTextMutationFn = ApolloReactCommon.MutationFunction<CreateEditedRichTextMutation, CreateEditedRichTextMutationVariables>;

/**
 * __useCreateEditedRichTextMutation__
 *
 * To run a mutation, you first call `useCreateEditedRichTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditedRichTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditedRichTextMutation, { data, loading, error }] = useCreateEditedRichTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditedRichTextMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEditedRichTextMutation, CreateEditedRichTextMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEditedRichTextMutation, CreateEditedRichTextMutationVariables>(CreateEditedRichTextDocument, baseOptions);
      }
export type CreateEditedRichTextMutationHookResult = ReturnType<typeof useCreateEditedRichTextMutation>;
export type CreateEditedRichTextMutationResult = ApolloReactCommon.MutationResult<CreateEditedRichTextMutation>;
export type CreateEditedRichTextMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEditedRichTextMutation, CreateEditedRichTextMutationVariables>;
export const UpdateEditedRichTextDocument = gql`
    mutation updateEditedRichText($input: UpdateEditedRichTextInput!) {
  updateEditedRichText(input: $input) {
    editedRichText {
      ...PartialEditedRichText
    }
  }
}
    ${PartialEditedRichTextFragmentDoc}`;
export type UpdateEditedRichTextMutationFn = ApolloReactCommon.MutationFunction<UpdateEditedRichTextMutation, UpdateEditedRichTextMutationVariables>;

/**
 * __useUpdateEditedRichTextMutation__
 *
 * To run a mutation, you first call `useUpdateEditedRichTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditedRichTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditedRichTextMutation, { data, loading, error }] = useUpdateEditedRichTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditedRichTextMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEditedRichTextMutation, UpdateEditedRichTextMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEditedRichTextMutation, UpdateEditedRichTextMutationVariables>(UpdateEditedRichTextDocument, baseOptions);
      }
export type UpdateEditedRichTextMutationHookResult = ReturnType<typeof useUpdateEditedRichTextMutation>;
export type UpdateEditedRichTextMutationResult = ApolloReactCommon.MutationResult<UpdateEditedRichTextMutation>;
export type UpdateEditedRichTextMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEditedRichTextMutation, UpdateEditedRichTextMutationVariables>;
export const LoadCourseRubricProgressDocument = gql`
    query LoadCourseRubricProgress($input: CourseRubricProgressInput!) {
  courseRubricProgress(input: $input) {
    ...CourseRubricProgressFragment
  }
}
    ${CourseRubricProgressFragmentFragmentDoc}`;

/**
 * __useLoadCourseRubricProgressQuery__
 *
 * To run a query within a React component, call `useLoadCourseRubricProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCourseRubricProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCourseRubricProgressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadCourseRubricProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadCourseRubricProgressQuery, LoadCourseRubricProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadCourseRubricProgressQuery, LoadCourseRubricProgressQueryVariables>(LoadCourseRubricProgressDocument, baseOptions);
      }
export function useLoadCourseRubricProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadCourseRubricProgressQuery, LoadCourseRubricProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadCourseRubricProgressQuery, LoadCourseRubricProgressQueryVariables>(LoadCourseRubricProgressDocument, baseOptions);
        }
export type LoadCourseRubricProgressQueryHookResult = ReturnType<typeof useLoadCourseRubricProgressQuery>;
export type LoadCourseRubricProgressLazyQueryHookResult = ReturnType<typeof useLoadCourseRubricProgressLazyQuery>;
export type LoadCourseRubricProgressQueryResult = ApolloReactCommon.QueryResult<LoadCourseRubricProgressQuery, LoadCourseRubricProgressQueryVariables>;
export const SaveCourseRubricProgressDocument = gql`
    mutation SaveCourseRubricProgress($input: UpdateCourseRubricProgressInput!) {
  updateCourseRubricProgress(input: $input) {
    progressUpdates {
      ...LearningTargetProgressFragment
    }
  }
}
    ${LearningTargetProgressFragmentFragmentDoc}`;
export type SaveCourseRubricProgressMutationFn = ApolloReactCommon.MutationFunction<SaveCourseRubricProgressMutation, SaveCourseRubricProgressMutationVariables>;

/**
 * __useSaveCourseRubricProgressMutation__
 *
 * To run a mutation, you first call `useSaveCourseRubricProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCourseRubricProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCourseRubricProgressMutation, { data, loading, error }] = useSaveCourseRubricProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCourseRubricProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveCourseRubricProgressMutation, SaveCourseRubricProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveCourseRubricProgressMutation, SaveCourseRubricProgressMutationVariables>(SaveCourseRubricProgressDocument, baseOptions);
      }
export type SaveCourseRubricProgressMutationHookResult = ReturnType<typeof useSaveCourseRubricProgressMutation>;
export type SaveCourseRubricProgressMutationResult = ApolloReactCommon.MutationResult<SaveCourseRubricProgressMutation>;
export type SaveCourseRubricProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveCourseRubricProgressMutation, SaveCourseRubricProgressMutationVariables>;
export const LoadComputerScienceModuleSectionsDocument = gql`
    query LoadComputerScienceModuleSections($ids: [ObjectId!]!) {
  computerScienceModuleSections(ids: $ids) {
    items {
      _id
      displayName
      isProject
    }
  }
}
    `;

/**
 * __useLoadComputerScienceModuleSectionsQuery__
 *
 * To run a query within a React component, call `useLoadComputerScienceModuleSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadComputerScienceModuleSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadComputerScienceModuleSectionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoadComputerScienceModuleSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadComputerScienceModuleSectionsQuery, LoadComputerScienceModuleSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadComputerScienceModuleSectionsQuery, LoadComputerScienceModuleSectionsQueryVariables>(LoadComputerScienceModuleSectionsDocument, baseOptions);
      }
export function useLoadComputerScienceModuleSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadComputerScienceModuleSectionsQuery, LoadComputerScienceModuleSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadComputerScienceModuleSectionsQuery, LoadComputerScienceModuleSectionsQueryVariables>(LoadComputerScienceModuleSectionsDocument, baseOptions);
        }
export type LoadComputerScienceModuleSectionsQueryHookResult = ReturnType<typeof useLoadComputerScienceModuleSectionsQuery>;
export type LoadComputerScienceModuleSectionsLazyQueryHookResult = ReturnType<typeof useLoadComputerScienceModuleSectionsLazyQuery>;
export type LoadComputerScienceModuleSectionsQueryResult = ApolloReactCommon.QueryResult<LoadComputerScienceModuleSectionsQuery, LoadComputerScienceModuleSectionsQueryVariables>;
export const LoadSessionFeedbackDocument = gql`
    query LoadSessionFeedback($teacherSessionId: ObjectId!) {
  sessionFeedback(teacherSessionId: $teacherSessionId) {
    ...SessionFeedbackBase
  }
}
    ${SessionFeedbackBaseFragmentDoc}`;

/**
 * __useLoadSessionFeedbackQuery__
 *
 * To run a query within a React component, call `useLoadSessionFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSessionFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSessionFeedbackQuery({
 *   variables: {
 *      teacherSessionId: // value for 'teacherSessionId'
 *   },
 * });
 */
export function useLoadSessionFeedbackQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadSessionFeedbackQuery, LoadSessionFeedbackQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadSessionFeedbackQuery, LoadSessionFeedbackQueryVariables>(LoadSessionFeedbackDocument, baseOptions);
      }
export function useLoadSessionFeedbackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadSessionFeedbackQuery, LoadSessionFeedbackQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadSessionFeedbackQuery, LoadSessionFeedbackQueryVariables>(LoadSessionFeedbackDocument, baseOptions);
        }
export type LoadSessionFeedbackQueryHookResult = ReturnType<typeof useLoadSessionFeedbackQuery>;
export type LoadSessionFeedbackLazyQueryHookResult = ReturnType<typeof useLoadSessionFeedbackLazyQuery>;
export type LoadSessionFeedbackQueryResult = ApolloReactCommon.QueryResult<LoadSessionFeedbackQuery, LoadSessionFeedbackQueryVariables>;
export const CreateSessionFeedbackDocument = gql`
    mutation CreateSessionFeedback($input: CreateSessionFeedbackInput!) {
  createSessionFeedback(input: $input) {
    sessionFeedback {
      ...SessionFeedbackBase
    }
  }
}
    ${SessionFeedbackBaseFragmentDoc}`;
export type CreateSessionFeedbackMutationFn = ApolloReactCommon.MutationFunction<CreateSessionFeedbackMutation, CreateSessionFeedbackMutationVariables>;

/**
 * __useCreateSessionFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateSessionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionFeedbackMutation, { data, loading, error }] = useCreateSessionFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSessionFeedbackMutation, CreateSessionFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSessionFeedbackMutation, CreateSessionFeedbackMutationVariables>(CreateSessionFeedbackDocument, baseOptions);
      }
export type CreateSessionFeedbackMutationHookResult = ReturnType<typeof useCreateSessionFeedbackMutation>;
export type CreateSessionFeedbackMutationResult = ApolloReactCommon.MutationResult<CreateSessionFeedbackMutation>;
export type CreateSessionFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSessionFeedbackMutation, CreateSessionFeedbackMutationVariables>;
export const LoadSessionFeedbackForInstructorDocument = gql`
    query LoadSessionFeedbackForInstructor($isComplete: Boolean) {
  sessionFeedbackForInstructor(isComplete: $isComplete) {
    items {
      ...SessionFeedbackAugmented
    }
  }
}
    ${SessionFeedbackAugmentedFragmentDoc}`;

/**
 * __useLoadSessionFeedbackForInstructorQuery__
 *
 * To run a query within a React component, call `useLoadSessionFeedbackForInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSessionFeedbackForInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSessionFeedbackForInstructorQuery({
 *   variables: {
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useLoadSessionFeedbackForInstructorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadSessionFeedbackForInstructorQuery, LoadSessionFeedbackForInstructorQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadSessionFeedbackForInstructorQuery, LoadSessionFeedbackForInstructorQueryVariables>(LoadSessionFeedbackForInstructorDocument, baseOptions);
      }
export function useLoadSessionFeedbackForInstructorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadSessionFeedbackForInstructorQuery, LoadSessionFeedbackForInstructorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadSessionFeedbackForInstructorQuery, LoadSessionFeedbackForInstructorQueryVariables>(LoadSessionFeedbackForInstructorDocument, baseOptions);
        }
export type LoadSessionFeedbackForInstructorQueryHookResult = ReturnType<typeof useLoadSessionFeedbackForInstructorQuery>;
export type LoadSessionFeedbackForInstructorLazyQueryHookResult = ReturnType<typeof useLoadSessionFeedbackForInstructorLazyQuery>;
export type LoadSessionFeedbackForInstructorQueryResult = ApolloReactCommon.QueryResult<LoadSessionFeedbackForInstructorQuery, LoadSessionFeedbackForInstructorQueryVariables>;
export const CompleteSessionFeedbackDocument = gql`
    mutation CompleteSessionFeedback($input: CompleteSessionFeedbackInput!) {
  completeSessionFeedback(input: $input) {
    sessionFeedback {
      ...SessionFeedbackBase
    }
  }
}
    ${SessionFeedbackBaseFragmentDoc}`;
export type CompleteSessionFeedbackMutationFn = ApolloReactCommon.MutationFunction<CompleteSessionFeedbackMutation, CompleteSessionFeedbackMutationVariables>;

/**
 * __useCompleteSessionFeedbackMutation__
 *
 * To run a mutation, you first call `useCompleteSessionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSessionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSessionFeedbackMutation, { data, loading, error }] = useCompleteSessionFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteSessionFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteSessionFeedbackMutation, CompleteSessionFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteSessionFeedbackMutation, CompleteSessionFeedbackMutationVariables>(CompleteSessionFeedbackDocument, baseOptions);
      }
export type CompleteSessionFeedbackMutationHookResult = ReturnType<typeof useCompleteSessionFeedbackMutation>;
export type CompleteSessionFeedbackMutationResult = ApolloReactCommon.MutationResult<CompleteSessionFeedbackMutation>;
export type CompleteSessionFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteSessionFeedbackMutation, CompleteSessionFeedbackMutationVariables>;
export const NotesFromHqByDateDocument = gql`
    query NotesFromHQByDate($date: DateTime!) {
  notesFromHQByDate(date: $date) {
    items {
      _id
      title
      text
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useNotesFromHqByDateQuery__
 *
 * To run a query within a React component, call `useNotesFromHqByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesFromHqByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesFromHqByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useNotesFromHqByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotesFromHqByDateQuery, NotesFromHqByDateQueryVariables>) {
        return ApolloReactHooks.useQuery<NotesFromHqByDateQuery, NotesFromHqByDateQueryVariables>(NotesFromHqByDateDocument, baseOptions);
      }
export function useNotesFromHqByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotesFromHqByDateQuery, NotesFromHqByDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotesFromHqByDateQuery, NotesFromHqByDateQueryVariables>(NotesFromHqByDateDocument, baseOptions);
        }
export type NotesFromHqByDateQueryHookResult = ReturnType<typeof useNotesFromHqByDateQuery>;
export type NotesFromHqByDateLazyQueryHookResult = ReturnType<typeof useNotesFromHqByDateLazyQuery>;
export type NotesFromHqByDateQueryResult = ApolloReactCommon.QueryResult<NotesFromHqByDateQuery, NotesFromHqByDateQueryVariables>;
export const ModuleProgressDocument = gql`
    query ModuleProgress($studentId: String) {
  queryModuleProgresses(studentId: $studentId) {
    items {
      _id
      moduleId
      studentId
      isCompleted
      isInProgress
      timestamp
    }
  }
}
    `;

/**
 * __useModuleProgressQuery__
 *
 * To run a query within a React component, call `useModuleProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleProgressQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useModuleProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModuleProgressQuery, ModuleProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<ModuleProgressQuery, ModuleProgressQueryVariables>(ModuleProgressDocument, baseOptions);
      }
export function useModuleProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModuleProgressQuery, ModuleProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModuleProgressQuery, ModuleProgressQueryVariables>(ModuleProgressDocument, baseOptions);
        }
export type ModuleProgressQueryHookResult = ReturnType<typeof useModuleProgressQuery>;
export type ModuleProgressLazyQueryHookResult = ReturnType<typeof useModuleProgressLazyQuery>;
export type ModuleProgressQueryResult = ApolloReactCommon.QueryResult<ModuleProgressQuery, ModuleProgressQueryVariables>;
export const GetUserSmsConsentStatusDocument = gql`
    query GetUserSmsConsentStatus {
  userSmsConsentStatus
}
    `;

/**
 * __useGetUserSmsConsentStatusQuery__
 *
 * To run a query within a React component, call `useGetUserSmsConsentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSmsConsentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSmsConsentStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSmsConsentStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSmsConsentStatusQuery, GetUserSmsConsentStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSmsConsentStatusQuery, GetUserSmsConsentStatusQueryVariables>(GetUserSmsConsentStatusDocument, baseOptions);
      }
export function useGetUserSmsConsentStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSmsConsentStatusQuery, GetUserSmsConsentStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSmsConsentStatusQuery, GetUserSmsConsentStatusQueryVariables>(GetUserSmsConsentStatusDocument, baseOptions);
        }
export type GetUserSmsConsentStatusQueryHookResult = ReturnType<typeof useGetUserSmsConsentStatusQuery>;
export type GetUserSmsConsentStatusLazyQueryHookResult = ReturnType<typeof useGetUserSmsConsentStatusLazyQuery>;
export type GetUserSmsConsentStatusQueryResult = ApolloReactCommon.QueryResult<GetUserSmsConsentStatusQuery, GetUserSmsConsentStatusQueryVariables>;
export const UpdateUserSmsConsentDocument = gql`
    mutation UpdateUserSmsConsent($input: UpdateUserSmsConsentInput!) {
  updateUserSmsConsent(input: $input) {
    ok
  }
}
    `;
export type UpdateUserSmsConsentMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSmsConsentMutation, UpdateUserSmsConsentMutationVariables>;

/**
 * __useUpdateUserSmsConsentMutation__
 *
 * To run a mutation, you first call `useUpdateUserSmsConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSmsConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSmsConsentMutation, { data, loading, error }] = useUpdateUserSmsConsentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSmsConsentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSmsConsentMutation, UpdateUserSmsConsentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserSmsConsentMutation, UpdateUserSmsConsentMutationVariables>(UpdateUserSmsConsentDocument, baseOptions);
      }
export type UpdateUserSmsConsentMutationHookResult = ReturnType<typeof useUpdateUserSmsConsentMutation>;
export type UpdateUserSmsConsentMutationResult = ApolloReactCommon.MutationResult<UpdateUserSmsConsentMutation>;
export type UpdateUserSmsConsentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSmsConsentMutation, UpdateUserSmsConsentMutationVariables>;
export const AdjustBillingDocument = gql`
    mutation adjustBilling($input: AdjustBillingInput!) {
  adjustBilling(input: $input) {
    success
  }
}
    `;
export type AdjustBillingMutationFn = ApolloReactCommon.MutationFunction<AdjustBillingMutation, AdjustBillingMutationVariables>;

/**
 * __useAdjustBillingMutation__
 *
 * To run a mutation, you first call `useAdjustBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjustBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustBillingMutation, { data, loading, error }] = useAdjustBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdjustBillingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdjustBillingMutation, AdjustBillingMutationVariables>) {
        return ApolloReactHooks.useMutation<AdjustBillingMutation, AdjustBillingMutationVariables>(AdjustBillingDocument, baseOptions);
      }
export type AdjustBillingMutationHookResult = ReturnType<typeof useAdjustBillingMutation>;
export type AdjustBillingMutationResult = ApolloReactCommon.MutationResult<AdjustBillingMutation>;
export type AdjustBillingMutationOptions = ApolloReactCommon.BaseMutationOptions<AdjustBillingMutation, AdjustBillingMutationVariables>;
export const GetBillingAdjustmentInstructorsDocument = gql`
    query GetBillingAdjustmentInstructors {
  instructors {
    items {
      _id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetBillingAdjustmentInstructorsQuery__
 *
 * To run a query within a React component, call `useGetBillingAdjustmentInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingAdjustmentInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingAdjustmentInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingAdjustmentInstructorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillingAdjustmentInstructorsQuery, GetBillingAdjustmentInstructorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillingAdjustmentInstructorsQuery, GetBillingAdjustmentInstructorsQueryVariables>(GetBillingAdjustmentInstructorsDocument, baseOptions);
      }
export function useGetBillingAdjustmentInstructorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillingAdjustmentInstructorsQuery, GetBillingAdjustmentInstructorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillingAdjustmentInstructorsQuery, GetBillingAdjustmentInstructorsQueryVariables>(GetBillingAdjustmentInstructorsDocument, baseOptions);
        }
export type GetBillingAdjustmentInstructorsQueryHookResult = ReturnType<typeof useGetBillingAdjustmentInstructorsQuery>;
export type GetBillingAdjustmentInstructorsLazyQueryHookResult = ReturnType<typeof useGetBillingAdjustmentInstructorsLazyQuery>;
export type GetBillingAdjustmentInstructorsQueryResult = ApolloReactCommon.QueryResult<GetBillingAdjustmentInstructorsQuery, GetBillingAdjustmentInstructorsQueryVariables>;
export const GetInstructorMatchesDocument = gql`
    query GetInstructorMatches($input: InstructorMatchInput!) {
  instructorMatches(input: $input) {
    items {
      _id
      userId
      firstName
      lastName
      courseIds
      ageRange5to7
      ageRange8to10
      ageRange11to13
      ageRange14to16
      ageRange17up
      acuityCalendarId
      notes
      energyLevel
      emotionalTone
      confidence
      bio
      school
      availability {
        start
        end
      }
      instructorLoad {
        date
        hours {
          trial
          recurring
        }
      }
      proposalHistory {
        pending
        declined
        accepted
        acceptedByOther
      }
      courses {
        courseId
        isQualified
        moduleProgress {
          status
        }
      }
    }
  }
}
    `;

/**
 * __useGetInstructorMatchesQuery__
 *
 * To run a query within a React component, call `useGetInstructorMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorMatchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInstructorMatchesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInstructorMatchesQuery, GetInstructorMatchesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInstructorMatchesQuery, GetInstructorMatchesQueryVariables>(GetInstructorMatchesDocument, baseOptions);
      }
export function useGetInstructorMatchesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInstructorMatchesQuery, GetInstructorMatchesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInstructorMatchesQuery, GetInstructorMatchesQueryVariables>(GetInstructorMatchesDocument, baseOptions);
        }
export type GetInstructorMatchesQueryHookResult = ReturnType<typeof useGetInstructorMatchesQuery>;
export type GetInstructorMatchesLazyQueryHookResult = ReturnType<typeof useGetInstructorMatchesLazyQuery>;
export type GetInstructorMatchesQueryResult = ApolloReactCommon.QueryResult<GetInstructorMatchesQuery, GetInstructorMatchesQueryVariables>;
export const AddStudentCourseDocument = gql`
    mutation addStudentCourse($input: AddStudentCourseInput!) {
  addStudentCourse(input: $input) {
    success
  }
}
    `;
export type AddStudentCourseMutationFn = ApolloReactCommon.MutationFunction<AddStudentCourseMutation, AddStudentCourseMutationVariables>;

/**
 * __useAddStudentCourseMutation__
 *
 * To run a mutation, you first call `useAddStudentCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentCourseMutation, { data, loading, error }] = useAddStudentCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStudentCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddStudentCourseMutation, AddStudentCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<AddStudentCourseMutation, AddStudentCourseMutationVariables>(AddStudentCourseDocument, baseOptions);
      }
export type AddStudentCourseMutationHookResult = ReturnType<typeof useAddStudentCourseMutation>;
export type AddStudentCourseMutationResult = ApolloReactCommon.MutationResult<AddStudentCourseMutation>;
export type AddStudentCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<AddStudentCourseMutation, AddStudentCourseMutationVariables>;
export const UpdateStudentCourseDocument = gql`
    mutation updateStudentCourse($input: UpdateStudentCourseInput!) {
  updateStudentCourse(input: $input) {
    success
  }
}
    `;
export type UpdateStudentCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateStudentCourseMutation, UpdateStudentCourseMutationVariables>;

/**
 * __useUpdateStudentCourseMutation__
 *
 * To run a mutation, you first call `useUpdateStudentCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentCourseMutation, { data, loading, error }] = useUpdateStudentCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStudentCourseMutation, UpdateStudentCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStudentCourseMutation, UpdateStudentCourseMutationVariables>(UpdateStudentCourseDocument, baseOptions);
      }
export type UpdateStudentCourseMutationHookResult = ReturnType<typeof useUpdateStudentCourseMutation>;
export type UpdateStudentCourseMutationResult = ApolloReactCommon.MutationResult<UpdateStudentCourseMutation>;
export type UpdateStudentCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStudentCourseMutation, UpdateStudentCourseMutationVariables>;
export const RemoveStudentCourseDocument = gql`
    mutation removeStudentCourse($input: RemoveStudentCourseInput!) {
  removeStudentCourse(input: $input) {
    success
  }
}
    `;
export type RemoveStudentCourseMutationFn = ApolloReactCommon.MutationFunction<RemoveStudentCourseMutation, RemoveStudentCourseMutationVariables>;

/**
 * __useRemoveStudentCourseMutation__
 *
 * To run a mutation, you first call `useRemoveStudentCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentCourseMutation, { data, loading, error }] = useRemoveStudentCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStudentCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveStudentCourseMutation, RemoveStudentCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveStudentCourseMutation, RemoveStudentCourseMutationVariables>(RemoveStudentCourseDocument, baseOptions);
      }
export type RemoveStudentCourseMutationHookResult = ReturnType<typeof useRemoveStudentCourseMutation>;
export type RemoveStudentCourseMutationResult = ApolloReactCommon.MutationResult<RemoveStudentCourseMutation>;
export type RemoveStudentCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveStudentCourseMutation, RemoveStudentCourseMutationVariables>;
export const UncompleteStudentCourseDocument = gql`
    mutation uncompleteStudentCourse($input: UncompleteStudentCourseInput!) {
  uncompleteStudentCourse(input: $input) {
    success
  }
}
    `;
export type UncompleteStudentCourseMutationFn = ApolloReactCommon.MutationFunction<UncompleteStudentCourseMutation, UncompleteStudentCourseMutationVariables>;

/**
 * __useUncompleteStudentCourseMutation__
 *
 * To run a mutation, you first call `useUncompleteStudentCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncompleteStudentCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncompleteStudentCourseMutation, { data, loading, error }] = useUncompleteStudentCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUncompleteStudentCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UncompleteStudentCourseMutation, UncompleteStudentCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<UncompleteStudentCourseMutation, UncompleteStudentCourseMutationVariables>(UncompleteStudentCourseDocument, baseOptions);
      }
export type UncompleteStudentCourseMutationHookResult = ReturnType<typeof useUncompleteStudentCourseMutation>;
export type UncompleteStudentCourseMutationResult = ApolloReactCommon.MutationResult<UncompleteStudentCourseMutation>;
export type UncompleteStudentCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UncompleteStudentCourseMutation, UncompleteStudentCourseMutationVariables>;
export const CompleteStudentCourseDocument = gql`
    mutation completeStudentCourse($input: CompleteStudentCourseInput!) {
  completeStudentCourse(input: $input) {
    success
  }
}
    `;
export type CompleteStudentCourseMutationFn = ApolloReactCommon.MutationFunction<CompleteStudentCourseMutation, CompleteStudentCourseMutationVariables>;

/**
 * __useCompleteStudentCourseMutation__
 *
 * To run a mutation, you first call `useCompleteStudentCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteStudentCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeStudentCourseMutation, { data, loading, error }] = useCompleteStudentCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteStudentCourseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteStudentCourseMutation, CompleteStudentCourseMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteStudentCourseMutation, CompleteStudentCourseMutationVariables>(CompleteStudentCourseDocument, baseOptions);
      }
export type CompleteStudentCourseMutationHookResult = ReturnType<typeof useCompleteStudentCourseMutation>;
export type CompleteStudentCourseMutationResult = ApolloReactCommon.MutationResult<CompleteStudentCourseMutation>;
export type CompleteStudentCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteStudentCourseMutation, CompleteStudentCourseMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation updateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    success
  }
}
    `;
export type UpdateEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, baseOptions);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = ApolloReactCommon.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateNameDocument = gql`
    mutation updateName($input: UpdateNameInput!) {
  updateName(input: $input) {
    success
  }
}
    `;
export type UpdateNameMutationFn = ApolloReactCommon.MutationFunction<UpdateNameMutation, UpdateNameMutationVariables>;

/**
 * __useUpdateNameMutation__
 *
 * To run a mutation, you first call `useUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNameMutation, { data, loading, error }] = useUpdateNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNameMutation, UpdateNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNameMutation, UpdateNameMutationVariables>(UpdateNameDocument, baseOptions);
      }
export type UpdateNameMutationHookResult = ReturnType<typeof useUpdateNameMutation>;
export type UpdateNameMutationResult = ApolloReactCommon.MutationResult<UpdateNameMutation>;
export type UpdateNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNameMutation, UpdateNameMutationVariables>;
export const DeleteS3ObjectDocument = gql`
    mutation deleteS3Object($input: DeleteS3ObjectInput!) {
  deleteS3Object(input: $input) {
    success
  }
}
    `;
export type DeleteS3ObjectMutationFn = ApolloReactCommon.MutationFunction<DeleteS3ObjectMutation, DeleteS3ObjectMutationVariables>;

/**
 * __useDeleteS3ObjectMutation__
 *
 * To run a mutation, you first call `useDeleteS3ObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteS3ObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteS3ObjectMutation, { data, loading, error }] = useDeleteS3ObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteS3ObjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteS3ObjectMutation, DeleteS3ObjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteS3ObjectMutation, DeleteS3ObjectMutationVariables>(DeleteS3ObjectDocument, baseOptions);
      }
export type DeleteS3ObjectMutationHookResult = ReturnType<typeof useDeleteS3ObjectMutation>;
export type DeleteS3ObjectMutationResult = ApolloReactCommon.MutationResult<DeleteS3ObjectMutation>;
export type DeleteS3ObjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteS3ObjectMutation, DeleteS3ObjectMutationVariables>;
export const CreateClubDocument = gql`
    mutation createClub($input: CreateClubInput!) {
  createClub(input: $input) {
    juniClub {
      _id
    }
    juniClubMembership {
      studentId
    }
  }
}
    `;
export type CreateClubMutationFn = ApolloReactCommon.MutationFunction<CreateClubMutation, CreateClubMutationVariables>;

/**
 * __useCreateClubMutation__
 *
 * To run a mutation, you first call `useCreateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubMutation, { data, loading, error }] = useCreateClubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClubMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClubMutation, CreateClubMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClubMutation, CreateClubMutationVariables>(CreateClubDocument, baseOptions);
      }
export type CreateClubMutationHookResult = ReturnType<typeof useCreateClubMutation>;
export type CreateClubMutationResult = ApolloReactCommon.MutationResult<CreateClubMutation>;
export type CreateClubMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClubMutation, CreateClubMutationVariables>;
export const UpdateClubDocument = gql`
    mutation updateClub($input: UpdateClubInput!) {
  updateClub(input: $input) {
    juniClub {
      _id
    }
  }
}
    `;
export type UpdateClubMutationFn = ApolloReactCommon.MutationFunction<UpdateClubMutation, UpdateClubMutationVariables>;

/**
 * __useUpdateClubMutation__
 *
 * To run a mutation, you first call `useUpdateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubMutation, { data, loading, error }] = useUpdateClubMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClubMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClubMutation, UpdateClubMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClubMutation, UpdateClubMutationVariables>(UpdateClubDocument, baseOptions);
      }
export type UpdateClubMutationHookResult = ReturnType<typeof useUpdateClubMutation>;
export type UpdateClubMutationResult = ApolloReactCommon.MutationResult<UpdateClubMutation>;
export type UpdateClubMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClubMutation, UpdateClubMutationVariables>;
export const StaticS3BucketUrlsDocument = gql`
    query staticS3BucketUrls($s3Bucket: String!, $folder: String!) {
  staticS3BucketUrls(s3Bucket: $s3Bucket, folder: $folder)
}
    `;

/**
 * __useStaticS3BucketUrlsQuery__
 *
 * To run a query within a React component, call `useStaticS3BucketUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticS3BucketUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticS3BucketUrlsQuery({
 *   variables: {
 *      s3Bucket: // value for 's3Bucket'
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useStaticS3BucketUrlsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaticS3BucketUrlsQuery, StaticS3BucketUrlsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaticS3BucketUrlsQuery, StaticS3BucketUrlsQueryVariables>(StaticS3BucketUrlsDocument, baseOptions);
      }
export function useStaticS3BucketUrlsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaticS3BucketUrlsQuery, StaticS3BucketUrlsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaticS3BucketUrlsQuery, StaticS3BucketUrlsQueryVariables>(StaticS3BucketUrlsDocument, baseOptions);
        }
export type StaticS3BucketUrlsQueryHookResult = ReturnType<typeof useStaticS3BucketUrlsQuery>;
export type StaticS3BucketUrlsLazyQueryHookResult = ReturnType<typeof useStaticS3BucketUrlsLazyQuery>;
export type StaticS3BucketUrlsQueryResult = ApolloReactCommon.QueryResult<StaticS3BucketUrlsQuery, StaticS3BucketUrlsQueryVariables>;
export const UpdateClubMetadataDocument = gql`
    mutation updateClubMetadata($input: UpdateClubMetadataInput!) {
  updateClubMetadata(input: $input) {
    juniClub {
      _id
      tags
    }
  }
}
    `;
export type UpdateClubMetadataMutationFn = ApolloReactCommon.MutationFunction<UpdateClubMetadataMutation, UpdateClubMetadataMutationVariables>;

/**
 * __useUpdateClubMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateClubMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubMetadataMutation, { data, loading, error }] = useUpdateClubMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClubMetadataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClubMetadataMutation, UpdateClubMetadataMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClubMetadataMutation, UpdateClubMetadataMutationVariables>(UpdateClubMetadataDocument, baseOptions);
      }
export type UpdateClubMetadataMutationHookResult = ReturnType<typeof useUpdateClubMetadataMutation>;
export type UpdateClubMetadataMutationResult = ApolloReactCommon.MutationResult<UpdateClubMetadataMutation>;
export type UpdateClubMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClubMetadataMutation, UpdateClubMetadataMutationVariables>;
export const GetJuniClubsByIdsDocument = gql`
    query getJuniClubsByIds($ids: [ObjectId!]!) {
  getJuniClubsByIds(ids: $ids) {
    items {
      _id
      displayName
      description
      tags
      channels {
        displayName
      }
      colorTheme
      coverPhoto
      logo
      creatorStudentId
      creatorInstructorUserId
      isJuniModerated
      archivedAt
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetJuniClubsByIdsQuery__
 *
 * To run a query within a React component, call `useGetJuniClubsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuniClubsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuniClubsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetJuniClubsByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJuniClubsByIdsQuery, GetJuniClubsByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJuniClubsByIdsQuery, GetJuniClubsByIdsQueryVariables>(GetJuniClubsByIdsDocument, baseOptions);
      }
export function useGetJuniClubsByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJuniClubsByIdsQuery, GetJuniClubsByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJuniClubsByIdsQuery, GetJuniClubsByIdsQueryVariables>(GetJuniClubsByIdsDocument, baseOptions);
        }
export type GetJuniClubsByIdsQueryHookResult = ReturnType<typeof useGetJuniClubsByIdsQuery>;
export type GetJuniClubsByIdsLazyQueryHookResult = ReturnType<typeof useGetJuniClubsByIdsLazyQuery>;
export type GetJuniClubsByIdsQueryResult = ApolloReactCommon.QueryResult<GetJuniClubsByIdsQuery, GetJuniClubsByIdsQueryVariables>;
export const GetClubInvitationsDocument = gql`
    query getClubInvitations {
  getClubInvitations {
    items {
      _id
      inviteeStudentId
      inviteeInstructorUserId
      inviteeParentId
      juniClubId
      inviterName
      acceptedAt
      rejectedAt
    }
  }
}
    `;

/**
 * __useGetClubInvitationsQuery__
 *
 * To run a query within a React component, call `useGetClubInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubInvitationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClubInvitationsQuery, GetClubInvitationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClubInvitationsQuery, GetClubInvitationsQueryVariables>(GetClubInvitationsDocument, baseOptions);
      }
export function useGetClubInvitationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClubInvitationsQuery, GetClubInvitationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClubInvitationsQuery, GetClubInvitationsQueryVariables>(GetClubInvitationsDocument, baseOptions);
        }
export type GetClubInvitationsQueryHookResult = ReturnType<typeof useGetClubInvitationsQuery>;
export type GetClubInvitationsLazyQueryHookResult = ReturnType<typeof useGetClubInvitationsLazyQuery>;
export type GetClubInvitationsQueryResult = ApolloReactCommon.QueryResult<GetClubInvitationsQuery, GetClubInvitationsQueryVariables>;
export const AcceptClubInvitationDocument = gql`
    mutation acceptClubInvitation($input: AcceptRejectJuniClubInvitationInput!) {
  acceptRejectJuniClubInvitation(input: $input) {
    success
  }
}
    `;
export type AcceptClubInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptClubInvitationMutation, AcceptClubInvitationMutationVariables>;

/**
 * __useAcceptClubInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptClubInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptClubInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptClubInvitationMutation, { data, loading, error }] = useAcceptClubInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptClubInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptClubInvitationMutation, AcceptClubInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptClubInvitationMutation, AcceptClubInvitationMutationVariables>(AcceptClubInvitationDocument, baseOptions);
      }
export type AcceptClubInvitationMutationHookResult = ReturnType<typeof useAcceptClubInvitationMutation>;
export type AcceptClubInvitationMutationResult = ApolloReactCommon.MutationResult<AcceptClubInvitationMutation>;
export type AcceptClubInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptClubInvitationMutation, AcceptClubInvitationMutationVariables>;
export const CreateClubChannelDocument = gql`
    mutation createClubChannel($input: CreateChannelInput!) {
  createChannel(input: $input) {
    juniClub {
      _id
      channels {
        displayName
      }
    }
  }
}
    `;
export type CreateClubChannelMutationFn = ApolloReactCommon.MutationFunction<CreateClubChannelMutation, CreateClubChannelMutationVariables>;

/**
 * __useCreateClubChannelMutation__
 *
 * To run a mutation, you first call `useCreateClubChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubChannelMutation, { data, loading, error }] = useCreateClubChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClubChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClubChannelMutation, CreateClubChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClubChannelMutation, CreateClubChannelMutationVariables>(CreateClubChannelDocument, baseOptions);
      }
export type CreateClubChannelMutationHookResult = ReturnType<typeof useCreateClubChannelMutation>;
export type CreateClubChannelMutationResult = ApolloReactCommon.MutationResult<CreateClubChannelMutation>;
export type CreateClubChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClubChannelMutation, CreateClubChannelMutationVariables>;
export const ArchiveChannelDocument = gql`
    mutation archiveChannel($input: ArchiveChannelInput!) {
  archiveChannel(input: $input) {
    juniClub {
      _id
      channels {
        archivedAt
      }
    }
  }
}
    `;
export type ArchiveChannelMutationFn = ApolloReactCommon.MutationFunction<ArchiveChannelMutation, ArchiveChannelMutationVariables>;

/**
 * __useArchiveChannelMutation__
 *
 * To run a mutation, you first call `useArchiveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveChannelMutation, { data, loading, error }] = useArchiveChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveChannelMutation, ArchiveChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveChannelMutation, ArchiveChannelMutationVariables>(ArchiveChannelDocument, baseOptions);
      }
export type ArchiveChannelMutationHookResult = ReturnType<typeof useArchiveChannelMutation>;
export type ArchiveChannelMutationResult = ApolloReactCommon.MutationResult<ArchiveChannelMutation>;
export type ArchiveChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveChannelMutation, ArchiveChannelMutationVariables>;
export const EditChannelDocument = gql`
    mutation editChannel($input: EditChannelInput!) {
  editChannel(input: $input) {
    juniClub {
      _id
      channels {
        displayName
        readOnly
      }
    }
  }
}
    `;
export type EditChannelMutationFn = ApolloReactCommon.MutationFunction<EditChannelMutation, EditChannelMutationVariables>;

/**
 * __useEditChannelMutation__
 *
 * To run a mutation, you first call `useEditChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChannelMutation, { data, loading, error }] = useEditChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditChannelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditChannelMutation, EditChannelMutationVariables>) {
        return ApolloReactHooks.useMutation<EditChannelMutation, EditChannelMutationVariables>(EditChannelDocument, baseOptions);
      }
export type EditChannelMutationHookResult = ReturnType<typeof useEditChannelMutation>;
export type EditChannelMutationResult = ApolloReactCommon.MutationResult<EditChannelMutation>;
export type EditChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<EditChannelMutation, EditChannelMutationVariables>;
export const CreateJuniCommunityEventDocument = gql`
    mutation createJuniCommunityEvent($input: CreateJuniCommunityEventInput!) {
  createJuniCommunityEvent(input: $input) {
    juniCommunityEvent {
      _id
    }
  }
}
    `;
export type CreateJuniCommunityEventMutationFn = ApolloReactCommon.MutationFunction<CreateJuniCommunityEventMutation, CreateJuniCommunityEventMutationVariables>;

/**
 * __useCreateJuniCommunityEventMutation__
 *
 * To run a mutation, you first call `useCreateJuniCommunityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJuniCommunityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJuniCommunityEventMutation, { data, loading, error }] = useCreateJuniCommunityEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJuniCommunityEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJuniCommunityEventMutation, CreateJuniCommunityEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJuniCommunityEventMutation, CreateJuniCommunityEventMutationVariables>(CreateJuniCommunityEventDocument, baseOptions);
      }
export type CreateJuniCommunityEventMutationHookResult = ReturnType<typeof useCreateJuniCommunityEventMutation>;
export type CreateJuniCommunityEventMutationResult = ApolloReactCommon.MutationResult<CreateJuniCommunityEventMutation>;
export type CreateJuniCommunityEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJuniCommunityEventMutation, CreateJuniCommunityEventMutationVariables>;
export const UpdateJuniCommunityEventDocument = gql`
    mutation updateJuniCommunityEvent($input: UpdateJuniCommunityEventInput!) {
  updateJuniCommunityEvent(input: $input) {
    juniCommunityEvent {
      _id
    }
  }
}
    `;
export type UpdateJuniCommunityEventMutationFn = ApolloReactCommon.MutationFunction<UpdateJuniCommunityEventMutation, UpdateJuniCommunityEventMutationVariables>;

/**
 * __useUpdateJuniCommunityEventMutation__
 *
 * To run a mutation, you first call `useUpdateJuniCommunityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJuniCommunityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJuniCommunityEventMutation, { data, loading, error }] = useUpdateJuniCommunityEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJuniCommunityEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJuniCommunityEventMutation, UpdateJuniCommunityEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJuniCommunityEventMutation, UpdateJuniCommunityEventMutationVariables>(UpdateJuniCommunityEventDocument, baseOptions);
      }
export type UpdateJuniCommunityEventMutationHookResult = ReturnType<typeof useUpdateJuniCommunityEventMutation>;
export type UpdateJuniCommunityEventMutationResult = ApolloReactCommon.MutationResult<UpdateJuniCommunityEventMutation>;
export type UpdateJuniCommunityEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJuniCommunityEventMutation, UpdateJuniCommunityEventMutationVariables>;
export const SendJuniClubInvitationDocument = gql`
    mutation SendJuniClubInvitation($input: SendJuniClubInvitationInput!) {
  sendJuniClubInvitation(input: $input) {
    juniClubInvitation {
      _id
      guestInvitationId
    }
  }
}
    `;
export type SendJuniClubInvitationMutationFn = ApolloReactCommon.MutationFunction<SendJuniClubInvitationMutation, SendJuniClubInvitationMutationVariables>;

/**
 * __useSendJuniClubInvitationMutation__
 *
 * To run a mutation, you first call `useSendJuniClubInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendJuniClubInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendJuniClubInvitationMutation, { data, loading, error }] = useSendJuniClubInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendJuniClubInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendJuniClubInvitationMutation, SendJuniClubInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendJuniClubInvitationMutation, SendJuniClubInvitationMutationVariables>(SendJuniClubInvitationDocument, baseOptions);
      }
export type SendJuniClubInvitationMutationHookResult = ReturnType<typeof useSendJuniClubInvitationMutation>;
export type SendJuniClubInvitationMutationResult = ApolloReactCommon.MutationResult<SendJuniClubInvitationMutation>;
export type SendJuniClubInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendJuniClubInvitationMutation, SendJuniClubInvitationMutationVariables>;
export const MarkOnDemandModuleSectionInProgressDocument = gql`
    mutation MarkOnDemandModuleSectionInProgress($input: MarkOnDemandModuleSectionInProgressInput!) {
  markOnDemandModuleSectionInProgress(input: $input) {
    success
  }
}
    `;
export type MarkOnDemandModuleSectionInProgressMutationFn = ApolloReactCommon.MutationFunction<MarkOnDemandModuleSectionInProgressMutation, MarkOnDemandModuleSectionInProgressMutationVariables>;

/**
 * __useMarkOnDemandModuleSectionInProgressMutation__
 *
 * To run a mutation, you first call `useMarkOnDemandModuleSectionInProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOnDemandModuleSectionInProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOnDemandModuleSectionInProgressMutation, { data, loading, error }] = useMarkOnDemandModuleSectionInProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkOnDemandModuleSectionInProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkOnDemandModuleSectionInProgressMutation, MarkOnDemandModuleSectionInProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkOnDemandModuleSectionInProgressMutation, MarkOnDemandModuleSectionInProgressMutationVariables>(MarkOnDemandModuleSectionInProgressDocument, baseOptions);
      }
export type MarkOnDemandModuleSectionInProgressMutationHookResult = ReturnType<typeof useMarkOnDemandModuleSectionInProgressMutation>;
export type MarkOnDemandModuleSectionInProgressMutationResult = ApolloReactCommon.MutationResult<MarkOnDemandModuleSectionInProgressMutation>;
export type MarkOnDemandModuleSectionInProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkOnDemandModuleSectionInProgressMutation, MarkOnDemandModuleSectionInProgressMutationVariables>;
export const UpdateProjectShareSettingsDocument = gql`
    mutation UpdateProjectShareSettings($input: UpdateProjectShareSettingsInput!) {
  updateProjectShareSettings(input: $input) {
    studentProject {
      title
      description
      isPublic
      attachedUsermedia
      isCodeHidden
      projectType
    }
    usermedia {
      _id
      title
      description
      status
      createdAt
      originalLength
      s3Url
    }
    projectPageLink
  }
}
    `;
export type UpdateProjectShareSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectShareSettingsMutation, UpdateProjectShareSettingsMutationVariables>;

/**
 * __useUpdateProjectShareSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectShareSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectShareSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectShareSettingsMutation, { data, loading, error }] = useUpdateProjectShareSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectShareSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectShareSettingsMutation, UpdateProjectShareSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectShareSettingsMutation, UpdateProjectShareSettingsMutationVariables>(UpdateProjectShareSettingsDocument, baseOptions);
      }
export type UpdateProjectShareSettingsMutationHookResult = ReturnType<typeof useUpdateProjectShareSettingsMutation>;
export type UpdateProjectShareSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateProjectShareSettingsMutation>;
export type UpdateProjectShareSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectShareSettingsMutation, UpdateProjectShareSettingsMutationVariables>;
export const EnglishAnswerSubmissionsForStudentProjectDocument = gql`
    query englishAnswerSubmissionsForStudentProject($studentId: ObjectId!, $moduleSectionId: ObjectId!) {
  englishAnswerSubmissionsForStudentProject(
    studentId: $studentId
    moduleSectionId: $moduleSectionId
  ) {
    items {
      ...EnglishAnswerSubmission
    }
  }
}
    ${EnglishAnswerSubmissionFragmentDoc}`;

/**
 * __useEnglishAnswerSubmissionsForStudentProjectQuery__
 *
 * To run a query within a React component, call `useEnglishAnswerSubmissionsForStudentProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnglishAnswerSubmissionsForStudentProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnglishAnswerSubmissionsForStudentProjectQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      moduleSectionId: // value for 'moduleSectionId'
 *   },
 * });
 */
export function useEnglishAnswerSubmissionsForStudentProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnglishAnswerSubmissionsForStudentProjectQuery, EnglishAnswerSubmissionsForStudentProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<EnglishAnswerSubmissionsForStudentProjectQuery, EnglishAnswerSubmissionsForStudentProjectQueryVariables>(EnglishAnswerSubmissionsForStudentProjectDocument, baseOptions);
      }
export function useEnglishAnswerSubmissionsForStudentProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnglishAnswerSubmissionsForStudentProjectQuery, EnglishAnswerSubmissionsForStudentProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EnglishAnswerSubmissionsForStudentProjectQuery, EnglishAnswerSubmissionsForStudentProjectQueryVariables>(EnglishAnswerSubmissionsForStudentProjectDocument, baseOptions);
        }
export type EnglishAnswerSubmissionsForStudentProjectQueryHookResult = ReturnType<typeof useEnglishAnswerSubmissionsForStudentProjectQuery>;
export type EnglishAnswerSubmissionsForStudentProjectLazyQueryHookResult = ReturnType<typeof useEnglishAnswerSubmissionsForStudentProjectLazyQuery>;
export type EnglishAnswerSubmissionsForStudentProjectQueryResult = ApolloReactCommon.QueryResult<EnglishAnswerSubmissionsForStudentProjectQuery, EnglishAnswerSubmissionsForStudentProjectQueryVariables>;
export const UpsertEnglishAnswerSubmissionDocument = gql`
    mutation upsertEnglishAnswerSubmission($input: UpsertEnglishAnswerSubmissionInput!) {
  upsertEnglishAnswerSubmission(input: $input) {
    success
  }
}
    `;
export type UpsertEnglishAnswerSubmissionMutationFn = ApolloReactCommon.MutationFunction<UpsertEnglishAnswerSubmissionMutation, UpsertEnglishAnswerSubmissionMutationVariables>;

/**
 * __useUpsertEnglishAnswerSubmissionMutation__
 *
 * To run a mutation, you first call `useUpsertEnglishAnswerSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEnglishAnswerSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEnglishAnswerSubmissionMutation, { data, loading, error }] = useUpsertEnglishAnswerSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertEnglishAnswerSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertEnglishAnswerSubmissionMutation, UpsertEnglishAnswerSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertEnglishAnswerSubmissionMutation, UpsertEnglishAnswerSubmissionMutationVariables>(UpsertEnglishAnswerSubmissionDocument, baseOptions);
      }
export type UpsertEnglishAnswerSubmissionMutationHookResult = ReturnType<typeof useUpsertEnglishAnswerSubmissionMutation>;
export type UpsertEnglishAnswerSubmissionMutationResult = ApolloReactCommon.MutationResult<UpsertEnglishAnswerSubmissionMutation>;
export type UpsertEnglishAnswerSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertEnglishAnswerSubmissionMutation, UpsertEnglishAnswerSubmissionMutationVariables>;
export const EnglishProjectsDocument = gql`
    query englishProjects($studentId: ObjectId!, $moduleSectionId: ObjectId!, $referenceTextSetId: ObjectId) {
  englishProjects(
    studentId: $studentId
    moduleSectionId: $moduleSectionId
    referenceTextSetId: $referenceTextSetId
  ) {
    items {
      ...EnglishProject
    }
  }
}
    ${EnglishProjectFragmentDoc}`;

/**
 * __useEnglishProjectsQuery__
 *
 * To run a query within a React component, call `useEnglishProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnglishProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnglishProjectsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      moduleSectionId: // value for 'moduleSectionId'
 *      referenceTextSetId: // value for 'referenceTextSetId'
 *   },
 * });
 */
export function useEnglishProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnglishProjectsQuery, EnglishProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<EnglishProjectsQuery, EnglishProjectsQueryVariables>(EnglishProjectsDocument, baseOptions);
      }
export function useEnglishProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnglishProjectsQuery, EnglishProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EnglishProjectsQuery, EnglishProjectsQueryVariables>(EnglishProjectsDocument, baseOptions);
        }
export type EnglishProjectsQueryHookResult = ReturnType<typeof useEnglishProjectsQuery>;
export type EnglishProjectsLazyQueryHookResult = ReturnType<typeof useEnglishProjectsLazyQuery>;
export type EnglishProjectsQueryResult = ApolloReactCommon.QueryResult<EnglishProjectsQuery, EnglishProjectsQueryVariables>;
export const CreateAndInitializeEnglishProjectDocument = gql`
    mutation createAndInitializeEnglishProject($input: CreateAndInitializeEnglishProjectInput!) {
  createAndInitializeEnglishProject(input: $input) {
    englishProject {
      ...EnglishProject
    }
  }
}
    ${EnglishProjectFragmentDoc}`;
export type CreateAndInitializeEnglishProjectMutationFn = ApolloReactCommon.MutationFunction<CreateAndInitializeEnglishProjectMutation, CreateAndInitializeEnglishProjectMutationVariables>;

/**
 * __useCreateAndInitializeEnglishProjectMutation__
 *
 * To run a mutation, you first call `useCreateAndInitializeEnglishProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndInitializeEnglishProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndInitializeEnglishProjectMutation, { data, loading, error }] = useCreateAndInitializeEnglishProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAndInitializeEnglishProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAndInitializeEnglishProjectMutation, CreateAndInitializeEnglishProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAndInitializeEnglishProjectMutation, CreateAndInitializeEnglishProjectMutationVariables>(CreateAndInitializeEnglishProjectDocument, baseOptions);
      }
export type CreateAndInitializeEnglishProjectMutationHookResult = ReturnType<typeof useCreateAndInitializeEnglishProjectMutation>;
export type CreateAndInitializeEnglishProjectMutationResult = ApolloReactCommon.MutationResult<CreateAndInitializeEnglishProjectMutation>;
export type CreateAndInitializeEnglishProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAndInitializeEnglishProjectMutation, CreateAndInitializeEnglishProjectMutationVariables>;
export const InvestingAnswerSubmissionsForStudentProjectDocument = gql`
    query investingAnswerSubmissionsForStudentProject($studentId: ObjectId!, $moduleSectionId: ObjectId!) {
  investingAnswerSubmissionsForStudentProject(
    studentId: $studentId
    moduleSectionId: $moduleSectionId
  ) {
    items {
      ...InvestingAnswerSubmission
    }
  }
}
    ${InvestingAnswerSubmissionFragmentDoc}`;

/**
 * __useInvestingAnswerSubmissionsForStudentProjectQuery__
 *
 * To run a query within a React component, call `useInvestingAnswerSubmissionsForStudentProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestingAnswerSubmissionsForStudentProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestingAnswerSubmissionsForStudentProjectQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      moduleSectionId: // value for 'moduleSectionId'
 *   },
 * });
 */
export function useInvestingAnswerSubmissionsForStudentProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvestingAnswerSubmissionsForStudentProjectQuery, InvestingAnswerSubmissionsForStudentProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<InvestingAnswerSubmissionsForStudentProjectQuery, InvestingAnswerSubmissionsForStudentProjectQueryVariables>(InvestingAnswerSubmissionsForStudentProjectDocument, baseOptions);
      }
export function useInvestingAnswerSubmissionsForStudentProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvestingAnswerSubmissionsForStudentProjectQuery, InvestingAnswerSubmissionsForStudentProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvestingAnswerSubmissionsForStudentProjectQuery, InvestingAnswerSubmissionsForStudentProjectQueryVariables>(InvestingAnswerSubmissionsForStudentProjectDocument, baseOptions);
        }
export type InvestingAnswerSubmissionsForStudentProjectQueryHookResult = ReturnType<typeof useInvestingAnswerSubmissionsForStudentProjectQuery>;
export type InvestingAnswerSubmissionsForStudentProjectLazyQueryHookResult = ReturnType<typeof useInvestingAnswerSubmissionsForStudentProjectLazyQuery>;
export type InvestingAnswerSubmissionsForStudentProjectQueryResult = ApolloReactCommon.QueryResult<InvestingAnswerSubmissionsForStudentProjectQuery, InvestingAnswerSubmissionsForStudentProjectQueryVariables>;
export const UpsertInvestingAnswerSubmissionDocument = gql`
    mutation upsertInvestingAnswerSubmission($input: UpsertInvestingAnswerSubmissionInput!) {
  upsertInvestingAnswerSubmission(input: $input) {
    success
  }
}
    `;
export type UpsertInvestingAnswerSubmissionMutationFn = ApolloReactCommon.MutationFunction<UpsertInvestingAnswerSubmissionMutation, UpsertInvestingAnswerSubmissionMutationVariables>;

/**
 * __useUpsertInvestingAnswerSubmissionMutation__
 *
 * To run a mutation, you first call `useUpsertInvestingAnswerSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInvestingAnswerSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInvestingAnswerSubmissionMutation, { data, loading, error }] = useUpsertInvestingAnswerSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertInvestingAnswerSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertInvestingAnswerSubmissionMutation, UpsertInvestingAnswerSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertInvestingAnswerSubmissionMutation, UpsertInvestingAnswerSubmissionMutationVariables>(UpsertInvestingAnswerSubmissionDocument, baseOptions);
      }
export type UpsertInvestingAnswerSubmissionMutationHookResult = ReturnType<typeof useUpsertInvestingAnswerSubmissionMutation>;
export type UpsertInvestingAnswerSubmissionMutationResult = ApolloReactCommon.MutationResult<UpsertInvestingAnswerSubmissionMutation>;
export type UpsertInvestingAnswerSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertInvestingAnswerSubmissionMutation, UpsertInvestingAnswerSubmissionMutationVariables>;
export const InvestingProjectsDocument = gql`
    query investingProjects($studentId: ObjectId!, $moduleSectionId: ObjectId!, $referenceTextSetId: ObjectId) {
  investingProjects(
    studentId: $studentId
    moduleSectionId: $moduleSectionId
    referenceTextSetId: $referenceTextSetId
  ) {
    items {
      ...InvestingProject
    }
  }
}
    ${InvestingProjectFragmentDoc}`;

/**
 * __useInvestingProjectsQuery__
 *
 * To run a query within a React component, call `useInvestingProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestingProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestingProjectsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      moduleSectionId: // value for 'moduleSectionId'
 *      referenceTextSetId: // value for 'referenceTextSetId'
 *   },
 * });
 */
export function useInvestingProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvestingProjectsQuery, InvestingProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<InvestingProjectsQuery, InvestingProjectsQueryVariables>(InvestingProjectsDocument, baseOptions);
      }
export function useInvestingProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvestingProjectsQuery, InvestingProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvestingProjectsQuery, InvestingProjectsQueryVariables>(InvestingProjectsDocument, baseOptions);
        }
export type InvestingProjectsQueryHookResult = ReturnType<typeof useInvestingProjectsQuery>;
export type InvestingProjectsLazyQueryHookResult = ReturnType<typeof useInvestingProjectsLazyQuery>;
export type InvestingProjectsQueryResult = ApolloReactCommon.QueryResult<InvestingProjectsQuery, InvestingProjectsQueryVariables>;
export const CreateAndInitializeInvestingProjectDocument = gql`
    mutation createAndInitializeInvestingProject($input: CreateAndInitializeInvestingProjectInput!) {
  createAndInitializeInvestingProject(input: $input) {
    investingProject {
      ...InvestingProject
    }
  }
}
    ${InvestingProjectFragmentDoc}`;
export type CreateAndInitializeInvestingProjectMutationFn = ApolloReactCommon.MutationFunction<CreateAndInitializeInvestingProjectMutation, CreateAndInitializeInvestingProjectMutationVariables>;

/**
 * __useCreateAndInitializeInvestingProjectMutation__
 *
 * To run a mutation, you first call `useCreateAndInitializeInvestingProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndInitializeInvestingProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndInitializeInvestingProjectMutation, { data, loading, error }] = useCreateAndInitializeInvestingProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAndInitializeInvestingProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAndInitializeInvestingProjectMutation, CreateAndInitializeInvestingProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAndInitializeInvestingProjectMutation, CreateAndInitializeInvestingProjectMutationVariables>(CreateAndInitializeInvestingProjectDocument, baseOptions);
      }
export type CreateAndInitializeInvestingProjectMutationHookResult = ReturnType<typeof useCreateAndInitializeInvestingProjectMutation>;
export type CreateAndInitializeInvestingProjectMutationResult = ApolloReactCommon.MutationResult<CreateAndInitializeInvestingProjectMutation>;
export type CreateAndInitializeInvestingProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAndInitializeInvestingProjectMutation, CreateAndInitializeInvestingProjectMutationVariables>;
export const GetReferenceTextSetsByIdsDocument = gql`
    query getReferenceTextSetsByIds($input: [ObjectId!]!) {
  referenceTextSetsByIds(ids: $input) {
    items {
      ...TextSet
    }
  }
}
    ${TextSetFragmentDoc}`;

/**
 * __useGetReferenceTextSetsByIdsQuery__
 *
 * To run a query within a React component, call `useGetReferenceTextSetsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceTextSetsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceTextSetsByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReferenceTextSetsByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReferenceTextSetsByIdsQuery, GetReferenceTextSetsByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReferenceTextSetsByIdsQuery, GetReferenceTextSetsByIdsQueryVariables>(GetReferenceTextSetsByIdsDocument, baseOptions);
      }
export function useGetReferenceTextSetsByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReferenceTextSetsByIdsQuery, GetReferenceTextSetsByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReferenceTextSetsByIdsQuery, GetReferenceTextSetsByIdsQueryVariables>(GetReferenceTextSetsByIdsDocument, baseOptions);
        }
export type GetReferenceTextSetsByIdsQueryHookResult = ReturnType<typeof useGetReferenceTextSetsByIdsQuery>;
export type GetReferenceTextSetsByIdsLazyQueryHookResult = ReturnType<typeof useGetReferenceTextSetsByIdsLazyQuery>;
export type GetReferenceTextSetsByIdsQueryResult = ApolloReactCommon.QueryResult<GetReferenceTextSetsByIdsQuery, GetReferenceTextSetsByIdsQueryVariables>;
export const RequestUploadUrlDocument = gql`
    mutation RequestUploadUrl($input: RequestUploadUrlInput!) {
  requestUploadUrl(input: $input) {
    uploadUrl
    downloadUrl
  }
}
    `;
export type RequestUploadUrlMutationFn = ApolloReactCommon.MutationFunction<RequestUploadUrlMutation, RequestUploadUrlMutationVariables>;

/**
 * __useRequestUploadUrlMutation__
 *
 * To run a mutation, you first call `useRequestUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUploadUrlMutation, { data, loading, error }] = useRequestUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestUploadUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestUploadUrlMutation, RequestUploadUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestUploadUrlMutation, RequestUploadUrlMutationVariables>(RequestUploadUrlDocument, baseOptions);
      }
export type RequestUploadUrlMutationHookResult = ReturnType<typeof useRequestUploadUrlMutation>;
export type RequestUploadUrlMutationResult = ApolloReactCommon.MutationResult<RequestUploadUrlMutation>;
export type RequestUploadUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestUploadUrlMutation, RequestUploadUrlMutationVariables>;
export const GetCoursesDocument = gql`
    query GetCourses($input: QueryCourseInput) {
  getCourses(input: $input) {
    ...GetCoursesReturn
  }
}
    ${GetCoursesReturnFragmentDoc}`;

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
      }
export function useGetCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
        }
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>;
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>;
export type GetCoursesQueryResult = ApolloReactCommon.QueryResult<GetCoursesQuery, GetCoursesQueryVariables>;
export const GetCourseOfferingsDocument = gql`
    query GetCourseOfferings($input: ObjectId!) {
  getCourseOffering(id: $input) {
    ...CourseOfferingFields
  }
}
    ${CourseOfferingFieldsFragmentDoc}`;

/**
 * __useGetCourseOfferingsQuery__
 *
 * To run a query within a React component, call `useGetCourseOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseOfferingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseOfferingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables>(GetCourseOfferingsDocument, baseOptions);
      }
export function useGetCourseOfferingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables>(GetCourseOfferingsDocument, baseOptions);
        }
export type GetCourseOfferingsQueryHookResult = ReturnType<typeof useGetCourseOfferingsQuery>;
export type GetCourseOfferingsLazyQueryHookResult = ReturnType<typeof useGetCourseOfferingsLazyQuery>;
export type GetCourseOfferingsQueryResult = ApolloReactCommon.QueryResult<GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables>;
export const CountCoursesDocument = gql`
    query CountCourses($input: QueryCourseInput) {
  coursesCount(where: $input)
}
    `;

/**
 * __useCountCoursesQuery__
 *
 * To run a query within a React component, call `useCountCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountCoursesQuery, CountCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountCoursesQuery, CountCoursesQueryVariables>(CountCoursesDocument, baseOptions);
      }
export function useCountCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountCoursesQuery, CountCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountCoursesQuery, CountCoursesQueryVariables>(CountCoursesDocument, baseOptions);
        }
export type CountCoursesQueryHookResult = ReturnType<typeof useCountCoursesQuery>;
export type CountCoursesLazyQueryHookResult = ReturnType<typeof useCountCoursesLazyQuery>;
export type CountCoursesQueryResult = ApolloReactCommon.QueryResult<CountCoursesQuery, CountCoursesQueryVariables>;
export const GetRecommendedAgesDocument = gql`
    query GetRecommendedAges {
  getCourses {
    _id
    name
    displayName
    minRecommendedAge
    maxRecommendedAge
  }
}
    `;

/**
 * __useGetRecommendedAgesQuery__
 *
 * To run a query within a React component, call `useGetRecommendedAgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedAgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedAgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecommendedAgesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecommendedAgesQuery, GetRecommendedAgesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecommendedAgesQuery, GetRecommendedAgesQueryVariables>(GetRecommendedAgesDocument, baseOptions);
      }
export function useGetRecommendedAgesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecommendedAgesQuery, GetRecommendedAgesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecommendedAgesQuery, GetRecommendedAgesQueryVariables>(GetRecommendedAgesDocument, baseOptions);
        }
export type GetRecommendedAgesQueryHookResult = ReturnType<typeof useGetRecommendedAgesQuery>;
export type GetRecommendedAgesLazyQueryHookResult = ReturnType<typeof useGetRecommendedAgesLazyQuery>;
export type GetRecommendedAgesQueryResult = ApolloReactCommon.QueryResult<GetRecommendedAgesQuery, GetRecommendedAgesQueryVariables>;
export const GetCourseBasicMetadataDocument = gql`
    query GetCourseBasicMetadata {
  getCourses {
    _id
    name
    displayName
    slug
    abbreviation
    iconName
    primaryColor
    subject {
      _id
      name
      displayName
    }
    isAcceptingEnrollment
    minRecommendedAge
    maxRecommendedAge
    schedulingFormat
    defaultJideEnv
  }
}
    `;

/**
 * __useGetCourseBasicMetadataQuery__
 *
 * To run a query within a React component, call `useGetCourseBasicMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseBasicMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseBasicMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseBasicMetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseBasicMetadataQuery, GetCourseBasicMetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseBasicMetadataQuery, GetCourseBasicMetadataQueryVariables>(GetCourseBasicMetadataDocument, baseOptions);
      }
export function useGetCourseBasicMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseBasicMetadataQuery, GetCourseBasicMetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseBasicMetadataQuery, GetCourseBasicMetadataQueryVariables>(GetCourseBasicMetadataDocument, baseOptions);
        }
export type GetCourseBasicMetadataQueryHookResult = ReturnType<typeof useGetCourseBasicMetadataQuery>;
export type GetCourseBasicMetadataLazyQueryHookResult = ReturnType<typeof useGetCourseBasicMetadataLazyQuery>;
export type GetCourseBasicMetadataQueryResult = ApolloReactCommon.QueryResult<GetCourseBasicMetadataQuery, GetCourseBasicMetadataQueryVariables>;
export const GetCourseServiceDocument = gql`
    query GetCourseService($input: QueryCourseInput) {
  getCourses(input: $input) {
    ...GetCourseServiceResult
  }
}
    ${GetCourseServiceResultFragmentDoc}`;

/**
 * __useGetCourseServiceQuery__
 *
 * To run a query within a React component, call `useGetCourseServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseServiceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseServiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCourseServiceQuery, GetCourseServiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCourseServiceQuery, GetCourseServiceQueryVariables>(GetCourseServiceDocument, baseOptions);
      }
export function useGetCourseServiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseServiceQuery, GetCourseServiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCourseServiceQuery, GetCourseServiceQueryVariables>(GetCourseServiceDocument, baseOptions);
        }
export type GetCourseServiceQueryHookResult = ReturnType<typeof useGetCourseServiceQuery>;
export type GetCourseServiceLazyQueryHookResult = ReturnType<typeof useGetCourseServiceLazyQuery>;
export type GetCourseServiceQueryResult = ApolloReactCommon.QueryResult<GetCourseServiceQuery, GetCourseServiceQueryVariables>;
export const GetRecommendedCoursesDocument = gql`
    query GetRecommendedCourses($input: QueryCourseInput) {
  getCourses(input: $input) {
    ...GetRecommendedCoursesResult
  }
}
    ${GetRecommendedCoursesResultFragmentDoc}`;

/**
 * __useGetRecommendedCoursesQuery__
 *
 * To run a query within a React component, call `useGetRecommendedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecommendedCoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecommendedCoursesQuery, GetRecommendedCoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecommendedCoursesQuery, GetRecommendedCoursesQueryVariables>(GetRecommendedCoursesDocument, baseOptions);
      }
export function useGetRecommendedCoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecommendedCoursesQuery, GetRecommendedCoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecommendedCoursesQuery, GetRecommendedCoursesQueryVariables>(GetRecommendedCoursesDocument, baseOptions);
        }
export type GetRecommendedCoursesQueryHookResult = ReturnType<typeof useGetRecommendedCoursesQuery>;
export type GetRecommendedCoursesLazyQueryHookResult = ReturnType<typeof useGetRecommendedCoursesLazyQuery>;
export type GetRecommendedCoursesQueryResult = ApolloReactCommon.QueryResult<GetRecommendedCoursesQuery, GetRecommendedCoursesQueryVariables>;
export const LoadTruncatedInstructorNamesDocument = gql`
    query LoadTruncatedInstructorNames($ids: [String!]!, $idFieldName: String!) {
  truncatedInstructorNames(ids: $ids, idFieldName: $idFieldName) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useLoadTruncatedInstructorNamesQuery__
 *
 * To run a query within a React component, call `useLoadTruncatedInstructorNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTruncatedInstructorNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTruncatedInstructorNamesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      idFieldName: // value for 'idFieldName'
 *   },
 * });
 */
export function useLoadTruncatedInstructorNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadTruncatedInstructorNamesQuery, LoadTruncatedInstructorNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadTruncatedInstructorNamesQuery, LoadTruncatedInstructorNamesQueryVariables>(LoadTruncatedInstructorNamesDocument, baseOptions);
      }
export function useLoadTruncatedInstructorNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadTruncatedInstructorNamesQuery, LoadTruncatedInstructorNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadTruncatedInstructorNamesQuery, LoadTruncatedInstructorNamesQueryVariables>(LoadTruncatedInstructorNamesDocument, baseOptions);
        }
export type LoadTruncatedInstructorNamesQueryHookResult = ReturnType<typeof useLoadTruncatedInstructorNamesQuery>;
export type LoadTruncatedInstructorNamesLazyQueryHookResult = ReturnType<typeof useLoadTruncatedInstructorNamesLazyQuery>;
export type LoadTruncatedInstructorNamesQueryResult = ApolloReactCommon.QueryResult<LoadTruncatedInstructorNamesQuery, LoadTruncatedInstructorNamesQueryVariables>;