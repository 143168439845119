import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  ANNOTATION_LINE_HEIGHT,
  ANNOTATION_WIDTH,
  INACTIVE_ANNOTATION_LEFT,
  HIGHLIGHT_COLOR_VALUES,
} from 'components/RichTextAnnotator/constants';

const Annotation: React.FC<{
  annotation: string;
  annotationTop: number;
  highlightColor: string;
  entityKey: string;
  setAnnotationBottom: (entityKey: string, bottom: number) => void;
}> = ({
  annotation,
  annotationTop,
  highlightColor,
  entityKey,
  setAnnotationBottom,
}) => {
  const self = useRef<HTMLDivElement>(null);
  const [bottom, setBottom] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (!self?.current) return;
    const curBottom = self.current.getBoundingClientRect().bottom + window.scrollY;
    if (curBottom === bottom) return;
    setBottom(curBottom);
    setAnnotationBottom(entityKey, curBottom);
  }, [bottom, setAnnotationBottom, entityKey]);
  const highlightColorValue = HIGHLIGHT_COLOR_VALUES[highlightColor].solidValue;
  return (
    <StyledAnnotation ref={self} top={annotationTop}>
      <StyledColorBar color={highlightColorValue} />
      <StyledAnnotationCardContent>
        <label>You wrote:</label>
        <StyledAnnotationText>{annotation}</StyledAnnotationText>
      </StyledAnnotationCardContent>
    </StyledAnnotation>
  );
};

const StyledAnnotation = styled.div<any>`
  position: absolute;
  top: ${props => props.top}px;
  left: ${INACTIVE_ANNOTATION_LEFT}px;
  background: #fff;
  border: 2px solid hsl(201, 20%, 89%);
  border-radius: 8px;
  width: ${ANNOTATION_WIDTH}px;
  font-size: 14px;
  label {
    font-weight: bold;
    color: hsl(206, 39%, 33%);
  }
  overflow: hidden;
  box-sizing: border-box;
`;
const StyledAnnotationCardContent = styled.div`
  padding: 1rem;
`;
const StyledColorBar = styled.div`
  background: ${props => props.color};
  height: 6px;
  width: 100%;
`;
const StyledAnnotationText = styled.div`
  margin-top: 6px;
  line-height: ${ANNOTATION_LINE_HEIGHT}px;
  color: hsl(202, 33%, 35%);
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
`;

export default Annotation;
