import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { juniPurple } from 'theme/old-colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const CLASSLIST = `
  mb-8
  font-semibold 
  no-underline
  bg-white 
  text-juni-purple-500
  hover:text-juni-purple-300
`;

const A_CLASSLIST = `
  flex
  items-center
  space-x-2
  no-underline
`;

const LinkContainer = styled.div`
  * {
    color: ${juniPurple[500]};
  }
  &:hover {
    * {
      color: ${juniPurple[300]};
    }
    polyline {
      stroke: ${juniPurple[300]};
    }
  }
`;

const TextBackButton: FC<{
  href?: string;
  external?: boolean;
  onClick?: () => void;
}> = ({ href, children, onClick, external = false }) => {
  if (onClick) {
    return (
      <LinkContainer className={CLASSLIST}>
        <div className={`${A_CLASSLIST} cursor-pointer`} onClick={onClick}>
          <FontAwesomeIcon className="text-juni-purple-500" icon={faChevronLeft} />{' '}
          <span>{children ?? 'back'}</span>
        </div>
      </LinkContainer>
    );
  }
  if (!href) {
    console.error('TextBackButton requires either a string or onClick');
    return null;
  }
  return external ? (
    <LinkContainer className={CLASSLIST}>
      <a className={A_CLASSLIST} href={href}>
        <FontAwesomeIcon className="text-juni-purple-500" icon={faChevronLeft} />{' '}
        <span>{children ?? 'back'}</span>
      </a>
    </LinkContainer>
  ) : (
    <LinkContainer className={CLASSLIST}>
      <Link className={A_CLASSLIST} to={href}>
        <FontAwesomeIcon className="text-juni-purple-500" icon={faChevronLeft} />{' '}
        <span>{children ?? 'back'}</span>
      </Link>
    </LinkContainer>
  );
};

export default TextBackButton;
