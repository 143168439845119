import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { NewButton as Button, Icon } from 'core-components';
import { logShareEvent } from 'utils/social_share/social_share';
import { ShareMethod } from 'constants/analytics_events';

const DISPLAY_TEXT_DEFAULT = 'Copy';
const ON_COPY_DISPLAY_TEXT_DEFAULT = 'Copied!';

interface CopyButtonProps {
  /**
   * Specifies the display text for the button's initial state.
   */
  displayText?: string;
  /**
   * Specifies display text for the button's copied state.
   */
  onCopyDisplayText?: string;
  /**
   * The text to be copied.
   */
  textContent: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  onCopyDisplayText = ON_COPY_DISPLAY_TEXT_DEFAULT,
  displayText = DISPLAY_TEXT_DEFAULT,
  textContent,
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (copied) {
      setTimeout(() => {
        if (mounted) setCopied(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [copied, displayText]);

  return (
    /** TODO: Figure out share content here */
    <CopyToClipboard
      text={textContent}
      onCopy={() => {
        setCopied(true);
        logShareEvent(ShareMethod.linkCopied);
      }}
    >
      <Button
        intent={copied ? 'success' : 'default'}
        variant="minimal"
        renderIconLeft={() => (copied ? <Icon.Check /> : <Icon.Copy />)}
      >
        <div className="ml-2">{copied ? onCopyDisplayText : displayText}</div>
      </Button>
    </CopyToClipboard>
  );
};

export default CopyButton;
