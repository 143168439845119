import { COURSE_TYPES } from 'constants/subjects';

export const getMySubject: (
  courseType: typeof COURSE_TYPES[keyof typeof COURSE_TYPES], // "code" | "math" | "english" | "investing"
) => string = courseType => {
  const courseTypeToMySubject = {
    [COURSE_TYPES.COMPUTER_SCIENCE]: 'my_code',
    [COURSE_TYPES.MATHEMATICS]: 'my_math',
    [COURSE_TYPES.ENGLISH]: 'my_english',
    [COURSE_TYPES.INVESTING]: 'my_courses',
    [COURSE_TYPES.SCIENCE]: 'my_courses',
  };
  return courseTypeToMySubject[courseType];
};

// prefix section displayName with section's module name
export const getQualifiedDisplayName = (
  sectionDisplayName: string,
  moduleName: string,
  sectionType = '',
) => {
  if (!sectionDisplayName || sectionDisplayName === '--') return '--';
  if (!moduleName) return sectionDisplayName;

  const sectionTypesToQualify = [
    'concept',
    'problem_set',
    'activity',
    'reading_project',
    'writing_project',
    'question_set',
  ];
  if (sectionTypesToQualify.includes(sectionType)) {
    if (sectionDisplayName.startsWith(moduleName)) return sectionDisplayName;

    return `${moduleName} ${sectionDisplayName}`;
  }

  return sectionDisplayName;
};
