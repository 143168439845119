import React, { FC, useState, useCallback } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import { parseError } from 'utils/errors';
import Button from '../Button';

import './index.css';

interface Props {
  className?: string;
  value?: string | string[] | null;
  options: { label: string; value: string | null }[];
  name?: string;
  save?: (value: string, name?: string) => Promise<any>;
  saveMulti?: (value: string[], name?: string) => Promise<any>;
  popOut?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
}

const EditableReactSelect: FC<Props> = ({
  className,
  value,
  options,
  name,
  save,
  popOut,
  isMulti,
  saveMulti,
  isClearable,
  disabled,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
    setError(null);
  }, []);

  const handleChange = useCallback(
    async option => {
      setIsSaving(true);
      try {
        if (Array.isArray(option)) {
          if (!saveMulti) throw new Error('saveMulti required if isMulti is true');
          await saveMulti(
            option.map(o => o.value),
            name,
          );
        } else {
          if (!save) throw new Error('save required if isMulti is false');
          await save(option.value, name);
        }
        setIsEditing(false);
      } catch (err) {
        setError(parseError(err));
      } finally {
        setIsSaving(false);
      }
    },
    [name, save, saveMulti],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const selectOptions = options || [];
  const findOption = (val?: string | null) =>
    selectOptions.find(o => o.value === val) || null;
  let selectedOptions;
  let optionsText = 'None';
  if (Array.isArray(value)) {
    const foundOptions = value.map(val => findOption(val));
    selectedOptions = foundOptions.filter(x => x);
    optionsText =
      foundOptions.map(o => o?.label ?? 'Unknown Option').join('\n') || 'None';
  } else if (value) {
    selectedOptions = findOption(value);
    optionsText = selectedOptions?.label || 'Unknown Option';
  }

  return (
    <div
      className={classNames(
        {
          'ui-editable-react-select': true,
          'ui-pop-out': popOut,
        },
        className,
      )}
    >
      {isEditing && !disabled ? (
        <div className="ui-editor">
          {isSaving ? (
            <em>Saving...</em>
          ) : (
            <Select
              disabled={isSaving}
              onChange={handleChange}
              options={selectOptions}
              value={selectedOptions}
              isMulti={isMulti}
              isClearable={isClearable}
            />
          )}
          {error && <div className="ui-field-error">{error}</div>}
          <div className="ui-button-row">
            <Button disabled={isSaving} onClick={handleCancel} small>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ whiteSpace: 'pre-line' }}>{optionsText}</div>
          {!disabled && (
            <div className="ui-button-row">
              <Button onClick={handleEdit} small>
                Edit
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditableReactSelect;
