import React from 'react';
import Footer from '../Footer';

interface FullScreenContentProps {
  /**
   * Any header content, string or JSX, ReactNode, etc.
   */
  header: React.ReactNode;
  /**
   * If included, the contents of the Footer.
   */
  renderFooterContent?: () => React.ReactNode;
}

const width = 'min(856px, 75vw)';

const FullScreenContent: React.FC<FullScreenContentProps> = ({
  header,
  renderFooterContent,
  children,
}) => (
  <div className="w-full h-full flex flex-col bg-white font-graphik text-j-dark-600">
    <div className="py-4 flex justify-center text-xl leading-8 border-0 border-b border-solid border-j-purple-200">
      <div style={{ width }}>{header}</div>
    </div>
    <div className="pt-6 flex flex-1 justify-center overflow-y-scroll">
      <div style={{ width }}>{children}</div>
    </div>
    {renderFooterContent && (
      <Footer className="flex justify-center">
        <div style={{ width }}>{renderFooterContent()}</div>
      </Footer>
    )}
  </div>
);

export default FullScreenContent;
