import { SUBJECTS } from 'constants/subjects';

export const shareableProjectTypes = [
  'python',
  'pythonTurtle',
  'scratch',
  'java',
  'cpp',
  'whiteboard',
  'math',
  'math_whiteboard',
  'english_writing',
  'english_whiteboard',
  'investing_whiteboard',
  'investing_writing',
];

export const environmentsWithCodeEditor = ['python', 'pythonTurtle', 'java', 'cpp'];

// NOTE: every time we add a new course, we need to add it here.
// TODO: maybe move this to DB field on course

export const MATH_WHITEBOARD_SECTION_TYPES = ['concept', 'activity', 'problem_set'];

export const ENGLISH_WHITEBOARD_SECTION_TYPES = ['concept', 'activity'];

export const INVESTING_WHITEBOARD_SECTION_TYPE = ['concept', 'activity'];

// For non coding projects the environment for each module section
// varies depending on sectionType. Each sectionType maps to a specific
// UI environment. This lookup table uses subjectName and sectionType to determine the project type
// for a module section.
// Make sure this is up to date as new subjects and/or
// new sectionTypes + UI environments are added.
export const environmentLookupBySubjectNameAndSectionType = (
  subjectName: string,
  sectionType: string,
) => {
  // TODO: If we introduce sectionType to CS course modules we will
  // want to update this
  if (subjectName === SUBJECTS.MATHEMATICS) {
    if (MATH_WHITEBOARD_SECTION_TYPES.includes(sectionType)) {
      return 'math_whiteboard';
    }
    return 'math';
  }
  if (subjectName === SUBJECTS.ENGLISH) {
    if (ENGLISH_WHITEBOARD_SECTION_TYPES.includes(sectionType)) {
      return 'english_whiteboard';
    }
    if (sectionType === 'writing_project') {
      return 'english_writing';
    }
    return 'english';
  }
  if (subjectName === SUBJECTS.INVESTING) {
    if (INVESTING_WHITEBOARD_SECTION_TYPE.includes(sectionType)) {
      return 'investing_whiteboard';
    }
    if (sectionType === 'writing_project') {
      return 'investing_writing';
    }
    return 'investing';
  }

  return 'unknown';
};

export const projectTypeToIconName = (projectType?: string) => {
  const projectTypeToIconNameLookup: Record<string, string> = {
    scratch: 'Scratch',
    web: 'Code',
    pythonTurtle: 'Turtle',
    python: 'Python',
    java: 'Java',
    math: 'Math',
    math_whiteboard: 'Math',
    whiteboard: 'Math',
    cpp: 'Cpp',
    js: 'Javascript',
    investing: 'Investing',
    investing_writing: 'Investing',
    investing_whiteboard: 'Investing',
    english: 'English',
    english_writing: 'English',
    english_whiteboard: 'English',
  };

  if (projectType && projectType in projectTypeToIconNameLookup) {
    return projectTypeToIconNameLookup[projectType];
  }
  return 'QuestionMark';
};
