import React, { useState, useMemo, useContext } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { startOfDay } from 'date-fns';
import {
  ClubChannels,
  FriendInviter,
  ClubSidebar,
  ClubMembersListModal,
  Chat,
  ClubOptions,
  ClubEventCard,
  EventEditor,
} from 'components/clubs';
import { Button, ModalWindow } from 'core-components';
import JuniSpinner from 'components/JuniSpinner';
import colors from 'theme/club-colors';
import { getClubLink, getMyClubsLink } from 'app/clubs/helpers';
import { useClubMemberLookup } from 'app/clubs/stores/ClubStoreHelpers';
import UserContext from 'modules/UserContext';
import useClubStore from './stores/ClubStore';
import { EventsHeader } from './styles/MyClubsStyles';

// IMPORTANT: VARIABLE NAMING CONVENTIONS
// Since there's a bit of overlap between variables that are passed down as props or URL params
// and variables gotten from hooks, API calls, and derived from the ClubStore/Zustand, we have
// defined a naming convention to make things more clear and lessen the chance of race conditions, etc.
// ___Param -> a variable that has been passed down from App.js/LearnerApp.js/InstructorApp.js
//     and/or a URL parameter. These variables are typically the first to change and are good for
//     API calls to depend on
// ___State -> a variable that is taken directly from ClubStore/Zustand. These variables are typically
//     updated after API calls and certain logics in custom hooks, and are slower to change.
//     these are better for UI elements to depend on (things that don't mind re-rendering a couple times)

interface ClubPageProps {
  juniClubIdParam: string;
  channelNameParam: string;
  studentIdParam?: string;
  instructorUserIdParam?: string;
}
const ClubPage: React.FC<ClubPageProps> = ({
  juniClubIdParam,
  channelNameParam,
  studentIdParam,
  instructorUserIdParam,
}) => {
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [inviterOpen, setInviterOpen] = useState(false);
  const [createEventOpen, setCreateEventOpen] = useState(false);

  const [clientUUIDToClubMemberState, currentClubMemberState] = useClubMemberLookup({
    juniClubId: juniClubIdParam,
    studentId: studentIdParam,
    instructorUserId: instructorUserIdParam,
  });
  const activeClubMembers = useMemo(
    () => _.values(clientUUIDToClubMemberState).filter(m => m.isActive),
    [clientUUIDToClubMemberState],
  );
  const { user } = useContext(UserContext);
  const isGuest = user?.isGuest;
  const isClubsLoading = useClubStore(state => state.isClubsLoading);
  const unreadMessages = useClubStore(state => state.unreadMessages);
  const juniClubsState = useClubStore(state => state.juniClubs);
  const juniClubsEventsState = useClubStore(state => state.juniClubsEvents);
  const leavingClubId = useClubStore(state => state.leavingClubId);
  const currentClubState = juniClubsState[juniClubIdParam] || {};
  const clubEvents = _.sortBy(
    _.values(juniClubsEventsState).filter(
      event =>
        event &&
        event.juniClubIds.includes(juniClubIdParam) &&
        !event.archivedAt &&
        new Date(event.startDateTime) > startOfDay(new Date()),
    ),
    obj => obj && obj.startDateTime && new Date(obj.startDateTime),
  );

  type ColorKey = keyof typeof colors;
  const bgColor = colors[currentClubState.colorTheme as ColorKey]?.medium;
  const injectAlphaIntoHex = (hexcode: string, alpha: string) =>
    `${hexcode}${alpha}`;
  const badgeName = currentClubMemberState?.badgeInfo?.name;
  const canCreateEvents = !!(
    badgeName && ['advisor', 'moderator'].includes(badgeName)
  );
  const currentClubChannel = currentClubState.channels?.find(
    channel => channel.displayName === channelNameParam && !channel.archivedAt,
  );
  if (isClubsLoading) {
    return <JuniSpinner size={100} />;
  }
  if (_.isEmpty(currentClubState)) {
    return (
      <Redirect
        to={getMyClubsLink({
          studentId: studentIdParam,
          queryParams: {
            // omit the joinClubId if they are actively leaving (leave modal)
            joinClubId:
              leavingClubId === juniClubIdParam ? undefined : juniClubIdParam,
          },
        })}
      />
    );
  }
  if (
    !(currentClubState.channels || [])
      .map(channel => channel.displayName)
      .includes(channelNameParam)
  ) {
    return (
      <Redirect
        to={getClubLink({ studentId: studentIdParam, juniClubId: juniClubIdParam })}
      />
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 0',
        overflow: 'auto hidden',
        backgroundColor: bgColor,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: `
          linear-gradient(
            to bottom,
            ${injectAlphaIntoHex(bgColor, 'cc')},
            ${injectAlphaIntoHex(bgColor, 'FF')}
          ),
          url(${currentClubState.coverPhoto || ''})`,
      }}
    >
      <ClubSidebar
        studentId={studentIdParam}
        currentClubId={currentClubState._id}
        juniClubs={_.values(juniClubsState)}
        unreadMessages={unreadMessages}
      />

      <ClubMembersListModal
        clubMembers={activeClubMembers}
        isOpen={membersModalOpen}
        closeModal={() => {
          setMembersModalOpen(false);
        }}
        juniClub={currentClubState}
        currentClubMemberState={currentClubMemberState}
        studentId={studentIdParam}
        instructorUserId={instructorUserIdParam}
      />

      <ModalWindow
        bannerImgSrc={currentClubState.coverPhoto || ''}
        title={`Invite Friends to ${currentClubState.displayName}`}
        description="You can invite any of your friends, even if they don't already have a Juni account!"
        isOpen={inviterOpen}
        closeModal={() => {
          setInviterOpen(false);
        }}
        maxWidth="100%"
      >
        <FriendInviter
          juniClubId={currentClubState._id}
          inviterStudentId={studentIdParam}
          inviterInstructorUserId={instructorUserIdParam}
        />
      </ModalWindow>
      <ModalWindow
        title="Create Event"
        isOpen={createEventOpen}
        closeModal={() => {
          setCreateEventOpen(false);
        }}
        maxWidth="100%"
        whiteBackground
      >
        <EventEditor
          instructorUserId={instructorUserIdParam}
          juniClubId={juniClubIdParam}
          juniClubCoverUrl={currentClubState?.coverPhoto || ''}
          onClose={() => {
            setCreateEventOpen(false);
          }}
        />
      </ModalWindow>

      <div
        className="flex flex-col flex-grow px-6 py-4"
        style={{ minWidth: '48rem' }}
      >
        <div className="text-white mx-1 mb-3">
          <div className="flex flex-wrap justify-between items-center">
            <h1 className="m-0 mr-6 text-2xl lg:text-3xl">
              {currentClubState.displayName}
            </h1>
            <div className="flex flex-row flex-grow flex-wrap items-center justify-between my-2">
              <div
                className="whitespace-nowrap mr-4 bg-white bg-opacity-25 py-1 px-3 rounded-lg font-semibold opacity-90 cursor-pointer"
                onClick={() => {
                  setMembersModalOpen(true);
                }}
              >
                {`${activeClubMembers.length} member${
                  activeClubMembers.length !== 1 ? 's' : ''
                }`}
              </div>
            </div>
            <div className="flex flex-row flex-wrap my-2">
              {canCreateEvents && (
                <Button
                  variant="transparent"
                  className="mr-4"
                  onClick={() => {
                    setCreateEventOpen(true);
                  }}
                >
                  Create an Event
                </Button>
              )}
              {!isGuest && (
                <Button
                  variant="transparent"
                  className="mr-4"
                  onClick={() => {
                    setInviterOpen(true);
                  }}
                >
                  Invite a Friend
                </Button>
              )}
              <ClubOptions
                juniClub={currentClubState}
                studentId={studentIdParam}
                instructorUserId={instructorUserIdParam}
                currentClubMemberState={currentClubMemberState}
              />
            </div>
          </div>
          <div
            className="whitespace-nowrap overflow-hidden overflow-ellipsis mt-1 mb-2"
            title={currentClubState.description || ''}
          >
            {currentClubState.description}
          </div>
        </div>
        <div className="flex flex-1 overflow-hidden">
          <div className="flex-shrink-0 w-1/5 overflow-auto">
            <ClubChannels
              studentIdParam={studentIdParam}
              instructorUserIdParam={instructorUserIdParam}
              channelNameParam={channelNameParam}
              currentClubState={currentClubState}
              currentClubMemberState={currentClubMemberState}
              unreadMessages={unreadMessages}
            />
            <>
              <EventsHeader>Events</EventsHeader>
              {clubEvents?.length ? (
                clubEvents.map(event => (
                  <ClubEventCard
                    key={event._id}
                    event={event}
                    className="text-white"
                    currentClubState={currentClubState}
                    currentClubMemberState={currentClubMemberState}
                    instructorUserIdParam={instructorUserIdParam}
                  />
                ))
              ) : (
                <div className="text-sm text-white opacity-70 mr-6">
                  There are currently no upcoming events for this Club!
                </div>
              )}
            </>
          </div>
          <Chat
            className={classNames(
              'flex',
              'flex-col',
              'flex-grow',
              'justify-end',
              'rounded-lg',
              'bg-white',
              'border-2',
              'border-solid',
              `border-${currentClubState.colorTheme}-light`,
            )}
            isGuest={isGuest}
            studentIdParam={studentIdParam}
            instructorUserIdParam={instructorUserIdParam}
            juniClubIdParam={juniClubIdParam}
            channelNameParam={channelNameParam}
            clientUUIDToClubMemberState={clientUUIDToClubMemberState}
            currentClubMemberState={currentClubMemberState}
            currentClubChannel={currentClubChannel}
          />
        </div>
      </div>
    </div>
  );
};

export default ClubPage;
