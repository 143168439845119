import { curry } from 'ramda';
import { DiscountData } from '../types';

const makeDiscountedPrice = curry(
  (priceBeforeCoupon: number, discountData: DiscountData): number =>
    discountData.discountType === 'amount_off'
      ? // for amount_off coupons, Stripe sends `discount` in cents. / 100 parses it back to dollars
        priceBeforeCoupon - discountData.discount / 100
      : discountData.discountType === 'percent_off'
      ? // similarhere - for percent_off coupons, stripe sends it in whole numbers rather than decimals
        priceBeforeCoupon - priceBeforeCoupon * (discountData.discount / 100)
      : priceBeforeCoupon,
);

export default makeDiscountedPrice;
