import React, { FC } from 'react';
import styled from 'styled-components/macro';

import {
  TailwindColor,
  TailwindSpace,
  TailwindBorderWidth,
  TailwindHeight,
} from 'theme/types';

export interface CardContentProps {
  /**
   * Padding for card, styled with Tailwind scale.
   */
  padding?: TailwindSpace;
  /**
   * Determines if we add spacing rules for content (recommended in most cases).
   */
  spaceContent?: boolean;
  /**
   * Class name that's passed to the card's content component; can include Tailwind classes.
   */
  contentClassName?: string;
}
export interface CardProps extends CardContentProps {
  /**
   * Background color of card; must be part our Tailwind theme (ex: juni-blue-500).
   */
  backgroundColor?: TailwindColor;
  /**
   * Source for a banner image at the top of the card.
   */
  bannerImgSrc?: string;
  /**
   * Class name that's passed to the banner image element; can include Tailwind classes
   */
  bannerImgClassName?: string;
  /**
   * Height of the container for the banner img and topLabelText, styled with Tailwind scale.
   */
  bannerHeight?: TailwindHeight;
  /**
   * React components or html elements to display on the banner
   */
  bannerContent?: any;
  /**
   * Border width for card, styled with Tailwind scale.
   */
  borderWidth?: TailwindBorderWidth;
  /**
   * Border color for card; must be part our Tailwind theme (ex: juni-blue-500).
   */
  borderColor?: TailwindColor;
  /**
   * Determines whether or not card has box shadow.
   */
  boxShadow?: boolean;
  /**
   * Determines whether or not card has hidden overflow.
   */
  hideOverflow?: boolean;
  /**
   * Determines whether or not card has rounded corners.
   */
  noRounding?: boolean;
  /**
   * Class name that's passed to the card; can include Tailwind classes.
   */
  className?: string;
  /**
   * Text at the top of the card
   */
  topLabelText?: string;
  /**
   * Determines background color of text at top of the card
   */
  topLabelTextBgColor?: TailwindColor;
}

const CardBase = styled.div.attrs((props: CardProps) => ({
  className: `
      border-solid
      ${props.hideOverflow ? 'overflow-hidden' : ''}
      ${props.noRounding ? '' : 'rounded-lg'}
      bg-${props.backgroundColor} 
      border${props.borderWidth ? `-${props.borderWidth}` : ''} 
      border-${props.borderColor}`,
}))<any>`
  box-shadow: ${({ boxShadow }) =>
    boxShadow && '0px 0.6rem 1rem rgba(209, 231, 239, 0.4)'};
`;

const CardContent = styled.div.attrs((props: CardContentProps) => ({
  className: `p-${props.padding} ${props.contentClassName}`,
}))<any>`
  & > * + * {
    margin-top: ${({ spaceContent }) => spaceContent && '1rem'};
  }
`;
/**
 * Basic card component, styled primarily via Tailwind.
 */
const Card: FC<CardProps> = ({
  backgroundColor = 'white',
  borderColor = 'blue-gray-100',
  borderWidth = '',
  boxShadow = true,
  hideOverflow = true,
  noRounding = false,
  padding = '6',
  spaceContent = true,
  bannerImgSrc,
  bannerImgClassName,
  bannerContent,
  bannerHeight,
  contentClassName,
  className,
  children,
  topLabelText,
  topLabelTextBgColor,
}) => (
  <CardBase
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    borderWidth={borderWidth}
    boxShadow={boxShadow}
    className={className}
    hideOverflow={hideOverflow}
    noRounding={noRounding}
  >
    <div className={`${bannerHeight ? `h-${bannerHeight}` : ''} overflow-hidden`}>
      {topLabelText && (
        <div
          className={`text-white text-sm p-2 text-center font-bold bg-${
            topLabelTextBgColor || 'juni-blue-400'
          }`}
        >
          {topLabelText}
        </div>
      )}
      {bannerImgSrc && (
        <img
          src={bannerImgSrc}
          alt=""
          className={`w-full p-0 ${!topLabelText ? 'rounded-t-lg' : ''} ${
            bannerImgClassName || ''
          }`}
        />
      )}
      {bannerContent}
    </div>
    <CardContent
      contentClassName={contentClassName}
      padding={padding}
      spaceContent={spaceContent}
    >
      {children}
    </CardContent>
  </CardBase>
);

export default Card;
