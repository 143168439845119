import { Dispatch, FC, SetStateAction } from 'react';
import { InstructorUnavailablePreference } from 'generated/graphql';
import { MutationResult } from '@apollo/client';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import SpinnerV2 from 'components/SpinnerV2';
import { ACUITY_TYPE_TO_TEXT } from 'constants/acuity';
import { PRIORITY_LEVELS } from 'utils/enrollment';
import { Message } from 'core-components';
import { AvailabilityPreferences } from '../types';

const daysAtTime = "EEEE's' 'at' h:mm a zzz";
const startingMDY = "'starting' MMM dd, yyyy";

interface InstructorUnavailablePreferenceStepProps {
  studentFirstName: string;
  appointmentDatetime: string;
  appointmentTypeID: number;
  selectedDatetime?: Date;
  isCustomTimeSelection: boolean;
  customAvailabilities: AvailabilityPreferences;
  results: MutationResult;
  timezone: string;
  instructorUnavailablePreference?: InstructorUnavailablePreference;
  setInstructorUnavailablePreference: Dispatch<
    SetStateAction<InstructorUnavailablePreference | undefined>
  >;
}

const InstructorUnavailablePreferenceStep: FC<InstructorUnavailablePreferenceStepProps> = ({
  studentFirstName,
  appointmentDatetime,
  appointmentTypeID,
  selectedDatetime,
  isCustomTimeSelection,
  customAvailabilities,
  results,
  timezone,
  instructorUnavailablePreference,
  setInstructorUnavailablePreference,
}) => {
  const currentSchedule = format(
    utcToZonedTime(parseISO(appointmentDatetime), timezone),
    daysAtTime,
    {
      timeZone: timezone,
    },
  );

  const requestedSchedule = isCustomTimeSelection ? (
    <div>
      {Object.entries(customAvailabilities).map(
        ([priority, availability]) =>
          availability.startDate && (
            <div
              className="flex gap-4"
              key={`${availability.day}${availability.time}`}
            >
              <span className="w-36">
                {PRIORITY_LEVELS[Number(priority)]} Choice:
              </span>
              <span className="font-semibold">
                {format(
                  utcToZonedTime(availability.startDate, timezone),
                  `${daysAtTime} ${startingMDY}`,
                  {
                    timeZone: timezone,
                  },
                )}
              </span>
            </div>
          ),
      )}
    </div>
  ) : selectedDatetime ? (
    <span className="font-semibold">
      {format(
        utcToZonedTime(selectedDatetime, timezone),
        `${daysAtTime} ${startingMDY}`,
        {
          timeZone: timezone,
        },
      )}
    </span>
  ) : (
    <Message status="error">
      Something went wrong. Please go back and try again.
    </Message>
  );

  return (
    <div className="w-full">
      {results.loading ? (
        <SpinnerV2 />
      ) : (
        <div className="flex flex-col gap-4">
          <div>
            You are requesting to change the recurring time of {studentFirstName}'s{' '}
            {ACUITY_TYPE_TO_TEXT[appointmentTypeID]}s:
          </div>
          <div className="flex gap-6">
            <span>Current Schedule:</span>
            <span className="font-semibold">{currentSchedule}</span>
          </div>
          <div className={`flex ${isCustomTimeSelection ? 'flex-col' : ''} gap-1`}>
            <span>Requested Schedule:</span>
            {requestedSchedule}
          </div>
          <div className="text-xs italic">
            If you need to move a class prior to this date, please use the one-time
            reschedule option.
          </div>
          <div className="mt-6 text-sm font-semibold">Important Note</div>
          <div className="text-xs">
            In the event that {studentFirstName}'s instructor no longer has this time
            free:
          </div>
          <div className="flex flex-col gap-2">
            <label className="flex gap-2">
              <input
                className="my-auto"
                type="radio"
                checked={
                  instructorUnavailablePreference ===
                  InstructorUnavailablePreference.NewInstructor
                }
                onChange={() =>
                  setInstructorUnavailablePreference(
                    InstructorUnavailablePreference.NewInstructor,
                  )
                }
              />
              <span className="text-xs">
                I would like to switch instructors if {studentFirstName}'s current
                instructor is no longer available at the selected time.
              </span>
            </label>
            <label className="flex gap-2">
              <input
                className="my-auto"
                type="radio"
                checked={
                  instructorUnavailablePreference ===
                  InstructorUnavailablePreference.ExploreOtherTimes
                }
                onChange={() =>
                  setInstructorUnavailablePreference(
                    InstructorUnavailablePreference.ExploreOtherTimes,
                  )
                }
              />
              <span className="text-xs">
                If {studentFirstName}'s current instructor is no longer available,
                I'd like to explore additional times that might work.
              </span>
            </label>
          </div>
          {results.error && (
            <Message status="error">
              Something went wrong. Please go back and try again.
            </Message>
          )}
        </div>
      )}
    </div>
  );
};

export default InstructorUnavailablePreferenceStep;
