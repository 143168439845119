/**
 * See app/signup_session/component/InputField for some good inspiration
 *   (it's a little too specific I think to bring in here copy+pasta).
 */
import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { jPurple, jDark } from 'theme/colors';
import { Icon } from 'core-components';
import { TailwindSpace } from 'theme/types';
import { InputSharedProps, Sizes } from './types';

// other more complicated states for text styles are captured in the styled component below
const textStyles = 'font-graphik text-j-dark-500 disabled:text-j-dark-300';

const sizeHorizontalSpacing: { [size in Sizes]: TailwindSpace } = {
  xsmall: '2',
  small: '3',
  medium: '4',
};

const iconTopSpacing: { [size in Sizes]: string } = {
  xsmall: '0.625rem',
  small: '0.875rem',
  medium: '1.25rem',
};

const sizeStyles: { [size in Sizes]: string } = {
  xsmall: `p-${sizeHorizontalSpacing.xsmall} text-sm`,
  small: `p-${sizeHorizontalSpacing.small} text-sm`,
  medium: `p-${sizeHorizontalSpacing.medium} text-base`,
};

const baseBorderStyles = 'border border-solid rounded-lg';
const nonValidatedStyles =
  'border-j-dark-200 hover:border-j-dark-300 focus:border-j-dark-300 active:border-j-dark-600 disabled:border-j-dark-100';

interface StyledInputProps {
  valid?: boolean;
  disabled?: boolean;
  select?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  ::placeholder {
    color: ${jDark[200]};
  }
  
  /* only show hover j-dark-300 when the placeholder is shown, and not disabled */
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      ::placeholder {
        color: ${jDark[300]};
      }
    }`}

  ${({ disabled }) =>
    disabled &&
    `
    ::placeholder {
      color: ${jDark[100]};
    }`}

  /* double border on focus and active states, applied with a box-shadow */
  ${({ valid, disabled }) =>
    valid === undefined &&
    !disabled &&
    `
    &:focus,
    &:active {
      box-shadow: 0 0 0 2px ${jPurple[200]};
    }
  `}

  ${({ select }) =>
    select &&
    `
      caret-color: transparent;
    `}
`;

interface InputBaseProps extends InputSharedProps {
  inputComponent: 'input' | 'textarea';
}

const InputBase = React.forwardRef<HTMLDivElement, InputBaseProps>(
  (
    {
      inputComponent,
      valid,
      message,
      size = 'medium',
      fullWidth = false,
      select = false,
      selectDropdownIsOpen,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    let IconRight;
    if (select) {
      IconRight = selectDropdownIsOpen ? Icon.CaretUp : Icon.CaretDown;
    }

    return (
      <>
        <div
          ref={ref}
          className={classNames('relative', {
            'inline-block': !fullWidth,
            'w-full': fullWidth,
          })}
        >
          <StyledInput
            {...props}
            as={inputComponent}
            valid={valid}
            disabled={disabled}
            select={select}
            className={classNames(
              'bg-white box-border',
              textStyles,
              baseBorderStyles,
              {
                [sizeStyles[size]]: true,
                'pr-10': !!IconRight && size === 'medium',
                'pr-8': !!IconRight && size === 'small',
                'pr-6': !!IconRight && size === 'xsmall',
                [nonValidatedStyles]: valid === undefined,
                'border-j-green-700': valid,
                'border-j-pink-700': valid === false,
                'w-full': fullWidth,
                'cursor-pointer': select && !disabled,
                'cursor-default': disabled,
              },
            )}
          />
          {IconRight && (
            <div
              className={classNames(
                'absolute w-4 h-4 flex justify-center items-center',
                'pointer-events-none',
                `right-${sizeHorizontalSpacing[size]}`,
                {
                  'text-j-dark-400': select,
                },
              )}
              style={{ top: iconTopSpacing[size] }}
            >
              <IconRight style={{ maxWidth: '1rem', maxHeight: '1rem' }} />
            </div>
          )}
        </div>
        {valid !== undefined && !!message && (
          <div
            className={classNames(
              'mt-2 flex items-center text-xs leading-5',
              valid ? 'text-j-green-700' : 'text-j-pink-700',
            )}
          >
            {valid ? <Icon.Success /> : <Icon.Error />}
            <div className="ml-1">{message}</div>
          </div>
        )}
      </>
    );
  },
);

export default InputBase;
