import basePlaygroundRoute from './basePlaygroundRoute';

const buildPlaygroundLink = ({
  projectId,
  projectType,
  projectName,
  userId,
}: {
  projectId: string;
  projectType: string;
  projectName: string;
  userId: string;
}): string =>
  `${basePlaygroundRoute(
    userId,
    'learner',
  )}/project?projectId=${projectId}&projectType=${projectType}&projectName=${projectName}`;

export default buildPlaygroundLink;
