import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { Arrow, XIcon } from 'components/Icons';
import {
  INLINE_ANNOTATION_EDITOR_WIDTH,
  HIGHLIGHT_COLORS,
  HIGHLIGHT_COLOR_VALUES,
} from 'components/RichTextAnnotator/constants';

/**
 * @deprecated With NH restyling, use jide/InlineAnnotationEditor instead.
 */
const InlineAnnotationEditor: React.FC<{
  top: number;
  left: number;
  onSubmit: (newData: { annotation?: string; highlightColor?: string }) => void;
  close: () => void;
  initialValue?: string;
  highlightColor: string;
  onDelete: () => void;
}> = ({
  top,
  left,
  onSubmit,
  close,
  onDelete,
  initialValue = '',
  highlightColor,
}) => {
  const self = useRef<any>();
  const [value, setValue] = useState(initialValue);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const clickHandler: { (event: MouseEvent): void } = (event: MouseEvent) => {
    if (self?.current && !self.current.contains(event.target)) {
      event.stopPropagation();
      setValue('');
      close();
    }
  };
  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  });
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };
  const handleUpdateAnnotationText = () => {
    onSubmit({ annotation: value });
    setValue('');
    close();
  };
  const handleUpdateHighlightColor = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newColor = e.currentTarget.value;
    onSubmit({ highlightColor: newColor });
  };
  const handleToggleShowDeleteConfirmation = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setShowDeleteConfirmation(val => !val);
  };
  const handleDelete = () => {
    onDelete();
    setValue('');
    close();
  };

  return (
    <StyledInlineAnnotationEditor top={top} left={left} ref={self}>
      {showDeleteConfirmation ? (
        <StyledDeleteConfirmation>
          <label>Are you sure you wish to delete this annotation?</label>
          <div className="delete-buttons">
            <StyledCancelRemoveAnnotationButton
              onClick={handleToggleShowDeleteConfirmation}
            >
              <span className="button-text">Cancel</span>
              <XIcon />
            </StyledCancelRemoveAnnotationButton>
            <StyledConfirmRemoveAnnotationButton onClick={handleDelete}>
              <span className="button-text">Delete</span>
              <Arrow orientation="right" />
            </StyledConfirmRemoveAnnotationButton>
          </div>
        </StyledDeleteConfirmation>
      ) : (
        <>
          <div>
            <label>Write an annotation for this text</label>
            <StyledInlineAnnotationEditorTextarea
              value={value}
              rows={3}
              onChange={handleChange}
              placeholder="Your annotation..."
            />
            <StyledSubmitAnnotationButton
              onClick={handleUpdateAnnotationText}
              disabled={initialValue === value}
            >
              <span className="button-text">
                {initialValue ? 'Update' : 'Create'} Annotation
              </span>
              <Arrow orientation="right" />
            </StyledSubmitAnnotationButton>
          </div>
          <div>
            <label>Highlight Color</label>
            <StyledHighlightColorOptions>
              {HIGHLIGHT_COLORS.map(color => (
                <StyledHighlightColorOption
                  key={color}
                  onClick={handleUpdateHighlightColor}
                  color={HIGHLIGHT_COLOR_VALUES[color].translucentValue}
                  value={color}
                  disabled={color === highlightColor}
                />
              ))}
            </StyledHighlightColorOptions>
            <StyledRemoveAnnotationButton
              onClick={handleToggleShowDeleteConfirmation}
            >
              Remove Annotation
            </StyledRemoveAnnotationButton>
          </div>
        </>
      )}
    </StyledInlineAnnotationEditor>
  );
};
const StyledInlineAnnotationEditor = styled.div<any>`
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  background: #fff;
  z-index: 5;
  border-radius: 8px;
  border: 2px solid hsl(201, 70%, 89%);
  width: ${INLINE_ANNOTATION_EDITOR_WIDTH}px;
  margin-bottom: 1.25rem;
  label {
    font-weight: bold;
    line-height: 22px;
  }
  display: flex;
  flex-direction: column;
  > div {
    padding: 12px;
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      border-top: 2px solid hsl(201, 70%, 89%);
    }
  }
`;
const StyledInlineAnnotationEditorTextarea = styled.textarea`
  width: 100%;
  border: 2px solid hsl(201, 100%, 50%);
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 12px;
`;
const StyledHighlightColorOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  height: 22px;
`;
const StyledHighlightColorOption = styled.button`
  width: 18px;
  height: 18px;
  background: ${props => props.color};
  box-sizing: border-box;
  border: 2px solid transparent;
  margin: 0 2px;
  border-radius: 4px;
  padding: 0;
  &:disabled {
    background: ${props => props.color};
    border: 2px solid hsl(201, 100%, 50%);
    width: 22px;
    height: 22px;
    margin: 0;
  }
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }
`;
const StyledRemoveAnnotationButton = styled.button`
  color: hsl(0, 88%, 46%);
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-top: 12px;
  box-shadow: none !important;
  background: none !important;
  text-transform: none;
  text-align: center;
  font-weight: bold;
  align-self: center;
  font-size: 12px;
  font-family: Open Sans, sans-serif;
`;
const StyledDeleteConfirmation = styled.div`
  border: 1px solid hsl(0, 100%, 94%);
  background: hsl(0, 100%, 98%);
  align-items: center;
  text-align: center;
  margin: 12px;
  border-radius: 8px;
  label {
    color: hsl(0, 69%, 43%);
    max-width: 220px;
    line-height: 22px;
  }
  .delete-buttons {
    margin-top: 12px;
    display: flex;
    align-items: center;
    min-width: 240px;
  }
`;
const StyledButton = styled.button`
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  .button-text {
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
  }
  .icon {
    border-radius: 6px;
    margin-left: 1rem;
  }
`;
const StyledSubmitAnnotationButton = styled(StyledButton)`
  width: 100%;
  margin-top: 12px;
  .icon {
    background: hsl(201, 100%, 57%);
  }
  .icon path {
    fill: #fff;
  }
  &:disabled .icon {
    background: #ccc;
  }
`;
const StyledCancelRemoveAnnotationButton = styled(StyledButton)`
  background: #fff;
  border: 2px solid hsl(199, 100%, 89%);
  color: hsl(201, 100%, 50%);
  flex: 1;
  margin-right: 6px;
  padding-left: 0.75rem;
  .icon {
    background: hsla(201, 100%, 50%, 0.15);
    margin-left: 0.75rem;
  }
  .icon path {
    stroke: hsl(201, 100%, 50%);
  }
`;
const StyledConfirmRemoveAnnotationButton = styled(StyledButton)`
  border: 2px solid hsl(0, 71%, 62%);
  background: hsl(0, 69%, 43%);
  flex: 1;
  margin-left: 6px;
  padding-left: 0.75rem;
  .icon {
    background: hsl(0, 83%, 35%);
    margin-left: 0.75rem;
  }
  .icon path {
    fill: #fff;
  }
`;

export default InlineAnnotationEditor;
