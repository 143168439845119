/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import * as R from 'ramda';
import { StudentData } from '../types';

const pickAllOnboardingIds = (student: Partial<StudentData>): Array<string> =>
  R.pipe(
    R.prop('bundle'),
    R.prop('selections'),
    R.pluck('onboardingTicketId'),
    R.values,
    R.uniq,
  )(student);

export default pickAllOnboardingIds;
