import React from 'react';
import { REPL } from 'components/jide';

import './jide_env_java.css';

const JideEnvJava = props => (
  <REPL {...props} key={`repl_${props.tab.tabId.toString()}`} />
);

export default JideEnvJava;
