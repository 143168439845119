import React from 'react';

import {
  StyledInstructionsPanelHeader,
  StyledInstructionsPanelTitle,
  StyledInstructionsPanelContainer,
  StyledInstructionsPanelData,
} from './styles';

interface IProps {
  instructions: string;
}

const InstructionsPanel: React.FC<IProps> = ({ instructions }) => (
  <StyledInstructionsPanelContainer>
    <StyledInstructionsPanelHeader>
      <StyledInstructionsPanelTitle>Instructions</StyledInstructionsPanelTitle>
    </StyledInstructionsPanelHeader>
    <StyledInstructionsPanelData
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: instructions }}
    />
  </StyledInstructionsPanelContainer>
);

export default InstructionsPanel;
