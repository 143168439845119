import React, { FC } from 'react';
import GuestEnrollButton from 'app/learner/components/GuestEnrollButton';
import classNames from 'classnames';
import { StarIcon } from 'components/Icons';
import { MAX_CLUB_MEMBERSHIPS_GUEST } from 'constants/clubs';

const ClubCTABanner: FC<{ studentId: string }> = ({ studentId }) => (
  <div className="w-full flex flex-end">
    <div className="bg-deep-purple-400 w-full flex flex-row font-semibold text-white justify-between flex-wrap py-3 px-3">
      <div className="flex flex-row items-center">
        <div className="flex w-7 h-7 bg-white rounded-md bg-opacity-10">
          <StarIcon white />
        </div>
        <div className={classNames('ml-2.5', 'leading-7')}>
          {`As a Guest, you can join up to ${MAX_CLUB_MEMBERSHIPS_GUEST} Clubs. Enroll in a Juni course to create or join more Clubs!`}{' '}
        </div>
      </div>
      <GuestEnrollButton width="auto" studentId={studentId} variant="transparent" />
    </div>
  </div>
);

export default ClubCTABanner;
