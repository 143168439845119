import React from 'react';

import './juni_ide_wrapper_admin.css';
import { JuniIDE } from 'components/jide';
import { COURSE_TYPES } from 'constants/subjects';

const JuniIDEWrapperAdmin = ({ userId }) => (
  <div className="jide-wrapper-admin jide-main-content">
    <JuniIDE
      jideUser={{ _id: userId, type: 'admin' }}
      courseTypes={[COURSE_TYPES.COMPUTER_SCIENCE]}
      idLookup={{}}
      includeTrialModules
    />
  </div>
);
export default JuniIDEWrapperAdmin;
