import { SignupData, StudentData } from '../types';

const updateStudentById = (
  studentId: string | undefined,
  updateData: Partial<StudentData> | undefined,
  signupData: Partial<SignupData> | undefined,
): Partial<SignupData> => ({
  ...(signupData ?? {}),
  students:
    signupData?.students?.map(student =>
      student._id === studentId
        ? {
            ...student,
            ...updateData,
          }
        : student,
    ) ?? [],
});

export default updateStudentById;
