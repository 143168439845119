import { FC, useEffect, useState, useCallback, MouseEvent } from 'react';
import juniAxios from 'services/axios';
import moment from 'moment';
import 'moment-timezone';
import classNames from 'classnames';
import { noop } from 'lodash';
import ModalWindow, {
  ModalButtonsFooter,
  ModalTextFooter,
} from 'core-components/NewModalWindow/NewModalWindow';
import Button from 'core-components/NewButton';
import { Icon, Message } from 'core-components';
import Spinner from 'components/Spinner';
import SpinnerV2 from 'components/SpinnerV2';
import { JuniLogo } from 'components/brand-assets';

import { LearnerClassSchedulerBaseProps } from '../types';
import './learner_class_scheduler_widget.css';

import { NUM_DAYS_TO_SHOW } from '../constants';
import { isSchedulingRestricted } from '../isSchedulingRestricted';

interface TimeButtonProps {
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  value: string;
}

const TimeButton: FC<TimeButtonProps> = ({ disabled, onClick, children, value }) => (
  <button
    className={classNames(
      disabled
        ? 'bg-j-gray-100 text-j-dark-300 cursor-auto'
        : 'bg-j-gray-200 text-j-dark-600',
      'rounded-md',
      'font-graphik text-xs tracking-normal',
      'w-20',
      'hover:shadow-none',
      'ignore-juni-globals',
      'py-1 px-2',
    )}
    onClick={disabled ? noop : onClick}
    value={value}
  >
    {children}
  </button>
);

interface TableHeaderDateProps {
  day: string;
  date: string;
}

const TableHeaderDate: FC<TableHeaderDateProps> = ({ day, date }) => (
  <div className="flex flex-col items-center flex-1 flex-shrink-0 text-center">
    <div className="w-20 mr-4 ">
      <div className={classNames('font-graphik text-j-dark-600 text-sm')}>{day}</div>
      <div className="text-j-dark-300 text-xs mt-3 ">{date}</div>
    </div>
  </div>
);

export interface LearnerClassSchedulerWidgetProps
  extends LearnerClassSchedulerBaseProps {
  additionalClassId?: string;
  isMakeupClass: boolean;
  refreshAdditionalClasses: () => void;
  refreshUpcomingSessions: () => void;
}

const LearnerClassSchedulerWidget: FC<LearnerClassSchedulerWidgetProps> = ({
  appointmentTypeID,
  additionalClassId,
  timezone,
  instructorAcuityId,
  isMakeupClass,
  studentFirstName,
  studentId,
  onFinish,
  parent,
  refreshAdditionalClasses,
  refreshUpcomingSessions,
  isOpen,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSchedulingClass, setIsSchedulingClass] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().seconds(0).milliseconds(0).add(1, 'd'),
  );
  const [availability, setAvailability] = useState<Date[]>([]);
  const [errorFetchingAvailability, setErrorFetchingAvailability] = useState(false);
  const [selectedDatetime, setSelectedDatetime] = useState<moment.Moment | null>(
    null,
  );
  const [classDatetimeConfirmed, setClassDatetimeConfirmed] = useState(false);

  const getAvailability = useCallback(() => {
    if (!instructorAcuityId || !appointmentTypeID) {
      return;
    }
    setIsLoading(true);
    setErrorFetchingAvailability(false);

    juniAxios
      .get(
        `/api/instructor_availability?acuityId=${instructorAcuityId}&startingDate=${startDate.format(
          'YYYY-MM-DD',
        )}&appointmentTypeID=${appointmentTypeID}`,
      )
      .then(res => {
        const newAvailability = (res.data as Date[])
          .filter(t => moment(t).isAfter(moment().add(1, 'd')))
          .sort((a, b) => {
            const aTime = moment(a).tz(timezone);
            const bTime = moment(b).tz(timezone);
            const aHours = aTime.hours();
            const bHours = bTime.hours();
            const aMin = aTime.minutes();
            const bMin = bTime.minutes();
            if (aHours - bHours < 0) {
              return -1;
            }
            if (aHours - bHours > 0) {
              return 1;
            }
            if (aMin - bMin < 0) {
              return -1;
            }
            if (aMin - bMin > 0) {
              return 1;
            }
            return aTime.diff(bTime);
          });
        setErrorFetchingAvailability(false);
        setIsLoading(false);
        setAvailability(newAvailability);
      })
      .catch(err => {
        setIsLoading(false);
        setErrorFetchingAvailability(true);
        console.log(err);
      });
  }, [instructorAcuityId, appointmentTypeID, startDate, timezone]);

  useEffect(() => {
    if (isSchedulingClass || selectedDatetime !== null) return;
    // this will trigger initial fetching of availability on render
    getAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructorAcuityId, startDate, isSchedulingClass, selectedDatetime]); // only refetch availability when instructor ID changes, not on every update to getAvailability

  const previousAvailability = () => {
    const prevDate = moment(startDate).subtract(NUM_DAYS_TO_SHOW, 'days');
    if (prevDate.isAfter(moment().add(1, 'days'))) {
      setStartDate(prevDate);
    }
  };

  const moreAvailability = () => {
    setStartDate(moment(startDate).add(NUM_DAYS_TO_SHOW, 'days'));
  };

  const handleSelectNewClassDatetime = (e: MouseEvent<HTMLButtonElement>) => {
    if (e !== null) {
      const target = e.target as HTMLButtonElement;
      setSelectedDatetime(moment(target.value).tz(timezone));
    }
  };

  const unselectNewClassDatetime = () => {
    setSelectedDatetime(null);
  };

  const scheduleNewClass = async () => {
    const payload = {
      datetime: selectedDatetime?.format(),
      datetimeStr: selectedDatetime?.format('dddd, MMMM Do [at] h:mm a zz'),
      appointmentTypeID,
      calendarId: instructorAcuityId,
      firstName: parent.firstName,
      lastName: parent.lastName,
      email: parent.email,
      phone: parent.phone,
      timezone,
      studentFirstName,
    };
    return juniAxios.post(
      `/api/schedule_additional_class/${additionalClassId}`,
      payload,
    );
  };

  const createAdditionalClassScheduledCustomerLog = async () => {
    const payload = {
      parentId: parent._id,
      studentId,
      type: isMakeupClass ? 'makeup_class_scheduled' : 'class_pack_class_scheduled',
      subtype: '',
      notes: '',
      status: 'closed',
      classDatetime: selectedDatetime?.format(),
      creationUserId: parent.userId,
      checkedByAdmin: false,
      additionalClassId,
    };

    return juniAxios
      .post(`/api/customer_log_additional_class_scheduled`, payload)
      .then(res => res.data._id)
      .catch(err => {
        console.log(err.response || err);
      });
  };

  const redeemAdditionalClass = async (redeemedCustomerLogId: string) =>
    juniAxios
      .post(`/api/redeem_additional_class/${additionalClassId}`, {
        redeemedCustomerLogId,
        newClassDatetime: selectedDatetime?.format(),
      })
      .catch(err => {
        console.log(err.response || err);
      });

  useEffect(() => {
    if (!isSchedulingClass) {
      return;
    }
    scheduleNewClass()
      .then(createAdditionalClassScheduledCustomerLog)
      .then(redeemAdditionalClass)
      .then(async () => {
        await Promise.all([refreshUpcomingSessions(), refreshAdditionalClasses()]);
        setClassDatetimeConfirmed(true);
        setIsSchedulingClass(false);
      })
      .catch(err => {
        setIsSchedulingClass(false);
        setSelectedDatetime(null);
        console.log(err.response || err);
        if (err?.response?.data?.error === 'not_redeemable') {
          alert(
            `Error: This ${
              isMakeupClass ? 'makeup' : 'class pack'
            } class has already been redeemed. Please refresh the page and try again.`,
          );
        } else {
          alert(
            `An error occurred while attempting to schedule your ${
              isMakeupClass ? 'makeup' : 'class pack'
            } class. This is likely due to a stale set of available times. Please try again.`,
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSchedulingClass]); // this is responding to state being set in onConfirmClassDatetime, only want to run on this specific dependency change

  const onConfirmClassDatetime = () => {
    if (isSchedulingClass) return;
    setIsSchedulingClass(true);
  };
  const finish = () => {
    if (onFinish) {
      onFinish();
    }
  };

  const tableHeaderDates = [startDate];
  for (let i = 1; i < NUM_DAYS_TO_SHOW; i += 1) {
    tableHeaderDates.push(moment(startDate).add(i, 'day'));
  }

  const availabilityRows = [];
  const availabilities = [];
  let earliestTime = null;
  let latestTime = null;
  if (!isLoading && availability.length > 0) {
    earliestTime = moment(availability[0]).tz(timezone);
    latestTime = moment(availability[availability.length - 1]).tz(timezone);
    const lastDate = tableHeaderDates[tableHeaderDates.length - 1];
    const lastTime = lastDate
      .tz(timezone)
      .hours(latestTime.hours())
      .minutes(latestTime.minutes());
    const curTime = startDate
      .tz(timezone)
      .hours(earliestTime.hours())
      .minutes(earliestTime.minutes());
    while (curTime.hours() <= lastTime.hours()) {
      const tds = [];
      const items = [];
      for (let i = 0; i < NUM_DAYS_TO_SHOW; i += 1) {
        const unavailable =
          isSchedulingRestricted(curTime.clone().toDate()) ||
          availability.findIndex(
            t =>
              moment(t).tz(timezone).format('ddd, MMM D h:mm a zz') ===
              curTime.format('ddd, MMM D h:mm a zz'),
          ) === -1;
        tds.push(
          <td
            key={curTime.format('YYYY-MM-DD')}
            className={`${unavailable ? 'unavailable' : ''}`}
          >
            <TimeButton
              disabled={unavailable}
              value={curTime.toISOString()}
              onClick={handleSelectNewClassDatetime}
            >
              {curTime.format('h:mm a')}
            </TimeButton>
          </td>,
        );
        items.push(
          <div
            key={curTime.format('YYYY-MM-DD')}
            className="flex flex-1 mr-4  justify-center"
          >
            <TimeButton
              disabled={unavailable}
              value={curTime.toISOString()}
              onClick={handleSelectNewClassDatetime}
            >
              {curTime.format('h:mm a')}
            </TimeButton>
          </div>,
        );
        curTime.add(1, 'day');
      }
      availabilityRows.push(<tr key={`row-${curTime.format('h:mm a')}`}>{tds}</tr>);
      availabilities.push(
        <div key={`row-${curTime.format('h:mm a')}`} className="flex mb-2">
          {items}
        </div>,
      );
      curTime.subtract(NUM_DAYS_TO_SHOW, 'days');
      if (
        !curTime
          .clone()
          .add(30, 'minutes')
          .isBefore(curTime.clone().add(1, 'd').hours(0).minutes(0).milliseconds(0))
      ) {
        break;
      }
      curTime.add(30, 'minutes');
    }
  }

  const previousAvailabilityDisabled = moment(startDate)
    .subtract(NUM_DAYS_TO_SHOW, 'd')
    .isBefore(moment().add(1, 'd'));

  const TimezoneFooter = (
    <ModalTextFooter
      title="Your Timezone"
      description={`${timezone} (${moment().tz(timezone).format('zz')})`}
    />
  );

  const ConfirmFooter = (
    <ModalButtonsFooter
      primary={
        <Button onClick={onConfirmClassDatetime} disabled={isSchedulingClass}>
          <div className="font-medium">Confirm</div>
        </Button>
      }
      secondary={
        <Button variant="secondary" onClick={unselectNewClassDatetime}>
          <Icon.ChevronLeft />
          <div className="font-medium">Back</div>
        </Button>
      }
    />
  );

  const CompleteFooter = (
    <ModalButtonsFooter
      primary={
        <Button variant="secondary" onClick={finish}>
          <div className="font-medium">Go to Calendar</div>
        </Button>
      }
    />
  );

  const ModalFooter = selectedDatetime
    ? !classDatetimeConfirmed
      ? ConfirmFooter
      : CompleteFooter
    : TimezoneFooter;

  return (
    <ModalWindow
      isOpen={isOpen}
      closeModal={closeModal}
      title={!classDatetimeConfirmed ? 'Schedule' : ''}
      description={
        !classDatetimeConfirmed
          ? `${
              isMakeupClass ? 'Makeup' : 'Class Pack'
            } Class for ${studentFirstName}`
          : ''
      }
      renderFooter={() => ModalFooter}
    >
      <div className="flex flex-col w-full">
        {/** Availability Calendar controls */}
        {!selectedDatetime && (
          <div className="flex justify-between items-center mb-4">
            <div className="text-j-dark-600 text-base font-medium">Pick a time</div>
            <div className="flex">
              <div className="mr-3">
                <Button
                  onClick={previousAvailability}
                  disabled={previousAvailabilityDisabled}
                  variant="secondary"
                  icon
                >
                  <Icon.ArrowLeft />
                </Button>
              </div>
              <div className="mr-4">
                <Button onClick={moreAvailability} variant="secondary" icon>
                  <Icon.ArrowRight />
                </Button>
              </div>
            </div>
          </div>
        )}

        {selectedDatetime && (
          <div className="w-full">
            {isSchedulingClass ? (
              <SpinnerV2 />
            ) : !classDatetimeConfirmed ? (
              <div className="font-graphik">
                <div className="text-j-dark-300 text-sm mb-4">{`You are about to schedule a ${
                  isMakeupClass ? 'makeup' : 'class pack'
                } class for ${studentFirstName} on:`}</div>
                <div className="text-j-dark-600 font-medium text-base mb-4">
                  {selectedDatetime.format('dddd, MMMM Do [at] h:mm a zz')}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center p-4">
                <div className="py-10 px-7 rounded-full bg-j-dark-100 mb-7">
                  <JuniLogo size="md" />
                </div>
                <div className="text-j-dark-600 text-base font-medium mb-3">
                  Class Scheduled!{' '}
                </div>
                <div className="text-j-dark-300 text-sm mb-3">
                  You've scheduled a session for {studentFirstName} on:
                </div>
                <Message status="success">
                  {selectedDatetime.format('dddd, MMMM Do [at] h:mm a zz')}
                </Message>
              </div>
            )}
          </div>
        )}
        {/** Availability table */}
        {!selectedDatetime && (
          <div className={classNames('flex flex-col w-full overflow-x-auto')}>
            {/** Container for table header */}
            <div className={classNames('flex mb-4')}>
              {tableHeaderDates.map(d => (
                <TableHeaderDate
                  key={d.tz(timezone).format('YYYY-MM-DD')}
                  day={d.tz(timezone).format('dddd')}
                  date={d.tz(timezone).format('MMMM DD')}
                />
              ))}
            </div>
            {/* Table body */}
            {!isLoading ? (
              !errorFetchingAvailability && availability.length > 0 ? (
                <div className="flex flex-col">{availabilities}</div>
              ) : (
                <div className="p-5">
                  {!errorFetchingAvailability ? (
                    <Message status="info">
                      {' '}
                      No available times for these dates.
                    </Message>
                  ) : (
                    <Message status="error">
                      {' '}
                      Unable to fetch availabilities at this time.
                    </Message>
                  )}
                </div>
              )
            ) : (
              <Spinner size="48" />
            )}
          </div>
        )}
      </div>
    </ModalWindow>
  );
};

export default LearnerClassSchedulerWidget;
