import React, { useState } from 'react';
import {
  useGetUserSmsConsentStatusQuery,
  useUpdateUserSmsConsentMutation,
} from 'generated/graphql';
import { Toggle } from 'core-components';
import {
  INSTRUCTOR_SMS_CONSENT_LABEL_TEXT,
  PARENT_SMS_CONSENT_LABEL_TEXT,
} from './constants';

interface Props {
  userType: 'teacher' | 'parent';
}

const SmsConsentCheckbox: React.FC<Props> = ({ userType }) => {
  const [consented, setConsented] = useState<boolean | null>(null);
  const { data } = useGetUserSmsConsentStatusQuery();
  const [
    updateUserSmsConsentMutation,
    { loading },
  ] = useUpdateUserSmsConsentMutation();

  if (data && consented === null) {
    setConsented(data.userSmsConsentStatus);
  }

  async function onChange() {
    const nextValue = Boolean(!consented);
    try {
      await updateUserSmsConsentMutation({
        variables: {
          input: {
            consented: nextValue,
          },
        },
      });
      setConsented(nextValue);
    } catch (err) {
      console.log(err.response || err);
    }
  }

  return (
    <Toggle
      label={
        userType === 'teacher'
          ? INSTRUCTOR_SMS_CONSENT_LABEL_TEXT
          : PARENT_SMS_CONSENT_LABEL_TEXT
      }
      onChange={onChange}
      disabled={!data || loading}
      isChecked={!!consented}
      renderLeft={false}
    />
  );
};

export default SmsConsentCheckbox;
