import React, { FC } from 'react';
import classNames from 'classnames';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';

import {
  CheckmarkIcon,
  PlansIcon,
  CheckoutIcon,
  ParentInfoIcon,
  StudentInfoIcon,
} from 'components/Icons';
import { CHECKOUT_FLOW_STEPS } from 'constants/signup_sessions';
import { CheckoutFlowPaths } from '../types';

interface CheckoutFlowWrapperProps {
  activeStep?: CheckoutFlowPaths;
}

const CheckoutStepper: FC<CheckoutFlowWrapperProps> = ({ activeStep }) => {
  const { flags } = useSignupContext();

  const checkoutSteps = CHECKOUT_FLOW_STEPS;

  return (
    <div className="flex items-center justify-center p-4 md:px-6 box-border bg-juni-light-100 z-20">
      {checkoutSteps.map((step, index) => {
        const isActiveStep = step.path === activeStep;
        const indexOfActivePath = checkoutSteps.findIndex(
          step => step.path === activeStep,
        );

        const icon = () => {
          switch (step.label) {
            case 'Plan Selection':
              return <PlansIcon dark={isActiveStep} />;
            case 'Parent Info':
              return <ParentInfoIcon dark={isActiveStep} />;
            case 'Student Info':
              return <StudentInfoIcon dark={isActiveStep} />;
            case 'Checkout':
              return <CheckoutIcon dark={isActiveStep} />;
            default:
              return null;
          }
        };
        if (flags.shouldSkipCourseFrequency && step.label === 'Plan Selection') {
          return null;
        }

        return (
          <React.Fragment key={step.path}>
            <div className="flex items-center flex-col mx-1 md:mx-4" key={step.path}>
              <div
                className={classNames(
                  'flex items-center justify-center w-8 h-8 rounded-full leading-none font-bold',
                  {
                    'text-j-dark-600': isActiveStep,
                    'text-j-green-400': index < indexOfActivePath,
                    'text-j-dark-300': index > indexOfActivePath,
                  },
                )}
              >
                {index < indexOfActivePath ? (
                  <div className="rounded-full border-2 border-solid w-5 h-5 border-j-green-400 flex justify-center items-center">
                    <CheckmarkIcon className="w-3" green />
                  </div>
                ) : (
                  icon()
                )}
              </div>
              <span
                className={classNames('whitespace-nowrap text-xs', {
                  'text-juni-dark-600': isActiveStep,
                  'text-j-green-400': index < indexOfActivePath,
                  'text-j-dark-300': index > indexOfActivePath,
                })}
              >
                {step.label}
              </span>
            </div>
            {index < checkoutSteps.length - 1 && (
              <div
                className={`
                  border-0
                  border-t 
                  border-solid 
                  mb-4
                  border-blue-gray-300
                  md:w-36
                  w-full`}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CheckoutStepper;
