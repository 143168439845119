import React from 'react';
import './juni_spinner.css';

const JuniSpinner = ({ size }) => (
  // TODO: post launch find a way to slow down the spinner
  // while keeping the animation smooth
  <div className="juni-spinner-wrapper">
    <div className="juni-spinner">
      <div className="juni-spinner-circles-container">
        <div style={{ height: `${size || 80}px`, width: `${size || 80}px` }}></div>
        <div style={{ height: `${size || 80}px`, width: `${size || 80}px` }}></div>
        <div style={{ height: `${size || 80}px`, width: `${size || 80}px` }}></div>
      </div>
    </div>
  </div>
);

export default JuniSpinner;
