import styled from 'styled-components/macro';
import classNames from 'classnames';

export const JideSidebarWrapper = styled.div.attrs({
  className: 'flex flex-col h-full',
})<any>`
  background-color: #171633;
  min-width: ${({ sidebarExpanded }) => (sidebarExpanded ? '220px' : '0px')};
`;

export const JideSidebarContent = styled.div.attrs({
  className: 'flex flex-col justify-between p-4 box-border h-full',
})``;

export const JideSidebarTitle = styled.div.attrs({
  className: classNames(
    'flex items-center',
    'box-border',
    'px-6 py-4',
    'border-0 border-b border-solid border-j-dark-400',
    'text-base font-graphik text-white capitalize',
    'h-14',
  ),
})`
  height: 56px;
`;
