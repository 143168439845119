import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { Chevron } from 'components/Icons';
import { Card } from 'core-components';
import { getMyClubsLink } from 'app/clubs/helpers';
import ClubsBanner from 'components/clubs/MyClubsZeroState/assets/guest-header.jpg';
import { juniBlue } from 'theme/old-colors';

interface EventsNotificationCard {
  numEvents?: number;
  studentId?: string;
  isOnMyClubsPage?: boolean;
}

const EventsNotificationCard: FC<EventsNotificationCard> = ({
  studentId,
  numEvents,
  isOnMyClubsPage,
}) => {
  const multipleEvents = numEvents && numEvents > 1;
  const clubsCalendarUrl = `${getMyClubsLink({ studentId })}/calendar` || '';
  return (
    <Link className="no-underline flex-1 mb-3" to={clubsCalendarUrl}>
      <Card
        className="flex-1 h-full border-2 border-solid bg-juni-blue-50 border-juni-blue-200 rounded-lg"
        contentClassName="flex flex-col justify-center"
        backgroundColor="eggshell-blue"
        borderColor="juni-blue-100"
        boxShadow
        bannerImgSrc={!isOnMyClubsPage ? ClubsBanner : ''}
        borderWidth="2"
        padding="6"
      >
        <h3 className="text-center p-0 m-0 text-xl font-bold text-blue-gray-500">
          Attend a Club Event!
        </h3>
        <div className="text-center text-blue-gray-400">
          There {multipleEvents ? 'are' : 'is'} <b>{numEvents}</b> upcoming event
          {multipleEvents ? 's' : ''}!
        </div>
        <div className="flex flex-row justify-center items-center pb-1">
          <span className="font-bold text-center text-juni-blue-500 cursor-pointer mr-2">
            Browse Events
          </span>
          <div className="flex items-center">
            <Chevron orientation="right" heavy stroke={juniBlue[400]} />
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default EventsNotificationCard;
