import {
  EditorState,
  ContentState,
  ContentBlock,
  EntityInstance,
  CharacterMetadata,
} from 'draft-js';

export const doesSelectionOverlapHighlight = (
  selection: {
    startOffset: number;
    startKey: string;
    endOffset: number;
    endKey: string;
    startBlockIndex: number;
    endBlockIndex: number;
  },
  highlight: {
    selectionState: {
      anchorOffset: number;
      anchorKey: string;
      focusOffset: number;
      focusKey: string;
      isBackward: boolean;
    };
  },
  blockIndexByKey: Record<string, number>,
) => {
  const existingHighlightSelection = highlight.selectionState;
  const { isBackward } = existingHighlightSelection;
  let startOffset = existingHighlightSelection.anchorOffset;
  let startKey = existingHighlightSelection.anchorKey;
  let endOffset = existingHighlightSelection.focusOffset;
  let endKey = existingHighlightSelection.focusKey;
  if (isBackward) {
    startOffset = existingHighlightSelection.focusOffset;
    startKey = existingHighlightSelection.focusKey;
    endOffset = existingHighlightSelection.anchorOffset;
    endKey = existingHighlightSelection.anchorKey;
  }
  const startBlockIndex = blockIndexByKey[startKey];
  const endBlockIndex = blockIndexByKey[endKey];

  if (
    endBlockIndex < selection.startBlockIndex ||
    startBlockIndex > selection.endBlockIndex
  ) {
    return false;
  }
  if (
    (endKey === selection.startKey && endOffset <= selection.startOffset) ||
    (startKey === selection.endKey && startOffset >= selection.endOffset)
  ) {
    return false;
  }
  return true;
};

export const getAllEntitiesAndBlocks: (
  editorState: EditorState,
) => {
  entities: { entityKey: string; entity: EntityInstance }[];
  blocks: ContentBlock[];
} = editorState => {
  const currentContent: ContentState = editorState.getCurrentContent();
  const entities: { entityKey: string; entity: EntityInstance }[] = [];
  const blocks: ContentBlock[] = [];
  currentContent.getBlockMap().forEach((block: ContentBlock | undefined) => {
    if (block) blocks.push(block);
    block?.findEntityRanges(
      (character: CharacterMetadata) => {
        const entityKey = character.getEntity();
        if (!entityKey) return false;
        const entity = currentContent.getEntity(entityKey);
        if (entity) {
          entities.push({
            entityKey,
            entity,
          });
        }
        return false;
      },
      () => undefined,
    );
  });
  return { entities, blocks };
};

export function findHighlightEntities(
  contentBlock: ContentBlock,
  callback: () => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'ANNOTATED_HIGHLIGHT'
    );
  }, callback);
}

/**
 * The functions below were copied from https://github.com/jpuri/draftjs-utils.
 * The repo doesn't seemed to be maintained anymore and I couldn't find an
 * associated typescript types package
 */

/**
 * Function returns collection of currently selected blocks.
 */
function getSelectedBlocksMap(editorState: EditorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

/**
 * Function returns collection of currently selected blocks.
 */
function getSelectedBlocksList(editorState: EditorState) {
  return getSelectedBlocksMap(editorState).toList();
}

/**
 * Function will return currently selected text in the editor.
 */
export function getSelectionText(editorState: EditorState) {
  let selectedText = '';
  const currentSelection = editorState.getSelection();
  let start = currentSelection.getAnchorOffset();
  let end = currentSelection.getFocusOffset();
  const selectedBlocks = getSelectedBlocksList(editorState);
  if (selectedBlocks.size > 0) {
    if (currentSelection.getIsBackward()) {
      const temp = start;
      start = end;
      end = temp;
    }
    for (let i = 0; i < selectedBlocks.size; i += 1) {
      const blockStart = i === 0 ? start : 0;
      const blockEnd =
        i === selectedBlocks.size - 1 ? end : selectedBlocks.get(i).getText().length;
      selectedText += selectedBlocks.get(i).getText().slice(blockStart, blockEnd);
    }
  }
  return selectedText;
}

export const getWordCount = (editorState: EditorState | undefined) =>
  editorState
    ?.getCurrentContent()
    .getBlocksAsArray()
    .map(
      block =>
        block
          .getText()
          .trim()
          .split(/\b\W+\b/)
          .filter(s => s).length,
    )
    .reduce((agg, curr) => agg + curr, 0) || 0;
