import React from 'react';

import './jide_env_python_turtle.css';
import { PythonTurtle } from 'components/jide';

const JideEnvPythonTurtle = props => (
  <PythonTurtle {...props} key={`pyturt_${props.tab.tabId.toString()}`} />
);

export default JideEnvPythonTurtle;
