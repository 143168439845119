export const JUNI_COOKIES = {
  DOMAIN: (env?: string) => (env === 'production' ? 'junilearning.com' : ''),
  NAMES: {
    JUNI_ANONYMOUS_ID: 'juni_anonymous_id',
    AFFILIATE_CUSTOMER_ID: 'affiliate_customer_id',
    AFFILIATE_ORDER_ID: 'affiliate_order_id',
    REFERRAL_CODE: 'juni_referral_code',
    REFERRER_NAME: 'juni_referrer_name',
  },
};
