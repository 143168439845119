import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { juniDark } from 'theme/old-colors';

interface PortalSectionProps {
  children: React.ReactNode | React.ReactNode[];
  name?: string;
  extraMarginBottom?: boolean;
}

interface PortalSectionHeaderProps {
  name: string;
  extraMarginBottom?: boolean;
}

const PortalSectionWrapper = styled.div`
  margin: 0px;
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  &:not(:first-of-type) {
    margin-top: 1.25rem;
  }
`;

const PortalSectionHeaderWrapper = styled.div`
  display: flex;
  margin-left: 4px;
  align-items: center;
  flex-direction: row;
  padding-bottom: 1.25rem;
`;

const PortalSectionHeaderText = styled.div`
  color: ${juniDark[300]};
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 600;
  padding-right: 18px;
  letter-spacing: 0.5px;
`;

const PortalSectionHeaderLine = styled.div`
  border-bottom: 1px solid ${juniDark[100]};
  width: 100%;
`;

const PortalSectionHeader: FC<PortalSectionHeaderProps> = ({
  name,
  extraMarginBottom,
}) => (
  <PortalSectionHeaderWrapper>
    <PortalSectionHeaderText>{name}</PortalSectionHeaderText>
    <PortalSectionHeaderLine
      // @ts-expect-error extraneous prop
      extraMarginBottom={extraMarginBottom}
    />
  </PortalSectionHeaderWrapper>
);

const PortalSection: FC<PortalSectionProps> = ({
  children,
  name,
  extraMarginBottom,
}) => (
  <PortalSectionWrapper>
    {name ? (
      <PortalSectionHeader name={name} extraMarginBottom={extraMarginBottom} />
    ) : (
      ''
    )}
    {children}
  </PortalSectionWrapper>
);

export default PortalSection;
