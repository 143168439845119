import React, { useEffect } from 'react';
import { ErrorableLoading } from 'components/ui';
import { HelpCenterContent } from 'generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { NewCard } from 'core-components';
import Breadcrumbs from './Breadcrumbs';
import { StyledHeader } from './styles';

interface MatchProps {
  articleId: string;
}

interface Props {
  helpCenterContent: HelpCenterContent;
  baseUrl: string;
}

const Article: React.FC<Props> = ({ helpCenterContent, baseUrl }) => {
  const match = useRouteMatch<MatchProps>();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const convertZendeskLinks = (content: string) =>
    content.replace(
      /https:\/\/learnwithjuni.zendesk.com\/hc\/en-us\/articles\//g,
      `${baseUrl}/articles/`,
    );

  const { categories, sections, articles } = helpCenterContent;
  const article = articles.find(article => article.id === match.params.articleId);
  const section = sections.find(section => section.id === article?.section_id);
  const category = categories.find(category => category.id === section?.category_id);
  if (!article || !section || !category) {
    return <ErrorableLoading error="Unable to find that article" />;
  }

  const breadcrumbs = [
    { to: baseUrl, name: 'Help Desk' },
    { to: `${baseUrl}/categories/${category.id}`, name: category.name },
    {
      to: `${baseUrl}/articles/${article.id}`,
      name: article.title,
    },
  ];

  return (
    <div className="p-10 lg:px-48">
      <div className="space-y-6">
        <StyledHeader>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </StyledHeader>

        <NewCard padding="5">
          <div className="flex flex-col">
            <div className="pb-4">
              <div className="text-lg leading-snug font-semi-bold text-j-dark-600">
                {article.title}
              </div>
            </div>
            <NewCard.Divider />
            <StyledArticleContent
              className="text-sm leading-normal font-normal text-j-dark-600 break-words pt-4"
              dangerouslySetInnerHTML={{ __html: convertZendeskLinks(article.body) }}
            />
          </div>
        </NewCard>
      </div>
    </div>
  );
};

const StyledArticleContent = styled.div`
  img {
    max-width: 100%;
  }
  a {
    font-weight: 600;
    text-decoration: none;
    color: #4865fc;
  }
`;

export default Article;
