import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'core-components';
import Nav from './Nav';
import NavIcon from './NavIcon';

interface DefaultNavProps {
  pathname: string;
  userIsAuthenticated: boolean;
  fullWidth: boolean;
  noShadow: boolean;
}

const DefaultNav: FC<DefaultNavProps> = ({
  pathname,
  userIsAuthenticated,
  fullWidth,
  noShadow,
}) => {
  const settingsButtonOptions = userIsAuthenticated
    ? [
        <Link to="/logout" key="dropdown-nav-link-logout">
          <NavIcon icon={<Icon.Account className="icon p-2" />} menuItem />
          Logout
        </Link>,
      ]
    : [];

  return (
    <Nav
      baseRoute="/"
      pathname={pathname}
      settingsButtonOptions={settingsButtonOptions}
      fullWidth={fullWidth}
      noShadow={noShadow}
    />
  );
};

export default DefaultNav;
