import React from 'react';
import styled from 'styled-components/macro';
import arrow_right from 'images/arrow-right.svg';
import { registerUserAction } from 'services/juniverse';
import { MARKETING_SITE_LINKS } from 'constants/links';

const GetStarted = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 13px;
  padding: 3px 6px 3px 6px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: 0.2s;
  background-color: #dd71fb;
  color: white;
  justify-content: center;
  align-items: center;

  :hover {
    transition: 0.2s;
    font-size: 14px;
    padding: 4px 7px 4px 7px;
  }
`;

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 22px;
  text-rendering: optimizeLegibility;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-top: 1px;
`;

interface Props {
  studentProjectId?: string;
  juniverseUsername?: string;
}
export const JuniverseGetStartedButton: React.FC<Props> = ({
  studentProjectId,
  juniverseUsername,
}) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={MARKETING_SITE_LINKS.TUITION_PAGE}
    onClick={() =>
      registerUserAction({
        action: 'click get started - user profile',
        studentProjectId,
        juniverseUsername,
      })
    }
    style={{ textDecoration: 'none' }}
  >
    <GetStarted>
      <Left>GET STARTED</Left>
      <Right>
        <img src={arrow_right} alt="arrow right" />
      </Right>
    </GetStarted>
  </a>
);
