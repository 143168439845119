import React, { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prompt } from 'react-router-dom';

import { Message } from 'components/ui';
import { Chevron } from 'components/Icons';
import MarkdownText from 'components/MarkdownText';

import { NewButton } from 'core-components';
import { CheckboxLabel, Checkmark, Instructions, TextAreaStyles } from '../styles';

interface TextEditorProps {
  checkboxLabel: string;
  instructions: string;
  placeholder: string;
  submit: (text: string, isDisabled: boolean) => Promise<void>;
}

const TextEditor: FC<TextEditorProps> = ({
  checkboxLabel,
  instructions,
  placeholder,
  submit,
}) => {
  const [text, setText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const toggleIsDisabled = () => {
    setIsDisabled(prevIsDisabled => !prevIsDisabled);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await submit(text, isDisabled);
      setHasError(false);
    } catch (err) {
      console.error(err);
      setHasError(true);
      setIsSubmitting(false);
    }
  };

  const submitDisabled = (!text && !isDisabled) || isSubmitting;

  return (
    <TextAreaStyles className="font-graphik text-j-dark-600">
      <Instructions>
        <ReactMarkdown
          renderers={{
            link: ({ href, children }) => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ),
          }}
        >
          {instructions}
        </ReactMarkdown>
      </Instructions>
      <Prompt
        when={text.length > 0}
        message="You have unsaved changes; are you sure that you want to leave?"
      />
      <MarkdownText
        text={text}
        handleChange={handleTextChange}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isEditable
      />
      <div className="flex flex-row justify-between pt-4">
        <CheckboxLabel className="flex items-center w-2/3">
          {checkboxLabel}
          <input type="checkbox" checked={isDisabled} onChange={toggleIsDisabled} />
          <Checkmark />
        </CheckboxLabel>
        <NewButton disabled={submitDisabled} onClick={handleSubmit}>
          Submit <Chevron orientation="right" />
        </NewButton>
      </div>
      {hasError && (
        <Message status="error">
          Oops! Something went wrong. Please try again, and if the error persists,
          contact Juni HQ.
        </Message>
      )}
    </TextAreaStyles>
  );
};

export default TextEditor;
