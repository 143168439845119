import React from 'react';
import classNames from 'classnames';

import './cute_progress_bar.css';

// A cute striped animated progress bar!
const CuteProgressBar = ({ pct, className }) => (
  <div className={classNames('cuteprogressbar', className)}>
    <span style={{ width: `${pct < 1 ? 1 : pct}%` }} />
  </div>
);

export default CuteProgressBar;
