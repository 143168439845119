import styled, { css } from 'styled-components/macro';

const ActionLinkSharedStyles = css`
  border-radius: 6px;
  padding: 0.5rem 1rem;
  margin: 1.15rem 0 0.35rem;
  font-weight: bold;
  text-decoration: none;
  background: #ffffff;
`;

const ActionLink = styled.a`
  ${ActionLinkSharedStyles}
  border: 1px solid #00a5ff;
  box-shadow: 0 0 2px #00a5ff;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 6fr 1fr;
  grid-column-gap: 6px;
  > :first-child {
    grid-column-start: 2;
  }
  > :last-child {
    margin-left: auto;
    background: rgba(0, 165, 255, 0.15);
    border-radius: 4px;
    padding: 0.75rem;
  }
  :hover {
    box-shadow: 0 0 4px #00a5ff;
  }
  @media only screen and (max-width: 460px) {
    grid-template-columns: 0.01fr 6fr 1fr;
  }
`;

const ActionLinkPlaceholder = styled.div`
  ${ActionLinkSharedStyles}
  display: flex;
  justify-content: center;
  color: #a0a0a0;
  border: 1px solid #c0c0c0;
`;

const CalendarIconWrapper = styled.span`
  display: flex;
  height: 22px;
  > div {
    align-self: center;
    margin-right: 0.75rem;
    padding-left: 0;

    svg path {
      fill: #7c9eb3;
    }
  }
`;

const ChallengeCardFooter = styled.div`
  background-color: #f8fdff;
  padding: 1.1rem;
  border-top: 2px solid #d1eaf7;
  border-radius: 0 0 8px 8px;
`;

const ChallengeCardHeader = styled.div`
  background-color: #ffffff;
  padding: 1.1rem;
  border-radius: 8px 8px 0 0;
`;

const ChallengeCardWrapper = styled.div`
  border: 2px solid #d1eaf7;
  border-radius: 8px;
`;

const ChallengeDatePairWrapper = styled.div`
  display: flex;
  > :first-child {
    border-right: 2px solid #cfe9fe;
    padding-right: 1rem;
  }
  > :last-child {
    padding-left: 1rem;
  }
`;

const ChallengeDateWrapper = styled.div`
  display: flex;
  line-height: 22px;
`;

const ChallengeDescription = styled.div`
  color: #4d6c7d;
  line-height: 24px;
  margin-bottom: 0.25rem;
`;

const ChallengeDetailsWrapper = styled.div`
  > p {
    color: #7c9eb3;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    margin: 0 0 0.5rem !important;
  }
`;

const ChallengeImageBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  height: 100%;

  margin-bottom: 0.5rem;
  background: white;
  border-radius: 6px;

  > img {
    width: 100%;
    max-height: 275px;
    flex: 1;
    object-fit: contain;
    overflow: hidden;
    height: 225px;
  }
`;

const ChallengeTitle = styled.div`
  color: #335875;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.45;
  margin: 1rem 0;
`;

export {
  ActionLink,
  ActionLinkPlaceholder,
  CalendarIconWrapper,
  ChallengeCardFooter,
  ChallengeCardHeader,
  ChallengeCardWrapper,
  ChallengeDatePairWrapper,
  ChallengeDateWrapper,
  ChallengeDescription,
  ChallengeDetailsWrapper,
  ChallengeImageBanner,
  ChallengeTitle,
};
