import React, { FC } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import { red } from 'theme/old-colors';
import { jDark } from 'theme/colors';
import { ValidationError } from 'app/signup_session/components/InputField';
import { CampaignCode } from 'app/signup_session/types';
import SpinnerV2 from 'components/SpinnerV2';
import { Icon, NewButton as Button } from 'core-components';

import {
  VisaIcon,
  MastercardIcon,
  AmexIcon,
  DiscoverIcon,
  Arrow,
} from 'components/Icons';
import classNames from 'classnames';
import { getContentForCampaign } from 'app/signup_session/campaign_content';
import CoppaDirectNotice from 'components/CoppaDirectNotice';
import styled from 'styled-components';
import { IconRenderProps } from 'core-components/NewButton/Button';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import { makePriceBeforeCoupon, sumWeeklyClasses } from 'app/signup_session/lib';
import { useGetCourseByNameQuery } from 'generated/graphql';
import { COMPUTER_SCIENCE, ENGLISH } from 'constants/signup_sessions';
import formatNumberAsCurrency from 'utils/formatNumberAsCurrency';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';
import { calculateDiscountedPriceWithCoupon } from 'app/signup_session/lib/calculateDiscountedPriceWithCoupon';
import CouponEditor from './CouponEditor';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: red[500],
    },
  },
};

const CoppaContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e2e1ed;
  border-radius: 8px;
  max-height: 44px;

  .coppa-direct-notice {
    color: ${jDark[400]};
    h1:first-of-type {
      color: ${jDark[400]};
      margin-top: 0;
      margin-bottom: -6px;
      font-size: 12px;
      font-weight: 500;
    }
    h2 {
      font-size: 12px;
      font-weight: 500;
      text-decoration: none;
    }

    p,
    a,
    ul,
    li {
      font-size: 12px;
      line-height: 140%;
    }
  }
`;

const PAYMENT_FORM_CONTENT = {
  formTitle: 'Checkout',
  checkoutFinePrint: `Free trials are one per family. If you don't cancel your subscription within
  14 days after your first session, you'll be charged monthly until you cancel or
  change your subscription.`,
  trustBullets: ['Your total today is $0', 'Cancel anytime'],
  checkoutButton: 'Start my trial',
  creditCardCopy:
    'For our students’ safety per the Children’s Online Privacy Protection Rule (COPPA), we require payment info. Read more below.',
};

const USE_HALF_OFF_BOOTCAMP = true;

const PaymentForm: FC<{
  stripeError: string;
  errorMessage: string;
  emailExists: boolean;
  paymentIsProcessing: boolean;
  campaign?: CampaignCode;
  onBack?: () => void;
}> = ({
  stripeError,
  errorMessage,
  emailExists,
  paymentIsProcessing,
  campaign,
  onBack,
}) => {
  const { signupData, flags } = useSignupContext();
  const isFromCourseExplorer =
    signupData.coursePlacement?.method === 'course-explorer';
  const activeStudent = signupData.students?.[0];
  const bundleSelections = getStudentBundleSelections(activeStudent?.bundle);
  const price =
    bundleSelections[0].price ??
    (activeStudent?.bundle
      ? makePriceBeforeCoupon(activeStudent.bundle.selections)
      : 0);
  const selectedDiscount = signupData.discountCodes?.find(code => code.isSelected);
  const currentWeeklyClassesSum = sumWeeklyClasses(
    activeStudent?.bundle?.selections ?? {},
  );
  const { data } = useGetCourseByNameQuery({
    variables: {
      courseName: bundleSelections[0]?.courseName || '',
    },
    skip: !bundleSelections[0]?.courseName,
  });
  const CONTENT = getContentForCampaign(PAYMENT_FORM_CONTENT, campaign);

  const selectedSubjects = Object.keys(
    activeStudent?.bundle?.selections || {},
  ).map(subject =>
    subject === COMPUTER_SCIENCE
      ? 'Coding'
      : subject === ENGLISH
      ? 'Storytelling'
      : subject,
  );

  return (
    <div>
      {errorMessage && <ValidationError>{errorMessage}</ValidationError>}
      {emailExists && (
        <ValidationError>
          This email address has already been used to register your family - please
          contact admissions@learnwithjuni.com to add additional students or make any
          changes to your enrollment.
        </ValidationError>
      )}
      {flags.isInDcfExperimentTreatmentGroup && (
        <h1 className="text-j-dark-600 text-xl font-medium mt-0">
          Start Your Account
        </h1>
      )}
      {!flags.isBootcampSignup && (
        <div>
          <p className="text-j-dark-400 text-sm my-0">
            {flags.isInDcfExperimentTreatmentGroup ? (
              <>
                <span className="text-j-green-500 font-semibold">
                  $0 until your second {flags.isOnDemandSignup ? 'week' : 'class'}.
                </span>{' '}
                <span className="text-j-dark-600 font-medium">
                  Cancel anytime before then at no charge.
                </span>
              </>
            ) : (
              <span className="text-j-green-500 font-medium uppercase text-xs">
                $0 now, 1st {flags.isOnDemandSignup ? 'week' : 'class'} free.{' '}
              </span>
            )}
            {flags.isInDcfExperimentTreatmentGroup && flags.isPrivateOneOnOne ? (
              <div>
                After your free class you will be enrolled into our weekly private
                1:1 class subscription for $250/month. You can upgrade, downgrade, or
                cancel from your account at any time.
              </div>
            ) : (
              CONTENT.creditCardCopy
            )}
          </p>
        </div>
      )}
      <div className="flex items-center space-x-4">
        <h3 className="flex-none font-medium text-j-dark-600 text-sm">
          Card Number
        </h3>
        <div className="w-full flex justify-end items-center space-x-1">
          <div className="w-5 h-5 text-j-dark-300 bg-j-gray-100 rounded border border-j-gray-300 border-solid flex justify-center items-center">
            <Icon.LockSolid />
          </div>
          <VisaIcon />
          <MastercardIcon />
          <AmexIcon />
          <DiscoverIcon />
        </div>
      </div>
      <div className="border border-solid border-blue-gray-200 p-3 rounded-lg">
        {window.Stripe && <CardElement options={CARD_ELEMENT_OPTIONS} />}
      </div>
      {stripeError && (
        <ValidationError>
          <span data-cy="stripe-error">{stripeError}</span>
        </ValidationError>
      )}
      <div className="text-j-dark-600">
        <p className="mb-0 text-sm">
          {flags.isOnDemandSignup ? (
            <>
              <span className="font-medium text-j-green-500 uppercase text-xs">
                Unlimited Access
              </span>{' '}
              On Demand Learning
            </>
          ) : data?.getCourse?.displayName && isFromCourseExplorer ? (
            data.getCourse.displayName
          ) : selectedSubjects.length === 1 ? (
            `${selectedSubjects[0]} Private 1:1 Course`
          ) : (
            ''
          )}
        </p>
        {flags.isBootcampSignup ? (
          <p className="my-0 text-sm">
            {selectedDiscount || USE_HALF_OFF_BOOTCAMP ? (
              <>
                <span className="line-through">
                  {formatNumberAsCurrency(price / 100)}
                </span>{' '}
                {formatNumberAsCurrency(
                  calculateDiscountedPriceWithCoupon(
                    USE_HALF_OFF_BOOTCAMP ? price / 2 : price,
                    selectedDiscount,
                  ) / 100,
                )}
              </>
            ) : (
              formatNumberAsCurrency(price / 100)
            )}
          </p>
        ) : (
          <p className="my-0 text-sm">
            <span className="font-medium">
              {flags.isOnDemandSignup
                ? 'First week free, then '
                : `${currentWeeklyClassesSum * 4} classes/mo at `}
              ${price}/mo
            </span>
            . Cancel anytime.
          </p>
        )}
      </div>
      <CouponEditor showCouponEditor />
      <>
        <CoppaContainer className="mb-6 mt-4 overflow-auto border border-solid border-blue-gray-100 p-4 rounded-lg">
          <CoppaDirectNotice />
        </CoppaContainer>
      </>
      <p className="text-sm text-blue-gray-500 mt-0 mb-0">
        By continuing, you have read the COPPA Direct Notice
      </p>
      <div className="flex items-center space-x-2">
        {onBack && (
          <Button
            className="mt-3 w-1/3"
            variant="secondary"
            onClick={onBack}
            renderIconLeft={(props: IconRenderProps) => (
              <Arrow {...props} orientation="left" />
            )}
          >
            Back
          </Button>
        )}
        <Button
          variant="primary"
          className={classNames('mt-3', {
            'w-full': !onBack,
            'w-2/3': onBack,
          })}
          type="submit"
          intent="success"
          disabled={paymentIsProcessing}
        >
          {paymentIsProcessing ? (
            <div className="h-5 flex items-center">
              <SpinnerV2 />
            </div>
          ) : flags.isBootcampSignup ? (
            'Complete Purchase'
          ) : flags.isOnDemandSignup ? (
            'Submit'
          ) : flags.isInDcfExperimentTreatmentGroup ? (
            'Get Your Free Class'
          ) : (
            CONTENT.checkoutButton
          )}
        </Button>
      </div>
    </div>
  );
};

export default PaymentForm;
