import bluejIcon from '../images/special_instructions/bluej.svg';
import codepenIcon from '../images/special_instructions/codepen.svg';

// (** 'mac', 'windows', 'linux', 'chromebook', and always include 'default')
// (** os array elements correspond to different steps/pages in UI element)
// (** general format below)
// {
//     os: [
//         {
//             title: "string",
//             image: "src",
//             video: "src",
//             link: "string",
//             text: "string",
//         },
//         {
//             title: "string",
//             image: "src",
//             video: "src",
//             link: "string",
//             text: "string",
//         }
//     ]
// }

const bluej = {
  default: [
    {
      title: 'Complete project with BlueJ',
      image: bluejIcon,
      text:
        'For our graphics-related Java projects, we use an application called BlueJ to write our code.',
    },
    {
      title: 'Download BlueJ Version 3.1.7',
      video: `https://static.junilearning.com/special_instructions/bluej1_windows.mp4`,
      link: 'https://www.bluej.org/versions.html',
      text: `Please download BlueJ Version 3.1.7 including the JDK (other versions will not work properly, so be sure to download the correct one).`,
    },
    {
      title: 'Start the BlueJ Application',
      video: `https://static.junilearning.com/special_instructions/bluej2_windows.mp4`,
      text:
        'Double click the downloaded zip file to extract contents. Then open the enclosed folder, and double click the BlueJ app to begin!',
    },
  ],
  mac: [
    {
      title: 'Complete project with BlueJ',
      image: bluejIcon,
      text:
        'For our graphics-related Java projects, we use an application called BlueJ to write our code.',
    },
    {
      title: 'Download BlueJ Version 3.1.7',
      video: `https://static.junilearning.com/special_instructions/bluej1_mac.mp4`,
      link: 'https://www.bluej.org/versions.html',
      text: `Please download BlueJ Version 3.1.7 including the JDK (other versions will not work properly, so be sure to download the correct one).`,
    },
    {
      title: 'Start the BlueJ Application',
      video: `https://static.junilearning.com/special_instructions/bluej2_mac.mp4`,
      text:
        'Double click the downloaded zip file to extract contents. Then open the enclosed folder, and double click the BlueJ app to begin!',
    },
    {
      title: 'Create your Applet',
      video: `https://static.junilearning.com/special_instructions/bluej3_mac.mp4`,
      text:
        "If you don't already have a project open, click Project -> New Project to create one. Then, click New Class, delete the templated code, and code your project. Once you want to run, compile your code, right click on the project, and click Run Applet.",
    },
  ],
  windows: [
    {
      title: 'Complete project with BlueJ',
      image: bluejIcon,
      text:
        'For our graphics-related Java projects, we use an application called BlueJ to write our code.',
    },
    {
      title: 'Download BlueJ Version 3.1.7',
      video: `https://static.junilearning.com/special_instructions/bluej1_windows.mp4`,
      link: 'https://www.bluej.org/versions.html',
      text: `Please download BlueJ Version 3.1.7 including the JDK (other versions will not work properly, so be sure to download the correct one).`,
    },
    {
      title: 'Start the BlueJ Application',
      video: `https://static.junilearning.com/special_instructions/bluej2_windows.mp4`,
      text:
        'Double click the downloaded install file and follow the setup instructions. Once complete, double click the BlueJ icon on the Windows Desktop to begin!',
    },
    {
      title: 'Create your Applet',
      video: `https://static.junilearning.com/special_instructions/bluej3_windows.mp4`,
      text:
        "If you don't already have a project open, click Project -> New Project to create one. Then, click New Class, delete the templated code, and code your project. Once you want to run, compile your code, right click on the project, and click Run Applet.",
    },
  ],
  linux: [
    {
      title: 'Complete project with BlueJ',
      image: bluejIcon,
      text:
        'For our graphics-related Java projects, we use an application called BlueJ to write our code. We will have setup instructions for Linux soon! In the meantime, please let your instructor know that you are using Linux, or switch to a Mac or Windows computer to continue with this project.',
    },
  ],
  chromebook: [
    {
      title: 'BlueJ is Not Compatible with Chromebook! 😞',
      image: bluejIcon,
      text:
        'For our graphics-related Java projects, we use an application called BlueJ to write our code. Unfortunately, BlueJ is not compatible with Chromebooks! Please use a Mac or Windows computer for the best experience.',
    },
  ],
};

const codepen = {
  default: [
    {
      title: 'Complete project with Codepen',
      image: codepenIcon,
      link: '',
      text:
        'For our JavaScript projects, we use a website called Codepen. Get started by clicking on the Starter Link on the right!',
    },
    {
      title: 'Fork the Starter Link',
      video: `https://static.junilearning.com/special_instructions/codepen.mp4`,
      text:
        'Once you have opened the Starter Link, click on the Fork button to save your own copy to your Codepen account.',
    },
  ],
};

const pygame_zero = {
  default: [
    {
      title: 'Complete project with Mu',
      video: `https://static.junilearning.com/special_instructions/pygame_setup_1.mp4`,
      link: 'https://codewith.mu/en/download',
      text:
        'In this Pygame course, we use a simple Python editor called Mu to run our programs. Follow the link above download Mu on your computer!',
    },
    {
      title: 'Create and save your file',
      video: `https://static.junilearning.com/special_instructions/pygame_setup_2.mp4`,
      text:
        'Once you open up Mu, your files will be saved in a folder on your computer called mu_code. Your instructor will provide you with the image, sounds, and music files for your projects!',
    },
  ],
};

const colab_notebook = {
  default: [
    {
      title: 'Complete project with Colab Notebook',
      video: `https://static.junilearning.com/special_instructions/colab_setup_1.mp4`,
      text:
        'Click on the Starter Link in the Instructions, and then click on Copy to Drive before starting the project in order to save your own copy!',
    },
  ],
};

export const SI_LOOKUP_TABLE = { bluej, codepen, pygame_zero, colab_notebook };
