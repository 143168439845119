import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ICurrentProject } from './models';

const projectLinkHasTrailingSlash = (projectLink: string) =>
  projectLink?.endsWith('/');

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
  #scratch-wrap {
    padding-bottom: 80%;
  }
  #scratch-frame {
    transform-origin: top left;
  }
  & iframe {
    margin-bottom: 1rem;
    position: absolute;
    border-radius: 16px;
    transform: scale(1);
    background: rgba(0, 165, 255, 1);
    box-shadow: 0px 0px 0px 2px #ccdfe8, 0px 14px 44px 0px hsl(202deg 31% 59% / 38%);
  }
`;

const ScratchEmbed: React.FC<{
  projectLink: ICurrentProject['properties']['projectLink'];
  displayName: ICurrentProject['displayName'];
}> = ({ projectLink, displayName }) => {
  const aspectRatio = 0.8;
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scaleFactor, setScaleFactor] = useState(1);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setContainerWidth(wrapperRef?.current?.offsetWidth || 0);
    const resetScaleFactor = () => {
      // ignore this callback when this
      // element has been destroyed
      if (!(wrapperRef as any).current) return;
      setScaleFactor((wrapperRef as any).current.clientWidth / containerWidth);
      // ensure that the scale factor is properly
      // set after multiple queued resizings.
      if (timeout?.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(resetScaleFactor, 250);
    };
    window.addEventListener('resize', resetScaleFactor);

    return () => {
      window.removeEventListener('resize', resetScaleFactor);
    };
  }, [containerWidth, timeout, wrapperRef]);

  const cleanedProjectLink = projectLink
    ? projectLinkHasTrailingSlash(projectLink)
      ? `${projectLink}embed`
      : `${projectLink}/embed`
    : undefined;

  return (
    <EmbedContainer>
      <div id="scratch-wrap" ref={wrapperRef}>
        {containerWidth !== undefined && containerWidth > 0 && (
          <iframe
            id="scratch-frame"
            src={cleanedProjectLink}
            style={{
              width: `${containerWidth}px`,
              height: `${containerWidth * aspectRatio}px`,
              transform: `scale(${scaleFactor})`,
            }}
            frameBorder={0}
            allowFullScreen
            title={displayName || 'scratch embed'}
          />
        )}
      </div>
    </EmbedContainer>
  );
};

export default ScratchEmbed;
