import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { clearSentryUser } from 'services/sentry';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { useUserContext } from 'modules/UserContext';

const Logout = ({ history }) => {
  const client = useApolloClient();
  const { deauthenticateUser } = useUserContext();

  useEffect(() => {
    clearSentryUser();
    JuniAnalytics.reset();
    deauthenticateUser();
    client.resetStore();
    history.push('/');
  }, [client, history, deauthenticateUser]);

  return null;
};

export default Logout;
