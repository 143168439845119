import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AxiosError } from 'axios';
import { GraphQLError } from 'graphql';
import { User } from 'models';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const IGNORED_ERRORS = [
  // was causing thousands of events but can be safely ignored
  // https://github.com/JuniLearning/juni-app-frontend/issues/866
  'ResizeObserver loop limit exceeded',
  // causing thousands of errors from Skulpt (Python Turtle) but can likely be safely ignored
  // https://github.com/JuniLearning/juni-app-frontend/issues/879
  'UnhandledRejection: Non-Error promise rejection captured with keys: args, traceback',
];

export function isSentryEnabled() {
  return !!SENTRY_DSN;
}

export function initializeSentry() {
  if (!isSentryEnabled()) {
    return;
  }
  const KNOWN_ENVIRONMENTS = ['production', 'staging', 'development'];
  const sentryEnvironment =
    ENVIRONMENT && KNOWN_ENVIRONMENTS.includes(ENVIRONMENT)
      ? ENVIRONMENT
      : 'development';
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // Sampling 10% of traces
    environment: sentryEnvironment,
    ignoreErrors: IGNORED_ERRORS,
  });
}

export function identifySentryUser(user: User) {
  if (!isSentryEnabled()) {
    return;
  }
  Sentry.setUser({
    id: user._id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  });
}

export function clearSentryUser() {
  if (!isSentryEnabled()) {
    return;
  }
  Sentry.configureScope(scope => scope.setUser(null));
}

// Structured error to wrap Axios error (that has little context)
class HttpError extends Error {
  constructor(error: AxiosError) {
    const message = error.response?.data?.error || error.message;
    super(message);
    this.name = 'HttpError';
  }
}

interface StructuredContexts {
  server?: {
    errorId?: string;
    method?: string;
    url?: string;
  };
}

export function logErrorToSentry(error: any) {
  if (!isSentryEnabled()) {
    return;
  }
  const contexts: StructuredContexts = {};
  // add additional context for HTTP errors
  if (error.isAxiosError) {
    const axiosError: AxiosError = error;
    contexts.server = {
      errorId: axiosError.response?.data?.errorId,
      method: axiosError.config.method,
      url:
        axiosError.config.url &&
        `${axiosError.config.baseURL || ''}${axiosError.config.url}`,
    };
  }
  // add additional context for Apollo errors
  if (error instanceof GraphQLError) {
    contexts.server = { errorId: error?.extensions?.errorId };
  }
  // convert Axios errors to provide more context
  const processedError = error.isAxiosError ? new HttpError(error) : error;
  Sentry.captureException(processedError, {
    contexts: contexts as Record<string, Record<string, unknown>>,
  });
}
