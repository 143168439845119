import React, { useState } from 'react';
import { NewButton as Button } from 'core-components';
import classNames from 'classnames';

interface SectionProps<T> {
  /**
   * Item length will be appended if items has a length.
   */
  title: string;
  items: T[];
  renderItem: (item: T) => React.ReactElement;
  /**
   * Unique React key to use for each item.
   */
  itemKey: (item: T) => React.Key;
  /**
   * If the items array is empty, an empty state will be displayed with this message.
   */
  emptyStateMessage: string;
  /**
   * If the items array is empty, an empty state will be displayed with this illustration url.
   */
  emptyStateIconUrl: string;
  /**
   * If the items array is empty, optionally show a full width CTA button at the bottom of the Section.
   */
  emptyStateCtaButton?: {
    text: string;
    href: string;
  };
  /**
   * Number of items to display before adding a button to expand/collapse.
   * This is not server pagination, just UI expand/collapse of all items.
   * Default 4.
   */
  collapsedShowCount?: number;
}

const Section = <T extends unknown>({
  title,
  items,
  renderItem,
  itemKey,
  emptyStateMessage,
  emptyStateIconUrl,
  emptyStateCtaButton,
  collapsedShowCount = 4,
}: SectionProps<T>) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="font-graphik">
      <div className="text-j-dark-600 text-sm leading-6 font-medium">
        {title}
        {items.length > 0 && ` (${items.length})`}
      </div>
      {/* Empty state */}
      {items.length === 0 && (
        <div>
          <div className="flex justify-center mt-6">
            <img
              src={emptyStateIconUrl}
              alt="Empty state icon"
              className="w-20 h-20"
            />
          </div>
          <div className="mt-2 text-center text-base text-j-dark-400">
            {emptyStateMessage}
          </div>
          {!!emptyStateCtaButton && (
            <div className="mt-4">
              <Button variant="secondary" href={emptyStateCtaButton.href} fullWidth>
                {emptyStateCtaButton.text}
              </Button>
            </div>
          )}
        </div>
      )}
      {items.length > 0 && (
        <div className="mt-10">
          {items
            .slice(0, expanded ? items.length : collapsedShowCount)
            .map((item, index) => (
              <div
                key={itemKey(item)}
                className={classNames({ 'mt-10': index > 0 })}
              >
                {renderItem(item)}
              </div>
            ))}
        </div>
      )}
      {items.length > collapsedShowCount && (
        <div className="mt-10">
          <Button
            onClick={() => setExpanded(prev => !prev)}
            variant="secondary"
            fullWidth
          >
            {expanded ? 'View Less' : 'View All'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Section;
