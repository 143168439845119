import React from 'react';
import { OpenChallenges } from 'app/home_views';

const ChallengesHome = () => (
  <div className="flex flex-col items-center justify-center">
    <div className="max-w-7xl p-6">
      <OpenChallenges />
    </div>
  </div>
);

export default ChallengesHome;
