import { jBlue, jGreen, jOrange, jPink, jPurple, jYellow } from 'theme/colors';

// adopted from https://www.html-code-generator.com/javascript/color-converter-script
const hexToHsl = (hexValue: string) => {
  let hex = hexValue.replace(/#/g, '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(h => h + h)
      .join('');
  }
  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex);
  if (!result) {
    throw new Error('Invalid hex value');
  }
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  let l = (max + min) / 2;
  if (max === min) {
    s = 0;
    h = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return {
    h,
    s,
    l,
  };
};

export const ANNOTATIONS_COLUMN_WIDTH = 240;
export const ANNOTATION_LINE_HEIGHT = 24;
export const ANNOTATION_WIDTH = 220;
export const INACTIVE_ANNOTATION_LEFT = 10;
export const ANNOTATIONS_MARGIN = 12;
export const RICH_TEXT_LINE_HEIGHT = 32;
export const INLINE_ANNOTATION_EDITOR_WIDTH = 280;
export const DEFAULT_HIGHLIGHT_COLOR = 'YELLOW';
export const NEW_HORIZONS_COLORS = [
  jPurple[300],
  jBlue[300],
  '#ABF0FF',
  jGreen[300],
  '#F6C0FF',
  jPink[300],
  jOrange[300],
  jYellow[300],
];
/**
 * @deprecated Replaced with New Horizons colors.
 */
export const HIGHLIGHT_COLORS = [
  'RED',
  'ORANGE',
  'YELLOW',
  'GREEN',
  'BLUE',
  'VIOLET',
  'PURPLE',
  'PINK',
];
const DEFAULT_HIGHLIGHT_OPACITY = 0.46;
const DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS = {
  s: 100,
  l: 50,
};
const COLOR_HSL_DATA: Record<string, { h: number; s: number; l: number }> = {
  RED: {
    h: 0,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  ORANGE: {
    h: 22,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  YELLOW: {
    h: 50,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  GREEN: {
    h: 108,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  BLUE: {
    h: 209,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  VIOLET: {
    h: 234,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  PURPLE: {
    h: 270,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
  PINK: {
    h: 302,
    ...DEFAULT_HIGHLIGHT_SATURATION_AND_LIGHTNESS,
  },
};

const OLD_HIGHLIGHT_COLOR_VALUES = HIGHLIGHT_COLORS.reduce(
  (
    agg: Record<string, { solidValue: string; translucentValue: string }>,
    color: string,
  ) => {
    const hsl = COLOR_HSL_DATA[color];
    return {
      ...agg,
      [color]: {
        solidValue: `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`,
        translucentValue: `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${DEFAULT_HIGHLIGHT_OPACITY})`,
      },
    };
  },
  {},
);

const NEW_HORIZONS_HIGHLIGHT_COLOR_VALUES = NEW_HORIZONS_COLORS.reduce(
  (
    agg: Record<string, { solidValue: string; translucentValue: string }>,
    hexColor: string,
  ) => {
    const hsl = hexToHsl(hexColor);
    return {
      ...agg,
      [hexColor]: {
        solidValue: `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`,
        translucentValue: `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${DEFAULT_HIGHLIGHT_OPACITY})`,
      },
    };
  },
  {},
);

export const HIGHLIGHT_COLOR_VALUES = {
  ...OLD_HIGHLIGHT_COLOR_VALUES,
  ...NEW_HORIZONS_HIGHLIGHT_COLOR_VALUES,
};

export const ANNOTATIONS_BAR_HEADER_HEIGHT = 64;
