export const REFERRED_ENROLLMENT_COUPON_STRIPE_ID = 'REFERRAL_FIRST_MONTH';
export const REFERRER_CREDIT_COUPON_STRIPE_ID = 'REFERRAL_100';

// these coupon codes should only be applied to the first matching subscription for which it is allowed
export const DISCOUNT_SPECIFIC = {
  fTutO7pp: {
    '6-month-1x-private': true, // deprecated
    '6-month-2x-private-450': true, // deprecated
    prod_core_1: true,
    prod_core_2: true,
  },
  MEMORIAL2020: {
    '6-month-1x-private': true, // deprecated
    '6-month-2x-private-450': true, // deprecated
    prod_core_1: true,
    prod_core_2: true,
  },
  [REFERRED_ENROLLMENT_COUPON_STRIPE_ID]: {
    '6-month-1x-private': true, // deprecated
    '6-month-2x-private-450': true, // deprecated
    prod_core_1: true,
    prod_core_2: true,
    'juni-jr-130': true, // deprecated
    prod_juniJr_1: true,
    plan_Gt61vsd5iEcabr: true,
    plan_Gt60cnODsvGnDA: true,
    price_1H0VnfIsJ8B817wyrEavvPUK: true,
    price_1H0Vn2IsJ8B817wyUBdVnk8u: true,
    price_1H0XxwIsJ8B817wyocYdeznG: true,
  },
  // REFERRAL_100 coupon should only be directly applied by admins in the Stripe dashboard
  [REFERRER_CREDIT_COUPON_STRIPE_ID]: {},
};
