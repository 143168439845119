import juniAxios from 'services/axios';

const ALLOWED_PROGRESS_STATUSES = ['completed', 'in_progress'];

export async function getModuleProgresses(student, status) {
  const trackNames = [
    student.track,
    ...student.tracks,
    ...student.completedTracks,
  ].filter(v => v != null);
  const search =
    status && ALLOWED_PROGRESS_STATUSES.includes(status) ? `?status=${status}` : '';

  const moduleProgresses = (
    await juniAxios.get(
      `/learner/progress/modules/${student._id}/${trackNames.join(',')}${search}`,
    )
  ).data.data;
  return moduleProgresses;
}

export async function getModuleSectionProgresses(studentId, status) {
  const search =
    status && ALLOWED_PROGRESS_STATUSES.includes(status) ? `?status=${status}` : '';

  const moduleSectionProgresses = (
    await juniAxios.get(`/learner/progress/module_sections/${studentId}${search}`)
  ).data.data;
  return moduleSectionProgresses;
}
