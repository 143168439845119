import { onError } from '@apollo/link-error';
import { ApolloError } from '@apollo/client';
import { ERROR_MESSAGES, GENERIC_ERROR_MESSAGE } from 'constants/errors';
import { ErrorInfo } from 'utils/errors';
import { logErrorToSentry } from 'services/sentry';
import { GraphQLError } from 'graphql';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  // log errors
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      console.error(
        `[GraphQL Error] Message: ${err.message}, Code: ${err.extensions?.code}, Path: ${err.path}`,
      );
      // log all non-user visible Sentry errors
      if (!err.extensions?.userVisible) {
        // construct new GraphQL error (Apollo is returning plain objects for some reason)
        const graphQlError = new GraphQLError(
          err.message,
          err.nodes,
          err.source,
          err.positions,
          err.path,
          err.originalError,
          err.extensions,
        );
        logErrorToSentry(graphQlError);
      }
    });
  }
  if (networkError) {
    console.error('[Network Error]', networkError);
  }
});

/**
 * Extracts relevant info from Apollo error
 *
 * Note: Only extracts the info from the first GraphQL error present
 *
 * @param error Apollo error thrown by apollo
 */
export function getApolloErrorInfo(error: ApolloError): ErrorInfo {
  if (error.graphQLErrors?.length) {
    const graphQLError = error.graphQLErrors[0];
    return {
      code: graphQLError.extensions?.code,
      message: graphQLError.extensions?.userVisible
        ? graphQLError.message
        : GENERIC_ERROR_MESSAGE,
      extensions: graphQLError.extensions,
    };
  }
  return {
    code: 'network-error',
    message: ERROR_MESSAGES['network-error'],
  };
}
