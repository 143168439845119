import { SignupSessionProps } from 'app/signup_session/types';
import UserContext from 'modules/UserContext';
import React, { FC, useContext } from 'react';
import { Redirect } from 'react-router-dom';

const RedirectPage: FC<SignupSessionProps> = ({ location }) => {
  const { user } = useContext(UserContext);

  // case 1: user is already logged in
  // -> forward them to bootcamp checkout page
  if (user && user.userType === 'parent') {
    return <Redirect to={`/signup2/payment${location.search}`} />;
  }

  // case 2/3: user is not logged in / user does not have an account
  // -> forward them to /signup/parent-info page
  // -> A -> the user enters their email, encounters a warning message, logs in, is redirected to this page
  // -> B -> the user completes the flow as a new user
  return <Redirect to={`/signup${location.search}`} />;
};

export default RedirectPage;
