import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';

import { SCIENCE_TRACKS } from 'constants/tracks';
import { projectTypeToRenderInfo } from 'constants/project_types';
import humanizeTimeAgo from 'utils/dates/humanizeTimeAgo';

import { ProjectTypes } from 'app/playground/models';
import {
  buildPlaygroundLink,
  buildNonPlaygroundLink,
  buildInstructorPlaygroundLink,
} from 'app/playground/utils';

import { COURSE_TYPES } from 'constants/subjects';
import classNames from 'classnames';
import { CardOrientations, IBadgeProperties } from './models';
import Camera from './badges/Camera';
import InProgress from './badges/InProgress';
import Complete from './badges/Complete';
import PlaygroundBadge from './badges/PlaygroundBadge';
import BadgeTooltip from './badges/BadgeTooltip';
import { ProjectCardContainer, ProjectInfo, ProjectBadges } from './styles';
import SettingsBadge from './badges/SettingsBadge';
import DeleteProject from './DeleteProject';

export const Badge: React.FC<{
  icon: JSX.Element;
  tooltipContent: JSX.Element | string;
  clickToOpen?: boolean;
  lightBg?: boolean;
}> = ({ icon, tooltipContent, lightBg, clickToOpen }) => {
  const [tooltipIsVisible, toggleTooltipIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (clickToOpen) {
      e.preventDefault();
      toggleTooltipIsVisible(!tooltipIsVisible);
    }
  };

  useEffect(() => {
    if (clickToOpen) {
      document.addEventListener('click', e => {
        if (!containerRef?.current?.contains(e.target as Node)) {
          toggleTooltipIsVisible(false);
        }
      });
    }
  }, [clickToOpen]);

  const hoverProps = clickToOpen
    ? {}
    : {
        onMouseEnter: () => toggleTooltipIsVisible(true),
        onMouseLeave: () => toggleTooltipIsVisible(false),
      };
  return (
    <div ref={containerRef} onClick={handleClick} {...hoverProps}>
      {tooltipIsVisible && (
        <BadgeTooltip lightBg={lightBg} tooltipContent={tooltipContent} />
      )}
      {icon}
    </div>
  );
};

const ProjectCard: React.FC<{
  isPlayground: boolean;
  userId: string;
  projectId: string;
  orientation: CardOrientations;
  linkTo?: string;
  homeView?: boolean;
  projectType?: ProjectTypes;
  projectTitle: string;
  publishedAt: string | null;
  moduleId?: string | null;
  properties?: IBadgeProperties;
  userType?: string;
  deleteProject?: () => void;
  idLookup: any;
}> = ({
  isPlayground,
  userId,
  projectId,
  projectType,
  projectTitle,
  publishedAt,
  orientation,
  homeView,
  linkTo,
  properties,
  userType,
  deleteProject,
  idLookup,
}) => {
  let isScienceCourse = false;
  try {
    const { courseId } = idLookup[projectId].properties;
    const courseName = idLookup[courseId].properties.name;
    isScienceCourse = SCIENCE_TRACKS.map(st => st.name).includes(courseName);
  } catch (error) {
    // pass
  }

  const projectTypeKey = isScienceCourse
    ? 'science_project'
    : projectType
    ? (ProjectTypes[projectType] as string)
    : 'unknown';

  const { icon, displayName, color, iconBgColor } = projectTypeToRenderInfo(
    projectTypeKey,
  );

  const nonCodeCourseTypes: string[] = Object.values(COURSE_TYPES).filter(
    courseType => courseType !== COURSE_TYPES.COMPUTER_SCIENCE,
  );
  const courseType = nonCodeCourseTypes.includes(projectTypeKey)
    ? (projectTypeKey as typeof COURSE_TYPES[keyof typeof COURSE_TYPES])
    : COURSE_TYPES.COMPUTER_SCIENCE;
  const link =
    linkTo ||
    (userType === 'teacher' && isPlayground
      ? buildInstructorPlaygroundLink({
          projectId,
          projectType: projectType!,
          projectName: projectTitle,
        })
      : isPlayground
      ? buildPlaygroundLink({
          projectId,
          projectType: projectType!,
          projectName: projectTitle,
          userId,
        })
      : buildNonPlaygroundLink({ userId, projectId, courseType }));

  const displayTitle = homeView
    ? projectTitle
    : truncate(projectTitle, { length: 20 });

  return (
    <ProjectCardContainer orientation={orientation}>
      <Link className="project-card-link" to={link}>
        <div
          className={classNames(
            'icon__container',
            `flex items-center justify-center text-white bg-${iconBgColor}`,
          )}
        >
          {React.createElement(icon, { height: 34, width: 34 })}
        </div>
        <div className="content__container">
          <ProjectInfo
            hasDate={!!publishedAt}
            projectType={projectType}
            orientation={orientation}
          >
            <span className="project-title">{displayTitle}</span>
            <div className="project-meta__container">
              <span className={classNames('project-type', color)}>
                {displayName}
              </span>
              {publishedAt && (
                <span className="project-published_at">
                  {humanizeTimeAgo(publishedAt)}
                </span>
              )}
            </div>
          </ProjectInfo>
          <ProjectBadges>
            {properties?.hasRecording && (
              <Badge
                tooltipContent="This project has a recording"
                icon={<Camera />}
              />
            )}
            {properties?.inProgress && (
              <Badge tooltipContent="In progress" icon={<InProgress />} />
            )}
            {properties?.completed && (
              <Badge tooltipContent="Completed" icon={<Complete />} />
            )}
            {properties?.playgroundProject && (
              <>
                <Badge
                  tooltipContent="Playground Project"
                  icon={<PlaygroundBadge />}
                />
                {deleteProject && (
                  <Badge
                    lightBg
                    clickToOpen
                    tooltipContent={<DeleteProject deleteProject={deleteProject} />}
                    icon={<SettingsBadge />}
                  />
                )}
              </>
            )}
          </ProjectBadges>
        </div>
      </Link>
    </ProjectCardContainer>
  );
};
ProjectCard.defaultProps = { userType: 'student' };

export default ProjectCard;
