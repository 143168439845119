import React, { useState } from 'react';
import { InputField } from 'components/ui';
import ActionButton from 'components/ActionButton';
import { Arrow } from 'components/Icons';
import { pingScratchUrl } from 'services/playgroundService';
import { IPlaygroundProject } from 'app/playground/models';
import {
  ProjectEditorContainer,
  Error,
} from 'components/ui/EditTooltipContainer/index';
import { validateScratchUrl, cleanUrl } from '.';
import { ICustomScratchProject } from '../models';

const ProjectEditor: React.FC<{
  scratchProject: ICustomScratchProject;
  onUpdate: ({
    newProjectName,
    newScratchUrl,
  }: {
    newProjectName: string;
    newScratchUrl: string;
  }) => Promise<void>;
  toggleEditing: (editing: boolean) => void;
  playgroundProject?: IPlaygroundProject;
  editing?: boolean;
}> = ({ scratchProject, onUpdate, toggleEditing }) => {
  const [newProjectName] = useState<string>(scratchProject.displayName);
  const [newScratchUrl, setNewScratchUrl] = useState<string>(
    scratchProject.scratchUrl!,
  );
  const [activeStep] = useState(0);
  const [scratchUrlStatus, setScratchUrlStatus] = useState<undefined | number>(
    undefined,
  );
  const [error] = useState<undefined | string>(undefined);

  const stepsToComplete = [
    {
      title: 'Change Scratch URL:',
      onChange: (e: any) => {
        if (scratchUrlStatus) {
          setScratchUrlStatus(undefined);
        }
        setNewScratchUrl(e);
      },
      placeholder: scratchProject.scratchUrl,
      value: newScratchUrl,
      onClick: async () => {
        const cleanedUrl = cleanUrl(newScratchUrl);
        const urlStatus = await pingScratchUrl(cleanedUrl);
        setScratchUrlStatus(urlStatus);
        if (urlStatus !== 200) return;

        onUpdate({ newProjectName, newScratchUrl: cleanedUrl });
        toggleEditing(false);
      },
    },
  ];

  const { title, onChange, placeholder, onClick, value } = stepsToComplete[
    activeStep
  ];

  const { valid, msg } = validateScratchUrl(newScratchUrl);

  return (
    <ProjectEditorContainer>
      <span className="project-header">{title}</span>
      <InputField
        value={value || ''}
        onChange={onChange}
        placeholder={placeholder!}
      />
      {!valid && <Error>{msg}</Error>}
      {scratchUrlStatus === 404 && (
        <Error>
          Oops! This project hasn't been published in Scratch yet. Click the orange
          Share button at the top of your project.
        </Error>
      )}
      {error && <Error>{error}</Error>}
      <ActionButton
        disabled={!valid}
        onClick={onClick}
        className="scratch-env-action-button"
      >
        <span>Save this project</span> <Arrow orientation="right" />
      </ActionButton>
    </ProjectEditorContainer>
  );
};

export default ProjectEditor;
