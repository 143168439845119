import React, { FC } from 'react';
import classNames from 'classnames';

import { Icon } from 'core-components';

import OutputTab from './OutputTab';

type TabNames = 'instructions' | 'console' | 'canvas';

interface OutputHeaderProps {
  showInstructions?: boolean;
  setPane: (activeTab: TabNames) => void;
  activePane: TabNames;
  showCanvas?: boolean;
  hasError?: boolean;
  showTabs?: boolean;
}

const JideCodeOutputHeader: FC<OutputHeaderProps> = ({
  showInstructions,
  setPane,
  activePane,
  showCanvas,
  hasError,
  showTabs,
}) => (
  <div
    className={classNames(
      'flex',
      'py-3 px-6',
      'w-auto',
      'bg-white',
      'border-solid border-j-dark-100 border-0 border-b',
      'box-border',
    )}
  >
    <div className={classNames('flex', 'mr-auto')}>
      {' '}
      {showInstructions && (
        <OutputTab
          renderIcon={props => <Icon.Notebook {...props} />}
          onClick={() => setPane('instructions')}
          selected={activePane === 'instructions'}
        >
          Instructions
        </OutputTab>
      )}
      {showCanvas && !showTabs && (
        <OutputTab
          renderIcon={props => <Icon.Canvas {...props} />}
          onClick={() => setPane('canvas')}
          selected={activePane === 'canvas'}
        >
          Canvas
        </OutputTab>
      )}
      {!showTabs && (
        <OutputTab
          renderIcon={props =>
            hasError ? <Icon.Error {...props} /> : <Icon.Console {...props} />
          }
          onClick={() => setPane('console')}
          selected={activePane === 'console'}
          hasError={hasError}
        >
          Console
        </OutputTab>
      )}
    </div>
  </div>
);

export default JideCodeOutputHeader;
