import React from 'react';
import { Phone, Email } from 'core-components/Icon';
import { StyledIcon } from './styles';

export const EmailIcon = () => (
  <StyledIcon>
    <Email className="text-juni-green-500" />
  </StyledIcon>
);
export const PhoneIcon = () => (
  <StyledIcon>
    <Phone className="text-juni-green-500" />
  </StyledIcon>
);
export const ClockIcon = () => (
  <StyledIcon>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51268 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20305 16.875 10C16.875 13.797 13.797 16.875 10 16.875C6.20304 16.875 3.125 13.797 3.125 10ZM10.625 6.66667C10.625 6.32149 10.3452 6.04167 10 6.04167C9.65482 6.04167 9.375 6.32149 9.375 6.66667L9.375 10C9.375 10.209 9.47944 10.4041 9.65331 10.52L12.1533 12.1867C12.4405 12.3782 12.8286 12.3006 13.02 12.0134C13.2115 11.7262 13.1339 11.3381 12.8467 11.1466L10.625 9.66551L10.625 6.66667Z"
        fill="#14A54A"
      />
    </svg>
  </StyledIcon>
);
