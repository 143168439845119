import React from 'react';
import PropTypes from 'prop-types';

import { CopyIcon, CheckmarkIcon } from '../Icons';

import './copy_button.css';

class CopyButton extends React.Component {
  state = {
    copied: false,
  };

  setClipboard = s => {
    const tempInput = document.createElement('textarea');
    tempInput.style = 'position: fixed; left: -1000px; top: -1000px';
    tempInput.value = s;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  render() {
    const { iconOnly, text, title, style, buttonLabel } = this.props;
    const { copied } = this.state;
    return (
      <div
        className={`link-copy-button ${iconOnly ? 'icon-only' : ''}`}
        onClick={() =>
          this.setClipboard(text) ||
          this.setState({ copied: true }, () =>
            setTimeout(() => this.setState({ copied: false }), 2000),
          )
        }
        title={title}
        style={style}
      >
        {!iconOnly && (
          <span style={{ marginTop: 1 }}>
            {copied ? 'Copied!' : buttonLabel || 'Copy'}
          </span>
        )}
        {(!copied || !iconOnly) && <CopyIcon blue lighterblue />}
        {copied && iconOnly && <CheckmarkIcon blue lighterblue />}
      </div>
    );
  }
}
CopyButton.defaultProps = {
  iconOnly: false,
  title: '',
  style: null,
};
CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.shape({}),
};

export default CopyButton;
