import React, { FC } from 'react';
import { Parent, Student } from 'models';
import MyCalendar from 'app/learner/MyCalendar';

interface CalendarHomeProps {
  parent: Parent;
  student: Student;
}

const CalendarHome: FC<CalendarHomeProps> = ({ parent, student }) => (
  <div className="flex flex-col items-center justify-center">
    <div className="max-w-7xl p-6">
      <MyCalendar parent={parent} student={student} />
    </div>
  </div>
);

export default CalendarHome;
