import React, { FC } from 'react';
import classNames from 'classnames';

import {
  TailwindBorderRadius,
  TailwindBorderWidth,
  TailwindColor,
  TailwindShadow,
  TailwindSpace,
} from 'theme/types';

export interface PanelProps {
  /**
   * Specifies the background color of panel, styled with colors from our Tailwind theme.
   */
  backgroundColor?: TailwindColor;
  /**
   * Specifies the border color of the panel, styled with colors from our Tailwind theme.
   */
  borderColor?: TailwindColor;
  /**
   * Specifies the border radius of the panel, styled with Tailwind scale.
   */
  borderRadius?: TailwindBorderRadius;
  /**
   * Specifies the border width of the panel, styled with Tailwind scale.
   */
  borderWidth?: TailwindBorderWidth;
  /**
   * Enables additional styling for the panel.
   */
  className?: string;
  /**
   * Determines the behavior when Panel is clicked.
   */
  handleOnClick?: () => void;
  /**
   * Specifies the box shadow of the panel, styled with Tailwind classes.
   */
  boxShadow?: TailwindShadow;
  /**
   * Determines if the cursor is a pointer.
   */
  isClickable?: boolean;
  /**
   * Specifies the padding of the panel, styled with Tailwind scale.
   */
  padding?: TailwindSpace | TailwindSpace[];
}

const getPaddingStyle = (padding: TailwindSpace | TailwindSpace[]) => {
  switch (padding?.length) {
    case 1:
      return `p-${padding}`;
    case 2:
      return `py-${padding[0]} px-${padding[1]}`;
    case 3:
      return `pt-${padding[0]} px-${padding[1]} pb-${padding[2]}`;
    case 4:
      return `pt-${padding[0]} pr-${padding[1]} pb-${padding[2]} pl-${padding[3]}`;
    default:
      return `p-${padding}`;
  }
};

const Panel: FC<PanelProps> = ({
  backgroundColor = 'j-gray-200',
  borderColor = 'transparent',
  borderRadius = 'lg',
  borderWidth = '0',
  boxShadow = 'none',
  children,
  className,
  handleOnClick,
  isClickable,
  padding = '5',
}) => {
  const paddingStyle = getPaddingStyle(padding);

  return (
    <div
      className={classNames(
        'flex',
        `bg-${backgroundColor}`,
        `border-solid border-${borderColor} border-${borderWidth} rounded${
          borderRadius ? `-${borderRadius}` : ''
        }`,
        `shadow${boxShadow ? `-${boxShadow}` : ''}`,
        { 'cursor-pointer': isClickable },
        paddingStyle,
        className,
      )}
      onClick={handleOnClick}
    >
      {children}
    </div>
  );
};

export default Panel;
