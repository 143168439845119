import React from 'react';
import Bubble from '../Bubble';

const BubblesHero: React.FC = ({ children }) => (
  <div className="relative bg-j-purple-600 pt-4 sm:pt-16 px-4 py-40 sm:py-64 overflow-hidden">
    <Bubble
      baseSize={444}
      breakpointSizes={[{ breakpoint: 'sm', size: 662 }]}
      // anchor center of the circle to bottom right corner of the block
      className="bg-j-purple-500 absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2"
    />
    <Bubble
      baseSize={290}
      breakpointSizes={[{ breakpoint: 'sm', size: 474 }]}
      // anchor center of the circle to bottom left corner of the block,
      // on mobile it's translated down an extra 1/4
      className="bg-j-purple-800 absolute bottom-0 left-0 transform -translate-x-1/2 translate-y-3/4 sm:translate-y-1/2"
    />
    {children}
  </div>
);

export default BubblesHero;
