import React from 'react';
import CopyButton from 'components/CopyButton';
import { Arrow, CodeIcon } from 'components/Icons';

const StartedScratchProject = () => (
  <div className="started-project__container scratch-env-option">
    <CodeIcon />
    <h2>I've already started this project</h2>
    <p>Find your saved project in your Scratch account under My Stuff:</p>
    <div className="buttons-container">
      <div className="scratch-env-link">
        <div className="link-text">https://scratch.mit.edu/mystuff/</div>
        <CopyButton text="https://scratch.mit.edu/mystuff/" />
      </div>
      <a
        href="https://scratch.mit.edu/mystuff/"
        target="_blank"
        rel="noopener noreferrer"
        className="scratch-env-action-button"
      >
        <span>Visit My Stuff in Scratch</span>
        <Arrow orientation="right" />
      </a>
    </div>
    <small>
      If you can't find this project in My Stuff, please click "I haven't started
      this project yet" →
    </small>
  </div>
);

export default StartedScratchProject;
