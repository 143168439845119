import juniAxios from 'services/axios';
import { COURSE_TYPES } from 'constants/subjects';

const ALLOWED_COURSE_TYPES = [
  COURSE_TYPES.COMPUTER_SCIENCE,
  COURSE_TYPES.MATHEMATICS,
];

// TODO: MOVE TO COURSES.JS
// Does not yet handle code type courses because the /api_trial/offerings/code endpoint is not yet implemented
export const getTrialCourseOfferings = async courseTypes => {
  let validCourseTypes = ALLOWED_COURSE_TYPES.filter(type =>
    courseTypes.includes(type),
  );

  // Delete below line out once /api_trial/offerings/code endpoint is set up if a /code_trial/:courseNames route
  // is needed for unauthenticated users. Also change 'let' --> 'const' in assignment above.
  validCourseTypes = [COURSE_TYPES.MATHEMATICS];

  const requests = validCourseTypes.map(type =>
    juniAxios.get(`/api_trial/offerings/${type}`).then(res => res.data),
  );
  const offerings = await Promise.all(requests);

  return offerings;
};
