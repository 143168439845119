import React, { useCallback } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';

import './index.css';
import { useField } from 'formik';

interface Props
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'label'> {
  label?: React.ReactNode;
  className?: string;
  type: 'checkbox' | 'radio';
  onChange?: (value: string, name: string, checked: boolean) => void;
  onRawChange?: React.ChangeEventHandler;
  dataCy?: string;
}

interface FormikProps extends Props {
  name: string;
  dataCy?: string;
}

const CheckedField = ({
  className,
  dataCy,
  label,
  onChange,
  onRawChange,
  disabled,
  ...rest
}: Props) => {
  const handleChange = useCallback(
    e => {
      onChange?.(e.target.value, e.target.name, e.target.checked);
      onRawChange?.(e);
    },
    [onChange, onRawChange],
  );

  return (
    <label
      className={classNames(
        'ui-checked-field ui-field',
        {
          'ui-disabled': disabled,
        },
        className,
      )}
    >
      <input
        disabled={disabled}
        {...rest}
        onChange={handleChange}
        data-cy={dataCy}
      />
      <span className="ui-checked-label">{label}</span>
    </label>
  );
};

const CheckedFieldFormik: React.FC<FormikProps> = props => {
  const [field] = useField(R.omit(['onChange'], props));

  const handleRawChange: React.ChangeEventHandler = (e): void => {
    field.onChange(e);
    if (props.onRawChange) props.onRawChange(e);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <CheckedField
      {...props}
      onRawChange={handleRawChange}
      onBlur={handleBlur}
      checked={field.checked}
    />
  );
};

CheckedField.Formik = CheckedFieldFormik;

export default CheckedField;
