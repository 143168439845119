import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';
import Radio from 'app/signup_session/components/Radio';

const SingleSelect: FC<{
  value?: string | number;
  options: Array<{
    label: string;
    value: string | number;
    subLabel?: string | React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
  }>;
  onSelect: (selected: string | number) => void;
}> = ({ value, options, onSelect }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-8">
    {options.map((option, index) => (
      <div
        role="button"
        tabIndex={index}
        onClick={() => onSelect(option.value)}
        key={option.value}
        className="cursor-pointer"
      >
        <div className="hidden">
          <Radio selected={option.value === value} />
        </div>
        <div
          className={classNames(
            'option__container w-full h-full p-6 box-border border border-solid border-transparent rounded-lg hover:border-j-purple-600',
            {
              'border-j-purple-300': value !== option.value,
              'border-j-purple-600': value === option.value,
            },
          )}
        >
          <div className="flex justify-between pb-3 items-center">
            {option.icon && (
              <div className="w-8 h-8 rounded-lg bg-j-green-100 text-j-green-500 flex justify-center items-center">
                {option.icon}
              </div>
            )}
            <div
              className={`w-6 h-6 rounded-full flex justify-center items-center border border-solid ${
                value === option.value
                  ? 'border-j-purple-600 bg-j-purple-600'
                  : 'border-j-purple-300'
              }`}
            >
              {value === option.value && (
                <Icon.Check className="text-white" width="14" />
              )}
            </div>
          </div>

          <h2 className="font-medium text-xl leading-8 text-j-dark-600 m-0">
            {option.label}
          </h2>
          {option.subLabel && (
            <p className="text-base m-0 pt-2 leading-6 text-j-dark-500">
              {option.subLabel}
            </p>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default SingleSelect;
