import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { JuniLogo } from 'components/brand-assets';
import { ReactComponent as BGArtFooter } from './assets/guest_acc_bg_footer.svg';
import { ReactComponent as WaveBG } from './assets/guest_acc_bg_wave.svg';
import GuestSignupCard from './GuestSignupCard';

interface GuestSignupProps extends RouteComponentProps {
  loadUserData: () => void;
}

const GuestSignup: FC<GuestSignupProps> = props => (
  <div className="relative overflow-hidden min-h-screen w-full flex justify-center">
    <WaveBG className="absolute z-0" />
    <div className="absolute max-w-screen-lg w-full my-4">
      <a href="https://junilearning.com/">
        <JuniLogo className="ml-2 -mt-0.5" color="light" />
      </a>
    </div>
    <div className="relative z-10 max-w-screen-sm mx-8 mt-16 mb-40 w-full ">
      <GuestSignupCard
        history={props.history}
        match={props.match}
        loadUserData={props.loadUserData}
      />
    </div>
    <BGArtFooter className="absolute z-0 bottom-0" />
  </div>
);

export default GuestSignup;
