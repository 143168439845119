import React, { useContext } from 'react';
import { Button, Card } from 'core-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import UserContext from 'modules/UserContext';
import ClubsBanner from './assets/guest-header.jpg';

const TopRow = styled.div.attrs({
  className:
    'flex flex-col sm:flex-row justify-between h-1/2 flex-grow-0 pb-4 items-center',
})`
  color: #335875;
  border-bottom: 2px solid #d1eaf7;
`;

const MyClubsZeroState: React.FC = () => {
  const { user } = useContext(UserContext);
  const isGuest = user?.isGuest;
  return (
    <Card className="w-full" boxShadow borderWidth="0" padding="4">
      <div className="flex flex-col sm:flex-row">
        <div className="w-full h-32 pb-4 sm:pb-0 sm:w-2/6 mr-6">
          <img
            src={ClubsBanner}
            alt=""
            className="object-cover rounded-lg h-full w-full"
          />
        </div>
        <div className="flex flex-col">
          <TopRow>
            <h3 className="p-2 sm:p-2 m-0 mr-4 mb-2 sm:mb-0">
              {isGuest ? 'Join a Juni Club!' : 'Join or start a Juni Club!'}
            </h3>
            <Link to="my_clubs">
              <Button hasArrowIcon className="m-0 p-0">
                Go To Juni Clubs
              </Button>
            </Link>
          </TopRow>
          <div className="h-1/2 pt-4 p-2">
            {isGuest
              ? 'Join a Juni Club to discover and explore new passions with your friends!'
              : 'Join or start your own Juni Club to discover and explore new passions with your friends!'}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default MyClubsZeroState;
