import juniAxios from 'services/axios';

export const createMultipartUpload = async ({
  userType,
  studentId,
  teacherUserId,
  projectId,
  customProjectId,
}) =>
  // one of studentId or teacherUserId
  // one of projectId or customProjectId
  juniAxios
    .post(`/usermedia/${userType}/create_multipart_upload`, {
      studentId,
      teacherUserId,
      projectId,
      customProjectId,
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));
// returns { success, urls, uploadId, usermediaId }

export const completeMultipartUpload = async ({
  userType,
  studentId,
  usermediaId,
  uploadId,
  parts,
}) =>
  juniAxios
    .post(`/usermedia/${userType}/complete_multipart_upload`, {
      usermediaId,
      studentId,
      uploadId,
      parts,
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const deleteUsermedia = async ({ userType, studentId, usermediaId }) =>
  juniAxios
    .post(`/usermedia/${userType}/delete`, { studentId, usermediaId })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const updateUsermedia = async ({
  userType,
  studentId,
  usermediaId,
  title,
  description,
  originalLength,
}) =>
  juniAxios
    .post(`/usermedia/${userType}/update`, {
      studentId,
      usermediaId,
      title,
      description,
      originalLength,
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const getUserUsermedia = async ({ userType, studentId, teacherUserId }) => {
  let mappedUserType = userType;
  if (userType === 'student') {
    mappedUserType = 'learner';
  } else if (userType === 'teacher') {
    mappedUserType = 'instructor';
  }
  // one of studentId or teacherUserId
  // one of projectId or customProjectId
  return juniAxios
    .get(`/usermedia/${mappedUserType}/get_user`, {
      params: {
        studentId,
        teacherUserId,
      },
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));
  // returns
  // [{...UsermediaSchema, s3Url}]
};

export const getProjectUsermedia = async ({
  userType,
  studentId,
  teacherUserId,
  projectId,
  customProjectId,
}) => {
  let mappedUserType = userType;
  if (userType === 'student') {
    mappedUserType = 'learner';
  } else if (userType === 'teacher') {
    mappedUserType = 'instructor';
  }
  // one of studentId or teacherUserId
  // one of projectId or customProjectId
  return juniAxios
    .get(`/usermedia/${mappedUserType}/get_project`, {
      params: {
        studentId,
        teacherUserId,
        projectId,
        customProjectId,
      },
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));
  // returns
  // [{...UsermediaSchema, s3Url}]
};
