import styled from 'styled-components/macro';

const ProjectCardLayout = styled.div.attrs({
  className:
    'flex flex-col cursor-pointer rounded-xl p-4 transition ease-in-out duration-200 hover:transform hover:scale-101',
})`
  background-color: #fafdff;
  border: 2px solid #ace2ff;
`;

const HeaderLeft = styled.div`
  max-width: 85%;
  @media only screen and (max-width: 350px) {
    flex-direction: column;
  }
`;

const HeaderDate = styled.div.attrs({
  className: 'text-xs',
})`
  color: #a7c3df;

  @media only screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

const ProjectTitle = styled.div.attrs({
  className:
    'overflow-hidden overflow-ellipsis text-lg whitespace-nowrap leading-8 font-bold',
})`
  color: #335875;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const ProjectByLine = styled.div.attrs<any>({
  className:
    'text-sm overflow-hidden overflow-ellipsis whitespace-nowrap font-medium transition ease-in-out duration-200 hover:transform hover:scale-101',
})`
  color: #8197a3;

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const ProjectCreatorType = styled.div.attrs<any>({
  className: 'px-1 py-0 text-xs rounded-lg font-bold uppercase',
})`
  color: #00a3ff;
  border: 2px solid #ace2ff;
`;

const ProjectIcon = styled.div`
  max-width: 40px;
  min-width: 40px;

  & > img {
    max-width: 100%;
  }

  @media only screen and (min-width: 921px) {
    align-self: center;
  }
`;

const ProjectContentLayout = styled.div.attrs({
  className: 'p-4 mt-2 bg-white rounded-md height-full',
})``;

const ProjectImgContentContainer = styled(ProjectContentLayout)`
  > img {
    width: 100%;
    max-height: 275px;
    flex: 1;
    object-fit: contain;
    overflow: hidden;
    height: 225px;
  }
`;

const ProjectVidContentContainer = styled(ProjectContentLayout).attrs({
  className: 'flex justify-center items-center w-full relative',
})`
  div,
  video {
    width: 100%;
    height: 225px;
  }

  video {
    object-fit: cover;
    object-position: top left;
    height: 225px;
  }

  > svg {
    position: absolute;
  }
`;

export {
  HeaderDate,
  HeaderLeft,
  ProjectByLine,
  ProjectCardLayout,
  ProjectCreatorType,
  ProjectIcon,
  ProjectTitle,
  ProjectImgContentContainer,
  ProjectVidContentContainer,
};
