import styled from 'styled-components/macro';
import { CSSProperties } from 'react';

import { Card } from 'core-components';
import { XIcon } from 'components/Icons';

// To set the max-height of the ScrollableContent appropriately, we need to
// subtract the top padding and the bottom padding of the overlay from 100vh.
// We therefore define the overlay padding as a constant and use it to compute
// the ScrollableContent's max-height to make the effect of the padding clear.
const OVERLAY_PADDING = '2rem';

const X_ICON_SIZE = '12px';
const X_ICON_PADDING = '8px';
const BAR_PADDING = '8px';

const BAR_HEIGHT = `calc(
  ${X_ICON_SIZE} +
  ${X_ICON_PADDING} +
  ${X_ICON_PADDING} +
  ${BAR_PADDING} +
  ${BAR_PADDING}
)`;

// Browser support for the CSS max() function is relatively good by now,
// but we still have a number of customers who use older browsers that do
// not support CSS max(). So, whenever the banner height is used in a CSS
// rule, we actually compute two CSS rules: one that does not use max(),
// and one that does, in that order. Old browsers that do not support max()
// will ignore the second rule. New browsers that support max() will override
// the first rule with the second one. This allows us to use max() where it
// is supported (and prevent the banner from shrinking past the BAR_HEIGHT)
// but still have a good-looking banner in most cases on old browsers.

const BANNER_HEIGHT_PLACEHOLDER = 'BANNER_HEIGHT';

const getBannerHeightWithMax = (bannerHeight = '15vh') =>
  `max(${bannerHeight}, ${BAR_HEIGHT})`;

const generateCSSRulePairFromBannerHeight = (
  bannerHeight: string,
  ruleTemplate: string,
) =>
  [bannerHeight, getBannerHeightWithMax(bannerHeight)]
    .map(value => ruleTemplate.replace(BANNER_HEIGHT_PLACEHOLDER, value))
    .join('\n');

const ScrollableContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - ${OVERLAY_PADDING} - ${OVERLAY_PADDING});
`;

const ModalTitle = styled.h1`
  margin: 0;
  padding-right: 1.5rem;
  font-size: 1.5rem;
  color: #335875;
`;

const ModalLabel = styled.div`
  border-radius: 8px;
  margin-bottom: auto;
  padding: 0.25rem 0.5rem;
  border: 2px solid #cfeeff;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 600;
  color: #607d8b;
`;

const ModalDescription = styled.p`
  margin: 0;
  padding: 0.5rem 1rem 1.25rem;
  font-size: 1rem;
  color: #343d45;
  margin-top: -0.5rem;
`;

const CloseButton = styled.button`
  background: none;
  display: block;
  margin-left: auto;
  margin-bottom: auto;
  padding: ${X_ICON_PADDING};
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);

  path {
    stroke: #335875;
  }
  :focus,
  :hover {
    background-color: #d33838;
    box-shadow: none;
    path {
      stroke: #ffebeb;
    }
  }
`;

const CloseButtonIcon = styled(XIcon)`
  height: ${X_ICON_SIZE};
  width: ${X_ICON_SIZE};
  display: block;
  padding: 0;
`;

const Banner = styled.div<any>`
  background-color: #335875;
  position: relative;
  ${({ bannerHeight }) =>
    generateCSSRulePairFromBannerHeight(
      bannerHeight,
      `min-height: ${BANNER_HEIGHT_PLACEHOLDER};`,
    )}
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: top;
  z-index: 2;
`;

const FlexWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  ${({ hasBanner, bannerHeight }) =>
    hasBanner &&
    generateCSSRulePairFromBannerHeight(
      bannerHeight,
      `padding-top: calc(${BANNER_HEIGHT_PLACEHOLDER} + 2rem);`,
    )}
`;

const StickyColorBar = styled.div`
  background: ${props => props.color || '#00a5ff'};
  width: 100%;
  min-height: ${BAR_HEIGHT};
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: calc(-1 * ${BAR_HEIGHT} + ${BAR_PADDING});
  box-shadow: 0rem 1rem 1rem white;
`;

const StickyWrapperBase = styled.div`
  z-index: 3;
  position: sticky;
  top: 0;
`;

const StickyWrapperOverBanner = styled(StickyWrapperBase)<any>`
  ${({ bannerHeight }) =>
    generateCSSRulePairFromBannerHeight(
      bannerHeight,
      `margin-top: calc(-1 * ${BAR_HEIGHT} - ${BANNER_HEIGHT_PLACEHOLDER});`,
    )}
  padding: ${BAR_PADDING} ${BAR_PADDING} 0;
`;

const StickyWrapperWithTitle = styled(StickyWrapperBase)`
  display: flex;
  flex-direction: row-reverse;
  background: white;
  box-shadow: 0 0 0.5rem white;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem 0.5rem;
`;

const ModalCardBody = styled(Card)`
  *:not(${StickyWrapperWithTitle}) + & {
    margin-top: 0.25rem;
  }
  border-width: 1px 0 0;
  h2 {
    margin-top: 0;
    color: #335875;
    font-size: 1.25rem;
  }
`;

const REACT_MODAL_CONTENT_STYLES: CSSProperties = {
  border: '3px solid #d1eaf7',
  borderRadius: '12px',
  overflow: 'hidden',
  padding: '0',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  left: 'auto',
  position: 'static',
  maxWidth: '42rem',
};

const REACT_MODAL_OVERLAY_STYLES: CSSProperties = {
  backgroundColor: 'rgba(0, 53, 84, 0.71)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: OVERLAY_PADDING,
  zIndex: 1001,
};

export {
  ScrollableContent,
  ModalTitle,
  ModalLabel,
  ModalDescription,
  ModalCardBody,
  CloseButton,
  CloseButtonIcon,
  Banner,
  FlexWrapper,
  StickyColorBar,
  StickyWrapperOverBanner,
  StickyWrapperWithTitle,
  REACT_MODAL_CONTENT_STYLES,
  REACT_MODAL_OVERLAY_STYLES,
};
