import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { isEqual, pick, throttle } from 'lodash';

import {
  useLoadEditedRichTextByIdQuery,
  useUpdateEditedRichTextMutation,
} from 'generated/graphql';
import { ErrorableLoading } from 'components/ui';
import WritingPrompt from 'components/WritingPrompt';
import { Chevron } from 'components/Icons';
import SpinnerV2 from 'components/SpinnerV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import {
  Editor as DraftEditor,
  EditorState,
  Modifier,
  DraftHandleValue,
  DraftEditorCommand,
  EntityInstance,
  convertFromRaw,
  convertToRaw,
  SelectionState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DEFAULT_TIMEZONE } from 'constants/timezones';
import { Divider, Icon, Popover, Select, SelectOption } from 'core-components';
import {
  SyncIndicator,
  ToolSelectButton,
  ToolControlsInsetDivider,
  ColorButton,
  AnnotationPopoverEditor,
  AnnotationsBar,
  AnnotationsBarHeader,
} from 'components/jide';
import { HEIGHT as FOOTER_HEIGHT } from 'components/jide/Footer';

import { jDark, jPurple, jBlue, jGreen } from 'theme/colors';

import {
  doesSelectionOverlapHighlight,
  getAllEntitiesAndBlocks,
  findHighlightEntities,
  getSelectionText,
  getWordCount,
} from './utils';
import {
  ANNOTATION_WIDTH,
  ANNOTATIONS_MARGIN,
  DEFAULT_HIGHLIGHT_COLOR,
  NEW_HORIZONS_COLORS,
  RICH_TEXT_LINE_HEIGHT,
  INLINE_ANNOTATION_EDITOR_WIDTH,
  HIGHLIGHT_COLOR_VALUES,
  ANNOTATIONS_BAR_HEADER_HEIGHT,
} from './constants';
import {
  AnnotatedHighlight,
  Annotations,
  InlineAnnotationEditor,
  SaveStatus,
} from './components';

import {
  StyledRichTextAnnotator,
  StyledToolsColumn,
  StyledAnnotatorToolButtons,
  StyledToolButton,
  StyledModeSelectButton,
  StyledScrollableArea,
  StyledAnnotationsAndTextsContainer,
  StyledMainContentContainer,
  StyledPromptCard,
  StyledRichTextContainer,
  StyledEditorContainer,
  StyledErrorMessage,
  StyledAnnotationsContainer,
  StyledWritingSubmissionInput,
  StyledSaveStatusContainer,
  StyledWordCountContainer,
  StyledWordCount,
  WordCount,
} from './styles';

const SAVE_INTERVAL = 5 * 1000; // Every 5 seconds

type SaveStatus = 'saved' | 'edited' | 'saving' | '';

const DOMRectProps = ['top', 'right', 'bottom', 'left', 'width', 'height', 'x', 'y'];

const writingOptions = [
  'inline',
  'colorPicker',
  'blockType',
  'textAlign',
  'list',
  'history',
];
const inlineControlsOptions = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'superscript',
  'subscript',
];

interface InlineControlsProps {
  onChange: (option: string) => void;
  currentState: {
    bold: boolean;
    italic: boolean;
    monospace: boolean;
    strikethrough: boolean;
    subscript: boolean;
    superscript: boolean;
    underline: boolean;
  };
}

const InlineControls: React.FC<InlineControlsProps> = ({
  onChange,
  currentState,
}) => (
  <div className="flex items-center">
    <ToolSelectButton
      selected={currentState.bold}
      onClick={() => onChange('bold')}
      title="Bold"
    >
      <Icon.Bold />
    </ToolSelectButton>
    <ToolSelectButton
      selected={currentState.italic}
      onClick={() => onChange('italic')}
      className="ml-1"
      title="Italic"
    >
      <Icon.Italic />
    </ToolSelectButton>
    <ToolSelectButton
      selected={currentState.underline}
      onClick={() => onChange('underline')}
      className="ml-1"
      title="Underline"
    >
      <Icon.Underline />
    </ToolSelectButton>
    <ToolSelectButton
      selected={currentState.strikethrough}
      onClick={() => onChange('strikethrough')}
      className="ml-1"
      title="Strikethrough"
    >
      <Icon.Strikethrough />
    </ToolSelectButton>
    <ToolSelectButton
      selected={currentState.superscript}
      onClick={() => onChange('superscript')}
      className="ml-1"
      title="Superscript"
    >
      <Icon.Superscript />
    </ToolSelectButton>
    <ToolSelectButton
      selected={currentState.subscript}
      onClick={() => onChange('subscript')}
      className="ml-1"
      title="Subscript"
    >
      <Icon.Subscript />
    </ToolSelectButton>
  </div>
);

interface ColorPickerTabProps {
  selected: boolean;
  onClick: () => void;
}

const ColorPickerTab: React.FC<ColorPickerTabProps> = ({
  selected,
  onClick,
  children,
}) => (
  <div
    className={classNames(
      'flex justify-center items-center',
      'text-sm leading-6 font-medium',
      'py-2',
      'cursor-pointer',
      'rounded-md',
      {
        'bg-j-purple-100': selected,
      },
    )}
    onClick={onClick}
    style={{ width: 111 }}
    role="button"
    tabIndex={0}
  >
    {children}
  </div>
);

const colors = [
  jDark[600],
  jPurple[600],
  jBlue[400],
  '#6BE4FF',
  jGreen[500],
  '#E64FFF',
  '#EA4237',
  '#EE8040',
  '#F3D153',
  '#AAF667',
  '#6DD5B8',
  '#4F8AF7',
  '#8D34F0',
  '#EC6AC8',
  '#B4BAC0',
] as const;
type Color = typeof colors[number];

interface ColorPickerControlProps {
  onChange: (option: 'color' | 'bgcolor', value: Color) => void;
  currentState: {
    color?: Color;
    bgColor?: Color;
  };
}

const ColorPickerControl: React.FC<ColorPickerControlProps> = ({
  onChange,
  currentState,
}) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<'color' | 'bgColor'>('color');
  const currentTextColor = currentState.color || jDark[600];
  const currentBgColor: Color | undefined = currentState.bgColor;
  const currentModeColor = mode === 'color' ? currentTextColor : currentBgColor;

  const handleSelectColor = (color: Color) => {
    setOpen(false);

    // the underlying draft-js styleType is bgcolor, but react-draft-wysiwyg uses 'bgColor" :big-sigh:
    onChange(
      mode === 'bgColor' ? 'bgcolor' : mode,
      color !== currentModeColor ? color : '',
    );
  };

  return (
    <div className="flex items-center ml-1 relative">
      <ToolSelectButton
        selected={false}
        onClick={() => setOpen(true)}
        title="Color Picker"
      >
        <ColorButton color={currentTextColor} />
      </ToolSelectButton>
      {open && (
        <Popover
          className="absolute bottom-14 -left-1 flex items-center z-10"
          onClose={() => setOpen(false)}
        >
          <div>
            <div className="flex">
              <ColorPickerTab
                selected={mode === 'color'}
                onClick={() => setMode('color')}
              >
                Text
              </ColorPickerTab>
              <ColorPickerTab
                selected={mode === 'bgColor'}
                onClick={() => setMode('bgColor')}
              >
                Highlight
              </ColorPickerTab>
            </div>
            {/* TODO - change to Divider after 1725 gets merged */}
            <hr className="border-0 bg-j-purple-100 h-px -mx-2 my-2" />
            <div className="flex flex-wrap -m-1">
              {colors.map(c => (
                <ToolSelectButton
                  key={c}
                  selected={c === currentModeColor}
                  onClick={() => handleSelectColor(c)}
                  className="m-1"
                >
                  <ColorButton color={c} />
                </ToolSelectButton>
              ))}
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

const blockTypeOptions = [
  'Normal',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'Blockquote',
  'Code',
] as const;
type BlockTypes = typeof blockTypeOptions[number];

interface BlockTypeControlsProps {
  onChange: (option: string) => void;
  currentState: {
    blockType: BlockTypes;
  };
}

const BlockTypeControls: React.FC<BlockTypeControlsProps> = ({
  onChange,
  currentState,
}) => {
  const options: SelectOption<string>[] = blockTypeOptions.map(option => ({
    value: option,
    label: option,
  }));

  return (
    <div className="flex items-center ml-1 z-10">
      <Select
        options={options}
        selected={currentState.blockType}
        onChange={selected => onChange(selected)}
        dropdownPosition="top-left"
        size="xsmall"
        width="6.75rem"
      />
    </div>
  );
};

interface HistoryControlsControlsProps {
  onChange: (option: string) => void;
  currentState: {
    undoDisabled: boolean;
    redoDisabled: boolean;
  };
}

const HistoryControls: React.FC<HistoryControlsControlsProps> = ({
  onChange,
  currentState,
}) => (
  <div className="flex items-center">
    <ToolControlsInsetDivider />
    <ToolSelectButton
      disabled={currentState.undoDisabled}
      onClick={() => onChange('undo')}
      title="Undo"
    >
      <Icon.Undo />
    </ToolSelectButton>
    <ToolSelectButton
      disabled={currentState.redoDisabled}
      onClick={() => onChange('redo')}
      className="ml-1"
      title="Redo"
    >
      <Icon.Redo />
    </ToolSelectButton>
  </div>
);

type TextAlignment = 'left' | 'center' | 'right' | 'justify';
interface TextAlignControlsProps {
  onChange: (option: string) => void;
  currentState: {
    textAlignment?: TextAlignment;
  };
}

type IconType = typeof Icon.AlignLeft &
  typeof Icon.AlignCenter &
  typeof Icon.AlignRight &
  typeof Icon.AlignJustify;

const textAlignOptions: {
  [alignment in TextAlignment]: { icon: IconType; value: string };
} = {
  left: {
    icon: Icon.AlignLeft,
    value: 'left',
  },
  center: {
    icon: Icon.AlignCenter,
    value: 'center',
  },
  right: {
    icon: Icon.AlignRight,
    value: 'right',
  },
  justify: {
    icon: Icon.AlignJustify,
    value: 'justify',
  },
};

const TextAlignControls: React.FC<TextAlignControlsProps> = ({
  onChange,
  currentState,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const currentAlignment = currentState.textAlignment || 'left';
  const { icon: CurrentAlignmentIcon, value: currentValue } = textAlignOptions[
    currentAlignment
  ];
  const handleSelectTextAlign = (newAlignment: string) => {
    setShowPopover(false);
    if (newAlignment !== currentAlignment) {
      onChange(newAlignment);
    }
  };

  return (
    <div className="flex items-center relative">
      <ToolSelectButton
        onClick={() => setShowPopover(true)}
        className="ml-1"
        title={currentValue[0].toLocaleUpperCase() + currentValue.slice(1)}
      >
        <CurrentAlignmentIcon />
      </ToolSelectButton>
      {showPopover && (
        <Popover
          className="absolute bottom-11 -left-1 flex items-center z-10"
          onClose={() => setShowPopover(false)}
        >
          {Object.entries(textAlignOptions).map(
            ([textAlign, { icon: Icon, value }], index) => (
              <ToolSelectButton
                key={textAlign}
                selected={value === currentAlignment}
                onClick={() => handleSelectTextAlign(value)}
                className={classNames({ 'ml-2': index > 0 })}
                title={value[0].toLocaleUpperCase() + value.slice(1)}
              >
                <Icon />
              </ToolSelectButton>
            ),
          )}
        </Popover>
      )}
    </div>
  );
};

interface ListControlsControlsProps {
  onChange: (option: string) => void;
  currentState: {
    listType: string;
  };
}

const ListControls: React.FC<ListControlsControlsProps> = ({
  onChange,
  currentState,
}) => (
  <div className="flex items-center">
    <ToolControlsInsetDivider />
    <ToolSelectButton
      selected={currentState.listType === 'unordered'}
      onClick={() => onChange('unordered')}
      title="Unordered list"
    >
      <Icon.UnorderedList />
    </ToolSelectButton>
    <ToolSelectButton
      selected={currentState.listType === 'ordered'}
      onClick={() => onChange('ordered')}
      className="ml-1"
      title="Ordered list"
    >
      <Icon.OrderedList />
    </ToolSelectButton>
  </div>
);

interface SyncIndicatorProps {
  saveStatus: SaveStatus;
  onSave: React.ComponentProps<typeof SyncIndicator>['onClickSave'];
}

const SyncIndicatorWriting: React.FC<SyncIndicatorProps> = ({
  saveStatus,
  onSave,
}) => (
  <div className="flex flex-1 justify-end">
    <SyncIndicator
      connectionStatus="connected" // no concept of a persistent connection here
      saveStatus={saveStatus === 'edited' ? 'unsaved' : saveStatus || 'unsaved'}
      onClickSave={onSave}
    />
  </div>
);

interface ReadingControlsProps {
  isInHighlightMode: boolean;
  toggleHighlightMode: () => void;
  showAnnotations: boolean;
  toggleShowAnnotations: () => void;
  saveStatus: SaveStatus;
  onSave: React.ComponentProps<typeof SyncIndicator>['onClickSave'];
  readOnly?: boolean;
  renderJideWidgets?: () => React.ReactNode;
}

const ReadingControls: React.FC<ReadingControlsProps> = ({
  isInHighlightMode,
  toggleHighlightMode,
  showAnnotations,
  toggleShowAnnotations,
  saveStatus,
  onSave,
  readOnly = false,
  renderJideWidgets,
}) => (
  <div className="flex w-full">
    <div
      className={classNames('flex justify-between items-center', {
        'w-full': !renderJideWidgets,
      })}
    >
      <div className="flex">
        <ToolSelectButton
          selected={isInHighlightMode}
          onClick={toggleHighlightMode}
          title="Annotate"
          disabled={readOnly}
        >
          <Icon.PencilTool />
        </ToolSelectButton>
        <ToolSelectButton
          selected={showAnnotations}
          onClick={toggleShowAnnotations}
          className="ml-1"
          title="Comments"
        >
          <Icon.Comment />
        </ToolSelectButton>
      </div>
      <div className="flex items-center">
        {!!renderJideWidgets && <ToolControlsInsetDivider />}
        <SyncIndicator
          connectionStatus="connected" // no concept of a persistent connection here
          saveStatus={saveStatus === 'edited' ? 'unsaved' : saveStatus || 'unsaved'}
          onClickSave={onSave}
        />
      </div>
    </div>
    {!!renderJideWidgets && (
      <div className="flex flex-1 justify-end items-center">
        {renderJideWidgets()}
      </div>
    )}
  </div>
);

interface TabButtonProps {
  selected: boolean;
  onClick: () => void;
  first?: boolean;
  last?: boolean;
  children: string;
}

const TabButton: React.FC<TabButtonProps> = ({
  selected,
  onClick,
  first = false,
  last = false,
  children,
}) => (
  <div
    className={classNames(
      'flex items-center justify-center h-10 w-40',
      'font-graphik font-medium cursor-pointer box-border',
      selected ? 'text-j-purple-800' : 'text-j-dark-600',
      'border-2 border-solid border-j-purple-200',
      {
        'bg-j-purple-200': selected,
        'rounded-l-lg': first,
        'rounded-r-lg': last,
      },
    )}
    onClick={onClick}
    role="button"
    tabIndex={0}
    aria-label={children}
  >
    {children}
  </div>
);

interface Props {
  editedRichTextId?: string;
  /**
   * Reference text id for english projects, if one exists.
   */
  referenceTextId?: string;
  projectType: 'reading_project' | 'writing_project';
  writingPromptId?: string;
  onToggleWriteMode: (writeMode: boolean) => void;
  isBeingViewedByPublic: boolean;
  disableAnnotating?: boolean;
  readOnly?: boolean;
  newHorizons?: boolean;
  /**
   * if yes, tabs will exist to toggle between the reading and writing text editor.
   */
  showReadingWritingTabs?: boolean;
  /**
   * Used in conjunction with the above. TODO - this will replace "write mode" when we
   * unflag new horizons completely.
   */
  toggleReadingWritingTab?: (tab: 'reading' | 'writing') => void;
  /**
   * True to render the Editor hidden.
   * Default false.
   */
  hidden?: boolean;
  /**
   * Render prop function to optionally pass "Jide widget" buttons to render in the footer.
   */
  renderJideWidgets?: () => React.ReactNode;
  /**
   * When fullScreen, text is sized up.
   * Default false.
   */
  fullScreen?: boolean;
}

const RichTextAnnotator: React.FC<Props> = ({
  editedRichTextId,
  referenceTextId,
  projectType,
  writingPromptId,
  onToggleWriteMode,
  isBeingViewedByPublic,
  disableAnnotating = false,
  readOnly = false,
  newHorizons = false,
  showReadingWritingTabs,
  toggleReadingWritingTab,
  hidden = false,
  renderJideWidgets,
  fullScreen = false,
}) => {
  let editorRef: DraftEditor | undefined;
  const editorContainerRef = useRef<any>();
  const richTextContainerRef = useRef<HTMLDivElement>(null);
  const annotationsColumnRef = useRef<HTMLDivElement>(null);
  const [annotationEditorPositioning, setAnnotationEditorPositioning] = useState<
    React.CSSProperties
  >({});
  const [
    editorContainerDOMRect,
    setEditorContainerDOMRect,
  ] = useState<DOMRect | null>(null);
  const [richTextContainerHeight, setRichTextContainerHeight] = useState<
    number | undefined
  >();
  const [isInHighlightMode, setIsInHighlightMode] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(false);
  const [editorState, setEditorState] = useState<EditorState | undefined>(undefined);
  const [highlightRefs, setHighlightRefs] = useState<Record<string, any>>({});
  const [annotationEntityKeyToEdit, setAnnotationEntityKeyToEdit] = useState('');
  const isWritingProject = projectType === 'writing_project';
  const isWritingText = isWritingProject && editedRichTextId !== referenceTextId;
  const writingProjectNoInstructions = isWritingProject && !writingPromptId;
  const [isInWriteMode, setIsInWriteMode] = useState(
    writingProjectNoInstructions ||
      isBeingViewedByPublic ||
      (newHorizons && showReadingWritingTabs && isWritingText),
  );
  const [writingSubmissionTitle, setWritingSubmissionTitle] = useState('');
  const [saveStatus, setSaveStatus] = useState<SaveStatus>('');
  const [lastSavedAt, setLastSavedAt] = useState<number>(0);
  const [forceUpdateHighlights, setForceUpdateHighlights] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceResizeUpdate] = useState(0);

  const { error, loading, data } = useLoadEditedRichTextByIdQuery({
    variables: { input: editedRichTextId },
    skip: !editedRichTextId,
    fetchPolicy: 'no-cache',
  });
  const [updateEditedRichText] = useUpdateEditedRichTextMutation({
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (
      !editedRichTextId ||
      loading ||
      editedRichTextId !== data?.editedRichTextById?._id
    ) {
      setEditorState(undefined);
      setWritingSubmissionTitle('');
      setSaveStatus('');
      setLastSavedAt(0);
      return;
    }
    if (data?.editedRichTextById?._id) {
      const initialEditorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(data.editedRichTextById.content)),
      );
      setEditorState(initialEditorState);
      setWritingSubmissionTitle(data.editedRichTextById.title || '');
      setSaveStatus('saved');
      setLastSavedAt(moment(data.editedRichTextById.updatedAt).unix());
    }
  }, [data, editedRichTextId, loading]);

  const saveChanges = useCallback(async () => {
    if (!editedRichTextId || readOnly) return;
    if (saveStatus !== 'edited') return;
    setSaveStatus('saving');
    const updates: { content?: string; title?: string; id: string } = {
      id: editedRichTextId,
    };
    if (editorState) {
      const updatedContentStr = JSON.stringify(
        convertToRaw(editorState.getCurrentContent()),
      );
      updates.content = updatedContentStr;
    }
    if (isWritingProject && isInWriteMode) {
      updates.title = writingSubmissionTitle;
    }
    if (Object.keys(updates).length < 2) return;
    const updatedEditedRichText = await updateEditedRichText({
      variables: { input: updates },
    });
    const newLastSavedAt = updatedEditedRichText.data?.updateEditedRichText
      .editedRichText.updatedAt
      ? moment(
          updatedEditedRichText.data.updateEditedRichText.editedRichText.updatedAt,
        ).unix()
      : undefined;
    if (!newLastSavedAt || newLastSavedAt === lastSavedAt) {
      setSaveStatus('edited');
      return;
    }
    setLastSavedAt(newLastSavedAt);
    setSaveStatus('saved');
  }, [
    editedRichTextId,
    editorState,
    isInWriteMode,
    isWritingProject,
    lastSavedAt,
    saveStatus,
    updateEditedRichText,
    writingSubmissionTitle,
    readOnly,
  ]);

  // save on component dismount
  useEffect(
    () => () => {
      if (!editedRichTextId) return;
      if (saveStatus !== 'edited') return;
      const updates: { content?: string; title?: string; id: string } = {
        id: editedRichTextId,
      };
      if (editorState) {
        const updatedContentStr = JSON.stringify(
          convertToRaw(editorState.getCurrentContent()),
        );
        updates.content = updatedContentStr;
      }
      if (isWritingProject && isInWriteMode) {
        updates.title = writingSubmissionTitle;
      }
      if (Object.keys(updates).length < 2) return;
      updateEditedRichText({
        variables: { input: updates },
      });
    },
    [
      editedRichTextId,
      saveStatus,
      editorState,
      isWritingProject,
      isInWriteMode,
      writingSubmissionTitle,
      updateEditedRichText,
    ],
  );

  // save after 5 seconds of no edits, if save status is 'edited'
  useEffect(() => {
    let saveInterval: ReturnType<typeof setTimeout> | undefined;
    if (editedRichTextId) {
      saveInterval = setInterval(
        () => {
          saveChanges();
        },
        SAVE_INTERVAL,
        saveStatus,
      );
    } else if (saveInterval) clearInterval(saveInterval);
    return () => {
      if (saveInterval) clearInterval(saveInterval);
    };
  }, [
    editedRichTextId,
    editorState,
    writingSubmissionTitle,
    isWritingProject,
    isInWriteMode,
    lastSavedAt,
    saveStatus,
    saveChanges,
  ]);

  // NH doesn't have a Prompt / Write mode.
  // Set it immediately to write mode if no reference text exists.
  // (if a reference text exists, reading/writing tabs are used)
  useLayoutEffect(() => {
    if (
      newHorizons &&
      projectType === 'writing_project' &&
      !showReadingWritingTabs &&
      // as soon as we get a text for editing, check if a reference text also exists
      !!editedRichTextId &&
      !referenceTextId
    ) {
      setIsInWriteMode(true);
    }
  }, [
    newHorizons,
    projectType,
    showReadingWritingTabs,
    editedRichTextId,
    referenceTextId,
  ]);

  // a better way to do this would be to only measure after initial mount and re-size
  // ...good candidate for a generic hook!
  // we want this hook running after every render with this approach
  // actually the "correct" way to do this is the way the annotationPopoverEditorRef does it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (richTextContainerRef.current) {
      setRichTextContainerHeight(
        richTextContainerRef.current.getBoundingClientRect().height,
      );
    }
  });

  // same note as above.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!newHorizons || !editorContainerRef.current || hidden) return;

    const rect = editorContainerRef.current.getBoundingClientRect();

    if (!editorContainerDOMRect) {
      setEditorContainerDOMRect(rect);
      return;
    }

    // not sure why a plain isEqual doesn't work
    const rectsEqual = isEqual(
      pick(rect, DOMRectProps),
      pick(editorContainerDOMRect, DOMRectProps),
    );

    if (!rectsEqual) {
      setEditorContainerDOMRect(rect);
    }
  });

  // because NH depends a lot on positioning often set from static measurements,
  // need to re-render on window re-sizing.
  useEffect(() => {
    // force re-render
    const handleResize = () => forceResizeUpdate(prev => prev + 1);

    if (newHorizons) {
      window.addEventListener('resize', throttle(handleResize, 50));
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [newHorizons]);

  const showInlineAnnotationEditor =
    !!editorState &&
    !!annotationEntityKeyToEdit &&
    !!highlightRefs[annotationEntityKeyToEdit]?.current &&
    !!editorContainerRef?.current;

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const annotationPopoverEditorRef = useCallback(
    node => {
      if (showInlineAnnotationEditor && newHorizons && node !== null) {
        const highlight = highlightRefs[
          annotationEntityKeyToEdit
        ]?.current?.getBoundingClientRect();
        const container = editorContainerRef?.current?.getBoundingClientRect();
        const popover = node.getBoundingClientRect();

        if (highlight && container) {
          const positioning: React.CSSProperties = {};
          const spacing = 8;
          positioning.top =
            highlight.bottom -
            container.top +
            spacing +
            editorContainerRef?.current?.scrollTop;
          positioning.left = highlight.left - container.left;

          if (
            popover &&
            positioning.left + popover.width > container.width - spacing
          ) {
            // too far right, align it against the right edge, giving respect to spacing.
            positioning.left = undefined;
            positioning.right = spacing;
          }

          setAnnotationEditorPositioning(positioning);
        }
      }
    },
    [
      showInlineAnnotationEditor,
      newHorizons,
      annotationEntityKeyToEdit,
      highlightRefs,
    ],
  );

  if (editedRichTextId && !data) {
    return <ErrorableLoading error={error} />;
  }
  if (editedRichTextId && !data?.editedRichTextById?._id) {
    return <StyledErrorMessage>Failed to fetch project details</StyledErrorMessage>;
  }

  const { entities, blocks } = editorState
    ? getAllEntitiesAndBlocks(editorState)
    : { entities: [], blocks: [] };
  const seenEntityKeys: Record<string, boolean> = {};
  const dupEntityKeys: Record<string, boolean> = {};
  const annotationsData =
    entities
      .filter((entityData: { entityKey: string; entity: EntityInstance }) => {
        if (seenEntityKeys[entityData.entityKey]) {
          dupEntityKeys[entityData.entityKey] = true;
          return false;
        }
        seenEntityKeys[entityData.entityKey] = true;
        return (
          entityData.entity?.getType() === 'ANNOTATED_HIGHLIGHT' &&
          highlightRefs[entityData.entityKey]?.current
        );
      })
      .map((entityData: { entityKey: string; entity: EntityInstance }) => {
        const highlightRef = highlightRefs[entityData.entityKey];
        return {
          ...entityData.entity.getData(),
          entityKey: entityData.entityKey,
          highlightRef,
        };
      }) || [];
  const nonEmptyAnnotationsData = annotationsData.filter(
    data => data.annotation.length > 0,
  );
  const blockIndexByKey: Record<string, number> = blocks.reduce(
    (agg, cur, index) => ({
      ...agg,
      [cur.getKey()]: index,
    }),
    {},
  );

  const contentState = editorState?.getCurrentContent();
  const baseHighlightColor = newHorizons
    ? NEW_HORIZONS_COLORS[0]
    : DEFAULT_HIGHLIGHT_COLOR;
  let curAnnotationText = '';
  let curHighlightColor = baseHighlightColor;
  if (annotationEntityKeyToEdit) {
    const curAnnotationEntity = contentState?.getEntity(annotationEntityKeyToEdit);
    const curAnnotationData = curAnnotationEntity?.getData();
    curAnnotationText = curAnnotationData?.annotation || '';
    curHighlightColor = curAnnotationData?.highlightColor || baseHighlightColor;
  }

  const setHighlightRef = (entityId: string, ref: any) => {
    setHighlightRefs(highlightRefs => ({
      ...highlightRefs,
      [entityId]: ref,
    }));
  };

  const onClickAnnotation = (entityKey: string) => {
    setAnnotationEntityKeyToEdit(entityKey);
  };

  const highlightDecorator = {
    strategy: findHighlightEntities,
    component: AnnotatedHighlight,
    props: {
      unfocus: () => editorRef?.blur(),
      setHighlightRef,
      onClickAnnotation,
      update: forceUpdateHighlights,
    },
  };

  const toggleHighlightMode = () => {
    setIsInHighlightMode(on => !on);
  };
  const toggleShowAnnotations = () => {
    setShowAnnotations(show => !show);
  };

  const toggleWriteMode = async (on: boolean) => {
    if (on === isInWriteMode) return;
    await saveChanges();
    setShowAnnotations(false);
    setIsInHighlightMode(false);
    onToggleWriteMode(on);
    setIsInWriteMode(on);
  };
  const switchToWriteMode = () => {
    toggleWriteMode(true);
  };
  const switchToPromptMode = () => {
    toggleWriteMode(false);
  };
  const onChange = (changedEditorState: EditorState) => {
    const contentState = changedEditorState.getCurrentContent();
    const selectionState = changedEditorState.getSelection();
    const curStartOffset = selectionState.getStartOffset();
    const curEndOffset = selectionState.getEndOffset();
    const curStartKey = selectionState.getStartKey();
    const curEndKey = selectionState.getEndKey();

    let nextEditorState = changedEditorState;
    if (
      !(isWritingProject && isInWriteMode) &&
      isInHighlightMode &&
      curStartOffset !== curEndOffset &&
      selectionState.getHasFocus()
    ) {
      /* If in highlight mode and some text is selected, convert the selected text to an
      annotated highlight IF AND ONLY IF the current selection does not overlap an existing highlight */
      const selection = {
        startOffset: curStartOffset,
        startKey: curStartKey,
        endOffset: curEndOffset,
        endKey: curEndKey,
        startBlockIndex: blockIndexByKey[curStartKey],
        endBlockIndex: blockIndexByKey[curEndKey],
      };
      const overlaps = annotationsData.some(highlight =>
        doesSelectionOverlapHighlight(selection, highlight, blockIndexByKey),
      );
      if (overlaps) {
        alert(
          `Please make sure your highlighted text doesn't overlap with text that's already highlighted!`,
        );
        return;
      }

      const now = moment.tz(DEFAULT_TIMEZONE).format();
      const selectedText = getSelectionText(changedEditorState);
      const contentStateWithEntity = contentState.createEntity(
        'ANNOTATED_HIGHLIGHT',
        'MUTABLE',
        {
          annotation: '',
          highlightColor: baseHighlightColor,
          selectionState,
          selectedText,
          lastUpdatedAt: now,
          createdAt: now,
        },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithNewAnnotationEntity = Modifier.applyEntity(
        contentState,
        selectionState,
        entityKey,
      );
      if (selectionState) {
        const clearedSelection = selectionState.merge({
          anchorOffset: selectionState.getFocusOffset(),
        });
        nextEditorState = EditorState.acceptSelection(
          changedEditorState,
          clearedSelection,
        );
      }
      nextEditorState = EditorState.push(
        nextEditorState,
        contentStateWithNewAnnotationEntity,
        'apply-entity',
      );
      setAnnotationEntityKeyToEdit(entityKey);
      setSaveStatus('edited');
    }
    if (isWritingProject && isInWriteMode && editorState) {
      const curContentStr = JSON.stringify(
        convertToRaw(editorState.getCurrentContent()),
      );
      const nextContentStr = JSON.stringify(
        convertToRaw(nextEditorState.getCurrentContent()),
      );
      if (curContentStr !== nextContentStr) {
        setSaveStatus('edited');
      }
    }
    setEditorState(nextEditorState);
  };

  const handleWritingSubmissionTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const title = e.currentTarget.value;
    setWritingSubmissionTitle(title);
    setSaveStatus('edited');
  };

  const setEditorRef = (ref: DraftEditor) => {
    editorRef = ref;
  };
  const clearSelection = () => {
    if (!editorState) return;
    const curSelection = editorState?.getSelection();
    if (curSelection) {
      const clearedSelection = curSelection.merge({
        focusOffset: curSelection.getAnchorOffset(),
      });
      setEditorState((editorState?: EditorState) =>
        editorState
          ? EditorState.acceptSelection(editorState!, clearedSelection)
          : editorState,
      );
    }
  };
  const onBlurEditor = () => {
    clearSelection();
  };

  let annotationEditorTop = -1000;
  let annotationEditorLeft = -1000;
  let handleSubmitInlineAnnotation: (newData: {
    annotation?: string;
    highlightColor?: string;
  }) => void;
  let handleDeleteAnnotation: () => void;
  if (showInlineAnnotationEditor) {
    if (!newHorizons) {
      const highlightRef = highlightRefs[annotationEntityKeyToEdit];
      const boundingRectCurAnnotation = highlightRef?.current?.getBoundingClientRect();
      const boundingRectContainer = editorContainerRef?.current?.getBoundingClientRect();
      if (boundingRectCurAnnotation && boundingRectContainer) {
        annotationEditorTop =
          boundingRectCurAnnotation.top -
          boundingRectContainer.top +
          RICH_TEXT_LINE_HEIGHT;
        const relativeHighlightLeft =
          boundingRectCurAnnotation.left - boundingRectContainer.left;
        const editorWidth = boundingRectContainer.width;
        const tooFarRight =
          editorWidth - relativeHighlightLeft <
          INLINE_ANNOTATION_EDITOR_WIDTH + ANNOTATIONS_MARGIN;
        annotationEditorLeft = tooFarRight
          ? editorWidth - INLINE_ANNOTATION_EDITOR_WIDTH
          : boundingRectCurAnnotation.left - boundingRectContainer.left;
      }
    }
    handleSubmitInlineAnnotation = (data: {
      annotation?: string;
      highlightColor?: string;
    }) => {
      if (!editorState) return;
      const contentState = editorState.getCurrentContent();
      const newData = {
        ...data,
        lastUpdatedAt: moment.tz(DEFAULT_TIMEZONE).format(),
      };
      const contentStateWithNewAnnotationEntity = contentState.mergeEntityData(
        annotationEntityKeyToEdit,
        newData,
      );
      const nextEditorState = EditorState.push(
        editorState,
        contentStateWithNewAnnotationEntity,
        'change-block-data',
      );

      setEditorState(
        EditorState.forceSelection(nextEditorState, nextEditorState.getSelection()),
      );
      if (data.highlightColor) {
        setForceUpdateHighlights(count => count + 1);
      }
      setSaveStatus('edited');
    };
    handleDeleteAnnotation = () => {
      if (!editorState) return;
      const contentState = editorState.getCurrentContent();
      const entityToDelete = contentState.getEntity(annotationEntityKeyToEdit);
      const deleteData = entityToDelete.getData();
      const cachedDeleteSelection = deleteData.selectionState;
      let deleteSelection = cachedDeleteSelection;

      if (!cachedDeleteSelection.getStartKey) {
        const selection = SelectionState.createEmpty(
          cachedDeleteSelection.anchorKey,
        );
        deleteSelection = selection.merge({
          ...cachedDeleteSelection,
        });
      }

      const nextContentState = Modifier.applyEntity(
        contentState,
        deleteSelection,
        null,
      );
      const nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'apply-entity',
      );
      setEditorState(nextEditorState);
      setSaveStatus('edited');
    };
  }
  const closeInlineAnnotationEditor = () => {
    /* Clear the selection by moving the start point of the selection (anchorOffset)
      to the same position as the end point (focusOffset).
      We do this so that when a user clicks back into the editor to close the inline annotation
      editor, it doesn't select all text from the last selection end point to wherever they
      clicked into the editor. */
    clearSelection();
    setAnnotationEntityKeyToEdit('');
  };

  const annotateOnly = !(isWritingProject && isInWriteMode) || isBeingViewedByPublic;
  const showAnnotationsColumn = annotateOnly && editedRichTextId && showAnnotations;
  const wordCount = getWordCount(editorState);

  const handleKeyCommand: (
    command: DraftEditorCommand | string,
    editorState: EditorState,
    eventTimeStamp: number,
  ) => DraftHandleValue = (command: string) => {
    if (annotateOnly && command === 'backspace') return 'handled';
    return 'not-handled';
  };

  if (newHorizons) {
    let currentTab: 'reading' | 'writing' | undefined;
    if (showReadingWritingTabs) {
      currentTab = editedRichTextId === referenceTextId ? 'reading' : 'writing';
    }

    const handleToggleReadingWritingTab = async (tab: 'reading' | 'writing') => {
      if (!toggleReadingWritingTab || (!!currentTab && tab === currentTab)) return;

      // same behavior here as toggling write mode.
      await saveChanges();
      setShowAnnotations(false);
      setIsInHighlightMode(false);
      toggleReadingWritingTab(tab);
    };

    return (
      <div className={classNames('w-full min-h-full relative', { hidden })}>
        {showReadingWritingTabs && !!toggleReadingWritingTab && (
          <div
            className={classNames(
              'absolute top-0 left-0 right-0 h-16',
              'flex',
              'border-0 border-solid border-j-dark-100 border-b',
              'bg-white z-10',
            )}
          >
            {showAnnotationsColumn && (
              <AnnotationsBarHeader
                onClose={() => setShowAnnotations(false)}
                className="sticky top-0 left-0 right-0 z-10"
                style={{
                  width: ANNOTATION_WIDTH,
                  height: ANNOTATIONS_BAR_HEADER_HEIGHT + 1,
                }}
              />
            )}
            <div className="flex flex-1 items-center justify-center">
              <TabButton
                selected={currentTab === 'reading'}
                onClick={() => handleToggleReadingWritingTab('reading')}
                first
              >
                Reading
              </TabButton>
              <TabButton
                selected={currentTab === 'writing'}
                onClick={() => handleToggleReadingWritingTab('writing')}
                last
              >
                Writing
              </TabButton>
            </div>
          </div>
        )}
        <div
          className={classNames(
            'absolute inset-x-0 overflow-scroll',
            isWritingText ? 'bg-white' : 'bg-j-gray-100',
          )}
          style={{
            bottom: FOOTER_HEIGHT,
            top: showReadingWritingTabs && !!toggleReadingWritingTab ? 65 : 0,
          }}
          ref={editorContainerRef}
        >
          <div className="flex h-full">
            {showAnnotationsColumn && (
              <div
                ref={annotationsColumnRef}
                className="absolute top-0 left-0 bottom-0 z-10"
              >
                {(!showReadingWritingTabs || !toggleReadingWritingTab) && (
                  <AnnotationsBarHeader
                    onClose={() => setShowAnnotations(false)}
                    className="fixed left-0 right-0 z-10"
                    style={{
                      width: ANNOTATION_WIDTH,
                      height: ANNOTATIONS_BAR_HEADER_HEIGHT + 1,
                      top: annotationsColumnRef.current?.getBoundingClientRect().top,
                    }}
                  />
                )}
                <AnnotationsBar
                  annotationsData={nonEmptyAnnotationsData}
                  colorMap={HIGHLIGHT_COLOR_VALUES}
                  width={ANNOTATION_WIDTH}
                  height={richTextContainerHeight}
                  containerBottomOffset={FOOTER_HEIGHT}
                />
              </div>
            )}
            {editedRichTextId && (
              <div
                ref={richTextContainerRef}
                className={classNames(
                  'p-6 w-full flex flex-col text-j-dark-600 box-border',
                  isWritingText ? 'text-base leading-8' : 'text-sm leading-6',
                  isWritingText ? 'font-graphik' : 'font-serif',
                  { 'text-base': fullScreen },
                )}
                style={{
                  marginLeft: showAnnotationsColumn ? ANNOTATION_WIDTH : undefined,
                }}
              >
                {!!data?.editedRichTextById?.originalReferenceText?.title && (
                  <h1 className="text-xl leading-8 font-semibold">
                    {data?.editedRichTextById.originalReferenceText.title}
                  </h1>
                )}
                {!!data?.editedRichTextById?.originalReferenceText?.description && (
                  <div>
                    {data?.editedRichTextById.originalReferenceText.description}
                  </div>
                )}
                {isWritingProject && isInWriteMode && (
                  <>
                    <input
                      value={writingSubmissionTitle}
                      onChange={handleWritingSubmissionTitleChange}
                      placeholder="Title"
                      readOnly={isBeingViewedByPublic || readOnly}
                      className={classNames(
                        'w-full appearance-none border-none',
                        'text-2xl font-semibold text-j-dark-600 placeholder-j-dark-200',
                        { 'select-none': isBeingViewedByPublic || readOnly },
                      )}
                    />
                    <Divider className="mt-6" />
                  </>
                )}
                <Editor
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  handleKeyCommand={handleKeyCommand}
                  handleBeforeInput={() =>
                    annotateOnly ? 'handled' : 'not-handled'
                  }
                  handleReturn={() => (annotateOnly ? 'handled' : 'not-handled')}
                  editorState={editorState}
                  onEditorStateChange={
                    // This is a hacky fix to get around a bug where published or featured writing projects can be edited
                    // by dragging and dropping text onto them (react-draft-wysiwyg Editor's readonly prop is not properly respected).
                    // To get around this, on every change, we're re-setting the editor state from the current state instead of from
                    // the changed state.
                    isBeingViewedByPublic || readOnly
                      ? () => {
                          if (editorState) {
                            const unchangedNextEditorState = EditorState.createWithContent(
                              editorState.getCurrentContent(),
                            );
                            setEditorState(unchangedNextEditorState);
                          }
                        }
                      : onChange
                  }
                  editorRef={setEditorRef}
                  customDecorators={[highlightDecorator]}
                  handlePastedText={() => annotateOnly}
                  readonly={isBeingViewedByPublic || readOnly}
                  toolbar={{
                    options: isWritingText ? writingOptions : [],
                    inline: {
                      component: InlineControls,
                      options: inlineControlsOptions,
                    },
                    colorPicker: {
                      component: ColorPickerControl,
                      colors,
                    },
                    blockType: {
                      component: BlockTypeControls,
                      options: blockTypeOptions,
                    },
                    textAlign: {
                      component: TextAlignControls,
                      options: ['left', 'center', 'right', 'justify'],
                    },
                    list: {
                      component: ListControls,
                      options: ['unordered', 'ordered'],
                    },
                    history: {
                      component: HistoryControls,
                      options: ['undo', 'redo'],
                    },
                    link: {
                      defaultTargetOption: '_blank',
                    },
                  }}
                  toolbarCustomButtons={
                    !disableAnnotating
                      ? [
                          <ReadingControls
                            isInHighlightMode={isInHighlightMode}
                            toggleHighlightMode={toggleHighlightMode}
                            showAnnotations={showAnnotations}
                            toggleShowAnnotations={toggleShowAnnotations}
                            saveStatus={saveStatus}
                            onSave={saveChanges}
                            readOnly={readOnly}
                            renderJideWidgets={renderJideWidgets}
                          />,
                        ]
                      : [
                          <SyncIndicatorWriting
                            saveStatus={saveStatus}
                            onSave={saveChanges}
                          />,
                        ]
                  }
                  onBlur={onBlurEditor}
                  placeholder={
                    isWritingText && isInWriteMode
                      ? 'Start your writing project here...'
                      : undefined
                  }
                  // All the below is added with NH...
                  // use the scroll from the container element that includes reading title & description
                  editorClassName="overflow-visible"
                  wrapperClassName="h-full"
                  // Mostly copy+pasta from the jide/Footer component.
                  // Need to position the toolbar fixed at the bottom.
                  toolbarHidden={isBeingViewedByPublic}
                  toolbarClassName={classNames(
                    'flex items-center',
                    'fixed',
                    'm-0 p-0 px-6',
                    'box-border',
                    'border-0 border-t border-solid border-j-dark-100',
                    'z-10',
                    isWritingText ? 'bg-white' : 'bg-j-gray-100',
                  )}
                  toolbarStyle={{
                    width: editorContainerDOMRect ? editorContainerDOMRect.width : 0,
                    top: editorContainerDOMRect
                      ? editorContainerDOMRect.bottom
                      : -10000,
                    left: editorContainerDOMRect
                      ? editorContainerDOMRect.left
                      : -10000,
                    minHeight: FOOTER_HEIGHT,
                    maxHeight: FOOTER_HEIGHT,
                    paddingLeft: showAnnotationsColumn
                      ? `calc(${ANNOTATION_WIDTH}px + 1.5rem)`
                      : undefined,
                  }}
                />
                {showInlineAnnotationEditor && (
                  <AnnotationPopoverEditor
                    ref={annotationPopoverEditorRef}
                    positioning={annotationEditorPositioning}
                    onSubmit={handleSubmitInlineAnnotation!}
                    onClose={closeInlineAnnotationEditor}
                    initialValue={curAnnotationText}
                    highlightColor={curHighlightColor}
                    onDelete={handleDeleteAnnotation!}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {!isBeingViewedByPublic &&
          editedRichTextId &&
          isWritingProject &&
          isInWriteMode &&
          saveStatus && (
            <WordCount>Word Count: {wordCount.toLocaleString()}</WordCount>
          )}
      </div>
    );
  }

  return (
    <StyledRichTextAnnotator isWritingProject={isWritingProject}>
      {!isBeingViewedByPublic && (
        <StyledToolsColumn isWritingProject={isWritingProject}>
          {isWritingProject && (
            <div>
              <>
                {writingPromptId && (
                  <StyledModeSelectButton
                    onClick={switchToPromptMode}
                    isActive={!isInWriteMode}
                    isWritingProject
                  >
                    Prompt
                    <Chevron orientation="right" />
                  </StyledModeSelectButton>
                )}
              </>
              <StyledModeSelectButton
                onClick={switchToWriteMode}
                isActive={isInWriteMode}
                isWritingProject
              >
                Writing
                <Chevron orientation="right" />
              </StyledModeSelectButton>
            </div>
          )}
          {!disableAnnotating && (
            <StyledAnnotatorToolButtons>
              <StyledToolButton
                onClick={toggleHighlightMode}
                isActive={isInHighlightMode}
                isWritingProject={isWritingProject}
                disabled={readOnly}
              >
                <FontAwesomeIcon
                  icon={['fas' as IconPrefix, 'highlighter' as IconName]}
                />
              </StyledToolButton>
              <StyledToolButton
                onClick={toggleShowAnnotations}
                isActive={showAnnotations}
                isWritingProject={isWritingProject}
              >
                <FontAwesomeIcon
                  icon={['fa' as IconPrefix, 'comment' as IconName]}
                />
              </StyledToolButton>
            </StyledAnnotatorToolButtons>
          )}
          {editedRichTextId && saveStatus && !loading && (
            <StyledSaveStatusContainer isWritingProject={isWritingProject}>
              <SaveStatus
                status={saveStatus}
                lastSavedAt={lastSavedAt}
                narrowViewMode={!isWritingProject}
                handleManualSave={saveChanges}
              />
            </StyledSaveStatusContainer>
          )}
          {editedRichTextId && isInWriteMode && saveStatus && (
            <StyledWordCountContainer>
              <StyledWordCount>
                Word Count: {wordCount.toLocaleString()}
              </StyledWordCount>
            </StyledWordCountContainer>
          )}
        </StyledToolsColumn>
      )}
      <StyledScrollableArea>
        <StyledAnnotationsAndTextsContainer isWritingProject={isWritingProject}>
          <StyledAnnotationsContainer
            isExpanded={showAnnotationsColumn}
            isWritingProject={isWritingProject}
          >
            {showAnnotationsColumn && (
              <Annotations annotationsData={nonEmptyAnnotationsData} />
            )}
          </StyledAnnotationsContainer>
          <StyledMainContentContainer>
            {isWritingProject &&
              !isInWriteMode &&
              writingPromptId &&
              !isBeingViewedByPublic && (
                <div>
                  <StyledPromptCard>
                    <div className="prompt-card-header">
                      <h1>Let's write!</h1>
                    </div>
                    <div className="prompt-content-wrapper">
                      <WritingPrompt writingPromptId={writingPromptId} />
                    </div>
                  </StyledPromptCard>
                </div>
              )}
            {editedRichTextId && (
              <StyledRichTextContainer
                isWritingProject={isWritingProject}
                isInWriteMode={isWritingProject && isInWriteMode}
                isInAnnotationsMode={showAnnotations}
                isLoading={loading}
              >
                {loading ? (
                  <SpinnerV2 />
                ) : (
                  <>
                    {data?.editedRichTextById?.originalReferenceText?.title && (
                      <h1 className="content-title">
                        {data?.editedRichTextById.originalReferenceText.title}
                      </h1>
                    )}
                    {data?.editedRichTextById?.originalReferenceText
                      ?.description && (
                      <p className="content-description">
                        {data?.editedRichTextById.originalReferenceText.description}
                      </p>
                    )}
                    {isWritingProject && isInWriteMode && (
                      <StyledWritingSubmissionInput
                        placeholder="Title"
                        value={writingSubmissionTitle}
                        onChange={handleWritingSubmissionTitleChange}
                        readOnly={isBeingViewedByPublic || readOnly}
                      />
                    )}
                    <StyledEditorContainer ref={editorContainerRef}>
                      {showInlineAnnotationEditor && (
                        <InlineAnnotationEditor
                          top={annotationEditorTop}
                          left={annotationEditorLeft}
                          onSubmit={handleSubmitInlineAnnotation!}
                          close={closeInlineAnnotationEditor}
                          initialValue={curAnnotationText}
                          highlightColor={curHighlightColor}
                          onDelete={handleDeleteAnnotation!}
                        />
                      )}
                      <Editor
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        handleKeyCommand={handleKeyCommand}
                        handleBeforeInput={() =>
                          annotateOnly ? 'handled' : 'not-handled'
                        }
                        handleReturn={() =>
                          annotateOnly ? 'handled' : 'not-handled'
                        }
                        editorState={editorState}
                        onEditorStateChange={
                          // This is a hacky fix to get around a bug where published or featured writing projects can be edited
                          // by dragging and dropping text onto them (react-draft-wysiwyg Editor's readonly prop is not properly respected).
                          // To get around this, on every change, we're re-setting the editor state from the current state instead of from
                          // the changed state.
                          isBeingViewedByPublic || readOnly
                            ? () => {
                                if (editorState) {
                                  const unchangedNextEditorState = EditorState.createWithContent(
                                    editorState.getCurrentContent(),
                                  );
                                  setEditorState(unchangedNextEditorState);
                                }
                              }
                            : onChange
                        }
                        editorRef={setEditorRef}
                        customDecorators={[highlightDecorator]}
                        handlePastedText={() => annotateOnly}
                        toolbarHidden={annotateOnly}
                        readonly={isBeingViewedByPublic || readOnly}
                        toolbar={{
                          options: [
                            'inline',
                            'blockType',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'history',
                          ],
                          inline: {
                            options: [
                              'bold',
                              'italic',
                              'underline',
                              'strikethrough',
                              'superscript',
                              'subscript',
                            ],
                          },
                          list: {
                            options: ['unordered', 'ordered'],
                          },
                          link: {
                            defaultTargetOption: '_blank',
                          },
                        }}
                        onBlur={onBlurEditor}
                        placeholder={
                          isWritingProject && isInWriteMode
                            ? 'Start your writing project here...'
                            : undefined
                        }
                      />
                    </StyledEditorContainer>
                  </>
                )}
              </StyledRichTextContainer>
            )}
          </StyledMainContentContainer>
        </StyledAnnotationsAndTextsContainer>
      </StyledScrollableArea>
    </StyledRichTextAnnotator>
  );
};

export default RichTextAnnotator;
