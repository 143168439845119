import React from 'react';
import ReferralContent from 'app/learner/ReferralContent/ReferralContent';
import NavBar from './NavBar';
import MainContent from './MainContent';
import BubblesHero from './BubblesHero';
import JuniverseFooter from './JuniverseFooter';

type Statics = {
  NavBar: typeof NavBar;
  MainContent: typeof MainContent;
  BubblesHero: typeof BubblesHero;
  ReferralContent: typeof ReferralContent;
  JuniverseFooter: typeof JuniverseFooter;
};

const PublicPage: React.FC & Statics = ({ children }) => (
  <div className="flex flex-col min-h-screen">{children}</div>
);

PublicPage.NavBar = NavBar;
PublicPage.MainContent = MainContent;
PublicPage.BubblesHero = BubblesHero;
PublicPage.ReferralContent = ReferralContent;
PublicPage.JuniverseFooter = JuniverseFooter;

export default PublicPage;
