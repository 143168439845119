import React, { FC } from 'react';
import classNames from 'classnames';

import JuniIconCircle from './JuniIconCircle';
import JuniIconSquare from './JuniIconSquare';

interface JuniEmblemIconProps {
  /**
   * Determines size (both height and width) of emblem
   */
  size?: 'small' | 'medium';
  /**
   * Determines the shape of the icon which will be displayed (either square or circle)
   */
  shape?: 'circle' | 'square';
  /**
   * Custom styles via Tailwind classnames
   */
  className?: string;
}

const sizeStyles = {
  small: 'w-3',
  medium: 'w-7',
};

const paddingStyles = {
  small: 'p-0',
  medium: 'p-2',
};

const iconShapes = {
  circle: <JuniIconCircle />,
  square: <JuniIconSquare />,
};

const JuniEmblemIcon: FC<JuniEmblemIconProps> = ({
  size = 'medium',
  shape = 'circle',
  className,
}) => (
  <div
    className={classNames(
      sizeStyles[size],
      paddingStyles[size],
      'box-border',
      'h-full',
      className,
    )}
  >
    {iconShapes[shape]}
  </div>
);

export default JuniEmblemIcon;
