export enum TimeTurnerStage {
  SetAndPreviewAvailability = 'SET_AND_PREVIEW_AVAILABILITY',
  RespectAvailability = 'RESPECT_AVAILABILITY',
}

const TT_STAGE_ENV_VAR_TO_INFO: Record<
  string,
  { whitelistedInstructorUserIds?: string[]; enabledStage: TimeTurnerStage }
> = {
  TT_INTERMEDIARY_DEPLOY_WHITELISTED: {
    whitelistedInstructorUserIds: [
      '5c11480a2f12190e5c1a8fa8', // Julian
      '5f281b15ef29c0590a6e102a', // Eric
      '60a304a32260a80a25bb94f3', // June
      '61088d7f7d19975256a930df', // Tauseef
      '5d9be760b9c550300171abb4', // Blake Eaton
      '5f56f27f4b33ef1df7de5a12', // Kelsey Mrkonic
      '61951bab5997c057f29ff7a8', // Self-Guided Learning Juni Learning
      '5d4a264f0a370e3a591ac7d9', // Maxine Prestwich
      '5cd4a7d7da66240f1b84a577', // Adam Bilbaeno
    ],
    enabledStage: TimeTurnerStage.SetAndPreviewAvailability,
  },
  TT_INTERMEDIARY_DEPLOY: {
    enabledStage: TimeTurnerStage.SetAndPreviewAvailability,
  },
  TT_FULL_PHASE_ONE_DEPLOYED: {
    enabledStage: TimeTurnerStage.RespectAvailability,
  },
};

const TIME_TURNER_DEPLOY_STAGE =
  process.env.REACT_APP_FEATURE_TIME_TURNER_DEPLOY_STAGE;

export function isTimeTurnerStageEnabled(
  stage: TimeTurnerStage,
  userId = '',
): boolean {
  if (
    !TIME_TURNER_DEPLOY_STAGE ||
    !TT_STAGE_ENV_VAR_TO_INFO[TIME_TURNER_DEPLOY_STAGE]
  ) {
    return false;
  }

  const { whitelistedInstructorUserIds, enabledStage } = TT_STAGE_ENV_VAR_TO_INFO[
    TIME_TURNER_DEPLOY_STAGE
  ];

  if (enabledStage !== stage) return false;
  if (whitelistedInstructorUserIds && !whitelistedInstructorUserIds.includes(userId))
    return false;
  return true;
}
