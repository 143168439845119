import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { JuniLogo } from 'components/brand-assets';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import { ONBOARDING_PATHS } from 'constants/signup_sessions';
import classNames from 'classnames';
import { SessionFlags } from 'app/signup_session/types';

const SINGLE_SUBJECT_STEPS: string[] = [
  ONBOARDING_PATHS.schedulingPreferences,
  ONBOARDING_PATHS.studentInfo,
  ONBOARDING_PATHS.confirmation,
];

const MULTI_SUBJECT_STEPS: string[] = [
  ONBOARDING_PATHS.schedulingPreferences,
  ONBOARDING_PATHS.studentInfo,
  ONBOARDING_PATHS.coursePlacement,
  ONBOARDING_PATHS.confirmation,
];

const BANNER_TEXT: { [step: string]: string } = {
  [ONBOARDING_PATHS.schedulingPreferences]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.studentInfo]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.coursePlacement]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.confirmation]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.createAccount]: 'WELCOME TO YOUR JUNI ACCOUNT',
};

const BANNER_TEXT_EXPERIMENTAL: { [step: string]: string } = {
  [ONBOARDING_PATHS.schedulingPreferences]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.studentInfo]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.coursePlacement]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.confirmation]: 'SCHEDULE YOUR CLASS',
  [ONBOARDING_PATHS.createAccount]: 'ACCOUNT SETUP',
};

const OnboardingLayout: FC<{
  activeStep?: string;
  flags: SessionFlags;
}> = ({ activeStep, children, flags }) => {
  const steps = flags.multiSubject
    ? MULTI_SUBJECT_STEPS
    : !flags.isBootcampSignup
    ? SINGLE_SUBJECT_STEPS
    : [];
  const { activeStudentId } = useSignupContext();
  const stepNumber = (activeStep ? steps.indexOf(activeStep) : -1) + 1; // needs to be 1-indexed to display
  const hideNav =
    flags.isBootcampSignup && activeStep === ONBOARDING_PATHS.confirmation;

  const navClassNames = classNames(
    'flex bg-white text-j-dark-600 items-center justify-between h-18 px-6 xl:px-18 z-20',
  );

  const textStyles = 'text-xs font-medium';

  return (
    <>
      {!hideNav && (
        <nav className={navClassNames}>
          <NavLink
            to={`/learner/${activeStudentId}/home`}
            className="hidden sm:block"
          >
            <JuniLogo color="dark" />
          </NavLink>
          {activeStep && (
            <div
              className={classNames('text-center', textStyles, {
                'w-full sm:w-auto': stepNumber === 0,
              })}
            >
              <p className="uppercase">
                {flags.isInDcfExperimentTreatmentGroup
                  ? BANNER_TEXT_EXPERIMENTAL[activeStep]
                  : BANNER_TEXT[activeStep]}
              </p>
            </div>
          )}
          <div
            className={classNames(textStyles, 'min-w-18', {
              'hidden sm:block invisible': stepNumber === 0,
            })}
          >
            {`0${stepNumber}`}{' '}
            <span className="text-j-dark-300">/ {`0${steps.length}`}</span>
          </div>
        </nav>
      )}
      <div className="pt-6 pb-12">{children}</div>
    </>
  );
};

export default OnboardingLayout;
