import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import queryString from 'query-string';

import { Dropdown, Icon, NewButton, PopoverDropdown } from 'core-components';
// import useDimensions from 'hooks/dimensions';
import { ShareMethod } from 'constants/analytics_events';
import {
  shareToFacebook,
  shareToTwitter,
  logShareEvent,
} from 'utils/social_share/social_share';
// /** Tailwind small breakpoint */
// const MOBILE_WIDTH = 640;

interface SocialShareButtonProps
  extends Pick<
    React.ComponentProps<typeof NewButton>,
    'variant' | 'size' | 'fullWidth'
  > {
  /**
   * Url used to share with social sharing options.
   */
  url: string;
  /**
   * Copy used to share with social sharing options.
   * Eg Twitter:tweet Facebook: quote
   */
  socialShareCopy: string;
  /**
   * Optional mailTo email address.
   */
  emailAddress?: string;
  /**
   * Optional mailTo email subject line.
   */
  emailSubject?: string;
  /**
   * Optional mailTo email body content.
   */
  emailBody?: string;
  /**
   * Position of the drop-down relative to the Select.
   * Default bottom-left.
   */
  dropdownPosition?: React.ComponentProps<
    typeof PopoverDropdown
  >['dropdownPosition'];
  /**
   * Specifies the content that is being shared
   */
  shareContent: string;
  /**
   * The button's text.
   * Default is "Share".
   */
  children?: string;
}

const SocialShareButton: React.FC<SocialShareButtonProps> = ({
  url,
  socialShareCopy,
  emailAddress,
  emailSubject,
  emailBody,
  dropdownPosition = 'bottom-right',
  shareContent,
  variant = 'minimal',
  size = 'small',
  fullWidth = false,
  children = 'Share',
}) => {
  const [shareDropdownOpen, setShareDropdownOpen] = useState(false);

  const closeShareDropdown = () => setShareDropdownOpen(false);
  const handleEmailShare = () => {
    // To address is optional and works fine omitted.
    let mailToLink = `mailto:${encodeURIComponent(emailAddress || '')}`;

    const params: Record<string, string> = {};

    if (emailSubject) {
      params.subject = emailSubject;
    }
    if (emailBody) {
      params.body = emailBody;
    }

    if (Object.entries(params).length) {
      mailToLink = `${mailToLink}?${queryString.stringify(params)}`;
    }

    window.open(mailToLink);
    logShareEvent(ShareMethod.email, emailBody);
    closeShareDropdown();
  };

  const handleTwitterShare = () => {
    shareToTwitter({ url, shareContent, socialShareCopy });
    closeShareDropdown();
  };
  const handleFacebookShare = () => {
    shareToFacebook({ url, shareContent, socialShareCopy });
    closeShareDropdown();
  };

  // this doesn't work.  The url to share doesn't show up.
  // const handleWhatsAppShare = () => {
  //   logShareEvent(ShareMethod.whatsApp);
  //   openSocialShareWindow(
  //     `https://wa.me/?text=${encodeURIComponent(
  //       socialShareCopy,
  //     )}&url=${encodeURIComponent(appendUtmSourceParam(url, 'whatsapp'))}`,
  //   );
  // };

  const handleCopyLink = () => {
    logShareEvent(ShareMethod.linkCopied, shareContent);
    closeShareDropdown();
  };

  return (
    <PopoverDropdown
      open={shareDropdownOpen}
      onCloseDropdown={() => setShareDropdownOpen(false)}
      dropdownPosition={dropdownPosition}
      fullWidth={fullWidth}
      renderDropdownChildren={() => (
        <>
          <div className="max-w-xs px-3 py-2 text-base text-j-dark-200 whitespace-nowrap overflow-hidden overflow-ellipsis">
            {url}
          </div>
          <CopyToClipboard text={url}>
            <Dropdown.Option
              value="copy"
              iconLeft={Icon.Copy}
              onClick={handleCopyLink}
              selectable={false}
            >
              Copy link
            </Dropdown.Option>
          </CopyToClipboard>
          <Dropdown.Option
            value="email"
            iconLeft={Icon.Email}
            onClick={handleEmailShare}
            selectable={false}
          >
            Email link
          </Dropdown.Option>
          <Dropdown.Option
            value="twitter"
            iconLeft={Icon.TwitterOutline}
            onClick={handleTwitterShare}
            selectable={false}
          >
            Share on Twitter
          </Dropdown.Option>
          <Dropdown.Option
            value="facebook"
            iconLeft={Icon.FacebookOutline}
            onClick={handleFacebookShare}
            selectable={false}
          >
            Share on Facebook
          </Dropdown.Option>
          {/* {width <= MOBILE_WIDTH && (
            <Dropdown.Option
              value="whatsApp"
              iconLeft={Icon.WhatsApp}
              onClick={handleWhatsAppShare}
            >
              Share on WhatsApp
            </Dropdown.Option>
          )} */}
        </>
      )}
    >
      {/* Button has a complicated story with refs, just wrap with a div for now */}
      <div>
        <NewButton
          variant={variant}
          size={size}
          fullWidth={fullWidth}
          renderIconLeft={props => <Icon.ArrowUpRight {...props} />}
          onClick={() => setShareDropdownOpen(prev => !prev)}
        >
          {children}
        </NewButton>
      </div>
    </PopoverDropdown>
  );
};

export default SocialShareButton;
