import React, { FC, MouseEvent } from 'react';
import classNames from 'classnames';

export interface RadioLabelProps {
  value: string;
  disabled?: boolean;
}

export const RadioLabel: FC<RadioLabelProps> = ({ value, children, disabled }) => {
  const labelStyles = classNames('leading-5 text-sm pl-3', {
    'text-j-dark-600': !disabled,
    'text-j-dark-300': disabled,
  });
  return (
    <label htmlFor={value} className={labelStyles}>
      {children}
    </label>
  );
};

export interface RadioButtonProps<T> {
  isChecked: boolean;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value: T;
  name: string;
}

export const RadioButton = <T extends unknown>({
  isChecked,
  onClick,
  disabled,
  value,
  name,
}: RadioButtonProps<T>) => {
  const containerStyles = classNames(
    'inline-flex relative rounded-full transition-all border-2 border-solid border-transparent focus-within:border-j-dark-200',
    {
      'hover:border-j-dark-200': !disabled,
    },
  );

  const radioStyles = classNames(
    'appearance-none w-4 h-4 border border-solid border-transparent box-content rounded-full m-0 transition-all',
    {
      'cursor-pointer': !disabled,
      'border-j-purple-600 ring-4 ring-inset ring-j-purple-600 bg-white':
        isChecked && !disabled,
      'border-j-purple-300 hover:border-j-purple-400 focus:border-j-purple-400 bg-j-gray-100':
        !isChecked && !disabled,
      'bg-j-gray-200': disabled && !isChecked,
      'border-j-gray-200 ring-4 ring-inset ring-j-gray-200 bg-j-dark-200':
        disabled && isChecked,
    },
  );

  return (
    <div className={containerStyles}>
      <input
        type="radio"
        id={String(value)}
        value={String(value)}
        checked={isChecked}
        disabled={disabled}
        className={radioStyles}
        onClick={onClick}
        name={name}
      />
    </div>
  );
};

export interface RadioProps<T> {
  isChecked: boolean;
  value: T;
  label: string;
  name: string;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Radio = <T extends unknown>({
  isChecked,
  onClick,
  disabled,
  label,
  value,
  name,
}: RadioProps<T>) => (
  <div className="flex flex-row items-center">
    <RadioButton
      disabled={disabled}
      isChecked={isChecked}
      onClick={onClick}
      value={value}
      name={name}
    />
    {label && (
      <RadioLabel value={String(value)} disabled={disabled}>
        {label}
      </RadioLabel>
    )}
  </div>
);

export default Radio;
