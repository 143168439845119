import React, { useState } from 'react';
import Input, { InputProps } from './Input';
import Dropdown from '../Dropdown';
import PopoverDropdown from '../PopoverDropdown';

export interface Option<T> {
  value: T;
  // TODO - iconLeft: (iconRight is already taken up by the selected check mark)
  label: string;
  disabled?: boolean;
}

export interface SelectProps<T> extends Omit<InputProps, 'onChange'> {
  /**
   * All options.
   */
  options: Option<T>[];
  /**
   * The currently selected option, if any.
   */
  selected?: T;
  /**
   * Called when the selected value changes.
   */
  onChange: (item: T) => void;
  /**
   * Position of the drop-down relative to the Select.
   * Default bottom-left.
   */
  dropdownPosition?: React.ComponentProps<
    typeof PopoverDropdown
  >['dropdownPosition'];
  /**
   * Used when no option is selected.  Use like an input placeholder.
   */
  placeholder?: string;
  /**
   * Optionally pass through inline styles to the Input element.
   * This should be used very sparingly.
   */
  width?: React.CSSProperties['width'];
}

/**
 * Functionally a Select, but an input is used to maintain consistent styling.
 */
const Select = <T extends unknown>({
  options,
  selected,
  onChange,
  dropdownPosition = 'bottom-left',
  placeholder,
  width,
  ...inputProps
}: SelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const handleChange = (item: T) => {
    setOpen(false);
    onChange(item);
  };

  return (
    <PopoverDropdown
      open={open}
      onCloseDropdown={() => setOpen(false)}
      dropdownPosition={dropdownPosition}
      renderDropdownChildren={() => (
        <>
          {options.map(o => (
            <Dropdown.Option
              key={String(o.value)}
              value={o.value}
              selected={o.value === selected}
              onClick={() => handleChange(o.value)}
              disabled={o.disabled}
            >
              {o.label}
            </Dropdown.Option>
          ))}
        </>
      )}
    >
      <Input
        {...inputProps}
        value={options.find(option => option.value === selected)?.label || ''}
        onClick={() => setOpen(prev => !prev)}
        placeholder={placeholder}
        select
        selectDropdownIsOpen={open}
        style={{ width: width !== undefined ? width : undefined }}
        readOnly
      />
    </PopoverDropdown>
  );
};

export default Select;
