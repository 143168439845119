import { MouseOrTouchEvent } from 'react-select/src/Select';
import _ from 'lodash';
import {
  getCursorPos,
  initializeNewText,
  getEventTarget,
  isDifferentTarget,
  isValidTextTarget,
  getTextInputFromEventTarget,
} from './text_tool_util';
import { setActiveClass } from './tool_util';

interface TextTool {
  board: any;
  name: string;
  eventHandlers: Record<
    string,
    (e: MouseOrTouchEvent, x: number, y: number) => void
  >;
  customCursor: string;
  oldState: UpdateSVGTextData | undefined;
  input: HTMLDivElement | undefined;
  inEditMode: boolean;
  color: string;
  foreignObject: SVGForeignObjectElement;
  lastSendTime: number;
  lastSentState: {
    text: string;
    x: number;
    y: number;
    width: number;
    height: number;
  };
  x: number;
  y: number;
  timeout: number | undefined;
  cursorOffsetFromForeignObject: { x: number; y: number };
}

interface WSMessage {
  id: string;
  type: string;
  text: string;
}

interface UpdateSVGTextData {
  text?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

interface BroadcastData {
  type: string;
  id: string;
  tool: string;
  text?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

const RULES = {
  DEFAULT_WIDTH: 200,
  DEFAULT_HEIGHT: 100,
  MIN_WIDTH: 100,
  MIN_HEIGHT: 100,
  THROTTLE_MS: 60,
  TIMEOUT_MS: 400,
  // To account for padding and border, the foreignObject's x/y
  // should be offset from where the user actually clicks. With
  // these constants, the typing cursor will be right where the
  // user clicked.
  NEW_TEXT_OFFSET_X: 36,
  NEW_TEXT_OFFSET_Y: 48,
  RESIZE_DISTANCE_FROM_CANVAS: 20,
  RESIZE_HANDLE_SIZE: 20,
};

class TextTool {
  constructor(board: any) {
    this.board = board;
    this.name = 'text';
    this.eventHandlers = {
      mousedown: this.initTextEdit,
      touchstart: this.initTextEdit,
    };
    this.customCursor = 'text';
    this.oldState = undefined;

    // reference to the contenteditable div
    this.input = undefined;
    this.inEditMode = false;

    this.color = this.board.color || 'black';

    // the outermost container of a text input
    this.foreignObject = (document.createElement(
      'foreignObject',
    ) as unknown) as SVGForeignObjectElement;
    // Q: why this weird id?
    // https://github.com/JuniLearning/juni-app-frontend/pull/716#discussion_r562244934
    this.foreignObject.id = 'FAKE PLACEHOLDER VALUE';
    this.lastSendTime = 0;
    this.lastSentState = { text: '', x: 0, y: 0, width: 0, height: 0 };
    this.timeout = undefined;

    this.cursorOffsetFromForeignObject = { x: 0, y: 0 };
  }

  handleWsMsg = (data: WSMessage) => {
    if (data.type === 'text') {
      this.newSVGText(data, true);
      if (data.text) {
        this.editExistingText(data.id, data, true);
      }
    } else if (data.type === 'update_text') {
      this.editExistingText(data.id, data, true);
    }
  };

  initTextEdit = (e: MouseOrTouchEvent, x: number, y: number) => {
    const eventTarget = getEventTarget(e);
    if (!eventTarget) return;

    const isDiffTarget = isDifferentTarget(this?.input, eventTarget);

    if (!this.input || !this.inEditMode || isDiffTarget) {
      e.preventDefault();

      let targetText;
      const isValidTarget = isValidTextTarget(eventTarget.id);
      if (isValidTarget) {
        if (isDiffTarget) {
          // exit edit mode on text input that is being switched away from
          this.exitEditMode();
          this.handleTextInputBlur(undefined, true);
        }

        targetText = getTextInputFromEventTarget(eventTarget);
        this.input = targetText;
      }
      if (this.inEditMode && !isValidTarget) {
        this.handleClickOutsideTextInput();
        return;
      }

      if (targetText) {
        // edit existing text
        const foreignObject = targetText.parentElement?.parentElement
          ?.parentElement as SVGForeignObjectElement | undefined;
        if (!foreignObject) return;

        this.foreignObject = foreignObject;
        this.color = targetText.style.color || 'black';
        this.oldState = {
          text: targetText.innerText,
          x: foreignObject.x.baseVal.value,
          y: foreignObject.y.baseVal.value,
          width: foreignObject.width.baseVal.value,
          height: foreignObject.height.baseVal.value,
        };

        // make this foreignObject the last of its sibling foreignObjects
        // to avoid z-fighting when grabbing a handle
        this.board.appendChild(foreignObject);

        this.initEditMode();
      } else {
        // create new text element
        this.color = this.board.color;
        const { foreignObject, text } = this.newSVGText({
          id: this.board.generateUniqueID('text'),
          x: x - RULES.NEW_TEXT_OFFSET_X,
          y: y - RULES.NEW_TEXT_OFFSET_Y,
          width: RULES.DEFAULT_WIDTH,
          height: RULES.DEFAULT_HEIGHT,
          color: this.color,
        });
        this.foreignObject = foreignObject;
        this.input = text;
        this.initEditMode();
      }
    }
  };

  handleClickOutsideTextInput = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.exitEditMode();
    this.handleTextInputBlur();
  };

  initEditMode = () => {
    this.inEditMode = true;
    this.board.triggerHtmlRender();
    if (this.input) {
      this.input.focus();

      // force cursor to end of text. Needed when initial click target is
      // not the contenteditable, but the container around it.
      document.execCommand('selectAll', false, undefined);
      document.getSelection()?.collapseToEnd();

      this.input.addEventListener('keyup', this.handleTextInputChange);
      this.foreignObject.addEventListener('blur', this.handleTextInputBlur);
      setActiveClass(this.foreignObject.id, true);
    }
  };

  exitEditMode = () => {
    this.inEditMode = false;
    this.board.triggerHtmlRender();
    if (this.input) {
      this.input.removeEventListener('keyup', this.handleTextInputChange);
      this.foreignObject.removeEventListener('blur', this.handleTextInputBlur);
      setActiveClass(this.foreignObject.id, false);

      // unselect any selected text
      window?.getSelection()?.removeAllRanges();
    }
  };

  getNewState = (patch: Record<string, unknown>) => ({
    text: this.input?.innerText || '',
    x: this.foreignObject.x.baseVal.value,
    y: this.foreignObject.y.baseVal.value,
    width: this.foreignObject.width.baseVal.value,
    height: this.foreignObject.height.baseVal.value,
    ...patch,
  });

  handleTextInputBlur = (e?: any, preventAdd?: boolean) => {
    // prevent blurring when clicking on text after clicking on a handle
    // otherwise there will be a premature blur and undo/redo will break
    if (e && e.currentTarget.contains(e.relatedTarget)) {
      return;
    }

    if (
      !this.input ||
      !this.foreignObject ||
      !this.board.canvas.contains(this.foreignObject)
    )
      return;

    const { foreignObject } = this;
    const innerText = this.input.innerText || '';
    const isInputEmpty = !innerText || innerText.trim().length === 0;
    const newState = this.getNewState({ text: innerText });

    if (isInputEmpty) {
      if (foreignObject) {
        this.board.canvas.removeChild(foreignObject);
        this.board.broadcast({
          type: 'remove',
          id: foreignObject.id,
          tool: 'eraser',
        });
      }
      if (this.oldState) {
        this.input.innerText = this.oldState.text || '';
        this.board.appendToEditHistory({
          type: 'remove',
          elem: foreignObject,
        });
        this.oldState = undefined;
      }
    } else {
      if (!_.isEqual(newState, this.lastSentState)) {
        this.editExistingText(foreignObject.id, newState);
        this.lastSendTime = performance.now();
        this.lastSentState = newState;
      }

      if (this.oldState) {
        if (!_.isEqual(newState, this.oldState)) {
          this.board.appendToEditHistory({
            type: 'edit_text',
            elem: foreignObject,
            oldState: this.oldState,
            newState,
          });
        }
        this.oldState = undefined;
      } else if (!preventAdd) {
        this.board.appendToEditHistory({
          type: 'add',
          elem: foreignObject,
        });
      }
    }
    this.input.blur();
  };

  handleTextInputChange = (e: KeyboardEvent) => {
    const innerText = this?.input?.innerText ? this.input.innerText : '';
    if (e.key === 'Escape') {
      this?.input?.blur();
      this.exitEditMode();
      this.handleTextInputBlur();
    } else {
      const msSinceLastSend = performance.now() - this.lastSendTime;
      if (msSinceLastSend < RULES.THROTTLE_MS) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.handleTextInputChange, RULES.TIMEOUT_MS, e);
      } else if (innerText !== this.lastSentState.text) {
        this.lastSentState.text = innerText;
        this.lastSendTime = performance.now();
        const newState = this.getNewState({ text: innerText });
        this.editExistingText(this.foreignObject.id, newState);
      }
    }
  };

  handleTextInputResize = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    const cursorPos = getCursorPos(e, this.board.canvas);

    const { foreignObject } = this;
    if (foreignObject) {
      let cursorX = Math.max(cursorPos.x, 0);
      let cursorY = Math.max(cursorPos.y, 0);
      const canvasRect = this.board.canvas.getBoundingClientRect();
      // prevent cursor from going too far to the right or bottom
      cursorX = Math.min(
        cursorX,
        canvasRect.width - RULES.RESIZE_DISTANCE_FROM_CANVAS,
      );
      cursorY = Math.min(
        cursorY,
        canvasRect.height - RULES.RESIZE_DISTANCE_FROM_CANVAS,
      );

      const unboundedWidth =
        cursorX + RULES.RESIZE_HANDLE_SIZE - foreignObject.x.baseVal.value;
      const unboundedHeight =
        cursorY + RULES.RESIZE_HANDLE_SIZE - foreignObject.y.baseVal.value;

      const width = Math.max(unboundedWidth, RULES.MIN_WIDTH);
      const height = Math.max(unboundedHeight, RULES.MIN_HEIGHT);

      foreignObject.width.baseVal.value = width;
      foreignObject.height.baseVal.value = height;

      const msSinceLastSend = performance.now() - this.lastSendTime;
      if (msSinceLastSend < RULES.THROTTLE_MS) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.handleTextInputResize, RULES.TIMEOUT_MS, e);
      } else if (
        width !== this.lastSentState.width ||
        height !== this.lastSentState.height
      ) {
        this.lastSentState.width = width;
        this.lastSentState.height = height;
        this.lastSendTime = performance.now();

        const newState = this.getNewState({ width, height });
        this.editExistingText(foreignObject.id, newState);
      }
    }
  };

  handleTextInputMove = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    const { foreignObject } = this;

    if (foreignObject) {
      const cursorPos = getCursorPos(e, this.board.canvas);

      // prevent x,y going below 0 and allowing the foreignObject to go off
      // the canvas's left or top sides.
      let x = Math.max(cursorPos.x - this.cursorOffsetFromForeignObject.x, 0);
      let y = Math.max(cursorPos.y - this.cursorOffsetFromForeignObject.y, 0);

      // prevent x,y from exceeding a value that would result in the foreignObject
      // rendering outside the canvas to the right or bottom.
      const canvasRect = this.board.canvas.getBoundingClientRect();
      x = Math.min(x, canvasRect.width - foreignObject.width.baseVal.value);
      y = Math.min(y, canvasRect.height - foreignObject.height.baseVal.value);

      foreignObject.x.baseVal.value = x;
      foreignObject.y.baseVal.value = y;

      const msSinceLastSend = performance.now() - this.lastSendTime;
      if (msSinceLastSend < RULES.THROTTLE_MS) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.handleTextInputMove, RULES.TIMEOUT_MS, e);
      } else if (x !== this.lastSentState.x || y !== this.lastSentState.y) {
        this.lastSentState.x = x;
        this.lastSentState.y = y;
        this.lastSendTime = performance.now();

        const newState = this.getNewState({ x, y });
        this.editExistingText(foreignObject.id, newState);
      }
    }
  };

  newSVGText = (data: any, noBroadcast?: boolean) => {
    const { foreignObject, text } = initializeNewText(data, this);

    if (!noBroadcast) {
      this.board.broadcast({
        type: 'text',
        id: data.elemId || data.id,
        color: data.color,
        x: data.x,
        y: data.y,
        width: data.width,
        height: data.height,
        tool: this.name,
      });
    }
    return { foreignObject, text };
  };

  updateSVGText = (
    foreignObjectId: string,
    data: UpdateSVGTextData,
    noBroadcast?: boolean,
  ) => {
    const foreignObject = (document.getElementById(
      foreignObjectId,
    ) as unknown) as SVGForeignObjectElement;
    if (foreignObject) {
      if (data.text) {
        const input = document.getElementById(`${foreignObjectId}_input`);
        if (input && input.innerText !== data.text) {
          input.innerText = data.text;
        }
      }
      if (data.x) foreignObject.x.baseVal.value = data.x;
      if (data.y) foreignObject.y.baseVal.value = data.y;
      if (data.width) foreignObject.width.baseVal.value = data.width;
      if (data.height) foreignObject.height.baseVal.value = data.height;
    }

    if (!noBroadcast) {
      const message: BroadcastData = {
        type: 'update_text',
        id: foreignObjectId,
        tool: this.name,
      };

      if (data.text) message.text = data.text;
      if (data.x) message.x = data.x;
      if (data.y) message.y = data.y;
      if (data.width) message.width = data.width;
      if (data.height) message.height = data.height;

      this.board.broadcast(message);
    }
  };

  editExistingText = (
    foreignObjectId: string,
    data: UpdateSVGTextData,
    noBroadcast?: boolean,
  ) => {
    if (this.board.canvas.getElementById(foreignObjectId)) {
      this.updateSVGText(foreignObjectId, data, noBroadcast);
    }
  };
}

export default TextTool;
