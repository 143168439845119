import moment from 'moment';
import { conferenceRoomTypes } from 'services/instructorZoomAccounts/instructorZoomAccountsService';

export const detectEvenOrOddHour = (
  datetime?: string,
): conferenceRoomTypes | null => {
  if (!datetime) return null;
  return moment(datetime).utc().get('hour') % 2 === 0
    ? conferenceRoomTypes.Even
    : conferenceRoomTypes.Odd;
};

export const mongoIDToDate = (id: string) => {
  const timestamp = id.substring(0, 8);
  return new Date(parseInt(timestamp, 16) * 1000);
};

/* Convert MongoID to datestring 
 https://stackoverflow.com/questions/6452021/getting-timestamp-from-mongodb-id
*/
export const mongoIDToDateStr = (id: string) => mongoIDToDate(id).toISOString();
