import axios from 'axios';
import baseUrl from '../baseUrl';

const postStudentEvent = (data, eventType) => {
  const payload = {
    ...data,
    eventType,
  };
  axios.post(`${baseUrl}/student_events/`, payload);
};

export default postStudentEvent;
