import React, { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';

import { StyledFormattedText, StyledTabs, StyledTextEditor } from './styles';

const escapeMarkdown = (string: string) =>
  string
    .replace(/[#|[\]]/g, '\\$&')
    .split(/\r?\n/)
    .reduce((prev, curr) => {
      const pattern = /^>{1,10}|/;
      const match = curr.match(pattern);
      if (match && match[0]) {
        const prefix = match[0];
        const postfix = curr.replace(pattern, '').replace(/>/, '\\$&');
        return `${prev}\n${prefix}${postfix}`;
      }
      return `${prev}\n${curr}`;
    }, '');
const unescapeMarkdown = (string?: string) =>
  string?.replace(/\\([#|[\]])/g, '$1') || '';

interface JuniFlavoredMarkdownProps {
  text: string;
}

interface FormattedTextProps extends JuniFlavoredMarkdownProps {
  displayAsPlainText?: boolean;
}

interface MarkdownTextProps extends FormattedTextProps {
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isDisabled?: boolean;
  isEditable?: boolean;
  placeholder?: string;
  defaultToEditMode?: boolean;
}

export const JuniFlavoredMarkdown: FC<JuniFlavoredMarkdownProps> = ({ text }) => (
  <ReactMarkdown
    plugins={[[RemarkGFM, { singleTilde: false }]]}
    renderers={{
      heading: ({ children }) => (
        <>
          <p>{children}</p>
          <hr />
        </>
      ),
      code: ({ value }) => (
        <pre>
          <code>{unescapeMarkdown(value)}</code>
        </pre>
      ),
      inlineCode: ({ value }) => <code>{unescapeMarkdown(value)}</code>,
      link: ({ href, children }) =>
        href.startsWith('http') ? (
          <a href={unescapeMarkdown(href)} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <span>{children}</span>
        ),
    }}
  >
    {/*
      We replace each solitary '\n' with '  \n' to support line breaks.
      RegEx explanation: https://stackoverflow.com/a/18012521
    */}
    {escapeMarkdown(text.replace(/(^|[^\n])\n(?!\n)/g, '$1  \n'))}
  </ReactMarkdown>
);

const FormattedText: FC<FormattedTextProps> = ({ text, displayAsPlainText }) => (
  <StyledFormattedText>
    {displayAsPlainText ? (
      <>
        {text
          .split('\n')
          // eslint-disable-next-line react/no-array-index-key
          .map((item, i) => (item.length > 0 ? <p key={i}>{item}</p> : null))}
      </>
    ) : (
      <JuniFlavoredMarkdown text={text} />
    )}
  </StyledFormattedText>
);

const MarkdownText: FC<MarkdownTextProps> = ({
  text,
  displayAsPlainText,
  handleChange,
  isDisabled,
  isEditable,
  placeholder,
  defaultToEditMode = false,
}) => {
  const [inWritingMode, setInWritingMode] = useState(
    defaultToEditMode || isEditable || false,
  );
  return isEditable ? (
    <>
      <StyledTabs>
        <button
          type="button"
          className={inWritingMode ? 'selected' : ''}
          onClick={() => setInWritingMode(true)}
        >
          Write
        </button>
        <button
          type="button"
          className={inWritingMode ? '' : 'selected'}
          onClick={() => setInWritingMode(false)}
        >
          Preview
        </button>
      </StyledTabs>
      <StyledTextEditor isDisabled={isDisabled}>
        {inWritingMode ? (
          <textarea
            value={text}
            readOnly={isDisabled}
            onChange={handleChange}
            placeholder={placeholder}
            cols={60}
          />
        ) : (
          <FormattedText
            text={text || 'Nothing to preview.'}
            displayAsPlainText={displayAsPlainText}
          />
        )}
      </StyledTextEditor>
    </>
  ) : (
    <FormattedText text={text} displayAsPlainText={displayAsPlainText} />
  );
};

export default MarkdownText;
