import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, NewCard } from 'core-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { SUBJECT_TYPE } from 'constants/subjects';

import Card from '../Card';
import { Bundle, TrackStatus, TrackStatusTypes } from './types';

const badgeLookup = {
  [TrackStatusTypes.COMPLETED]: {
    textColor: 'j-green-700',
    bgColor: 'j-green-100',
    BadgeIcon: Icon.Check,
  },
  [TrackStatusTypes.INPROGRESS]: {
    textColor: 'j-orange-700',
    bgColor: 'j-orange-100',
    BadgeIcon: Icon.Loader,
  },
};

interface StatusBadgeProps {
  status: typeof TrackStatusTypes[keyof typeof TrackStatusTypes];
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const { textColor, bgColor, BadgeIcon } = badgeLookup[status];
  return (
    <div
      className={classNames(
        'inline',
        'p-2',
        'rounded-md',
        'text-xs',
        'uppercase',
        'font-graphik',
        'font-medium',
        'leading-none',
        `text-${textColor}`,
        `bg-${bgColor}`,
      )}
    >
      <div className="flex">
        <BadgeIcon style={{ height: '12px', width: '12px' }} />
        <span className="pl-1.5 whitespace-nowrap">{status}</span>
      </div>
    </div>
  );
};

interface BundleCardProps extends React.ComponentProps<typeof NewCard> {
  bundle: Bundle;
  defaultExpanded: boolean;
  roadmapLinkBase: string;
  subject: SUBJECT_TYPE;
  trackStatus: TrackStatus;
  isFavorite: boolean;
  handleFavoriteClick: () => void;
  certificateUrl?: string;
}

const BundleCard: React.FC<BundleCardProps> = ({
  bundle,
  defaultExpanded,
  roadmapLinkBase,
  subject,
  trackStatus,
  isFavorite,
  handleFavoriteClick,
  certificateUrl,
  ...cardProps
}) => (
  <Card
    headerElement={
      <>
        <FontAwesomeIcon
          className={`mr-2 text-lg ${
            isFavorite ? 'text-j-yellow-400' : 'text-j-gray-500'
          }`}
          onClick={e => {
            handleFavoriteClick();
            e.stopPropagation();
          }}
          icon={isFavorite ? fasStar : farStar}
        />
        {bundle.name}
      </>
    }
    headerLabel={bundle.label}
    defaultExpanded={defaultExpanded}
    footerElement={
      certificateUrl ? (
        <div className="p-4 pl-6">
          🎉 Congratulations on completing {bundle.name}! &nbsp;
          <a
            className="download-link"
            target="_blank"
            rel="noopener noreferrer"
            href={`/bundle-certificate/${certificateUrl}`}
          >
            View your certificate!
          </a>
        </div>
      ) : undefined
    }
    {...cardProps}
  >
    {bundle.tracks.map((track, i) => {
      const status = trackStatus[track.name];
      return (
        <Link
          className="block no-underline text-j-dark-600 hover:bg-j-gray-100"
          to={`${roadmapLinkBase}/${subject}/${track.name}`}
          key={`${subject}/${bundle.name}/${track.name}`}
        >
          <Card.Row hideTopBorder={i === 0}>
            <span>{track.displayName}</span>
            <div className="flex items-center ml-2">
              {!!status && <StatusBadge status={status} />}
              <Icon.ArrowRight className="icon ml-4" />
            </div>
          </Card.Row>
        </Link>
      );
    })}
  </Card>
);

export default BundleCard;
