import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, withRouter, Route } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { CookiesProvider, useCookies } from 'react-cookie';
import ConditionalWrap from 'components/ConditionalWrap';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';

import { EVENT_NAMES } from 'constants/analytics_events';
import { JUNI_COOKIES } from 'constants/cookies';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import {
  useFetchExperiments,
  ExperimentsProvider,
} from '@junilearning/juni-experiments';

import UserContext from 'modules/UserContext';
import { useAffiliates } from 'hooks/affiliates';
import useMountEffect from 'hooks/useMountEffect';
import AffiliateContext from 'modules/AffiliatesContext';
import Auth from 'modules/Auth';
import axios from 'axios';
import { getCurrentUser } from 'services/users';
import {
  clearSentryUser,
  identifySentryUser,
  initializeSentry,
} from 'services/sentry';
import jwt_decode from 'jwt-decode';
import Routes from './routes';

import apolloClient from './services/apollo';

import './tailwind.output.css';

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const LEARNER_ROUTE_REGEX = /^\/learner\/([a-f\d]{24})/;

initializeSentry();
const ContextWrapper = withRouter(({ history }) => {
  const [cookies, , removeCookie] = useCookies([
    JUNI_COOKIES.NAMES.JUNI_ANONYMOUS_ID,
  ]);
  const [user, setUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingUserError, setIsLoadingUserError] = useState();
  const [authToken, setAuthToken] = useState(Auth.getToken());
  const impersonationUserId = authToken && jwt_decode(authToken).iud;
  const isImpersonationMode = !!impersonationUserId;

  const onStorageUpdate = useCallback(async () => {
    if (authToken !== Auth.getToken()) {
      console.log('storage event: auth token changed');
      setAuthToken(Auth.getToken());
      await loadUserData();
    }
  }, [loadUserData, authToken]);

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [onStorageUpdate]);

  const setAuthenticatedUser = authToken => {
    Auth.authenticateUser(authToken);
    // fire manually so the listener in this tab hears it
    window.dispatchEvent(new Event('storage'));
  };
  const deauthenticateUser = () => {
    Auth.deauthenticateUser();
    // fire manually so the listener in this tab hears it
    window.dispatchEvent(new Event('storage'));
  };

  const loadUserData = useCallback(async () => {
    setIsLoadingUserError(undefined);
    if (!Auth.isUserAuthenticated()) {
      setUser(null);
      clearSentryUser();
      setIsLoadingUser(false);
      return;
    }

    try {
      setIsLoadingUser(true);
      const currentUser = await getCurrentUser();
      setUser(currentUser);
      identifySentryUser(currentUser);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response && err.response.status === 401) {
        // if we are getting unauthenticated errors, something is wrong with our token
        Auth.deauthenticateUser();
        setIsLoadingUserError(err);
      } else {
        console.log(`Failed to load authenticated user data: ${err}`);
        setIsLoadingUserError(err);
      }
    } finally {
      setIsLoadingUser(false);
    }
  }, []);

  const [identifyAffiliateCustomer, trackAffiliateConversion] = useAffiliates({
    customerEmail: user?.email,
  });

  useMountEffect(() => {
    identifyAffiliateCustomer();
    loadUserData();
  });

  useEffect(() => {
    const studentIdMiddleware = {
      name: 'studentId',
      process: (properties, { path }) => {
        const additionalProperties = {};

        // check for student ID
        const match = path.match(LEARNER_ROUTE_REGEX);
        if (match) {
          // eslint-disable-next-line prefer-destructuring
          additionalProperties.studentId = match[1];
        }

        return { ...properties, ...additionalProperties };
      },
    };

    const impersonationMiddleware = {
      name: 'impersonation',
      process: properties => ({ ...properties, impersonationUserId }),
    };

    const middlewares = [
      studentIdMiddleware,
      ...(impersonationUserId ? [impersonationMiddleware] : []),
    ];

    JuniAnalytics.initialize({
      source: 'app-frontend',
      environment: APP_ENVIRONMENT,
      middlewares,
    });
    // experiments relies on the anon id set in cookies so we need to force cookies to be reevaluated after init
    // this will update the value of cookies and rerender the ContextWrapper which will recall useFetchExperiments
    removeCookie('REFRESH_ANALYTICS_COOKIES'); // doesn't exist so it shouldn't affect cookie values
  }, [removeCookie, impersonationUserId]);

  useEffect(() => {
    if (impersonationUserId) {
      const impersonationMiddleware = {
        name: 'impersonation',
        process: properties => ({ ...properties, impersonationUserId }),
      };
      JuniAnalytics.addMiddleware(impersonationMiddleware);
    } else {
      JuniAnalytics.removeMiddleware('impersonation');
    }
  }, [impersonationUserId]);

  const userId = user?._id;
  const parentId = user?.parent?._id;
  const instructorId = user?.instructor?._id;

  useEffect(() => {
    if (!user) {
      return;
    }
    JuniAnalytics.identify({
      userId,
      instructorId,
      parentId,
      email: user.email,
      stripeCustomerId: user.parent?.stripeCustomerId,
    });
  }, [instructorId, parentId, user, userId]);

  useEffect(() => {
    JuniAnalytics.page();
    history.listen(() => JuniAnalytics.page());
  }, [history]);

  const experiments = useFetchExperiments(
    {
      instructorId,
      parentId,
      userId,
      isAdmin: user?.roles?.includes('admin'),
      // this anon id is set on the cookie during initialization for analytics
      // unless we call set or remove on cookies we won't get the refreshed value after initializing
      anonymousId: cookies[JUNI_COOKIES.NAMES.JUNI_ANONYMOUS_ID],
    },
    {
      apiUrl: process.env.REACT_APP_API_URL,
    },
  );

  useEffect(() => {
    if (experiments?.length) {
      JuniAnalytics.addMiddleware({
        name: 'experiments',
        process: properties => ({
          ...properties,
          experiments: experiments.reduce((acc, { key, treatmentKey }) => {
            acc[key] = treatmentKey;
            return acc;
          }, {}),
        }),
      });
      JuniAnalytics.track(EVENT_NAMES.experimentsLoaded);
    }
  }, [experiments]);

  return (
    <UserContext.Provider
      value={{
        user,
        isLoadingUser,
        isLoadingUserError,
        loadUserData,
        authToken,
        impersonationUserId,
        isImpersonationMode,
        setAuthenticatedUser,
        deauthenticateUser,
      }}
    >
      <QueryParamProvider ReactRouterRoute={Route}>
        <AffiliateContext.Provider
          value={{
            trackAffiliateConversion,
          }}
        >
          <Routes />
        </AffiliateContext.Provider>
      </QueryParamProvider>
    </UserContext.Provider>
  );
});

ReactDOM.render(
  <CookiesProvider>
    <ApolloProvider client={apolloClient}>
      <ConditionalWrap
        condition={window.Stripe != null && STRIPE_API_KEY !== 'none'}
        wrap={children => (
          <StripeProvider apiKey={STRIPE_API_KEY}>{children}</StripeProvider>
        )}
      >
        <ExperimentsProvider>
          {/* BrowserRouter needs to be outside ContextWrapper to allow usage of withRouter */}
          <BrowserRouter>
            <ContextWrapper />
          </BrowserRouter>
        </ExperimentsProvider>
      </ConditionalWrap>
    </ApolloProvider>
  </CookiesProvider>,
  document.getElementById('root'),
);
