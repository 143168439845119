import _ from 'lodash';

import calendar from 'images/signup_sessions/icons/calendar.svg';
import instructorMatching from 'images/signup_sessions/icons/instructor_matching.svg';
import checkboxes from 'images/signup_sessions/icons/checkboxes.svg';
import {
  ONE_FREE_SESSION,
  ON_DEMAND,
  TWO_FREE_SESSIONS,
  TWO_WEEK_TRIAL,
} from 'constants/signup_sessions';
import { CampaignCode } from './types';

const CAMPAIGN_CONTENT: Record<CampaignCode, any> = {
  [TWO_FREE_SESSIONS]: {
    formTitle: 'Set up your Juni Account to get started',
    parentAndStudentInfoTitle: 'Get started with your Juni account',
    backButton: 'Change your plan',
    steps: [
      {
        icon: calendar,
        title: 'Set up your sessions',
        description:
          "We'll help you choose your child's courses and session times. If anything changes later, you can always adjust your schedule, courses, or plan.",
      },
      {
        icon: instructorMatching,
        title: 'Get personalized instructor matching',
        description:
          "Our team will find instructors who are right for your child's learning style and preferences. We'll match you with one instructor per each of your subjects.",
      },
      {
        icon: checkboxes,
        title: 'Start learning!',
        description:
          "Get 2 free sessions to see if our program is right for your child. If you're not satisfied, cancel your plan at no cost.",
      },
    ],
    checkoutFinePrint:
      "The 2 free sessions promotion is one per family. If you don't cancel your subscription by your third class, you'll be charged monthly until you cancel or change your subscription.",
    cartFinePrint:
      "You'll be charged monthly starting on your third class, unless you cancel your subscription.",
    highlightedPromotion: '2 Free Sessions',
    checkoutButton: 'Start my membership',
    signupWelcomeTitle: 'Welcome to Juni!',
    specialPromotion: '2 Free Sessions',
    promoOfferForWarning: 'two free sessions',
    summaryPromo: '2 Free Sessions',
    summaryPromoAfter: 'after your second session.',
  },
  [ONE_FREE_SESSION]: {
    formTitle: 'Set up your Juni Account to get started',
    parentAndStudentInfoTitle: 'Get started with your Juni account',
    backButton: 'Change your plan',
    steps: [
      {
        icon: calendar,
        title: 'Set up your session',
        description:
          "We'll help you choose your child's course and session time. If anything changes later, you can always adjust your schedule, courses, or plan.",
      },
      {
        icon: instructorMatching,
        title: 'Get personalized instructor matching',
        description:
          "Our team will find instructors who are right for your child's learning style and preferences. We'll match you with one instructor per each of your subjects.",
      },
      {
        icon: checkboxes,
        title: 'Start learning!',
        description:
          "Get 1 free session to see if our program is right for your child. If you're not satisfied, cancel your plan at no cost.",
      },
    ],
    checkoutFinePrint:
      "The 1 free session promotion is one per family. If you don't cancel your subscription by your second class, you'll be charged monthly until you cancel or change your subscription.",
    cartFinePrint:
      "You'll be charged monthly starting on your second class, unless you cancel your subscription.",
    highlightedPromotion: '1 Free Session',
    checkoutButton: 'Book free class',
    signupWelcomeTitle: 'Welcome to Juni!',
    specialPromotion: '1 Free Session',
    promoOfferForWarning: 'one free session',
    summaryPromo: '1 Free Session',
    summaryPromoAfter: 'after your first session.',
  },
  [TWO_WEEK_TRIAL]: {
    signupWelcomeTitle: 'Welcome to your Juni trial!',
    setupInstructions:
      'Choose a password you and your child can share. You can set up classes after logging in.',
  },
  [ON_DEMAND]: {
    parentAndStudentInfoTitle: 'Get started with your Juni account',
    checkoutButton: 'Submit',
    createAccountWelcomeTitle: 'Welcome to Juni!',
  },
};

export const getContentForCampaign = (
  defaultContent: Record<string, any>,
  campaign?: CampaignCode,
) => {
  if (!campaign) {
    return defaultContent;
  }
  return _.mapValues(defaultContent, (origContent, contentKey) => {
    if (CAMPAIGN_CONTENT[campaign]?.[contentKey]) {
      return CAMPAIGN_CONTENT[campaign][contentKey];
    }
    return origContent;
  });
};
