const jDark = {
  100: '#E2E1ED',
  200: '#CAC9D8',
  300: '#9492B1',
  400: '#5F5C8A',
  500: '#413E75',
  600: '#292563',
  700: '#252350',
  800: '#1A183E',
  900: '#0E0B33',
};

const jGray = {
  100: '#F7FAFC',
  200: '#F0F5FA',
  300: '#E5EDF4',
  400: '#DAE5EE',
  500: '#CBDAE7',
};

const jPurple = {
  100: '#EFEEF7',
  200: '#E2E1ED',
  300: '#C1BCE0',
  400: '#A199D0',
  500: '#8277C0',
  600: '#6255B0',
  700: '#5548A7',
  800: '#463897',
};

const jOrange = {
  100: '#FDE9D6',
  200: '#FBD3AD',
  300: '#F9BD84',
  400: '#F7A75B',
  500: '#F59132',
  600: '#EF821A',
  700: '#E87305',
};

const jPink = {
  100: '#FDF0F1',
  200: '#FBDFE2',
  300: '#F7C0C6',
  400: '#F3A0A9',
  500: '#EE808C',
  600: '#EA606F',
  700: '#C13A53',
};

const jGreen = {
  100: '#E7F6ED',
  200: '#D0EDDB',
  300: '#A2DBB7',
  400: '#5BC080',
  500: '#14A54A',
  600: '#09923C',
  700: '#03782F',
};

const jBlue = {
  100: '#EDF0FF',
  200: '#DAE0FE',
  300: '#A4B2FD',
  400: '#4865FC',
  500: '#2D4CEE',
  600: '#1733C7',
};

const jYellow = {
  100: '#FFFBED',
  200: '#FEF4CF',
  300: '#FEE9A0',
  400: '#FED542',
  500: '#907000',
};

module.exports = {
  jDark,
  jGray,
  jPurple,
  jOrange,
  jPink,
  jGreen,
  jBlue,
  jYellow,
};
