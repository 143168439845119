import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'core-components';
import { Topic } from './types';

interface TopicWrapperProps {
  topic: Topic;
}

const TopicWrapper: FC<TopicWrapperProps> = ({ topic }) => (
  <div className="flex items-center font-graphik font-medium text-sm">
    <div className="flex items-center">
      {topic.completed ? (
        <Icon.CheckboxCircle className="pr-3 w-5 h-5 text-j-green-700" />
      ) : (
        <Icon.FastForward className="pr-3 w-5 h-5 text-j-orange-700" />
      )}
      <span className="text-j-dark-600">{topic.displayName}</span>
    </div>
    {topic.linkHref && (
      <Link
        className="text-j-blue-400 pl-3 no-underline"
        to={topic.linkHref}
        target="_blank"
      >
        {topic.linkText}
      </Link>
    )}
  </div>
);

interface TopicsCovered {
  topics: Topic[];
}

const TopicsCovered: FC<TopicsCovered> = ({ topics }) => {
  if (topics.length === 0) {
    return null;
  }
  return (
    <div>
      <div className="font-graphik text-j-dark-300 font-medium uppercase text-xs pb-6">
        Topics Covered
      </div>
      <div className="flex flex-col gap-6">
        {topics.map(topic => (
          <TopicWrapper key={topic.moduleSectionId} topic={topic} />
        ))}
      </div>
    </div>
  );
};

export default TopicsCovered;
