import { AsYouType } from 'libphonenumber-js';

/**
 * Formats a phone number as a user is typing it.
 * Intended to be used in an `<input type="tel" />` `onChange` or `onInput` handler.
 * @example
 * formatInputPhone('70', '702') => '(702)'
 * @example
 * const [phone, setPhone] = useState('');
 * <input
 *   type="tel"
 *   value={phone}
 *   onChange={e => {
 *     setPhone(
 *       (current) => formatInputPhone(current, e.target.value)
 *     )
 *   }}
 * />
 */
export function formatInputPhone(currentValue: string, nextValue: string) {
  // If the user presses backspace in a string like "(702)", attempting to
  // remove the final ")", the automatic formatter will re-insert that character.
  // So we handle that edge case here by manually erasing it.
  const isDeleting = nextValue.length < currentValue.length;
  const newFormattedValue = new AsYouType('US').input(nextValue);
  if (isDeleting && newFormattedValue === currentValue) {
    return nextValue.slice(0, -1);
  }
  return newFormattedValue;
}
