import { WhiteboardTool, WhiteboardObjectData } from './tool_util_types';
/** S3 whiteboard image to show for broken image links. */
const BROKEN_IMAGE_URL = 'Could not find image S3 placeholder.';

/**
 * Creates object from svg properties.
 * TODO(andrew): create a generic fn, getWhiteboardDataFromElement<T>.
 */
const getImageDataFromElement = (element: HTMLElement) => ({
  ...getContainerDataFromElement(element),
  href: element.getAttribute('href') || BROKEN_IMAGE_URL,
  aspectRatio: Number(element.getAttribute('aspectRatio')),
});

/**
 * Creates object from svg properties.
 * TODO(andrew|michael): create a generic fn, getWhiteboardDataFromElement<T>.
 */
const getContainerDataFromElement = (element: HTMLElement) => ({
  id: element.id,
  x: Number(element.getAttribute('x')),
  y: Number(element.getAttribute('y')),
  width: Number(element.getAttribute('width')),
  height: Number(element.getAttribute('height')),
});

/**
 * Returns object id when a tool is clicked.
 */
const getIdFromToolTarget = (target: EventTarget | null) =>
  (target as HTMLElement).id.split('_')[0];

/**
 * Returns direction of a corner or side tool from its id.
 */
const getDirectionFromToolTarget = (target: EventTarget | null) =>
  (target as HTMLElement).id?.split('_')[1]?.split('-')[0];

export const SIDE_DIRECTIONS = ['right', 'bottom', 'left', 'top'];
export const CORNER_DIRECTIONS = ['ne', 'nw', 'se', 'sw'];
export const EDITING_TOOL_CONFIG = {
  wrapperOffset: 40,
  minComponentSize: 50,
  initialMaxComponentSize: 800,
  defaultComponentDimension: 200,
  defaultComponentPosition: 50,
};

/**
 * Updates a whiteboard element from a data object.
 *
 * Every update broadcasts(saves) unless flagged(LOCAL_CHANGE).
 * A local change only renders eg: recieving a change from another user.
 */
const updateWhiteboardElement = (
  element: HTMLElement,
  elementData: WhiteboardObjectData,
  tool: WhiteboardTool,
  noBroadcast = false,
) => {
  element.setAttribute('width', `${elementData.width}`);
  element.setAttribute('height', `${elementData.height}`);
  element.setAttribute('x', `${elementData.x}`);
  element.setAttribute('y', `${elementData.y}`);

  if (!noBroadcast) {
    tool.board.broadcast({
      ...elementData,
      type: tool.type,
      tool: tool.name,
    });
  }
};

/**
 * Returns true if component is too small.
 */
const isHandleBelowMinComponentSize = (target: WhiteboardObjectData) => {
  const { wrapperOffset, minComponentSize } = EDITING_TOOL_CONFIG;
  return (
    !target.width ||
    !target.height ||
    target.width - wrapperOffset < minComponentSize ||
    target.height - wrapperOffset < minComponentSize
  );
};

/**
 * Translates a mouse x/y coord to an offset from a target element's (0,0)
 * eg: mouse:{x:123, y:567} to offset:{x:-10, y:0}
 */
const getTargetCoordsFromMouseCoords = (
  mouseX: number,
  mouseY: number,
  canvasOffset: { left: number; top: number },
  target: WhiteboardObjectData,
) => ({
  x: mouseX - canvasOffset.left - target.x!,
  y: mouseY - canvasOffset.top - target.y!,
});

/*
 * Toggle active class on the container and handles.
 */
const setActiveClass = (baseId: string, enabled: boolean) => {
  const ids = [`${baseId}_container`].concat(
    [...SIDE_DIRECTIONS, ...CORNER_DIRECTIONS].map(
      side => `${baseId}_${side}-handle`,
    ),
  );
  ids.forEach(id => {
    const classList = document.getElementById(id)?.classList;
    if (enabled) {
      classList?.add('active');
    } else {
      classList?.remove('active');
    }
  });
};

export {
  isHandleBelowMinComponentSize,
  getImageDataFromElement,
  getContainerDataFromElement,
  getIdFromToolTarget,
  getDirectionFromToolTarget,
  getTargetCoordsFromMouseCoords,
  setActiveClass,
  updateWhiteboardElement,
};
