import styled from 'styled-components/macro';

const StyledFormattedText = styled.div`
  p,
  ol,
  ul,
  pre,
  blockquote {
    color: hsl(244, 46%, 27%); /* j-dark-600 */
    margin: 1rem 0 !important;
  }
  ol,
  ul {
    padding: 0 1.5rem;
  }
  code,
  pre {
    font-size: 0.8rem;
    background-color: hsl(210, 20%, 95%);
    color: hsl(244, 46%, 27%); /* j-dark-600 */
    border-radius: 6px;
  }
  pre {
    padding: 1rem;
    overflow: auto;
    line-height: 1.45;
    code {
      padding: 0;
    }
  }
  code {
    padding: 0.2rem 0.4rem;
  }
  blockquote {
    padding: 0 1rem;
    border-left: 0.25rem solid hsl(210, 20%, 85%);
  }
  hr {
    height: 2px;
    margin: 0.25rem 0;
    background-color: hsl(210, 20%, 85%);
    border: 0;
  }
  > :first-child {
    margin-top: 0 !important;
  }
  > :last-child {
    margin-bottom: 0 !important;
  }
`;

const StyledTabs = styled.div`
  button {
    background-color: transparent;
    border: 2px solid hsl(208, 36%, 87%);
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    color: #335875;
    font-size: 0.8rem;
    letter-spacing: 0;
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
  }
  button:first-child {
    margin-left: 0.5rem;
  }
  button.selected {
    background-color: hsl(208, 36%, 87%);
  }
`;

const StyledTextEditor = styled.div<any>`
  background-color: hsl(208, 36%, 87%);
  border: 2px solid hsl(208, 36%, 87%);
  border-radius: 3px;
  > div,
  > textarea {
    min-height: 16rem;
    background-color: ${props =>
      props.isDisabled ? 'hsl(208, 26%, 87%)' : '#fafdff'};
    margin: 0;
    padding: 1rem;
    color: hsl(208, 100%, 11%);
  }
  > textarea:read-only {
    background-color: hsl(208, 26%, 87%);
  }
`;

export { StyledFormattedText, StyledTabs, StyledTextEditor };
