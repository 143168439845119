import React, { useState } from 'react';
import { SettingsIcon } from 'components/Icons';
import { useUpdateClubMetadataMutation } from 'generated/graphql';
import { Button, Message, ModalWindow } from 'core-components';
import { ALL_TAGS, CLUB_DISPLAY_TAGS, CLUB_INTERNAL_TAGS } from 'constants/clubs';
import SpinnerV2 from 'components/SpinnerV2';
import LabelTag from 'components/LabelTag';
import { sortTags } from 'app/clubs/helpers';
import MyClubsTagSelector from '../MyClubsTagSelector';

interface ClubMetadataModalProps {
  modalOpen: boolean;
  closeModal: () => void;
  clubTags: string[];
  juniClubId?: string;
}

const ClubMetadataModal: React.FC<ClubMetadataModalProps> = ({
  modalOpen,
  closeModal,
  clubTags,
  juniClubId,
}) => {
  const [updateClubMetadata] = useUpdateClubMetadataMutation();
  const [currentTags, setCurrentTags] = useState(sortTags(clubTags));
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const handleClose = () => {
    setSubmitting(false);
    setError('');
    setCurrentTags(sortTags(clubTags));
    closeModal();
  };
  const handleSubmit = () => {
    setSubmitting(true);
    try {
      updateClubMetadata({
        variables: {
          input: {
            juniClubId,
            tags: currentTags,
          },
        },
      });
      handleClose();
      // refresh the page on success.
      window.location.reload();
    } catch (e) {
      console.log(e);
      setError('We had an issue updating tags. Please try again');
      setSubmitting(false);
    }
  };
  const addToTags = (newTag: string) => {
    const filteredTags = currentTags.filter(currTag => currTag !== newTag);
    setCurrentTags(sortTags([...filteredTags, newTag]));
  };
  const removeTag = (tag: string) =>
    setCurrentTags(sortTags(currentTags.filter(currTag => currTag !== tag)));
  return (
    <ModalWindow title="Edit club tags" isOpen={modalOpen} closeModal={handleClose}>
      <div>
        <strong>Current tags</strong>
      </div>
      <div className="flex flex-col ">
        {currentTags.map(tag => {
          const labelInfo = ALL_TAGS.find(tagInfo => tag === tagInfo.value);
          return (
            labelInfo && (
              <div className="flex items-center" key={tag}>
                <LabelTag
                  color={labelInfo.color}
                  name={labelInfo.label}
                  key={labelInfo?.value}
                />
                <span className="cursor-pointer" onClick={() => removeTag(tag)}>
                  x
                </span>
              </div>
            )
          );
        })}
      </div>
      <div>
        <strong>Add display tags</strong>
        <span>
          {' '}
          (these tags are how students can search for clubs. They also show up on the
          search cards)
        </span>
      </div>
      <MyClubsTagSelector
        handleTagSelect={(tag: string) => {
          if (tag) {
            addToTags(tag);
          }
        }}
        clubTags={CLUB_DISPLAY_TAGS}
      />
      <div>
        <strong>Add internal tags</strong>
        <span>
          {' '}
          (these tags do not show up on the search cards, but have certain effects,
          like moving things up in the search order.)
        </span>
      </div>
      <MyClubsTagSelector
        handleTagSelect={(tag: string) => {
          if (tag) {
            addToTags(tag);
          }
        }}
        clubTags={CLUB_INTERNAL_TAGS}
      />
      {error && <Message status="error">{error}</Message>}
      <Button
        onClick={() => {
          handleSubmit();
        }}
        disabled={submitting}
      >
        {submitting ? <SpinnerV2 /> : 'Update Tags'}
      </Button>
    </ModalWindow>
  );
};

interface ClubMetadataEditorProps {
  clubTags: string[];
  juniClubId?: string;
}
/* We export a button that holds the state of the modal */
const ClubMetadataEditor: React.FC<ClubMetadataEditorProps> = ({
  clubTags,
  juniClubId,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  return (
    <div className="p-0 flex items-center">
      <ClubMetadataModal
        clubTags={clubTags}
        modalOpen={editOpen}
        closeModal={() => setEditOpen(false)}
        juniClubId={juniClubId}
      />
      <div
        className="cursor-pointer flex items-center h-6"
        onClick={() => setEditOpen(true)}
      >
        <SettingsIcon style={{ height: '1rem' }} />
      </div>
    </div>
  );
};

export default ClubMetadataEditor;
