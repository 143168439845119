/* eslint-disable react/no-array-index-key */
import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';
import { useHistory } from 'react-router-dom';

export interface StepperStep {
  title: string;
  icon?: ReactElement;
  path?: string;
  displayStep?: boolean;
}

export interface StepperProps {
  steps: StepperStep[];
  currentStep: number;
  allowNavigation?: boolean;
  onClick?: (step: StepperStep, i: number) => void;
}

const Stepper: FC<StepperProps> = ({
  steps,
  currentStep = 0,
  allowNavigation = false,
  onClick,
}) => {
  const history = useHistory();

  if (!steps || !steps?.length) {
    return null;
  }

  return (
    <div className="flex justify-center max-w-lg mx-auto px-5">
      {steps?.map((step, i) => {
        if (step.displayStep === false) {
          return null;
        }

        const iconColor = classNames({
          'text-j-dark-600': currentStep === i,
          'text-j-dark-300': currentStep < i,
          'text-j-green-400': currentStep > i,
        });
        const isPastStep = currentStep > i;
        const isActiveStep = currentStep === i;
        return (
          <React.Fragment key={`step-${i}`}>
            {i !== 0 && (
              <div className="border-0 border-b border-solid border-j-purple-200 w-full h-0 pt-3" />
            )}
            <div
              className={`text-center w-16 flex justify-center flex-col items-center ${
                ((allowNavigation && step.path) || onClick) &&
                !isActiveStep &&
                'cursor-pointer'
              }`}
              onClick={() => {
                if (step.path && allowNavigation && !isActiveStep) {
                  history.push(step.path);
                }
                if (onClick && !isActiveStep) {
                  onClick(step, i);
                }
              }}
            >
              <div className={iconColor}>
                <div className={`h-8 box-border ${isPastStep && 'pt-1'}`}>
                  {isPastStep ? (
                    <div className="w-4 h-4 rounded-full border-2 border-solid border-j-green-400 flex justify-center items-center">
                      <Icon.Check width="10" />
                    </div>
                  ) : (
                    step.icon || null
                  )}
                </div>
              </div>
              <h2
                className={`whitespace-pre-wrap sm:whitespace-nowrap text-center m-0 font-normal text-xs leading-4 ${iconColor}`}
              >
                {step.title}
              </h2>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
