/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';
import classNames from 'classnames';

import './fit_vid.css';

// A video wrapper to make sizing video components easier
// NOTE: It's not perfect but it helps in places like the JideRecStudio modals
const FitVid = ({
  src,
  autoPlay,
  loop,
  muted,
  controls,
  unlockAspectRatio,
  noBorderRadius,
}) => {
  const videoRef = useRef(undefined);
  const srcThumbnail = autoPlay ? src : `${src}#t=0.1`; // trick to render thumbnail
  const preload = autoPlay ? undefined : 'metadata';

  // chrome isn't good at writing and reading webm headers for some reason, so
  // duration isn't loaded by default, meaning the seeking doesn't work.
  // solution borrowed from: https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
  // though I think it's quite inefficient on slow connections for long videos,
  // so i'm keeping it commented out for now.
  // much better solution would be to create the postprocessing pipeline
  // to write vids to mp4. another solution may be to try https://www.npmjs.com/package/ts-ebml
  // or figure out a way to set duration, which is normally read-only

  // const chromeBugWorkaround = useCallback(() => {
  //   if (videoRef.current && controls) {
  //     const vid = videoRef.current;
  //     vid.onplay = function () {
  //       if (vid.currentSrc.includes('.webm') && vid.duration === Infinity) {
  //         vid.currentTime = 1e100;
  //         vid.ontimeupdate = function () {
  //           this.ontimeupdate = () => {
  //             return;
  //           };
  //           vid.currentTime = 0.1;
  //         };
  //       }
  //     };
  //   }
  // }, [controls]);
  // useEffect(chromeBugWorkaround, []);

  return (
    <div
      className={classNames('fitvid', {
        'fitvid-unlockAR': unlockAspectRatio,
        'fitvid-noBR': noBorderRadius,
      })}
    >
      <video
        ref={videoRef}
        key={srcThumbnail}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        controls={controls}
        preload={preload}
      >
        <source src={srcThumbnail} />
      </video>
    </div>
  );
};

export default FitVid;
