import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import { Chevron } from 'components/Icons';
import { Button, ReactSelectField } from 'components/ui';
import { difficultyDisplayNames } from 'constants/project_difficulty';
import useDimensions from 'hooks/dimensions';
import { JideWidgets } from 'components/jide';
import scratchProjectsService from 'services/playgroundService';
import StartedScratchProject from './StartedScratchProject';
import UnstartedScratchProject from './UnstartedScratchProject';

import './jide_env_scratch.css';
import PortfolioScratchProject from './PortfolioScratchProject';
import JuniverseScratchProject from './JuniverseScratchProject';

const VIEW_OPTIONS = [
  { label: "I haven't started this project yet", value: 'unstarted' },
  { label: "I've already started this project", value: 'started' },
  {
    label: 'I’ve completed this project and want to add it to my portfolio',
    value: 'add_to_portfolio',
  },
];

const JideEnvScratch = ({
  activeNav,
  jideUser,
  tab,
  isActive,
  isLoading,
  idLookup,
  showVideosWidget,
  learnerAnalyticsEnabled,
  playgroundProject,
  updateProjectName,
  recordingMode,
  setRecordingMode,
  schedulingFormat,
}) => {
  const [currentView, setCurrentView] = useState(
    playgroundProject ? 'add_to_portfolio' : 'unstarted',
  );
  const [customScratchProject, setCustomScratchProject] = useState(undefined);

  const { width } = useDimensions();
  const currentProject = idLookup[tab.tabNav.project];

  useEffect(() => {
    const fetchSavedScratchProject = async () => {
      const params = {
        userType: jideUser.type,
        userId: jideUser._id,
        moduleId: currentProject.properties.moduleId || 'playground',
        projectId: activeNav.project,
      };

      const projects = await scratchProjectsService.list(params);
      if (projects[0]) {
        setCustomScratchProject(projects[0]);
      } else {
        setCustomScratchProject(undefined);
      }
    };

    if (activeNav.project && currentProject && !customScratchProject) {
      fetchSavedScratchProject();
    }
  }, [
    activeNav.project,
    currentProject,
    customScratchProject,
    jideUser._id,
    jideUser.type,
  ]);

  if (isLoading || !currentProject) {
    return (
      <div className="jide-scratch-env loading-state-view">
        <Spinner />
      </div>
    );
  }
  if (jideUser.type === 'public') {
    const { module, student, project } = activeNav;
    if (module) {
      return (
        <JuniverseScratchProject
          projectId={project}
          moduleId={module}
          userId={student}
        />
      );
    }
  }

  const desktopView = width > 992;
  const { properties } = currentProject;
  const { difficulty, isSupplemental } = properties;

  const module = idLookup[tab.tabNav.module] || {};
  const title = currentProject.displayName
    ? currentProject.displayName
    : module.displayName || '';

  return (
    <div className="jide-scratch-env">
      <div className="layout">
        <div className="jide-scratch-env-header">
          {!playgroundProject && <h1>{title}</h1>}
          {isSupplemental && difficulty && (
            <div className="header-tag-v2 missed">
              {difficultyDisplayNames[difficulty]}
            </div>
          )}
        </div>
        {!playgroundProject && !desktopView && (
          <div className="jide-scratch-select">
            <ReactSelectField
              options={VIEW_OPTIONS}
              value={currentView}
              onChange={option => setCurrentView(option)}
            />
          </div>
        )}
        <div className="scratch-env-options">
          {!playgroundProject && desktopView && (
            <div className="scratch-buttons-container">
              {VIEW_OPTIONS.map(option => (
                <Button
                  className={`scratch-env-button ${
                    option.value === currentView ? 'selected' : ''
                  }`}
                  key={option.value}
                  onClick={() => setCurrentView(option.value)}
                  icon={<Chevron orientation="right" />}
                >
                  <span>{option.label}</span>
                </Button>
              ))}
            </div>
          )}
          <div
            className={`scratch-project-container${desktopView ? '-desktop' : ''} ${
              playgroundProject ? 'playground-project' : ''
            }`}
          >
            {currentView === 'started' && <StartedScratchProject />}
            {currentView === 'unstarted' && (
              <UnstartedScratchProject
                currentProject={currentProject}
                isActive={isActive}
              />
            )}
            {currentView === 'add_to_portfolio' && (
              <PortfolioScratchProject
                user={jideUser}
                initialDisplayName={currentProject.displayName}
                moduleId={currentProject.properties.moduleId}
                projectId={activeNav.project}
                customScratchProject={customScratchProject}
                setCustomScratchProject={setCustomScratchProject}
                playgroundProject={playgroundProject}
                updateProjectName={updateProjectName}
              />
            )}
          </div>
        </div>
      </div>
      <div className="upper-border" />

      <JideWidgets
        environmentType="scratch"
        showVideosWidget={showVideosWidget}
        activeNav={activeNav}
        idLookup={idLookup}
        learnerAnalyticsEnabled={learnerAnalyticsEnabled}
        tab={tab}
        jideUser={jideUser}
        isCustomProject={!!playgroundProject}
        hideShareWidget={customScratchProject === undefined}
        isPlayground={!!playgroundProject}
        recordingMode={recordingMode}
        setRecordingMode={setRecordingMode}
        schedulingFormat={schedulingFormat}
      />
    </div>
  );
};

JideEnvScratch.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  idLookup: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  tab: PropTypes.shape({}).isRequired,
  activeNav: PropTypes.shape({}).isRequired,
  showVideosWidget: PropTypes.bool,
  recordingMode: PropTypes.bool,
  setRecordingMode: PropTypes.func,
};

export default JideEnvScratch;
