import React, { useCallback, useRef, useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { Icon } from 'core-components';
import useClickAwayListener from 'hooks/useClickAwayListener';

export interface Option<T> {
  value: T;
  label: string;
}

/**
 * Supported (Popper.js) placements of the dropdown.
 */
export type DropdownPlacement = 'bottom-start' | 'bottom-end';

export interface SelectDropdownProps<T = any> {
  /**
   * Provide an id's to make it accessible.
   * The Dropdown container uses "{id}-menu" if provided.
   */
  id?: string;
  /**
   * All options.
   */
  options: Option<T>[];
  /**
   * The currently selected options, if any
   */
  selected?: T[];
  /**
   * Called when a value is selected.
   */
  onSelectedValuesChanged: (values: T[]) => void;
  /**
   * The button is highlighted indicating actively used.
   * Could be a selection, or a persistent use case (eg sort with default).
   */
  isSelected: boolean;
  /**
   * Positioning of the dropdown menu.
   * Default bottom-start.
   */
  dropdownPlacement?: DropdownPlacement;
  /**
   * Allow multi-select.
   * Default true.
   */
  multi?: boolean;
  /**
   * Shows the action buttons at the bottom of the menu.
   * Default true.
   */
  showMenuActionButtons?: boolean;
  /**
   *
   */
  buttonContent?: React.ReactNode;
  /**
   * Optional equality function for comparing selections in handleSelect.
   */
  equalityFn?: (value1: T, value2: T) => boolean;
  /**
   * Optionally add additional classes to the button element.
   */
  className?: string;
  /**
   * Optionally add additional classes to the menu container element.
   */
  menuContainerClassName?: string;
  /*
   Render props for the selectable elements
   */
  children: (
    options: any[],
    handleSelect: (value: T) => void,
    selected: T[],
  ) => JSX.Element;
}

const SelectDropdown = <T extends unknown>({
  id,
  options,
  selected = [],
  onSelectedValuesChanged,
  isSelected,
  dropdownPlacement = 'bottom-start',
  multi = true,
  showMenuActionButtons = true,
  buttonContent,
  equalityFn = (value1: T, value2: T) => value1 === value2,
  className,
  menuContainerClassName,
  children,
}: SelectDropdownProps<T>) => {
  const [open, setOpen] = useState(false);
  const referenceElement = useRef<HTMLButtonElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const { styles, attributes, update } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: dropdownPlacement,
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            // extra 2px y to account for faux border overflow = 4px offset
            offset: [0, 6],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [
              dropdownPlacement === 'bottom-start' ? 'bottom-end' : 'bottom-start',
            ],
          },
        },
      ],
    },
  );

  // added to reposition the dropdown/popper when the size/position of the other filters before/after changes
  useEffect(() => {
    update?.();
  }, [open, update]);

  const clickAwayHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useClickAwayListener(popperElement, clickAwayHandler, [referenceElement]);

  const handleSelect = (value: T) => {
    if (multi) {
      const currentlySelected = selected.some(v => equalityFn(v, value));
      onSelectedValuesChanged(
        currentlySelected
          ? selected.filter(s => !equalityFn(s, value))
          : selected.concat(value),
      );
    } else {
      setOpen(false);
      onSelectedValuesChanged([value]);
    }
  };

  const handleClear = () => {
    setOpen(false);
    onSelectedValuesChanged([]);
  };

  const menuId = id ? `${id}-menu` : undefined;

  return (
    <>
      <button
        id={id}
        type="button"
        ref={referenceElement}
        onClick={() => setOpen(prev => !prev)}
        className={classNames(
          'ignore-juni-globals',
          // need p-0.5 to offset faux border
          'inline-flex p-0.5 justify-center box-border items-center py-3 px-4 min-w-32 bg-white rounded-lg font-graphik text-sm',
          'border border-solid outline-none',
          className,
          {
            'text-j-dark-700': open || isSelected,
            'active:text-j-dark-700 text-j-dark-400': !open && !isSelected,
            'border-j-purple-500': open && !isSelected,
            'border-j-dark-200 hover:border-j-dark-400 active:border-j-purple-500 focus:border-j-purple-500 focus:shadow-button-j-purple-300': !isSelected,
            // the box-shadow is a faux border that emulates a real one, and prevents layout shift on selection
            'border-transparent shadow-button-j-purple-600': isSelected,
          },
        )}
        aria-haspopup="menu"
        aria-controls={menuId}
        aria-expanded={open}
      >
        {buttonContent}
        <Icon.CaretDown
          className={classNames('ml-2', { 'transform rotate-180': open })}
        />
      </button>
      <div
        id={menuId}
        ref={popperElement}
        role="menu"
        className={classNames(
          'rounded-lg bg-white shadow-card border border-solid border-j-purple-300 py-1 z-10',
          menuContainerClassName,
          { hidden: !open },
        )}
        style={{ ...styles.popper, maxWidth: 'calc(100% - 3rem)' }}
        {...attributes.popper}
        aria-labelledby={id}
        aria-orientation="vertical"
      >
        {children(options, handleSelect, selected)}

        {showMenuActionButtons && (
          <div className="flex py-3 px-4">
            <button
              onClick={() => setOpen(false)}
              className="ignore-juni-globals py-3 px-4 rounded-lg bg-j-purple-600 text-white text-sm leading-6 font-medium"
            >
              Done
            </button>
            <button
              onClick={handleClear}
              className="ignore-juni-globals ml-6 py-3 px-4 rounded-lg bg-white  text-j-dark-600 text-sm leading-6 font-medium"
            >
              Clear
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectDropdown;
