import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import clubColors from 'theme/club-colors';

const MainItem = styled.div.attrs({
  className: 'h-8 w-full rounded-lg',
})<any>`
  background-color: ${props => props.colorValue};
`;
const OptionItem = styled(MainItem)`
  width: 80%;
`;

/* Structure matches default SingleOption from react-select */
const SelectedColor = (props: any) => (
  <div className="flex items-center justify-center w-5/6">
    <MainItem colorValue={props.data.label.light} />
  </div>
);
/* Structure matches default Option from react-select */
const ColorOption = (props: any) => {
  const { innerProps, innerRef, isFocused, isSelected } = props;
  const backgroundClass =
    (isSelected && 'bg-juni-blue-400') || (isFocused && 'bg-juni-blue-100') || '';
  return (
    <article ref={innerRef} {...innerProps}>
      <div className={`flex justify-center py-2 ${backgroundClass}`}>
        <OptionItem colorValue={props.data.label.light} />
      </div>
    </article>
  );
};

const ColorSelectDropdown = styled(Select)`
  .react-select__control {
    border: 1px solid hsl(201, 70%, 50%);
    height: 3rem;
    border-color: ${props => (props.hasError ? '#da0f0f' : '')};
    background-color: ${props => (props.hasError ? '#ffe9e8' : '#fbfdff')};
    border-radius: 0.5rem;
    &--is-focused {
      box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
        inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
    }
  }
  .react-select__placeholder {
    color: #adc2ce;
  }
  .react-select__input {
    color: transparent;
  }
`;

type ColorType = keyof typeof clubColors;

interface ColorSelectorProps {
  changeColor: React.Dispatch<React.SetStateAction<ColorType | undefined>>;
  currentColor?: ColorType;
  // The color selector needs the below callback so it can update its parents formik state.
  updateFormikColor: (color: ColorType) => void;
  error: string;
  touched: boolean;
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({
  changeColor,
  currentColor,
  updateFormikColor,
  error,
  touched,
}) => {
  const colors = Object.keys(clubColors).map(color => ({
    value: color,
    label: clubColors[color as ColorType],
  }));
  const value = currentColor
    ? {
        value: currentColor,
        label: clubColors[currentColor],
      }
    : undefined;
  return (
    <ColorSelectDropdown
      value={value}
      components={{ SingleValue: SelectedColor, Option: ColorOption }}
      name="color"
      options={colors}
      hasError={error && touched}
      classNamePrefix="react-select"
      onChange={({ value }: { value: ColorType }) => {
        changeColor(value);
        updateFormikColor(value);
      }}
    />
  );
};
