import _ from 'lodash';

// keep in sync with juni-app-backend/server/app/juniverse/constants/tags.js
const JUNIVERSE_TAGS_WITH_COLORS = [
  { key: 'writing_project', name: 'Writing Project', color: '#AAAAAA' },
  { key: 'classic_game', name: 'Classic Game', color: '#E040FB' },
  { key: 'juniverse_challenges', name: 'Juniverse Challenges', color: '#3D5AFE' },
  { key: 'original_game', name: 'Original Game', color: '#E57373' },
  { key: 'python', name: 'Python', color: '#7E57C2' },
  { key: 'graphics', name: 'Graphics', color: '#0097A7' },
];

export const JUNIVERSE_TAGS = [
  ...JUNIVERSE_TAGS_WITH_COLORS,
  {
    key: 'featured-juniverse',
    name: 'Featured on Juniverse Homepage (Hero)',
    isPrivate: true,
  },
  {
    key: 'featured-juniverse-trending',
    name: 'Featured on Juniverse Homepage (Trending)',
    isPrivate: true,
  },
  { key: 'featured-my-feed', name: 'Featured on My Feed', isPrivate: true },
];

export const DISPLAYABLE_JUNIVERSE_TAGS = _.keyBy(JUNIVERSE_TAGS_WITH_COLORS, 'key');
