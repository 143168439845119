import React from 'react';
import PropTypes from 'prop-types';
import TreeNode from './TreeNode';

const SubTree = ({ items = [], openIds, activeIds, onToggle, renderLabel }) => (
  <ul className="ui-tree-subtree">
    {items.map(item => (
      <li key={item.id}>
        <div className="ui-tree-lines" />
        <TreeNode
          opened={openIds[item.id]}
          onToggle={() => onToggle(item.id)}
          hasChildren={item.children && item.children.length}
          subtree={
            <SubTree
              items={item.children}
              openIds={openIds}
              activeIds={activeIds}
              onToggle={onToggle}
              renderLabel={renderLabel}
            />
          }
        >
          {renderLabel(item, activeIds[item.id])}
        </TreeNode>
      </li>
    ))}
  </ul>
);

SubTree.propTypes = {
  items: PropTypes.array,
  openIds: PropTypes.object.isRequired,
  activeIds: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  renderLabel: PropTypes.func.isRequired,
};

export default SubTree;
