import React, { FC, useRef } from 'react';
import { Link } from 'react-router-dom';

import { JuniLogo } from 'components/brand-assets';
import QUOTES from 'constants/quotes';

const AuthLayout: FC = ({ children }) => {
  const quote = useRef(QUOTES[Math.floor(Math.random() * QUOTES.length)]);
  return (
    <section className="learnerlogin">
      <div id="auth">
        <div id="auth-sidebar-wrap" className="bg-juni-purple-500">
          <div className="stars">
            <svg
              id="triangle"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 678.47 596.85"
            >
              <g style={{ opacity: 0.05 }}>
                <path
                  d="M309,17.48,4.73,544.42C-8.72,567.72,8.1,596.85,35,596.85H643.47c26.91,0,43.72-29.13,30.27-52.43L369.5,17.48C356.05-5.83,322.42-5.83,309,17.48Z"
                  transform="translate(0)"
                  style={{ fill: '#fff' }}
                />
              </g>
            </svg>
            <svg
              id="star"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 888.35 810.35"
            >
              <g style={{ opacity: 0.05 }}>
                <path
                  d="M522.55,114.17,725.72,59.7A35.05,35.05,0,0,0,758,64.54L996-18.32c27.44-9.55,53.91,16.5,44.81,44.08l-79,239.3a35,35,0,0,0,5.36,32.17L1119.47,498c17.56,23.14,1,56.37-28.08,56.23L839.4,553a35.06,35.06,0,0,0-28.94,15L666.63,775c-16.58,23.85-53.31,18.34-62.15-9.33l-76.75-240a35.08,35.08,0,0,0-23.25-22.88L263.24,633.43c-27.8-8.39-33.91-45-10.33-62L457.47,220.73A35,35,0,0,0,472,191.55l-5.26-48.39C466.17,114.11,499.13,97,522.55,114.17Z"
                  transform="translate(-238.32 20.33)"
                  style={{ fill: '#fff' }}
                />
              </g>
            </svg>
          </div>
          <div id="auth-sidebar">
            <p>“{quote.current}”</p>
          </div>
          <div className="nav-logo">
            <Link to="/">
              <JuniLogo width="24" color="light" />
            </Link>
          </div>
        </div>
        <div id="auth-content-wrap">
          <div id="auth-content">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
