import { SignupData, UserType } from 'app/signup_session/types';
import { Student } from 'models';
import juniAxios from 'services/axios';

const SIGNUP_SESSIONS_URL = `/join`;
const origin = 'dcf';

export type GuestAccountStudentData = Pick<
  Student,
  '_id' | 'firstName' | 'lastName' | 'age'
> & { birthDate: string; email: string };

const makeSignupSessionsService = (signupSessionId?: string) => ({
  fetchGuestInformation: async (invitationLookupId: string) =>
    juniAxios.get<Partial<GuestAccountStudentData>>(
      `/auth/student/${invitationLookupId}`,
    ),
  detectExistingUser: (email: string) =>
    juniAxios.get<{
      userType: UserType;
      invitationLookupId?: string;
      inviteeStudentId: string;
    }>(`${SIGNUP_SESSIONS_URL}/signup/check_account_exists`, {
      params: {
        email,
      },
    }),
  findSession: (_id = signupSessionId) =>
    juniAxios.get(`${SIGNUP_SESSIONS_URL}/sessions/${_id}`),

  getEnrollmentCompleted: (parentId: string) =>
    juniAxios.get(
      `${SIGNUP_SESSIONS_URL}/sessions/completed-enrollment/${parentId}`,
    ),

  updateSession: (signupData: Partial<SignupData>) =>
    juniAxios.patch(
      `${SIGNUP_SESSIONS_URL}/sessions/${signupSessionId}`,
      signupData,
    ),
  createSession: (signupData?: Partial<SignupData>) =>
    juniAxios.post(`${SIGNUP_SESSIONS_URL}/sessions`, {
      ...signupData,
      origin,
    }),
});

export default makeSignupSessionsService;
