import classNames from 'classnames';
import React from 'react';
import SelectDropdown, { SelectDropdownProps, Option } from '../SelectDropdown';

export interface SelectButtonDropdownProps<T>
  extends Omit<SelectDropdownProps<T>, 'children'> {
  /*
  Button content
  */
  buttonText: string;
}

const SelectButtonDropdown = <T extends unknown>({
  buttonText,
  ...props
}: SelectButtonDropdownProps<T>) => (
  <>
    <SelectDropdown<T> {...props} buttonContent={buttonText}>
      {(options: Option<T>[], handleSelect: (value: T) => void, selected: T[]) => (
        <div>
          {options.map(({ value, label }) => {
            const isSelected = selected.some(v => v === value);

            return (
              <div
                key={String(value)}
                role="menuitem"
                tabIndex={0}
                onClick={() => handleSelect(value)}
                className={classNames(
                  'py-3 px-4 font-graphik text-j-dark-600 text-sm leading-6 font-medium cursor-pointer select-none',
                  {
                    'hover:bg-j-gray-100': !isSelected,
                    'bg-j-gray-200 text-j-purple-600': isSelected,
                  },
                )}
              >
                {label}
              </div>
            );
          })}
        </div>
      )}
    </SelectDropdown>
  </>
);

export default SelectButtonDropdown;
