import classNames from 'classnames';
import { Icon } from 'core-components';
import useDimensions from 'hooks/dimensions';
import React from 'react';
import { Link } from 'react-router-dom';

interface Breadcrumb {
  to: string;
  name: string;
}

interface Props {
  breadcrumbs?: Breadcrumb[];
}

const isLastBreadcrumb = (breadcrumbs: Breadcrumb[], i: number) =>
  i === breadcrumbs.length - 1;

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const { width } = useDimensions();
  const isMobile = width < 768;
  // grab the last navigable icon: will either be the -2nd index or 0th.
  const mobileBreadcrumb = breadcrumbs
    ? breadcrumbs[breadcrumbs.length > 2 ? breadcrumbs.length - 2 : 0]
    : undefined;
  return isMobile ? (
    <div className="text-sm">
      {mobileBreadcrumb && (
        <React.Fragment key={mobileBreadcrumb.to}>
          <Link
            className="no-underline font-semibold text-j-dark-400 flex items-center"
            to={mobileBreadcrumb.to}
          >
            <Icon.DoubleChevron className="w-4 h-4 mr-1 transform rotate-180" />
            {mobileBreadcrumb.name}
          </Link>
        </React.Fragment>
      )}
    </div>
  ) : (
    <div className="text-sm">
      {breadcrumbs &&
        breadcrumbs.map((crumb, i) => {
          const isEnd = isLastBreadcrumb(breadcrumbs, i);
          return (
            <React.Fragment key={crumb.to}>
              {i !== 0 && <span className="mx-2 text-j-dark-300">/</span>}
              <Link
                className={classNames(
                  'no-underline font-semibold',
                  {
                    'text-j-dark-400 hover:text-j-blue-500': !isEnd,
                  },
                  { 'cursor-default text-j-dark-300': isEnd },
                )}
                to={crumb.to}
              >
                {crumb.name}
              </Link>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;
