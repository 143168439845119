/**
 * Largely adapted from ImageUploadField, but with NH styling.
 */

import React, { useState } from 'react';
import Dropzone, { IMeta, ILayoutProps, MethodValue } from 'react-dropzone-uploader';
import { useRequestUploadUrlMutation, UploadDestination } from 'generated/graphql';
import { logError } from 'utils/errors';
import { Icon, NewButton } from 'core-components';
import { getError, getExtension } from './shared';

const makeLayoutComponent: (
  instructions?: string,
  onClose?: () => void,
  className?: string,
  error?: string | null,
) => React.FC<ILayoutProps> = (instructions, onClose, className, error) => ({
  input,
  dropzoneProps,
}) => (
  <div {...dropzoneProps} className={className}>
    <div className="w-full h-full relative bg-j-blue-100 border border-dashed border-j-blue-600 font-graphik text-j-blue-600 overflow-hidden">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="flex items-center justify-center p-9 bg-white rounded-full">
          {/* the icon is not a square, center it here also to use padding above */}
          <div className="flex items-center justify-center h-14 w-14">
            <Icon.FileUpload />
          </div>
        </div>
        <div className="mt-4 text-xs leading-tight uppercase font-semibold">
          {instructions}
        </div>
        <div className="mt-2 text-sm">or</div>
        {/* This is a faux button, the whole canvas surface is actually clickable */}
        <div key="input" className="mt-2">
          <NewButton intent="info">Upload File</NewButton>
        </div>
        {!!error && <div className="mt-4 text-sm text-j-pink-700">{error}</div>}
        {input}
      </div>
      {onClose && (
        <div
          className="absolute top-4 right-4 text-j-dark-600 cursor-pointer"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          <Icon.Times />
        </div>
      )}
    </div>
  </div>
);

interface DownloadMeta extends IMeta {
  downloadUrl: string;
}

export interface ImageUploadCanvasProps {
  /**
   * Customizeable instructions text.
   */
  instructions?: string;
  /**
   * One of a constant set of supported upload destinations.
   */
  uploadDestination: UploadDestination;
  /**
   * More like a named part of the file url path.
   * Example whiteboardId in {whiteboardId}/image_{nano(12)}.{ext}.
   */
  filename?: string;
  /**
   * File uploaded callback.
   */
  onFileUploaded?: (downloadUrl: string, meta?: IMeta) => void;
  /**
   * Optional styling, only meant for layout, positioning, and outer spacing.
   */
  className?: string;
  /**
   * If provided, an X icon will be rendered in the upper right corner to close.
   */
  onClose?: () => void;
  /**
   * The range of uploadable content that will be accepted.  Used by the input element's accept attribute.
   * Default 'image/*'.
   */
  accept?: React.ComponentProps<typeof Dropzone>['accept'];
}

const ImageUploadCanvas: React.FC<ImageUploadCanvasProps> = ({
  instructions = 'Drag and drop your photo here',
  uploadDestination,
  filename,
  onFileUploaded,
  className,
  onClose,
  accept = 'image/*',
}) => {
  const [error, setError] = useState<string | null>(null);
  const [requestUploadUrl] = useRequestUploadUrlMutation();

  const getUploadParams: React.ComponentProps<
    typeof Dropzone
  >['getUploadParams'] = async ({ file, meta }) => {
    try {
      const result = await requestUploadUrl({
        variables: {
          input: {
            destination: uploadDestination,
            filename: filename
              ? `${filename}/image${getExtension(meta.name)}`
              : meta.name,
          },
        },
      });

      if (!result.data) {
        throw new Error('No data received from server');
      }
      const { uploadUrl, downloadUrl } = result.data.requestUploadUrl;
      if (!downloadUrl) {
        throw new Error('No download URL returned from server');
      }
      return {
        body: file,
        url: uploadUrl,
        meta: { downloadUrl },
        method: 'PUT' as MethodValue,
      };
    } catch (err) {
      logError(err);
      return { url: '' };
    }
  };

  const handleChangeStatus: React.ComponentProps<
    typeof Dropzone
  >['onChangeStatus'] = ({ meta, remove }, status) => {
    setError(getError(meta));

    if (status === 'done' && onFileUploaded) {
      onFileUploaded((meta as DownloadMeta).downloadUrl, meta);
      // remove it so more can be uploaded if needed
      remove();
    }
  };

  return (
    <Dropzone
      classNames={{ input: 'hidden', inputLabel: 'absolute inset-0 cursor-pointer' }}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept={accept}
      inputContent={null}
      LayoutComponent={makeLayoutComponent(instructions, onClose, className, error)}
      inputWithFilesContent={null}
      canCancel={false} // not supported here yet
      multiple={false} // not supported here yet
    />
  );
};

export default ImageUploadCanvas;
