import React, { FC } from 'react';
import formatNumberAsCurrency from 'utils/formatNumberAsCurrency';
import { DiscountData } from '../types';

const DiscountedExplainerText: FC<{
  selectedDiscount: DiscountData;
  addColon?: boolean;
  isBootcampSignup?: boolean;
}> = ({ selectedDiscount, addColon = false, isBootcampSignup = false }) => (
  <>
    {selectedDiscount.discountType === 'amount_off'
      ? `${formatNumberAsCurrency(selectedDiscount.discount / 100)}`
      : `${selectedDiscount.discount}%`}{' '}
    off
    {isBootcampSignup ? (
      ' your purchase'
    ) : (
      <>
        {selectedDiscount.duration === 'repeating' && (
          <span>
            {' '}
            {selectedDiscount.durationInMonths}{' '}
            {selectedDiscount.durationInMonths === 1 ? 'month' : 'months'}
          </span>
        )}
        {selectedDiscount.duration === 'once' && <span> 1 month</span>}
        {selectedDiscount.duration === 'forever' && <span> for life</span>}
      </>
    )}
    {addColon && `:`}{' '}
  </>
);
export default DiscountedExplainerText;
