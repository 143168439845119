import React from 'react';
import { Link } from 'react-router-dom';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

import { Student } from 'models';
import referralCard from './friends-and-family-referral-card.svg';

interface Props {
  student: Student;
}

const Referral: React.FC<Props> = ({ student }) => {
  const { _id: studentId } = student;

  return (
    <div className="flex flex-col">
      <Link
        to={`/learner/${studentId}/refer`}
        className="flex p-6 rounded-t-lg bg-j-purple-300 shadow-1"
        onClick={() => JuniAnalytics.track('referral_link_clicked')}
      >
        <img src={referralCard} alt="Referral Money" className="w-5/6 mx-auto" />
      </Link>
      <div className="flex flex-col gap-2 p-6 bg-white rounded-b-lg shadow-1">
        <div className="font-medium text-j-dark-600">
          Refer a friend to give them $50 off their first paid month of juni.
        </div>
        <div className="text-j-dark-400">
          You'll also earn $100 for each referral that signs up. It's a win-win!
        </div>
      </div>
    </div>
  );
};

export default Referral;
