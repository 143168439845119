import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as R from 'ramda';
import { FuseSearchResults } from './useFuse';

// Consider syncing changes to highlighting logic with
// src/app/instructor/InstructorHandbook/SearchResults/SearchResults.tsx

const Highlight = styled.b`
  background: #edf0ff;
  border-radius: 4px;
  font-weight: 600;
`;

const highlightText = (
  body: string,
  indices: (readonly [number, number])[],
  progress = 0,
): React.ReactNode[] => {
  const next = indices[0];
  if (next === undefined) {
    return [body];
  }
  // indices of start and end of highlight;
  const [i, j] = next;
  const [first, last] = next.map(x => x - progress);
  const restOfIndices = R.tail(indices);

  const [beforeHighlighted, highlightedAndRest] = R.splitAt(first, body);
  const [highlighted, restOfBody] = R.splitAt(last - first + 1, highlightedAndRest);

  return [
    <React.Fragment key={`${i} ${j}`}>
      {beforeHighlighted}
      <Highlight>{highlighted}</Highlight>
    </React.Fragment>,
    ...highlightText(restOfBody, restOfIndices, j + 1),
  ];
};

function getIndices(
  result: FuseSearchResults[0],
  inputLength: number,
  key: 'stripped' | 'title',
) {
  return R.chain(
    match => (match.key === key ? match.indices : []),
    result.matches || [],
  ).filter(([i, j]) => j - i + 1 > inputLength - inputLength / 4);
}

const HighlightedText: FC<{ result: FuseSearchResults[0]; inputLength: number }> = ({
  result,
  inputLength,
}) => {
  const indices = getIndices(result, inputLength, 'stripped');

  const head = R.head(indices);
  const last = R.last(indices);

  if (head === undefined || last === undefined) {
    return null;
  }

  const body = result.item.stripped;
  const startOfSmallestPossiblePreview = Math.max(head[0] - 100, 0);
  const endOfSmallestPossiblePreview = last[1] + 100;
  const [front] = R.splitAt(startOfSmallestPossiblePreview)(body);
  const [, back] = R.splitAt(endOfSmallestPossiblePreview)(body);
  const startOfPreview = Math.max(
    R.findLastIndex(x => x === '\n')(front.split('')) + 1,
    0,
  );
  const relativeEndOfPreviewOrNegativeOne = R.findIndex(x => x === '\n')(
    back.split(''),
  );
  const endOfPreview =
    relativeEndOfPreviewOrNegativeOne === -1
      ? 9999
      : relativeEndOfPreviewOrNegativeOne + endOfSmallestPossiblePreview;
  const preview = R.slice(startOfPreview, endOfPreview, body);
  const previewIndices = indices.map(
    ([i, j]) => [i - startOfPreview, j - startOfPreview] as const,
  );

  return (
    <>
      {startOfPreview > 0 && '...'}
      {highlightText(preview, previewIndices)}
      {endOfPreview < body.length && '...'}
    </>
  );
};

const HighlightedName: FC<{ result: FuseSearchResults[0]; inputLength: number }> = ({
  result,
  inputLength,
}) => {
  const indices = getIndices(result, inputLength, 'title');

  return (
    <>
      {indices.length > 0
        ? highlightText(result.item.title, indices)
        : result.item.title}
    </>
  );
};

export { HighlightedText, HighlightedName };
