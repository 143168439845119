import React, { FC } from 'react';
import { StripePaymentMethod, Parent } from 'generated/graphql';
import { Message } from 'core-components';

interface DefaultPaymentMethodDisplayProps {
  card:
    | Pick<
        StripePaymentMethod,
        'object' | 'brand' | 'last4' | 'exp_month' | 'exp_year'
      >
    | undefined
    | null;
  parent: Parent | undefined;
}

const DefaultPaymentMethodDisplay: FC<DefaultPaymentMethodDisplayProps> = ({
  card,
  parent,
}) => (
  <div className="flex flex-col gap-2 mb-4">
    <div className="text-md text-j-dark-400">Payment Information</div>
    {card && card.object === 'card' ? (
      <div className="flex flex-row text-j-dark-600 text-sm">
        <div className="mr-1">{`We will use your primary payment method: `}</div>
        <div className="font-semibold">{`${card?.brand}-${card?.last4}`}</div>
      </div>
    ) : (
      <div className="flex flex-col text-j-dark-600 text-sm">
        <Message status="error">
          Could not find a default payment method. Please update your information in
          the{' '}
          <a
            href={
              parent
                ? `https://app.junilearning.com/learner/${parent._id}/account`
                : `https://app.junilearning.com/account`
            }
          >
            Learner Account
          </a>
          .
        </Message>
      </div>
    )}
  </div>
);

export default DefaultPaymentMethodDisplay;
