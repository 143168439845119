import React from 'react';
import {
  JuniClubMembershipInfoPlusBadgeType,
  MsgType,
} from 'app/clubs/MyClubsTypes';
import { JuniFlavoredMarkdown } from 'components/MarkdownText';
import { StyledFormattedText } from 'components/MarkdownText/styles';
import { format, formatDistance } from 'date-fns';
import { UserInfo, ChatMessageOptions } from 'components/clubs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { red } from 'theme/old-colors';
import classNames from 'classnames';
import styled from 'styled-components/macro';

const MAX_PREVIEW_LENGTH = 140;

interface MessageProps {
  previewMode: boolean;
  message: MsgType;
  errorText?: string;
  disableImages?: boolean;
}

const ImageWrapper = styled.img`
  max-height: 50vh;
  max-width: 100%;
  width: auto;
  overflow: hidden;
  margin: 0.25rem;
`;

const ImageHolder: React.FC<{ src: string }> = ({ src }) => (
  <a href={src} target="_blank" rel="noreferrer">
    <ImageWrapper src={src} />
  </a>
);
const Message: React.FC<MessageProps> = ({
  previewMode,
  message,
  errorText,
  disableImages,
}) => {
  // backcompatibility on original message object, which do not have imageUrls, and just have a string payload
  const { payload } = message;
  const messageText = String(payload.msgText);
  const imageUrls: string[] = payload.msgImageUrls;
  return (
    <div>
      <div className="py-1 pr-1 text-blue-gray-600 text-sm overflow-hidden leading-6">
        <StyledFormattedText style={{ wordBreak: 'break-word' }}>
          <JuniFlavoredMarkdown
            text={
              previewMode
                ? `${messageText.slice(0, MAX_PREVIEW_LENGTH).trim()}${
                    messageText.length > MAX_PREVIEW_LENGTH ? '\u2026' : ''
                  }`
                : messageText
            }
          />
        </StyledFormattedText>
        {errorText && (
          <div className="italic text-red-800 mt-1 text-sm">{errorText}</div>
        )}
      </div>
      {imageUrls.length && previewMode ? (
        <div className="text-sm text-blue-gray-300">[click to view image]</div>
      ) : (
        <div className="flex flex-col flex-wrap overflow-hidden">
          {!disableImages &&
            imageUrls.map(url => <ImageHolder key={url} src={url} />)}
        </div>
      )}
    </div>
  );
};

interface ChatMessageProps {
  message: MsgType;
  sender?: JuniClubMembershipInfoPlusBadgeType;
  studentId?: string;
  instructorUserId?: string;
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
  previewMode?: boolean;
  scrollableContainerRef?: React.RefObject<HTMLDivElement>;
  hideHeader?: boolean;
  disableImages?: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  sender,
  studentId,
  instructorUserId,
  currentClubMemberState,
  previewMode = false,
  scrollableContainerRef,
  hideHeader = false,
  disableImages,
}) => {
  const {
    juniClubChannel,
    timestamp,
    payload,
    senderUUID,
    removed,
    deleted,
    isFlagged,
    isFailed,
    isMsgIdTemporary,
    flaggedReason,
  } = message;
  const clientUUID = studentId || instructorUserId;
  const sentAt = previewMode
    ? formatDistance(timestamp, new Date(), {
        addSuffix: true,
      })
    : format(timestamp, 'MMM dd, h:mm a');
  const badgeName = currentClubMemberState?.badgeInfo.name;
  const canModerate = !!(badgeName && ['advisor', 'moderator'].includes(badgeName));
  const canDelete = !!(canModerate || (clientUUID && clientUUID === senderUUID));
  const wasModeratedOrDeleted = removed || deleted;
  const didNotSend = isFlagged || isFailed;
  const showChatMessageDropdown =
    (canModerate || canDelete) &&
    !(wasModeratedOrDeleted || didNotSend || isMsgIdTemporary);
  // This is somewhat questionable, could probably update the MsgType definition to
  // be explicit about expecting a payload like {text: string, images: string[]}, not
  // sure how i feel about that though, given that the payload is explicitly any.j
  // Commentary on this would be welcome.
  const errorText = didNotSend
    ? isFlagged
      ? `This message was blocked from sending by our automated moderation system. ${flaggedReason}`
      : 'This message failed to send. Please check your internet connection.'
    : undefined;

  const emptyWithoutImages = !String(message.payload.msgText) && disableImages;
  if (emptyWithoutImages) {
    return null;
  }
  return payload && !deleted ? (
    <div
      className={classNames(
        {
          'mt-1': !hideHeader || didNotSend,
          'mb-1 bg-red-50': didNotSend,
          'hover:bg-blue-gray-100 hover:bg-opacity-20': !didNotSend && !previewMode,
        },
        'flex',
        'flex-col',
        'rounded-md',
      )}
    >
      <div className={`flex ${hideHeader ? 'pr-2' : 'p-2'} pb-1 group`}>
        <div className="flex flex-grow-1 items-center flex-wrap">
          {hideHeader ? (
            <div className="ml-14">
              <Message
                previewMode={previewMode}
                message={message}
                errorText={errorText}
                disableImages={disableImages}
              />
            </div>
          ) : (
            <UserInfo member={sender}>
              {previewMode && (
                <div className="py-1 text-sm text-blue-gray-300">
                  in #{juniClubChannel} {sentAt}
                </div>
              )}
              <Message
                previewMode={previewMode}
                message={message}
                errorText={errorText}
                disableImages={disableImages}
              />
            </UserInfo>
          )}
        </div>
        {!previewMode && (
          <div
            className={classNames(
              'flex',
              'flex-shrink-0',
              { 'mt-1.5': hideHeader },
              'ml-auto',
              'pl-2.5',
            )}
          >
            {didNotSend ? (
              <div className="font-semibold text-xs text-red-800">
                <FontAwesomeIcon
                  className="mr-1"
                  icon={faExclamationCircle}
                  color={red[800]}
                  size="lg"
                />
                Failed to send.
              </div>
            ) : (
              <div
                className={classNames(
                  'flex',
                  'flex-shrink-0',
                  'group-hover:opacity-100',
                  { 'opacity-0': hideHeader },
                )}
              >
                <div
                  className="flex flex-shrink-0 text-xs text-blue-gray-300"
                  title={format(timestamp, "MMM dd, yyyy 'at' h:mm:ss a")}
                >
                  {sentAt}
                </div>
                {showChatMessageDropdown && (
                  <ChatMessageOptions
                    message={message}
                    studentId={studentId}
                    instructorUserId={instructorUserId}
                    canModerate={canModerate}
                    canDelete={canDelete}
                    scrollableContainerRef={scrollableContainerRef}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default ChatMessage;
