import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getInitNavIdLookupInstructor } from 'services/courses';

import './juni_ide_wrapper_teacher.css';
import { JuniIDE } from 'components/jide';
import { COURSE_TYPES } from 'constants/subjects';

const JuniIDEWrapperTeacher = ({ initNav, userId, setHideNavBar }) => {
  const [isLoadingIdLookup, setIsLoadingIdLookup] = useState(true);
  const [idLookup, setIdLookup] = useState({});

  useEffect(() => {
    async function fetchData() {
      const idLookup = await getInitNavIdLookupInstructor(initNav);
      setIdLookup(idLookup);
      setIsLoadingIdLookup(false);
    }
    fetchData();
  }, [initNav]);

  return (
    <div className="jide-wrapper-teacher">
      <div className="jide-main-content">
        <JuniIDE
          jideUser={{ _id: userId, type: 'teacher' }}
          initNav={initNav}
          courseTypes={Object.values(COURSE_TYPES)}
          idLookup={idLookup}
          isLoadingIdLookup={isLoadingIdLookup}
          setHideNavBar={setHideNavBar}
          includeTrialModules
          modalViewMode
        />
      </div>
    </div>
  );
};
JuniIDEWrapperTeacher.defaultProps = {
  initNav: {},
};
JuniIDEWrapperTeacher.propTypes = {
  initNav: PropTypes.shape({
    student: PropTypes.string.isRequired,
  }),
  userId: PropTypes.string.isRequired,
};

export default JuniIDEWrapperTeacher;
