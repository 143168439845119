import React from 'react';
import classNames from 'classnames';
import { TailwindColor } from 'theme/types';

interface FooterProps {
  /**
   * Add optional styling.
   */
  className?: string;
  /**
   * Specifies border color styled with Tailwind Scale.
   * Defaults to j-dark-100.
   */
  borderColor?: TailwindColor;
}

export const HEIGHT = 64;

/**
 * Sticky bottom bar used across different Jide environments.
 */
const Footer: React.FC<FooterProps> = ({
  className,
  children,
  borderColor = 'j-dark-100',
}) => (
  <div
    className={classNames(
      className,
      `flex items-center box-border px-2 border-0 border-t border-solid border-${borderColor}`,
    )}
    // this keeps the height at a consistent 64 pixels always, inclusive of border.
    style={{ minHeight: HEIGHT, maxHeight: HEIGHT }}
  >
    {children}
  </div>
);

export default Footer;
