import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './checkbox_v2.css';

class CheckboxV2 extends Component {
  onChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e.target.checked);
    }
  };
  render() {
    const {
      className,
      id,
      isActive,
      isChecked,
      name,
      onChangeV2,
      size,
      spanStyle,
      style,
      value,
      title,
      dark = '',
    } = this.props;
    const darkStyle = dark ? 'dark' : '';
    let checkboxClassName = '';
    if (size === 'small' && isActive) {
      checkboxClassName = `checkbox-v2-container checkbox-v2-container-small ${darkStyle}`;
    } else if (size === 'small' && !isActive) {
      checkboxClassName = `checkbox-v2-container checkbox-v2-container-small checkbox-v2-container-disabled`;
    } else if (size === 'large' && isActive) {
      checkboxClassName = `checkbox-v2-container ${darkStyle} `;
    } else {
      checkboxClassName = `checkbox-v2-container checkbox-v2-container-disabled ${darkStyle} `;
    }
    return (
      <div
        title={title}
        className={`${checkboxClassName} ${className}`}
        style={style}
      >
        <input
          type="checkbox"
          id={id}
          name={name || 'check'}
          value={value}
          disabled={!isActive}
          checked={isChecked}
          onChange={onChangeV2 || this.onChange}
        />
        <label htmlFor={id}>
          <span style={spanStyle} />
        </label>
      </div>
    );
  }
}

CheckboxV2.defaultProps = {
  className: '',
  name: '',
  onChange: null,
  onChangeV2: null,
  style: null,
  spanStyle: null,
  value: '',
  title: '',
};

CheckboxV2.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeV2: PropTypes.func,
  size: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  spanStyle: PropTypes.shape({}),
  value: PropTypes.string,
  title: PropTypes.string,
};

export default CheckboxV2;
