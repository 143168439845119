import React, { FC } from 'react';
import { Route, Switch, match, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import BootcampCheckout from './pages/BootcampCheckout';
import OnDemandCheckout from './pages/OnDemandCheckout';
import CheckoutFlowWrapper from '../components/CheckoutFlowWrapper';
import { SessionFlags } from '../types';
import RedirectPage from './RedirectPage';
import ConfirmationPage from './ConfirmationPage';

interface CheckoutFlowProps extends RouteComponentProps {
  match: match;
  flags: SessionFlags;
}

const CheckoutFlow: FC<CheckoutFlowProps> = ({ match, flags, location }) => {
  const queryStringObj = queryString.parse(location.search);
  const CheckoutComponent =
    queryStringObj.courseOfferingId !== undefined
      ? BootcampCheckout
      : OnDemandCheckout;
  return (
    <CheckoutFlowWrapper theme="light" hideBanner={false} flags={flags}>
      <Switch>
        <Route
          path={`${match.path}/redirect`}
          render={props => (
            <RedirectPage
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/payment`}
          render={props => (
            <CheckoutComponent
              {...props}
              // @ts-expect-error match.signupSessionId is nullable
              match={props.match}
            />
          )}
        />
        <Route
          path={`${match.path}/confirmation`}
          render={() => <ConfirmationPage />}
        />
      </Switch>
    </CheckoutFlowWrapper>
  );
};

export default CheckoutFlow;
