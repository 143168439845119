import React from 'react';
import teacher from 'images/teacher.png';

import './default_jide_env_content.css';

const DefaultJideEnvContent = () => (
  <div className="jide-default-env-content">
    <div className="jide-default-env-content-main">
      <div className="jide-default-env-content-top">
        Your Instructor will provide you with instructions to work on this project in
        Repl.it!
      </div>
      <div className="jide-default-env-content-bottom">
        <img src={teacher} alt="" />
      </div>
    </div>
  </div>
);

export default DefaultJideEnvContent;
