import React, { useState, useEffect, useReducer, useMemo, useCallback } from 'react';
import styled from 'styled-components/macro';
import CustomProjectService from 'services/custom_projects';
import queryString from 'query-string';

import classNames from 'classnames';
import { Loading } from 'components/ui';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomProject } from 'models/custom_project';
import { parseError } from 'utils/errors';
import {
  useCreateAndInitializeEnglishProjectMutation,
  CreateAndInitializeEnglishProjectInput,
} from 'generated/graphql';
import { useCourseNameToBasicMetadata } from 'hooks/course';
import { basePlaygroundRoute } from './utils';
import PlaygroundProjectWrapper from './PlaygroundProjectWrapper';
import {
  IPlaygroundProps,
  ProjectTypes,
  IUpdateProjectNameParams,
  IValidationResponse,
  IPlaygroundProjectForView,
  IInitialFilterState,
  ProjectsViewStates,
} from './models';
import RecentProjects from './RecentProjects';
import PlaygroundQuickstart from './PlaygroundQuickstart';

const PlaygroundContainer = styled.div.attrs({ className: 'bg-juni-light-100' })`
  height: 100%;
  min-height: 100vh;
  .layout-content {
    max-width: 1240px !important;
    position: relative;
    margin: 0 auto;
  }
  & .spinner-wrapper {
    position: absolute;
    top: 75%;
    left: 50%;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
  & .top {
    position: relative;
    .layout-content {
      padding: 1rem 1rem 1.75rem;
      .projects__container > * {
        box-shadow: 0px 8.14931px 13.7956px rgb(230 241 252);
      }
      .header {
        margin: 0 0 1.5rem;
        max-width: 768px;
        p {
          margin: 0.25rem 0 0 0;
          color: #57748f;
          text-shadow: 0px 0px 0px #dbeaf8;
          line-height: 1.8;
        }
      }
    }
    @media (min-width: 992px) {
      & .top {
        .layout-content {
          padding: 1rem 2rem 2rem 2rem;
        }
      }
    }
    h1 {
      margin: 0;
      color: #274a6a;
    }
  }

  & .top.fullScreen {
    height: 100vh;
  }
`;

const makeInitialFilteredStateFromProjects = () =>
  Object.keys(ProjectTypes)
    .filter(x => !!x)
    .reduce((agg, curr) => ({ ...agg, [curr]: { visible: true } }), {});

const filterReducer = (state: IInitialFilterState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_FILTER_STATE':
      return {
        ...payload.setState,
      };
    case 'FILTER_TYPE':
      return {
        ...state,
        [payload.key]: {
          visible: payload.visible,
        },
      };
    default:
      return state;
  }
};

const isPlaygroundProject = (project: IPlaygroundProjectForView) =>
  !project.moduleId ||
  project.moduleId === 'n/a' ||
  project.moduleId === 'playground';

const isCustomProject = (project: IPlaygroundProjectForView) =>
  !!project.moduleId && !['n/a', 'playground'].includes(project.moduleId);

export const SUPPORTED_PLAYGROUND_PROJECTS = [
  'python',
  'pythonTurtle',
  'java',
  'cpp',
  'scratch',
  'whiteboard',
  'english_writing',
];

enum PlaygroundStates {
  view,
  adding,
}

const ALLOWED_QUERY_PARAMETERS = [
  'projectType',
  'projectId',
  'projectName',
  'viewState',
  // new horizons flag
  'newHorizons',
];

const Playground: React.FC<IPlaygroundProps> = props => {
  const location = useLocation();
  const history = useHistory();

  const courseNameToBasicMetadata = useCourseNameToBasicMetadata();

  const { pathname } = location;
  const { userType } = props.currentUser;
  const querystring = queryString.parse(history.location.search);

  const [loading, toggleLoading] = useState(true);
  const [viewState, setViewState] = useState(
    pathname.includes('project') ? PlaygroundStates.adding : PlaygroundStates.view,
  );
  const [projectsViewState, setProjectsViewState] = useState(
    querystring.viewState
      ? parseInt(querystring.viewState as string, 10)
      : ProjectsViewStates.playgroundProjects,
  );

  const [error, setError] = useState<undefined | string>(undefined);
  const [projects, setProjects] = useState<
    Array<IPlaygroundProjectForView> | undefined
  >(undefined);

  const [
    createAndInitializeEnglishProject,
  ] = useCreateAndInitializeEnglishProjectMutation({
    errorPolicy: 'all',
  });

  const idKey =
    userType === 'student'
      ? 'studentId'
      : userType === 'teacher'
      ? 'instructorId'
      : '';

  const userId: string =
    idKey === 'studentId'
      ? props.currentUser.activeStudentId!
      : props.currentUser._id;

  const fetchProjects = useCallback(
    async (idKey?: string) => {
      if (!idKey) return;
      try {
        const customProjectsRes = await CustomProjectService.list({
          [idKey]: userId,
        });

        const customProjects = customProjectsRes.map(project => {
          const { moduleId } = project;
          if (!moduleId) return project;
          const moduleRef = props.idLookup[moduleId] || {};
          const courseId = moduleRef?.properties?.courseId;
          if (!courseId) return project;
          const courseRef = props.idLookup[courseId] || {};
          const courseName = courseRef?.properties?.name;
          const { defaultJideEnv } = courseNameToBasicMetadata(courseName);
          return { ...project, projectType: defaultJideEnv };
        });

        setProjects([...customProjects]);
        toggleLoading(false);
      } catch (err) {
        setError('There was a problem getting the projects');
        console.error(err);
      }
    },
    [courseNameToBasicMetadata, props.idLookup, userId],
  );

  // detect if we're viewing a project or the Playground dashboard.
  // If dashboard, fetch the projects, else, render the project
  useEffect(() => {
    if (
      querystring.projectId &&
      querystring.projectType &&
      querystring.projectName &&
      pathname.includes('project')
    ) {
      setViewState(PlaygroundStates.adding);
      toggleLoading(false);
    } else if (
      (userType === 'student' &&
        props.idLookup &&
        Object.keys(props.idLookup).length > 0) ||
      userType === 'teacher'
    ) {
      setViewState(PlaygroundStates.view);
      fetchProjects(idKey);
    }
  }, [
    fetchProjects,
    idKey,
    pathname,
    props.idLookup,
    querystring.projectId,
    querystring.projectName,
    querystring.projectType,
    userType,
  ]);

  // detect a bad query parameter
  const routeHasInvalidParams =
    Object.keys(querystring).filter(x => !ALLOWED_QUERY_PARAMETERS.includes(x))
      .length > 0;
  useEffect(() => {
    if (routeHasInvalidParams) {
      history.push(
        basePlaygroundRoute(userId, userType === 'student' ? 'learner' : 'teacher'),
      );
      setViewState(PlaygroundStates.view);
    }
  }, [history, querystring, userId, userType, routeHasInvalidParams]);

  const createProject = async (projectName: string, projectType: ProjectTypes) => {
    if (!idKey) return;
    try {
      const project = await CustomProjectService.create({
        [idKey]: userId,
        displayName: projectName,
        projectType,
      });
      if (projectType === 'english_writing') {
        const newEnglishProjectData: CreateAndInitializeEnglishProjectInput = {
          projectType: 'writing_project',
          studentId: userId,
          moduleSectionId: project._id,
          isCustomProject: true,
        };
        const createRes = await createAndInitializeEnglishProject({
          variables: { input: newEnglishProjectData },
        });
        if (createRes.errors) {
          console.error(createRes.errors);
          alert(
            'Unable to create new project. Please refresh the page and try again.',
          );
          return;
        }
      }

      history.push(
        `${pathname}/project?projectId=${project._id}&projectType=${projectType}&projectName=${projectName}`,
      );
      updateLookupIdEntry(project);

      setError(undefined);
    } catch (err) {
      console.error(err);
      const errorString =
        `Sorry, that didn't work. ` +
        `Either a project with that name already exists or something else went wrong`;
      setError(parseError(err, errorString));
    }
  };

  const updateLookupIdEntry = (project: CustomProject) => {
    if (!props.idLookup) {
      return;
    }
    props.idLookup[project._id] = {
      ...project,
      id: project._id,
      isPlayground: true,
      properties: {},
    };
  };
  const deleteIdLookupEntry = (projectId: string) => {
    if (!props.idLookup) {
      return;
    }
    delete props.idLookup[projectId];
  };

  const updateProjectName = async ({
    projectId,
    desiredProjectName,
  }: IUpdateProjectNameParams): Promise<IValidationResponse | undefined> => {
    if (!projectId || !desiredProjectName) return undefined;
    try {
      const project = await CustomProjectService.update(projectId, {
        displayName: desiredProjectName,
      });

      history.replace(
        `${pathname}?projectId=${project._id}&projectType=${querystring.projectType}&projectName=${project.displayName}`,
      );

      updateLookupIdEntry(project);

      return {
        valid: true,
      };
    } catch (err) {
      console.error(err);
      const errorString =
        `Sorry, that didn't work. ` +
        `Either a project with that name already exists or something else went wrong`;
      setError(parseError(err, errorString));
    }
  };

  const deleteProject = async ({ projectId }: { projectId: string }) => {
    if (projects) {
      setProjects(projects.filter(project => project._id !== projectId));
      deleteIdLookupEntry(projectId);
    }
    try {
      CustomProjectService.remove(projectId);
    } catch (e) {
      console.error(e);
      fetchProjects();
    }
  };

  const filteredProjects = useMemo(
    () =>
      projects
        ? projects.filter(project =>
            project.projectType !== undefined &&
            projectsViewState === ProjectsViewStates.customProjects
              ? isCustomProject(project)
              : isPlaygroundProject(project),
          )
        : [],
    [projects, projectsViewState],
  );

  // for the dropdown to filter the viewed projects by project type
  const [filteredState, dispatchFilterEvent] = useReducer(filterReducer, {});
  useEffect(() => {
    dispatchFilterEvent({
      type: 'SET_FILTER_STATE',
      payload: {
        setState: makeInitialFilteredStateFromProjects(),
      },
    });
  }, [filteredProjects]);

  // filter, sort, and remove duplicates from the projects
  const projectsToDisplay = useMemo(
    () =>
      orderBy(
        uniqBy(
          filteredProjects.filter(
            project =>
              filteredState[project.projectType || 'unknown']?.visible === true,
          ),
          '_id',
        ),
        ({ updatedAt }) => updatedAt || '',
        ['desc'],
      ),
    [filteredProjects, filteredState],
  );

  if (viewState === PlaygroundStates.view && loading) {
    return (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      {viewState === PlaygroundStates.view ? (
        <PlaygroundContainer>
          <div
            className={classNames({
              top: true,
              fullScreen: !projects || projects.length === 0,
            })}
          >
            <div className="layout-content">
              <div className="header">
                <h1>Playground</h1>
                <p className="p">
                  The Playground is where you can build freely to practice your
                  skills and publish your own unique projects. Create a new project
                  to get started! Or, pick up where you left off on a recent project.
                </p>
              </div>
              <PlaygroundQuickstart
                errorFromParent={error}
                clearErrorFromParent={() => setError(undefined)}
                createProject={createProject}
                projects={projectsToDisplay}
                deleteProject={deleteProject}
                {...props}
              />
            </div>
          </div>
          {projects && projects.length > 0 && (
            <RecentProjects
              projects={projectsToDisplay}
              filteredState={filteredState}
              dispatchFilterEvent={dispatchFilterEvent}
              projectsViewState={projectsViewState}
              setProjectsViewState={setProjectsViewState}
              deleteProject={deleteProject}
              {...props}
            />
          )}
        </PlaygroundContainer>
      ) : viewState === PlaygroundStates.adding ? (
        <PlaygroundProjectWrapper
          idKey={idKey}
          projectName={querystring?.projectName as string}
          projectId={querystring?.projectId as string}
          projectType={querystring?.projectType as string}
          jideUser={{
            ...props.currentUser,
            _id: userId,
            type: props.currentUser.userType,
          }}
          returnToPlayground={() => {
            setViewState(PlaygroundStates.view);
            history.push(
              basePlaygroundRoute(
                userId,
                userType === 'student' ? 'learner' : 'teacher',
              ),
            );
          }}
          updateProjectName={updateProjectName}
          setHideNavBar={props.setHideNavBar}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Playground;
