import React, { FC, useState } from 'react';

import { CaretIcon } from 'components/PortalIcons';
import useScrollLock from 'hooks/scrollLock';

import { PortalNavProps } from '../../types';
import {
  PortalDropdownNavWrapper,
  DropdownNavBar,
  DropdownNavOptions,
  TextAndIcon,
  WelcomeWrapper,
  WelcomeText,
  MobileLink,
  MobileButton,
  LinkText,
  MobileActiveComponentOverlay,
} from './mobile_styles';

interface OptionProps {
  isActive: boolean;
  path: string;
  handleClick: () => void;
  setImpersonatedPath?: PortalNavProps['setImpersonatedPath'];
}

const Option: FC<OptionProps> = ({
  isActive,
  path,
  handleClick,
  setImpersonatedPath,
  children,
}) =>
  setImpersonatedPath ? (
    <MobileButton
      key={path}
      active={isActive}
      onClick={() => {
        setImpersonatedPath(path);
        handleClick();
      }}
    >
      {children}
    </MobileButton>
  ) : (
    <MobileLink key={path} to={path} $active={isActive} onClick={handleClick}>
      {children}
    </MobileLink>
  );

const PortalNavMobile: FC<PortalNavProps> = ({
  activeOption,
  options,
  userFirstName,
  setImpersonatedPath,
}) => {
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  useScrollLock(showDropdownOptions);
  return (
    <>
      <PortalDropdownNavWrapper>
        <DropdownNavBar onClick={() => setShowDropdownOptions(!showDropdownOptions)}>
          <TextAndIcon>
            {activeOption.icon}
            <LinkText active>{activeOption.name}</LinkText>
          </TextAndIcon>
          <CaretIcon arrowUp={showDropdownOptions} />
        </DropdownNavBar>
        {showDropdownOptions && (
          <DropdownNavOptions>
            <WelcomeWrapper>
              <WelcomeText>{`Welcome ${userFirstName}!`}</WelcomeText>
            </WelcomeWrapper>
            {options.map(option => {
              const { icon, name, path } = option;
              const isActive = path === activeOption.path;
              return (
                <Option
                  key={path}
                  path={path}
                  isActive={isActive}
                  handleClick={() => setShowDropdownOptions(false)}
                  setImpersonatedPath={setImpersonatedPath}
                >
                  {icon}
                  <LinkText active={isActive}>{name}</LinkText>
                </Option>
              );
            })}
          </DropdownNavOptions>
        )}
      </PortalDropdownNavWrapper>
      {showDropdownOptions && (
        <MobileActiveComponentOverlay
          onClick={() => setShowDropdownOptions(!showDropdownOptions)}
        />
      )}
    </>
  );
};

export default PortalNavMobile;
