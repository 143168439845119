import React, { useEffect, useState } from 'react';
import { ModalWindow } from 'core-components';
import clubColors from 'theme/club-colors';

import {
  JuniClubType,
  useCreateClubMutation,
  useUpdateClubMutation,
} from 'generated/graphql';
import { useUpdateMyClubsState } from 'app/clubs/stores/ClubStoreHelpers';
import { getClubLink } from 'app/clubs/helpers';
import { ColorType } from 'app/clubs/MyClubsTypes';
import { CoverSelect } from './CoverSelect';
import { ClubDetails, ClubDetailsFormValues } from './ClubDetails';
import { ClubSuccess } from './ClubSuccess';

export interface ViewInfo {
  name: string;
  title: string;
  visible: boolean;
}

export interface ViewModalProps {
  visible: boolean;
  changeViewState: (direction: 'next' | 'back') => void;
  juniClub?: JuniClubType;
}

interface ClubCreatorProps {
  studentId?: string;
  instructorUserId?: string;
  requiresRefresh: boolean;
  modalOpen: boolean;
  handleClose: () => void;
  /* Passed in to edit a club */
  juniClub?: JuniClubType;
}

const ClubCreator: React.FC<ClubCreatorProps> = ({
  studentId,
  instructorUserId,
  requiresRefresh,
  modalOpen,
  handleClose,
  juniClub,
}) => {
  // Form state variables, passed into lower level components.
  const DEFAULT_COVER_IMAGE =
    'https://s3-us-west-1.amazonaws.com/clubs.junilearning.com/coverImages/_CreateClubHeader.jpg';
  const INITIAL_CLUB_DETAILS = {
    clubName: '',
    description: '',
    color: '',
  };
  const [clubColor, setClubColor] = useState<keyof typeof clubColors | undefined>(
    undefined,
  );
  const [clubDetails, setClubDetails] = useState<ClubDetailsFormValues>({
    ...INITIAL_CLUB_DETAILS,
  });
  const [coverImgSrc, setCoverImgSrc] = useState(DEFAULT_COVER_IMAGE);
  // View state and routing variables
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [clubUrl, setClubUrl] = useState('');
  const [currViewIndex, setCurrViewIndex] = useState(0);
  const [originalStudentId, setOriginalStudentId] = useState(studentId);
  // Mutations and hooks
  const [createClub] = useCreateClubMutation();
  const [updateClub] = useUpdateClubMutation();
  const updateMyClubsState = useUpdateMyClubsState();

  /* The modal ModalWindow does not unmount, so we need these below functions 
     and conditions to reset the forms manually. */
  const createModeResetForms = () => {
    setClubDetails(INITIAL_CLUB_DETAILS);
    setClubColor(undefined);
    setCoverImgSrc(DEFAULT_COVER_IMAGE);
    setSubmissionSuccess(false);
  };
  const editModeResetForms = (juniClub: JuniClubType) => {
    setClubDetails({
      clubName: juniClub.displayName,
      description: juniClub.description || '',
      color: juniClub.colorTheme || '',
    });
    setClubColor(juniClub.colorTheme as ColorType);
    setCoverImgSrc(juniClub.coverPhoto || '');
    setSubmissionSuccess(false);
  };
  /* During edit mode, you should always re-populate forms when club changes. */
  useEffect(() => {
    if (juniClub) editModeResetForms(juniClub);
  }, [juniClub]);
  /* Clear modal if studentID changes */
  if (originalStudentId !== studentId) {
    createModeResetForms();
    setOriginalStudentId(studentId);
  }
  // Data used for each child modal window.
  const VIEW_DATA = [
    {
      name: 'DETAILS',
      title: juniClub ? 'Edit Club' : 'Create a new club',
    },
    {
      name: 'COVER',
      title: 'Choose a cover photo',
    },
    {
      name: 'SUCCESS',
      title: 'Club Saved!',
    },
  ];
  const { name: viewName, title } = VIEW_DATA[currViewIndex];

  const handleUpdateClub = async () =>
    updateClub({
      variables: {
        input: {
          juniClubId: juniClub?._id,
          description: clubDetails.description,
          colorTheme: clubDetails.color,
          coverPhoto: coverImgSrc,
          studentId,
          instructorUserId,
        },
      },
    });
  const submitClub = async () => {
    const response = await createClub({
      variables: {
        input: {
          displayName: clubDetails.clubName,
          description: clubDetails.description,
          colorTheme: clubDetails.color,
          coverPhoto: coverImgSrc,
          studentId,
          instructorUserId,
        },
      },
    });
    // Get returned club, add to the club store.
    const juniClubId = response?.data?.createClub?.juniClub?._id;
    await updateMyClubsState({
      studentId,
      instructorUserId,
      specificClubs: [juniClubId],
    });
    setClubUrl(getClubLink({ studentId, juniClubId }));
  };
  const onClose = () => {
    /* Create mode: Clear forms if we have sucessfully created a club from scratch. */
    if (!juniClub && submissionSuccess) {
      createModeResetForms();
    }
    /* edit mode, reset form whenever closed */
    if (juniClub) {
      editModeResetForms(juniClub);
    }
    setCurrViewIndex(0);
    handleClose();
  };
  /* Move view state forward or backwards */
  const changeViewState: ViewModalProps['changeViewState'] = direction => {
    setCurrViewIndex(index =>
      direction === 'next'
        ? Math.min(index + 1, VIEW_DATA.length - 1)
        : Math.max(index - 1, 0),
    );
  };
  return (
    <ModalWindow
      isOpen={modalOpen}
      closeModal={onClose}
      title={title}
      bannerImgSrc={coverImgSrc}
      bannerHeight="30vh"
      maxWidth="100%"
      underBannerColor={clubColor ? clubColors[clubColor].light : undefined}
    >
      <ClubDetails
        visible={viewName === 'DETAILS'}
        changeViewState={changeViewState}
        changeColor={setClubColor}
        setClubDetails={setClubDetails}
        initialClubValues={clubDetails}
        juniClub={juniClub}
      />
      <CoverSelect
        visible={viewName === 'COVER'}
        changeViewState={changeViewState}
        setSrcImg={setCoverImgSrc}
        setSubmissionSuccess={setSubmissionSuccess}
        submitClub={juniClub ? handleUpdateClub : submitClub}
        juniClub={juniClub}
        handleClose={onClose}
      />
      <ClubSuccess
        visible={viewName === 'SUCCESS'}
        changeViewState={changeViewState}
        requiresRefresh={requiresRefresh}
        clubUrl={clubUrl}
        juniClub={juniClub}
      />
    </ModalWindow>
  );
};
export default ClubCreator;
