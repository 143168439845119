import React, { useState } from 'react';
import styled from 'styled-components/macro';
import WarningBlock from 'components/WarningBlock/WarningBlock';
import { TrashIcon } from 'components/Icons';

const DeleteProjectContainer = styled.div`
  display: flex;

  .delete-link {
    display: flex;
    align-items: center;
    color: #da0f0f;
    & .delete-text {
      font-family: 'Open Sans';
      text-align: center;
      font-weight: 700;
    }
    & .icon {
      path {
        fill: #da0f0f;
      }
    }
  }
`;

const DeleteModal = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: arrow;

  & .delete-modal-body__container {
    display: flex;
    background: white;
    min-width: 24.375rem;
    max-width: 29.375rem;
    border: 2px solid #c5eaff;
    box-shadow: 0px 8px 12px rgb(2 56 86 / 26%);
    box-sizing: border-box;
    border-radius: 0.625rem;
    overflow: hidden;
  }
`;
const DeleteProject: React.FC<{ deleteProject: () => void }> = ({
  deleteProject,
}) => {
  const stopPropagationShorthand = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault(); // prevents the click from bubbling to <Link /> component in ProjectCard
    e.stopPropagation(); // Prevents the click from triggering the close badge function on badge container
  };
  const [modalIsOpen, toggleModalIsOpen] = useState(false);

  const onDelete = () => {
    deleteProject();
    toggleModalIsOpen(false);
  };
  return (
    <DeleteProjectContainer onClick={stopPropagationShorthand}>
      <div className="delete-link" onClick={() => toggleModalIsOpen(true)}>
        <TrashIcon /> <span className="delete-text">Delete Project</span>
      </div>
      {modalIsOpen && (
        <DeleteModal onClick={() => toggleModalIsOpen(false)}>
          <div
            onClick={stopPropagationShorthand}
            className="delete-modal-body__container"
          >
            <WarningBlock
              cancelWarning={() => toggleModalIsOpen(false)}
              completeWarning={onDelete}
              headerText="Are you sure you wish to delete this project?"
              subHeaderText="This action cannot be reversed!"
              primaryActionText="Delete"
            />
          </div>
        </DeleteModal>
      )}
    </DeleteProjectContainer>
  );
};

export default DeleteProject;
