import { SizeMap } from './types';

export const paddingStyles: SizeMap = {
  regular: 'py-1 px-2',
  medium: 'py-1.5 px-2',
};

export const fontStyles: SizeMap = {
  regular: 'leading-4 text-2xs',
  medium: 'leading-5 text-xs',
};

export const iconStyles: SizeMap = {
  regular: 'w-3 h-3',
  medium: 'w-4 h-4',
};

export const sectionIconStyles: SizeMap = {
  regular: 'h-2.5 w-auto',
  medium: 'h-3 w-auto',
};
