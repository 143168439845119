import { SIGNUP_SESSION_COOKIE_NAME } from 'constants/signup_sessions';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';

import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';

import { addDays } from 'date-fns';
import * as R from 'ramda';
import UserContext from 'modules/UserContext';
import { JUNI_COOKIES } from 'constants/cookies';
import { SignupData } from '../types';

const makeSignupSessionCookieParams = (
  _id: string,
): [
  string,
  string,
  {
    expires?: Date;
    domain: string;
    path: string;
  },
] => [
  SIGNUP_SESSION_COOKIE_NAME,
  _id,
  {
    expires: addDays(new Date(), 3),
    domain: process.env.NODE_ENV === 'production' ? 'junilearning.com' : '',
    path: '/',
  },
];

const { JUNI_ANONYMOUS_ID } = JUNI_COOKIES.NAMES;

const useSignupSession = (
  signupSessionId?: string,
): {
  signupData: Partial<SignupData>;
  setSignupData: React.Dispatch<React.SetStateAction<Partial<SignupData>>>;
  sessionIsLoading: boolean;
} => {
  const { user } = useContext(UserContext);
  const userIsAdmin = user?.roles.includes('admin');

  const [cookies, setCookie, removeCookie] = useCookies([
    SIGNUP_SESSION_COOKIE_NAME,
    JUNI_ANONYMOUS_ID,
  ]);
  const [signupData, setSignupData] = useState<Partial<SignupData>>({});
  const [sessionIsLoading, setSessionIsLoading] = useState(true);
  const isMountedRef = useRef(false);

  const signupSessionsService = makeSignupSessionsService(signupData._id);

  // If the user is logged in as an admin, clear the sessionId from cookies
  if (userIsAdmin && cookies[SIGNUP_SESSION_COOKIE_NAME]) {
    removeCookie(SIGNUP_SESSION_COOKIE_NAME, {
      path: '/',
      domain: process.env.NODE_ENV === 'production' ? 'junilearning.com' : '',
    });
  }

  useEffect(() => {
    if (isMountedRef.current === true) return;

    const createAndSetNewSession = async () => {
      const newSession = await signupSessionsService.createSession({
        referringUrl: window.location.href,
        // convert fallback to undefined since localStorage falls back to null
        anonymousId: cookies[JUNI_ANONYMOUS_ID],
      });

      if (newSession?.data.data) {
        setSignupData(newSession.data.data);
        if (!userIsAdmin) {
          setCookie(...makeSignupSessionCookieParams(newSession.data.data._id));
        }
        return setSessionIsLoading(false);
      }
    };

    const getExistingSession = async () => {
      try {
        if (signupSessionId || signupData._id) {
          const res = await signupSessionsService.findSession(
            signupSessionId ?? signupData._id,
          );
          return res;
        }
      } catch {
        return undefined;
      }
    };

    const findOrCreateSession = async () => {
      if (
        !signupSessionId &&
        (!cookies[SIGNUP_SESSION_COOKIE_NAME] || userIsAdmin)
      ) {
        return createAndSetNewSession();
      }

      try {
        const existingSessionFromParameter = signupSessionId
          ? await getExistingSession()
          : undefined;

        const existingSession =
          existingSessionFromParameter ??
          (await signupSessionsService.findSession(
            cookies[SIGNUP_SESSION_COOKIE_NAME] ?? signupSessionId,
          ));

        if (existingSession?.data.data) {
          setSignupData(existingSession?.data.data);
          if (
            (!cookies[SIGNUP_SESSION_COOKIE_NAME] ||
              cookies[SIGNUP_SESSION_COOKIE_NAME] !==
                existingSession.data.data._id) &&
            !existingSessionFromParameter &&
            !userIsAdmin
          ) {
            setCookie(
              ...makeSignupSessionCookieParams(existingSession.data.data._id),
            );
          }

          return setSessionIsLoading(false);
        }
      } catch (e) {
        if (e?.response?.status === 404) {
          createAndSetNewSession();
        }
      }
    };

    if (R.isEmpty(signupData)) {
      findOrCreateSession();
      isMountedRef.current = true;
    }
  }, [
    cookies,
    setCookie,
    signupData,
    signupSessionId,
    signupSessionsService,
    userIsAdmin,
  ]);

  return { signupData, setSignupData, sessionIsLoading };
};

export default useSignupSession;
