import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

const positions = ['top', 'left', 'right', 'bottom'] as const;
type Position = typeof positions[number];

const PopperContainer = styled.div<{ themeDark?: boolean }>`
  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    &:after {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: ${props => (props.themeDark ? '#413e75' : '#F8FAFF')};
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -10px;
    :after {
      top: -5px;
      left: 0;
      border-radius: 0 0 4px 0;
    }
  }
  &[data-popper-placement^='bottom'] > .arrow {
    top: -10px;
    :after {
      bottom: -5px;
      left: 0;
      border-radius: 4px 0 0 0;
    }
  }
  &[data-popper-placement^='right'] > .arrow {
    left: -10px;
    :after {
      right: -5px;
      bottom: 0;
      border-radius: 0 0 0 4px;
    }
  }
  &[data-popper-placement^='left'] > .arrow {
    right: -10px;
    :after {
      left: -5px;
      top: 0;
      border-radius: 0 4px 0 0;
    }
  }
`;
export interface TooltipProps {
  /**
   * The text shown in the tooltip
   */
  title: ReactNode;
  /**
   * Wrapped children element.
   */
  children: React.ReactElement;
  /**
   * If true, adds an arrow pointer to the tooltip.
   * Default true. Only works for primary.
   */
  arrow?: boolean;
  /**
   * Tooltip placement.
   * Default bottom.
   */
  placement?: Position;
  /**
   * Theme of the tooltip, light or dark
   * Default Dark
   */
  theme?: 'light' | 'dark';
  /**
   * Specifies the variant of the tooltip.
   * Default Primary
   */
  variant?: 'primary' | 'minimal';
  /** Offset in pixel from tooltip to item */
  offset?: number;
  /** ClassName */
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  arrow = true,
  placement = 'bottom',
  theme = 'dark',
  variant = 'primary',
  offset = 8,
  className,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, offset],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom', 'right', 'top', 'left'],
        },
      },
    ],
  });

  const textColor = classNames({
    'text-white bg-j-dark-500': theme === 'dark',
    'text-j-dark-600 bg-j-gray-100': theme === 'light',
  });

  const variantStyle = classNames({
    'p-2.5 text-sm rounded-lg': variant === 'primary',
    'p-1 text-xs rounded-md': variant === 'minimal',
  });

  return (
    <div className={className}>
      <div
        ref={setReferenceElement}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      >
        {children}
      </div>
      {isVisible && (
        <PopperContainer
          ref={setPopperElement}
          themeDark={theme === 'dark'}
          className="max-w-xs"
          style={styles.popper}
          {...attributes.popper}
        >
          <div className={`${textColor} ${variantStyle} shadow-4`}>{title}</div>
          {arrow && variant === 'primary' && (
            <div
              ref={setArrowElement}
              style={styles.arrow}
              className="arrow shadow-4"
            />
          )}
        </PopperContainer>
      )}
    </div>
  );
};

export default Tooltip;
