import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { ContentState } from 'draft-js';

import { HIGHLIGHT_COLOR_VALUES } from 'components/RichTextAnnotator/constants';

const AnnotatedHighlight: React.FC<{
  contentState: ContentState;
  entityKey: string;
  setHighlightRef: (entityKey: string, ref: any) => void;
  children: any;
  unfocus: () => void;
  onClickAnnotation: (entityKey: string) => void;
  decoratedText: string;
}> = ({
  contentState,
  entityKey,
  setHighlightRef,
  children,
  unfocus,
  onClickAnnotation,
  decoratedText,
}) => {
  const highlightedRef = useRef<HTMLSpanElement>(null);
  const entity = contentState.getEntity(entityKey);
  const data = entity.getData();
  const originallySelectedText = data.selectedText;

  useEffect(() => {
    /* For highlights that cross multiple blocks, several AnnotatedHighlight components will be rendered (one per block).
     * Only set the highlight ref for the first AnnotatedHighlight if they are split up across blocks.
     * This way, the annotation will stay vertically aligned with the first AnnotatedHighlight instead of the last.
     */
    if (!originallySelectedText.startsWith(decoratedText)) return;
    setHighlightRef(entityKey, highlightedRef);
  }, [
    entityKey,
    highlightedRef,
    originallySelectedText,
    decoratedText,
    setHighlightRef,
  ]);

  const { highlightColor } = data;
  const highlightColorValue =
    HIGHLIGHT_COLOR_VALUES[highlightColor].translucentValue;

  const preventHighlighting = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    unfocus();
  };
  const handleClick = (e: React.MouseEvent) => {
    preventHighlighting(e);
    onClickAnnotation(entityKey);
  };

  return (
    <StyledHighlight
      color={highlightColorValue}
      onClick={handleClick}
      onMouseDown={preventHighlighting}
      ref={highlightedRef}
    >
      {children}
    </StyledHighlight>
  );
};
const StyledHighlight = styled.span`
  position: relative;
  background: ${props => props.color};
  border-radius: 3px;
`;

export default AnnotatedHighlight;
