export const GRADE_LEVELS = [
  {
    value: 'kindergarden',
    displayName: 'Kindergarden',
  },
  {
    value: '1st',
    displayName: '1st Grade',
  },
  {
    value: '2nd',
    displayName: '2nd Grade',
  },
  {
    value: '3rd',
    displayName: '3rd Grade',
  },
  {
    value: '4th',
    displayName: '4th Grade',
  },
  {
    value: '5th',
    displayName: '5th Grade',
  },
  {
    value: '6th',
    displayName: '6th Grade',
  },
  {
    value: '7th',
    displayName: '7th Grade',
  },
  {
    value: '8th',
    displayName: '8th Grade',
  },
  {
    value: '9th',
    displayName: '9th Grade',
  },
  {
    value: '10th',
    displayName: '10th Grade',
  },
  {
    value: '11th',
    displayName: '11th Grade',
  },
  {
    value: '12th',
    displayName: '12th Grade',
  },
  {
    value: 'college',
    displayName: 'College',
  },
  {
    value: 'none',
    displayName: 'N/A',
  },
];
