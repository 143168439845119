import React, { Component, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { shareableProjectTypes } from 'services/juni_ide';
import {
  JideEnvCpp,
  JideEnvDefault,
  JideEnvEnglish,
  JideEnvInvesting,
  JideEnvJava,
  JideEnvJs,
  JideEnvMath,
  JideEnvPython,
  JideEnvPythonTurtle,
  JideEnvScratch,
  JideEnvEmptyState,
} from 'components/jide/env';
import { PUBLISHABLE_SECTION_TYPES } from 'constants/course_section_types';
import { useCourseNameToBasicMetadata } from 'hooks/course';
import './jide_environment_bar.css';
import { useMarkOnDemandModuleSectionInProgressMutation } from 'generated/graphql';

import usePrevious from 'hooks/usePrevious';
import { JideEnvBarContext } from './JideEnvBarContext';

const JideEnvironmentBar = props => {
  const [fullScreenMode, toggleFullScreenMode] = useState(false);
  const [raiseZIndex, toggleRaiseZIndex] = useState(false);

  useEffect(() => {
    document.body.style.overflow = fullScreenMode ? 'hidden' : 'visible';
  }, [fullScreenMode]);

  const jideEnvironments = props.openTabs.map(t => (
    <JideEnvironmentContainer
      {...props}
      key={`envContainer_${t.tabId.toString()}`}
      isActive={t.tabId === props.activeTabId}
      tab={t}
      updateProjectName={props.updateProjectName}
      toggleFullScreenMode={() => toggleFullScreenMode(!fullScreenMode)}
      fullScreenMode={fullScreenMode}
    />
  ));
  return (
    <div
      className="jide-eb"
      style={{
        zIndex: raiseZIndex && !fullScreenMode ? 101 : 'auto',
      }}
    >
      <JideEnvBarContext.Provider
        value={{
          raiseZIndex,
          toggleRaiseZIndex,
        }}
      >
        {jideEnvironments}
      </JideEnvBarContext.Provider>
    </div>
  );
};

class JideEnvironmentContainerClassComponent extends Component {
  state = {
    fullScreenMode: false,
  };

  componentDidMount = () => {
    const course = this.props.idLookup[this.props.tab.tabNav.course];
    const courseName = course?.properties?.name;
    const envName = this.props.playgroundProject
      ? this.props.playgroundProject.projectType
      : this.props.courseNameToBasicMetadata(courseName).defaultJideEnv;
    if (this.props.setJuniverseEnvType) {
      this.props.setJuniverseEnvType(envName);
    }
  };

  render() {
    const course = this.props.idLookup[this.props.tab.tabNav.course];
    const courseName = course?.properties?.name || 'playground';
    const projectInfo = this.props.idLookup[this.props.tab.tabNav.project];
    const sectionType = projectInfo?.properties?.sectionType;
    const environmentTypeRaw = this.props.playgroundProject
      ? this.props.playgroundProject.projectType
      : this.props.courseNameToBasicMetadata(courseName).defaultJideEnv;
    const environmentType =
      this.props.jideUser.type === 'public' &&
      !shareableProjectTypes.includes(environmentTypeRaw) &&
      !sectionType &&
      !PUBLISHABLE_SECTION_TYPES.includes(sectionType)
        ? 'public_non_renderable'
        : environmentTypeRaw;
    const curCourseHasVideos = course
      ? course.children.findIndex(
          m => m.properties.videos && m.properties.videos.length > 0,
        ) !== -1
      : false;

    const { schedulingFormat } = this.props.courseNameToBasicMetadata(courseName);

    const showVideosWidget =
      !(this.props.modalViewMode || this.props.showcaseViewMode) &&
      this.props.jideUser &&
      this.props.jideUser.type === 'student' &&
      curCourseHasVideos &&
      !this.props.recordingMode;

    const defaultPropsToPass = {
      activeNav: this.props.activeNav,
      tab: this.props.tab,
      showVideosWidget,
      idLookup: this.props.idLookup,
      jideUser: this.props.jideUser,
      learnerAnalyticsEnabled: this.props.learnerAnalyticsEnabled,
      playgroundProject: this.props.playgroundProject,
      updateProjectName: this.props.updateProjectName,
      juniverseProjectData: this.props.juniverseProjectData,
      recordingMode: this.props.recordingMode,
      setRecordingMode: this.props.setRecordingMode,
      encryptedStudentAndProject: this.props.encryptedStudentAndProject,
      isCodeHidden: this.props.isCodeHidden,
      schedulingFormat,
    };

    let jideEnvironment = null;
    switch (environmentType) {
      case 'scratch':
        jideEnvironment = (
          <JideEnvScratch
            key={`env_${this.props.tab.tabId.toString()}`}
            isActive={this.props.isActive}
            isLoading={this.props.isLoading}
            {...defaultPropsToPass}
          />
        );
        break;
      case 'java':
        jideEnvironment = (
          <JideEnvJava
            key={`env_${this.props.tab.tabId.toString()}`}
            isActive={this.props.isActive}
            fullScreenMode={this.props.fullScreenMode}
            toggleFullScreenMode={this.props.toggleFullScreenMode}
            courseName={courseName}
            defaultLang="java"
            {...defaultPropsToPass}
            newHorizons
          />
        );
        break;
      case 'cpp':
        jideEnvironment = (
          <JideEnvCpp
            key={`env_${this.props.tab.tabId.toString()}`}
            isActive={this.props.isActive}
            fullScreenMode={this.props.fullScreenMode}
            toggleFullScreenMode={this.props.toggleFullScreenMode}
            courseName={courseName}
            defaultLang="cpp"
            {...defaultPropsToPass}
            newHorizons
          />
        );
        break;
      case 'pythonTurtle':
        jideEnvironment = (
          <JideEnvPythonTurtle
            key={`env_${this.props.tab.tabId.toString()}`}
            isActive={this.props.isActive}
            fullScreenMode={this.props.fullScreenMode}
            toggleFullScreenMode={this.props.toggleFullScreenMode}
            newHorizons
            {...defaultPropsToPass}
          />
        );
        break;
      case 'python':
        jideEnvironment = (
          <JideEnvPython
            key={`env_${this.props.tab.tabId.toString()}`}
            isActive={this.props.isActive}
            fullScreenMode={this.props.fullScreenMode}
            toggleFullScreenMode={this.props.toggleFullScreenMode}
            courseName={courseName}
            defaultLang="python"
            {...defaultPropsToPass}
            newHorizons
          />
        );
        break;
      case 'whiteboard':
      case 'math':
      case 'math_whiteboard':
        jideEnvironment = (
          <JideEnvMath
            key={`env_${this.props.tab.tabId.toString()}`}
            modalViewMode={this.props.modalViewMode}
            shiftKeyIsPressed={this.props.shiftKeyIsPressed}
            newHorizons
            {...defaultPropsToPass}
          />
        );
        break;
      case 'english':
      case 'english_writing':
      case 'english_whiteboard':
        jideEnvironment = (
          <JideEnvEnglish
            key={`env_${this.props.tab.tabId.toString()}`}
            modalViewMode={this.props.modalViewMode}
            shiftKeyIsPressed={this.props.shiftKeyIsPressed}
            newHorizons
            {...defaultPropsToPass}
          />
        );
        break;
      case 'investing':
      case 'investing_whiteboard':
      case 'investing_writing':
        jideEnvironment = (
          <JideEnvInvesting
            key={`env_${this.props.tab.tabId.toString()}`}
            modalViewMode={this.props.modalViewMode}
            shiftKeyIsPressed={this.props.shiftKeyIsPressed}
            newHorizons
            {...defaultPropsToPass}
          />
        );
        break;
      case 'js':
        jideEnvironment = (
          <JideEnvJs
            key={`env_${this.props.tab.tabId.toString()}`}
            isActive={this.props.isActive}
            fullScreenMode={this.props.fullScreenMode}
            toggleFullScreenMode={this.props.toggleFullScreenMode}
            courseName={courseName}
            defaultLang="js"
            {...defaultPropsToPass}
            newHorizons
          />
        );
        break;
      case 'public_non_renderable':
        jideEnvironment = (
          <JideEnvEmptyState key={`env_${this.props.tab.tabId.toString()}`} />
        );
        break;
      default:
        jideEnvironment = (
          <JideEnvDefault key={`env_${this.props.tab.tabId.toString()}`} />
        );
    }

    return (
      <div
        className={`jide-environment-container${
          this.props.isActive ? ' jide-environment-container-active' : ''
        }${
          this.props.fullScreenMode ? ' jide-environment-container-fullscreen' : ''
        }`}
      >
        {jideEnvironment}
      </div>
    );
  }
}
const JideEnvironmentContainer = props => {
  const courseNameToBasicMetadata = useCourseNameToBasicMetadata();
  const [
    markOnDemandModuleSectionProgress,
  ] = useMarkOnDemandModuleSectionInProgressMutation();

  const prevActiveTabId = usePrevious(props.activeTabId);

  const markCourseProgress = useCallback(() => {
    // Note: The parent component JideEnvironmentBar renders
    // an array of JideEnvironmentContainer for each open tab using the prop openTabs.
    // The problem here is that when a student opens a new tab, the openTabs array
    // changes along with the activeTabId. However, it's not guaranteed they change at
    // the same time so this component is re-rendered twice, one for each prop change.
    // The first re-render could be that the openTabs has changed, but the activeTabId has not yet.
    // We want to avoid that case since this will call the mutation to mark the course progress
    // for the already marked section.
    const activeTabHasChanged = prevActiveTabId !== props.activeTabId;

    if (props.isActive && activeTabHasChanged) {
      const { activeNav, idLookup, tab } = props;
      if (!_.isEmpty(activeNav)) {
        const course = idLookup[tab.tabNav.course];
        const courseName = course?.properties?.name;

        const { schedulingFormat } = courseNameToBasicMetadata(courseName);
        const isOnDemand = schedulingFormat === 'on_demand';

        const {
          module: moduleId,
          project: sectionId,
          student: studentId,
          course: courseCurriculumId,
        } = activeNav;
        const studentInfo = idLookup[studentId];

        const projectInfo = idLookup[courseCurriculumId]?.children
          ?.find(module => module.id === moduleId)
          ?.children?.find(section => section.id === sectionId);

        const isCustomProject = projectInfo?.properties?.isCustomProject;
        if (studentInfo) {
          const inProgress = studentInfo?.inProgress;

          const sectionIsMarkedInProgress = inProgress
            ? inProgress[sectionId]
            : false;
          // Note: We don't mark custom projects because they are not part of a module
          // section in the db which will cause the mutation to throw an error
          // TODO: Remove feature flag after testing
          if (
            isOnDemand &&
            !sectionIsMarkedInProgress &&
            !isCustomProject &&
            studentId === '61647dcc0499a243d0de1c3c'
          ) {
            markOnDemandModuleSectionProgress({
              variables: {
                input: {
                  moduleId,
                  sectionId,
                  courseCurriculumId,
                  studentId,
                },
              },
            });
          }
        }
      }
    }
    // Note: this is disabled because it wants props as a dependency.
    // The problem with that is react hooks compares objects by references
    // and whenever a component re-renders it creates a new reference to that object
    // regardless if the values are the same or not.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseNameToBasicMetadata, props.activeTabId, prevActiveTabId]);

  useEffect(() => {
    markCourseProgress();
  }, [markCourseProgress]);

  return (
    <JideEnvironmentContainerClassComponent
      courseNameToBasicMetadata={courseNameToBasicMetadata}
      {...props}
    />
  );
};

JideEnvironmentContainer.propTypes = {
  setJuniverseEnvType: PropTypes.func,
  idLookup: PropTypes.shape({}).isRequired,
  tab: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  modalViewMode: PropTypes.bool,
  shiftKeyIsPressed: PropTypes.bool,
  showcaseViewMode: PropTypes.bool,
  activeNav: PropTypes.shape({
    student: PropTypes.string,
    course: PropTypes.string,
    module: PropTypes.string,
    project: PropTypes.string,
  }).isRequired,
  learnerAnalyticsEnabled: PropTypes.bool,
  playgroundProject: PropTypes.shape({
    projectType: PropTypes.string,
    userType: PropTypes.string,
  }),
  recordingMode: PropTypes.bool,
  setRecordingMode: PropTypes.func,
  // feature flag for New Horizons restyling
  newHorizons: PropTypes.bool,
  encrypedParams: PropTypes.string,
  courseNameToBasicMetadata: PropTypes.func,
};
JideEnvironmentBar.propTypes = {
  openTabs: PropTypes.array.isRequired,
  activeTabId: PropTypes.any,
  playgroundProject: PropTypes.shape({
    projectType: PropTypes.string,
    userType: PropTypes.string,
  }),
  updateProjectName: PropTypes.func,
  encryptedStudentAndProject: PropTypes.string,
  isCodeHidden: PropTypes.bool,
};
export default JideEnvironmentBar;
