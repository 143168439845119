import juniAxios from 'services/axios';

// PUBLIC
export const publicCodeSync = async (
  studentId,
  projectId,
  userType = 'student',
  encryptedStudentAndProject,
) =>
  juniAxios.post(`/code/public/sync`, {
    studentId,
    projectId,
    userType,
    encryptedStudentAndProject,
  });

// STUDENT
export const studentCodeSync = async (
  studentId,
  projectId,
  dbUpdatedAt,
  hasNewEdits,
  code,
) => {
  const response = await juniAxios.post('/code/learner/sync', {
    studentId,
    projectId,
    dbUpdatedAt,
    hasNewEdits,
    code,
  });
  return response;
};

// TEACHER
export const teacherCodeSync = async (
  userId,
  projectId,
  dbUpdatedAt,
  hasNewEdits,
  code,
  isPlayground = false,
) => {
  const response = await juniAxios.post('/code/instructor/sync', {
    userId,
    projectId,
    dbUpdatedAt,
    hasNewEdits,
    code,
    isPlayground,
  });
  return response;
};

export const getFileVersions = async (studentId, projectId, filename) => {
  const url = [
    '/code/versioning',
    '/',
    encodeURIComponent(studentId),
    '/',
    encodeURIComponent(projectId),
    '/',
    encodeURIComponent(filename),
    '?deduplicate=true',
    '&group=true',
  ];

  return juniAxios.get(url.join(''));
};
