import React from 'react';
import styled from 'styled-components';
import { TailwindBreakpoints } from 'theme/types';
import { tailwindBreakpointSizes } from 'theme/breakpoints';
import classNames from 'classnames';

interface BreakpointSize {
  /**
   * Optionally include to apply the size at a min width.
   */
  breakpoint: TailwindBreakpoints;
  size: number;
}

interface BubbleProps {
  /**
   * Width and height of the circle.
   */
  baseSize: number;
  /**
   * Optionally include different sizes (w/h) at different breakpoints.
   */
  breakpointSizes?: BreakpointSize[];
  /**
   * Optionally intended for positioning.  Use size otherwise.
   */
  style?: React.CSSProperties;
  /**
   * Optionally add class names for further styling.
   */
  className?: string;
}

const breakpointCss = ({ breakpoint, size }: BreakpointSize) => `
  @media (min-width: ${tailwindBreakpointSizes[breakpoint]}px) {
    width: ${size}px;
    height: ${size}px;
  }
`;

const BubbleContainer = styled.div<
  Pick<BubbleProps, 'baseSize' | 'breakpointSizes'>
>`
  ${({ baseSize }) => `
    width: ${baseSize}px;
    height: ${baseSize}px;
  `}

  ${({ breakpointSizes }) => (breakpointSizes || []).map(breakpointCss).join('\n')}
`;

const Bubble: React.FC<BubbleProps> = ({
  baseSize,
  breakpointSizes,
  className,
  style = {},
}) => (
  <BubbleContainer
    className={classNames('rounded-full', className)}
    baseSize={baseSize}
    breakpointSizes={breakpointSizes}
    style={style}
  />
);

export default Bubble;
