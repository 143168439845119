import React from 'react';

interface ProfilePictureProps {
  src?: string;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ src }) => (
  <div className="rounded-full h-48 w-48 ">
    <img className="rounded-full w-full h-full" src={src} alt="Profile" />
  </div>
);
