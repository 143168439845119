import React, { FC, useState } from 'react';
import ObjectID from 'bson-objectid';

import { useGetRecapPageDataQuery } from 'generated/graphql';
import { NewButton as Button } from 'core-components';

import { Content, MainContent, SideContent } from './styles';
import QuickStatsCard from './QuickStatsCard';
import StudentInfoCard from './StudentInfoCard';
import LatestProjectsCard from './LatestProjectsCard';
import ReferralContent from '../ReferralContent';
import PublicPage, { PublicPageLoading, PublicPageError } from '../PublicPage';
import ActiveCourseCard from './ActiveCourseCard';

const DEFAULT_NUM_ACTIVE_COURSES_TO_SHOW = 2;

interface StudentRecapProps {
  userReportId: string;
}

const StudentRecap: FC<StudentRecapProps> = ({ userReportId }) => {
  const [showAllActiveCourses, setShowAllActiveCourses] = useState(false);

  const {
    data: recapData,
    loading: loadingRecapData,
    error: recapDataQueryError,
  } = useGetRecapPageDataQuery({
    variables: { userReportId: new ObjectID(userReportId) },
  });

  if (loadingRecapData) return <PublicPageLoading />;
  if (recapDataQueryError)
    return <PublicPageError errorMessage={recapDataQueryError.message} />;

  const { studentRecapByUserReportId } = recapData || {};

  const { parentFirstName: parentName, referralCode, studentInfo } =
    studentRecapByUserReportId?.userInfo || {};

  const studentFirstName = studentInfo?.firstName || '';
  const studentLastName = studentInfo?.lastName || '';
  const username = studentInfo?.username;
  const activeCoursesProgress = studentInfo?.activeCoursesProgress;

  const quickStats = studentRecapByUserReportId?.userReport;
  const recentProjects = studentRecapByUserReportId?.recentProjects;

  const activeCoursesToDisplay = activeCoursesProgress?.slice(
    0,
    showAllActiveCourses
      ? activeCoursesProgress.length
      : DEFAULT_NUM_ACTIVE_COURSES_TO_SHOW,
  );

  return (
    <PublicPage>
      <PublicPage.NavBar referralCode={referralCode} sticky />
      <Content>
        <SideContent>
          {studentInfo && (
            <StudentInfoCard
              username={username}
              firstName={studentFirstName}
              lastName={studentLastName}
            />
          )}
        </SideContent>
        <MainContent>
          {quickStats && (
            <QuickStatsCard
              userReportId={userReportId}
              studentFirstName={studentFirstName}
              quickStats={quickStats}
            />
          )}
          {recentProjects && (
            <LatestProjectsCard
              studentFirstName={studentFirstName}
              projects={recentProjects}
            />
          )}
          {activeCoursesToDisplay && activeCoursesToDisplay.length > 0 && (
            <div className="pt-3">
              <div className="pb-6 pl-2 font-graphik">
                <div className="text-j-dark-600 font-medium text-xl pb-2">
                  Active Courses
                </div>
                <div className="text-base text-j-dark-300">{`${studentFirstName}'s progress on their courses`}</div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {activeCoursesToDisplay.map(course => (
                  <ActiveCourseCard {...course} />
                ))}
              </div>
              {activeCoursesProgress &&
                activeCoursesProgress.length >
                  DEFAULT_NUM_ACTIVE_COURSES_TO_SHOW && (
                  <div className="my-4 flex">
                    <Button
                      variant="minimal"
                      fullWidth
                      onClick={() => setShowAllActiveCourses(!showAllActiveCourses)}
                    >
                      {showAllActiveCourses ? 'View Less' : 'View All'}
                    </Button>
                  </div>
                )}
            </div>
          )}
        </MainContent>
      </Content>
      {/** TODO: figure out if we should show referralCode regardless */}
      {parentName && referralCode && (
        <ReferralContent parentFirstName={parentName} referralCode={referralCode} />
      )}
    </PublicPage>
  );
};

export default StudentRecap;
