import * as R from 'ramda';

import { SignupData, SubjectName } from '../types';
import findStudentById from './findStudentById';

const findSelectionBySubject = R.curry(
  (studentId = '', subjectName: SubjectName, signupData: Partial<SignupData>) =>
    findStudentById(studentId ?? '', signupData)?.bundle?.selections[subjectName],
);
export default findSelectionBySubject;
