import { SignupSessionProps } from 'app/signup_session/types';
import classNames from 'classnames';
import SpinnerV2 from 'components/SpinnerV2';
import { ErrorableLoading } from 'components/ui';
import { Card, Message, NewButton as Button, RadioGroup } from 'core-components';
import { format } from 'date-fns-tz';
import { isBefore, parseISO } from 'date-fns';
import _ from 'lodash';
import {
  Parent,
  StripeCoupon,
  Student,
  useGetCourseOfferingsQuery,
  useGetCoursesQuery,
  useLoadDefaultPaymentInformationByParentIdQuery,
  usePurchaseBootcampMutation,
  PurchaseBootcampInput,
} from 'generated/graphql';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { getLoggedInParent } from 'services/learner/parent';
import CouponField from './components/CouponField';
import DefaultPaymentMethodDisplay from './components/DefaultPaymentMethodDisplay';
import PriceDisplay from './components/PriceDisplay';

const BootcampCheckout: FC<SignupSessionProps> = ({ history, location }) => {
  const [coupon, setCoupon] = useState<StripeCoupon | undefined>(undefined);
  const [parent, setParent] = useState<Parent | undefined>();
  const [students, setStudents] = useState([]);
  const [isLoadingParent, setIsLoadingParent] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState('');

  const [purchaseBootcamp, { loading, error }] = usePurchaseBootcampMutation();
  const { courseOfferingId } = queryString.parse(location.search);

  const handleSubmit = async () => {
    if (loading) return;

    const purchaseBootcampInput: PurchaseBootcampInput = {
      studentId: selectedStudentId,
      courseOfferingId: courseOfferingId as string,
    };
    if (coupon) {
      purchaseBootcampInput.couponId = coupon.id;
    }

    try {
      await purchaseBootcamp({
        variables: {
          input: purchaseBootcampInput,
        },
      });
      history.push('/signup2/confirmation');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const load = async () => {
      setIsLoadingParent(true);
      try {
        const data = await getLoggedInParent(true);
        setParent(data.parent);
        setStudents(data.students);
      } catch (error) {
        console.log(error);
      }
      setIsLoadingParent(false);
    };
    load();
  }, [setSelectedStudentId]);

  const courseOfferingsQuery = useGetCourseOfferingsQuery({
    variables: {
      input: courseOfferingId,
    },
  });
  const courseOffering = courseOfferingsQuery.data?.getCourseOffering;

  const coursesQuery = useGetCoursesQuery({
    variables: {
      input: {
        names: [courseOffering?.courseName || ''],
      },
    },
    skip: !courseOffering?.courseName,
  });
  const course = coursesQuery.data?.getCourses[0];

  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: parent && parent._id },
    skip: !parent || !parent._id,
  });

  const card = cardQuery?.data?.defaultPaymentInformationByParentId;

  const isLoading =
    isLoadingParent ||
    coursesQuery.loading ||
    cardQuery.loading ||
    courseOfferingsQuery.loading;
  let paramError = '';
  if (!isLoading && courseOffering === undefined)
    paramError =
      'Could not find this course offering. Please contact Juni Support at support@learnwithjuni.com or (650) 263-4306.';
  if (
    !_.isNil(courseOffering) &&
    !isBefore(new Date(), parseISO(courseOffering.enrollmentEndDate))
  )
    paramError = 'The enrollment period for this offering has ended.';

  if (isLoading || paramError) {
    return <ErrorableLoading error={paramError} />;
  }

  return (
    <div className="flex items-center justify-center">
      <Card
        borderWidth="0"
        className="w-full sm:max-w-screen-xs sm:w-3/5 sm:rounded-lg"
        noRounding
      >
        <div>
          <div className="flex flex-col padding-2">
            <p className="text-j-dark-400 text-md mt-0 mb-2">Course Name</p>
            <div className="text-j-dark-600 text-sm mb-4">{course?.displayName}</div>
            <div className="mb-2">
              <p className="text-j-dark-400 text-md mt-0 mb-2">Schedule</p>
              {courseOffering && (
                <div className="text-j-dark-600 mb-2 text-sm">
                  {courseOffering.schedule.map(scheduleItem => (
                    <div>
                      {format(
                        new Date(scheduleItem.startTime),
                        `EEEE, MMMM do 'at' h:mma z`,
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="mb-4">
              <p className="text-j-dark-400 text-md mt-0 mb-2">Select a student</p>
              <RadioGroup
                name="student"
                orientation="vertical"
                options={students.map((student: Student) => ({
                  value: student._id.toString(),
                  label: `${student.firstName} ${student.lastName}`,
                }))}
                onChange={selected => {
                  setSelectedStudentId(selected);
                }}
                selectedValue={selectedStudentId}
              />
            </div>
            <PriceDisplay
              basePriceInCents={courseOffering?.totalPriceInCents || 0}
              coupon={coupon}
              isBootcampSignup
            />
            <CouponField setCoupon={setCoupon} isBootcampSignup />
            <DefaultPaymentMethodDisplay card={card} parent={parent} />
            <div className="flex items-center">
              <Button
                variant="primary"
                className={classNames('w-full')}
                type="submit"
                intent="success"
                disabled={loading || !selectedStudentId || !card}
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                {loading ? (
                  <div className="h-5 flex items-center">
                    <SpinnerV2 />
                  </div>
                ) : (
                  'Purchase'
                )}
              </Button>
            </div>
          </div>
        </div>
        {error && (
          <Message status="error">
            {`Error: ${error.message} Please `}
            <a href="https://junilearning.com/contact/">contact Juni Support</a>
            {` if the error persists.`}
          </Message>
        )}
      </Card>
    </div>
  );
};

export default BootcampCheckout;
