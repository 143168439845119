/**
 * History - used to combine English and Investing.
 */
import React, { useState, useEffect } from 'react';

import {
  useGetReferenceTextSetsByIdsQuery,
  EnglishProjectFragment,
  TextSetFragment,
  InvestingProjectFragment,
  EnglishProjectsQuery,
  InvestingProjectsQuery,
} from 'generated/graphql';
import { ErrorableLoading } from 'components/ui';
import RichTextAnnotator from 'components/RichTextAnnotator';
import { addWhiteboardPage } from 'services/whiteboards';
import Whiteboard from 'components/Whiteboard';
import {
  JideWidgets,
  Footer,
  JideEnvLayout,
  WritingInstructions,
  ActivityInstructions,
  SelectPassage,
} from 'components/jide';

import {
  SECTION_TYPES_WITH_PROBLEMS,
  SECTION_TYPES_WITH_WHITEBOARD,
  SECTION_TYPES_WITH_RIGHT_PANEL,
  PUBLISHABLE_SECTION_TYPES,
  PUBLISHABLE_PROJECT_TYPES,
} from 'constants/course_section_types';

import _ from 'lodash';
import InstructionsPanel from 'components/InstructionsPanel';
import classNames from 'classnames';
import { ApolloError } from '@apollo/client';
import { SelectAPassage, EnglishProblems } from './components';

import { StyledJideEnvEnglish, StyledLeftPanel, StyledRightPanel } from './styles';

function getReferenceTextSetIdsFromOptions(moduleSection: any) {
  const options = moduleSection.properties.referenceTextSetOptions;
  return options ? options.map((option: any) => option.referenceTextSetId) : [];
}

// pulls all properties shared by all supplied project fragment types, sans gql generated __typename
type ProjectFragment = Omit<
  EnglishProjectFragment | InvestingProjectFragment,
  '__typename'
>;

interface JideReadingWritingProps {
  projects?: ProjectFragment[];
  projectsData?: EnglishProjectsQuery | InvestingProjectsQuery;
  projectsLoading: boolean;
  projectsError: ApolloError | undefined;
  refetchProjects: () => Promise<any>;
  isCreatingNewProject: boolean;
  createProject: (
    referenceTextSetId?: string,
    writingPromptId?: string,
  ) => Promise<void>;
  createAndInitializeProject: () => Promise<void>;
  jideUser: { _id: string; type: string; firstName: string; lastName: string };
  tab: any;
  activeNav: any;
  modalViewMode: boolean;
  shiftKeyIsPressed: boolean;
  idLookup: any;
  recordingMode: any;
  setRecordingMode: any;
  juniverseProjectData: any;
  newHorizons: boolean;
  playgroundProject?: { projectId: string; projectType: string; userType: string };
  showInstructionsSideContentHeader?: boolean;
  schedulingFormat: string;
}

const JideReadingWriting: React.FC<JideReadingWritingProps> = ({
  projects,
  projectsData,
  projectsLoading,
  projectsError,
  refetchProjects,
  isCreatingNewProject,
  createProject,
  createAndInitializeProject,
  jideUser,
  tab,
  activeNav,
  modalViewMode,
  shiftKeyIsPressed,
  idLookup,
  recordingMode,
  setRecordingMode,
  juniverseProjectData,
  newHorizons,
  playgroundProject,
  showInstructionsSideContentHeader = false,
  schedulingFormat,
}) => {
  const [project, setEnglishProject] = useState<
    EnglishProjectFragment | InvestingProjectFragment | undefined
  >(undefined);
  const [textIdToEdit, setTextIdToEdit] = useState<string>('');
  const [isHandlingPassageSelection, setIsHandlingPassageSelection] = useState<
    boolean
  >(false);
  const [pageData, setPageData] = useState({ numPages: 1, currentPageIndex: 0 });
  const [
    didSendWritingProjectInitRequest,
    setDidSendWritingProjectInitRequest,
  ] = useState<boolean>(false);

  const onToggleWriteMode = (writeMode: boolean) => {
    if (!project) return;
    if (project.projectType !== 'writing_project') return;
    setTextIdToEdit(writeMode ? project.editedTextId : project.annotatedTextIds[0]);
  };

  const moduleSection = idLookup[tab.tabNav.project];
  const referenceTextSetIds: string[] = getReferenceTextSetIdsFromOptions(
    moduleSection,
  );

  const {
    data: referenceTextSetsData,
    error: referenceTextSetsError,
  } = useGetReferenceTextSetsByIdsQuery({
    variables: {
      input: referenceTextSetIds,
    },
    skip: tab.tabNav.module === 'playground',
  });

  const isStudentProfileWorkViewedByInstructor =
    modalViewMode && jideUser.type === 'teacher';

  // reading - draftsjs text editor non editable
  // writing - draftjs text editor
  // whiteboard
  // quiz section

  // set current project from existing one(s) if any
  useEffect(() => {
    if (projects && projects.length > 0) {
      const projectWithReferenceTextSetId = projects.find(project =>
        moduleSection.type === 'reading_project'
          ? project.referenceTextSetId
          : project,
      );
      if (projectWithReferenceTextSetId) {
        setEnglishProject(projectWithReferenceTextSetId);
        // custom writing project, or the user is a public user, use the project's text,
        // else start with the accompanying reference text
        const textIdToEdit =
          (projectWithReferenceTextSetId.projectType === 'writing_project' &&
            !!projectWithReferenceTextSetId.isCustomProject) ||
          jideUser.type === 'public'
            ? projectWithReferenceTextSetId.editedTextId
            : projectWithReferenceTextSetId.annotatedTextIds[0] || '';

        // For writing projects without a reference text,
        // set the text to edit to be the project's writing text immediately.
        if (
          newHorizons &&
          moduleSection.type === 'writing_project' &&
          !projectWithReferenceTextSetId.annotatedTextIds.length
        ) {
          setTextIdToEdit(projectWithReferenceTextSetId.editedTextId);
        } else {
          setTextIdToEdit(textIdToEdit);
        }
      }
    } else {
      setEnglishProject(undefined);
      setTextIdToEdit('');
    }
    return () => {
      setEnglishProject(undefined);
      setTextIdToEdit('');
    };
  }, [newHorizons, projects, moduleSection.type, jideUser.type]);

  const writingPromptId = moduleSection?.properties.writingPromptId;

  // initialize a new english project if this is a writing project with no reference
  // text set options and no project already exists for this module section and student.
  useEffect(() => {
    if (!playgroundProject && jideUser.type === 'teacher') {
      // don't let instructors initialize a project when viewing student's work from the teacher/student_profile view
      return;
    }
    if (
      !didSendWritingProjectInitRequest &&
      moduleSection.type === 'writing_project' &&
      referenceTextSetIds.length === 0 &&
      !projectsLoading &&
      !isCreatingNewProject &&
      !projectsError &&
      projects &&
      projects.length === 0 &&
      !project
    ) {
      setDidSendWritingProjectInitRequest(true);
      createAndInitializeProject().then(() => {
        refetchProjects();
      });
    }
  }, [
    moduleSection.type,
    writingPromptId,
    referenceTextSetIds.length,
    projectsLoading,
    projectsError,
    projects,
    project,
    createAndInitializeProject,
    refetchProjects,
    isCreatingNewProject,
    didSendWritingProjectInitRequest,
    playgroundProject,
    jideUser.type,
  ]);

  /** RENDER */

  if (referenceTextSetIds.length > 0 && !referenceTextSetsData)
    return <ErrorableLoading error={referenceTextSetsError} />;

  if (!projectsData || projectsLoading)
    return <ErrorableLoading error={projectsError} />;

  const referenceTextSets: TextSetFragment[] = referenceTextSetsData
    ? referenceTextSetIds
        .map(
          textSetId =>
            referenceTextSetsData.referenceTextSetsByIds.items.find(
              item => item._id.toString() === textSetId,
            ) as TextSetFragment,
        )
        .filter(x => x)
    : [];
  const showSelectAPassage =
    !projectsError &&
    !projectsLoading &&
    projects &&
    projects.length === 0 &&
    !project &&
    ['reading_project', 'writing_project'].includes(moduleSection.type) &&
    referenceTextSetIds.length > 0 &&
    referenceTextSets &&
    referenceTextSets.length > 0;

  const handleSelectAPassage = async (selectedReferenceTextSetId: string) => {
    setIsHandlingPassageSelection(true);
    await createProject(
      selectedReferenceTextSetId,
      moduleSection.type === 'writing_project'
        ? moduleSection?.properties.writingPromptId
        : undefined,
    );
    setIsHandlingPassageSelection(false);
  };

  const problems = SECTION_TYPES_WITH_PROBLEMS.includes(moduleSection.type)
    ? moduleSection.properties.problems
    : [];

  const onFetchPageData = (
    rootBoardId: string, // used in JideEnvMath
    numPages: number,
    curPageIndex: number,
  ) =>
    new Promise<void>(resolve => {
      setPageData({ numPages, currentPageIndex: curPageIndex || 0 });
      resolve();
    });

  // dummy function because we do not want to track English whiteboard events yet
  const onWhiteboardEdit = () => undefined;

  const nextPage = () => {
    if (pageData.numPages > pageData.currentPageIndex + 1) {
      setPageData({ ...pageData, currentPageIndex: pageData.currentPageIndex + 1 });
    }
  };

  const prevPage = () => {
    if (pageData.currentPageIndex > 0) {
      setPageData({ ...pageData, currentPageIndex: pageData.currentPageIndex - 1 });
    }
  };

  const addPage = async (boardId: string) => {
    if (jideUser._id.length) {
      // if student is associated with this whiteboard,
      // save incremented numPages to database
      return addWhiteboardPage(boardId).then((data: any) => {
        setPageData({
          numPages: data.numPages,
          currentPageIndex: data.numPages - 1,
        });

        return data.numPages;
      });
    }
    // if no student is associated with this whiteboard,
    // only increment numPages in state
    setPageData({
      numPages: pageData.numPages + 1,
      currentPageIndex: pageData.numPages,
    });
  };

  const rootBoardIdBase = tab?.tabNav?.student
    ? `student-${tab.tabNav.student}`
    : '';
  const whiteboards = [];
  const sectionType = moduleSection?.properties?.sectionType;
  const isPublishableType =
    PUBLISHABLE_SECTION_TYPES.includes(sectionType) ||
    PUBLISHABLE_PROJECT_TYPES.includes(playgroundProject?.projectType || '');
  // English project must be initialized in the db before surfacing share button for writing projects.
  const isPublishable =
    isPublishableType &&
    !(
      (sectionType === 'writing_project' ||
        playgroundProject?.projectType === 'english_writing') &&
      (!project || project.projectType !== 'writing_project')
    );
  const instructions = moduleSection?.properties?.instructions;
  const hasSideContent =
    !juniverseProjectData &&
    !(
      newHorizons &&
      moduleSection.type === 'reading_project' &&
      !instructions &&
      problems.length === 0
    ) &&
    (SECTION_TYPES_WITH_RIGHT_PANEL.includes(moduleSection.type) ||
      (newHorizons &&
        project?.projectType === 'writing_project' &&
        // not a playground project
        moduleSection.type !== 'project'));

  if (SECTION_TYPES_WITH_WHITEBOARD.includes(sectionType)) {
    const rootBoardId = `${rootBoardIdBase}/${sectionType}-${moduleSection.id}`;

    for (let i = 0; i < pageData.numPages; i += 1) {
      whiteboards.push(
        <Whiteboard
          id={`${rootBoardId}/page-${i}`}
          key={`${rootBoardId}/page-${i}`}
          hidden={i !== pageData.currentPageIndex}
          numPages={pageData.numPages}
          onFetchPageData={onFetchPageData}
          pageIndex={pageData.currentPageIndex || 0}
          withSaving={!!jideUser?._id}
          nextPage={nextPage}
          prevPage={prevPage}
          addPage={addPage}
          isInModal={modalViewMode}
          shiftKeyIsPressed={shiftKeyIsPressed}
          handleWhiteboardEdit={_.throttle(onWhiteboardEdit, 10000)}
          juniverseProjectData={juniverseProjectData}
          newHorizons={newHorizons}
          fullScreen={!hasSideContent}
        />,
      );
    }
  }

  if (newHorizons) {
    if (showSelectAPassage) {
      return (
        <JideEnvLayout.FullScreenContent header="Select a passage for this project">
          <SelectPassage
            referenceTextSets={referenceTextSets}
            handleSelectPassage={handleSelectAPassage}
            submitDisabled={
              isHandlingPassageSelection ||
              isCreatingNewProject ||
              projectsLoading ||
              isStudentProfileWorkViewedByInstructor
            }
          />
        </JideEnvLayout.FullScreenContent>
      );
    }

    const jideWidgets = (
      <JideWidgets
        environmentType={
          SECTION_TYPES_WITH_WHITEBOARD.includes(sectionType)
            ? 'english_whiteboard'
            : 'english_writing'
        }
        showVideosWidget={false}
        activeNav={activeNav}
        idLookup={idLookup}
        learnerAnalyticsEnabled
        tab={tab}
        jideUser={jideUser}
        isCustomProject={!!project?.isCustomProject}
        hideShareWidget={!isPublishable}
        isPlayground={!!project?.isCustomProject && !project.writingPromptId}
        recordingMode={recordingMode}
        setRecordingMode={setRecordingMode}
        newHorizons
        schedulingFormat={schedulingFormat}
      />
    );

    if (moduleSection.type === 'question_set') {
      return (
        <EnglishProblems
          problems={problems}
          studentId={jideUser._id}
          moduleSectionId={moduleSection.id}
          newHorizons
          moduleQuestionSet
          renderJideWidgets={() => jideWidgets}
        />
      );
    }

    let richTextEditors = null;
    if (project) {
      const showReadingWritingTabs =
        project?.projectType === 'writing_project' &&
        project?.annotatedTextIds.length > 0 &&
        jideUser.type !== 'public';
      const referenceTextId =
        project?.projectType === 'writing_project'
          ? project?.annotatedTextIds[0] || undefined
          : undefined;
      const richTextAnnotatorBaseProps = {
        isBeingViewedByPublic: jideUser.type === 'public',
        readOnly: isStudentProfileWorkViewedByInstructor,
        writingPromptId: project?.writingPromptId,
        projectType: project?.projectType,
        onToggleWriteMode,
        // disable annotating for writing submissions (only allow annotating for reference texts)
        disableAnnotating: !project?.annotatedTextIds.includes(textIdToEdit),
        showReadingWritingTabs,
        referenceTextId,
        newHorizons: true,
      };
      // used in place of onToggleWriteMode for the case where we need to toggle between reading and writing.
      const handleToggleReadingWritingTab = (tab: 'reading' | 'writing') => {
        setTextIdToEdit(
          tab === 'reading' ? project.annotatedTextIds[0] : project.editedTextId,
        );
      };

      // CrAzY. The Editor inside RichTextAnnotator will not update its toolbar when the options change.
      // So, using two editors - one hidden at time - for the case where we need to toggle between reading and writing.
      // This is done be looking at the textIdToEdit.
      richTextEditors = (
        <>
          <RichTextAnnotator
            {...richTextAnnotatorBaseProps}
            editedRichTextId={
              // in reading/writing mode, always start with the Reading reference text
              showReadingWritingTabs ? referenceTextId : textIdToEdit
            }
            hidden={showReadingWritingTabs && textIdToEdit !== referenceTextId}
            toggleReadingWritingTab={handleToggleReadingWritingTab}
            renderJideWidgets={hasSideContent ? undefined : () => jideWidgets}
            fullScreen={
              project?.projectType === 'reading_project' && !hasSideContent
            }
          />
          {showReadingWritingTabs && (
            // the Writing Editor
            <RichTextAnnotator
              {...richTextAnnotatorBaseProps}
              editedRichTextId={project.editedTextId}
              hidden={showReadingWritingTabs && textIdToEdit === referenceTextId}
              toggleReadingWritingTab={handleToggleReadingWritingTab}
            />
          )}
        </>
      );
    }

    return (
      <JideEnvLayout>
        <JideEnvLayout.MainContent>
          {whiteboards}
          {richTextEditors}
        </JideEnvLayout.MainContent>
        {hasSideContent && (
          <JideEnvLayout.SideContent
            className={classNames({
              'border-0 border-l border-solid border-j-dark-100':
                project?.projectType === 'writing_project',
            })}
          >
            <div className="h-full overflow-y-auto">
              {problems.length > 0 && (
                <EnglishProblems
                  problems={problems}
                  studentId={jideUser._id}
                  moduleSectionId={moduleSection.id}
                  newHorizons
                />
              )}
              {moduleSection.type === 'activity' && (
                <>
                  {showInstructionsSideContentHeader && (
                    <>
                      <div className="px-6 py-4 font-graphik text-xl text-j-dark-600 leading-8">
                        Instructions
                      </div>
                      <hr className="border-0 bg-j-dark-100 h-px mt-0 mb-4 -mx-6" />
                    </>
                  )}
                  <ActivityInstructions instructions={instructions} />
                </>
              )}
              {project?.projectType === 'writing_project' && (
                <WritingInstructions writingPromptId={writingPromptId} />
              )}
            </div>
            <Footer className="justify-end">{jideWidgets}</Footer>
          </JideEnvLayout.SideContent>
        )}
      </JideEnvLayout>
    );
  }

  return (
    <StyledJideEnvEnglish>
      {showSelectAPassage && (
        <SelectAPassage
          referenceTextSets={referenceTextSets!}
          handleSelectAPassage={handleSelectAPassage}
          submitDisabled={
            isHandlingPassageSelection ||
            isCreatingNewProject ||
            projectsLoading ||
            isStudentProfileWorkViewedByInstructor
          }
        />
      )}
      {!showSelectAPassage && (
        <>
          {moduleSection.type !== 'question_set' && (
            <StyledLeftPanel
              isWritingProject={project?.projectType === 'writing_project'}
            >
              {SECTION_TYPES_WITH_WHITEBOARD.includes(moduleSection.type) && (
                <>{whiteboards}</>
              )}
              {project && (
                <RichTextAnnotator
                  isBeingViewedByPublic={jideUser.type === 'public'}
                  readOnly={isStudentProfileWorkViewedByInstructor}
                  editedRichTextId={textIdToEdit}
                  writingPromptId={project.writingPromptId}
                  projectType={project.projectType}
                  onToggleWriteMode={onToggleWriteMode}
                  // disable annotating for writing submissions (only allow annotating for reference texts)
                  disableAnnotating={
                    !project.annotatedTextIds.includes(textIdToEdit)
                  }
                />
              )}
            </StyledLeftPanel>
          )}
          {!juniverseProjectData &&
            SECTION_TYPES_WITH_RIGHT_PANEL.includes(moduleSection.type) && (
              <StyledRightPanel>
                {problems.length > 0 && (
                  <EnglishProblems
                    problems={problems}
                    studentId={jideUser._id}
                    moduleSectionId={moduleSection.id}
                  />
                )}
                {moduleSection.type === 'activity' && (
                  <InstructionsPanel instructions={instructions} />
                )}
              </StyledRightPanel>
            )}
        </>
      )}
      <JideWidgets
        environmentType={
          SECTION_TYPES_WITH_WHITEBOARD.includes(sectionType)
            ? 'english_whiteboard'
            : 'english_writing'
        }
        showVideosWidget={false}
        activeNav={activeNav}
        idLookup={idLookup}
        learnerAnalyticsEnabled
        tab={tab}
        jideUser={jideUser}
        isCustomProject={!!project?.isCustomProject}
        hideShareWidget={!isPublishable}
        isPlayground={!!project?.isCustomProject && !project.writingPromptId}
        recordingMode={recordingMode}
        setRecordingMode={setRecordingMode}
        schedulingFormat={schedulingFormat}
      />
    </StyledJideEnvEnglish>
  );
};

export default JideReadingWriting;
