import React from 'react';
import { Icon } from 'core-components';
import classNames from 'classnames';
import { StylesMap } from 'components/jide/types';
import { noop } from 'lodash';

import { ConnectionStatus, SaveStatus } from './types';

// RFD context: https://www.notion.so/junilearning/Offline-Whiteboards-93dc909732f741a1add9f801051be43d#ce0d004acaad461f828930a35e4a752a

export interface SyncIndicatorProps {
  /**
   * Specifies the connection status.
   * Defaults to offline.
   */
  connectionStatus: ConnectionStatus;
  /**
   * Specifies the save status.
   * Defaults to unsaved.
   */
  saveStatus?: SaveStatus;
  /**
   * Callback function that handles saving when status is unsaved.
   */
  onClickSave?: () => void;
  /**
   * Specifies whether the sync indicator is in dark mode.
   * Defaults to light mode.
   */
  darkMode?: boolean;
  /**
   * Determines if file is savable or not.
   * Defaults to false but if true, then the save state will always be locked.
   */
  isPublic?: boolean;
}

const modeStyles: StylesMap = {
  light: 'text-j-dark-400 border-j-dark-400 bg-j-gray-300',
  dark: 'text-j-dark-200 border-j-dark-400 bg-j-dark-700',
};

const SyncIndicator: React.FC<SyncIndicatorProps> = ({
  connectionStatus,
  saveStatus = 'unsaved',
  darkMode = false,
  onClickSave,
  isPublic,
}) => {
  const connected = connectionStatus === 'connected';
  const connecting = connectionStatus === 'connecting';
  const isOffline = !connected && !connecting;

  const saving = saveStatus === 'saving';
  const syncing = saveStatus === 'syncing';
  const saved = saveStatus === 'saved';
  const restoring = saveStatus === 'restoring';

  const isSavable = !!onClickSave && !saving && !saved;

  let IconComponent = Icon.Error;
  let text = 'Offline';

  if (connecting) {
    IconComponent = Icon.Refresh;
    text = 'Connecting';
  } else if (connected) {
    if (syncing) {
      IconComponent = Icon.Refresh;
      text = 'Syncing';
    } else if (isPublic) {
      // TODO: Add update with lock icon
      IconComponent = Icon.Lock;
      text = 'Locked';
    } else if (restoring) {
      IconComponent = Icon.Refresh;
      text = 'Restoring';
    } else if (saving) {
      IconComponent = Icon.Refresh;
      text = 'Saving';
    } else if (saved) {
      IconComponent = Icon.Success;
      text = 'Saved';
    } else {
      IconComponent = Icon.SaveDisk;
      text = 'Unsaved';
    }
  }

  return (
    <div
      className={classNames(
        'inline-flex items-center px-4 py-2 border border-solid rounded-lg font-graphik text-sm leading-6',
        {
          'text-j-pink-700 text-j-pink-700 bg-j-pink-100': isOffline,
          [modeStyles[darkMode ? 'dark' : 'light']]: !isOffline,
          'cursor-pointer': isSavable,
        },
      )}
      onClick={isSavable ? onClickSave : noop}
    >
      <IconComponent
        width={14}
        height={14}
        className={classNames({
          'animate-spin': connecting || (connected && saving),
        })}
      />
      <div className="ml-2">{text}</div>
    </div>
  );
};
export default SyncIndicator;
