import { Card, NewButton } from 'core-components';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

const ConfirmationPage: FC = () => {
  const history = useHistory();
  return (
    <div className="flex items-center justify-center">
      <Card
        borderWidth="0"
        className="w-full sm:max-w-screen-xs sm:w-3/5 sm:rounded-lg"
        noRounding
      >
        <div className="flex flex-col items-center">
          <div className="mb-2">You're all set!</div>
          <NewButton
            onClick={() => {
              history.push('/learner');
            }}
          >
            Return to Learner Portal
          </NewButton>
          <NewButton
            variant="minimal"
            onClick={() => {
              JuniAnalytics.track(
                'customer_browsing_courses_after_bootcamp_checkout',
              );
              window.location.href = 'https://junilearning.com/courses/';
            }}
          >
            Add more courses
          </NewButton>
        </div>
      </Card>
    </div>
  );
};

export default ConfirmationPage;
