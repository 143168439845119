import React from 'react';
import styled from 'styled-components/macro';

const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const IconContainer = styled.div`
  background: #fff;
  border: 1px solid hsl(208, 36%, 87%);
  margin-right: 12px;
  line-height: 0;
`;

const CardHeaderTitle = styled.div`
  font-family: 'Gotham', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: hsl(208, 100%, 11%);
  font-size: 14px;
  font-weight: 400;
`;

interface Props {
  title: string;
  icon: any;
  style?: any;
  children?: any;
}

const CardHeader: React.FC<Props> = ({ title, icon, style, children }) => (
  <CardHeaderWrapper style={style}>
    <IconContainer>
      {React.cloneElement(icon, { style: { fill: 'hsl(208, 100%, 11%)' } })}
    </IconContainer>
    <CardHeaderTitle>{title}</CardHeaderTitle>
    {children}
  </CardHeaderWrapper>
);

export default CardHeader;
