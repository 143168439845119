import React from 'react';
import { InstructorAchievement } from 'generated/graphql';

interface AchievementProps {
  achievement: Pick<
    InstructorAchievement,
    'thumbnailUrl' | 'name' | 'externalDescription'
  >;
}

const Achievement: React.FC<AchievementProps> = ({ achievement }) => (
  <div className="flex items-start">
    <img
      src={achievement.thumbnailUrl}
      alt={achievement.name}
      className="h-10 w-10"
    />
    <div className="ml-3 font-graphik">
      <div className="text-base text-j-dark-600 font-medium">{achievement.name}</div>
      <div className="mt-1 text-xs leading-5 text-j-dark-400">
        {achievement.externalDescription}
      </div>
    </div>
  </div>
);

export default Achievement;
