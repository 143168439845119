import React from 'react';
import { ClubInvitationCard } from 'components/clubs';
import { JuniClubInvitationType, JuniClubType } from 'generated/graphql';

interface ClubInvitesSectionProps {
  studentId?: string;
  instructorUserId?: string;
  username?: string;
  setSelectedClubToJoin: React.Dispatch<
    React.SetStateAction<JuniClubType | undefined>
  >;
  invites?: JuniClubInvitationType[];
  clubs?: JuniClubType[];
}
const ClubInvitesSection: React.FC<ClubInvitesSectionProps> = ({
  setSelectedClubToJoin,
  invites,
  clubs,
}) => (
  <>
    {invites?.map((invite: JuniClubInvitationType) => {
      const juniClub = clubs?.find(club => club._id === invite.juniClubId);
      if (juniClub) {
        return (
          <ClubInvitationCard
            juniClub={juniClub}
            inviterName={invite.inviterName || ''}
            key={invite._id}
            handleJoinClick={() => setSelectedClubToJoin(juniClub)}
          />
        );
      }
      return undefined;
    })}
  </>
);

export default ClubInvitesSection;
