import React, { useState, FC } from 'react';
import * as yup from 'yup';

import { Button } from 'core-components';
import { InputField } from 'components/ui';
import SpinnerV2 from 'components/SpinnerV2';

import { useSendParentRegistrationEmailMutation } from 'generated/graphql';

import { ReactComponent as SuccessBG } from './assets/email_success.svg';

interface GuestParentEmailCardProps {
  guestInvitationId: null | string;
  studentName?: null | string;
}

const GuestParentEmailCard: FC<GuestParentEmailCardProps> = ({
  guestInvitationId,
  studentName,
}) => {
  const [parentEmail, setParentEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [sendParentRegistrationEmail] = useSendParentRegistrationEmailMutation();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter a valid email'),
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await validationSchema.validate({ email: parentEmail });

      const { data } = await sendParentRegistrationEmail({
        variables: {
          input: {
            parentEmail,
            guestInvitationId,
            studentName: studentName || '',
          },
        },
      });
      const success = data?.sendParentRegistrationEmail?.success;
      if (success) {
        setShowSuccess(true);
      } else {
        setErrorMessage(
          'We had trouble sending the email. Please try again or contact support@learnwithjuni.com if this issue persists.',
        );
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col items-center ui-vertical-spacing bg-juni-blue-100 p-5 sm:p-8 rounded-xl">
      {isSubmitting ? (
        <SpinnerV2 />
      ) : showSuccess ? (
        <>
          <div className="flex justify-center">
            <SuccessBG />
          </div>
          <div
            data-cy="guest-registration-parent-confirmation-msg"
            className="text-blue-gray-800 text-center pb-4 font-semibold"
          >{`We've sent an email to ${parentEmail}!`}</div>
          <a href="https://junilearning.com" className="w-full">
            <Button className="w-full">Back to Juni Learning</Button>
          </a>
        </>
      ) : (
        <>
          <div className="text-blue-gray-800 text-lg sm:text-xl text-center font-semibold">
            Based on your age, we’ll need your parent to set up your account for you.
            We’ll send them an email with next steps!
          </div>
          <InputField
            className="w-full"
            name="email"
            label="Parent Email"
            onChange={val => {
              setParentEmail(val);
              setErrorMessage('');
            }}
            value={parentEmail}
            type="email"
            error={errorMessage}
          />
          <Button
            data-cy="guest-registration-parent-submit"
            onClick={handleSubmit}
            className="w-full"
            hasArrowIcon
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default GuestParentEmailCard;
