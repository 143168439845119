import { useRef, useState, useEffect, useCallback } from 'react';

const noVideoDeviceId = 'novideo';

// This custom hook is responsible for the logic that manages the MediaStreams
// used in the RecStudio. This involves invoking getUserMedia and getDisplayMedia
// prompts, building a MediaStream from the selected input devices to display
// in the user webcam preview, and creating a final combinedStream from
// microphone input and screenshare video stream that will be sent to the
// MediaRecorder
const useStreamBuilder = ({ closeStream, userVideoRef }) => {
  // Refs
  // These refs refer to MediaStream objects, that can be modified
  // by the browser, so they can't be saved in state. However, to allow
  // for other entities to listen to changes we will use the "Flag"
  // state variables related to each of these refs and update them
  // as needed (e.g. in certain MediaStream event callbacks)
  const userStreamRef = useRef(null);
  const displayStreamRef = useRef(null);
  const combinedStreamRef = useRef(null);

  // States
  const [micInfo, setMicInfo] = useState([]);
  const [cameraInfo, setCameraInfo] = useState([]);
  const [activeMic, setActiveMic] = useState(null);
  const [activeCamera, setActiveCamera] = useState(null);
  const [userStreamActiveMic, setUserStreamActiveMic] = useState(null);
  const [displayStreamFlag, setDisplayStreamFlag] = useState(0);
  const [combinedStreamFlag, setCombinedStreamFlag] = useState(0);

  const resetMicAndCameraInfo = () => {
    setMicInfo([]);
    setCameraInfo([]);
  };

  // WebRTC-Related
  // Refresh list of available input devices using getUserMedia
  const refreshDevices = useCallback(async () => {
    // Use a blanket call to getUserMedia to get all available devices and check for errors.
    // Reference: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    const constraints = { audio: true, video: true };
    try {
      await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      // Denying permission is a blocking error.
      if (err.name === 'NotAllowedError') {
        resetMicAndCameraInfo();
        return err.name;
      }
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (err) {
        // Not having a working microphone is a blocking error.
        resetMicAndCameraInfo();
        return 'NoMicrophone';
      }
    }
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const filteredDevices = devices.filter(d => d.deviceId && d.deviceId.length);

      // get list of microphones
      const defaultMic = filteredDevices.find(
        d => d.kind === 'audioinput' && d.deviceId === 'default',
      );
      const allMics = [
        ...(defaultMic ? [defaultMic] : []),
        ...filteredDevices
          .filter(d => d.kind === 'audioinput' && d.groupId !== defaultMic?.groupId)
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
      ];
      const augmentedMics = await Promise.all(
        allMics.map(async mic => {
          try {
            await navigator.mediaDevices.getUserMedia({
              audio: { deviceId: { exact: mic.deviceId } },
            });
            return { mic, usable: true };
          } catch (err) {
            return { mic, usable: false };
          }
        }),
      );

      // get list of cameras
      const defaultCamera = filteredDevices.find(
        d =>
          d.kind === 'videoinput' &&
          (d.deviceId === 'default' || d.groupId === defaultMic?.groupId),
      );
      const allCameras = [
        ...(defaultCamera ? [defaultCamera] : []),
        ...filteredDevices
          .filter(
            d => d.kind === 'videoinput' && d.groupId !== defaultCamera?.groupId,
          )
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
      ];
      const augmentedCameras = await Promise.all(
        allCameras.map(async camera => {
          try {
            await navigator.mediaDevices.getUserMedia({
              video: { deviceId: { exact: camera.deviceId } },
            });
            return { camera, usable: true };
          } catch (err) {
            return { camera, usable: false };
          }
        }),
      );

      setMicInfo(augmentedMics);
      setCameraInfo([
        ...augmentedCameras,
        { camera: { deviceId: noVideoDeviceId, label: 'No Video' }, usable: true },
      ]);

      if (!navigator.mediaDevices.ondevicechange) {
        // every time the device list changes, refresh the list
        navigator.mediaDevices.ondevicechange = () => {
          refreshDevices();
        };
      }
      if (
        augmentedCameras.length > 0 &&
        augmentedCameras.every(({ usable }) => !usable)
      ) {
        return 'NoCameraUsable';
      }
      return 'Ready';
    } catch (err) {
      console.error('ERROR', err);
      resetMicAndCameraInfo();
      return 'UnknownError';
    }
  }, []);

  const autoSelectActiveMic = () => {
    // If the currently-selected mic is no longer in the list
    // or no longer usable, switch to another one
    setActiveMic(prevActiveMic => {
      const matchingMic = micInfo.find(
        ({ mic }) => mic.deviceId === prevActiveMic?.deviceId,
      );
      if (!matchingMic?.usable) {
        for (const { mic, usable } of micInfo) {
          if (usable) {
            return mic;
          }
        }
        return null;
      }
      return prevActiveMic;
    });
  };

  const autoSelectActiveCamera = () => {
    // If the currently-selected camera is no longer in the list
    // or no longer usable, switch to another one
    setActiveCamera(prevActiveCamera => {
      const matchingCamera = cameraInfo.find(
        ({ camera }) => camera.deviceId === prevActiveCamera?.deviceId,
      );
      if (!matchingCamera?.usable) {
        for (const { camera, usable } of cameraInfo) {
          if (usable) {
            return camera;
          }
        }
        return null;
      }
      return prevActiveCamera;
    });
  };

  // Builds a "UserStream" - a MediaStream
  // object that contains the audio and video streams from the user's
  // selected input devices. This stream can be used for the user's webcam
  // view in the RecStudio UI
  const buildUserStream = useCallback(() => {
    async function buildUserStreamAsync() {
      // If camera or mic isn't available then close UserStream
      if (!(activeMic && activeCamera)) {
        userStreamRef.current = closeStream(userStreamRef.current);
        if (userVideoRef.current) {
          userVideoRef.current.pause();
          userVideoRef.current.srcObject = null;
        }
        return;
      }
      refreshDevices();
      // Use getUserMedia to get a MediaStream with selected deviceIds
      // and params
      const constraints = {
        audio: { deviceId: activeMic.deviceId },
      };
      if (activeCamera.deviceId !== noVideoDeviceId) {
        constraints.video = {
          deviceId: activeCamera.deviceId,
          width: { ideal: 320 }, // Users video feed doesn't have to be hi-res
          height: { ideal: 240 },
        };
      }
      userStreamRef.current = await navigator.mediaDevices
        .getUserMedia(constraints)
        .catch(err => {
          console.error('ERROR:', err);
          return undefined;
        });
      if (userVideoRef.current) {
        userVideoRef.current.srcObject = userStreamRef.current;
      }

      setUserStreamActiveMic(activeMic);
    }
    buildUserStreamAsync();
  }, [activeMic, activeCamera, userVideoRef, closeStream, refreshDevices]);

  // Uses getDisplayMedia to get a MediaStream object
  // that has the screenshare content as the video stream (and no audio streams)
  const buildDisplayStream = async () => {
    const constraints = {
      audio: false,
      video: {
        cursor: 'always',
      },
    };
    try {
      displayStreamRef.current = await navigator.mediaDevices.getDisplayMedia(
        constraints,
      );
      // If user stops sharing manually, close the DisplayStream
      displayStreamRef.current.getVideoTracks()[0].onended = () => {
        displayStreamRef.current = closeStream(displayStreamRef.current);
        setDisplayStreamFlag(0);
      };
      setDisplayStreamFlag(displayStreamFlag + 1);
      return true;
    } catch (err) {
      console.error(err);
      displayStreamRef.current = closeStream(displayStreamRef.current);
      setDisplayStreamFlag(0);
      return false;
    }
  };

  // Creates a combined MediaStream on the fly by combining the audio tracks
  // from the UserStream (ie. the microphone input) and the video feed from the
  // DisplayStream (ie. the screenshare, which includes the camera output thats
  // displayed in the UI)
  const buildCombinedStream = () => {
    // If userStream or displayStream don't exist, close this stream
    if (!(userStreamRef.current && displayStreamRef.current)) {
      combinedStreamRef.current = closeStream(combinedStreamRef.current);
      setCombinedStreamFlag(0);
      return;
    }

    // Combine audio tracks from userStream and video tracks from displayStream
    const newTracks = [
      ...userStreamRef.current.getAudioTracks(),
      ...displayStreamRef.current.getVideoTracks(),
    ];

    // If no combinedStream has been made, make a new MediaStream with the tracks.
    // Else, replace tracks in the existing MediaStream object so as to not
    // destroy it (less disruptive)
    if (!combinedStreamRef.current) {
      combinedStreamRef.current = new MediaStream(newTracks);
    } else {
      combinedStreamRef.current.getTracks().forEach(t => {
        combinedStreamRef.current.removeTrack(t);
      });
      newTracks.forEach(t => {
        combinedStreamRef.current.addTrack(t);
      });
    }
    setCombinedStreamFlag(prevCombinedStreamFlag => prevCombinedStreamFlag + 1);
  };

  // Cleanup
  const cleanupStreamBuilder = useCallback(() => {
    navigator.mediaDevices.ondevicechange = null;
    if (userVideoRef.current) {
      userVideoRef.current.pause();
      userVideoRef.current.srcObject = null;
    }
    [
      userStreamRef.current,
      displayStreamRef.current,
      combinedStreamRef.current,
    ].forEach(s => {
      closeStream(s); // sets them all to null once closed
    });

    resetMicAndCameraInfo();
    setActiveMic(null);
    setActiveCamera(null);
  }, [closeStream, userVideoRef]);
  const cleanupStreamBuilderWrapper = useCallback(() => cleanupStreamBuilder, [
    cleanupStreamBuilder,
  ]);

  // Effects
  // select new mic and camera when devices change
  useEffect(autoSelectActiveMic, [micInfo]);
  useEffect(autoSelectActiveCamera, [cameraInfo]);
  // update UserStream when active mic or camera changes
  useEffect(buildUserStream, [buildUserStream]);
  // update CombinedStream when active mic or DisplayStream changes
  useEffect(buildCombinedStream, [
    userStreamActiveMic,
    displayStreamFlag,
    closeStream,
  ]);
  useEffect(cleanupStreamBuilderWrapper, [cleanupStreamBuilderWrapper]);

  // Export utility functions and important state variables
  return {
    mics: micInfo.map(({ mic }) => mic),
    cameras: cameraInfo.map(({ camera }) => camera),
    isUsableMic: micInfo.reduce(
      (acc, { mic, usable }) => ({ ...acc, [mic.deviceId]: usable }),
      {},
    ),
    isUsableCamera: cameraInfo.reduce(
      (acc, { camera, usable }) => ({ ...acc, [camera.deviceId]: usable }),
      {},
    ),
    activeMic,
    activeCamera,
    noVideoDeviceId,
    combinedStreamRef,
    combinedStreamFlag,
    setActiveMic,
    setActiveCamera,
    refreshDevices,
    buildDisplayStream,
    cleanupStreamBuilder,
  };
};

export default useStreamBuilder;
