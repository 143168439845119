import React, { useEffect } from 'react';
import ProjectCard from 'components/ProjectCard';
import { CardOrientations } from 'components/ProjectCard/models';
import styled from 'styled-components/macro';
import SectionLabel from 'components/ui/SectionLabel';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IPlaygroundProps,
  IInitialFilterState,
  IFilterAction,
  ProjectsViewStates,
} from './models';
import PlaygroundFilterController from './PlaygroundFilterController';

const RecentProjectsContainer = styled.div.attrs({
  className: 'bg-juni-light-100',
})`
  min-height: 60vh;
  .layout-content {
    padding: 1rem;
  }
`;

const Toolbar = styled.div<any>`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: space-between;
  > .right {
    margin-top: 0.75rem;
  }
  & .right {
    flex-direction: column;
    justify-content: space-around;

    & .toggle-view-type {
      margin: 0 0 1rem;
      display: flex;
      flex: 1;
      > * {
        margin-right: 1rem;
      }
    }

    & .toggle-group {
      display: flex;
      align-items: center;
      z-index: 2;
    }
  }
  .left {
    flex: 1;
  }
  & .left,
  .right {
    display: flex;
    align-items: stretch;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    > .left {
      margin-right: 1rem;
    }
    > .right {
      margin-top: 0;
    }
    .right {
      flex-direction: row;
      .toggle-view-type {
        margin: initial;
        .toggle-group {
        }
      }
    }
  }
`;

const ProjectsListContainer = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(3, 1.33fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 2fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(1, 4fr);
  }
`;

const Radio = styled.button<any>`
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border-radius: 50%;

  &:not(:first-of-type) {
    margin-left: 1rem;
  }
  ${props =>
    props.active
      ? `
      background: #00a5ff;
      border: 0.2rem solid #ace2ff;`
      : `
      background: #CEEEFF;
      border: 0.3rem solid #ace2ff
    `};
`;

const FilterLabel = styled.span`
  color: #32444f;
  font-weight: 600;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  white-space: nowrap;
  cursor: pointer;
  ${(props: any) => (props.active ? `font-weight: 700` : `font-weight: 600`)}
`;

interface IRecentProjectsProps extends IPlaygroundProps {
  dispatchFilterEvent: (action: IFilterAction) => void;
  filteredState: IInitialFilterState;
  projectsViewState: ProjectsViewStates;
  setProjectsViewState: (viewState: ProjectsViewStates) => void;
  deleteProject: ({ projectId }: { projectId: string }) => void;
}

const RecentProjects: React.FC<IRecentProjectsProps> = props => {
  const location = useLocation();
  const history = useHistory();
  const querystring = queryString.parse(location.search);
  const { projects, projectsViewState, setProjectsViewState } = props;

  useEffect(() => {
    if (parseInt(querystring.viewState as string, 10) !== projectsViewState) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...querystring,
          viewState: projectsViewState,
        }),
      });
    }
  }, [projectsViewState, history, location.pathname, location.search, querystring]);

  const customProjectsSelectorProps = {
    active: projectsViewState === ProjectsViewStates.customProjects,
    onClick: () => setProjectsViewState(ProjectsViewStates.customProjects),
  };
  const playgroundSelectorProps = {
    active: projectsViewState === ProjectsViewStates.playgroundProjects,
    onClick: () => setProjectsViewState(ProjectsViewStates.playgroundProjects),
  };

  return (
    <RecentProjectsContainer>
      <div className="layout-content">
        <Toolbar userType={props.currentUser?.userType}>
          <div className="left">
            <SectionLabel>Recent Projects</SectionLabel>
          </div>
          <div className="right">
            {props.currentUser.userType === 'student' && (
              <div className="toggle-view-type">
                <div className="toggle-group">
                  <Radio {...customProjectsSelectorProps} />
                  <FilterLabel {...customProjectsSelectorProps}>
                    Custom projects
                  </FilterLabel>
                </div>
                <div className="toggle-group">
                  <Radio {...playgroundSelectorProps} />
                  <FilterLabel {...playgroundSelectorProps}>
                    Playground Projects
                  </FilterLabel>
                </div>
              </div>
            )}
            <div className="filter-controller__container">
              <PlaygroundFilterController
                userType={props.currentUser?.userType}
                dispatchFilterEvent={props.dispatchFilterEvent}
                filteredState={props.filteredState}
              />
            </div>
          </div>
        </Toolbar>
        <ProjectsListContainer userType={props.currentUser?.userType}>
          {projects && projects.length > 0 && (
            <>
              {projects.map(project => (
                <ProjectCard
                  key={`${project._id}`}
                  projectId={project._id}
                  moduleId={project.moduleId}
                  userId={project.studentId || project.instructorId || ''}
                  isPlayground={
                    project.moduleId === 'playground' || !project.moduleId
                  }
                  orientation={CardOrientations.landscape}
                  projectTitle={project.displayName}
                  publishedAt={project.createdAt || ''}
                  projectType={project.projectType}
                  userType={props.currentUser.userType}
                  deleteProject={() =>
                    props.deleteProject({ projectId: project._id })
                  }
                  properties={{
                    playgroundProject:
                      project.moduleId === 'playground' || !project.moduleId,
                    inProgress: project.properties?.inProgress,
                    completed: project.properties?.completed,
                  }}
                  idLookup={props.idLookup}
                />
              ))}
            </>
          )}
        </ProjectsListContainer>
      </div>
    </RecentProjectsContainer>
  );
};

export default RecentProjects;
