import React from 'react';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import codeCertificateBadge from 'images/certificate-badge-code.svg';
import mathCertificateBadge from 'images/certificate-badge-math.svg';
import englishCertificateBadge from 'images/certificate-badge-english.svg';
import investingCertificateBadge from 'images/certificate-badge-investing.svg';

type SUBJECT_VALUES = typeof SUBJECTS[keyof typeof SUBJECTS];

const badgeUrls: { [subject in SUBJECT_VALUES]: string } = {
  computer_science: codeCertificateBadge,
  mathematics: mathCertificateBadge,
  english: englishCertificateBadge,
  investing: investingCertificateBadge,
};

interface CompletedCourseBadgeProps {
  subject: SUBJECT_VALUES;
  /**
   * Width and height of the badge.
   * Default auto.
   */
  width?: number;
  /**
   * Width and height of the badge.
   * Default auto.
   */
  height?: number;
}

const CompletedCourseBadge: React.FC<CompletedCourseBadgeProps> = ({
  subject,
  width,
  height,
}) => (
  <img
    src={badgeUrls[subject]}
    alt={`${SUBJECT_DISPLAY_NAMES[subject]} certificate badge`}
    width={width || 'auto'}
    height={height || 'auto'}
  />
);

export default CompletedCourseBadge;
