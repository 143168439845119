import { FC } from 'react';

import { JUNI_EMAILS, JUNI_PHONES } from 'constants/contact_information';

interface AllSetStepProps {
  parentEmail: string;
}
const AllSetStep: FC<AllSetStepProps> = ({ parentEmail }) => (
  <div className="w-full">
    <div className="text-j-dark-300 text-sm mb-4">
      <div>
        We're currently processing your recurring schedule change request; here's
        what happens next:
      </div>
      <ul>
        <li>
          We will review the information you submitted and make sure everything is
          filled out correctly.
        </li>
        <li>
          One of our team members will reach out to you via email to follow up with
          additional questions or confirm when your recurring schedule has been
          updated.
        </li>
        <li>We've sent an email to {parentEmail} with a copy of your request</li>
      </ul>
      <div>
        If you have any questions, you can email us at {JUNI_EMAILS.support} or give
        us a call at {JUNI_PHONES.support}.
      </div>
    </div>
  </div>
);

export default AllSetStep;
