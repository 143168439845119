import React from 'react';
import { REPL } from 'components/jide';

import './jide_env_python.css';

const JideEnvPython = props => (
  <REPL {...props} key={`repl_${props.tab.tabId.toString()}`} />
);

export default JideEnvPython;
