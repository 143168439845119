import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import FitVid from 'components/FitVid';

import '../styles/subcomponents.css';
import '../styles/jide_rec_studio.css';
import classNames from 'classnames';
import { Icon } from 'core-components';

// PRIVATE METHODS
const humanizeTimeAgoRecStudio = timestamp => {
  if (!timestamp) return 'unknown date';
  const duration = moment.duration(moment.utc().diff(moment.utc(timestamp)));
  const seconds = Math.floor(duration.asSeconds());
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  }
  if (Math.floor(duration.asDays()) >= 7) {
    return moment.utc(timestamp).local().format('DD-MMM-YYYY');
  }
  return `${duration.humanize()} ago`;
};

// Component to render a non-playing preview video element.
// This is effectively a hack to show a video thumbnail without having
// to generate it separately as an image. It pulls the first frame of the video
// and shows it in the video element that doesn't play.
// (However, I've tried to make the logic behind it efficient such that it doesn't
// pre-download the whole video itself, just the first small chunk - that logic
// is housed in FitVid)
export const JRSPreviewVideo = ({ src }) => (
  <FitVid src={src} muted unlockAspectRatio noBorderRadius />
);

// Generates a line of text in default font color and size, with
// option to put a fontawesome icon and bold text in front
export const JRSModalLine = ({ icon, boldText, text }) => (
  <div className="jrs-modal-line">
    {icon ? (
      <div className="jrs-modal-line-icon">
        <FontAwesomeIcon icon={['fas', icon]} />
      </div>
    ) : null}
    <span>
      {boldText ? <span className="jrs-bold">{`${boldText}`}</span> : null}
      {text ? <span>{text}</span> : null}
    </span>
  </div>
);

// Component to generate the different "setup steps" visual
// Provide currStep value in order to automatically color steps green as they complete
export const JRSSteps = ({ currStep, onClick }) => (
  <div className="jrs-modal-cards" onClick={onClick}>
    <div
      className={`jrs-modal-card${currStep === 1 ? ' jrs-active' : ''}${
        currStep > 1 ? ' jrs-green' : ''
      }`}
    >
      <div className="jrs-modal-card-upper">
        <span>
          <FontAwesomeIcon icon={['fas', 'video']} />{' '}
          <FontAwesomeIcon icon={['fas', 'plus']} transform="shrink-9" />{' '}
          <FontAwesomeIcon icon={['fas', 'microphone']} />
        </span>
      </div>
      <div className="jrs-modal-card-lower">Enable Camera + Mic</div>
    </div>
    <div
      className={`jrs-modal-card${currStep === 2 ? ' jrs-active' : ''}${
        currStep > 2 ? ' jrs-green' : ''
      }`}
    >
      <div className="jrs-modal-card-upper">
        <FontAwesomeIcon icon={['fas', 'window-restore']} />
      </div>
      <div className="jrs-modal-card-lower">Select Screen</div>
    </div>
  </div>
);

// Renders a button to indicate a "back" or "cancel" action
// Is colored red on mouseover
export const JRSBackButton = ({ onClick, text, enabled, red }) => (
  <div
    className={`jrs-modal-backbutton${enabled ? ' jrs-active' : ''}${
      red ? ' jrs-red' : ''
    }`}
    onClick={() => {
      if (enabled) onClick();
    }}
  >
    <div className="jrs-modal-backbutton-content">{text}</div>
    <div className="jrs-modal-backbutton-arrow">
      <FontAwesomeIcon icon={['fas', 'redo-alt']} />
    </div>
  </div>
);

// Renders  abutton to indicate "next" or "confirm" action
// Is colored blue
export const JRSNextButton = ({ onClick, text, enabled, red, link }) => (
  <div
    className={`jrs-modal-nextbutton${enabled ? ' jrs-active' : ''}${
      red ? ' jrs-red' : ''
    }${link ? ' jrs-link' : ''}`}
    onClick={() => {
      if (enabled) onClick();
    }}
  >
    <div className="jrs-modal-nextbutton-content">{text}</div>
    <div className="jrs-modal-nextbutton-arrow">
      <FontAwesomeIcon icon={['fas', 'angle-right']} />
    </div>
  </div>
);

//
export const JRSStatusMessage = ({ color, icon, text, buttonText, onClick }) => (
  <div className={`jrs-modal-statusmessage${color ? ` jrs-${color}` : ''}`}>
    <div className="jrs-modal-statusmessage-icon">
      <FontAwesomeIcon icon={['fas', icon]} />
    </div>
    <div className="jrs-modal-statusmessage-content">{text}</div>
    {buttonText ? (
      <div className="jrs-modal-statusmessage-button" onClick={onClick}>
        {buttonText}
      </div>
    ) : null}
  </div>
);

// Component to render a short video in a "gif"-like behavior
// using FitVid inside. Video will autoplay and loop on mute
// but since it's an mp4 will be far more efficient than a real gif
export const JRSGif = ({ src, unlockAspectRatio }) => (
  <div className="jrs-modal-gif">
    <FitVid src={src} autoPlay loop muted unlockAspectRatio={unlockAspectRatio} />
  </div>
);

// Component to render a more traditional play/pause video element
// using FitVid
export const JRSVideo = ({ src }) => (
  <div className="jrs-modal-video">
    <FitVid src={src} controls />
  </div>
);

// Effectively the wrapper component for JRSPreviewVideo that includes the video metadata
// displayed underneath the thumbnail
export const JRSModalPreview = ({
  onClick,
  selected,
  src,
  title,
  createdAt,
  originalLength,
}) => {
  const recordingLength =
    ((originalLength || originalLength === 0) &&
      moment.utc(originalLength * 1000).format('mm:ss')) ||
    '--:--';
  return (
    <div
      className={`jrs-modal-preview${selected ? ' jrs-selected' : ''}`}
      onClick={onClick}
    >
      <div className="jrs-modal-preview-upper">
        {src ? (
          <>
            <JRSPreviewVideo src={src} />
            <div className="jrs-modal-preview-length">{recordingLength}</div>
          </>
        ) : (
          'UPLOADING'
        )}
      </div>
      <div className="jrs-modal-preview-lower">
        <div className="jrs-modal-preview-title">{title || 'untitled'}</div>
        <div className="jrs-modal-preview-createdat">
          {humanizeTimeAgoRecStudio(createdAt)}
        </div>
      </div>
    </div>
  );
};

export const JRSModalPreviewV2 = ({
  onClick,
  selected,
  src,
  title,
  createdAt,
  originalLength,
}) => {
  const recordingLength =
    ((originalLength || originalLength === 0) &&
      moment.utc(originalLength * 1000).format('mm:ss')) ||
    '--:--';
  return (
    <div
      className={classNames(
        'flex flex-grow-0 flex-shrink-0 flex-col box-border rounded-lg h-40 w-40',
        'overflow-hidden border border-solid cursor-pointer',
        'hover:border-juni-blue-200 hover:bg-juni-blue-50',
        {
          'bg-juni-blue-100 border-juni-blue-200': selected,
          'border-j-dark-100': !selected,
        },
      )}
      onClick={onClick}
    >
      <div className="flex h-28 relative">
        {src ? (
          <>
            <JRSPreviewVideo src={src} />
            <div
              className={classNames(
                'flex font-graphik font-medium px-1 text-2xs absolute bottom-1 right-1',
                'rounded bg-white border border-solid border-j-dark-100 items-center justify-center',
              )}
            >
              {recordingLength}
              {selected && (
                <div className="flex items-center justify-center font-bold h-3 w-3 ml-1">
                  <Icon.Check />
                </div>
              )}
            </div>
          </>
        ) : (
          'UPLOADING'
        )}
      </div>
      <div
        className={classNames(
          'flex flex-col box-border flex-grow px-2 justify-center',
          {
            'bg-juni-blue-200': selected,
            'bg-blue-gray-50': !selected,
          },
        )}
      >
        <div className="font-graphik font-medium">{title || 'untitled'}</div>
        <div className="font-graphik text-xs">
          {humanizeTimeAgoRecStudio(createdAt)}
        </div>
      </div>
    </div>
  );
};
