import create, { SetState } from 'zustand';
import produce from 'immer';
import { devtools } from 'zustand/middleware';
import { Student } from 'models';

export type NavStoreType = {
  navWidth: number;
  navIsMobile: boolean;
  navLayout?: string;
  navOrderedStudents?: Student[];
  navMobileHamburgerExpanded: boolean;
  // Above is to ensure only one is expanded. Not the most elegant but will do for now
  set: (x: any) => void;
};

// This is where we initialize the Zustand Club Store
// This includes modifying the set function so that it's wrapped in Immer
// as recommended in the Zustand documentation:
// https://github.com/pmndrs/zustand#sick-of-reducers-and-changing-nested-state-use-immer
const useNavStore = create(
  devtools((set: SetState<NavStoreType>) => ({
    navWidth: 1024,
    navIsMobile: false,
    navLayout: undefined,
    navOrderedStudents: undefined,
    navMobileHamburgerExpanded: false,
    set: (fn: any) => set(produce(fn)),
  })),
);

export default useNavStore;
