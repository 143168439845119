import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { StudentProject } from 'generated/graphql';
import PortalSection from 'components/PortalSection';
import { tailwindBreakpointSizes } from 'theme/breakpoints';
import projectsEmptyState from 'images/projects-empty-state.png';
import { Icon, NewButton as Button } from 'core-components';
import JuniverseProjectCard from '../JuniverseProjectCard';

// 0.75rem * 2 = 1.5rem of spacing in-between on each row
const CardWrapper = styled.div`
  width: 100%;

  @media (min-width: ${tailwindBreakpointSizes.md}px) {
    width: calc(50% - 0.75rem);
  }
`;

const ProjectsGrid: React.FC<{ projects: StudentProject[] }> = ({ projects }) => (
  <div className="flex flex-wrap justify-between">
    {projects.map((project, index) => (
      <CardWrapper
        key={project._id.toString()}
        className={classNames('box-border', {
          // 1st card = no mt, 2nd card = mt when cards are 2 column grid layout
          'mt-6 md:mt-0': index === 1,
          // rest all have mt
          'mt-6': index > 1,
        })}
      >
        <JuniverseProjectCard
          {...project}
          variant="minimal"
          cardIsLink
          linksOpenInNewTab={false}
        />
      </CardWrapper>
    ))}
  </div>
);

interface ProjectsProps {
  featuredProjects: StudentProject[];
  projects: StudentProject[];
  userFirstName: string;
  isActiveStudent?: boolean;
  studentId?: string;
}

const Projects: React.FC<ProjectsProps> = ({
  featuredProjects,
  projects,
  userFirstName,
  isActiveStudent = false,
  studentId,
}) => (
  <>
    {featuredProjects.length > 0 && (
      <PortalSection name="Featured Projects">
        <ProjectsGrid projects={featuredProjects} />
      </PortalSection>
    )}
    <PortalSection name={featuredProjects.length > 0 ? 'All Projects' : 'Projects'}>
      {projects.length === 0 && (
        <div className="flex flex-col justify-center items-center">
          <img
            className="block w-24 h-24"
            src={projectsEmptyState}
            alt="No projects icon"
          />
          <div className="mt-4 font-graphik text-j-dark-600 text-xl leading-8 font-medium text-center">
            {isActiveStudent ? 'You have' : `${userFirstName} has`} not published any
            projects yet.
          </div>
          {isActiveStudent && (
            <div className="mt-6">
              <Button
                href={`/learner/${studentId}/playground`}
                renderIconLeft={props => <Icon.File {...props} />}
              >
                Publish First Project
              </Button>
            </div>
          )}
        </div>
      )}
      {projects.length > 0 && <ProjectsGrid projects={projects} />}
    </PortalSection>
  </>
);

export default Projects;
