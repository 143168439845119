import { QueryResult } from '@apollo/client';
import { NewButton, RadioGroup } from 'core-components';
import {
  LoadProductsByStripeIdQuery,
  LoadStudentsByParentIdsQuery,
} from 'generated/graphql';
import { FC, useState } from 'react';
import _ from 'lodash';
import {
  checkIfStudentHasMoreThanOneWeeklyClassInSubject,
  extractSubjectMetadata,
} from '../../../../../utils/stripe';
import { UPDATE_FLOWS } from '../../LearnerAccount';
import LearnerPortalModal from '../LearnerPortalModal';
import { FormState, FormValues } from '../types';

interface Props {
  formState: FormState;
  formValues: FormValues;
  updateFormState: (newValue: any, field: string) => void;
  updateFormValue: (newValue: any, field: string) => void;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  productsQuery: QueryResult<LoadProductsByStripeIdQuery>;
}

const DowngradeSelectorModal: FC<Props> = ({
  formState,
  formValues,
  studentsQuery,
  productsQuery,
  updateFormState,
}) => {
  const [downgradeOption, setDowngradeOption] = useState('');
  const { subscription } = formValues;

  const students = studentsQuery?.data?.studentsByParentIds?.items || [];

  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );

  const products = productsQuery?.data?.productsByStripeIds?.items || [];
  const studentHasMoreThanOneWeeklyClassInSubject = checkIfStudentHasMoreThanOneWeeklyClassInSubject(
    subscription,
    products,
  );

  const downgradeOptions = [
    {
      label: 'I would like to pause or cancel this subscription.',
      value: UPDATE_FLOWS.CANCEL,
    },
  ];

  if (!_.isEmpty(extractSubjectMetadata(subscription!.metadata))) {
    if (currentStudents[0].hasMultipleTracks) {
      downgradeOptions.unshift({
        label: 'I would like to reduce the number of courses for this subscription.',
        value: UPDATE_FLOWS.REDUCE_COURSES,
      });
    }
    if (studentHasMoreThanOneWeeklyClassInSubject) {
      downgradeOptions.unshift({
        label: 'I would like to reduce the class frequency for this subscription.',
        value: UPDATE_FLOWS.REDUCE_FREQUENCY,
      });
    }
  }
  return (
    <LearnerPortalModal
      updateFormState={updateFormState}
      formState={formState}
      title="Downgrade Subscription"
      renderPrimaryButton={
        <NewButton
          onClick={() => {
            let nextModal = '';
            switch (downgradeOption) {
              case UPDATE_FLOWS.CANCEL: {
                nextModal = 'cancel_check';
                break;
              }
              case UPDATE_FLOWS.REDUCE_COURSES: {
                nextModal = 'downgrade_course';
                break;
              }
              case UPDATE_FLOWS.REDUCE_FREQUENCY: {
                nextModal = 'downgrade_frequency';
                break;
              }
              default:
                break;
            }
            updateFormState(downgradeOption, 'updateFlow');
            updateFormState(nextModal, 'modal');
          }}
          disabled={!downgradeOption}
        >
          <div className="font-medium">Next</div>
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            window.open(`mailto:support@learnwithjuni.com`, '_blank');
          }}
          variant="secondary"
        >
          Email Support
        </NewButton>
      }
    >
      <div className="flex flex-col w-full">
        <div className="mb-2 text-base font-medium text-j-dark-600">
          Select the type of change you would like to make...
        </div>
        <div>
          <RadioGroup
            name="downgradeOption"
            orientation="vertical"
            options={downgradeOptions}
            onChange={(selected: any) => {
              setDowngradeOption(selected);
            }}
            selectedValue={downgradeOption}
          />
        </div>
      </div>
    </LearnerPortalModal>
  );
};

export default DowngradeSelectorModal;
