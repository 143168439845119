import React from 'react';
import styled from 'styled-components';

// only use these on sufficiently large screens (1440px+)
const Container = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }
`;

// Vertical inset divider for New Horizons tool controls row
const ToolControlsInsetDivider = () => (
  <Container className="mx-3">
    <hr className="m-0 h-8 border-0 border-l border-solid border-j-dark-100" />
  </Container>
);

export default ToolControlsInsetDivider;
