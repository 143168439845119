import React from 'react';
import { StyledConductList } from './styles';

export const CodeOfConduct: React.FC = () => (
  <div>
    <h2>Code of Conduct</h2>
    <div>
      As a member of Juni Clubs, I will conduct myself in accordance with these
      principles and agree to the following Code of Conduct:
    </div>
    <StyledConductList>
      <ul>
        <li>
          <span>
            I will show respect and courtesy when interacting with other Juni Clubs
            members, Club Advisors, and Moderators in the Juni Club chat and at all
            group events.
          </span>
        </li>
        <li>
          <span>
            I will not engage in any form of bullying, including but not limited to:
            encouraging violence, harassment, bullying, and hate speech.
          </span>
        </li>
        <li>
          <span>
            I will work to create a supportive and welcoming environment by being
            friendly, patient, and kind.
          </span>
        </li>
        <li>
          <span>I will respect the opinion of those who disagree with me.</span>
        </li>
        <li>
          <span>
            I will refrain from posting spam, sensitive personal information such as
            emails and phone numbers, and messages that include extreme violence,
            explicit content, or abuse (including animal abuse).
          </span>
        </li>
      </ul>
    </StyledConductList>
  </div>
);
