import { SUBJECTS } from 'constants/subjects';
import { Student, Course } from 'generated/graphql';

interface CurriculumCourse {
  name: string;
  displayName: string;
  subject?: string;
}

export const getShortDisplayName = (course: CurriculumCourse) => {
  if (!course) return '';
  const { name, displayName, subject } = course;
  const splitIndex = displayName.indexOf(':');
  return splitIndex !== -1 &&
    subject &&
    name !== 'ap_cs' &&
    !([SUBJECTS.ENGLISH, SUBJECTS.INVESTING] as string[]).includes(subject)
    ? displayName.substring(0, splitIndex)
    : displayName;
};

export const splitDisplayName = (displayName: string) => {
  let supertitle;
  let title = displayName;
  let subtitle;

  if (displayName.includes(':')) {
    const splitString = displayName.split(':');
    // eslint-disable-next-line prefer-destructuring
    supertitle = splitString[0];
    // eslint-disable-next-line prefer-destructuring
    title = splitString[1];
  }

  const regExp = /\(([^)]+)\)/g;
  const matches = displayName.match(regExp);
  if (matches && matches.length) {
    const match = matches[matches.length - 1];
    subtitle = match.substring(1, match.length - 1);
    title = title.replace(match, '').trim();
  }

  return { supertitle, title, subtitle };
};

export const getCourseByName = (allCourses: any[], courseName: string) =>
  allCourses.find((c: any) => c.properties.name === courseName);

export const getStudentCourseNames = (student: Student) => {
  const { track, tracks, hasMultipleTracks, completedTracks } = student;
  const completedCourseNames = completedTracks || [];
  const activeCourseNames: string[] = (hasMultipleTracks
    ? tracks || []
    : track
    ? [track]
    : []
  ).filter(c => !completedCourseNames.includes(c));

  return { activeCourseNames, completedCourseNames };
};

export const getStudentCoursesFromIdLookup = (
  student: Student,
  idLookup: any,
  isLoadingIdLookup?: boolean,
) => {
  if (isLoadingIdLookup) {
    return { activeCourses: [], completedCourses: [] };
  }

  const { activeCourseNames, completedCourseNames } = getStudentCourseNames(student);

  const allCourses = idLookup[student._id].children;
  const activeCourses = activeCourseNames
    .map(courseName => getCourseByName(allCourses, courseName))
    .filter(x => !!x);
  const completedCourses = completedCourseNames
    .map(courseName => getCourseByName(allCourses, courseName))
    .filter(x => !!x);

  return { activeCourses, completedCourses };
};

type InputCourse = Pick<Course, 'displayName' | 'isAcceptingEnrollment'>;
/**
 * @returns Course name formatted for display in admin views.
 * This means displayName plus non-enrolling status.
 */
export const getAdminCourseDisplayName = ({
  displayName,
  isAcceptingEnrollment,
}: InputCourse): string =>
  `${displayName}${!isAcceptingEnrollment ? ' [NOT ACCEPTING ENROLLMENT]' : ''}`;
