// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as R from 'ramda';
import { BundleData } from '../types';

const initializeEmptySchedulingPreferences = (
  bundleData: BundleData,
): BundleData['selections'] =>
  R.map(selection =>
    R.assoc(
      'schedulingPreferences',
      [...Array(Math.max(selection.weeklyClasses ?? 0, 1) * 3)].map(() => ({})),
      selection,
    ),
  )(bundleData.selections);

export default initializeEmptySchedulingPreferences;
