import { SubjectName } from '../types';

interface TicketNoteParams {
  _id: string;
  planFrequency: string;
  subject: SubjectName;
  learningStyle: string;
}

const makeOnboardingTicketNote = ({
  _id,
  planFrequency,
  subject,
  learningStyle,
}: Partial<TicketNoteParams>) =>
  `Recovery link: https://app.junilearning.com/onboarding/${_id}/confirmation
    Plan frequency: ${planFrequency ?? ''}
    Subject for this ticket: ${subject ?? ''}
    Learning style: ${learningStyle ?? ''}`;

export default makeOnboardingTicketNote;
