import classNames from 'classnames';

interface ButtonTheme {
  button: string;
  icon: string;
  disabledIcon: string;
}

export const primaryTheme: ButtonTheme = {
  button: classNames(
    'font-bold',
    'text-white',
    'bg-juni-blue-500',
    'border-white',
    'border-opacity-25',
    'disabled:text-white',
    'disabled:bg-blue-gray-200',
    'disabled:border-white',
    'disabled:border-opacity-25',
    'hover:bg-juni-blue-600',
  ),
  icon: 'text-white bg-juni-blue-400 group-hover:bg-juni-blue-500',
  disabledIcon: 'text-white bg-blue-gray-400 bg-opacity-20',
};

export const secondaryTheme: ButtonTheme = {
  button: classNames(
    'font-bold',
    'text-juni-blue-500',
    'bg-white',
    'border-juni-blue-400',
    'border-opacity-50',
    'disabled:text-blue-gray-200',
    'disabled:bg-white',
    'disabled:border-blue-gray-400',
    'disabled:border-opacity-25',
    'hover:text-juni-blue-600',
    'hover:bg-juni-blue-50',
  ),
  icon: classNames(
    'text-juni-blue-500',
    'bg-juni-blue-100',
    'group-hover:text-juni-blue-600',
    'group-hover:bg-juni-blue-200',
  ),
  disabledIcon: 'text-white bg-blue-gray-400 bg-opacity-30',
};

export const transparentTheme: ButtonTheme = {
  button: classNames(
    'font-bold',
    'text-white',
    'bg-transparent',
    'border-white',
    'border-opacity-20',
    'disabled:white',
    'disabled:text-white',
    'disabled:text-opacity-70',
    'disabled:bg-transparent',
    'disabled:border-white',
    'disabled:border-opacity-10',
    'hover:bg-black',
    'hover:bg-opacity-10',
  ),
  icon: 'text-white bg-white bg-opacity-10',
  disabledIcon: 'text-white text-opacity-70 bg-white bg-opacity-5',
};

export const pinkTheme: ButtonTheme = {
  button: classNames(
    'text-white',
    'font-graphik',
    'font-medium',
    'rounded-xl',
    'bg-gradient-to-b',
    'from-juni-pink-400',
    'to-juni-pink-500',
    'border-white',
    'border-opacity-20',
    'hover:from-juni-pink-700',
    'hover:to-juni-pink-700',
    'hover:bg-opacity-10',
    'disabled:text-white',
    'disabled:from-blue-gray-200',
    'disabled:to-blue-gray-200',
    'disabled:text-opacity-70',
    'disabled:border-white',
    'disabled:border-opacity-10',
  ),
  icon: 'text-white bg-white bg-opacity-10',
  disabledIcon: 'text-white text-opacity-70 bg-white bg-opacity-5',
};

export const pinkSecondaryTheme: ButtonTheme = {
  button: classNames(
    'text-juni-pink-500',
    'font-graphik',
    'font-medium',
    'rounded-xl',
    'bg-gradient-to-b',
    'from-white',
    'to-juni-pink-50',
    'border-juni-pink-500',
    'border-2 ',
    'border-solid',
    'hover:bg-juni-pink-800',
    'hover:bg-opacity-10',
    'disabled:white',
    'disabled:text-white',
    'disabled:text-opacity-70',
    'disabled:bg-transparent',
    'disabled:border-white',
    'disabled:border-opacity-10',
  ),
  icon: 'text-white bg-white bg-opacity-10',
  disabledIcon: 'text-white text-opacity-70 bg-white bg-opacity-5',
};
