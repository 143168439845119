import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import useDimensions from 'hooks/dimensions';
import NavIcon from './NavIcon';
import NavMenuButton from './NavMenuButton';

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-left: 12px;
  white-space: nowrap;
`;

const WrapperStyles = `
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: flex; 
  align-items: center; 
  cursor: pointer;  
  position: relative;
  @media (min-width: 921px) {
    &:not(:first-child):after {
        z-index: -1;
        top: 18px;
        left: 0;
        bottom: 18px;
        right: 0;
        content: "";
        position: absolute;
        border-left: 1px solid #C1BCE0;
    }
  }
  &:not(:first-child) {
    margin: 0 0 0 12px;
  }
  @media (min-width: 921px) {
    padding-left: 18px;
    margin: 0px 8px;
  }
`;

const MenuButtonWrapper = styled.div.attrs((props: any) => ({
  className: props.active ? 'text-juni-pink-500' : 'text-juni-dark-700',
}))<any>`
  ${WrapperStyles}
`;

// We're prefixing all props passed to this with a dollar sign to use the transient
// props API styled-components offers. This is because the component wraps a
// react-router component, which is not set up to receive the custom props we want
// to pass to it (and which styled-components would normally pass automatically to
// wrapped components).

// See this article for more info: https://medium.com/@probablyup/introducing-transient-props-f35fd5203e0c
const LinkButtonWrapper = styled(NavLink).attrs((props: any) => ({
  className: props.$active ? 'text-juni-pink-500' : 'text-juni-dark-700',
}))<any>`
  ${WrapperStyles}
  text-decoration: none;
  &:hover {
    transform: ${props => props.$hoverState && 'scale(1.015)'};
    transition: ${props => props.$hoverState && 'transform .2s ease'};
  }
`;

interface DropdownLinkProps {
  icon: React.ReactNode;
  text: string;
  to: string;
}

interface NavLeftButtonCoreProps {
  active: boolean;
  icon: React.ReactNode;
  text: string;
}

interface NavLeftButtonLinkProps extends NavLeftButtonCoreProps {
  active: boolean;
  link: string;
}

interface NavLeftButtonMenuProps extends NavLeftButtonCoreProps {
  active: boolean;
  dropdownLinks: DropdownLinkProps[];
}

const NavLeftButtonCore: FC<NavLeftButtonCoreProps> = ({ active, icon, text }) => {
  const { width } = useDimensions();
  const desktopView = width > 920;

  return (
    <>
      <NavIcon active={active} icon={icon} />
      {desktopView && <ButtonText>{text}</ButtonText>}
    </>
  );
};

const NavLeftButtonLink: FC<NavLeftButtonLinkProps> = ({
  active,
  icon,
  link,
  text,
}) => {
  const { width } = useDimensions();
  const mobileView = width <= 920;

  return (
    <LinkButtonWrapper $hoverState={!mobileView} $active={active} to={link}>
      <NavLeftButtonCore active={active} icon={icon} text={text} />
    </LinkButtonWrapper>
  );
};

const DropdownLink: FC<DropdownLinkProps> = ({ icon, text, to }) => (
  <Link to={to} key="dropdown-nav-link-learner-cs">
    <NavIcon icon={icon} menuItem />
    {text}
  </Link>
);

const NavLeftButtonMenu: FC<NavLeftButtonMenuProps> = ({
  active,
  dropdownLinks,
  icon,
  text,
}) => {
  const { width } = useDimensions();
  const mobileView = width <= 920;

  const dropdownOptions = dropdownLinks.map(props => (
    <DropdownLink icon={props.icon} text={props.text} to={props.to} key={props.to} />
  ));

  return (
    <MenuButtonWrapper active={active}>
      <NavMenuButton
        hoverState={!mobileView}
        dropdownOptions={dropdownOptions}
        navLeft
      >
        <NavLeftButtonCore active={active} icon={icon} text={text} />
      </NavMenuButton>
    </MenuButtonWrapper>
  );
};

export { NavLeftButtonLink, NavLeftButtonMenu };
