import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as WaveBG } from 'images/juniverse_home_wave.svg';

const JuniverseBGWaveWrapper = styled.div`
  position: absolute;
  width: 3500px;
  top: -100px;
  z-index: 1;
  @media only screen and (min-width: 1161px) {
    top: -220px;
  }
  @media only screen and (min-width: 950px) {
    width: 3000px;
  }
`;

export const JuniverseBGWave = () => (
  <JuniverseBGWaveWrapper>
    <WaveBG />
  </JuniverseBGWaveWrapper>
);
