import React from 'react';
import ModalWindow from 'core-components/ModalWindow';
import { JuniClubType } from 'generated/graphql';
import colors from 'theme/club-colors';
import { ClubColor } from 'theme/types';
import JoinClubButton from './JoinClubButton';
import { CodeOfConduct } from './CodeOfConduct';

interface ConductModalProps {
  isOpen: boolean;
  handleClose: () => void;
  canJoin: boolean;
  studentId?: string;
  instructorUserId?: string;
  juniClub: JuniClubType;
  requiresRefresh: boolean;
}
const ConductModal: React.FC<ConductModalProps> = ({
  isOpen,
  handleClose,
  canJoin,
  studentId,
  instructorUserId,
  juniClub,
  requiresRefresh,
}) => (
  // TODO: we still need number of students!
  // Club info
  // 1. Label, aka: number of students
  <ModalWindow
    isOpen={isOpen}
    closeModal={handleClose}
    bannerImgSrc={juniClub.coverPhoto || ''}
    labelText=""
    title={juniClub.displayName}
    description={juniClub.description || ''}
    underBannerColor={colors[juniClub.colorTheme as ClubColor].light || ''}
    whiteBackground
  >
    <CodeOfConduct />
    <JoinClubButton
      studentId={studentId}
      instructorUserId={instructorUserId}
      clubId={juniClub._id}
      canJoin={canJoin}
      requiresRefresh={requiresRefresh}
    />
  </ModalWindow>
);
export default ConductModal;
