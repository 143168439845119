import React, { FC } from 'react';
import classNames from 'classnames';

import { StudentProjectCreatorType } from 'generated/graphql';
import { TailwindWidth } from 'theme/types';
import JuniLogo from './JuniLogo';

interface UserTypeTagProps {
  userType?: StudentProjectCreatorType | string;
  className?: string;
  /**
   * Custom styles applied to JuniLogo component using Tailwind utility classes
   */
  logoClassName?: string;
  /**
   * Determines the size of logo width and user type text
   */
  size?: 'small' | 'medium';
}

const logoWidthStyles: { [key: string]: TailwindWidth } = {
  small: '6',
  medium: '7',
};

const userTypeTextFontStyles = {
  small: 'text-2xs',
  medium: 'text-xs',
};

const paddingStyles = {
  small: 'px-1',
  medium: 'px-2',
};

const UserTypeTag: FC<UserTypeTagProps> = ({
  userType = 'Student',
  className,
  logoClassName,
  size = 'medium',
}) => (
  <div className={`flex ${className}`}>
    <div
      className={classNames(
        'bg-juni-dark-900',
        'flex items-center',
        paddingStyles[size],
        'border border-juni-dark-900 border-0 rounded-tl-md rounded-bl-md',
        'box-border',
      )}
    >
      <JuniLogo
        color="light"
        width={logoWidthStyles[size]}
        className={`${logoClassName || ''}`}
      />
    </div>
    <div
      className={classNames(
        'bg-juni-purple-100',
        'flex items-center',
        paddingStyles[size],
        size === 'small' ? 'pt-0.5' : '',
        'border border-juni-purple-300 border-0 rounded-tr-md rounded-br-md',
        'box-border',
      )}
    >
      <div
        className={`${userTypeTextFontStyles[size]} text-juni-dark-900 font-semibold uppercase leading-relaxed`}
        style={{ fontFamily: 'Graphik' }}
      >
        {userType}
      </div>
    </div>
  </div>
);

export default UserTypeTag;
