import { createMachine } from 'xstate';
import navStates from '../states';
import { Context, RouteEvent, Router } from '../types';
import { guards } from '../guards';

export const navVersion = 2;

export const defaultOrder = [
  navStates.signup.parentInfo,
  navStates.signup.studentInfo,
  navStates.signup.starterCourse,
  navStates.signup.courseFrequency,
  navStates.signup.checkout,
  navStates.onboarding.createAccount,
  navStates.onboarding.schedulingPreferences,
  navStates.onboarding.coursePlacement,
  navStates.onboarding.studentInfo,
  navStates.onboarding.confirmation,
];

// if changing the DCF navigation, refer to https://www.notion.so/junilearning/Guide-to-DCF-Navigation-fe2c5809e19f4dc48a8ef1736f712c4a first!
export const defaultMachineConfig = {
  id: 'faster-checkout-router',
  initial: navStates.signup.default,
  states: {
    [navStates.signup.default]: {
      on: {
        LOAD: [{ cond: 'notApprovedRoute', target: navStates.signup.parentInfo }],
      },
    },
    [navStates.signup.parentInfo]: {
      on: {
        SUBMIT: [
          {
            cond: 'isBootcampSignup',
            target: navStates.signup.studentInfo,
          },
          {
            cond: 'isOnDemandSignup',
            target: navStates.signup.studentInfo,
          },
          {
            cond: 'shouldSkipCourseFrequency',
            target: navStates.signup.starterCourse,
          },
          { target: navStates.signup.studentInfo },
        ],
      },
    },
    [navStates.signup.starterCourse]: {
      on: {
        LOAD: [
          {
            cond: 'shouldViewCourseFrequency',
            target: navStates.signup.studentInfo,
          },
          { cond: 'isBootcampSignup', target: navStates.signup.studentInfo },
          { cond: 'isOnDemandSignup', target: navStates.signup.studentInfo },
        ],
        SUBMIT: navStates.signup.checkout,
        BACK: navStates.signup.parentInfo,
      },
    },
    [navStates.signup.studentInfo]: {
      on: {
        LOAD: [
          // This empty condition avoids rerouting to starterCourse if the user is a bootcamp or onDemand signup
          { cond: 'isBootcampSignup' },
          { cond: 'isOnDemandSignup' },
          {
            cond: 'shouldSkipCourseFrequency',
            target: navStates.signup.starterCourse,
          },
        ],
        SUBMIT: [
          { cond: 'isBootcampSignup', target: navStates.signup.checkout },
          { cond: 'isOnDemandSignup', target: navStates.signup.checkout },
          { target: navStates.signup.courseFrequency },
        ],
        BACK: navStates.signup.parentInfo,
      },
    },
    [navStates.signup.courseFrequency]: {
      on: {
        LOAD: [
          {
            cond: 'shouldSkipCourseFrequency',
            target: navStates.signup.checkout,
          },
        ],
        BACK: navStates.signup.studentInfo,
        SUBMIT: navStates.signup.checkout,
      },
    },
    [navStates.signup.checkout]: {
      on: {
        LOAD: [
          // if the student has no bundle data, send them back to the beginning of the flow
          {
            cond: 'hasNoCourseFrequency',
            target: navStates.signup.default,
          },
        ],
        SUBMIT: navStates.onboarding.default,
        BACK: [
          {
            cond: 'shouldSkipCourseFrequency',
            target: navStates.signup.starterCourse,
          },
          { target: navStates.signup.courseFrequency },
        ],
      },
    },
    [navStates.onboarding.default]: {
      on: {
        LOAD: [
          {
            cond: 'noPayment',
            target: navStates.signup.default,
          },
          {
            cond: 'shouldCreateAccount',
            target: navStates.onboarding.createAccount,
          },
          {
            cond: 'isOnDemandSignup',
            target: navStates.onDemandLibrary,
          },
          {
            cond: 'notApprovedRoute',
            target: navStates.onboarding.schedulingPreferences,
          },
          {
            cond: 'onboardingIsComplete',
            target: navStates.onboarding.confirmation,
          },
        ],
      },
    },
    [navStates.onboarding.createAccount]: {
      on: { SUBMIT: navStates.onboarding.default },
    },
    [navStates.onboarding.schedulingPreferences]: {
      on: {
        SUBMIT: [
          {
            cond: 'shouldSkipCourseFrequency',
            target: navStates.onboarding.studentInfo,
          },
          { target: navStates.onboarding.coursePlacement },
        ],
      },
    },
    [navStates.onboarding.coursePlacement]: {
      on: {
        BACK: navStates.onboarding.schedulingPreferences,
        SUBMIT: navStates.onboarding.studentInfo,
      },
    },
    [navStates.onboarding.studentInfo]: {
      on: {
        BACK: [
          {
            cond: 'shouldSkipCourseFrequency',
            target: navStates.onboarding.schedulingPreferences,
          },
          { target: navStates.onboarding.coursePlacement },
        ],
        SUBMIT: navStates.onboarding.confirmation,
      },
    },
    [navStates.onboarding.confirmation]: {
      on: { BACK: navStates.onboarding.studentInfo },
    },
    [navStates.tuitionPage]: {},
    [navStates.onDemandLibrary]: {},
  },
};

const defaultRouter: Router = createMachine<Context, RouteEvent>(
  defaultMachineConfig,
  { guards },
);

export default defaultRouter;
