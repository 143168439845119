import juniAxios from 'services/axios';

export const validateCoupon = async couponId => {
  const res = await juniAxios.get(`/auth/validate_coupon/${couponId}`);
  return res.data.data;
};

export const getCouponDataFromStripe = async couponId => {
  const res = await juniAxios.get(`/auth/coupon/${couponId}`);
  return res.data.data;
};
