import React, { FC } from 'react';
import './coppa_direct_notice.css';

interface Props {
  className?: string;
}

const CoppaDirectNotice: FC<Props> = ({ className }) => (
  <div className={`coppa-direct-notice ${className || ''}`}>
    <h1>COPPA Direct Notice to Parents</h1>
    <p>
      Before we can provide your child with access to the Juni Learner Portal or to
      other features and activities on our website, we need your consent. Under the
      Children’s Online Privacy Protection Act (“COPPA”), parental consent is
      required for the collection, use, or disclosure of personal information from
      children under the age of 13. Juni Learning will not collect, use, or disclose
      any personal information from your child unless you provide your consent.
    </p>
    <p>
      To help you understand how we collect, use, and disclose your child’s personal
      information, we have prepared this COPPA Direct Notice to Parents (“Direct
      Notice”). We also encourage you to review our Privacy Policy and our Terms of
      Use, available{' '}
      <a
        href="https://junilearning.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      , for more information. Terms capitalized in this Direct Notice are defined in
      our general Privacy Policy.
    </p>
    <p>
      Subject to your consent, in order to allow your child to use Juni Learning’s
      Site and Services, we would like to collect, use and disclose your child’s
      Personal Information as set forth below.
    </p>
    <h2>Information We Collect:</h2>
    <p>We collect information from your child in the following ways: </p>
    <ul>
      <li>
        <strong>Information collected when creating your child’s account.</strong> If
        you enroll for our Service, we will provide you with access to the Juni
        Learner Portal. We will populate your family’s account with the information
        supplied during registration, for example, your child’s name, grade and age,
        and ask you to set a password for that account that can be shared with your
        family. Later your child may choose to add information such as a username,
        profile photo, biographical information, location, projects s/he is working
        on, and videos about these projects. We advise students to choose a username
        that does not reveal their real name or any other information that could
        identify them. Other Juni Learning users will be able to see your child’s
        username, chosen avatar or profile photo, general location, and any other
        content they choose to make public. If you sign up for a Guest Account, we
        ask you to create a username and password to give you access to the Juni
        Learner Portal (though not to our classes). Later, your child can also choose
        to add additional information such as a profile photo or avatar, general
        location, and biographical information.
      </li>
      <li>
        <strong>Information collected if your child contacts us.</strong> If your
        child contacts us by email; through our branded social media accounts;
        through any integrated chat providers we may offer, or by telephone, SMS, or
        physical mail, we may collect your child’s name, username, email address,
        phone number, physical address, and the contents of your communication which
        can include any information your child chooses to provide. We will only use
        that information for the purpose of providing the service or support
        requested.
      </li>
      <li>
        <strong>
          Information collected when registering for a competition or other activity
          (such as our online Hackathon).
        </strong>{' '}
        We require that children interested in participating in competitions such as
        our online Hackathon and other activities have a parent or legal guardian
        register them. Once we have obtained your consent to collect information from
        your child, we may ask your child to provide us with his or her name, age,
        grade level, past programming experience, and any other information relevant
        to the competition or activity that is being registered for.
      </li>
      <li>
        <strong>Information collected through user-generated content.</strong> We
        collect any information that your child provides to us when he or she creates
        or publishes projects, records videos, writes comments, or posts on forums
        accessible to other Juni Learning students and instructors.
      </li>
      <li>
        <strong>
          Information collected through your child’s participation in classes and
          other virtual events.
        </strong>{' '}
        To enable our virtual classes and other live events such as Juni Clubs
        meetings, we use third-party services, such as Zoom, to stream video and
        audio feed of interactions between students and instructors as well as the
        contents of any communications enabled by integrated chat functions. Certain
        information is captured incidentally through your child’s participation in
        such events, including your child’s image, voice, movements, physical
        environment, and any other information provided during the event. Ordinarily,
        neither we, nor Zoom, retain this information. However, from time to time, we
        may record special events and make those recordings accessible to other users
        of the Juni Learning Service. If we do so, we will indicate that the event
        will be recorded.
      </li>
      <li>
        <strong>Log Data.</strong> Like many websites, Juni Learning’s Site collects
        certain information automatically and stores it in log files. This may
        include information about the devices your child uses to access our Service
        (including your child’s Internet Protocol (IP) address, browser type, and
        operating system); the address of the web page visited before and after using
        our Service; details about your child’s interactions with our Service (such
        as the date, time, length of stay, and specific pages accessed during his or
        her visits); search terms used to reach our Service; and usage information
        (such as the number and frequency of visitors to our Service). We use this
        information to help us design and administer our website, improve our
        Service, and gather broad demographic information that helps us identify
        visitor preferences.
      </li>
      <li>
        <strong>Student Activity Data.</strong> We also collect certain information
        about students’ activity on the Juni Learner Portal. For instance, we collect
        information about when a student is working on a project or whether a student
        has completed a particular video in order to help our instructors monitor how
        a student is progressing throughout the week and to help Juni Learning
        improve its Service.
      </li>
      <li>
        <strong>Cookies and similar technologies.</strong> We collect some of the
        information above using cookies and similar technologies. A “cookie” is a
        text file that websites send to a visitor’s computer or other
        Internet-connected device to uniquely identify the visitor’s browser or to
        store information or settings in the browser. Juni Learning uses cookies and
        user session data to remember whether your child is logged in, to ensure that
        the site functions properly, to determine how many users have visited certain
        pages, and prevent fraud. You can disable cookies, limit the types of cookies
        you allow, or set your browser to alert you when cookies are being sent.
        Please refer to the guidance offered by your chosen web browser if you’d like
        help managing your cookie preferences.
      </li>
    </ul>
    <p>
      Juni Learning does not require a child to disclose more information than is
      reasonably necessary to participate in Juni Learning’s Service.
    </p>
    <h2>How We Use Your Child’s Information</h2>
    <p>We use the information described above to:</p>
    <ul>
      <li>
        Provide our Services, including administering our classes, features, and
        activities;
      </li>
      <li>Deliver a high-quality and customized learning experience;</li>
      <li>Identify, fix, and troubleshoot bugs and service errors;</li>
      <li>Publish the names of winners or high-performers; and</li>
      <li>
        Send your child prizes for performance in competitions such as our online
        Hackathon.
      </li>
    </ul>
    <p>
      We do not use Personal Information to engage in targeted advertising to
      children and we do not create or build personal profiles of students other than
      for educational purposes or as specifically authorized by a parent or legal
      guardian.
    </p>
    <h2>When Your Child’s Information is Available to Others</h2>
    <p>
      We may share information about your child with certain third parties in the
      following circumstances:
    </p>
    <ul>
      <li>
        <strong>Companies under common control.</strong> We may share Personal
        Information between and among any current or future parents, subsidiaries,
        affiliates, and other companies under common control and ownership with Juni
        Learning.
      </li>
      <li>
        <strong>Vendors and service providers.</strong> We may share Personal
        Information with vendors, consultants, and other service providers who need
        to access the data in order to perform services on our behalf, such as
        providing cloud-hosting or cloud-storage services.
      </li>
      <li>
        <strong>Business transfers.</strong> We may share information with another
        company in connection with or during negotiations of any merger, acquisition,
        financing, re-organization, bankruptcy, sale of all or a portion of our
        assets, or transition of services to another provider.
      </li>
      <li>
        <strong>Legal requirements.</strong> We may share Personal Information when
        we believe it is necessary to comply with a legal obligation, including
        lawful requests from public authorities to meet national security or law
        enforcement requirements. We may also share Personal Information when we
        believe it is necessary to protect Juni Learning’s rights and property, to
        protect the safety of our users, and to defend against legal liability.
      </li>
      <li>
        <strong>Consent.</strong> We may share Personal Information with third
        parties with a parent or legal guardian’s consent.
      </li>
      <li>
        <strong>Juni Learning instructors.</strong> In the course of providing our
        Service, we also share Personal Information about students with our
        instructors to enable instructors to communicate with students and to assist
        instructors in understanding each student’s individual needs and
        capabilities.
      </li>
      <li>
        <strong>Social media platforms.</strong> Our Service may offer social sharing
        features and other integrated tools such as widgets, which allow students to
        share information from their Juni Learning account with third-party social
        media platforms like Facebook or Twitter. Using these social sharing features
        is optional.
      </li>
      <li>
        <strong>Public forums and publicly available content.</strong> We offer
        several features that allow our students to connect and share content in
        public or semi-public spaces. Students do not have to use these features, but
        if they do, they should be sure to use good judgement. Certain information in
        students’ profiles such as their username and photo or avatar, the projects
        they choose to share, and any other information they disclose in the public
        or semi-public areas of Juni Learning’s Service, may be read, collected,
        stored, or used by others.
      </li>
    </ul>
    <p>
      We may also share aggregate or de-identified information, which cannot
      reasonably be used to identify your child, for various purposes including
      compliance with various reporting obligations; for business or marketing
      purposes; or to assist third parties in understanding our users’ interests,
      habits, and usage patterns for certain programs, content, services,
      advertisements, promotions, and/or functionality available through the Service.
    </p>
    <h2>Parental Choices and Controls:</h2>
    <p>At any time, you have the right to:</p>
    <ul>
      <li>Review your child’s Personal Information;</li>
      <li>Direct us to delete your child’s Personal Information;</li>
      <li>
        Refuse to permit Juni Learning to collect further Personal Information from
        your child online and request that we delete the Personal Information that we
        have already collected from your child; and
      </li>
      <li>
        Agree to the collection and use of your child’s Personal Information, but
        choose not to allow disclosure to third parties.
      </li>
    </ul>
    <p>
      Please keep in mind that a request to delete information or refusal to permit
      disclosure of the information to third parties may affect your child’s ability
      to access the Juni Learner Portal or other features or activities on our Site.
    </p>
    <p>
      To exercise any of these options, please contact us at{' '}
      <a href="mailto:privacy@learnwithjuni.com">privacy@learnwithjuni.com</a>.
      Please be aware that to protect children’s privacy and security, we will take
      reasonable steps to verify a parent or legal guardian’s identity before
      granting access to any Personal Information.
    </p>
    <p>
      Additionally, if you have a family account with Juni Learning, you may access,
      change, or delete Personal Information that we have collected from your child
      though the account settings in your family account on the Juni Learner Portal.
    </p>
    <h2>Changes to Our Practices:</h2>
    <p>
      If we change the way we collect, use, or disclose your child’s Personal
      Information, we will send you a new Direct Notice and new request for consent.
    </p>
    <h2>How to Provide Consent:</h2>
    <p>
      Once you have reviewed this Direct Notice, you may provide your consent to our
      collection, use, or disclosure of your child’s Personal Information by
      completing this submission.
    </p>
  </div>
);

export default CoppaDirectNotice;
