import styled from 'styled-components';

interface OverlayProps {
  /**
   * The background color to apply to the overlay, if any.
   */
  bgColor?: string;
}

/**
 * Covers the full container of its parent element.
 */
const Overlay = styled.div.attrs<OverlayProps>({
  className:
    'absolute flex justify-center items-center top-0 right-0 bottom-0 left-0',
})<OverlayProps>`
  ${({ bgColor }) =>
    bgColor &&
    `
    background-color: ${bgColor};
  `}
`;

export default Overlay;
