import { useState } from 'react';

export interface Status {
  status: 'success' | 'error' | 'warning';
  message: string;
}

type MessageSetter = (message: string) => void;

type StatusReturnValue = [
  Status | null,
  {
    setSuccess: MessageSetter;
    setError: MessageSetter;
    setWarning: MessageSetter;
    setStatus: (status: Status | null) => void;
  },
];

export function useStatus(): StatusReturnValue {
  const [status, setStatus] = useState<Status | null>(null);
  return [
    status,
    {
      setStatus,
      setSuccess: message => setStatus({ status: 'success', message }),
      setWarning: message => setStatus({ status: 'warning', message }),
      setError: message => setStatus({ status: 'error', message }),
    },
  ];
}
