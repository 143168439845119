import React from 'react';
import styled from 'styled-components/macro';

const PortalIcon = styled.div`
  align-self: center;
  width: 14px;
  height: 14px;
  padding-left: 20px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
  }
`;

interface CaretIconProps {
  arrowUp: boolean;
}

interface NumberIconProps {
  number: number;
}

interface TagIconProps {
  fill: string;
}

const CaretIconWrapper = styled(PortalIcon)<any>`
  transform: ${props => props.arrowUp && 'rotate(180deg)'};
  padding: ${props => props.arrowUp && '0px'};
`;

const JuniBadgeIconWrapper = styled(PortalIcon)`
  width: 12px;
  height: 12px;
  padding-left: 0px;
  margin-right: 8px;
`;

const NumberIconWrapper = styled.div`
  align-self: center;
  width: 20px;
  height: 20px;
  padding-left: 17px;
  display: inline-flex;
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f55151;
    color: #fff;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
`;

const CalendarIcon = () => (
  <PortalIcon>
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 16.2782H9V16.0282V12.171V11.921H8.75H2.5H2.25V12.171V16.0282V16.2782H2.5H8.75ZM8.75 9.84961H9V9.59961V5.74247V5.49247H8.75H2.5H2.25V5.74247V9.59961V9.84961H2.5H8.75ZM17.5 16.2782H17.75V16.0282V12.171V11.921H17.5H11.25H11V12.171V16.0282V16.2782H11.25H17.5ZM17.5 9.84961H17.75V9.59961V5.74247V5.49247H17.5H11.25H11V5.74247V9.59961V9.84961H11.25H17.5ZM1.875 0.849609H18.125C19.0159 0.849609 19.75 1.59443 19.75 2.52818V16.671C19.75 17.6048 19.0159 18.3496 18.125 18.3496H1.875C0.984116 18.3496 0.25 17.6048 0.25 16.671V2.52818C0.25 1.59443 0.984116 0.849609 1.875 0.849609Z"
        fill="#DDEBF2"
        stroke="#1B658D"
        strokeWidth="0.5"
      />
    </svg>
  </PortalIcon>
);

const StarIcon = () => (
  <PortalIcon>
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7929 1.59148L11.7927 1.59115C11.309 0.571743 9.89788 0.556381 9.40877 1.59141L9.40874 1.59148L7.10579 6.47001L1.95658 7.25435C0.861045 7.42036 0.443636 8.81418 1.22035 9.60441C1.22037 9.60443 1.22039 9.60445 1.2204 9.60446L4.95029 13.4019L4.06778 18.7682L4.06776 18.7683C3.88491 19.8848 5.0178 20.7596 5.99641 20.2178C5.9965 20.2178 5.99659 20.2177 5.99668 20.2177L10.6008 17.6892L15.2055 20.218C16.1829 20.755 17.3169 19.8861 17.1338 18.7683L17.1338 18.7682L16.2513 13.4019L19.9812 9.60446C20.758 8.81424 20.3406 7.42036 19.245 7.25435L14.0958 6.47001L11.7929 1.59148ZM14.0836 12.5857L14.0199 12.6505L14.0346 12.7402L14.8257 17.567L10.687 15.2954L10.6008 15.248L10.5146 15.2954L6.37586 17.567L7.16697 12.7402L7.18167 12.6505L7.11797 12.5857L3.75303 9.16089L8.39528 8.45604L8.48961 8.44172L8.53036 8.35546L10.6008 3.97346L12.6712 8.35546L12.712 8.44172L12.8063 8.45604L17.4486 9.16089L14.0836 12.5857Z"
        fill="white"
        stroke="white"
        strokeWidth="0.358282"
      />
    </svg>
  </PortalIcon>
);

const ClockIcon = () => (
  <PortalIcon>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1691 0.664551C5.0319 0.664551 0.869629 4.82683 0.869629 9.96405C0.869629 15.1013 5.0319 19.2635 10.1691 19.2635C15.3063 19.2635 19.4686 15.1013 19.4686 9.96405C19.4686 4.82683 15.3063 0.664551 10.1691 0.664551ZM10.1691 17.4636C6.0256 17.4636 2.66953 14.1076 2.66953 9.96405C2.66953 5.82052 6.0256 2.46445 10.1691 2.46445C14.3127 2.46445 17.6687 5.82052 17.6687 9.96405C17.6687 14.1076 14.3127 17.4636 10.1691 17.4636ZM12.4865 13.5489L9.30292 11.2352C9.18668 11.149 9.11918 11.014 9.11918 10.8715V4.71433C9.11918 4.46684 9.32167 4.26436 9.56916 4.26436H10.7691C11.0166 4.26436 11.2191 4.46684 11.2191 4.71433V10.0278L13.7239 11.8502C13.9264 11.9964 13.9677 12.2777 13.8214 12.4802L13.1165 13.4514C12.9702 13.6501 12.689 13.6951 12.4865 13.5489Z"
        fill="white"
      />
    </svg>
  </PortalIcon>
);

const InfoIcon = () => (
  <PortalIcon>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1154 6.31079C10.7103 6.31079 11.1925 5.82856 11.1925 5.2337C11.1925 4.63884 10.7103 4.15662 10.1154 4.15662C9.52056 4.15662 9.03833 4.63884 9.03833 5.2337C9.03833 5.82856 9.52056 6.31079 10.1154 6.31079Z"
        fill="white"
      />
      <path
        d="M12.4772 13.7734H10.9385V7.61865H8.63049C8.42645 7.61865 8.23076 7.69971 8.08648 7.84399C7.9422 7.98827 7.86114 8.18396 7.86114 8.388C7.86114 8.59204 7.9422 8.78773 8.08648 8.93201C8.23076 9.07629 8.42645 9.15735 8.63049 9.15735H9.39984V13.7734H7.86114C7.6571 13.7734 7.46141 13.8545 7.31713 13.9988C7.17285 14.1431 7.0918 14.3387 7.0918 14.5428C7.0918 14.7468 7.17285 14.9425 7.31713 15.0868C7.46141 15.2311 7.6571 15.3121 7.86114 15.3121H12.4772C12.6813 15.3121 12.877 15.2311 13.0212 15.0868C13.1655 14.9425 13.2466 14.7468 13.2466 14.5428C13.2466 14.3387 13.1655 14.1431 13.0212 13.9988C12.877 13.8545 12.6813 13.7734 12.4772 13.7734Z"
        fill="white"
      />
      <path
        d="M10.1692 0.69458C8.34324 0.69458 6.55829 1.23604 5.04007 2.25048C3.52185 3.26493 2.33853 4.70679 1.63977 6.39375C0.941012 8.08071 0.758184 9.937 1.11441 11.7279C1.47063 13.5187 2.34991 15.1637 3.64106 16.4549C4.9322 17.746 6.57722 18.6253 8.36808 18.9815C10.1589 19.3378 12.0152 19.1549 13.7022 18.4562C15.3892 17.7574 16.831 16.5741 17.8455 15.0559C18.8599 13.5377 19.4014 11.7527 19.4014 9.92675C19.4014 7.47823 18.4287 5.12999 16.6973 3.39862C14.966 1.66725 12.6177 0.69458 10.1692 0.69458ZM10.1692 17.6202C8.64756 17.6202 7.16011 17.169 5.89492 16.3236C4.62974 15.4783 3.64364 14.2767 3.06134 12.8709C2.47904 11.4651 2.32669 9.91822 2.62354 8.42583C2.92039 6.93344 3.65313 5.5626 4.72908 4.48664C5.80503 3.41069 7.17588 2.67796 8.66827 2.3811C10.1607 2.08425 11.7076 2.23661 13.1134 2.81891C14.5192 3.40121 15.7207 4.3873 16.5661 5.65249C17.4115 6.91767 17.8627 8.40513 17.8627 9.92675C17.8627 10.9371 17.6637 11.9375 17.277 12.8709C16.8904 13.8043 16.3237 14.6525 15.6093 15.3669C14.8949 16.0813 14.0468 16.648 13.1134 17.0346C12.1799 17.4212 11.1795 17.6202 10.1692 17.6202Z"
        fill="white"
      />
    </svg>
  </PortalIcon>
);

const FeedIcon = () => (
  <PortalIcon>
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8845 9.05601H18.7416C19.5304 9.05601 20.1702 9.68294 20.1702 10.456V13.256C20.1702 14.0291 19.5304 14.656 18.7416 14.656H15.8845C15.0956 14.656 14.4559 14.0291 14.4559 13.256V10.456C14.4559 9.68294 15.0956 9.05601 15.8845 9.05601ZM8.74159 9.05601H11.5987C12.3876 9.05601 13.0273 9.68294 13.0273 10.456V13.256C13.0273 14.0291 12.3876 14.656 11.5987 14.656H8.74159C7.95275 14.656 7.31302 14.0291 7.31302 13.256V10.456C7.31302 9.68294 7.95275 9.05601 8.74159 9.05601ZM1.59874 9.05601H4.45588C5.24472 9.05601 5.88445 9.68294 5.88445 10.456V13.256C5.88445 14.0291 5.24472 14.656 4.45588 14.656H1.59874C0.809898 14.656 0.170166 14.0291 0.170166 13.256V10.456C0.170166 9.68294 0.809898 9.05601 1.59874 9.05601ZM15.8845 0.656006H18.7416C19.5304 0.656006 20.1702 1.28294 20.1702 2.05601V4.85601C20.1702 5.62907 19.5304 6.25601 18.7416 6.25601H15.8845C15.0956 6.25601 14.4559 5.62907 14.4559 4.85601V2.05601C14.4559 1.28294 15.0956 0.656006 15.8845 0.656006ZM8.74159 0.656006H11.5987C12.3876 0.656006 13.0273 1.28294 13.0273 2.05601V4.85601C13.0273 5.62907 12.3876 6.25601 11.5987 6.25601H8.74159C7.95275 6.25601 7.31302 5.62907 7.31302 4.85601V2.05601C7.31302 1.28294 7.95275 0.656006 8.74159 0.656006ZM1.59874 0.656006H4.45588C5.24472 0.656006 5.88445 1.28294 5.88445 2.05601V4.85601C5.88445 5.62907 5.24472 6.25601 4.45588 6.25601H1.59874C0.809898 6.25601 0.170166 5.62907 0.170166 4.85601V2.05601C0.170166 1.28294 0.809898 0.656006 1.59874 0.656006Z"
        fill="white"
      />
    </svg>
  </PortalIcon>
);

const CaretIcon = (props: CaretIconProps) => (
  <CaretIconWrapper arrowUp={props.arrowUp}>
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91707 8.76129L0.241469 2.16119C-0.0804897 1.84287 -0.0804897 1.3268 0.241469 1.00852L1.02007 0.238729C1.34148 -0.0790433 1.86239 -0.0796549 2.18456 0.23737L7.50002 5.46807L12.8154 0.23737C13.1376 -0.0796549 13.6585 -0.0790433 13.9799 0.238729L14.7585 1.00852C15.0805 1.32684 15.0805 1.84291 14.7585 2.16119L8.08296 8.76129C7.76101 9.07957 7.23903 9.07957 6.91707 8.76129Z"
        fill="white"
      />
    </svg>
  </CaretIconWrapper>
);

const NumberIcon = (props: NumberIconProps) => (
  <NumberIconWrapper>
    <div>{Math.min(props.number, 99)}</div>
  </NumberIconWrapper>
);

const JuniBadgeIcon = () => (
  <JuniBadgeIconWrapper>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.19727 0.429688C5.10947 0.429688 4.0461 0.752257 3.14163 1.3566C2.23716 1.96095 1.53221 2.81993 1.11593 3.82493C0.699649 4.82992 0.59073 5.93579 0.802949 7.00268C1.01517 8.06958 1.53899 9.04958 2.30818 9.81877C3.07737 10.588 4.05738 11.1118 5.12427 11.324C6.19117 11.5362 7.29703 11.4273 8.30202 11.011C9.30702 10.5947 10.166 9.88979 10.7703 8.98532C11.3747 8.08085 11.6973 7.01748 11.6973 5.92969C11.6973 4.471 11.1178 3.07205 10.0864 2.0406C9.0549 1.00915 7.65596 0.429688 6.19727 0.429688ZM7.73969 7.69703C7.74454 8.00607 7.7163 8.31474 7.65545 8.61777C7.61102 8.85124 7.50285 9.06786 7.34292 9.24367C7.17046 9.41631 6.95399 9.53838 6.71702 9.59663C6.38241 9.67782 6.03857 9.71464 5.69436 9.70614H4.65653V8.08453H5.0971C5.27906 8.07864 5.39952 8.04326 5.45849 7.97923C5.51745 7.91521 5.54273 7.79391 5.54273 7.61785V2.90043H7.73969V7.69703ZM7.73969 2.47418H5.54946V2.15239H7.73969V2.47418Z"
        fill="#00A3FF"
      />
    </svg>
  </JuniBadgeIconWrapper>
);

const SpeechBubbleIcon = () => (
  <PortalIcon>
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4011 0.813232H2.09492C1.10868 0.813232 0.306641 1.61527 0.306641 2.60151V13.3312C0.306641 14.3174 1.10868 15.1194 2.09492 15.1194H4.77733V18.4876L10.3898 15.1194H16.4011C17.3873 15.1194 18.1894 14.3174 18.1894 13.3312V2.60151C18.1894 1.61527 17.3873 0.813232 16.4011 0.813232ZM16.4011 13.3312H9.89447L6.5656 15.3278V13.3312H2.09492V2.60151H16.4011V13.3312Z"
        fill="white"
      />
    </svg>
  </PortalIcon>
);

const TagIcon = (props: TagIconProps) => (
  <JuniBadgeIconWrapper>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7043 0.512695H6.68358C6.38289 0.512695 6.09354 0.631837 5.88135 0.845158L0.828031 5.89848C0.615484 6.11124 0.496094 6.39968 0.496094 6.70042C0.496094 7.00116 0.615484 7.2896 0.828031 7.50236L4.84822 11.5226C5.06099 11.7351 5.34942 11.8545 5.65016 11.8545C5.9509 11.8545 6.23934 11.7351 6.4521 11.5226L11.5049 6.4698C11.7182 6.25761 11.8379 5.96883 11.8379 5.66814V1.64738C11.8379 1.34644 11.7183 1.05783 11.5055 0.845037C11.2928 0.632242 11.0041 0.512695 10.7032 0.512695H10.7043ZM9.00628 4.48013C8.85518 4.48354 8.70491 4.45674 8.56431 4.40128C8.4237 4.34583 8.2956 4.26284 8.18751 4.15719C8.07942 4.05155 7.99352 3.92537 7.93487 3.78607C7.87621 3.64677 7.84598 3.49716 7.84594 3.34601C7.8459 3.19487 7.87606 3.04524 7.93465 2.90591C7.99323 2.76658 8.07906 2.64036 8.1871 2.53466C8.29514 2.42896 8.4232 2.34591 8.56378 2.29039C8.70435 2.23486 8.85461 2.20798 9.00571 2.21132C9.30219 2.21788 9.58431 2.34023 9.79169 2.5522C9.99908 2.76417 10.1152 3.0489 10.1153 3.34545C10.1154 3.64199 9.99937 3.92678 9.79209 4.13885C9.58481 4.35092 9.30275 4.47342 9.00628 4.48013Z"
        fill={props.fill}
      />
    </svg>
  </JuniBadgeIconWrapper>
);

const TrophyIcon = () => (
  <PortalIcon>
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1219 2.85522H15.6219V2.10522C15.6216 1.70752 15.4634 1.32622 15.1822 1.045C14.901 0.763785 14.5197 0.605622 14.1219 0.605225H5.12195C4.72425 0.605622 4.34295 0.763785 4.06173 1.045C3.78051 1.32622 3.62235 1.70752 3.62195 2.10522V2.85522H2.12195C1.72425 2.85562 1.34295 3.01378 1.06173 3.295C0.780508 3.57622 0.622345 3.95752 0.621948 4.35522V6.60522C0.622941 7.40057 0.93933 8.16305 1.50172 8.72545C2.06412 9.28784 2.8266 9.60423 3.62195 9.60522H3.86345C4.17598 10.7533 4.81602 11.7855 5.70543 12.5758C6.59484 13.3661 7.69513 13.8803 8.87195 14.0557V17.1052H5.12195V18.6052H14.1219V17.1052H10.3719V14.0535C11.564 13.9061 12.684 13.4028 13.5858 12.6093C14.4875 11.8158 15.1292 10.7689 15.4269 9.60522H15.6219C16.4173 9.60423 17.1798 9.28784 17.7422 8.72545C18.3046 8.16305 18.621 7.40057 18.6219 6.60522V4.35522C18.6216 3.95752 18.4634 3.57622 18.1822 3.295C17.901 3.01378 17.5197 2.85562 17.1219 2.85522ZM3.62195 8.10522C3.22425 8.10483 2.84295 7.94666 2.56173 7.66545C2.28051 7.38423 2.12235 7.00293 2.12195 6.60522V4.35522H3.62195V8.10522ZM14.1219 8.10522C14.1219 8.70791 14.0009 9.30445 13.7659 9.85945C13.531 10.4144 13.1869 10.9166 12.7542 11.3361C12.3215 11.7556 11.8089 12.0839 11.2469 12.3015C10.6849 12.5191 10.0848 12.6217 9.48245 12.603C8.28829 12.5286 7.16874 11.9969 6.35661 11.1183C5.54448 10.2396 5.10227 9.08178 5.12195 7.88547V2.10522H14.1219V8.10522ZM17.1219 6.60522C17.1216 7.00293 16.9634 7.38423 16.6822 7.66545C16.401 7.94666 16.0197 8.10483 15.6219 8.10522V4.35522H17.1219V6.60522Z"
        fill="white"
      />
    </svg>
  </PortalIcon>
);

export {
  CalendarIcon,
  ClockIcon,
  FeedIcon,
  InfoIcon,
  StarIcon,
  CaretIcon,
  NumberIcon,
  JuniBadgeIcon,
  SpeechBubbleIcon,
  TagIcon,
  TrophyIcon,
};
