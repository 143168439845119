import React from 'react';
import { jDark, jBlue, jGreen, jPink, jOrange, jYellow } from 'theme/colors';

// need to support both old and new colors pre and post New Horizons restyling
const COLORS = [
  'black',
  'orange',
  'yellow',
  'lime',
  'cyan',
  'blue',
  'darkslateblue',
  'magenta',
  'red',
  // new horizons colors
  jDark[600],
  jBlue[400],
  '#6BE4FF',
  jGreen[500],
  '#E64FFF',
  jPink[600],
  jOrange[500],
  jYellow[400],
];

const ARROW_HEAD_WIDTH = 10;
const ARROW_HEAD_HEIGHT = 10;

const POINTS = `0 0, ${ARROW_HEAD_WIDTH} ${
  ARROW_HEAD_HEIGHT / 2
}, 0 ${ARROW_HEAD_HEIGHT}`;

/* Create triangle-shaped markers to be used in the arrow tool */
const MarkerSVG: React.FC = () => (
  <svg id="defs" className="svg-defs">
    <defs>
      {COLORS.map(color => (
        <marker
          id={`triangle-${color}`}
          key={color}
          refX={ARROW_HEAD_WIDTH - 1}
          refY={ARROW_HEAD_HEIGHT / 2}
          markerUnits="strokeWidth"
          markerWidth={ARROW_HEAD_WIDTH}
          markerHeight={ARROW_HEAD_HEIGHT}
          orient="auto"
        >
          <polygon points={POINTS} fill={color} />
        </marker>
      ))}
    </defs>
  </svg>
);

export default MarkerSVG;
