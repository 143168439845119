import juniAxios from 'services/axios';

export async function getVideos(videoIds) {
  if (videoIds.length === 0) {
    return [];
  }
  return (await juniAxios.get(`/videos/${videoIds.join(',')}`)).data.data;
}

export async function addVideoToStudentRecentVideos(
  studentId,
  courseId,
  courseType,
  moduleId,
  videoId,
) {
  return (
    await juniAxios.post(
      `/videos/recently_watched/${studentId}/course/${courseId}/courseType/${courseType}/module/${moduleId}/video/${videoId}`,
      {},
    )
  ).data.data;
}

export async function getStudentRecentVideos(studentId) {
  return (await juniAxios.get(`/videos/recently_watched/${studentId}`)).data.data;
}
