import {
  CHECKOUT_PATHS,
  ONBOARDING_PATHS,
  BASE_PATHS,
} from 'constants/signup_sessions';

import { MARKETING_SITE_LINKS } from 'constants/links';

import { Context } from './types';
import navStates from './states';

const defaultRoute = MARKETING_SITE_LINKS.PATHS_PAGE;

const getRouteOrDefault = (context: Context, base: string, path = '') => {
  const id = context.signupData?._id || context.signupSessionId;
  if (id) {
    return `/${base}/${id}${path ? `/${path}` : ''}${context.search || ''}`;
  }
  return defaultRoute;
};

export const routes: { [x: string]: (context: Context) => string } = {
  [navStates.signup.default]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.checkout),
  [navStates.signup.courseFrequency]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.checkout, CHECKOUT_PATHS.courseFrequency),
  [navStates.signup.parentInfo]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.checkout, CHECKOUT_PATHS.parentInfo),
  [navStates.signup.studentInfo]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.checkout, CHECKOUT_PATHS.studentInfo),
  [navStates.signup.starterCourse]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.checkout, CHECKOUT_PATHS.starterCourse),
  [navStates.signup.checkout]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.checkout, CHECKOUT_PATHS.checkout),
  [navStates.onboarding.default]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.onboarding),
  [navStates.onboarding.createAccount]: (context: Context) =>
    getRouteOrDefault(
      context,
      BASE_PATHS.onboarding,
      ONBOARDING_PATHS.createAccount,
    ),
  [navStates.onboarding.schedulingPreferences]: (context: Context) =>
    getRouteOrDefault(
      context,
      BASE_PATHS.onboarding,
      ONBOARDING_PATHS.schedulingPreferences,
    ),
  [navStates.onboarding.coursePlacement]: (context: Context) =>
    getRouteOrDefault(
      context,
      BASE_PATHS.onboarding,
      ONBOARDING_PATHS.coursePlacement,
    ),
  [navStates.onboarding.studentInfo]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.onboarding, ONBOARDING_PATHS.studentInfo),
  [navStates.onboarding.review]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.onboarding, ONBOARDING_PATHS.review),
  [navStates.onboarding.confirmation]: (context: Context) =>
    getRouteOrDefault(context, BASE_PATHS.onboarding, ONBOARDING_PATHS.confirmation),
  // we want to pass the context to all routes in order to maintain the pattern
  // eslint-disable-next-line
  [navStates.tuitionPage]: (context: Context) => MARKETING_SITE_LINKS.TUITION_PAGE,
  [navStates.onDemandLibrary]: (context: Context) => {
    const mongoStudentId = context.signupData?.students?.[0]?.mongoStudentId;
    return mongoStudentId ? `/learner/${mongoStudentId}/on_demand` : '/learner';
  },
};
