import { FC } from 'react';
import 'moment-timezone';
import ModalWindow from 'core-components/NewModalWindow/NewModalWindow';

interface CalendlyWidgetProps {
  calendlyUrl: string;
  title: string;
  isOpen: boolean;
  closeModal: () => void;
  contentHeight?: string;
}

const CalendlyWidget: FC<CalendlyWidgetProps> = ({
  calendlyUrl,
  title,
  isOpen,
  closeModal,
  contentHeight,
}) => (
  <ModalWindow
    isOpen={isOpen}
    closeModal={closeModal}
    title={title}
    contentMaxHeight="85vh"
  >
    {/* The official way to do this is an iframe.
        https://stackoverflow.com/questions/53891698/embed-calendly-into-react */}
    <div style={{ height: contentHeight, width: '100%' }}>
      <iframe
        title={title}
        src={calendlyUrl}
        width="100%"
        height="100%"
        scrolling="no"
        frameBorder={0}
      ></iframe>
    </div>
  </ModalWindow>
);

export default CalendlyWidget;
