import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import { projectTypeToRenderInfo } from 'constants/project_types';

import { FilledTriangle } from 'components/Icons';
import { IInitialFilterState, IFilterAction } from './models';

const FilterContainer = styled.div<any>`
  display: flex;
  border: ${props =>
    props.empty ? '2px solid rgba(0,0,0,.2)' : '2px solid #00a5ff'};
  border-radius: ${props => (props.open && !props.empty ? `8px 8px 0 0` : `8px`)};
  background: white;
  cursor: pointer;
  z-index: 999;
`;

const FilterListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  & .select-toggle-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 5px 0;
    p {
      line-height: 23px;
    }
    & .left {
      padding: 0 0.5rem 0 0.75rem;
    }

    & .left {
      width: auto;

      & .label {
        font-weight: 700;
        white-space: nowrap;
      }
    }

    & .right {
      padding: 0 4px 0 2px;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      margin: 0;
      flex-direction: row;

      & .filter-by {
        padding: 0 24px 0 0;
        color: #a1b5c0;
        font-weight: 600;
        .icon__container {
          transform: translateY(1px);
        }
      }

      & .icon__container {
        display: flex;
        align-self: center;
        margin-left: auto;
      }
    }

    & .left:after {
      content: '';
      height: 1.5rem;
      width: 2px;
      background: #d4f0ff;
      margin-left: 0.5rem;
    }
  }
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #00a5ff;
  position: absolute;
  border-radius: 0 0 8px 8px;
  background: white;
  width: 100%;
  left: -2px;
  z-index: 1;
  top: 100%;
  > *:not(:first-of-type) {
    border-top: 1px solid #d1eaf7;
  }
`;

const ProjectTypeController = styled.div`
  display: flex;
  color: #32444f;
  font-weight: 700;
  padding: 0.5rem 0.7rem;
  transition-duration: 0.1s;
`;

const Radio = styled.button<any>`
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 2.125rem;
  margin-left: auto;
  width: 2.5rem;
  height: 1.5rem;

  background: ${(props: any) => (props.active ? '#00A5FF' : '#8ECFF2')};

  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    display: flex;
    position: relative;
    top: -0.2rem;
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid #dff3ff;
    box-sizing: border-box;
    box-shadow: 0px 3.51202px 11.4141px rgba(0, 0, 0, 0.25);
    left: ${(props: any) => (props.active ? '.55rem' : '-0.35rem')};
  }
`;

const PlaygroundFilterController: React.FC<{
  userType?: string;
  dispatchFilterEvent: (action: IFilterAction) => void;
  filteredState: IInitialFilterState;
}> = ({ dispatchFilterEvent, filteredState, userType }) => {
  const [open, toggleOpen] = useState(false);

  // detect click outside of dropdown and close
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('click', (e: any) => {
      if (!containerRef.current?.contains(e.target)) {
        toggleOpen(false);
      }
    });

    return () => document.removeEventListener('click', () => undefined);
  });

  const projectKeys = Object.keys(filteredState);
  return (
    <FilterContainer
      empty={projectKeys.length === 0}
      open={open}
      userType={userType}
      ref={containerRef}
    >
      <FilterListContainer>
        <div className="select-toggle-container" onClick={() => toggleOpen(!open)}>
          <div className="left">
            <p className="label">Filter by</p>
          </div>
          <div className="right">
            <p className="filter-by">Filter Projects...</p>
            <div className="icon__container">
              <FilledTriangle />
            </div>
          </div>
        </div>
        {open && projectKeys.length > 0 && (
          <FilterOptions>
            {projectKeys.map(key => (
              <ProjectTypeController
                onClick={() => {
                  dispatchFilterEvent({
                    type: 'FILTER_TYPE',
                    payload: {
                      key,
                      visible: !filteredState[key].visible,
                    },
                  });
                }}
                key={key}
              >
                <p>{projectTypeToRenderInfo(key).displayName}</p>
                <Radio active={filteredState[key].visible} />
              </ProjectTypeController>
            ))}
          </FilterOptions>
        )}
      </FilterListContainer>
    </FilterContainer>
  );
};

export default PlaygroundFilterController;
