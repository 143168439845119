import React, { FC } from 'react';
import styled from 'styled-components/macro';

import alertIcon from 'images/signup_sessions/icons/alert.svg';
import { brown } from 'theme/old-colors';

const WarningBlockContainer = styled.div`
  a {
    color: ${brown[600]};
    font-weight: 500;
  }
`;

const WarningBlock: FC = ({ children }) => (
  <WarningBlockContainer className="flex items-center space-x-4 p-2 rounded-lg bg-amber-50 border-2 border-solid border-amber-100">
    <div>
      <img src={alertIcon} alt="alert" />
    </div>
    <div className="text-brown-600 text-sm">{children}</div>
  </WarningBlockContainer>
);

export default WarningBlock;
