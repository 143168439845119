import React, { FC } from 'react';
import { Formik, Form, FormikHelpers, FormikState } from 'formik';
import * as yup from 'yup';
import { InputField, FormGroup, Button } from 'components/ui';
import { ModalWindow } from 'core-components';
import { parseError } from 'utils/errors';
import { ProjectTypes } from 'app/playground/models';

import { CustomProject } from 'models';
import CustomProjectService from 'services/custom_projects';
import SpinnerV2 from 'components/SpinnerV2';

import { FormWrapper } from './styles';

const MODAL_TITLE = 'Name this project';

const MODAL_DESCRIPTION = `
  Custom projects are blank projects that you can use to store reference code or
  create your own project of choice. Name your custom project something that will
  help you remember what it is! You can always find this project within the module
  you created it in.
`;

interface Props {
  onSuccess(project: CustomProject): void;
  closeModal(): void;
  reloadIdLookup(): void;
  isVisible: boolean;
  moduleId: string;
  studentId: string;
  projectType: ProjectTypes;
}

interface FormValues {
  displayName: string;
}

const ProjectSchema = yup.object().shape({
  displayName: yup
    .string()
    .min(2, 'Project name is too short')
    .required('Project name is required'),
});

const CreateCustomProjectModal: FC<Props> = ({
  isVisible,
  onSuccess,
  closeModal,
  studentId,
  moduleId,
  reloadIdLookup,
  projectType,
}) => {
  const initialValues: FormValues = {
    displayName: '',
  };

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      helpers.setSubmitting(true);

      const { displayName } = values;
      const project = { displayName, moduleId, studentId, projectType };
      const saved = await CustomProjectService.create(project);
      await reloadIdLookup();

      requestCloseModal();
      helpers.resetForm();
      helpers.setSubmitting(false);

      onSuccess(saved);
    } catch (err) {
      if (err?.response?.status === 409) {
        helpers.setFieldError('displayName', err.response.data.error);
      } else {
        helpers.setFieldError(
          'displayName',
          parseError(err, `Sorry, that didn't work.`),
        );
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const requestCloseModal = () => {
    closeModal();
  };

  return (
    <ModalWindow
      closeModal={requestCloseModal}
      isOpen={isVisible}
      description={MODAL_DESCRIPTION}
      title={MODAL_TITLE}
      maxWidth="36rem"
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ProjectSchema}
        >
          {({ isSubmitting }: FormikState<FormValues>) => (
            <Form autoComplete="off">
              <FormGroup title="Project Title">
                <InputField.Formik
                  type="text"
                  name="displayName"
                  placeholder="e.g. Purple Turtle Squasher"
                  disabled={isSubmitting}
                />
              </FormGroup>

              <FormGroup className="submit">
                <Button disabled={isSubmitting} type="submit">
                  {isSubmitting ? <SpinnerV2 size={24} /> : 'Create'}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </ModalWindow>
  );
};

export default CreateCustomProjectModal;
