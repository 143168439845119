/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import {
  publicCodeSync,
  studentCodeSync,
  teacherCodeSync,
  getFileVersions,
} from 'services/code';

import { postJideProjectSaved } from 'services/user-events/student-events';

import { difficultyDisplayNames } from 'constants/project_difficulty';

import './python_turtle.css';
import { Panel, Icon } from 'core-components';
import { JideSidebar, JideWidgets } from 'components/jide';
import AlertBanner from 'components/AlertBanner';
import classNames from 'classnames';
import Button from 'core-components/NewButton';
import { JideCodeEditorFooter } from '../JideCodeEditor/JideCodeEditor';
import JideEnvLayout from '../JideEnvLayout';
import JideCodeOutputHeader from '../JideCodeOutput/OutputHeader';
import Footer from '../Footer';
import ActivityInstructions from '../ActivityInstructions';

const CANVAS_SIZE = 2000;

// Initialize Skulpt variables
const { Sk } = window;
// interrupt code borrowed from
// https://github.com/skulpt/skulpt/pull/470#issuecomment-120626639
Sk.builtin.KeyboardInterrupt = function (...args) {
  let o;
  if (!(this instanceof Sk.builtin.KeyboardInterrupt)) {
    o = Object.create(Sk.builtin.KeyboardInterrupt.prototype);
    o.constructor(...args);
    return o;
  }
  Sk.builtin.BaseException.apply(this, args);
};
Sk.abstr.setUpInheritance(
  'KeyboardInterrupt',
  Sk.builtin.KeyboardInterrupt,
  Sk.builtin.BaseException,
);

// Main Component Code
class PythonTurtle extends Component {
  state = {
    codeRunning: false,
    hardInterrupt: false,
    activePane: 'instructions',
    hasError: false,
    canEdit: false,
    clientLastSync: null, // timestamp for manual sync throttling
    dbUpdatedAt: null, // timestamp sent from server, never modified in client
    hasNewEdits: null,
    code: '',
    saveError: false,
    project: null,
    isSyncing: false,
    orientation: 'horizontal',
    sidebarExpanded: false,
    activeSidebarTab: null,
    restoreInProgress: false,
    filename: 'main.py',
    files: {
      'main.py': {
        isText: true,
        name: 'main.py',
      },
    },
    filesVersions: null,
    isPreview: false,
  };
  debouncedSync = null;
  pyturtRef = React.createRef();
  reactAceRef = React.createRef();
  syncSetTimeout = null;

  componentDidMount() {
    this.isPlayground = this.props.tab.tabNav.course === 'playground';
    this.setOrientation();
    window.addEventListener('resize', this.setOrientation);

    if (!['teacher', 'student'].includes(this.props.jideUser.type)) {
      this.setState({ activePane: 'canvas' });
    }
    this.fetchProject();
    const userHasPermissionsToSave =
      this.props.jideUser.type === 'student' || this.isPlayground;

    if (userHasPermissionsToSave) {
      window.addEventListener('focus', this.syncCodeDelay);
      window.addEventListener('blur', this.syncCodeIfActive);
      window.addEventListener('beforeunload', this.preventIfHasUnsavedCode);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setOrientation);

    const userHasPermissionsToSave =
      this.props.jideUser.type === 'student' || this.isPlayground;

    if (userHasPermissionsToSave) {
      window.removeEventListener('focus', this.syncCodeDelay);
      window.removeEventListener('blur', this.syncCodeIfActive);
      window.removeEventListener('beforeunload', this.preventIfHasUnsavedCode);
    }

    this.stopCode();
    if (this.state.hasNewEdits && !this.state.isSyncing) {
      this.syncCodeCore(
        this.props.tab.tabNav.student,
        this.state.project.id,
        this.state.dbUpdatedAt,
        this.state.hasNewEdits,
        this.state.code,
        this.props.jideUser.type,
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Sync code on project tab switch
    if (prevProps.isActive !== this.props.isActive) {
      this.syncCodeDefault();
    }

    if (this.reactAceRef?.current?.editor) {
      if (
        prevProps.fullScreenMode !== this.props.fullScreenMode ||
        this.state.activeSidebarTab !== prevState.activeSidebarTab
      ) {
        this.setOrientation();
      }

      const fileRestored =
        prevState.restoreInProgress && !this.state.restoreInProgress;
      const fileLoaded =
        prevState.isSyncing &&
        !this.state.isSyncing &&
        prevState.code !== this.state.code;

      if (fileLoaded || fileRestored) {
        // UndoManager isn't dirty yet, but will is updated in this execution step
        // As such, we'll clear the undo manager in the next event loop
        setTimeout(() => {
          this.reactAceRef.current.editor.getSession().getUndoManager().reset();
        }, 0);
      }
    }

    // lets only get the history once we click into the sidebar
    if (
      this.state.activeSidebarTab === JideSidebar.TAB_VERSIONS &&
      prevState.activeSidebarTab !== this.state.activeSidebarTab
    ) {
      this.getFileVersions();
    }

    if (
      this.state.activeSidebarTab !== JideSidebar.TAB_VERSIONS &&
      prevState.activeSidebarTab === JideSidebar.TAB_VERSIONS
    ) {
      // if we move away from the version tab,
      // lets restore and reload the previous file
      if (
        this.state.filesVersions &&
        this.state.filesVersions[this.state.filename]
      ) {
        const filename = this.state.filesVersions[this.state.filename].name;
        this.selectFile(filename);
      }

      // once we move away from the restore tab, free up memory
      this.setState({ filesVersions: null });
    }
  }

  async getFileVersions() {
    // filename is always main.py in python turtle
    const { filename } = this.state;
    const { student, project } = this.props.tab.tabNav;

    // only get version history of files that are isText
    if (!student || !project || !filename) {
      return;
    }

    this.setState({ filesVersions: null });

    // lets sync code first
    this.syncCode(async () => {
      let firstVersion = null;
      const filesVersions = {};

      try {
        const res = await getFileVersions(student, project, filename);

        if (res.status === 200) {
          res.data.versions.forEach(version => {
            const name = `${version.versionId}-${version.name}`;
            filesVersions[name] = version;

            if (firstVersion === null) {
              firstVersion = name;
            }
          });
        }

        this.setState({ filesVersions }, () => {
          // auto select first version
          this.selectFile(firstVersion);
        });
      } catch (error) {
        this.setState({ filesVersions: { error } });
      }
    });
  }

  setOrientation = () => {
    if (!this.props.newHorizons) {
      const elem = this.pyturtRef.current;
      let newOrientation = 'horizontal';
      if (elem.offsetHeight / elem.offsetWidth > 1.2) {
        newOrientation = 'vertical';
      }
      this.setState({ orientation: newOrientation }, () => {
        this.reactAceRef.current.editor.resize();
      });
    } else {
      this.reactAceRef.current.editor.resize();
    }
    const { turtleCanvas } = this.refs;
    turtleCanvas.scrollLeft = (CANVAS_SIZE - turtleCanvas.offsetWidth) / 2;
    turtleCanvas.scrollTop = (CANVAS_SIZE - turtleCanvas.offsetHeight) / 2;
  };

  fetchProject = () => {
    const projectId = this.props.tab.tabNav.project;
    const project = this.props.idLookup[projectId];
    this.setState({ project }, this.syncCodeDefault);
  };

  preventIfHasUnsavedCode = e => {
    if (this.state.hasNewEdits) {
      this.syncCodeDefault();
      e.preventDefault();
      e.returnValue = '';
    } else {
      delete e.returnValue;
    }
  };

  activeStudentOwnsCode = () =>
    this.props.jideUser && this.props.jideUser._id === this.props.tab.tabNav.student;

  syncCode = (cb, delay, onlyIfActive) => {
    const callback = cb || (() => undefined);
    let skipSync = false;

    if (!this.state.project) {
      skipSync = true;
    } else if (onlyIfActive && !this.props.isActive) {
      skipSync = true;
    }
    // if already syncing, then skip
    else if (this.state.isSyncing) {
      skipSync = true;
    }
    // if no edits since last sync and last synced less than 1.5s ago, then skip
    else if (
      !this.state.hasNewEdits &&
      moment
        .utc()
        .isBefore(moment.utc(this.state.clientLastSync).add(1.5, 'seconds'))
    ) {
      skipSync = true;
    }

    // prevent syncing file while we're in preview mode
    if (this.state.isPreview && !this.state.restoreInProgress) {
      skipSync = true;
    }

    if (skipSync) {
      callback();
    } else {
      this.setState({ isSyncing: true, canEdit: false, saveError: false }, () => {
        setTimeout(() => {
          const studentId = this.props.tab.tabNav.student;
          const projectId = this.state.project.id;
          this.syncCodeCore(
            studentId,
            projectId,
            this.state.dbUpdatedAt,
            this.state.hasNewEdits,
            this.state.code,
            this.props.jideUser.type,
          )
            .then(res => {
              const { jideUser, tab } = this.props;
              const { project, course } = tab.tabNav || {};
              const { isCustomProject } = this.state.project.properties || {};

              if (!!jideUser.type && jideUser.type === 'student') {
                postJideProjectSaved({
                  studentId,
                  projectId: project,
                  courseId: course,
                  isCustomProject,
                });
              }

              this.setState(
                {
                  isSyncing: false,
                  code: res.data.code ? res.data.code : '',
                  dbUpdatedAt: res.data.updatedAt,
                  clientLastSync: moment.utc().toISOString(),
                  canEdit: true,
                  saveError: false,
                  hasNewEdits: false,
                },
                callback,
              );
            })
            .catch(() => {
              this.setState(
                { isSyncing: false, canEdit: true, saveError: true },
                callback,
              );
            });
        }, delay || 0);
      });
    }
  };
  syncCodeCore = async (
    studentId,
    projectId,
    dbUpdatedAt,
    hasNewEdits,
    code,
    jideUserType,
  ) => {
    if (jideUserType === 'student') {
      const result = await studentCodeSync(
        studentId,
        projectId,
        dbUpdatedAt,
        hasNewEdits,
        hasNewEdits ? code : null,
      );
      return result;
    }
    if (jideUserType === 'teacher') {
      const result = await teacherCodeSync(
        this.isPlayground ? this.props.jideUser._id : studentId,
        projectId,
        dbUpdatedAt,
        hasNewEdits,
        hasNewEdits ? code : null,
        this.isPlayground,
      );
      return result;
    }
    const result = await publicCodeSync(
      studentId,
      projectId,
      this.props.jideUser?.type === 'public'
        ? this.props.jideUser?.createdByUserType
        : this.props.jideUser?.type,
      this.props.encryptedStudentAndProject,
    );
    return result;
  };
  syncCodeDefault = () => {
    this.syncCode();
  };
  syncCodeDelay = () => {
    this.syncCode(null, 600, true);
  };
  syncCodeIfActive = () => {
    this.syncCode(null, null, true);
  };

  prepareSkulpt = () => {
    // Specify pre element to send console output to
    const mypre = this.refs[`consoleOutput_${this.props.tab.tabId}`];
    mypre.innerHTML = '';
    Sk.pre = `pyturt-console-output-${this.props.tab.tabId}`;

    const outf = text => {
      mypre.innerHTML += text;
    };
    const builtinRead = x => {
      if (Sk.builtinFiles === undefined || Sk.builtinFiles.files[x] === undefined)
        throw new Error(`File not found: '${x}'`);
      return Sk.builtinFiles.files[x];
    };
    Sk.configure({ output: outf, read: builtinRead });

    // Specify canvas element to draw Turtle graphics on
    (
      Sk.TurtleGraphics || (Sk.TurtleGraphics = {})
    ).target = `pyturt-turtle-canvas-${this.props.tab.tabId}`;
    Sk.TurtleGraphics.width = CANVAS_SIZE;
    Sk.TurtleGraphics.height = CANVAS_SIZE;

    // Define interrupt handler for Skulpt
    const interruptHandler = () => {
      if (Sk.hardInterrupt === true) {
        Sk.hardInterrupt = false;
        this.setState({ hardInterrupt: false });
        throw new Sk.builtin.KeyboardInterrupt('aborted execution');
      } else {
        return null;
      }
    };

    return [interruptHandler, mypre];
  };

  // Executes upon hitting 'Run' button
  runCode = () => {
    if (this.state.codeRunning || this.state.isSyncing) return;
    if (!this.state.code || !this.state.code.length) return;
    if (Sk.codeRunning) {
      if (Sk.hardInterrupt) {
        window.alert(
          'Just a moment! Stopping previously running Python with Turtle script. \n\n' +
            'Please try again in a few seconds or open this project in a new browser tab.',
        );
      } else {
        window.alert(
          'In order to run this project, please stop any other Python with Turtle projects that are currently running on this page. \n\n' +
            'Or, you can open this project in a new browser tab!',
        );
      }
      return;
    }

    this.syncCode(async () => {
      this.setState(
        {
          codeRunning: true,
          activePane: 'canvas',
          hasError: false,
        },
        () => {
          // Execute code from Ace editor with Skulpt
          const [interruptHandler, mypre] = this.prepareSkulpt();

          const myPromise = Sk.misceval.asyncToPromise(
            () => {
              Sk.codeRunning = true;
              if (
                this.props.jideUser.type === 'public' &&
                !this.props.fullScreenMode
              ) {
                this.props.toggleFullScreenMode();
              }
              // set orientation and focus canvas element after
              // waiting a bit to ensure it has been rendered to DOM
              setTimeout(() => {
                this.setOrientation();
                const canvasHolderId = `#pyturt-turtle-canvas-${this.props.tab.tabId}`;
                const canvasHolder = document.querySelector(canvasHolderId);
                const canvas = document.querySelector(`${canvasHolderId} > canvas`);
                if (canvas) {
                  canvas.setAttribute('tabindex', '1');
                  canvas.focus();
                } else if (canvasHolder) {
                  canvasHolder.setAttribute('tabindex', '1');
                  canvasHolder.focus();
                }
              }, 200);

              return Sk.importMainWithBody('<stdin>', false, this.state.code, true);
            },
            { '*': interruptHandler },
          );
          myPromise
            .then(() => {
              Sk.codeRunning = false;
              this.setState({ codeRunning: false });
            })
            .catch(err => {
              Sk.codeRunning = false;
              this.setState({ codeRunning: false }, () => {
                const errString = err.toString();
                if (!errString.includes('Keyboard')) {
                  console.error(errString);
                  this.setState({ hasError: true, activePane: 'console' });

                  mypre.innerHTML = `${mypre.innerHTML}<p style='color:red'>${errString}</p>`;
                }
              });
            });
        },
      );
    });
  };

  // Executes upon hitting 'Stop' button
  // Sends hard interrupt to Skulpt
  stopCode = async () => {
    if (!this.state.codeRunning) return;

    Sk.hardInterrupt = true;
    this.setState({ hardInterrupt: true });
    // setTimeout(() => { Sk.hardInterrupt = false; return false; }, 500);
    // this.setState({codeRunning: false, canEdit: true});
  };

  // Ace editor onChange method called every time editor is updated
  aceOnChange = newValue => {
    this.setState({
      code: newValue,
      hasNewEdits: true,
    });

    clearTimeout(this.syncSetTimeout);
    this.syncSetTimeout = setTimeout(() => {
      this.syncCode();
    }, 10 * 1000);
  };

  setSidebarTab = (tab, cb) => {
    if (!this.state.sidebarExpanded) {
      this.setState({ activeSidebarTab: tab, sidebarExpanded: true }, cb);
    } else if (this.state.activeSidebarTab === tab) {
      this.setState({ activeSidebarTab: '', sidebarExpanded: false }, cb);
    } else {
      this.setState({ activeSidebarTab: tab }, cb);
    }
  };

  selectFile = async filename => {
    let file;
    let isPreview;

    if (this.state.filename && filename === this.state.filename) {
      return;
    }

    if (
      this.state.filename &&
      this.state.hasNewEdits &&
      this.state.files[this.state.filename]
    ) {
      // save file before selecting new one
      await this.syncCodeRaw();
    }

    if (this.state.files && this.state.files[filename]) {
      file = this.state.files[filename];
      isPreview = false;
    } else if (this.state.filesVersions && this.state.filesVersions[filename]) {
      file = this.state.filesVersions[filename];
      isPreview = true;
    } else {
      return;
    }

    const fileType = 'code/python';

    if (!isPreview) {
      this.setState(
        {
          isPreview: false,
          filename,
          fileType,
          code: '',
          isSyncing: false,
          canEdit: false,
        },
        this.syncCodeDefault,
      );
    } else {
      this.setState({
        isPreview: true,
        filename,
        fileType,
        code: file.contents,
        saveStatus: 'saved',
        isSyncing: false,
        canEdit: false,
      });
    }
  };

  restoreFile = async () => {
    const file =
      this.state.filesVersions && this.state.filesVersions[this.state.filename];

    if (!file) {
      console.log("Couldn't find file to restore", this.state.filename);
      return;
    }

    // update the file in state
    this.setState(
      {
        restoreInProgress: true,
        code: file.contents,
        filename: file.name,
        hasNewEdits: true,
      },
      async () => {
        // sync code
        await this.syncCode(() => {
          // close sidebar and mark restore complete
          this.setState({
            activeSidebarTab: '',
            sidebarExpanded: false,
            restoreInProgress: false,
            isPreview: false,
          });
        });
      },
    );
  };

  render() {
    const sidebarProps = {
      jideUser: this.props.jideUser,
      sidebarExpanded: this.state.sidebarExpanded,
      fullScreenMode: this.props.fullScreenMode,
      toggleFullScreenMode: this.props.toggleFullScreenMode,
      activeSidebarTab: this.state.activeSidebarTab,
      setSidebarTab: this.setSidebarTab,
      selectFile: this.selectFile,
      parseFileType: () => 'code/python',
      filename: this.state.filename,
      files: this.state.files,
      filesVersions: this.state.filesVersions,
      hasEditPermissions: this.activeStudentOwnsCode(),
      isPreview: this.state.isPreview,
      isDisabled: false,
      enabledTabs: [JideSidebar.TAB_VERSIONS],
      /* new horizons props */
      newHorizons: this.props.newHorizons,
      isPythonTurtle: true,
      runCode: this.runCode,
      stopCode: this.stopCode,
      codeRunning: this.state.codeRunning,
      /** TODO: Verify with samvaran if this is always true
       * or how to determine connection status
       */
      connectionStatus: 'connected',
      // TODO: Confirm this logic
      saveStatus: this.state.restoreInProgress
        ? 'restoring'
        : this.state.isSyncing
        ? 'syncing'
        : this.state.hasNewEdits
        ? 'unsaved'
        : 'saved',
      /** TODO: Figure out why this is undefined and if it's always code/python */
      fileType: 'code/python',
      /** TODO: figure out states for this  */
      startInProgress: false,
      stopInProgress: false,
      syncCodeDefault: this.syncCodeDefault,
    };

    const canDisplayInstructions =
      !this.state.project?.properties?.isCustomProject &&
      ['teacher', 'student'].includes(this.props.jideUser.type);

    if (this.props.newHorizons) {
      return (
        <JideEnvLayout fullScreen={this.props.fullScreenMode}>
          <JideEnvLayout.MainContent withCanvas>
            <JideSidebar {...sidebarProps} />
            <div
              className="w-full box-border flex flex-col"
              style={{ backgroundColor: '#282a36' }}
            >
              {this.state.isPreview && (
                <div className="px-3 my-2 w-full box-border">
                  <Panel
                    backgroundColor="j-blue-500"
                    padding="3"
                    className="flex text-white text-sm font-graphik"
                  >
                    <div className="flex">
                      <Icon.Info className="mr-2" style={{ marginTop: '0.15rem' }} />
                      Editing is disabled. You are currently previewing an older
                      version.
                    </div>
                    {this.activeStudentOwnsCode() && (
                      <div
                        onClick={() => {
                          if (
                            !this.state.restoreInProgress &&
                            this.state.isPreview
                          ) {
                            this.restoreFile();
                          }
                        }}
                        className="cursor-pointer font-medium ml-auto"
                      >
                        Restore Version
                      </div>
                    )}
                  </Panel>
                </div>
              )}
              <div
                className={classNames('pyturt-code-editor-ace', 'flex', 'flex-col', {
                  'new-horizons box-border': this.props.newHorizons,
                  relative: this.props.newHorizons && this.props.fullScreenMode,
                })}
              >
                {this.props.fullScreenMode && (
                  <div className="absolute right-3 top-3 z-10">
                    <Button
                      renderIconLeft={props => <Icon.FullScreen {...props} />}
                      onClick={this.props.toggleFullScreenMode}
                    >
                      <div className="text-sm">Exit Fullscreen</div>
                    </Button>
                  </div>
                )}
                <AceEditor
                  ref={this.reactAceRef}
                  mode="python"
                  theme={this.props.newHorizons ? 'dracula' : 'monokai'}
                  fontSize={14}
                  showPrintMargin={false}
                  readOnly={!this.activeStudentOwnsCode() || !this.state.canEdit}
                  tabSize={2}
                  onChange={this.aceOnChange}
                  focus={this.props.isActive}
                  width="100%"
                  height="100%"
                  editorProps={{ $blockScrolling: true }}
                  wrapEnabled
                  value={
                    this.props.isCodeHidden
                      ? 'The owner has hidden their code. You can still click Run to see their project.'
                      : this.state.code
                  }
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: false,
                  }}
                />
                <JideCodeEditorFooter {...sidebarProps} />
              </div>
            </div>
          </JideEnvLayout.MainContent>
          <JideEnvLayout.SideContent withCanvas>
            <JideCodeOutputHeader
              showInstructions={canDisplayInstructions}
              setPane={tab => this.setState({ activePane: tab })}
              activePane={this.state.activePane}
              showCanvas
              hasError={this.state.hasError}
            />
            <div className="pyturt-code-output-main">
              <div className="pyturt-code-output-elements-wrapper">
                <div
                  className={`pyturt-code-output-element pyturt-turtle-canvas ${
                    this.state.activePane === 'canvas'
                      ? 'pyturt-active '
                      : 'pyturt-inactive '
                  }${this.state.codeRunning ? 'pyturt-running' : ''}`}
                  id={`pyturt-turtle-canvas-${this.props.tab.tabId}`}
                  ref="turtleCanvas"
                />
                <pre
                  className={`pyturt-code-output-element pyturt-console-output ${
                    this.state.activePane === 'console'
                      ? 'pyturt-active'
                      : 'pyturt-inactive'
                  }`}
                  id={`pyturt-console-output-${this.props.tab.tabId}`}
                  ref={`consoleOutput_${this.props.tab.tabId}`}
                />
                {canDisplayInstructions ? (
                  <div
                    className={classNames(
                      'inset-0',
                      'absolute',
                      'm-0',
                      'overflow-auto',
                      'box-border',
                      'bg-white',
                      this.state.activePane === 'instructions' ? 'z-10' : 'z-0',
                    )}
                    ref="instructionsPane"
                  >
                    <ActivityInstructions
                      instructions={
                        this.state.project &&
                        this.state.project.properties.instructions
                      }
                      difficultyLevel={
                        this.state.project &&
                        this.state.project.properties.isSupplemental &&
                        this.state.project.properties.difficulty &&
                        difficultyDisplayNames[
                          this.state.project.properties.difficulty
                        ]
                      }
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {this.activeStudentOwnsCode() && (
              <Footer className="justify-end">
                <JideWidgets
                  environmentType="pythonTurtle"
                  showVideosWidget={this.props.showVideosWidget}
                  activeNav={this.props.activeNav}
                  idLookup={this.props.idLookup}
                  learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
                  tab={this.props.tab}
                  jideUser={this.props.jideUser}
                  isCustomProject={this.state.project?.properties?.isCustomProject}
                  isPlayground={!!this.props.playgroundProject}
                  recordingMode={this.props.recordingMode}
                  setRecordingMode={this.props.setRecordingMode}
                  newHorizons
                  schedulingFormat={this.props.schedulingFormat}
                />
              </Footer>
            )}
          </JideEnvLayout.SideContent>
        </JideEnvLayout>
      );
    }

    return (
      <div
        ref={this.pyturtRef}
        className={
          `pyturt` +
          ` pyturt-${this.state.orientation}${
            this.props.fullScreenMode ? ' pyturt-fullscreen' : ''
          }`
        }
      >
        <div className="pyturt-left-pane">
          <JideSidebar {...sidebarProps} />
          <div className="pyturt-code-editor">
            <div className="pyturt-code-editor-bar">
              <div className="pyturt-code-editor-bar-left" />
              <div className="pyturt-code-editor-bar-right">
                {this.state.activeSidebarTab !== JideSidebar.TAB_VERSIONS ? (
                  <>
                    {this.activeStudentOwnsCode() ? (
                      ''
                    ) : (
                      <button
                        className="pyturt-code-controls pyturt-code-lock"
                        title="Read-Only"
                      >
                        <div>
                          <FontAwesomeIcon icon={['fas', 'lock']} />
                        </div>
                      </button>
                    )}

                    {this.props.jideUser.type !== 'public' ? (
                      <button
                        className={`pyturt-code-controls pyturt-code-sync${
                          this.state.isSyncing ? ' pyturt-is-syncing' : ''
                        }${this.state.saveError ? ' pyturt-save-error' : ''}`}
                        onClick={this.syncCodeDefault}
                        title={
                          this.state.saveError
                            ? 'Error: We ran into an issue and your code may not have been saved! ' +
                              'Try again in a little while or copy code into text editor and refresh the page.'
                            : `Last Saved: ${
                                this.state.clientLastSync
                                  ? moment
                                      .duration(
                                        moment
                                          .utc()
                                          .diff(
                                            moment.utc(this.state.clientLastSync),
                                          ),
                                      )
                                      .humanize()
                                  : '--'
                              } ago`
                        }
                      >
                        <div>
                          {this.state.isSyncing ? (
                            <FontAwesomeIcon icon={['fas', 'sync']} spin />
                          ) : this.state.saveError ? (
                            <FontAwesomeIcon icon={['fas', 'exclamation']} />
                          ) : this.state.hasNewEdits ? (
                            <FontAwesomeIcon icon={['fas', 'save']} />
                          ) : (
                            <FontAwesomeIcon icon={['fas', 'check']} />
                          )}
                        </div>
                      </button>
                    ) : null}

                    <button
                      className={`pyturt-code-controls pyturt-code-run ${
                        this.state.codeRunning || this.state.isSyncing
                          ? 'pyturt-inactive'
                          : 'pyturt-active'
                      }`}
                      onClick={this.runCode}
                    >
                      <div>
                        Run&nbsp;
                        <FontAwesomeIcon
                          icon={['fas', 'play']}
                          transform="shrink-4"
                        />
                      </div>
                    </button>
                    <button
                      className={`pyturt-code-controls pyturt-code-stop ${
                        this.state.codeRunning ? 'pyturt-active' : 'pyturt-inactive'
                      }${
                        this.state.hardInterrupt && this.state.codeRunning
                          ? ' pyturt-code-stop-in-progress'
                          : ''
                      }`}
                      onClick={this.stopCode}
                    >
                      <div>
                        Stop&nbsp;
                        {this.state.hardInterrupt && this.state.codeRunning ? (
                          <FontAwesomeIcon
                            icon={['fas', 'stop']}
                            transform="shrink-4"
                            spin
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={['fas', 'stop']}
                            transform="shrink-4"
                          />
                        )}
                      </div>
                    </button>
                  </>
                ) : this.activeStudentOwnsCode() ? (
                  <button
                    className={`jce-controls jce-restore ${
                      this.state.restoreInProgress || !this.state.isPreview
                        ? 'jce-inactive'
                        : 'jce-active'
                    }`}
                    onClick={() => {
                      if (!this.state.restoreInProgress && this.state.isPreview) {
                        this.restoreFile();
                      }
                    }}
                  >
                    <div>
                      Restore &nbsp;
                      <FontAwesomeIcon icon={['fas', 'history']} />
                    </div>
                  </button>
                ) : null}
              </div>
            </div>
            {this.state.isPreview ? (
              <AlertBanner
                style={{ width: '100%', marginBottom: '1em' }}
                className="alert-banner-warning"
                content={
                  <div>
                    <strong>Editing is disabled.</strong>
                    <br />
                    You are currently previewing an older version.
                  </div>
                }
              />
            ) : null}
            <div className="pyturt-code-editor-ace">
              <AceEditor
                ref={this.reactAceRef}
                mode="python"
                theme="monokai"
                fontSize={14}
                showPrintMargin={false}
                readOnly={!this.activeStudentOwnsCode() || !this.state.canEdit}
                tabSize={2}
                onChange={this.aceOnChange}
                focus={this.props.isActive}
                width="100%"
                height="100%"
                editorProps={{ $blockScrolling: true }}
                wrapEnabled
                value={this.state.code}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: false,
                }}
              />
            </div>
          </div>
        </div>
        <div className="pyturt-right-pane">
          <div className="pyturt-code-output">
            <div className="pyturt-code-output-bar">
              <div className="pyturt-code-output-bar-left">
                <div
                  className={`pyturt-code-output-tab pyturt-canvas-tab ${
                    this.state.activePane === 'canvas'
                      ? 'pyturt-active'
                      : 'pyturt-inactive'
                  }`}
                  onClick={() => this.setState({ activePane: 'canvas' })}
                >
                  <div className="pyturt-iconBox">
                    <FontAwesomeIcon icon={['fas', 'paint-brush']} />
                  </div>
                  &nbsp;&nbsp;Canvas
                </div>
                <div
                  className={`pyturt-code-output-tab pyturt-console-tab ${
                    this.state.activePane === 'console'
                      ? 'pyturt-active'
                      : 'pyturt-inactive'
                  }`}
                  onClick={() => this.setState({ activePane: 'console' })}
                >
                  <div
                    className={`pyturt-iconBox ${
                      this.state.hasError ? 'pyturt-errorBox' : ''
                    }`}
                  >
                    {this.state.hasError ? (
                      <FontAwesomeIcon icon={['fas', 'exclamation']} />
                    ) : (
                      <FontAwesomeIcon icon={['fas', 'terminal']} />
                    )}
                  </div>
                  &nbsp;&nbsp;Console
                </div>
              </div>
              <div className="pyturt-code-output-bar-right">
                {canDisplayInstructions ? (
                  <div
                    className={`pyturt-code-output-tab pyturt-instructions-tab ${
                      this.state.activePane === 'instructions'
                        ? 'pyturt-active'
                        : 'pyturt-inactive'
                    }`}
                    onClick={() => this.setState({ activePane: 'instructions' })}
                  >
                    <div className="pyturt-iconBox">
                      <FontAwesomeIcon icon={['fas', 'star']} />
                    </div>
                    &nbsp;&nbsp;Instructions
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="pyturt-code-output-main">
              <div className="pyturt-code-output-elements-wrapper">
                <div
                  className={`pyturt-code-output-element pyturt-turtle-canvas ${
                    this.state.activePane === 'canvas'
                      ? 'pyturt-active '
                      : 'pyturt-inactive '
                  }${this.state.codeRunning ? 'pyturt-running' : ''}`}
                  id={`pyturt-turtle-canvas-${this.props.tab.tabId}`}
                  ref="turtleCanvas"
                />
                <pre
                  className={`pyturt-code-output-element pyturt-console-output ${
                    this.state.activePane === 'console'
                      ? 'pyturt-active'
                      : 'pyturt-inactive'
                  }`}
                  id={`pyturt-console-output-${this.props.tab.tabId}`}
                  ref={`consoleOutput_${this.props.tab.tabId}`}
                />
                {canDisplayInstructions ? (
                  <div
                    className={`pyturt-code-output-element pyturt-instructions-pane ${
                      this.state.activePane === 'instructions'
                        ? 'pyturt-active'
                        : 'pyturt-inactive'
                    }`}
                    ref="instructionsPane"
                  >
                    {this.state.project &&
                      this.state.project.properties.isSupplemental &&
                      this.state.project.properties.difficulty && (
                        <div className="header-tag-v2 missed">
                          {
                            difficultyDisplayNames[
                              this.state.project.properties.difficulty
                            ]
                          }
                        </div>
                      )}
                    <div
                      className={`pyturt-instructions-pane-content ${
                        this.state.project &&
                        this.state.project.properties.isSupplemental
                          ? `pyturt-supplemental ${this.state.project.properties.difficulty}`
                          : ''
                      }`}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html:
                          this.state.project &&
                          this.state.project.properties.instructions,
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <JideWidgets
          environmentType="pythonTurtle"
          showVideosWidget={this.props.showVideosWidget}
          activeNav={this.props.activeNav}
          idLookup={this.props.idLookup}
          learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
          tab={this.props.tab}
          jideUser={this.props.jideUser}
          isCustomProject={this.state.project?.properties?.isCustomProject}
          isPlayground={!!this.props.playgroundProject}
          recordingMode={this.props.recordingMode}
          setRecordingMode={this.props.setRecordingMode}
        />
      </div>
    );
  }
}

PythonTurtle.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  tab: PropTypes.shape({}).isRequired,
  isActive: PropTypes.bool,
  fullScreenMode: PropTypes.bool,
  toggleFullScreenMode: PropTypes.func.isRequired,
  idLookup: PropTypes.shape({}).isRequired,
  recordingMode: PropTypes.bool,
  setRecordingMode: PropTypes.func,
  encryptedStudentAndProject: PropTypes.string,
  isCodeHidden: PropTypes.bool,
};
export default PythonTurtle;
