import JuniSpinner from 'components/JuniSpinner';
import React, { FC, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loader } from '@googlemaps/js-api-loader';
import * as R from 'ramda';

import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';

import { Helmet, HelmetTags } from 'react-helmet';
import { INTERCOM_CONSTANTS } from 'constants/intercom';
import { FULLSTORY_CONSTANTS } from 'constants/fullstory';
import { useExperiments } from '@junilearning/juni-experiments';
import { EXPERIMENT_NAMES } from 'constants/experiments';
import { COURSE_FORMAT } from 'constants/signup_sessions';
import Onboarding from './Onboarding';
import CheckoutFlow from './CheckoutFlow';
import ExistingUserCheckoutFlow from './ExistingUserCheckoutFlow';

import SignupSessionContext from './hooks/useSignupContext/SignupSessionContext';
import NavRouterContext from './hooks/useNavRouter/NavStateContext';
import useSignupSession from './hooks/useSignupSession';

import { SessionFlags, SignupData, SignupSessionProps } from './types';
import ReferralsPage from './SignupLandingPages';
import GuestAccountsPage from './SignupLandingPages/pages/GuestAccountsPage';
import defaultRouter from './navigation/machines/defaultNavigation';
import { findStudentById, shouldSkipCourseFrequencyPage } from './lib';
import { checkIsBootcampSignup } from './lib/checkIsBootcampSignup';

declare global {
  interface Window {
    Intercom: any;
  }
}

const SignupSession: FC<SignupSessionProps> = ({
  match,
  history,
  location,
  checkAuth,
}) => {
  const fullStory = typeof FS === 'undefined' ? undefined : FS;
  const intercom =
    typeof window.Intercom === 'undefined' ? undefined : window.Intercom;

  const { params } = match;
  const [mapsIsLoading, setMapsIsLoading] = useState(true);
  const [scriptsLoaded, setScriptsLoaded] = useState<Record<string, boolean>>({});
  const experiments = useExperiments([EXPERIMENT_NAMES.DCF_UI_COPY]);

  // if changing the DCF navigation, refer to https://www.notion.so/junilearning/Guide-to-DCF-Navigation-fe2c5809e19f4dc48a8ef1736f712c4a first!
  const navRouter = defaultRouter;

  useEffect(() => {
    const loadMaps = async () => {
      try {
        const loader = new Loader({
          apiKey: 'AIzaSyBDPmVDRLYXJuQIzWjP_QDH1pV41h7FEig',
          version: 'weekly',
          libraries: ['places'],
        });
        await loader.load();
      } catch (e) {
        console.error('error loading Google maps', e);
      }
      setMapsIsLoading(false);
    };
    loadMaps();
  }, []);

  const { signupData, setSignupData, sessionIsLoading } = useSignupSession(
    params.signupSessionId,
  );

  useEffect(() => {
    if (signupData._id && fullStory) {
      const anonId = signupData.anonymousId;
      if (anonId) {
        fullStory.identify(anonId);
      }
      fullStory.setUserVars({
        signupSessionId: signupData._id,
      });
    }
  }, [signupData._id, fullStory, signupData.anonymousId]);

  useEffect(() => {
    if (
      match.path.includes(':signupSessionId') &&
      signupData._id &&
      signupData._id !== params.signupSessionId
    ) {
      history.replace(
        `${match.path.replace(':signupSessionId?', '')}${signupData._id}${
          location.search
        }`,
      );
    }
  }, [
    history,
    location.search,
    match.path,
    params.signupSessionId,
    signupData,
    signupData._id,
  ]);

  useEffect(() => {
    if (scriptsLoaded.fullstory) {
      fullStory?.restart();
    }
    return () => fullStory?.shutdown();
  }, [scriptsLoaded.fullstory, fullStory]);

  useEffect(() => {
    if (scriptsLoaded.intercom) {
      intercom?.('boot', {
        app_id: INTERCOM_CONSTANTS.APP_ID,
      });
    }
  }, [scriptsLoaded.intercom, intercom]);

  const setSignupSession = async (newData: Partial<SignupData>) => {
    if (!signupData._id) {
      console.error('error in submitStep: signupData._id not available yet');
      return;
    }
    const signupService = makeSignupSessionsService(signupData._id);
    const data: Partial<SignupData> = {
      ...signupData,
      ...newData,
      lastSeenUrl: window.location.href,
    };

    setSignupData(data);

    if (Object.keys(data).length > 0) {
      try {
        return signupService.updateSession(
          // omit readonly keys
          R.omit(['_id', 'closeLeadId'], data),
        );
      } catch (e) {
        console.error('Error updating signup session', e);
      }
    }
  };

  if (sessionIsLoading || mapsIsLoading || !navRouter)
    return <JuniSpinner size={80} />;

  /*
    Hard-coding the activeStudent to the 0th index. As of this release,
    the dcf will only support signing up one student at a time, but I would 
    like to keep this flexible in case we want to support multiple students in the future.
  */
  const activeStudentId = signupData.students?.[0]?._id;
  const activeStudent = findStudentById(activeStudentId, signupData);

  const flags: SessionFlags = {
    isBootcampSignup: checkIsBootcampSignup(activeStudent?.bundle),
    isPrivateOneOnOne: !checkIsBootcampSignup(activeStudent?.bundle), // NOTE: for now, 1:1 is just the default as long as isBootcampSignup is false
    shouldSkipCourseFrequency: shouldSkipCourseFrequencyPage(
      activeStudent?.bundle?.selections ?? {},
      signupData?.campaign,
    ),
    multiSubject: !shouldSkipCourseFrequencyPage(
      activeStudent?.bundle?.selections ?? {},
      signupData?.campaign,
    ),
    isOnDemandSignup: activeStudent?.bundle?.bundleName === COURSE_FORMAT.onDemand,
    isInDcfExperimentTreatmentGroup:
      experiments[EXPERIMENT_NAMES.DCF_UI_COPY] === 'treatment',
  };

  const handleScriptInject = (scriptTags: HelmetTags['scriptTags']) => {
    (scriptTags || []).forEach(scriptTag => {
      if (scriptTag.id) {
        setScriptsLoaded(prevState => ({ ...prevState, [scriptTag.id]: true }));
      }
    });
  };

  return (
    // todo this should go away when the whole app is converted to graphik
    // need to wait for script to load before accessing full story and intercom
    // https://github.com/nfl/react-helmet/issues/146#issuecomment-271552211
    <div className="font-graphik">
      <Helmet
        onChangeClientState={(_newState, { scriptTags }) =>
          handleScriptInject(scriptTags)
        }
      >
        Full story
        <script id="fullstory" type="text/javascript">
          {`
              window['_fs_debug'] = false;
              window['_fs_host'] = 'fullstory.com';
              window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
              window['_fs_org'] = '${FULLSTORY_CONSTANTS.ORG}';
              window['_fs_namespace'] = 'FS';
              (function(m,n,e,t,l,o,g,y){
                  if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                  g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                  o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                  y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                  g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                  g.anonymize=function(){g.identify(!!0)};
                  g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                  g.log = function(a,b){g("log",[a,b])};
                  g.consent=function(a){g("consent",!arguments.length||a)};
                  g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                  g.clearUserCookie=function(){};
                  g.setVars=function(n, p){g('setVars',[n,p]);};
                  g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                  if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                  g._v="1.3.0";
              })(window,document,window['_fs_namespace'],'script','user');
            `}
        </script>
        Intercom integration
        <script id="intercom" type="text/javascript">
          {`
        var APP_ID = "${INTERCOM_CONSTANTS.APP_ID}";
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      `}
        </script>
      </Helmet>
      <SignupSessionContext.Provider
        value={{
          activeStudentId,
          signupData,
          setSignupSession,
          fullStory,
          flags,
        }}
      >
        <NavRouterContext.Provider
          value={{
            router: navRouter,
            multiSubjectSignup: !flags.shouldSkipCourseFrequency,
          }}
        >
          <Switch>
            <Route
              path="/signup2"
              render={props => <ExistingUserCheckoutFlow flags={flags} {...props} />}
            />
            <Route
              path="/referral/:referralCode?"
              render={() => <ReferralsPage />}
            />
            <Route
              path="/signup/guest/:invitationLookupId"
              render={() => <GuestAccountsPage />}
            />
            <Route
              path="/signup/:signupSessionId"
              render={props => <CheckoutFlow {...props} />}
            />
            <Route
              path="/onboarding/:signupSessionId"
              render={props => <Onboarding {...props} checkAuth={checkAuth} />}
            />
          </Switch>
        </NavRouterContext.Provider>
      </SignupSessionContext.Provider>
    </div>
  );
};

export default SignupSession;
