import React from 'react';
import { ClubSelectorOption } from 'components/clubs';
import { JuniClubType } from 'generated/graphql';
import { Link } from 'react-router-dom';
import { getClubLink, sortClubs } from 'app/clubs/helpers';
import { UnreadMsgLookupType } from 'app/clubs/MyClubsTypes';
import classNames from 'classnames';
import _ from 'lodash';

interface ClubSidebarProps {
  studentId?: string;
  currentClubId: string;
  juniClubs: JuniClubType[];
  unreadMessages: UnreadMsgLookupType;
}

const ClubSidebar: React.FC<ClubSidebarProps> = ({
  studentId,
  juniClubs,
  currentClubId,
  unreadMessages,
}) => {
  const currentColor = juniClubs.find(club => club._id === currentClubId)
    ?.colorTheme;
  return (
    <div
      className={classNames(
        `bg-${currentColor}-dark`,
        'flex',
        'flex-col',
        'flex-shrink-0',
        'p-4',
        'pr-5',
        'overflow-y-auto',
      )}
    >
      {sortClubs(juniClubs).map(c => {
        const notifications =
          c._id in unreadMessages ? _.sum(_.values(unreadMessages[c._id])) : 0;
        return (
          <Link key={c._id} to={getClubLink({ studentId, juniClubId: c._id })}>
            <ClubSelectorOption
              active={c._id === currentClubId}
              clubName={c.displayName}
              /* grab thumbnail by replacing s3 bucket URL '_full' to '_thumbnail' */
              clubThumbnailUrl={c.coverPhoto?.replace('_full', '_thumbnail') || ''}
              notifications={notifications}
              colorTheme={currentColor}
            />
          </Link>
        );
      })}
    </div>
  );
};
export default ClubSidebar;
