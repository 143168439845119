import { useState } from 'react';
import { format, parseISO, addDays, isBefore } from 'date-fns';
import classNames from 'classnames';
import _ from 'lodash';

import { Message, NewButton, Icon, Select, Input } from 'core-components';
import ModalWindow, {
  ModalButtonsFooter,
} from 'core-components/NewModalWindow/NewModalWindow';

import { US_TIMEZONES, FOREIGN_TIMEZONES } from 'constants/timezones';
import { DAYS_OF_THE_WEEK } from 'constants/dates';
import { CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME } from 'constants/subscription_plans';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';

import { START_HOUR, END_HOUR } from 'utils/enrollment';
import LearnerPortalModal from '../LearnerPortalModal';

export const PRIORITY_LEVELS = {
  0: 'First',
  1: 'Second',
  2: 'Third',
};

const NUMBER_OF_AVAILS = 3;

const TableHeaderDate = ({ day }) => (
  <div className="flex flex-col items-center flex-1 flex-shrink-0 text-center">
    <div className="w-20 mr-2.5">
      <div className={classNames('font-graphik text-j-dark-600 text-sm')}>{day}</div>
    </div>
  </div>
);

const TimeButton = ({ selected, disabled, onClick, children, value }) => (
  <button
    className={classNames(
      disabled
        ? 'bg-j-gray-100 text-j-dark-300 cursor-auto'
        : selected
        ? 'bg-j-purple-200 font-medium text-j-dark-600 border border-solid border-j-purple-400'
        : 'bg-j-gray-200 text-j-dark-600',
      'rounded-md',
      'font-graphik text-xs tracking-normal',
      'w-20',
      'hover:shadow-none',
      'ignore-juni-globals',
      'py-1 px-2',
    )}
    onClick={disabled ? _.noop : onClick}
    value={value}
  >
    {children}
  </button>
);

const DateTimeSelectorModal = ({
  courses,
  setIsAvailabilityModalOpen,
  formValues,
  updateFormValue,
  activeSubject,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const courseNames = courses
    .filter(course =>
      formValues.metadataNew[activeSubject].courses.includes(course.name),
    )
    .map(course => course.displayName)
    .join(', ');

  const availabilities = _.map(_.range(START_HOUR, END_HOUR), hour => {
    const items = _.map(_.range(0, DAYS_OF_THE_WEEK.length), day => {
      // arbitrarily selected a month/year in which the 1st is a Monday
      const currentTime = new Date('2021', '10', day + 1, hour, 0, 0);
      const selected = selectedDates.includes(currentTime.toISOString());
      const disabled = !selected && selectedDates.length === NUMBER_OF_AVAILS;
      return (
        <div
          key={format(currentTime, 'yyyy-mm-dd hh:mm')}
          className="flex flex-1 mr-2.5 justify-center"
        >
          <TimeButton
            selected={selected}
            disabled={disabled}
            value={currentTime.toISOString()}
            onClick={event => {
              const date = event.target.value;
              if (selectedDates.includes(date)) {
                setSelectedDates(
                  selectedDates.filter(selectedDate => selectedDate !== date),
                );
              } else {
                setSelectedDates([...selectedDates, date]);
              }
            }}
          >
            {format(currentTime, 'h:mm a')}
          </TimeButton>
        </div>
      );
    });
    return <div className="flex mb-2">{items}</div>;
  });

  return (
    <ModalWindow
      isOpen
      closeModal={() => {
        setIsAvailabilityModalOpen(false);
      }}
      title="Schedule Preferences"
      description={courseNames}
      renderFooter={() => (
        <ModalButtonsFooter
          primary={
            <NewButton
              onClick={() => {
                updateFormValue(
                  {
                    ...formValues.metadataNew,
                    [activeSubject]: {
                      ...formValues.metadataNew[activeSubject],
                      availabilities: selectedDates,
                    },
                  },
                  'metadataNew',
                );
                setIsAvailabilityModalOpen(false);
              }}
              disabled={selectedDates.length < NUMBER_OF_AVAILS}
            >
              <div className="font-medium">Confirm</div>
            </NewButton>
          }
          secondary={
            <NewButton
              variant="secondary"
              onClick={() => {
                setIsAvailabilityModalOpen(false);
              }}
            >
              <div className="font-medium">Cancel</div>
            </NewButton>
          }
        />
      )}
    >
      <div className="flex flex-col w-full">
        <div className={classNames('flex flex-col w-full overflow-x-auto')}>
          <Message
            className="mb-4"
            status="info"
            description={`Select 3 times on a weekly basis (${selectedDates.length}/3).`}
          ></Message>
          <div className={classNames('flex mb-3')}>
            {DAYS_OF_THE_WEEK.map(day => (
              <TableHeaderDate key={day} day={day} />
            ))}
          </div>
          <div className="flex flex-col">{availabilities}</div>
        </div>
      </div>
    </ModalWindow>
  );
};

const ScheduleSelectorModal = ({
  coursesQuery,
  formValues,
  formState,
  updateFormState,
  updateFormValue,
}) => {
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [activeSubject, setActiveSubject] = useState(undefined);

  const courses = coursesQuery?.data?.getCourses;

  const {
    currentStudent,
    metadataNew,
    subscription,
    startingDate,
    timezone,
  } = formValues;

  const studentCurrentCourses = currentStudent.hasMultipleTracks
    ? currentStudent.tracks
    : [currentStudent.track];

  // e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
  const courseMetadataObj = _.pickBy(
    subscription ? subscription.metadata : {},
    (value, key) =>
      value && Object.keys(CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME).includes(key),
  );

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const changedSubscriptions = _.filter(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );

  const nextButtonEnabled =
    startingDate &&
    !isBefore(new Date(startingDate), addDays(new Date(), 3)) &&
    timezone &&
    _.every(
      changedSubscriptions,
      ([, metadata]) => metadata.availabilities !== undefined,
    );

  return (
    <>
      {isAvailabilityModalOpen ? (
        <DateTimeSelectorModal
          setIsAvailabilityModalOpen={setIsAvailabilityModalOpen}
          formValues={formValues}
          updateFormValue={updateFormValue}
          activeSubject={activeSubject}
          courses={courses}
        />
      ) : (
        <LearnerPortalModal
          title={formState.updateFlow}
          formState={formState}
          updateFormState={updateFormState}
          renderPrimaryButton={
            <NewButton
              disabled={!nextButtonEnabled}
              onClick={() => {
                const modalName = 'payment_check';
                updateFormState(modalName, 'modal');
              }}
            >
              <div className="font-medium">Next</div>
            </NewButton>
          }
        >
          <div className="flex flex-col w-full gap-2">
            <div className="text-j-dark-600 font-graphik text-sm font-medium">
              Timezone
            </div>
            <div className="flex flex-col w-full mb-2">
              <Select
                dropdownPosition="bottom-left"
                placeholder="Select a timezone"
                options={US_TIMEZONES.concat(FOREIGN_TIMEZONES).map(tz => ({
                  value: tz.value,
                  label: tz.displayName,
                }))}
                selected={timezone}
                onChange={option => {
                  updateFormValue(option, 'timezone');
                }}
                size="small"
                fullWidth
              />
            </div>
            <div className="text-j-dark-600 text-sm font-medium">
              Schedule Preferences
            </div>
            <div className="flex flex-col">
              {_.map(changedSubscriptions, ([key, newState]) => (
                <div className="flex flex-col" key={key}>
                  <div className="flex flex-row justify-between mb-1 text-sm text-j-dark-600">
                    <div className="flex items-center">
                      {courses
                        .filter(course => newState.courses.includes(course.name))
                        .map(course => course.displayName)
                        .join(', ')}
                    </div>
                    <div className="flex">
                      {newState.availabilities ? (
                        <NewButton
                          size="small"
                          className="text-j-dark-600 p-0 hover:bg-opacity-20 font-light bg-opacity-0"
                          onClick={() => {
                            setActiveSubject(key);
                            setIsAvailabilityModalOpen(true);
                          }}
                        >
                          Reselect
                        </NewButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="flex w-full flex-row">
                    {newState.availabilities ? (
                      <div className="flex flex-row w-full mb-3 text-j-dark-600 bg-j-gray-200 p-2 justify-between items-center border rounded-md">
                        {Object.entries(PRIORITY_LEVELS).map(([priority, label]) => (
                          <div className="flex flex-col p-1">
                            <div className="flex text-sm pb-1 pr-1">{`${label} option`}</div>
                            <div className="flex bg-white border rounded-md p-3">
                              {format(
                                parseISO(newState.availabilities[priority]),
                                'EEE, h:mm a',
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="pt-1 mb-2 w-full">
                        <NewButton
                          onClick={() => {
                            setActiveSubject(key);
                            setIsAvailabilityModalOpen(true);
                          }}
                          size="medium"
                          className="p-2 w-full hover:bg-opacity-0 bg-opacity-0 text-j-dark-200 border border-solid hover:border-j-dark-300 border-j-dark-200"
                        >
                          <div className="flex justify-between w-full">
                            <div className="flex font-normal">
                              Add schedule options
                            </div>
                            <div className="flex">
                              <Icon.Calendar2 />
                            </div>
                          </div>
                        </NewButton>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="text-j-dark-600 text-sm font-medium">Starting Date</div>
            <Input
              size="small"
              type="date"
              value={startingDate}
              name="startingDate"
              fullWidth
              onChange={e => updateFormValue(e.target.value, 'startingDate')}
              min={format(addDays(new Date(), 4), 'yyyy-MM-dd')}
              valid={
                !startingDate
                  ? undefined
                  : isBefore(new Date(startingDate), addDays(new Date(), 3))
                  ? false
                  : undefined
              }
              message={
                isBefore(new Date(startingDate), addDays(new Date(), 3))
                  ? 'Please select a date 4 days from today.'
                  : undefined
              }
            />
          </div>
        </LearnerPortalModal>
      )}
    </>
  );
};

export default ScheduleSelectorModal;
