import React, { FC } from 'react';
import ReactModal from 'react-modal';

import useScrollLock from 'hooks/scrollLock';

import {
  ScrollableContent,
  ModalTitle,
  ModalLabel,
  ModalDescription,
  ModalCardBody,
  CloseButton,
  CloseButtonIcon,
  Banner,
  FlexWrapper,
  StickyColorBar,
  StickyWrapperOverBanner,
  StickyWrapperWithTitle,
  REACT_MODAL_CONTENT_STYLES,
  REACT_MODAL_OVERLAY_STYLES,
} from './styles';

ReactModal.setAppElement('#root');

export interface ModalWindowProps {
  /**
   * Function that closes the modal. Not set to be manipulable in Storybook.
   */
  closeModal: () => void;
  /**
   * Variable that determines if the modal is open or not. Not set to be manipulable in Storybook.
   */
  isOpen: boolean;
  /**
   * Title for the modal - required prop.
   */
  title: string;
  /**
   * Description for the modal - goes under the title.
   */
  description?: string;
  /**
   * CSS `max-width` property for modal.
   */
  maxWidth?: string;
  /**
   * CSS property that sets the height of the modal's banner, if it exists.
   */
  bannerHeight?: string;
  /**
   * Source for the modal's banner image. Not set to be manipulable in Storybook.
   */
  bannerImgSrc?: string;
  /**
   * Text for the optional label that goes in the right of the modal, below the banner.
   */
  labelText?: string;
  /**
   * Solid color displayed under the modal's banner. Defaults to `juni-blue-500`.
   */
  underBannerColor?: string;
  /**
   * Determines whether the body of the modal is `white`, rather than `eggshell-blue`.
   */
  whiteBackground?: boolean;
}

/**
 * Basic modal component, specifically tailored for use in Q1 2021 Learner Team projects (although this
 * should be usable throughout the app). The layout is set up with the assumption that the component will wrap
 * child elements; make sure that's the case when using this!
 */

const ModalWindow: FC<ModalWindowProps> = ({
  children,
  closeModal,
  isOpen,
  title,
  description,
  maxWidth,
  bannerHeight,
  bannerImgSrc,
  labelText,
  underBannerColor,
  whiteBackground,
}) => {
  useScrollLock(isOpen);
  const hasBanner = !!bannerImgSrc;
  const StickyWrapper = hasBanner ? StickyWrapperOverBanner : StickyWrapperWithTitle;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...REACT_MODAL_CONTENT_STYLES,
          maxWidth: maxWidth || REACT_MODAL_CONTENT_STYLES.maxWidth,
        },
        overlay: REACT_MODAL_OVERLAY_STYLES,
      }}
    >
      <ScrollableContent>
        {hasBanner && (
          <>
            <Banner src={bannerImgSrc} bannerHeight={bannerHeight} />
            <StickyColorBar color={underBannerColor} />
          </>
        )}
        <StickyWrapper bannerHeight={bannerHeight}>
          <CloseButton aria-label="Close" onClick={closeModal}>
            <CloseButtonIcon />
          </CloseButton>
          {!hasBanner && <ModalTitle>{title}</ModalTitle>}
        </StickyWrapper>
        {(hasBanner || labelText) && (
          <FlexWrapper hasBanner={hasBanner} bannerHeight={bannerHeight}>
            {hasBanner && <ModalTitle>{title}</ModalTitle>}
            {labelText && <ModalLabel>{labelText}</ModalLabel>}
          </FlexWrapper>
        )}
        {description && <ModalDescription>{description}</ModalDescription>}
        <ModalCardBody
          backgroundColor={whiteBackground ? 'white' : 'eggshell-blue'}
          borderColor="juni-blue-100"
          hideOverflow={false}
          noRounding
          padding="4"
        >
          {children}
        </ModalCardBody>
      </ScrollableContent>
    </ReactModal>
  );
};

export default ModalWindow;
