import React, { FC } from 'react';
import styled from 'styled-components/macro';

import {
  useGetFeaturedMyFeedProjectsQuery,
  StudentProject,
} from 'generated/graphql';

import JuniSpinner from 'components/JuniSpinner';
import { PortalComponentLayout } from 'components/PortalLayout';
import { Card, Message } from 'components/ui';

import { mongoIDToDateStr } from 'utils/timeUtils';
import FeaturedProject from './FeaturedProject';
import JuniverseCard from './JuniverseCard';

const ProjectCardContainer = styled.div`
  > * {
    margin-bottom: 20px !important;
  }

  p {
    line-height: 1.8em;
  }

  p + p {
    margin-top: -10px !important;
  }
`;

const MyFeedContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 920px) {
    & {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 600px) {
    & > * {
      max-width: 90vw;
      margin-left: 0px;
    }
  }
`;

const MyFeedLeftColumn = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;

  @media only screen and (max-width: 920px) {
    flex: 1;
  }
`;

const MyFeedRightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1.75rem;
  padding-top: 115px;

  @media only screen and (max-width: 1389px) {
    padding-top: 140px;
  }

  @media only screen and (max-width: 1092px) {
    padding-top: 168px;
  }

  @media only screen and (max-width: 920px) {
    padding-top: 0px;
    margin-left: 0px;
    margin-top: 5px;
  }
`;

const MyFeed: FC<{
  username?: string;
}> = ({ username }) => {
  const { data, loading, error } = useGetFeaturedMyFeedProjectsQuery();

  const featuredProjects = data?.featuredStudentProjects?.items;

  /* Sort our project using IDs, which actually encode the date when the project was featured */
  const featuredProjectsToDisplay = featuredProjects
    ?.map(project => project)
    .sort((p1, p2) => {
      const date1 = Date.parse(mongoIDToDateStr(p1?._id));
      const date2 = Date.parse(mongoIDToDateStr(p2?._id));
      return date2 - date1;
    });

  const urlBase =
    process.env.REACT_APP_API_URL === 'http://localhost:3001'
      ? 'http://localhost:3000'
      : 'https://app.junilearning.com';

  return loading ? (
    <JuniSpinner size={null} />
  ) : (
    <PortalComponentLayout title="My Feed">
      <MyFeedContainer>
        <MyFeedLeftColumn>
          <ProjectCardContainer>
            <p>
              Get inspiration from featured projects created by Juni students and
              instructors around the world!
            </p>
            <p>
              Want to see your project here? Publish a project in your Juni Portal
              that showcases your skills and creativity, and you may be featured too!
            </p>
            {error ? (
              <Card>
                <Card.Content>
                  <Message status="error">
                    Oops, something went wrong! Try refreshing the page.
                  </Message>
                </Card.Content>
              </Card>
            ) : featuredProjectsToDisplay?.length ? (
              featuredProjectsToDisplay?.map(
                (project: StudentProject | null) =>
                  project && <FeaturedProject project={project} key={project._id} />,
              )
            ) : (
              <Card>
                <Card.Content>
                  <Message status="warning">
                    Looks like there's nothing in your feed right now. Check back
                    later!
                  </Message>
                </Card.Content>
              </Card>
            )}
          </ProjectCardContainer>
        </MyFeedLeftColumn>
        <MyFeedRightColumn>
          {username ? (
            <JuniverseCard
              title="Your Juni Profile"
              description="Check out your Juni profile, which features all of the projects you’ve published!"
              link={`${urlBase}/juniverse/users/${username}`}
              buttonText="Visit My Profile"
            />
          ) : (
            <JuniverseCard
              title="Your Juni Profile"
              description="Publish one of your projects to create your Juni profile!"
              link={`${urlBase}/juniverse/users/${username}`}
            />
          )}
          <JuniverseCard
            title="The Juniverse"
            description="See even more projects created by Juni students and instructors!"
            link="https://junilearning.com/juniverse/"
            buttonText="Visit the Juniverse"
          />
        </MyFeedRightColumn>
      </MyFeedContainer>
    </PortalComponentLayout>
  );
};

export default MyFeed;
