import React, { useState, ChangeEvent } from 'react';
import Select from 'react-select';
import { Chevron } from 'components/Icons';
import ProjectCard from 'components/ProjectCard';
import { CardOrientations } from 'components/ProjectCard/models';
import styled from 'styled-components/macro';
import SectionLabel from 'components/ui/SectionLabel';
import clouds from 'images/clouds.svg';
import { validateCustomProjectName } from 'utils/validation';
import { ProjectTypes, IPlaygroundProps, IPlaygroundProjectForView } from './models';
import PlaygroundEmptyState from './PlaygroundEmptyState';

const dropdownStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#3C307D' : '#5a42e0',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    color: 'white',
    cursor: 'pointer',
    'font-size': '14px',
    ':active': {
      fontWeight: 'bold',
      background: '#3C307D',
    },
    ':hover': {
      fontWeight: 'bold',
      background: '#3C307D',
    },
    ':placeholder': {
      color: 'white',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'white',
    cursor: 'pointer',
    ':hover': {
      color: 'white',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
    cursor: 'pointer',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'white',
    'font-size': '14px',
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';

    return { ...provided, opacity, transition, color };
  },
};

const CreateNewInput = styled.input`
  background: #5a42e0;
  font-size: 1rem;
  border: 0.125rem solid #a08fff;
  border-radius: 0.375rem;
  color: white;
  width: calc(100% - 1rem);
  height: 1.5rem;
  padding: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  &:placeholder {
    color: white;
  }
`;

const QuickstartContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
    height: 15.625rem;
  }
`;

const CreateNewContainer = styled.div`
  color: #fff;
  border: 2px solid #8f80e5;
  box-shadow: 0px 8px 22px #7a62ff57;
  border-radius: 8px;
  flex-basis: 40%;
  display: flex;
  position: relative;
  flex-direction: column;
  background: #7a62ff;
  align-items: stretch;
  z-index: 1;

  & .clouds-container {
    position: absolute;
    width: inherit;
    opacity: 0.5;
    z-index: 1;
    flex-basis: 100%;

    img {
      width: 100%;
      max-height: 15.625rem;
    }
  }

  & .inner {
    padding: 1.25rem 1.25rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 2;
    .new-project-content {
      margin: 0 0 1.5rem;
      .new-project-subheading {
        opacity: 0.75;
        margin: 0.5rem 0 0 2px;
      }
    }
  }

  & .new-project-heading {
    margin: 0;
    font-size: 1.75rem;
    letter-spacing: -0.5px;
  }

  & .dropdown > div {
    border: 2px solid #a08fff;
    background-color: #5a42e0;
    border-radius: 6px;
    color: white;
  }

  @media (max-width: 992px) {
    flex-basis: 100%;

    & .clouds-container {
      width: 100%;
      left: 0;
    }

    & .inner {
      width: 70%;
      margin: 0 auto;
    }
  }

  @media (max-width: 650px) {
    & .inner {
      width: 90%;
    }
  }
`;

const JumpBackInContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
  & .projects__container {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: space-between;
  }
  > :first-child:not(:only-child) {
    flex: 0;
    margin: 0 0 1rem;
  }

  & .span__container {
    border: 1px solid red;
    margin-bottom: 1rem;

    & .title-with-border {
      color: #7c9eb3;
      text-transform: uppercase;
      font-size: 0.8rem;
      display: flex;
      margin-right: 1em;
      text-shadow: 0px 0px 0px #abceeb;
    }
  }
  @media (min-width: 992px) {
    padding: 0 0 0 1.25rem;
    margin: 0;
  }
  @media (max-width: 650px) {
    & .projects__container {
      grid-template-columns: repeat(1, 4fr);
    }
  }
`;

const CreateNewButton = styled.button`
  align-self: flex-end;
  margin: 0.5rem 0 0 auto;
  font-size: 1rem;
  background-color: #4f34e6;
  border: 2px solid #5a40ef;
  box-shadow: 0px 8px 12px rgba(79, 52, 230, 0.51);
  border-radius: 6px;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  width: 150px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0px;

  & .next {
    width: 100%;
    margin: 0 auto;
  }

  & .icon.chevron {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 4px;
    margin-left: auto;
    padding: 5px 6px;
    & polyline {
      stroke: #fff;
    }
  }
`;

const Error = styled.span`
  color: white;
`;

interface IDropdownOption {
  label: string;
  value: string;
}

export const dropdownOptions: Array<IDropdownOption> = [
  { label: 'Scratch', value: 'scratch' },
  { label: 'Python with Turtle', value: 'pythonTurtle' },
  { label: 'Python', value: 'python' },
  { label: 'Java', value: 'java' },
  { label: 'C++', value: 'cpp' },
  { label: 'Whiteboard', value: 'whiteboard' },
  { label: 'Writing', value: 'english_writing' },
];

const NewProjectSetup: React.FC<{
  createProject: (projectName: string, projectType: ProjectTypes) => void;
  errorFromParent?: string;
  clearErrorFromParent: () => void;
}> = ({ createProject, errorFromParent, clearErrorFromParent }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [newProjectType, setNewProjectType] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >(undefined);
  const [newProjectName, setNewProjectName] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const stepsToComplete: Array<{
    title: string;
    description: string;
    questionType: string;
    buttonLabel: string;
    onClick: () => void;
  }> = [
    {
      title: 'Create a New Project',
      description: 'Select from the list of project types',
      questionType: 'dropdown',
      buttonLabel: 'next',
      onClick: () => {
        if (!newProjectType?.value) {
          setErrorMsg('Select a project type from the dropdown first!');
          return;
        }
        setErrorMsg(undefined);
        setActiveStep(activeStep + 1);
      },
    },
    {
      title: 'Name your project',
      description: `Name your ${newProjectType?.label} project`,
      questionType: 'input',
      buttonLabel: 'Create',
      onClick: () => {
        const errorMessage = validateCustomProjectName(newProjectName);
        if (errorMessage) {
          setErrorMsg(errorMessage);
          return;
        }
        if (!newProjectType) {
          setErrorMsg('Oops! Something went wrong!');
          return;
        }
        setErrorMsg(undefined);
        createProject(newProjectName, newProjectType.value as ProjectTypes);
      },
    },
  ];
  const { title, description, questionType, buttonLabel, onClick } = stepsToComplete[
    activeStep
  ];

  let infoComponent;
  switch (questionType) {
    case 'dropdown':
      infoComponent = (
        <Select
          styles={dropdownStyles}
          className="dropdown"
          options={dropdownOptions}
          placeholder="Select a Project Type..."
          onChange={(e: any) => setNewProjectType(e)}
          value={
            dropdownOptions.filter(
              (opt: IDropdownOption) => opt.value === newProjectType?.value,
            )[0]
          }
        />
      );
      break;
    case 'input':
      infoComponent = (
        <CreateNewInput
          value={newProjectName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (errorFromParent) {
              clearErrorFromParent();
            }
            setNewProjectName(e.target.value);
          }}
        />
      );
      break;
    default:
      break;
  }
  return (
    <>
      <div className="new-project-content">
        <h2 className="new-project-heading">{title}</h2>
        <p className="new-project-subheading">{description}</p>
      </div>
      <div className="new-project-action">
        {infoComponent}
        <p>
          {errorMsg && <Error>{errorMsg}</Error>}
          {errorFromParent && <Error>{errorFromParent}</Error>}
        </p>
        <CreateNewButton onClick={onClick}>
          <p className="next">{buttonLabel}</p> <Chevron orientation="right" />
        </CreateNewButton>
      </div>
    </>
  );
};

interface IPlaygroundQuickstartProps extends IPlaygroundProps {
  createProject: (projectName: string, projectType: ProjectTypes) => void;
  errorFromParent?: string;
  clearErrorFromParent: () => void;
  deleteProject: ({ projectId }: { projectId: string }) => void;
}

const PlaygroundQuickstart: React.FC<IPlaygroundQuickstartProps> = props => {
  const projectsToDisplay = 2;

  return (
    <QuickstartContainer>
      <CreateNewContainer>
        <div className="clouds-container">
          <img src={clouds} alt="" />
        </div>
        <div className="inner">
          <NewProjectSetup
            errorFromParent={props.errorFromParent}
            createProject={props.createProject}
            clearErrorFromParent={props.clearErrorFromParent}
          />
        </div>
      </CreateNewContainer>
      <JumpBackInContainer>
        {props.projects && props.projects.length > 0 ? (
          <>
            <SectionLabel>Jump back in</SectionLabel>
            <div className="projects__container">
              {props.projects
                .slice(0, projectsToDisplay)
                .map((project: IPlaygroundProjectForView) => (
                  <ProjectCard
                    key={project._id}
                    projectId={project._id}
                    moduleId={project.moduleId}
                    userId={project.studentId || project.instructorId || ''}
                    isPlayground={['playground', null, undefined].includes(
                      project.moduleId,
                    )}
                    userType={props.currentUser.userType}
                    orientation={CardOrientations.portrait}
                    projectTitle={project.displayName}
                    publishedAt={project.createdAt || ''}
                    projectType={project.projectType}
                    properties={{
                      playgroundProject:
                        project.moduleId === 'playground' || !project.moduleId,
                      inProgress: project.properties?.inProgress,
                      completed: project.properties?.completed,
                    }}
                    idLookup={props.idLookup}
                  />
                ))}
            </div>
          </>
        ) : (
          <PlaygroundEmptyState />
        )}
      </JumpBackInContainer>
    </QuickstartContainer>
  );
};

export default PlaygroundQuickstart;
