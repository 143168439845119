import { Course, TagType } from 'generated/graphql';
import _, { flatMap, sortBy } from 'lodash';

interface Option {
  value: string;
  label: string;
}

export const topicOptionsByCourses = (courses: Course[]) => {
  const tags = [];
  for (const course of courses) {
    for (const tag of course.tags) {
      if (tag.type === TagType.Topic) {
        tags.push(tag);
      }
    }
  }
  const uniqueTags = _.uniq(tags);
  return uniqueTags.map(tag => ({
    value: tag.value,
    label: tag.value,
  }));
};

// query type is (string | null)[] | null | undefined so we need to convert to string[] using filter variable
export const filteredVariables = (
  rawSelectedVariables: (string | null)[] | null | undefined,
  Options: Option[],
) =>
  (rawSelectedVariables || []).filter(
    (selectedVarlable): selectedVarlable is string =>
      !!selectedVarlable &&
      (Options.map((opt: Option) => opt.value) as string[]).includes(
        selectedVarlable,
      ),
  );

// Copied from Website and adjusted two show the first two value with remaining in +
export const defaultStringLabelText = (values: string[]) => {
  if (values.length < 3) return values.join(', ');
  return `${values[0]}, ${values[1]}, + ${values.length - 2}`;
};

// Copied from Website
export const constructAgeLabelText = (values: string[], prefix: string) => {
  const sortedNumbers = sortBy(values.map(value => Number(value)));
  // https://stackoverflow.com/questions/47906850/javascript-group-the-numbers-from-an-array-with-series-of-consecutive-numbers
  const rangeGroups = sortedNumbers.reduce((r: number[][], n: number) => {
    const lastSubArray = r[r.length - 1];

    if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
      r.push([]);
    }

    r[r.length - 1].push(n);

    return r;
  }, []);
  const rangeStrings = rangeGroups.map((rangeGroup: number[]) => {
    if (rangeGroup.length > 2) {
      return `${rangeGroup[0]}-${rangeGroup[rangeGroup.length - 1]}`;
    }
    return rangeGroup.map((num: any) => num.toString());
  });
  const flattened = flatMap(rangeStrings);
  return `${prefix} ${flattened.join(',')}`;
};

export const ageOptions = [
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
];

export const DUMMYSORTBYOPTIONS = [
  { value: 'recommended', label: 'Recommended' },
  { value: 'most_popular', label: 'Most Popular' },
  { value: 'subject', label: 'Subject' },
];
