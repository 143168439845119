import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

class ZoomMeeting extends Component {
  componentDidMount() {
    const { meetingId } = this.props.match.params;
    JuniAnalytics.track('trial_confirmed_launching_meeting');
    window.location.assign(`http://zoom.us/j/${meetingId}`);
  }
  // eslint-disable-next-line class-methods-use-this
  render() {
    return <Spinner />;
  }
}
ZoomMeeting.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      meetingId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ZoomMeeting;
