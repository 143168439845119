import React, { useEffect, useState } from 'react';
import PortalSection from 'components/PortalSection';
import {
  StudentProject,
  useGetFeaturedMyFeedProjectsQuery,
  JuniClubType,
  Student,
} from 'generated/graphql';
import {
  useClubMemberLookup,
  usePubnubFetchMessagesRaw,
} from 'app/clubs/stores/ClubStoreHelpers';
import {
  JuniClubMembershipInfoPlusBadgeType,
  MsgType,
} from 'app/clubs/MyClubsTypes';

import { formatDistanceToNowStrict, differenceInSeconds } from 'date-fns';
import RingSpinner from 'components/RingSpinner';

import _ from 'lodash';

import { NewCard, Icon, NewButton, Divider, Message } from 'core-components';
import { NavLink } from 'react-router-dom';

import useClubStore, { ClubStoreType } from 'app/clubs/stores/ClubStore';
import classNames from 'classnames';
import JuniverseProjectCard from 'app/miscellaneous/JuniverseProjectCard';

const CLUB_PREVIEW_CACHE_DURATION_SECONDS = 20;
const NUM_PREVIEW_MESSAGES_PER_CLUB = 2;

export const ClubPreviewMessage = ({
  clubMessage,
  sender,
}: {
  clubMessage: MsgType;
  sender?: JuniClubMembershipInfoPlusBadgeType;
}) => {
  if (!sender) return null;

  const senderName = `${sender.firstName} ${sender.lastInitial}.`;
  const senderUsername = `@${sender.username}`;
  const timeAgo = `${formatDistanceToNowStrict(clubMessage.timestamp)} ago`;

  return (
    <div className="flex font-graphik w-full">
      <div className="flex items-start">
        <div className="flex mr-4 items-center justify-center rounded-full w-7 h-7 text-j-purple-600 border-solid bg-j-purple-200 border-2 border-j-purple-400">
          <Icon.User className="w-4 h-4" />
        </div>
      </div>
      <div className="flex flex-col flex-grow">
        <div className="flex text-sm items-center mb-1">
          <div className="font-medium text-j-dark-600 mr-3">{senderName}</div>
          <div className="text-j-dark-300 mr-3 hidden lg:flex">{senderUsername}</div>
          <div className="flex items-center bg-j-gray-200 text-j-dark-400 text-2xs rounded-sm uppercase px-1">
            <div className="text-sm pt-0.5 mr-0.5">#</div>
            <div>{clubMessage.juniClubChannel}</div>
          </div>
          <div className="ml-auto text-j-dark-300">{timeAgo}</div>
        </div>
        <div className="text-sm">{clubMessage.payload.msgText}</div>
      </div>
    </div>
  );
};

export const ClubPreviewCard = ({
  student,
  juniClub,
  clubMessages,
}: {
  student: Student;
  juniClub?: JuniClubType;
  clubMessages?: MsgType[];
}) => {
  const [clientUUIDToClubMember] = useClubMemberLookup({
    juniClubId: juniClub?._id,
  });

  const clubIcon = juniClub?.coverPhoto?.length ? (
    <img
      className="rounded-md mr-3 pl-0.5 h-8 w-8"
      src={juniClub?.coverPhoto?.replace('_full', '_thumbnail')}
      alt=""
    />
  ) : (
    <div
      className={classNames(
        'flex mr-4 items-center justify-center',
        'rounded-lg w-7 h-7 text-j-purple-600',
        'border-solid bg-j-purple-200 border-2 border-j-purple-400',
      )}
    >
      <Icon.Award className="w-4 h-4" />
    </div>
  );

  return (
    <div className="mb-4">
      <NavLink
        className="no-underline"
        to={`/learner/${student._id}/club_page/${juniClub?._id}/general`}
      >
        <NewCard squareBorder="bottom">
          <div className="flex flex-col font-graphik">
            <div className="flex items-center">
              {clubIcon}
              <div className="text-sm font-medium text-j-dark-600 mr-3">
                {juniClub?.displayName}
              </div>
              <div className="text-sm text-j-dark-300 hidden lg:flex">
                Recent messages
              </div>

              <div className="ml-auto hidden md:flex">
                <NewButton size="small" variant="secondary" fullWidth>
                  Visit Club
                </NewButton>
              </div>

              <div className="ml-auto flex md:hidden">
                <Icon.ArrowRight />
              </div>
            </div>
          </div>
        </NewCard>
      </NavLink>

      <Divider />

      <NewCard squareBorder="top">
        <div className="flex flex-col space-y-5">
          {(clubMessages || []).map(msg => (
            <ClubPreviewMessage
              key={msg.msgId}
              clubMessage={msg}
              sender={
                msg.senderUUID ? clientUUIDToClubMember[msg.senderUUID] : undefined
              }
            />
          ))}
        </div>
      </NewCard>
    </div>
  );
};

const HomepageMainFeed = ({ student }: { student: Student }) => {
  // Get Featured Projects
  const { data, loading: isLoadingProjects } = useGetFeaturedMyFeedProjectsQuery();

  const featuredStudentProjects = data?.featuredStudentProjects?.items || [];
  const juniverseProjects = featuredStudentProjects
    .filter((p): p is StudentProject => !!p)
    .sort((a, b) => (a._id < b._id ? 1 : -1));
  // Typescript Maybe<T> is really annoying to get rid of

  // Clubs Preview Stuff
  // Adapted from src/components/clubs/ClubSection/ClubSection.tsx
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const juniClubs = useClubStore(state => state.juniClubs);
  const unreadMessages = useClubStore(state => state.unreadMessages);
  const isClubsLoading = useClubStore(state => state.isClubsLoading);
  const lastPreviewMessageFetchAt = useClubStore(
    state => state.lastPreviewMessageFetchAt,
  );
  const previewMessagesByClub = useClubStore(state => state.previewMessagesByClub);
  const set = useClubStore(state => state.set);

  const juniClubsArray = _.values(juniClubs);
  const unreadMessagesByClub: Record<string, number> = {};
  juniClubsArray.forEach(jc => {
    unreadMessagesByClub[jc._id] = _.sum(_.values(unreadMessages[jc._id]));
  });
  const numClubsUnread = _.values(unreadMessagesByClub).filter(n => n > 0).length;
  const pubnubFetchMessagesRaw = usePubnubFetchMessagesRaw();

  useEffect(() => {
    if (isClubsLoading) return;
    // If cached preview messages in club store are recent, no need to refetch
    if (lastPreviewMessageFetchAt) {
      const secondsSinceLastFetch = differenceInSeconds(
        new Date(),
        lastPreviewMessageFetchAt,
      );
      if (secondsSinceLastFetch <= CLUB_PREVIEW_CACHE_DURATION_SECONDS) return;
    }

    setLoadingNotifications(true);
    const getTopUnreadChannel = (juniClub: JuniClubType): string[] => {
      const unreadByChannel = unreadMessages[juniClub._id] || {};
      const channels: string[] = juniClub.channels
        ? juniClub.channels
            .filter(c => !!c.displayName)
            .map(c => c.displayName || '')
        : [];

      const topUnreadChannel = channels
        .sort((a, b) => (unreadByChannel[a] > unreadByChannel[b] ? -1 : 1))
        .slice(0, 1)
        .map(c => `${juniClub._id}.${c}`);

      return topUnreadChannel;
    };

    const fetchPreviewMessages = async (): Promise<Record<string, MsgType[]>> => {
      const currentClientUUID = student._id;
      if (!currentClientUUID) return {};

      const topUnreadClubs = juniClubsArray
        .sort((a, b) =>
          unreadMessagesByClub[a._id] > unreadMessagesByClub[b._id] ? -1 : 1,
        )
        .slice(0, Math.min(juniClubsArray.length, 1));
      const topUnreadChannels = topUnreadClubs.map(club =>
        getTopUnreadChannel(club),
      );

      const messages = _.flatten(
        await Promise.all(
          topUnreadChannels.map(cs =>
            pubnubFetchMessagesRaw({
              currentClientUUID,
              channels: cs,
              count: NUM_PREVIEW_MESSAGES_PER_CLUB,
            }),
          ),
        ),
      );

      const messagesByClub = _.groupBy(messages, 'juniClubId');
      return messagesByClub;
    };

    fetchPreviewMessages().then(messagesByClub => {
      set((state: ClubStoreType) => {
        state.lastPreviewMessageFetchAt = new Date();
        state.previewMessagesByClub = messagesByClub;
      });
      setLoadingNotifications(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClubsLoading]);

  const clubCards = _.keys(previewMessagesByClub).map(juniClubId => (
    <ClubPreviewCard
      key={juniClubId}
      student={student}
      juniClub={juniClubs[juniClubId]}
      clubMessages={previewMessagesByClub[juniClubId]}
    />
  ));

  const projectCards = juniverseProjects.map((juniverseProject, index) => (
    <div
      key={juniverseProject._id.toString()}
      className={classNames({ 'mt-6': index > 0 })}
    >
      <JuniverseProjectCard {...juniverseProject} />
    </div>
  ));

  const isLoadingClubCards = loadingNotifications || isClubsLoading;

  return (
    <>
      {clubCards.length > 0 || isLoadingClubCards ? (
        <PortalSection name="Club Messages">
          {isLoadingClubCards ? (
            <RingSpinner />
          ) : (
            <>
              {numClubsUnread > 0 && (
                <Message status="info" className="flex items-center mb-4" outline>
                  {`You have unread messages from ${numClubsUnread} clubs`}
                </Message>
              )}

              {clubCards}

              <NavLink
                to={`/learner/${student._id}/my_clubs`}
                className="flex items-center justify-center font-graphik no-underline text-j-blue-400 hover:text-j-blue-300 text-sm font-semibold"
              >
                See All Clubs
              </NavLink>
            </>
          )}
        </PortalSection>
      ) : null}

      <PortalSection name="Juniverse Project Feed">
        {isLoadingProjects ? <RingSpinner /> : <> {projectCards}</>}
      </PortalSection>
    </>
  );
};

export default HomepageMainFeed;
