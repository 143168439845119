import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { NewButton } from 'core-components';
import React from 'react';
import LearnerPortalModal from '../LearnerPortalModal';

const CancelCheckModal = ({ updateFormState, formState }) => (
  <LearnerPortalModal
    formState={formState}
    updateFormState={updateFormState}
    title="Cancel Subscription"
    renderPrimaryButton={
      <NewButton
        onClick={() => {
          const modalName = 'cancel_feedback';
          JuniAnalytics.track(`${formState.modal}_button_clicked`, {
            funnel: 'subscription_upgrade_downgrade',
            sourceModal: formState.modal,
            destinationModal: modalName,
            actionFlow: formState.downgradeOption || formState.updateFlow,
          });
          updateFormState(modalName, 'modal');
        }}
      >
        No thanks, I would like to cancel
      </NewButton>
    }
    renderSecondaryButton={
      <NewButton
        onClick={() => {
          window.open(`mailto:support@learnwithjuni.com`, '_blank');
        }}
        variant="secondary"
      >
        Email Support
      </NewButton>
    }
  >
    <div className="flex flex-col text-j-dark-600 leading-5 gap-2">
      <div className="font-semibold">Are you sure you would like to cancel?</div>
      <div className="text-j-dark-400">
        You can cancel at any time during your two week trial without being charged.
      </div>
      <div>
        Please <a href="https://junilearning.com/contact/">contact us</a> instead of
        cancelling your subscription:
      </div>
      <div className="text-j-dark-400">
        • If you are experiencing learning challenges.
      </div>
      <div className="text-j-dark-400">
        • If you are looking to reduce the number of subjects or class frequency for
        your student.
      </div>
      <div className="text-j-dark-400">
        • If you would like to pause your classes for up to one month.
      </div>
      <div className="text-j-dark-400">
        • We’ll reach out to you via email to confirm your pause or cancellation.
      </div>
    </div>
  </LearnerPortalModal>
);

export default CancelCheckModal;
