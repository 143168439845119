import classNames from 'classnames';
import Radio from './Radio';

export interface Option<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export interface RadioGroupProps<T> {
  ariaLabel?: string;
  name: string;
  orientation?: 'horizontal' | 'vertical';
  options: Option<T>[];
  onChange: (selected: T) => void;
  selectedValue?: T;
}

const RadioGroup = <T extends unknown>({
  ariaLabel,
  name,
  orientation = 'horizontal',
  options,
  onChange,
  selectedValue,
}: RadioGroupProps<T>) => {
  const RadioGroupStyles = classNames('flex', {
    'flex-row gap-6': orientation === 'horizontal',
    'flex-col gap-2': orientation === 'vertical',
  });

  return (
    <div role="radiogroup" className={RadioGroupStyles} aria-label={ariaLabel}>
      {options.map(option => (
        <Radio
          key={String(option.value)}
          name={name}
          label={option.label}
          value={option.value}
          isChecked={selectedValue === option.value}
          onClick={() => onChange(option.value)}
          disabled={option.disabled}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
