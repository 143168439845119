import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';
import { ProjectType } from 'generated/graphql';

interface Props {
  projectType: ProjectType;
}

const ProjectIcon: FC<Props> = ({ projectType }) => {
  const containerStyles = classNames(
    'rounded-lg text-center leading-8 w-8 h-8 flex items-center justify-center',
    {
      'bg-j-blue-400': projectType === ProjectType.Cpp,
      'bg-j-pink-500': projectType === ProjectType.EnglishWriting,
      'bg-j-pink-600': projectType === ProjectType.Java,
      'bg-j-dark-500': projectType === ProjectType.Python,
      'bg-j-green-400': projectType === ProjectType.PythonTurtle,
      'bg-j-orange-400': projectType === ProjectType.Scratch,
      'bg-j-purple-500': projectType === ProjectType.Whiteboard,
      'bg-j-blue-600': projectType === ProjectType.InvestingWriting,
      'bg-j-yellow-400': projectType === ProjectType.Js,
      'bg-j-orange-700': projectType === ProjectType.Math,
    },
  );

  const iconStyles = 'text-white w-4 h-4';

  const icon = () => {
    switch (projectType) {
      case ProjectType.Cpp:
        return <Icon.Cpp className={iconStyles} />;
      case ProjectType.EnglishWriting:
        return <Icon.FileText className={iconStyles} />;
      case ProjectType.Java:
        return <Icon.Java className={iconStyles} />;
      case ProjectType.Js:
        return <Icon.Javascript className={iconStyles} />;
      case ProjectType.Math:
        return <Icon.Math className={iconStyles} />;
      case ProjectType.Python:
        return <Icon.Python className={iconStyles} />;
      case ProjectType.PythonTurtle:
        return <Icon.Turtle className={iconStyles} />;
      case ProjectType.Scratch:
        return <Icon.Scratch className={iconStyles} />;
      case ProjectType.Whiteboard:
        return <Icon.Percent className={iconStyles} />;
      case ProjectType.InvestingWriting:
        return <Icon.Investing className={iconStyles} />;
      default:
        return null;
    }
  };

  return <div className={containerStyles}>{icon()}</div>;
};

export default ProjectIcon;
