import { NewButton, NewCard } from 'core-components';
import { ErrorableLoading } from 'components/ui';

import { QueryResult } from '@apollo/client';
import { LoadDefaultPaymentInformationByParentIdQuery } from 'generated/graphql';

interface PaymentInformationCardProps {
  updateFormState: (value: any, field: string) => void;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
}

const PaymentInformationCard = ({
  updateFormState,
  cardQuery,
}: PaymentInformationCardProps) => {
  const card = cardQuery?.data?.defaultPaymentInformationByParentId;
  const { loading, error } = cardQuery;
  return (
    <NewCard padding="0">
      <div className="flex flex-row justify-between items-center px-6 py-4">
        <div className="text-xl font-bold">Payment Information</div>
        <div>
          <NewButton
            size="small"
            variant="secondary"
            onClick={() => {
              updateFormState('payment_update', 'modal');
            }}
          >
            Edit
          </NewButton>
        </div>
      </div>
      <NewCard.Divider />
      <div className="flex flex-col gap-6 p-6">
        {loading || error ? (
          <ErrorableLoading error={error} />
        ) : card ? (
          <>
            <div className="flex flex-col">
              <div className="font-semibold mb-1">Card # (Last 4)</div>
              <div className="text-j-dark-300">...{card.last4}</div>
            </div>
            <div className="flex flex-col">
              <div className="font-semibold mb-1">Card Type</div>
              <div className="text-j-dark-300">{card.brand}</div>
            </div>
            <div className="flex flex-col">
              <div className="font-semibold mb-1">Expires</div>
              <div className="text-j-dark-300">
                {card.exp_month ? card.exp_month.toString().padStart(2, '0') : 'MM'}/
                {card.exp_year ? card.exp_year.toString().substring(2, 4) : 'YY'}
              </div>
            </div>
          </>
        ) : (
          <div>No default payment information found.</div>
        )}
      </div>
    </NewCard>
  );
};

export default PaymentInformationCard;
