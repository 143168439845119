import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Icon, NewButton, NewModalWindow, Popover, TextArea } from 'core-components';
import { NEW_HORIZONS_COLORS } from 'components/RichTextAnnotator/constants';
import ToolSelectButton from '../ToolSelectButton';
import ColorButton from '../ColorButton';

export interface AnnotationPopoverEditorProps {
  /**
   * Actually the style property, but just trying to capture its intended usage :)
   */
  positioning: React.CSSProperties;
  initialValue?: string;
  highlightColor: string;
  onClose: () => void;
  onSubmit: (newData: { annotation?: string; highlightColor?: string }) => void;
  onDelete: () => void;
}

const AnnotationPopoverEditor = React.forwardRef<
  HTMLDivElement,
  AnnotationPopoverEditorProps
>(
  (
    { positioning, initialValue, highlightColor, onClose, onSubmit, onDelete },
    ref,
  ) => {
    const [value, setValue] = useState(initialValue);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    // copy initial value to local state on change
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleUpdateAnnotationText = () => {
      onSubmit({ annotation: value });
      setValue('');
      onClose();
    };
    const handleDelete = () => {
      onDelete();
      setValue('');
      setShowConfirmDelete(false);
      onClose();
    };

    return (
      <>
        <Popover
          ref={ref}
          className="absolute p-6 pt-4 z-10 shadow-8 font-graphik text-j-dark-600"
          style={positioning}
          // cannot close while the delete confirm is open
          onClose={showConfirmDelete ? noop : onClose}
        >
          <TextArea
            value={value}
            rows={3}
            onChange={e => setValue(e.currentTarget.value)}
            placeholder="Your annotation..."
            fullWidth
          />
          <div className="mt-4 text-base">Highlight Color</div>
          <div className="mt-4 flex">
            {NEW_HORIZONS_COLORS.map((color, index) => (
              <ToolSelectButton
                key={color}
                onClick={() => onSubmit({ highlightColor: color })}
                selected={color === highlightColor}
                className={classNames({ 'ml-1': index > 0 })}
              >
                <ColorButton color={color} />
              </ToolSelectButton>
            ))}
          </div>
          <div className="flex mt-4">
            <div className="flex-1">
              <NewButton
                onClick={handleUpdateAnnotationText}
                disabled={initialValue === value}
                fullWidth
              >
                {initialValue ? 'Update' : 'Create'} Annotation
              </NewButton>
            </div>
            <div className="ml-4">
              <NewButton
                intent="error"
                variant="secondary"
                onClick={() => setShowConfirmDelete(true)}
                icon
              >
                <Icon.Trash />
              </NewButton>
            </div>
          </div>
        </Popover>
        <NewModalWindow
          isOpen={showConfirmDelete}
          closeModal={() => setShowConfirmDelete(false)}
          title="Remove Annotation"
          maxWidth="416px"
          renderFooter={() => (
            <div className="flex justify-between w-full">
              <NewButton
                variant="minimal"
                onClick={() => setShowConfirmDelete(false)}
              >
                Close
              </NewButton>
              <NewButton
                intent="error"
                onClick={handleDelete}
                renderIconLeft={props => <Icon.Trash {...props} />}
              >
                Delete
              </NewButton>
            </div>
          )}
        >
          <div className="font-graphik text-j-dark-600 text-base">
            Are you sure you wish to delete this annotation?
            <br />
            This cannot be undone.
          </div>
        </NewModalWindow>
      </>
    );
  },
);

export default AnnotationPopoverEditor;
