import React from 'react';
import JuniSpinner from 'components/JuniSpinner';
import NavBar from './NavBar';

const PublicPageLoading: React.FC<React.ComponentProps<
  typeof NavBar
>> = navBarProps => (
  <>
    <NavBar {...navBarProps} />
    <JuniSpinner size={100} />
  </>
);

export default PublicPageLoading;
