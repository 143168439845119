import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ShareMethod } from 'constants/analytics_events';

import { Icon } from 'core-components';

export const openSocialShareWindow = (url: string) => {
  window.open(url, undefined, 'width=626,height=436,menubar=no');
};

export const logShareEvent = (method: string, shareContent?: string) => {
  JuniAnalytics.track(`social_share_button_clicked`, {
    method,
    ...(shareContent && { shareContent }),
  });
};

export const appendUtmSourceParam = (url: string, utmSourceValue: string) => {
  const parsed = new URL(url);
  parsed.searchParams.append('utm_source', utmSourceValue);
  return parsed.toString();
};

interface ShareProps {
  url: string;
  shareContent: string;
  socialShareCopy: string;
}

export const shareToTwitter = ({
  url,
  shareContent,
  socialShareCopy,
}: ShareProps) => {
  logShareEvent(ShareMethod.twitter, shareContent);
  openSocialShareWindow(
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      socialShareCopy,
    )}&url=${encodeURIComponent(appendUtmSourceParam(url, 'twitter'))}`,
  );
};

export const shareToFacebook = ({
  url,
  shareContent,
  socialShareCopy,
}: ShareProps) => {
  logShareEvent(ShareMethod.facebook, shareContent);
  openSocialShareWindow(
    `https://www.facebook.com/dialog/share?app_id=858657068100830&quote=${encodeURIComponent(
      socialShareCopy,
    )}&href=${encodeURIComponent(appendUtmSourceParam(url, 'facebook'))}`,
  );
};

export const shareToDiscord = ({ url, shareContent }: ShareProps) => {
  window.open(url);
  logShareEvent(ShareMethod.discord, shareContent);
};

export const shareToGoogleChat = ({ url, shareContent }: ShareProps) => {
  window.open(url);
  logShareEvent(ShareMethod.googleChat, shareContent);
};

export const shareToSms = ({ url, shareContent }: ShareProps) => {
  window.open(url);
  logShareEvent(ShareMethod.sms, shareContent);
};

export const buildEmailLink = (url: string): string =>
  appendUtmSourceParam(url, 'email');

export type SocialShareMethods = 'discord' | 'facebook' | 'iMessage' | 'google_chat';

export const INDIRECT_SHARE_SOCIAL_NETWORKS = ['google_chat', 'discord'];

export const SOCIAL_SHARE_METHOD_TO_RENDER_INFO = {
  /**
   * TODO: Add handleShare property
   */
  facebook: {
    icon: Icon.Facebook,
    handleShare: shareToFacebook,
    url: 'https://facebook.com',
    displayName: 'Facebook',
  },
  discord: {
    icon: Icon.Discord,
    handleShare: shareToDiscord,
    url: 'https://discord.com/app',
    displayName: 'Discord',
  },
  iMessage: {
    icon: Icon.iMessage,
    handleShare: shareToSms,
    url: 'sms:?',
    displayName: 'iMessage',
  },
  google_chat: {
    icon: Icon.GoogleChat,
    handleShare: shareToGoogleChat,
    url: 'https://chat.google.com',
    displayName: 'Google Chat',
  },
};
