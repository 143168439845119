import React, { useCallback } from 'react';
import classNames from 'classnames';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import moment, { Moment } from 'moment-timezone';

import { parseMoment } from 'constants/timezones';
import { useField } from 'formik';

import styles from './index.module.css';
import 'react-datepicker/dist/react-datepicker.css';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'value'> {
  label?: string;
  className?: string;
  name?: string;
  onChange?: (value: string, name?: string) => void;
  onDateChange?: (value: Moment, name?: string) => void;
  value?: string | Moment;
  error?: string;
  fluidWidth?: boolean;
}

interface FormikProps extends Omit<Props, 'error'> {
  name: string;
}

type IDatePickerField = React.FC<Props> & {
  Formik: React.FC<FormikProps>;
};

const DatePickerField: IDatePickerField = ({
  className,
  label,
  name,
  value,
  onChange,
  onDateChange,
  error,
  fluidWidth,
  ...rest
}) => {
  const handleChange = useCallback(
    (date: Moment, e?: React.SyntheticEvent<any>) => {
      // prevent default to avoid causing it to re-appear
      // Issue: https://github.com/Hacker0x01/react-datepicker/issues/1012
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      if (onChange) onChange(date ? date.toISOString() : '', name);
      if (onDateChange) onDateChange(date, name);
    },
    [onChange, name, onDateChange],
  );
  // patch time parsing so that user can edit value manually
  const handleChangeRaw = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!e.target.value) return;
      if (!rest.showTimeSelect || !rest.dateFormat) return;
      const date = moment(e.target.value, rest.dateFormat, true);
      if (!date.isValid()) return;
      if (onChange) onChange(date.toISOString(), name);
      if (onDateChange) onDateChange(date, name);
    },
    [name, onChange, onDateChange, rest.dateFormat, rest.showTimeSelect],
  );
  const dateValue = typeof value === 'string' ? parseMoment(value) : value;
  // get UTC offset from date or current date if none provided
  const utcOffset = (dateValue || parseMoment()).utcOffset() / 60;

  return (
    <label
      className={classNames('ui-field', className, {
        [styles.timePicker]: rest.showTimeSelect,
        [styles.fluidWidth]: fluidWidth,
      })}
    >
      {label && <div className="ui-field-label">{label}</div>}
      {error && <div className="ui-field-error">{error}</div>}
      <DatePicker
        {...rest}
        selected={dateValue}
        name={name}
        onChange={handleChange}
        onChangeRaw={handleChangeRaw}
        className={classNames({
          'ui-input': true,
          'ui-input-error': !!error,
        })}
        utcOffset={utcOffset}
      />
    </label>
  );
};

const FormikDatePickerField: React.FC<FormikProps> = props => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value: string, name?: string): void => {
    helpers.setValue(value);
    if (props.onChange) props.onChange(value, name);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // e is not guaranteed to exist despite React Datepicker typings
    if (e) {
      field.onBlur(e);
    } else {
      helpers.setTouched(true);
    }
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <DatePickerField
      {...props}
      error={meta.touched ? meta.error : undefined}
      onChange={handleChange}
      onBlur={handleBlur}
      value={field.value}
    />
  );
};

DatePickerField.Formik = FormikDatePickerField;

export default DatePickerField;
