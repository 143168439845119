import * as R from 'ramda';
import queryString from 'query-string';

import { StudentData, SubjectName, CampaignCode } from '../types';
import sumWeeklyClasses from './sumWeeklyClasses';
import { getStudentBundleSelections } from './getStudentBundleSelections';

interface DCFQueryStringParams {
  campaign?: CampaignCode;
  discountCode?: string;
  subjects?: SubjectName[];
  courses?: string[];
  courseOfferingId?: string;
  weeklyClasses?: number;
}

const parseDCFQuerystringParams = (
  student: Partial<StudentData> | undefined,
  query: string,
): DCFQueryStringParams => {
  const params = queryString.parse(query);
  const bundleSelections = getStudentBundleSelections(student?.bundle);

  if (R.isEmpty(params)) {
    return {
      campaign: undefined,
      discountCode: undefined,
      subjects: Object.keys(student?.bundle?.selections ?? {}) as SubjectName[],
      courses: undefined,
      weeklyClasses: student?.bundle?.selections
        ? sumWeeklyClasses(student.bundle.selections)
        : undefined,
      courseOfferingId: bundleSelections[0]?.courseOfferingId,
    };
  }

  const {
    campaign,
    discountCode,
    subjects,
    courses,
    weeklyClasses,
    courseOfferingId,
  } = params;
  return {
    campaign: (campaign as CampaignCode) || undefined,
    discountCode:
      discountCode && typeof discountCode === 'string' ? discountCode : undefined,
    subjects: (Array.isArray(subjects) ? subjects : subjects?.split(',')) as
      | SubjectName[]
      | undefined,
    courses: Array.isArray(courses) ? courses : courses?.split(','),
    courseOfferingId: Array.isArray(courseOfferingId)
      ? courseOfferingId[0]
      : courseOfferingId ?? undefined,
    weeklyClasses:
      typeof weeklyClasses === 'string' ? parseFloat(weeklyClasses) : undefined,
  };
};

export default parseDCFQuerystringParams;
