import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

import SpinnerV2 from 'components/SpinnerV2';
import { REFERRED_ENROLLMENT_COUPON_STRIPE_ID } from 'constants/coupons';
import { SUBJECT_TO_METADATA_KEY } from 'constants/subjects';
import {
  CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME,
  formatPrice,
  ASYNC_PLAN_PRODUCT,
} from 'constants/subscription_plans';
import { Input, NewButton, NewCard, Message } from 'core-components';

import {
  useCreateStripeSubscriptionMutation,
  useCreateStudentMutation,
} from 'generated/graphql';
import ReferralCodeService from 'services/join/referral_codes';
import { validateCoupon } from 'services/payment';
import { PRIORITY_LEVELS } from 'utils/enrollment';
import {
  extractItemsFromMetadataNew,
  getDisplayNameFromMetadataNew,
  hasAsync,
} from 'utils/stripe';
import { UPDATE_FLOWS } from '../../LearnerAccount';
import LearnerPortalModal from '../LearnerPortalModal';

const SIBLING_COUPON = {
  id: 'SIBLINGDISCOUNT',
  amount_off: null,
  duration: 'forever',
  name: 'SIBLINGDISCOUNT',
  percent_off: 10,
  valid: true,
};

// the way to think about sibling discounts is:
// "do I have an active sibling who is not using the sibling discount?"
export const getSiblingDiscount = (subscriptions, studentId) => {
  const activeSubscriptions = subscriptions.filter(
    subscription => subscription.status !== 'canceled',
  );

  // if the studentId does not exist, we are in the process of creating a new student
  // apply the sibling discount if there is at least one other active subscription
  if (!studentId) {
    return activeSubscriptions.length > 0 ? SIBLING_COUPON : null;
  }

  // otherwise, find all sibling subscriptions
  const activeSiblingSubscriptions = subscriptions.filter(
    subscription =>
      subscription.status !== 'canceled' &&
      !(subscription?.metadata?.studentIds || '').includes(studentId),
  );

  // if there is at least one sibling paying full price, return the sibling discount
  return _.some(
    activeSiblingSubscriptions,
    subscription =>
      subscription.discount === null ||
      subscription.discount?.coupon?.id !== 'SIBLINGDISCOUNT',
  )
    ? SIBLING_COUPON
    : null;
};

// get referral coupon info from stripe or state if already fetched
const getReferralCouponStripeData = async () => {
  let referralCouponInfo;
  try {
    if (!referralCouponInfo) {
      referralCouponInfo = await getCouponDataFromStripe(
        REFERRED_ENROLLMENT_COUPON_STRIPE_ID,
      );
    }
  } catch (err) {
    alert(
      "Sorry, we're having trouble connecting to our payments provider to apply your referral discount at the moment! Please try again later or contact support@learnwithjuni.com if the issue persists.",
    );
  }
  return referralCouponInfo;
};

const getReferralCodeInfoIfValid = async referralCode => {
  try {
    const matchingReferralCodes = await ReferralCodeService.list({
      code: referralCode,
    });
    if (
      !matchingReferralCodes ||
      !matchingReferralCodes.length ||
      !matchingReferralCodes[0].code
    )
      return;
    return matchingReferralCodes[0];
  } catch (err) {
    alert(
      "Sorry, we're having trouble validating your referral code. Please refresh the page to try again or contact support@learnwithjuni.com if the issue persists.",
    );
  }
};

const getReferralCodeCouponIfValid = async referralCode => {
  try {
    const validReferralCode = await getReferralCodeInfoIfValid(referralCode);
    if (!validReferralCode) return;

    // get stripe coupon data for the referral coupon so we can show accurate discount info in the cart
    const referralCouponInfo = await getReferralCouponStripeData();
    if (!referralCouponInfo) return;

    const referralCoupon = {
      ...validReferralCode,
      referrerFirstName: validReferralCode.name,
      ...referralCouponInfo,
      id: validReferralCode.code,
    };
    return referralCoupon;
  } catch (err) {
    alert(
      "Sorry, we're having trouble validating your referral code. Please refresh the page to try again or contact support@learnwithjuni.com if the issue persists.",
    );
  }
};

const getCoupon = async (subscriptions, couponId, studentId) => {
  let coupon;
  let couponCodeExistsInStripe = true;
  let couponError = '';
  try {
    // if the customer attempts to enter the sibling discount manually, check that it is valid
    if (couponId === 'SIBLINGDISCOUNT') {
      coupon = getSiblingDiscount(subscriptions, studentId);
      if (!coupon) {
        couponError = 'This coupon is invalid';
      }
    } else {
      coupon = await validateCoupon(couponId);
    }
  } catch (err) {
    couponError = 'This coupon is invalid';
    if (err && err.response) {
      if (err.response.status === 404) {
        couponCodeExistsInStripe = false;
      }
      if (err.response.data && err.response.data.error) {
        couponError = err.response.data.error;
      }
    }
  }
  if (!couponCodeExistsInStripe) {
    coupon = await getReferralCodeCouponIfValid(couponId);
  }
  return { coupon, couponError };
};

const PlanDetailsSection = ({
  courses,
  metadataNew,
  formValues: { addAsync },
  hasAsyncProduct,
}) => (
  <div className="flex flex-col gap-2">
    <div className="text-base font-medium">Plan Details</div>
    <NewCard
      className="border border-j-dark-100 border-solid"
      padding="0"
      shadow="none"
    >
      <div className="flex p-4 flex-col">
        <div className="text-j-dark-300">Plan</div>
        {getDisplayNameFromMetadataNew(metadataNew)
          .split(', ')
          .map(entry => (
            <div>{entry}</div>
          ))}
        {(addAsync || hasAsyncProduct) && (
          <div>{ASYNC_PLAN_PRODUCT.displayName}</div>
        )}
      </div>
      <NewCard.Divider />
      <div className="flex p-4 flex-col">
        <div className="text-j-dark-300">Courses</div>
        <div className="items-center flex-col">
          {courses
            .filter(course =>
              _.flatMap(_.values(metadataNew), value => value.courses).includes(
                course.name,
              ),
            )
            .map(course => (
              <div key={course.name}>{course.displayName}</div>
            ))}
          {(addAsync || hasAsyncProduct) && (
            <div>{ASYNC_PLAN_PRODUCT.description}</div>
          )}
        </div>
      </div>
    </NewCard>
  </div>
);

const SchedulingPreferencesSection = ({
  formValues: { metadataNew, startingDate },
  metadataOld,
  courses,
}) => (
  <div className="flex gap-2 flex-col">
    <div className="text-base font-medium">Scheduling Preferences</div>
    {_.map(
      _.filter(
        _.toPairs(metadataNew),
        ([key, newState]) =>
          !metadataOld[key] ||
          newState.frequency !== metadataOld[key].frequency ||
          _.difference(newState.courses, metadataOld[key].courses).length > 0,
      ),
      ([key, newState]) => (
        <div className="flex flex-col" key={key}>
          <div className="flex w-full flex-col">
            <div className="flex">
              {courses
                .filter(course => newState.courses.includes(course.name))
                .map(course => course.displayName)
                .join(', ')}
            </div>
            <NewCard
              className="border border-j-dark-100 border-solid"
              padding="0"
              shadow="none"
            >
              {_.map(_.toPairs(PRIORITY_LEVELS), ([priority, label]) => (
                <div key={`${key}_${priority}`} className="flex flex-col">
                  <div className="m-4">
                    <div className="text-j-dark-300">{`${label} Choice`}</div>
                    <div>
                      {format(
                        parseISO(newState.availabilities[priority - 1]),
                        'EEEE, h:mm a',
                      )}
                    </div>
                  </div>
                  {priority < _.max(_.keys(PRIORITY_LEVELS)) && <NewCard.Divider />}
                </div>
              ))}
            </NewCard>
          </div>
        </div>
      ),
    )}
    <div>Earliest Starting Date</div>
    <NewCard
      className="border border-j-dark-100 border-solid"
      padding="0"
      shadow="none"
    >
      <div className="p-4">
        <div className="text-j-dark-300">Starting Date</div>
        <div>{format(new Date(startingDate), 'MMMM do, yyyy')}</div>
      </div>
    </NewCard>
  </div>
);

const PricingSection = ({
  formValues,
  metadataOld,
  coupon,
  setCoupon,
  subscriptions,
  hasAsyncProduct,
}) => {
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  const [couponError, setCouponError] = useState(undefined);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);

  const { addAsync, metadataNew } = formValues;

  const metadataItems = extractItemsFromMetadataNew(metadataNew);
  if (addAsync || hasAsyncProduct) {
    metadataItems.push(_.pick(ASYNC_PLAN_PRODUCT, ['key', 'price']));
  }
  const totalPrice = metadataItems.reduce((total, item) => total + item.price, 0);
  const discount = coupon.id
    ? coupon.amount_off !== null
      ? coupon.amount_off / 100
      : (coupon.percent_off / 100) * totalPrice
    : 0;

  const showRoboticsPaymentMessage = _.difference(
    _.flatMap(_.values(metadataNew), value => value.courses),
    _.flatMap(_.values(metadataOld), value => value.courses),
  ).includes('robotics_1');

  return (
    <div className="flex flex-col gap-2">
      <div className="text-base font-medium">Pricing</div>
      <div className="flex flex-col">
        {metadataItems.map(({ key, frequency, price }) => {
          let displayName = '';
          if (key === ASYNC_PLAN_PRODUCT.key) {
            displayName = ASYNC_PLAN_PRODUCT.displayName;
          } else {
            const category =
              key !== 'core_weeklyFrequency'
                ? CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME[key]
                : '';
            const frequencyStr =
              frequency === 0.5 ? '2x per month' : `${frequency}x per week`;
            displayName = `${category} ${frequencyStr}`;
          }
          return (
            <div className="w-full flex flex-row justify-between text-j-dark-400">
              <div>{displayName}</div>
              <div>{`${formatPrice(price)}/mo`}</div>
            </div>
          );
        })}
        {coupon.id && (
          <div className="flex flex-row justify-between text-j-dark-400">
            <div className="">
              {`${coupon.id} (${
                coupon.amount_off !== null
                  ? `$${coupon.amount_off / 100}`
                  : `${coupon.percent_off}%`
              } off ${
                coupon.duration === 'forever' || coupon.duration === 'once'
                  ? coupon.duration
                  : `for ${coupon.duration}`
              }) `}
            </div>
            <div>
              <div>
                {`–${formatPrice(discount)}${
                  coupon.duration !== 'once' ? '/mo' : ''
                }`}
              </div>
            </div>
          </div>
        )}
        <div className="w-full flex flex-row justify-between text-j-dark-600 ">
          <div>Total</div>
          <div>{`${formatPrice(totalPrice - discount)}${
            !coupon.id || (coupon.id && coupon.duration !== 'once') ? '/mo' : ''
          }`}</div>
        </div>
      </div>
      <div className="w-full">
        {showCouponField ? (
          <div className="flex flex-row w-full">
            <div className="mr-2 w-full">
              <Input
                size="xsmall"
                fullWidth
                placeholder="Coupon code (case sensitive)"
                name="coupon"
                value={couponInput}
                message={couponError}
                valid={couponError ? false : undefined}
                onChange={event => setCouponInput(event.target.value)}
              />
            </div>
            <div>
              <NewButton
                size="xsmall"
                disabled={!couponInput}
                onClick={async () => {
                  setIsLoadingCoupon(true);
                  const { coupon, couponError } = await getCoupon(
                    subscriptions,
                    couponInput,
                    formValues.currentStudent._id.toString(),
                  );
                  if (coupon) {
                    setCoupon(coupon);
                    setShowCouponField(false);
                    setCouponError('');
                  } else {
                    setCouponError(couponError);
                  }
                  setIsLoadingCoupon(false);
                }}
              >
                {isLoadingCoupon ? <SpinnerV2 /> : 'Apply'}
              </NewButton>
            </div>
          </div>
        ) : (
          <NewButton
            size="medium"
            variant="secondary"
            onClick={() => setShowCouponField(true)}
          >
            <span>{coupon.id ? 'Update Coupon' : 'Add Coupon'}</span>
          </NewButton>
        )}
      </div>
      {showRoboticsPaymentMessage && (
        <div className="w-full mt-4">
          <Message status="info">
            Please note, an additional charge of $95 will be added to the card on
            file to cover the cost of the robot. The robot is non-refundable.
          </Message>
        </div>
      )}
    </div>
  );
};

const PaymentInformationSection = ({ card }) => (
  <div className="flex flex-col gap-2">
    <div className="text-base font-medium">Payment Information</div>
    {card.object === 'card' && (
      <div className="flex flex-row">
        <div className="mr-1">{`We will use your primary payment method: `}</div>
        <div className="font-semibold">{`${card?.brand}-${card?.last4}`}</div>
      </div>
    )}
  </div>
);

const PaymentCheckModal = ({
  formValues,
  formState,
  updateFormValue,
  coursesQuery,
  cardQuery,
  subscriptionsQuery,
  studentsQuery,
  updateFormState,
  parent,
}) => {
  const [createSubscription] = useCreateStripeSubscriptionMutation();
  const [createStudent] = useCreateStudentMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [coupon, setCoupon] = useState({});

  const {
    currentStudent,
    subscription,
    metadataNew,
    timezone,
    startingDate,
    addAsync,
  } = formValues;

  const hasAsyncProduct = hasAsync(subscription);

  const { modal, updateFlow } = formState;
  const courses = coursesQuery?.data?.getCourses;
  const card = cardQuery?.data?.defaultPaymentInformationByParentId || {};

  const subscriptions = // eslint-disable-line react-hooks/exhaustive-deps
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];

  useEffect(() => {
    if (coupon.id) return;
    const siblingDiscount = getSiblingDiscount(
      subscriptions,
      currentStudent._id.toString(),
    );
    setCoupon(siblingDiscount || {});
  }, [coupon.id, subscriptions, currentStudent._id]);

  const studentCurrentCourses = currentStudent.hasMultipleTracks
    ? currentStudent.tracks
    : [currentStudent.track];

  // e.g. { csWeeklyFrequency: 2, usacoWeeklyFrequency: 1 }
  const courseMetadataObj = _.pickBy(
    subscription ? subscription.metadata : {},
    (value, key) =>
      value && Object.keys(CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME).includes(key),
  );

  // e.g. { csWeeklyFrequency: { frequency: 2 }, usacoWeeklyFrequency: { frequency: 1 }}
  const metadataOld = _.mapValues(courseMetadataObj, (frequencyStr, metadataKey) => {
    const subject = _.findKey(
      SUBJECT_TO_METADATA_KEY,
      value => value === metadataKey,
    );
    const courseNames = _.map(
      _.filter(
        courses,
        course =>
          course.subject.name === subject &&
          studentCurrentCourses.includes(course.name),
      ),
      'name',
    );
    return {
      frequency: Number(frequencyStr),
      courses: courseNames,
    };
  });

  const subscriptionHasChanged = _.some(
    _.toPairs(metadataNew),
    ([key, newState]) =>
      !metadataOld[key] ||
      newState.frequency !== metadataOld[key].frequency ||
      _.difference(newState.courses, metadataOld[key].courses).length > 0,
  );

  const handleSubmitUpgrade = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setSubmitError('');

    let studentId;
    if (currentStudent._id) {
      studentId = currentStudent._id;
    } else {
      const createStudentInput = {
        parentId: parent._id,
        courses: Object.values(metadataNew).reduce(
          (result, newState) => result.concat(...newState.courses),
          [],
        ),
        availabilities: Object.values(metadataNew).reduce(
          (result, newState) => result.concat(...newState.availabilities),
          [],
        ),
        ..._.pick(currentStudent, [
          'firstName',
          'lastName',
          'courses',
          'birthdate',
          'grade',
          'timezone',
        ]),
      };
      try {
        const response = await createStudent({
          variables: {
            input: createStudentInput,
          },
        });
        studentId = response?.data?.createStudent?.student._id;
        updateFormValue(
          {
            ...currentStudent,
            _id: studentId,
          },
          'currentStudent',
        );
      } catch (err) {
        setSubmitError(err.toString());
      }
    }

    if (studentId) {
      const createSubscriptionInput = {
        subscriptionUpdateType: _.findKey(UPDATE_FLOWS, key => key === updateFlow),
        studentId,
        metadataNew,
        timezone,
        startingDate,
        addAsync,
      };
      if (coupon) {
        createSubscriptionInput.couponId = coupon.id;
      }

      try {
        await createSubscription({
          variables: {
            input: createSubscriptionInput,
          },
        });
        await subscriptionsQuery.refetch();
        await studentsQuery.refetch();

        const modalName = 'payment_confirmation';
        JuniAnalytics.track(`${modal}_button_clicked`, {
          funnel: 'subscription_upgrade_downgrade',
          sourceModal: modal,
          destinationModal: modalName,
          actionFlow: updateFlow,
        });

        updateFormState('', 'updateFlow');
        updateFormState(modalName, 'modal');
      } catch (err) {
        setSubmitError(err.toString());
      }
    }
    setIsSubmitting(false);
  };

  return (
    <LearnerPortalModal
      title={formState.updateFlow}
      formState={formState}
      updateFormState={updateFormState}
      renderPrimaryButton={
        <NewButton onClick={handleSubmitUpgrade} disabled={isSubmitting}>
          {isSubmitting ? <SpinnerV2 /> : <div className="font-medium">Submit</div>}
        </NewButton>
      }
    >
      <div className="flex flex-col gap-6 text-j-dark-600 text-base w-full">
        <PlanDetailsSection
          courses={courses}
          metadataNew={metadataNew}
          formValues={formValues}
          hasAsyncProduct={hasAsyncProduct}
        />
        {
          // only show scheduling section if courses were added/updated
          subscriptionHasChanged && (
            <SchedulingPreferencesSection
              formValues={formValues}
              metadataOld={metadataOld}
              courses={courses}
            />
          )
        }
        <PricingSection
          coupon={coupon}
          setCoupon={setCoupon}
          formValues={formValues}
          metadataOld={metadataOld}
          subscriptions={subscriptions}
          hasAsyncProduct={hasAsyncProduct}
        />
        <PaymentInformationSection card={card} />
        {submitError && (
          <Message status="error">
            There was an error creating your subscription! Please send us an email at{' '}
            <a href="mailto:support@learnwithjuni.com">support@learnwithjuni.com</a>{' '}
            so that we can assist.
          </Message>
        )}
      </div>
    </LearnerPortalModal>
  );
};

export default PaymentCheckModal;
