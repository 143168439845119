import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'components/ui';
import { NextArrow } from 'components/Icons';
import { TextSetFragment } from 'generated/graphql';

interface IProps {
  referenceTextSets: TextSetFragment[];
  handleSelectAPassage: (id: string) => Promise<void>;
  submitDisabled?: boolean;
}

const SelectAPassage: React.FC<IProps> = ({
  referenceTextSets,
  handleSelectAPassage,
  submitDisabled = false,
}) => {
  const [selectedTextSetId, setSelectedTextSetId] = useState('');
  const onSelectPassage = (e: React.MouseEvent<HTMLButtonElement>) => {
    const textSetId = e.currentTarget.value;
    setSelectedTextSetId(textSetId);
  };
  const onSubmitPassageSelection = () => {
    handleSelectAPassage(selectedTextSetId);
  };
  const textSetOptions = referenceTextSets.map(
    (referenceTextSet: TextSetFragment) => (
      <StyledTextSetOption
        key={referenceTextSet._id}
        isSelected={selectedTextSetId === referenceTextSet._id}
        onClick={onSelectPassage}
        value={referenceTextSet._id}
      >
        {referenceTextSet.title}
      </StyledTextSetOption>
    ),
  );

  return (
    <StyledSelectAPassage>
      <StyledHeader>
        <h1>Select a passage for this project</h1>
      </StyledHeader>
      <StyledTextSetOptionsAndButtonContainer>
        {textSetOptions}
        <Button
          className="submit-selection"
          onClick={onSubmitPassageSelection}
          disabled={submitDisabled || !selectedTextSetId}
          icon={<NextArrow />}
        >
          Select Passage
        </Button>
      </StyledTextSetOptionsAndButtonContainer>
    </StyledSelectAPassage>
  );
};

const StyledSelectAPassage = styled.div`
  padding: 1.5rem;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  button.submit-selection {
    margin-left: auto;
    margin-top: 1.25rem;
  }
`;
const StyledHeader = styled.div`
  padding-bottom: 1.25rem;
  border-bottom: 2px solid hsl(198, 100%, 93%);
  width: 100%;
  color: hsl(206, 39%, 33%);
  > h1 {
    margin: 0;
    font-size: 1.25rem;
  }
`;
const StyledTextSetOptionsAndButtonContainer = styled.div`
  padding: 1.25rem 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const StyledTextSetOption = styled.button<any>`
  padding: 1rem;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  border: 2px solid
    ${props => (props.isSelected ? 'hsl(201, 100%, 50%)' : 'hsl(201, 70%, 89%)')};
  border-radius: 8px;
  background: ${props => (props.isSelected ? 'hsl(197, 100%, 98%)' : '#fff')};
  color: ${props =>
    props.isSelected ? 'hsl(201, 100%, 50%)' : 'hsl(202, 33%, 35%)'};
  text-transform: none;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
`;

export default SelectAPassage;
