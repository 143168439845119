import React from 'react';
import _ from 'lodash';
import useClubStore from 'app/clubs/stores/ClubStore';
import NotificationsBadge from 'components/NotificationsBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { NavLeftButtonLink } from './NavLeftButton';
import { NavLeftButtonProps } from './types';

interface ClubsButtonProps extends NavLeftButtonProps {
  baseRoute: string;
  isClubsEnabled?: boolean;
}

const ClubsButton: React.FC<ClubsButtonProps> = ({
  baseRoute,
  pathname,
  isClubsEnabled,
}) => {
  const active = pathname.includes(`${baseRoute}/my_clubs`);
  const isOnClubsPage = active || pathname.includes(`${baseRoute}/club_page`);
  const unreadMessages = useClubStore(state => state.unreadMessages);
  const totalUnreadMessages = isClubsEnabled
    ? _.sum(_.values(unreadMessages).map(club => _.sum(_.values(club))))
    : 0;
  return (
    <NavLeftButtonLink
      active={active}
      icon={
        <div className="icon">
          <FontAwesomeIcon icon={faUserFriends} />
          <NotificationsBadge
            numNotifications={totalUnreadMessages}
            showAsDot={!isOnClubsPage}
            hasTopRightPositioning
          />
        </div>
      }
      link={`${baseRoute}/my_clubs`}
      text="My Clubs"
    />
  );
};

export default ClubsButton;
