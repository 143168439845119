import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import MarkdownText from 'components/MarkdownText';

import { TeacherSession } from 'models';
import { SESSION_STATE_DESCRIPTION } from '../constants';

interface NoteTextProps {
  isUpcoming: boolean;
  teacherMode: boolean;
  teacherSession?: TeacherSession;
}

const NoteText: FC<NoteTextProps> = ({
  isUpcoming,
  teacherMode,
  teacherSession,
}) => {
  let displayText;
  if (!teacherMode) {
    displayText = undefined;
  }
  if (teacherSession?.wasMissed) {
    displayText = SESSION_STATE_DESCRIPTION.missed;
  }
  if (teacherSession?.end && teacherSession.completed && teacherSession.notes) {
    displayText = teacherSession.notes;
    return (
      <MarkdownText
        text={teacherSession.notes}
        displayAsPlainText={!teacherSession.usesMarkdown}
      />
    );
  }
  if (!teacherSession) {
    if (isUpcoming) {
      displayText = SESSION_STATE_DESCRIPTION.upcoming;
    }
    displayText = SESSION_STATE_DESCRIPTION.incomplete;
  }
  if (teacherSession && !teacherSession.end) {
    displayText = SESSION_STATE_DESCRIPTION.inProgress;
  }

  if (displayText) {
    return (
      <div className="font-graphik text-j-dark-600 text-base whitespace-pre-line">
        <ReactMarkdown>{displayText}</ReactMarkdown>
      </div>
    );
  }
  return null;
};

export default NoteText;
