import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from 'modules/Auth';
import { DefaultNav } from 'components/Nav';
import { NotAuthorizedErrorPage } from 'authentication';

import './authenticated_route.css';

const AuthenticatedRoute = ({
  allowAdmin,
  allowTeacher,
  allowParent,
  isAuthorized,
  userPermissions,
  userRoles,
  userType,
  render,
  component: Component,
  ...rest
}) => {
  const accessAllowed = isAuthorized
    ? () =>
        isAuthorized(userType, userPermissions, userRoles, rest.location.pathname)
    : () =>
        (!allowAdmin && !allowTeacher && !allowParent) ||
        (allowAdmin && userType === 'admin') ||
        (allowTeacher && userType === 'teacher') ||
        (allowParent && userType === 'parent');

  return (
    <Route
      {...rest}
      render={props =>
        Auth.isUserAuthenticated() ? (
          accessAllowed() ? (
            render ? (
              render(props)
            ) : (
              <Component {...props} />
            )
          ) : (
            <div className="not-authorized-error-page-container">
              <DefaultNav
                pathname={rest.location.pathname}
                userIsAuthenticated={Auth.isUserAuthenticated()}
              />
              <NotAuthorizedErrorPage />
            </div>
          )
        ) : (
          <Redirect to={`/?redirectTo=${encodeURI(window.location.href)}`} />
        )
      }
    />
  );
};
AuthenticatedRoute.defaultProps = {
  allowAdmin: false,
  allowTeacher: false,
  allowParent: false,
  component: null,
  isAuthorized: null,
  render: null,
  userPermissions: [],
  userRoles: [],
};
AuthenticatedRoute.propTypes = {
  allowAdmin: PropTypes.bool,
  allowTeacher: PropTypes.bool,
  allowParent: PropTypes.bool,
  component: PropTypes.node,
  isAuthorized: PropTypes.func,
  render: PropTypes.func,
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  userType: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

export default withRouter(AuthenticatedRoute);
