import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { addDays } from 'date-fns';

import {
  REFERRAL_COOKIE_NAME,
  REFERRER_COOKIE_NAME,
} from 'constants/signup_sessions';

import discountCodeService from 'services/signupSessions/discountCodeService';
import UserContext from 'modules/UserContext';

interface ReferralData {
  referrerName: string;
  referralCode: string;
}

interface UseReferralCodeReturnValue extends Partial<ReferralData> {
  isValid: boolean;
  isLoading: boolean;
  errorCode?: number;
}

const REFERRAL_COOKIE_MAX_AGE = 7; // 7 days
export const makeReferralCookieParams = (
  cookieName: typeof REFERRAL_COOKIE_NAME | typeof REFERRER_COOKIE_NAME,
  value: string,
): [
  string,
  string,
  {
    expires?: Date;
    domain: string;
    path: string;
  },
] => [
  cookieName,
  value,
  {
    expires: addDays(new Date(), REFERRAL_COOKIE_MAX_AGE),
    domain: process.env.NODE_ENV === 'production' ? 'junilearning.com' : '',
    path: '/',
  },
];

const useReferralCode = (code?: string): UseReferralCodeReturnValue => {
  const { user } = useContext(UserContext);
  const userIsAdmin = user?.roles.includes('admin');

  const [cookies, setCookie, removeCookie] = useCookies([REFERRAL_COOKIE_NAME]);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [referralData, setReferralData] = useState<ReferralData | undefined>();
  const [errorCode, setErrorCode] = useState<number | undefined>();

  useEffect(() => {
    const codeToValidate = code ?? cookies[REFERRAL_COOKIE_NAME];
    if (!codeToValidate) {
      setIsLoading(false);
      return;
    }

    const fetchReferralCode = async () => {
      setErrorCode(undefined);
      const res = await discountCodeService.validateReferralCode(codeToValidate);
      if (res.data?.data?.[0]) {
        const validCode = res.data?.data?.[0];
        setIsLoading(false);
        setIsValid?.(true);
        setReferralData({
          referralCode: validCode.code,
          referrerName: validCode.name,
        });

        removeCookie(REFERRAL_COOKIE_NAME);
        removeCookie(REFERRER_COOKIE_NAME);

        if (!userIsAdmin) {
          setCookie(
            ...makeReferralCookieParams(REFERRAL_COOKIE_NAME, validCode.code),
          );
          setCookie(
            ...makeReferralCookieParams(REFERRER_COOKIE_NAME, validCode.name),
          );
        }

        return res.data.data?.[0];
      }

      setIsLoading(false);
      setIsValid(false);
    };

    try {
      if (codeToValidate !== referralData?.referralCode) {
        fetchReferralCode();
      }
    } catch (e) {
      if (e?.response.status === 404) {
        setIsValid(false);
      } else {
        setErrorCode(e?.response.status);
      }
      setIsLoading(false);
    }
  }, [
    code,
    cookies,
    referralData?.referralCode,
    removeCookie,
    setCookie,
    userIsAdmin,
  ]);

  return {
    isValid,
    isLoading,
    errorCode,
    ...referralData,
  };
};

export default useReferralCode;
