import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpinnerV2 from 'components/SpinnerV2';

import {
  JRSModalLine,
  JRSSteps,
  JRSNextButton,
  JRSStatusMessage,
  JRSGif,
} from './subcomponents';

import '../styles/user_media_request.css';
import '../styles/jide_rec_studio.css';

const JRSUserMediaRequestLoadingState = () => (
  <>
    <div className="jrs-modal-heading5">Checking for Connected Devices...</div>
    <SpinnerV2 size={30} />
  </>
);

const JRSUserMediaRequestUnknownErrorState = () => (
  <JRSStatusMessage
    color="red"
    icon="exclamation"
    text="Oops! Something went wrong when checking for Audio and Video Devices. Please try quitting any other programs (such as Zoom) that are using the microphone or camera, refreshing the page, or using a different computer!"
  />
);

const JRSUserMediaRequestDefaultState = () => (
  <>
    <JRSModalLine
      icon="chalkboard-teacher"
      boldText="Step 1: Allow Access"
      text={`Ensure you have a webcam connected to your computer. Then click "Allow" on the prompt at the top-left corner of the browser window`}
    />

    <JRSGif
      src={`${window.location.origin}/recording_studio/allowUserMedia.mp4`}
      unlockAspectRatio
    />
  </>
);

const JRSUserMediaRequestGreenState = ({ numCameras, numMics, setMode }) => {
  const audioStatus = `${numMics} Audio Device${numMics === 1 ? '' : 's'}`;
  const videoStatus = `${numCameras} Video Device${numCameras === 1 ? '' : 's'}`;
  return (
    <>
      <JRSSteps
        currStep={1.5}
        onClick={() => {
          setMode('displayMediaRequest');
        }}
      />

      {numCameras === 0 ? (
        <JRSStatusMessage
          color="yellow"
          icon="exclamation-triangle"
          text="Permissions granted! However, we weren't able to detect any Video Devices. You'll still be able to use the Recording Studio, but no video will be recorded."
        />
      ) : (
        <>
          <JRSStatusMessage
            color="green"
            icon="check"
            text={`Permissions Granted! ${audioStatus} and ${videoStatus} Detected`}
          />
          <JRSStatusMessage
            color="gray"
            icon="chevron-right"
            text="If you are currently running another program (such as Zoom) that is using the camera or microphone, the Recording Studio may not work properly! If this happens, try closing the other program and try again."
          />
        </>
      )}

      <JRSNextButton
        onClick={() => {
          setMode('displayMediaRequest');
        }}
        text="Next Step: Select a Screen to Record"
        enabled
      />
    </>
  );
};

const JRSUserMediaRequestNotAllowedState = ({ onMount }) => (
  <>
    <div className="jrs-modal-heading4">
      We couldn't get access to your microphone or camera. Ensure you have them
      connected and then try following the steps below to fix.
    </div>
    <JRSModalLine
      icon="chalkboard-teacher"
      boldText="Step 1: Unblock Microphone & Camera"
      text={`Click the "Camera & Microphone Blocked" icon on the right side of your browser's address bar`}
    />
    <JRSModalLine
      icon="chalkboard-teacher"
      boldText="Step 2: Allow Access"
      text={`Select "Always allow access to the camera and microphone" and then click the red "Try Again" button below`}
    />

    <JRSGif
      src={`${window.location.origin}/recording_studio/unblockCameraMic.mp4`}
      unlockAspectRatio
    />
    <JRSStatusMessage
      color="red"
      icon="exclamation-triangle"
      text="Enable access to your camera and microphone using the steps above, then click Try Again"
      buttonText="Try Again"
      onClick={onMount}
    />
  </>
);

const JRSUserMediaRequestNoMicrophoneState = ({ onMount }) => (
  <>
    <div className="jrs-modal-heading4">
      We were unable to find an Audio Device that you can use in the Recording
      Studio! Ensure that you have a microphone connected and that you are not
      running another program (such as Zoom) that might be using your microphone.
      Then click Try Again.
    </div>
    <JRSStatusMessage
      color="red"
      icon="exclamation-triangle"
      text="Make sure that you have a microphone connected and available, then click Try Again"
      buttonText="Try Again"
      onClick={onMount}
    />
  </>
);

const JRSUserMediaRequestNoCameraUsableState = ({
  resetActiveDevices,
  onMount,
  setMode,
}) => (
  <>
    <div className="jrs-modal-heading4">
      It looks like you might be running another program (such as Zoom) that is
      interfering with your camera! You can still use the Recording Studio, but you
      will not be able to record any video. Or, you can try quitting the other
      program and then clicking Try Again.
    </div>
    <JRSStatusMessage
      color="red"
      icon="exclamation-triangle"
      text="Quit the other program that is using your camera, then click Try Again"
      buttonText="Try Again"
      onClick={() => {
        resetActiveDevices();
        onMount();
      }}
    />
    <JRSNextButton
      onClick={() => {
        setMode('displayMediaRequest');
      }}
      text="Proceed anyway without a camera"
      enabled
      link
    />
  </>
);

// Modal that guides user through the userMedia (i.e. camera and mic) request process
// by showing them a gif of the browser notification they will see, what to do once
// it shows up, and and a confirmation that the access was granted once it's complete.
// If access is denied or there is another error, it shows a backup set of instructions
// on how to enable userMedia, and if it is a blocking error (denied permissions or no
// microphone) it won't let the user continue with setup until the error is remedied.
const JRSUserMediaRequest = ({
  setMode,
  refreshDevices,
  resetActiveDevices,
  mics,
  cameras,
}) => {
  const [status, setStatus] = useState('');

  // Invoke refreshDevices function from streamBuilder
  // to trigger getUserMedia and change modal depending on whether
  // access was granted or not
  const onMount = useCallback(() => {
    async function onMountAsync() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const filteredDevices = devices.filter(d => d.deviceId && d.deviceId.length);
      if (!filteredDevices.length) setStatus('FirstTimeRequest');

      setStatus(await refreshDevices());
    }
    onMountAsync();
  }, [refreshDevices]);

  useEffect(onMount, [onMount]);

  const numMics = mics.length;
  const numCameras = cameras.length - 1; // subtract one for "no camera" selection

  return (
    <div className="jrs-modal jrs-usermediarequest">
      <div className="jrs-modal-section jrs-row jrs-modal-header">
        <div className="jrs-modal-heading1">Enable Camera + Microphone</div>
        <div className="jrs-modal-x" onClick={() => setMode('close')}>
          <div className="jrs-modal-x-inner">
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
      </div>

      <div className="jrs-modal-section jrs-section jrs-column">
        {status === 'NotAllowedError' ? (
          <JRSUserMediaRequestNotAllowedState onMount={onMount} />
        ) : status === 'NoMicrophone' ? (
          <JRSUserMediaRequestNoMicrophoneState onMount={onMount} />
        ) : status === 'NoCameraUsable' ? (
          <JRSUserMediaRequestNoCameraUsableState
            resetActiveDevices={resetActiveDevices}
            onMount={onMount}
            setMode={setMode}
          />
        ) : status === 'Ready' ? (
          <JRSUserMediaRequestGreenState
            numCameras={numCameras}
            numMics={numMics}
            setMode={setMode}
          />
        ) : status === 'FirstTimeRequest' ? (
          <JRSUserMediaRequestDefaultState />
        ) : status === 'UnknownError' ? (
          <JRSUserMediaRequestUnknownErrorState />
        ) : (
          <JRSUserMediaRequestLoadingState />
        )}
      </div>
    </div>
  );
};

export default JRSUserMediaRequest;
