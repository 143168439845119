import React from 'react';

const PlaygroundIcon: React.FC = () => (
  <svg
    width="160"
    height="127"
    viewBox="0 0 160 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M151.395 127H9.10494C4.56204 127 0.992676 123.432 0.992676 118.89V8.10983C0.992676 3.56833 4.56204 0 9.10494 0H151.395C155.938 0 159.507 3.56833 159.507 8.10983V118.89C159.507 123.432 155.938 127 151.395 127Z"
      fill="#225D80"
    />
    <path
      d="M0.992676 31.304V8.10983C0.992676 3.56833 4.56204 0 9.10494 0H151.395C155.938 0 159.507 3.56833 159.507 8.10983V31.304"
      fill="#A6C1FF"
    />
    <path
      d="M21.761 15.7334C21.761 18.0041 19.9763 19.7883 17.7048 19.7883C15.4334 19.7883 13.6487 18.0041 13.6487 15.7334C13.6487 13.4626 15.4334 11.6785 17.7048 11.6785C19.9763 11.6785 21.761 13.4626 21.761 15.7334Z"
      fill="white"
    />
    <path
      d="M38.6335 15.7334C38.6335 18.0041 36.8488 19.7883 34.5774 19.7883C32.3059 19.7883 30.5212 18.0041 30.5212 15.7334C30.5212 13.4626 32.3059 11.6785 34.5774 11.6785C36.8488 11.6785 38.6335 13.4626 38.6335 15.7334Z"
      fill="white"
    />
    <path
      d="M55.5081 15.7334C55.5081 18.0041 53.7234 19.7883 51.4519 19.7883C49.1805 19.7883 47.3958 18.0041 47.3958 15.7334C47.3958 13.4626 49.1805 11.6785 51.4519 11.6785C53.7234 11.6785 55.5081 13.4626 55.5081 15.7334Z"
      fill="white"
    />
    <path d="M141.173 48.6594H57.7788V106.564H141.173V48.6594Z" fill="#5486FB" />
    <path d="M57.7772 48.6594H19.1626V106.564H57.7772V48.6594Z" fill="#59F986" />
    <path d="M127.22 60.3369H71.8936V65.0406H127.22V60.3369Z" fill="white" />
    <path d="M127.22 75.2583H71.8936V79.962H127.22V75.2583Z" fill="white" />
    <path d="M105.965 90.019H71.8936V94.7227H105.965V90.019Z" fill="white" />
  </svg>
);

export default PlaygroundIcon;
