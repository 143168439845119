import React, { useState } from 'react';
import {
  Student,
  useGetJuniverseUserProfileByUsernameQuery,
} from 'generated/graphql';
import { Link } from 'react-router-dom';

import astronaut from 'images/astronaut.svg';
import RingSpinner from 'components/RingSpinner';

import { NewCard, NewButton, Panel, Divider } from 'core-components';
import PortalSection from 'components/PortalSection';
import UsernameCreator from 'components/UsernameCreatorV2';

const StudentProfilePreview = ({ student }: { student: Student }) => {
  const { data, loading } = useGetJuniverseUserProfileByUsernameQuery({
    variables: { username: student.username || '' },
    // this should be skipped if username is undef due to the line below
    skip: student.username === undefined,
  });
  const projects = data?.juniverseUserProfileByUsername?.publicProjects || [];

  const [showUsernameCreator, setShowUsernameCreator] = useState(false);

  const usernameCreatorCallback = async () => {
    window.location.reload();
  };

  let viewProfileButton = (
    <NewButton
      onClick={!student.username ? () => setShowUsernameCreator(true) : undefined}
      fullWidth
    >
      View My Profile
    </NewButton>
  );
  if (student.username) {
    viewProfileButton = (
      <Link to={`/juniverse/users/${student.username}`} className="no-underline">
        {viewProfileButton}
      </Link>
    );
  }

  return (
    <PortalSection name="My Profile">
      <div className="mb-4">
        <NewCard squareBorder="bottom">
          <img
            src={astronaut}
            alt=""
            className="block mt-0 mx-auto mb-2 rounded-full w-3/5"
          />
          <div className="text-base font-semibold text-center mb-1 text-j-dark-600">
            {`${student.firstName} ${student.lastName}`}
          </div>
          {student.username && (
            <div className="text-sm text-center mb-3 text-j-dark-500">
              {`@${student.username}`}
            </div>
          )}

          {/* TODO: Enable this once we add bio to Student as part of User Profiles */}
          {/* <div className="text-xs text-center mb-3 text-j-dark-400">
            {`${student.bio}`}
          </div> */}

          {viewProfileButton}
        </NewCard>

        <Divider />

        <NewCard squareBorder="top">
          {loading ? (
            <RingSpinner />
          ) : (
            <>
              <Panel
                backgroundColor="transparent"
                padding="1"
                className="justify-between text-sm text-j-dark-600 mb-2"
              >
                <span>Published Projects</span>
                <span className="font-semibold">{` ${projects?.length || 0}`}</span>
              </Panel>
              <Panel
                backgroundColor="transparent"
                padding="1"
                className="justify-between text-sm text-j-dark-600"
              >
                <span>Project Views</span>
                <span className="font-semibold">
                  {` ${projects?.reduce(
                    (acc, curr) => acc + (curr.viewCount || 0),
                    0,
                  )}`}
                </span>
              </Panel>
            </>
          )}
        </NewCard>
        <UsernameCreator
          isOpen={showUsernameCreator}
          closeModal={() => setShowUsernameCreator(false)}
          studentId={student._id}
          onSuccessCallback={usernameCreatorCallback}
        />
      </div>
    </PortalSection>
  );
};

export default StudentProfilePreview;
