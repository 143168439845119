import React from 'react';
import classNames from 'classnames';
import { TailwindHeight, TailwindBorderRadius, TailwindColor } from 'theme/types';

export interface ProgressBarProps {
  /**
   * Determines the inner width of the progress bar.
   * Scale is in percentage.
   */
  percent: number;
  /**
   * Specifies the height of progress bar.
   * Styled with Tailwind scale and defaults to 4.
   * */
  height?: TailwindHeight;
  /**
   * Specifies border radius.
   * */
  borderRadius?: TailwindBorderRadius;
  /**
   * Specifies the color of progress bar.
   * Styled with Tailwind scale and defaults to bg-j-purple-700.
   * */
  color?: TailwindColor;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  percent,
  height = '4',
  borderRadius,
  color = 'bg-j-purple-700',
}) => {
  const innerWidth = percent > 100 ? 100 : percent < 0 ? 0 : percent;
  return (
    <div
      className={classNames(`bg-j-gray-200 w-full h-${height}`, {
        [`rounded-${borderRadius}`]: borderRadius,
      })}
    >
      <div
        className={classNames(`${color} h-full`, {
          [`rounded-${borderRadius}`]: borderRadius,
        })}
        style={{ width: `${innerWidth}%` }}
      ></div>
    </div>
  );
};
export default ProgressBar;
