import styled from 'styled-components/macro';
import { CSSProperties } from 'react';

import { XIcon } from 'components/Icons';

// To set the max-height of the ScrollableContent appropriately, we need to
// subtract the top and bottom padding the overlay as well as the max height of
// the header and footer sections (if they exists) from 100vh.
// We therefore define the overlay padding as a constant and use it to compute
// the ScrollableContent's max-height to make the effect of the padding clear.
const OVERLAY_PADDING = '2rem';

const MODAL_HEADER_MAX_HEIGHT = '7rem';
const MODAL_FOOTER_MAX_HEIGHT = '5rem';

const X_ICON_SIZE = '12px';
const X_ICON_PADDING = '8px';

const ScrollableContent = styled.div<any>`
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
`;

const CloseButton = styled.button.attrs({
  className: 'text-j-dark-600 stroke-current',
})`
  background: none;
  display: block;
  margin-left: auto;
  margin-bottom: auto;
  padding: ${X_ICON_PADDING};
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);

  path {
    stroke: rgba(41, 37, 99, 1);
  }
  :focus,
  :hover {
    background-color: #d33838;
    box-shadow: none;
    path {
      stroke: #ffebeb;
    }
  }
`;

const CloseButtonIcon = styled(XIcon)`
  height: ${X_ICON_SIZE};
  width: ${X_ICON_SIZE};
  display: block;
  padding: 0;
`;

const ModalHeader = styled.div.attrs({ className: 'flex px-5 py-4' })`
  max-height: ${MODAL_HEADER_MAX_HEIGHT};
`;

const ModalFooter = styled.div.attrs({ className: 'flex justify-between p-4 ' })`
  max-height: ${MODAL_FOOTER_MAX_HEIGHT};
`;

const REACT_MODAL_CONTENT_STYLES: CSSProperties = {
  border: '1px solid rgba(229, 228, 236, 1)',
  borderRadius: '12px',
  overflow: 'hidden',
  padding: '0',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  left: 'auto',
  position: 'static',
  maxWidth: '42rem',
  width: '100%',
};

const REACT_MODAL_OVERLAY_STYLES: CSSProperties = {
  backgroundColor: 'rgba(41, 37, 99, 0.16)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: OVERLAY_PADDING,
  zIndex: 1001,
};

export {
  ScrollableContent,
  ModalHeader,
  ModalFooter,
  CloseButton,
  CloseButtonIcon,
  REACT_MODAL_CONTENT_STYLES,
  REACT_MODAL_OVERLAY_STYLES,
};
