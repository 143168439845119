import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { Link } from 'react-router-dom';
import { JuniClubType, useUpdateMembershipStatusMutation } from 'generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JuniClubMembershipInfoPlusBadgeType } from 'app/clubs/MyClubsTypes';
import { ModalWindow, Button } from 'core-components';
import { UserInfo } from 'components/clubs';
import { getClubUserBadgeInfo, ClubUserBadgeInfo } from 'app/clubs/helpers';

interface ClubMembersListModalProps {
  clubMembers?: JuniClubMembershipInfoPlusBadgeType[];
  isOpen: boolean;
  closeModal: (isOpen: boolean) => void;
  juniClub: JuniClubType;
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
  studentId?: string;
  instructorUserId?: string;
}

interface ClubMembersListRowProps {
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
  member: JuniClubMembershipInfoPlusBadgeType;
  studentId?: string;
  instructorUserId?: string;
  juniClubId: string;
}

const ClubMembersListRowWrapper = styled.div.attrs({
  className: 'flex flex-col py-2 first:pt-0 last:pb-0',
})`
  :not(:last-child) {
    border-bottom: 1px solid #e0f2ff;
  }
`;
const ClubMembersListRowTop = styled.div.attrs({
  className: 'flex justify-between',
})`
  flex-direction: column;

  @media only screen and (min-width: 640px) {
    flex-direction: row;
  }
`;

interface ClubUserBadgeInfoPlusMembershipStatus extends ClubUserBadgeInfo {
  membershipStatus?: string | null;
}

const ClubMembersListRow: FC<ClubMembersListRowProps> = ({
  currentClubMemberState,
  member,
  studentId,
  instructorUserId,
  juniClubId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateMembershipStatusMutation] = useUpdateMembershipStatusMutation();

  const isCurrentClubMemberModerator =
    currentClubMemberState?.badgeInfo.name === 'moderator';
  const isStudentOrInstructor =
    member.userCategory && ['student', 'instructor'].includes(member.userCategory);
  const possibleBadges: ClubUserBadgeInfoPlusMembershipStatus[] =
    isCurrentClubMemberModerator && isStudentOrInstructor
      ? ['member', 'moderator'].map(membershipStatus => {
          const badgeInfo = getClubUserBadgeInfo({
            userCategory: member.userCategory,
            membershipStatus,
          });
          return { ...badgeInfo, membershipStatus };
        })
      : [];

  return (
    <ClubMembersListRowWrapper>
      <ClubMembersListRowTop>
        <UserInfo key={`${member.clientUUID}`} member={member} />
        <Link to={`/juniverse/users/${member.username}`}>
          <Button className="mt-3 sm:mt-0 text-sm" hasArrowIcon>
            View Profile
          </Button>
        </Link>
      </ClubMembersListRowTop>
      <div className="flex">
        {possibleBadges.map(({ name, displayName, membershipStatus }) => (
          <Button
            key={name}
            variant={member.badgeInfo.name === name ? 'secondary' : 'primary'}
            size="small"
            width="28"
            className="p-0 text-xs mt-2 mr-1"
            onClick={async () => {
              if (
                juniClubId &&
                member?.userCategory &&
                member?.clientUUID &&
                membershipStatus
              ) {
                setIsLoading(true);
                await updateMembershipStatusMutation({
                  variables: {
                    juniClubId,
                    studentId,
                    instructorUserId,
                    memberUserCategory: member.userCategory,
                    memberClientUUID: member.clientUUID,
                    newMembershipStatus: membershipStatus,
                  },
                });
                setIsLoading(false);
              }
            }}
            disabled={member.badgeInfo.name === name}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
            ) : (
              `Make ${displayName}`
            )}
          </Button>
        ))}
      </div>
    </ClubMembersListRowWrapper>
  );
};

const ClubMembersListModal: FC<ClubMembersListModalProps> = ({
  clubMembers,
  isOpen,
  closeModal,
  juniClub,
  currentClubMemberState,
  studentId,
  instructorUserId,
}) => {
  const sortedClubMembers: JuniClubMembershipInfoPlusBadgeType[] = (
    clubMembers || []
  ).sort((a, b) => {
    const aName = `${a.firstName}${a.lastInitial}`.toLowerCase();
    const bName = `${b.firstName}${b.lastInitial}`.toLowerCase();
    return aName < bName ? -1 : 1;
  });

  return (
    <ModalWindow
      bannerImgSrc={juniClub.coverPhoto || undefined}
      title={juniClub.displayName}
      isOpen={isOpen}
      closeModal={() => closeModal(false)}
      labelText={`${sortedClubMembers?.length} member${
        sortedClubMembers?.length !== 1 ? 's' : ''
      }`}
    >
      <div>
        {sortedClubMembers.length > 0
          ? sortedClubMembers.map(member => (
              <ClubMembersListRow
                key={member._id}
                currentClubMemberState={currentClubMemberState}
                member={member}
                studentId={studentId}
                instructorUserId={instructorUserId}
                juniClubId={juniClub._id}
              />
            ))
          : 'There are currently no members in this club!'}
      </div>
    </ModalWindow>
  );
};
export default ClubMembersListModal;
