import React, { FC } from 'react';
import classNames from 'classnames';

import { Icon } from 'core-components';

export const ALL_STATUSES = ['success', 'error', 'warning', 'info', 'dark'] as const;
type Status = typeof ALL_STATUSES[number]; // union type

export interface MessageProps {
  /**
   * Child elements that this component can wrap, usually containing the message text.
   */
  children?: React.ReactNode;
  /**
   * Specific type of message to display.
   */
  status: Status;
  /**
   * Optional prop enabling additional styling.
   */
  className?: string;
  /**
   * Determines if container should stretch beyond content width.
   */
  fullWidth?: boolean;
  /**
   * Applies an outline to the container box.
   * Default false.
   */
  outline?: boolean;
  /**
   * Optional secondary text that displays below the primary message.
   */
  description?: string;
}

type StatusMap = { [status in Status]: string };

const statusStyles: StatusMap = {
  success: 'bg-j-green-100 text-j-green-700',
  error: 'bg-j-pink-100 text-j-pink-700',
  warning: 'bg-j-yellow-100 text-j-yellow-500',
  info: 'bg-j-blue-100 text-j-blue-600',
  dark: 'bg-j-dark-700 text-j-dark-200',
};

const borderColors: StatusMap = {
  success: 'border-j-green-700',
  error: 'border-j-pink-700',
  warning: 'border-j-yellow-500',
  info: 'border-j-blue-600',
  dark: 'border-j-dark-800',
};

function getIcon(status: Status) {
  switch (status) {
    case 'error':
      return <Icon.Error />;
    case 'success':
      return <Icon.Success />;
    case 'warning':
      return <Icon.Warning />;
    case 'info':
      return <Icon.Info />;
    case 'dark':
      return <Icon.Info />;
    default:
      return null;
  }
}
/**
 * Component that displays messages, often dependent on results
 * from API calls.
 */
const Message: FC<MessageProps> = ({
  children,
  className,
  fullWidth = true,
  status,
  outline = false,
  description,
}) => (
  <div
    className={classNames(
      {
        'flex p-3 pr-4 rounded-lg': true,
        'inline-flex': !fullWidth,
        [statusStyles[status]]: true,
        'border border-solid': outline,
        [borderColors[status]]: outline,
        'w-full': fullWidth,
      },
      'box-border',
      className,
    )}
  >
    {/* h-5 (1.25rem) is equal to the line height of the children text for icon centering */}
    <div className="h-5 flex items-center justify-center">{getIcon(status)}</div>
    <div className="ml-2 font-graphik text-sm w-full">
      {children}
      {!!description && <div className="mt-1 text-j-dark-400">{description}</div>}
    </div>
  </div>
);

export default Message;
