import { FC } from 'react';

const Footer: FC = () => (
  <div className="flex items-center py-4 pl-4 md:pl-20 lg:pl-40 mt-auto bg-juni-dark-700 text-white">
    <span className="w-4/5">
      Copyright &#169; {new Date().getFullYear()} Juni Learning. All rights reserved.
    </span>
  </div>
);

export default Footer;
