export const iconSize = (size: number) => ({ height: size, width: size });

const dropdownDefaultStyle: Record<string, number | string> = {
  top: 35,
  width: 260,
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 12,
  paddingRight: 12,
  boxSizing: 'border-box',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 60px)',
};
const dropdownMobileStyle: Record<string, number | string> = {
  ...dropdownDefaultStyle,
  position: 'fixed',
  top: 50,
  right: 10,
  paddingBottom: 48,
  width: 'calc(100vw - 20px)',
};
export const dropdownStyle = (
  navIsMobile?: boolean | null,
  isUserDropdown?: boolean | null,
) =>
  navIsMobile
    ? dropdownMobileStyle
    : { ...dropdownDefaultStyle, right: isUserDropdown ? 0 : 'auto' };

// add updated icons
