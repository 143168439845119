import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getInitNavIdLookupPublic } from 'services/courses';

import './juni_ide_wrapper_public.css';
import { JuniIDE } from 'components/jide';
import { COURSE_TYPES } from 'constants/subjects';

const JuniIDEWrapperPublic = ({
  initNav,
  setJuniverseEnvType,
  playgroundProject,
  juniverseProjectData,
  setHideNavBar,
  encryptedStudentAndProject,
  isCodeHidden,
}) => {
  const [isLoadingIdLookup, setIsLoadingIdLookup] = useState(true);
  const [idLookup, setIdLookup] = useState({});

  const { createdByUserType } = juniverseProjectData;
  useEffect(() => {
    async function fetchData() {
      const idLookup = await getInitNavIdLookupPublic(initNav);
      setIdLookup(idLookup);
      setIsLoadingIdLookup(false);
    }
    fetchData();
  }, [initNav]);

  return (
    <div className="jide-wrapper-public">
      <JuniIDE
        jideUser={{ type: 'public', createdByUserType }}
        juniverseProjectData={juniverseProjectData}
        initNav={initNav}
        courseTypes={[COURSE_TYPES.COMPUTER_SCIENCE, COURSE_TYPES.MATHEMATICS]}
        idLookup={idLookup}
        isLoadingIdLookup={isLoadingIdLookup}
        modalViewMode
        setJuniverseEnvType={setJuniverseEnvType}
        playgroundProject={playgroundProject}
        setHideNavBar={setHideNavBar}
        encryptedStudentAndProject={encryptedStudentAndProject}
        isCodeHidden={isCodeHidden}
      />
    </div>
  );
};
JuniIDEWrapperPublic.defaultProps = {
  initNav: {},
};
JuniIDEWrapperPublic.propTypes = {
  setJuniverseEnvType: PropTypes.func,
  initNav: PropTypes.shape({
    student: PropTypes.string.isRequired,
    course: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
  }),
  encryptedStudentAndProject: PropTypes.string,
  isCodeHidden: PropTypes.bool,
};

export default JuniIDEWrapperPublic;
