import React from 'react';
import { Switch, Route } from 'react-router-dom';

import App from 'app/App';

import { ForgotPassword, Logout, ResetPassword } from 'authentication';
import { DCF_DEFAULT_ENTRY_POINT } from 'constants/links';

const Routes = () => (
  <Switch>
    <Route path="/logout" render={props => <Logout {...props} />} />
    <Route path="/forgot_password" render={props => <ForgotPassword {...props} />} />
    <Route path="/reset_password" render={props => <ResetPassword {...props} />} />
    <Route
      path="/enroll"
      render={() => {
        window.location.href = DCF_DEFAULT_ENTRY_POINT;
      }}
    />
    <Route path="/" render={props => <App {...props} />} />
  </Switch>
);

export default Routes;
