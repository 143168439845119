import React, { Component } from 'react';
import juniAxios from 'services/axios';

import { Button } from 'core-components';
import AuthLayout from '../AuthLayout';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

class ForgotPassword extends Component {
  state = {
    email: '',
    errorMessage: '',
    showConfirmation: false,
    newUser: undefined,
  };
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.email) {
      this.setState({ errorMessage: 'Please enter an email address.' });
      return;
    }

    this.setState({ errorMessage: '' });

    juniAxios
      .post(
        `/auth/request_password_reset`,
        {
          email: this.state.email,
        },
        config,
      )
      .then(res => {
        this.setState({ showConfirmation: true, newUser: res?.data?.newUser });
      })
      .catch(err => {
        console.log(err.response);
        this.setState({ errorMessage: err.response.data.message });
      });
  }
  render() {
    return (
      <AuthLayout>
        <div className="forgot-password-view">
          {this.state.showConfirmation ? (
            <form>
              <div className="form-title">
                {this.state.newUser ? 'Set your Password' : 'Forgot Password?'}
              </div>
              <div className="form-description">
                We've sent an email to {this.state.email} with a link to{' '}
                {this.state.newUser ? 'set up' : 'reset'} your password.
              </div>
            </form>
          ) : (
            <div className="auth-form" id="login">
              <h1>Reset your password</h1>
              <hr />
              <form onSubmit={e => this.handleSubmit(e)}>
                {this.state.errorMessage ? (
                  <div className="form-error-wrap">
                    <div className="form-error">{this.state.errorMessage}</div>
                  </div>
                ) : null}
                <div
                  className={`form-field${this.state.errorMessage ? ' error' : ''}`}
                >
                  <div className="form-field-icon">
                    <span className="icon-mail" />
                  </div>
                  <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={e => this.handleChange(e)}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="btn-group">
                  <div />
                  <Button variant="pink" type="submit" hasArrowIcon>
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </AuthLayout>
    );
  }
}

export default ForgotPassword;
