import React from 'react';
import styled from 'styled-components/macro';
import { AttentionIcon, Arrow, XIcon } from 'components/Icons';

const WarningContainer = styled.div`
  padding: 0.5rem;
  background: white;
  flex: 1 1;
  * {
    font-size: 0.875rem;
    box-sizing: border-box;
  }

  .inner__content {
    flex-direction: column;
    border: 2px solid #ffe0e0;
    background: #fff3f3;
    border-radius: 10px;
    padding: 0.75rem;
    & .alert__container {
      display: flex;
      .icon {
        background: rgba(187, 34, 34, 0.05);
        width: 4rem;
        height: 4rem;
        padding: 3rem;
        border-radius: 0.5rem;
        margin: 0.5rem auto 1rem;
        border-radius: 0.5rem;
        margin: 0 auto 1rem;

        & path {
          fill: #bb2222;
        }
      }
    }
    & .header {
      text-align: center;
      color: #bb2222;
      font-weight: 700;
      max-width: 270px;
      width: initial;
      margin: 0 auto;
      white-space: normal;
    }

    & ul {
      color: #8e4747;
      width: 80%;
      margin: 0.5rem 0 1rem;
      list-style-type: none;
      text-align: center;
      padding: 0;
      & li {
        font-family: 'Open Sans';
        font-weight: 600;
      }
    }
  }

  .warning__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const WarningButtonsContainer = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 0.5rem !important;
  }
  & > :last-child {
    margin-left: 0.5rem;
  }
  & > * {
    margin: 0;
  }
  & button {
    flex-basis: 50%;
    margin: initial;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
    letter-spacing: 0;
    font-weight: bold;
    text-transform: capitalize;
    align-items: center;
    font-family: 'Open Sans';

    & span {
      text-align: center;
      width: 100%;
    }
  }

  & .warning-button__icons {
    width: 0.875rem;
    height: 0.875rem;
    padding: 0.8rem;
    border-radius: 6px;
    align-self: flex-end;
  }

  & button#cancel {
    background: white;
    color: #00a5ff;
    border: 2px solid #c9eeff;

    & .icon {
      background: rgba(0, 165, 255, 0.15);

      & path {
        stroke: #00a5ff;
      }
    }
  }

  & button#continue {
    background: #bb2222;
    border: 2px solid #e25959;
    color: white;

    & .icon {
      background: #a50f0f;
      & path {
        fill: white;
      }
    }
  }
`;

const WarningBlock: React.FC<{
  cancelWarning: () => void;
  completeWarning: () => void;
  headerText: string;
  subHeaderText: string;
  primaryActionText: string;
}> = ({
  cancelWarning,
  completeWarning,
  headerText,
  subHeaderText,
  primaryActionText,
}) => (
  <WarningContainer>
    <div className="inner__content">
      <div className="alert__container">
        <AttentionIcon />
      </div>
      <div className="warning__content">
        <h3 className="header">{headerText}</h3>
        <ul>
          <li>{subHeaderText}</li>
        </ul>
      </div>
      <WarningButtonsContainer>
        <button id="cancel" onClick={cancelWarning}>
          <span>Cancel</span> <XIcon className="warning-button__icons" />
        </button>
        <button id="continue" onClick={completeWarning}>
          <span>{primaryActionText}</span>{' '}
          <Arrow className="warning-button__icons" orientation="right" />
        </button>
      </WarningButtonsContainer>
    </div>
  </WarningContainer>
);

export default WarningBlock;
