import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Message, NewButton as Button } from 'core-components';
import CopyButtonV2 from 'components/CopyButtonV2';
import {
  INDIRECT_SHARE_SOCIAL_NETWORKS,
  SocialShareMethods,
  SOCIAL_SHARE_METHOD_TO_RENDER_INFO,
} from 'utils/social_share/social_share';

interface JideSocialShareProps {
  shareLink: string;
  shareContent: string;
  shareCopy: string;
}
interface JideSocialIconButtonProps {
  onClick: () => void;
  isIndirectlyShared: boolean;
  shareLink: string;
}
interface CopyWrapperProps {
  textContent: string;
}

const DefaultButtonWrapper = styled.div.attrs({ className: 'flex w-full h-full' })``;

const CopyWrapper: React.FC<CopyWrapperProps> = ({ textContent, children }) => (
  <CopyToClipboard text={textContent}>{children}</CopyToClipboard>
);

const JideSocialIconButton: React.FC<JideSocialIconButtonProps> = ({
  onClick,
  isIndirectlyShared,
  shareLink,
  children,
}) => {
  const Wrapper = isIndirectlyShared ? CopyWrapper : DefaultButtonWrapper;

  // TODO: update default text content
  const wrapperProps = { textContent: shareLink };

  return (
    <Wrapper {...wrapperProps}>
      <Button icon variant="secondary" size="small" fullWidth onClick={onClick}>
        {children}
      </Button>
    </Wrapper>
  );
};

const JideSocialShare: React.FC<JideSocialShareProps> = ({
  shareLink,
  shareCopy,
  shareContent,
}) => {
  const [copiedMessage, setCopiedMessage] = useState('');

  useEffect(() => {
    let mounted = true;
    if (copiedMessage) {
      setTimeout(() => {
        if (mounted) setCopiedMessage('');
      }, 10000);
    }
    return () => {
      mounted = false;
    };
  }, [copiedMessage]);

  return (
    <div className="bg-white flex flex-col font-graphik py-4">
      <div className="uppercase text-j-dark-600 mb-6 text-xs font-medium">
        Share with your family and friends
      </div>
      {copiedMessage && (
        <div className="mb-3">
          <Message status="success">{copiedMessage}</Message>
        </div>
      )}
      <div className="flex justify-between items-center box-border">
        <div className="flex">
          {Object.keys(SOCIAL_SHARE_METHOD_TO_RENDER_INFO).map(socialMethod => {
            const socialInfo =
              SOCIAL_SHARE_METHOD_TO_RENDER_INFO[socialMethod as SocialShareMethods];

            const isIndirectlyShared = INDIRECT_SHARE_SOCIAL_NETWORKS.includes(
              socialMethod,
            );

            const onClickHandler = () => {
              if (copiedMessage && !isIndirectlyShared) {
                setCopiedMessage('');
              } else if (isIndirectlyShared) {
                setCopiedMessage(
                  `Link Copied! ${socialInfo.displayName} will open in a new tab.`,
                );
              }
              setTimeout(
                () =>
                  socialInfo.handleShare({
                    url: `${socialInfo.url}${
                      socialMethod === 'iMessage'
                        ? `&body=Check out this project I made on Juni! ${shareLink}`
                        : ''
                    }`,
                    shareContent,
                    socialShareCopy: `${shareCopy} ${shareLink}`,
                  }),
                isIndirectlyShared ? 1000 : 0,
              );
            };

            return (
              <div key={socialMethod} className="mr-4 flex w-10 h-10">
                <JideSocialIconButton
                  onClick={onClickHandler}
                  isIndirectlyShared={isIndirectlyShared}
                  shareLink={shareLink}
                >
                  <socialInfo.icon className="text-j-purple-600" />
                </JideSocialIconButton>
              </div>
            );
          })}
        </div>
        <CopyButtonV2
          textContent={shareLink}
          displayText="Copy Link"
          onCopyDisplayText="Link Copied!"
        />
      </div>
    </div>
  );
};

export default JideSocialShare;
