import React from 'react';

const JuniIconCircle = () => (
  <svg
    style={{ display: 'block' }}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1000 1000"
  >
    <defs>
      <path
        id="prefix__a"
        d="M364.51 18.58C154.21 77.66.01 270.84.01 500.01c0 194.07 110.59 362.33 272.17 445.19h455.63c161.58-82.86 272.17-251.12 272.17-445.19 0-229.17-154.2-422.35-364.5-481.43H364.51z"
      />
    </defs>
    <clipPath id="prefix__b">
      <use xlinkHref="#prefix__a" overflow="visible" />
    </clipPath>
    <path
      d="M500.01 500.01C500.01 223.89 723.85 0 999.99 0s499.99 223.89 499.99 500.01S1276.13 1000 999.99 1000 500.01 776.13 500.01 500.01"
      clipPath="url(#prefix__b)"
      fill="#6256a5"
    />
    <g>
      <defs>
        <path
          id="prefix__c"
          d="M364.51 18.58C154.21 77.66.01 270.84.01 500.01c0 194.07 110.59 362.33 272.17 445.19h455.63c161.58-82.86 272.17-251.12 272.17-445.19 0-229.17-154.2-422.35-364.5-481.43H364.51z"
        />
      </defs>
      <clipPath id="prefix__d">
        <use xlinkHref="#prefix__c" overflow="visible" />
      </clipPath>
      <path
        d="M-499.99 500.01C-499.99 223.89-276.14 0 .01 0c276.14 0 500 223.89 500 500.01S276.15 1000 .01 1000c-276.15 0-500-223.87-500-499.99"
        clipPath="url(#prefix__d)"
        fill="#292763"
      />
    </g>
  </svg>
);
export default JuniIconCircle;
