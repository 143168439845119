import React, { useState } from 'react';
import { TextSetFragment } from 'generated/graphql';
import classNames from 'classnames';
import { NewButton } from 'core-components';
import SelectionBox from '../SelectionBox';

interface SelectPassageProps {
  referenceTextSets: TextSetFragment[];
  handleSelectPassage: (id: string) => Promise<void>;
  submitDisabled?: boolean;
}

const SelectPassage: React.FC<SelectPassageProps> = ({
  referenceTextSets,
  handleSelectPassage,
  submitDisabled = false,
}) => {
  const [selectedTextSetId, setSelectedTextSetId] = useState('');

  return (
    <>
      {referenceTextSets.map((referenceTextSet: TextSetFragment, index) => (
        <SelectionBox
          key={referenceTextSet._id}
          selected={selectedTextSetId === referenceTextSet._id}
          onClick={() => setSelectedTextSetId(referenceTextSet._id)}
          className={classNames({ 'mt-4': index > 0 })}
          title={referenceTextSet.title}
        >
          {referenceTextSet.title}
        </SelectionBox>
      ))}
      <div className="mt-6">
        <NewButton
          disabled={submitDisabled || !selectedTextSetId}
          onClick={() => handleSelectPassage(selectedTextSetId)}
        >
          Select Passage
        </NewButton>
      </div>
    </>
  );
};

export default SelectPassage;
