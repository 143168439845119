import styled from 'styled-components/macro';

// Override the styles applied to all learner-app paragraphs.

const ParagraphText = styled.div`
  p {
    margin: 1rem 0 !important;
    line-height: 1.5 !important;
  }
`;

export default ParagraphText;
