import React, { FC } from 'react';
import { blueGray, juniPurple } from 'theme/old-colors';

const ACTIVE_COLOR = juniPurple[500];
const INACTIVE_COLOR = blueGray[500];

const CalendarIcon: FC<{ filled?: boolean; columns?: '1' | '2' }> = ({
  filled = false,
  columns = '1',
}) => {
  const primaryColor = filled ? ACTIVE_COLOR : INACTIVE_COLOR;

  if (columns === '1') {
    return (
      <svg
        width="36"
        height="33"
        viewBox="0 0 36 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="3" width="35" height="29" rx="1.5" stroke={primaryColor} />
        <rect x="4" y="10.5" width="4" height="3" fill={primaryColor} />
        <rect x="4" y="15.5" width="4" height="3" fill={primaryColor} />
        <rect x="4" y="20.5" width="4" height="3" fill={primaryColor} />
        <rect x="4" y="25.5" width="4" height="3" fill={primaryColor} />
        <rect x="10" y="10.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="10" y="15.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="10" y="20.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="10" y="25.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="16" y="10.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="16" y="15.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="16" y="20.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="16" y="25.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="22" y="10.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="22" y="15.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="22" y="20.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="22" y="25.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="28" y="10.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="28" y="15.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="28" y="20.5" width="4" height="3" fill={blueGray[200]} />
        <rect x="28" y="25.5" width="4" height="3" fill={blueGray[200]} />
        <path
          d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H34C35.1046 0.5 36 1.39543 36 2.5V7.5H0V2.5Z"
          fill={primaryColor}
        />
        <path
          d="M7 0.5H11V2.5C11 3.60457 10.1046 4.5 9 4.5C7.89543 4.5 7 3.60457 7 2.5V0.5Z"
          fill="white"
        />
        <path
          d="M25 0.5H29V2.5C29 3.60457 28.1046 4.5 27 4.5C25.8954 4.5 25 3.60457 25 2.5V0.5Z"
          fill="white"
        />
      </svg>
    );
  }

  return (
    <svg
      width="36"
      height="33"
      viewBox="0 0 36 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="3" width="35" height="29" rx="1.5" stroke={primaryColor} />
      <rect x="4" y="10.5" width="4" height="3" fill={primaryColor} />
      <rect x="4" y="15.5" width="4" height="3" fill={primaryColor} />
      <rect x="4" y="20.5" width="4" height="3" fill={primaryColor} />
      <rect x="4" y="25.5" width="4" height="3" fill={primaryColor} />
      <rect x="10" y="10.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="10" y="15.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="10" y="20.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="10" y="25.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="16" y="10.5" width="4" height="3" fill={primaryColor} />
      <rect x="16" y="15.5" width="4" height="3" fill={primaryColor} />
      <rect x="16" y="20.5" width="4" height="3" fill={primaryColor} />
      <rect x="16" y="25.5" width="4" height="3" fill={primaryColor} />
      <rect x="22" y="10.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="22" y="15.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="22" y="20.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="22" y="25.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="28" y="10.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="28" y="15.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="28" y="20.5" width="4" height="3" fill="#CFD8DC" />
      <rect x="28" y="25.5" width="4" height="3" fill="#CFD8DC" />
      <path
        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H34C35.1046 0.5 36 1.39543 36 2.5V7.5H0V2.5Z"
        fill={primaryColor}
      />
      <path
        d="M7 0.5H11V2.5C11 3.60457 10.1046 4.5 9 4.5C7.89543 4.5 7 3.60457 7 2.5V0.5Z"
        fill="white"
      />
      <path
        d="M25 0.5H29V2.5C29 3.60457 28.1046 4.5 27 4.5C25.8954 4.5 25 3.60457 25 2.5V0.5Z"
        fill="white"
      />
    </svg>
  );
};

export default CalendarIcon;
