import V1Widget from './LearnerClassSchedulerWidget';
import V2Widget from './LearnerClassSchedulerWidgetV2';

export type { LearnerClassSchedulerWidgetProps } from './LearnerClassSchedulerWidget';

// The interfaces for these two widgets has diverged enough that they should no
// longer be treated as interchangeable.
// There may be a better syntax for doing this, but I'm not so sure, in
// general, I think I prefer named exports over default exports.
export const LearnerClassSchedulerWidget = V1Widget;
export const LearnerClassSchedulerWidgetV2 = V2Widget;
