// Functions for syntactic validation of user input.
// IF CHANGING: update server/services/moderation/input.js on juni-app-backend.

const MAX_CHARACTERS_CLUB_MESSAGE = 1200;

interface LengthCheckInput {
  input: string;
  inputDisplayName: string;
  inputDisplayNameArticle?: 'a' | 'an' | 'the';
  min: number;
  max: number;
}

const getLengthErrorMessage = ({
  input,
  inputDisplayName,
  inputDisplayNameArticle = 'a',
  min,
  max,
}: LengthCheckInput) => {
  if (!input) {
    return `Please enter ${inputDisplayNameArticle} ${inputDisplayName}.`;
  }
  if (input.length < min) {
    return `The ${inputDisplayName} you entered is too short.`;
  }
  if (input.length > max) {
    return `The ${inputDisplayName} you entered is too long.`;
  }
  return null;
};

const validateUsername = (username: string) => {
  const inputDisplayName = 'username';
  const lengthErrorMessage = getLengthErrorMessage({
    input: username,
    inputDisplayName,
    min: 4,
    max: 20,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  if (!username.match(/^[a-zA-Z0-9_]*$/g)) {
    return 'Only numbers, letters, and underscores allowed.';
  }
  return null;
};

const validateJuniverseProjectTitle = (title: string) => {
  const inputDisplayName = 'title';
  const lengthErrorMessage = getLengthErrorMessage({
    input: title,
    inputDisplayName,
    min: 4,
    max: 30,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  if (!title.match(/^[a-zA-Z0-9 ]*$/g)) {
    return 'Only numbers, letters, and spaces allowed.';
  }
  return null;
};

const validateCustomProjectName = (name: string) => {
  const inputDisplayName = 'project name';
  const lengthErrorMessage = getLengthErrorMessage({
    input: name,
    inputDisplayName,
    min: 1,
    max: 100,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  if (!name.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
    return `Your project name can only contain letters (and make sure it doesn't start with a space)!`;
  }
  return null;
};

const validateClubName = (clubName: string) => {
  const inputDisplayName = 'club name';
  const lengthErrorMessage = getLengthErrorMessage({
    input: clubName,
    inputDisplayName,
    min: 4,
    max: 50,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  return null;
};

const validateClubDescription = (clubDescription: string) => {
  const inputDisplayName = 'club description';
  const lengthErrorMessage = getLengthErrorMessage({
    input: clubDescription,
    inputDisplayName,
    min: 4,
    max: 2000,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  return null;
};

const validateChannelName = (channelName: string) => {
  const inputDisplayName = 'channel name';
  const lengthErrorMessage = getLengthErrorMessage({
    input: channelName,
    inputDisplayName,
    min: 4,
    max: 20,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  // Note: PubNub only allows certain characters in channel names.
  // https://www.pubnub.com/docs/channels/overview#channel-names
  if (!channelName.match(/^[a-z0-9-_]*$/g)) {
    return 'Only numbers, lowercase letters, dashes, and underscores allowed.';
  }
  return null;
};

const validateClubMessage = (clubMessage: string) => {
  const inputDisplayName = 'message';
  const lengthErrorMessage = getLengthErrorMessage({
    input: clubMessage,
    inputDisplayName,
    min: 1,
    max: MAX_CHARACTERS_CLUB_MESSAGE,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  return null;
};

const validateEventTitle = (eventTitle: string) => {
  const lengthErrorMessage = getLengthErrorMessage({
    input: eventTitle,
    inputDisplayName: 'title',
    min: 4,
    max: 100,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  return null;
};

const validateEventDescription = (eventDescription: string) => {
  const lengthErrorMessage = getLengthErrorMessage({
    input: eventDescription,
    inputDisplayName: 'description',
    min: 4,
    max: 1200,
  });
  if (lengthErrorMessage) {
    return lengthErrorMessage;
  }
  return null;
};

const validateEventLink = (eventLink: string) => {
  const linkRegex = /^(http:\/\/|https:\/\/)/;
  if (!eventLink.match(linkRegex)) {
    return 'You must include http:// or https:// in your link.';
  }
  return null;
};

export {
  validateUsername,
  validateJuniverseProjectTitle,
  validateCustomProjectName,
  validateClubName,
  validateClubDescription,
  validateChannelName,
  validateClubMessage,
  validateEventTitle,
  validateEventDescription,
  validateEventLink,
  MAX_CHARACTERS_CLUB_MESSAGE,
};
