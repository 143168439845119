import React, { FC } from 'react';
import moment from 'moment';
import {
  isTimeTurnerStageEnabled,
  TimeTurnerStage,
} from 'app/feature_flags/time-turner-deploy-stage-flags';

import { AcuityAppointment } from 'models';

import {
  ACUITY_TYPE_TO_TEXT,
  ACUITY_APPOINTMENT_TYPES,
  ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS,
  ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
} from 'constants/acuity';
import { getLearnerSessionActionType } from 'utils/acuity';

import JoinSessionButton from 'components/JoinSessionButton';

import classNames from 'classnames';
import Button from 'core-components/NewButton';
import { Message } from 'core-components';
import { ScheduleButtonContainer } from './styles';
import { ScheduleCardRowProps, isAdditionalClass, isClassPack } from './types';
import SchedulePanel from './SchedulePanel';

const DATE_FORMAT = 'ddd, MMM DD [at] h:mm a z';

interface FlexProps {
  className?: string;
  children?: React.ReactNode;
}

const Flex: FC<FlexProps> = ({ children, className }) => (
  <div className={classNames('flex flex-col sm:flex-row', className)}>
    {children}
  </div>
);

export const ScheduleCardRow = (props: ScheduleCardRowProps) => {
  const { type, session, student, parent, instructorName, onClickSchedule } = props;

  const getSessionActionItem = (
    actionType: string | null,
    session: AcuityAppointment,
  ) => {
    const acuityAppt = session;
    switch (actionType) {
      case 'group-upcoming':
        return (
          <Message status="info">
            Contact us to reschedule semi-private classes!
          </Message>
        );
      case 'upcoming':
        return isTimeTurnerStageEnabled(TimeTurnerStage.RespectAvailability) ? (
          <Button
            variant="secondary"
            onClick={() => onClickSchedule && onClickSchedule(acuityAppt.id)}
          >
            <div className="font-medium">Reschedule</div>
          </Button>
        ) : (
          <Button variant="secondary" href={acuityAppt.confirmationPage} fullWidth>
            <div className="font-medium">Reschedule</div>
          </Button>
        );
      case 'upcoming-imminent':
        return <Message status="info">Your class is coming up!</Message>;
      case 'joinable':
        return (
          <ScheduleButtonContainer>
            <JoinSessionButton
              session={acuityAppt}
              isTrial={ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS.includes(
                acuityAppt.appointmentTypeID,
              )}
            />
          </ScheduleButtonContainer>
        );
      default:
        return null;
    }
  };

  let header: React.ReactNode;
  let subheader: React.ReactNode;
  let actionItem: React.ReactNode;

  switch (type) {
    case 'upcoming':
      if (student && parent) {
        const acuityAppt = session as AcuityAppointment;
        const classType = ACUITY_TYPE_TO_TEXT[acuityAppt?.appointmentTypeID];
        const classDatetime = moment(acuityAppt?.datetime)
          .tz(acuityAppt.timezone)
          .format(DATE_FORMAT);
        header = (
          <div className={classNames('text-j-dark-600 font-medium', 'mb-2')}>
            {classDatetime}
          </div>
        );
        subheader = (
          <Flex>
            <div className="text-j-dark-600">{classType}</div>{' '}
            <div className="text-j-dark-300 sm:ml-1">{`with ${instructorName}`}</div>
          </Flex>
        );

        const sessionActionType = getLearnerSessionActionType(
          acuityAppt,
          student?.firstName,
          parent?.hasMultipleChildren,
        );
        const sessionActionButton =
          [
            ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
            ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
          ].includes(acuityAppt.appointmentTypeID) &&
          sessionActionType !== 'joinable'
            ? null
            : getSessionActionItem(sessionActionType, acuityAppt);
        actionItem = sessionActionButton;
      }
      break;
    case 'makeup':
      if (isAdditionalClass(session!)) {
        const classType = ACUITY_TYPE_TO_TEXT[session.appointmentTypeID];
        const prevClassDate = moment(session?.originalClassDatetime)
          .tz(session?.originalClassTimezone)
          .format(DATE_FORMAT);
        header = (
          <Flex>
            <div className="text-j-dark-600">{classType}</div>
            <div className="sm:ml-1 text-j-dark-300">{`with ${instructorName}`}</div>
          </Flex>
        );
        subheader = (
          <div className="flex flex-col sm:flex-row">
            <div className="text-j-dark-600">Prev Class Date:</div>
            <div className="sm:ml-1 text-j-dark-300">{prevClassDate}</div>
          </div>
        );
        actionItem = (
          <Button
            variant="secondary"
            onClick={() => onClickSchedule && onClickSchedule(session._id)}
            fullWidth
          >
            <div className="font-medium">Schedule</div>
          </Button>
        );
      }
      break;
    case 'class_pack':
      if (isClassPack(session!)) {
        const classType = ACUITY_TYPE_TO_TEXT[session[0].appointmentTypeID];
        header = <div className="text-j-dark-600">{classType}</div>;
        subheader = (
          <div className="text-j-dark-300">{`${session.length} available`}</div>
        );
        actionItem = (
          <Button
            variant="secondary"
            onClick={() => onClickSchedule && onClickSchedule(session[0]._id)}
            fullWidth
          >
            <div className="font-medium">Schedule</div>
          </Button>
        );
      }
      break;
    case 'advisor_checkin':
      header = (
        <Flex>
          <div className="text-j-dark-600">Parent Advisor Check-in</div>
        </Flex>
      );
      actionItem = (
        <Button
          variant="secondary"
          onClick={() => onClickSchedule && onClickSchedule(parent?.userId || '')}
          fullWidth
        >
          <div className="font-medium">Schedule</div>
        </Button>
      );
      break;
    default:
      break;
  }
  return (
    <SchedulePanel
      className="mb-3 last:mb-0"
      renderHeader={() => header}
      renderSubheader={() => subheader}
      renderActionItem={() => actionItem}
    />
  );
};

export default ScheduleCardRow;
