/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';

import CopyButton from 'components/CopyButton';
import { Arrow } from 'components/Icons';
import styled from 'styled-components/macro';
import ScratchEmbed from './ScratchEmbed';

const ProjectPreviewContainer = styled.div`
  #scratch-embed-wrap {
    margin-bottom: 1rem;
    padding-bottom: 80%;
    height: 0;
  }
  & .span__container {
    margin: 1.2rem 0;

    & .span {
      color: #7c9eb3;
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      margin-right: 1em;

      &:after {
        content: '';
        flex: 1 1;
        border-top: 1.25px solid #cee6f3;
        margin: auto auto auto 1rem;
      }
    }
  }
`;

const parseInstructions = instructions =>
  instructions.split('\n').filter(item => item.length);

const UnstartedScratchProject = ({ currentProject, isActive }) => {
  const videoElement = useRef(null);
  const { properties } = currentProject || {};
  const { instructions, projectLink, videoLink } = properties || {};

  const videoType = videoLink ? videoLink.substr(videoLink.length - 3) : '';
  const validVideoType = videoType === 'mp4';

  const renderedProjectLink =
    projectLink || 'https://scratch.mit.edu/projects/editor';

  const parsedInstructions = parseInstructions(instructions || '');

  useEffect(() => {
    if (!isActive && validVideoType) videoElement.current.pause();
  }, [isActive, validVideoType]);

  return (
    <>
      <div className="scratch-env-option">
        <div className="unstarted-scratch-project">
          {validVideoType && (
            <div className="unstarted-scratch-video">
              <video ref={videoElement} controls>
                <source type="video/mp4" src={videoLink} />
              </video>
            </div>
          )}
          <div
            className={`unstarted-scratch-link ${validVideoType ? '' : 'no-video'}`}
          >
            <div className="unstarted-scratch-desc">
              <h3>Start this project</h3>
              <p>
                Start this project in Scratch by clicking the link below. Remember to{' '}
                <strong>Remix</strong> the project to save it to your own account!
                {!projectLink
                  ? ' If the link takes you to a blank Scratch project, that means you get to build the project from a blank project!'
                  : ''}
              </p>
            </div>
            <div className="scratch-link-container">
              <div className="scratch-env-link">
                <div className="link-text">{renderedProjectLink}</div>
                <CopyButton text={renderedProjectLink} />
              </div>
              <a
                href={renderedProjectLink}
                target="_blank"
                rel="noopener noreferrer"
                className="scratch-env-action-button"
              >
                <span>Start project in Scratch</span>
                <Arrow orientation="right" />
              </a>
            </div>
          </div>
        </div>
        {!!parsedInstructions.length && (
          <div className="jide-scratch-instructions">
            <h3>Instructions</h3>
            {parsedInstructions.map((paragraph, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        )}
      </div>

      {currentProject.properties?.solutionLink && (
        <ProjectPreviewContainer>
          <div className="span__container">
            <small className="span">Interact with the sample solution</small>
          </div>
          <div id="scratch-embed-wrap">
            <ScratchEmbed
              displayName={currentProject.displayName}
              projectLink={currentProject.properties.solutionLink}
            />
          </div>
        </ProjectPreviewContainer>
      )}
    </>
  );
};

export default UnstartedScratchProject;
