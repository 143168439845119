import React, { FC } from 'react';

import { PlaygroundIcon } from 'components/Icons';
import { NavLeftButtonLink } from './NavLeftButton';
import { NavLeftButtonProps } from './types';

interface PlaygroundButtonProps extends NavLeftButtonProps {
  baseRoute: string;
}

const PlaygroundButton: FC<PlaygroundButtonProps> = ({ baseRoute, pathname }) => {
  const active = pathname.includes(`${baseRoute}/playground`);

  return (
    <NavLeftButtonLink
      active={active}
      icon={<PlaygroundIcon active={active} />}
      link={`${baseRoute}/playground`}
      text="Playground"
    />
  );
};

export default PlaygroundButton;
