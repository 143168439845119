import React from 'react';
import { Icon, NewButton as Button } from 'core-components';
import { DCF_DEFAULT_ENTRY_POINT } from 'constants/links';

const JuniverseFooter: React.FC = () => (
  <footer className="bg-j-dark-600 py-20">
    <div className="max-w-7xl m-auto px-6 flex justify-between flex-col md:flex-row">
      <div
        className="w-full text-center md:text-left m-auto md:m-0"
        style={{ maxWidth: '680px' }}
      >
        <h2 className="text-white font-normal text-xl leading-8 m-0">
          What is Juni Learning?
        </h2>
        <p className="text-white text-sm m-0 pt-4 pb-8 text-center md:text-left">
          Juni Learning is an online learning experience for kids 7-18, preparing
          them for the real world. Explore 1:1 courses in coding, math, investing
          &amp; more with instructors from top US universities!
        </p>
        <Button href={DCF_DEFAULT_ENTRY_POINT}>Book 1st Class Free</Button>
      </div>
      <div className="flex justify-center flex-col items-center w-full pt-20 md:pt-0">
        <h2 className="text-white font-normal text-xl leading-8 text-center m-0">
          “Excellent”
        </h2>
        <p className="text-white text-sm m-0 whitespace-nowrap py-6">
          <span className="font-medium">4.9 out of 5 based on 200+</span> reviews
        </p>
        <ul className="flex list-none p-0 m-0 pb-4 gap-1">
          {[...Array(5).keys()].map((star, i) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`star-${i}`}
              className="bg-j-green-600 w-8 h-8 flex justify-center items-center"
            >
              <Icon.TrustpilotStar className="w-6 h-6" />
            </li>
          ))}
        </ul>
        <Icon.Trustpilot className="" />
      </div>
    </div>
  </footer>
);

export default JuniverseFooter;
