import React, { FC } from 'react';
import styled from 'styled-components';

import SearchIconUrl from './assets/search_icon.svg';

const MyClubsSearchBoxContainer = styled.div.attrs({
  className: 'relative flex w-full',
})`
  height: 40px;
`;

const MyClubsSearchBoxInput = styled.input.attrs({
  className: 'font-semibold pl-5 py-2 pr-6 text-base w-full',
})`
  box-sizing: border-box;
  background-color: #fbfeff;
  border: 2px solid #d1eaf7;
  font-size: 14px;
  width: 16em;
  height: 100%;
  color: #335875;
  font-weight: 600;
  font-family: 'Open Sans';
  padding: 0px 12px;
  border-radius: 16px !important;

  ::placeholder {
    color: #a1becd;
  }
`;
// TODO: update to use moar tailwind!

export interface MyClubsSearchBoxProps {
  searchString: string | null;
  handleOnChange: (e: any) => void;
  handleKeyPress: (e: any) => void;
}

const MyClubsSearchBox: FC<MyClubsSearchBoxProps> = ({
  searchString,
  handleOnChange,
  handleKeyPress,
}) => (
  <MyClubsSearchBoxContainer>
    <MyClubsSearchBoxInput
      type="text"
      placeholder="Press enter to search..."
      value={searchString || ''}
      onChange={handleOnChange}
      onKeyPress={handleKeyPress}
    />
    <div className="absolute flex items-center justify-center p-3 mr-2 right-0 h-full box-border">
      <img src={SearchIconUrl} alt="" />
    </div>
  </MyClubsSearchBoxContainer>
);

export default MyClubsSearchBox;
