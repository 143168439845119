import React, { Component } from 'react';
import juniAxios from 'services/axios';
import moment from 'moment';
import queryString from 'query-string';

import { Button } from 'core-components';
import AuthLayout from '../AuthLayout';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

class ResetPassword extends Component {
  state = {
    email: '',
    password: '',
    token: '',
    tokenIsValid: false,
    errorMessage: '',
  };
  componentDidMount() {
    const { search } = this.props.location;
    const params = queryString.parse(search);
    const { token } = params;
    this.setState({ token });

    // get record matching the invite code in the URL
    juniAxios
      .get(`/auth/check_password_reset_token/${token}`)
      .then(res => {
        if (res.data.length === 0) {
          // if no records found, then the token is invalid
          this.setState({ tokenIsValid: false });
        } else if (!res.data.isValid) {
          // if the token has already been used, it will return with isValid: false
          this.setState({ tokenIsValid: false });
        } else {
          // check that the invite code has not expired
          const expirationTimestamp = moment(res.data.expirationTimestamp);
          if (expirationTimestamp.isAfter(moment())) {
            this.setState({
              email: res.data.email,
              tokenIsValid: true,
            });
          } else {
            this.setState({ tokenIsValid: false });
          }
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          inviteCodeIsValid: false,
        });
      });
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.password.length < 8) {
      this.setState({
        errorMessage: 'Your password must be at least 8 characters.',
      });
    } else {
      this.setState({ errorMessage: '' });

      juniAxios
        .post(
          `/auth/reset_password`,
          {
            email: this.state.email,
            password: this.state.password,
            token: this.state.token,
          },
          config,
        )
        .then(res => {
          console.log(res);
          if (res.data.userType === 'teacher' || res.data.userType === 'admin') {
            this.props.history.push('/teacher/login');
          } else if (res.data.userType === 'parent') {
            this.props.history.push('/learner/login');
          } else {
            this.props.hsitory.push('/');
          }
        })
        .catch(err => {
          console.log(err.response);
          this.setState({ errorMessage: err.response.data.message });
        });
    }
  }
  render() {
    return (
      <AuthLayout>
        {this.state.tokenIsValid ? (
          <div className="form-container" style={{ width: 'auto' }}>
            <form onSubmit={e => this.handleSubmit(e)}>
              <div className="form-title">Reset Your Password</div>
              <div className="form-description">
                After you create a new password, you will be taken to log in to your
                account.
              </div>
              <div className="form-error" style={{ textAlign: 'center' }}>
                {this.state.errorMessage}
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>{this.state.email}</td>
                  </tr>
                  <tr>
                    <td>Password (8 char min)</td>
                    <td>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={e => this.handleChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button type="submit" variant="pink">
                Submit
              </Button>
            </form>
          </div>
        ) : (
          <div className="page-error">This password reset link is invalid.</div>
        )}
      </AuthLayout>
    );
  }
}

export default ResetPassword;
