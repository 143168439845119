import React from 'react';
import classNames from 'classnames';

export interface DividerProps {
  /**
   * Should only be used for margins, including applying negative horizontal margins.
   * Examples: -mx-6 or -mr-6 and -ml-6.
   */
  className?: string;
}

/**
 * General purpose and standardized Divider / Separator.
 */
const Divider: React.FC<DividerProps> = ({ className }) => (
  <hr className={classNames('border-0 bg-j-purple-100 h-px my-0 mx-0', className)} />
);

export default Divider;
