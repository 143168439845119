import React, { FC } from 'react';
import classNames from 'classnames';

import zeroState from '../assets/zero-state.svg';

export interface LatestProjectZeroStateProps {
  studentName: string;
}

const LatestProjectZeroState: FC<LatestProjectZeroStateProps> = ({
  studentName,
}) => (
  <div
    className={classNames(
      'flex flex-col items-center justify-center',
      'bg-j-white py-16 w-full',
      'font-graphik',
      'p-3',
      'box-border',
    )}
  >
    <img src={zeroState} alt="no projects published" />
    <div className="text-j-dark-600 font-medium text-xl text-center">
      {studentName} hasn't published anything yet
    </div>{' '}
    <div className="text-j-dark-300 text-base">Check back later!</div>
  </div>
);

export default LatestProjectZeroState;
