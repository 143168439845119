import React, { FC } from 'react';
import Card from 'core-components/NewCard/Card';
import classNames from 'classnames';

interface CalendarCardProps {
  title: string;
  renderDescription?: () => React.ReactNode;
}

const CalendarCard: FC<CalendarCardProps> = ({
  title,
  renderDescription,
  children,
}) => (
  <Card>
    <div
      className={classNames(
        'font-graphik text-j-dark-600 font-medium text-base',
        'mb-3',
      )}
    >
      {title}
    </div>
    {renderDescription && (
      <div className={classNames('mb-4', 'font-graphik text-sm')}>
        {renderDescription()}
      </div>
    )}
    <Card.Divider />
    <div className={classNames('pt-6')}>{children}</div>
  </Card>
);

export default CalendarCard;
