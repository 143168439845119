import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { Arrow } from 'components/Icons';
import { Button } from 'components/ui';
import { Card } from 'core-components';

import juniverseProfile from './assets/juniverse_profile.png';
import juniverseWelcome from './assets/juniverse_welcome.png';

interface JuniverseCardProps {
  title: 'The Juniverse' | 'Your Juni Profile';
  description: string;
  link: string;
  buttonText?: 'Visit the Juniverse' | 'Visit My Profile';
}

const StyledJuniverseCard = styled(Card)`
  margin-bottom: 20px;
  width: 225px;

  @media only screen and (min-width: 1105px) {
    width: 275px;
  }

  @media only screen and (max-width: 920px) {
    width: 60%;
    min-width: 250px;
    align-self: center;
  }
`;

const JuniverseCardTitle = styled.div`
  color: #335875;
  font-weight: bold;
  font-size: 18px;

  @media only screen and (min-width: 1105px) {
    font-size: 20px;
  }
`;

const JuniverseCardDescription = styled.div`
  color: #4d6c7d;
  font-weight: normal;
  font-size: 14px;

  @media only screen and (min-width: 1105px) {
    font-size: 16px;
  }
`;

const JuniverseCardButton = styled(Button)`
  font-size: 12px;
  padding-right: 2rem !important;
  padding-left: 1rem !important;
  width: 100%;
  text-align: left;

  @media only screen and (min-width: 1105px) {
    font-size: 14px;
    padding-right: 4rem !important;
  }
`;

const JunivserseCard: FC<JuniverseCardProps> = ({
  title,
  description,
  link,
  buttonText,
}) => {
  const topImageSrc =
    title === 'The Juniverse' ? juniverseWelcome : juniverseProfile;

  return (
    <StyledJuniverseCard
      backgroundColor="eggshell-blue"
      bannerImgSrc={topImageSrc}
      borderColor="juni-blue-50"
      contentClassName="pt-3"
    >
      <JuniverseCardTitle>{title}</JuniverseCardTitle>
      <JuniverseCardDescription>{description}</JuniverseCardDescription>
      {buttonText ? (
        <JuniverseCardButton
          icon={<Arrow orientation="right" style={{ fill: '#fff' }} />}
          onClick={() => window.open(link)}
        >
          {buttonText}
        </JuniverseCardButton>
      ) : null}
    </StyledJuniverseCard>
  );
};

export default JunivserseCard;
