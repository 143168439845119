import React from 'react';
import { NewCard as Card } from 'core-components';
import Section from './Section';

type Statics = {
  Section: typeof Section;
};

/**
 * children is meant to be SidebarCard.Section[]
 */
const SidebarCard: React.FC & Statics = ({ children }) => (
  <Card>
    {/* React.Children.toArray(children) filters nulls which is important for conditionals in JSX */}
    {React.Children.map(React.Children.toArray(children), (section, index) => (
      <>
        {index > 0 && (
          <div className="py-7">
            <Card.Divider />
          </div>
        )}
        {section}
      </>
    ))}
  </Card>
);

SidebarCard.Section = Section;

export default SidebarCard;
