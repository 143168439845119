import Card from 'core-components/NewCard';
import React, { useState } from 'react';
import { CloseButton, CloseButtonIcon } from 'core-components/NewModalWindow/styles';
import Button from 'core-components/NewButton';
import { Link } from 'react-router-dom';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import projectInfo from './images/project.svg';
import userInfo from './images/userInfo.svg';

const NewProjectPromotion: React.FC<{ studentId: string }> = ({ studentId }) => {
  const daysToMs = 24 * 3600 * 1000;
  const daysToStayClosed = 3;
  const lsPromotionKey = 'promotionClosedAt';
  const lastClosedAt: string | null = localStorage.getItem(lsPromotionKey);

  const [closed, setClosed] = useState(
    lastClosedAt &&
      new Date().getTime() - new Date(lastClosedAt).getTime() <
        daysToStayClosed * daysToMs,
  );
  const closePromotion = () => {
    localStorage.setItem(lsPromotionKey, new Date().toDateString());
    setClosed(true);
  };
  if (closed) {
    return null;
  }

  return (
    <div className="mt-6">
      <Card>
        <div className="flex justify-between mb-2">
          <img src={userInfo} alt=""></img>
          <CloseButton aria-label="Close" onClick={closePromotion}>
            <CloseButtonIcon />
          </CloseButton>
        </div>
        <div className="flex justify-between space-x-4">
          <div className="flex flex-col text-j-dark-600 justify-around items-center md:items-start text-center md:text-left">
            <div className="font-semibold my-2 text-lg">
              Want your project featured here?
            </div>
            <div className="text-sm">
              Add a project to your Profile and it can be selected by Juni to
              feature.
            </div>
            <div className="w-1/2 mt-2">
              <Link
                to={`/learner/${studentId}/playground`}
                onClick={() =>
                  JuniAnalytics.track('create_project_promotion_clicked')
                }
                className="no-underline"
              >
                <Button variant="secondary">My Projects </Button>
              </Link>
            </div>
          </div>
          <div className="hidden md:flex items-center">
            <img src={projectInfo} alt="" />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewProjectPromotion;
