/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Terminal } from 'xterm';
import * as fit from 'xterm/lib/addons/fit/fit';
import io from 'socket.io-client';
import axios from 'axios';
import moment from 'moment';

import {
  JideCodeEditor,
  JideCodeOutput,
  JideSidebar,
  JideWidgets,
} from 'components/jide';
import {
  requestReplPublic,
  requestReplStudent,
  requestReplTeacher,
  selfUnassignStudent,
  selfUnassignTeacher,
} from 'services/repl';
import { getFileVersions } from 'services/code';
import { postJideProjectSaved } from 'services/user-events/student-events';

import './repl.css';
import Footer from '../Footer';
import JideEnvLayout from '../JideEnvLayout';

Terminal.applyAddon(fit);

// Main Component Code
class REPL extends Component {
  state = {
    codeRunning: false,
    startInProgress: false,
    stopInProgress: false,
    restoreInProgress: false,
    activePane: 'instructions',
    hasError: false,
    hasEditPermissions: false,
    canEdit: false, // use to temporarily disable editing
    clientLastSync: null, // timestamp for manual sync throttling
    connectionStatus: 'connecting',
    saveStatus: 'saved',
    isSyncing: true,
    project: null,
    replUrl: null,
    network: null,
    user: null,
    sessionKey: null,
    files: null,
    filesVersions: null,
    users: null,
    filename: null,
    fileType: null,
    code: '',
    sidebarExpanded: false,
    activeSidebarTab: null,
    defaultFontSize: 14,
    orientation: 'horizontal',
    syncingForeverError: false,
  };
  terminal = null;
  firstLoadCompleted = false;
  replRef = React.createRef();
  syncSetTimeout = null;

  // For Local testing
  // 1. set REPL_LOCAL_TEST_MODE=true in backend .env file
  // 2. start backend and follow instructions printed in the node console to start juni_repl container
  // 3. refresh or navigate to the desired Juni REPL page
  // 4. if you switch users, you must restart the container manually to unassign

  componentDidMount() {
    this.isPlayground = this.props.tab.tabNav.course === 'playground';
    // this.detectDefaultFontSize();
    if (this.props.newHorizons) {
      this.resizeTerminals();
      window.addEventListener('resize', this.resizeTerminals);
    } else {
      this.setOrientation();
      window.addEventListener('resize', this.setOrientation);
    }

    if (!['teacher', 'student'].includes(this.props.jideUser.type)) {
      this.setState({ activePane: 'console' });
    }
    this.setUpTerminal();

    const userHasPermissionsToSave =
      this.props.jideUser.type === 'student' || this.isPlayground;

    if (userHasPermissionsToSave) {
      window.addEventListener('focus', this.syncCodeDelay);
      window.addEventListener('blur', this.syncCodeIfActive);
      window.addEventListener('beforeunload', this.preventIfHasUnsavedCode);
    }
  }
  componentWillUnmount() {
    if (this.props.newHorizons) {
      window.removeEventListener('resize', this.resizeTerminals);
    } else {
      window.removeEventListener('resize', this.setOrientation);
    }

    const userHasPermissionsToSave =
      this.props.jideUser.type === 'student' || this.isPlayground;

    if (userHasPermissionsToSave) {
      window.removeEventListener('focus', this.syncCodeDelay);
      window.removeEventListener('blur', this.syncCodeIfActive);
      window.removeEventListener('beforeunload', this.preventIfHasUnsavedCode);
    }

    if (this.state.saveStatus === 'unsaved') {
      this.syncCodeRaw();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.firstLoadCompleted &&
      prevState.connectionStatus !== 'connected' &&
      this.state.connectionStatus === 'connected' &&
      this.state.isSyncing
    ) {
      /* setTimeout function was added as an attempt to investigate a code-sync issue. When users would
      see "Connecting" indefinitely. Below code helps us to increase visibility on when
      this issue occurs in production. */
      setTimeout(() => {
        if (!this.firstLoadCompleted && this.state.isSyncing) {
          console.error({
            timestamp: moment.utc().toISOString(),
            msg: 'SYNCING FOREVER ERROR',
            ...this.state,
            ...this.props,
          });
          this.setState({ syncingForeverError: true });
        }
      }, 8 * 1000);
    }
    if (
      !this.firstLoadCompleted &&
      this.state.connectionStatus === 'connected' &&
      prevState.isSyncing &&
      !this.state.isSyncing
    ) {
      this.firstLoadCompleted = true;
    }
    if (
      prevState.syncingForeverError &&
      this.state.connectionStatus === 'connected' &&
      !this.state.isSyncing
    ) {
      this.setState({ syncingForeverError: false });
    }

    // Ensure filename and files match up, and there is always a filename selected
    if (
      prevState.filename !== this.state.filename ||
      prevState.files !== this.state.files
    ) {
      if (this.state.filename && !this.state.files) {
        this.setState({ filename: null });
      } else if (
        (!this.state.filename && this.state.files) ||
        (this.state.filename &&
          !(this.state.filename in this.state.files) &&
          this.state.filename &&
          !(this.state.filename in this.state.filesVersions))
      ) {
        if ('main.py' in this.state.files) {
          this.selectFile('main.py');
        } else if ('Main.java' in this.state.files) {
          this.selectFile('Main.java');
        } else if ('main.cpp' in this.state.files) {
          this.selectFile('main.cpp');
        } else if (Object.keys(this.state.files).length) {
          this.selectFile(Object.keys(this.state.files)[0]);
        } else {
          this.setState({ filename: null, saveStatus: 'saved' });
        }
      }
    }

    // Resize terminal if sidebar changes
    if (
      prevState.sidebarExpanded !== this.state.sidebarExpanded ||
      prevProps.fullScreenMode !== this.props.fullScreenMode
    ) {
      if (this.props.newHorizons) {
        this.resizeTerminals();
      } else {
        this.setOrientation();
      }
    }

    // Sync code on project tab switch
    if (prevProps.isActive !== this.props.isActive) {
      this.syncCodeDefault();
    }

    // lets only get the history once we click into the sidebar
    if (
      this.state.activeSidebarTab === JideSidebar.TAB_VERSIONS &&
      prevState.activeSidebarTab !== this.state.activeSidebarTab
    ) {
      this.getFileVersions();
    }

    if (
      this.state.activeSidebarTab !== JideSidebar.TAB_VERSIONS &&
      prevState.activeSidebarTab === JideSidebar.TAB_VERSIONS
    ) {
      // if we move away from the version tab,
      // lets restore and reload the previous file
      if (
        this.state.filesVersions &&
        this.state.filesVersions[this.state.filename]
      ) {
        const filename = this.state.filesVersions[this.state.filename].name;
        this.selectFile(filename);
      }

      // once we move away from the restore tab, free up memory
      this.setState({ filesVersions: null });
    }

    // TODO THIS SEEMS TO CAUSE PERF ISSUES
    // Hide cursor if not running
    // if (this.terminal) {
    //   if (!prevState.codeRunning && this.state.codeRunning) {
    //     this.terminal.setOption('theme', {
    //       background: 'white',
    //       foreground: 'black',
    //       cursor: 'black',
    //       selection: 'rgba(100,100,100,0.2)',
    //     });
    //   } else if (prevState.codeRunning && !this.state.codeRunning) {
    //     this.terminal.setOption('theme', {
    //       background: 'white',
    //       foreground: 'black',
    //       cursor: 'transparent',
    //       selection: 'rgba(100,100,100,0.2)',
    //     });
    //   }
    // }
  }

  async getFileVersions() {
    const { filename } = this.state;
    const { student, project } = this.props.tab.tabNav;
    const file = this.state.files && this.state.files[filename];

    // only get version history of files that are isText
    if (!student || !project || !filename || !file) {
      return;
    }

    if (!file.isText) {
      // we still want to succeed loading, as the error will be handled later
      return this.setState({ filesVersions: [] });
    }

    this.setState({ filesVersions: null });

    // lets sync code first
    this.syncCode(async () => {
      let firstVersion = null;
      const filesVersions = {};

      try {
        const res = await getFileVersions(student, project, filename);

        if (res.status === 200) {
          res.data.versions.forEach(version => {
            const name = `${version.versionId}-${version.name}`;
            filesVersions[name] = version;

            if (firstVersion === null) {
              firstVersion = name;
            }
          });
        }

        this.setState({ filesVersions }, () => {
          // auto select first version
          this.selectFile(firstVersion);
        });
      } catch (error) {
        this.setState({ filesVersions: { error } });
      }
    });
  }

  static getDerivedStateFromProps(props) {
    const hasEditPermissions =
      props.jideUser && props.jideUser._id === props.tab.tabNav.student;
    return { hasEditPermissions };
  }

  preventIfHasUnsavedCode = e => {
    if (this.state.saveStatus === 'unsaved') {
      this.syncCodeDefault();
      e.preventDefault();
      e.returnValue = '';
    } else {
      delete e.returnValue;
    }
  };

  setUpTerminal = async () => {
    setTimeout(() => {
      const terminalProps = {
        cursorBlink: false,
        fontFamily: 'Monaco,Menlo,Ubuntu Mono,Consolas,source-code-pro,monospace',
        letterSpacing: 1,
        fontSize: this.state.defaultFontSize,
        theme: {
          background: 'white',
          foreground: 'black',
          cursor: 'black',
          selection: 'rgba(100,100,100,0.2)',
        },
      };

      this.terminal = new Terminal(terminalProps);
      this.terminal.open(
        document.getElementById(`jco-console-output-${this.props.tab.tabId}`),
      );
      if (this.props.newHorizons) {
        this.resizeTerminals();
      } else {
        this.setOrientation();
      }

      this.requestReplAndConnect();
    }, 500);
  };

  setOrientation = () => {
    const elem = this.replRef.current;
    let newOrientation = 'horizontal';
    if (elem.offsetHeight / elem.offsetWidth > 1.2) {
      newOrientation = 'vertical';
    }
    this.setState({ orientation: newOrientation }, this.resizeTerminals);
  };
  resizeTerminals = () => {
    if (this.terminal) this.terminal.fit();
  };

  requestReplAndConnect = async () => {
    try {
      const studentId = this.props.tab.tabNav.student;
      const projectId = this.props.tab.tabNav.project;
      const project = this.props.idLookup[projectId];

      const specialInstructions = project?.properties?.specialInstructions;
      if (specialInstructions) {
        this.setState({ connectionStatus: 'disconnected', project });
        return;
      }

      this.setState({ connectionStatus: 'connecting', project });
      const { success, replUrl, network, user, sessionKey } =
        this.props.jideUser.type === 'teacher'
          ? await requestReplTeacher()
          : this.props.jideUser.type === 'student'
          ? await requestReplStudent(this.props.tab.tabNav.student)
          : this.props.jideUser.type === 'public'
          ? await requestReplPublic(
              studentId,
              projectId,
              this.props.jideUser?.type === 'public'
                ? this.props.jideUser?.createdByUserType
                : this.props.jideUser?.type,
            )
          : {};
      if (!success) throw new Error('Requesting REPL Failed!');

      await this.openProject(
        replUrl,
        sessionKey,
        network,
        user,
        studentId,
        projectId,
      );
      await this.setUpSocket(
        replUrl,
        sessionKey,
        network,
        user,
        studentId,
        projectId,
      );
    } catch (err) {
      console.error('ERROR', err);
      this.setState({
        connectionStatus: 'disconnected',
        replUrl: null,
        network: null,
        user: null,
        sessionKey: null,
      });
    }
  };

  openProject = async (
    replUrl,
    sessionKey,
    network,
    user,
    userIdForProject,
    projectId,
  ) =>
    axios.post(`${replUrl}/api/open_project`, {
      sessionKey,
      userIdForProject,
      projectId,
      defaultLang: this.props.defaultLang,
    });

  setUpSocket = async (
    replUrl,
    sessionKey,
    network,
    user,
    userIdForProject,
    projectId,
    // upidKey,
  ) => {
    let path = new URL(replUrl).pathname;
    if (path[path.length - 1] === '/') {
      path = path.slice(0, -1);
    }
    const baseUrl = path.length ? replUrl.split(path)[0] : replUrl;

    path += '/socket.io';
    const socket = io(baseUrl, {
      autoConnect: false,
      path,
      query: {
        sessionKey,
        userIdForProject,
        projectId,
        userName: user.name,
      },
    });

    socket.on('connect', () => {
      this.setState({
        connectionStatus: 'connected',
        replUrl,
        sessionKey,
        network,
        user,
        syncingForeverError: false,
      });
    });
    socket.on('disconnect', () => {
      this.setState({
        connectionStatus: 'disconnected',
      });
    });
    socket.on('pty', data => {
      if ('running' in data) {
        if (this.terminal && !data.running && this.state.codeRunning) {
          this.terminal.write('\n\n\r');
        }
        this.setState({
          codeRunning: !!data.running,
          stopInProgress: false,
          startInProgress: false,
        });
      }
    });
    socket.on('data', data => {
      const d = data.data;
      if (this.terminal) this.terminal.write(d);
    });
    socket.on('info', data => {
      this.setState({
        files: data.files,
        users: data.users,
        // network: data.network,
      });
    });

    this.terminal.on('data', data => {
      socket.emit('data', { data });
    });

    socket.open();
  };

  constructStaticFileUrl = filename => {
    if (!(filename in this.state.files)) return;
    const filepath = this.state.files[filename].filePath.slice(1);
    const baseUrl = this.state.replUrl + filepath;
    let fileUrl = `${baseUrl}?sessionKey=${
      this.state.sessionKey
    }&userIdForProject=${this.props.tab.tabNav.student.toString()}&projectId=${this.props.tab.tabNav.project.toString()}&timestamp=${moment
      .utc()
      .toString()}`;
    fileUrl = encodeURI(fileUrl);
    return fileUrl;
  };

  setPane = (pane, cb) => {
    this.setState({ activePane: pane }, cb);
  };

  setSidebarTab = (tab, cb) => {
    if (!this.state.sidebarExpanded) {
      this.setState({ activeSidebarTab: tab, sidebarExpanded: true }, cb);
    } else if (this.state.activeSidebarTab === tab) {
      this.setState({ activeSidebarTab: '', sidebarExpanded: false }, cb);
    } else {
      this.setState({ activeSidebarTab: tab }, cb);
    }
  };

  selectFile = async filename => {
    let file;
    let isPreview;
    if (this.state.filename && filename === this.state.filename) {
      return;
    }
    if (
      this.state.filename &&
      this.state.saveStatus === 'unsaved' &&
      this.state.files[this.state.filename]
    ) {
      // save file before selecting new one
      await this.syncCodeRaw();
    }

    if (this.state.files && this.state.files[filename]) {
      file = this.state.files[filename];
      isPreview = false;
    } else if (this.state.filesVersions && this.state.filesVersions[filename]) {
      file = this.state.filesVersions[filename];
      isPreview = true;
    } else {
      return;
    }

    const fileType = this.parseFileType(filename);
    if (!isPreview) {
      this.setState(
        {
          isPreview: false,
          filename,
          fileType,
          code: '',
          saveStatus: this.state.files[filename].isText ? null : 'saved',
          isSyncing: this.state.files[filename].isText,
          canEdit: false,
        },
        this.syncCodeDefault,
      );
    } else {
      this.setState({
        isPreview: true,
        filename,
        fileType,
        code: file.contents,
        saveStatus: 'saved',
        isSyncing: false,
        canEdit: false,
      });
    }
  };

  restoreFile = async () => {
    const file =
      this.state.filesVersions && this.state.filesVersions[this.state.filename];

    if (!file) {
      console.log('Couldnt file file to restore', this.state.filename);
      return;
    }

    // update the file in state
    this.setState(
      {
        restoreInProgress: true,
        code: file.contents,
        filename: file.name,
        saveStatus: 'unsaved',
      },
      async () => {
        // sync code
        await this.syncCode(() => {
          // close sidebar and mark restore complete
          this.setState({
            activeSidebarTab: '',
            sidebarExpanded: false,
            restoreInProgress: false,
          });
        });
      },
    );
  };

  createFile = async filename => {
    const reqBody = {
      sessionKey: this.state.sessionKey,
      userIdForProject: this.props.tab.tabNav.student,
      projectId: this.props.tab.tabNav.project,
      filename,
    };
    await axios
      .post(`${this.state.replUrl}/api/create_file/`, reqBody)
      .then(res => {
        if (!res.data.success) console.error('createFile Failed!');
      })
      .catch(err => console.error('createFile Failed!', err));
  };

  parseFileType = filename => {
    let fileType = 'unknown';
    try {
      const file = this.state.files[filename] || this.state.filesVersions[filename];
      const { isText } = file;
      const mimeType = (file.mimeType && file.mimeType.split('/')[0]) || '';
      const rawMimeType = file.mimeType;
      const re = /(?:\.([^.]+))?$/;
      const fileSuffix = re.exec(filename)[1];

      if (fileSuffix === 'py' && isText) {
        fileType = 'code/python';
      } else if (fileSuffix === 'java' && isText) {
        fileType = 'code/java';
      } else if (
        ['cpp', 'h'].includes(fileSuffix) &&
        isText &&
        rawMimeType === 'text/x-c'
      ) {
        fileType = 'code/cpp';
      } else if (fileSuffix === 'csv' && isText) {
        fileType = 'csv';
      } else if (fileSuffix === 'zip' && !isText) {
        fileType = 'zip';
      } else if (fileSuffix === 'pdf' && !isText) {
        fileType = 'pdf';
      } else if (mimeType === 'video' && !isText) {
        fileType = 'video';
      } else if (mimeType === 'audio' && !isText) {
        fileType = 'audio';
      } else if (mimeType === 'image' && !isText) {
        fileType = 'image';
      } else if (isText) {
        fileType = 'text';
      } else {
        fileType = 'unknown';
      }
    } catch (err) {
      console.log(err);
    }
    return fileType;
  };

  readyForApiCall = filenameRequired =>
    this.state.replUrl &&
    this.state.sessionKey &&
    this.props.tab.tabNav.project &&
    (!filenameRequired || (filenameRequired && !!this.state.filename));

  syncCode = async (cb, onlyIfActive) => {
    const callback = cb || (() => undefined);

    if (!this.state.files || !this.state.files[this.state.filename]) {
      return callback();
    }

    let skipSync = false;
    if (!this.readyForApiCall(true)) {
      console.log('not ready 1', this.state);
      skipSync = true;
    } else if (onlyIfActive && !this.props.isActive) {
      console.log('not ready 2');
      skipSync = true;
    } else if (
      ['disconnected', 'connecting'].includes(this.state.connectionStatus)
    ) {
      console.log('not ready 3');
      skipSync = true;
    } else if (!this.state.files[this.state.filename].isText) {
      console.log('not ready 4', this.state);
      skipSync = true;
    }

    if (skipSync) {
      callback();
    } else {
      this.setState({ isSyncing: true, canEdit: false }, () => {
        this.syncCodeRaw()
          .then(res => {
            const { jideUser, tab } = this.props;
            const { student, project, course } = tab.tabNav || {};
            const { isCustomProject } = this.state.project.properties || {};

            if (!!jideUser.type && jideUser.type === 'student') {
              postJideProjectSaved({
                studentId: student,
                projectId: project,
                courseId: course,
                isCustomProject,
              });
            }

            this.setState(
              {
                saveStatus: 'saved',
                isSyncing: false,
                code: res.data.data ? res.data.data : '',
                canEdit: true,
              },
              callback,
            );
            return true;
          })
          .catch(err => {
            console.error(err);
            this.setState({ saveStatus: 'error', canEdit: true }, callback);
            return false;
          });
      });
    }
  };
  syncCodeRaw = async () => {
    if (!this.readyForApiCall(true)) return;
    if (!this.state.files[this.state.filename].isText) {
      return;
    }
    const result = await this.syncCodeCore(
      `${this.state.replUrl}/api/sync_text`,
      this.state.sessionKey,
      this.props.tab.tabNav.student,
      this.props.tab.tabNav.project,
      this.state.filename,
      this.state.files[this.state.filename].updatedAt,
      this.state.saveStatus === 'unsaved',
      this.state.code,
      this.props.jideUser.type,
    );
    return result;
  };
  syncCodeCore = async (
    replUrl,
    sessionKey,
    userIdForProject,
    projectId,
    filename,
    dbUpdatedAt,
    hasNewEdits,
    code,
    jideUserType,
  ) => {
    const shouldRenderNewUpdates = jideUserType === 'student' || this.isPlayground;
    const result = await axios.post(replUrl, {
      sessionKey,
      userIdForProject,
      projectId,
      filename,
      dbUpdatedAt,
      hasNewEdits: shouldRenderNewUpdates && hasNewEdits,
      data: shouldRenderNewUpdates && hasNewEdits ? code : null,
    });
    return result;
  };
  syncCodeDefault = async () => {
    this.syncCode();
  };
  syncCodeCb = async cb => {
    this.syncCode(cb);
  };
  syncCodeDelay = async () => {
    setTimeout(() => {
      this.syncCode(null, true);
    }, 600);
  };
  syncCodeIfActive = async () => {
    this.syncCode(null, true);
  };

  // Executes upon hitting 'Run' button
  runCode = async () => {
    if (
      !this.state.connectionStatus ||
      ['disconnected', 'connecting'].includes(this.state.connectionStatus)
    )
      return;
    if (this.state.codeRunning || this.state.startInProgress) return;
    if (
      (!this.state.code || !this.state.code.length) &&
      this.props.defaultLang !== 'java'
    ) {
      return;
    }
    if (
      !this.state.replUrl ||
      !this.state.sessionKey ||
      !this.state.user ||
      !this.state.filename ||
      !this.props.tab.tabNav.student ||
      !this.props.tab.tabNav.project ||
      !this.terminal
    )
      return;

    // Partial escape
    // this.terminal.write('\x1b');
    // First \n will cancel any existing escape or go to new line
    // Then the \n\r will put the cursor at the start of the next line
    // this.terminal.write('\n\n\r');
    this.terminal.clear();
    this.setState({ activePane: 'console', startInProgress: true }, () => {
      this.syncCodeCb(() => {
        const filename =
          this.props.defaultLang === 'java' && 'Main.java' in this.state.files
            ? 'Main.java'
            : this.state.filename;
        const reqBody = {
          sessionKey: this.state.sessionKey,
          userIdForProject: this.props.tab.tabNav.student,
          projectId: this.props.tab.tabNav.project,
          filename,
        };
        axios.post(`${this.state.replUrl}/api/run_code/`, reqBody);
        if (this.props.jideUser.type === 'public' && !this.props.fullScreenMode) {
          this.props.toggleFullScreenMode();
        }
        this.terminal.focus();
      });
    });
  };

  // Executes upon hitting 'Stop' button
  stopCode = async () => {
    if (!this.state.codeRunning || this.state.stopInProgress) return;
    if (
      !this.state.replUrl ||
      !this.state.sessionKey ||
      !this.state.user ||
      !this.props.tab.tabNav.student ||
      !this.props.tab.tabNav.project
    )
      return;
    this.setState({ stopInProgress: true });
    const reqBody = {
      sessionKey: this.state.sessionKey,
      userIdForProject: this.props.tab.tabNav.student,
      projectId: this.props.tab.tabNav.project,
    };
    axios.post(`${this.state.replUrl}/api/stop_code/`, reqBody);
  };

  hardReset = async source => {
    const selfUnassign =
      this.props.jideUser.type === 'student'
        ? selfUnassignStudent
        : selfUnassignTeacher;
    const resp = await selfUnassign(this.props.jideUser._id, source);
    if (!resp || !resp.success) return false;
    window.location.reload();
    return true;
  };

  // Ace editor onChange method called every time editor is updated
  aceOnChange = newValue => {
    this.setState({
      code: newValue,
      saveStatus: 'unsaved',
    });

    clearTimeout(this.syncSetTimeout);
    this.syncSetTimeout = setTimeout(() => {
      this.syncCode();
    }, 10 * 1000);
  };

  // I know the below structure is slightly ugly/unorthodox, but the intent is to
  // preserve the same instance of the terminal object as the css changes around it
  // due to orientation shifts
  render() {
    const { newHorizons } = this.props;
    const specialInstructions = this.state.project?.properties?.specialInstructions;

    const sidebarProps = {
      jideUser: this.props.jideUser,
      sidebarExpanded: this.state.sidebarExpanded,
      fullScreenMode: this.props.fullScreenMode,
      toggleFullScreenMode: this.props.toggleFullScreenMode,
      activeSidebarTab: this.state.activeSidebarTab,
      setSidebarTab: this.setSidebarTab,
      selectFile: this.selectFile,
      parseFileType: this.parseFileType,
      filename: this.state.filename,
      files: this.state.files,
      filesVersions: this.state.filesVersions,
      hasEditPermissions: this.state.hasEditPermissions,
      isPreview: this.state.isPreview,
      isDisabled: !!specialInstructions,
      users: this.state.users,
      network: this.state.network,
      connectionStatus: this.state.connectionStatus,
      isSyncing: this.state.isSyncing,
      createFile: this.createFile,
      specialInstructions,
      hardReset: this.hardReset,
      newHorizons: this.props.newHorizons,
    };

    const codeEditorProps = {
      jideUser: this.props.jideUser,
      tab: this.props.tab,
      hasEditPermissions: this.state.hasEditPermissions,
      isSyncing: this.state.isSyncing,
      saveStatus: this.state.saveStatus,
      connectionStatus: this.state.connectionStatus,
      codeRunning: this.state.codeRunning,
      fileType: this.state.fileType,
      startInProgress: this.state.startInProgress,
      stopInProgress: this.state.stopInProgress,
      sidebarExpanded: this.state.sidebarExpanded,
      fullScreenMode: this.props.fullScreenMode,
      filename: this.state.filename,
      files: this.state.files,
      filesVersions: this.state.filesVersions,
      canEdit: this.state.canEdit,
      code: this.state.code,
      syncCodeDefault: this.syncCodeDefault,
      runCode: this.runCode,
      stopCode: this.stopCode,
      aceOnChange: this.aceOnChange,
      selectFile: this.selectFile,
      constructStaticFileUrl: this.constructStaticFileUrl,
      defaultFontSize: this.state.defaultFontSize,
      activeSidebarTab: this.state.activeSidebarTab,
      restoreInProgress: this.state.restoreInProgress,
      restoreFile: this.restoreFile,
      isActive: this.props.isActive,
      isCustomProject: this.state.project?.properties?.isCustomProject || false,
      specialInstructions,
      syncingForeverError: this.state.syncingForeverError,
      hardReset: this.hardReset,
      recordingMode: this.props.recordingMode,
      setRecordingMode: this.props.setRecordingMode,
      /* for new horizons restyle */
      setSidebarTab: this.setSidebarTab,
      isDisabled: !!specialInstructions,
      newHorizons,
      toggleFullScreenMode: this.props.toggleFullScreenMode,
      isCodeHidden: this.props.isCodeHidden,
    };

    if (newHorizons) {
      return (
        <JideEnvLayout fullScreen={this.props.fullScreenMode}>
          <JideEnvLayout.MainContent className="flex-1 overflow-hidden">
            <JideSidebar {...sidebarProps} />
            <JideCodeEditor {...codeEditorProps} />
          </JideEnvLayout.MainContent>
          <JideEnvLayout.SideContent>
            <JideCodeOutput
              jideUser={this.props.jideUser}
              activePane={
                this.props.tab.tabNav.course === 'playground'
                  ? 'console'
                  : this.state.activePane
              }
              hasError={this.state.hasError}
              tab={this.props.tab}
              project={this.state.project}
              setPane={this.setPane}
              specialInstructions={specialInstructions}
              isPlayground={this.props.tab.tabNav.course === 'playground'}
              newHorizons
            />
            {this.state.hasEditPermissions && (
              <Footer className="justify-end">
                <JideWidgets
                  environmentType={this.props.defaultLang}
                  showVideosWidget={this.props.showVideosWidget}
                  activeNav={this.props.activeNav}
                  idLookup={this.props.idLookup}
                  learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
                  tab={this.props.tab}
                  jideUser={this.props.jideUser}
                  isCustomProject={codeEditorProps.isCustomProject}
                  isPlayground={!!this.props.playgroundProject}
                  recordingMode={this.props.recordingMode}
                  setRecordingMode={this.props.setRecordingMode}
                  newHorizons
                  schedulingFormat={this.props.schedulingFormat}
                />
              </Footer>
            )}
          </JideEnvLayout.SideContent>
        </JideEnvLayout>
      );
    }

    return (
      <div
        ref={this.replRef}
        className={
          `repl` +
          ` repl-${this.state.orientation}${
            this.props.fullScreenMode ? ' repl-fullscreen' : ''
          }`
        }
      >
        {this.state.orientation === 'vertical' ? (
          <div className="repl-sidebar-and-ce">
            <JideSidebar {...sidebarProps} />
            <JideCodeEditor {...codeEditorProps} />
          </div>
        ) : null}

        {this.state.orientation === 'vertical' ? null : (
          <JideSidebar {...sidebarProps} />
        )}
        {this.state.orientation === 'vertical' ? null : (
          <JideCodeEditor {...codeEditorProps} />
        )}

        <JideCodeOutput
          jideUser={this.props.jideUser}
          activePane={
            this.props.tab.tabNav.course === 'playground'
              ? 'console'
              : this.state.activePane
          }
          hasError={this.state.hasError}
          tab={this.props.tab}
          project={this.state.project}
          setPane={this.setPane}
          specialInstructions={specialInstructions}
          isPlayground={this.props.tab.tabNav.course === 'playground'}
        />
        <JideWidgets
          environmentType={this.props.defaultLang}
          showVideosWidget={this.props.showVideosWidget}
          activeNav={this.props.activeNav}
          idLookup={this.props.idLookup}
          learnerAnalyticsEnabled={this.props.learnerAnalyticsEnabled}
          tab={this.props.tab}
          jideUser={this.props.jideUser}
          isCustomProject={codeEditorProps.isCustomProject}
          isPlayground={!!this.props.playgroundProject}
          recordingMode={this.props.recordingMode}
          setRecordingMode={this.props.setRecordingMode}
        />
      </div>
    );
  }
}

REPL.defaultProps = {
  defaultLang: 'python',
};
REPL.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  activeNav: PropTypes.shape({}),
  tab: PropTypes.shape({}).isRequired,
  isActive: PropTypes.bool,
  fullScreenMode: PropTypes.bool,
  toggleFullScreenMode: PropTypes.func.isRequired,
  idLookup: PropTypes.shape({}).isRequired,
  courseName: PropTypes.string,
  defaultLang: PropTypes.string,
  showVideosWidget: PropTypes.bool,
  recordingMode: PropTypes.bool,
  setRecordingMode: PropTypes.func,
  newHorizons: PropTypes.bool,
  isCodeHidden: PropTypes.bool,
};
export default REPL;
