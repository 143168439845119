import katex from 'katex';

// Borrowed from here: https://github.com/zzish/react-latex/blob/master/src/latex.js
const latexify = (string, options) => {
  const regularExpression = /\$\$[\s\S]+?\$\$|\\\[[\s\S]+?\\\]|\\\([\s\S]+?\\\)|\$[\s\S]+?\$/g;
  const blockRegularExpression = /\$\$[\s\S]+?\$\$|\\\[[\s\S]+?\\\]/g;

  const stripDollars = stringToStrip =>
    stringToStrip[0] === '$' && stringToStrip[1] !== '$'
      ? stringToStrip.slice(1, -1)
      : stringToStrip.slice(2, -2);

  const getDisplay = stringToDisplay =>
    stringToDisplay.match(blockRegularExpression) ? 'block' : 'inline';

  const renderLatexString = (s, t) => {
    let renderedString;
    try {
      // returns HTML markup
      renderedString = katex.renderToString(
        s,
        t === 'block' ? { displayMode: true, ...options } : options,
      );
    } catch (err) {
      console.error('couldn`t convert string', s);
      return s;
    }
    return renderedString;
  };

  const result = [];

  const latexMatch = string.match(regularExpression);
  const stringWithoutLatex = string.split(regularExpression);

  if (latexMatch) {
    stringWithoutLatex.forEach((s, index) => {
      result.push({
        string: s,
        type: 'text',
      });
      if (latexMatch[index]) {
        result.push({
          string: stripDollars(latexMatch[index]),
          type: getDisplay(latexMatch[index]),
        });
      }
    });
  } else {
    result.push({
      string,
      type: 'text',
    });
  }

  const processResult = resultToProcess => {
    const newResult = resultToProcess.map(r => {
      if (r.type === 'text') {
        return r.string;
      }
      return `<span>${renderLatexString(r.string, r.type)}</span>`;
    });

    return newResult;
  };

  // Returns list of spans with latex and non-latex strings.
  return processResult(result);
};

export const getLatexHtmlStr = jsxStr => {
  const html = [];
  let start = 0;
  let latexIndex = jsxStr.indexOf('<Latex');
  let lastChar = '';

  while (latexIndex !== -1) {
    if (start !== latexIndex) {
      const nonLatexPart = jsxStr.substring(start, latexIndex);
      html.push(nonLatexPart);
      lastChar = nonLatexPart[nonLatexPart.length - 1];
    }
    let latexContentStartIndex = jsxStr.indexOf('>', latexIndex);
    if (latexContentStartIndex !== -1) {
      latexContentStartIndex += 1;
      const options = jsxStr.substring(latexIndex + 6, latexContentStartIndex - 1);
      let latexContentEndIndex = jsxStr.indexOf('</Latex>', latexIndex);
      if (latexContentEndIndex === -1) {
        latexContentEndIndex = jsxStr.length;
      }
      let htmlStr = latexify(
        jsxStr.substring(latexContentStartIndex, latexContentEndIndex),
        { displayMode: options.includes('displayMode'), throwOnError: false },
      ).join('');
      if (lastChar !== ' ') {
        htmlStr = ` ${htmlStr}`;
      }
      html.push(htmlStr);
      lastChar = htmlStr[htmlStr.length - 1];

      start = latexContentEndIndex + 8;
      latexIndex = jsxStr.indexOf('<Latex', start);
    } else {
      start = latexIndex + 6;
      latexIndex = jsxStr.indexOf('<Latex', start);
    }
  }
  html.push(jsxStr.substring(start));

  return html.join('');
};
