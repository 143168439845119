import React from 'react';
import { REPL } from 'components/jide';

import './jide_env_cpp.css';

const JideEnvCpp = props => (
  <REPL {...props} key={`repl_${props.tab.tabId.toString()}`} />
);

export default JideEnvCpp;
