import { Message } from 'core-components';
import React from 'react';
import NavBar from './NavBar';

interface PublicPageErrorProps extends React.ComponentProps<typeof NavBar> {
  errorMessage?: React.ComponentProps<typeof Message>['children'];
  errorDescription?: React.ComponentProps<typeof Message>['description'];
}

const PublicPageError: React.FC<PublicPageErrorProps> = ({
  errorMessage = 'Sorry, an unexpected error occurred.',
  errorDescription = 'Please try again later!',
  ...navBarProps
}) => (
  <>
    <NavBar {...navBarProps} />
    <div className="flex justify-center items-center box-border max-w-5xl my-6 px-6 sm:my-10 sm:px-10 mx-auto w-full">
      <Message status="error" outline description={errorDescription}>
        {errorMessage}
      </Message>
    </div>
  </>
);

export default PublicPageError;
