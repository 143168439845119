import { Dispatch, FC, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { setHours, setMinutes, nextDay, addWeeks, getDay, parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import styled from 'styled-components';

import { PRIORITY_LEVELS } from 'utils/enrollment';
import { DEFAULT_TIMEZONE, Timezone } from 'constants/timezones';
import { PencilTool } from 'core-components/Icon';
import { NewButton as Button } from 'core-components';
import { SelectField } from 'components/ui';
import { AvailabilityPreferences } from '../types';

const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const START_HOUR = 7;
const END_HOUR = 19;
const NUM_FUTURE_START_DATES = 6;

const createStartDatesArray = (day: Day, time: string, timezone: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const now = new Date();
  const firstDate = getDay(now) === Number(day) ? now : nextDay(now, day);
  firstDate.setHours(hours, minutes, 0, 0);

  return _.range(0, NUM_FUTURE_START_DATES).map(i =>
    addWeeks(zonedTimeToUtc(firstDate, timezone), i + 1),
  );
};

const StyledSelectField = styled(SelectField)`
  select {
    margin: 5px 0px 5px 0px;
    padding: 7px 10px;
    font-size: 13px;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans';
    color: #25546d;
    min-height: 42px;
    border-radius: 6px;
    border: 1px solid rgb(98, 85, 176);
    box-shadow: inset 0px 5px 20px -10px #cfe5f3;
  }
`;

const dayOptions = _.range(0, 7).map(i => ({
  value: i as Day,
  label: `${DAY_NAMES[i]}s`,
}));

interface SchedulingPreferenceSelectorProps {
  priority: number;
  timezone: string;
  customAvailabilities: AvailabilityPreferences;
  setCustomAvailabilities: Dispatch<SetStateAction<AvailabilityPreferences>>;
}

const SchedulingPreferenceSelector: FC<SchedulingPreferenceSelectorProps> = ({
  priority,
  timezone,
  customAvailabilities,
  setCustomAvailabilities,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const timeOptions = _.range(START_HOUR, END_HOUR).map(hour => {
    const ptHour = zonedTimeToUtc(
      setMinutes(setHours(new Date(), hour), 0),
      DEFAULT_TIMEZONE,
    );
    const clientHour = utcToZonedTime(ptHour, timezone);
    return {
      value: format(clientHour, 'HH:mm', { timeZone: timezone }),
      label: format(clientHour, 'h:mm a', { timeZone: timezone }),
    };
  });

  const preference = customAvailabilities[priority] || {};
  const startDatesArray =
    preference.day && preference.time
      ? createStartDatesArray(preference.day, preference.time, timezone)
      : [];

  const error =
    preference.day &&
    preference.time &&
    Object.values(customAvailabilities).filter(
      a => a.day === preference.day && a.time === preference.time,
    ).length > 1
      ? 'This day and time has already been selected'
      : '';

  return (
    <>
      <div className="mt-4 mb-2 text-sm">
        {`${PRIORITY_LEVELS[priority]} Choice: (Timezone: ${timezone})`}
      </div>
      <div className="flex gap-4">
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <StyledSelectField
          className="full-width"
          error={error}
          value={preference.day}
          onChange={(value: Day) =>
            setCustomAvailabilities({
              ...customAvailabilities,
              [priority]: {
                ...customAvailabilities[priority],
                day: value,
                startDate: undefined,
              },
            })
          }
          placeholder="Select day of week"
          options={dayOptions}
        />
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <StyledSelectField
          className="full-width"
          value={preference.time}
          disabled={!preference.day}
          onChange={(value: string) =>
            setCustomAvailabilities({
              ...customAvailabilities,
              [priority]: {
                ...customAvailabilities[priority],
                time: value,
                startDate: undefined,
              },
            })
          }
          placeholder="Select time"
          options={timeOptions}
        />
      </div>
      <div className="text-sm my-4">
        {preference.day && preference.time && !error && (
          <div className="flex gap-4">
            <div className="text-xs text-juni-purple-500 w-56">
              If Juni selects this as your weekly time, the first class will be on
            </div>
            {isEditing ? (
              <div>
                {/* eslint-disable-next-line */}
                {/* @ts-ignore */}
                <StyledSelectField
                  placeholder="Select a date"
                  value={preference.startDate?.toISOString()}
                  onChange={(value: string) => {
                    setCustomAvailabilities({
                      ...customAvailabilities,
                      [priority]: {
                        ...customAvailabilities[priority],
                        startDate: parseISO(value),
                      },
                    });
                    setIsEditing(false);
                  }}
                  options={startDatesArray.map(availability => ({
                    value: availability.toISOString(),
                    label: format(availability, 'EEEE, MMMM do, yyyy', {
                      timeZone: timezone,
                    }),
                  }))}
                />
              </div>
            ) : (
              <Button
                variant="minimal"
                size="small"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                {preference.startDate && (
                  <span className="mr-2">
                    {format(
                      utcToZonedTime(preference.startDate, timezone),
                      'EEEE, MMMM do, yyyy',
                      {
                        timeZone: timezone,
                      },
                    )}
                  </span>
                )}
                <PencilTool className="text-juni-purple-500" />
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

interface CustomTimePickerStepProps {
  timezone: string;
  customAvailabilities: AvailabilityPreferences;
  setCustomAvailabilities: Dispatch<SetStateAction<AvailabilityPreferences>>;
}

const CustomTimePickerStep: FC<CustomTimePickerStepProps> = ({
  timezone,
  customAvailabilities,
  setCustomAvailabilities,
}) => (
  <div className="flex flex-col gap-2 w-full">
    <div className="text-sm">
      <div>
        Please provide times that would work best for you. We will work with your
        current instructor first to find a match, and if none can be made we will
        match your student with another highly skilled instructor who has
        availability during your preferred time(s).
      </div>
      <div className="mt-2">
        Make sure that these times generally work on a weekly basis for your student.
        Our team will do our best to meet these preferences, and we may reach out to
        discuss as needed.
      </div>
    </div>
    <div>
      {Object.keys(PRIORITY_LEVELS).map(priority => (
        <SchedulingPreferenceSelector
          key={priority}
          priority={Number(priority)}
          timezone={timezone as Timezone}
          customAvailabilities={customAvailabilities}
          setCustomAvailabilities={setCustomAvailabilities}
        />
      ))}
    </div>
  </div>
);

export default CustomTimePickerStep;
