import { TailwindSpace } from 'theme/types';

type Spacing = TailwindSpace | TailwindSpace[];

const getSpacingStyle = (type: 'm' | 'p', spacing?: Spacing) => {
  if (!spacing) {
    return '';
  }
  if (!Array.isArray(spacing)) {
    return `${type}-${spacing}`;
  }
  switch (spacing.length) {
    case 1:
      return `${type}-${spacing[0]}`;
    case 2:
      return `${type}y-${spacing[0]} ${type}x-${spacing[1]}`;
    case 3:
      return `${type}t-${spacing[0]} ${type}x-${spacing[1]} ${type}b-${spacing[2]}`;
    case 4:
      return `${type}t-${spacing[0]} ${type}r-${spacing[1]} ${type}b-${spacing[2]} ${type}l-${spacing[3]}`;
    default:
      return '';
  }
};

export const getMarginStyle = (margin?: Spacing) => getSpacingStyle('m', margin);

export const getPaddingStyle = (padding?: Spacing) => getSpacingStyle('p', padding);
