import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { Resizable } from 're-resizable';

import { Arrow, XIcon } from 'components/Icons';
import JuniSpinner from 'components/JuniSpinner';

import './jide_video_player.css';

// TODO: Refactor VideoPlayer and JideVideoPlayer components into single VideoPlayer component
class JideVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.player = null;
    this.lastSeekedSeconds = 0;
  }

  componentDidMount() {
    if (!this.player && this.iframeRef && this.iframeRef.current) {
      this.player = new Player(this.iframeRef.current);
      this.player.on('play', this.onPlayVideo);
      this.player.on('pause', this.onPauseVideo);
      this.player.on('ended', this.onFinishPlayback);
      this.player.on('seeked', this.onSeek);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.curActiveProjectId !== this.props.curActiveProjectId &&
      this.props.isPlaying
    ) {
      if (this.player) {
        this.player.pause();
      }
    }
  }

  onPlayVideo = e => {
    if (this.props.onPlayVideo) {
      this.props.onPlayVideo(
        e || { seconds: this.lastSeekedSeconds },
        this.props.video,
      );
    }
  };

  onPauseVideo = e => {
    if (this.props.onPauseVideo) {
      this.props.onPauseVideo(e, this.props.video);
    }
  };

  onFinishPlayback = e => {
    if (this.props.onFinishPlayback) {
      this.props.onFinishPlayback(e, this.props.video);
    }
  };

  onSeek = e => {
    if (e) {
      this.lastSeekedSeconds = e.seconds;
    }
  };

  render() {
    const { className, moduleName, onClose, onGoBack, video } = this.props;

    return (
      <Resizable
        lockAspectRatio
        defaultSize={{ width: 300, height: '70%' }}
        minWidth={300}
        maxWidth={650}
        className={`jide-video-player ${className || ''}`}
      >
        <div className="header">
          <div className="left buttons">
            <button className="go-back-btn" onClick={onGoBack}>
              <Arrow />
            </button>
          </div>
          <div className="video-title">
            {video.displayName} {moduleName ? `(${moduleName})` : ''}
          </div>
          <div className="right buttons">
            <button className="exit-cur-video-player" onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>
        <div className="video-container">
          <div className="video-spinner-container">
            <JuniSpinner size={80} />
          </div>
          <iframe
            title={video.displayName}
            src={`${video.src}?byline=false&autoplay=true`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            ref={this.iframeRef}
          />
        </div>
      </Resizable>
    );
  }
}
JideVideoPlayer.defaultProps = {
  className: '',
};
JideVideoPlayer.propTypes = {
  className: PropTypes.string,
  curActiveProjectId: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPlayVideo: PropTypes.func,
  onPauseVideo: PropTypes.func,
  onFinishPlayback: PropTypes.func,
  video: PropTypes.shape({}).isRequired,
};
export default JideVideoPlayer;
