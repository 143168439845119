import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addVideoToStudentRecentVideos } from 'services/videos';
import {
  postVideoStarted,
  postVideoEnded,
  postVideoPaused,
} from 'services/user-events/student-events';

import { Chevron, PlayOutlineIcon, XIcon } from 'components/Icons';
import { JideVideoPlayer } from 'components/jide';
import { NewButton, Icon } from 'core-components';
import VideoDuration from 'components/VideoDuration';
import './jide_videos_widget.css';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { VideoOpenButton } from '..';

class JideVideoOption extends Component {
  handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick(this.props._id);
  };

  render() {
    const { displayName, duration, src } = this.props;
    return (
      <div className="jide-video-option">
        <div className="video-container">
          <iframe
            title={displayName}
            src={`${src}?byline=false&controls=false&title=false`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
          <div className="video-overlay" onClick={this.handleClick}>
            <PlayOutlineIcon />
          </div>
        </div>
        {(displayName.length > 0 || duration > 0) && (
          <div className="video-info">
            {displayName.length > 0 && (
              <div className="video-title" onClick={this.handleClick}>
                {displayName}
              </div>
            )}
            {duration > 0 && <VideoDuration duration={duration} />}
          </div>
        )}
      </div>
    );
  }
}
JideVideoOption.defaultProps = {
  displayName: '',
  duration: 0,
};
JideVideoOption.propTypes = {
  _id: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  duration: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

class JideVideosWidget extends Component {
  state = {
    curModuleId: this.props.tab.tabNav.module,
    curVideoId: null,
    isHidden: true,
    isPlaying: false,
  };
  videoOptionsRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    if (
      this.videoOptionsRef.current &&
      (prevState.curModuleId !== this.state.curModuleId ||
        (prevState.isHidden && !this.state.isHidden) ||
        (!this.state.isHidden && !prevState.curVideoId && this.state.curVideoId))
    ) {
      this.videoOptionsRef.current.scrollLeft = 1;
      this.videoOptionsRef.current.scrollLeft = 0;
    }
  }

  goBackToVideoSelect = () => {
    this.setState({ curVideoId: null });
  };

  toggleHidden = () => {
    if (this.props.toggleSiblingWidgets) {
      this.props.toggleSiblingWidgets();
    }
    this.setState(prevState => ({
      isHidden: !prevState.isHidden,
      curVideoId: !prevState.isHidden ? null : prevState.curVideoId,
    }));
  };

  setCurrentModuleForVideos = moduleId => {
    this.setState({ curModuleId: moduleId });
  };

  onSelectModule = e => {
    const selectedModuleId = e.currentTarget.value;
    this.setCurrentModuleForVideos(selectedModuleId);
  };

  onSelectVideo = videoId => {
    this.setState({ curVideoId: videoId });
  };

  onPlayVideo = (e, video) => {
    postVideoStarted({
      studentId: this.props.student._id,
      videoId: this.state.curVideoId,
      moduleId: this.state.curModuleId,
    });

    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true });
    }
    if (this.props.learnerAnalyticsEnabled) {
      const { curVideoId, curModuleId } = this.state;
      const { idLookup, student } = this.props;
      const module = idLookup[curModuleId];
      const course = idLookup[module.properties.courseId];
      addVideoToStudentRecentVideos(
        student._id,
        course.id,
        course.properties.courseType,
        curModuleId,
        curVideoId,
      );

      if (e && e.seconds <= 0.5) {
        JuniAnalytics.track('video_played_from_jide', {
          studentId: this.props.student._id,
          videoId: video._id,
          videoTitle: video.displayName,
          associatedModuleId: this.state.curModuleId,
          where: 'JideVideosWidget',
        });
      }
    }
  };

  onPauseVideo = () => {
    if (this.state.isPlaying) {
      this.setState({ isPlaying: false });

      postVideoPaused({
        studentId: this.props.student._id,
        videoId: this.state.curVideoId,
        moduleId: this.state.curModuleId,
      });
    }
  };

  onFinishPlayback = (e, video) => {
    postVideoEnded({
      studentId: this.props.student._id,
      videoId: this.state.curVideoId,
      moduleId: this.state.curModuleId,
    });

    if (this.props.learnerAnalyticsEnabled) {
      JuniAnalytics.track('learner_finished_video', {
        studentId: this.props.student._id,
        videoId: video._id,
        videoTitle: video.displayName,
        associatedModuleId: this.state.curModuleId,
        where: 'JideVideosWidget',
      });
    }
  };

  render() {
    const {
      activeNav,
      className,
      idLookup,
      student,
      newHorizons = false,
    } = this.props;
    const { curModuleId, curVideoId, isHidden, isPlaying } = this.state;

    if (!student) {
      return null;
    }

    const module = idLookup[curModuleId];
    const course = module ? idLookup[module.properties.courseId] : null;
    const jideStudent = idLookup[student._id];
    let prevModule = null;
    let nextModule = null;
    let prevModuleWithVideos = null;
    let nextModuleWithVideos = null;
    if (course && module) {
      const courseModules = course.children || [];
      const curModuleIndex = courseModules.findIndex(mod => mod.id === curModuleId);

      // find previous module that has at least one unlocked video
      for (let i = curModuleIndex - 1; i > -1; i -= 1) {
        const m = courseModules[i];
        if (
          m.properties.videos.length > 0 &&
          jideStudent &&
          m.id in jideStudent.unlocked
        ) {
          prevModuleWithVideos = m;
          break;
        }
      }

      // find next module that has at least one unlocked video
      for (let i = curModuleIndex + 1; i < courseModules.length; i += 1) {
        const m = courseModules[i];
        if (
          m.properties.videos.length > 0 &&
          jideStudent &&
          m.id in jideStudent.unlocked
        ) {
          nextModuleWithVideos = m;
          break;
        }
      }

      prevModule = curModuleIndex > 0 ? courseModules[curModuleIndex - 1] : null;
      nextModule =
        curModuleIndex < courseModules.length - 1
          ? courseModules[curModuleIndex + 1]
          : null;
    }

    const unlockedVideos =
      module && jideStudent && module.id in jideStudent.unlocked
        ? module.properties.videos
        : [];

    const videoOptionsHtml = (
      <div
        className={`video-options ${unlockedVideos.length > 3 ? 'scrollable' : ''} ${
          className || ''
        }`}
        ref={this.videoOptionsRef}
      >
        {unlockedVideos.length === 0 ? (
          <div className="no-videos-msg">
            No videos available
            <br />
            for this module yet!
          </div>
        ) : (
          unlockedVideos.map(video => (
            <JideVideoOption
              key={video._id}
              {...video}
              onClick={this.onSelectVideo}
            />
          ))
        )}
      </div>
    );

    const curVideo = curVideoId ? idLookup[curVideoId] : null;

    return (
      <div className={`jide-videos-widget ${className || ''}`}>
        {isHidden && !newHorizons && (
          <VideoOpenButton className="open-video-select" onClick={this.toggleHidden}>
            <span className="widget__title">Watch</span>
            <div className="widget-icon-container">
              <FontAwesomeIcon icon={['fab', 'youtube']} />
            </div>
          </VideoOpenButton>
        )}
        {isHidden && newHorizons && (
          <NewButton
            variant="secondary"
            size="xsmall"
            renderIconLeft={props => <Icon.FilmBoard {...props} />}
            onClick={this.toggleHidden}
          >
            Watch
          </NewButton>
        )}
        <div
          className={newHorizons ? 'z-10' : ''}
          style={
            newHorizons ? { position: 'fixed', right: '1rem', bottom: '4rem' } : {}
          }
        >
          {!isHidden && !curVideoId && (
            <div className="video-selector">
              <div className="header">
                <div
                  className={`module-name ${
                    module && module.properties.videos.length === 0
                      ? 'no-videos'
                      : ''
                  }`}
                >
                  {module ? module.displayName : ''}
                </div>
                <div className="close-video-select-btn-container">
                  <button className="close-video-select" onClick={this.toggleHidden}>
                    <XIcon />
                  </button>
                </div>
              </div>
              <div className="content">
                <button
                  className="select-module"
                  onClick={this.onSelectModule}
                  value={prevModuleWithVideos ? prevModuleWithVideos.id : ''}
                  disabled={!prevModuleWithVideos}
                >
                  <Chevron orientation="left" />
                  <div className="module-name">
                    {prevModuleWithVideos
                      ? prevModuleWithVideos.properties.name
                      : prevModule
                      ? prevModule.properties.name
                      : ''}
                  </div>
                </button>
                {videoOptionsHtml}
                <button
                  className="select-module"
                  onClick={this.onSelectModule}
                  value={nextModuleWithVideos ? nextModuleWithVideos.id : ''}
                  disabled={!nextModuleWithVideos}
                >
                  <Chevron orientation="right" />
                  <div className="module-name">
                    {nextModuleWithVideos
                      ? nextModuleWithVideos.properties.name
                      : nextModule
                      ? nextModule.properties.name
                      : ''}
                  </div>
                </button>
              </div>
            </div>
          )}
          {!isHidden && curVideo && (
            <JideVideoPlayer
              key={curVideo._id}
              curActiveProjectId={activeNav.project}
              isPlaying={isPlaying}
              moduleName={module ? module.properties.name : ''}
              onClose={this.toggleHidden}
              onGoBack={this.goBackToVideoSelect}
              onPauseVideo={this.onPauseVideo}
              onPlayVideo={this.onPlayVideo}
              onFinishPlayback={this.onFinishPlayback}
              video={curVideo}
            />
          )}
        </div>
      </div>
    );
  }
}
JideVideosWidget.defaultProps = {
  className: '',
  learnerAnalyticsEnabled: false,
};
JideVideosWidget.propTypes = {
  activeNav: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  idLookup: PropTypes.shape({}).isRequired,
  learnerAnalyticsEnabled: PropTypes.bool,
  student: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  tab: PropTypes.shape({
    tabNav: PropTypes.shape({
      module: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  toggleSiblingWidgets: PropTypes.func,
};
export default JideVideosWidget;
