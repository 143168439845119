import { ORDERED_TRACKS_LIST } from 'constants/tracks';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';

const nonJuniJrCourses = ORDERED_TRACKS_LIST.filter(course => course !== 'juni_jr');

export const PRICING_PLANS = [
  {
    // https://dashboard.stripe.com/products/prod_BUk5S80qsrCpSN
    stripeDisplayName: 'Monthly Pass, 1x per Week, Private',
    planId: '6-month-1x-private',
    displayName: '4 Private Classes / month',
    planType: 'private',
    price: 250,
    deepLinkId: '6QW5UsTseg',
    course: '',
    allowedCourses: nonJuniJrCourses,
    monthlySessionCount: 4,
  },
  {
    // https://dashboard.stripe.com/products/prod_BYwUpAUV0TVipt
    stripeDisplayName: 'Monthly Pass, 2x per Week, Private',
    planId: '6-month-2x-private-450',
    displayName: '8 Private Classes / month',
    planType: 'private',
    price: 450,
    deepLinkId: 'S8j3WGp8aF',
    course: '',
    allowedCourses: nonJuniJrCourses,
    monthlySessionCount: 8,
  },
];

export const USACO_PRICES = {
  '1': 300,
  '2': 550,
  '3': 800,
};

export const JUNI_JR_PRICES = {
  '1': 130,
  '2': 260,
  '3': 390,
  '4': 520,
};

export const CORE_PRICES = {
  '0': 29, // on demand price
  '0.5': 140,
  '1': 250,
  '2': 450,
  '3': 650,
  '4': 900,
  '5': 1125,
  '6': 1350,
  '7': 1575,
  '8': 1800,
  '9': 2025,
  '10': 2250,
  '11': 2475,
  '12': 2700,
  '13': 2925,
  '14': 3150,
  '15': 3375,
};

export const CORE_PRICING_PLANS = Object.entries(CORE_PRICES).map(
  ([frequency, price]) =>
    frequency > 0
      ? {
          planId: `prod_core_${
            frequency === '0.5' || frequency === '.5' ? 'biweekly' : frequency
          }`,
          displayName: `Monthly Membership (${
            frequency === '0.5' || frequency === '.5'
              ? '2x monthly'
              : `${frequency}x per week`
          })`,
          weeklyFrequency: Number(frequency),
          price,
        }
      : {
          planId: 'prod_KEVJaM3RHzTFDL',
          displayName: 'On Demand Unlimited',
          weeklyFrequency: Number(frequency), // this is 0 for on demand
          price,
        },
);

export const CORE_PRICING_PLANS_BY_ID = _.keyBy(CORE_PRICING_PLANS, 'planId');

export const SUPPORTED_SELF_UPGRADE_PLANS = _.pick(CORE_PRICING_PLANS_BY_ID, [
  'prod_core_biweekly',
  'prod_core_1',
  'prod_core_2',
  'prod_core_3',
  'prod_core_4',
  'prod_core_5',
  'prod_core_6',
  'prod_core_7',
  'prod_core_8',
  'prod_core_9',
  'prod_core_10',
  'prod_core_11',
  'prod_core_12',
]);

export const MULTI_COURSE_PLAN_IDS_BY_SINGLE_COURSE_PLAN_ID = {
  '6-month-2x-private-450': '6-month-2x-private-450', // Monthly Pass, 2x per Week, Private
  price_1H0Vn2IsJ8B817wyUBdVnk8u: 'price_1H0XxwIsJ8B817wyocYdeznG', // Mathematics Team Sessions - 8x per month
  plan_Gt60cnODsvGnDA: 'price_1H0XxwIsJ8B817wyocYdeznG', // Computer Science Team Sessions - 8x per Month
};

export const MULTI_COURSE_PLAN_IDS = Object.keys(
  MULTI_COURSE_PLAN_IDS_BY_SINGLE_COURSE_PLAN_ID,
);

export const VALID_DEEP_LINK_IDS = PRICING_PLANS.filter(plan => plan.deepLinkId).map(
  plan => plan.deepLinkId,
);

export const PRICING_PLANS_BY_DEEP_LINK_ID = PRICING_PLANS.reduce(
  (agg, cur) =>
    cur.deepLinkId
      ? {
          ...agg,
          [cur.deepLinkId]: cur,
        }
      : agg,
  {},
);

export const PRICING_PLANS_BY_ID = PRICING_PLANS.reduce(
  (agg, cur) => ({
    ...agg,
    [cur.planId]: cur,
  }),
  {},
);

// TODO: pull this product directly from Stripe
export const ASYNC_PLAN_PRODUCT = {
  productId: 'prod_KEVJaM3RHzTFDL',
  price: 29,
  name: 'Monthly Membership (On Demand learning with live help)',
  displayName: 'On Demand Unlimited',
  description: 'Unlimited Access to On Demand Library',
  key: 'on_demand',
};

export const CLASS_FREQ_METADATA_KEY_TO_DISPLAY_NAME = {
  cs_weeklyFrequency: SUBJECT_DISPLAY_NAMES[SUBJECTS.COMPUTER_SCIENCE],
  math_weeklyFrequency: SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS],
  english_weeklyFrequency: SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH],
  investing_weeklyFrequency: SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING],
  science_weeklyFrequency: SUBJECT_DISPLAY_NAMES[SUBJECTS.SCIENCE],
  usaco_weeklyFrequency: 'USACO',
  juniJr_weeklyFrequency: 'Juni Jr',
};

export const CORE_PRODUCT_METADATA_KEYS = [
  'cs_weeklyFrequency',
  'math_weeklyFrequency',
  'english_weeklyFrequency',
  'investing_weeklyFrequency',
  'science_weeklyFrequency',
];

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatPrice = price => priceFormatter.format(price);

export const STRIPE_BOOTCAMP_PRODUCT_KEY =
  process.env.NODE_ENV === 'production'
    ? 'prod_L9oZXMGEQ578N9'
    : 'prod_L9X8JyzKAcnxV5';
