import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PlusBullet = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.125 4.625H4.625V7.125H3.375V4.625H0.875V3.375H3.375V0.875H4.625V3.375H7.125V4.625Z" />
  </svg>
);

const MinusBullet = () => (
  <svg width="8" height="2" viewBox="0 0 8 2" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.625 1.625H7.125V0.375H4.625H3H0.875V1.625H3.375H4.625Z" />
  </svg>
);

const TreeNode = ({ children, hasChildren, subtree, opened, onToggle }) => (
  <div
    className={classNames({
      'ui-tree-node': true,
      'ui-tree-node-end': !hasChildren || !opened,
    })}
  >
    <div className="ui-tree-label">
      <div className="ui-tree-bullet-container">
        {hasChildren ? (
          <div
            onClick={onToggle}
            className={classNames({
              'ui-tree-bullet': true,
              'ui-opened': opened,
              'ui-collapsed': !opened,
            })}
          >
            {opened ? <MinusBullet /> : <PlusBullet />}
          </div>
        ) : (
          <div className="ui-tree-bullet" />
        )}
      </div>
      <div className="ui-tree-node-link">{children}</div>
    </div>
    {opened && hasChildren ? subtree : null}
  </div>
);

TreeNode.propTypes = {
  children: PropTypes.node.isRequired,
  hasChildren: PropTypes.any,
  subtree: PropTypes.node,
  opened: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default TreeNode;
