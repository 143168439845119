import styled from 'styled-components';

/* This element is shared by all child views: inserted into 
  all content to keep views standard width */
export const ForcedWidth = styled.div`
  width: 50vw;
  @media all and (max-width: 600px) {
    width: 75vw;
  }
`;

export const StyledError = styled.div`
  color: red;
  font-size: 0.75rem;
  padding-top: 0.25rem;
  margin-left: 0.25rem;
`;

export const Label = styled.div.attrs<any>({
  className: 'mt-4 mb-3 ml-1 text-sm text-blue-gray-500',
})``;

export const ButtonRow = styled.div.attrs({
  className: 'flex pt-8',
})<any>`
  justify-content: ${props => (props.oneButton ? 'flex-end' : 'space-between')};
`;

export const RowWrapper = styled.div.attrs({
  className: 'flex justify-between py-0',
})``;

export const ResponsiveRowWrapper = styled(RowWrapper)`
  max-width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

/* 
This component adds styles to nested formIK text areas.
TODO: Styles copy pasted from components/ui/ index.css, 
We could potentially relocate this code there. 
It does differ slightly from normal InputField styles, 
as the form must explictly write juni-textarea in className.
*/
export const DetailsFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  .juni-textarea,
  .juni-field {
    resize: none;
    background: #fbfdff;
    border: 1px solid hsl(201, 70%, 50%);
    border-radius: 0.5rem;
    box-shadow: inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
    padding: 0.75rem;
    width: 100%;

    &:focus {
      box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
        inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
    }

    &:disabled {
      background: #e7eaec;
      border: 2px solid #adc2ce;
      color: #b7c0c7;
    }

    &-error {
      background: #ffe9e8;
      border-color: #da0f0f;
      box-shadow: inset 0px 0.25px 1rem #ddeef7;
      color: #a73434;
    }

    &::placeholder {
      color: #adc2ce;
    }

    &-error::placeholder {
      color: #a48080;
    }
  }
`;

export const ImgWrapper = styled.a.attrs({
  className:
    'inline-block w-32 h-32 m-2 overflow-hidden rounded-2xl cursor-pointer flex-shrink-0',
})<any>`
  background-size: cover;
  background-image: url(${props => props.src});
  :hover,
  :focus {
    box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.7),
      inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.1);
  }
`;
