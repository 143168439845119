import React from 'react';

export interface ColorButtonProps {
  color: string;
}

const ColorButton: React.FC<ColorButtonProps> = ({ color }) => (
  <div
    className="rounded-full cursor-pointer"
    style={{ width: 18, height: 18, backgroundColor: color }}
  />
);

export default ColorButton;
