import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import moment from 'moment';
import classNames from 'classnames';
import {
  validateEventDescription,
  validateEventTitle,
  validateEventLink,
} from 'utils/validation';
import {
  JuniCommunityEventType,
  useCreateJuniCommunityEventMutation,
  useUpdateJuniCommunityEventMutation,
} from 'generated/graphql';

import { parseError } from 'utils/errors';
import { Button, Message } from 'core-components';
import { DatePickerField, ReactSelectField } from 'components/ui';
import SpinnerV2 from 'components/SpinnerV2';
import {
  FOREIGN_TIMEZONES,
  US_TIMEZONES,
  DEFAULT_TIMEZONE,
} from 'constants/timezones';
import useClickOutside from 'hooks/outside';
import styled from 'styled-components/macro';
import { ErrorText, EventFormWrapper, Label, ModalWidthSetter } from './styles';

const TIMEZONE_OPTIONS = [
  {
    label: 'United States',
    options: US_TIMEZONES.map(tz => ({
      label: tz.displayName,
      value: tz.value.toString(),
    })),
  },
  ...FOREIGN_TIMEZONES.map(tz => ({
    label: tz.displayName,
    value: tz.value.toString(),
  })),
];

const ToolTipText = styled.div.attrs({
  className:
    'absolute flex left-0 top-5 p-2 z-50 rounded-md cursor-default bg-blue-gray-50 font-normal break-words',
})`
  min-width: 8rem;
`;
const ToolTip: React.FC<{ toolTipText: string }> = ({ toolTipText }) => {
  const [clicked, setClicked] = useState(false);
  const clickRef = React.useRef<HTMLSpanElement>(null);

  useClickOutside(clickRef, () => {
    setClicked(false);
  });
  return (
    <span
      className="ml-2 w-4 h-4 relative flex justify-center cursor-pointer bg-blue-gray-50 rounded-full text-xs"
      ref={clickRef}
      onClick={() => setClicked(true)}
    >
      ?{clicked && <ToolTipText>{toolTipText}</ToolTipText>}
    </span>
  );
};

interface EventFormValues {
  title: string;
  startDateTime?: string;
  endDateTime?: string;
  description: string;
  eventLink?: string;
}

interface EventEditorProps {
  instructorUserId?: string;
  juniClubId?: string;
  juniClubCoverUrl?: string;
  onClose: () => void;
  /* Event passed in if in edit mode */
  juniCommunityEvent?: JuniCommunityEventType;
}

const EventEditor: React.FC<EventEditorProps> = ({
  instructorUserId,
  juniClubId,
  juniCommunityEvent,
  juniClubCoverUrl,
  onClose,
}) => {
  const [createEvent] = useCreateJuniCommunityEventMutation();
  const [updateEvent] = useUpdateJuniCommunityEventMutation();
  const [submissionError, setSubmissionError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [timezone, setTimezone] = useState(
    moment.tz.guess() || 'America/Los_Angeles',
  );
  const validateInputs = (values: EventFormValues) => {
    const titleError = validateEventTitle(values.title);
    const descriptionError = validateEventDescription(values.description);
    const linkError = values.eventLink ? validateEventLink(values.eventLink) : '';
    const startDateError = !values.startDateTime ? 'Please select a start date' : '';
    const endDateError = !values.endDateTime ? 'Please select an end date.' : '';

    const hasStartAndEnd = values.startDateTime && values.endDateTime;
    const startBeforeNow = moment()
      .tz(timezone)
      .isAfter(moment.tz(values.startDateTime, DEFAULT_TIMEZONE).tz(timezone, true));

    const startTimeAfterEnd =
      values.startDateTime &&
      values.endDateTime &&
      new Date(values.startDateTime) > new Date(values.endDateTime);
    const dateFormat = 'dddd, MMMM Do YYYY, h:mm a';
    const startSameAsEnd =
      hasStartAndEnd &&
      moment(values.endDateTime).format(dateFormat) ===
        moment(values.startDateTime).format(dateFormat);
    const startBeforeNowError = startBeforeNow
      ? 'The start date cannot be in the past'
      : '';
    const datesFlipped =
      startTimeAfterEnd || startSameAsEnd
        ? 'The end date must be after the start date.'
        : '';
    if (
      titleError ||
      descriptionError ||
      startDateError ||
      endDateError ||
      datesFlipped ||
      linkError ||
      startBeforeNowError
    ) {
      return {
        title: titleError,
        description: descriptionError,
        startDateTime: startDateError || startBeforeNowError,
        endDateTime: endDateError || datesFlipped,
        eventLink: linkError,
      };
    }
    return {};
  };
  const initialValues = {
    title: juniCommunityEvent?.title || '',
    startDateTime: juniCommunityEvent?.startDateTime
      ? moment
          .tz(juniCommunityEvent?.startDateTime, timezone)
          .tz(DEFAULT_TIMEZONE, true)
          .format()
      : undefined,
    endDateTime: juniCommunityEvent?.endDateTime
      ? moment
          .tz(juniCommunityEvent?.endDateTime, timezone)
          .tz(DEFAULT_TIMEZONE, true)
          .format()
      : undefined,
    description: juniCommunityEvent?.description || '',
    eventLink: juniCommunityEvent?.eventLink || '',
  };
  const handleClose = () => {
    onClose();
  };
  const handleSubmit = async (values: EventFormValues) => {
    const formattedStartDate = moment
      .tz(values.startDateTime, DEFAULT_TIMEZONE)
      .tz(timezone, true)
      .format();
    const formattedEndDate = moment
      .tz(values.endDateTime, DEFAULT_TIMEZONE)
      .tz(timezone, true)
      .format();
    setSubmitting(true);
    // create mode
    if (!juniCommunityEvent) {
      try {
        await createEvent({
          variables: {
            input: {
              title: values.title,
              startDateTime: formattedStartDate,
              endDateTime: formattedEndDate,
              description: values.description,
              eventLink: values.eventLink,
              juniClubIds: [juniClubId],
              creatorInstructorUserId: instructorUserId,
              /* Below parsing is due to the way clubs covers are stored: as _full and an _thumbnail in s3 */
              thumbnailUrl: juniClubCoverUrl?.replace('_full', '_thumbnail') || '',
            },
          },
        });
        setSubmitting(false);
        handleClose();
      } catch (e) {
        setSubmissionError(parseError(e, `Sorry, that didn't work.`));
        console.log(e);
        setSubmitting(false);
      }
    }
    // edit mode
    else {
      try {
        await updateEvent({
          variables: {
            input: {
              eventId: juniCommunityEvent._id,
              title: values.title,
              startDateTime: formattedStartDate,
              endDateTime: formattedEndDate,
              description: values.description,
              eventLink: values.eventLink,
              instructorUserId,
              /* Below parsing is due to the way clubs covers are stored: as _full and an _thumbnail in s3 */
              thumbnailUrl: juniClubCoverUrl?.replace('_full', '_thumbnail') || '',
            },
          },
        });
        setSubmitting(false);
        handleClose();
      } catch (e) {
        setSubmitting(false);
        setSubmissionError(parseError(e, `Sorry, that didn't work.`));
        console.log(e);
      }
    }
  };

  return (
    <EventFormWrapper>
      <ModalWidthSetter>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validateInputs}
        >
          {({ errors, touched }) => {
            const showStartDateError = errors.startDateTime && touched.startDateTime;
            const showEndDateError = errors.endDateTime && touched.endDateTime;
            const showTitleError = errors.title && touched.title;
            const showDescriptionError = errors.description && touched.description;
            const showLinkError = errors.eventLink && touched.eventLink;
            return (
              <Form>
                <div>
                  <Label className="ui-field-label -mt-1">Title</Label>
                  {showTitleError ? (
                    <ErrorMessage name="title">
                      {msg => <ErrorText>{msg}</ErrorText>}
                    </ErrorMessage>
                  ) : undefined}
                  <Field
                    className={classNames('juni-field h3', {
                      'juni-field-error': showTitleError,
                    })}
                    name="title"
                    placeholder="Enter a title for this event"
                  />
                </div>
                <div className="flex w-full">
                  <div className="w-full">
                    <Label
                      className={classNames('ui-field-label', {
                        'pb-7': showEndDateError && !showStartDateError,
                      })}
                    >
                      Start Date / Time
                    </Label>
                    <DatePickerField.Formik
                      name="startDateTime"
                      showTimeSelect
                      timeFormat="h:mm a"
                      dateFormat="ddd, MMM Do, YYYY h:mm a"
                      fluidWidth
                      placeholderText="Enter a start date and time"
                      minDate={moment()}
                    />
                  </div>
                  <div className="w-full ml-4">
                    <Label
                      className={classNames('ui-field-label', {
                        'pb-7': showStartDateError && !showEndDateError,
                      })}
                    >
                      End Date / Time
                    </Label>
                    <DatePickerField.Formik
                      name="endDateTime"
                      timeFormat="h:mm a"
                      showTimeSelect
                      dateFormat="ddd, MMM Do, YYYY h:mma"
                      fluidWidth
                      placeholderText="Enter an end date and time"
                      minDate={moment()}
                    />
                  </div>
                  <div className="w-full ml-4">
                    <Label
                      className={classNames('ui-field-label', {
                        'pb-7': showEndDateError || showStartDateError,
                      })}
                    >
                      <span className="flex flex-row items-center">
                        Timezone
                        <ToolTip toolTipText="We'll show the date and time to everyone in their local timezone!" />
                      </span>
                    </Label>
                    <ReactSelectField
                      name="timezone"
                      options={TIMEZONE_OPTIONS}
                      value={timezone}
                      // See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange={(tz: string) => setTimezone(tz)}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          border: '1px solid hsl(201, 70%, 50%)',
                          height: '3rem',
                          backgroundColor: '#fbfdff',
                          borderRadius: '0.5rem',
                        }),
                        group: (provided: any) => ({
                          ...provided,
                          marginLeft: '0rem',
                        }),
                        placeholder: (provided: any) => ({
                          ...provided,
                          color: '#adc2ce',
                        }),
                        groupHeading: (provided: any) => ({
                          ...provided,
                          marginLeft: '0rem',
                        }),
                        menu: (provided: any) => ({
                          ...provided,
                          zIndex: 3,
                        }),
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Label className="ui-field-label">
                    <span className="flex flex-row items-center">
                      <span>Event Link</span>
                      <span className="ml-1 text-xs font-normal">(optional)</span>
                    </span>
                  </Label>
                  {showLinkError ? (
                    <ErrorMessage name="eventLink">
                      {msg => <ErrorText>{msg}</ErrorText>}
                    </ErrorMessage>
                  ) : undefined}
                  <Field
                    className={classNames('juni-field h3', {
                      'juni-field-error': showLinkError,
                    })}
                    name="eventLink"
                    placeholder="Enter your meeting link..."
                  />
                </div>
                <div>
                  <Label className="ui-field-label">Description</Label>
                  {showDescriptionError ? (
                    <ErrorMessage name="description">
                      {msg => <ErrorText>{msg}</ErrorText>}
                    </ErrorMessage>
                  ) : undefined}
                  <Field
                    as="textarea"
                    rows={10}
                    className={classNames('w-full juni-field', {
                      'juni-field-error': showDescriptionError,
                    })}
                    name="description"
                    placeholder="Enter a description"
                  />
                </div>
                {submissionError && (
                  <Message status="error" className="mt-4">
                    {submissionError}
                  </Message>
                )}
                <div className="flex justify-end mt-4">
                  <Button type="submit" hasArrowIcon disabled={submitting}>
                    {submitting ? (
                      <SpinnerV2 />
                    ) : juniCommunityEvent ? (
                      'Update Event'
                    ) : (
                      'Create Event'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalWidthSetter>
    </EventFormWrapper>
  );
};

export default EventEditor;
