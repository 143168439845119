import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { HomeIcon } from 'components/Icons';
import { JuniLogo } from 'components/brand-assets';
import useDimensions from 'hooks/dimensions';
import { useUserContext } from 'modules/UserContext';
import { NavLeftButtonLink } from './NavLeftButton';
import NavSettingsButton from './NavSettingsButton';

const NavWrapper = styled.div<any>`
  background-color: white;
  color: hsl(208, 100%, 53%);
  box-shadow: ${props => (props.noShadow ? 'none' : '0px 5px 10px -8px #002f5514;')};
  height: 60px;
  position: sticky;
  top: ${props => (props.isImpersonationMode ? 64 : 0)};
  z-index: 1000;
  user-select: none;
`;

const NavContentsDesktop = styled.div<any>`
  max-width: ${props => !props.homeView && !props.jideView && `1280px`};
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 8px;
`;

const NavContentsMobile = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
`;

const NavLogoDesktop = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;

  & img {
    width: 150px;
  }
  & > a {
    display: flex;
    align-items: center;
  }
`;

const NavLogoMobile = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;

  & .icon {
    padding: 0;
  }
  & img {
    width: 24px;
  }
  & > a {
    display: flex;
    align-items: center;
  }
`;

const NavLeft = styled.div`
  display: flex;
  height: 100%;
`;

const NavRight = styled.div<any>`
  display: flex;
  height: 100%;
  padding-right: ${props => props.jideView && '8px'};
  align-items: stretch;
  box-sizing: border-box;
`;

const NavLinks = styled.div<any>`
  height: 100%;
  display: flex;
  margin-left: 12px;
  @media (min-width: 921px) {
    margin-left: 0px;
  }
`;

interface NavProps {
  baseRoute: string;
  pathname: string;
  settingsButtonOptions: React.ReactNode[];
  homePaths?: string[];
  navLeftLinks?: React.ReactNode;
  navRightLinks?: React.ReactNode;
  fullWidth?: boolean;
  noShadow?: boolean;
  isAdmin?: boolean;
}

interface HomeButtonProps {
  active: boolean;
  baseRoute: string;
}

const HomeButton: FC<HomeButtonProps> = ({ baseRoute, active }) => (
  <NavLeftButtonLink
    active={active}
    text="Home"
    icon={<HomeIcon active={active} />}
    link={baseRoute}
  />
);

const Nav: FC<NavProps> = ({
  baseRoute,
  pathname,
  homePaths,
  navLeftLinks,
  navRightLinks,
  noShadow,
  settingsButtonOptions,
}) => {
  const { width } = useDimensions();
  const { isImpersonationMode } = useUserContext();

  const mobileView = width <= 920;
  const smallMobileView = width <= 480;
  const jideView = pathname.includes('project');
  const homeView = homePaths
    ? homePaths.includes(pathname)
    : pathname === baseRoute || pathname === `${baseRoute}/`;

  const NavContents = mobileView ? NavContentsMobile : NavContentsDesktop;
  const NavLogo = mobileView ? NavLogoMobile : NavLogoDesktop;

  return (
    <NavWrapper noShadow={noShadow} isImpersonationMode={isImpersonationMode}>
      <NavContents homeView={homePaths && homeView} jideView={jideView}>
        <NavLeft>
          {!smallMobileView && (
            <NavLogo>
              <Link to="/">
                <JuniLogo className="pt-1 pl-1.5" />
              </Link>
            </NavLogo>
          )}
          <NavLinks desktopView={!mobileView}>
            <HomeButton active={homeView} baseRoute={baseRoute} />
            {navLeftLinks}
          </NavLinks>
        </NavLeft>
        <NavRight jideView={jideView}>
          {navRightLinks}
          {settingsButtonOptions.length > 0 && (
            <NavSettingsButton dropdownOptions={settingsButtonOptions} />
          )}
        </NavRight>
      </NavContents>
    </NavWrapper>
  );
};

Nav.defaultProps = {
  fullWidth: false,
  noShadow: false,
};

export default Nav;
