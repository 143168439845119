import styled from 'styled-components/macro';

/* 
This component adds styles to nested formIK text areas.
TODO: Styles copy pasted from components/ui/ index.css, 
We could potentially relocate this code there. 
*/
export const EventFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* This below is a hack to move the react calendar icon to the correct place: it defaults to 80px */
  .react-datepicker__navigation--next {
    right: 110px;
  }
  .juni-field {
    resize: none;
    background: #fbfdff;
    border: 1px solid hsl(201, 70%, 50%);
    border-radius: 0.5rem;
    box-shadow: inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
    padding: 0.75rem;
    width: 100%;

    &:focus {
      box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
        inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
    }

    &:disabled {
      background: #e7eaec;
      border: 2px solid #adc2ce;
      color: #b7c0c7;
    }

    &-error {
      background: #ffe9e8;
      border-color: #da0f0f;
      box-shadow: inset 0px 0.25px 1rem #ddeef7;
      color: #a73434;
    }

    &::placeholder {
      color: #adc2ce;
    }

    &-error::placeholder {
      color: #a48080;
    }
  }
`;

export const Label = styled.div.attrs<any>({
  className: 'mt-4 mb-2 ml-1 text-sm text-blue-gray-500',
})``;

export const ErrorText = styled.div.attrs({
  className: 'text-red-900 text-sm pb-2 ml-1',
})``;

export const ModalWidthSetter = styled.div`
  width: 55vw;
  @media all and (max-width: 600px) {
    width: 95vw;
  }
`;
