// Logic from text tool 1 used to migrate existing text
// so it can be used by text tool v2

const newSVGText = (board, data) => {
  const text = board.newSVGChild('text');
  text.style.fill = data.color || 'black';
  text.setAttribute('dy', '1.2em');
  text.setAttribute('x', data.x);
  text.setAttribute('y', data.y);
  if (data.text) {
    text.textContent = data.text;
  }
  text.id = data.elemId || data.id;
  board.appendChild(text);
  return text;
};

const updateSVGText = (textElem, text) => {
  // this early return prevents an error when old tab and new tab are both open
  if (!text) return;
  const textContent =
    text[text.length - 1] === '\n' ? text.substring(0, text.length - 1) : text;
  const textLines = textContent.split('\n');
  if (textLines.length > 1) {
    textElem.innerHTML = textLines
      .map(() => `<tspan x="${textElem.getAttribute('x')}" dy="1.2em" />`)
      .join('');
    for (let i = 0; i < textElem.children.length; i += 1) {
      textElem.children[i].textContent = `${textLines[i]}${
        i !== textLines.length - 1 ? '\n' : ''
      }`;
    }
  } else {
    textElem.textContent = textContent;
  }
};

const getMigratedData = (canvas, board, data) => {
  const PADDING_PER_SIDE = 32;
  const BORDER_PER_SIDE = 2;
  const VERTICAL_OFFSET = 4; // helps line up new text vertically
  const migratedData = { ...data };

  // render original text
  const textElem = newSVGText(board, data);
  canvas.appendChild(textElem);
  updateSVGText(textElem, data.text);
  // grab bounding box and remove rendered text
  const bbox = textElem.getBBox();
  canvas.removeChild(textElem);

  // To account for all the padding added in v2,
  // shift up and to the left, and increase width and height
  const positionAdjustment = -1 * (PADDING_PER_SIDE + BORDER_PER_SIDE);
  migratedData.x = data.x + positionAdjustment;
  migratedData.y = data.y + positionAdjustment + VERTICAL_OFFSET;
  const sizeAdjustment = 2 * (PADDING_PER_SIDE + BORDER_PER_SIDE);
  migratedData.width = bbox.width + sizeAdjustment;
  migratedData.height = bbox.height + sizeAdjustment;

  return migratedData;
};

export default getMigratedData;
