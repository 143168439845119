import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.css';

const Section = ({
  className,
  children,
  title,
  active,
  disabled,
  index,
  onToggle,
  contentClassName,
}) => (
  <div className={classNames('ui-accordion-section', className)}>
    <div
      className={classNames('ui-accordion-title', {
        'ui-active': active,
        'ui-disabled': disabled,
      })}
      onClick={() => onToggle && !disabled && onToggle(index)}
    >
      {active ? '-' : '+'} {title}
    </div>
    {active && (
      <div className={classNames('ui-accordion-content py-2', contentClassName)}>
        {children}
      </div>
    )}
  </div>
);

Section.propTypes = {
  contentClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  active: PropTypes.bool,
  index: PropTypes.number,
  onToggle: PropTypes.func,
};

/**
 * Implements a view structure where a user can toggle content visibility by clicking buttons
 * shaped like plus signs. Content options must be wrapped by `Accordion.Section`.
 *
 * The component in Storybook deliberately has no controls, as the only props
 * that get passed to it are `children` and `className`.
 */
const Accordion = ({ className, children }) => (
  <div className={classNames('ui-accordion', className)}>{children}</div>
);

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Accordion.Section = Section;

export default Accordion;
