import React from 'react';
import { Badge, Icon, NewButton } from 'core-components';
import { JideEnvLayout } from '..';

export interface ProblemsProps {
  /**
   * The label used in the header to name the problem set.
   * Example Question or Problem
   */
  label: string;
  /**
   * Zero based current problem index.
   */
  curProblemIndex: number;
  /**
   * Difficulty property of the current problem.
   */
  curProblemDifficulty?: string;
  /**
   * Total number of problems.
   */
  numProblems: number;
  /**
   * Callback fired with the new index when a problem set navigation button is clicked.
   */
  onProblemIndexChanged: (newIndex: number) => void;
  /**
   * Optional loading state that disables the problem set navigation buttons.
   * Default false.
   */
  isSubmitting?: boolean;
  /**
   * Set to true to render in "full screen" mode with no left content (whiteboard, reading, writing).
   * Example is a pure question set module.
   * Default false.
   */
  fullScreen?: boolean;
  /**
   * When fullScreen is true, render prop for rendering Jide Widgets in the footer.
   */
  renderJideWidgets?: () => React.ReactNode;
}

const Problems: React.FC<ProblemsProps> = ({
  label,
  curProblemIndex,
  curProblemDifficulty,
  numProblems,
  onProblemIndexChanged,
  isSubmitting = false,
  fullScreen = false,
  renderJideWidgets,
  children: problem,
}) => {
  const prevProblemDisabled = curProblemIndex === 0;
  const nextProblemDisabled = curProblemIndex === numProblems - 1;

  const header = (
    <div className="flex items-center font-graphik text-j-dark-600">
      <div className="text-xl leading-8 font-medium">
        {label} {curProblemIndex + 1}/{numProblems}
      </div>
      <div className="ml-4">
        <NewButton
          onClick={() => onProblemIndexChanged(curProblemIndex - 1)}
          size="small"
          variant="secondary"
          disabled={prevProblemDisabled || isSubmitting}
          title="Previous problem"
          icon
        >
          <Icon.ArrowLeft />
        </NewButton>
      </div>
      <div className="ml-2">
        <NewButton
          onClick={() => onProblemIndexChanged(curProblemIndex + 1)}
          size="small"
          variant="secondary"
          disabled={nextProblemDisabled || isSubmitting}
          title="Next problem"
          icon
        >
          <Icon.ArrowRight />
        </NewButton>
      </div>
      {curProblemDifficulty === 'challenge' && (
        <div className="ml-4">
          <Badge backgroundColor="j-blue-100" color="j-blue-600">
            {curProblemDifficulty}
          </Badge>
        </div>
      )}
    </div>
  );

  if (fullScreen) {
    return (
      <JideEnvLayout.FullScreenContent
        header={header}
        renderFooterContent={renderJideWidgets}
      >
        {problem}
      </JideEnvLayout.FullScreenContent>
    );
  }

  return (
    <div className="mx-6 my-4">
      <div>{header}</div>
      {/* TODO - replace with Divider after #1725 */}
      <hr className="border-0 bg-j-purple-100 h-px mt-4 -mx-6" />
      <div className="mt-6 overflow-y-scroll">{problem}</div>
    </div>
  );
};

export default Problems;
