import React, { FC } from 'react';
import classNames from 'classnames';
import { StyledJideEnvLayout, StyledMainContent, StyledSideContent } from './styles';
import FullScreenContent from './FullScreenContent';

interface JideEnvLayoutProps {
  fullScreen?: boolean;
  className?: string;
}

type Statics = {
  SideContent: typeof StyledSideContent;
  MainContent: typeof StyledMainContent;
  FullScreenContent: typeof FullScreenContent;
};

const JideEnvLayout: FC<JideEnvLayoutProps> & Statics = ({
  children,
  fullScreen,
  className,
}) => (
  <StyledJideEnvLayout
    fullScreen={fullScreen}
    className={classNames(
      'flex flex-col sm:flex-row items-stretch w-full h-full overflow-y-hidden',
      className,
    )}
  >
    {children}
  </StyledJideEnvLayout>
);

JideEnvLayout.MainContent = StyledMainContent;
JideEnvLayout.SideContent = StyledSideContent;
JideEnvLayout.FullScreenContent = FullScreenContent;

export default JideEnvLayout;
