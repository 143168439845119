import styled from 'styled-components';
import { jBlue, jPurple, jDark, jGray } from 'theme/colors';
import lightbulb from 'images/lightbulb.svg';
import info from 'images/info.svg';

export const StyledContent = styled.article`
  /* Title's */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 0;
    padding: 1rem 0;
  }

  /* This is the most commonly used */
  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  hr {
    margin: 0;
    border: 0;
    height: 1px;
    background-color: ${jPurple[100]};
  }

  /* Ordered lists, only counts direct descendant list items. */
  ol {
    list-style: none;
    counter-reset: my-counter;
  }

  ol > li {
    counter-increment: my-counter;
  }

  ol > li:not(:first-child) {
    margin-top: 1.5rem;
  }

  /*
  * ::marker does not support CSS properties needed to do this.
  * Turning off the list-style and doing it custom here.
  */
  ol > li::before {
    content: counter(my-counter);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${jBlue[600]};
    background-color: ${jBlue[100]};
    margin-right: 1rem;
    margin-left: -2.5rem;
    font-size: 0.625rem;
    font-weight: 500;
    border-radius: 0.375rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  ol li pre {
    font-family: 'Source Serif Pro', serif;
    margin: 1.5rem 0 1.5rem -2.5rem;
  }

  /* "info" Message pane/boxes */
  ol li pre p::before,
  .info-box::before,
  .activity-objective::before {
    content: url(${lightbulb});
    /* these have to be pixels since the lightbulb svg element has a pixel-based size (12px width) */
    margin-right: 12px;
    margin-left: -24px;
  }

  /* override with info icon */
  .activity-objective::before {
    content: url(${info});
    margin-right: 10px;
  }

  ol li pre p,
  .info-box,
  .activity-objective {
    background-color: ${jBlue[100]};
    /* left has to be in pixels since the svg pseudo-element has a pixel-based size */
    padding: 0.625rem 1rem 0.625rem 36px;
    color: ${jBlue[600]} !important;
    border-radius: 0.5rem;
    border: 0;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  b {
    font-weight: 600;
  }

  ul {
    padding-inline-start: 0;
  }

  ul li {
    color: ${jDark[400]};
    padding-left: 0.5rem;
  }

  ul li::marker {
    content: '•';
  }

  ul li:not(:first-child) {
    margin-top: 0.75rem;
  }

  /* this is more to handle current usage better, normally a line break could be added instead. */
  ol li > ul {
    margin-top: 1rem;
  }

  a {
    color: ${jBlue[400]};
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }

  img,
  video {
    width: 100%;
  }

  /* spaces math typesetting instructions */
  span.katex {
    line-height: 1.8em;
  }

  /* tables */
  table {
    /* reset */
    border: 0 !important;
    border-collapse: separate;
    border-spacing: 0;

    th,
    tr,
    td {
      /* reset */
      border: 0;
      padding: 1rem;
    }

    th {
      text-align: left;
      color: ${jDark[300]};
      text-transform: uppercase;
      background-color: ${jGray[100]};
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 600;
      white-space: nowrap;
    }

    th:first-child,
    td:first-child {
      padding-left: 1.5rem;
    }
    th:last-child,
    td:last-child {
      padding-right: 1.5rem;
    }

    /* borders, have to use borders on cells with above table styling (gets border radius's properly applied) */
    tr:first-child th,
    tr:first-child td {
      border-top: 1px solid ${jDark[100]};
    }
    td,
    th {
      border-bottom: 1px solid ${jDark[100]};
    }

    tr th:first-child,
    tr td:first-child {
      border-left: 1px solid ${jDark[100]};
    }
    tr th:last-child,
    tr td:last-child {
      border-right: 1px solid ${jDark[100]};
    }

    tr:first-child th:last-child,
    tr:first-child td:last-child {
      border-top-right-radius: 0.5rem;
    }
    tr:first-child th:first-child,
    tr:first-child td:first-child {
      border-top-left-radius: 0.5rem;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 0.5rem;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 0.5rem;
    }
  }
`;
