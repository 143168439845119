import React, { FC, useState } from 'react';

import { Message } from 'components/ui';
import { ModalWindow, NewButton } from 'core-components';
import { WideArrow } from 'components/Icons';
import MarkdownText from 'components/MarkdownText';
import SpinnerV2 from 'components/SpinnerV2';

import {
  LoadSessionFeedbackDocument,
  LoadSessionFeedbackQuery,
  useCreateSessionFeedbackMutation,
  useLoadSessionFeedbackQuery,
} from 'generated/graphql';

import Checkmark from 'images/launch_checkmark.svg';

import { TeacherSession } from 'models';

import { humanizeTimeAgo } from 'utils/dates';

import {
  MODAL_TITLE,
  MODAL_DESCRIPTION,
  DEFAULT_STATUS_MESSAGE,
  ERROR_STATUS_MESSAGE,
  NUM_DAYS_FEEDBACK_WINDOW,
} from './constants';

import {
  SubmitButton,
  BackButton,
  FeedbackTextArea,
  CheckmarkWrapper,
  CompletedFeedbackWrapper,
  AuthorAndTimestamp,
} from './styles';

type Status = React.ComponentProps<typeof Message>['status'];

interface SessionFeedbackProps {
  teacherSession: TeacherSession;
  numDaysSinceLogged: number;
  instructorName: string;
}

const SessionFeedback: FC<SessionFeedbackProps> = ({
  teacherSession,
  numDaysSinceLogged,
  instructorName,
}) => {
  const [inProgressFeedback, setInProgressFeedback] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<Status>('warning');

  const [createFeedback] = useCreateSessionFeedbackMutation({
    update: (cache, { data }) => {
      const newSessionFeedback = data?.createSessionFeedback.sessionFeedback;
      if (!newSessionFeedback) {
        return;
      }
      const queryArgs = {
        query: LoadSessionFeedbackDocument,
        variables: { teacherSessionId: newSessionFeedback.teacherSessionId },
      };
      const existingQueryResult = cache.readQuery<LoadSessionFeedbackQuery>(
        queryArgs,
      );
      if (!existingQueryResult?.sessionFeedback) {
        cache.writeQuery({
          ...queryArgs,
          data: {
            sessionFeedback: newSessionFeedback,
          },
        });
      }
    },
  });

  const submitFeedback = async () => {
    setIsSubmitting(true);
    try {
      await createFeedback({
        variables: {
          input: {
            feedback: inProgressFeedback,
            teacherSessionId: teacherSession._id,
          },
        },
      });
      setStatus('success');
    } catch (err) {
      console.error(err);
      setStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    data: feedbackData,
    loading: feedbackLoading,
  } = useLoadSessionFeedbackQuery({
    variables: { teacherSessionId: teacherSession._id },
  });

  const { completedAt, createdAt, feedback, response } =
    feedbackData?.sessionFeedback || {};

  const isEligibleForFeedback =
    teacherSession.completed &&
    !teacherSession.wasMissed &&
    numDaysSinceLogged < NUM_DAYS_FEEDBACK_WINDOW;

  if (feedbackLoading || (!feedback && !isEligibleForFeedback)) {
    return null;
  }

  return (
    <>
      <NewButton
        onClick={
          feedback
            ? () => setIsExpanded(prevIsExpanded => !prevIsExpanded)
            : () => setIsModalOpen(true)
        }
        variant="secondary"
      >
        {`${
          feedback
            ? `${isExpanded ? 'Hide' : 'Show'} feedback that you left`
            : 'Leave feedback'
        } for this session`}
      </NewButton>
      {feedback && isExpanded ? (
        <CompletedFeedbackWrapper>
          <AuthorAndTimestamp>
            <span>You wrote:</span>
            <span>{humanizeTimeAgo(createdAt || '')}</span>
          </AuthorAndTimestamp>
          <MarkdownText text={feedback} displayAsPlainText />
          {response ? (
            <>
              <hr />
              <AuthorAndTimestamp>
                <span>{instructorName || 'The instructor'} responded:</span>
                <span>{humanizeTimeAgo(completedAt || '')}</span>
              </AuthorAndTimestamp>
              <MarkdownText text={response} />
            </>
          ) : null}
        </CompletedFeedbackWrapper>
      ) : null}
      <ModalWindow
        closeModal={() => {
          setIsModalOpen(false);
        }}
        isOpen={isModalOpen}
        title={MODAL_TITLE}
        description={MODAL_DESCRIPTION}
        maxWidth="60rem"
      >
        {status === 'success' ? (
          <CheckmarkWrapper>
            <h2>Your feedback has been saved!</h2>
            <img src={Checkmark} alt="" />
            <BackButton
              onClick={() => setIsModalOpen(false)}
              icon={
                <WideArrow
                  orientation="right"
                  style={{
                    fill: '#fff',
                  }}
                />
              }
            >
              Back to My Work
            </BackButton>
          </CheckmarkWrapper>
        ) : (
          <>
            <h2>Your message</h2>
            <Message status={status} className="text-sm">
              {status === 'error' ? ERROR_STATUS_MESSAGE : DEFAULT_STATUS_MESSAGE}
            </Message>
            <FeedbackTextArea
              name="name"
              value={inProgressFeedback}
              placeholder="Leave your feedback here..."
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setInProgressFeedback(e.target.value)
              }
              rows={8}
            />
            <SubmitButton
              disabled={isSubmitting || inProgressFeedback.length === 0}
              onClick={submitFeedback}
              icon={
                isSubmitting ? (
                  <SpinnerV2 />
                ) : (
                  <WideArrow
                    orientation="right"
                    style={{
                      fill: '#fff',
                    }}
                  />
                )
              }
            >
              Submit
            </SubmitButton>
          </>
        )}
      </ModalWindow>
    </>
  );
};

export default SessionFeedback;
