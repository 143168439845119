import { FC, useState } from 'react';
import {
  InstructorUnavailablePreference,
  useCreateParentRecurringRescheduleRequestMutation,
  useRescheduleClassMutation,
} from 'generated/graphql';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import { AcuityAppointment } from 'models';
import { NewModalWindow } from 'core-components';
import { ACUITY_TYPE_TO_TEXT } from 'constants/acuity';
import {
  isTimeTurnerStageEnabled,
  TimeTurnerStage,
} from 'app/feature_flags/time-turner-deploy-stage-flags';
import { LearnerClassSchedulerBaseProps } from '../types';
import { SingleClassSchedulerModal } from '../components';
import { RecurringClassReschedulerModal } from './components';

// not sure if these css styles are still needed
import '../LearnerClassSchedulerWidget/learner_class_scheduler_widget.css';

enum ReschedulingRequestType {
  SINGLE = 'single',
  RECURRING = 'recurring',
}

interface LearnerClassReschedulerWidgetProps extends LearnerClassSchedulerBaseProps {
  acuityAppointmentId: number;
  isRecurring: boolean;
  appointmentDatetime: string;
  instructorName: string;
  refreshUpcomingSessions: () => void;
  upcomingClasses?: AcuityAppointment[];
}

const LearnerClassReschedulerWidget: FC<LearnerClassReschedulerWidgetProps> = props => {
  const {
    studentFirstName,
    isRecurring,
    acuityAppointmentId,
    appointmentTypeID,
    appointmentDatetime,
    timezone,
    instructorName,
  } = props;
  const [reschedulingReqType, setReschedulingReqType] = useState<
    ReschedulingRequestType | undefined
  >(isRecurring ? undefined : ReschedulingRequestType.SINGLE);
  const [scheduleAdditionalClass, results] = useRescheduleClassMutation();
  const description = `Class for ${studentFirstName} `;
  const confirmationMessage = `You are about to reschedule a class for ${studentFirstName} to:`;
  const handleSubmit = async (selectedDatetime: Date) => {
    if (acuityAppointmentId && selectedDatetime) {
      await scheduleAdditionalClass({
        variables: {
          input: {
            acuityAppointmentId,
            selectedAppointmentSlot: selectedDatetime.toString(),
          },
        },
      });
    }
  };

  const [
    createParentRecurringRescheduleRequest,
    prrrResults,
  ] = useCreateParentRecurringRescheduleRequestMutation();
  const handlePRRRSubmit = async (
    acuityAppointmentId: number,
    proposedCustomDatetimes: Date[],
    isCustomTimeSelection: boolean,
    instructorUnavailablePreference: InstructorUnavailablePreference,
    proposedDatetime?: Date,
  ) => {
    try {
      await createParentRecurringRescheduleRequest({
        variables: {
          input: {
            acuityAppointmentId: String(acuityAppointmentId),
            instructorUnavailablePreference,
            proposedCustomDatetimes: isCustomTimeSelection
              ? proposedCustomDatetimes.map(d => d.toISOString())
              : undefined,
            proposedDatetime: isCustomTimeSelection
              ? undefined
              : proposedDatetime!.toISOString(),
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const isRecurringFlowEnabled = isTimeTurnerStageEnabled(
    TimeTurnerStage.RespectAvailability,
  );
  if (isRecurringFlowEnabled && isRecurring && !reschedulingReqType) {
    return (
      <NewModalWindow
        isOpen={props.isOpen}
        closeModal={props.closeModal}
        title={`Reschedule class for ${studentFirstName}`}
      >
        <div className="flex flex-col gap-6">
          <label className="flex gap-3">
            <input
              type="radio"
              onClick={() => setReschedulingReqType(ReschedulingRequestType.SINGLE)}
            />
            <span>
              Reschedule {studentFirstName}'s upcoming{' '}
              {ACUITY_TYPE_TO_TEXT[appointmentTypeID] || 'Juni Session'} on{' '}
              {format(
                utcToZonedTime(parseISO(appointmentDatetime), timezone),
                `eeee, MMM d 'at' h:mma zz`,
                {
                  timeZone: timezone,
                },
              )}
            </span>
          </label>
          <label className="flex gap-3">
            <input
              type="radio"
              onClick={() =>
                setReschedulingReqType(ReschedulingRequestType.RECURRING)
              }
            />
            <span>
              Request a <span className="italic">recurring time change</span> for{' '}
              {studentFirstName}'s{' '}
              {ACUITY_TYPE_TO_TEXT[appointmentTypeID] || 'Juni Session'}s with{' '}
              {instructorName} on{' '}
              {format(
                utcToZonedTime(parseISO(appointmentDatetime), timezone),
                `eeee's' 'at' h:mma zz`,
                {
                  timeZone: timezone,
                },
              )}
            </span>
          </label>
        </div>
      </NewModalWindow>
    );
  }

  if (
    !isRecurringFlowEnabled ||
    reschedulingReqType === ReschedulingRequestType.SINGLE
  ) {
    return (
      <SingleClassSchedulerModal
        onSubmit={handleSubmit}
        results={results}
        modalDescription={description}
        confirmationMessage={confirmationMessage}
        refreshParentState={props.refreshUpcomingSessions}
        {...props}
      />
    );
  }

  return (
    <RecurringClassReschedulerModal
      onSubmit={handlePRRRSubmit}
      results={prrrResults}
      modalDescription={description}
      confirmationMessage={confirmationMessage}
      {...props}
    />
  );
};

export default LearnerClassReschedulerWidget;
