import React from 'react';

import { Card } from 'core-components';

import styled from 'styled-components/macro';

const StyledCard = styled(Card)`
  background-color: #dff3ff;
`;

const GuestAccountCTA = ({
  image,
  headerText,
  descriptionText,
  primaryButton,
  secondaryButton,
}) => (
  <StyledCard borderColor="juni-blue-200" className="flex flex-col items-center">
    <div className="flex flex-col items-center">
      <div className="w-2/3 flex flex-col items-center">
        <img src={image} alt="" />
        {headerText && (
          <div className="pt-4 text-center text-blue-gray-500 text-xl font-bold">
            {headerText}
          </div>
        )}
        <div className="pt-4 text-center text-blue-gray-500">{descriptionText}</div>
        <div className="pt-4">{primaryButton}</div>
        <div className="mt-4">{secondaryButton}</div>
      </div>
    </div>
  </StyledCard>
);

export default GuestAccountCTA;
