import { FC } from 'react';

import SmsConsentCheckbox from 'components/SmsConsentCheckbox';
import { NewCard } from 'core-components';

const SMSNotificationsCard: FC = () => (
  <NewCard padding="0">
    <div className="text-xl font-bold px-6 py-4">Notifications</div>
    <NewCard.Divider />
    <div className="p-6">
      <SmsConsentCheckbox userType="parent" />
    </div>
  </NewCard>
);

const PreferencesSection = () => (
  <div className="w-full lg:w-2/5 flex flex-col gap-6">
    <SMSNotificationsCard />
    {/* TODO: add substitute opt-in card */}
  </div>
);

export default PreferencesSection;
