import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const PageError = ({ title, children }) => (
  <div className="ui-page-error">
    <h1>{title || 'Oops!'}</h1>
    <div className="ui-content">
      {children || 'Sorry, there was an issue loading this page. Please try again.'}
    </div>
  </div>
);

PageError.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default PageError;
