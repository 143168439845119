const juniBlue = {
  50: '#E9F7FF',
  100: '#CFEEFF',
  200: '#9DDCFF',
  300: '#6ACAFF',
  400: '#37B9FF',
  500: '#00A5FF',
  600: '#0190E3',
  700: '#017BC6',
  800: '#0165A9',
  900: '#014F8C',
};

const juniPurple = {
  100: '#E0DDEF',
  200: '#C1BCE0',
  300: '#A199D0',
  400: '#8277C0',
  500: '#6255B0',
};

const juniGreen = {
  100: '#D0EDDB',
  200: '#A2DBB7',
  300: '#72C993',
  400: '#44B76F',
  500: '#14A54A',
};

const juniPink = {
  50: '#FDEFF1',
  100: '#FBDFE2',
  200: '#F7C0C6',
  300: '#F3A0A9',
  400: '#EE808C',
  500: '#EA606F',
  600: '#D64D61',
  700: '#C13A53',
  800: '#AD2745',
  900: '#981436',
};

const juniOrange = {
  100: '#FDE9D6',
  200: '#FBD3AD',
  300: '#F9BD84',
  400: '#F7A75B',
  500: '#F59132',
};

const juniDark = {
  100: '#E5E4EC',
  200: '#CAC9D8',
  300: '#9492B1',
  500: '#5F5C8A',
  700: '#413E75',
  900: '#292563',
};

const juniLight = {
  100: '#EFF4FB',
};

const blueGray = {
  50: '#ECEFF1',
  100: '#CFD8DC',
  200: '#B0BEC5',
  300: '#90A4AE',
  400: '#78909C',
  500: '#607D8B',
  600: '#546E7A',
  700: '#455A64',
  800: '#37474F',
  900: '#263238',
};

const green = {
  50: '#E8F5E9',
  100: '#C8E6C9',
  200: '#A5D6A7',
  300: '#81C784',
  400: '#66BB6A',
  500: '#4CAF50',
  600: '#43A047',
  700: '#388E3C',
  800: '#2E7D32',
  900: '#1B5E20',
};

const red = {
  50: '#FEEBEE',
  100: '#FECDD2',
  200: '#EF9A9A',
  300: '#E57373',
  400: '#EF5350',
  500: '#F44336',
  600: '#E53935',
  700: '#D32F2F',
  800: '#C62828',
  900: '#B71C1C',
};

const amber = {
  50: '#FFF8E1',
  100: '#FFECB3',
  200: '#FFE082',
  300: '#FFD54F',
  400: '#FFCA28',
  500: '#FFC107',
  600: '#FFB300',
  700: '#FFA000',
  800: '#FF8F00',
  900: '#FF6F00',
};

const brown = {
  50: '#EFEBE9',
  100: '#D7CCC8',
  200: '#BCAAA4',
  300: '#A1887F',
  400: '#8D6E63',
  500: '#795548',
  600: '#6D4C41',
  700: '#5D4037',
  800: '#4E342E',
  900: '#3E2723',
};

const lightGreen = {
  100: '#CCFF90',
  200: '#B2FF59',
  400: '#76FF03',
  700: '#64DD17',
};

const cyan = {
  100: '#84FFFF',
  200: '#18FFFF',
  400: '#00E5FF',
  700: '#00B8D4',
};

const indigo = {
  100: '#8C9EFF',
  200: '#536DFE',
  400: '#3D5AFE',
  700: '#304FFE',
};

const purple = {
  100: '#EA80FC',
  200: '#E040FB',
  400: '#D500F9',
  700: '#AA00FF',
};

const deepPurple = {
  100: '#D1C4E9',
  400: '#7E57C2',
  500: '#673AB7',
};

module.exports = {
  juniBlue,
  juniPurple,
  juniGreen,
  juniPink,
  juniOrange,
  juniDark,
  juniLight,
  blueGray,
  green,
  red,
  amber,
  brown,
  lightGreen,
  cyan,
  indigo,
  purple,
  deepPurple,
};
