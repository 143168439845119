import React, { FC } from 'react';
import styled from 'styled-components';
import { CheckmarkIcon, XIcon } from 'components/Icons';
import Spinner from 'components/SpinnerV2';

// RFD context: https://www.notion.so/junilearning/Offline-Whiteboards-93dc909732f741a1add9f801051be43d#ce0d004acaad461f828930a35e4a752a

type Props = {
  connected: boolean;
  anyUnacked: boolean;
};

const Box = styled.div`
  background: #e7f4f9;
  border: 0.730502px solid #b6dae8;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  padding: 0px 6px;
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  color: #7c92a8;
`;

const iconStyle = { padding: 0, marginRight: 3 };

/**
 * @deprecated With new horizons restyling, use the SyncIndicator one level up in src/components
 */
const DeprecatedSyncIndicator: FC<Props> = ({ connected, anyUnacked }) => (
  <Box>
    {connected ? (
      anyUnacked ? (
        <>
          <div style={iconStyle}>
            <div
              style={{
                // For some reason, spinners have an 8px margin, which I must undo
                margin: '-8px',
              }}
            >
              <Spinner size={14} />
            </div>
          </div>
          <div>Saving</div>
        </>
      ) : (
        <>
          <CheckmarkIcon style={iconStyle} stroke="#7c92a8" />
          <div>Saved</div>
        </>
      )
    ) : (
      <>
        <XIcon style={{ ...iconStyle, height: '8px' }} stroke="#DB4545" />
        <div>Offline</div>
      </>
    )}
  </Box>
);
export default DeprecatedSyncIndicator;
