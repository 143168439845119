import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { JuniClubType } from 'generated/graphql';
import styled from 'styled-components/macro';

import { ChatMessage } from 'components/clubs';
import { getClubLink } from 'app/clubs/helpers';
import Card from 'core-components/Card';
import { Chevron } from 'components/Icons';
import { useClubMemberLookup } from 'app/clubs/stores/ClubStoreHelpers';
import { MsgType } from 'app/clubs/MyClubsTypes';
import NotificationsBadge from 'components/NotificationsBadge';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

export interface MyClubCardProps {
  juniClub: JuniClubType;
  studentId?: string;
  notifications?: number;
  messagesToShow?: MsgType[];
}

interface MyClubsCardClubInfoProps {
  clubName: string;
  clubIcon: any;
  notifications?: number;
}
const MyClubsCardLinkWrapper = styled.div.attrs({
  className: `flex items-center text-blue-gray-600 p-3`,
})`
  > * {
    display: flex;
    height: 35px;
    align-items: center;
  }
  > img {
    width: 35px;
    border-radius: 4px;
  }
`;

const MyClubCardWrapper = styled(Card).attrs({
  spaceContent: false,
})`
  transition: 0.2s;
  background: #f7fcff;
  border: 2px solid #d2e7f3;
  :hover {
    background: white;
  }
`;

const MyClubsRecentMessages = styled.div.attrs({ className: 'p-3 mt-0' })`
  border-top: 2px solid #d2e7f3;
`;

const MyClubsCardClubInfo: FC<MyClubsCardClubInfoProps> = ({
  clubIcon,
  clubName,
  notifications,
}) => (
  <MyClubsCardLinkWrapper>
    <img className="mr-3" src={clubIcon} alt="" />
    <div className="text-base font-bold text-blue-gray-600">{clubName}</div>
    <div className="flex ml-auto mr-3">
      {notifications ? (
        <NotificationsBadge
          numNotifications={notifications}
          backgroundColor="juni-blue-400"
        />
      ) : null}
    </div>
    <div className="flex">
      <Chevron style={{ boxSizing: 'border-box' }} orientation="right" />
    </div>
  </MyClubsCardLinkWrapper>
);

const logClubCardClick = (juniClubId: string, messagesToShow: MsgType[]) => {
  // send club card clicked event to snowflake.
  const expandedCard = messagesToShow.length > 0;
  JuniAnalytics.track('my_club_card_clicked', {
    juniClubId,
    expandedCard,
  });
};
const MyClubCard: FC<MyClubCardProps> = ({
  juniClub,
  studentId,
  notifications,
  messagesToShow,
}) => {
  const messages: MsgType[] = (messagesToShow || []).filter(
    msg => !msg.removed && !msg.deleted,
  );
  const [clientUUIDToClubMember] = useClubMemberLookup({
    juniClubId: juniClub._id,
  });

  const messageChannel = messagesToShow?.length
    ? messagesToShow[0].juniClubChannel
    : undefined;

  return (
    <Link
      className="no-underline w-full m-0 p-0 flex-1 mb-3"
      to={getClubLink({
        studentId,
        juniClubId: juniClub._id,
        channelName: messageChannel,
      })}
      onClick={() => logClubCardClick(juniClub._id, messagesToShow || [])}
    >
      <MyClubCardWrapper
        topLabelText={
          notifications && messageChannel
            ? `${notifications} New Message${notifications !== 1 ? 's' : ''}`
            : undefined
        }
        padding="0"
      >
        <MyClubsCardClubInfo
          notifications={!messageChannel ? notifications : undefined}
          clubName={juniClub.displayName || ''}
          clubIcon={juniClub.coverPhoto?.replace('_full', '_thumbnail')}
        />
        {messages.length ? (
          <MyClubsRecentMessages>
            {messages.map(message => (
              <ChatMessage
                key={message.msgId}
                message={message}
                sender={
                  message.senderUUID
                    ? clientUUIDToClubMember[message.senderUUID]
                    : undefined
                }
                previewMode
              />
            ))}
          </MyClubsRecentMessages>
        ) : null}
      </MyClubCardWrapper>
    </Link>
  );
};

export default MyClubCard;
