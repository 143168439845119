import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as WaveBG } from 'images/juniverse_home_wave_bottom.svg';

const JuniverseBGWaveBottomWrapper = styled.div`
  position: absolute;
  bottom: -1100px;
`;

export const JuniverseBGWaveBottom = () => (
  <JuniverseBGWaveBottomWrapper>
    <WaveBG />
  </JuniverseBGWaveBottomWrapper>
);
