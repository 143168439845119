import React, { useState } from 'react';
import ModalWindow from 'core-components/ModalWindow';
import Button from 'core-components/Button';
import styled from 'styled-components';
import { JuniClubType } from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import { Message } from 'core-components';
import { useLeaveClub } from 'app/clubs/stores/ClubStoreHelpers';
import useClubStore from 'app/clubs/stores/ClubStore';

const ButtonLayout = styled.div.attrs({ className: 'flex' })`
  .cancel-button {
    margin-right: 1rem;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    .cancel-button {
      margin-right: 0rem;
      margin-bottom: 1rem;
    }
  }
`;

interface LeaveClubModalProps {
  handleClose: () => void;
  isOpen: boolean;
  juniClub: JuniClubType;
  studentId?: string;
  instructorUserId?: string;
}

const LeaveClubModal: React.FC<LeaveClubModalProps> = ({
  handleClose,
  isOpen,
  studentId,
  instructorUserId,
  juniClub,
}) => {
  const leaveClub = useLeaveClub();
  const [error, setError] = useState('');
  const leavingClubId = useClubStore(state => state.leavingClubId);
  const isLeavingClub = !!leavingClubId;

  const handleSubmit = async () => {
    if (isLeavingClub) {
      return;
    }

    try {
      await leaveClub({
        studentId,
        instructorUserId,
        juniClubId: juniClub._id,
      });
    } catch (e) {
      setError('Oops! The server errored. Please try again later.');
    }
  };
  return (
    <ModalWindow
      title={`Leave ${juniClub.displayName}`}
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <p className="mt-2 mb-2 pb-2">
        Are you sure you wish to leave this club? You can join again at any time.
      </p>
      {error && <Message status="error">{error}</Message>}
      <ButtonLayout>
        <Button
          variant="secondary"
          className="flex-grow cancel-button"
          hasArrowIcon
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} hasArrowIcon className="bg-white flex-grow">
          {isLeavingClub ? <SpinnerV2 /> : 'Leave Club'}
        </Button>
      </ButtonLayout>
    </ModalWindow>
  );
};
export default LeaveClubModal;
