import styled from 'styled-components/macro';
import { MOBILE_BREAKPOINT } from '../constants';

export const GreenIconWrapper = styled.div.attrs({
  className:
    'w-5 h-5 p-1 flex items-center justify-center rounded-lg mr-4 bg-j-green-100',
})``;

const StyledHeader = styled.div.attrs({ className: 'w-full py-1 space-y-3' })<any>`
  @media only screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    padding-bottom: ${props => (props.padBottom ? `9rem` : ``)};
  }
`;

const StyledIcon = styled.div<any>`
  display: flex;
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export { StyledHeader, StyledIcon };
