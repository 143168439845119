import React, { FC, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import classNames from 'classnames';
import { NewButton, Icon } from 'core-components';
import { JideEnvBarContext } from 'components/jide';

import { jPurple } from 'theme/colors';
import { shareableProjectTypes } from 'services/juni_ide';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import JideVideosWidget from './JideVideosWidget';
import JideShareButton from './JideShareButton';
import JideRecordButton from './JideRecordButton';
import JideGetHelpButton from './JideGetHelpButton';
import JideShareV2 from './JideShareV2';
import { getHomeworkHelpClubId } from '../../../constants/clubs';
import JideNextWidget from './JideNextWidget';

const Container = styled.div<any>`
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 1rem;
  ${props =>
    props.leftCorner
      ? `
      left: 1rem;
    `
      : `
      right: 1rem;
    `}
  width: auto;
  z-index: 999;
`;

const Widget = styled.div`
  margin-top: 0.5rem;
  align-self: flex-end;
`;

const WidgetButton = styled.button`
  border-radius: 0.75rem;
  width: 6rem;
  height: 2.5rem;
  position: relative;
  top: 0;
  transition-duration: 0.2s;
  border-width: 0.25rem;
  border-style: solid;
  display: flex;
  align-items: center;
  padding: 5px 6px;

  .widget__title {
    display: flex;
    justify-content: center;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    flex: 1 0 auto;
    margin-right: 3px;
  }

  .widget-icon-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto;
    font-size: 13px;
  }
`;

export const ShareButton = styled(WidgetButton)`
  background: hsl(184, 75%, 44%);
  border-color: hsl(184, 78%, 41%);

  &:hover {
    background: hsl(184, 79%, 60%);
    border-color: hsl(184, 82%, 50%);
  }
`;

export const VideoOpenButton = styled(WidgetButton)`
  background: #00456b;
  border-color: rgba(255, 255, 255, 0.1);

  &:hover {
    background: hsl(202, 70%, 41%);
    border-color: hsl(201, 100%, 34%);
  }
`;

export const RecordOpenButton = styled(WidgetButton)`
  background: #d33838;
  border-color: #b62323;
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;

  &:hover {
    background: #e45353;
    border-color: #d33838;
  }

  @keyframes fadeIn {
    40% {
      border-color: #b62323;
      background-color: #d33838;
      color: white;
    }
    70% {
      border-color: #ebc3c3;
      background-color: white;
      color: #b62323;
    }
  }
  @-moz-keyframes fadeIn {
    40% {
      border-color: #b62323;
      background-color: #d33838;
      color: white;
    }
    70% {
      border-color: #ebc3c3;
      background-color: white;
      color: #b62323;
    }
  }
  @-webkit-keyframes fadeIn {
    40% {
      border-color: #b62323;
      background-color: #d33838;
      color: white;
    }
    70% {
      border-color: #ebc3c3;
      background-color: white;
      color: #b62323;
    }
  }
`;

export const GetHelpButton = styled(WidgetButton)`
  background: ${jPurple[500]};
  border-color: rgba(255, 255, 255, 0.1);

  &:hover {
    background: ${jPurple[400]};
    border-color: ${jPurple[400]};
  }
`;

const IsPublicBadge: FC<{ isPublic: boolean; className?: string }> = ({
  isPublic,
  className,
}) => (
  <div
    className={classNames(className, {
      'bg-j-green-100 text-j-green-700': isPublic,
      'bg-j-purple-200 text-j-dark-600': !isPublic,
    })}
  >
    {isPublic ? <Icon.Globe /> : <Icon.Lock />}
    <div className="hidden xl:flex">{isPublic ? 'Public' : 'Private'}</div>
  </div>
);

enum WidgetNames {
  videos,
  share,
  record,
}

interface ITabNav {
  student: string;
  course: string;
  module: string;
  project: string;
}

interface IJideWidgets {
  // todo make enum
  environmentType: string;
  jideUser: {
    _id: string;
    type: string;
    firstName: string;
    lastName: string;
    username?: string;
  };
  tab: {
    tabId: string;
    tabNav: ITabNav;
  };
  idLookup: { [key: string]: any };
  activeNav: ITabNav;
  learnerAnalyticsEnabled: boolean;
  showVideosWidget: boolean;
  isCustomProject: boolean;
  isPlayground: boolean;
  hideShareWidget?: boolean;
  recordingMode?: boolean;
  setRecordingMode: (val: boolean) => void;
  /**
   * New Horizons restyling flag, default false.
   */
  newHorizons?: boolean;
  schedulingFormat: string;
}

const createWidgetIsViewable = (currentlyOpenWidget?: WidgetNames) => (
  widgetName: WidgetNames,
) => currentlyOpenWidget === undefined || currentlyOpenWidget === widgetName;

const JideWidgets: React.FC<IJideWidgets> = props => {
  const jideEnvBarContext = useContext(JideEnvBarContext);
  const { activeStudentId } = useParams<{ activeStudentId: string }>();
  const { idLookup, activeNav } = props;
  const [openWidget, defineOpenWidget] = useState<WidgetNames | undefined>(
    undefined,
  );
  const [isPublic, setIsPublic] = useState<boolean | undefined | null>(undefined);
  const [nextWidgetIsOpen, setNextWidgetIsOpen] = useState(false);

  useEffect(() => {
    jideEnvBarContext?.toggleRaiseZIndex?.(!!openWidget);
  }, [openWidget, jideEnvBarContext]);

  const widgetIsViewable: (
    widgetName: WidgetNames,
  ) => boolean = createWidgetIsViewable(openWidget);

  const location = useLocation();

  const shouldShowVideosWidget =
    props.showVideosWidget && widgetIsViewable(WidgetNames.videos);

  const shouldShowShareWidget =
    !props.hideShareWidget &&
    widgetIsViewable(WidgetNames.share) &&
    shareableProjectTypes.includes(props.environmentType);

  const playgroundOrStudent =
    props.isPlayground && props.jideUser.type !== 'public'
      ? true
      : props.jideUser.type === 'student';
  const shouldShowRecordWidget =
    playgroundOrStudent && widgetIsViewable(WidgetNames.record);

  const isActiveTab = props.tab.tabNav.project === props.activeNav.project;
  const projectTitleDisplayName =
    idLookup[activeNav.project]?.displayName || 'My Project';
  const projectTitle = projectTitleDisplayName.includes(': ')
    ? projectTitleDisplayName.split(': ')[1]
    : projectTitleDisplayName;
  const studentId =
    props.jideUser.type === 'student' ? props.jideUser._id : undefined;
  const instructorUserId =
    props.jideUser.type === 'teacher' ? props.jideUser._id : undefined;

  const homeworkHelpClubId = getHomeworkHelpClubId(
    activeNav.course,
    props.environmentType,
  );

  const moduleMatchesActiveNavModule = (module: any) =>
    module.id === props.activeNav.module;

  const sectionMatchesActiveNavSection = (section: any) =>
    section.id === props.activeNav.project;

  const sectionIsPathwaysProject = (section: any) =>
    section.type !== 'normal' &&
    !section.properties.isSupplemental &&
    !section.properties.isCustomProject;

  const handleOpenNextWidget = () => setNextWidgetIsOpen(!nextWidgetIsOpen);

  let nextSection;
  const courseInfo = props.idLookup[props.activeNav.course];

  const isOnDemandCourse = props.schedulingFormat === 'on_demand';

  if (isOnDemandCourse && courseInfo) {
    const modules = courseInfo.children;
    const currModuleIndex = _.findIndex(modules, moduleMatchesActiveNavModule);
    const moduleInfo = modules[currModuleIndex];
    const sections = moduleInfo.children;
    const pathwayProjectSections = sections.filter(sectionIsPathwaysProject);

    const currSection = sections.find(sectionMatchesActiveNavSection);
    const currSectionIndex = _.findIndex(sections, sectionMatchesActiveNavSection);

    const { isSupplemental } = currSection?.properties;
    // Note: Only surface 'next' button for non-supplemental projects for now
    if (!isSupplemental) {
      // this is the last section in the module
      if (currSectionIndex + 1 > pathwayProjectSections.length - 1) {
        if (currModuleIndex + 1 <= modules.length - 1) {
          // eslint-disable-next-line prefer-destructuring
          nextSection = modules[currModuleIndex + 1].children.filter(
            sectionIsPathwaysProject,
          )[0];
        }
      } else {
        nextSection = pathwayProjectSections[currSectionIndex + 1];
      }
    }
  }

  if (nextSection) {
    let index = location.pathname.indexOf('/project/');
    if (index !== -1) {
      index += '/project/'.length;
      nextSection.url = location.pathname.substring(0, index) + nextSection?.id;
    }
  }

  if (props.recordingMode) return null;

  // Not sure why the old version renders an empty container without this,
  // id think it could return null on the line above.
  // But pulling up to here for use in both New Horizons and the old,
  // as the new horizons version doesn't need to render an empty container.
  const showButtons = props.jideUser?.type !== 'public' && isActiveTab;

  // TODO - verify all the old display logic is still the same.
  if (props.newHorizons) {
    if (!showButtons) return null;

    return (
      <div className="flex items-center w-full space-x-2">
        <div className="mr-auto">
          {isPublic !== undefined &&
            isPublic !== null &&
            openWidget === undefined && (
              <IsPublicBadge
                isPublic={isPublic}
                className="flex text-sm items-center justify-center py-2 px-3 space-x-1 rounded-lg"
              />
            )}
        </div>

        {props.jideUser && shouldShowRecordWidget && (
          <NewButton
            variant="secondary"
            size="xsmall"
            renderIconLeft={props => <Icon.VideoCamera {...props} />}
            onClick={() => props.setRecordingMode(true)}
          >
            Rec
          </NewButton>
        )}
        {shouldShowVideosWidget && (
          <JideVideosWidget
            toggleSiblingWidgets={() => {
              defineOpenWidget(
                openWidget === undefined ? WidgetNames.videos : undefined,
              );
            }}
            activeNav={props.activeNav}
            idLookup={props.idLookup}
            learnerAnalyticsEnabled={props.learnerAnalyticsEnabled}
            student={props.jideUser}
            tab={props.tab}
            newHorizons
          />
        )}
        {homeworkHelpClubId && openWidget === undefined && (
          <NewButton
            variant="secondary"
            size="xsmall"
            href={`/learner/${activeStudentId}/club_page/${homeworkHelpClubId}/general`}
            renderIconLeft={props => <Icon.SpeechBubble {...props} />}
            onClick={() =>
              JuniAnalytics.track('homework_help_button_clicked', {
                // activeNav gives id's for course, module, project, student
                ...activeNav,
                clubId: homeworkHelpClubId,
              })
            }
          >
            HW Help?
          </NewButton>
        )}
        {props.jideUser && shouldShowShareWidget && (
          <JideShareV2
            toggleSiblingWidgets={() =>
              defineOpenWidget(
                openWidget === undefined ? WidgetNames.share : undefined,
              )
            }
            setRecordingMode={props.setRecordingMode}
            studentId={studentId}
            instructorUserId={instructorUserId}
            username={props.jideUser?.username}
            courseId={props.tab.tabNav.course}
            moduleId={props.tab.tabNav.module}
            projectId={props.tab.tabNav.project}
            isCustomProject={props.isCustomProject}
            defaultProjectTitle={projectTitle.replace(/[^A-Za-z0-9 _]/g, '')}
            setIsPublicBadge={setIsPublic}
            environmentType={props.environmentType}
            schedulingFormat={props.schedulingFormat}
            nextSection={nextSection}
          />
        )}
        {!shouldShowShareWidget && nextSection && (
          <>
            {nextWidgetIsOpen && (
              <JideNextWidget
                onClose={handleOpenNextWidget}
                nextSection={nextSection}
              />
            )}
            <NewButton
              variant="secondary"
              size="xsmall"
              renderIconLeft={props => <Icon.ArrowUpRight {...props} />}
              className="px-1"
              onClick={handleOpenNextWidget}
            >
              Next
            </NewButton>
          </>
        )}
      </div>
    );
  }

  return (
    <Container
      leftCorner={
        ['math', 'math_whiteboard'].includes(props.environmentType) &&
        props.hideShareWidget
      }
    >
      {showButtons && (
        <>
          {homeworkHelpClubId && (
            <Widget>
              <a
                href={`/learner/${activeStudentId}/club_page/${homeworkHelpClubId}/general`}
                target="_blank"
                rel="noreferrer"
                className="no-underline"
              >
                <JideGetHelpButton />
              </a>
            </Widget>
          )}
          {shouldShowVideosWidget && (
            <Widget>
              <JideVideosWidget
                toggleSiblingWidgets={() => {
                  defineOpenWidget(
                    openWidget === undefined ? WidgetNames.videos : undefined,
                  );
                }}
                activeNav={props.activeNav}
                idLookup={props.idLookup}
                learnerAnalyticsEnabled={props.learnerAnalyticsEnabled}
                student={props.jideUser}
                tab={props.tab}
              />
            </Widget>
          )}
          {props.jideUser && shouldShowShareWidget && (
            <Widget>
              <JideShareButton
                toggleSiblingWidgets={() =>
                  defineOpenWidget(
                    openWidget === undefined ? WidgetNames.share : undefined,
                  )
                }
                jideUser={props.jideUser}
                tab={props.tab}
                isCustomProject={props.isCustomProject}
                isPlayground={props.isPlayground}
                environmentType={props.environmentType}
                setRecordingMode={props.setRecordingMode}
                defaultTitle={projectTitle}
              />
            </Widget>
          )}
          {props.jideUser && shouldShowRecordWidget && (
            <Widget>
              <JideRecordButton setRecordingMode={props.setRecordingMode} />
            </Widget>
          )}
        </>
      )}
    </Container>
  );
};

export default JideWidgets;
