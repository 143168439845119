import { FC, useState, useMemo } from 'react';
import classNames from 'classnames';
import {
  isBefore,
  addDays,
  addMinutes,
  subDays,
  startOfHour,
  endOfDay,
  startOfDay,
  subMinutes,
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { MutationResult } from '@apollo/client';

import Button from 'core-components/NewButton';
import { Icon, Message } from 'core-components';
import ModalWindow, {
  ModalButtonsFooter,
  ModalTextFooter,
} from 'core-components/NewModalWindow/NewModalWindow';
import { JuniLogo } from 'components/brand-assets';
import { useGetAvailableAppointmentSlotsQuery } from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import { AcuityAppointment } from 'models';
import TimeSlotPicker, { MaskedBlock } from './TimeSlotPicker';
import { LearnerClassSchedulerBaseProps } from '../types';

import { NUM_DAYS_TO_SHOW } from '../constants';
import {
  ACUITY_TYPE_DURATION,
  ACUITY_TYPE_TO_TEXT,
  ACUITY_APPOINTMENT_DEFAULT_DURATION,
} from '../../../../constants/acuity.js';

interface SingleClassSchedulerModalProps extends LearnerClassSchedulerBaseProps {
  onSubmit: (time: Date) => void;
  modalDescription: string;
  confirmationMessage: string;
  results: MutationResult;
  refreshParentState?: () => void;
  upcomingClasses?: AcuityAppointment[];
}

const SingleClassSchedulerModal: FC<SingleClassSchedulerModalProps> = ({
  appointmentTypeID,
  timezone,
  onSubmit,
  instructorAcuityId,
  modalDescription,
  confirmationMessage,
  studentFirstName,
  onFinish,
  refreshParentState,
  isOpen,
  closeModal,
  results,
  upcomingClasses,
}) => {
  const [startDate, setStartDate] = useState(startOfDay(addDays(new Date(), 1)));
  const [selectedDatetime, setSelectedDatetime] = useState<Date | null>(null);
  const [classDatetimeConfirmed, setClassDatetimeConfirmed] = useState(false);
  const { data, loading, error } = useGetAvailableAppointmentSlotsQuery({
    variables: {
      instructorAcuityId: `${instructorAcuityId}`,
      appointmentTypeId: appointmentTypeID,
      startDatetime: startOfHour(startDate).toString(),
      endDatetime: endOfDay(addDays(startDate, NUM_DAYS_TO_SHOW)).toString(),
    },
    skip: results.loading || selectedDatetime !== null,
  });
  // Callback for previous page button
  const previousAvailability = () => {
    setStartDate(subDays(startDate, NUM_DAYS_TO_SHOW));
  };

  // Callback for next page button
  const moreAvailability = () => {
    setStartDate(addDays(startDate, NUM_DAYS_TO_SHOW));
  };

  const unselectNewClassDatetime = () => {
    setSelectedDatetime(null);
  };
  // This is really sketchy.
  // May not even need the useMemo piece.
  // - June
  const sessionDuration =
    ACUITY_TYPE_DURATION[appointmentTypeID] || ACUITY_APPOINTMENT_DEFAULT_DURATION;
  const unavailabilityIntervals = useMemo(
    () =>
      upcomingClasses
        ? upcomingClasses.reduce((intervals, session) => {
            const duration =
              ACUITY_TYPE_DURATION[session.appointmentTypeID] ||
              ACUITY_APPOINTMENT_DEFAULT_DURATION;
            const start = new Date(session.datetime);
            return [
              ...intervals,
              {
                start: subMinutes(start, sessionDuration - 1),
                end: addMinutes(start, duration - 1),
                description: `Conflicts with existing ${
                  ACUITY_TYPE_TO_TEXT[session.appointmentTypeID] || 'Session'
                } for ${studentFirstName}`,
              },
            ];
          }, [] as MaskedBlock[])
        : [],
    [upcomingClasses, studentFirstName, sessionDuration],
  );
  const handleSubmit = async () => {
    if (selectedDatetime) {
      try {
        await onSubmit(selectedDatetime);
      } catch (e) {
        console.log(e);
      }
    }
    setClassDatetimeConfirmed(true);
    if (refreshParentState) {
      await refreshParentState();
    }
  };
  const finish = () => {
    if (onFinish) {
      onFinish();
    }
  };
  const previousAvailabilityDisabled = isBefore(
    subDays(startDate, NUM_DAYS_TO_SHOW),
    new Date(),
  );

  const TimezoneFooter = (
    <ModalTextFooter
      title="Your Timezone"
      description={`${timezone} (${format(new Date(), 'zz', {
        timeZone: timezone,
      })})`}
    />
  );

  const ConfirmFooter = (
    <ModalButtonsFooter
      primary={
        <Button onClick={handleSubmit} disabled={results.loading}>
          <div className="font-medium">Confirm</div>
        </Button>
      }
      secondary={
        <Button variant="secondary" onClick={unselectNewClassDatetime}>
          <Icon.ChevronLeft />
          <div className="font-medium">Back</div>
        </Button>
      }
    />
  );

  const CompleteFooter = (
    <ModalButtonsFooter
      primary={
        <Button variant="secondary" onClick={finish}>
          <div className="font-medium">Go to Calendar</div>
        </Button>
      }
    />
  );

  const ModalFooter = selectedDatetime
    ? !classDatetimeConfirmed
      ? ConfirmFooter
      : CompleteFooter
    : TimezoneFooter;

  const availableSlots = data?.availableAppointmentSlots;
  return (
    <ModalWindow
      isOpen={isOpen}
      closeModal={closeModal}
      title={!classDatetimeConfirmed ? 'Schedule' : ''}
      description={!classDatetimeConfirmed ? modalDescription : ''}
      renderFooter={() => ModalFooter}
    >
      <div className="flex flex-col w-full">
        {/** Availability Calendar controls */}
        {!selectedDatetime && (
          <div className="flex justify-between items-center mb-4">
            <div className="text-j-dark-600 text-base font-medium">Pick a time</div>
            <div className="flex">
              <div className="mr-3">
                <Button
                  onClick={previousAvailability}
                  disabled={previousAvailabilityDisabled}
                  variant="secondary"
                  icon
                >
                  <Icon.ArrowLeft />
                </Button>
              </div>
              <div className="mr-4">
                <Button onClick={moreAvailability} variant="secondary" icon>
                  <Icon.ArrowRight />
                </Button>
              </div>
            </div>
          </div>
        )}

        {selectedDatetime && !results.error && (
          <div className="w-full">
            {results.loading ? (
              <SpinnerV2 />
            ) : !classDatetimeConfirmed ? (
              <div className="font-graphik">
                <div className="text-j-dark-300 text-sm mb-4">
                  {confirmationMessage}
                </div>
                <div className="text-j-dark-600 font-medium text-base mb-4">
                  {format(
                    utcToZonedTime(selectedDatetime, timezone),
                    "EEEE, MMMM do 'at' h:mm a zzz",
                    {
                      timeZone: timezone,
                    },
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center p-4">
                <div className="py-10 px-7 rounded-full bg-j-dark-100 mb-7">
                  <JuniLogo size="md" />
                </div>
                <div className="text-j-dark-600 text-base font-medium mb-3">
                  Class Scheduled!{' '}
                </div>
                <div className="text-j-dark-300 text-sm mb-3">
                  You've scheduled a session for {studentFirstName} on:
                </div>
                <Message status="success">
                  {format(
                    utcToZonedTime(selectedDatetime, timezone),
                    "EEEE, LLLL do 'at' h:mm a zz",
                    {
                      timeZone: timezone,
                    },
                  )}
                </Message>
              </div>
            )}
          </div>
        )}
        {/** Availability table */}
        {!selectedDatetime && (
          <div className={classNames('flex flex-col w-full overflow-x-auto')}>
            {/** Container for table header */}
            {/* Table body */}
            {!loading ? (
              !error && !results.error ? (
                <TimeSlotPicker
                  availableSlots={availableSlots}
                  timezone={timezone}
                  setSelectedDatetime={setSelectedDatetime}
                  windowStart={startDate}
                  windowEnd={endOfDay(addDays(startDate, NUM_DAYS_TO_SHOW))}
                  maskedBlocks={unavailabilityIntervals}
                />
              ) : (
                <div className="p-5">
                  {!error && !results.error ? (
                    <Message status="info">
                      {' '}
                      No available times for these dates.
                    </Message>
                  ) : (
                    <Message status="error">
                      {' '}
                      {results.error ? (
                        <>{results.error.message}</>
                      ) : (
                        <>Unable to fetch availabilities at this time.</>
                      )}
                    </Message>
                  )}
                </div>
              )
            ) : (
              <SpinnerV2 size={48} />
            )}
          </div>
        )}
      </div>
    </ModalWindow>
  );
};

export default SingleClassSchedulerModal;
