import React, { useState, useEffect, FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { parseISO, subWeeks, isAfter } from 'date-fns';
import * as R from 'ramda';
import { getModuleSectionProgresses } from 'services/learner/progress';
import { TRACKS } from 'constants/tracks';
import { NewCard as Card } from 'core-components';
import { Student, ModuleSectionProgress } from 'models';
import { getUserUsermedia } from 'services/usermedia';
import FitVid from 'components/FitVid';
import { ErrorableLoading, ReactSelectField } from 'components/ui';
import {
  useCourseCompletionDatesQuery,
  useGetRecommendedCoursesQuery,
} from 'generated/graphql';
import {
  COURSE_TYPE_SUBJECTS,
  SUBJECTS,
  SUBJECT_COURSE_TYPES,
} from 'constants/subjects';
import { Award, Code, English, Import, Math, Investing } from 'core-components/Icon';
import { jDark } from 'theme/colors';
import { getShortDisplayName } from 'utils/courses';

const iconMap = {
  [SUBJECTS.COMPUTER_SCIENCE]: Code,
  [SUBJECTS.MATHEMATICS]: Math,
  [SUBJECTS.ENGLISH]: English,
  [SUBJECTS.INVESTING]: Investing,
};

interface Usermedia {
  _id: string;
  projectId: string;
  title: string;
  s3Url: string;
  createdAt: Date;
}

interface IdLookupEntry {
  id: string;
  type: string;
  displayName: string;
  properties: {
    courseId?: string;
    moduleId?: string;
    name?: string;
    courseType?: keyof typeof COURSE_TYPE_SUBJECTS;
  };
}

interface IdLookup {
  [index: string]: IdLookupEntry;
}

interface AugmentedModuleSectionProgress extends ModuleSectionProgress {
  sectionProperties: IdLookupEntry;
  moduleProperties: IdLookupEntry;
  courseProperties: IdLookupEntry;
}

interface Props {
  students: Student[];
  student: Student;
  idLookup: IdLookup;
}

const WEEKS_AGO = 6;

const LearningAtAGlance: React.FC<Props> = ({ students, student, idLookup }) => {
  const { _id: studentId } = student;

  const history = useHistory();

  const [moduleSectionProgresses, setModuleSectionProgresses] = useState<
    AugmentedModuleSectionProgress[]
  >([]);
  const [studentRecordings, setStudentRecordings] = useState<Usermedia[]>([]);
  const [courses, setCourses] = useState<string[]>([]);
  const [selectedCourseName, setSelectedCourseName] = useState<string>('');

  // fetch moduleSectionProgresses and augment each one with idLookup data
  useEffect(() => {
    const fetchModuleSectionProgresses = async () => {
      const moduleSectionProgresses: ModuleSectionProgress[] = await getModuleSectionProgresses(
        studentId,
      );
      const augmentedModuleSectionProgresses = attachIdLookup(
        moduleSectionProgresses,
        idLookup,
      );
      setModuleSectionProgresses(augmentedModuleSectionProgresses);
    };
    fetchModuleSectionProgresses();
  }, [studentId, idLookup]);

  // identify courses with recent progress
  // set an initial selectedCourseName
  useEffect(() => {
    const recentCourses = getCourseIdsWithRecentProgress(
      student.tracks.length !== 0 ? student.tracks : [student.track],
      moduleSectionProgresses,
    );
    setCourses(recentCourses);
    setSelectedCourseName(recentCourses?.[0] || '');
  }, [moduleSectionProgresses, student]);

  // fetch all recordings for student
  useEffect(() => {
    const getStudentRecordings = async () => {
      const results = await getUserUsermedia({
        userType: 'student',
        studentId,
        teacherUserId: undefined,
      });
      setStudentRecordings(results.usermedias);
    };
    getStudentRecordings();
  }, [studentId]);

  const {
    data: courseCompletionDatesData,
    loading: loadingCourseCompletionDates,
  } = useCourseCompletionDatesQuery({
    variables: {
      studentId,
    },
  });
  const mostRecentCourseCompletionsBySubject = courseCompletionDatesData?.courseCompletionDates.items.reduce(
    (agg, curr) => {
      const subject = toSubject(curr.courseName, idLookup);
      if (!subject || agg[subject]) return agg;
      return { ...agg, [subject]: curr.courseName };
    },
    {} as { [Property in keyof typeof SUBJECT_COURSE_TYPES]: string },
  );

  const {
    data: recommendedCoursesData,
    loading: loadingRecommendedCourses,
  } = useGetRecommendedCoursesQuery({
    variables: {
      input: { names: courses, isAcceptingEnrollment: true },
    },
  });

  if (loadingCourseCompletionDates || loadingRecommendedCourses)
    return <ErrorableLoading />;

  const recommendedCoursesByName = R.indexBy(
    R.prop('name'),
    recommendedCoursesData?.getCourses || [],
  );
  const selectedCourseNextRecommended =
    recommendedCoursesByName[selectedCourseName]?.nextRecommendedCourses || [];

  const { id: courseId } = idLookup[selectedCourseName] || {};
  const selectedCourseSubject = toSubject(selectedCourseName, idLookup);
  const SelectedSubjectIcon = selectedCourseSubject
    ? iconMap[selectedCourseSubject]
    : undefined;
  const mostRecentCompletedCourse = selectedCourseSubject
    ? mostRecentCourseCompletionsBySubject?.[selectedCourseSubject]
    : undefined;

  const moduleSectionProgressesForCourse = moduleSectionProgresses.filter(
    m => m.moduleProperties.properties.courseId === courseId,
  );

  const recordingsForCourse = getRecordingsForCourse(
    studentRecordings,
    idLookup,
    courseId,
  );

  return (
    <div className="flex flex-col gap-6">
      {/* student and course selector */}
      <div className="grid lg:grid-cols-3 gap-6">
        <div>
          <ReactSelectField
            options={students.map(student => ({
              value: student._id,
              label: `${student.firstName} ${student.lastName}`,
            }))}
            value={student._id}
            onChange={v => history.push(`/learner/${v}/parent_corner`)}
            styles={{
              control: (provided: any) => ({
                ...provided,
                padding: '0.5rem 0',
                borderRadius: '.5rem',
                border: 'none',
                boxShadow: '0px 2px 4px rgba(98, 85, 176, 0.08)',
              }),
              singleValue: (provided: any) => ({
                ...provided,
                color: jDark[600],
                fontSize: '1rem',
              }),
              menuList: (provided: any) => ({
                ...provided,
                color: jDark[600],
                fontSize: '1rem',
              }),
              indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
              }),
            }}
          />
        </div>
        <div className="lg:col-span-2">
          <ReactSelectField
            options={courses.map(course => ({
              value: course,
              label: idLookup[course].displayName,
            }))}
            value={selectedCourseName}
            onChange={setSelectedCourseName}
            styles={{
              control: (provided: any) => ({
                ...provided,
                padding: '0.5rem 0',
                border: `1px solid ${jDark[200]}`,
                borderRadius: '.5rem',
              }),
              singleValue: (provided: any) => ({
                ...provided,
                color: jDark[500],
                fontSize: '1rem',
              }),
              menuList: (provided: any) => ({
                ...provided,
                color: jDark[600],
                fontSize: '1rem',
              }),
              indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
              }),
            }}
          />
        </div>
      </div>
      {!selectedCourseName && (
        <Card shadow="1">{student.firstName} doesn't have any recent activity!</Card>
      )}
      {selectedCourseName && SelectedSubjectIcon && (
        <Card shadow="1">
          <div className="flex flex-col gap-6">
            <Section
              title="In Progress"
              titleLink={`/learner/${studentId}/roadmap/${selectedCourseSubject}/${selectedCourseName}`}
              subtitle="Sections currently in progress"
              disableDivider
            >
              {moduleSectionProgressesForCourse
                .filter(m => m.isInProgress && m.inProgressTimestamp)
                .sort(
                  (o1, o2) =>
                    new Date(o2.inProgressTimestamp!).getTime() -
                    new Date(o1.inProgressTimestamp!).getTime(),
                )
                .slice(0, 2)
                .map(m => (
                  <InnerCard
                    key={m.moduleSectionId}
                    title={m.sectionProperties.displayName}
                    body={m.moduleProperties.displayName}
                    leftIcon={
                      <SelectedSubjectIcon className="p-2 w-4 h-4 rounded-md text-white bg-j-green-400" />
                    }
                  />
                ))}
            </Section>

            <Section title="Mastered" subtitle="Recently completed sections">
              {moduleSectionProgressesForCourse
                .filter(m => m.isCompleted)
                .sort(
                  (o1, o2) =>
                    new Date(o2.timestamp).getTime() -
                    new Date(o1.timestamp).getTime(),
                )
                .slice(0, 2)
                .map(m => (
                  <InnerCard
                    key={m.moduleSectionId}
                    title={m.sectionProperties.displayName}
                    body={m.moduleProperties.displayName}
                    leftIcon={
                      <SelectedSubjectIcon className="p-2 w-4 h-4 rounded-md text-white bg-j-green-400" />
                    }
                  />
                ))}
            </Section>

            {selectedCourseNextRecommended.length !== 0 && (
              <Section title="Up Next" subtitle="Suggested next course">
                <div className="col-span-2 flex flex-col gap-6">
                  {selectedCourseNextRecommended.map(c => (
                    <InnerCard
                      key={c.name}
                      title={c.displayName}
                      body={c.courseDescription || ''}
                      leftIcon={
                        <SelectedSubjectIcon className="p-2 w-4 h-4 rounded-md text-white bg-j-green-400" />
                      }
                    />
                  ))}
                </div>
              </Section>
            )}

            {mostRecentCompletedCourse && (
              <Section
                title="Certificates Earned"
                subtitle="Certificates earned from completing courses"
              >
                {[mostRecentCompletedCourse].map(
                  (mostRecentCompletedCourse: string) => {
                    const track = TRACKS.find(
                      t => t.name === mostRecentCompletedCourse,
                    )!;
                    return (
                      <InnerCard
                        key={mostRecentCompletedCourse}
                        title={getShortDisplayName({
                          name: track.name,
                          displayName: track.displayName,
                          subject: selectedCourseSubject,
                        })}
                        onClick={() =>
                          history.push(
                            `/learner/${studentId}/course_certificate/${mostRecentCompletedCourse}`,
                          )
                        }
                        className="cursor-pointer lg:col-span-2 xl:col-span-1 items-center"
                        leftIcon={
                          <Award className="p-2 w-4 h-4 rounded-md text-j-orange-700 bg-j-orange-100" />
                        }
                        rightIcon={<Import />}
                      />
                    );
                  },
                )}
              </Section>
            )}

            {recordingsForCourse.length !== 0 && (
              <Section
                title="Created content"
                subtitle={`Project recordings created by ${student.firstName}`}
              >
                <div className="col-span-2 grid sm:grid-cols-2 gap-4">
                  {recordingsForCourse.map(recording => (
                    <div key={recording._id} className="my-3">
                      <FitVid
                        src={recording.s3Url}
                        autoPlay={false}
                        controls
                        loop={false}
                        muted={false}
                        noBorderRadius
                        unlockAspectRatio={false}
                      />
                      {recording.title}
                    </div>
                  ))}
                </div>
              </Section>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

interface ISectionProps {
  title: string;
  titleLink?: string;
  subtitle: string;
  disableDivider?: boolean;
}

const Section: FC<ISectionProps> = ({
  title,
  titleLink,
  subtitle,
  disableDivider,
  children,
}) => {
  if (!children) return null;
  return (
    <>
      {!disableDivider && <Card.Divider />}
      <div className="grid lg:grid-cols-3 gap-6">
        <div className="flex flex-col gap-2">
          <div className="font-medium text-j-dark-600">
            {titleLink ? (
              <Link
                target="_blank"
                className="text-j-dark-600 no-underline"
                to={titleLink}
              >
                {title}
              </Link>
            ) : (
              title
            )}
          </div>
          <div className="text-j-dark-300">{subtitle}</div>
        </div>
        {children}
      </div>
    </>
  );
};

interface InnerCardProps {
  title: string;
  body?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  className?: string;
  onClick?: () => void;
}

const InnerCard: FC<InnerCardProps> = ({
  title,
  body,
  leftIcon,
  rightIcon,
  className,
  onClick,
}) => (
  <div
    className={`flex items-start gap-4 px-4 py-3 border border-solid border-j-dark-100 rounded-lg ${className}`}
    onClick={onClick}
  >
    <div>{leftIcon}</div>
    <div className="flex flex-col gap-1">
      <div className="font-medium text-j-dark-600">{title}</div>
      <div className="text-j-dark-300">{body}</div>
    </div>
    {rightIcon && <div className="ml-auto">{rightIcon}</div>}
  </div>
);

// populate module and course data from idLookup
const attachIdLookup = (
  moduleSectionProgresses: ModuleSectionProgress[],
  idLookup: any,
) =>
  moduleSectionProgresses
    .map(m => {
      if (!idLookup[m.moduleSectionId]) {
        console.log(`can't find module section ${m.moduleSectionId}`);
      }
      return m;
    })
    .filter(m => idLookup[m.moduleSectionId])
    .map(m => {
      const sectionProperties = idLookup[m.moduleSectionId] as IdLookupEntry;
      const moduleProperties = idLookup[
        sectionProperties.properties.moduleId!
      ] as IdLookupEntry;
      const courseProperties = idLookup[
        moduleProperties.properties.courseId!
      ] as IdLookupEntry;

      return {
        ...m,
        sectionProperties,
        moduleProperties,
        courseProperties,
      };
    });

// returns up to two most recently created recordings
const getRecordingsForCourse = (
  recordings: Usermedia[],
  idLookup: any,
  courseId: string,
) =>
  recordings
    .filter(recording => {
      const moduleSection = idLookup[recording.projectId];
      return moduleSection && moduleSection.properties.courseId === courseId;
    })
    .sort((o1, o2) => (new Date(o1.createdAt) > new Date(o2.createdAt) ? -1 : 1))
    .slice(0, 2);

const lessThanXWeeksAgo = (date: Date, weeksAgo: number) =>
  isAfter(date, subWeeks(new Date(), weeksAgo));

const getCourseIdsWithRecentProgress = (
  courses: string[],
  moduleSectionProgresses: AugmentedModuleSectionProgress[],
) => {
  const recentCourses = moduleSectionProgresses
    .filter(m => lessThanXWeeksAgo(parseISO(m.timestamp), WEEKS_AGO))
    .map(m => m.courseProperties.properties.name);

  return courses.filter(course => recentCourses.includes(course));
};

const toSubject = (courseName: string, idLookup: IdLookup) =>
  courseName
    ? COURSE_TYPE_SUBJECTS[idLookup[courseName].properties.courseType!]
    : undefined;

export default LearningAtAGlance;
