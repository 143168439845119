import React from 'react';
import classNames from 'classnames';

const ProgressBadge: React.FC<{ label: string }> = ({ label }) => (
  <div
    className={classNames(
      'inline',
      'p-2',
      'rounded-md',
      'text-xs',
      'uppercase',
      'font-graphik',
      'font-medium',
      'leading-none',
      'text-j-orange-700',
      'bg-j-orange-100',
    )}
  >
    <span className="whitespace-nowrap">{label}</span>
  </div>
);

export default ProgressBadge;
