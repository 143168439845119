import React from 'react';
import { JuniverseUserProfile } from 'generated/graphql';
import { useLocation } from 'react-router-dom';
import { mongoIDToDateStr } from 'utils/timeUtils';
import astronaut from 'images/astronaut.svg';
import otterSquare from 'images/otter_square.svg';
import { Icon, NewButton as Button } from 'core-components';
import UserTypeBadge from '../../../components/UserTypeBadge';
import HeaderStat from './HeaderStat';

const previewLinkHref = (pathname: string, search: string) => {
  const searchParams = new URLSearchParams(search);
  searchParams.set('preview', 'true');
  return `${pathname}?${searchParams.toString()}`;
};

interface HeaderProps
  extends Pick<
    JuniverseUserProfile,
    | '_id'
    | 'firstName'
    | 'lastInitial'
    | 'type'
    | 'username'
    | 'projectViewsAllTime'
    | 'projectsPublishedAllTime'
    | 'isGuest'
    | 'createdAt'
  > {
  isActiveStudent: boolean;
}

const Header: React.FC<HeaderProps> = ({
  _id,
  firstName,
  lastInitial,
  type,
  username,
  projectViewsAllTime,
  projectsPublishedAllTime,
  isGuest,
  createdAt,
  isActiveStudent,
}) => {
  const { pathname, search } = useLocation();
  const showPreviewBtn =
    isActiveStudent && new URLSearchParams(search).get('preview') !== 'true';

  return (
    <>
      <div className="h-20 bg-j-purple-600 w-full">
        <div className="px-6 xl:px-18 font-graphik text-xs leading-5 font-medium">
          <a
            href="https://junilearning.com/juniverse"
            className="text-j-purple-100 no-underline hover:text-j-purple-400 active:text-j-purple-500"
          >
            JUNIVERSE HOME
          </a>
          <span className="mx-2 text-j-purple-300">/</span>
          <span className="text-j-purple-300">{username.toUpperCase()}</span>
        </div>
      </div>
      <div className="pt-18 lg:pt-10 pb-6 lg:pb-10 px-6 xl:px-18 flex justify-center bg-white">
        <div className="relative w-full max-w-screen-xl">
          <img
            // students and guests both get the astronaut, only instructors get the otter square
            src={type === 'INSTRUCTOR' ? otterSquare : astronaut}
            alt="User Profile"
            className="h-20 w-20 absolute block rounded-full -top-28 lg:-top-20 transform left-2/4 lg:left-0 -translate-x-1/2 lg:translate-x-0"
          />
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
            <div className="flex flex-col lg:flex-row items-center font-graphik text-j-dark-600">
              <div className="text-2xl font-medium">
                {firstName} {lastInitial}
                {lastInitial && '.'}
              </div>
              <div className="lg:ml-3 text-base">@{username}</div>
              <div className="mt-1 lg:mt-0 lg:ml-3">
                <UserTypeBadge userType={isGuest ? 'GUEST' : type} />
              </div>
            </div>
            {showPreviewBtn && (
              <div className="flex justify-center lg:hidden mt-6">
                <Button
                  variant="secondary"
                  href={previewLinkHref(pathname, search)}
                  renderIconLeft={props => <Icon.Eye {...props} />}
                >
                  View public profile
                </Button>
              </div>
            )}
            <div className="flex justify-center mt-6 lg:mt-0">
              <div className="flex">
                {Number.isInteger(projectViewsAllTime) && (
                  <HeaderStat
                    label="Project Views"
                    className="flex-1 flex flex-col justify-between lg:ml-6 w-24 lg:w-40 xl:w-48 px-3 lg:p-0 lg:pl-4 border-l-0 lg:border-l border-r lg:border-r-0 text-center lg:text-left"
                  >
                    {(projectViewsAllTime || 0).toLocaleString()}
                  </HeaderStat>
                )}
                {Number.isInteger(projectsPublishedAllTime) && (
                  <HeaderStat
                    label="Published Projects"
                    className="flex-1 flex flex-col justify-between lg:ml-6 w-24 lg:w-40 xl:w-48 px-3 lg:p-0 lg:pl-4 border-l-0 lg:border-l border-r lg:border-r-0 text-center lg:text-left"
                  >
                    {(projectsPublishedAllTime || 0).toLocaleString()}
                  </HeaderStat>
                )}
                <HeaderStat
                  label="Member Since"
                  className="flex-1 flex flex-col justify-between lg:ml-6 w-24 lg:w-40 xl:w-48 px-3 lg:p-0 lg:pl-4 border-l-0 lg:border-l text-center lg:text-left"
                >
                  {new Date(createdAt || mongoIDToDateStr(_id)).toLocaleDateString(
                    'default',
                    {
                      year: 'numeric',
                      month: 'short',
                    },
                  )}
                </HeaderStat>
              </div>
            </div>
          </div>
          {showPreviewBtn && (
            <div className="hidden lg:block mt-4 -mb-6">
              <Button
                variant="secondary"
                href={previewLinkHref(pathname, search)}
                renderIconLeft={props => <Icon.Eye {...props} />}
              >
                View public profile
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
