import React, { FC } from 'react';

import useDimensions from 'hooks/dimensions';

import _ from 'lodash';
import { PortalNavDesktop, PortalNavMobile } from './PortalNav';
import { PortalContainer, PortalView } from './styles';
import { PortalContentProps } from './types';

const PortalContent: FC<PortalContentProps> = ({
  options,
  pathname,
  userFirstName,
  setImpersonatedPath,
}) => {
  const { width } = useDimensions();
  const showDesktopLayout = width > 920;
  const activeOption =
    options.find(option => option.path === pathname) || options[0];
  return (
    <PortalContainer showDesktopLayout={showDesktopLayout}>
      {_.last(pathname.split('/')) !== 'my_homepage_v2' && (
        <>
          {showDesktopLayout ? (
            <PortalNavDesktop
              options={options}
              activeOption={activeOption}
              userFirstName={userFirstName}
              setImpersonatedPath={setImpersonatedPath}
            />
          ) : (
            <PortalNavMobile
              options={options}
              activeOption={activeOption}
              userFirstName={userFirstName}
              setImpersonatedPath={setImpersonatedPath}
            />
          )}
        </>
      )}
      <PortalView>{activeOption.component}</PortalView>
    </PortalContainer>
  );
};

export default PortalContent;
