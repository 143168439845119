import styled from 'styled-components/macro';

import { CardOrientations } from './models';

export const ProjectCardContainer = styled.div<any>`
  border: var(--juni-card-border);
  background: var(--juni-card, hsl(200, 100%, 98%));
  box-shadow: var(--juni-shadow, 0px 8.14931px 22.7956px -4px rgb(219 238 255));
  border-radius: var(--radius, 8px);
  display: flex;
  align-items: stretch;
  padding: 0.75rem;
  flex: 1 1 auto;
  cursor: pointer;
  transition: all 0.2s linear;
  & .project-card-link {
    display: flex;
    align-items: stretch;
    text-decoration: none;
    justify-content: space-between;
    flex: 1;
  }

  & .icon__container {
    display: flex;
    align-self: stretch;
    border: 2px solid #def0f9;
    border-radius: 8px;

    & img {
      max-width: 100%;
    }
  }

  & .content__container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  ${props =>
    props.orientation === CardOrientations.landscape
      ? `  
        flex-wrap: wrap;

        & .icon__container {
          justify-content: center;
          width: 72px;
          height: 60px;
          & img {
            width: 70%;
            padding: .25rem;
            object-fit: contain;
          }
        }

      `
      : ``}

  ${props =>
    props.orientation === CardOrientations.portrait
      ? `  
        padding: .75rem;
        flex-direction: column;

        & .project-card-link {
          flex-direction: column;
        }

        & .content__container {
            padding: .6rem 2px 0;
            box-sizing: border-box;
        }
        & .icon__container {
          flex: 1;
          align-items: center;
          min-height: 120px;

          & img {
            width: auto;
            height: 80%;
            margin: 0 auto;
            object-fit: contain;
            max-height: 80px;
            width: 100%;
          }
        }

      `
      : ``}

  flex-wrap: nowrap;

  &::last-of-type {
    margin-right: auto;
  }
`;

export const ProjectInfo = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  flex-wrap: nowrap;
  align-self: center;

  ${props =>
    props.orientation === CardOrientations.portrait
      ? `
        align-self: flex-start;
        padding-left: 0;
      `
      : ``}

  & span.project-title {
    color: var(--juni-primary, #335875);
    font-size: var(--juni-body-size, 0.8rem);
    font-weight: 700;
  }
  .project-published_at {
    white-space: nowrap;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      border-left: 1px solid #d1eaf7;
      top: 4px;
      height: 12px;
      left: 0;
    }
  }
  & span.project-type {
    line-height: 1.5rem;
    font-size: var(--juni-body-size, 0.8rem);
    padding-right: 0.5rem;
    flex-wrap: nowrap;
    white-space: nowrap;

    @media (max-width: 850px) {
      white-space: pre-wrap;
    }
  }

  & .project-meta__container {
    align-items: center;
    display: flex;
  }

  & span.project-published_at {
    color: #8c9ea8;
    font-size: var(--juni-body-size, 0.8rem);
    padding-left: 0.5rem;
  }
`;

export const ProjectBadges = styled.div`
  display: flex;
  margin-left: auto;
  align-self: flex-start;
  > * {
    position: relative;
  }
`;
