import React, { FC, useContext } from 'react';

import { useLoadMyStripeCustomerQuery } from 'generated/graphql';

import UserContext from 'modules/UserContext';
import GuestEnrollmentBanner from './GuestEnrollmentBanner';

import PortalSessionAlert from './PortalSessionAlert';
import PortalBillingAlert from './PortalBillingAlert';

import PortalContent from './PortalContent';
import { PortalLayoutProps } from './types';

const PortalLayout: FC<PortalLayoutProps> = ({
  options,
  pathname,
  userFirstName,
  studentId,
  joinableSession,
  timezone,
}) => {
  const { user } = useContext(UserContext);
  const { data } = useLoadMyStripeCustomerQuery({
    skip: pathname.startsWith('/teacher') || user?.isGuest,
  });
  const stripeCustomer = data?.myStripeCustomer;
  return (
    <>
      {stripeCustomer?.delinquent && (
        <PortalBillingAlert stripeCustomer={stripeCustomer} />
      )}
      {joinableSession && (
        <PortalSessionAlert session={joinableSession} timezone={timezone} />
      )}
      {user?.isGuest && <GuestEnrollmentBanner studentId={studentId} />}
      <PortalContent
        studentId={studentId}
        options={options}
        pathname={pathname}
        userFirstName={userFirstName}
      />
    </>
  );
};

export default PortalLayout;
