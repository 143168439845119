import React, { FC } from 'react';
import { JuniClubLookupType, MsgType } from 'app/clubs/MyClubsTypes';
import MyClubCard from '../MyClubCard';

export interface ClubNotificationsSectionProps {
  studentId?: string;
  instructorUserId?: string;
  previewMessagesByClub?: Record<string, MsgType[]>;
  unreadMessagesByClub?: Record<string, number>;
  juniClubs: JuniClubLookupType;
  juniClubIds: string[];
}
const ClubNotificationsSection: FC<ClubNotificationsSectionProps> = ({
  previewMessagesByClub,
  unreadMessagesByClub,
  studentId,
  juniClubs,
  juniClubIds,
}) => (
  <>
    {juniClubIds.length
      ? juniClubIds.map(juniClubId => (
          <MyClubCard
            key={juniClubId}
            juniClub={juniClubs[juniClubId]}
            studentId={studentId}
            // TODO: MyClubCard needs to get instructor notifications.
            notifications={
              unreadMessagesByClub ? unreadMessagesByClub[juniClubId] : undefined
            }
            messagesToShow={
              previewMessagesByClub ? previewMessagesByClub[juniClubId] : undefined
            }
          />
        ))
      : null}
  </>
);
export default ClubNotificationsSection;
