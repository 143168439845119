import classNames from 'classnames';
import Card from 'core-components/NewCard';
import useDimensions from 'hooks/dimensions';
import React from 'react';
import Breadcrumbs from './Breadcrumbs';
import { ClockIcon, EmailIcon, PhoneIcon } from './ContactIcon';
import { FloatingCircle, getCircleAnchorPoint } from './HeaderCircles';
import { GreenIconWrapper, StyledHeader } from './styles';

const Contact: React.FC<{ baseUrl: string }> = ({ baseUrl }) => {
  const { width } = useDimensions();
  const breadcrumbs = [
    { to: baseUrl, name: 'Help Desk' },
    {
      to: `${baseUrl}/contact`,
      name: 'Contact',
    },
  ];
  return (
    <div>
      <div
        className={classNames([
          'flex',
          'flex-col',
          'justify-center',
          'items-center',
          'relative',
          'py-24',
          'bg-j-purple-600',
          'w-full',
          'text-white',
          'overflow-hidden',
          'z-10',
        ])}
      >
        <h1 className="mb-3 font-semibold text-4xl z-20">Contact Us</h1>
        <p className="font-normal px-4 z-20 text-center">
          Reach out to us by email or phone, and a member of the team will be happy
          to help.
        </p>

        <FloatingCircle
          bottom="-7rem"
          left={`${getCircleAnchorPoint({
            width,
            smallVwAnchor: -30,
            largeVwAnchor: -5,
          })}vw`}
          size="16rem"
          className="bg-j-purple-700 z-0"
        />
        <FloatingCircle
          top="-7rem"
          left={`${getCircleAnchorPoint({
            width,
            smallVwAnchor: 80,
            largeVwAnchor: 90,
          })}vw`}
          size="18rem"
          className="bg-j-purple-700 z-0"
        />
      </div>
      <div className="px-10 lg:px-32 py-4">
        <StyledHeader>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </StyledHeader>
        <div className="pt-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Card padding="6">
            <div className="flex flex-col">
              <div className="flex items-center pb-4">
                <GreenIconWrapper>
                  <div className="w-4 h-4">
                    <EmailIcon />
                  </div>
                </GreenIconWrapper>
                <div className="text-lg font-normal text-j-dark-600">Email</div>
              </div>
              <div className="font-normal mb-1">Please email us at </div>
              <div className="font-normal text-j-blue-400">
                support@learnwithjuni.com
              </div>
            </div>
          </Card>
          <Card padding="6">
            <div className="flex flex-col">
              <div className="flex items-center pb-4">
                <GreenIconWrapper>
                  <div className="w-4 h-4">
                    <PhoneIcon />
                  </div>
                </GreenIconWrapper>
                <div className="text-lg font-normal text-j-dark-600">Phone</div>
              </div>
              <div className="font-normal mb-1">Feel free to call us at</div>
              <div className="font-normal text-j-blue-400">(650) 263-4306</div>
            </div>
          </Card>
          <Card padding="6">
            <div className="flex flex-col">
              <div className="flex items-center pb-4">
                <GreenIconWrapper>
                  <div className="w-4 h-4">
                    <ClockIcon />
                  </div>
                </GreenIconWrapper>
                <div className="text-lg font-normal text-j-dark-600">
                  Opening Hours
                </div>
              </div>
              <div className="font-normal mb-1">Mon - Fri 8:00 am - 8:00 pm PT</div>
              <div className="font-normal ">Sat - Sun 7:00 am - 6:30 pm PT </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Contact;
