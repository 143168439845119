import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const PortalDropdownNavWrapper = styled.div.attrs({ className: 'bg-juni-dark-900' })`
  position: sticky;
  z-index: 100;
  width: 100%;
  top: 60px;
`;

const OptionWrapper = css`
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #6255b0;
`;

const DropdownNavBar = styled.div`
  ${OptionWrapper}
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  width: unset;
  cursor: pointer;
`;

const DropdownNavOptions = styled.div.attrs({ className: 'bg-juni-dark-900' })`
  position: absolute;
  width: 100%;
`;

const TextAndIcon = styled.div`
  ${OptionWrapper}
  display: flex;
`;

const WelcomeWrapper = styled.div`
  text-align: left;
  height: 55px;
  border-bottom: 1px solid #6255b0;
`;

const WelcomeText = styled.div`
  color: white;
  font-size: 16px;
  padding: 15px 20px;
`;

const MobileLink = styled(Link)<any>`
  ${OptionWrapper}
  background: ${props =>
    props.$active && 'linear-gradient(180deg, #EB7480 0%, #E75564 100%)'};
  text-decoration: none;
  display: flex;
  border: ${props => props.$active && 'none'};
`;

const MobileButton = styled.div<any>`
  ${OptionWrapper}
  background: ${props =>
    props.active && 'linear-gradient(180deg, #EB7480 0%, #E75564 100%)'};
  cursor: pointer;
  display: flex;
  border: ${props => props.active && 'none'};
`;

const LinkText = styled(WelcomeText)<any>`
  font-weight: ${props => props.active && '800'};
`;

const MobileActiveComponentOverlay = styled.div`
  background-color: #004c69;
  opacity: 0.22;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 99;
  top: 60px;
`;

export {
  PortalDropdownNavWrapper,
  DropdownNavBar,
  DropdownNavOptions,
  TextAndIcon,
  WelcomeWrapper,
  WelcomeText,
  MobileLink,
  MobileButton,
  LinkText,
  MobileActiveComponentOverlay,
};
