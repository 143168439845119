import { detectEvenOrOddHour } from 'utils/timeUtils';
import generateRest from '../generateRest';

export const instructorZoomAccountsService = generateRest<any>(
  'zoom/meeting_rooms',
  { noEnvelope: true },
);

export const makeZoomUrl = (roomUrlMeetingId: number): string =>
  `https://zoom.us/j/${roomUrlMeetingId}`;

export enum conferenceRoomTypes {
  Odd = 'odd',
  Even = 'even',
  Trial = 'trial',
}

export interface IConferenceRoom {
  _id?: string;
  roomUrlMeetingId: number;
  roomType: conferenceRoomTypes;
}
export interface IConferenceRoomForView {
  zoomUrl?: string;
  zoomId?: number;
  roomType?: conferenceRoomTypes | string;
  roomUrlMeetingId?: number;
  zoomMeetingId?: number;
}

export interface IZoomAccount {
  _id: string;
  zoomId: string;
  activeInstructorCalendarId: number;
  rooms: Array<IConferenceRoom>;
}

export const getSingleConferenceRoom = async ({
  calendarId,
  lessonDatetime,
  isTrial,
}: {
  calendarId: number;
  lessonDatetime?: string;
  isTrial: boolean;
}): Promise<IConferenceRoomForView> => {
  const rooms = await instructorZoomAccountsService.list({
    activeInstructorCalendarId: calendarId,
  });

  const room = rooms[0] ? rooms[0].rooms : [];
  const selectedRoom = room.filter(
    (r: IConferenceRoom) =>
      r.roomType ===
      (isTrial ? conferenceRoomTypes.Trial : detectEvenOrOddHour(lessonDatetime)),
  )[0];

  const zoomMeetingId = selectedRoom?.roomUrlMeetingId;
  const zoomUrl =
    selectedRoom && zoomMeetingId ? makeZoomUrl(zoomMeetingId) : undefined;

  return {
    zoomUrl,
    zoomMeetingId,
  };
};
