import JuniSpinner from 'components/JuniSpinner';
import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { JUNI_LINKS } from 'constants/links';
import LinkCopier from 'components/LinkCopier';
import Button from 'core-components/NewButton';
import styled from 'styled-components/macro';
import { JuniLogo } from 'components/brand-assets';
import Youtube from 'react-youtube';
import useDimensions from 'hooks/dimensions';
import useReferralCTA from 'app/signup_session/hooks/useReferralCTA';
import GiftCard from './gift-card.svg';
import GiftCard404 from './gift-card-404.svg';

import Background from './bg.png';
import Step1 from './step-1.svg';
import Step2 from './step-2.svg';
import Step3 from './step-3.svg';
import useReferralCode from '../../hooks/useReferralCode';
import useSignupContext from '../../hooks/useSignupContext';

const PageWrapper = styled.div.attrs({
  className: 'min-h-screen flex flex-col items-center font-graphik',
})`
  background-image: url(${Background});
  background-size: 100%;
  background-repeat: no-repeat;
`;

const GiftCardImage = styled.img.attrs({
  className: 'w-1/3 md:w-1/4 z-10',
})`
  transform: translateY(50%);
`;

const TitleWrapper = styled.div.attrs({
  className: 'text-j-dark-600 text-xl w-5/6',
})`
  //due to the gift card overlap, this is a magic number selected so this looks good.
  margin-top: 32%;
  @media all and (max-width: 768px) {
    margin-top: 20%;
  }
`;
const NavBarHeader = styled.div.attrs({
  className: ' w-full flex justify-center sticky top-0 z-50',
})`
  backdrop-filter: blur(6px);
  background-color: rgba(98, 85, 176, 0.8);
`;
const CardWrapper = styled.div.attrs({
  className: ' bg-white p-4 md:p-6 rounded-lg shadow-1 w-10/12 md:w-1/3',
})``;
const CardContentWrapper = styled.div.attrs({
  className: 'flex flex-col items-center space-y-2 text-center',
})``;

const ReferralsPage: FC = () => {
  const { width } = useDimensions();
  // below line: on mobile, width should be 11/12 the width, else should be 1/2 the width
  const videoWidth = width < 768 ? (11 * width) / 12 : width / 2;
  const match = useRouteMatch<{ referralCode?: string }>();
  const { signupData } = useSignupContext();
  const referralCodeFromSession = signupData.discountCodes?.find(
    code => code.isReferral,
  );
  const { isLoading, isValid, referrerName, referralCode } = useReferralCode(
    match.params.referralCode ?? referralCodeFromSession?.discountCode,
  );
  const { isLoading: isLoadingReferralCTA, onClaim } = useReferralCTA(referralCode);

  if (isLoading) return <JuniSpinner size={80} />;

  const NavBar = () => (
    <NavBarHeader>
      <div className="flex flex-row justify-between py-2 items-center w-11/12">
        <a href={JUNI_LINKS.MARKETING}>
          <JuniLogo color="light" />
        </a>
        <Button
          key="claim-my-credit-button"
          disabled={isLoadingReferralCTA}
          onClick={() =>
            onClaim({ componentName: 'nav_bar_trial_button', referralCode })
          }
          intent="success"
        >
          Get $50 Off & 1st Class Free
        </Button>
      </div>
    </NavBarHeader>
  );

  const ValidCard = () => (
    <CardWrapper>
      <CardContentWrapper>
        <TitleWrapper>
          <strong>{referrerName}</strong> gifted you{' '}
          <strong className="text-j-green-500">$50</strong> off your first month with
          Juni
        </TitleWrapper>
        <div className="w-5/6 mb-4">
          <Button
            key="claim-my-credit-button"
            disabled={isLoadingReferralCTA}
            onClick={() =>
              onClaim({ componentName: 'valid_card_trial_button', referralCode })
            }
            intent="success"
            size="large"
            fullWidth
          >
            Get $50 Off & 1st Class Free{' '}
          </Button>
        </div>
        <div className="w-5/6">
          <LinkCopier
            variant="light"
            width="w-full"
            referralCode={referralCode?.toUpperCase() || ''}
          />
        </div>
        <div className="text-sm w-5/6 text-j-dark-300">
          This credit will automatically be enabled at checkout. If you have ad
          blockers on your browser, enter the code directly.
        </div>
      </CardContentWrapper>
    </CardWrapper>
  );
  const InvalidCard = () => (
    <CardWrapper>
      <CardContentWrapper>
        <TitleWrapper>
          <strong>
            Check your email for a referral link if you were invited by a friend.
          </strong>
        </TitleWrapper>

        <div className="text-base w-5/6 ">
          You can also continue without a referral by starting your trial or setting
          up an appointment with one of our friendly advisors.
        </div>

        <div className="flex flex-col md:flex-row justify-between w-full md:space-x-4 pt-8">
          <Button
            key="claim-my-credit-button"
            disabled={isLoadingReferralCTA}
            onClick={() =>
              onClaim({ componentName: 'invalid_card_trial_button', referralCode })
            }
            intent="success"
            size="large"
            fullWidth
          >
            Start My Free Trial
          </Button>
          <Button
            key="Book a call"
            onClick={() => {
              window.location.href =
                'https://calendly.com/juni-advisors/introduction?utm_source=referralpagelead&utm_medium=referralpagelead&utm_campaign=referral';
            }}
            variant="secondary"
            size="large"
            fullWidth
          >
            Book a Call
          </Button>
        </div>
      </CardContentWrapper>
    </CardWrapper>
  );

  const CallSection = () => (
    <>
      <div>
        <Button
          key="claim-my-credit-button"
          disabled={isLoadingReferralCTA}
          onClick={() =>
            onClaim({ componentName: 'call_section_trial_button', referralCode })
          }
          intent="success"
          size="large"
        >
          Get $50 Off & 1st Class Free
        </Button>
      </div>
      <div className="py-10 w-11/12 md:w-1/3 text-j-dark-300 text-center">
        Or{' '}
        <a
          href="https://calendly.com/juni-advisors/introduction?utm_source=referralpagelead&utm_medium=referralpagelead&utm_campaign=referral"
          className="text-j-dark-600"
        >
          book a call
        </a>{' '}
        with one of our friendly advisors to discuss if Juni is right for you.
      </div>
    </>
  );

  const blockStyles = `flex flex-col items-center space-y-4 w-11/12 md:w-3/12 text-center`;
  const TrialSection = () => (
    <>
      <div className="text-2xl text-j-dark-600 font-semibold pt-20 pb-8 text-center">
        Get started in 3 easy steps
      </div>
      <div className="w-full flex flex-col space-y-8 md:space-y-0 items-center md:items-baseline md:flex-row md:justify-around pb-8">
        <div className={blockStyles}>
          <img src={Step1} alt="" className="w-1/3" />
          <div>Select your membership plan and class frequency.</div>
        </div>
        <div className={blockStyles}>
          <img src={Step2} alt="" className="w-1/3" />
          <div>
            Choose your courses, schedule, and instructor learning preferences.
          </div>
        </div>
        <div className={blockStyles}>
          <img src={Step3} alt="" className="w-1/3" />
          <div>Try your 1st class free!</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <NavBar />
      <PageWrapper>
        <GiftCardImage src={isValid ? GiftCard : GiftCard404} alt="" />
        {isValid ? <ValidCard /> : <InvalidCard />}
        <div className="pt-24">
          <Youtube
            videoId="MfRTnnMjwOg"
            opts={{
              height: (videoWidth / 1.6).toString(),
              width: videoWidth.toString(),
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                playsinline: 0,
              },
            }}
          />
        </div>
        <div className="py-4 text-center w-11/12 md:w-1/3 text-j-dark-600">
          <strong>Juni Learning</strong> is an online learning experience for kids
          7-18, preparing them for the real world through 1:1 instruction. Access our
          online community of learners and explore courses in coding, math, investing
          & more!
        </div>
        <TrialSection />
        {isValid && <CallSection />}
        <div className="w-full flex items-center py-4 justify-center text-center mt-auto bg-juni-dark-700 text-white">
          <span className="w-4/5">
            Copyright &#169; {new Date().getFullYear()} Juni Learning. All rights
            reserved.
          </span>
        </div>
      </PageWrapper>
    </>
  );
};

export default ReferralsPage;
