import juniAxios from 'services/axios';

const discountCodeService = {
  validateReferralCode: async (referralCode: string) =>
    juniAxios.get<{
      data: {
        _id: string;
        name: string;
        code: string;
      }[];
    }>(`/join/referral_codes?code=${referralCode}`),
  validateCouponCode: async (couponCode: string) =>
    juniAxios.get(`/auth/coupon/${couponCode}`),
};

export default discountCodeService;
