import {
  IPlaygroundProject,
  IUpdateProjectNameParams,
  IValidationResponse,
} from 'app/playground/models';

export interface ICurrentProject {
  properties: {
    canCodeBePublic: boolean;
    courseId: string;
    difficulty: string | null;
    instructions: string;
    isProject: boolean;
    isSupplemental: boolean;
    moduleId: string;
    moduleSectionBullets: Array<string>;
    projectLink: string | null;
    solutionLink: string | null;
    solutionLinkJava: string | null;
    videoLink: string | null;
  };
  displayName: string | null;
}

export enum JideUserTypes {
  student = 'student',
  instructor = 'instructor',
}

export interface ICustomScratchProject {
  _id: string;
  userId: string;
  userType: JideUserTypes;
  moduleId: string | null;
  projectId: string | null;
  scratchUrl: string | null;
  displayName: string;
}

export interface ICustomScratchProjectProps {
  initialDisplayName: string;
  moduleId: string | null;
  projectId: string | null;
  user: {
    _id: string;
    type: JideUserTypes;
  };
  customScratchProject: ICustomScratchProject;
  setCustomScratchProject: (project: ICustomScratchProject) => void;
  playgroundProject?: IPlaygroundProject;
  updateProjectName?: (
    params: IUpdateProjectNameParams,
  ) => Promise<IValidationResponse | undefined>;
}
