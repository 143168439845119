import React, { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  NewButton as Button,
  NewCard as Card,
  TextArea,
  Icon,
  Message,
} from 'core-components';
import {
  HomeworkLoadType,
  LearningPreferencesInput,
  LessonPaceType,
  Student,
  SubjectFragment,
  useUpdateLearningPreferencesMutation,
} from 'generated/graphql';
import _ from 'lodash';
import SpinnerV2 from 'components/SpinnerV2';
import Tooltip from 'core-components/Tooltip';

export const LESSON_PACE_DISPLAY_NAME = {
  [LessonPaceType.InDepth]: 'In-depth',
  [LessonPaceType.Standard]: 'Standard',
  [LessonPaceType.FastPaced]: 'Fast-paced',
};

export const HOMEWORK_LOAD_DISPLAY_NAME = {
  [HomeworkLoadType.None]: 'None',
  [HomeworkLoadType.ThirtyMinutes]: '30 minutes',
  [HomeworkLoadType.SixtyMinutes]: '60 minutes',
};

interface IRadioProps {
  name: string;
  value: string;
  label: string;
  selected: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: FC<IRadioProps> = ({
  name,
  value,
  label,
  selected = false,
  onChange,
}) => (
  <div className="flex flex-row items-top text-j-dark-600">
    <input
      type="radio"
      name={name}
      value={value}
      checked={selected}
      onChange={onChange}
      className="h-4 w-4 border-j-gray-500 focus:ring-3 text-j-purple-500"
    />
    <label className="text-j-dark-600 pl-1">{label}</label>
  </div>
);

interface IPreferenceCardProps {
  subject: SubjectFragment;
  student: Student;
}

const SubjectPreferencesCard: FC<IPreferenceCardProps> = ({ subject, student }) => {
  const [
    saveLearningPreferences,
    { loading },
  ] = useUpdateLearningPreferencesMutation();
  const learningPreferences = _.find(student?.learningPreferences, [
    'subject',
    subject.name,
  ]);
  const originalData = {
    lessonPace: learningPreferences?.lessonPace ?? LessonPaceType.Standard,
    homeworkLoad: learningPreferences?.homeworkLoad ?? HomeworkLoadType.SixtyMinutes,
    noteForInstructor: learningPreferences?.noteForInstructor ?? '',
  };
  const [isEditing, setIsEditing] = useState(false);
  const [formValues, setFormValues] = useState(originalData);
  const [error, setError] = useState<string | undefined>(undefined);
  const [updatedDate, setUpdatedDate] = useState(
    learningPreferences?.updatedAt ?? undefined,
  );

  useEffect(() => {
    setFormValues(originalData);
    setUpdatedDate(learningPreferences?.updatedAt ?? undefined);
    setIsEditing(false);
    setError(undefined);
    // reload when student switches
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  const handleCancel = () => {
    setFormValues(originalData);
    setIsEditing(false);
  };

  const handleSubmit = async () => {
    const input = {
      subject: subject.name,
      ...formValues,
    };
    try {
      const savedLearningPreferences = await saveLearningPreferences({
        variables: {
          input: {
            studentId: student._id,
            learningPreferences: input as LearningPreferencesInput,
          },
        },
      });
      const result =
        savedLearningPreferences.data?.updateLearningPreferences
          .updatedLearningPreference;
      setUpdatedDate(result?.updatedAt ?? undefined);
      setIsEditing(false);
      if (error) {
        setError(undefined);
      }
    } catch (err) {
      setError('Failed to update, please try again or contact Juni support.');
    }
  };

  const handleLessonPaceChange = (e: { target: { value: any } }) => {
    setFormValues({
      ...formValues,
      lessonPace: e.target.value,
    });
  };

  const handleHomeworkLoadChange = (e: { target: { value: any } }) => {
    setFormValues({
      ...formValues,
      homeworkLoad: e.target.value,
    });
  };

  return (
    <>
      <Card.Divider />
      <div className="grid lg:grid-cols-3 gap-6">
        <div className="flex flex-col gap-2">
          <div className="font-medium text-j-dark-600">{subject.displayName}</div>
          <div className="text-j-dark-300 italic">
            {updatedDate &&
              `Last Updated: ${format(new Date(updatedDate), 'MMMM dd, yyyy')}`}
          </div>
        </div>
        <div className="col-span-2">
          {error && (
            <div className="pb-5">
              <Message status="error">{error}</Message>
            </div>
          )}
          <div className="flex flex-col gap-10 relative">
            <div className="w-full flex flex-row gap-2">
              <div className="flex flex-row gap-1 w-1/3 text-j-dark-300">
                Lesson Pace
                <Tooltip
                  title="Lesson pace will be tailored to each student's learning style; default is standard, select in-depth to slow down and go deeper or fast-paced for more high-level but quicker progression. Your instructor will do their best to accommodate your preferences."
                  placement="top"
                >
                  <Icon.Info className="w-3 h-3" />
                </Tooltip>
              </div>
              {isEditing ? (
                <div className="w-2/3 flex justify-between">
                  <Radio
                    name={`${subject.name}-lessonPace`}
                    value={LessonPaceType.InDepth}
                    label={LESSON_PACE_DISPLAY_NAME[LessonPaceType.InDepth]}
                    selected={formValues.lessonPace === LessonPaceType.InDepth}
                    onChange={handleLessonPaceChange}
                  />
                  <Radio
                    name={`${subject.name}-lessonPace`}
                    value={LessonPaceType.Standard}
                    label={LESSON_PACE_DISPLAY_NAME[LessonPaceType.Standard]}
                    selected={formValues.lessonPace === LessonPaceType.Standard}
                    onChange={handleLessonPaceChange}
                  />
                  <Radio
                    name={`${subject.name}-lessonPace`}
                    value={LessonPaceType.FastPaced}
                    label={LESSON_PACE_DISPLAY_NAME[LessonPaceType.FastPaced]}
                    selected={formValues.lessonPace === LessonPaceType.FastPaced}
                    onChange={handleLessonPaceChange}
                  />
                </div>
              ) : (
                <div className="text-j-dark-600">
                  {LESSON_PACE_DISPLAY_NAME[formValues.lessonPace as LessonPaceType]}
                </div>
              )}
              <div className="absolute top-0 right-0">
                {!isEditing && (
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    icon
                    onClick={() => setIsEditing(true)}
                  >
                    <Icon.PencilTool className="w-4 h-4" />
                  </Button>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-row gap-1 w-1/3 text-j-dark-300">
                Homework Load
                <Tooltip
                  title="Instructors aim to submit 60 minutes of homework per session. Update your preference here to request a lower volume of homework for your student."
                  placement="top"
                >
                  <Icon.Info className="w-3 h-3" />
                </Tooltip>
              </div>
              {isEditing ? (
                <div className="w-2/3 flex justify-between">
                  <Radio
                    name={`${subject.name}-homeworkLoad`}
                    value={HomeworkLoadType.None}
                    label={HOMEWORK_LOAD_DISPLAY_NAME[HomeworkLoadType.None]}
                    selected={formValues.homeworkLoad === HomeworkLoadType.None}
                    onChange={handleHomeworkLoadChange}
                  />
                  <Radio
                    name={`${subject.name}-homeworkLoad`}
                    value={HomeworkLoadType.ThirtyMinutes}
                    label={
                      HOMEWORK_LOAD_DISPLAY_NAME[HomeworkLoadType.ThirtyMinutes]
                    }
                    selected={
                      formValues.homeworkLoad === HomeworkLoadType.ThirtyMinutes
                    }
                    onChange={handleHomeworkLoadChange}
                  />
                  <Radio
                    name={`${subject.name}-homeworkLoad`}
                    value={HomeworkLoadType.SixtyMinutes}
                    label={HOMEWORK_LOAD_DISPLAY_NAME[HomeworkLoadType.SixtyMinutes]}
                    selected={
                      formValues.homeworkLoad === HomeworkLoadType.SixtyMinutes
                    }
                    onChange={handleHomeworkLoadChange}
                  />
                </div>
              ) : (
                <div className="text-j-dark-600">
                  {
                    HOMEWORK_LOAD_DISPLAY_NAME[
                      formValues.homeworkLoad as HomeworkLoadType
                    ]
                  }
                </div>
              )}
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-row gap-2 w-1/3 text-j-dark-300">
                Notes for Instructors
                <Tooltip
                  title="Feel free to share any learning style or instructor preferences here. If your child has any special learning needs, we’ll do our best to accommodate."
                  placement="top"
                >
                  <Icon.Info className="w-3 h-3" />
                </Tooltip>
              </div>
              {isEditing ? (
                <div className="w-2/3">
                  <TextArea
                    size="xsmall"
                    name="notesForInstructor"
                    value={formValues.noteForInstructor || ''}
                    fullWidth
                    onChange={e =>
                      setFormValues({
                        ...formValues,
                        noteForInstructor: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="w-2/3 text-j-dark-600">
                  {formValues.noteForInstructor ?? '-'}
                </div>
              )}
            </div>
          </div>
          {isEditing && (
            <div className="flex justify-end space-x-2 pt-2">
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={learningPreferences && _.isEqual(formValues, originalData)}
              >
                {loading ? <SpinnerV2 size={12} /> : 'Save'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubjectPreferencesCard;
