import { DiscountData } from '../types';

const updateSelectedDiscountData = (
  selectedDiscount: DiscountData,
  discountData?: DiscountData[],
): DiscountData[] =>
  discountData?.map(code =>
    code.discountCode === selectedDiscount.discountCode
      ? { ...selectedDiscount, isSelected: true }
      : { ...code, isSelected: false },
  ) ?? [];

export default updateSelectedDiscountData;
