import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { difficultyDisplayNames } from 'constants/project_difficulty';

import './jide_code_output.css';
import 'xterm/dist/xterm.css';

import ActivityInstructions from '../ActivityInstructions';
import OutputHeader from './OutputHeader';

const JideCodeOutputNew = props => {
  const { setPane, activePane, hasError, specialInstructions } = props;
  const showInstructions =
    ['teacher', 'student'].includes(props.jideUser.type) && !props.isPlayground;
  return (
    <div className="jco">
      <OutputHeader
        showInstructions={showInstructions}
        setPane={setPane}
        activePane={activePane}
        hasError={hasError}
        showTabs={specialInstructions}
      />
      <div className="jco-main">
        <div className="jco-elements-wrapper">
          <div
            className={`jco-element jco-console-output ${
              props.activePane === 'console' ? 'jco-active' : 'jco-inactive'
            }`}
            id={`jco-console-output-${props.tab.tabId}`}
          />
          {['teacher', 'student'].includes(props.jideUser.type) ? (
            <div
              className={`jco-element jco-instructions-pane ${
                props.activePane === 'instructions' ? 'jco-active' : 'jco-inactive'
              } p-0`}
            >
              <ActivityInstructions
                instructions={props.project && props.project.properties.instructions}
                difficultyLevel={
                  props.project &&
                  props.project.properties.isSupplemental &&
                  props.project.properties.difficulty
                    ? difficultyDisplayNames[props.project.properties.difficulty]
                    : null
                }
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

const JideCodeOutput = props => {
  if (props.newHorizons) {
    return <JideCodeOutputNew {...props} />;
  }

  return (
    <div className="jco">
      <div className="jco-bar">
        <div className="jco-bar-left">
          <div
            className={`jco-tab jco-console-tab ${
              props.activePane === 'console' ? 'jco-active' : 'jco-inactive'
            }${props.specialInstructions ? ' jco-hide' : ''}`}
            onClick={() => props.setPane('console')}
          >
            <div className="jco-tab-upper">
              <div className={`jco-iconBox ${props.hasError ? 'jco-errorBox' : ''}`}>
                {props.hasError ? (
                  <FontAwesomeIcon icon={['fas', 'exclamation']} />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'terminal']} />
                )}
              </div>
              &nbsp;CONSOLE
            </div>
            <div className="jco-tab-lower">
              <FontAwesomeIcon icon={['fas', 'caret-up']} />
            </div>
          </div>
        </div>

        <div className="jco-bar-right">
          {['teacher', 'student'].includes(props.jideUser.type) &&
          !props.isPlayground ? (
            <div
              className={`jco-tab jco-instructions-tab ${
                props.activePane === 'instructions' ? 'jco-active' : 'jco-inactive'
              }`}
              onClick={() => props.setPane('instructions')}
            >
              <div className="jco-tab-upper">
                <div className="jco-iconBox">
                  <FontAwesomeIcon icon={['fas', 'star']} />
                </div>
                &nbsp;INSTRUCTIONS
              </div>
              <div className="jco-tab-lower">
                <FontAwesomeIcon icon={['fas', 'caret-up']} />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="jco-main">
        <div className="jco-elements-wrapper">
          <div
            className={`jco-element jco-console-output ${
              props.activePane === 'console' ? 'jco-active' : 'jco-inactive'
            }`}
            id={`jco-console-output-${props.tab.tabId}`}
          />
          {['teacher', 'student'].includes(props.jideUser.type) ? (
            <div
              className={`jco-element jco-instructions-pane ${
                props.activePane === 'instructions' ? 'jco-active' : 'jco-inactive'
              }`}
            >
              {props.project &&
                props.project.properties.isSupplemental &&
                props.project.properties.difficulty && (
                  <div className="header-tag-v2 missed">
                    {difficultyDisplayNames[props.project.properties.difficulty]}
                  </div>
                )}
              <div
                className={`jco-instructions-pane-content ${
                  props.project && props.project.properties.isSupplemental
                    ? `jco-supplemental ${props.project.properties.difficulty}`
                    : ''
                }`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: props.project && props.project.properties.instructions,
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

JideCodeOutput.propTypes = {
  jideUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  activePane: PropTypes.string,
  hasError: PropTypes.bool,
  tab: PropTypes.shape({}).isRequired,
  project: PropTypes.shape({}),
  setPane: PropTypes.func.isRequired,
  isPlayground: PropTypes.bool,
  newHorizons: PropTypes.bool,
};
export default JideCodeOutput;
