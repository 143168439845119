import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './index.module.css';

export interface ButtonProps {
  /**
   * Enables additional styling.
   */
  className?: string;
  /**
   * Specifies if this is a normal button or a form submit button.
   */
  type?: 'button' | 'submit';
  /**
   * Specifies whether or not the button is disabled.
   */
  disabled?: boolean;
  /**
   * Child elements that this component can wrap, usually containing the button text.
   */
  children?: React.ReactNode;
  /**
   * Specifies a click handler function for the button.
   */
  onClick?: () => void;
  /**
   * Specifies whether or not secondary styles are applied to the button.
   */
  secondary?: boolean;
  /**
   * Specifies whether or not tertiary styles are applied to the button.
   */
  tertiary?: boolean;
  /**
   * Specifies whether or not the button is active.
   */
  active?: boolean;
  /**
   * Specifies whether or not the button is small.
   */
  small?: boolean;
  /**
   * Specifies whether or not the button wraps a link.
   */
  link?: boolean;
  /**
   * Allows the button to display an icon next to its text.
   */
  icon?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  className,
  type = 'button',
  disabled,
  children,
  onClick,
  secondary,
  tertiary,
  active,
  small,
  link,
  icon,
}) => (
  <button
    className={classNames(
      {
        'ui-button': true,
        [styles.button]: true,
        [styles.small]: small,
        [styles.link]: link,
        [styles.secondary]: secondary,
        [styles.tertiary]: tertiary,
        [styles.active]: active,
        [styles.withIcon]: icon,
      },
      className,
    )}
    disabled={disabled}
    type={type}
    onClick={onClick}
  >
    {children}
    {icon && <div className={styles.icon}>{icon}</div>}
  </button>
);

export default Button;
