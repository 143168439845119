import React, { FC, useEffect } from 'react';
import * as R from 'ramda';
import { Route, Switch } from 'react-router-dom';
import { SignupSessionProps } from 'app/signup_session/types';
import { useUserContext } from 'modules/UserContext';
import findLastPath from 'utils/findLastPath';
import {
  COURSE_FORMAT,
  ONBOARDING_FLOW_STEPS,
  TWO_WEEK_TRIAL,
} from 'constants/signup_sessions';
import { pickAllOnboardingStatuses } from '../lib';
import useSignupContext from '../hooks/useSignupContext';
import useNavRouter from '../hooks/useNavRouter';
import OnboardingLayout from './components/OnboardingLayout';
import navStates from '../navigation/states';
import * as Page from './pages';
import { ROUTE_EVENT } from '../navigation/types';

const Onboarding: FC<SignupSessionProps> = props => {
  const { signupData, flags } = useSignupContext();
  const { user } = useUserContext();
  const { getNextPage, hasNextPage, routerType } = useNavRouter();

  const activeStudent = signupData.students?.[0];

  const onboardingIsComplete =
    // if on demand bundle skip onboarding and go directly to confirmation page
    activeStudent?.bundle?.bundleName === COURSE_FORMAT.onDemand ||
    flags.isBootcampSignup ||
    pickAllOnboardingStatuses(signupData).every(
      status => status === 'ready_to_onboard',
    );

  useEffect(() => {
    const isOnApprovedRoute = R.includes(
      findLastPath(props.location.pathname),
      R.pluck('path', ONBOARDING_FLOW_STEPS),
    );
    if (
      hasNextPage(navStates.onboarding.default, ROUTE_EVENT.LOAD, {
        isNotApprovedRoute: !isOnApprovedRoute,
        signupData,
        user,
        onboardingIsComplete,
        search: !signupData.campaign ? `?campaign=${TWO_WEEK_TRIAL}` : undefined,
        locationPath: props.location.pathname,
      })
    ) {
      props.history.replace(
        getNextPage(navStates.onboarding.default, ROUTE_EVENT.LOAD, {
          isNotApprovedRoute: !isOnApprovedRoute,
          signupData,
          user,
          onboardingIsComplete,
          search: !signupData.campaign ? `?campaign=${TWO_WEEK_TRIAL}` : undefined,
        }),
      );
    }
  }, [
    props.history,
    props.location.pathname,
    props.location.search,
    props.match.url,
    signupData._id,
    signupData.submittedPayment,
    signupData.accountCreated,
    signupData.invitationCode,
    user?.userType,
    signupData,
    user,
    onboardingIsComplete,
    hasNextPage,
    getNextPage,
    routerType,
  ]);

  return (
    <OnboardingLayout
      activeStep={findLastPath(props.location.pathname) as string}
      flags={flags}
    >
      <Switch>
        <Route
          path={`${props.match.path}/create-account`}
          render={() => <Page.CreateAccount {...props} />}
        />
        <Route
          path={`${props.match.path}/scheduling-preferences`}
          render={() => <Page.SchedulingPreferences {...props} />}
        />
        <Route
          path={`${props.match.path}/course-placement`}
          render={() => <Page.CoursePlacement {...props} />}
        />
        <Route
          path={`${props.match.path}/student-info`}
          render={() => <Page.StudentInfo {...props} />}
        />
        <Route
          path={`${props.match.path}/confirmation`}
          render={() => <Page.Confirmation {...props} />}
        />
      </Switch>
    </OnboardingLayout>
  );
};

export default Onboarding;
