import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAtom,
  faBars,
  faBell,
  faBellSlash,
  faBook,
  faCalculator,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChess,
  faChild,
  faClock,
  faCode,
  faCog,
  faCompress,
  faCopy,
  faExclamation,
  faExpand,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFlask,
  faGavel,
  faGlobeAmericas,
  faHighlighter,
  faHistory,
  faLink,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMobileAlt,
  faNetworkWired,
  faPaintBrush,
  faPenSquare,
  faPhone,
  faPlay,
  faQuestion,
  faSave,
  faSearch,
  faServer,
  faShare,
  faShareSquare,
  faSlidersH,
  faSpinner,
  faSquareRootAlt,
  faStar,
  faStop,
  faSync,
  faTerminal,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrophy,
  faUser,
  faUserFriends,
  faUsers,
  faVideo,
  faVideoSlash,
  faWifi,
  faCampground,
  faGripVertical,
  faCircle,
  faCertificate,
  faSquare,
  faUserLock,
  faAsterisk,
  faDesktop,
  faComment,
  faExclamationTriangle,
  faFlag,
  faEllipsisH,
  faEnvelope,
  faUserCircle,
  faUserGraduate,
  faChalkboardTeacher,
  faHome,
  faEye,
  faPen,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faPowerOff,
  faSmileWink,
  faWindowRestore,
  faAngleRight,
  faRedoAlt,
  faHourglassHalf,
  faArrowCircleLeft,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';

import {
  faComment as farComment,
  faCopy as farCopy,
  faCircle as farCircle,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAndroid,
  faApple,
  faChrome,
  faFirefox,
  faHtml5,
  faJava,
  faLinux,
  faPython,
  faWindows,
  faYoutube,
  faSlack,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faAndroid,
  faApple,
  faAtom,
  faBars,
  faBell,
  faBellSlash,
  faBook,
  faCalculator,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChess,
  faChild,
  faChrome,
  faClock,
  faCode,
  faCog,
  faCompress,
  faCopy,
  faExclamation,
  faExpand,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFirefox,
  faFlask,
  faGavel,
  faGlobeAmericas,
  faHighlighter,
  faHistory,
  faHtml5,
  faJava,
  faLink,
  faLinux,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMobileAlt,
  faNetworkWired,
  faPaintBrush,
  faPenSquare,
  faPhone,
  faPlay,
  faPython,
  faQuestion,
  faSave,
  faSearch,
  faServer,
  faShare,
  faShareSquare,
  faSlidersH,
  faSpinner,
  faSquareRootAlt,
  faStar,
  faStop,
  faSync,
  faTerminal,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrophy,
  faUser,
  faUserFriends,
  faUsers,
  faVideo,
  faVideoSlash,
  faWifi,
  faWindows,
  faCampground,
  faGripVertical,
  faCircle,
  faCertificate,
  faSquare,
  faUserLock,
  faAsterisk,
  faDesktop,
  faComment,
  farComment,
  farCopy,
  farCircle,
  faExclamationTriangle,
  faFlag,
  faEllipsisH,
  faEnvelope,
  faUserCircle,
  faUserGraduate,
  faChalkboardTeacher,
  faHome,
  faEye,
  faPen,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faPowerOff,
  faSmileWink,
  faWindowRestore,
  faAngleRight,
  faRedoAlt,
  faHourglassHalf,
  faArrowCircleLeft,
  faYoutube,
  faSlack,
  faChevronDown,
  faChevronUp,
  faChartLine,
);
