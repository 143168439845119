import React from 'react';
import classNames from 'classnames';

interface HeaderStatProps {
  /**
   * The stat label.
   */
  label: string;
  /**
   * Optional CSS styling.
   */
  className?: string;
  /**
   * The stat value.
   */
  children: string;
}

const HeaderStat: React.FC<HeaderStatProps> = ({ label, className, children }) => (
  <div
    className={classNames(
      'box-border border-0 border-solid border-j-dark-100 font-graphik',
      className,
    )}
  >
    <div className="text-xs leading-5 text-j-dark-400">{label}</div>
    <div className="mt-1 text-base leading-8 text-j-dark-600 font-medium">
      {children}
    </div>
  </div>
);

export default HeaderStat;
