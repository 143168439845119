import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parser from 'ua-parser-js';
import { SI_LOOKUP_TABLE } from 'constants/special_instructions';

class JideSpecialInstructions extends Component {
  state = {
    currentStep: null,
    steps: [],
  };
  osType = null;

  componentDidMount() {
    this.getOsType();
    this.populate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.specialInstructions !== this.props.specialInstructions) {
      this.populate();
    }
  }

  getOsType = () => {
    const uaString = window.navigator.userAgent;
    const ua = parser(uaString);
    if (!ua) return;
    const os = ua.os && ua.os.name;
    this.osType = os.includes('Mac OS')
      ? 'mac'
      : os.includes('Windows')
      ? 'windows'
      : uaString.includes('Chromebook')
      ? 'chromebook'
      : 'linux';
  };

  populate = () => {
    try {
      let si = SI_LOOKUP_TABLE[this.props.specialInstructions];
      if (this.osType && this.osType in si) {
        si = si[this.osType];
      } else {
        si = si.default;
      }
      this.setState({ steps: si, currentStep: 0 });
    } catch (err) {
      console.error(err);
      this.setState({ steps: [], currentStep: null });
    }
  };

  nextStepHandler = () => {
    this.setState(prevState => ({
      currentStep: Math.min(prevState.currentStep + 1, prevState.steps.length - 1),
    }));
  };
  specificStepHandler = stepNumber => {
    if (stepNumber < 0 || stepNumber >= this.state.steps.length) return;
    this.setState({ currentStep: stepNumber });
  };
  prevStepHandler = () => {
    this.setState(prevState => ({
      currentStep: Math.max(prevState.currentStep - 1, 0),
    }));
  };

  render() {
    const step =
      this.state.currentStep != null &&
      this.state.steps.length &&
      this.state.steps[this.state.currentStep];

    const navDots = this.state.steps.map((p, i) => {
      const eye = i;
      const dot = (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`jce-si-nav-dot-${i}`}
          className={`jce-si-nav-dot${
            i === this.state.currentStep ? ' jce-si-active' : ''
          }`}
          onClick={() => this.specificStepHandler(eye)}
        >
          <FontAwesomeIcon icon={['fas', 'circle']} />
        </div>
      );
      return dot;
    });

    return (
      <div className="jce-si">
        <div className="jce-si-content">
          <div className="jce-si-content-wrapper">
            <JideSpecialInstructionsTitle step={step} />
            <JideSpecialInstructionsVideo step={step} />
            <JideSpecialInstructionsImage step={step} />
            <JideSpecialInstructionsLink step={step} />
            <JideSpecialInstructionsText step={step} />
          </div>
          <div className="jce-si-nav">
            <div className="jce-si-nav-arrow" onClick={this.prevStepHandler}>
              <FontAwesomeIcon icon={['fas', 'chevron-left']} />
            </div>
            {navDots}
            <div className="jce-si-nav-arrow" onClick={this.nextStepHandler}>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const JideSpecialInstructionsTitle = ({ step }) => {
  if (!step || !step.title) return null;
  return <div className="jce-si-title">{step.title}</div>;
};
const JideSpecialInstructionsVideo = ({ step }) => {
  if (!step || !step.video) return null;
  return (
    <div className="jce-si-video">
      <video key={step.video} autoPlay loop muted>
        <source src={step.video} />
      </video>
    </div>
  );
};
const JideSpecialInstructionsImage = ({ step }) => {
  if (!step || !step.image) return null;
  return (
    <div className="jce-si-image">
      <img src={step.image} alt="" />
    </div>
  );
};
const JideSpecialInstructionsText = ({ step }) => {
  if (!step || !step.text || !step.text.length) return null;
  return <div className="jce-si-text">{step.text}</div>;
};
const JideSpecialInstructionsLink = ({ step }) => {
  const [icon, setIcon] = useState('copy');

  if (!step || !step.link || !step.link.length) return null;
  const { link } = step;

  return (
    <div className="jce-si-link">
      <a href={link} rel="noopener noreferrer" target="_blank">
        <div className="jce-si-link-left">{link}</div>
      </a>
      <CopyToClipboard text={link} options={{ format: 'text/plain' }}>
        <div
          className="jce-si-link-right"
          onClick={() => {
            setIcon('check');
            setTimeout(() => {
              setIcon('copy');
            }, 1000);
          }}
        >
          <div className="jce-si-link-right-left">COPY</div>
          <div className="jce-si-link-right-right">
            <FontAwesomeIcon icon={['fas', icon]} />
          </div>
        </div>
      </CopyToClipboard>
    </div>
  );
};

JideSpecialInstructions.propTypes = {
  specialInstructions: PropTypes.string,
};
export default JideSpecialInstructions;
