import PropTypes from 'prop-types';

const ConditionalWrap = ({ condition, wrap, elseWrap, children }) =>
  condition ? wrap(children) : elseWrap ? elseWrap(children) : children;

ConditionalWrap.defaultProps = {
  elseWrap: null,
};

ConditionalWrap.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrap: PropTypes.func.isRequired,
  elseWrap: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default ConditionalWrap;
