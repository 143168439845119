import React, { FC } from 'react';
import styled from 'styled-components/macro';

const NavIconContainer = styled.div.attrs((props: any) => ({
  className: props.$active ? 'bg-juni-pink-200' : 'bg-juni-purple-200',
}))<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  border-radius: 6px;
  margin-right: ${props => props.menuItem && '12px'};
  z-index: 1;
  svg path {
    fill: currentColor;
  }
`;

interface NavIconProps {
  active?: boolean;
  icon: React.ReactNode;
  menuItem?: boolean;
}

const NavIcon: FC<NavIconProps> = ({ active, icon, menuItem }) => (
  <NavIconContainer $active={menuItem ? false : active} menuItem={menuItem}>
    {icon}
  </NavIconContainer>
);

export default NavIcon;
