import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GetHelpButton } from '..';

const JideGetHelpButton = () => (
  <GetHelpButton>
    <span className="widget__title">Help?</span>
    <div className="widget-icon-container">
      <FontAwesomeIcon icon={['fas', 'comment']} />
    </div>
  </GetHelpButton>
);

export default JideGetHelpButton;
