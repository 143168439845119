import React from 'react';
import PropTypes from 'prop-types';

import './action_button.css';

const ActionButton = ({
  className,
  children,
  icon,
  iconLeft,
  disabled,
  onClick,
  value,
  style,
  title,
}) => (
  <button
    className={`action-button ${iconLeft ? 'icon-left' : ''} ${
      icon ? 'with-icon' : ''
    } ${className || ''}`}
    onClick={onClick}
    disabled={disabled}
    style={style}
    value={value}
    title={title}
  >
    {iconLeft && icon}
    {children}
    {!iconLeft && icon}
  </button>
);

ActionButton.defaultProps = {
  className: null,
  children: null,
  icon: null,
  iconLeft: false,
  onClick: null,
  disabled: false,
  style: null,
  title: '',
  value: null,
};

ActionButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  iconLeft: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ActionButton;
