import React, { useEffect, useRef } from 'react';

import 'emoji-picker-element';
import Picker from 'emoji-picker-element/picker';
import { EmojiClickEvent } from 'emoji-picker-element/shared';

import { blueGray, juniPurple } from 'theme/old-colors';

const style = {
  '--border-color': blueGray[100],
  '--button-active-background': blueGray[50],
  '--button-hover-background': blueGray[100],
  '--indicator-color': juniPurple[500],
  '--input-border-color': blueGray[300],
  '--input-font-color': blueGray[900],
  '--input-padding': '0.25rem 0.5rem',
  '--input-placeholder-color': blueGray[300],
  '--outline-color': blueGray[200],
};

interface EmojiPickerProps {
  onEmojiClick: (e: EmojiClickEvent) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onEmojiClick }) => {
  const ref = useRef<Picker>(null);

  useEffect(() => {
    const { current } = ref;
    if (!current) {
      return;
    }
    current.addEventListener('emoji-click', onEmojiClick);
    return () => {
      current.removeEventListener('emoji-click', onEmojiClick);
    };
  });

  // @ts-expect-error since this is a custom element.
  return <emoji-picker ref={ref} style={style} />;
};

export default EmojiPicker;
