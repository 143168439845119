import React, { FC } from 'react';

const Line: FC = () => (
  <div
    className={`
    w-full 
    my-6
    border-solid 
    border-t 
    border-l-0 border-r-0 border-b-0
    border-blue-gray-100`}
  />
);

export default Line;
