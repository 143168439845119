import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import juniAxios from 'services/axios';
import moment from 'moment-timezone';
import queryString from 'query-string';
import styled from 'styled-components';

import { ACUITY_TYPE_TO_TEXT } from 'constants/acuity.js';

import {
  ModuleSectionProgress,
  Student,
  StudentSession,
  TeacherSession,
} from 'models';

import { deleteTeacherSessionLog } from 'services/learner/teacher_sessions';
import { formatSessionDatetime } from 'utils/dates';

import { SUBJECT_TYPE } from 'constants/subjects';
import { NewCard } from 'core-components';
import {
  getInstructionsAndPlaceholder,
  SESSION_STATE_DESCRIPTION,
} from './constants';

import {
  SessionFeedback,
  NoteText,
  NoteHeader,
  TextEditor,
  CourseProgress,
  NotesFromHQ,
} from './subcomponents';

interface SessionNoteProps extends RouteComponentProps {
  getTeacherSessions: any;
  teacherSession?: TeacherSession;
  session: StudentSession;
  subjectName: SUBJECT_TYPE;
  teacherMode: boolean;
  moduleSectionInfo: any;
  moduleSectionProgresses: ModuleSectionProgress[];
  instructorNameLookup: Record<string, string>;
  idLookup: any;
  userId: string;
  userIsAdmin: boolean;
  student: Student;
}

const StatusBar = styled.div<any>`
  padding: 16px 0px;
  > div {
    border-left: ${props =>
      props.teacherMode && props.isIncomplete && '4px solid #ea606f'};
  }
`;

const SessionNote: FC<SessionNoteProps> = ({
  getTeacherSessions,
  teacherSession,
  session,
  subjectName,
  teacherMode,
  moduleSectionInfo,
  moduleSectionProgresses,
  instructorNameLookup,
  idLookup,
  userId,
  userIsAdmin,
  student,
  location,
}) => {
  const saveNotes = async (notes: string, sessionWasMissed: boolean) => {
    const { search } = location;
    const params = queryString.parse(search);
    const studentId = params.id;
    try {
      await juniAxios.post(`/api_teacher/teacher_session_notes`, {
        teacherId: userId,
        studentId,
        acuityId: teacherSession?.acuityId,
        notes,
        wasMissed: sessionWasMissed,
      });
      await getTeacherSessions();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSessionNote = async () => {
    if (
      window.confirm(
        'Are you sure you wish to delete this missed log? This action cannot be undone.',
      )
    ) {
      try {
        await deleteTeacherSessionLog(teacherSession?.acuityId);
        await getTeacherSessions();
      } catch (error) {
        console.log(`Failed to delete missed log. Error: ${error}`);
      }
    }
  };

  const sessionType = ACUITY_TYPE_TO_TEXT[session.appointmentTypeID] || '';

  const sessionDatetime = formatSessionDatetime(
    session.datetime,
    !teacherMode ? session.timezone : '',
  );
  const instructorName = teacherSession
    ? instructorNameLookup[teacherSession.teacherId]
    : '';
  const sessionLabel = `${sessionDatetime}${
    instructorName ? ` with ${instructorName}` : ''
  }`;

  const isUpcoming = moment(session.datetime).isAfter(moment());
  const isIncomplete =
    !isUpcoming &&
    (!teacherSession || (!teacherSession.completed && !teacherSession.wasMissed));
  const numDaysSinceLogged = moment().diff(
    moment(teacherSession?.end || ''),
    'days',
  );

  const { instructions, placeholder } = getInstructionsAndPlaceholder(subjectName);

  return (
    <StatusBar teacherMode={teacherMode} isIncomplete={isIncomplete}>
      <NewCard>
        <NoteHeader
          deleteSessionNote={deleteSessionNote}
          isUpcoming={isUpcoming}
          sessionLabel={sessionLabel}
          sessionType={sessionType}
          teacherMode={teacherMode}
          teacherSession={teacherSession}
          userIsAdmin={userIsAdmin}
        />
        <NewCard.Divider />
        <div className="flex flex-col gap-12 pt-6">
          {/* Session Notes */}
          <NoteText
            isUpcoming={isUpcoming}
            teacherMode={teacherMode}
            teacherSession={teacherSession}
          />
          {/* Session Notes Editor */}
          {teacherMode && teacherSession?.end && !teacherSession.completed && (
            <TextEditor
              checkboxLabel={SESSION_STATE_DESCRIPTION.missed}
              instructions={instructions}
              placeholder={placeholder}
              submit={saveNotes}
            />
          )}
          {/* Notes From HQ  & Progress */}
          {teacherSession?.end && (
            <>
              <CourseProgress
                teacherSession={teacherSession}
                idLookup={idLookup}
                sessionSubject={subjectName}
                student={student}
                moduleSectionProgresses={moduleSectionProgresses}
                moduleSectionInfo={moduleSectionInfo}
                teacherMode={teacherMode}
              />
              <NotesFromHQ teacherSession={teacherSession} />
            </>
          )}
          {/* Session Feedback */}
          {!teacherMode && teacherSession && (
            <SessionFeedback
              teacherSession={teacherSession}
              numDaysSinceLogged={numDaysSinceLogged}
              instructorName={instructorName}
            />
          )}
        </div>
      </NewCard>
    </StatusBar>
  );
};

export default SessionNote;
