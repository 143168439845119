import React, { FC } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { TailwindWidth } from 'theme/types/';

interface JuniLogoProps {
  /**
   * Specifies the fill color of the logo
   */
  color?: 'light' | 'dark';
  /**
   * Specifies the width of the logo based on widths listed in widthStyles
   */
  size?: 'sm' | 'md' | 'lg';
  /**
   * Specifies the width of the logo based on widths from the Tailwind scale
   */
  width?: TailwindWidth;
  /**
   * Enables additional styling
   */
  className?: string;
}

const LogoWrapper = styled.div.attrs(({ className }) => ({
  className,
}))``;

const colorStyles = {
  light: 'text-juni-light-100',
  dark: 'text-juni-dark-900',
};

const sizeStyles = {
  sm: 'w-9',
  md: 'w-18',
  lg: 'w-32',
};

const JuniLogo: FC<JuniLogoProps> = ({
  color = 'dark',
  size = 'md',
  width,
  className,
}) => (
  <LogoWrapper
    className={classNames(
      {
        [sizeStyles[size]]: !width,
        [colorStyles[color]]: true,
        [`w-${width}`]: !!width,
      },
      'fill-current',
      className,
    )}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 379.74">
      <path d="M146.96 39.22c-18.17 0-32.9 14.73-32.9 32.9s14.73 32.9 32.9 32.9 32.9-14.73 32.9-32.9-14.73-32.9-32.9-32.9zm177.2 196.28c0 33.29-22.99 43.6-41.62 43.6-17.84 0-35.28-13.48-35.28-42.41V137.6h-51.92v101.86c-.4 55.49 24.97 87.99 73.33 87.99 18.23 0 47.56-10.7 55.49-34.88v30.12h51.92V137.6h-51.92v97.9zm181.58-102.66c-18.23 0-47.56 11.1-55.49 36.07V137.6h-51.92v185.09h51.92v-97.1c0-33.69 22.99-43.6 41.62-43.6 17.83 0 35.27 13.08 35.27 42.01v98.69h51.92V221.23c.4-55.49-24.96-88.39-73.32-88.39zm95.73 189.85h51.92V137.6h-51.92v185.09zm-542.06 4.76h34.02c51.29 0 79.53-30.5 79.53-85.87V137.6h-51.97v101.28c0 25.64-13.08 41.3-36.83 41.3H59.41v47.27zm568.02-222.44c18.17 0 32.9-14.73 32.9-32.9s-14.73-32.9-32.9-32.9c-18.17 0-32.9 14.73-32.9 32.9s14.73 32.9 32.9 32.9z" />
    </svg>
  </LogoWrapper>
);

export default JuniLogo;
