import { FC } from 'react';
import { NewCard, NewButton } from 'core-components';

const CodeOfConductSection: FC = () => (
  <div className="w-full lg:w-2/5 gap-6">
    <NewCard padding="0">
      <div className="text-xl font-bold px-6 py-4"> Code of Conduct </div>
      <NewCard.Divider />
      <div className="flex flex-col gap-6 p-6">
        <div>
          Juni Learning aims to provide excellent educational opportunities for our
          students and develop their interest and skill level in technical and
          creative subjects outside of school.
        </div>
        <div>
          We encourage and foster a safe and respectful environment that values
          critical thinking, integrity, and inclusion.These aims are reflected in
          Juni's{' '}
          <a
            className="text-j-dark-600 hover:underline"
            href="https://junilearning.com/docs/juni_learning_code_of_conduct.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Code of Conduct
          </a>{' '}
          for our students, parents, and instructors.
        </div>
        <NewButton
          onClick={() => {
            window.open(
              'https://junilearning.com/docs/juni_learning_code_of_conduct.pdf',
              '_blank',
            );
          }}
          className="border-0"
        >
          Read Code of Conduct
        </NewButton>
      </div>
    </NewCard>
  </div>
);

export default CodeOfConductSection;
