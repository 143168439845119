import { createContext } from 'react';

const CheckoutContext = createContext<{
  discountIsValid: boolean;
  setDiscountIsValid?: (valid: boolean) => void;
  referralCodeValid: boolean;
  setReferralCodeValid?: (valid: boolean) => void;
}>({
  discountIsValid: true,
  referralCodeValid: false,
});

export default CheckoutContext;
