import React, { FC } from 'react';
import { Icon, Stepper } from 'core-components';
import NewModalWindow, {
  ModalButtonsFooter,
} from 'core-components/NewModalWindow/NewModalWindow';
import { UPDATE_FLOWS } from '../../LearnerAccount';
import { FormState } from '../types';

const LearnerPortalModalStepper = ({ formState }: { formState: FormState }) => {
  const { updateFlow, modal } = formState;
  const upgradeFlowSteps = [
    { path: 'course_select', title: 'COURSE', icon: <Icon.Books width="24" /> },
    {
      path: 'schedule_select',
      title: 'SCHEDULE',
      icon: <Icon.CalendarDates width="24" />,
    },
    {
      path: 'payment_check',
      title: 'SUMMARY',
      icon: <Icon.Receipt width="24" />,
    },
  ];
  if ([UPDATE_FLOWS.NEW_STUDENT, UPDATE_FLOWS.REENGAGE_CHURN].includes(updateFlow)) {
    upgradeFlowSteps.unshift({
      path: 'student_create',
      title: 'DETAILS',
      icon: <Icon.UserSquare width="24" />,
    });
  }
  const idx = upgradeFlowSteps.findIndex(step => step.path === modal);
  return idx !== -1 ? <Stepper steps={upgradeFlowSteps} currentStep={idx} /> : null;
};

interface Props {
  formState: FormState;
  updateFormState: (newValue: any, field: string) => void;
  title: string;
  children?: React.ReactNode;
  renderPrimaryButton: React.ReactNode;
  renderSecondaryButton?: React.ReactNode;
}

const LearnerPortalModal: FC<Props> = ({
  updateFormState,
  formState,
  title,
  children,
  renderPrimaryButton,
  renderSecondaryButton,
}) => {
  const { updateFlow } = formState;
  const renderSecondaryHeader = [
    UPDATE_FLOWS.NEW_CLASS,
    UPDATE_FLOWS.NEW_STUDENT,
    UPDATE_FLOWS.REENGAGE_CHURN,
  ].includes(updateFlow);
  return (
    <NewModalWindow
      isOpen
      closeModal={() => {
        updateFormState('', 'modal');
      }}
      title={title}
      renderFooter={() => (
        <ModalButtonsFooter
          primary={renderPrimaryButton}
          secondary={renderSecondaryButton || <div></div>}
        />
      )}
      secondaryHeader={() =>
        renderSecondaryHeader && (
          <div className="py-4">
            <LearnerPortalModalStepper formState={formState} />
          </div>
        )
      }
    >
      {children}
    </NewModalWindow>
  );
};

export default LearnerPortalModal;
