import { useState, useEffect } from 'react';
import _ from 'lodash';

const useDimensions = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let isMounted = true;
    const setDimensions = _.throttle(() => {
      if (isMounted) {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
      }
    }, 200);

    window.addEventListener('resize', setDimensions);
    return () => {
      isMounted = false;
      window.removeEventListener('resize', setDimensions);
    };
  }, []);

  return { height, width };
};

export default useDimensions;
