import React, { ChangeEvent, useCallback, useRef } from 'react';
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
  IInputProps,
  IMeta,
  MethodValue,
  StatusValue,
} from 'react-dropzone-uploader';
import Compressor from 'compressorjs';

import { blueGray } from 'theme/old-colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useRequestUploadUrlMutation, UploadDestination } from 'generated/graphql';
import { logError } from 'utils/errors';
import Button from '../../../core-components/Button';

const Layout: React.FC<ILayoutProps> = ({ input, dropzoneProps }) => {
  const { ref, className, style } = dropzoneProps;
  return (
    <div ref={ref} className={className} style={style}>
      {input}
    </div>
  );
};

const PaperclipButton: React.FC<IInputProps> = ({
  accept,
  onFiles,
  getFilesFromEvent,
  disabled,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (fileInputRef && fileInputRef.current && fileInputRef.current.click) {
      fileInputRef.current.click();
    }
  };
  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const files = await getFilesFromEvent(e);
    await onFiles(files);
    // This is shady, but basically ripped straight from the source
    // of react-dropzone-uploader. We should probably move from using
    // react-dropzone-uploader to react-dropzone, as react-dropzone-uploader
    // has been abandoned
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    target.value = null;
  };
  return (
    <Button
      size="small"
      variant="transparent"
      title="Pick Emoji"
      onClick={handleClick}
      disabled={disabled}
    >
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        onChange={handleUpload}
      />
      <FontAwesomeIcon
        icon={faPaperclip}
        color={disabled ? blueGray[200] : blueGray[800]}
        size="lg"
      />
    </Button>
  );
};

interface DownloadMeta extends IMeta {
  downloadUrl: string;
}

interface ChatImageUploadProps {
  onChange?: (value: string, meta: IMeta, status?: StatusValue) => void;
  error?: string;
  dropzoneOverrides?: Partial<IDropzoneProps>;
  value?: string;
  disabled?: boolean;
}

const ChatImageUpload: React.FC<ChatImageUploadProps> = ({ onChange, disabled }) => {
  const [requestUploadUrl] = useRequestUploadUrlMutation();
  /**
   * Returns the file extension.
   * Uses the filename instead of type because type is browser dependent and inconsistent.
   * @param filename
   */
  const handleChangeStatus = useCallback<
    NonNullable<IDropzoneProps['onChangeStatus']>
  >(
    ({ meta, remove }, status) => {
      if (onChange) {
        onChange((meta as DownloadMeta).downloadUrl, meta, status);
      }
      if (status === 'done') {
        remove();
      }
    },
    [onChange],
  );

  const getUploadParams = useCallback<
    NonNullable<IDropzoneProps['getUploadParams']>
  >(
    async ({ file, meta }) => {
      try {
        const result = await requestUploadUrl({
          variables: {
            input: {
              destination: UploadDestination.ClubUserImages,
              filename: meta.name,
            },
          },
        });

        if (!result.data) {
          throw new Error('No data received from server');
        }
        const { uploadUrl, downloadUrl } = result.data.requestUploadUrl;
        if (!downloadUrl) {
          throw new Error('No download URL returned from server');
        }

        // Compressin.
        const compressedBlob: Blob = await new Promise((resolve, reject) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const _compressor = new Compressor(file, {
            success: resolve,
            error: reject,
            maxHeight: 2000,
            maxWidth: 2000,
          });
        });

        const compressedFile = new File([compressedBlob], meta.name, {
          type: compressedBlob.type,
        });

        return {
          body: compressedFile,
          url: uploadUrl,
          meta: { downloadUrl },
          method: 'PUT' as MethodValue,
        };
      } catch (err) {
        logError(err);
        return { url: '' };
      }
    },
    [requestUploadUrl],
  );

  return (
    <Dropzone
      disabled={disabled}
      accept=".bmp,.ico,.jpeg,.jpg,.png,.svg,.tif,.tiff,.webp"
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      LayoutComponent={Layout}
      InputComponent={PaperclipButton}
    />
  );
};

export default ChatImageUpload;
