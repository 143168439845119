import styled from 'styled-components/macro';

export const StyledConductList = styled.div`
  li span {
    position: relative;
    left: 10px;
  }
  li::marker {
    color: #adc2ce;
  }
  li {
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
`;
