import { makeSHA1 } from '../utils/crypto';

declare global {
  function ire(
    type: 'identify',
    payload: {
      customerId?: string;
      customerEmail: string;
    },
  ): void;

  function ire(
    type: 'trackConversion',
    tag: number,
    payload: {
      orderId: string;
      customerId?: string;
      customerEmail: string;
    },
    options: {
      verifySiteDefnitionMatch: boolean;
    },
  ): void;
}

export const useAffiliates = ({ customerEmail }: { customerEmail: string }) => {
  const emailHashPromise = customerEmail
    ? makeSHA1(customerEmail)
    : Promise.resolve('');

  const identifyAffiliateCustomer = async () => {
    const emailHash = await emailHashPromise;
    window.ire('identify', { customerEmail: emailHash });
  };

  const trackAffiliateConversion = async (
    tag: number,
    orderId: string,
    customerId: string,
  ) => {
    const emailHash = await emailHashPromise;
    ire(
      'trackConversion',
      tag,
      {
        orderId,
        customerId,
        customerEmail: emailHash,
      },
      {
        verifySiteDefnitionMatch: true,
      },
    );
  };

  return [identifyAffiliateCustomer, trackAffiliateConversion];
};
