import { remove, isEqual } from 'lodash';
import { uid } from 'uid';

const MsgQueue = <E extends string, T extends { id?: string }>(
  socketEmit: any,
  // tell React state whether the queue is empty
  setIsEmpty: (x: boolean) => void,
) => {
  const queue = { current: [] as ReturnType<typeof QueuedMsg>[] };
  const isOpen = { current: true };

  /** set the MsgQueue to be offline, stop trying to transmit */
  const close = () => {
    isOpen.current = false;
  };

  /** start transmitting again + resend anything still pending */
  const reopen = () => {
    isOpen.current = true;
    queue.current.forEach(msg => {
      msg.transmitIfOpen();
    });
  };

  /** remove a message from the queue */
  const eject = (payload: T) => {
    remove(queue.current, x => isEqual(payload, x.payload));
    setIsEmpty(queue.current.length === 0);
  };

  const QueuedMsg = (event: E, payloadSansKey: T) => {
    // We add a duplicationKey so that the server can discard messages it receives multiple times
    const payload = { ...payloadSansKey, duplicationKey: uid() };

    const transmitIfOpen = () => {
      if (isOpen.current) {
        socketEmit(event, payload, () => {
          eject(payload);
        });
      }
    };
    // Go ahead and try to transmit after construction
    transmitIfOpen();

    return { payload, transmitIfOpen };
  };

  const enqueue = (event: E, payload: T) => {
    queue.current.push(QueuedMsg(event, payload));
    setIsEmpty(false);
  };

  return { queue, reopen, close, enqueue };
};
export default MsgQueue;
