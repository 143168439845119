import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Sizes, Variants, Intents } from './types';
import * as styles from './styles';
import { StyledButton } from './styles';

const iconStyle = { padding: 0, width: 14, height: 14 };

export interface IconRenderProps {
  className: string;
  style: React.CSSProperties;
}

export interface ButtonProps {
  /**
   * Specifies the size of the button.
   * Default large.
   */
  size?: Sizes;
  /**
   * Specifies the variant of the button.
   * Default primary.
   */
  variant?: Variants;
  /**
   * Affects the colors of the button and its content.
   * Default is, well, default.
   * The remainder of the intents are mostly utilities for other button use cases.
   */
  intent?: Intents;
  /**
   * Specifies whether or not the button is disabled.
   * Default false.
   */
  disabled?: boolean;
  /**
   * Specifies the title attribute of the button.
   */
  title?: string;
  /**
   * Specifies if this is a normal button or a form submit button.
   * Default button.
   */
  type?: 'button' | 'submit';
  /**
   * Specifies a click handler function for the button.
   */
  onClick?: () => void;
  /**
   * Optionally set to true to stretch the button to fill the width of its container.
   * Default false.
   */
  fullWidth?: boolean;
  /**
   * For use when the button's only content is an icon.  This will square up the button.
   * Default false.
   */
  icon?: boolean;
  /**
   * Specifies a URL that this button (semantically an anchor element) should go to.
   * The destination will open in a new tab. Not intended for intra-app navigation.
   */
  href?: string;
  /**
   * Paired with href for use as a download.  HTML anchor element download attribute.
   */
  download?: string;
  /**
   * Add an icon before button text.
   * The rendered icon needs to render these props, and be able to derive text color from the CSS className (eg currentColor).
   */
  renderIconLeft?: (props: IconRenderProps) => React.ReactNode;
  /**
   * Add an icon after button text.
   * The rendered icon needs to render these props, and be able to derive text color from the CSS className (eg currentColor).
   */
  renderIconRight?: (props: IconRenderProps) => React.ReactNode;
  /**
   * Specifies the data-cy attribute (used by Cypress testing) of the button element.
   */
  ['data-cy']?: string;
  /**
   * Tailwind classes that are appended to the styled button.
   */
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  href,
  download,
  title,
  size = 'medium',
  type = 'button',
  variant = 'primary',
  intent = 'default',
  disabled = false,
  fullWidth = false,
  icon = false,
  renderIconLeft = noop,
  renderIconRight = noop,
  'data-cy': cypressTag,
  className,
}) => {
  const contentColorClassName = styles.getContentColorClasses(
    variant,
    intent,
    disabled,
  );
  const isEnabledLink = !!(href && !disabled);
  const elementSpecificProps = isEnabledLink
    ? {
        href,
        rel: 'noreferrer',
        target: '_blank',
        download,
        onClick,
      }
    : { disabled, onClick, type };
  const externalClassnames = className ? className.split(' ') : [];
  return (
    <StyledButton
      as={isEnabledLink ? 'a' : 'button'}
      data-cy={cypressTag}
      title={title}
      variant={variant}
      intent={intent}
      className={classNames(
        'ignore-juni-globals',
        'bg-clip-padding',
        'box-border',
        'transition',
        'inline-flex',
        'justify-center',
        'items-center',
        styles.bordersBaseClasses,
        styles.textBaseClasses,
        contentColorClassName,
        {
          [styles.sizeStyles[size]]: !icon,
          [styles.iconSizeStyles[size]]: icon,
          [styles.bgColors[variant][intent]]: true,
          [styles.borders[variant][intent]]: true,
          'cursor-default': disabled,
          'w-full': fullWidth,
        },
        ...externalClassnames,
      )}
      {...elementSpecificProps}
    >
      <>
        {renderIconLeft({
          className: classNames(
            { 'mr-3': size !== 'xsmall', 'mr-2': size === 'xsmall' },
            contentColorClassName,
          ),
          style: iconStyle,
        })}
        {children}
        {renderIconRight({
          className: classNames(
            { 'ml-3': size !== 'xsmall', 'ml-2': size === 'xsmall' },
            contentColorClassName,
          ),
          style: iconStyle,
        })}
      </>
    </StyledButton>
  );
};

export default Button;
