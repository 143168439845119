import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './index.module.css';

interface ChildProps {
  className?: string;
  children?: React.ReactNode;
}

export interface CardProps {
  /**
   * Enables additional styling.
   */
  className?: string;
  /**
   * Child elements that this component can wrap, which can include `Card.Header`, `Card.Footer`, and `Card.Content` elements.
   */
  children?: React.ReactNode;
  /**
   * Specifies if the card has narrow padding.
   */
  narrowPadding?: boolean;
  /**
   * Specifies if the card has no padding.
   */
  noPadding?: boolean;
  /**
   * Specifies if the card has a white background.
   */
  white?: boolean;
  /**
   * Specifies if the card has additional box shadow, but doesn't appear to change
   * anything in Storybook.
   */
  addBoxShadow?: boolean;
}

const Header: FC<ChildProps> = ({ className, children }) => (
  <div className={classNames(styles.cardHeader, className)}>{children}</div>
);

const Content: FC<ChildProps> = ({ className, children }) => (
  <div className={classNames(styles.cardContent, className)}>{children}</div>
);

const Footer: FC<ChildProps> = ({ className, children }) => (
  <div className={classNames(styles.cardFooter, className)}>{children}</div>
);

/**
 * Basic card component. Content should always be wrapped by `Card.Content` - the
 * "Vanilla Card" story shows what happens if this isn't the case.
 */
function Card({
  className,
  children,
  narrowPadding,
  noPadding,
  white,
  addBoxShadow,
}: CardProps) {
  const cardClass = classNames(
    {
      'ui-card-base': true,
      [styles.card]: true,
      [styles.addBoxShadow]: addBoxShadow,
      [styles.narrowPadding]: narrowPadding,
      [styles.noPadding]: noPadding,
      [styles.whiteCard]: white,
    },
    className,
  );
  return <div className={cardClass}>{children}</div>;
}

Card.Header = Header;
Card.Footer = Footer;
Card.Content = Content;

export default Card;
