import styled from 'styled-components/macro';
import { XIcon } from 'components/Icons';
import { Button, Message } from 'core-components';

export const OuterWrapper = styled.div.attrs({
  className: `pb-6 mb-4 flex flex-col rounded-2xl bg-white relative w-full`,
})`
  border: 2px solid #d3e7f2;
`;

export const InviteLabel = styled.label.attrs({
  className: 'font-semibold text-sm leading-9',
})`
  color: #4d6c7d;
`;

export const RowWrapper = styled.div.attrs<any>({
  className: 'flex justify-between py-0 w-full',
})``;

export const ResponsiveRowWrapper = styled(RowWrapper)`
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InputWrapper = styled.div.attrs({
  className: 'flex flex-col w-1/2 px-4',
})`
  position: relative;
  padding-bottom: 1rem;
  .ui-input {
    margin-top: 0;
  }
  .ui-field-error {
    bottom: -0.5rem;
    position: absolute;
  }
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const CloseButton = styled.button.attrs({
  className:
    'w-7 h-7 bg-transparent rounded-full p-0 absolute right-1.5 top-1.5 opacity-50 border-0',
})`
  path {
    stroke: #546e7a;
  }
  :focus,
  :hover {
    opacity: 1;
    background-color: #eceff1;
    box-shadow: none;
    path {
      stroke: black;
    }
  }
`;

export const CloseIcon = styled(XIcon).attrs({
  className: 'p-0 m-0',
})``;

export const InviteMessage = styled(Message).attrs({
  className: 'w-9/12 sm:w-5/6 m-auto mt-6 ',
})``;

export const SubmitButton = styled(Button).attrs({
  className: 'text-xs pr-8',
})`
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const AddFriendButton = styled.div.attrs<any>({
  className: 'font-bold hover:underline cursor-pointer mt-3 ml-2',
})`
  color: #00a5ff;
`;
