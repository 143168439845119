import React, { FC } from 'react';

import { StudentProject } from 'generated/graphql';
import { Link } from 'react-router-dom';
import {
  JUNIVERSE_PROJECT_TYPE_MAP,
  projectTypeToRenderInfo,
} from 'constants/project_types';
import ProjectIcon from './ProjectIcon';

interface Props {
  project: StudentProject;
}

const JuniverseProjectCard: FC<Props> = ({ project }) => {
  const projectTypeTitle =
    project.projectType && JUNIVERSE_PROJECT_TYPE_MAP[project.projectType]
      ? projectTypeToRenderInfo(JUNIVERSE_PROJECT_TYPE_MAP[project.projectType])
      : undefined;

  return (
    <Link
      to={`/juniverse/projects/${project._id}`}
      className="no-underline mt-6 block"
    >
      <div className="bg-white rounded-lg border border-solid border-j-dark-100 p-4 flex shadow-1 hover:shadow-2">
        {project.projectType && <ProjectIcon projectType={project.projectType} />}
        <div className="pl-3 flex-grow">
          <h4 className="font-normal text-sm text-j-dark-600 leading-6 m-0 line-clamp-1">
            {project?.title}
          </h4>
          {projectTypeTitle && (
            <p className="text-j-dark-400 m-0 text-xs line-clamp-1">
              {projectTypeTitle.displayName}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default JuniverseProjectCard;
