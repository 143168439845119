// NOT related to MyClubs/Guest accounts, but an older concept of clubs
export const CLUB_NAMES = [
  'Advanced Programming Club',
  'Journalism Club',
  'Film Club',
  'Yoga Club',
];

export type ClubTagOptionsType = {
  label: string;
  value: string;
  color: string;
};

// TODO: proper solution might be to have a separate tags collection in DB
// TODO: Update club colors later
/* For the components that need to use Club tags: we expose an API compatible with React-dropdown */
export const CLUB_INTERNAL_TAGS: ClubTagOptionsType[] = [
  {
    value: 'promoted',
    label: '[INTERNAL] Show first in searches',
    color: '#000',
  },
];
export const CLUB_DISPLAY_TAGS: ClubTagOptionsType[] = [
  { value: 'featured', label: 'Featured', color: '#37B9FF' }, // first entry will be default search on myclubs page
  { value: 'video_games', label: 'Video Games', color: '#d9134a' },
  { value: 'hobbies', label: 'Hobbies', color: '#3d81ff' },
  { value: 'animals', label: 'Animals', color: '#7e73f6' },
  { value: 'art', label: 'Art', color: '#c76609' },
  { value: 'food', label: 'Food', color: '#55c9b6' },
  { value: 'sports', label: 'Sports', color: '#c8c39c' },
  { value: 'coding', label: 'Coding', color: '#182712' },
  { value: 'mindfulness', label: 'Mindfulness', color: '#07084d' },
  { value: 'reading_and_books', label: 'Reading & Books', color: '#94bc1c' },
  { value: 'film_and_movies', label: 'Film & Movies', color: '#a70c7e' },
  {
    value: 'career_exploration',
    label: 'Career Exploration',
    color: '#2275c1',
  },
  { value: 'philanthropy', label: 'Philanthropy', color: '#2dab92' },
  {
    value: 'college_exploration',
    label: 'College Exploration',
    color: '#3200e0',
  },
  { value: 'stem', label: 'STEM', color: '#0d3536' },
  { value: 'language', label: 'Language', color: '#7c4db8' },
  { value: 'debate', label: 'Debate', color: '#5355a5' },
  { value: 'homework_help', label: 'Homework Help', color: '#f82557' },
  { value: 'business', label: 'Business', color: '#3863AB' },
  { value: 'general', label: 'General', color: '#F7D8C5' },
  { value: 'writing', label: 'Writing', color: '#96805F' },
];
export const ALL_TAGS: ClubTagOptionsType[] = [
  ...CLUB_DISPLAY_TAGS,
  ...CLUB_INTERNAL_TAGS,
];
export const WELCOME_CLUB_NAME = 'Welcome & Community Announcements Club';
export const MAX_CLUB_MEMBERSHIPS_GUEST = 5;
export const MAX_CLUB_MEMBERSHIPS_USER = 100;
export const MAX_CHANNELS_PER_CLUB = 20;

const HOMEWORK_HELP_CLUBS = {
  SCRATCH: '607625a4b2ef4b789729eb5f',
  PYTHON: '60b934b844c14647ee5200e2',
  PYGAME: '60b70b0d7a780d6dbc0c1487',
  JAVA: '60b70ac00d02106510cff275',
  AI: '60762651a4c3b80543b7f7f2',
  WEB_DEVELOPMENT: '60762665a4c3b80543b7f7f4',
  USACO: '6076267aa4c3b80543b7f7f6',
  MATH: '607626a522dec60c5a82e6ee',
  ENGLISH: '607626bc4c61c6069582b382',
  NOVEL_WRITING: '607626deb2ef4b789729eb83',
  INVESTING_AND_ENTREPRENEURSHIP: '61007d4fe957e5525fb30703',
};

/**
 * Specific course ID => club ID mappings
 */
const COURSE_HOMEWORK_HELP_CLUBS: { [courseId: string]: string } = {
  // USACO Bronze
  '5c5a0ab79613b5c7891154f5': HOMEWORK_HELP_CLUBS.USACO,
  // USACO Silver
  '5c5a0ab79613b5c7891154f6': HOMEWORK_HELP_CLUBS.USACO,
  // USACO Gold
  '5c5a0ab79613b5c7891154f7': HOMEWORK_HELP_CLUBS.USACO,
  // PyGame: Python Game Development
  '5f34385cdb306930b3fb1bb5': HOMEWORK_HELP_CLUBS.PYGAME,
  // AI Level 1: Foundations of Artificial Intelligence
  '5f348a80e9068d03b7101a8b': HOMEWORK_HELP_CLUBS.AI,
  // AI Level 2: Machine Learning
  '5f4d72d29439b4101f6bb79e': HOMEWORK_HELP_CLUBS.AI,
  // Novel Writing
  '5fb569eb8f020334600dd999': HOMEWORK_HELP_CLUBS.NOVEL_WRITING,
  // Money-Minded: Investing in the Stock Market
  '609199587448273172ebf302': HOMEWORK_HELP_CLUBS.INVESTING_AND_ENTREPRENEURSHIP,
  // Smart Money: Introduction to Personal Finance
  '60dceaa400048520097728f6': HOMEWORK_HELP_CLUBS.INVESTING_AND_ENTREPRENEURSHIP,
  // Be Your Own Boss: Entrepreneurship 101
  '60dceabf62748e658dec5448': HOMEWORK_HELP_CLUBS.INVESTING_AND_ENTREPRENEURSHIP,
};

/**
 * General mapping of an environment type to a homework help course.
 */
const ENV_TYPE_HOMEWORK_HELP_CLUBS: { [environmentType: string]: string } = {
  math: HOMEWORK_HELP_CLUBS.MATH,
  english_writing: HOMEWORK_HELP_CLUBS.ENGLISH,
  java: HOMEWORK_HELP_CLUBS.JAVA,
  python: HOMEWORK_HELP_CLUBS.PYTHON,
  pythonTurtle: HOMEWORK_HELP_CLUBS.PYTHON,
  web: HOMEWORK_HELP_CLUBS.WEB_DEVELOPMENT,
  js: HOMEWORK_HELP_CLUBS.WEB_DEVELOPMENT,
  scratch: HOMEWORK_HELP_CLUBS.SCRATCH,
  investing: HOMEWORK_HELP_CLUBS.INVESTING_AND_ENTREPRENEURSHIP,
};

/**
 * Gets the clubId designated as homework help for a particular course or playground environment.
 * Attempts lookup first by a specific course to club mapping, with fallback to the environment type.
 */
export const getHomeworkHelpClubId = (courseId: string, environmentType: string) =>
  COURSE_HOMEWORK_HELP_CLUBS[courseId] ||
  ENV_TYPE_HOMEWORK_HELP_CLUBS[environmentType] ||
  null;
