// keep in sync with juni-app-backend/server/constants/acuity.js and juni-lcd/acuity_constants.js

import { SUBJECT_DISPLAY_NAMES, SUBJECTS } from 'constants/subjects';

export const ACUITY_APPOINTMENT_TYPES = {
  RECURRING_SESSION: 3660432,
  JUNI_EXPLORER_UNLIMITED_SESSION: 12729102,
  COMPUTER_SCIENCE_TEAM_SESSION: 13252218,
  MATH_TEAM_SESSION: 15557499,
  COMPUTER_SCIENCE_CAMP_SESSION: 13795654,
  MATH_SESSION: 10411971,
  MATH_TRIAL_SESSION: 11384823,
  JUNI_JR_SESSION: 5221637,
  GROUP_INTENSIVE_SESSION: 6833224,
  GROUP_SESSION: 4147018,
  TRIAL_SESSION: 3660409,
  JUNI_JR_TRIAL_SESSION: 6099858,
  INTENSIVE_SESSION: 5041778,
  PHONE_SCREEN: 7221404,
  MOCK_LESSON: 7221643,
  MOCK_LESSON_HQ: 9299645,
  PRE_CLASS_CHECK_IN: 10623690,
  FIRST_CLASS_SHADOW: 10623699,
  ONBOARDING: 7755670,
  TRANSITION_SHADOW_SESSION: 15868107,
  BEGINNER_COMPUTER_SCIENCE_MOCK_LESSON: 15981463,
  ADVANCED_COMPUTER_SCIENCE_MOCK_LESSON: 15981467,
  MATHEMATICS_MOCK_LESSON: 15981471,
  CLASS_SESSION: 3782769,
  ENGLISH_SESSION: 16717457,
  WELCOME_CALL: 9201289,
  ADMISSIONS_CALL: 8491210,
  ORIENTATION_CALL: 10671452,
  ENROLLMENT_CALL: 9526940,
  CHECK_IN_CALL: 9310809,
  ENGLISH_MOCK_LESSON: 17277542,
  MATHEMATICS_MOCK_LESSON_FAST_TRACK: 16918447,
  ADVANCED_MATHEMATICS_MOCK_LESSON: 18237405,
  ENGLISH_PRE_CLASS_TRAINING: 10902153,
  CLUB_MEETING: 17785127,
  INSTRUCTOR_FELLOWS_COMPUTER_SCIENCE_MOCK_LESSON: 18017932,
  INSTRUCTOR_FELLOWS_MATHEMATICS_MOCK_LESSON: 17964250,
  INSTRUCTOR_FELLOWS_ENGLISH_MOCK_LESSON: 18095322,
  INSTRUCTOR_FELLOWS_PHONE_SCREEN: 18251586,
  INSTRUCTOR_FELLOWS_STEM_DEEP_MOCK_LESSON: 25971498,
  FELLOWS_FINAL_INTERVIEW: 25971419,
  SEMI_PRIVATE_TRAINING: 17260856,
  SPECIAL_SHADOW: 17261148,
  COMPUTER_SCIENCE_INSTRUCTOR_TRAINING: 18611571,
  COMPUTER_SCIENCE_PRE_CLASS_REHEARSAL: 18611632,
  MATH_INSTRUCTOR_TRAINING: 18611673,
  MATH_PRE_CLASS_REHEARSAL: 18611686,
  INVESTING_SESSION: 22694986,
  INVESTING_MOCK_LESSON: 22695101,
  INVESTING_PRE_CLASS_REHEARSAL: 22695111,
  INVESTING_INSTRUCTOR_TRAINING: 22695120,
  USACO_SESSION: 26528706,
  BOOTCAMP_COMPUTER_SCIENCE_SESSION: 26980627,
  SCIENCE_SESSION: 29442137,
  MARKETPLACE_BOOTCAMP_SESSION_45M: 30326250,
  MARKETPLACE_BOOTCAMP_SESSION_50M: 30325496,
  MARKETPLACE_BOOTCAMP_SESSION_60M: 30326310,
  MARKETPLACE_BOOTCAMP_SESSION_90M: 30326359,
};

// As of 2/2/22, this constant is only used to exclude class type options to filter by
// when updating a student's schedule from the CustomerDB and viewing their upcoming classes
// in the scheduling modal (StudentScheduleModal.tsx)
export const ACUITY_APPOINTMENT_TYPES_TO_EXCLUDE_FROM_SCHEDULE = {
  JUNI_JR_TRIAL_SESSION: 6099858,
  INTENSIVE_SESSION: 5041778,
  PHONE_SCREEN: 7221404,
  MOCK_LESSON: 7221643,
  MOCK_LESSON_HQ: 9299645,
  PRE_CLASS_CHECK_IN: 10623690,
  FIRST_CLASS_SHADOW: 10623699,
  ONBOARDING: 7755670,
  TRANSITION_SHADOW_SESSION: 15868107,
  BEGINNER_COMPUTER_SCIENCE_MOCK_LESSON: 15981463,
  ADVANCED_COMPUTER_SCIENCE_MOCK_LESSON: 15981467,
  ADVANCED_MATHEMATICS_MOCK_LESSON: 18237405,
  MATHEMATICS_MOCK_LESSON: 15981471,
  JUNI_EXPLORER_UNLIMITED_SESSION: 12729102,
  WELCOME_CALL: 9201289,
  ADMISSIONS_CALL: 8491210,
  ORIENTATION_CALL: 10671452,
  ENROLLMENT_CALL: 9526940,
  CHECK_IN_CALL: 9310809,
  ENGLISH_MOCK_LESSON: 17277542,
  MATHEMATICS_MOCK_LESSON_FAST_TRACK: 16918447,
  ENGLISH_PRE_CLASS_TRAINING: 10902153,
  INSTRUCTOR_FELLOWS_COMPUTER_SCIENCE_MOCK_LESSON: 18017932,
  INSTRUCTOR_FELLOWS_MATHEMATICS_MOCK_LESSON: 17964250,
  INSTRUCTOR_FELLOWS_ENGLISH_MOCK_LESSON: 18095322,
  INSTRUCTOR_FELLOWS_PHONE_SCREEN: 18251586,
  INSTRUCTOR_FELLOWS_STEM_DEEP_MOCK_LESSON: 25971498,
  FELLOWS_FINAL_INTERVIEW: 25971419,
  SEMI_PRIVATE_TRAINING: 17260856,
  SPECIAL_SHADOW: 17261148,
  COMPUTER_SCIENCE_INSTRUCTOR_TRAINING: 18611571,
  COMPUTER_SCIENCE_PRE_CLASS_REHEARSAL: 18611632,
  MATH_INSTRUCTOR_TRAINING: 18611673,
  MATH_PRE_CLASS_REHEARSAL: 18611686,
  INVESTING_MOCK_LESSON: 22695101,
  INVESTING_PRE_CLASS_REHEARSAL: 22695111,
  INVESTING_INSTRUCTOR_TRAINING: 22695120,
};

// appointment types that can be scheduled through juni app
export const ACUITY_APPOINTMENT_TYPES_SCHEDULED_JUNI_APP = {
  RECURRING_SESSION: ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION,
  MATH_SESSION: ACUITY_APPOINTMENT_TYPES.MATH_SESSION,
  JUNI_JR_SESSION: ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION,
  ENGLISH_SESSION: ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION,
  INVESTING_SESSION: ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION,
  USACO_SESSION: ACUITY_APPOINTMENT_TYPES.USACO_SESSION,
  BOOTCAMP_COMPUTER_SCIENCE_SESSION:
    ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION,
  SCIENCE_SESSION: ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION,
  MARKETPLACE_BOOTCAMP_SESSION_45M:
    ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_45M,
  MARKETPLACE_BOOTCAMP_SESSION_50M:
    ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_50M,
  MARKETPLACE_BOOTCAMP_SESSION_60M:
    ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_60M,
  MARKETPLACE_BOOTCAMP_SESSION_90M:
    ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_90M,
};

const COMPUTER_SCIENCE = SUBJECT_DISPLAY_NAMES[SUBJECTS.COMPUTER_SCIENCE];
const MATHEMATICS = SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS];
const ENGLISH = SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH];
const INVESTING = SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING];
const SCIENCE = SUBJECT_DISPLAY_NAMES[SUBJECTS.SCIENCE];

export const ACUITY_TYPE_TO_TEXT = {
  [ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION]: `${COMPUTER_SCIENCE} Session`,
  [ACUITY_APPOINTMENT_TYPES.JUNI_EXPLORER_UNLIMITED_SESSION]: `${COMPUTER_SCIENCE} Team Session`,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_TEAM_SESSION]: `${COMPUTER_SCIENCE} Team Session`,
  [ACUITY_APPOINTMENT_TYPES.MATH_TEAM_SESSION]: `${MATHEMATICS} Team Session`,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION]: `Camp Session`,
  [ACUITY_APPOINTMENT_TYPES.MATH_SESSION]: `${MATHEMATICS} Session`,
  [ACUITY_APPOINTMENT_TYPES.MATH_TRIAL_SESSION]: `${MATHEMATICS} Trial Session`,
  [ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION]: 'Juni Jr Session',
  [ACUITY_APPOINTMENT_TYPES.GROUP_SESSION]: 'Semi-Private Session',
  [ACUITY_APPOINTMENT_TYPES.TRIAL_SESSION]: 'Trial Session',
  [ACUITY_APPOINTMENT_TYPES.JUNI_JR_TRIAL_SESSION]: 'Juni Jr Trial Session',
  [ACUITY_APPOINTMENT_TYPES.INTENSIVE_SESSION]: 'Intensive Session',
  [ACUITY_APPOINTMENT_TYPES.GROUP_INTENSIVE_SESSION]: 'Group Intensive Session',
  [ACUITY_APPOINTMENT_TYPES.CLASS_SESSION]: 'Class Session',
  [ACUITY_APPOINTMENT_TYPES.PHONE_SCREEN]: 'Phone Screen',
  [ACUITY_APPOINTMENT_TYPES.MOCK_LESSON]: 'Mock Lesson',
  [ACUITY_APPOINTMENT_TYPES.MOCK_LESSON_HQ]: 'Mock Lesson',
  [ACUITY_APPOINTMENT_TYPES.PRE_CLASS_CHECK_IN]: 'Pre-Class Check-in',
  [ACUITY_APPOINTMENT_TYPES.FIRST_CLASS_SHADOW]: 'First Class Shadow',
  [ACUITY_APPOINTMENT_TYPES.ONBOARDING]: 'Onboarding Session',
  [ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION]:
    'Student Transition Shadow Session',
  [ACUITY_APPOINTMENT_TYPES.BEGINNER_COMPUTER_SCIENCE_MOCK_LESSON]: `Beginner ${COMPUTER_SCIENCE} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.ADVANCED_COMPUTER_SCIENCE_MOCK_LESSON]: `Advanced ${COMPUTER_SCIENCE} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.MATHEMATICS_MOCK_LESSON]: `${MATHEMATICS} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION]: `${ENGLISH} Session`,
  [ACUITY_APPOINTMENT_TYPES.WELCOME_CALL]: 'Welcome Call',
  [ACUITY_APPOINTMENT_TYPES.ADMISSIONS_CALL]: 'Admissions Call',
  [ACUITY_APPOINTMENT_TYPES.ORIENTATION_CALL]: 'Orientation Call',
  [ACUITY_APPOINTMENT_TYPES.ENROLLMENT_CALL]: 'Enrollment Call',
  [ACUITY_APPOINTMENT_TYPES.CHECK_IN_CALL]: 'Check-in Call',
  [ACUITY_APPOINTMENT_TYPES.ENGLISH_MOCK_LESSON]: `${ENGLISH} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.MATHEMATICS_MOCK_LESSON_FAST_TRACK]: `${MATHEMATICS} Mock Lesson (Fast Track)`,
  [ACUITY_APPOINTMENT_TYPES.ADVANCED_MATHEMATICS_MOCK_LESSON]: `Advanced ${MATHEMATICS} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.ENGLISH_PRE_CLASS_TRAINING]: `${ENGLISH} Pre Class Training`,
  [ACUITY_APPOINTMENT_TYPES.CLUB_MEETING]: 'Club Meeting',
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_COMPUTER_SCIENCE_MOCK_LESSON]: `Instructor Fellows ${COMPUTER_SCIENCE} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_MATHEMATICS_MOCK_LESSON]: `Instructor Fellows ${MATHEMATICS} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_ENGLISH_MOCK_LESSON]: `Instructor Fellows ${ENGLISH} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_PHONE_SCREEN]:
    'Instructor Fellows Phone Screen',
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_STEM_DEEP_MOCK_LESSON]:
    'Instructor Fellows STEM Deep Mock Lesson',
  [ACUITY_APPOINTMENT_TYPES.FELLOWS_FINAL_INTERVIEW]: 'Fellows Final Interview',
  [ACUITY_APPOINTMENT_TYPES.SEMI_PRIVATE_TRAINING]: 'Semi-Private Class Training',
  [ACUITY_APPOINTMENT_TYPES.SPECIAL_SHADOW]: 'Instructor Special Shadow',
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_INSTRUCTOR_TRAINING]: `${COMPUTER_SCIENCE} Instructor Training`,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_PRE_CLASS_REHEARSAL]: `${COMPUTER_SCIENCE} Pre-Class Rehearsal`,
  [ACUITY_APPOINTMENT_TYPES.MATH_INSTRUCTOR_TRAINING]: `${MATHEMATICS} Instructor Training`,
  [ACUITY_APPOINTMENT_TYPES.MATH_PRE_CLASS_REHEARSAL]: `${MATHEMATICS} Pre-Class Rehearsal`,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION]: `${INVESTING} Session`,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_MOCK_LESSON]: `${INVESTING} Mock Lesson`,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_PRE_CLASS_REHEARSAL]: `${INVESTING} Pre-Class Rehearsal`,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_INSTRUCTOR_TRAINING]: `${INVESTING} Instructor Training`,
  [ACUITY_APPOINTMENT_TYPES.USACO_SESSION]: 'USACO Session',
  [ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION]: `Bootcamp ${COMPUTER_SCIENCE} Session`,
  [ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION]: `${SCIENCE} Session`,
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_45M]:
    '45min Bootcamp Session',
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_50M]:
    '50min Bootcamp Session',
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_60M]:
    '60min Bootcamp Session',
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_90M]:
    '90min Bootcamp Session',
};

export const ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES = [
  ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_45M,
  ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_50M,
  ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_60M,
  ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_90M,
];

export const ACUITY_APPT_TYPES_TO_IGNORE_FOR_BILLING_CHECKS = [
  ACUITY_APPOINTMENT_TYPES.WELCOME_CALL,
  ACUITY_APPOINTMENT_TYPES.ADMISSIONS_CALL,
  ACUITY_APPOINTMENT_TYPES.ORIENTATION_CALL,
  ACUITY_APPOINTMENT_TYPES.ENROLLMENT_CALL,
  ACUITY_APPOINTMENT_TYPES.PHONE_SCREEN,
  ACUITY_APPOINTMENT_TYPES.MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.ADVANCED_MATHEMATICS_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.ONBOARDING,
  ACUITY_APPOINTMENT_TYPES.CHECK_IN_CALL,
  ACUITY_APPOINTMENT_TYPES.PRE_CLASS_CHECK_IN,
  ACUITY_APPOINTMENT_TYPES.FIRST_CLASS_SHADOW,
  ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_STEM_DEEP_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.FELLOWS_FINAL_INTERVIEW,
  ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
];

export const ACUITY_COMPUTER_SCIENCE_APPT_TYPES = [
  ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION,
  ACUITY_APPOINTMENT_TYPES.USACO_SESSION,
  ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_EXPLORER_UNLIMITED_SESSION,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION,
  ACUITY_APPOINTMENT_TYPES.GROUP_SESSION,
  ACUITY_APPOINTMENT_TYPES.TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_JR_TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_COMPUTER_SCIENCE_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.ADVANCED_COMPUTER_SCIENCE_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_COMPUTER_SCIENCE_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.USACO_SESSION,
  ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION,
];

export const ACUITY_MATH_APPT_TYPES = [
  ACUITY_APPOINTMENT_TYPES.MATH_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATHEMATICS_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.ADVANCED_MATHEMATICS_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_MATHEMATICS_MOCK_LESSON,
];

export const ACUITY_ENGLISH_APPT_TYPES = [
  ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION,
  ACUITY_APPOINTMENT_TYPES.ENGLISH_MOCK_LESSON,
  ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_ENGLISH_MOCK_LESSON,
];

export const ACUITY_INVESTING_APPT_TYPES = [
  ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION,
  ACUITY_APPOINTMENT_TYPES.INVESTING_MOCK_LESSON,
];

export const ACUITY_SCIENCE_APPT_TYPES = [ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION];

export const ACUITY_CAMP_APPT_TYPES = [
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
];

export const ACUITY_CLASS_PACK_TYPES = [
  ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_SESSION,
  ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION,
  ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION,
  ACUITY_APPOINTMENT_TYPES.USACO_SESSION,
  ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION,
  ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION,
];

export const ACUITY_TYPE_DURATION = {
  [ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.JUNI_EXPLORER_UNLIMITED_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_TEAM_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.MATH_TEAM_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION]: 180,
  [ACUITY_APPOINTMENT_TYPES.MATH_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.MATH_TRIAL_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION]: 30,
  [ACUITY_APPOINTMENT_TYPES.GROUP_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.TRIAL_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.JUNI_JR_TRIAL_SESSION]: 30,
  [ACUITY_APPOINTMENT_TYPES.INTENSIVE_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.PHONE_SCREEN]: 60,
  [ACUITY_APPOINTMENT_TYPES.MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.MOCK_LESSON_HQ]: 60,
  [ACUITY_APPOINTMENT_TYPES.PRE_CLASS_CHECK_IN]: 60,
  [ACUITY_APPOINTMENT_TYPES.FIRST_CLASS_SHADOW]: 60,
  [ACUITY_APPOINTMENT_TYPES.ONBOARDING]: 60,
  [ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.BEGINNER_COMPUTER_SCIENCE_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.ADVANCED_COMPUTER_SCIENCE_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.ENGLISH_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.MATHEMATICS_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.MATHEMATICS_MOCK_LESSON_FAST_TRACK]: 75,
  [ACUITY_APPOINTMENT_TYPES.ADVANCED_MATHEMATICS_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.ENGLISH_PRE_CLASS_TRAINING]: 60,
  [ACUITY_APPOINTMENT_TYPES.CLUB_MEETING]: 90,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_COMPUTER_SCIENCE_MOCK_LESSON]: 75,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_MATHEMATICS_MOCK_LESSON]: 75,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_ENGLISH_MOCK_LESSON]: 75,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_PHONE_SCREEN]: 30,
  [ACUITY_APPOINTMENT_TYPES.INSTRUCTOR_FELLOWS_STEM_DEEP_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.FELLOWS_FINAL_INTERVIEW]: 45,
  [ACUITY_APPOINTMENT_TYPES.SEMI_PRIVATE_TRAINING]: 30,
  [ACUITY_APPOINTMENT_TYPES.SPECIAL_SHADOW]: 60,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_INSTRUCTOR_TRAINING]: 30,
  [ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_PRE_CLASS_REHEARSAL]: 30,
  [ACUITY_APPOINTMENT_TYPES.MATH_INSTRUCTOR_TRAINING]: 30,
  [ACUITY_APPOINTMENT_TYPES.MATH_PRE_CLASS_REHEARSAL]: 30,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_MOCK_LESSON]: 60,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_PRE_CLASS_REHEARSAL]: 30,
  [ACUITY_APPOINTMENT_TYPES.INVESTING_INSTRUCTOR_TRAINING]: 30,
  [ACUITY_APPOINTMENT_TYPES.USACO_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION]: 60,
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_45M]: 45,
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_50M]: 50,
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_60M]: 60,
  [ACUITY_APPOINTMENT_TYPES.MARKETPLACE_BOOTCAMP_SESSION_90M]: 90,
};

export const ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS = [
  ACUITY_APPOINTMENT_TYPES.MATH_TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_JR_TRIAL_SESSION,
];

export const APPOINTMENT_TYPE_IDS_EXPECTING_STUDENT_FROM_CLOSE = ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS.filter(
  apptId => apptId !== ACUITY_APPOINTMENT_TYPES.MATH_TRIAL_SESSION,
);

export const ACUITY_APPOINTMENT_TYPE_IDS_WITH_STUDENT_ID_IN_FORMS = [
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION,
  ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION,
  ACUITY_APPOINTMENT_TYPES.CLUB_MEETING,
  ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION,
  ACUITY_APPOINTMENT_TYPES.USACO_SESSION,
  ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION,
  ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION,
  ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
];

export const ACUITY_GROUP_SESSION_APPT_IDS = [
  ACUITY_APPOINTMENT_TYPES.GROUP_SESSION,
  ACUITY_APPOINTMENT_TYPES.GROUP_INTENSIVE_SESSION,
];

// These are strictly Team Sessions, defined as:
// - self-scheduled with valid Team Session Pass
// - students follow self-paced curriculums
// - non-private and non-semi-private
// - instructor can have 1 or more students per session
export const ACUITY_EXPLORER_APPOINTMENT_TYPE_IDS = [
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_EXPLORER_UNLIMITED_SESSION,
];

// Appointment types where sessions are structured like Team Sessions,
// where the instructor can have 1 or more students per session.
// ie. Team Sessions and Camp Sessions
export const ACUITY_TEAM_FORMAT_APPOINTMENT_TYPE_IDS = [
  ...ACUITY_EXPLORER_APPOINTMENT_TYPE_IDS,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
];

export const NO_LOGGING_APPOINTMENT_TYPE_IDS = [
  ...ACUITY_EXPLORER_APPOINTMENT_TYPE_IDS,
  ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION,
  ACUITY_APPOINTMENT_TYPES.CLUB_MEETING,
  ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
];

export const NON_PRIVATE_APPOINTMENT_TYPE_IDS = [
  ...ACUITY_EXPLORER_APPOINTMENT_TYPE_IDS,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
  ACUITY_APPOINTMENT_TYPES.GROUP_SESSION,
  ACUITY_APPOINTMENT_TYPES.GROUP_INTENSIVE_SESSION,
  ACUITY_APPOINTMENT_TYPES.CLUB_MEETING,
];

export const EXCLUDED_FROM_RESCHEDULING_REQUESTS = [
  ...ACUITY_EXPLORER_APPOINTMENT_TYPE_IDS,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
  ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION,
  ACUITY_APPOINTMENT_TYPES.CLUB_MEETING,
  ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
];

export const MAKEUP_NOT_ALLOWED_APPOINTMENT_TYPE_IDS = [
  ...NON_PRIVATE_APPOINTMENT_TYPE_IDS,
  ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION,
  ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
];

export const ACUITY_CLASS_APPOINTMENT_TYPE_IDS = [
  ACUITY_APPOINTMENT_TYPES.RECURRING_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_EXPLORER_UNLIMITED_SESSION,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_TEAM_SESSION,
  ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_SESSION,
  ACUITY_APPOINTMENT_TYPES.MATH_TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_JR_SESSION,
  ACUITY_APPOINTMENT_TYPES.GROUP_SESSION,
  ACUITY_APPOINTMENT_TYPES.TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.JUNI_JR_TRIAL_SESSION,
  ACUITY_APPOINTMENT_TYPES.INTENSIVE_SESSION,
  ACUITY_APPOINTMENT_TYPES.GROUP_INTENSIVE_SESSION,
  ACUITY_APPOINTMENT_TYPES.CLASS_SESSION,
  ACUITY_APPOINTMENT_TYPES.TRANSITION_SHADOW_SESSION,
  ACUITY_APPOINTMENT_TYPES.ENGLISH_SESSION,
  ACUITY_APPOINTMENT_TYPES.CLUB_MEETING,
  ACUITY_APPOINTMENT_TYPES.INVESTING_SESSION,
  ACUITY_APPOINTMENT_TYPES.USACO_SESSION,
  ACUITY_APPOINTMENT_TYPES.BOOTCAMP_COMPUTER_SCIENCE_SESSION,
  ACUITY_APPOINTMENT_TYPES.SCIENCE_SESSION,
];

export const MINUTES_JOINABLE_BEFORE_SESSION_START = 15;
export const CONSECUTIVE_JOINABLE_SESSION_ALERT_BUFFER_MINUTES = 6; // should match the refreshGapUI variable in server/jobs/meetingsUpdateJob.js on server
export const ACUITY_APPOINTMENT_DEFAULT_DURATION = 60;
export const ACUITY_ASSOCIATED_STUDENT_IDS_FORM_FIELD_ID = 1342265;
export const ACUITY_SHADOW_INSTRUCTOR_CALENDAR_FORM_FIELD_ID = 1488509;
export const ACUITY_CLUB_INFORMATION_FORM_ID = 1565002;

export const ACUITY_FORM_FIELDS = {
  STUDENT_IDS: 7366323,
  STUDENT_NAME: 3556248,
  CLUB_NAME: 8623171,
};
