import moment from 'moment-timezone';

export const DEFAULT_TIMEZONE: Timezone = 'America/Los_Angeles';

export const DEFAULT_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const DEFAULT_TIME_FORMAT = 'h:mma z';
export const DEFAULT_DATETIME_FORMAT = 'dddd, MMMM D, YYYY [at] h:mma z';
export const SHORT_DATE_FORMAT = 'ddd, L';
export const SHORT_DATETIME_FORMAT = 'ddd, L h:mma z';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const parseMoment = (date?: string | number) =>
  moment.tz(date, DEFAULT_TIMEZONE);

export type TimezoneDisplay = {
  displayName: string;
  value: Timezone;
};

export const US_TIMEZONES: TimezoneDisplay[] = [
  { value: 'Pacific/Honolulu', displayName: '(GMT-10:00) Hawaii Time' },
  { value: 'America/Anchorage', displayName: '(GMT-9:00) Alaska Time' },
  { value: 'America/Los_Angeles', displayName: '(GMT-8:00) Pacific Time' },
  { value: 'America/Denver', displayName: '(GMT-7:00) Mountain Time - Denver' },
  { value: 'America/Phoenix', displayName: '(GMT-7:00) Arizona Time' },
  { value: 'America/Chicago', displayName: '(GMT-6:00) Central Time' },
  { value: 'America/New_York', displayName: '(GMT-5:00) Eastern Time' },
];

export const FOREIGN_TIMEZONES: TimezoneDisplay[] = [
  { value: 'Pacific/Apia', displayName: '(GMT+14:00) Apia' },
  { value: 'Pacific/Midway', displayName: '(GMT-11:00) Midway' },
  { value: 'Pacific/Niue', displayName: '(GMT-11:00) Niue' },
  { value: 'Pacific/Pago_Pago', displayName: '(GMT-11:00) Pago Pago' },
  { value: 'Pacific/Fakaofo', displayName: '(GMT+13:00) Fakaofo' },
  { value: 'Pacific/Johnston', displayName: '(GMT-10:00) Johnston' },
  { value: 'Pacific/Rarotonga', displayName: '(GMT-10:00) Rarotonga' },
  { value: 'Pacific/Tahiti', displayName: '(GMT-10:00) Tahiti' },
  { value: 'Pacific/Marquesas', displayName: '(GMT-9:30) Marquesas' },
  { value: 'Pacific/Gambier', displayName: '(GMT-9:00) Gambier' },

  { value: 'America/Tijuana', displayName: '(GMT-8:00) Pacific Time - Tijuana' },
  { value: 'America/Vancouver', displayName: '(GMT-8:00) Pacific Time - Vancouver' },
  {
    value: 'America/Whitehorse',
    displayName: '(GMT-8:00) Pacific Time - Whitehorse',
  },
  { value: 'Pacific/Pitcairn', displayName: '(GMT-8:00) Pitcairn' },
  {
    value: 'America/Dawson_Creek',
    displayName: '(GMT-7:00) Mountain Time - Dawson Creek',
  },
  { value: 'America/Edmonton', displayName: '(GMT-7:00) Mountain Time - Edmonton' },
  {
    value: 'America/Hermosillo',
    displayName: '(GMT-7:00) Mountain Time - Hermosillo',
  },
  {
    value: 'America/Mazatlan',
    displayName: '(GMT-7:00) Mountain Time - Chihuahua, Mazatlan',
  },
  {
    value: 'America/Yellowknife',
    displayName: '(GMT-7:00) Mountain Time - Yellowknife',
  },
  { value: 'America/Belize', displayName: '(GMT-6:00) Belize' },
  { value: 'America/Costa_Rica', displayName: '(GMT-6:00) Costa Rica' },
  { value: 'America/El_Salvador', displayName: '(GMT-6:00) El Salvador' },
  { value: 'America/Guatemala', displayName: '(GMT-6:00) Guatemala' },
  { value: 'America/Managua', displayName: '(GMT-6:00) Managua' },
  {
    value: 'America/Mexico_City',
    displayName: '(GMT-6:00) Central Time - Mexico City',
  },
  { value: 'America/Regina', displayName: '(GMT-6:00) Central Time - Regina' },
  {
    value: 'America/Tegucigalpa',
    displayName: '(GMT-6:00) Central Time - Tegucigalpa (no DST)',
  },
  { value: 'America/Winnipeg', displayName: '(GMT-6:00) Central Time - Winnipeg' },
  { value: 'Pacific/Easter', displayName: '(GMT-5:00) Easter Island' },
  { value: 'Pacific/Galapagos', displayName: '(GMT-6:00) Galapagos' },
  { value: 'America/Bogota', displayName: '(GMT-5:00) Bogota' },
  { value: 'America/Cayman', displayName: '(GMT-5:00) Cayman' },
  { value: 'America/Grand_Turk', displayName: '(GMT-4:00) Grand Turk' },
  { value: 'America/Guayaquil', displayName: '(GMT-5:00) Guayaquil' },
  { value: 'America/Havana', displayName: '(GMT-5:00) Havana' },
  { value: 'America/Iqaluit', displayName: '(GMT-5:00) Eastern Time - Iqaluit' },
  { value: 'America/Jamaica', displayName: '(GMT-5:00) Jamaica' },
  { value: 'America/Lima', displayName: '(GMT-5:00) Lima' },
  {
    value: 'America/Cancun',
    displayName: '(GMT-5:00) Cancun - Eastern Time - Quintana Roo',
  },
  { value: 'America/Montreal', displayName: '(GMT-5:00) Eastern Time - Montreal' },
  { value: 'America/Nassau', displayName: '(GMT-5:00) Nassau' },
  { value: 'America/Panama', displayName: '(GMT-5:00) Panama' },
  { value: 'America/Port-au-Prince', displayName: '(GMT-5:00) Port-au-Prince' },
  { value: 'America/Toronto', displayName: '(GMT-5:00) Eastern Time - Toronto' },
  { value: 'America/Caracas', displayName: '(GMT-4:00) Caracas' },
  { value: 'America/Anguilla', displayName: '(GMT-4:00) Anguilla' },
  { value: 'America/Antigua', displayName: '(GMT-4:00) Antigua' },
  { value: 'America/Aruba', displayName: '(GMT-4:00) Aruba' },
  { value: 'America/Asuncion', displayName: '(GMT-3:00) Asuncion' },
  { value: 'America/Barbados', displayName: '(GMT-4:00) Barbados' },
  { value: 'America/Boa_Vista', displayName: '(GMT-4:00) Boa Vista' },
  { value: 'America/Campo_Grande', displayName: '(GMT-3:00) Campo Grande' },
  { value: 'America/Cuiaba', displayName: '(GMT-3:00) Cuiaba' },
  { value: 'America/Curacao', displayName: '(GMT-4:00) Curacao' },
  { value: 'America/Dominica', displayName: '(GMT-4:00) Dominica' },
  { value: 'America/Grenada', displayName: '(GMT-4:00) Grenada' },
  { value: 'America/Guadeloupe', displayName: '(GMT-4:00) Guadeloupe' },
  { value: 'America/Guyana', displayName: '(GMT-4:00) Guyana' },
  { value: 'America/Halifax', displayName: '(GMT-4:00) Atlantic Time - Halifax' },
  { value: 'America/La_Paz', displayName: '(GMT-4:00) La Paz' },
  { value: 'America/Manaus', displayName: '(GMT-4:00) Manaus' },

  { value: 'America/Martinique', displayName: '(GMT-4:00) Martinique' },
  { value: 'America/Montserrat', displayName: '(GMT-4:00) Montserrat' },
  { value: 'America/Port_of_Spain', displayName: '(GMT-4:00) Port of Spain' },
  { value: 'America/Porto_Velho', displayName: '(GMT-4:00) Porto Velho' },
  { value: 'America/Puerto_Rico', displayName: '(GMT-4:00) Puerto Rico' },
  { value: 'America/Rio_Branco', displayName: '(GMT-5:00) Rio Branco' },
  { value: 'America/Santiago', displayName: '(GMT-3:00) Santiago' },
  { value: 'America/Santo_Domingo', displayName: '(GMT-4:00) Santo Domingo' },
  { value: 'America/St_Kitts', displayName: '(GMT-4:00) St. Kitts' },
  { value: 'America/St_Lucia', displayName: '(GMT-4:00) St. Lucia' },
  { value: 'America/St_Thomas', displayName: '(GMT-4:00) St. Thomas' },
  { value: 'America/St_Vincent', displayName: '(GMT-4:00) St. Vincent' },
  { value: 'America/Thule', displayName: '(GMT-4:00) Thule' },
  { value: 'America/Tortola', displayName: '(GMT-4:00) Tortola' },
  { value: 'Antarctica/Palmer', displayName: '(GMT-3:00) Palmer' },
  { value: 'Atlantic/Bermuda', displayName: '(GMT-4:00) Bermuda' },
  { value: 'Atlantic/Stanley', displayName: '(GMT-3:00) Stanley' },
  {
    value: 'America/St_Johns',
    displayName: '(GMT-3:30) Newfoundland Time - St. Johns',
  },
  { value: 'America/Araguaina', displayName: '(GMT-3:00) Araguaina' },
  {
    value: 'America/Argentina/Buenos_Aires',
    displayName: '(GMT-3:00) Buenos Aires',
  },
  { value: 'America/Bahia', displayName: '(GMT-3:00) Salvador' },
  { value: 'America/Belem', displayName: '(GMT-3:00) Belem' },
  { value: 'America/Cayenne', displayName: '(GMT-3:00) Cayenne' },
  { value: 'America/Fortaleza', displayName: '(GMT-3:00) Fortaleza' },
  { value: 'America/Godthab', displayName: '(GMT-3:00) Godthab' },
  { value: 'America/Maceio', displayName: '(GMT-3:00) Maceio' },
  { value: 'America/Miquelon', displayName: '(GMT-3:00) Miquelon' },
  { value: 'America/Montevideo', displayName: '(GMT-3:00) Montevideo' },
  { value: 'America/Paramaribo', displayName: '(GMT-3:00) Paramaribo' },
  { value: 'America/Recife', displayName: '(GMT-3:00) Recife' },
  { value: 'America/Sao_Paulo', displayName: '(GMT-2:00) Sao Paulo' },
  { value: 'Antarctica/Rothera', displayName: '(GMT-3:00) Rothera' },
  { value: 'America/Noronha', displayName: '(GMT-2:00) Noronha' },
  { value: 'Atlantic/South_Georgia', displayName: '(GMT-2:00) South Georgia' },
  { value: 'America/Scoresbysund', displayName: '(GMT-1:00) Scoresbysund' },
  { value: 'Atlantic/Azores', displayName: '(GMT-1:00) Azores' },
  { value: 'Atlantic/Cape_Verde', displayName: '(GMT-1:00) Cape Verde' },
  { value: 'Africa/Abidjan', displayName: '(GMT+0:00) Abidjan' },
  { value: 'Africa/Accra', displayName: '(GMT+0:00) Accra' },
  { value: 'Africa/Bamako', displayName: '(GMT+0:00) Bamako' },
  { value: 'Africa/Banjul', displayName: '(GMT+0:00) Banjul' },
  { value: 'Africa/Bissau', displayName: '(GMT+0:00) Bissau' },
  { value: 'Africa/Casablanca', displayName: '(GMT+0:00) Casablanca' },
  { value: 'Africa/Conakry', displayName: '(GMT+0:00) Conakry' },
  { value: 'Africa/Dakar', displayName: '(GMT+0:00) Dakar' },
  { value: 'Africa/El_Aaiun', displayName: '(GMT+0:00) El Aaiun' },
  { value: 'Africa/Freetown', displayName: '(GMT+0:00) Freetown' },
  { value: 'Africa/Lome', displayName: '(GMT+0:00) Lome' },
  { value: 'Africa/Monrovia', displayName: '(GMT+0:00) Monrovia' },
  { value: 'Africa/Nouakchott', displayName: '(GMT+0:00) Nouakchott' },
  { value: 'Africa/Ouagadougou', displayName: '(GMT+0:00) Ouagadougou' },
  { value: 'Africa/Sao_Tome', displayName: '(GMT+0:00) Sao Tome' },
  { value: 'America/Danmarkshavn', displayName: '(GMT+0:00) Danmarkshavn' },
  { value: 'Atlantic/Canary', displayName: '(GMT+0:00) Canary Islands' },
  { value: 'Atlantic/Faroe', displayName: '(GMT+0:00) Faeroe' },
  { value: 'Atlantic/Reykjavik', displayName: '(GMT+0:00) Reykjavik' },
  { value: 'Atlantic/St_Helena', displayName: '(GMT+0:00) St Helena' },
  { value: 'Etc/GMT', displayName: '(GMT+0:00) GMT (no daylight saving)' },
  { value: 'Europe/Dublin', displayName: '(GMT+0:00) Dublin' },
  { value: 'Europe/Lisbon', displayName: '(GMT+0:00) Lisbon' },
  { value: 'Europe/London', displayName: '(GMT+0:00) London' },
  { value: 'Africa/Algiers', displayName: '(GMT+1:00) Algiers' },
  { value: 'Africa/Bangui', displayName: '(GMT+1:00) Bangui' },
  { value: 'Africa/Brazzaville', displayName: '(GMT+1:00) Brazzaville' },
  { value: 'Africa/Ceuta', displayName: '(GMT+1:00) Ceuta' },
  { value: 'Africa/Douala', displayName: '(GMT+1:00) Douala' },
  { value: 'Africa/Kinshasa', displayName: '(GMT+1:00) Kinshasa' },
  { value: 'Africa/Lagos', displayName: '(GMT+1:00) Lagos' },
  { value: 'Africa/Libreville', displayName: '(GMT+1:00) Libreville' },
  { value: 'Africa/Luanda', displayName: '(GMT+1:00) Luanda' },
  { value: 'Africa/Malabo', displayName: '(GMT+1:00) Malabo' },
  { value: 'Africa/Ndjamena', displayName: '(GMT+1:00) Ndjamena' },
  { value: 'Africa/Niamey', displayName: '(GMT+1:00) Niamey' },
  { value: 'Africa/Porto-Novo', displayName: '(GMT+1:00) Porto-Novo' },
  { value: 'Africa/Tunis', displayName: '(GMT+1:00) Tunis' },
  { value: 'Africa/Windhoek', displayName: '(GMT+2:00) Windhoek' },
  { value: 'Europe/Amsterdam', displayName: '(GMT+1:00) Amsterdam' },
  { value: 'Europe/Andorra', displayName: '(GMT+1:00) Andorra' },
  {
    value: 'Europe/Belgrade',
    displayName: '(GMT+1:00) Central European Time (Europe/Belgrade)',
  },
  { value: 'Europe/Berlin', displayName: '(GMT+1:00) Berlin' },
  { value: 'Europe/Brussels', displayName: '(GMT+1:00) Brussels' },
  { value: 'Europe/Budapest', displayName: '(GMT+1:00) Budapest' },
  { value: 'Europe/Copenhagen', displayName: '(GMT+1:00) Copenhagen' },
  { value: 'Europe/Gibraltar', displayName: '(GMT+1:00) Gibraltar' },
  { value: 'Europe/Luxembourg', displayName: '(GMT+1:00) Luxembourg' },
  { value: 'Europe/Madrid', displayName: '(GMT+1:00) Madrid' },
  { value: 'Europe/Malta', displayName: '(GMT+1:00) Malta' },
  { value: 'Europe/Monaco', displayName: '(GMT+1:00) Monaco' },
  { value: 'Europe/Oslo', displayName: '(GMT+1:00) Oslo' },
  { value: 'Europe/Paris', displayName: '(GMT+1:00) Paris' },
  {
    value: 'Europe/Prague',
    displayName: '(GMT+1:00) Central European Time (Europe/Prague)',
  },
  { value: 'Europe/Rome', displayName: '(GMT+1:00) Rome' },
  { value: 'Europe/Stockholm', displayName: '(GMT+1:00) Stockholm' },
  { value: 'Europe/Tirane', displayName: '(GMT+1:00) Tirane' },
  { value: 'Europe/Vaduz', displayName: '(GMT+1:00) Vaduz' },
  { value: 'Europe/Vienna', displayName: '(GMT+1:00) Vienna' },
  { value: 'Europe/Warsaw', displayName: '(GMT+1:00) Warsaw' },
  { value: 'Europe/Zurich', displayName: '(GMT+1:00) Zurich' },
  { value: 'Africa/Blantyre', displayName: '(GMT+2:00) Blantyre' },
  { value: 'Africa/Bujumbura', displayName: '(GMT+2:00) Bujumbura' },
  { value: 'Africa/Cairo', displayName: '(GMT+2:00) Cairo' },
  { value: 'Africa/Gaborone', displayName: '(GMT+2:00) Gaborone' },
  { value: 'Africa/Harare', displayName: '(GMT+2:00) Harare' },
  { value: 'Africa/Johannesburg', displayName: '(GMT+2:00) Johannesburg' },
  { value: 'Africa/Kigali', displayName: '(GMT+2:00) Kigali' },
  { value: 'Africa/Lubumbashi', displayName: '(GMT+2:00) Lubumbashi' },
  { value: 'Africa/Lusaka', displayName: '(GMT+2:00) Lusaka' },
  { value: 'Africa/Maputo', displayName: '(GMT+2:00) Maputo' },
  { value: 'Africa/Maseru', displayName: '(GMT+2:00) Maseru' },
  { value: 'Africa/Mbabane', displayName: '(GMT+2:00) Mbabane' },
  { value: 'Africa/Tripoli', displayName: '(GMT+2:00) Tripoli' },
  { value: 'Asia/Amman', displayName: '(GMT+2:00) Amman' },
  { value: 'Asia/Beirut', displayName: '(GMT+2:00) Beirut' },
  { value: 'Asia/Damascus', displayName: '(GMT+2:00) Damascus' },
  { value: 'Asia/Gaza', displayName: '(GMT+2:00) Gaza' },
  { value: 'Asia/Jerusalem', displayName: '(GMT+2:00) Jerusalem' },
  { value: 'Asia/Nicosia', displayName: '(GMT+2:00) Nicosia' },
  { value: 'Europe/Athens', displayName: '(GMT+2:00) Athens' },
  { value: 'Europe/Bucharest', displayName: '(GMT+2:00) Bucharest' },
  { value: 'Europe/Chisinau', displayName: '(GMT+2:00) Chisinau' },
  { value: 'Europe/Helsinki', displayName: '(GMT+2:00) Helsinki' },
  { value: 'Europe/Istanbul', displayName: '(GMT+3:00) Istanbul' },
  { value: 'Europe/Kaliningrad', displayName: '(GMT+2:00) Russia - Kaliningrad' },
  { value: 'Europe/Kiev', displayName: '(GMT+2:00) Kiev' },
  { value: 'Europe/Minsk', displayName: '(GMT+3:00) Minsk' },
  { value: 'Europe/Riga', displayName: '(GMT+2:00) Riga' },
  { value: 'Europe/Sofia', displayName: '(GMT+2:00) Sofia' },
  { value: 'Europe/Tallinn', displayName: '(GMT+2:00) Tallinn' },
  { value: 'Europe/Vilnius', displayName: '(GMT+2:00) Vilnius' },
  { value: 'Africa/Addis_Ababa', displayName: '(GMT+3:00) Addis Ababa' },
  { value: 'Africa/Asmara', displayName: '(GMT+3:00) Asmera' },
  { value: 'Africa/Dar_es_Salaam', displayName: '(GMT+3:00) Dar es Salaam' },
  { value: 'Africa/Djibouti', displayName: '(GMT+3:00) Djibouti' },
  { value: 'Africa/Kampala', displayName: '(GMT+3:00) Kampala' },
  { value: 'Africa/Khartoum', displayName: '(GMT+2:00) Khartoum' },
  { value: 'Africa/Mogadishu', displayName: '(GMT+3:00) Mogadishu' },
  { value: 'Africa/Nairobi', displayName: '(GMT+3:00) Nairobi' },
  { value: 'Antarctica/Syowa', displayName: '(GMT+3:00) Syowa' },
  { value: 'Asia/Aden', displayName: '(GMT+3:00) Aden' },
  { value: 'Asia/Baghdad', displayName: '(GMT+3:00) Baghdad' },
  { value: 'Asia/Bahrain', displayName: '(GMT+3:00) Bahrain' },
  { value: 'Asia/Kuwait', displayName: '(GMT+3:00) Kuwait' },
  { value: 'Asia/Qatar', displayName: '(GMT+3:00) Qatar' },
  { value: 'Asia/Riyadh', displayName: '(GMT+3:00) Riyadh' },
  { value: 'Europe/Moscow', displayName: '(GMT+3:00) Russia - Moscow' },
  { value: 'Indian/Antananarivo', displayName: '(GMT+3:00) Antananarivo' },
  { value: 'Indian/Comoro', displayName: '(GMT+3:00) Comoro' },
  { value: 'Indian/Mayotte', displayName: '(GMT+3:00) Mayotte' },
  { value: 'Asia/Tehran', displayName: '(GMT+3:30) Tehran' },
  { value: 'Asia/Baku', displayName: '(GMT+4:00) Baku' },
  { value: 'Asia/Dubai', displayName: '(GMT+4:00) Dubai' },
  { value: 'Asia/Muscat', displayName: '(GMT+4:00) Muscat' },
  { value: 'Asia/Tbilisi', displayName: '(GMT+4:00) Tbilisi' },
  { value: 'Asia/Yerevan', displayName: '(GMT+4:00) Yerevan' },
  { value: 'Europe/Samara', displayName: '(GMT+4:00) Russia - Samara' },
  { value: 'Indian/Mahe', displayName: '(GMT+4:00) Mahe' },
  { value: 'Indian/Mauritius', displayName: '(GMT+4:00) Mauritius' },
  { value: 'Indian/Reunion', displayName: '(GMT+4:00) Reunion' },
  { value: 'Asia/Kabul', displayName: '(GMT+4:30) Kabul' },
  { value: 'Asia/Aqtau', displayName: '(GMT+5:00) Aqtau' },
  { value: 'Asia/Aqtobe', displayName: '(GMT+5:00) Aqtobe' },
  { value: 'Asia/Ashgabat', displayName: '(GMT+5:00) Ashgabat' },
  { value: 'Asia/Dushanbe', displayName: '(GMT+5:00) Dushanbe' },
  { value: 'Asia/Karachi', displayName: '(GMT+5:00) Karachi' },
  { value: 'Asia/Tashkent', displayName: '(GMT+5:00) Tashkent' },
  { value: 'Asia/Yekaterinburg', displayName: '(GMT+5:00) Russia - Yekaterinburg' },
  { value: 'Indian/Kerguelen', displayName: '(GMT+5:00) Kerguelen' },
  { value: 'Indian/Maldives', displayName: '(GMT+5:00) Maldives' },
  { value: 'Asia/Calcutta', displayName: '(GMT+5:30) India Standard Time' },
  { value: 'Asia/Colombo', displayName: '(GMT+5:30) Colombo' },
  { value: 'Asia/Katmandu', displayName: '(GMT+5:45) Katmandu' },
  { value: 'Antarctica/Mawson', displayName: '(GMT+5:00) Mawson' },
  { value: 'Antarctica/Vostok', displayName: '(GMT+6:00) Vostok' },
  { value: 'Asia/Almaty', displayName: '(GMT+6:00) Almaty' },
  { value: 'Asia/Bishkek', displayName: '(GMT+6:00) Bishkek' },
  { value: 'Asia/Dhaka', displayName: '(GMT+6:00) Dhaka' },
  { value: 'Asia/Omsk', displayName: '(GMT+6:00) Russia - Omsk, Novosibirsk' },
  { value: 'Asia/Thimphu', displayName: '(GMT+6:00) Thimphu' },
  { value: 'Indian/Chagos', displayName: '(GMT+6:00) Chagos' },
  { value: 'Asia/Rangoon', displayName: '(GMT+6:30) Rangoon' },
  { value: 'Indian/Cocos', displayName: '(GMT+6:30) Cocos' },
  { value: 'Antarctica/Davis', displayName: '(GMT+7:00) Davis' },
  { value: 'Asia/Bangkok', displayName: '(GMT+7:00) Bangkok' },
  { value: 'Asia/Hovd', displayName: '(GMT+7:00) Hovd' },
  { value: 'Asia/Jakarta', displayName: '(GMT+7:00) Jakarta' },
  { value: 'Asia/Krasnoyarsk', displayName: '(GMT+7:00) Russia - Krasnoyarsk' },
  { value: 'Asia/Phnom_Penh', displayName: '(GMT+7:00) Phnom Penh' },
  { value: 'Asia/Saigon', displayName: '(GMT+7:00) Hanoi' },
  { value: 'Asia/Vientiane', displayName: '(GMT+7:00) Vientiane' },
  { value: 'Indian/Christmas', displayName: '(GMT+7:00) Christmas' },
  { value: 'Antarctica/Casey', displayName: '(GMT+11:00) Casey' },
  { value: 'Asia/Brunei', displayName: '(GMT+8:00) Brunei' },
  { value: 'Asia/Choibalsan', displayName: '(GMT+8:00) Choibalsan' },
  { value: 'Asia/Hong_Kong', displayName: '(GMT+8:00) Hong Kong' },
  { value: 'Asia/Irkutsk', displayName: '(GMT+8:00) Russia - Irkutsk' },
  { value: 'Asia/Kuala_Lumpur', displayName: '(GMT+8:00) Kuala Lumpur' },
  { value: 'Asia/Macau', displayName: '(GMT+8:00) Macau' },
  { value: 'Asia/Makassar', displayName: '(GMT+8:00) Makassar' },
  { value: 'Asia/Manila', displayName: '(GMT+8:00) Manila' },
  { value: 'Asia/Shanghai', displayName: '(GMT+8:00) China Time - Beijing' },
  { value: 'Asia/Singapore', displayName: '(GMT+8:00) Singapore' },
  { value: 'Asia/Taipei', displayName: '(GMT+8:00) Taipei' },
  { value: 'Asia/Ulaanbaatar', displayName: '(GMT+8:00) Ulaanbaatar' },
  { value: 'Australia/Perth', displayName: '(GMT+8:00) Western Time - Perth' },
  { value: 'Asia/Dili', displayName: '(GMT+9:00) Dili' },
  { value: 'Asia/Jayapura', displayName: '(GMT+9:00) Jayapura' },
  { value: 'Asia/Pyongyang', displayName: '(GMT+8:30) Pyongyang' },
  { value: 'Asia/Seoul', displayName: '(GMT+9:00) Seoul' },
  { value: 'Asia/Tokyo', displayName: '(GMT+9:00) Tokyo' },
  { value: 'Asia/Yakutsk', displayName: '(GMT+9:00) Russia - Yakutsk' },
  { value: 'Pacific/Palau', displayName: '(GMT+9:00) Palau' },
  {
    value: 'Australia/Adelaide',
    displayName: '(GMT+10:30) Central Time - Adelaide',
  },
  { value: 'Australia/Darwin', displayName: '(GMT+9:30) Central Time - Darwin' },
  {
    value: 'Antarctica/DumontDUrville',
    displayName: "(GMT+10:00) Dumont D'Urville",
  },
  {
    value: 'Asia/Vladivostok',
    displayName: '(GMT+10:00) Russia - Yuzhno-Sakhalinsk',
  },
  {
    value: 'Australia/Brisbane',
    displayName: '(GMT+10:00) Eastern Time - Brisbane',
  },
  { value: 'Australia/Hobart', displayName: '(GMT+11:00) Eastern Time - Hobart' },
  { value: 'Australia/Sydney', displayName: '(GMT+11:00) Eastern Time - Sydney' },
  {
    value: 'Australia/Melbourne',
    displayName: '(GMT+11:00) Eastern Time - Melbourne',
  },
  { value: 'Pacific/Guam', displayName: '(GMT+10:00) Guam' },
  { value: 'Pacific/Port_Moresby', displayName: '(GMT+10:00) Port Moresby' },
  { value: 'Pacific/Saipan', displayName: '(GMT+10:00) Saipan' },
  { value: 'Pacific/Truk', displayName: '(GMT+10:00) Truk' },
  { value: 'Asia/Magadan', displayName: '(GMT+11:00) Russia - Magadan' },
  { value: 'Pacific/Efate', displayName: '(GMT+11:00) Efate' },
  { value: 'Pacific/Guadalcanal', displayName: '(GMT+11:00) Guadalcanal' },
  { value: 'Pacific/Kosrae', displayName: '(GMT+11:00) Kosrae' },
  { value: 'Pacific/Noumea', displayName: '(GMT+11:00) Noumea' },
  { value: 'Pacific/Ponape', displayName: '(GMT+11:00) Ponape' },
  { value: 'Pacific/Norfolk', displayName: '(GMT+11:00) Norfolk' },
  {
    value: 'Asia/Kamchatka',
    displayName: '(GMT+12:00) Russia - Petropavlovsk-Kamchatskiy',
  },
  { value: 'Pacific/Auckland', displayName: '(GMT+13:00) Auckland' },
  { value: 'Pacific/Fiji', displayName: '(GMT+12:00) Fiji' },
  { value: 'Pacific/Funafuti', displayName: '(GMT+12:00) Funafuti' },
  { value: 'Pacific/Kwajalein', displayName: '(GMT+12:00) Kwajalein' },
  { value: 'Pacific/Majuro', displayName: '(GMT+12:00) Majuro' },
  { value: 'Pacific/Nauru', displayName: '(GMT+12:00) Nauru' },
  { value: 'Pacific/Tarawa', displayName: '(GMT+12:00) Tarawa' },
  { value: 'Pacific/Wake', displayName: '(GMT+12:00) Wake' },
  { value: 'Pacific/Wallis', displayName: '(GMT+12:00) Wallis' },
  { value: 'Pacific/Enderbury', displayName: '(GMT+13:00) Enderbury' },
  { value: 'Pacific/Tongatapu', displayName: '(GMT+13:00) Tongatapu' },
  { value: 'Pacific/Kiritimati', displayName: '(GMT+14:00) Kiritimati' },
];

export const timezoneToDisplayName = (inputTimezone: string) => {
  const result = [...US_TIMEZONES, ...FOREIGN_TIMEZONES].find(
    ({ value }) => value === inputTimezone,
  );
  return result ? result.displayName : inputTimezone;
};

export type Timezone =
  | 'Africa/Abidjan'
  | 'Africa/Accra'
  | 'Africa/Addis_Ababa'
  | 'Africa/Algiers'
  | 'Africa/Asmara'
  | 'Africa/Bamako'
  | 'Africa/Bangui'
  | 'Africa/Banjul'
  | 'Africa/Bissau'
  | 'Africa/Blantyre'
  | 'Africa/Brazzaville'
  | 'Africa/Bujumbura'
  | 'Africa/Cairo'
  | 'Africa/Casablanca'
  | 'Africa/Ceuta'
  | 'Africa/Conakry'
  | 'Africa/Dakar'
  | 'Africa/Dar_es_Salaam'
  | 'Africa/Djibouti'
  | 'Africa/Douala'
  | 'Africa/El_Aaiun'
  | 'Africa/Freetown'
  | 'Africa/Gaborone'
  | 'Africa/Harare'
  | 'Africa/Johannesburg'
  | 'Africa/Kampala'
  | 'Africa/Khartoum'
  | 'Africa/Kigali'
  | 'Africa/Kinshasa'
  | 'Africa/Lagos'
  | 'Africa/Libreville'
  | 'Africa/Lome'
  | 'Africa/Luanda'
  | 'Africa/Lubumbashi'
  | 'Africa/Lusaka'
  | 'Africa/Malabo'
  | 'Africa/Maputo'
  | 'Africa/Maseru'
  | 'Africa/Mbabane'
  | 'Africa/Mogadishu'
  | 'Africa/Monrovia'
  | 'Africa/Nairobi'
  | 'Africa/Ndjamena'
  | 'Africa/Niamey'
  | 'Africa/Nouakchott'
  | 'Africa/Ouagadougou'
  | 'Africa/Porto-Novo'
  | 'Africa/Sao_Tome'
  | 'Africa/Tripoli'
  | 'Africa/Tunis'
  | 'Africa/Windhoek'
  | 'America/Anchorage'
  | 'America/Anguilla'
  | 'America/Antigua'
  | 'America/Araguaina'
  | 'America/Argentina/Buenos_Aires'
  | 'America/Aruba'
  | 'America/Asuncion'
  | 'America/Bahia'
  | 'America/Barbados'
  | 'America/Belem'
  | 'America/Belize'
  | 'America/Boa_Vista'
  | 'America/Bogota'
  | 'America/Campo_Grande'
  | 'America/Cancun'
  | 'America/Caracas'
  | 'America/Cayenne'
  | 'America/Cayman'
  | 'America/Chicago'
  | 'America/Costa_Rica'
  | 'America/Cuiaba'
  | 'America/Curacao'
  | 'America/Danmarkshavn'
  | 'America/Dawson_Creek'
  | 'America/Denver'
  | 'America/Dominica'
  | 'America/Edmonton'
  | 'America/El_Salvador'
  | 'America/Fortaleza'
  | 'America/Godthab'
  | 'America/Grand_Turk'
  | 'America/Grenada'
  | 'America/Guadeloupe'
  | 'America/Guatemala'
  | 'America/Guayaquil'
  | 'America/Guyana'
  | 'America/Halifax'
  | 'America/Havana'
  | 'America/Hermosillo'
  | 'America/Iqaluit'
  | 'America/Jamaica'
  | 'America/La_Paz'
  | 'America/Lima'
  | 'America/Los_Angeles'
  | 'America/Maceio'
  | 'America/Managua'
  | 'America/Manaus'
  | 'America/Martinique'
  | 'America/Mazatlan'
  | 'America/Mexico_City'
  | 'America/Miquelon'
  | 'America/Montevideo'
  | 'America/Montreal'
  | 'America/Montserrat'
  | 'America/Nassau'
  | 'America/New_York'
  | 'America/Noronha'
  | 'America/Panama'
  | 'America/Paramaribo'
  | 'America/Phoenix'
  | 'America/Port-au-Prince'
  | 'America/Port_of_Spain'
  | 'America/Porto_Velho'
  | 'America/Puerto_Rico'
  | 'America/Recife'
  | 'America/Regina'
  | 'America/Rio_Branco'
  | 'America/Santiago'
  | 'America/Santo_Domingo'
  | 'America/Sao_Paulo'
  | 'America/Scoresbysund'
  | 'America/St_Johns'
  | 'America/St_Kitts'
  | 'America/St_Lucia'
  | 'America/St_Thomas'
  | 'America/St_Vincent'
  | 'America/Tegucigalpa'
  | 'America/Thule'
  | 'America/Tijuana'
  | 'America/Toronto'
  | 'America/Tortola'
  | 'America/Vancouver'
  | 'America/Whitehorse'
  | 'America/Winnipeg'
  | 'America/Yellowknife'
  | 'Antarctica/Casey'
  | 'Antarctica/Davis'
  | 'Antarctica/DumontDUrville'
  | 'Antarctica/Mawson'
  | 'Antarctica/Palmer'
  | 'Antarctica/Rothera'
  | 'Antarctica/Syowa'
  | 'Antarctica/Vostok'
  | 'Asia/Aden'
  | 'Asia/Almaty'
  | 'Asia/Amman'
  | 'Asia/Aqtau'
  | 'Asia/Aqtobe'
  | 'Asia/Ashgabat'
  | 'Asia/Baghdad'
  | 'Asia/Bahrain'
  | 'Asia/Baku'
  | 'Asia/Bangkok'
  | 'Asia/Beirut'
  | 'Asia/Bishkek'
  | 'Asia/Brunei'
  | 'Asia/Calcutta'
  | 'Asia/Choibalsan'
  | 'Asia/Colombo'
  | 'Asia/Damascus'
  | 'Asia/Dhaka'
  | 'Asia/Dili'
  | 'Asia/Dubai'
  | 'Asia/Dushanbe'
  | 'Asia/Gaza'
  | 'Asia/Hong_Kong'
  | 'Asia/Hovd'
  | 'Asia/Irkutsk'
  | 'Asia/Jakarta'
  | 'Asia/Jayapura'
  | 'Asia/Jerusalem'
  | 'Asia/Kabul'
  | 'Asia/Kamchatka'
  | 'Asia/Karachi'
  | 'Asia/Katmandu'
  | 'Asia/Krasnoyarsk'
  | 'Asia/Kuala_Lumpur'
  | 'Asia/Kuwait'
  | 'Asia/Macau'
  | 'Asia/Magadan'
  | 'Asia/Makassar'
  | 'Asia/Manila'
  | 'Asia/Muscat'
  | 'Asia/Nicosia'
  | 'Asia/Omsk'
  | 'Asia/Phnom_Penh'
  | 'Asia/Pyongyang'
  | 'Asia/Qatar'
  | 'Asia/Rangoon'
  | 'Asia/Riyadh'
  | 'Asia/Saigon'
  | 'Asia/Seoul'
  | 'Asia/Shanghai'
  | 'Asia/Singapore'
  | 'Asia/Taipei'
  | 'Asia/Tashkent'
  | 'Asia/Tbilisi'
  | 'Asia/Tehran'
  | 'Asia/Thimphu'
  | 'Asia/Tokyo'
  | 'Asia/Ulaanbaatar'
  | 'Asia/Vientiane'
  | 'Asia/Vladivostok'
  | 'Asia/Yakutsk'
  | 'Asia/Yekaterinburg'
  | 'Asia/Yerevan'
  | 'Atlantic/Azores'
  | 'Atlantic/Bermuda'
  | 'Atlantic/Canary'
  | 'Atlantic/Cape_Verde'
  | 'Atlantic/Faroe'
  | 'Atlantic/Reykjavik'
  | 'Atlantic/South_Georgia'
  | 'Atlantic/St_Helena'
  | 'Atlantic/Stanley'
  | 'Australia/Adelaide'
  | 'Australia/Brisbane'
  | 'Australia/Darwin'
  | 'Australia/Hobart'
  | 'Australia/Melbourne'
  | 'Australia/Perth'
  | 'Australia/Sydney'
  | 'Etc/GMT'
  | 'Europe/Amsterdam'
  | 'Europe/Andorra'
  | 'Europe/Athens'
  | 'Europe/Belgrade'
  | 'Europe/Berlin'
  | 'Europe/Brussels'
  | 'Europe/Bucharest'
  | 'Europe/Budapest'
  | 'Europe/Chisinau'
  | 'Europe/Copenhagen'
  | 'Europe/Dublin'
  | 'Europe/Gibraltar'
  | 'Europe/Helsinki'
  | 'Europe/Istanbul'
  | 'Europe/Kaliningrad'
  | 'Europe/Kiev'
  | 'Europe/Lisbon'
  | 'Europe/London'
  | 'Europe/Luxembourg'
  | 'Europe/Madrid'
  | 'Europe/Malta'
  | 'Europe/Minsk'
  | 'Europe/Monaco'
  | 'Europe/Moscow'
  | 'Europe/Oslo'
  | 'Europe/Paris'
  | 'Europe/Prague'
  | 'Europe/Riga'
  | 'Europe/Rome'
  | 'Europe/Samara'
  | 'Europe/Sofia'
  | 'Europe/Stockholm'
  | 'Europe/Tallinn'
  | 'Europe/Tirane'
  | 'Europe/Vaduz'
  | 'Europe/Vienna'
  | 'Europe/Vilnius'
  | 'Europe/Warsaw'
  | 'Europe/Zurich'
  | 'Indian/Antananarivo'
  | 'Indian/Chagos'
  | 'Indian/Christmas'
  | 'Indian/Cocos'
  | 'Indian/Comoro'
  | 'Indian/Kerguelen'
  | 'Indian/Mahe'
  | 'Indian/Maldives'
  | 'Indian/Mauritius'
  | 'Indian/Mayotte'
  | 'Indian/Reunion'
  | 'Pacific/Apia'
  | 'Pacific/Auckland'
  | 'Pacific/Easter'
  | 'Pacific/Efate'
  | 'Pacific/Enderbury'
  | 'Pacific/Fakaofo'
  | 'Pacific/Fiji'
  | 'Pacific/Funafuti'
  | 'Pacific/Galapagos'
  | 'Pacific/Gambier'
  | 'Pacific/Guadalcanal'
  | 'Pacific/Guam'
  | 'Pacific/Honolulu'
  | 'Pacific/Johnston'
  | 'Pacific/Kiritimati'
  | 'Pacific/Kosrae'
  | 'Pacific/Kwajalein'
  | 'Pacific/Majuro'
  | 'Pacific/Marquesas'
  | 'Pacific/Midway'
  | 'Pacific/Nauru'
  | 'Pacific/Niue'
  | 'Pacific/Norfolk'
  | 'Pacific/Noumea'
  | 'Pacific/Pago_Pago'
  | 'Pacific/Palau'
  | 'Pacific/Pitcairn'
  | 'Pacific/Ponape'
  | 'Pacific/Port_Moresby'
  | 'Pacific/Rarotonga'
  | 'Pacific/Saipan'
  | 'Pacific/Tahiti'
  | 'Pacific/Tarawa'
  | 'Pacific/Tongatapu'
  | 'Pacific/Truk'
  | 'Pacific/Wake'
  | 'Pacific/Wallis';
