import React from 'react';
import PropTypes from 'prop-types';

import './jide_tab_bar.css';
import BackButton from 'components/BackButton';
import { AlertIcon, XIcon } from 'components/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COURSE_TYPES } from 'constants/subjects';
import EditProjectName from './EditProjectName';

const WHITEBOARD_SECTION_TYPES = ['concept', 'activity', 'problem_set'];

const JideTabBar = ({
  activeNav,
  activeTabId,
  recordingTabId,
  idLookup,
  navBarSections,
  onTabClick,
  onTabClose,
  openTabs,
  modalViewMode,
  playgroundProject,
  returnToPlayground,
  updateProjectName,
}) => {
  if (openTabs.length === 0) {
    return null;
  }
  const tabComponents = openTabs.map(t => {
    const isActive = t.tabId === activeTabId;
    const lastSectionType = navBarSections[navBarSections.length - 1];
    const tabTitle = idLookup[t.tabNav[lastSectionType]]?.displayName;
    return (
      <JideTab
        key={`tab_${t.tabId.toString()}`}
        tab={t}
        isActive={isActive}
        tabTitle={tabTitle || ''}
        onTabClick={onTabClick}
        onTabClose={onTabClose}
        activeNav={activeNav}
        idLookup={idLookup}
        modalViewMode={modalViewMode}
        updateProjectName={updateProjectName}
        recordingTabId={recordingTabId}
      />
    );
  });
  const course = idLookup[activeNav.course];
  const student = idLookup[activeNav.student];
  const moduleSection = idLookup[activeNav.project];

  return (
    <div className={`jide-tb ${playgroundProject ? 'playground' : ''}`}>
      <div>
        {tabComponents}
        {modalViewMode &&
          course &&
          [
            COURSE_TYPES.MATHEMATICS,
            COURSE_TYPES.ENGLISH,
            COURSE_TYPES.INVESTING,
          ].includes(course.properties.courseType) &&
          moduleSection &&
          WHITEBOARD_SECTION_TYPES.includes(
            moduleSection.properties.sectionType,
          ) && (
            <div className="edit-mode-warning">
              <AlertIcon />
              Any edits made here are immediately saved to{' '}
              {`${
                student ? `${student.displayName.split(' ')[0]}'s` : "this student's"
              }`}{' '}
              whiteboard.
            </div>
          )}
        {playgroundProject && (
          <div className="return-to-playground__button">
            <BackButton onClick={returnToPlayground}>Back to Playground</BackButton>
          </div>
        )}
      </div>
      <hr className="bottom-bar" />
    </div>
  );
};

const JideTab = ({
  idLookup,
  isActive,
  onTabClick,
  onTabClose,
  tab,
  tabTitle,
  modalViewMode,
  updateProjectName,
  recordingTabId,
}) => {
  const tabIsRecording = tab.tabId === recordingTabId;
  const curCourse = idLookup[tab.tabNav.course];
  const isMathTab = curCourse?.properties.courseType === COURSE_TYPES.MATHEMATICS;
  const isEnglishTab = curCourse?.properties.courseType === COURSE_TYPES.ENGLISH;
  const isInvestingTab = curCourse?.properties.courseType === COURSE_TYPES.INVESTING;
  const isScratchTab =
    curCourse?.properties.name.includes('scratch') ||
    curCourse?.properties.name === 'game_superstar' ||
    curCourse?.properties.name === 'juni_jr';
  const isWebTab = curCourse?.properties.name.includes('web');
  const tabSection = idLookup[tab.tabNav.project];

  let tabText =
    tabSection && ['concept', 'activity'].includes(tabSection.properties.sectionType)
      ? tabTitle
      : tabTitle?.split(':')[0];

  const module = idLookup[tab.tabNav.module];

  if (module && (isMathTab || isEnglishTab || isInvestingTab)) {
    tabText = `${module.properties.name} ${tabText}`;
  }

  return (
    <div
      className={`jide-tab ${isActive ? 'active' : ''} ${isMathTab ? 'math' : ''} ${
        isEnglishTab ? 'english' : ''
      } ${isInvestingTab ? 'investing' : ''} ${isScratchTab ? 'scratch' : ''} ${
        isWebTab ? 'web' : ''
      }`}
      onClick={updateProjectName ? undefined : () => onTabClick(tab.tabId)}
      title={tabTitle}
    >
      <div className="jide-tab-left">
        <div />
      </div>
      <div className={`jide-tab-mid ${modalViewMode ? 'teacher-view-mode' : ''}`}>
        <div className="jide-tab-mid-text">{tabText}</div>
        {!modalViewMode && !updateProjectName && (
          <div className="jide-tab-mid-x">
            {tabIsRecording ? (
              <button className="jide-recording-tab" value={tab.tabId}>
                <FontAwesomeIcon icon={['fas', 'circle']} />
              </button>
            ) : (
              <button
                className="jide-close-tab"
                onClick={onTabClose}
                value={tab.tabId}
              >
                <XIcon />
              </button>
            )}
          </div>
        )}
        {updateProjectName && (
          <EditProjectName
            projectId={tab.tabNav.project}
            initialProjectName={tabTitle}
            updateProjectName={updateProjectName}
          />
        )}
      </div>
      <div className="jide-tab-right">
        <div />
        {!isActive && <div className="inner-triangle" />}
      </div>
    </div>
  );
};

JideTab.propTypes = {
  idLookup: PropTypes.shape({}).isRequired,
  isActive: PropTypes.bool,
  onTabClick: PropTypes.func.isRequired,
  onTabClose: PropTypes.func.isRequired,
  tab: PropTypes.shape({}).isRequired,
  tabTitle: PropTypes.string.isRequired,
  modalViewMode: PropTypes.bool,
  updateProjectName: PropTypes.func,
};
JideTabBar.propTypes = {
  activeNav: PropTypes.shape({
    student: PropTypes.string,
    course: PropTypes.string,
    module: PropTypes.string,
    project: PropTypes.string,
  }).isRequired,
  activeTabId: PropTypes.string,
  idLookup: PropTypes.shape({}).isRequired,
  navBarSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTabClick: PropTypes.func.isRequired,
  onTabClose: PropTypes.func.isRequired,
  openTabs: PropTypes.array.isRequired,
  modalViewMode: PropTypes.bool,
  playgroundProject: PropTypes.shape({
    projectType: PropTypes.string,
    userType: PropTypes.string,
  }),
  updateProjectName: PropTypes.func,
};
export default JideTabBar;
