import {
  CHECKOUT_PATHS,
  ONBOARDING_PATHS,
  BASE_PATHS,
} from 'constants/signup_sessions';

const createDefaultStates = (basePath: string, states: { [key: string]: string }) =>
  Object.entries(states).reduce(
    (prev, [k, v]) => ({
      ...prev,
      [k]: `${basePath}-${v}`,
    }),
    {},
  );

const navStates: Record<string, any> = {
  [BASE_PATHS.checkout]: {
    default: 'signup-default',
    ...createDefaultStates(BASE_PATHS.checkout, CHECKOUT_PATHS),
  },
  [BASE_PATHS.onboarding]: {
    default: 'onboarding-default',
    ...createDefaultStates(BASE_PATHS.onboarding, ONBOARDING_PATHS),
  },
  tuitionPage: 'tuition-page',
  onDemandLibrary: 'on-demand-lib',
};

export default navStates;
