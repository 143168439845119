import React, { FC } from 'react';
import { formatPrice } from 'constants/subscription_plans';
import { StripeCoupon } from 'generated/graphql';
import _ from 'lodash';

const BOOTCAMP_PERCENT_OFF_DISCOUNT = 0.5; // applying 50% off discount to all bootcamps for launch

interface PriceDisplayProps {
  basePriceInCents: number;
  coupon: StripeCoupon | undefined;
  isBootcampSignup: boolean;
}

const PriceDisplay: FC<PriceDisplayProps> = ({
  basePriceInCents,
  coupon,
  isBootcampSignup,
}) => {
  const basePrice = basePriceInCents / 100;
  const priceWithPromo = isBootcampSignup
    ? basePrice * (1 - BOOTCAMP_PERCENT_OFF_DISCOUNT)
    : basePrice;
  let priceWithCoupon = priceWithPromo;
  if (coupon) {
    priceWithCoupon = !_.isNil(coupon.amount_off)
      ? priceWithPromo - coupon.amount_off / 100
      : !_.isNil(coupon.percent_off)
      ? priceWithPromo * (1 - coupon.percent_off / 100)
      : priceWithPromo;
  }

  return (
    <div className="mb-4">
      <p className="text-j-dark-400 text-md mt-0 mb-2">Price</p>
      <div className="text-j-dark-600 text-sm flex flex-col">
        {isBootcampSignup ? (
          <>
            <div className="text-j-dark-600 text-sm flex flex-col">
              <span className="line-through">{`${formatPrice(basePrice)}`}</span>{' '}
              {coupon !== undefined ? (
                <>
                  <span className="line-through">
                    {`${formatPrice(
                      priceWithPromo,
                    )} (Bootcamp Launch Discount: 50% off)`}
                  </span>
                  <span>
                    {`${formatPrice(priceWithCoupon)} (${coupon.id}: ${
                      !_.isNil(coupon.amount_off)
                        ? `$${coupon.amount_off / 100}`
                        : `${coupon.percent_off}%`
                    } off)`}
                  </span>
                </>
              ) : (
                <span>
                  {`${formatPrice(
                    priceWithPromo,
                  )} (Bootcamp Launch Discount: 50% off)`}
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            {coupon !== undefined ? (
              <>
                <span className="line-through">{`${formatPrice(
                  basePrice,
                )} / month`}</span>
                <span>
                  {`${formatPrice(priceWithCoupon)} / month (${coupon.id}: ${
                    !_.isNil(coupon.amount_off)
                      ? `$${coupon.amount_off / 100}`
                      : `${coupon.percent_off}%`
                  } off)`}
                </span>
              </>
            ) : (
              <div className="text-j-dark-600 text-sm flex flex-col">
                <span>{`${formatPrice(basePrice)} / month`}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PriceDisplay;
