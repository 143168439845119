import React from 'react';
import { Card } from 'core-components';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

const MyClubsAnnouncement: React.FC = () => (
  <Card
    borderColor="juni-purple-200"
    borderWidth="2"
    className="mb-10 px-4 py-2"
    contentClassName="flex flex-col sm:flex-row items-center sm:items-start"
    padding="0"
    spaceContent={false}
  >
    <div className="flex flex-col self-center p-5">
      <b className="text-lg text-center">✏️ 📚 🍎 Back to School! 🍎 📚 ✏️ </b>
      <div>
        <hr className="border border-solid border-juni-purple-100 my-4" />
      </div>
      <p className="m-0">
        Are you back at school or headed back soon? If you’re allowed to be on social
        media, visit Juni’s channels for some fun back to school content! Visit and
        follow our{' '}
        <a
          className="text-j-blue-500"
          href="https://www.tiktok.com/@junilearning?lang=en"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            // send message sent event with moderation feedback to snowflake.
            JuniAnalytics.track('club_banner_clicked', {});
          }}
        >
          Tik Tok
        </a>{' '}
        channel to get some back to school inspiration and laughs!
      </p>
    </div>
  </Card>
);

export default MyClubsAnnouncement;
