export const getOffsetTop = initialElem => {
  let offsetTop = 0;
  let currentElem = initialElem;
  do {
    if (!Number.isNaN(currentElem.offsetTop)) {
      offsetTop += currentElem.offsetTop;
    }
    currentElem = currentElem.offsetParent;
  } while (currentElem);
  return offsetTop;
};

export const getOffsetLeft = initialElem => {
  let offsetLeft = 0;
  let currentElem = initialElem;
  do {
    if (!Number.isNaN(currentElem.offsetLeft)) {
      offsetLeft += currentElem.offsetLeft;
    }
    currentElem = currentElem.offsetParent;
  } while (currentElem);
  return offsetLeft;
};
