import React, { FC } from 'react';
import { Button } from 'core-components';

import { useRegisterCtaBannerClickMutation } from 'generated/graphql';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

const URL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:3000'
    : 'https://app.junilearning.com';

interface EnrollButtonProps {
  variant?: React.ComponentProps<typeof Button>['variant'];
  width?: React.ComponentProps<typeof Button>['width'];
  className?: string;
  studentId?: string;
}

const EnrollButton: FC<EnrollButtonProps> = ({
  className,
  studentId,
  variant,
  width,
}) => {
  const [registerCTABannerClick] = useRegisterCtaBannerClickMutation();
  const handleClick = async () => {
    const enrollmentPage = window.open('', '_blank');
    const response = await registerCTABannerClick({
      variables: {
        input: {
          guestStudentId: studentId,
        },
      },
    });
    const guestInvitationLookupId =
      response.data?.registerCtaBannerClick?.guestInvitation?.invitationLookupId;
    if (enrollmentPage && guestInvitationLookupId) {
      // send click analytics to snowflake. Juni Analytics by default includes URL and student ID.
      JuniAnalytics.track('guest_enroll_button_clicked', {
        guestInvitationLookupId,
      });
      enrollmentPage.location.href = `${URL}/signup/guest/${guestInvitationLookupId}`;
    } else {
      enrollmentPage?.close();
    }
  };
  return studentId ? (
    <Button
      variant={variant}
      width={width}
      type="submit"
      className={className}
      onClick={handleClick}
      hasArrowIcon
    >
      Enroll Now
    </Button>
  ) : null;
};

export default EnrollButton;
