import React, { FC } from 'react';
import { Icon, NewButton } from 'core-components';

export interface JideNextSectionProps {
  nextSection: {
    id: string;
    displayName: string;
    url: string;
  };
}

const JideNextSection: FC<JideNextSectionProps> = ({ nextSection }) => (
  <div>
    <div className="flex flex-col py-4 font-graphik text-xs">
      <div className="uppercase text-j-dark-600 mb-2 flex">
        <div className="font-medium mr-1">Next:</div>
        {nextSection.displayName}
      </div>
      {/** TODO: replace with onClick and open a new jide tab on the same page */}
      <a href={nextSection.url} className="no-underline">
        <NewButton
          renderIconRight={props => <Icon.ArrowRight {...props} />}
          fullWidth
          size="xsmall"
        >
          Next Section
        </NewButton>
      </a>
    </div>
  </div>
);

export default JideNextSection;
