import { COURSE_FORMAT } from 'constants/signup_sessions';

export const getCourseFormatFromBundleName = (
  bundleName?: string,
): string | undefined =>
  !bundleName
    ? undefined
    : bundleName === COURSE_FORMAT.group
    ? COURSE_FORMAT.group
    : bundleName === COURSE_FORMAT.onDemand
    ? COURSE_FORMAT.onDemand
    : COURSE_FORMAT.private;
