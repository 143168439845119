import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { CheckmarkIcon, PencilIcon } from 'components/Icons';
import SpinnerV2 from 'components/SpinnerV2';

const SAVE_STATUS_ICONS: Record<string, any> = {
  saved: <CheckmarkIcon />,
  saving: <SpinnerV2 size={12} />,
  edited: <PencilIcon />,
};
const SAVE_STATUS_COLORS: Record<
  string,
  { border: string; background: string; text: string; subText: string }
> = {
  saved: {
    border: 'hsl(117, 100%, 30%)',
    background: 'hsl(117, 91%, 37%)',
    text: '#fff',
    subText: 'rgba(255, 255, 255, 0.7)',
  },
  saving: {
    border: 'hsl(50, 100%, 42%)',
    background: 'hsl(50, 91%, 45%)',
    text: '#fff',
    subText: 'rgba(255, 255, 255, 0.7)',
  },
  edited: {
    border: 'hsl(0, 0%, 70%)',
    background: 'hsl(0, 0%, 70%)',
    text: '#fff',
    subText: 'rgba(255, 255, 255, 0.7)',
  },
};

const getTimeSinceLastSaveDisplayStr = (secondsPassed: number) => {
  if (secondsPassed < 10) return 'Just now';
  if (secondsPassed < 60) return '< 1 minute ago';
  const minutesPassed = Math.floor(secondsPassed / 60);
  if (secondsPassed < 60 * 90)
    return `${minutesPassed} minute${minutesPassed > 1 ? 's' : ''} ago`;
  const hoursPassed = Math.floor(secondsPassed / (60 * 60));
  if (secondsPassed < 60 * 60 * 24)
    return `${hoursPassed} hour${hoursPassed > 1 ? 's' : ''} ago`;
  const daysPassed = Math.floor(secondsPassed / (60 * 60 * 24));
  return `${daysPassed} day${daysPassed > 1 ? 's' : ''} ago`;
};

const getTimeSinceLastSaveShortDisplayStr = (secondsPassed: number) => {
  if (secondsPassed < 10) return 'Just now';
  if (secondsPassed < 60) return '<1m ago';
  const minutesPassed = Math.floor(secondsPassed / 60);
  if (secondsPassed < 60 * 90) return `${minutesPassed}m ago`;
  const hoursPassed = Math.floor(secondsPassed / (60 * 60));
  if (secondsPassed < 60 * 60 * 24) return `${hoursPassed}h ago`;
  const daysPassed = Math.floor(secondsPassed / (60 * 60 * 24));
  return `${daysPassed}d ago`;
};

const SaveStatus: React.FC<{
  status: 'saved' | 'edited' | 'saving';
  lastSavedAt: number;
  narrowViewMode: boolean;
  handleManualSave: () => void;
}> = ({ status, lastSavedAt, narrowViewMode, handleManualSave }) => {
  const now = moment().unix();
  const [secondsSinceLastSave, setSecondsSinceLastSave] = useState<number>(
    now - lastSavedAt,
  );
  useEffect(() => {
    let interval: ReturnType<typeof setTimeout> | undefined;
    if (status === 'saved') {
      setSecondsSinceLastSave(moment().unix() - lastSavedAt);
      interval = setInterval(() => {
        setSecondsSinceLastSave(moment().unix() - lastSavedAt);
      }, 1000);
    } else if (interval) clearInterval(interval);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [lastSavedAt, status]);

  const clickHandler = status === 'edited' ? handleManualSave : undefined;

  return (
    <StyledSaveStatus status={status} narrow={narrowViewMode} onClick={clickHandler}>
      <div className="icon-and-status">
        {SAVE_STATUS_ICONS[status]}
        {`${status[0].toUpperCase()}${status.substring(1)}`}
      </div>
      {status === 'saved' && lastSavedAt !== 0 && (
        <div className="subtext">
          {narrowViewMode
            ? getTimeSinceLastSaveShortDisplayStr(secondsSinceLastSave)
            : getTimeSinceLastSaveDisplayStr(secondsSinceLastSave)}
        </div>
      )}
      {status === 'edited' && <div className="subtext">Click to save now</div>}
    </StyledSaveStatus>
  );
};
const StyledSaveStatus = styled.div<any>`
  border-radius: 8px;
  border: 2px solid ${props => SAVE_STATUS_COLORS[props.status].border};
  background: ${props => SAVE_STATUS_COLORS[props.status].background};
  color: ${props => SAVE_STATUS_COLORS[props.status].text};
  padding: ${props => (props.narrow ? '6px' : '8px')};
  font-size: ${props => (props.narrow ? '12px' : '14px')};
  font-weight: bold;
  box-sizing: border-box;
  text-align: ${props => (props.narrow ? 'center' : 'left')};
  ${props => (props.narrow ? 'width: 56px;' : '')}
  cursor: ${props => (props.status === 'edited' ? 'pointer' : '')};
  .spinner-v2-wrapper {
    flex: 0;
  }
  .spinner-v2 {
    margin: 2px;
    ${props => (props.narrow ? '' : 'margin-right: 12px;')}
  }
  .spinner-v2 .spinner-dot-container .spinner-dot {
    background-color: #fff;
  }
  .icon-and-status {
    display: flex;
    flex-direction: ${props => (props.narrow ? 'column' : 'row')};
    align-items: center;
  }
  .icon {
    padding: 0;
    margin-right: ${props => (props.narrow ? '0' : '12px')};
    ${props => (props.narrow ? 'margin-bottom: 2px;' : '')}
  }
  .icon polyline {
    stroke: #fff;
  }
  .icon path {
    fill: #fff;
  }
  .subtext {
    color: ${props => SAVE_STATUS_COLORS[props.status].subText};
    font-size: ${props => (props.narrow ? '8px' : '10px')};
  }
`;

export default SaveStatus;
