import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  JRSModalLine,
  JRSSteps,
  JRSNextButton,
  JRSStatusMessage,
  JRSGif,
} from './subcomponents';

import '../styles/display_media_request.css';
import '../styles/jide_rec_studio.css';

// PRIVATE SUBCOMPONENTS
const JRSDisplayMediaRequestMainContent = ({ requestScreen, hideNextButton }) => (
  <>
    <div className="jrs-modal-subsection jrs-row jrs-displaymediarequest-instructions">
      <JRSGif
        src={`${window.location.origin}/recording_studio/selectDisplayMedia.mp4`}
      />

      <div className="jrs-modal-subsection jrs-column">
        <div className="jrs-modal-heading4">
          You'll now be prompted to share your current tab.
        </div>
        <JRSModalLine
          icon="chalkboard-teacher"
          boldText="Step 1:"
          text={`Click "Chrome Tab" in the popup window`}
        />
        <JRSModalLine
          icon="chalkboard-teacher"
          boldText="Step 2: Select Juni Tab"
          text={`Select the line labeled "JUNI RECORDING STUDIO" and then hit "Share"`}
        />
        <div className="sm">
          <JRSModalLine text="For best results, make sure your window is maximized to the size of your screen" />
        </div>
        {hideNextButton ? null : (
          <JRSNextButton
            onClick={() => requestScreen()}
            text="Enable Access"
            enabled
          />
        )}
      </div>
    </div>
  </>
);

// Special state with a modal while the large browser popup
// to select screen recording source is open
const JRSDisplayMediaRequestWaiting = () => (
  <div className="jrs-modal-section jrs-column">
    <div className="jrs-modal-subsection jrs-row jrs-waiting-upper">
      <JRSGif
        src={`${window.location.origin}/recording_studio/selectDisplayMedia.mp4`}
      />
    </div>

    <div className="jrs-modal-subsection jrs-column jrs-waiting-lower">
      <div className="jrs-modal-heading1">Select a Screen to Record</div>

      <JRSModalLine boldText="Step 1" text={`Click "Chrome Tab"`} />
      <JRSModalLine
        boldText="Step 2"
        text={`Select the line labeled "JUNI RECORDING STUDIO"`}
      />
      <JRSModalLine boldText="Step 3" text={`Hit the "Share" button!`} />
    </div>
  </div>
);

// Modal that guides user through the displayMedia (ie. screenshare) request process
// by showing them a gif of the browser notification they will see, what to do
// once it shows up, and a confirmation that the access was granted once complete.
// If access is denied, it will indicate that it failed and ask the user to try again.
const JRSDisplayMediaRequest = ({ setMode, buildDisplayStream }) => {
  const [requestSuccess, setRequestSuccess] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);

  // Invoke buildDisplayStream function from streamBuilder
  // to trigger getDisplayMedia and change modal depending on
  // whether access was granted or not
  const requestScreen = async () => {
    setRequestSuccess(null);
    setIsWaiting(true);
    const success = await buildDisplayStream();
    if (success) {
      setRequestSuccess(true);
    } else {
      setRequestSuccess(false);
    }
    setIsWaiting(false);
  };

  const currStep = requestSuccess ? 2.5 : 2;

  return (
    <div
      className={`jrs-modal jrs-displaymediarequest${
        isWaiting ? ' jrs-waiting' : ''
      }`}
    >
      {isWaiting ? (
        <JRSDisplayMediaRequestWaiting />
      ) : (
        <>
          <div className="jrs-modal-section jrs-modal-header jrs-row">
            <div className="jrs-modal-heading1">Select a Screen to Record</div>
            <div className="jrs-modal-x" onClick={() => setMode('close')}>
              <div className="jrs-modal-x-inner">
                <FontAwesomeIcon icon={['fas', 'times']} />
              </div>
            </div>
          </div>

          <div className="jrs-modal-section jrs-column jrs-section">
            {requestSuccess === true ? (
              <>
                <JRSSteps
                  currStep={currStep}
                  onClick={() => {
                    setMode('record');
                  }}
                />
                <JRSStatusMessage
                  color="green"
                  icon="check"
                  text="Screen Selected!"
                />
                <JRSNextButton
                  onClick={() => {
                    setMode('record');
                  }}
                  text="Next Step: Enter the Recording Studio"
                  enabled
                />
              </>
            ) : requestSuccess === false ? (
              <>
                <JRSDisplayMediaRequestMainContent
                  requestScreen={requestScreen}
                  hideNextButton
                />
                <JRSStatusMessage
                  color="red"
                  icon="exclamation-triangle"
                  text="No screen was selected"
                  buttonText="Try Again"
                  onClick={requestScreen}
                />
              </>
            ) : (
              <>
                <JRSDisplayMediaRequestMainContent requestScreen={requestScreen} />
                {/* <JRSStatusMessage
                  icon={'window-restore'}
                  color={'blue'}
                  text={`To use this, you must enable screen sharing`}
                  buttonText={`Enable Access`}
                  onClick={requestScreen}
                /> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default JRSDisplayMediaRequest;
