import Auth from 'modules/Auth';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
if (apiUrl === undefined) {
  throw new Error('env variable REACT_APP_API_URL must be defined');
}

const juniAxios = axios.create({
  baseURL: apiUrl,
});

const isAuthHandlerEnabled = (config = {}) =>
  !(
    Object.prototype.hasOwnProperty.call(config, 'authHandlerEnabled') &&
    !config.authHandlerEnabled
  );

const requestAuthHandler = request => {
  if (isAuthHandlerEnabled(request)) {
    const authToken = Auth.getToken();
    if (authToken) {
      request.headers.Authorization = `bearer ${authToken}`;
    }
  }
  return request;
};

juniAxios.interceptors.request.use(request => requestAuthHandler(request));

export default juniAxios;
