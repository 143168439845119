import React, { FC } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import 'moment-timezone';

import JoinSessionButton from 'components/JoinSessionButton';
import useDimensions from 'hooks/dimensions';
import { StudentSession } from 'models/acuity';
import { ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS } from 'constants/acuity';

interface PortalSessionAlertProps {
  session: StudentSession;
  timezone?: string;
}

// TODO: bg-juni-purple-500 is a temporary color since
// bg-j-dark-600 blends with the sidebar. We will
// change this to bg-juni-dark-600 when New Horizons restyling
// is complete.
const SessionAlertBanner = styled.div.attrs({ className: 'bg-juni-purple-500' })`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SessionAlertContent = styled.div<any>`
  color: white;
  font-size: ${props => (props.smallMobileView ? '12px' : '14px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${props =>
    props.largeDesktopView ? '62%' : props.desktopView ? '70%' : '100%'};
`;

const SessionAlertText = styled.div`
  margin-left: 15px;
`;

const JoinSessionButtonWrapper = styled.div<any>`
  padding: ${props => (props.smallMobileView ? '5px 10px' : ' 10px 20px')};
  min-width: 150px;
`;

const PortalSessionAlert: FC<PortalSessionAlertProps> = ({ session, timezone }) => {
  const { width } = useDimensions();
  const mobileView = width <= 920;
  const smallMobileView = width <= 550;
  const largeDesktopView = width > 1100;
  const bannerText = `You have a Juni session starting at
    ${moment(session.datetime)
      .tz(timezone || session.timezone)
      .format('h:mm a z.')}`;

  const isTrial = ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS.includes(
    session.appointmentTypeID,
  );

  return (
    <SessionAlertBanner>
      <SessionAlertContent
        desktopView={!mobileView}
        largeDesktopView={largeDesktopView}
        smallMobileView={smallMobileView}
      >
        <SessionAlertText>{bannerText}</SessionAlertText>
        <JoinSessionButtonWrapper smallMobileView={smallMobileView}>
          <JoinSessionButton session={session} isTrial={isTrial} bannerButton />
        </JoinSessionButtonWrapper>
      </SessionAlertContent>
    </SessionAlertBanner>
  );
};

export default PortalSessionAlert;
