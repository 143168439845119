import React from 'react';
import PropTypes from 'prop-types';

import { AlertIcon } from '../Icons';

import './alert_banner.css';

const AlertBanner = ({ content, button, style, textStyle, className, onClick }) => (
  <div className={`alert-banner ${className}`} style={style} onClick={onClick}>
    <div className="icon-container">
      <AlertIcon white style={{ height: '16px', width: '16px' }} />
    </div>
    <div className={`alert-banner-content ${button ? 'with-button' : ''}`}>
      <div className="alert-banner-text" style={textStyle}>
        {content}
      </div>
      {button}
    </div>
  </div>
);

AlertBanner.defaultProps = {
  button: null,
  textStyle: null,
  style: null,
  className: '',
  onClick: null,
};

AlertBanner.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  button: PropTypes.element,
  textStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default AlertBanner;
