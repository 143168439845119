import React from 'react';
import Card from 'core-components/Card';
import styled from 'styled-components';
import { Chevron } from 'components/Icons';
import { JuniClubType } from 'generated/graphql';
import { juniBlue } from 'theme/old-colors';

// Image size in REM
const IMG_SIZE = 6;
const IMG_WH = `${IMG_SIZE}rem`;
const IMG_TOP_MARGIN = `-${IMG_SIZE / 2}rem`;

const TopPadding = styled.div`
  min-height: 5rem;
  background-color: white;
  border-bottom: 2px solid #e2f1f9;
`;

const RoundClubThumbnail = styled.img.attrs({
  className: 'block m-auto rounded-full',
})`
  height: ${IMG_WH};
  width: ${IMG_WH};
  margin-top: ${IMG_TOP_MARGIN};
`;

const HeaderUnderThumbnail = styled.h2`
  margin-top: 0;
  padding-top: 1rem;
`;
interface ClubInvitationCardProps {
  juniClub: JuniClubType;
  inviterName: string;
  handleJoinClick: () => void;
}

const ClubInvitationCard: React.FC<ClubInvitationCardProps> = ({
  juniClub,
  inviterName,
  handleJoinClick,
}) => (
  <Card
    backgroundColor="eggshell-blue"
    borderWidth="2"
    boxShadow
    padding="0"
    spaceContent={false}
    className="flex-1 w-full box-border"
    borderColor="juni-blue-100"
  >
    <TopPadding />
    <div className="flex flex-col justify-center text-center p-6 pt-0">
      <RoundClubThumbnail
        alt=""
        src={juniClub.coverPhoto?.replace('_full', '_thumbnail') || ''}
      />
      <div className="flex flex-row items-center justify-center">
        <HeaderUnderThumbnail>{`${juniClub.displayName} Invitation`}</HeaderUnderThumbnail>
      </div>
      <div>{`${inviterName} has invited you to join ${juniClub.displayName}`}</div>
      <div className="flex flex-row justify-center items-center pt-8">
        <a
          className="font-bold text-center text-juni-blue-500 no-underline cursor-pointer mr-2"
          onClick={handleJoinClick}
        >
          Join Club
        </a>
        <div className="flex items-center">
          <Chevron orientation="right" heavy stroke={juniBlue[400]} />
        </div>
      </div>
    </div>
  </Card>
);

export default ClubInvitationCard;
