import { COURSE_FORMAT, SIGNUP_DATA_BY_SUBJECT } from 'constants/signup_sessions';

import explorerIcon from 'images/signup_sessions/icons/explorer.svg';
import builderIcon from 'images/signup_sessions/icons/builder.svg';
import innovatorIcon from 'images/signup_sessions/icons/innovator.svg';
import { SubjectName, SubjectSignupData, CourseBundleName } from '../types';

export const BUNDLE_IMAGE_MAP: {
  [key in CourseBundleName]: string;
} = {
  explorer: explorerIcon,
  builder: builderIcon,
  innovator: innovatorIcon,
};

export interface CourseBundlesMap {
  bundleName: CourseBundleName;
  basePrice: number;
  icon: string;
  subjects?: SubjectSignupData[];
  courseOfferingId?: string;
  weeklyClasses: number;
}

// if updating this, be sure to update getCourseFormatFromBundleName() to reflect any updates
const makeCourseBundlesMap = (
  bundleName: CourseBundleName,
  subjectNames?: SubjectName[],
  price?: number,
  courseOfferingId?: string,
): CourseBundlesMap =>
  ({
    explorer: {
      bundleName,
      basePrice: 140,
      subjects: subjectNames ? [SIGNUP_DATA_BY_SUBJECT[subjectNames[0]]] : undefined,
      icon: explorerIcon,
      weeklyClasses: 0.5,
    },
    builder: {
      bundleName,
      basePrice: 250,
      subjects: subjectNames ? [SIGNUP_DATA_BY_SUBJECT[subjectNames[0]]] : undefined,
      icon: builderIcon,
      weeklyClasses: 1,
    },
    innovator: {
      bundleName,
      basePrice: 450,
      subjects: subjectNames?.map(subject => SIGNUP_DATA_BY_SUBJECT[subject]),
      icon: innovatorIcon,
      weeklyClasses: 2,
    },
    [COURSE_FORMAT.onDemand]: {
      bundleName,
      basePrice: price ?? 29,
      courseOfferingId,
      subjects: subjectNames ? [SIGNUP_DATA_BY_SUBJECT[subjectNames[0]]] : undefined,
      icon: builderIcon,
      weeklyClasses: 0,
    },
    [COURSE_FORMAT.group]: {
      bundleName,
      basePrice: price ?? 29,
      courseOfferingId,
      subjects: subjectNames ? [SIGNUP_DATA_BY_SUBJECT[subjectNames[0]]] : undefined,
      icon: builderIcon,
      weeklyClasses: 0,
    },
  }[bundleName]);

export default makeCourseBundlesMap;
