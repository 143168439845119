import React from 'react';

import styles from './index.module.css';

export interface StatisticProps {
  /**
   * Number between 0 and 100, in the form of a string.
   */
  figure: string;
  /**
   * Label for the statistic.
   */
  label: string;
}

const Statistic: React.FC<StatisticProps> = ({ figure, label }) => (
  <div className={styles.statistic}>
    <div className={styles.figure}>{figure}</div>
    <div className={styles.label}>{label}</div>
  </div>
);

export default Statistic;
