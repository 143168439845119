import React, { useContext } from 'react';
import { TailwindSpace } from 'theme/types';

interface CardContext {
  padding: TailwindSpace | TailwindSpace[];
}

const CardContext = React.createContext<CardContext>({ padding: '6' });

export const useCardContext = () => useContext(CardContext);

export default CardContext;
