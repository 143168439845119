import React from 'react';
import classNames from 'classnames';
import {
  TailwindHeight,
  TailwindShadow,
  TailwindSpace,
  TailwindWidth,
  TailwindColor,
} from 'theme/types';
import { getPaddingStyle } from 'utils/tailwind/spacing';
import CardContext from './CardContext';
import Divider from './Divider';

type Statics = {
  Divider: typeof Divider;
};

export interface CardProps {
  /**
   * Padding for card, styled with Tailwind scale.
   * Default 6.
   */
  padding?: TailwindSpace | TailwindSpace[];
  /**
   * Optionally specify the width of the Card.
   */
  width?: TailwindWidth;
  /**
   * Optionally specify the height of the Card.
   */
  height?: TailwindHeight | number;
  /**
   * Optionally specify the shadow of the Card.
   */
  shadow?: TailwindShadow;
  /**
   * Allows you to specify which borders should not be rounded
   */
  squareBorder?: 'bottom' | 'top' | 'left' | 'right' | 'all';
  /**
   * Optionally specify the background color of the Card.
   */
  backgroundColor?: TailwindColor;
  /**
   * Tailwind classes that are appended to the styled card.
   */
  className?: string;
}

const Card: React.FC<CardProps> & Statics = ({
  padding = '6',
  width,
  height,
  shadow,
  squareBorder,
  backgroundColor,
  children,
  className = '',
}) => {
  const heightInPixels = Number.isFinite(height);
  const paddingStyle = getPaddingStyle(padding);
  return (
    <CardContext.Provider value={{ padding }}>
      <div
        className={classNames(
          {
            'rounded-lg': !squareBorder,
            'rounded-none': squareBorder === 'all',
            'rounded-t-lg rounded-b-none': squareBorder === 'bottom',
            'rounded-t-none rounded-b-lg': squareBorder === 'top',
            'rounded-l-lg rounded-r-none': squareBorder === 'right',
            'rounded-l-none rouned-r-lg': squareBorder === 'left',
          },
          `bg-${backgroundColor || 'white'}`,
          paddingStyle,
          `shadow${shadow ? `-${shadow}` : ''}`,
          {
            [`w-${width}`]: !!width,
            [`h-${height}`]: !heightInPixels && !!height,
          },
          className,
        )}
        style={{ height: heightInPixels ? height : undefined }}
      >
        {children}
      </div>
    </CardContext.Provider>
  );
};

Card.Divider = Divider;

export default Card;
