import { QueryResult } from '@apollo/client';
import { SignupSessionProps } from 'app/signup_session/types';
import classNames from 'classnames';
import SpinnerV2 from 'components/SpinnerV2';
import { ErrorableLoading } from 'components/ui';
import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';
import { Card, Message, NewButton as Button, RadioGroup } from 'core-components';
import {
  Parent,
  StripeCoupon,
  Student,
  GetCoursesQuery,
  useAddStudentCourseMutation,
  useCreateStripeSubscriptionMutation,
  useLoadDefaultPaymentInformationByParentIdQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
  CreateStripeSubscriptionInput,
  useGetCoursesQuery,
} from 'generated/graphql';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { getLoggedInParent } from 'services/learner/parent';
import { hasAsync, extractMetadataFromSubscription } from 'utils/stripe';
import _ from 'lodash';
import CouponField from './components/CouponField';
import DefaultPaymentMethodDisplay from './components/DefaultPaymentMethodDisplay';
import PriceDisplay from './components/PriceDisplay';

const OnDemandCheckout: FC<SignupSessionProps> = ({ history, location }) => {
  const { courses: courseSlug } = queryString.parse(location.search);

  const [coupon, setCoupon] = useState<StripeCoupon | undefined>(undefined);
  const [parent, setParent] = useState<Parent | undefined>();
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoadingParent, setIsLoadingParent] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [
    createSubscription,
    { loading: isAddingSubscription, error: subscriptionError },
  ] = useCreateStripeSubscriptionMutation();
  const [
    addCourse,
    { loading: isAddingCourse, error: courseError },
  ] = useAddStudentCourseMutation();

  useEffect(() => {
    const load = async () => {
      setIsLoadingParent(true);
      try {
        const data = await getLoggedInParent(true);
        setParent(data.parent);
        setStudents(data.students);
      } catch (error) {
        console.log(error);
      }
      setIsLoadingParent(false);
    };
    load();
  }, []);

  const studentCourses = _.uniq(
    students.reduce(
      (result, student: Student) => [
        ...result,
        ...(student.hasMultipleTracks ? student.tracks! : [student.track!]),
      ],
      [] as string[],
    ),
  ).filter(course => course);

  const selectedCoursesQuery: QueryResult<GetCoursesQuery> = useGetCoursesQuery({
    variables: {
      input: {
        isAcceptingEnrollment: true,
        slugs: [courseSlug as string],
      },
    },
    skip: !courseSlug,
  });
  const selectedCourse = selectedCoursesQuery?.data?.getCourses?.[0];

  const coursesQuery: QueryResult<GetCoursesQuery> = useGetCoursesQuery({
    variables: {
      input: {
        isAcceptingEnrollment: true,
        names: studentCourses,
      },
    },
    skip: students.length === 0 || isLoadingParent,
  });
  const courses = (coursesQuery?.data?.getCourses || []).concat(
    selectedCoursesQuery?.data?.getCourses || [],
  );

  const cardQuery = useLoadDefaultPaymentInformationByParentIdQuery({
    variables: { id: parent && parent._id },
    skip: !parent || !parent._id,
  });

  const card = cardQuery?.data?.defaultPaymentInformationByParentId;

  const subscriptionsQuery = useLoadStripeSubscriptionsByParentIdQuery({
    variables: { id: parent && parent._id },
    skip: !parent || !parent._id,
  });
  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];
  const studentsEnrolledInAsync = subscriptions.reduce(
    (result, subscription) =>
      subscription.status !== 'canceled' &&
      hasAsync(subscription) &&
      typeof subscription.metadata?.studentIds === 'string'
        ? [...result, subscription.metadata?.studentIds]
        : result,
    [] as string[],
  );

  const studentOptions = students
    .map((student: Student) => {
      const studentIsEnrolledInAsync = studentsEnrolledInAsync.includes(
        student._id.toString(),
      );
      return {
        value: student._id.toString(),
        label: `${student.firstName} ${student.lastName}${
          studentIsEnrolledInAsync ? ' (already enrolled)' : ''
        }`,
        disabled: studentIsEnrolledInAsync,
      };
    })
    .sort((a, b) => (a.disabled ? 1 : b.disabled ? -1 : 0));

  const isLoading =
    isLoadingParent || subscriptionsQuery.loading || cardQuery.loading;

  if (isLoading) {
    return <ErrorableLoading />;
  }

  const handleSubmit = async () => {
    if (isAddingCourse || isAddingSubscription || isLoading) return;

    const activeSubscription = subscriptions.find(
      subscription =>
        subscription.status !== 'canceled' &&
        subscription.metadata?.studentIds === selectedStudentId,
    );

    const student = students.find(
      student => student._id.toString() === selectedStudentId,
    ) as Student;
    const studentCurrentCourses = student.hasMultipleTracks
      ? student.tracks
      : [student.track];

    const createSubscriptionInput: CreateStripeSubscriptionInput = {
      subscriptionUpdateType: activeSubscription ? 'NEW_CLASS' : 'REENGAGE_CHURN',
      studentId: selectedStudentId,
      metadataNew: extractMetadataFromSubscription(
        activeSubscription,
        courses,
        studentCurrentCourses,
      ),
      addAsync: true,
    };

    if (coupon) {
      createSubscriptionInput.couponId = coupon.id;
    }

    try {
      await createSubscription({
        variables: {
          input: createSubscriptionInput,
        },
      });
      // note: the most likely cause for selectedCourse to be falsy is an invalid query parameter
      // in this case, we will still create an on demand subscription but skip the course addition step
      if (selectedCourse) {
        await addCourse({
          variables: {
            input: {
              studentId: selectedStudentId,
              course: selectedCourse?.name || '',
            },
          },
        });
      }
      history.push('/signup2/confirmation');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <Card
        borderWidth="0"
        className="w-full sm:max-w-screen-xs sm:w-3/5 sm:rounded-lg"
        noRounding
      >
        <div>
          <div className="flex flex-col padding-2">
            <>
              <p className="text-j-dark-400 text-md mt-0 mb-2">
                {ASYNC_PLAN_PRODUCT.displayName}
              </p>
              <div className="text-j-dark-600 text-sm mb-4">
                {ASYNC_PLAN_PRODUCT.description}
              </div>
            </>
            <div className="mb-4">
              <p className="text-j-dark-400 text-md mt-0 mb-2">Select a student</p>
              <RadioGroup
                name="student"
                orientation="vertical"
                options={studentOptions}
                onChange={selected => {
                  setSelectedStudentId(selected);
                }}
                selectedValue={selectedStudentId}
              />
            </div>
            <PriceDisplay
              basePriceInCents={ASYNC_PLAN_PRODUCT.price * 100}
              coupon={coupon}
              isBootcampSignup={false}
            />
            <CouponField setCoupon={setCoupon} isBootcampSignup={false} />
            <DefaultPaymentMethodDisplay card={card} parent={parent} />
            <div className="flex items-center">
              <Button
                variant="primary"
                className={classNames('w-full')}
                type="submit"
                intent="success"
                disabled={isLoading || !selectedStudentId || !card}
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                {isAddingSubscription || isAddingCourse ? (
                  <div className="h-5 flex items-center">
                    <SpinnerV2 />
                  </div>
                ) : (
                  'Subscribe'
                )}
              </Button>
            </div>
          </div>
        </div>
        {subscriptionError ||
          (courseError && (
            <Message status="error">
              {`Error: ${subscriptionError || courseError} Please `}
              <a href="https://junilearning.com/contact/">contact Juni Support</a>
              {` if the error persists.`}
            </Message>
          ))}
      </Card>
    </div>
  );
};

export default OnDemandCheckout;
