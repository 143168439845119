// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as R from 'ramda';
import { StudentData } from '../types';

const studentIsReadyForOnboarding = (student?: Partial<StudentData>): boolean =>
  student
    ? R.pipe(
        R.map(
          selection =>
            selection.schedulingPreferences.filter(pref => pref.datetime).length ===
              Math.max(selection.weeklyClasses, 1) * 3 && !!selection.courseName,
        ),
        R.values,
        R.all(R.equals(true)),
      )(student?.bundle?.selections ?? {}) && student.birthdate
    : false;

export default studentIsReadyForOnboarding;
