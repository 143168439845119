import React, { FC } from 'react';

interface Props {
  modulesCompleted: number;
  totalModules: number;
  courseName: string;
}

const ProgressBar: FC<Props> = ({ modulesCompleted, totalModules, courseName }) => {
  const courseProgressPercentage = Math.round(
    (modulesCompleted / totalModules) * 100,
  );
  return (
    <div key={`course-progress-bar-${courseName}`}>
      <div className="flex flex-row justify-between pb-2">
        <div className="text-j-dark-600 text-base font-medium">{courseName}</div>
        <div className="text-j-dark-300 text-base text-right">
          {modulesCompleted}/{totalModules} Modules Completed
        </div>
      </div>
      <div className="bg-j-gray-200 rounded-lg h-14 relative">
        {courseProgressPercentage > 9 ? (
          <div
            className="flex items-center justify-end bg-j-green-400 rounded-lg h-full font-medium md:text-base text-sm text-white md:pr-4 pr-2"
            style={{ width: `${courseProgressPercentage}%` }}
          >
            {courseProgressPercentage}%
          </div>
        ) : (
          <div className="w-full h-full flex flex-row items-center">
            <div
              className="flex items-center justify-end bg-j-green-400 rounded-lg  h-full"
              style={{ width: `${courseProgressPercentage}%` }}
            />
            <span className="font-medium text-base text-j-dark-600 pl-2">
              {courseProgressPercentage}%
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
