import { AxiosResponse } from 'axios';
import { createContext } from 'react';
import { SessionFlags, SignupData, SubmitStepOptions } from '../../types';

const SignupSessionContext = createContext<{
  activeStudentId?: string;
  signupData: Partial<SignupData>;
  setSignupSession: (
    newData: Partial<SignupData>,
    options?: SubmitStepOptions,
  ) => Promise<AxiosResponse<any> | undefined>;
  fullStory?: typeof FS;
  flags: SessionFlags;
}>({
  signupData: {},
  setSignupSession: async () => undefined,
  flags: {
    isPrivateOneOnOne: true,
    isBootcampSignup: false,
    shouldSkipCourseFrequency: true,
    multiSubject: false,
    isOnDemandSignup: false,
    isInDcfExperimentTreatmentGroup: false,
  },
});

export default SignupSessionContext;
