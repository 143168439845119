import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

const Loading = ({ delayInMs = 500 }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShowSpinner(true), delayInMs);
    return () => clearTimeout(timeout);
  }, [delayInMs]);
  return showSpinner ? <Spinner /> : null;
};

Loading.propTypes = {
  delayInMs: PropTypes.number,
};

export default Loading;
