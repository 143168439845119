import classNames from 'classnames';
import { Icon } from 'core-components';
import React, { useEffect, useState } from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

interface LinkCopierProps {
  referralCode: string;
  variant: CopyVariant;
  width: string;
  onCopy?: () => void;
  skipLowerCase?: boolean;
}

type CopyVariant = 'light' | 'dark';

const sharedLayout = `flex-shrink-0 px-3 py-2 border border-solid rounded-lg font-semibold text-sm flex items-center justify-between cursor-pointer hover:shadow-1`;
const lightVariantStyle = `bg-transparent text-j-dark-600 border-j-dark-200`;
const lightVariantRightTextStyle = `text-sm text-j-dark-600 p-0 m-0 pr-2`;
const darkVariantStyle = `bg-transparent text-white border-j-purple-600`;
const darkVariantRightTextStyle = `text-sm text-j-purple-500 p-0 m-0 pr-2 `;
// TODO: make accessible. No tabbable behaviour.
const LinkCopier: React.FC<LinkCopierProps> = ({
  referralCode,
  variant,
  width,
  onCopy,
  skipLowerCase,
}) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (copied) {
      setTimeout(() => {
        if (mounted) setCopied(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [copied]);

  const text = skipLowerCase ? referralCode : referralCode.toLocaleLowerCase();
  return (
    <div className={width}>
      <CopyToClipboard
        text={text}
        onCopy={() => {
          setCopied(true);
          if (onCopy) onCopy();
        }}
      >
        <div
          className={classNames(
            variant === ('dark' as CopyVariant)
              ? darkVariantStyle
              : lightVariantStyle,
            sharedLayout,
          )}
        >
          <div className="truncate">{referralCode}</div>
          <div className="flex items-center cursor-pointer">
            <div
              className={
                variant === 'dark'
                  ? darkVariantRightTextStyle
                  : lightVariantRightTextStyle
              }
            >
              {copied ? (
                <div className="py-1 px-2 bg-j-green-400 flex items-center text-white rounded-md">
                  <Icon.Success className="h-3 mr-1" />
                  <div>Copied!</div>
                </div>
              ) : (
                <div className="py-1 px-2">Copy</div>
              )}
            </div>
            <Icon.Copy className="w-4 h-4 text-j-dark-300" />
          </div>
        </div>
      </CopyToClipboard>
    </div>
  );
};
export default LinkCopier;
