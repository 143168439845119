import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { format, utcToZonedTime } from 'date-fns-tz';

import { JuniClubType, JuniCommunityEventType } from 'generated/graphql';
import { Card, Button, ModalWindow } from 'core-components';
import { JuniClubMembershipInfoPlusBadgeType } from 'app/clubs/MyClubsTypes';
import { EventCardOptions } from 'components/clubs';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { getGoogleCalLink } from './utils/googleCalendar';

const DATE_FORMAT = 'EEE, MMM do';
const TIME_FORMAT = 'h:mm aaa (zzz)';

interface ClubEventCardProps {
  event: JuniCommunityEventType;
  className: string;
  currentClubState: JuniClubType;
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
  instructorUserIdParam?: string;
}

const ClubEventCard: FC<ClubEventCardProps> = ({
  event,
  className,
  currentClubState,
  currentClubMemberState,
  instructorUserIdParam,
}) => {
  const [showViewModal, setShowViewModal] = useState<boolean>(false);

  const { title, description, startDateTime, endDateTime, eventLink } = event;
  const userTimezone = guessTimezoneValue();

  const clubLink = window.location.href;

  const startDateLocal = utcToZonedTime(startDateTime, userTimezone);
  const startDate =
    startDateTime &&
    `${format(startDateLocal, DATE_FORMAT)} at ${format(
      startDateLocal,
      TIME_FORMAT,
    )}`;

  const endDateLocal = utcToZonedTime(endDateTime, userTimezone);
  const endDate =
    endDateTime &&
    `${format(endDateLocal, DATE_FORMAT)} at ${format(endDateLocal, TIME_FORMAT)}`;

  const baseColor = currentClubState?.colorTheme || '';
  const badgeName = currentClubMemberState?.badgeInfo?.name;
  const canUpdateEvents = !!(
    badgeName && ['advisor', 'moderator'].includes(badgeName)
  );
  const handleClose = () => setShowViewModal(false);

  return (
    <Card
      className={classNames(
        `flex bg-${baseColor}-light mr-6 mb-6 h-auto shadow-xl`,
        className,
      )}
      contentClassName="flex flex-col w-full"
      spaceContent={false}
      padding="0"
      borderWidth="0"
      boxShadow={false}
    >
      <ModalWindow
        isOpen={showViewModal}
        closeModal={handleClose}
        title={title || ''}
      >
        <div className="text-base p-2 text-blue-gray-600 font-normal">
          <div className="flex flex-col flex-grow overflow-y-auto whitespace-pre-wrap">
            {description}
          </div>
          <div className="pt-4 pb-2">
            <b>Start:</b> {startDate}
          </div>
          <div className="pb-2">
            <b>End:</b> {endDate}
          </div>
          {eventLink && (
            <div className="pb-2">
              <b>Event Link:</b>{' '}
              <a
                href={eventLink}
                target="_blank"
                rel="noreferrer"
                className="text-juni-blue-600"
              >
                {eventLink}
              </a>
            </div>
          )}
        </div>
      </ModalWindow>
      <div className="flex items-center justify-between bg-opacity-10 bg-white px-4 py-2.5">
        <h4 className="p-0 m-0 text-sm text-white font-semibold break-all">
          {title}
        </h4>
        {canUpdateEvents && (
          <EventCardOptions
            juniClubId={currentClubState._id}
            instructorUserId={instructorUserIdParam}
            juniCommunityEvent={event}
            juniClubCoverUrl={currentClubState?.coverPhoto || ''}
          />
        )}
      </div>
      <div className="p-4 text-sm">
        <div className="pb-2">
          <b>Start:</b> {startDate}
        </div>
        <div className="pb-2">
          <b>End:</b> {endDate}
        </div>
        {eventLink && (
          <div className="pb-2">
            <b>Event Link:</b>{' '}
            <a
              href={eventLink}
              target="_blank"
              rel="noreferrer"
              className="text-white break-all"
            >
              {eventLink}
            </a>
          </div>
        )}
        <div className="flex flex-row items-center pb-4">
          <b className="pr-1">Add To:</b>
          <a
            href={getGoogleCalLink(
              title,
              startDateLocal,
              endDateLocal,
              description,
              eventLink || clubLink,
            )}
            target="_blank"
            rel="noreferrer"
            className="text-white underline flex items-center"
          >
            Google Calendar
          </a>
        </div>

        <Button
          variant="transparent"
          hasArrowIcon
          className="w-full"
          onClick={() => setShowViewModal(true)}
        >
          View Details
        </Button>
      </div>
    </Card>
  );
};

export default ClubEventCard;
