import React from 'react';
import BadgeContainer from './BadgeContainer';

const Complete: React.FC = () => (
  <BadgeContainer>
    <svg
      style={{
        background: '#EBFFFA',
        borderRadius: '50%',
      }}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2708 16.7001C32.2708 25.5366 25.1073 32.7001 16.2708 32.7001C7.4342 32.7001 0.270752 25.5366 0.270752 16.7001C0.270752 7.86352 7.4342 0.700073 16.2708 0.700073C25.1073 0.700073 32.2708 7.86352 32.2708 16.7001ZM16.2708 30.7001C24.0027 30.7001 30.2708 24.4321 30.2708 16.7001C30.2708 8.96809 24.0027 2.70007 16.2708 2.70007C8.53877 2.70007 2.27075 8.96809 2.27075 16.7001C2.27075 24.4321 8.53877 30.7001 16.2708 30.7001Z"
        fill="#79F0D3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79715 14.5824L7.72949 16.6501L13.9325 22.8531L24.2708 12.5148L22.2031 10.4471L13.9325 18.7177L9.79715 14.5824Z"
        fill="#79F0D3"
      />
    </svg>
  </BadgeContainer>
);

export default Complete;
