import styled from 'styled-components/macro';

const StyledJideEnvEnglish = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: stretch;
  overflow-y: hidden;
  background-color: white;
  border-top: 2px solid hsl(198, 100%, 93%);
`;
const StyledLeftPanel = styled.div<any>`
  flex: 2;
  display: flex;
  flex-direction: row;
  position: relative;
  background: ${props => (props.isWritingProject ? 'hsl(207, 100%, 96%)' : '#fff')};
  padding: ${props => (props.isWritingProject ? '0 1rem 0 0' : '0')};
  ${props => (props.isWritingProject ? 'justify-content: center;' : '')}
`;
const StyledRightPanel = styled.div`
  flex: 1;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-left: 2px solid hsl(198, 100%, 93%);
  background: hsl(197, 100%, 99%);
`;

// the ratio should be 60/40 between the main content and right side content,
// up to 1440px, at which point the right side content maxes out at 576px.
const StyledMainContent = styled.div.attrs({
  className: 'flex',
})`
  flex: 6;
`;
const StyledSideContent = styled.div`
  flex: 4;
  max-width: 576px;
`;

export {
  StyledJideEnvEnglish,
  StyledLeftPanel,
  StyledRightPanel,
  StyledMainContent,
  StyledSideContent,
};
