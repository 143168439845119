import { Caret } from 'components/Icons';
import React, { FC } from 'react';

interface Props {
  sortBy: string;
  sortDirection: string;
  headerName: string;
}

const DashHeaderCaret: FC<Props> = ({ sortBy, sortDirection, headerName }) =>
  sortBy.startsWith(headerName) ? (
    <Caret
      style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
      orientation={sortDirection === 'asc' ? '' : 'flipped'}
      blue
    />
  ) : (
    <Caret style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }} />
  );

export default DashHeaderCaret;
