export const getStudentNamesFromStudents = students => {
  if (students.length === 0) {
    return '';
  }
  if (students.length === 1) {
    return `${students[0].firstName}`;
  }
  if (students.length === 2) {
    return `${students[1].firstName} and ${students[0].firstName}`;
  }
  return `${students
    .slice(1)
    .map(student => student.firstName)
    .join(', ')}, and ${students[0].firstName}`;
};

export const getStudentsFromSubscription = (subscription, students) => {
  const studentIdMetadata = subscription.metadata?.studentIds;
  if (studentIdMetadata && students.length > 0) {
    const studentIds = studentIdMetadata.split(',');
    return students.filter(student => studentIds.includes(student._id));
  }
  return [];
};

export const getCourseOptionByCourse = (allCourseOptions, selectedCourse) =>
  allCourseOptions.find(
    courseOption =>
      courseOption.options.find(({ value }) => value === selectedCourse) !==
      undefined,
  );
