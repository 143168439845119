import wbGridImg from 'images/wb_grid.svg';
import wbGridDeleteBtnImg from 'images/wb_grid_delete_button.svg';

const GRID_DELETE_BTN_RADIUS = 8;
const GRID_DELETE_BTN_MARGIN = 8;

// If somebody goes offline and then draws a grid, this will make sure the image is already downloaded
// There may be a more canonical solution with React, such as a wrapper that says "don't lazy load this"
const preloadedImages = [];
function preload(images) {
  images.forEach(url => {
    const img = new Image();
    img.src = url;
    preloadedImages.push(img);
  });
}
class GridTool {
  constructor(board) {
    this.board = board;
    this.activeGridId = '';
    this.lastSendTime = 0;
    this.activeGridElem = null;
    this.name = 'grid';
    this.eventHandlers = {
      mousedown: this.beginDrawingGrid,
      mousemove: this.drawGrid,
      mouseup: this.finishDrawingGrid,
      mouseleave: this.finishDrawingGrid,
      touchstart: this.beginDrawingGrid,
      touchmove: this.drawGrid,
      touchleave: this.finishDrawingGrid,
      touchend: this.finishDrawingGrid,
      touchcancel: this.finishDrawingGrid,
    };
    this.firstTouchCoordinates = null;

    preload([wbGridImg, wbGridDeleteBtnImg]);
  }

  handleWsMsg = data => {
    if (data.type === 'grid') {
      const gridToUpdate = this.board.canvas.getElementById(data.id);
      if (gridToUpdate) {
        this.updateGrid(gridToUpdate, data.x, data.y, data.width, data.height, true);
      } else {
        this.newSVGGrid(data.id, data.x, data.y, data.width, data.height, true);
      }
    }
  };

  beginDrawingGrid = (e, x, y) => {
    e.preventDefault();
    this.activeGridId = this.board.generateUniqueID('grid');
    this.firstTouchCoordinates = { x, y };
  };

  drawGrid = (e, x, y) => {
    const msSinceLastSend = performance.now() - this.lastSendTime;
    if (msSinceLastSend > 60 && this.activeGridId) {
      let grid = null;
      if (this.activeGridElem && this.activeGridId === this.activeGridElem.id) {
        grid = this.activeGridElem;
      } else {
        grid = this.board.canvas.getElementById(this.activeGridId);
      }
      const deltaX = x - this.firstTouchCoordinates.x;
      const deltaY = y - this.firstTouchCoordinates.y;
      const size = Math.min(Math.abs(deltaX), Math.abs(deltaY));
      const gridX =
        deltaX < 0
          ? this.firstTouchCoordinates.x - size
          : this.firstTouchCoordinates.x;
      const gridY =
        deltaY < 0
          ? this.firstTouchCoordinates.y - size
          : this.firstTouchCoordinates.y;
      this.lastSendTime = performance.now();
      if (!grid) {
        if (size > 4) {
          this.newSVGGrid(this.activeGridId, gridX, gridY, size, size);
        }
      } else {
        this.updateGrid(grid, gridX, gridY, size, size);
      }
    }
  };

  finishDrawingGrid = (e, x, y) => {
    this.drawGrid(e, x, y);
    if (this.activeGridId) {
      this.board.appendToEditHistory({
        type: 'add',
        elem: this.board.canvas.getElementById(this.activeGridId),
      });
    }
    this.activeGridId = '';
    this.firstTouchCoordinates = null;
  };

  newSVGGrid = (id, x, y, width, height, noBroadcast) => {
    const deleteGridButton = this.board.newSVGChild('image', {
      id: `delete-${id}`,
      class: 'wb-grid-delete-btn',
      width: GRID_DELETE_BTN_RADIUS * 2,
      height: GRID_DELETE_BTN_RADIUS * 2,
      x: x + GRID_DELETE_BTN_MARGIN,
      y: y + GRID_DELETE_BTN_MARGIN,
      href: wbGridDeleteBtnImg,
      title: 'Delete grid',
      onClick: () => {
        const gridToDelete = this.board.canvas.getElementById(id);
        if (gridToDelete) {
          this.board.canvas.removeChild(gridToDelete);
          const thisButton = this.board.canvas.getElementById(`delete-${id}`);
          this.board.canvas.removeChild(thisButton);
          this.board.broadcast({
            type: 'remove',
            id,
            tool: 'eraser',
          });
          this.board.appendToEditHistory({
            type: 'remove',
            elem: gridToDelete,
          });
        }
      },
    });

    const grid = this.board.newSVGChild('image', {
      id,
      width,
      height,
      x,
      y,
      class: 'wb-grid',
      href: wbGridImg,
    });

    this.board.appendChild(grid);
    this.board.canvas.appendChild(deleteGridButton);

    if (!noBroadcast) {
      this.board.broadcast({
        type: 'grid',
        id,
        x,
        y,
        width,
        height,
        tool: 'grid',
      });
    }
    return grid;
  };

  updateGrid = (grid, x, y, width, height, noBroadcast) => {
    if (!grid) return;
    const deleteBtn = this.board.canvas.getElementById(`delete-${grid.id}`);
    if (deleteBtn) {
      deleteBtn.setAttribute('x', x + GRID_DELETE_BTN_MARGIN);
      deleteBtn.setAttribute('y', y + GRID_DELETE_BTN_MARGIN);
    }
    grid.setAttribute('width', width);
    grid.setAttribute('height', height);
    grid.setAttribute('x', x);
    grid.setAttribute('y', y);

    if (!noBroadcast) {
      this.board.broadcast({
        type: 'grid',
        id: grid.id,
        x,
        y,
        width,
        height,
        tool: 'grid',
      });
    }
  };
}

export default GridTool;
