import React from 'react';
import { Link } from 'react-router-dom';

import { Student } from 'models/student';
import {
  Comments,
  Gear,
  Calendar,
  Map,
  Lifebuoy,
  FileText,
} from 'core-components/Icon';
import { NewCard as Card } from 'core-components';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

interface Props {
  student: Student;
}

const KeyLinks: React.FC<Props> = ({ student }) => {
  const { firstName, _id: id } = student;

  const links = [
    {
      title: 'My Schedule',
      path: `/calendar`,
      body: `View ${firstName}'s upcoming sessions, reschedule a session, or redeem a makeup session`,
      icon: Calendar,
    },
    {
      title: 'My Account',
      path: `/account`,
      body: (
        <ul className="m-0 pl-4">
          <li>
            Add a course, increase session frequency, and manage {firstName}'s
            subscription
          </li>
          <li>Update your payment method and view past payments</li>
        </ul>
      ),
      icon: Gear,
    },
    {
      title: 'Roadmap',
      path: `/roadmap`,
      body: `View ${firstName}'s progress and upcoming courses`,
      icon: Map,
    },
    {
      title: 'Session Notes & Session Feedback',
      path: `/session_notes`,
      body: `Review session notes and share feedback directly with ${firstName}'s instructor`,
      icon: FileText,
    },
    {
      title: 'Help Center',
      path: `/help`,
      body:
        'Get answers to all questions Juni - courses, extracurriculars, policies, and more',
      icon: Lifebuoy,
    },
    {
      title: 'Contact Us',
      path: `/help/contact`,
      body: 'Reach out to us and a member of the team will be happy to help',
      icon: Comments,
    },
  ];

  return (
    <Card shadow="1">
      <div className="flex flex-col gap-4 text-j-dark-600">
        <div className="font-medium">Key Links</div>
        {links.map(link => {
          const Icon = link.icon;
          return (
            <div
              key={link.title}
              className="p-4 rounded-lg border-j-dark-100 border border-solid cursor-pointer"
            >
              <Link
                to={`/learner/${id}${link.path}`}
                className="no-underline"
                onClick={() => {
                  JuniAnalytics.track('keylinks_clicked', {
                    label: link.title,
                    destinationPath: link.path,
                  });
                }}
              >
                <div className="flex gap-3 items-start text-j-dark-600">
                  <div>
                    <div className="w-5 h-5 p-1.5 flex items-center justify-center rounded-lg bg-j-gray-200">
                      <Icon className="w-full h-full" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-medium">{link.title}</div>
                    <div className="mt-1 text-xs text-j-dark-400">{link.body}</div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default KeyLinks;
