import React from 'react';
import classNames from 'classnames';
import { Props as SelectProps } from 'react-select';

import './index.css';

interface TextAreaProps {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  name?: SelectProps['name'];
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: SelectProps['onBlur'];
  rest?: any;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, placeholder, name, value, onChange, onBlur, ...rest }, ref) => (
    <textarea
      className={classNames('ui-text-area', className)}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
      ref={ref}
    />
  ),
);

export default TextArea;
