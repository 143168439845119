import juniAxios from 'services/axios';

export async function getStudentsByIds(studentIds) {
  return (await juniAxios.get(`/instructor/students/${studentIds.join(',')}`)).data
    .data;
}

export async function getStudentById(studentId) {
  return (await juniAxios.get(`/instructor/students/${studentId}`)).data.data[0];
}

export async function getStudentIdByParentEmail(parentEmail) {
  return (await juniAxios.get(`/api_teacher/student_by_parent_email/${parentEmail}`))
    .data;
}
