import React from 'react';
import styled from 'styled-components/macro';

const LabelContainer = styled.div`
  flex: 1;
  display: flex;
  & .title-with-border {
    color: #92b9d9;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    text-shadow: 0px 0px 0px #abceeb;
    flex: 1;
    &:after {
      content: '';
      flex: 1 1;
      border-top: 1.25px solid #d6eafc;
      margin: auto auto auto 1.5rem;
    }
  }
`;

const SectionLabel: React.FC<{
  children: React.ReactChild;
  id?: string;
  className?: string;
}> = ({ children, id, className }) => (
  <LabelContainer className={className} id={id}>
    <span className="title-with-border">{children}</span>
  </LabelContainer>
);

export default SectionLabel;
