import React, { FC } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment-timezone';
import {
  StripeCharge,
  StripeCustomer,
  useLoadMyUnpaidStripeChargesQuery,
  useLoadUnpaidStripeChargesByStripeCustomerIdQuery,
} from 'generated/graphql';
import useDimensions from 'hooks/dimensions';
import { Arrow } from 'components/Icons';
import { CalendarButtonStyle } from 'app/learner/MyCalendar/styles';

export const PaymentButton = styled.button`
  ${CalendarButtonStyle}
  line-height: normal;
  background-color: #88191a;
  color: #ffffff;
  border: none;
  width: auto;
`;

export const PaymentButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaymentButtonTextBase = styled.div`
  color: #ffffff;
  line-height: normal;
  font-family: Open Sans;
  text-transform: capitalize;
  font-weight: bold;
`;

export const PaymentButtonText = styled(PaymentButtonTextBase)`
  font-size: 15px;
`;

const UpdatePaymentBanner = styled.div`
  width: 100%;
  background: #da2727;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const UpdatePaymentContent = styled.div<any>`
  color: white;
  font-weight: 600;
  font-size: ${props => (props.smallMobileView ? '12px' : '14px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${props =>
    props.largeDesktopView ? '90%' : props.desktopView ? '80%' : '100%'};
`;

const UpdatePaymentText = styled.div`
  margin-left: 15px;
`;

const UpdatePaymentButtonWrapper = styled.div<any>`
  padding: ${props => (props.smallMobileView ? '5px 10px' : ' 10px 20px')};
  min-width: 150px;
`;

interface PortalBillingAlert {
  impersonationMode?: boolean;
  stripeCustomer: StripeCustomer | undefined;
}

interface PortalBillingAlertImpersonation {
  stripeCustomerId: string;
}

interface PortalBillingAlertBanner {
  unpaidStripeCharges: StripeCharge[] | undefined;
}

const PortalBillingAlert: FC<PortalBillingAlert> = ({
  impersonationMode,
  stripeCustomer,
}) => {
  if (!stripeCustomer) {
    return null;
  }
  return impersonationMode ? (
    <PortalBillingAlertImpersonation stripeCustomerId={stripeCustomer.id} />
  ) : (
    <PortalBillingAlertCustomer />
  );
};

const PortalBillingAlertImpersonation: FC<PortalBillingAlertImpersonation> = ({
  stripeCustomerId,
}) => {
  const { data } = useLoadUnpaidStripeChargesByStripeCustomerIdQuery({
    variables: { input: stripeCustomerId },
  });
  return (
    <PortalBillingAlertBanner
      unpaidStripeCharges={data?.unpaidStripeChargesByStripeCustomerId.items}
    />
  );
};

const PortalBillingAlertCustomer: FC = () => {
  const { data } = useLoadMyUnpaidStripeChargesQuery();
  return (
    <PortalBillingAlertBanner
      unpaidStripeCharges={data?.myUnpaidStripeCharges.items}
    />
  );
};

const PortalBillingAlertBanner: FC<PortalBillingAlertBanner> = ({
  unpaidStripeCharges,
}) => {
  const { width } = useDimensions();
  const mobileView = width <= 920;
  const smallMobileView = width <= 550;
  const largeDesktopView = width > 1100;

  if (!unpaidStripeCharges || unpaidStripeCharges.length === 0) {
    return null;
  }

  const [charge] = unpaidStripeCharges;
  const { brand, last4 } = charge.payment_method_details.card || {};
  const paymentDate = moment.unix(charge.created).format('MM/DD');
  const bannerContent = `Your last payment on ${paymentDate}${
    brand && last4
      ? ` with ${brand.charAt(0).toUpperCase() + brand.slice(1)} ending in ${last4}`
      : ''
  } was unsuccessful. Please update your payment information! Changes may take 1-2 days to take effect.`;

  return (
    <UpdatePaymentBanner>
      <UpdatePaymentContent
        desktopView={!mobileView}
        largeDesktopView={largeDesktopView}
        smallMobileView={smallMobileView}
      >
        <UpdatePaymentText>{bannerContent}</UpdatePaymentText>
        <UpdatePaymentButtonWrapper smallMobileView={smallMobileView}>
          <PaymentButton onClick={() => window.open('account', '_blank')}>
            <PaymentButtonTextWrapper>
              <PaymentButtonText>Update Payment Info</PaymentButtonText>
            </PaymentButtonTextWrapper>
            <Arrow
              orientation="right"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                fill: '#FFFFFF',
              }}
            />
          </PaymentButton>
        </UpdatePaymentButtonWrapper>
      </UpdatePaymentContent>
    </UpdatePaymentBanner>
  );
};

export default PortalBillingAlert;
