import React from 'react';
import bgImage from './random_code.svg';

export const JuniverseNotFound = () => (
  <div
    className="bg-cover flex justify-center items-center h-full"
    style={{ backgroundImage: `url('${bgImage}')` }}
  >
    <h2
      className="text-3xl text-white font-normal text-center"
      style={{ maxWidth: '300px' }}
    >
      Sorry, we couldn't find this project!
    </h2>
  </div>
);
