import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import UserContext from 'modules/UserContext';
import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';

import InputField from 'app/signup_session/components/InputField';
import useGuestAccountLookup from 'app/signup_session/hooks/useGuestAccountLookup';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import findStudentById from 'app/signup_session/lib/findStudentById';
import updateBundleDataByStudent from 'app/signup_session/lib/updateBundleDataByStudent';
import navStates from 'app/signup_session/navigation/states';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import { SignupSessionProps, SubjectName, UserType } from 'app/signup_session/types';
import { Arrow } from 'components/Icons';
import {
  Card,
  Select,
  SelectOption,
  NewButton as Button,
  RadioGroup,
  TextArea,
} from 'core-components';
import { Option } from 'core-components/Input';
import { IconRenderProps } from 'core-components/NewButton/Button';

import {
  COMPUTER_SCIENCE,
  MATH,
  INVESTING,
  ENGLISH,
  SCIENCE,
} from 'constants/signup_sessions';
import { useGetCourseByNameQuery } from 'generated/graphql';
import classNames from 'classnames';
import { getStudentBundleSelections } from 'app/signup_session/lib/getStudentBundleSelections';

interface LocalStudentState {
  name: string;
  learningPreferences: string;
  selectedSubject: SubjectName;
  selectedCourse: string;
}

const radioOptions: { [topic: string]: Option<string>[] } = {
  [MATH]: [
    { label: 'Elementary School', value: 'math_foundations_a' },
    { label: 'Middle School', value: 'pre_algebra_a_v2' },
    { label: 'High School', value: 'geometry_a' },
  ],
  [ENGLISH]: [
    { label: 'Elementary School', value: 'english_early_elementary_a' },
    { label: 'Middle School', value: 'english_core_middle_school_a' },
    { label: 'Public Speaking', value: 'public_speaking' },
  ],
  [INVESTING]: [
    { label: 'Intro to the stock market', value: 'investing_stock_market' },
    { label: 'Intro to personal finance', value: 'personal_finance' },
    { label: 'Entrepreneurship 101', value: 'entrepreneurship' },
  ],
  [COMPUTER_SCIENCE]: [{ label: 'Scratch Level 1', value: 'scratch_level_1' }],
  // TODO maybe add starter options for science later
  [SCIENCE]: [],
};

const subjectOptions: SelectOption<string>[] = [
  { value: COMPUTER_SCIENCE, label: 'Coding' },
  { value: ENGLISH, label: 'Storytelling' },
  { value: INVESTING, label: INVESTING },
  { value: MATH, label: MATH },
];

const getLabelForSubject: (subject: string) => string = (subject: string) =>
  subjectOptions.find(option => option.value === subject)?.label ??
  'your selected topic';

const StarterCourse: FC<SignupSessionProps> = ({ history, location }) => {
  const { user } = useContext(UserContext);
  const { signupData, setSignupSession, flags } = useSignupContext();
  const { guestAccount } = useGuestAccountLookup(signupData.invitationLookupId);
  const { getNextPage, hasNextPage } = useNavRouter();
  const isFromCourseExplorer =
    signupData.coursePlacement?.method === 'course-explorer';

  const signupService = makeSignupSessionsService(signupData._id);
  const activeStudent = signupData.students?.[0];
  const bundleSelections = getStudentBundleSelections(activeStudent?.bundle);
  const queryParams = queryString.parse(location.search);
  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const addToTouchedFields = (keyName: string) => {
    if (!touchedFields.includes(keyName)) {
      setTouchedFields([...touchedFields, keyName]);
    }
  };

  const { data, loading, error } = useGetCourseByNameQuery({
    variables: {
      courseName: bundleSelections[0].courseName || '',
    },
  });

  const [localStudentState, setLocalStudentState] = useState<LocalStudentState>({
    name:
      activeStudent?.firstName && activeStudent?.lastName
        ? `${activeStudent?.firstName} ${activeStudent?.lastName}`
        : user?.isGuest && user?.firstName && user?.lastName
        ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
        : '',
    learningPreferences: activeStudent?.learningStyle ?? '',
    // because we use signupData as the source of truth here, manually changing the subject in the query string doesn't
    // work on this page as the query string is modified to match the selectedSubject by the useEffect below, and the
    // selectedSubject is the subject stored in the signupData
    selectedSubject: (Object.keys(activeStudent?.bundle?.selections ?? {})[0] ??
      COMPUTER_SCIENCE) as SubjectName,
    selectedCourse: '',
  });

  const onLocalStudentChange = (newData: Partial<typeof localStudentState>) =>
    setLocalStudentState({ ...localStudentState, ...newData });

  useEffect(() => {
    // this page should only be accessed by users where shouldSkipCourseFrequency === true,
    // this redirects users where shouldSkipCourseFrequency === false to /student-info
    if (
      hasNextPage(navStates.signup.starterCourse, ROUTE_EVENT.LOAD, {
        signupData,
        search: location.search,
        shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
      })
    ) {
      history.replace(
        getNextPage(navStates.signup.starterCourse, ROUTE_EVENT.LOAD, {
          signupData,
          search: location.search,
          shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
        }),
      );
    }
  }, [
    getNextPage,
    hasNextPage,
    history,
    location.search,
    flags.shouldSkipCourseFrequency,
    signupData,
  ]);

  useEffect(() => {
    if (
      !localStudentState.name &&
      guestAccount?.firstName &&
      guestAccount?.lastName
    ) {
      setLocalStudentState({
        ...localStudentState,
        name: `${guestAccount.firstName} ${guestAccount.lastName}`,
      });
    }
  }, [guestAccount?.firstName, guestAccount?.lastName, localStudentState]);

  useEffect(() => {
    if (
      !localStudentState.selectedSubject ||
      queryParams.subjects === localStudentState.selectedSubject ||
      !flags.shouldSkipCourseFrequency ||
      isFromCourseExplorer
    ) {
      return;
    }
    queryParams.subjects = localStudentState.selectedSubject;
    if (!queryParams.weeklyClasses) {
      // if weeklyClasses is not in queryString, use the value in signupData, if that
      // doesn't exist, default to 1. Users with multiple subject selections can't access this page
      const existingWeeklyClasses = bundleSelections[0]?.weeklyClasses;
      queryParams.weeklyClasses = existingWeeklyClasses
        ? existingWeeklyClasses.toString()
        : '1';
    }
    history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
  }, [
    history,
    location.pathname,
    location.search,
    queryParams,
    localStudentState.selectedSubject,
    flags.shouldSkipCourseFrequency,
    isFromCourseExplorer,
    bundleSelections,
  ]);

  useEffect(() => {
    // if the selected course is in the selected subject, return
    if (
      radioOptions[localStudentState.selectedSubject].filter(
        option => option.value === localStudentState.selectedCourse,
      ).length > 0 ||
      isFromCourseExplorer
    ) {
      return;
    }
    const selectedCourse = radioOptions[localStudentState.selectedSubject][0].value;

    if (localStudentState.selectedSubject === COMPUTER_SCIENCE) {
      // if the course is computer science, we select the course by age, choosing a default course here
      setLocalStudentState({
        ...localStudentState,
        selectedCourse,
      });
    } else {
      // if the signupData has a selected course in the selected subject, use that
      // otherwise, select the default course for the selected subject
      setLocalStudentState({
        ...localStudentState,
        selectedCourse: Object.keys(
          activeStudent?.bundle?.selections ?? {},
        ).includes(localStudentState.selectedSubject)
          ? activeStudent?.bundle?.selections[localStudentState.selectedSubject]
              ?.courseName ?? selectedCourse
          : selectedCourse,
      });
    }
  }, [activeStudent?.bundle?.selections, isFromCourseExplorer, localStudentState]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!signupData.email) {
      console.error('no email present in signupData');
      return;
    }

    const fetchUserIfExists = async (): Promise<
      | {
          userType: UserType;
          invitationLookupId?: string;
          inviteeStudentId: string;
        }
      | undefined
    > => {
      if (!signupData.email) {
        return;
      }
      try {
        const existingUserResponse = await signupService.detectExistingUser(
          signupData.email,
        );

        if (!existingUserResponse?.data) {
          throw new Error('Missing response body');
        }

        const {
          userType,
          invitationLookupId,
          inviteeStudentId,
        } = existingUserResponse.data;

        return {
          userType,
          invitationLookupId,
          inviteeStudentId,
        };
      } catch (e) {
        console.error(e);
        alert(
          'We are having trouble connecting to our database. Please try again in a few moments.',
        );
      }
    };

    const existingUser = await fetchUserIfExists();

    // if we the user type is set, and the user is not logged in as a guest
    if (
      (!signupData.invitationLookupId &&
        existingUser?.userType !== undefined &&
        !user?.isGuest) ||
      (existingUser?.userType && existingUser?.userType !== 'guest') ||
      !localStudentState.selectedSubject
    ) {
      console.error('the user type is set but the user is not logged in as a guest');
      return;
    }

    const splitName = localStudentState.name.split(' ');
    const student = findStudentById(activeStudent?._id, signupData)!;
    const { bundle } = student;
    // update the course name based on selection
    const updatedSubject = updateBundleDataByStudent(
      activeStudent?._id,
      localStudentState.selectedSubject,
      {
        ...(bundle?.selections?.[localStudentState.selectedSubject] ?? {}),
        courseName: localStudentState.selectedCourse,
      },
      signupData,
    );

    setSignupSession({
      students: updatedSubject.students?.map(student =>
        student._id === activeStudent?._id
          ? {
              ...student,
              bundle: { ...student.bundle!, isCourseNameProvided: true },
              _id: existingUser?.inviteeStudentId ?? student._id,
              firstName: splitName[0].trim(),
              lastName: splitName.slice(1).join(' ').trim(),
              learningStyle: localStudentState.learningPreferences,
            }
          : student,
      ),
    });

    history.push(
      getNextPage(navStates.signup.starterCourse, ROUTE_EVENT.SUBMIT, {
        signupData,
        search: location.search,
        shouldSkipCourseFrequency: flags.shouldSkipCourseFrequency,
      }),
    );
  };

  const labelTextStyles = 'text-j-dark-600 font-medium text-sm';

  return (
    <div className="flex flex-col-reverse justify-center items-center">
      <Card
        borderWidth="0"
        className="w-full max-w-screen-xs sm:w-3/5 sm:rounded-lg"
        noRounding
      >
        <form noValidate onSubmit={onSubmit}>
          {isFromCourseExplorer ? (
            <>
              <h1 className="text-j-dark-600 text-xl font-medium my-0">
                {loading || error
                  ? 'Confirm your starter course'
                  : `${data?.getCourse?.displayName}${
                      !flags.isOnDemandSignup ? ' (1:1 Private)' : ''
                    }`}
              </h1>
              <p className={classNames(labelTextStyles, 'mt-0')}>
                {flags.isOnDemandSignup ? 'FIRST WEEK FREE' : 'FIRST SESSION FREE'}
              </p>
            </>
          ) : (
            <>
              <h1 className="text-j-dark-600 text-xl font-medium mt-0">
                {flags.isInDcfExperimentTreatmentGroup && flags.isPrivateOneOnOne
                  ? 'Confirm your first free class'
                  : 'Confirm your starter course'}
              </h1>
              <p className="text-j-dark-400">
                You can add additional topics after the first session.
              </p>

              <div className="flex flex-col space-y-2">
                <label className={labelTextStyles}>Topic</label>
                <Select
                  options={subjectOptions}
                  selected={localStudentState.selectedSubject}
                  onChange={item =>
                    onLocalStudentChange({ selectedSubject: item as SubjectName })
                  }
                  dropdownPosition="bottom-left"
                  placeholder="Select topic"
                  fullWidth
                  size="small"
                />
                {localStudentState.selectedSubject &&
                  localStudentState.selectedSubject !== COMPUTER_SCIENCE && (
                    <RadioGroup
                      name="course-selection"
                      options={radioOptions[localStudentState.selectedSubject]}
                      onChange={selected =>
                        onLocalStudentChange({ selectedCourse: selected })
                      }
                      selectedValue={
                        radioOptions[localStudentState.selectedSubject].filter(
                          option =>
                            option.value === localStudentState.selectedCourse,
                        )[0]?.value
                      }
                      orientation="vertical"
                    />
                  )}
              </div>
              <p className="text-j-dark-400">
                {`The free session will be an exciting introduction to ${getLabelForSubject(
                  localStudentState.selectedSubject,
                )},
                tailored to your child's learning style and level.`}
              </p>
            </>
          )}
          <InputField
            labelTextStyles={labelTextStyles}
            inputClassName="fs-mask"
            value={localStudentState.name ?? ''}
            id="student-name"
            label="Student Full Name"
            onBlur={() => addToTouchedFields('student-name')}
            onChange={e => onLocalStudentChange({ name: e.target.value })}
            validationError={
              touchedFields.includes('student-name') && !localStudentState.name
                ? 'This field is required'
                : undefined
            }
          />
          <div className="flex flex-col space-y-2">
            <label className={labelTextStyles}>
              {flags.isInDcfExperimentTreatmentGroup && flags.isPrivateOneOnOne
                ? '(Optional) Have a specific course or skill in mind for your learner? Let us know:'
                : 'Other Preferences (Optional)'}
            </label>
            <TextArea
              fullWidth
              style={{ resize: 'vertical' }}
              placeholder={
                isFromCourseExplorer
                  ? 'Tell us a bit about your child and their learning style.'
                  : 'Request a specific course, or tell us your child’s learning style.'
              }
              value={localStudentState.learningPreferences ?? ''}
              onChange={e =>
                onLocalStudentChange({ learningPreferences: e.target.value })
              }
            />
          </div>
          <div className="w-full flex flex-row space-x-4 pt-4">
            <Button
              className="h-12 w-1/3"
              variant="secondary"
              onClick={() =>
                history.push(
                  getNextPage(navStates.signup.starterCourse, ROUTE_EVENT.BACK, {
                    signupData,
                    search: location.search,
                  }),
                )
              }
              renderIconLeft={(props: IconRenderProps) => (
                <Arrow {...props} orientation="left" />
              )}
            >
              Back
            </Button>
            <Button
              className="h-12 w-2/3"
              type="submit"
              disabled={
                !localStudentState.name ||
                ((!localStudentState.selectedCourse ||
                  !localStudentState.selectedSubject) &&
                  !isFromCourseExplorer)
              }
            >
              Continue
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default StarterCourse;
