import React, { FC } from 'react';

import styled from 'styled-components/macro';
import { Layout } from 'components/ui';

const CustomUILayout = styled(Layout)`
  h1 {
    font-size: 1.8rem;
  }

  & > *:not(:first-child) {
    margin-top: 1.5rem;
  }

  & > :nth-child(2) {
    margin-top: 1rem;
  }

  .ui-tab-button {
    font-size: var(--juni-body-size, 12px);

    &:not([class*='Button_active']) {
      color: var(--juni-body);
      background: #ffffff9c;
      [class*='Button_icon'] {
        background: rgb(131 184 214 / 15%);
        svg * {
          fill: #93b6db;
        }
      }
    }
  }
`;

interface PortalComponentLayoutProps {
  title: string;
}

const PortalComponentLayout: FC<PortalComponentLayoutProps> = ({
  children,
  title,
}) => (
  <CustomUILayout title={title} contentClass="ui-content ui-vertical-spacing">
    {children}
  </CustomUILayout>
);

export default PortalComponentLayout;
