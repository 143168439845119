import { TWO_FREE_SESSIONS } from 'constants/signup_sessions';
import { BundleData, CampaignCode } from '../types';
import sumWeeklyClasses from './sumWeeklyClasses';

/*
  Why would we skip the course frequency page?
  - If the user has a plan with a weeklyClasses quantity of <= 1
  - If the user has a plan with weeklyClasses quantity of more than one, but only selected one subject (therefore we should give them { weeklyClasses: 2 } in that subject) 

  Course frequency is assumed in these cases
*/

const shouldSkipCourseFrequencyPage = (
  bundleSelections: BundleData['selections'],
  campaign: CampaignCode | undefined,
) => {
  if (campaign === TWO_FREE_SESSIONS) {
    return true;
  }
  const weeklyClasses = sumWeeklyClasses(bundleSelections);
  return (
    weeklyClasses <= 1 ||
    (weeklyClasses > 1 && Object.keys(bundleSelections).length === 1)
  );
};

export default shouldSkipCourseFrequencyPage;
