import { RecordProjectViewDocument } from 'generated/graphql';
import juniAxios from 'services/axios';
import apolloClient from './apollo';

// PUBLIC
export const studentProjectLookupPublic = async studentProjectId =>
  juniAxios
    .get(`/juniverse/public/student_project_details`, {
      params: { studentProjectId },
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const registerUserAction = async ({
  action,
  studentProjectId,
  juniverseUsername,
}) =>
  juniAxios
    .post(`/juniverse/public/user_actions`, {
      action,
      studentProjectId,
      juniverseUsername,
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

// LEARNER
// TODO: there should be a separate utility method for instructors
export const studentProjectLookup = async ({
  instructorUserId,
  studentId,
  courseId,
  moduleId,
  projectId,
  playgroundProjectType,
}) =>
  juniAxios
    .get(`/juniverse/learner/student_project_details`, {
      params: {
        instructorUserId,
        studentId,
        courseId,
        moduleId,
        projectId,
        playgroundProjectType,
      },
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const checkUsernameAvailable = async (
  desiredUsername,
  userType = 'student',
) =>
  juniAxios
    .get(`/juniverse/learner/check_username_available`, {
      params: { desiredUsername, userType },
    })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const setUsername = async (userId, desiredUsername, userType = 'student') =>
  juniAxios
    .post(`/juniverse/learner/set_username`, { userId, desiredUsername, userType })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const upsertStudentProject = async updateParams =>
  juniAxios
    .post('/juniverse/learner/upsert_student_project', updateParams)
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const recordProjectView = async projectId => {
  const result = await apolloClient.mutate({
    mutation: RecordProjectViewDocument,
    variables: { projectId },
  });
  return result.data?.recordProjectView?.wasRecorded || false;
};

// ADMIN
export const getStats = async () =>
  juniAxios
    .get('/juniverse/admin/get_stats')
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));

export const juniverseSearch = async searchQuery =>
  juniAxios
    .get('/juniverse/admin/search', { params: { searchQuery } })
    .then(res => res.data)
    .catch(err => ({ error: err, success: false }));
