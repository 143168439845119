import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { Props as SelectProps } from 'react-select';
import TextArea from '../TextArea';

interface TextAreaFieldProps {
  className?: string;
  textAreaClassName?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  field?: Record<string, any>;
  form?: Record<string, any>;
  placeholder?: string;
  name?: string;
  onChange?: (value: string, name: string) => void;
  onBlur?: SelectProps['onBlur'];
  value?: string;
  error?: React.ReactNode;
}

const TextAreaField: FC<TextAreaFieldProps> = ({
  className,
  textAreaClassName,
  field,
  form,
  disabled,
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  const {
    name: fieldName,
    value: fieldValue,
    onChange: fieldChange,
    onBlur: fieldBlur,
  } = field || {};

  const handleChange = useCallback(
    e => {
      fieldChange?.(e);
      onChange?.(e.target.value, e.target.name);
    },
    [fieldChange, onChange],
  );

  const handleBlur = useCallback(
    e => {
      fieldBlur?.(e);
      onBlur?.(e.target.name);
    },
    [fieldBlur, onBlur],
  );

  const meta = form && form.getFieldMeta(fieldName);
  const errorText = meta ? meta.touched && meta.error : error;

  return (
    <label className={classNames('ui-text-area-field', 'ui-field', className)}>
      {label && <div className="ui-field-label">{label}</div>}
      <TextArea
        className={textAreaClassName}
        disabled={disabled}
        placeholder={placeholder}
        name={field ? fieldName : name}
        value={field ? fieldValue : value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errorText && <div className="ui-field-error">{errorText}</div>}
    </label>
  );
};

export default TextAreaField;
