import React, { useState } from 'react';
import { Button, Message } from 'core-components';
import { useStaticS3BucketUrlsQuery } from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import { parseError } from 'utils/errors';
import { ViewModalProps } from './ClubCreator';
import { ForcedWidth, ImgWrapper, ButtonRow } from './styles';

// The GraphQL query returns URLS that look like this:
// https://s3-us-west-1.amazonaws.com/clubs.junilearning.com/coverImages/[name]_[thumbnail|full].[jpg|png],

const S3_BUCKET = 'clubs.junilearning.com';
const FOLDER = 'coverImages';

const URL_REG_EXP = new RegExp(
  `${S3_BUCKET}\\/${FOLDER}\\/(.+)_(full|thumbnail)\\.(jpg|png)`,
);

const processUrls = (rawUrls: string[]) => {
  const lookup = rawUrls.reduce(
    (accumulator, url) => {
      const matches = url.match(URL_REG_EXP);
      if (!matches) {
        return accumulator;
      }
      const name = matches[1];
      const type = matches[2] as 'full' | 'thumbnail';
      return { ...accumulator, [type]: { ...accumulator[type], [name]: url } };
    },
    { full: {} as Record<string, string>, thumbnail: {} as Record<string, string> },
  );
  return Object.keys(lookup.full).reduce((accumulator, name) => {
    const fullImageUrl = lookup.full[name];
    const thumbnailUrl = lookup.thumbnail[name];
    if (!fullImageUrl || !thumbnailUrl) {
      return accumulator;
    }
    return [...accumulator, { fullImageUrl, thumbnailUrl }];
  }, [] as Array<{ fullImageUrl: string; thumbnailUrl: string }>);
};
interface CoverSelectProps extends ViewModalProps {
  setSrcImg: React.Dispatch<React.SetStateAction<string>>;
  setSubmissionSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  /* Submit club is either an updateMutation or createMutation, depending on mode. */
  submitClub: () => Promise<any>;
  handleClose: () => void;
}
export const CoverSelect: React.FC<CoverSelectProps> = ({
  setSrcImg,
  submitClub,
  handleClose,
  changeViewState,
  setSubmissionSuccess,
  visible,
  juniClub,
}) => {
  const { data: urls, error } = useStaticS3BucketUrlsQuery({
    variables: { s3Bucket: S3_BUCKET, folder: FOLDER },
  });
  const processedUrls = processUrls(urls?.staticS3BucketUrls || []);
  const showErrorMessage = !!(error || processedUrls.length === 0);
  const [hasSelectedCover, setHasSelectedCover] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const SUBMIT_BUTTON_TEXT = juniClub ? 'Save Club' : 'Create Club';

  const handleSubmit = async () => {
    if (submitting) {
      return;
    }
    if (!juniClub) {
      /* create mode */
      if (!hasSelectedCover) {
        setSubmissionError('Please select a cover photo.');
        return;
      }
      setSubmitting(true);
      try {
        await submitClub();
        changeViewState('next');
        setSubmissionSuccess(true);
      } catch (e) {
        setSubmissionError(parseError(e, `Sorry, that didn't work.`));
      } finally {
        setSubmitting(false);
      }
    } else {
      /* update mode */
      setSubmitting(true);
      try {
        await submitClub();
        setSubmitting(false);
        handleClose();
      } catch (e) {
        setSubmissionError(parseError(e, `Sorry, that didn't work.`));
        setSubmitting(false);
      }
    }
  };
  return (
    <div style={{ display: !visible ? 'none' : '' }}>
      <ForcedWidth>
        <div className="flex flex-wrap justify-center">
          {processedUrls.map((item, idx) => {
            const cypress_identifier = `image-header-${idx}`;
            const { thumbnailUrl, fullImageUrl } = item;
            return (
              /* TODO: ACCESSIBILITY: make selectable when enter is hit */
              <ImgWrapper
                tabIndex="0"
                onClick={() => {
                  setSrcImg(fullImageUrl);
                  setHasSelectedCover(true);
                  setSubmissionError('');
                }}
                src={thumbnailUrl}
                key={fullImageUrl}
                data-cy={cypress_identifier}
              />
            );
          })}
        </div>
        {showErrorMessage && (
          <Message status="error">Could not load cover images.</Message>
        )}
      </ForcedWidth>
      {submissionError && <Message status="error">{submissionError}</Message>}
      <ButtonRow>
        <Button
          onClick={() => {
            changeViewState('back');
            setSubmissionError('');
          }}
          disabled={submitting}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          disabled={submitting}
          data-cy="club-submit"
        >
          {submitting ? <SpinnerV2 /> : SUBMIT_BUTTON_TEXT}
        </Button>
      </ButtonRow>
    </div>
  );
};
