import { FC } from 'react';
import classNames from 'classnames';

const InfoBlock: FC<{ variant?: 'light' | 'dark' }> = ({
  children,
  variant = 'dark',
}) => (
  <div
    className={classNames('rounded-lg', 'p-4', 'my-4', 'text-sm', {
      'bg-white text-juni-purple-500': variant === 'light',
      'bg-juni-purple-100 bg-opacity-50 text-juni-dark-900': variant === 'dark',
    })}
  >
    {children}
  </div>
);

export default InfoBlock;
