import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { ExpandableCard, NewCard } from 'core-components';

const CardRow: React.FC<{ hideTopBorder?: boolean }> = ({
  hideTopBorder,
  children,
}) => (
  <div
    className={classNames(
      'flex',
      'items-center',
      'justify-between',
      'py-3',
      'px-6',
      'border-0',
      { 'border-t': !hideTopBorder },
      'border-solid',
      'border-j-purple-100',
    )}
  >
    {children}
  </div>
);

type Statics = {
  Row: typeof CardRow;
};

interface CardProps extends React.ComponentProps<typeof NewCard> {
  headerText?: string;
  headerElement?: ReactElement;
  headerLabel?: string;
  defaultExpanded: boolean;
  footerElement?: ReactElement;
}

const Card: React.FC<CardProps> & Statics = ({
  headerText,
  headerElement,
  headerLabel,
  defaultExpanded,
  children,
  ...cardProps
}) => (
  <ExpandableCard
    padding="0"
    headerPadding={['4', '6']}
    defaultExpanded={defaultExpanded}
    headerLabel={headerLabel}
    renderHeaderContent={() => (
      <div
        className={classNames(
          'text-j-dark-600',
          'font-graphik',
          'font-medium',
          'text-xl',
          'leading-relaxed',
        )}
      >
        {headerText || headerElement}
      </div>
    )}
    {...cardProps}
  >
    <div className="text-j-dark-600 font-graphik">{children}</div>
  </ExpandableCard>
);

Card.Row = CardRow;

export default Card;
