import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { format, utcToZonedTime } from 'date-fns-tz';
import styled from 'styled-components/macro';

import { JuniCommunityEventType, JuniClubType } from 'generated/graphql';
import { Card, Button } from 'core-components';
import useClubStore, { ClubStoreType } from 'app/clubs/stores/ClubStore';
import { getClubLink } from 'app/clubs/helpers';
import guessTimezoneValue from 'utils/guessTimezoneValue';

const DATE_FORMAT = 'EEE, MMM do';
const TIME_FORMAT = 'h:mm aaa (zzz)';

const CardRowWrapper = styled.div.attrs({ className: 'p-4' })`
  &:not(:last-child) {
    border-bottom: 1.25px solid #cee6f3;
  }
`;

const DateTextWrapper = styled.div.attrs({
  className: 'text-sm my-4 text-blue-gray-600 font-semibold',
})``;

interface MyClubsEventsCalendarCardProps {
  communityEvents: JuniCommunityEventType[];
  handleJoinClick: (juniClub: JuniClubType) => void;
  studentId?: string;
}
interface MyClubsEventsCalendarCardRowProps {
  communityEvent: JuniCommunityEventType;
  handleJoinClick: (juniClub: JuniClubType) => void;
  studentId?: string;
}

const MyClubsEventsCalendarCardRow: FC<MyClubsEventsCalendarCardRowProps> = ({
  communityEvent,
  handleJoinClick,
  studentId,
}) => {
  const { thumbnailUrl, startDateTime, endDateTime } = communityEvent;
  const userTimezone = guessTimezoneValue();
  // TODO: update imgUrl with proper fallback image
  const imgUrl =
    thumbnailUrl ||
    'https://s3-us-west-1.amazonaws.com/clubs.junilearning.com/coverImages/amongus_thumbnail.jpg';
  const startDateLocal = utcToZonedTime(startDateTime, userTimezone);
  const startDate =
    startDateTime &&
    `${format(startDateLocal, DATE_FORMAT)} at ${format(
      startDateLocal,
      TIME_FORMAT,
    )}`;

  const endDateLocal = utcToZonedTime(endDateTime, userTimezone);
  const endDate =
    endDateTime &&
    `${format(endDateLocal, DATE_FORMAT)} at ${format(endDateLocal, TIME_FORMAT)}`;

  const userClubs = useClubStore((state: ClubStoreType) => state.juniClubs);
  // For v1: juniClubs will contain 1 club
  const clubHost = communityEvent?.juniClubs?.[0];
  const isMemberOfEventClubHost = communityEvent?.juniClubIds
    ?.map(clubId => userClubs[clubId])
    .filter(club => club)?.length;
  const clubNames = communityEvent?.juniClubs?.map(club => club.displayName) || [];
  // TODO: add meeting link once it's added from the backend
  return (
    <CardRowWrapper>
      <div className="flex">
        <img src={imgUrl} alt="" className="w-11 h-12 rounded-md" />
        <div className="ml-3 flex flex-col justify-center">
          <div className="font-bold" style={{ color: '#335875' }}>
            {communityEvent.title}
          </div>
          <div className="text-sm mt-0.5 text-blue-gray-600 font-semibold">
            {_.flatten(clubNames)}
          </div>
        </div>
        {!!isMemberOfEventClubHost && (
          <div className="ml-auto bg-indigo-400 py-2 px-4 text-xs font-bold text-white self-start rounded-3xl">
            You're a member of this club
          </div>
        )}
      </div>
      <div className="text-sm my-4 text-blue-gray-600 font-semibold">
        {communityEvent.description}
      </div>
      {communityEvent?.startDateTime && (
        <div>
          <DateTextWrapper>
            <b>Start Time: </b>
            {startDate}
          </DateTextWrapper>
          <DateTextWrapper>
            <b>End Time: </b>
            {endDate}
          </DateTextWrapper>
        </div>
      )}

      {!isMemberOfEventClubHost && clubHost ? (
        <Button
          variant="secondary"
          hasArrowIcon
          className="w-full"
          onClick={() => handleJoinClick(clubHost)}
        >
          Join Club to Attend
        </Button>
      ) : (
        <Link to={getClubLink({ studentId, juniClubId: clubHost?._id })}>
          <Button variant="secondary" width="full" hasArrowIcon>
            Go To Club
          </Button>
        </Link>
      )}
    </CardRowWrapper>
  );
};

const MyClubsEventsCalendarCard: FC<MyClubsEventsCalendarCardProps> = ({
  communityEvents,
  handleJoinClick,
  studentId,
}) => {
  const sortedCommunityEvents = _.sortBy(
    communityEvents,
    obj => obj && obj.startDateTime && new Date(obj.startDateTime),
  );

  return (
    <Card padding="0">
      {sortedCommunityEvents.map(event => (
        <MyClubsEventsCalendarCardRow
          communityEvent={event}
          key={event._id}
          handleJoinClick={handleJoinClick}
          studentId={studentId}
        />
      ))}
    </Card>
  );
};

export default MyClubsEventsCalendarCard;
