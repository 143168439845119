import styled from 'styled-components/macro';

export const FloatingCircle = styled.div<any>`
  position: absolute;
  border-radius: 50%;
  top: ${props => props.top};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  width: ${props => props.size};
  height: ${props => props.size};
`;

/* 
Get circle anchor points: 
takes VW positions corresponding to small and large breakpoints,
returns either the smallest or largest anchor or the current range.
*/
export const getCircleAnchorPoint = ({
  width,
  smallVwAnchor,
  largeVwAnchor,
}: {
  width: number;
  smallVwAnchor: number;
  largeVwAnchor: number;
}) => {
  const lowerBoundPx = 350;
  const upperBoundPx = 850;
  const inRange = width > lowerBoundPx && width < upperBoundPx;
  const rangePx = upperBoundPx - lowerBoundPx;
  const rangeVw = largeVwAnchor - smallVwAnchor;
  const computedAnchor =
    ((width - lowerBoundPx) / rangePx) * rangeVw + smallVwAnchor;
  if (inRange) {
    return computedAnchor;
  }
  if (width < lowerBoundPx) {
    return smallVwAnchor;
  }
  return largeVwAnchor;
};
