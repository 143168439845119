import React, { FC } from 'react';
import { JuniLogo } from 'components/brand-assets';
import calendlyLogo from 'images/signup_sessions/icons/calendly.svg';
import { CALENDLY_LINKS } from 'constants/signup_sessions';
import { Icon, NewButton } from 'core-components';

const BookCallWidget: FC<{
  callIsBooked: boolean;
  onCalendlyLinkClick: () => void;
}> = ({ callIsBooked, onCalendlyLinkClick }) => (
  <div>
    <div className="flex items-center pb-4">
      <div className="bg-j-purple-700 rounded-xl w-16 h-16 flex justify-center items-center">
        <JuniLogo width="12" color="light" />
      </div>
      <h3 className="m-0 font-medium text-j-dark-600 text-base leading-6 pl-4">
        Juni Advisor
      </h3>
    </div>
    <h2 className="m-0 font-medium text-2xl text-j-dark-600 pb-2">
      Book your course placement call
    </h2>
    <p className="text-j-dark-400 text-base leading-6 m-0">
      Book a call with a Juni Advisor for personalized course matching. After
      booking, return to this page to finish setting up your classes. Calendly will
      email you a link to manage your call.
    </p>
    <div className="text-j-dark-600 flex pt-4 pb-6">
      <Icon.Time width="16" />
      <p className="text-sm leading-6 m-0 font-medium pl-1">30 min</p>
    </div>
    <a
      target="_blank"
      rel="noreferrer"
      className="no-underline"
      href={CALENDLY_LINKS.PETeam}
    >
      <NewButton
        disabled={callIsBooked}
        onClick={onCalendlyLinkClick}
        intent="success"
        renderIconLeft={() => <img src={calendlyLogo} alt="calendly" />}
      >
        <span className="pl-2">Book my Calendly call</span>
      </NewButton>
    </a>
  </div>
);

export default BookCallWidget;
