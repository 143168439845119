import React, { FC, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { Icon, NewButton as Button } from 'core-components';
import Dropdown from 'core-components/Dropdown';
import { useLocation, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import useNavStore, { NavStoreType } from 'app/miscellaneous/stores/NavStore';
import { iconSize, dropdownStyle } from './styles';

const getPathProps = (children: React.ReactNode): string[] =>
  _.castArray(children)
    .filter(
      c => typeof c === 'object' && c !== null && 'props' in c && !!c.props.path,
    )
    .map(c => typeof c === 'object' && c !== null && 'props' in c && c.props.path)
    .filter(c => !!c);

interface NavLinkOrAnchorProps extends React.HTMLAttributes<HTMLAnchorElement> {
  to?: string;
  newTab?: boolean | null;
}
const NavLinkOrAnchor: FC<NavLinkOrAnchorProps> = ({
  children,
  to,
  newTab,
  ...rest
}) => {
  const isExternalLink = to && to.length && to.slice(0, 4) === 'http';
  if (to && !isExternalLink) {
    return (
      <NavLink
        {...rest}
        to={to}
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noopener noreferrer' : undefined}
      >
        {children}
      </NavLink>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={isExternalLink ? to : undefined}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : undefined}
      {...rest}
    >
      {children}
    </a>
  );
};

// Nav Bar Option Component
// If path is specified, it will be a button that routes you there on click
// Otherwise if given children, it will automatically be a dropdown
// It will automatically mark itself as active (isOnPath=true) if the current pathname
//     matches the path prop or one of the children's path props
interface NavOptionProps {
  icon: React.ReactNode;
  iconActive?: React.ReactNode;
  title: string;
  path?: string;
  newTab?: boolean | null; // open path in new tab
  isUserDropdown?: boolean | null; // special css if it's the right side user dropdown
  closeHamburgerMenu?: () => void; // Automatically injected by parent
}
export const NavOption: FC<NavOptionProps> = ({
  children,
  icon,
  iconActive,
  title,
  path,
  newTab,
  isUserDropdown,
  closeHamburgerMenu = () => undefined,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { pathname } = useLocation();
  const navIsMobile = useNavStore(state => state.navIsMobile);
  const navMobileHamburgerExpanded = useNavStore(
    state => state.navMobileHamburgerExpanded,
  );
  const optionRef = useRef(null);

  const isDropdown = !!children;

  // I know this looks janky AF but basically all it's doing is getting all path props
  // from children. All this ridiculousness is to appease the linter
  const childrenPaths = getPathProps(children);

  // Consider it onPath if current pathname matches given path or
  // one of the children's paths
  const isOnPath = path
    ? pathname.includes(path)
    : isDropdown
    ? childrenPaths.some(p => pathname.includes(p))
    : false;
  const textColor = isOnPath || isUserDropdown ? 'text-white' : 'text-j-purple-300';
  const bgColor = isDropdown && isExpanded ? 'bg-j-purple-700' : '';

  const closeDropdown = () => {
    if (isDropdown) {
      if (!isUserDropdown) {
        setIsExpanded(false);
      } else {
        if (navIsMobile && navMobileHamburgerExpanded) return;
        setIsExpanded(false);
      }
    }
  };
  const toggleDropdown = () => {
    if (isDropdown) {
      if (!isUserDropdown) {
        setIsExpanded(!isExpanded);
      } else {
        if (navIsMobile && navMobileHamburgerExpanded) return;
        setIsExpanded(!isExpanded);
      }
    }
  };

  // Inject the closeDropdown method into children so they can close dropdown
  //     when clicked
  const injectedChildren = React.Children.map(children, child =>
    React.cloneElement(child as React.ReactElement<any>, {
      closeDropdown,
      closeHamburgerMenu,
      isUserDropdown,
    }),
  );

  const dropdownComponent =
    navIsMobile && !isUserDropdown ? (
      <div className="ml-3">{injectedChildren}</div>
    ) : (
      <Dropdown
        onClose={closeDropdown}
        style={{ ...dropdownStyle(navIsMobile, isUserDropdown) }}
        additionalRefs={[optionRef]}
      >
        {injectedChildren}
      </Dropdown>
    );

  useEffect(() => {
    if (navIsMobile && isOnPath && !isUserDropdown) {
      setIsExpanded(true);
    }
  }, [navIsMobile, isOnPath, isUserDropdown]);

  return (
    <div
      className={classNames('flex relative my-1 flex-col sm:my-0 sm:flex-row', {
        'mr-3': !isUserDropdown,
        'mr-0': isUserDropdown,
      })}
    >
      <NavLinkOrAnchor
        to={path}
        newTab={newTab}
        className={classNames(
          'flex px-2 py-2 rounded-md items-center no-underline cursor-pointer',
          textColor,
          bgColor,
        )}
        onClick={() => {
          if (isDropdown) {
            toggleDropdown();
          } else {
            closeHamburgerMenu();
          }
        }}
      >
        <div className="flex items-center" ref={optionRef}>
          <div className="flex flex-shrink-0 items-center mr-2">
            {isOnPath ? iconActive || icon : icon}
          </div>
          <div className="text-base mr-2 flex sm:hidden lg:flex">{title}</div>
          {isDropdown && <Icon.CaretDown height={7} width={7} />}
        </div>
      </NavLinkOrAnchor>

      {isDropdown && isExpanded && dropdownComponent}
    </div>
  );
};

// Dropdown Item Component
// Should be added as children to the NavOption component above in order to create
//     a dropdown list
interface NavDropdownItemProps {
  icon: React.ReactNode;
  supertitle?: string;
  title: string;
  subtitle?: string;
  path?: string; // If specified, will use react-router to follow the link
  isSelected?: boolean | null; // Show blue background to indicate that it's selected
  showCheckForSelected?: boolean | null; // Show checkmark on the right if selected
  onClick?: () => void;
  closeDropdown?: () => void; // Automatically injected by parent
  closeHamburgerMenu?: () => void;
  isUserDropdown?: boolean;
  newTab?: boolean | null; // open path in new tab
  isSpecial?: boolean | null; // looks *special*
}
export const NavDropdownItem: FC<NavDropdownItemProps> = ({
  icon,
  supertitle,
  title,
  subtitle,
  path,
  isSelected,
  showCheckForSelected,
  onClick = () => undefined,
  closeDropdown = () => undefined,
  closeHamburgerMenu = () => undefined,
  isUserDropdown,
  newTab,
  isSpecial,
}) => {
  const { pathname } = useLocation();
  const isOnPath = path && pathname.includes(path);

  const textColorDefault = isSpecial
    ? 'text-j-purple-600 sm:text-white'
    : isOnPath || isSelected
    ? 'text-white sm:text-j-dark-600 font-semibold sm:font-medium'
    : 'text-j-purple-300 sm:text-j-dark-600';
  const bgColorDefault = isSpecial
    ? 'bg-white sm:bg-j-purple-400'
    : isOnPath || isSelected
    ? 'sm:bg-j-gray-300'
    : 'sm:hover:bg-j-gray-200';

  const textColorUserDropdown = 'text-j-dark-600';
  const bgColorUserDropdown =
    isOnPath || isSelected ? 'bg-j-gray-300' : 'hover:bg-j-gray-200';

  const textColor = isUserDropdown ? textColorUserDropdown : textColorDefault;
  const bgColor = isUserDropdown ? bgColorUserDropdown : bgColorDefault;

  return (
    <NavLinkOrAnchor
      to={path}
      newTab={newTab}
      onClick={() => {
        onClick();
        closeDropdown();
        closeHamburgerMenu();
      }}
      className={classNames(
        'flex no-underline rounded-md px-3 py-2 cursor-pointer',
        textColor,
        bgColor,
        { 'my-1': isSpecial },
      )}
    >
      <div
        className={classNames('flex flex-shrink-0', {
          'items-center': !supertitle,
        })}
      >
        {icon}
      </div>
      <div className="flex flex-1 flex-col ml-4 text-base">
        <div
          className={classNames('font-graphik text-xs leading-4', {
            'text-j-dark-300': !isSpecial,
            'text-j-dark-300 sm:text-j-purple-100': isSpecial,
          })}
        >
          {supertitle}
        </div>
        <div>{title}</div>
        <div
          className={classNames('font-graphik text-2xs leading-4 uppercase', {
            'text-j-dark-200 sm:text-j-dark-400': !isSpecial,
            'text-j-dark-300 sm:text-j-purple-100': isSpecial,
          })}
        >
          {subtitle}
        </div>
      </div>
      {isSelected && showCheckForSelected && (
        <div className="flex flex-shrink-0 items-center">
          <Icon.Check height={16} width={16} className="ml-auto" />
        </div>
      )}
    </NavLinkOrAnchor>
  );
};

// Dropdown Section Header Component
// Title will be converted to all caps
export const DropdownHeader: FC<{ title: string; isUserDropdown?: boolean }> = ({
  title,
  isUserDropdown,
}) => (
  <div
    className={classNames('font-graphik text-xs font-medium', {
      'text-j-purple-300 mt-2 sm:text-j-dark-600 sm:mt-0': !isUserDropdown,
      'text-j-dark-600 mb-1': isUserDropdown,
    })}
  >
    {title?.toUpperCase()}
  </div>
);

// Dropdown Separator Component
// Draws a thin line to separate dropdown sections
export const DropdownSeparator: FC = () => (
  <div className="border-0 border-b border-j-purple-500 sm:border-j-gray-500 border-solid my-4 mx-2"></div>
);

export const MobileHamburger: FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const set = useNavStore(state => state.set);

  const closeHamburgerMenu = () => {
    setIsExpanded(false);
    set((state: NavStoreType) => {
      state.navMobileHamburgerExpanded = false;
    });
  };
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    set((state: NavStoreType) => {
      state.navMobileHamburgerExpanded = !isExpanded;
    });
  };

  // Inject the closeHamburgerMenu method into children so they can close hamburger menu
  //     when clicked
  const injectedChildren = React.Children.map(children, child =>
    React.cloneElement(child as React.ReactElement<any>, {
      closeHamburgerMenu,
    }),
  );

  return (
    <>
      <div className="flex items-center cursor-pointer" onClick={toggleExpanded}>
        {isExpanded ? (
          <Icon.Times {...iconSize(20)} />
        ) : (
          <Icon.Hamburger {...iconSize(20)} />
        )}
      </div>
      {isExpanded && (
        <div
          className="flex flex-col flex-1 absolute box-border z-50 bg-j-purple-600 left-0 w-full px-6 pt-6 pb-14 border-solid border-0 border-j-purple-500 border-t overflow-y-auto"
          style={{ height: 'calc(100vh - 4.5rem)', top: '4.5rem' }}
        >
          {injectedChildren}
        </div>
      )}
    </>
  );
};

interface ReferButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  to: string;
  closeHamburgerMenu?: () => void; // Automatically injected by parent
}
export const ReferButton: FC<ReferButtonProps> = ({
  to,
  closeHamburgerMenu = () => undefined,
}) => (
  <Button href={to} onClick={closeHamburgerMenu} intent="success">
    Give $50
  </Button>
);
