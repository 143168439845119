import { createContext } from 'react';
import defaultRouter from 'app/signup_session/navigation/machines/defaultNavigation';
import { Router } from 'app/signup_session/navigation/types';

interface RouterContext {
  router: Router;
  multiSubjectSignup: boolean;
}

const NavRouterContext = createContext<RouterContext>({
  router: defaultRouter,
  multiSubjectSignup: false,
});

export default NavRouterContext;
