import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ErrorableLoading } from 'components/ui';

import { NewButton, NewCard } from 'core-components';

import { getSubscriptionDisplayName, hasAsync } from 'utils/stripe';
import { format, fromUnixTime, getUnixTime } from 'date-fns';
import { ASYNC_PLAN_PRODUCT, formatPrice } from 'constants/subscription_plans';

import { QueryResult } from '@apollo/client';
import {
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  StripeSubscription,
} from 'generated/graphql';
import {
  getStudentNamesFromStudents,
  getStudentsFromSubscription,
} from '../../utils';

interface SubscriptionCardProps {
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  resetForm: () => void;
  subscription: StripeSubscription;
}

const SubscriptionCard = ({
  subscription,
  studentsQuery,
  updateFormState,
  updateFormValue,
  resetForm,
}: SubscriptionCardProps) => {
  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const matchingStudents = getStudentsFromSubscription(subscription, students);
  const studentNames = getStudentNamesFromStudents(matchingStudents);
  const productName = getSubscriptionDisplayName(subscription);
  const hasAsyncProduct = hasAsync(subscription);

  const handleClick = () => {
    resetForm();
    const modalName = 'subscription_update';
    JuniAnalytics.track(`subscription_card_button_clicked`, {
      funnel: 'subscription_upgrade_downgrade',
      destinationModal: modalName,
    });

    updateFormState(modalName, 'modal');
    updateFormValue(subscription, 'subscription');
    if (matchingStudents.length === 1) {
      updateFormValue(matchingStudents[0], 'currentStudent');
    }
  };

  const baseCost = subscription.items.data.reduce(
    (total: number, subscriptionItem) => total + (subscriptionItem.plan.amount || 0),
    0,
  );
  let monthlyCost = baseCost;
  if (subscription.discount && subscription.discount.coupon) {
    const { coupon } = subscription.discount;
    monthlyCost =
      coupon.amount_off !== null
        ? baseCost - (coupon.amount_off || 0)
        : (1 - (coupon.percent_off || 0) / 100) * baseCost;
  }

  return (
    <NewCard
      padding="0"
      shadow="none"
      className="border border-solid border-j-dark-100"
    >
      <div className="flex flex-row text-base items-center px-6 py-4 justify-between">
        <div className="font-bold">{studentNames}</div>
        {subscription.status !== 'canceled' && (
          <div>
            <NewButton size="xsmall" variant="secondary" onClick={handleClick}>
              Manage
            </NewButton>
          </div>
        )}
      </div>
      <NewCard.Divider />
      <div className="py-4 px-6 flex flex-col gap-2">
        <div className="text-j-dark-300">Subscription</div>
        {productName && <div>{productName}</div>}
        {hasAsyncProduct && <div>{ASYNC_PLAN_PRODUCT.displayName}</div>}
      </div>
      <NewCard.Divider />
      <div className="py-4 px-6 flex flex-row w-full justify-start">
        <div className="flex flex-col w-1/2 gap-2">
          <div className="text-j-dark-300">
            {subscription.status !== 'canceled'
              ? 'Next Bill Date'
              : 'Expiration Date'}
          </div>
          <div>
            {format(fromUnixTime(subscription.current_period_end), 'MMM d, yyyy')}
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-2">
          <div className="text-j-dark-300">Monthly Price</div>
          <div>{`${formatPrice(monthlyCost / 100)}/month`}</div>
        </div>
      </div>
    </NewCard>
  );
};

interface SubscriptionsCardProps {
  updateFormState: (value: any, field: string) => void;
  updateFormValue: (value: any, field: string) => void;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  resetForm: () => void;
}

const SubscriptionsCard = ({
  subscriptionsQuery,
  studentsQuery,
  updateFormValue,
  updateFormState,
  resetForm,
}: SubscriptionsCardProps) => {
  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];
  const {
    loading: subscriptionsLoading,
    error: subscriptionsError,
  } = subscriptionsQuery;
  return (
    <>
      <NewCard padding="0">
        <div className="text-xl font-bold px-6 py-4">Subscriptions</div>
        <NewCard.Divider />
        {subscriptionsLoading || subscriptionsError ? (
          <ErrorableLoading error={subscriptionsError} />
        ) : (
          <div className="p-6 flex flex-col gap-4">
            {subscriptions
              .filter(
                subscription =>
                  subscription.status !== 'canceled' ||
                  subscription.current_period_end > getUnixTime(new Date()),
              )
              .map(subscription => (
                <SubscriptionCard
                  key={subscription.id}
                  subscription={subscription}
                  studentsQuery={studentsQuery}
                  updateFormValue={updateFormValue}
                  updateFormState={updateFormState}
                  resetForm={resetForm}
                />
              ))}
          </div>
        )}
      </NewCard>
    </>
  );
};

export default SubscriptionsCard;
