import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Chevron } from 'components/Icons';
import Wrapper from './Wrapper';

interface BannerProps {
  backLink?: string;
  title: string;
}

const Banner: React.FC<BannerProps> = ({ backLink, title, children }) => (
  <div className="bg-j-dark-600 overflow-hidden">
    <Wrapper>
      {backLink && (
        <div className="-ml-2 mt-7">
          <Link
            className={classNames(
              'text-white',
              'text-sm',
              'text-right',
              'font-graphik',
              'font-medium',
              'inline-flex',
              'items-center',
              'no-underline',
              'hover:underline',
            )}
            to={backLink}
          >
            <Chevron orientation="left" stroke="white" />
            Back to all courses
          </Link>
        </div>
      )}
      <div className="flex flex-row" style={{ minHeight: '240px' }}>
        <div
          className={classNames(
            'pt-2',
            'flex',
            'flex-col',
            'justify-center',
            'mr-auto',
            'text-white',
            'leading-normal',
          )}
        >
          <h1 className="font-semibold m-0">{title}</h1>
          {children}
        </div>
        {!backLink && (
          <div className="hidden sm:flex">
            <div
              className={classNames(
                'relative',
                'flex-shrink-0',
                'bg-j-purple-800',
                'rounded-full',
                '-bottom-14',
                '-right-32',
                'w-24',
                'h-24',
              )}
            />
            <div
              className={classNames(
                'relative',
                'flex-shrink-0',
                'bg-j-purple-700',
                'rounded-full',
                '-bottom-36',
                '-right-28',
                'w-56',
                'h-56',
              )}
            />
          </div>
        )}
      </div>
    </Wrapper>
  </div>
);

export default Banner;
