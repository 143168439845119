import React from 'react';
import BadgeContainer from './BadgeContainer';

const Camera: React.FC = () => (
  <BadgeContainer>
    <svg
      style={{
        background: '#FFF0F0',
        borderRadius: '50%',
      }}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.271 16.4001C32.271 25.2367 25.1076 32.4001 16.271 32.4001C7.43444 32.4001 0.270996 25.2367 0.270996 16.4001C0.270996 7.56359 7.43444 0.400146 16.271 0.400146C25.1076 0.400146 32.271 7.56359 32.271 16.4001ZM16.271 30.4001C24.003 30.4001 30.271 24.1321 30.271 16.4001C30.271 8.66816 24.003 2.40015 16.271 2.40015C8.53901 2.40015 2.271 8.66816 2.271 16.4001C2.271 24.1321 8.53901 30.4001 16.271 30.4001Z"
        fill="#FC7A7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1894 11.4409C23.6021 10.5045 25.771 11.3702 25.771 13.3114V19.4868C25.771 21.4337 23.5952 22.2935 22.1867 21.3555L20.3061 20.1027C20.1837 21.4939 18.9892 22.5157 17.6282 22.5157H10.4592C9.02232 22.5157 7.771 21.3767 7.771 19.8666V12.934C7.771 11.4239 9.02232 10.2849 10.4592 10.2849H17.6282C18.989 10.2849 20.1833 11.3064 20.3061 12.6971L22.1894 11.4409ZM20.2937 14.5085V18.2921L23.0182 20.107C23.5451 20.458 24.271 20.0998 24.271 19.4868V13.3114C24.271 12.7008 23.5476 12.3402 23.0182 12.6912L20.2937 14.5085ZM18.8165 12.934C18.8165 12.2993 18.2845 11.7849 17.6282 11.7849H10.4592C9.80296 11.7849 9.271 12.2993 9.271 12.934V19.8666C9.271 20.5013 9.80296 21.0157 10.4592 21.0157H17.6282C18.2845 21.0157 18.8165 20.5013 18.8165 19.8666V12.934Z"
        fill="#FC7A7A"
      />
    </svg>
  </BadgeContainer>
);

export default Camera;
