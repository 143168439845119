import React from 'react';
import {
  Route,
  Switch,
  Link,
  Redirect,
  useHistory,
  useLocation,
  match,
} from 'react-router-dom';

import { JuniLogo } from 'components/brand-assets';
import { ErrorableLoading } from 'components/ui';
import qs from 'qs';
import * as R from 'ramda';
import { useGetHelpCenterContentQuery } from 'generated/graphql';
import useDimensions from 'hooks/dimensions';
import classNames from 'classnames';
import { NewButton } from 'core-components';
import styled from 'styled-components/macro';
import {
  Article,
  CategoryExplore,
  Contact,
  Home,
  Search,
  SearchInput,
} from './components';
import { FloatingCircle, getCircleAnchorPoint } from './components/HeaderCircles';

const HelpCenterNav: React.FC<{ baseUrl: string }> = ({ baseUrl }) => (
  <div className="flex items-center px-4 py-0 mt-2 mb-2 space-x-8 font-bold">
    <Link to="/">
      <JuniLogo className="-ml-1 mt-1.5" />
    </Link>
    <span className="flex-grow" />
    <Link to="/" className="no-underline text-juni-dark-700 text-base md:text-lg">
      Login
    </Link>
    <Link to={`${baseUrl}/contact`} className="no-underline text-juni-blue-500">
      <NewButton>Contact us</NewButton>
    </Link>
  </div>
);

const HelpCenterFooter: React.FC<{ baseUrl: string }> = ({ baseUrl }) => (
  <div className="flex items-center justify-center space-x-4 w-full bg-white h-24 mt-8 md:mt-10">
    <span className="font-semibold text-j-dark-600 tracking-tight">
      Need more help?
    </span>
    <Link to={`${baseUrl}/contact`} className="no-underline">
      <NewButton>Contact us</NewButton>
    </Link>
  </div>
);
const HelpCenterContainer = styled.div.attrs<any>({
  className: 'flex flex-col justify-between',
})<any>`
  min-height: ${props => (props.loggedIn ? 'calc(100vh - 60px)' : '100vh')};
`;

interface LocationState {
  autofocus?: boolean;
}

interface Props {
  match: match;
  userType?: string;
}
const ParentHelpCenter: React.FC<Props> = ({ match, userType }) => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { data, error } = useGetHelpCenterContentQuery();
  const { width } = useDimensions();

  if (!data || error) return <ErrorableLoading error={error} />;
  const helpCenterContent = data.queryHelpCenterContent;
  const { categories, sections, articles } = helpCenterContent;
  if (!categories.length || !sections.length || !articles.length)
    return <ErrorableLoading error="Unable to load help center content" />;

  const searchInput = qs.parse(R.tail(location.search)).search ?? '';
  const setSearchInput = (input: string) => {
    // don't push a new item to history for each keystroke, but do for the first one
    const operation = searchInput ? history.replace : history.push;
    operation({
      pathname: `${match.url}/search`,
      search: `${input && `?search=${input}`}`,
      state: { autofocus: true },
    });
  };

  const searchHeader = (
    <div
      className={classNames([
        'flex',
        'flex-col',
        'justify-center',
        'items-center',
        'relative',
        'pb-24',
        'pt-24',
        'bg-j-dark-600',
        'w-full',
        'text-white',
        'overflow-hidden',
      ])}
    >
      <p className="font-normal text-sm">Help center & FAQ</p>
      <h1 className="mt-2 font-semibold text-2xl md:text-4xl text-center mx-4">
        Hi, How can we help you?
      </h1>
      <SearchInput
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        // autofocus whenever on search view
        // autofocus when navigating to / from search view strictly by changing search input (eg. typing first letter or deleting last)
        // DO NOT autofocus when navigating away from search view using browser nav (ie. history.action === 'POP')
        // DO NOT autofocus on fresh page load
        autofocus={
          !!searchInput ||
          (location?.state?.autofocus &&
            history?.action &&
            history.action !== 'POP') ||
          false
        }
      />
      <FloatingCircle
        top="-10rem"
        left={`${getCircleAnchorPoint({
          width,
          smallVwAnchor: -30,
          largeVwAnchor: 12,
        })}vw`}
        size="16rem"
        className="bg-j-purple-500"
      />
      <FloatingCircle
        bottom="-7rem"
        left={`${getCircleAnchorPoint({
          width,
          smallVwAnchor: 70,
          largeVwAnchor: 80,
        })}vw`}
        size="12rem"
        className="bg-j-purple-600"
      />
    </div>
  );

  return (
    <HelpCenterContainer loggedIn={!!userType}>
      <div className="w-full text-xs font-semibold text-blue-gray-600 font-graphik">
        {
          // If user isn't logged in, there's no navbar, so we need to add a minimal custom one.
          !userType && <HelpCenterNav baseUrl={match.url} />
        }
        {searchInput.length ? (
          <Search
            helpCenterContent={helpCenterContent}
            history={history}
            match={match}
            location={location}
            baseUrl={match.url}
            searchHeader={searchHeader}
            searchInput={searchInput}
          />
        ) : (
          <Switch>
            {/* Note: The first path to match is the Route that gets rendered. */}
            {/* This redirect fixes an issue when navigating to app.junilearning.com/help/ 
                  (notice the trailing slash). Any links then clicked on will lead to a
                  url with double slashes, for example app.junilearning.com/help//contact. This
                  redirect removes trailing slashes. Inspired by 
                  https://github.com/ReactTraining/react-router/issues/4841#issuecomment-821980722
              */}
            <Redirect
              from="/:url*(/+)"
              to={window.location.pathname.replace(/\/+$/, window.location.search)}
            />
            <Route
              path={`${match.path}/search`}
              render={props => (
                <Search
                  helpCenterContent={helpCenterContent}
                  baseUrl={match.url}
                  searchHeader={searchHeader}
                  searchInput={searchInput}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.path}/contact`}
              render={props => <Contact baseUrl={match.url} {...props} />}
            />
            <Route
              path={`${match.path}/categories/:categoryId/:sectionId?`}
              render={() => (
                <CategoryExplore
                  helpCenterContent={helpCenterContent}
                  baseUrl={match.url}
                />
              )}
            />
            <Route
              path={`${match.path}/articles/:articleId`}
              render={() => (
                <Article helpCenterContent={helpCenterContent} baseUrl={match.url} />
              )}
            />
            <Route
              path={`${match.path}/`}
              render={props => (
                <Home
                  helpCenterContent={helpCenterContent}
                  baseUrl={match.url}
                  searchHeader={searchHeader}
                  {...props}
                />
              )}
            />
          </Switch>
        )}
      </div>
      {
        // If you're not looking at contact page, insert a link to it below.
        (location.pathname !== `${match.url}/contact` || searchInput) && (
          <HelpCenterFooter baseUrl={match.url} />
        )
      }
    </HelpCenterContainer>
  );
};

export default ParentHelpCenter;
