import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Message, ModalWindow, NewButton } from 'core-components';
import {
  useArchiveChannelMutation,
  useEditChannelMutation,
} from 'generated/graphql';
import { Formik, Form, Field } from 'formik';
import { SettingsIcon } from 'components/Icons';
import SpinnerV2 from 'components/SpinnerV2';

interface EditChannelModalProps {
  isOpen: boolean;
  handleClose: () => void;
  channelName: string;
  studentIdParam?: string;
  instructorUserIdParam?: string;
  juniClubId: string;
  isChannelReadOnly: boolean;
}

const READ_ONLY = 'READ_ONLY';
const PUBLIC = 'PUBLIC';
type ChannelStatus = typeof READ_ONLY | typeof PUBLIC;
interface EditChannelFormValues {
  channelStatus: ChannelStatus;
}
const EditChannelModal: React.FC<EditChannelModalProps> = ({
  isOpen,
  handleClose,
  channelName,
  studentIdParam,
  instructorUserIdParam,
  juniClubId,
  isChannelReadOnly,
}) => {
  const [editChannel] = useEditChannelMutation();
  const [archiveChannel] = useArchiveChannelMutation();
  const [editErrors, setEditErrors] = useState('');
  const [archiveErrors, setArchiveErrors] = useState('');
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [submittingArchive, setSubmittingArchive] = useState(false);

  const handleSubmit = async (values: EditChannelFormValues) => {
    const readOnly = values.channelStatus === READ_ONLY;
    setSubmittingEdit(true);
    try {
      await editChannel({
        variables: {
          input: {
            studentId: studentIdParam,
            instructorUserId: instructorUserIdParam,
            juniClubId,
            channelName,
            readOnly,
          },
        },
      });
      setSubmittingEdit(false);
      handleClose();
    } catch (e) {
      console.log(e);
      setSubmittingEdit(false);
      setEditErrors('Error editing channel.');
    }
  };

  const handleArchive = async () => {
    try {
      setSubmittingArchive(true);
      await archiveChannel({
        variables: {
          input: {
            studentId: studentIdParam,
            instructorUserId: instructorUserIdParam,
            juniClubId,
            channelName,
          },
        },
      });
      setSubmittingArchive(false);
      handleClose();
    } catch (e) {
      setArchiveErrors('Error archiving channel');
      console.log(e);
    }
  };
  return (
    <ModalWindow
      title={`Edit #${channelName}`}
      isOpen={isOpen}
      closeModal={handleClose}
      maxWidth="100%"
    >
      <Formik
        initialValues={{
          channelStatus: isChannelReadOnly ? READ_ONLY : PUBLIC,
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className="flex flex-col">
            <div className="font-semibold mb-2" id="form-radio-group">
              Channel Type
            </div>
            <div role="group" aria-labelledby="form-radio-group">
              <div>
                <label className="cursor-pointer">
                  <Field
                    type="radio"
                    name="channelStatus"
                    value={PUBLIC}
                    checked={values.channelStatus === PUBLIC}
                  />
                  <strong>Public </strong>
                  <span className="text-sm">(anyone can post on this channel)</span>
                </label>
              </div>
              <div>
                <label className="cursor-pointer">
                  <Field
                    type="radio"
                    name="channelStatus"
                    value={READ_ONLY}
                    checked={values.channelStatus === READ_ONLY}
                  />
                  <strong>Announcements Only </strong>
                  <span className="text-sm">
                    (only Club leaders, advisors, and moderators can post on this
                    channel)
                  </span>
                </label>
              </div>
            </div>
            {editErrors && <Message status="error">{editErrors}</Message>}
            <div>
              <Button type="submit" disabled={submittingEdit} className="mt-4">
                {submittingEdit ? <SpinnerV2 /> : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <hr />
      <div>
        <div className="pb-4">
          Archiving a channel will permanently remove the channel from the list of
          channels on the side.
        </div>
        {archiveErrors && <Message status="error">{archiveErrors}</Message>}
        <div>
          <NewButton
            variant="primary"
            intent="error"
            disabled={submittingArchive}
            onClick={handleArchive}
          >
            {submittingArchive ? <SpinnerV2 /> : 'Archive Channel'}
          </NewButton>
        </div>
      </div>
    </ModalWindow>
  );
};
interface EditChannelProps {
  channelName: string;
  studentIdParam?: string;
  instructorUserIdParam?: string;
  juniClubId: string;
  isChannelReadOnly: boolean;
  showButton: boolean;
}
const EditChannel: React.FC<EditChannelProps> = ({
  channelName,
  studentIdParam,
  instructorUserIdParam,
  juniClubId,
  isChannelReadOnly,
  showButton,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  return (
    <>
      <EditChannelModal
        isOpen={editOpen}
        handleClose={() => setEditOpen(false)}
        channelName={channelName}
        studentIdParam={studentIdParam}
        instructorUserIdParam={instructorUserIdParam}
        isChannelReadOnly={isChannelReadOnly}
        juniClubId={juniClubId}
      />
      {showButton && (
        <div
          className={classNames(
            'flex',
            'justify-end',
            'items-center',
            'cursor-pointer',
            'opacity-80',
          )}
          onClick={() => setEditOpen(true)}
        >
          <SettingsIcon
            style={{ fill: 'white', height: '1rem', padding: '0 0.25rem' }}
            customFill
          />
        </div>
      )}
    </>
  );
};
export default EditChannel;
