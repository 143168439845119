import { createContext } from 'react';

const AffiliateContext = createContext<
  Partial<{
    trackAffiliateConversion: (
      tag: number,
      orderId: string,
      customerId: string,
    ) => void;
  }>
>({});

export default AffiliateContext;
