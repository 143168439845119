import * as R from 'ramda';

// Replaces first item found using given predicate func with a new item.
export const replaceFirstMatchingElem: (
  func: (item: any) => boolean,
  value: any,
  target: any[],
) => [any[], boolean] = R.curry((func, value, target) => {
  const index = R.findIndex(func, target);
  return index >= 0 ? [R.update(index, value, target), true] : [target, false];
});

/**
 * The generateCombinations function was largely borrowed / adapted from
 * https://stackoverflow.com/questions/43241174/javascript-generating-all-combinations-of-elements-in-a-single-array-in-pairs
 *
 * Generate all combinations of an array up to a max combination size.
 * @param {Array} sourceArray - Array of input elements.
 * @param {number} maxComboSize - Maximum desired length of combinations.
 * @return {Array} Array of combination arrays.
 */
export const generateCombinations: (
  sourceArray: any[],
  maxComboSize: number,
) => any[][] = (sourceArray, maxComboSize) => {
  const sourceLength = sourceArray.length;
  if (maxComboSize > sourceLength) return [];

  const combos: any[][] = []; // Stores valid combinations as they are generated.

  // Accepts a partial combination, an index into sourceArray,
  // and the number of elements required to be added to create a full-length combination.
  // Called recursively to build combinations, adding subsequent elements at each call depth.
  const makeNextCombos = (
    workingCombo: any[],
    currentIndex: number,
    remainingCount: number,
  ) => {
    const oneAwayFromComboLength = remainingCount === 1;

    // For each element that remaines to be added to the working combination.
    for (
      let sourceIndex = currentIndex;
      sourceIndex < sourceLength;
      sourceIndex += 1
    ) {
      // Get next (possibly partial) combination.
      const next = [...workingCombo, sourceArray[sourceIndex]];

      // save combination (regardless of partial or complete)
      combos.push(next);

      if (!oneAwayFromComboLength) {
        // Go deeper to add more elements to the current partial combination.
        makeNextCombos(next, sourceIndex + 1, remainingCount - 1);
      }
    }
  };

  makeNextCombos([], 0, maxComboSize);
  return combos;
};

// Inspired by https://wsvincent.com/javascript-merge-two-sorted-arrays/
// but made 1000% more awesome by @jproft
// O(n) time & O(n) space
export const mergeSortedArrays = (arr1: any[], arr2: any[], property: string) => {
  const mergedArray: any[] = [];
  const a = [...arr1];
  const b = [...arr2];
  while (a.length > 0 && b.length > 0) {
    const arr = a[0][property] < b[0][property] ? a : b;
    mergedArray.push(arr.shift());
  }
  return [...mergedArray, ...a, ...b];
};
