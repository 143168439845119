/**
 * Why we need this in some cases:
 * https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 * TLDR - React.forwardRef + innerRef needs.
 * See the Popover component for an example.
 */
import { useRef, useEffect } from 'react';

const useCombinedRef = <T>(
  // React.forwardRef prop type
  ref: ((instance: T | null) => void) | React.MutableRefObject<T | null> | null,
) => {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  }, [ref]);

  return innerRef;
};

export default useCombinedRef;
