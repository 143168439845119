import { TailwindBreakpoints } from './types/index';

/**
 * All in pixels.
 */
export const tailwindBreakpointSizes: {
  [breakpoint in TailwindBreakpoints]: number;
} = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};
