import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import juniAxios from 'services/axios';
import moment from 'moment';
import queryString from 'query-string';

import SpinnerV2 from 'components/SpinnerV2';
import CoppaDirectNotice from 'components/CoppaDirectNotice';
import {
  INSTRUCTOR_SMS_CONSENT_LABEL_TEXT,
  PARENT_SMS_CONSENT_LABEL_TEXT,
} from 'components/SmsConsentCheckbox/constants';
import { getContentForCampaign } from 'app/signup_session/campaign_content';
import { Button } from 'core-components';
import Auth from 'modules/Auth';
import navStates from 'app/signup_session/navigation/states';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import AuthLayout from '../AuthLayout';

import './signup.css';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

const REFETCH_INVITE_INTERVAL = 60 * 1000; // 60 seconds

const DEFAULT_CONTENT = {
  signupWelcomeTitle: 'Welcome to Juni!',
  setupInstructions: 'Choose a password you and your child can share.',
};

class Signup extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    inviteCode: '',
    isLoading: true,
    inviteCodeInvalid: false,
    invitationExpired: false,
    invitationAlreadyUsed: false,
    smsConsentChecked: false,
    tosIsChecked: false,
    errorMessage: '',
    isRefreshingInvite: false,
  };
  refetchInviteInterval = null;

  componentDidMount() {
    window.addEventListener('focus', this.handleWindowFocus);
    this.refetchInviteInterval = setInterval(
      this.getInviteInfoInBackground,
      REFETCH_INVITE_INTERVAL,
    );
    const { search } = this.props.location;
    const {
      invite_code: inviteCode,
      campaign: campaignOverride,
    } = queryString.parse(search);

    this.setState({
      inviteCode: inviteCode || this.props.signupData?.invitationCode,
      campaignOverride,
    });

    // get record matching the invite code in the URL
    this.getInviteInfo(inviteCode);
  }
  componentWillUnmount() {
    window.removeEventListener('focus', this.handleWindowFocus);
    clearInterval(this.refetchInviteInterval);
    this.refetchInviteInterval = null;
  }
  getInviteInfo = inviteCodeParam => {
    const inviteCode = inviteCodeParam || this.state.inviteCode;
    this.setState({ isLoading: true }, () => {
      this.getInviteInfoInBackground(inviteCode);
    });
  };
  getInviteInfoInBackground = inviteCodeParam => {
    const inviteCode = inviteCodeParam || this.state.inviteCode;
    this.setState({ isRefreshingInvite: true }, () =>
      juniAxios
        .get(`/auth/get_invitation/${inviteCode}`)
        .then(res => {
          if (res.data.length === 0) {
            // if no records found with this invite code, then the invite code is invalid
            this.setState({
              inviteCodeInvalid: true,
              isLoading: false,
              isRefreshingInvite: false,
            });
          } else if (!res.data.isValid) {
            // if the invitation has already been used, it will return with isValid: false
            this.setState({
              invitationAlreadyUsed: true,
              email: res.data.email,
              isLoading: false,
              isRefreshingInvite: false,
            });
          } else if (res.data.userType !== this.props.userType) {
            // if the invitation is not for the right userType, then the invite code is invalid
            this.setState({
              inviteCodeInvalid: true,
              isLoading: false,
              isRefreshingInvite: false,
            });
          } else {
            // check that the invite code has not expired
            let expirationTimestamp = null;
            if (res.data.expirationTimestamp.length > 14) {
              expirationTimestamp = moment(res.data.expirationTimestamp);
            } else {
              expirationTimestamp = moment.unix(res.data.expirationTimestamp / 1000);
            }
            if (expirationTimestamp && expirationTimestamp.isAfter(moment())) {
              this.setState({
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                email: res.data.email,
                isLoading: false,
                isRefreshingInvite: false,
              });
            } else {
              this.setState({
                invitationExpired: true,
                isLoading: false,
                isRefreshingInvite: false,
              });
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            inviteCodeIsValid: false,
            isLoading: false,
            isRefreshingInvite: false,
          });
        }),
    );
  };
  handleWindowFocus = async () => {
    this.getInviteInfoInBackground(this.state.inviteCode);
  };
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.tosIsChecked) {
      this.setState({
        errorMessage:
          'You must agree to the Juni Terms of Use and Privacy Policy in order to sign up.',
      });
    } else if (this.state.password.length < 8) {
      this.setState({
        errorMessage: 'Your password must be at least 8 characters.',
      });
    } else {
      this.setState({ errorMessage: '' });
      try {
        const signupRes = await juniAxios.post(
          `/auth/signup`,
          {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            inviteCode: this.state.inviteCode,
            smsConsent: this.state.smsConsentChecked,
            userType: this.props.userType,
          },
          config,
        );

        if (signupRes?.data?.parent?.userId) {
          JuniAnalytics.identify({ userId: signupRes?.data?.parent?.userId });
        }

        const inDcf = this.props.signupData && this.props.setSignupSession;
        // only automatically log the user in when they are in the DCF
        const autoLogin = inDcf && this.props.checkAuth;

        if (autoLogin) {
          const loginRes = await juniAxios.post(
            `/auth/login`,
            {
              email: this.state.email,
              password: this.state.password,
              userType: this.props.userType,
            },
            config,
          );
          Auth.authenticateUser(loginRes?.data?.token);
          await this.props.checkAuth();
        }

        if (this.props.userType === 'teacher') {
          if (signupRes.data.teacher) {
            this.props.history.push(autoLogin ? '/teacher/' : '/teacher/login/');
          }
        } else if (signupRes.data.parent) {
          // only redirect into account creation if the user is creating their account inside DCF
          if (inDcf) {
            this.props.setSignupSession({ accountCreated: true });
            this.props.history.push(
              this.props.getNextPage(
                navStates.onboarding.createAccount,
                ROUTE_EVENT.SUBMIT,
                { signupData: this.props.signupData },
              ),
            );
          } else {
            this.props.history.push(autoLogin ? '/learner/' : '/learner/login/');
          }
        }
      } catch (err) {
        console.log(err);
        this.setState({ errorMessage: err.response.data.message });
      }
    }
  }

  render() {
    const CONTENT = getContentForCampaign(
      DEFAULT_CONTENT,
      this.state.campaignOverride || this.props.signupData?.campaign, // override campaign copy if set in url
    );

    return (
      <AuthLayout>
        <div className="auth-form auth-form-signup">
          {this.state.isLoading ? (
            <div className="page-error">Loading...</div>
          ) : (
            <div>
              {this.state.inviteCodeInvalid ? (
                <div className="page-error-container">
                  <div className="page-error-container-title">Oops!</div>
                  <div className="page-error-container-text">
                    <p>
                      You must have a valid invitation to sign up for your Juni
                      account.
                    </p>
                    <p>
                      For assistance, please email support@learnwithjuni.com or call
                      (650) 263-4306.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.invitationExpired ? (
                    <div className="page-error-container">
                      <div className="page-error-container-title">Oops!</div>
                      <div className="page-error-container-text">
                        <p>Your invitation has expired.</p>
                        <p>
                          For assistance, please email support@learnwithjuni.com or
                          call (650) 263-4306.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {this.state.invitationAlreadyUsed ? (
                        <div className="invitation-used">
                          <h1>This account's password has already been set!</h1>
                          <hr />
                          <p>
                            Somebody has already set the password for{' '}
                            {this.state.email.length === 0 ? (
                              'this account'
                            ) : (
                              <b>{this.state.email}</b>
                            )}
                            . Please go ahead and login.
                          </p>
                          <div className="btn-group">
                            <Link
                              to={
                                this.props.userType === 'teacher'
                                  ? '/teacher/login'
                                  : '/learner/login'
                              }
                            >
                              <Button variant="pink" hasArrowIcon>
                                Login
                              </Button>
                            </Link>
                            <Link to="/forgot_password">
                              <Button variant="pinkSecondary">
                                Forgot password
                              </Button>
                            </Link>
                          </div>
                          <small style={{ marginTop: '.75rem' }}>
                            For assistance, please email support@learnwithjuni.com or
                            call (650) 263-4306.
                          </small>
                        </div>
                      ) : (
                        <div className="invitation-valid">
                          <h1 style={{ textAlign: 'center' }}>
                            {CONTENT.signupWelcomeTitle}
                            <br />
                            Set up your Learner Portal Account.
                          </h1>
                          <hr />
                          <p style={{ marginBottom: 24, textAlign: 'center' }}>
                            {this.props.userType === 'parent' ? (
                              <span>{CONTENT.setupInstructions}</span>
                            ) : null}
                          </p>
                          <form onSubmit={e => this.handleSubmit(e)}>
                            {this.state.errorMessage ? (
                              <div className="form-error-wrap">
                                <div className="form-error">
                                  {this.state.errorMessage}
                                </div>
                              </div>
                            ) : null}
                            <table>
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <td>
                                    {this.state.firstName} {this.state.lastName}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Email</th>
                                  <td className="email-value">
                                    <div>
                                      <span>{this.state.email}</span>
                                      {this.state.isRefreshingInvite && (
                                        <SpinnerV2 size={14} />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Password (8 char min)</th>
                                  <td>
                                    <input
                                      placeholder="Enter a password"
                                      type="password"
                                      name="password"
                                      value={this.state.password}
                                      onChange={e => this.handleChange(e)}
                                      style={{ padding: 8 }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {this.props.userType === 'parent' && (
                              <div className="scrollable-direct-notice-container outer">
                                <div className="scrollable-direct-notice-container inner">
                                  <CoppaDirectNotice />
                                </div>
                              </div>
                            )}
                            <p
                              className={
                                this.props.userType === 'parent'
                                  ? 'checkbox-label'
                                  : ''
                              }
                            >
                              <input
                                id="tos"
                                type="checkbox"
                                onChange={() =>
                                  this.setState(prevState => ({
                                    tosIsChecked: !prevState.tosIsChecked,
                                  }))
                                }
                                style={{ marginRight: '10px' }}
                              />
                              {this.props.userType === 'parent' ? (
                                <label htmlFor="tos">
                                  I consent to the{' '}
                                  <a
                                    href="https://junilearning.com/terms/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="tos"
                                  >
                                    Juni Learning Terms of Use and Privacy Policy
                                  </a>{' '}
                                  and the collection, use, and disclosure of my
                                  child’s personal information as described in the
                                  Direct Notice to Parents.
                                </label>
                              ) : (
                                <label htmlFor="tos">
                                  I agree to the{' '}
                                  <a
                                    href="https://junilearning.com/terms/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="tos"
                                  >
                                    Juni Learning Terms of Use and Privacy Policy
                                  </a>
                                  .
                                </label>
                              )}
                            </p>
                            <p
                              className={
                                this.props.userType === 'parent'
                                  ? 'checkbox-label'
                                  : ''
                              }
                              style={{ marginTop: 0 }}
                            >
                              <input
                                id="sms-consent"
                                type="checkbox"
                                onChange={() =>
                                  this.setState(prevState => ({
                                    smsConsentChecked: !prevState.smsConsentChecked,
                                  }))
                                }
                                style={{ marginRight: '10px' }}
                              />
                              <label htmlFor="sms-consent">
                                {this.props.userType === 'parent'
                                  ? PARENT_SMS_CONSENT_LABEL_TEXT
                                  : INSTRUCTOR_SMS_CONSENT_LABEL_TEXT}
                              </label>
                            </p>
                            <div className="btn-group">
                              <div />
                              <Button type="submit" hasArrowIcon variant="pink">
                                Submit
                              </Button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </AuthLayout>
    );
  }
}

export default withRouter(Signup);
