import styled from 'styled-components/macro';

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  & svg {
    width: 100%;
    height: 100%;
  }
`;

export default BadgeContainer;
