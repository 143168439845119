import React, { FC } from 'react';
import * as R from 'ramda';
import { Message, Select } from 'core-components';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import findStudentById from 'app/signup_session/lib/findStudentById';
import updateBundleDataByStudent from 'app/signup_session/lib/updateBundleDataByStudent';
import { SubjectName } from 'app/signup_session/types';
import { useRecommendedAges } from 'hooks/course';
import {
  GetCoursesForCourseSelectionReturnFragment,
  useGetCoursesForCourseSelectionQuery,
} from 'generated/graphql';
import RingSpinner from 'components/RingSpinner';

const CourseSelection: FC = () => {
  const { signupData, activeStudentId, setSignupSession } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData)!;
  const { bundle } = student;
  const updateCourseNameBySubject = (courseName: string, subject: SubjectName) =>
    setSignupSession(
      updateBundleDataByStudent(
        activeStudentId,
        subject,
        { ...(bundle?.selections?.[subject] ?? {}), courseName },
        signupData,
      ),
    );

  const { data, loading, error } = useGetCoursesForCourseSelectionQuery({
    variables: {
      input: { isAcceptingEnrollment: true, schedulingFormat: 'private' },
    },
  });
  const courses: GetCoursesForCourseSelectionReturnFragment[] = [
    ...(data?.getCourses || []),
  ].sort((a, b) => a.displayName.localeCompare(b.displayName));
  const coursesBySubject = R.groupBy(c => c.subject.displayName, courses);
  const recommendedAges = useRecommendedAges();

  if (error) {
    return (
      <Message status="error">
        Oops, something went wrong! Try refreshing the page.
      </Message>
    );
  }
  if (loading) return <RingSpinner />;

  return (
    <div className="flex flex-col space-y-4">
      {Object.entries(coursesBySubject)
        .filter(
          ([subjectDisplayName]) =>
            bundle?.selections?.[subjectDisplayName as SubjectName],
        )
        .map(([subjectDisplayName, courses]) => {
          const courseOptions = courses.map(course => ({
            label: recommendedAges[course.name]?.displayString,
            value: course.name,
          }));

          const selectedCourse = courseOptions.find(
            course =>
              course.value ===
              bundle?.selections?.[subjectDisplayName as SubjectName]?.courseName,
          );

          return (
            <div key={subjectDisplayName}>
              <div className="text-sm text-j-dark-600 mb-2">
                {subjectDisplayName}
              </div>
              <Select
                size="small"
                fullWidth
                onChange={e =>
                  updateCourseNameBySubject(e, subjectDisplayName as SubjectName)
                }
                selected={selectedCourse?.value}
                options={courseOptions}
                placeholder="Select a course"
              />
            </div>
          );
        })}
    </div>
  );
};

export default CourseSelection;
