import CustomProjectService from 'services/custom_projects';
import { CustomProject } from '../models/custom_project';

interface Student {
  id: string;
}

const fetchPlaygroundProjects = async (student: Student) => {
  try {
    const playgroundProjectsRes = await CustomProjectService.list({
      studentId: student.id,
    });
    return playgroundProjectsRes;
  } catch (err) {
    console.log(err);
  }
};
// export functions.
export const fetchPlaygroundProjectsForAllStudents = async (
  jideStudents: [Student],
) => Promise.all(jideStudents.map(student => fetchPlaygroundProjects(student)));

// process playground takes the 2D array results of the fetched playground projects.
export const processPlaygroundProjects = (allStudentProjects: CustomProject[][]) =>
  allStudentProjects
    .reduce((acc, val) => acc.concat(val), [])
    .filter(project => project?.moduleId === null)
    .map(project => ({
      ...project,
      id: project._id,
      isPlayground: true,
      properties: {},
    }));
