import React from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

import Auth from 'modules/Auth';

import './home.css';

import learnerImg from 'images/learner.png';
import teacherImg from 'images/teacher.png';

import AuthLayout from '../AuthLayout';

const Home = ({ userType }) => {
  const history = useHistory();
  const location = useLocation();

  if (Auth.isUserAuthenticated() && userType.length !== 0) {
    if (userType === 'teacher' || userType === 'admin') {
      return <Redirect to="/teacher" />;
    }
    if (userType === 'parent') {
      return <Redirect to="/learner" />;
    }
  }

  if (window.location.pathname !== '/') {
    history.replace(`/?redirectTo=${location.pathname}${location.search}`);
  }

  return (
    <AuthLayout>
      <div>
        <div id="account-types">
          <Link
            to={`/learner/login${window.location.search}`}
            className="form-reset-link"
          >
            <div className="account-type">
              <div className="account-type-head">
                <h5>I'm a learner</h5>
                <div className="account-type-img-wrap">
                  <img src={learnerImg} alt="learner" />
                </div>
                <p>Track your student's progress and view their schedule</p>
              </div>
              <button className="bg-white hover:shadow-none ignore-juni-globals">
                <div className="text-juni-dark-900">
                  <small>Sign In</small>
                </div>
              </button>
            </div>
          </Link>
          <Link
            to={`/teacher/login${window.location.search}`}
            className="form-reset-link"
            style={{ marginBottom: 36 }}
          >
            <div className="account-type">
              <div className="account-type-head">
                <h5>I'm an instructor</h5>
                <div className="account-type-img-wrap">
                  <img src={teacherImg} alt="instructor" />
                </div>
                <p>Manage your schedule, lesson notes, and more</p>
              </div>
              <button className="bg-white hover:shadow-none ignore-juni-globals">
                <div className="text-juni-purple-500">
                  <small>Sign In</small>
                </div>
              </button>
            </div>
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Home;
