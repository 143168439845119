import React, { useState, useContext } from 'react';
import { Button, Message } from 'core-components';
import SpinnerV2 from 'components/SpinnerV2';

import { useHistory } from 'react-router-dom';
import UserContext from 'modules/UserContext';

import { useJoinLeaveClubMutation } from 'generated/graphql';
import { useUpdateMyClubsState } from 'app/clubs/stores/ClubStoreHelpers';
import { getClubLink } from 'app/clubs/helpers';

import {
  MAX_CLUB_MEMBERSHIPS_GUEST,
  MAX_CLUB_MEMBERSHIPS_USER,
} from 'constants/clubs';

const LIMIT_MESSAGE_GUEST = `
  You've joined the maximum of ${MAX_CLUB_MEMBERSHIPS_GUEST} Clubs.
  Enroll in a Juni course to join more Clubs!
`;

const LIMIT_MESSAGE_USER = `
  You've joined the maximum of ${MAX_CLUB_MEMBERSHIPS_USER} Clubs.
  Please leave a club to join this one.
`;

interface JoinClubButtonProps {
  studentId?: string;
  instructorUserId?: string;
  clubId: string;
  canJoin: boolean;
  requiresRefresh: boolean;
}

// TODO: Need logic for banned users.
// TODO: Add invitation join logic to BE and remove mutation entirely on FE.

const JoinClubButton: React.FC<JoinClubButtonProps> = ({
  studentId,
  instructorUserId,
  clubId,
  canJoin,
  requiresRefresh,
}) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [joinClub] = useJoinLeaveClubMutation();

  const redirectUrl = getClubLink({ studentId, juniClubId: clubId });
  const [submitting, setSubmitting] = useState(false);
  const updateMyClubsState = useUpdateMyClubsState();

  const submitJoinClub = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      await joinClub({
        variables: {
          studentId,
          instructorUserId,
          juniClubId: clubId,
          isActive: true,
        },
      });
      await updateMyClubsState({
        studentId,
        instructorUserId,
        specificClubs: [clubId],
      });
      if (requiresRefresh) {
        window.location.href = redirectUrl;
      } else {
        history.push(redirectUrl);
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return !canJoin ? (
    <div className="flex flex-col py-4">
      <Message className="mt-4" status="error">
        {user?.isGuest ? LIMIT_MESSAGE_GUEST : LIMIT_MESSAGE_USER}
      </Message>
      <div className="flex justify-end pt-4">
        <Button disabled hasArrowIcon>
          Join Club
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex justify-end p-4 pb-1">
        <Button hasArrowIcon onClick={submitJoinClub}>
          {submitting ? <SpinnerV2 /> : 'Agree & Join Club!'}
        </Button>
      </div>
    </div>
  );
};

export default JoinClubButton;
