/**
 * Very much adapted - copy+pasted almost - from previous Annotations component.
 */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';
import Annotation from './Annotation';

interface AnnotationData {
  annotation: string;
  highlightColor: string;
  entityKey: string;
  highlightRef: React.RefObject<HTMLSpanElement>;
}
interface AnnotationRenderData extends AnnotationData {
  highlightTop: number;
  annotationTop: number;
}
export interface AnnotationsBarProps {
  /**
   * Annotations data to render.
   */
  annotationsData: AnnotationData[];
  /**
   * Map of colors to hsl color values.
   */
  colorMap: React.ComponentProps<typeof Annotation>['colorMap'];
  /**
   * The vertical margin between annotations if they would otherwise overlap.
   * Default 12px.
   */
  annotationsMargin?: number;
  /**
   * Optionally offset annotations a number of pixels from the top.
   * Default 0.
   */
  annotationsTopOffset?: number;
  /**
   * Optionally offset the container from the bottom by a number of pixels.
   * Ensures a minimum height in an empty state.
   * Default 0.
   */
  containerBottomOffset?: number;
  /**
   * Optional styling.
   */
  className?: string;
  /**
   * Optional width to set the container bar to.
   */
  width?: React.CSSProperties['width'];
  /**
   * Optional height to set the container bar to.
   */
  height?: React.CSSProperties['height'];
}

const AnnotationsBar: React.FC<AnnotationsBarProps> = ({
  annotationsData,
  colorMap,
  annotationsMargin = 12,
  annotationsTopOffset = 0,
  containerBottomOffset = 0,
  className,
  width,
  height,
}) => {
  const annotationsContainerRef = useRef<HTMLDivElement>(null);
  const [annotations, setAnnotations] = useState<AnnotationRenderData[]>([]);
  const [annotationBottomsByEntityKey, setAnnotationBottomsByEntityKey] = useState<
    Record<string, number>
  >({});
  const [minHeight, setMinHeight] = useState(0);

  const setAnnotationBottom = (entityKey: string, bottom: number) => {
    setAnnotationBottomsByEntityKey(annotationBottomsByEntityKey => ({
      ...annotationBottomsByEntityKey,
      [entityKey]: bottom,
    }));
  };

  const computeAnnotationTop = (annotationData: AnnotationRenderData) =>
    containerTop
      ? annotationData.annotationTop - containerTop + annotationsTopOffset
      : -1000;

  useEffect(() => {
    // sort annotations by the top position of their associated highlight
    const sortedAnnotations = annotationsData
      .map((annotationData: AnnotationData) => {
        const boundingRect = annotationData.highlightRef.current!.getBoundingClientRect();
        return {
          ...annotationData,
          highlightTop: boundingRect.top + window.scrollY,
        };
      })
      .sort((a: any, b: any) => a.highlightTop - b.highlightTop);

    const annotations: AnnotationRenderData[] = [];
    let prevAnnotationData: AnnotationRenderData | undefined;
    for (let i = 0; i < sortedAnnotations.length; i += 1) {
      const annotationData = sortedAnnotations[i];

      let prevAnnotationBottom = 0;
      if (prevAnnotationData) {
        prevAnnotationBottom =
          annotationBottomsByEntityKey[prevAnnotationData.entityKey] || 0;
      }

      let annotationTop = annotationData.highlightTop;
      // adjust the top position of an annotation if it will overlap the one above it
      if (prevAnnotationBottom > annotationData.highlightTop - annotationsMargin) {
        annotationTop = prevAnnotationBottom + annotationsMargin;
      }
      const annotation = {
        ...annotationData,
        annotationTop,
      };
      prevAnnotationData = annotation;
      annotations.push(annotation);
    }
    setAnnotations(annotations);
  }, [annotationsData, annotationBottomsByEntityKey, annotationsMargin]);

  // need this to run after every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (annotationsContainerRef.current) {
      const { top } = annotationsContainerRef.current.getBoundingClientRect();
      const minContainerHeightNoAnnotations =
        window.innerHeight - top - containerBottomOffset;

      // height of all the annotation content
      let annotationsHeight = 0;
      if (annotations.length) {
        const annotationElements = [...annotationsContainerRef.current.children];
        const lastElement = annotationElements[annotationElements.length - 1];

        // last element's top + height + margin for the bottom
        const lastAnnotationTop = computeAnnotationTop(
          annotations[annotations.length - 1],
        );
        annotationsHeight =
          lastAnnotationTop +
          lastElement.getBoundingClientRect().height +
          annotationsMargin;
      }
      setMinHeight(Math.max(minContainerHeightNoAnnotations, annotationsHeight));
    }
  });

  const containerTop = annotationsContainerRef?.current
    ? annotationsContainerRef.current.getBoundingClientRect().top
    : undefined;

  return (
    <div
      ref={annotationsContainerRef}
      className={classNames(
        'bg-j-gray-200 box-border',
        'border-0 border-solid border-j-dark-100 border-r',
        'font-graphik',
        className,
      )}
      style={{ width, height, minHeight }}
    >
      {annotations.length === 0 && (
        <div
          className="sticky left-0 right-0 flex flex-col justify-center items-center"
          style={{ top: 125 }}
        >
          <div className="flex items-center justify-center p-5 bg-j-gray-300 rounded-full">
            <Icon.CommentSlash className="w-7 h-7 text-j-dark-400" />
          </div>
          <div className="mt-4 text-center text-j-dark-600 text-base font-semibold">
            You don't have any annotations yet
          </div>
          <div className="mt-1 text-center text-j-dark-400 text-xs leading-5">
            Your annotations will appear here
          </div>
        </div>
      )}
      {annotations.map((annotationData: AnnotationRenderData) => (
        <Annotation
          key={annotationData.entityKey}
          annotation={annotationData.annotation}
          colorMap={colorMap}
          highlightColor={annotationData.highlightColor}
          annotationTop={computeAnnotationTop(annotationData)}
          entityKey={annotationData.entityKey}
          setAnnotationBottom={setAnnotationBottom}
        />
      ))}
    </div>
  );
};

export default AnnotationsBar;
