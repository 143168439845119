import React, { useContext } from 'react';
import classNames from 'classnames';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Button, Card } from 'core-components';
import { JuniClubType } from 'generated/graphql';
import { CLUB_DISPLAY_TAGS } from 'constants/clubs';

import LabelTag from 'components/LabelTag';
import { getClubLink, sortTags } from 'app/clubs/helpers';
import UserContext from 'modules/UserContext';
import ClubMetadataEditor from '../ClubMetadataEditor';

const ClubCardWrapper = styled(Card)<any>`
  display: flex;
  flex-direction: column;
  height: 470px;

  > div:last-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
`;

const GradientOverlay = styled.div.attrs({
  className: 'absolute bottom-0 w-full m-0 py-5',
})`
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.9) 100%
  );
`;

export interface ClubCardProps {
  studentId?: string;
  juniClub: JuniClubType;
  isMember?: boolean;
  memberCount?: number | null;
  handleJoinClick: () => void;
}

const ClubCard: React.FC<ClubCardProps> = ({
  studentId,
  juniClub,
  isMember,
  memberCount,
  handleJoinClick,
}) => {
  const { displayName, tags, description } = juniClub;
  const clubDescription = description || '';
  const { user } = useContext(UserContext);
  const isAdmin = user?.userType.includes('admin');
  return (
    <ClubCardWrapper
      bannerImgSrc={juniClub.coverPhoto}
      bannerImgClassName="h-full object-cover"
      bannerHeight="48"
      boxShadow
      topLabelText={isMember && "You're a member of this club"}
      topLabelTextBgColor="indigo-400"
      contentClassName="pt-4"
    >
      <div className="flex-grow-0">
        <div className="flex flex-wrap justify-between items-center">
          <div className="font-bold text-base text-blue-gray-600 mr-2 mb-1">
            {displayName}
          </div>
          <div
            className={classNames(
              'whitespace-nowrap',
              'text-xs',
              'text-blue-gray-500',
              'bg-blue-gray-50',
              'bg-opacity-50',
              'mb-1',
              'py-1',
              'px-3',
              'border',
              'border-solid',
              'rounded-2xl',
              'border-blue-gray-100',
            )}
          >
            {`${memberCount} member${memberCount !== 1 ? 's' : ''}`}
          </div>
        </div>
        <div className="flex flex-wrap mt-2">
          {sortTags(tags || []).map(tagName => {
            const tagInfo = CLUB_DISPLAY_TAGS.find(tag => tag.value === tagName);
            return (
              tagInfo && (
                <LabelTag
                  color={tagInfo.color}
                  name={tagInfo.label}
                  key={tagInfo.value}
                />
              )
            );
          })}
          {isAdmin && (
            <ClubMetadataEditor clubTags={tags || []} juniClubId={juniClub._id} />
          )}
        </div>
      </div>
      <div
        title={clubDescription}
        className={classNames(
          'flex-grow',
          'overflow-hidden',
          'relative',
          'h-32',
          'text-blue-gray-600',
          'font-semibold',
          'text-sm',
        )}
      >
        {clubDescription}
        <GradientOverlay />
      </div>

      <div className="flex-grow-0">
        {isMember ? (
          <Link to={getClubLink({ studentId, juniClubId: juniClub._id })}>
            <Button variant="secondary" width="full" hasArrowIcon>
              Go To Club
            </Button>
          </Link>
        ) : (
          <Button
            variant="secondary"
            width="full"
            hasArrowIcon
            onClick={handleJoinClick}
          >
            Join Club
          </Button>
        )}
      </div>
    </ClubCardWrapper>
  );
};

export default ClubCard;
