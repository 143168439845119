import React, { RefObject, useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import commentWithEllipsisImg from 'images/comment_with_ellipsis.svg';
import { Annotation } from 'components/RichTextAnnotator/components';
import {
  ANNOTATIONS_COLUMN_WIDTH,
  ANNOTATIONS_MARGIN,
} from 'components/RichTextAnnotator/constants';

interface AnnotationData {
  annotation: string;
  highlightColor: string;
  entityKey: string;
  highlightRef: RefObject<HTMLSpanElement>;
}
interface AnnotationRenderData extends AnnotationData {
  highlightTop: number;
  annotationTop: number;
}
interface Props {
  annotationsData: AnnotationData[];
}

const Annotations: React.FC<Props> = ({ annotationsData }) => {
  const annotationsContainerRef = useRef<HTMLDivElement>(null);
  const [annotations, setAnnotations] = useState<AnnotationRenderData[]>([]);
  const [annotationBottomsByEntityKey, setAnnotationBottomsByEntityKey] = useState<
    Record<string, number>
  >({});

  const setAnnotationBottom = (entityKey: string, bottom: number) => {
    setAnnotationBottomsByEntityKey(annotationBottomsByEntityKey => ({
      ...annotationBottomsByEntityKey,
      [entityKey]: bottom,
    }));
  };
  useEffect(() => {
    // sort annotations by the top position of their associated highlight
    const sortedAnnotations = annotationsData
      .map((annotationData: AnnotationData) => {
        const boundingRect = annotationData.highlightRef.current!.getBoundingClientRect();
        return {
          ...annotationData,
          highlightTop: boundingRect.top + window.scrollY,
        };
      })
      .sort((a: any, b: any) => a.highlightTop - b.highlightTop);

    const annotations: AnnotationRenderData[] = [];
    let prevAnnotationData: AnnotationRenderData | undefined;
    for (let i = 0; i < sortedAnnotations.length; i += 1) {
      const annotationData = sortedAnnotations[i];

      let prevAnnotationBottom = 0;
      if (prevAnnotationData) {
        prevAnnotationBottom =
          annotationBottomsByEntityKey[prevAnnotationData.entityKey] || 0;
      }

      let annotationTop = annotationData.highlightTop;
      // adjust the top position of an annotation if it will overlap the one above it
      if (prevAnnotationBottom > annotationData.highlightTop - ANNOTATIONS_MARGIN) {
        annotationTop = prevAnnotationBottom + ANNOTATIONS_MARGIN;
      }
      const annotation = {
        ...annotationData,
        annotationTop,
      };
      prevAnnotationData = annotation;
      annotations.push(annotation);
    }
    setAnnotations(annotations);
  }, [annotationsData, annotationBottomsByEntityKey]);

  const containerTop = annotationsContainerRef?.current
    ? annotationsContainerRef.current.getBoundingClientRect().top
    : undefined;

  return (
    <StyledAnnotations ref={annotationsContainerRef}>
      {annotations.length === 0 && (
        <StyledNoAnnotationsMessageContainer>
          <StyledImageContainer>
            <img
              src={commentWithEllipsisImg}
              alt="Annotation bubble with an ellipsis"
            />
          </StyledImageContainer>
          <div className="msg-text primary">You don't have any annotations yet</div>
          <div className="msg-text secondary">Your annotations will appear here</div>
        </StyledNoAnnotationsMessageContainer>
      )}
      {annotations.map((annotationData: AnnotationRenderData) => (
        <Annotation
          key={annotationData.entityKey}
          annotation={annotationData.annotation}
          highlightColor={annotationData.highlightColor}
          annotationTop={
            containerTop ? annotationData.annotationTop - containerTop : -1000
          }
          entityKey={annotationData.entityKey}
          setAnnotationBottom={setAnnotationBottom}
        />
      ))}
    </StyledAnnotations>
  );
};

const StyledAnnotations = styled.div`
  height: 100%;
  width: ${ANNOTATIONS_COLUMN_WIDTH}px;
  min-width: ${ANNOTATIONS_COLUMN_WIDTH}px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledNoAnnotationsMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: ${ANNOTATIONS_COLUMN_WIDTH - 2 * ANNOTATIONS_MARGIN}px;
  margin-top: 3rem;
  .msg-text {
    text-align: center;
    &.primary {
      color: hsl(206, 39%, 33%);
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-top: 2rem;
    }
    &.secondary {
      color: hsl(202, 33%, 35%);
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 0.75rem;
    }
  }
`;
const StyledImageContainer = styled.div`
  padding: 1.2rem 1rem 1.6rem 1rem;
  background: hsl(197, 100%, 96%);
  border: 2px solid hsl(197, 100%, 79%);
  border-radius: 8px;
  img {
    margin: 15px 15px 0 15px;
    display: block;
  }
`;

export default Annotations;
