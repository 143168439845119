import generateRest from 'services/generateRest';
import { ICustomScratchProject } from 'components/jide/env/JideEnvScratch';
import juniAxios from './axios';

const scratchProjectsService = generateRest<ICustomScratchProject>(
  'playground/scratch',
  {
    noEnvelope: true,
  },
);

export const pingScratchUrl = async (url: string) => {
  try {
    const urlStatus = await juniAxios.get(`/playground/ping_scratch_url?url=${url}`);
    return urlStatus.status;
  } catch (e) {
    return e.response?.status;
  }
};

export const playgroundService = () => ({
  fetchAllProjects: async ({
    studentId,
    unlocked,
  }: {
    studentId: string;
    unlocked?: Array<string>;
  }) =>
    juniAxios.post(
      '/learner/all_projects',
      { unlocked: unlocked || [] },
      { params: { studentId } },
    ),
});

export default scratchProjectsService;
