import React from 'react';
import { registerUserAction } from 'services/juniverse';
import { DCF_DEFAULT_ENTRY_POINT } from 'constants/links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  studentProjectId?: string;
  juniverseUsername?: string;
}

export const JuniverseGet2WeeksFree: React.FC<Props> = ({
  studentProjectId,
  juniverseUsername,
}) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={DCF_DEFAULT_ENTRY_POINT}
    onClick={() =>
      registerUserAction({
        action: 'click get 2 weeks free - user profile',
        studentProjectId,
        juniverseUsername,
      })
    }
    className="btn secondary"
  >
    Get Started{' '}
    <span className="pl-1">
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </span>
  </a>
);
