import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';

const SESSION_NOTES_HANDBOOK_LINKS = `The Instructor Handbook has more information about [writing great session notes](/teacher/handbook/teaching/session-notes/basics) and [supported text formatting](/teacher/handbook/teaching/session-notes/markdown)!`;

const COMPUTER_SCIENCE_SESSION_NOTES_INSTRUCTIONS = `Please enter your notes for this session, which will be viewable to the student and their parents. Please be sure to include:

  - How the student did with their **homework assignment**, if applicable
  - The **main concepts & projects** covered in class today (please capitalize project names appropriately, e.g. Turtle Race or Crazy Nametags)
  - The student's **overall progress** (i.e. general things they are doing well with or need to work on)
  - The student's **homework assignment** for this week (always aim to assign 60 minutes of homework)

${SESSION_NOTES_HANDBOOK_LINKS}`;

const MATHEMATICS_SESSION_NOTES_INSTRUCTIONS = `Please enter your notes for this session, which will be viewable to the student and their parents. Please be sure to include:

- How the student did with their **homework assignment**, if applicable
- The **main concepts & problem sets** covered in class today (please capitalize problem set names appropriately, e.g. Multiplying Fractions)
- The student's **overall progress** (i.e. general things they are doing well with or need to work on)
- The student's **homework assignment** for this week (always aim to assign 60 minutes of homework)

${SESSION_NOTES_HANDBOOK_LINKS}`;

const ENGLISH_SESSION_NOTES_INSTRUCTIONS = `Please enter your notes for this session, which will be viewable to the student and their parents. Address session notes to the the student's parents, and follow these steps:

  - Note how the student did with their **homework assignment**, if applicable
  - **Summarize the session**, and mention a detail about your student to show that you see the student as a person, not just a student
  - Comment on their proficiency in the **Learning Targets** for the module
  - Provide **two specific next steps** the student can take to continue developing proficiency in the Learning Targets
  - Assign the student's homework (always aim to assign **60 minutes of homework**)

${SESSION_NOTES_HANDBOOK_LINKS}`;

const INVESTING_SESSION_NOTES_INSTRUCTIONS = ENGLISH_SESSION_NOTES_INSTRUCTIONS;

const COMPUTER_SCIENCE_SESSION_NOTES_PLACEHOLDER = `**Homework check**: Natalia did a great job completing the Rock, Paper, Scissors game for homework, and she was able to add a bonus feature so that it asks the user if they would like to continue playing when the game ends. Nice work!

**Our class today**: Today, we reviewed conditionals to answer some of Natalia's questions, and then we worked on some more complex projects involving string manipulation and various mathematical operators. We programmed a basic calculator, and Natalia figured out how to program more advanced functions: mod and exponents. Remember, here are some examples of equations using mod (which is represented with the \`%\` symbol in Python):

* 5 mod 2 = 1
* 4 mod 2 = 0
* 3 mod 2 = 1
* 10 mod 5 = 0
* 13 mod 5 = 3

**Overall progress**: Overall, I always appreciate Natalia's positive attitude and enthusiasm for programming. She is making great progress through this course. One thing she can continue to work on is planning out how she will tackle a project _before_ she jumps into coding.

**Assignment**: Natalia's assignment is to finish the FizzBuzz project by adding code to account for if the number is a multiple of 5 and if the number is a multiple of both 3 and 5 (hint: consider where in the code this line should go!). Remember the basic structure of conditional statements in Python:

\`\`\`
if condition:
  do something
elif condition:
  do something else
else:
  do this if none of the above conditions are true
\`\`\`

Good work today!`;

const MATHEMATICS_SESSION_NOTES_PLACEHOLDER = `**Homework check**: Amy did a great job in class today! Today we began class by going over the homework. Amy did well with the homework and put in solid effort into completing the problem set. She had some trouble with transversals and corresponding angles, so we focused on reviewing problems involving these concepts.

**Our class today**: After reviewing the homework, we went over special right triangles. In particular, we practiced how to use the ratios that correspond with 45-45-90 and 30-60-90 triangles. Once Amy gained independence with this topic, we discussed congruence and similarity. We reviewed what congruence and similarity are, along with the different types of congruence and similarity in the context of triangles.

**Overall progress**: Overall, Amy is doing well and will benefit from some independent practice with each of these topics in order to solidify and retain her knowledge about these concepts. She'll get this practice from her assignment this week. Great job, Amy!

**Assignment**:

* PAB6 Problem Set 2: Pythagorean Theorem and Special Right Triangles
* PAB6 Problem Set 3: Module Review
* PAB7 Problem Set 1: Congruence and Similarity, problems #1-7
`;

const ENGLISH_SESSION_NOTES_PLACEHOLDER = `It was a delight to meet Joshua in our first session today! He told me that he is an avid reader, which will set him up for success as an ${
  SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH]
} student! Today, we discussed the concept of **main ideas** in fiction and practiced identifying main ideas and **supporting them with textual evidence**. We also practiced summarizing a main idea in original language using Jacob and Wilhelm Grimm's story "The Fox and the Horse". Joshua is already demonstrating proficiency in identifying a main idea and explaining how textual evidence supports it.

The next steps for Joshua in this module will be to continue practicing summarizing the main idea in his own words and to practice writing concise main idea statements. Remember, some helpful tips include:

* Highlight text that might represent the main idea as you're reading
* Try to describe the main idea out loud after you finish reading

**Assignment**: For homework, Joshua should complete MSA1 Project 2: More Main Idea Practice. If he completes that project in under 60 minutes, he can move on to MSA1 Project 3: Your Main Idea. Additionally, I promised him some book recommendations. Given his love of fantasy novels, I'd recommend _The Golden Compass_ by Philip Pullman and _Sabriel_ by Garth Nix, both of which I read when I was around his age!
`;

const INVESTING_SESSION_NOTES_PLACEHOLDER = `It was a delight to meet Joshua in our first session today! He told me that he is interested in business which will make him an excellent ${
  SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING]
} student! Today, we discussed the concept of **the stock market** and completed a project where Joshua purchased his first stock in the Investopedia Simulator! Through this project, he was able to think out loud about the reasons why he chose his stock by analyzing historical performance and reflecting on what makes companies successful.

The next steps for Joshua in this module will be to continue monitoring his stock throughout the next week and purchase 2 more stocks to add to his portfolio. Remember, some helpful tips include:

* Looking at historical stock prices of a stock you're interested in.
* Reading current events to identify reasons companies and events that would impact the stock market.

**Assignment**: For homework, Joshua should complete INV1 Project 1: Joining the Investopedia Simulator. Additionally, Joshua should also use the Recording Studio to reflect on the project and publish the project so it's added to their User Profile.
`;

export const getInstructionsAndPlaceholder = (subject: string) => {
  switch (subject) {
    case SUBJECTS.MATHEMATICS:
      return {
        instructions: MATHEMATICS_SESSION_NOTES_INSTRUCTIONS,
        placeholder: MATHEMATICS_SESSION_NOTES_PLACEHOLDER,
      };
    case SUBJECTS.ENGLISH:
      return {
        instructions: ENGLISH_SESSION_NOTES_INSTRUCTIONS,
        placeholder: ENGLISH_SESSION_NOTES_PLACEHOLDER,
      };
    case SUBJECTS.INVESTING:
      return {
        instructions: INVESTING_SESSION_NOTES_INSTRUCTIONS,
        placeholder: INVESTING_SESSION_NOTES_PLACEHOLDER,
      };
    default:
      return {
        instructions: COMPUTER_SCIENCE_SESSION_NOTES_INSTRUCTIONS,
        placeholder: COMPUTER_SCIENCE_SESSION_NOTES_PLACEHOLDER,
      };
  }
};

export const SESSION_STATE_DESCRIPTION = {
  upcoming:
    'This session is upcoming. Please make sure to log it within 24 hours after the session is complete.',
  incomplete:
    'This session still needs to be logged. If you taught this session, please log the session by clicking **Start Logging** in the status bar at the top of this page and checking off the curriculum that is in progress or completed.',
  missed:
    'This session was missed (i.e. no-show without 24 hour notice, so session will not be rescheduled).',
  inProgress:
    "This session log is in progress. You can add session notes here once you've checked off the curriculum that is in progress or completed, and click **End Logging** in the status bar at the top of this page.",
};
