// generate generic REST service
import juniAxios from 'services/axios';
import qs from 'qs';

export type StringDict = Record<string, any>;

const paramsSerializer = (params: any) => qs.stringify(params);

export type OptionalId<T> = Omit<T, '_id'> & {
  _id?: string;
};

interface Options<T, PopulatedType = T> {
  processItem?: (item: PopulatedType) => PopulatedType;
  noEnvelope?: boolean; // required for new rest endpoints
}

export interface RestService<T, CreateType = T, PopulatedType = T> {
  list: (query?: StringDict, options?: StringDict) => Promise<[PopulatedType]>;
  get: (id: string, options?: StringDict) => Promise<PopulatedType>;
  create: (
    data: OptionalId<CreateType>,
    options?: StringDict,
  ) => Promise<PopulatedType>;
  update: (
    id: string,
    data: Partial<T>,
    options?: StringDict,
  ) => Promise<PopulatedType>;
  remove: (id: string, options?: StringDict) => Promise<PopulatedType>;
}

function generateRest<T, CreateType = T, PopulatedType = T>(
  url: string,
  options?: Options<T, PopulatedType>,
): RestService<T, CreateType, PopulatedType> {
  const processItem =
    options && options.processItem ? options.processItem : (x: PopulatedType) => x;
  const noEnvelope = options?.noEnvelope;
  return {
    list: async (query?, options?) =>
      (
        await juniAxios.get(`/${url}`, {
          params: { ...query, ...options },
          paramsSerializer,
        })
      ).data.data.map((item: PopulatedType) => processItem(item)),
    get: async (id, options?) => {
      const response = await juniAxios.get(`/${url}/${id}`, {
        params: options,
        paramsSerializer,
      });
      return processItem(noEnvelope ? response.data : response.data.data);
    },
    create: async (data, options?) => {
      const response = await juniAxios.post(`/${url}`, data, {
        params: options,
        paramsSerializer,
      });
      return processItem(noEnvelope ? response.data : response.data.data);
    },
    update: async (id, data, options?) => {
      const response = await juniAxios.patch(`/${url}/${id}`, data, {
        params: options,
        paramsSerializer,
      });
      return processItem(noEnvelope ? response.data : response.data.data);
    },
    remove: async (id, options?) => {
      const response = await juniAxios.delete(`/${url}/${id}`, {
        params: options,
        paramsSerializer,
      });
      return noEnvelope ? response.data : response.data.data;
    },
  };
}

export default generateRest;
