import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { Icon } from 'core-components';
import useDimensions from 'hooks/dimensions';
import NavMenuButton from './NavMenuButton';

const NavSettingsButtonWrapper = styled.div<any>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: ${props => !props.mobileView && '12px'};
  padding-left: 8px;

  &:after {
    z-index: -1;
    top: 18px;
    left: 0;
    bottom: 18px;
    right: 0;
    content: '';
    position: absolute;
    border-left: 1px solid #c1bce0;
  }

  @media only screen and (max-width: 1170px) {
    padding: 8px;
  }
`;

interface NavSettingsButtonProps {
  dropdownOptions: React.ReactNode[];
}

const NavSettingsButton: FC<NavSettingsButtonProps> = ({ dropdownOptions }) => {
  const { width } = useDimensions();
  const mobileView = width <= 920;

  return (
    <NavSettingsButtonWrapper mobileView={mobileView}>
      <NavMenuButton
        dropdownOptions={dropdownOptions}
        hoverState={!mobileView}
        settingsButton
      >
        <Icon.Hamburger className="p-2 text-j-dark-600" />
      </NavMenuButton>
    </NavSettingsButtonWrapper>
  );
};

export default NavSettingsButton;
