import React, { FC } from 'react';
import MaskedInput from 'react-maskedinput';

import error from 'images/error.svg';

export const makeInputClasslist = (validationError = false, isMaxHeight = true) => `
  flex 
  flex-grow 
  my-1
  p-3 
  h-4
  ${isMaxHeight ? 'max-h-4' : ''}
  text-base
  rounded-lg 
  border 
  text-blue-gray-800
  border-solid 
  ${validationError ? 'border-red-500' : 'border-blue-gray-200'}
  shadow-none 
  placeholder-blue-gray-200
  tracking-normal
  font-normal
  font-sans
`;

export const ValidationError: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div
    className={`
      text-sm
      my-2
      font-bold
      text-red-500
      flex
      items-center
    `}
  >
    <img src={error} className="mr-2" alt="" />
    {children}
  </div>
);

const InputField: FC<{
  type?:
    | 'text'
    | 'textarea'
    | 'number'
    | 'tel'
    | 'email'
    | 'url'
    | 'date'
    | 'select';
  value: string | number;
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>,
  ) => void;
  placeholder?: string;
  labelClassName?: string;
  labelTextStyles?: string;
  inputClassName?: string;
  validationError?: string;
  label?: string;
  id?: string;
  name?: string;
  dateFormat?: string;
  helperText?: string;
  textBelow?: string;
  textRight?: string;
  options?: Array<{ label: string; value: string | number }>;
}> = ({
  type = 'text',
  value,
  onChange,
  labelClassName,
  labelTextStyles,
  inputClassName,
  placeholder,
  validationError,
  onBlur,
  label,
  id,
  name,
  helperText,
  textBelow,
  textRight,
  options,
}) => {
  const helperTextClassList = `
    lg:w-full
    text-sm
    text-blue-gray-500
  `;

  return (
    <div className={`my-2 flex flex-col ${labelClassName ?? ''}`}>
      {(label || textRight) && (
        <div className="flex justify-between mb-1">
          {label && (
            <label
              className={
                labelTextStyles ??
                'font-bold text-blue-gray-900 text-sm whitespace-nowrap'
              }
              htmlFor={id ?? name}
            >
              {label}
            </label>
          )}
          {textRight && (
            <span className={`${helperTextClassList} text-right`}>{textRight}</span>
          )}
        </div>
      )}

      {helperText && <span className={helperTextClassList}>{helperText}</span>}

      {type === 'date' ? (
        <MaskedInput
          className={`${makeInputClasslist()} ${inputClassName ?? ''}`}
          name={name ?? id}
          id={id}
          value={value}
          pattern="[0-9]*"
          mask="11/11/1111"
          placeholder="mm/dd/yyyy"
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : type === 'select' ? (
        <select onChange={onChange} className={inputClassName ?? ''}>
          {options?.map(opt => <option value={opt.value}>{opt.label}</option>) ?? []}
        </select>
      ) : type === 'textarea' ? (
        <textarea
          id={id}
          data-cy={id}
          name={name ?? id}
          className={`${inputClassName ?? ''}  h-40 resize-y ${makeInputClasslist(
            !!validationError,
            false,
          )}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        <input
          id={id}
          data-cy={id}
          name={name ?? id}
          className={`${inputClassName ?? ''} ${makeInputClasslist()}`}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {textBelow && <span className="text-xs text-blue-gray-500">{textBelow}</span>}
      {validationError && <ValidationError>{validationError}</ValidationError>}
    </div>
  );
};

export default InputField;
