import React, { useEffect } from 'react';
import * as R from 'ramda';
import { ErrorableLoading } from 'components/ui';
import { HelpCenterContent } from 'generated/graphql';
import { Link, useRouteMatch } from 'react-router-dom';
import { NewCard } from 'core-components';
import { ArrowRight } from 'core-components/Icon';
import Breadcrumbs from './Breadcrumbs';
import { StyledHeader } from './styles';
import CategoryIcon from './icons/CategoryIcon';

interface MatchProps {
  categoryId: string;
  sectionId?: string;
}

interface Props {
  helpCenterContent: HelpCenterContent;
  baseUrl: string;
}

const CategoryExplore: React.FC<Props> = ({ helpCenterContent, baseUrl }) => {
  const match = useRouteMatch<MatchProps>();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const { categories, sections, articles } = helpCenterContent;
  const category = categories.find(
    category => category.id === match.params?.categoryId,
  );
  if (!category) {
    return <ErrorableLoading error="Unable to load help center content" />;
  }

  const breadcrumbs = [
    { to: baseUrl, name: 'Help Desk' },
    {
      to: `${baseUrl}/categories/${category.id}`,
      name: category.name,
    },
  ];

  return (
    <div className="p-10 lg:px-48">
      <div className="space-y-6">
        <StyledHeader>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </StyledHeader>

        <div className="space-y-6">
          {R.sortBy(R.prop('position'))(sections)
            .filter(section => section.category_id === category.id)
            .map(section => (
              <NewCard key={section.id} padding="4">
                <div className="pb-2">
                  <div className="flex items-center pb-4">
                    <div className="w-5 h-5 p-1 flex items-center justify-center rounded-lg mr-4 bg-j-green-100">
                      <div className="w-4 h-4">
                        <CategoryIcon categoryId={category.id} />
                      </div>
                    </div>
                    <div className="text-lg leading-snug font-semi-bold text-j-dark-600">
                      {section.name}
                    </div>
                  </div>
                  <NewCard.Divider />
                  <div className="pt-4 space-y-2 text-md">
                    {R.sortBy(R.prop('position'))(articles)
                      .filter(article => article.section_id === section.id)
                      .map(article => (
                        <div key={article.id}>
                          <Link
                            to={`${baseUrl}/articles/${article.id}`}
                            className="no-underline"
                          >
                            <div className="flex bg-j-gray-200 text-j-dark-600 py-3 px-4 rounded-lg justify-between items-center">
                              <div className="text-sm leading-normal font-normal">
                                {article.title}
                              </div>
                              <div className="w-6 h-6 flex items-center">
                                <ArrowRight />
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </NewCard>
            ))}
        </div>
      </div>
    </div>
  );
};
export default CategoryExplore;
