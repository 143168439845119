import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import SubTree from './SubTree';
import TreeNode from './TreeNode';
import './index.css';

const defaultRenderLabel = (item, isActive) => (
  <Link className={classNames({ 'ui-tree-active': isActive })} to={item.url}>
    {item.name}
  </Link>
);

const Tree = ({
  className,
  items = [],
  openIds = {},
  activeIds = {},
  onToggle,
  renderLabel = defaultRenderLabel,
}) => (
  <div className={classNames('ui-tree', className)}>
    {items.map(item => (
      <TreeNode
        key={item.id}
        opened={openIds[item.id]}
        onToggle={() => onToggle(item.id)}
        hasChildren={item.children && item.children.length}
        subtree={
          <SubTree
            items={item.children}
            openIds={openIds}
            activeIds={activeIds}
            onToggle={onToggle}
            renderLabel={renderLabel}
          />
        }
      >
        {renderLabel(item, activeIds[item.id])}
      </TreeNode>
    ))}
  </div>
);

// items:
// array of { id, name, url (relative url), children (array of items)}
// onToggle: (item, isActive)
Tree.propTypes = {
  items: PropTypes.array,
  openIds: PropTypes.object,
  activeIds: PropTypes.object,
  renderLabel: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
};

export default Tree;
