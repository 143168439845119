const LOCALE = 'en-US';

const formatNumberAsCurrency = (input: number) =>
  new Intl.NumberFormat(LOCALE, {
    style: 'currency',
    currency: 'USD',
    // input % 1 > 1 would indicate that there are decimal points.
    // This sets it to format $590.05 with decimals, but $590.00 as $590
    minimumFractionDigits: input % 1 === 0 ? 0 : 2,
  }).format(input);

export default formatNumberAsCurrency;
