/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import io from 'socket.io-client';
import juniAxios from 'services/axios.js';
import MsgQueue from 'components/Whiteboard/MsgQueue';
import GridTool from './grid_tool.js';
import PencilTool from './pencil_tool.js';
import EraserTool from './eraser_tool.js';
import ImageTool from './image_tool.ts';
import ShapeTool from './shape_tool.js';
import TextTool from './text_tool.ts';
import getMigratedData from './text_tool_migration.js';

import { getOffsetTop, getOffsetLeft } from '../domUtils.js';

const DIYANA_STUDENT_ID = '5f3849e5795a6030fc6e49ba';
const RISHEN_STUDENT_ID = '5ef3eb4df5baf60fb3eff791';
const CHANDANA_STUDENT_ID = '604f7b72d455940e3aa764bc';

class Board {
  initialLoaded = false;

  constructor(
    svg,
    initialColor,
    initialToolName,
    triggerHtmlRender,
    id,
    withSaving,
    env,
    isInModal,
    handlePageCountUpdate,
    setState,
    handleWhiteboardEdit,
    readOnly,
  ) {
    this.canvas = svg;
    const pencil = new PencilTool(this);
    const eraser = new EraserTool(this);
    const line = new ShapeTool(this, 'line');
    const arrow = new ShapeTool(this, 'arrow');
    const rect = new ShapeTool(this, 'rect');
    const ellipse = new ShapeTool(this, 'ellipse');
    const text = new TextTool(this);
    const grid = new GridTool(this);
    const image = new ImageTool(this);
    this.tools = {
      [pencil.name]: pencil,
      [eraser.name]: eraser,
      [text.name]: text,
      [grid.name]: grid,
      [line.name]: line,
      [arrow.name]: arrow,
      [rect.name]: rect,
      [ellipse.name]: ellipse,
      [image.name]: image,
    };
    this.toolNames = Object.keys(this.tools);
    this.readOnly = readOnly;
    this.curTool = this.toolNames.includes(initialToolName)
      ? this.tools[initialToolName]
      : this.tools[pencil.name];
    if (!this.readOnly) {
      this.setEventListeners(this.curTool);
    }
    this.canvas.style.cursor = this.readOnly
      ? 'auto'
      : this.curTool.customCursor || 'auto';
    this.color = initialColor;
    this.editHistory = [];
    this.undoStack = [];
    this.triggerHtmlRender = triggerHtmlRender;
    this.id = id;
    this.saveEnabled = withSaving;
    this.manualReconnectTimeout = null;
    this.isInModal = isInModal;
    this.handlePageCountUpdate = handlePageCountUpdate;
    this.setState = setState;
    this.shiftKeyIsPressed = false;
    this.handleWhiteboardEdit = handleWhiteboardEdit;

    if (this.readOnly) {
      this.fetchReadonlyBoardData();
    } else if (this.saveEnabled) {
      // socket event listeners
      const wssUrl =
        env === 'production'
          ? 'wss://wb.junilearning.com:443'
          : 'ws://localhost:8000';

      this.socket = io(wssUrl, {
        query: `boardId=${this.id}`,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        transports: ['websocket'],
      });

      this.msgQueue = MsgQueue(this.socket.emit.bind(this.socket), x =>
        setState({ anyUnacked: !x }),
      );

      this.socket.on('connect_error', err => {
        console.log('connect_error');
        console.log(err);
        this.serverDown();
      });
      if (
        this.id.includes(DIYANA_STUDENT_ID) ||
        this.id.includes(RISHEN_STUDENT_ID) ||
        this.id.includes(CHANDANA_STUDENT_ID)
      ) {
        this.socket.on('reconnect_attempt', () => {
          console.log('reconnect_attempt');
          this.socket.io.opts.transports = ['polling', 'websocket'];
        });
      }
      this.socket.on('error', err => {
        console.log(err);
      });
      this.socket.on('board data', this.handleBoardDataLoaded);
      this.socket.on('load failed', () => {
        this.setState({
          isLoadingBoard: false,
          failedToLoadBoard: true,
        });
      });
      this.socket.on('update board', this.handleWsMsg);
      this.socket.on('disconnect', reason => {
        this.msgQueue.close();
        console.debug('DISCONNECT', reason);
        if (reason !== 'io client disconnect') {
          this.lostConnection();
        }
        if (reason === 'io server disconnect') {
          this.manualReconnectTimeout = setTimeout(() => {
            this.socket.connect();
          }, 1500);
        }
      });
      this.socket.on('server down', this.serverDown);
      this.socket.on('connect', () => {
        // HACK ALERT!! We're taking over message retransmission, so we need to stop socket.io from doing it
        this.socket.sendBuffer = [];

        this.setState({
          isLoadingBoard: true,
          failedToLoadBoard: false,
          wsServerIsDown: false,
          lostConnection: false,
        });
        if (this.manualReconnectTimeout) {
          clearTimeout(this.manualReconnectTimeout);
          this.manualReconnectTimeout = null;
        }
        console.debug('requesting board data');
        this.socket.emit('load board', { boardId: this.id });
      });
    }
  }

  disconnect = () => {
    if (this.socket) {
      this.socket.disconnect();
    }
  };

  fetchReadonlyBoardData = async () => {
    try {
      const boardDataRes = await juniAxios.get(
        `/playground/whiteboards/raw_whiteboard_data?boardId=${this.id}`,
      );
      this.handleBoardDataLoaded(boardDataRes.data);
    } catch (e) {
      if (e.response.status === 404) {
        this.setState({ failedToLoadBoard: true });
      }
    }
  };

  lostConnection = () => {
    this.setState({
      lostConnection: true,
      isLoadingBoard: false,
    });
  };

  serverDown = () => {
    this.setState({
      wsServerIsDown: true,
      isLoadingBoard: false,
      failedToLoadBoard: this.initialLoaded ? undefined : true,
    });
  };

  setShiftKeyPressed = shiftKeyIsPressed => {
    this.shiftKeyIsPressed = shiftKeyIsPressed;
  };

  getJideWrapper = () => {
    let wrapper = this.canvas.parentElement;
    let count = 0;
    while (!wrapper.className.includes('jide-main-content') && count < 10) {
      wrapper = wrapper.parentElement;
      count += 1;
    }
    return wrapper.className.includes('jide-main-content') ? wrapper : null;
  };

  broadcast = evt => {
    if (this.saveEnabled) {
      const data = {
        boardId: this.id,
        ...evt,
      };

      if (!this.readOnly) {
        this.handleWhiteboardEdit();
        this.msgQueue.enqueue('update board', data);
      }
    }
  };

  handleBoardDataLoaded = data => {
    // Pencil tool has caching and stuff... we want to get rid of that
    this.tools.pencil = new PencilTool(this);

    while (this.canvas.firstChild) {
      this.canvas.removeChild(this.canvas.firstChild);
    }

    if (data && data.board) {
      for (let i = 0; i < data.board.length; i += 1) {
        this.handleWsMsg(data.board[i]);
      }
    }

    if (this.msgQueue !== undefined) {
      // Did we have any edits while offline? Let's reapply them after syncing.
      this.msgQueue.queue.current.forEach(msg => {
        this.handleWsMsg(msg.payload);
      });
      // if we were disconnected before, this tells the msgs to retransmit
      this.msgQueue.reopen();
    }

    this.initialLoaded = true;
    this.setState({ isLoadingBoard: false });
  };

  migrateText = data => {
    const migratedData = getMigratedData(this.canvas, this, data);
    // render modified text2 data
    this.handleToolMsg(migratedData);
    // broadcast text2 data, overwriting the original in S3
    this.broadcast(migratedData);
  };

  handleWsMsg = data => {
    if (data.type === 'page_count') {
      this.handlePageCountUpdate(data.pageCount);
    } else {
      const couldClear = this.canClear();

      if (
        data.tool &&
        data.tool === 'text' &&
        data.width === undefined &&
        data.height === undefined
      ) {
        this.migrateText(data);
        return;
      }

      if (data.tool) {
        this.handleToolMsg(data);
      }
      if (data.children && data.children.length > 0) {
        for (let i = 0; i < data.children.length; i += 1) {
          this.handleWsMsg(data.children[i]);
        }
      }
      if (this.canClear() !== couldClear) {
        this.triggerHtmlRender();
      }
    }
  };

  handleToolMsg = data => {
    const toolName = data.tool;
    const tool = this.tools[toolName];
    if (tool) {
      tool.handleWsMsg(data);
    }
  };

  getEventListener = eventHandler => e => {
    const canvasWidth = this.canvas.parentElement.offsetWidth;
    const canvasHeight = this.canvas.parentElement.offsetHeight;
    let x = e.pageX - getOffsetLeft(this.canvas.parentElement);
    let y =
      (this.isInModal ? e.clientY : e.pageY) -
      getOffsetTop(this.canvas.parentElement);
    const jideWrapper = this.getJideWrapper();
    if (jideWrapper) {
      x += jideWrapper.scrollLeft;
    }
    y = y < 0 ? 0 : y;
    y = y > canvasHeight ? canvasHeight : y;
    x = x < 0 ? 0 : x;
    x = x > canvasWidth ? canvasWidth : x;
    return eventHandler(e, x, y);
  };

  getTouchEventListener = eventHandler => e => {
    if (e.changedTouches.length === 1) {
      const touch = e.changedTouches[0];
      const canvasWidth = this.canvas.parentElement.offsetWidth;
      const canvasHeight = this.canvas.parentElement.offsetHeight;
      let x = touch.pageX - getOffsetLeft(this.canvas.parentElement);
      let y =
        (this.isInModal ? touch.clientY : touch.pageY) -
        getOffsetTop(this.canvas.parentElement);
      y = y < 0 ? 0 : y;
      y = y > canvasHeight ? canvasHeight : y;
      x = x < 0 ? 0 : x;
      x = x > canvasWidth ? canvasWidth : x;
      return eventHandler(e, x, y);
    }
    return true;
  };

  prepareEventListeners = tool => {
    const eventListeners = tool.eventListeners || {};
    tool.eventListeners = eventListeners;
    for (const event in tool.eventHandlers) {
      eventListeners[event] = event.includes('touch')
        ? this.getTouchEventListener(tool.eventHandlers[event])
        : this.getEventListener(tool.eventHandlers[event]);
    }
  };

  setEventListeners = tool => {
    if (!tool.eventListeners) {
      this.prepareEventListeners(tool);
    }
    for (const event in tool.eventListeners) {
      this.canvas.addEventListener(event, tool.eventListeners[event]);
    }
  };

  switchToTool = toolName => {
    if (this.toolNames.includes(toolName) && toolName !== this.curTool.name) {
      if (this.curTool.name === 'text') {
        this.curTool.handleClickOutsideTextInput();
      }

      for (const event in this.curTool.eventListeners) {
        this.canvas.removeEventListener(event, this.curTool.eventListeners[event]);
      }
      this.setEventListeners(this.tools[toolName]);
      this.curTool = this.tools[toolName];
      this.canvas.style.cursor = this.curTool.customCursor || 'auto';
    }
  };

  // inserts the new child at the end of the canvas's current array of children, but before any delete-grid or delete-image
  // button children, which need to stay at the end of the array of children so they render above all other child elements
  appendChild = newChild => {
    const firstDeleteButton = Array.from(this.canvas.children).find(child => {
      const elemClass = child.getAttribute('class');
      return (
        elemClass &&
        (elemClass.includes('wb-grid-delete-btn') ||
          elemClass.includes('wb-image-delete-btn'))
      );
    });
    this.canvas.insertBefore(newChild, firstDeleteButton);
  };

  appendToEditHistory = (editData, maintainUndoStack) => {
    if (editData && (editData.elem || editData.elems)) {
      const couldUndo = this.canUndo();
      this.editHistory.push(editData);
      if (!maintainUndoStack) {
        this.undoStack = [];
      }
      if (!couldUndo || !this.canRedo()) {
        this.triggerHtmlRender();
      }
    }
  };

  appendToUndoStack = editData => {
    if (editData && (editData.elem || editData.elems)) {
      const couldRedo = this.canRedo();
      this.undoStack.push(editData);
      if (!couldRedo || !this.canUndo()) {
        this.triggerHtmlRender();
      }
    }
  };

  canUndo = () => {
    if (this.isEditingText()) return false;
    return this.editHistory.length > 0;
  };

  canRedo = () => {
    if (this.isEditingText()) return false;
    return this.undoStack.length > 0;
  };

  isEditingText = () =>
    this.curTool && this.curTool.name === 'text' && this.tools.text.inEditMode;

  canClear = () => {
    if (this.isEditingText()) return false;
    return this.canvas.firstChild != null;
  };

  newSVGChild = (name, attrs) => {
    const el = document.createElementNS(this.canvas.namespaceURI, name);
    if (attrs && typeof attrs === 'object') {
      for (const k in attrs) {
        if (k === 'xlink:href' || k === 'href') {
          el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', attrs[k]);
        } else if (k === 'onClick') {
          el.onclick = attrs[k];
        } else {
          el.setAttribute(k, attrs[k]);
        }
      }
    }
    return el;
  };

  generateUniqueID = (prefix, suffix) => {
    let result = '';
    for (let j = 0; j < 32; j += 1) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result += '-';
      }
      const ch = Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase();
      result += ch;
    }
    if (this.id) {
      result = `${this.id}-${result}`;
    }
    if (prefix) {
      result = `${prefix}-${result}`;
    }
    if (suffix) {
      result = `${result}-${suffix}`;
    }
    return result;
  };

  unRemoveChild = elem => {
    const elemClass = elem.getAttribute('class');
    if (elemClass && elemClass.includes('wb-grid')) {
      this.tools.grid.newSVGGrid(
        elem.id,
        elem.x.baseVal.value,
        elem.y.baseVal.value,
        elem.width.baseVal.value,
        elem.height.baseVal.value,
      );
      return;
    }
    if (elemClass && elemClass.includes('wb-image')) {
      this.tools.image.createImage(
        {
          id: elem.getAttribute('id'),
          x: elem.getAttribute('x'),
          y: elem.getAttribute('y'),
          width: elem.getAttribute('width'),
          height: elem.getAttribute('height'),
          aspectRatio: elem.getAttribute('aspectRatio'),
          href: elem.getAttribute('href'),
        },
        {
          updateEditHistory: false,
        },
      );
      return;
    }
    const elemTag = elem.id.startsWith('shape_arrow') ? 'arrow' : elem.tagName;
    this.appendChild(elem);
    if (['line', 'arrow', 'rect', 'ellipse'].includes(elemTag)) {
      const broadcastData = {
        type: `shape_${elemTag}`,
        id: elem.id,
        stroke: elem.getAttribute('stroke'),
        tool: `shape_${elemTag}`,
      };
      switch (elemTag) {
        case 'line':
          broadcastData.x1 = elem.x1.baseVal.value;
          broadcastData.y1 = elem.y1.baseVal.value;
          broadcastData.x2 = elem.x2.baseVal.value;
          broadcastData.y2 = elem.y2.baseVal.value;
          break;
        case 'arrow':
          broadcastData.x1 = elem.x1.baseVal.value;
          broadcastData.y1 = elem.y1.baseVal.value;
          broadcastData.x2 = elem.x2.baseVal.value;
          broadcastData.y2 = elem.y2.baseVal.value;
          broadcastData['marker-end'] = elem.getAttribute('marker-end');
          break;
        case 'rect':
          broadcastData.x = elem.x.baseVal.value;
          broadcastData.y = elem.y.baseVal.value;
          broadcastData.width = elem.width.baseVal.value;
          broadcastData.height = elem.height.baseVal.value;
          broadcastData.fill = elem.getAttribute('fill');
          break;
        case 'ellipse':
          broadcastData.cx = elem.cx.baseVal.value;
          broadcastData.cy = elem.cy.baseVal.value;
          broadcastData.rx = elem.rx.baseVal.value;
          broadcastData.ry = elem.ry.baseVal.value;
          broadcastData.fill = elem.getAttribute('fill');
          break;
        default:
          return;
      }
      this.broadcast(broadcastData);
      return;
    }

    if (elemTag === 'foreignObject') {
      this.broadcast({
        type: 'text',
        id: elem.id,
        color: elem.style.fill,
        x: elem.x.baseVal.value,
        y: elem.y.baseVal.value,
        width: elem.width.baseVal.value,
        height: elem.height.baseVal.value,
        text: document.getElementById(`${elem.id}_input`).innerText,
        tool: 'text',
      });
    } else {
      const pathData = elem.getPathData();
      this.broadcast({
        type: 'line',
        color: elem.style.stroke || elem.style.fill,
        x: pathData[0].values[0],
        y: pathData[0].values[1],
        id: elem.id,
        tool: 'pencil',
      });
      for (let i = 1; i < pathData.length; i += 1) {
        const numVals = pathData[i].values.length;
        this.broadcast({
          type: 'line_point',
          x: pathData[i].values[numVals - 2],
          y: pathData[i].values[numVals - 1],
          parentId: elem.id,
          tool: 'pencil',
        });
      }
    }
  };

  undo = () => {
    const lastEdit = this.editHistory.pop();
    if (lastEdit) {
      try {
        switch (lastEdit.type) {
          case 'add': {
            const removedChild = this.removeChildSafe(lastEdit.elem);
            lastEdit.elem = removedChild;
            this.broadcast({
              type: 'remove',
              id: lastEdit.elem.id,
              tool: 'eraser',
            });
            break;
          }
          case 'remove': {
            this.unRemoveChild(lastEdit.elem);
            break;
          }
          case 'edit_text': {
            const textElem = this.canvas.getElementById(lastEdit.elem.id);
            if (textElem) {
              this.tools.text.updateSVGText(textElem.id, lastEdit.oldState);
              this.broadcast({
                type: 'update_text',
                id: lastEdit.elem.id,
                tool: 'text',
                ...lastEdit.oldState,
              });
            }
            break;
          }
          case 'resize':
          case 'relocate': {
            const { elem, oldState } = lastEdit;
            this.tools.image.transformImage(
              elem.id,
              oldState.x,
              oldState.y,
              oldState.height,
              oldState.width,
            );
            break;
          }
          case 'clear': {
            for (let i = 0; i < lastEdit.elems.length; i += 1) {
              this.unRemoveChild(lastEdit.elems[i]);
            }
            break;
          }
          default:
            return;
        }
        this.appendToUndoStack(lastEdit);
      } catch (err) {
        // Error is usually caused by a call to removeChild on an element that is not a child of the canvas.
        // Implies the edit history was corrupted or a canvas element was manually removed from the DOM with dev tools.
        console.log('Failed to undo whiteboard edit');
        console.log(err);
      }
    }
  };

  redo = () => {
    const lastUndo = this.undoStack.pop();
    if (lastUndo) {
      try {
        switch (lastUndo.type) {
          case 'add': {
            this.unRemoveChild(lastUndo.elem);
            break;
          }
          case 'remove': {
            const removedChild = this.removeChildSafe(lastUndo.elem);
            lastUndo.elem = removedChild;
            this.broadcast({
              type: 'remove',
              id: lastUndo.elem.id,
              tool: 'eraser',
            });
            break;
          }
          case 'edit_text': {
            const textElem = this.canvas.getElementById(lastUndo.elem.id);
            if (textElem) {
              this.tools.text.updateSVGText(textElem.id, lastUndo.newState);
              this.broadcast({
                type: 'update_text',
                id: lastUndo.elem.id,
                tool: 'text',
                ...lastUndo.newState,
              });
            }
            break;
          }
          case 'resize':
          case 'relocate': {
            const { elem, newState } = lastUndo;
            this.tools.image.transformImage(
              elem.id,
              newState.x,
              newState.y,
              newState.height,
              newState.width,
            );
            break;
          }
          case 'clear': {
            for (let i = 0; i < lastUndo.elems.length; i += 1) {
              const removedChild = this.removeChildSafe(lastUndo.elems[i]);
              lastUndo.elems[i] = removedChild;
            }
            this.broadcast({
              type: 'clear',
              tool: 'eraser',
            });
            break;
          }
          default:
            return;
        }
        this.appendToEditHistory(lastUndo, true);
      } catch (err) {
        // Error is usually caused by a call to removeChild on an element that is not a child of the canvas.
        // Implies the undo stack was corrupted or a canvas element was manually removed from the DOM with dev tools.
        console.log('Failed to redo whiteboard edit');
        console.log(err);
      }
    }
  };

  removeChildSafe = elem => {
    let childToRemove = elem;
    if (!childToRemove || !this.canvas.contains(childToRemove)) {
      childToRemove = this.canvas.getElementById(elem.id);
    }
    if (childToRemove && this.canvas.contains(childToRemove)) {
      this.canvas.removeChild(childToRemove);
    }
    const elemClass = elem.getAttribute('class');
    if (
      elemClass &&
      (elemClass.includes('wb-grid') || elemClass.includes('wb-image'))
    ) {
      const gridDeleteBtnToRemove = this.canvas.getElementById(`delete-${elem.id}`);
      if (gridDeleteBtnToRemove && this.canvas.contains(gridDeleteBtnToRemove)) {
        this.canvas.removeChild(gridDeleteBtnToRemove);
      }
      this.tools.image.removeAllImageEditingHandles();
    }
    return childToRemove;
  };

  clear = noBroadcast => {
    if (this.canvas.firstChild) {
      const elems = [];
      while (this.canvas.firstChild) {
        const elemClass = this.canvas.firstChild.getAttribute('class');
        if (
          !elemClass ||
          !(
            elemClass.includes('wb-grid-delete-btn') ||
            elemClass.includes('wb-image-delete-btn') ||
            elemClass.includes('-wrapper')
          )
        ) {
          elems.push(this.canvas.firstChild);
        }
        this.canvas.removeChild(this.canvas.firstChild);
      }
      this.appendToEditHistory({
        type: 'clear',
        elems,
      });
      if (!noBroadcast) {
        this.broadcast({
          type: 'clear',
          tool: 'eraser',
        });
      }
    }
  };
}
export default Board;
