import juniAxios from 'services/axios';

export const getStudentMathAnswerAttemptsForModuleSection = async (
  studentId,
  moduleSectionId,
) =>
  juniAxios
    .get(
      `/api/math_answer_attempt/student/${studentId}/moduleSection/${moduleSectionId}`,
    )
    .then(res => res.data);

export const createMathAnswerAttempt = async (
  studentId,
  problemId,
  moduleSectionId,
  value,
  isCorrect,
) => {
  const newAnswerAttempt = {
    studentId,
    problemId,
    moduleSectionId,
    value,
    wasCorrect: isCorrect,
  };
  return juniAxios
    .post(`/api/math_answer_attempt`, newAnswerAttempt)
    .then(res => res.data);
};

export const submitMathAnswerAttempt = async (answerAttemptId, value, isCorrect) =>
  juniAxios
    .post(`/api/math_answer_attempt/${answerAttemptId}`, {
      value,
      wasCorrect: isCorrect,
    })
    .then(res => res.data);
