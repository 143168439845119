import React from 'react';

import FitVid from 'components/FitVid';
import { PlayButton } from 'components/Icons';

import { ProjectVidContentContainer } from './styles';

const JuniversePreviewVideo = ({ src }) => (
  <ProjectVidContentContainer>
    <PlayButton />
    <FitVid src={src} muted unlockAspectRatio />
  </ProjectVidContentContainer>
);

export default JuniversePreviewVideo;
