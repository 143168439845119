const eventTypes = {
  JIDE_PROJECT_SAVED: 'JIDE_PROJECT_SAVED',
  JIDE_PROJECT_SHARED: 'JIDE_PROJECT_SHARED',
  VIDEO_STARTED: 'VIDEO_STARTED',
  VIDEO_PAUSED: 'VIDEO_PAUSED',
  VIDEO_ENDED: 'VIDEO_ENDED',
  WHITEBOARD_EDITED: 'WHITEBOARD_EDITED',
  WHITEBOARD_SAVED: 'WHITEBOARD_SAVED',
  WHITEBOARD_SKIPPED: 'WHITEBOARD_SKIPPED',
};

export default eventTypes;
