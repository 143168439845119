import React from 'react';
import ColorHash from 'color-hash';
import classNames from 'classnames';
import { JuniClubMembershipInfoPlusBadgeType } from 'app/clubs/MyClubsTypes';
import { JuniEmblemIcon } from 'components/brand-assets';

const colorHashDark = new ColorHash({ lightness: 0.6 });
const colorHashLight = new ColorHash({ lightness: 0.4 });

const getColorGradient = (username: string) =>
  `linear-gradient(
    180deg,
    ${colorHashDark.hex(username)} 0%,
    ${colorHashLight.hex(username)} 100%
  )`;

interface UserInfoProps {
  member?: JuniClubMembershipInfoPlusBadgeType;
}

const UserInfo: React.FC<UserInfoProps> = ({ member, children }) => {
  const { firstName, lastInitial, username, badgeInfo } = member || {};
  const { color: badgeColor, displayName: badgeDisplayName } = badgeInfo || {};
  const firstNameInitial = firstName?.charAt(0) || '?';
  const lastNameInitial = lastInitial || '?';
  const userDisplayName =
    firstName && lastInitial ? `${firstName} ${lastInitial}.` : 'Unknown User';
  return (
    <div className={classNames('flex', 'flex-row', { 'items-center': !children })}>
      <div
        className={classNames(
          'flex',
          'flex-shrink-0',
          'items-center',
          'justify-center',
          'border-2',
          'border-solid',
          'border-blue-gray-50',
          'rounded-full',
          'box-border',
          'p-1',
          'w-9',
          'h-9',
          'text-sm',
          'mr-3',
        )}
        style={{ background: getColorGradient(username || '') }}
      >
        <div className="font-bold text-white text-center">
          {`${firstNameInitial}${lastNameInitial}`.toUpperCase()}
        </div>
      </div>
      <div>
        <div className="flex items-center flex-wrap">
          <div className="font-semibold text-blue-gray-600 text-sm mr-3">
            {userDisplayName}
          </div>
          {username && (
            <div className="text-blue-gray-300 text-sm mr-3">{`@${username}`}</div>
          )}
          {badgeDisplayName && (
            <div
              className={classNames(
                'flex',
                'items-center',
                'border-2',
                'border-solid',
                `border-${badgeColor}-100`,
                'rounded-md',
                'px-2',
                'py-1',
                'bg-white',
                'mr-3',
              )}
            >
              <JuniEmblemIcon size="small" />
              <div
                className={classNames(
                  'font-semibold',
                  `text-${badgeColor}-500`,
                  'text-2xs',
                  'uppercase',
                  'ml-1',
                )}
              >
                {badgeDisplayName}
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default UserInfo;
