import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import 'moment-timezone';

import JoinSessionButton from 'components/JoinSessionButton';
import { StudentSession } from 'models/acuity';
import {
  ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS,
  CONSECUTIVE_JOINABLE_SESSION_ALERT_BUFFER_MINUTES,
} from 'constants/acuity';
import UserContext from 'modules/UserContext';
import { Parent, Student } from 'models';
import { getLearnerSessionActionType, sessionHasEnded } from 'utils/acuity';
import { getParentsUpcomingSessions } from 'services/learner/sessions';

interface PortalSessionAlertProps {
  parent: Parent;
  student?: Student;
}

const JoinSessionButtonWrapper = styled.div<any>`
  min-width: 150px;
`;

const PortalSessionAlertV2: FC<PortalSessionAlertProps> = ({ parent, student }) => {
  // Below is the copy pasta-ed upcoming session code.
  // TODO: Write utility function that generalizes this logic for both
  // instructor and learner session alerts.
  const { user } = useContext(UserContext);
  const [joinableSession, setJoinableSession] = useState<StudentSession | null>(
    null,
  );
  const [upcomingSessions, setUpcomingSessions] = useState<StudentSession[]>([]);
  const [activeStudent, setActiveStudent] = useState<Student | undefined>(student);
  const updateJoinableSession = useCallback(() => {
    const parentHasMultipleChildren = parent ? parent.hasMultipleChildren : false;

    const allJoinableSessionsForActiveStudent = upcomingSessions
      .filter(
        session =>
          getLearnerSessionActionType(
            session,
            activeStudent?.firstName,
            parentHasMultipleChildren,
          ) === 'joinable',
      )
      .sort((a, b) => (a.datetime <= b.datetime ? -1 : 1));

    let newJoinableSession = null;
    if (allJoinableSessionsForActiveStudent.length === 1) {
      [newJoinableSession] = allJoinableSessionsForActiveStudent;
    } else if (allJoinableSessionsForActiveStudent.length > 1) {
      const firstJoinableSession = allJoinableSessionsForActiveStudent[0];
      const nextJoinableSession = allJoinableSessionsForActiveStudent[1];
      const timeTillNextJoinableSession = moment(nextJoinableSession.datetime).diff(
        moment(),
        'minutes',
      );
      newJoinableSession =
        timeTillNextJoinableSession <
        CONSECUTIVE_JOINABLE_SESSION_ALERT_BUFFER_MINUTES
          ? nextJoinableSession
          : firstJoinableSession;
    }

    setJoinableSession(newJoinableSession);
  }, [upcomingSessions, parent, activeStudent]);

  const fetchUpcomingSessions = useCallback(async () => {
    try {
      const sessions = await getParentsUpcomingSessions(parent.email);
      const filteredSessions = [...sessions].filter(
        session => !sessionHasEnded(session),
      );
      setUpcomingSessions(filteredSessions);
    } catch (err) {
      console.error(err.response || err);
    }
  }, [parent]);

  const updateStudent = (student?: Student) => {
    setActiveStudent(student);
    setJoinableSession(null);
  };

  useEffect(() => {
    updateStudent(student);
  }, [student, student?._id]);

  useEffect(() => {
    if (!user?.isGuest) {
      fetchUpcomingSessions();
    }
  }, [fetchUpcomingSessions, user?.isGuest]);

  useEffect(() => {
    updateJoinableSession();
    const updateInterval = setInterval(updateJoinableSession, 6000);
    return () => clearInterval(updateInterval);
  }, [updateJoinableSession]);

  if (!joinableSession) {
    return null;
  }
  const bannerText = `You have a Juni session starting at
    ${moment(joinableSession.datetime)
      .tz(joinableSession.timezone)
      .format('h:mm a z.')}`;

  const isTrial = ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS.includes(
    joinableSession.appointmentTypeID,
  );

  return (
    <div className="flex w-full bg-j-dark-600 font-graphik justify-center py-4 sm:py-2">
      <div className="flex w-11/12 flex-col sm:flex-row justify-between items-center">
        <div className="text-white text-center pb-2 sm:pb-0">{bannerText}</div>
        <JoinSessionButtonWrapper>
          <JoinSessionButton
            session={joinableSession}
            isTrial={isTrial}
            bannerButton
          />
        </JoinSessionButtonWrapper>
      </div>
    </div>
  );
};

export default PortalSessionAlertV2;
