import { QueryResult } from '@apollo/client';
import SpinnerV2 from 'components/SpinnerV2';
import { Badge, NewButton, NewModalWindow, Message } from 'core-components';
import {
  Course,
  LoadDefaultPaymentInformationByParentIdQuery,
  LoadStripeSubscriptionsByParentIdQuery,
  LoadStudentsByParentIdsQuery,
  useAddStudentCourseMutation,
  useCreateStripeSubscriptionMutation,
  GetCoursesQuery,
  Student,
} from 'generated/graphql';
import UPGRADE_IMAGE from 'images/ondemand-upgrade.png';
import React from 'react';
import _ from 'lodash';
import {
  isSubscriptionForStudentId,
  extractMetadataFromSubscription,
} from 'utils/stripe';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';

interface UpgradeModalProps {
  setCurrentCourse: React.Dispatch<React.SetStateAction<Course | undefined>>;
  currentCourse: Course;
  studentId: string;
  cardQuery: QueryResult<LoadDefaultPaymentInformationByParentIdQuery>;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
  coursesQuery: QueryResult<GetCoursesQuery>;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({
  currentCourse,
  setCurrentCourse,
  studentId,
  cardQuery,
  subscriptionsQuery,
  studentsQuery,
  coursesQuery,
}) => {
  const [
    createSubscription,
    { loading: isAddingSubscription, error: subscriptionError },
  ] = useCreateStripeSubscriptionMutation();
  const [
    addCourse,
    { loading: isAddingCourse, error: courseError },
  ] = useAddStudentCourseMutation();
  const card = cardQuery?.data?.defaultPaymentInformationByParentId || {};

  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];
  const [activeSubscription] = _.filter(subscriptions, subscription =>
    isSubscriptionForStudentId(subscription, studentId),
  );

  const courses = coursesQuery?.data?.getCourses || [];
  const student = studentsQuery.data?.studentsByParentIds?.items.find(
    student => student._id === studentId,
  ) as Student;

  const studentCurrentCourses = student.hasMultipleTracks
    ? student.tracks
    : [student.track];

  return (
    <NewModalWindow
      isOpen
      closeModal={() => {
        analytics.track('on_demand_course_explorer_upgrade_modal_closed', {
          course: currentCourse,
        });
        setCurrentCourse(undefined);
      }}
      contentPadding="0"
      contentMaxHeight="max-content"
      maxWidth="526px"
    >
      <div className="flex flex-col font-graphik text-j-dark-600">
        <div className="flex justify-center items-center h-56 bg-j-gray-200">
          <img
            className="w-48"
            src={UPGRADE_IMAGE}
            alt="A bucket with course with a launch icon"
          />
        </div>
        <div className="p-6 flex flex-col">
          <div className="text-xl leading-8 font-medium">
            Upgrade to unlock course library
          </div>
          <div className="text-sm pt-2 pb-6">
            Unlimited access to 150+ hours (and growing) of Juni’s On Demand course
            content. The first week is free, and you can cancel at any time.
          </div>
          <div className="rounded-lg border border-solid border-j-dark-100 mb-4">
            <div className="p-4">
              <div className="flex flex-row justify-between">
                <div className="font-normal text-base">On Demand Subscription</div>
                <Badge backgroundColor="j-blue-100" color="j-blue-600" size="medium">
                  billed monthly
                </Badge>
              </div>
              <div className="pt-2">
                <span className="font-medium text-xl leading-6">{`$${ASYNC_PLAN_PRODUCT.price}`}</span>
                <span className="text-xs text-j-dark-300 pl-1">/ month</span>
              </div>
            </div>
          </div>
          {_.isEmpty(card) ? (
            <div className="my-2.5 text-base">
              {'To unlock the On Demand course library, please '}
              <a href="account">add a payment method</a>.
            </div>
          ) : (
            <NewButton
              onClick={async () => {
                JuniAnalytics.track(
                  'on_demand_course_explorer_upgrade_modal_button_clicked',
                  {
                    course: currentCourse,
                  },
                );
                try {
                  await createSubscription({
                    variables: {
                      input: {
                        subscriptionUpdateType: activeSubscription
                          ? 'NEW_CLASS'
                          : 'REENGAGE_CHURN',
                        studentId,
                        metadataNew: extractMetadataFromSubscription(
                          activeSubscription,
                          courses,
                          studentCurrentCourses,
                        ),
                        addAsync: true,
                      },
                    },
                  });
                  await addCourse({
                    variables: {
                      input: { studentId, course: currentCourse.name },
                    },
                  });
                  setCurrentCourse(undefined);
                  await subscriptionsQuery.refetch();
                  await studentsQuery.refetch();
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {isAddingSubscription || isAddingCourse ? (
                <SpinnerV2 />
              ) : (
                <>Upgrade Now</>
              )}
            </NewButton>
          )}
          {(subscriptionError || courseError) && (
            <Message className="mt-2" status="error">
              {`There was an error ${
                subscriptionError
                  ? 'updating your subscription'
                  : 'adding the course'
              }! Please send us an email at `}
              <a href="mailto:support@learnwithjuni.com">
                support@learnwithjuni.com
              </a>
              {' so that we can assist.'}
            </Message>
          )}
        </div>
      </div>
    </NewModalWindow>
  );
};

export default UpgradeModal;
