import React, { FC } from 'react';
import { getHours } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import guessTimezoneValue from 'utils/guessTimezoneValue';
import { Draggable } from 'react-beautiful-dnd';
import { Icon } from 'core-components';

interface SchedulingOptionProps {
  date: string | undefined;
  timezone: string;
  index: number;
}

const SchedulingOption: FC<SchedulingOptionProps> = ({
  date: datetime,
  timezone,
  index,
}) => {
  const date = datetime
    ? utcToZonedTime(
        zonedTimeToUtc(new Date(datetime), guessTimezoneValue()),
        timezone ?? guessTimezoneValue(),
      )
    : undefined;

  const dayOfWeek = date
    ? date.toLocaleString('default', {
        weekday: 'long',
      })
    : '';
  const hours = date ? getHours(date) : 0;
  const hourOfDay = hours > 12 ? hours - 12 : hours;
  const amOrPm = hours > 11 ? 'PM' : 'AM';

  return (
    <Draggable draggableId={`${date}-${index}`} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          className="w-full bg-white pl-1 py-1 rounded-lg flex items-center hover:shadow-2 active:shadow-2 group"
          {...provided.draggableProps}
        >
          <div
            {...provided.dragHandleProps}
            className="w-8 h-8 rounded-md flex items-center justify-center hover:bg-j-purple-100 active:bg-j-purple-100"
          >
            <Icon.DragHandle className="text-j-dark-400 w-2 h-3" />
          </div>
          <p className="pl-2 text-j-dark-600 text-sm leading-6 m-0">
            {date ? `${dayOfWeek}, ${hourOfDay}:00 ${amOrPm}` : '--'}
          </p>
        </div>
      )}
    </Draggable>
  );
};

export default SchedulingOption;
