import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { differenceInMinutes } from 'date-fns';
import {
  ChatMessage,
  ChatMessageComposer,
  ClubMessagesZeroState,
} from 'components/clubs';
import {
  JuniClubMembershipInfoPlusBadgeType,
  MsgType,
} from 'app/clubs/MyClubsTypes';
import {
  PUBNUB_NUM_MESSAGES_TO_FETCH,
  usePubnubFetchMessages,
  usePubnubSubscribe,
  usePubnubPublishMessage,
  useUpdateLastReadTimetoken,
  useSetCurrentChatChannel,
} from 'app/clubs/stores/ClubStoreHelpers';
import useClubStore from 'app/clubs/stores/ClubStore';
import JuniSpinner from 'components/JuniSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'core-components';
import bigInt from 'big-integer';
import { JuniClubChannelType } from 'generated/graphql';
import classNames from 'classnames';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';

// TODO: move prop types out into interface with history props included
const Chat: React.FC<{
  studentIdParam?: string;
  instructorUserIdParam?: string;
  juniClubIdParam: string;
  channelNameParam: string;
  clientUUIDToClubMemberState: _.Dictionary<JuniClubMembershipInfoPlusBadgeType>;
  currentClubMemberState?: JuniClubMembershipInfoPlusBadgeType;
  currentClubChannel?: JuniClubChannelType;
  className?: string;
  isGuest?: boolean;
}> = ({
  studentIdParam,
  instructorUserIdParam,
  juniClubIdParam,
  channelNameParam,
  clientUUIDToClubMemberState,
  currentClubMemberState,
  currentClubChannel,
  className,
  isGuest,
}) => {
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [isMoreToLoad, setIsMoreToLoad] = useState(true);

  const badgeName = currentClubMemberState?.badgeInfo.name;
  const writePermissions = !!(
    badgeName && ['advisor', 'moderator', 'leader'].includes(badgeName)
  );
  const canWriteToChannel = !currentClubChannel?.readOnly || writePermissions;

  const pubnubFetchMessages = usePubnubFetchMessages();
  const pubnubSubscribe = usePubnubSubscribe();
  const pubnubPublishMessage = usePubnubPublishMessage();
  const updateLastReadTimetoken = useUpdateLastReadTimetoken();
  const setCurrentChatChannel = useSetCurrentChatChannel();

  const prevStudentIdParam = useRef<string | undefined>();
  const prevInstructorUserIdParam = useRef<string | undefined>();
  const prevJuniClubIdParam = useRef<string | undefined>();
  const prevChannelNameParam = useRef<string | undefined>();
  const prevLastMsg = useRef<MsgType | undefined>();

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const allSortedMessages = useClubStore(state => state.sortedMessages);
  const allChannelMessages = allSortedMessages.filter(
    m => m.juniClubId === juniClubIdParam && m.juniClubChannel === channelNameParam,
  );
  const sortedChannelMessages = allChannelMessages.filter(m => !m.deleted);
  const showLoadMoreMessagesButton =
    allChannelMessages.length >= PUBNUB_NUM_MESSAGES_TO_FETCH;

  // Note: scrollTop can be negative when flex-direction: column-reverse is used.
  // The two callbacks below should work regardless of the flex direction.
  // See https://github.com/mdn/content/issues/4142 for details.

  const scrollToTop = useCallback(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop =
        scrollableContainerRef.current.clientHeight -
        scrollableContainerRef.current.scrollHeight;
    }
  }, []);

  const scrollToBottom = useCallback(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop =
        scrollableContainerRef.current.scrollHeight -
        scrollableContainerRef.current.clientHeight;
    }
  }, []);

  const loadMoreMessages = useCallback(async () => {
    if (!isMoreToLoad || isLoadingMoreMessages) return;
    setIsLoadingMoreMessages(true);
    const currentClientUUID = studentIdParam || instructorUserIdParam;
    const oldestMsg = _.first(sortedChannelMessages);
    const oldestMsgTimetoken = oldestMsg?.msgId;
    if (!oldestMsgTimetoken) return;
    const { success, lastMsgTimetoken } = await pubnubFetchMessages({
      currentClientUUID,
      juniClubId: juniClubIdParam,
      channelName: channelNameParam,
      start: oldestMsgTimetoken,
      count: PUBNUB_NUM_MESSAGES_TO_FETCH,
    });
    if (success && !lastMsgTimetoken) {
      setIsMoreToLoad(false);
    }
    setIsLoadingMoreMessages(false);
    scrollToTop();
  }, [
    channelNameParam,
    instructorUserIdParam,
    isMoreToLoad,
    juniClubIdParam,
    pubnubFetchMessages,
    scrollToTop,
    sortedChannelMessages,
    studentIdParam,
    isLoadingMoreMessages,
  ]);

  const sendMessage = useCallback(
    (message: string, imageUrls: string[] = []) => {
      pubnubPublishMessage({
        studentId: studentIdParam,
        instructorUserId: instructorUserIdParam,
        juniClubId: juniClubIdParam,
        channelName: channelNameParam,
        message: { msgText: message, msgImageUrls: imageUrls },
      });
    },
    [
      channelNameParam,
      instructorUserIdParam,
      juniClubIdParam,
      pubnubPublishMessage,
      studentIdParam,
    ],
  );

  const onChannelSwitch = useCallback(
    async (
      prevStudentIdParamValue: string | undefined,
      prevInstructorUserIdParamValue: string | undefined,
      prevJuniClubIdParamValue: string | undefined,
      prevChannelNameParamValue: string | undefined,
    ) => {
      console.log('clubs: channel switch detected');
      setIsMoreToLoad(true);
      setIsLoadingMessages(true);
      setCurrentChatChannel({
        juniClubId: juniClubIdParam,
        channelName: channelNameParam,
      });
      // Send read channel event to snowflake
      JuniAnalytics.track('chat_read_channel', {
        juniClubId: juniClubIdParam,
        channelName: channelNameParam,
      });

      const currentClientUUID = studentIdParam || instructorUserIdParam;
      if (!currentClientUUID) return;

      const prevChannelLastMsg = _.findLast(
        allSortedMessages,
        msg =>
          msg.juniClubId === prevJuniClubIdParamValue &&
          msg.juniClubChannel === prevChannelNameParamValue,
      );
      const lastMsg = _.findLast(
        allSortedMessages,
        msg =>
          msg.juniClubId === juniClubIdParam &&
          msg.juniClubChannel === channelNameParam,
      );

      const lastMsgTimetokenPlusOne = lastMsg
        ? String(bigInt(lastMsg.msgId).plus(1))
        : undefined;

      const {
        success,
        lastMsgTimetoken: newLastMsgTimetoken,
      } = await pubnubFetchMessages({
        currentClientUUID,
        juniClubId: juniClubIdParam,
        channelName: channelNameParam,
        end: lastMsgTimetokenPlusOne,
      });

      if (success) {
        // Update last channel
        updateLastReadTimetoken({
          studentId: prevStudentIdParamValue,
          instructorUserId: prevInstructorUserIdParamValue,
          juniClubId: prevJuniClubIdParamValue,
          channelName: prevChannelNameParamValue,
          timetoken: prevChannelLastMsg?.msgId,
        });
        // Update this channel
        updateLastReadTimetoken({
          studentId: studentIdParam,
          instructorUserId: instructorUserIdParam,
          juniClubId: juniClubIdParam,
          channelName: channelNameParam,
          timetoken: newLastMsgTimetoken,
        });
        await pubnubSubscribe({
          juniClubId: juniClubIdParam,
          channelName: channelNameParam,
          prevJuniClubId: prevJuniClubIdParamValue,
          prevChannelName: prevChannelNameParamValue,
        });
      } else {
        console.error('ERROR: Could not fetch messages');
      }

      setIsLoadingMessages(false);
      scrollToBottom();
    },
    [
      allSortedMessages,
      channelNameParam,
      instructorUserIdParam,
      juniClubIdParam,
      pubnubFetchMessages,
      pubnubSubscribe,
      scrollToBottom,
      setCurrentChatChannel,
      studentIdParam,
      updateLastReadTimetoken,
    ],
  );

  if (
    prevStudentIdParam.current !== studentIdParam ||
    prevInstructorUserIdParam.current !== instructorUserIdParam ||
    prevJuniClubIdParam.current !== juniClubIdParam ||
    prevChannelNameParam.current !== channelNameParam
  ) {
    onChannelSwitch(
      prevStudentIdParam.current,
      prevInstructorUserIdParam.current,
      prevJuniClubIdParam.current,
      prevChannelNameParam.current,
    );
    prevStudentIdParam.current = studentIdParam;
    prevInstructorUserIdParam.current = instructorUserIdParam;
    prevJuniClubIdParam.current = juniClubIdParam;
    prevChannelNameParam.current = channelNameParam;
  }

  // scroll to bottom when a message comes in only if it is newer than the newest
  // message we currently had
  useEffect(() => {
    if (sortedChannelMessages && sortedChannelMessages.length) {
      const lastMsg = _.last(sortedChannelMessages);
      if (lastMsg?.msgId && lastMsg?.msgId !== prevLastMsg.current?.msgId) {
        prevLastMsg.current = lastMsg;
        scrollToBottom();
      }
    }
  }, [scrollToBottom, sortedChannelMessages]);

  // unsubscribe on "unmount", and send last read timetoken
  // TODO: when leaving a club, the server removes your auth from the club
  // so the unsubscribe ends up erroring out
  const cleanupFn = useCallback(
    () => () => {
      pubnubSubscribe({
        prevJuniClubId: prevJuniClubIdParam.current,
        prevChannelName: prevChannelNameParam.current,
      });
      updateLastReadTimetoken({
        studentId: prevStudentIdParam.current,
        instructorUserId: prevInstructorUserIdParam.current,
        juniClubId: prevJuniClubIdParam.current,
        channelName: prevChannelNameParam.current,
        timetoken: prevLastMsg.current?.msgId,
      });
      setCurrentChatChannel({
        juniClubId: undefined,
        channelName: undefined,
      });
    },
    [pubnubSubscribe, setCurrentChatChannel, updateLastReadTimetoken],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cleanupFn, []);

  // TODO: make this render better on Windows/Linux with scroll bars
  return (
    <div className={className} ref={chatContainerRef}>
      {isLoadingMessages ? (
        <JuniSpinner size={60} />
      ) : sortedChannelMessages.length > 0 ? (
        <div
          className="flex flex-col-reverse flex-grow px-4 mb-4 overflow-y-scroll"
          ref={scrollableContainerRef}
        >
          <div>
            <div className="flex justify-center pt-3">
              {showLoadMoreMessagesButton ? (
                <Button
                  variant="secondary"
                  className="rounded-full"
                  onClick={loadMoreMessages}
                  disabled={!isMoreToLoad || isLoadingMoreMessages}
                >
                  {!isMoreToLoad ? (
                    `End of Channel History`
                  ) : isLoadingMoreMessages ? (
                    <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
                  ) : (
                    `↑ Load More Messages ↑`
                  )}
                </Button>
              ) : null}
            </div>

            {sortedChannelMessages.map((m, i) => {
              const senderClubMember = m.senderUUID
                ? clientUUIDToClubMemberState[m.senderUUID]
                : undefined;
              const previousMessage =
                i !== 0 ? sortedChannelMessages[i - 1] : undefined;
              const hideHeader = !!(
                previousMessage &&
                m.senderUUID === previousMessage.senderUUID &&
                differenceInMinutes(m.timestamp, previousMessage.timestamp) <= 5
              );
              return (
                <ChatMessage
                  key={m.msgId}
                  message={m}
                  sender={senderClubMember}
                  studentId={studentIdParam}
                  instructorUserId={instructorUserIdParam}
                  currentClubMemberState={currentClubMemberState}
                  scrollableContainerRef={scrollableContainerRef}
                  hideHeader={hideHeader}
                  disableImages={false}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <ClubMessagesZeroState channelName={channelNameParam} />
      )}
      {canWriteToChannel ? (
        <ChatMessageComposer
          chatContainerRef={chatContainerRef}
          placeholder={`Message #${channelNameParam}`}
          sendMessage={sendMessage}
          disableImageUpload={isGuest}
        />
      ) : (
        <div
          className={classNames(
            'rounded',
            'border',
            'border-solid',
            'bg-blue-gray-50',
            'border-blue-gray-200',
            'mx-6',
            'mb-6',
            'p-4',
          )}
        >
          This channel is for announcements — only Club leaders, advisors, and
          moderators can post messages in this channel!
        </div>
      )}
    </div>
  );
};

export default Chat;
