/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  JRSVideo,
  JRSModalPreview,
  JRSModalLine,
  JRSNextButton,
  JRSBackButton,
  JRSSteps,
} from './subcomponents';

import '../styles/view_recordings.css';
import '../styles/jide_rec_studio.css';

// PRIVATE SUBCOMPONENTS
export const JRSViewRecordingsCreateNew = ({ setMode }) => (
  <div className="jrs-modal-subsection jrs-column jrs-modal-createnew">
    <div className="jrs-modal-subsection jrs-column">
      <div className="jrs-modal-heading4">Create a New Recording</div>
      <JRSModalLine
        icon="chalkboard-teacher"
        boldText={`Share what you've learned!`}
        text="Use recording sessions to share your progress and explain what you’ve learned."
      />
      <JRSModalLine
        icon="lock"
        boldText="Privacy:"
        text="Recording sessions are private, and only viewable by you and your instructor by default until you choose to publish it."
      />
      <JRSModalLine
        icon="smile-wink"
        boldText="Have fun with it!"
        text="Show off your creations and be yourself!"
      />
    </div>

    <div className="jrs-modal-subsection jrs-column jrs-cta">
      <div className="jrs-modal-heading4">
        We'll walk you through the next steps:
      </div>
      <JRSModalLine
        boldText="#1"
        text={`You'll enable access to your camera and microphone.`}
      />
      <JRSModalLine boldText="#2" text={`You'll select a screen to share.`} />
      <JRSSteps
        onClick={() => {
          setMode('userMediaRequest');
        }}
      />
    </div>

    <div className="jrs-modal-subsection jrs-column">
      <JRSNextButton
        onClick={() => {
          setMode('userMediaRequest');
        }}
        text="Next Step: Enable Camera + Microphone"
        enabled
      />
    </div>
  </div>
);

// "Create New Recording" button and container for recording thumbnails
// on the left
export const JRSViewRecordingsPreviews = ({
  projectVideos,
  selectedVideoIdx,
  setSelectedVideoIdx,
  setCreateNewSelected,
  setEditTitleMode,
  setDeleteVideoMode,
  createNewSelected,
  viewOnlyMode,
}) => {
  // Preview Elements (ie. the video thumbnails on the left of modal)
  const previewElems = projectVideos.map((pv, i) => (
    <JRSModalPreview
      key={pv.s3Url || pv.createdAt || i}
      onClick={() => {
        setSelectedVideoIdx(i);
        setCreateNewSelected(false);
        setEditTitleMode(false);
        setDeleteVideoMode(false);
      }}
      selected={selectedVideoIdx === i}
      src={pv.s3Url}
      title={pv.title}
      createdAt={pv.createdAt}
      originalLength={pv.originalLength}
    />
  ));

  return (
    <div className="jrs-modal-subsection jrs-column jrs-modal-previews">
      {createNewSelected ? null : viewOnlyMode ? null : (
        <JRSNextButton
          onClick={() => {
            setSelectedVideoIdx(null);
            setCreateNewSelected(true);
            setEditTitleMode(false);
            setDeleteVideoMode(false);
          }}
          text="+ New Recording"
          enabled
        />
      )}

      <div className="jrs-modal-heading2 jrs-modal-previewsheading">
        {`${projectVideos.length} Saved Recording${
          projectVideos.length === 1 ? '' : 's'
        } for This Project`}
      </div>
      <div className="jrs-modal-subsection jrs-column jrs-modal-previewslist">
        <div className="jrs-modal-subsection jrs-column jrs-modal-previewslist-content-wrapper">
          <div className="jrs-modal-subsection jrs-column jrs-modal-previewslist-content">
            {previewElems}
          </div>
        </div>
      </div>
    </div>
  );
};

// confirmation dialogue for deleting video
export const JRSViewRecordingsDeleteVideoSplash = ({
  setDeleteVideoMode,
  deleteVideoForRealz,
}) => (
  <div className="jrs-modal-subsection jrs-column jrs-deletevideo-splash">
    <div className="jrs-deletevideo-splash-icon">
      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
    </div>

    <div className="jrs-modal-spacing" />
    <div className="jrs-modal-spacing" />

    <div className="jrs-deletevideo-splash-message">
      Are you sure you wish to delete this recording?
    </div>
    <div className="jrs-deletevideo-splash-text">
      This recording will be permanently deleted and no longer recoverable
    </div>

    <div className="jrs-modal-spacing" />
    <div className="jrs-modal-spacing" />

    <div className="jrs-modal-subsection jrs-row jrs-deletevideo-splash-btns">
      <JRSBackButton
        onClick={() => setDeleteVideoMode(false)}
        text="Cancel"
        enabled
      />
      <JRSNextButton
        onClick={deleteVideoForRealz}
        text="Delete Recording"
        enabled
        red
      />
    </div>
  </div>
);

// viewing selected video
export const JRSSelectedVideo = ({
  projectVideos,
  selectedVideoIdx,
  editTitleMode,
  titleValue,
  titleChangeHandler,
  updateTitle,
  cancelTitle,
  editTitle,
  setDeleteVideoMode,
  createdAtString,
  recordingLength,
  viewOnlyMode,
}) => (
  <>
    <JRSVideo src={projectVideos[selectedVideoIdx]?.s3Url} />
    <div className="jrs-modal-subsection jrs-row">
      <div className="jrs-modal-heading3">RECORDING TITLE</div>
    </div>
    {editTitleMode ? (
      <div className="jrs-modal-subsection jrs-row">
        <input
          className="jrs-modal-input"
          type="text"
          value={titleValue}
          onChange={titleChangeHandler}
          spellCheck={false}
          autoFocus
        />
        <JRSNextButton onClick={updateTitle} text="Save Changes" enabled />
        <JRSBackButton onClick={cancelTitle} text="Cancel" enabled />
      </div>
    ) : (
      <div className="jrs-modal-subsection jrs-row">
        <div className="jrs-modal-heading1">
          {projectVideos[selectedVideoIdx]?.title || 'untitled'}
        </div>
        {!viewOnlyMode && (
          <div
            className="jrs-modal-titlebutton jrs-modal-edittitle"
            onClick={editTitle}
          >
            <FontAwesomeIcon icon={['fas', 'pen']} />
          </div>
        )}
        {!viewOnlyMode && (
          <div
            className="jrs-modal-titlebutton jrs-modal-deletevideo"
            onClick={() => setDeleteVideoMode(true)}
          >
            <FontAwesomeIcon icon={['fas', 'trash-alt']} />
          </div>
        )}
      </div>
    )}
    <div className="jrs-modal-subsection jrs-row jrs-modal-selectedvideo-metadata">
      <div className="jrs-modal-subsection jrs-column">
        <div className="jrs-modal-heading3">CREATED AT</div>
        <div className="jrs-modal-heading2">{createdAtString}</div>
      </div>
      <div className="jrs-modal-subsection jrs-column">
        <div className="jrs-modal-heading3">LENGTH</div>
        <div className="jrs-modal-heading2">{recordingLength}</div>
      </div>
    </div>
  </>
);
