import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { parseError } from 'utils/errors';
import TextArea from '../TextArea';
import Button from '../Button';

const EditableTextArea = ({
  value = '',
  name,
  save,
  className = '',
  editing = false,
  controlled = false,
  centeredButtons = false,
  cancel = null,
  disabled = false,
  maxLines = Infinity,
  maxLineWidth = Infinity,
}) => {
  const isMountedRef = useRef(null);
  const textAreaRef = React.createRef();
  const [editValue, setEditValue] = useState(undefined);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isTruncated, setTruncated] = useState(true);
  const displayValue = wrapText(value, maxLines, maxLineWidth);
  const hasContentToggle = value !== displayValue;

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      return false;
    };
  }, []);

  const handleEdit = useCallback(() => {
    setEditValue(value || '');
    setError(null);
  }, [value]);

  useEffect(() => {
    if (controlled && editing) handleEdit();
  }, [controlled, editing, handleEdit]);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current && !textAreaRef.current.style.height) {
      const scrollHeight = [textAreaRef.current.scrollHeight, 'px'].join('');
      textAreaRef.current.style.height = scrollHeight;
    }
  }, [textAreaRef]);

  const handleChange = useCallback(e => {
    setEditValue(e.target.value);
  }, []);

  const handleSave = useCallback(async () => {
    try {
      await save(editValue, name);
      if (isMountedRef.current) setEditValue(undefined);
    } catch (err) {
      if (isMountedRef.current) setError(parseError(err));
    } finally {
      if (isMountedRef.current) setIsSaving(false);
    }
  }, [editValue, save, name]);

  const handleCancel = useCallback(() => {
    if (controlled && cancel) {
      cancel();
    }
    setEditValue(undefined);
  }, [cancel, controlled]);

  const isEditing = (controlled && editing) || editValue !== undefined;

  return (
    <div className={className}>
      {isEditing && !disabled ? (
        <>
          <TextArea
            ref={textAreaRef}
            disabled={isSaving}
            onChange={handleChange}
            value={editValue}
          />
          {error && <div className="ui-field-error">{error}</div>}
          <div className={`ui-button-row ${centeredButtons ? 'centered' : ''}`}>
            <Button disabled={isSaving} onClick={handleSave} small>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
            <Button disabled={isSaving} onClick={handleCancel} small>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <>
          <span className="ui-pre-wrap">
            {hasContentToggle && isTruncated ? `${displayValue}...` : value}
          </span>

          {((!controlled && !disabled) || hasContentToggle) && (
            <div className={`ui-button-row ${centeredButtons ? 'centered' : ''}`}>
              {!controlled && !disabled && (
                <Button onClick={handleEdit} small>
                  Edit
                </Button>
              )}

              {hasContentToggle && (
                <Button onClick={() => setTruncated(!isTruncated)} small>
                  {isTruncated ? 'Show more content' : 'Hide content'}
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const wrapText = (str, maxLines = Infinity, maxWidth = Infinity) => {
  let lines = 0;
  let lastNewline = 0;

  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '\n' || i - lastNewline >= maxWidth || i === str.length - 1) {
      lines += 1;
      lastNewline = i;
    }

    if (lines >= maxLines) {
      return i > str.length - 10 ? str : str.substr(0, i);
    }
  }

  return str;
};

EditableTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  save: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  cancel: PropTypes.any,
  controlled: PropTypes.bool,
  centeredButtons: PropTypes.bool,
};

export default EditableTextArea;
