import React from 'react';

import { Elements } from 'react-stripe-elements';
import LearnerPaymentInfo from '../../LearnerPaymentInfo';

const PaymentUpdateModal = ({ paymentsQuery, updateFormState, parent }) => (
  <Elements>
    <LearnerPaymentInfo
      getPaymentInfo={() => {
        paymentsQuery.refetch(parent);
      }}
      onHide={() => {
        updateFormState('', 'modal');
      }}
      isVisible
      parent={parent}
    />
  </Elements>
);

export default PaymentUpdateModal;
