import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BrowserIcon from 'images/browser-icon.svg';
import { JRSViewRecordings } from './viewRecordings';

import '../styles/intro.css';
import '../styles/jide_rec_studio.css';

// The Intro modal is basically just the ViewRecordings modal, but with
// the "createnew" button preselected
const JRSIntro = props => {
  let canRecord = false;
  try {
    canRecord = !!(
      window.chrome &&
      navigator.mediaDevices.getDisplayMedia &&
      MediaRecorder.isTypeSupported('video/webm;codecs=h264')
    );
  } catch (err) {
    console.error(err);
  }

  return canRecord ? (
    <JRSViewRecordings {...props} selectCreateNew />
  ) : (
    <div className="jrs-modal jrs-intro">
      <div className="jrs-modal-header jrs-row">
        <div
          className="jrs-modal-x"
          onClick={() => {
            props.setMode('close');
          }}
        >
          <div className="jrs-modal-x-inner">
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
      </div>

      <div className="jrs-modal-section jrs-row jrs-intro-upper">
        <div className="jrs-intro-img">
          <img src={BrowserIcon} alt="Browser Icon" />
        </div>
      </div>

      <div className="jrs-modal-section jrs-column jrs-intro-lower">
        <div className="jrs-modal-heading1">Your browser is not supported :(</div>
        <div className="jrs-modal-heading4">
          Please switch to the latest version of Google Chrome to use the Recording
          Studio
        </div>
      </div>
    </div>
  );
};

export default JRSIntro;
