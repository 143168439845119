export enum CardOrientations {
  portrait,
  landscape,
}

export interface IBadgeProperties {
  inProgress?: boolean;
  hasRecording?: boolean;
  playgroundProject?: boolean;
  completed?: boolean;
}
