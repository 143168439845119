import React, { FC } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';

import { Panel, NewButton } from 'core-components';

interface SidebarPillProps {
  label: string;
  sublabel?: string;
  onClick?: () => void;
  selected?: boolean;
  renderIcon?: React.ComponentProps<typeof NewButton>['renderIconLeft'];
}

const SidebarPill: FC<SidebarPillProps> = ({
  label,
  sublabel,
  onClick,
  selected = false,
  renderIcon = noop,
}) => {
  const notSelectedAndClickable = !selected && !!onClick;
  return (
    <Panel
      handleOnClick={onClick}
      isClickable={!!onClick}
      backgroundColor={selected ? 'j-dark-600' : 'transparent'}
      className={classNames('mb-3', 'group', 'font-graphik', {
        'hover:bg-j-dark-700': notSelectedAndClickable,
      })}
      padding={['2', '1.5']}
    >
      {renderIcon({
        className: classNames(
          'mt-0.5',
          'w-4 h-4',
          'flex items-start',
          selected ? 'text-white' : 'text-j-dark-300',
          { 'group-hover:text-j-dark-100': notSelectedAndClickable },
        ),
      })}
      <div className={classNames('flex', 'flex-col', 'ml-2.5')}>
        <div
          className={classNames(
            'text-sm',
            selected ? 'text-white' : 'text-j-dark-300',
            { 'group-hover:text-j-dark-100': notSelectedAndClickable },
          )}
        >
          {label}
        </div>
        {sublabel && (
          <div
            className={classNames(
              'text-xs',
              selected ? 'text-j-dark-200' : 'text-j-dark-400',
              {
                'group-hover:text-j-dark-300': notSelectedAndClickable,
              },
            )}
          >
            {sublabel}
          </div>
        )}
      </div>
    </Panel>
  );
};

export default SidebarPill;
