import React, { Component } from 'react';
import { getTrialCourseOfferings } from 'services/api/api_trial.js';

import './juni_ide_math_trial.css';
import { JuniIDE } from 'components/jide';
import { COURSE_TYPES } from 'constants/subjects';

class JuniIDEMathTrial extends Component {
  state = {
    isLoadingIdLookup: true,
    idLookup: {},
  };
  courseTypes = [COURSE_TYPES.MATHEMATICS];
  idLookupTable = {};

  componentDidMount() {
    this.buildTrialIdLookupTable();
  }

  buildTrialIdLookupTable = async () => {
    const offerings = await getTrialCourseOfferings(this.courseTypes);
    for (const data of offerings) {
      this.addToIdLookupTable(data);
    }
    this.setState({ isLoadingIdLookup: false, idLookup: this.idLookupTable });
  };

  addToIdLookupTable = offerings => {
    for (const course of offerings.courses) {
      this.idLookupTable[course.id] = course;
      for (const m of course.children) {
        this.idLookupTable[m.id] = m;
        for (const project of m.children) {
          this.idLookupTable[project.id] = project;
        }
      }
    }
  };

  render() {
    return (
      <div className="jide-math-trial jide-main-content">
        <JuniIDE
          jideUser={{ type: 'student' }}
          courseTypes={[COURSE_TYPES.MATHEMATICS]}
          onlyTrialCourses={this.props.match.params.courseNames.split(',')}
          isLoadingIdLookup={this.state.isLoadingIdLookup}
          idLookup={this.state.idLookup}
        />
      </div>
    );
  }
}

export default JuniIDEMathTrial;
