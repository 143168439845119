const hickory = {
  dark: '#480909',
  medium: '#590C0C',
  light: '#6E2D2D',
};

const gingerbread = {
  dark: '#482309',
  medium: '#592C0C',
  light: '#6E482D',
};

const olive = {
  dark: '#484809',
  medium: '#59590C',
  light: '#6E6E2D',
};

const cucumber = {
  dark: '#214E0C',
  medium: '#3F7526',
  light: '#558F3A',
};

const ocean = {
  dark: '#0C4E3F',
  medium: '#267563',
  light: '#3A8F7B',
};

const sapphire = {
  dark: '#0C3A4E',
  medium: '#265D75',
  light: '#3A758F',
};

const navy = {
  dark: '#091A48',
  medium: '#0C1D59',
  light: '#2D3B6E',
};

const eggplant = {
  dark: '#150C4E',
  medium: '#312675',
  light: '#463A8F',
};

const grape = {
  dark: '#480948',
  medium: '#590C59',
  light: '#6E2D6E',
};

module.exports = {
  hickory,
  gingerbread,
  olive,
  cucumber,
  ocean,
  sapphire,
  navy,
  eggplant,
  grape,
};
