import React, { FC } from 'react';
import { Icon } from 'core-components';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';
import navStates from 'app/signup_session/navigation/states';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import { CoursePlacementOption, SignupSessionProps } from 'app/signup_session/types';
import {
  countIncompleteCourseSelections,
  findStudentById,
  updateStudentById,
} from 'app/signup_session/lib';
import OnboardingFooter from 'app/signup_session/Onboarding/components/OnboardingFooter';
import SingleSelect from './components/SingleSelect';
import BookCallWidget from './components/BookCallWidget';
import CourseSelection from './components/CourseSelection';

const OPTIONS = [
  {
    label: 'I want to get course recommendations from a Juni Advisor.',
    subLabel: `If you’re unsure which courses will be best for your child, our team will be happy to help!`,
    value: 'setup-call',
    icon: <Icon.CalendarPerson width="20" />,
  },
  {
    label: `I’ll choose my child’s courses for each subject now.`,
    subLabel: `Pick this option if you know which courses you want to take. If needed, we can help adjust your placement later.`,
    value: 'self-serve',
    icon: <Icon.UnorderedList2 width="20" />,
  },
];
const CoursePlacement: FC<SignupSessionProps> = ({ history }) => {
  const { signupData, activeStudentId, setSignupSession } = useSignupContext();
  const student = findStudentById(activeStudentId, signupData);
  const { getNextPage } = useNavRouter();

  const isCoursePlacementValid = () => {
    if (!student?.bundle || !signupData.coursePlacement?.method) {
      return false;
    }
    if (signupData.coursePlacement?.method === 'self-serve') {
      return countIncompleteCourseSelections(student.bundle) === 0;
    }
    if (signupData.coursePlacement?.method === 'setup-call') {
      return signupData.coursePlacement.callDetails?.isBooked;
    }
    return true;
  };

  const saveAndNavigate = (path: string) => {
    if (!student?.bundle) return;
    if (countIncompleteCourseSelections(student.bundle) === 0) {
      setSignupSession(
        updateStudentById(
          activeStudentId,
          {
            bundle: {
              ...student.bundle,
              isCourseNameProvided: true,
            },
          },
          signupData,
        ),
      );
    }
    history.push(path);
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <div className="bg-white max-w-2xl mx-auto mt-6 p-8 rounded-xl shadow-1">
        <header className="border-0 border-b border-solid border-j-purple-200 pb-4 mb-8">
          <h1 className="text-j-dark-600 m-0 text-lg font-medium pb-2">
            Course Placement
          </h1>
          <p className="text-j-dark-300 m-0 text-base">
            How would you like to place {student?.firstName || 'your student'} into
            their courses?
          </p>
        </header>
        <div className="space-y-8">
          <SingleSelect
            value={signupData.coursePlacement?.method}
            onSelect={option =>
              setSignupSession({
                coursePlacement: {
                  ...signupData.coursePlacement,
                  method: option as CoursePlacementOption,
                },
              })
            }
            options={OPTIONS}
          />
          {signupData.coursePlacement?.method === 'self-serve' && (
            <CourseSelection />
          )}
          {signupData.coursePlacement?.method === 'setup-call' && (
            <BookCallWidget
              callIsBooked={
                signupData.coursePlacement.callDetails?.isBooked ?? false
              }
              onCalendlyLinkClick={() =>
                setSignupSession({
                  coursePlacement: {
                    ...signupData.coursePlacement,
                    callDetails: {
                      ...(signupData.coursePlacement?.callDetails ?? {}),
                      isBooked: true,
                    },
                  },
                })
              }
            />
          )}
        </div>
        <OnboardingFooter
          actions={{
            back: {
              text: 'Back',
              handler() {
                history.push(
                  getNextPage(
                    navStates.onboarding.coursePlacement,
                    ROUTE_EVENT.BACK,
                    {
                      signupData,
                    },
                  ),
                );
              },
            },
            exit: {
              text: 'Save and exit',
              handler() {
                saveAndNavigate(`/learner/${student?.mongoStudentId}/home`);
              },
            },
            next: {
              text: 'Next step',
              disabled: !isCoursePlacementValid(),
              handler() {
                saveAndNavigate(
                  getNextPage(
                    navStates.onboarding.coursePlacement,
                    ROUTE_EVENT.SUBMIT,
                    {
                      signupData,
                    },
                  ),
                );
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default CoursePlacement;
