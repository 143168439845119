import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import { map, prop } from 'ramda';

// temporary for jan2022 launch
// eventually these should be moved into the DB along with
// all other constants
export const SCIENCE_TRACKS = [
  {
    name: 'biology',
    displayName: 'Biology',
  },
  {
    name: '3d_design',
    displayName: '3D Design',
  },
  {
    name: 'chemistry',
    displayName: 'Chemistry',
  },
  {
    name: 'environmental_science',
    displayName: 'Environmental Science',
  },
  {
    name: 'physics',
    displayName: 'Physics',
  },
  {
    name: 'video_editing',
    displayName: 'Video Editing',
  },
];

export const TRACKS = [
  // computer science
  {
    name: 'juni_jr',
    displayName: 'Juni Jr',
  },
  {
    name: 'game_superstar',
    displayName: 'Scratch: Game Superstar',
  },
  {
    name: 'scratch_level_1',
    displayName: 'Scratch Level 1: Game Superstar',
  },
  {
    name: 'scratch_level_1_bootcamp',
    displayName: 'Scratch Level 1: Game Superstar (Private 4-Class Bootcamp)',
  },
  {
    name: 'scratch_level_2',
    displayName: 'Scratch Level 2: Game Master',
  },
  {
    name: 'web_superstar',
    displayName: 'Web Level 1: Web Superstar',
  },
  {
    name: 'javascript_level_1',
    displayName: 'JavaScript Level 1: JavaScript Superstar',
  },
  {
    name: 'web_app_master',
    displayName: 'Web Level 2: App Master',
  },
  {
    name: 'web_dev_1',
    displayName: 'Web Design and Development',
  },
  {
    name: 'web_dev_1_on_demand',
    displayName: 'Intro to Web Development (On Demand)',
  },
  {
    name: 'javascript_level_2',
    displayName: 'JavaScript Level 2: JavaScript Master',
  },
  {
    name: 'python_graphics_superstar', // deprecating in favor of new version, python_level_1
    displayName: 'Python Level 1: Graphics Superstar',
  },
  {
    name: 'python_level_1',
    displayName: 'Python Level 1: Graphics Superstar',
  },
  {
    name: 'python_level_1_async_a',
    displayName:
      'Python Level 1: Graphics Superstar (Self-learning with Live Help Membership)',
  },
  {
    name: 'python_level_1_bootcamp',
    displayName: 'Python Level 1: Graphics Superstar (Private 4-Class Bootcamp)',
  },
  {
    name: 'python_level_1_group',
    displayName: 'Python Level 1: Graphics Superstar (Group Course)',
  },
  {
    name: 'python_problem_solver',
    displayName: 'Python Level 2: Problem Solver',
  },
  {
    name: 'python_level_2_async',
    displayName:
      'Python Level 2: Problem Solver (Self-learning with Live Help Membership)',
  },
  {
    name: 'python_data_master',
    displayName: 'Python Level 3: Data & Algorithms Master',
  },
  {
    name: 'python_level_3',
    displayName: 'Python Level 3: Algorithms Master',
  },
  {
    name: 'pygame',
    displayName: 'PyGame: Python Game Development',
  },
  {
    name: 'python_data_science',
    displayName: 'Data Science in Python',
  },
  {
    name: 'ai_level_1',
    displayName: 'AI Level 1: Foundations of Artificial Intelligence',
  },
  {
    name: 'ai_level_2',
    displayName: 'AI Level 2: Machine Learning',
  },
  {
    name: 'java_level_1',
    displayName: 'Java Level 1: Java Superstar',
  },
  {
    name: 'java_level_2',
    displayName: 'Java Level 2: Java Master',
  },
  {
    name: 'java_level_3',
    displayName: 'Java Level 3: Advanced Java',
  },
  {
    name: 'ap_cs',
    displayName: 'Java: AP Computer Science A',
  },
  {
    name: 'ap_computer_science_a',
    displayName: 'AP Computer Science A',
  },
  {
    name: 'cpp_level_1',
    displayName: 'C++ Level 1: C++ Fundamentals',
  },
  {
    name: 'hacking_and_cybersecurity',
    displayName: 'Hacking and Cybersecurity',
  },
  {
    name: 'video_game_development',
    displayName: 'Video Game Development',
  },
  {
    name: 'app_development',
    displayName: 'App Development',
  },
  {
    name: 'minecraft',
    displayName: 'Minecraft',
  },
  {
    name: 'minecraft_on_demand',
    displayName: 'Minecraft (On Demand)',
  },
  {
    name: 'robotics_scratch',
    displayName: 'Robotics (Scratch)',
  },
  {
    name: 'robotics_scratch_on_demand',
    displayName: 'Robotics - On Demand (Scratch)',
  },
  {
    name: 'robotics_python',
    displayName: 'Robotics (Python)',
  },
  {
    name: 'robotics_python_on_demand',
    displayName: 'Robotics - On Demand (Python)',
  },
  {
    name: 'google_interview',
    displayName: 'Google Interview',
  },
  {
    name: 'usaco_bronze',
    displayName: 'USACO Bronze',
  },
  {
    name: 'usaco_bronze_on_demand',
    displayName: 'USACO Bronze (On Demand)',
  },
  {
    name: 'usaco_silver',
    displayName: 'USACO Silver',
  },
  {
    name: 'usaco_gold',
    displayName: 'USACO Gold',
  },
  // mathematics
  {
    name: 'math_foundations_a',
    displayName:
      'Early Elementary A: Discovering Numbers, Operations, and Measurement',
  },
  {
    name: 'math_foundations_b',
    displayName: 'Early Elementary B: Exploring Arithmetic, Fractions, and Geometry',
  },
  {
    name: 'math_applications_a',
    displayName:
      'Late Elementary A: Investigating Multiplication, Division, and Geometry',
  },
  {
    name: 'math_applications_b',
    displayName:
      'Late Elementary B: Mastering Fractions, Decimals, Units, and Coordinates',
  },
  {
    name: 'pre_algebra_a_v2',
    displayName: 'Pre-Algebra A',
  },
  {
    name: 'pre_algebra_b_v2',
    displayName: 'Pre-Algebra B',
  },
  {
    name: 'algebra_1_a',
    displayName: 'Algebra 1A',
  },
  {
    name: 'algebra_1_b',
    displayName: 'Algebra 1B',
  },
  {
    name: 'geometry_a',
    displayName: 'Geometry A',
  },
  {
    name: 'geometry_b',
    displayName: 'Geometry B',
  },
  {
    name: 'algebra_2_a',
    displayName: 'Algebra 2A',
  },
  {
    name: 'algebra_2_b',
    displayName: 'Algebra 2B',
  },
  {
    name: 'pre_calc_trig_a',
    displayName: 'Pre-Calculus & Trigonometry A',
  },
  {
    name: 'pre_calc_trig_b',
    displayName: 'Pre-Calculus & Trigonometry B',
  },
  {
    name: 'ap_calculus',
    displayName: 'AP Calculus',
  },
  // english
  {
    name: 'english_early_elementary_a',
    displayName: 'Early Elementary A: Discovering the Joy of Reading',
  },
  {
    name: 'english_early_elementary_b',
    displayName: 'Early Elementary B: Write Your Own Picture Book',
  },
  {
    name: 'english_core_middle_school_a',
    displayName: 'Middle School A: Reading & Analyzing Literature',
  },
  {
    name: 'english_core_middle_school_b',
    displayName: 'Middle School B: Analytical & Creative Writing',
  },
  {
    name: 'english_core_middle_school_c',
    displayName: 'Middle School C: Grammar & Mechanics',
  },
  {
    name: 'novel_writing',
    displayName: 'Novel Writing',
  },
  {
    name: 'public_speaking',
    displayName: 'Make Your Point: Introduction to Public Speaking',
  },
  // investing and entrepreneurship
  {
    name: 'investing_stock_market',
    displayName: 'Money-Minded: Investing in the Stock Market',
  },
  {
    name: 'investing_stock_market_async',
    displayName:
      'Money-Minded: Investing in the Stock Market (Self-learning with Live Help Membership)',
  },
  {
    name: 'investing_ondemand',
    displayName: 'Money-Minded: Investing in the Stock Market (On-Demand)',
  },
  {
    name: 'investing_stock_market_bootcamp',
    displayName:
      'Money-Minded: Investing in the Stock Market (Private 4-Class Bootcamp)',
  },
  {
    name: 'personal_finance',
    displayName: 'Smart Money: Introduction to Personal Finance',
  },
  {
    name: 'entrepreneurship',
    displayName: 'Be Your Own Boss: Entrepreneurship 101',
  },
  {
    name: 'negotiations',
    displayName: 'Negotiations',
  },
  {
    name: 'sports_data_and_management_bootcamp',
    displayName: 'Sports Data and Management (Private 4-class Bootcamp)',
  },
  {
    name: 'economics_of_pop_culture',
    displayName: 'Economics of Pop Culture',
  },
  {
    name: 'cryptocurrency',
    displayName: 'Cryptocurrency',
  },
  // science
  ...SCIENCE_TRACKS,
];

export const getDisplayName = name =>
  TRACKS.filter(track => track.name === name).pop()?.displayName || '';

export const ROADMAP_BUNDLE_GROUPS = [
  {
    subject: SUBJECTS.COMPUTER_SCIENCE,
    bundles: [
      {
        name: `${SUBJECT_DISPLAY_NAMES[SUBJECTS.COMPUTER_SCIENCE]} Core`,
        tracks: [
          'scratch_level_1',
          'scratch_level_2',
          'python_level_1',
          'python_problem_solver',
          'python_level_3',
          'ap_computer_science_a',
        ],
      },
      {
        name: 'Java Focus',
        tracks: [
          'python_level_1',
          'python_problem_solver',
          'java_level_1',
          'java_level_2',
          'java_level_3',
        ],
      },
      {
        name: 'Artificial Intelligence & Data Science',
        tracks: [
          'python_level_1',
          'python_problem_solver',
          'python_data_science',
          'python_level_3',
          'ai_level_1',
          'ai_level_2',
        ],
      },
      {
        name: 'Game Development',
        tracks: ['scratch_level_1', 'scratch_level_2', 'python_level_1', 'pygame'],
      },
      {
        name: 'Web Development',
        tracks: [
          'python_level_1',
          'python_problem_solver',
          'javascript_level_1',
          'javascript_level_2',
        ],
      },
      {
        name: 'Competitive Programming',
        tracks: [
          'python_level_1',
          'python_problem_solver',
          'python_level_3',
          'usaco_bronze',
          'usaco_silver',
          'usaco_gold',
        ],
      },
    ],
  },
  {
    subject: SUBJECTS.MATHEMATICS,
    bundles: [
      {
        name: `Elementary ${SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS]} Core`,
        tracks: [
          'math_foundations_a',
          'math_foundations_b',
          'math_applications_a',
          'math_applications_b',
        ],
      },
      {
        name: `Middle School ${SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS]} Core`,
        tracks: [
          'pre_algebra_a_v2',
          'pre_algebra_b_v2',
          'algebra_1_a',
          'algebra_1_b',
        ],
      },
      {
        name: `High School ${SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS]} Core`,
        tracks: [
          'geometry_a',
          'geometry_b',
          'algebra_2_a',
          'algebra_2_b',
          'pre_calc_trig_a',
          'pre_calc_trig_b',
          'ap_calculus',
        ],
      },
    ],
  },
  {
    subject: SUBJECTS.ENGLISH,
    bundles: [
      {
        name: `${SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH]} Electives`,
        tracks: ['novel_writing', 'public_speaking'],
      },
      {
        name: `Elementary ${SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH]} Core`,
        tracks: ['english_early_elementary_a', 'english_early_elementary_b'],
      },
      {
        name: `Middle School ${SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH]} Core`,
        tracks: [
          'english_core_middle_school_a',
          'english_core_middle_school_b',
          'english_core_middle_school_c',
        ],
      },
    ],
  },
  {
    subject: SUBJECTS.INVESTING,
    bundles: [
      {
        name: `${SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING]} Core`,
        tracks: ['investing_stock_market', 'personal_finance', 'entrepreneurship'],
      },
    ],
  },
].map(({ subject, bundles }) => ({
  subject,
  bundles: bundles.map(bundle => ({
    name: bundle.name,
    tracks: bundle.tracks.map(trackName => ({
      name: trackName,
      displayName: getDisplayName(trackName),
    })),
  })),
}));

export const CS_TEAM_TRACKS = [
  'python_graphics_superstar', // deprecating in favor of new version, python_level_1
  'python_level_1',
  'python_problem_solver',
];

export const MATH_TEAM_TRACKS = [
  'pre_algebra_a_v2',
  'algebra_1_a',
  'pre_algebra_b_v2',
  'algebra_1_b',
];

export const ORDERED_TRACKS_LIST = [
  'juni_jr',
  'scratch_level_1',
  'scratch_level_1_bootcamp',
  'scratch_level_2',
  'game_superstar',
  'python_graphics_superstar', // deprecating in favor of new version, python_level_1
  'python_level_1',
  'python_level_1_async_a',
  'python_level_1_bootcamp',
  'python_level_1_group',
  'python_problem_solver',
  'python_level_2_async',
  'python_level_3',
  'pygame',
  'video_game_development',
  'minecraft',
  'minecraft_on_demand',
  'python_data_science',
  'ai_level_1',
  'ai_level_2',
  'robotics_scratch',
  'robotics_scratch_on_demand',
  'robotics_python',
  'robotics_python_on_demand',
  'python_data_master',
  'java_level_1',
  'java_level_2',
  'java_level_3',
  'ap_computer_science_a',
  'ap_cs',
  'cpp_level_1',
  'web_superstar',
  'web_app_master',
  'javascript_level_1',
  'javascript_level_2',
  'web_dev_1',
  'web_dev_1_on_demand',
  'hacking_and_cybersecurity',
  'app_development',
  'usaco_bronze',
  'usaco_bronze_on_demand',
  'usaco_silver',
  'usaco_gold',
  'google_interview',
  'investing_stock_market',
  'investing_stock_market_async',
  'investing_ondemand',
  'investing_stock_market_bootcamp',
  'entrepreneurship',
  'personal_finance',
  'negotiations',
  'sports_data_and_management_bootcamp',
  'economics_of_pop_culture',
  'cryptocurrency',
  'math_foundations_a',
  'math_foundations_b',
  'math_applications_a',
  'math_applications_b',
  'pre_algebra_a_v2',
  'pre_algebra_a',
  'pre_algebra_b_v2',
  'pre_algebra_b',
  'algebra_1_a',
  'algebra_1_b',
  'geometry_a',
  'geometry_b',
  'algebra_2_a',
  'algebra_2_b',
  'pre_calc_trig_a',
  'pre_calc_trig_b',
  'ap_calculus',
  'biology',
  'chemistry',
  'environmental_science',
  'physics',
  '3d_design',
  'video_editing',
  'english_early_elementary_a',
  'english_early_elementary_b',
  'english_core_middle_school_a',
  'english_core_middle_school_b',
  'english_core_middle_school_c',
  'novel_writing',
  'public_speaking',
];

export const DEFAULT_TRACK_ORDER: {
  [key: string]: number,
} = ORDERED_TRACKS_LIST.reduce(
  (agg, cur, i) => ({
    ...agg,
    [cur]: i + 1,
  }),
  {},
);

const ASYNC_COURSES = [
  {
    name: 'python_level_1_async_a',
    _id: '61413144cfc4cb66279124ff',
  },
  {
    name: 'python_level_2_async',
    _id: '619bf8d1d39148135d94f5d7',
  },
  {
    name: 'investing_stock_market_async',
    _id: '61cb45e5fc19f55e1fc32651',
  },
  {
    name: 'investing_ondemand',
    _id: '61e83393ac4127344f03be7a',
  },
  {
    name: 'web_dev_1_on_demand',
    _id: '61f044ec5809c704d86fc911',
  },
  {
    name: 'robotics_scratch_on_demand',
    _id: '61e8b9116f656774c378fef4',
  },
  {
    name: 'robotics_python_on_demand',
    _id: '61e8bb96eb4b7c4008392d0e',
  },
  {
    name: 'minecraft_on_demand',
    _id: '619ea26892d61d670b2f1895',
  },
  {
    name: 'usaco_bronze_on_demand',
    _id: '61f044be351e8f33886739f3',
  },
];

export const ASYNC_COURSE_NAMES = map(prop('name'), ASYNC_COURSES);
export const ASYNC_COURSE_IDS = map(prop('_id'), ASYNC_COURSES);

/**
 * Maps courses no longer offered to courses with credit toward certifications.
 * @type {{[key: string]: string}}
 */
export const TRACK_COUNTS_TOWARD_MAP = {
  ap_cs: 'ap_computer_science_a',
  pre_algebra_a: 'pre_algebra_a_v2',
  pre_algebra_b: 'pre_algebra_b_v2',
  python_graphics_superstar: 'python_level_1',
  python_level_1_group: 'python_level_1',
  python_level_1_async_a: 'python_level_1',
  python_data_master: 'python_level_3',
  game_superstar: 'scratch_level_1',
  investing_stock_market_async: 'investing_stock_market',
  investing_ondemand: 'investing_stock_market',
};
