import styled from 'styled-components/macro';
import classNames from 'classnames';
import { jDark, jPurple } from 'theme/colors';

/** Tailwind breakpoint for medium in px */
const MOBILE_WIDTH = 768;

/** Tailwind breakpoint for large in px. iPad Pro width */
const TABLET_WIDTH = 1024;

/** Tailwind breakpoint 7xl which is 80rem translated into px */
const MAX_WIDTH = 1280;

export const Content = styled.div.attrs({
  className: 'flex flex-col max-w-7xl md:flex-row',
})`
  margin: 1.5rem;

  @media only screen and (min-width: ${MOBILE_WIDTH}px) {
    margin: 32px 72px;
  }

  @media only screen and (min-width: ${MAX_WIDTH}px) {
    margin: 32px auto;
  }
`;

export const SideContent = styled.div.attrs({
  className: 'flex flex-col mb-8 md:mr-8',
})`
  flex: 2.5;
`;

export const MainContent = styled.div.attrs({ className: 'flex flex-col' })`
  flex: 7.5;
  > * {
    margin-bottom: 1.5rem;
  }
`;

/** Recap Page Cards */

/** Quick Stats Table */
export const Table = styled.table.attrs({ className: 'border-collapse' })`
  font-family: 'Graphik';
  width: 100%;

  th {
    color: ${jDark[200]};
    font-weight: 500;
    font-size: 12px;
    padding: 24px;
    text-transform: uppercase;
  }
  th:first-child {
    text-align: left;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid ${jDark[100]};
    padding: 24px;
  }
  td {
    padding: 24px;
    text-align: center;
  }

  td:first-child {
    color: ${jDark[600]};
    font-size: 12px;
    text-align: left;
  }
  td:not(:first-child) {
    color: ${jPurple[600]};
    font-size: 16px;
    font-weight: 500;
  }
  td:nth-child(2),
  th:nth-child(2) {
    background-color: ${jPurple[100]};
  }

  th:nth-child(2) {
    border-top: 5px solid ${jPurple[500]};
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  tr:last-child td:nth-child(2) {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

/** Latest Project Card */
export const LatestProjCardContent = styled.div.attrs({
  className: 'flex flex-col flex-1 sm:flex-row',
})`
  flex: 4;
`;

export const StudentInfoContent = styled.div.attrs({
  className: 'flex flex-col justify-center items-center w-full',
})`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

/** Referral Banners */
export const GiftCardImg = styled.img.attrs({ className: 'mb-10 lg:mb-0' })`
  width: 200px;
  height: 140px;

  @media only screen and (min-width: ${MOBILE_WIDTH}px) {
    height: 215px;
    width: 305px;
  }

  @media only screen and (min-width: ${TABLET_WIDTH}px) {
    margin-right: 134px;
  }
`;

export const JoinBannerContent = styled.div.attrs({
  className: classNames(
    'font-graphik text-center lg:text-left',
    'max-w-5xl',
    'flex flex-col justify-center items-center lg:flex-row',
    'mx-6 my-16',
  ),
})`
  @media only screen and (min-width: ${TABLET_WIDTH}px) {
    padding: 182.5px 120px;
  }

  @media only screen and (min-width: ${MOBILE_WIDTH}px) {
    padding: 40px 84px;
  }
`;
