import styled from 'styled-components';
import CheckboxV2 from 'components/CheckboxV2';

export const selectFieldStyles = {
  control: (provided: any) => ({
    ...provided,
    background: '#fff',
    color: '#292563',
    'font-size': '13px',
    border: '1px solid #cac9d8',
    'border-radius': '0.5rem',
    'box-sizing': 'border-box',
    'min-height': '2.5rem',
    width: '100%',
    ':hover': {
      border: '1px solid #cac9d8',
    },
    'font-family': 'inherit',
    padding: '2px 4px',
    'line-height': 'normal',
  }),
  menu: (provided: any) => ({
    ...provided,
    'font-size': '13px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#292563',
    fontSize: '13px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#292563',
  }),
};

export const DatePickerContainer = styled.div`
  display: flex;
  flex: 1 0;
  align-items: flex-end;
  margin-right: 1rem;
  width: 100%;
  .ui-field {
    flex: 1;
    width: max-content;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .ui-input {
    height: 2.5rem;
    background: #fff;
    border: 1px solid #cac9d8; //j-dark-200
    padding: 0 0 0 0.75rem;
    font-size: 0.87rem;
    line-height: 1.25rem;
    font-family: 'Graphik', sans-serif;
    color: '#292563'; //j-dark-600
  }
  .ui-input:hover {
    border: 1px solid #9492b1; //j-dark-300
  }
  .ui-input:focus {
    border: 1px solid #9492b1; //j-dark-300
    box-shadow: 0 0 0 2px #e2e1ed;
    padding-left: 0.75rem;
  }
  .ui-input:active {
    border: 1px solid #292563; //j-dark-600
    box-shadow: 0 0 0 2px #e2e1ed;
    padding-left: 0.75rem;
  }
  .react-datepicker__close-icon::after {
    background: #413e75; //j-dark-500
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  label {
    font-size: 14px;
    line-height: 1.7;
  }
  .checkbox-v2-container {
    margin: 0 0.5rem 0 0;
  }
`;

export const ModalCheckbox = styled(CheckboxV2)`
  margin: input {
    margin: 0 0.5rem 0 0;
  }
`;
