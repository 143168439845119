import { FC } from 'react';
import styled from 'styled-components/macro';

import { JuniLogo } from 'components/brand-assets';

const HeroContainer = styled.section<{ bgUrl: string }>`
  background-image: url(${props => props.bgUrl});
  height: 36rem;

  @media (max-width: 767px) {
    height: 32rem;
  }

  .darkened__container {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
`;

interface HeroSectionProps {
  content: {
    headline: string;
    image: string;
  };
}

const HeroSection: FC<HeroSectionProps> = ({ content }) => (
  <HeroContainer
    className={`
      bg-cover
      bg-center
      bg-no-repeat
      lg:bg-top
    `}
    bgUrl={content.image}
  >
    <div className="darkened__container flex flex-col">
      <div className="container mx-auto py-4">
        <div className="flex justify-center">
          <JuniLogo color="light" size="lg" />
        </div>
      </div>
      <div className="container mt-14 md:mt-20 mx-auto w-full md:w-2/3">
        <h2 className="w-3/4 md:w-4/5 mx-auto text-white text-4xl md:text-5xl xl:text-6xl my-auto font-semibold text-center">
          {content.headline}
        </h2>
      </div>
    </div>
  </HeroContainer>
);

export default HeroSection;
