import _ from 'lodash';
import SpinnerV2 from 'components/SpinnerV2';
import { Badge, Divider, Icon, NewButton, NewCard, Message } from 'core-components';
import { QueryResult } from '@apollo/client';
import {
  Course,
  LoadStudentsByParentIdsQuery,
  LoadStripeSubscriptionsByParentIdQuery,
  Student,
  useAddStudentCourseMutation,
  TagType,
} from 'generated/graphql';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { hasAsync, isSubscriptionForStudentId } from 'utils/stripe';
import React from 'react';
import { CLImg } from 'app/admin/curriculum/CourseListing/CourseListingComponents';

export interface CourseCardProps {
  course: Course;
  student: Student;
  setCurrentCourse: React.Dispatch<React.SetStateAction<Course | undefined>>;
  subscriptionsQuery: QueryResult<LoadStripeSubscriptionsByParentIdQuery>;
  studentsQuery: QueryResult<LoadStudentsByParentIdsQuery>;
}

const CourseCard: React.FC<CourseCardProps> = ({
  course,
  student,
  setCurrentCourse,
  studentsQuery,
  subscriptionsQuery,
}) => {
  const [addCourse, { loading, error }] = useAddStudentCourseMutation();

  const enrolledTracks = student.hasMultipleTracks
    ? student.tracks
    : [student.track];

  const subscriptions =
    subscriptionsQuery?.data?.stripeSubscriptionsByParentId?.items || [];
  const [activeSubscription] = _.filter(subscriptions, subscription =>
    isSubscriptionForStudentId(subscription, student._id.toString()),
  );
  const getStatusButton = () => {
    if (student.completedTracks?.includes(course.name)) {
      return (
        <div className="bg-j-green-100 rounded-lg py-2 px-4 text-j-green-700 flex flex-row items-center gap-2 m-0.5">
          <Icon.CheckCircle className="h-3.5 w-3.5" />
          <span className="text-sm leading-6 font-medium">Completed</span>
        </div>
      );
    }
    if (!hasAsync(activeSubscription)) {
      return (
        <NewButton
          intent="success"
          onClick={() => {
            JuniAnalytics.track(
              'on_demand_course_explorer_course_card_button_clicked',
              {
                course,
                has_async_subscription: false,
              },
            );
            setCurrentCourse(course);
          }}
        >
          Unlock Course
        </NewButton>
      );
    }
    if (enrolledTracks?.includes(course.name)) {
      return (
        <div className="bg-j-gray-200 rounded-lg py-2 px-4 text-j-dark-400 flex flex-row items-center gap-2 m-0.5">
          <Icon.RefreshCW className="h-3.5 w-3.5" />
          <span className="text-sm leading-6 font-medium">In Progress</span>
        </div>
      );
    }
    return (
      <NewButton
        intent="success"
        onClick={async () => {
          JuniAnalytics.track(
            'on_demand_course_explorer_course_card_button_clicked',
            {
              course,
              has_async_subscription: true,
            },
          );
          try {
            await addCourse({
              variables: {
                input: { studentId: student._id, course: course.name },
              },
            });
            studentsQuery.refetch();
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {loading ? (
          <div className="w-18 h-6">
            <SpinnerV2 size={14} />
          </div>
        ) : (
          'Add course'
        )}
      </NewButton>
    );
  };

  const ratingAverage =
    course &&
    course.statistics &&
    course.statistics.ratingCount &&
    course.statistics.ratingTotal &&
    (
      Math.round(
        (course.statistics.ratingTotal / course.statistics.ratingCount) * 10,
      ) / 10
    ).toFixed(1);

  return (
    <NewCard className="flex flex-col justify-between">
      <div className="flex flex-col content-between text-j-dark-600 text-sm w-full">
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap gap-2 max-w-max">
            {course.tags.map(tag => (
              <Badge
                key={`${course.name}-${tag.value}`}
                backgroundColor={
                  tag.type === TagType.Topic ? 'j-blue-100' : 'j-pink-100'
                }
                color={tag.type === TagType.Topic ? 'j-blue-600' : 'j-pink-600'}
                size="medium"
              >
                {tag.value}
              </Badge>
            ))}
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-4 items-start">
              {course && <CLImg course={course} />}
              <div className="flex flex-col max-w-xs w-full">
                <span className="font-medium text-lg">{course.displayName}</span>
                <span>
                  recommended ages {course.minRecommendedAge}-
                  {course.maxRecommendedAge}
                </span>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <div className="w-full max-w-max">{getStatusButton()}</div>
              <div className="w-full">
                <NewButton
                  variant="minimal"
                  fullWidth
                  size="xsmall"
                  onClick={() => {
                    JuniAnalytics.track(
                      'on_demand_course_explorer_course_details_button_clicked',
                      {
                        course,
                        has_async_subscription: hasAsync(activeSubscription),
                      },
                    );
                    window.open(
                      `https://junilearning.com/courses/${course.slug}`,
                      '_blank',
                    );
                  }}
                >
                  Details
                </NewButton>
              </div>
            </div>
          </div>
          {error && (
            <Message className="mt-2" status="error">
              There was an error adding this course! Please send us an email at{' '}
              <a href="mailto:support@learnwithjuni.com">
                support@learnwithjuni.com
              </a>{' '}
              so that we can assist.
            </Message>
          )}
        </div>
      </div>
      <div>
        <Divider className="my-4" />
        <div className="flex flex-row justify-between text-j-dark-600">
          <div className="flex items-end">
            {course.statistics?.totalEnrollments && (
              <>
                <Icon.Check className="h-full pr-2" />
                {course.statistics?.totalEnrollments} student
                {course.statistics?.totalEnrollments === 1 ? ' has' : 's have'} taken
                this course
              </>
            )}
          </div>
          {ratingAverage && (
            <div className="flex items-end">
              <Icon.Star className="h-full pr-2" />
              {ratingAverage}
              <span className="text-j-dark-300 pl-1">
                {course?.statistics?.ratingCount &&
                  ` (${course.statistics.ratingCount} ${
                    course.statistics.ratingCount > 1 ? 'reviews' : 'review'
                  })`}
              </span>
            </div>
          )}
          <div className="flex items-end">
            {course.formattedDuration && (
              <>
                <Icon.Time className="h-full pr-2" /> {course.formattedDuration}
              </>
            )}
          </div>
        </div>
      </div>
    </NewCard>
  );
};

export default CourseCard;
