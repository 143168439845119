import { FC, ReactNode } from 'react';

const CTASection: FC<{
  content: {
    headline: string;
    body?: string | ReactNode;
    callToActionButtons: Array<ReactNode>;
    image: string;
    bgClass: string;
  };
}> = ({ content }) => (
  <div className="flex flex-col items-center bg-juni-light-100 z-10">
    <div
      style={{ borderWidth: '6px' }} // tailwind has 4px and 8px, but no 6, and I don't want to overwrite the theme for this one case.
      className={`
          flex
          items-center
          justify-center

          w-40
          h-40
          md:w-60 
          md:h-60

          -mt-28
          md:-mt-40
          p-4

          rounded-full 
          border-juni-light-100 
          border-solid
          ${content.bgClass}
        `}
    >
      <img className="w-4/5" src={content.image} alt="" />
    </div>

    <div className="container w-4/5 md:w-1/2 flex flex-col items-center justify-center text-center pt-6 pb-24">
      <h4 className="text-juni-dark-900 font-medium text-2xl">{content.headline}</h4>
      {content.body && (
        <p className="mt-0 mb-8 text-juni-dark-500">{content.body}</p>
      )}

      <div className="flex flex-col w-full md:flex-row items-center justify-center md:space-x-8 space-y-4 md:space-y-0">
        {content.callToActionButtons}
      </div>
    </div>
  </div>
);

export default CTASection;
