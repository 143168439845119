export const MODAL_TITLE = 'Leave feedback for this session';

export const MODAL_DESCRIPTION = `
  We'd love to hear what you thought of this session! If you have questions or
  feedback that the instructor can address, such as a request to review certain
  concepts next class or clarify a homework assignment, please let us know here.
`;

export const DEFAULT_STATUS_MESSAGE = `
  Your feedback will be visible to both the instructor and Juni HQ. If you would like
  to share feedback with Juni HQ only or request changes to your student's schedule,
  please email us at support@learnwithjuni.com.
`;

export const ERROR_STATUS_MESSAGE = `
  Oops! We had a problem submitting your feedback. Please try again,
  and if the problem persists, email us at support@learnwithjuni.com.
`;

export const NUM_DAYS_FEEDBACK_WINDOW = 21;
