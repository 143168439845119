import React, { FC, useRef, useCallback, useEffect } from 'react';
import { useRefreshPubnubCreds } from 'app/clubs/stores/ClubStoreHelpers';

// TODO: add proper error handling
// TODO: heartbeat and connection lost indicators and behavior
// TODO: consider replacing sending payload thru pubnub with sending pubnub signal
//  which is cheaper, and having that trigger a gql update
// TODO: small optimization - don't need to unsubscribe if switching students since
//  pubnub client will be refreshed anyways

// This is the main "hidden" (ie. renders null) component in which all the setup
// logic for the FE Realtime Data Architecture is done
// This includes setting up listeners and hooks for automatically triggering
// behaviors that involve Zustand (ClubStore), PubNub, GraphQL, and the interplay beteween them
const MyClubsLoader: FC<{
  studentIdParam?: string;
  instructorUserIdParam?: string;
}> = ({ studentIdParam, instructorUserIdParam }) => {
  const [refreshPubnubCreds, cleanupPubnubCreds] = useRefreshPubnubCreds();

  const prevStudentIdParam = useRef<string | undefined>();
  const prevInstructorUserIdParam = useRef<string | undefined>();

  const refreshCreds = useCallback(async () => {
    await refreshPubnubCreds({
      studentIdParam,
      instructorUserIdParam,
    });
  }, [refreshPubnubCreds, studentIdParam, instructorUserIdParam]);

  if (
    prevStudentIdParam.current !== studentIdParam ||
    prevInstructorUserIdParam.current !== instructorUserIdParam
  ) {
    refreshCreds();
    prevStudentIdParam.current = studentIdParam;
    prevInstructorUserIdParam.current = instructorUserIdParam;
  }

  useEffect(
    () => cleanupPubnubCreds,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // TODO: reliable cross-browser tab syncing. Can use git history to get
  // previous approach that used localstorage connected to a Zustand subscriber
  // Don't render anything! This is an invisible component
  return null;
};

// React.memo used to ensure it only reloads when necessary
export default React.memo(MyClubsLoader);
