import * as R from 'ramda';

import { BundleData, BundleSelection, SignupData, SubjectName } from '../types';
import findStudentById from './findStudentById';

const updateBundleDataByStudent = (
  studentId: string | undefined,
  subjectName: SubjectName,
  updateData: Partial<BundleSelection>,
  signupData: Partial<SignupData>,
): Partial<SignupData> => {
  const bundleData = R.assocPath(
    ['bundle', 'selections', subjectName],
    updateData,
  )(findStudentById(studentId, signupData) ?? {}) as BundleData;

  return {
    ...signupData,
    students:
      signupData.students?.map(student =>
        student._id === studentId
          ? {
              ...student,
              ...bundleData,
            }
          : student,
      ) ?? [],
  };
};

export default updateBundleDataByStudent;
