import juniAxios from 'services/axios';

// ADMIN
export const getReplDetails = async () =>
  juniAxios.get(`/repl/admin/get_repl_details`).then(res => res.data);

export const unassignRepl = async userId =>
  juniAxios.post(`/repl/admin/unassign`, { userId }).then(res => res.data);

export const forceCloseReplServer = async (arn, server) =>
  juniAxios
    .post(`/repl/admin/force_close_coordination`, { arn, server })
    .then(res => res.data);

export const forceCloseRepl = async (arn, server, container) =>
  juniAxios
    .post(`/repl/admin/force_close`, { arn, server, container })
    .then(res => res.data);

export const setDesiredReplVersion = async (server, newVersion) =>
  juniAxios
    .post(`/repl/admin/set_desired_repl_version`, { server, newVersion })
    .then(res => res.data);

// LEARNER
export const requestReplStudent = async studentId =>
  juniAxios.post(`/repl/learner/request_repl/`, { studentId }).then(res => res.data);

export const selfUnassignStudent = async (studentId, source) =>
  juniAxios
    .post(`/repl/learner/unassign`, { studentId, source })
    .then(res => res.data);

// INSTRUCTOR
export const requestReplTeacher = async () =>
  juniAxios.post(`/repl/instructor/request_repl/`, {}).then(res => res.data);

export const selfUnassignTeacher = async (_instructorId, source) =>
  juniAxios
    .post(
      `/repl/instructor/unassign`,
      { source }, // instructorId will be inferred from auth login by server
    )
    .then(res => res.data);

// PUBLIC
export const requestReplPublic = async (userId, projectId, userType = 'student') =>
  juniAxios
    .post(`/repl/public/request_repl/`, { userId, projectId, userType })
    .then(res => res.data);
