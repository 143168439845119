import { ApolloError } from '@apollo/client';
import { getApolloErrorInfo } from 'services/apollo/errors';
import { logErrorToSentry } from 'services/sentry';
import { GENERIC_ERROR_MESSAGE, ERROR_MESSAGES } from '../constants/errors';

export function getResponseErrorType(error: any) {
  return error?.response?.data?.type;
}

export interface ErrorInfo {
  message: string;
  code: string;
  extensions?: Record<string, any>;
}

export function getErrorInfo(error: any) {
  const responseErrorType = getResponseErrorType(error);
  if (responseErrorType && error.config) {
    if (responseErrorType === 'user-visible-error') {
      return {
        code: responseErrorType,
        message: error?.response?.data?.error || GENERIC_ERROR_MESSAGE,
      };
    }
    // old REST endpoints use { type: <code> } to pass the error code
    return {
      code: responseErrorType,
      message: (ERROR_MESSAGES as any)[responseErrorType] || GENERIC_ERROR_MESSAGE,
    };
  }
  if (error.config && error.response?.data?.error) {
    return {
      code: 'unknown-server-error',
      message: GENERIC_ERROR_MESSAGE,
    };
  }
  if (error.message === 'Network Error') {
    return {
      code: 'network-error',
      message: ERROR_MESSAGES['network-error'],
    };
  }
  if (error instanceof ApolloError) {
    return getApolloErrorInfo(error);
  }
  return {
    code: 'unknown-error',
    internalLogMessage: error,
    message: GENERIC_ERROR_MESSAGE,
  };
}

export function logError(error: any) {
  // ignore ApolloError as handled by @apollo/link-error
  if (error instanceof ApolloError) {
    return;
  }

  if (error.config && error.response?.data?.error) {
    logErrorToSentry(error);
    console.error(
      `Server Error on ${error.config.url}: ${error.response.data.error}`,
    );
  } else if (error.message === 'Network Error') {
    console.error(`Network error on ${error.config.url}: ${error.message}`);
  } else {
    logErrorToSentry(error);
    console.error(error);
  }
}

// parses error into a readable form and logs it
export function parseError(
  error: any,
  errorPrelude = 'Sorry, something went wrong.',
): string {
  logError(error);

  const errorInfo = getErrorInfo(error);
  return `${errorPrelude} ${errorInfo.message}`;
}
