import React from 'react';
import { MathIcon, EnglishIcon, CodeIcon, InvestingIcon } from 'components/Icons';
import { Icon as CoreIcons } from 'core-components';
import { SUBJECTS, SUBJECT_TYPE } from 'constants/subjects';

const iconMap = {
  [SUBJECTS.COMPUTER_SCIENCE]: CodeIcon,
  [SUBJECTS.MATHEMATICS]: MathIcon,
  [SUBJECTS.ENGLISH]: EnglishIcon,
  [SUBJECTS.INVESTING]: InvestingIcon,
};

interface SubjectIconProps extends React.HTMLAttributes<HTMLDivElement> {
  subject: SUBJECT_TYPE;
}

const SubjectIcon: React.FC<SubjectIconProps> = ({ subject, ...otherProps }) => {
  // since the prop types are different for core-components icons, science needs to be separate here
  if (subject === SUBJECTS.SCIENCE) {
    return <CoreIcons.Atom />;
  }
  const Icon = iconMap[subject];
  return <Icon {...otherProps} />;
};

export default SubjectIcon;
