import React from 'react';
import ReactMarkdown from 'react-markdown';

import Banner from '../Banner';
import ParagraphText from './ParagraphText';

interface CourseBannerProps {
  courseDisplayName: string;
  roadmapLinkBase: string;
  suggestedAge?: string;
  description?: string[];
}

const CourseBanner: React.FC<CourseBannerProps> = ({
  courseDisplayName,
  roadmapLinkBase,
  suggestedAge,
  description,
}) => (
  <Banner title={courseDisplayName} backLink={roadmapLinkBase}>
    <div className="my-4 max-w-prose">
      {suggestedAge && (
        <p className="text-sm">Suggested age range: {suggestedAge}</p>
      )}
      {description && (
        <ParagraphText>
          <ReactMarkdown
            renderers={{
              link: ({ href, children }) => (
                <a
                  href={href}
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {children}
                </a>
              ),
            }}
          >
            {description.join('\n\n')}
          </ReactMarkdown>
        </ParagraphText>
      )}
    </div>
  </Banner>
);

export default CourseBanner;
