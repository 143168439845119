import React from 'react';
import styled from 'styled-components';

import RichTextViewer from 'components/RichTextViewer';

import { useLoadPromptByIdQuery } from 'generated/graphql';
import { ErrorableLoading } from 'components/ui';

interface Props {
  writingPromptId: string;
}

const WritingPrompt: React.FC<Props> = ({ writingPromptId }) => {
  const { error, data } = useLoadPromptByIdQuery({
    variables: { input: writingPromptId },
  });
  if (!data) {
    return <ErrorableLoading error={error} />;
  }
  const richTextState = data.promptById?.content;
  return (
    <div>
      {richTextState ? (
        <RichTextViewer richTextState={richTextState} />
      ) : (
        <StyledErrorMessage>
          Oops! Sorry, something went wrong while trying to load this Writing Prompt.
        </StyledErrorMessage>
      )}
    </div>
  );
};

const StyledErrorMessage = styled.div`
  color: hsl(0, 72%, 49%);
`;

export default WritingPrompt;
