import { useEffect, useState } from 'react';
import makeSignupSessionsService, {
  GuestAccountStudentData,
} from 'services/signupSessions/signupSessionsService';

interface UseGuestAccountLookupReturnValue {
  isLoading: boolean;
  isValid: boolean;
  errorCode?: number;
  guestAccount?: GuestAccountStudentData;
}

const useGuestAccountLookup = (
  invitationLookupId?: string,
): UseGuestAccountLookupReturnValue => {
  const [guestAccount, setGuestAccount] = useState<
    GuestAccountStudentData | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [errorCode, setErrorCode] = useState<number | undefined>();

  useEffect(() => {
    if (!invitationLookupId) return;

    const fetchGuestStudentInformation = async () => {
      const service = makeSignupSessionsService();
      setErrorCode(undefined);
      const res = await service.fetchGuestInformation(invitationLookupId);
      if (res.data && res.data._id) {
        setGuestAccount(res.data as GuestAccountStudentData);
        setIsValid(true);
      } else if (!res.data._id) {
        setIsValid(false);
      }
      setIsLoading(false);
    };

    try {
      if (!guestAccount) {
        fetchGuestStudentInformation();
      }
    } catch (e) {
      setIsLoading(false);
      if (e?.response.status >= 400) {
        setIsValid(false);
      } else {
        setErrorCode(e?.response.status);
      }
    }
  }, [guestAccount, invitationLookupId]);

  return {
    isLoading,
    isValid,
    errorCode,
    guestAccount,
  };
};

export default useGuestAccountLookup;
