import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { JuniLogo } from 'components/brand-assets';

import './styles/bar.css';
import './styles/jide_rec_studio.css';

// PRIVATE COMPONENTS
// Device selector component
// TODO: ensure this works properly for Firefox
const JRSDeviceSelector = ({
  icon,
  headerText,
  activeDevice,
  hasNoActiveDevice,
  devices = [],
  setActiveDevice,
  isUsableDevice,
  selectorNumber,
  disabled,
}) => {
  // DL stands for "DeviceList"
  const [dlIsExpanded, setDlIsExpanded] = useState(false);

  // Create reference to assign to component to help with detecting
  // outside clicks
  const thisRef = useRef(null);
  const handleClick = e => {
    if (!thisRef.current.contains(e.target)) {
      setDlIsExpanded(false);
    }
  };

  // Add devices to list
  const deviceElems = devices.map(d => {
    const isSelected = activeDevice?.deviceId === d.deviceId;
    const isUnusable = !isUsableDevice[d.deviceId];
    return (
      <div
        key={JSON.stringify(d)}
        className={`jrs-selector-device${isSelected ? ' jrs-selected' : ''}${
          isUnusable ? ' jrs-unusable' : ''
        }`}
        title={
          isUnusable
            ? "We couldn't read from this device! Another program might be using it. To use this device, try quitting the other program and then selecting this device."
            : undefined
        }
        onClick={() => {
          setActiveDevice(d);
          setDlIsExpanded(false);
        }}
      >
        {isUnusable && '\u26A0 '}
        {d.label}
      </div>
    );
  });

  // Add header text to top of list
  const dlHeader = (
    <div key={headerText} className="jrs-selector-deviceheader">
      <div className="jrs-selector-deviceheader-left">
        <FontAwesomeIcon icon={['fas', icon]} />
      </div>
      <div className="jrs-selector-deviceheader-right">{headerText}</div>
    </div>
  );
  const devicesPlusHeader = [dlHeader, ...deviceElems];

  useEffect(() => {
    if (disabled) setDlIsExpanded(false);
  }, [disabled]);
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div ref={thisRef} className={`jrs-selector jrs-selector-${selectorNumber}`}>
      {dlIsExpanded && (
        <div className="jrs-selector-devicelist">{devicesPlusHeader}</div>
      )}

      <div
        className={`jrs-selector-button${dlIsExpanded ? ' jrs-selected' : ''}${
          disabled ? ' jrs-disabled' : ' jrs-enabled'
        }`}
        onClick={() => {
          if (!disabled) {
            setDlIsExpanded(!dlIsExpanded);
          }
        }}
      >
        <FontAwesomeIcon
          icon={['fas', `${icon}${hasNoActiveDevice ? '-slash' : ''}`]}
        />
      </div>
    </div>
  );
};

// The bottom bar in the recording studio
// includes the start/stop controls, device selection, and webcam preview
const JRSBar = ({
  isExpanded,
  isRecording,
  userVideoRef,
  mics,
  cameras,
  activeMic,
  activeCamera,
  isUsableMic,
  isUsableCamera,
  noVideoDeviceId,
  recordingLength,
  setActiveMic,
  setActiveCamera,
  startRecording,
  stopRecording,
  setMode,
}) => {
  // States
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={`jrs-bar${isExpanded ? ' jrs-expanded' : ''}${
        isRecording ? ' jrs-red' : ''
      }`}
    >
      <div className="jrs-uservideo-container">
        <video ref={userVideoRef} className="jrs-uservideo" autoPlay muted />
        <div className="jrs-uservideo-background">
          <JuniLogo className="bg-juni-light-100 w-32 h-32 rounded-full p-2.5 pb-3" />
        </div>
      </div>
      <div className="jrs-options">
        {isRecording ? (
          <>
            <div className="jrs-bar-button jrs-duration">
              {moment.utc(recordingLength * 1000).format('mm:ss')}
            </div>
            <div className="jrs-bar-button jrs-maxduration">(10 min max)</div>
          </>
        ) : (
          <>
            <JRSDeviceSelector
              icon="microphone"
              headerText="Choose an Audio Device"
              activeDevice={activeMic}
              hasNoActiveDevice={false}
              devices={mics}
              setActiveDevice={setActiveMic}
              isUsableDevice={isUsableMic}
              selectorNumber={0}
              disabled={isRecording}
            />
            <JRSDeviceSelector
              icon="video"
              headerText="Choose a Video Device"
              activeDevice={activeCamera}
              hasNoActiveDevice={activeCamera?.deviceId === noVideoDeviceId}
              devices={cameras}
              setActiveDevice={setActiveCamera}
              isUsableDevice={isUsableCamera}
              selectorNumber={1}
              disabled={isRecording}
            />
          </>
        )}
      </div>
      <div className="jrs-bar-logo">
        <JuniLogo color="light" />
      </div>
      <div className="jrs-controls">
        {isRecording ? (
          <>
            <div className="jrs-bar-button jrs-stop" onClick={stopRecording}>
              <div className="jrs-bar-button-left">
                {isLoading ? (
                  <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'square']} />
                )}
              </div>
              <div className="jrs-bar-button-right">End Recording Session</div>
            </div>
          </>
        ) : (
          <>
            <div
              className="jrs-bar-button jrs-start"
              onClick={async () => {
                if (isLoading) return;
                setIsLoading(true);
                await startRecording();
                setIsLoading(false);
              }}
            >
              <div className="jrs-bar-button-left">
                {isLoading ? (
                  <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'circle']} />
                )}
              </div>
              <div className="jrs-bar-button-right">Start Recording Session</div>
            </div>
            <div
              className="jrs-bar-button jrs-cancel"
              onClick={() => setMode('close')}
            >
              <div className="jrs-bar-button-left">
                <FontAwesomeIcon icon={['fas', 'times']} />
              </div>
              <div className="jrs-bar-button-right">Cancel</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JRSBar;
