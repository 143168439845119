import React from 'react';
import { REPL } from 'components/jide';

import './jide_env_js.css';

// this is a temporary solution so we can show special instructions for JS
// long term we want a separate component, not REPL, that is JS-specific
// and reuses the modular JIDE components
const JideEnvJs = props => (
  <REPL {...props} key={`repl_${props.tab.tabId.toString()}`} />
);

export default JideEnvJs;
