const buildInstructorPlaygroundLink = ({
  projectId,
  projectType,
  projectName,
}: {
  projectId: string;
  projectType: string;
  projectName: string;
}): string =>
  `/teacher/playground/project?projectId=${projectId}&projectType=${projectType}&projectName=${projectName}`;

export default buildInstructorPlaygroundLink;
