import React from 'react';
import { NewCard as Card } from 'core-components';
import {
  Parent,
  useGetCoursesQuery,
  useLoadStripeSubscriptionsByParentIdQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { getSubscriptionDisplayName } from 'utils/stripe';
import { Student } from 'models';
import { getStudentCourseNames } from 'utils/courses';
import SubjectPreferencesCard from './SubjectPreferencesCard';

interface Props {
  student: Student;
  parent: Parent;
}

const MembershipPlanAndLearningPreferences: React.FC<Props> = ({
  student,
  parent,
}) => {
  const { activeCourseNames } = getStudentCourseNames(student);

  const { data: coursesData } = useGetCoursesQuery({
    variables: {
      input: {
        names: activeCourseNames,
      },
    },
  });
  const enrolledSubjects = _.uniqBy(
    coursesData?.getCourses.map(c => c.subject),
    s => s.name,
  );

  const { data: subscriptionData } = useLoadStripeSubscriptionsByParentIdQuery({
    variables: { id: parent._id },
  });

  const productNames = subscriptionData?.stripeSubscriptionsByParentId?.items
    .filter(
      subscription =>
        subscription.status !== 'canceled' &&
        subscription.metadata?.studentIds === student._id,
    )
    .map(subscription => getSubscriptionDisplayName(subscription));

  return (
    <Card shadow="1">
      <div className="mb-4 text-xl font-medium text-j-dark-600">
        Subscription & Learning Preferences
      </div>
      <div className="flex flex-col gap-6">
        <Card.Divider />
        <div className="grid lg:grid-cols-3 gap-6">
          <div className="flex flex-col gap-2">
            <span className="font-medium text-j-dark-600">Membership Plan</span>
          </div>
          <div className="col-span-2 flex flex-col space-y-10 text-j-dark-500">
            {productNames?.join(', ') || '-'}
          </div>
        </div>

        {enrolledSubjects.map(subject => (
          <SubjectPreferencesCard
            key={subject._id}
            subject={subject}
            student={student}
          />
        ))}
      </div>
    </Card>
  );
};

export default MembershipPlanAndLearningPreferences;
