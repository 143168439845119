import React, { useState, useEffect } from 'react';
import scratchProjectsService, { pingScratchUrl } from 'services/playgroundService';
import { IValidationResponse } from 'app/playground/models';
import { ICustomScratchProjectProps } from '../models';
import PublishProject from './PublishProject';
import AddedScratchProject from './AddedScratchProject';

export const validateScratchUrl = (url: string) => {
  const getMsg = (url: string) => {
    if (url?.substring(0, 8) !== 'https://')
      return `The URL should start with https://`;
    if (url?.substring(8, 33) !== 'scratch.mit.edu/projects/')
      return `Oops! This should be a Scratch URL in the format: https://scratch.mit.edu/projects/{projectID}`;
    if (Number.isNaN(parseInt(url?.substring(33, 50), 10)))
      return `Oops! This doesn't look like a valid Scratch project URL`;
  };

  const msg = getMsg(url);
  return {
    valid: !msg,
    msg,
  };
};

export const cleanUrl = (url: string): string => {
  if (!(url.endsWith(' ') || url.endsWith('/'))) return url;
  const Url = url.substring(0, url.length - 1);
  return cleanUrl(Url);
};

const PortfolioScratchProject: React.FC<ICustomScratchProjectProps> = ({
  user,
  moduleId,
  projectId,
  initialDisplayName,
  customScratchProject,
  setCustomScratchProject,
  playgroundProject,
  updateProjectName,
}) => {
  const [newProjectUrl, setNewProjectUrl] = useState('');
  const [editing, toggleEditing] = useState(false);
  const [scratchUrlStatus, setScratchUrlStatus] = useState<number | undefined>(
    undefined,
  );

  const [customError, setCustomError] = useState<IValidationResponse | undefined>(
    undefined,
  );

  const { valid, msg } = validateScratchUrl(newProjectUrl);

  useEffect(() => {
    const syncScratchProjectWithPlayground = async (newName: string) => {
      try {
        const project = await scratchProjectsService.update(
          customScratchProject._id,
          {
            displayName: newName,
          },
        );

        setCustomScratchProject(project);
      } catch (err) {
        console.error(err);
      }
    };

    if (
      playgroundProject &&
      customScratchProject?._id &&
      customScratchProject.displayName !== initialDisplayName
    ) {
      syncScratchProjectWithPlayground(initialDisplayName);
    }
  }, [
    customScratchProject,
    initialDisplayName,
    playgroundProject,
    setCustomScratchProject,
  ]);

  const onUpdate = async ({
    newProjectName,
    newScratchUrl,
  }: {
    newProjectName: string;
    newScratchUrl: string;
  }) => {
    if (!customScratchProject?._id) return;
    if (playgroundProject && updateProjectName) {
      try {
        const res = await updateProjectName({
          projectId: playgroundProject.projectId,
          desiredProjectName: newProjectName,
        });

        if (!res?.valid) {
          setCustomError({
            valid: false,
            msg: res?.msg,
          });
          return;
        }
        setCustomError({
          valid: true,
          msg: undefined,
        });
      } catch (err) {
        console.log(err);
      }
    }

    const project = await scratchProjectsService.update(customScratchProject._id, {
      displayName: newProjectName,
      scratchUrl: newScratchUrl,
    });
    setCustomScratchProject(project);
    toggleEditing(false);
  };

  const onSave = async () => {
    const cleanedUrl = cleanUrl(newProjectUrl);
    const urlStatus = await pingScratchUrl(cleanedUrl);
    setScratchUrlStatus(urlStatus);
    if (urlStatus !== 200) return;

    const project = await scratchProjectsService.create({
      displayName: initialDisplayName,
      scratchUrl: cleanedUrl,
      moduleId: moduleId || 'playground',
      projectId,
      userId: user._id,
      userType: user.type,
    });

    setCustomScratchProject(project);
  };

  const showError = !valid && msg && newProjectUrl !== '';

  return (
    <div
      className={`scratch-env-option ${customScratchProject ? `fix-height` : ''} ${
        playgroundProject ? 'fix-height-extend' : ''
      }`}
    >
      {customScratchProject ? (
        <AddedScratchProject
          editing={editing}
          initialDisplayName={initialDisplayName}
          onUpdate={onUpdate}
          scratchProject={customScratchProject}
          toggleEditing={toggleEditing}
          playgroundProject={playgroundProject}
        />
      ) : (
        <PublishProject
          urlDisplayErrMsg={customError ? customError.msg : msg}
          initialDisplayName={initialDisplayName}
          showError={customError ? true : showError}
          newProjectUrl={newProjectUrl}
          scratchUrlStatus={scratchUrlStatus}
          valid={valid}
          onUrlUpdate={e => {
            if (scratchUrlStatus) {
              setScratchUrlStatus(undefined);
            }
            setNewProjectUrl(e);
          }}
          onSave={onSave}
        />
      )}
    </div>
  );
};

export default PortfolioScratchProject;
