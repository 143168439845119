import { Gender } from 'generated/graphql';

export const GENDERS = [
  {
    value: Gender.Female,
    label: 'Female',
  },
  {
    value: Gender.Male,
    label: 'Male',
  },
  {
    value: Gender.Other,
    label: 'Nonbinary / Other',
  },
] as const;

export const GENDERS_BY_VALUE = GENDERS.reduce(
  (agg, cur) => ({
    ...agg,
    [cur.value]: cur,
  }),
  {},
);
