import {
  CancelCheckModal,
  CancelConfirmationModal,
  CancelFeedbackModal,
  CourseSelectorModal,
  DowngradeConfirmationModal,
  DowngradeSelectorModal,
  PaymentCheckModal,
  PaymentConfirmationModal,
  PaymentUpdateModal,
  ScheduleSelectorModal,
  StudentCreationModal,
  StudentSelectorModal,
  SubscriptionUpdateModal,
  ReduceClassFrequencyModal,
  ReduceCourseModal,
} from '..';

const getModal = modalName => {
  switch (modalName) {
    case 'student_select_or_create': {
      return StudentSelectorModal;
    }
    // landing modal to update subscription - add courses, subject, or downgrade
    case 'subscription_update': {
      return SubscriptionUpdateModal;
    }
    // select different downgrade options
    case 'downgrade_select': {
      return DowngradeSelectorModal;
    }
    // downgrade confirmation screen
    case 'downgrade_confirmation': {
      return DowngradeConfirmationModal;
    }
    case 'student_create': {
      return StudentCreationModal;
    }
    // course selection
    case 'course_select': {
      return CourseSelectorModal;
    }
    case 'schedule_select': {
      return ScheduleSelectorModal;
    }
    // payment modals
    case 'payment_check': {
      return PaymentCheckModal;
    }
    case 'payment_confirmation': {
      return PaymentConfirmationModal;
    }
    // cancellation modals
    case 'cancel_check': {
      return CancelCheckModal;
    }
    case 'cancel_feedback': {
      return CancelFeedbackModal;
    }
    case 'cancel_confirmation': {
      return CancelConfirmationModal;
    }
    case 'downgrade_course': {
      return ReduceCourseModal;
    }
    case 'downgrade_frequency': {
      return ReduceClassFrequencyModal;
    }
    case 'payment_update': {
      return PaymentUpdateModal;
    }
    default: {
      return null;
    }
  }
};

const FormStateModal = props => {
  const { formState } = props;
  const Modal = getModal(formState.modal);
  return Modal ? <Modal className="text-j-dark-600" {...props} /> : null;
};

export default FormStateModal;
