import { SUBJECT_TYPE } from 'constants/subjects';

interface Track {
  name: string;
  displayName: string;
}

export const TrackStatusTypes = {
  COMPLETED: 'Completed',
  INPROGRESS: 'In progress',
} as const;

export interface TrackStatus {
  [key: string]: typeof TrackStatusTypes[keyof typeof TrackStatusTypes] | undefined;
}

export interface Bundle {
  name: string;
  label: string;
  tracks: Track[];
}

export interface BundleGroup {
  subject: SUBJECT_TYPE;
  bundles: Bundle[];
}
