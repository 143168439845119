import eraserBase64 from 'images/base64/eraser.js';

class EraserTool {
  constructor(board) {
    this.board = board;
    this.isErasing = false;
    this.name = 'eraser';
    this.eventHandlers = {
      mousedown: this.beginErasing,
      mousemove: this.erase,
      mouseup: this.doneErasing,
      mouseleave: this.doneErasing,
      touchstart: this.beginErasing,
      touchmove: this.erase,
      touchleave: this.doneErasing,
      touchend: this.doneErasing,
      touchcancel: this.doneErasing,
    };
    this.customCursor = `url("${eraserBase64}") 1 11, crosshair`;
  }

  handleWsMsg = data => {
    if (data.type === 'remove') {
      const elemToRemove = data.id
        ? this.board.canvas.getElementById(data.id)
        : null;
      if (elemToRemove) {
        const elemClass = elemToRemove.getAttribute('class');
        if (
          elemClass &&
          (elemClass.includes('wb-grid') || elemClass.includes('wb-image'))
        ) {
          const deleteBtnToRemove = this.board.canvas.getElementById(
            `delete-${data.id}`,
          );
          if (deleteBtnToRemove) {
            this.board.canvas.removeChild(deleteBtnToRemove);
          }
          this.tools.image.removeAllImageEditingHandles();
        }
        this.board.canvas.removeChild(elemToRemove);
      }
    } else if (data.type === 'clear') {
      this.board.clear(true);
    }
  };

  beginErasing = (e, x, y) => {
    e.preventDefault();
    this.isErasing = true;
    this.erase(e, x, y);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  erase = (e, x, y) => {
    // The target for touchmove events is the element first touched on touchstart,
    // NOT the element beneath the current touch coordinates
    let target =
      e.type === 'touchmove'
        ? document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY)
        : e.target;

    // Disable eraser for images
    if (target.id.startsWith('image-')) {
      return;
    }

    // If the target is a tspan, we want to delete it's entire parent text element
    if (target.tagName === 'tspan') {
      target = target.parentElement;
    }

    // ignore elements outside the actual text input
    // if target is the text input, delete entire foreignObject
    if (target.tagName === 'foreignObject') return;
    if (target.tagName.toLowerCase() === 'div' && target.id.startsWith('text')) {
      if (target.classList.contains('text-input'))
        target = target.closest('foreignObject');
      else return;
    }

    if (this.isErasing && target !== this.board.canvas) {
      const elem = target.id ? this.board.canvas.getElementById(target.id) : null;
      if (elem) {
        this.board.appendToEditHistory({
          type: 'remove',
          elem,
        });
        this.board.canvas.removeChild(elem);
        if (!this.board.canClear()) {
          this.board.triggerHtmlRender();
        }
      }

      this.board.broadcast({
        type: 'remove',
        id: target.id,
        tool: 'eraser',
      });
    }
  };

  doneErasing = () => {
    this.isErasing = false;
  };
}

export default EraserTool;
