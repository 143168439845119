import React, { FC, useEffect } from 'react';

import useNavStore, { NavStoreType } from 'app/miscellaneous/stores/NavStore';
import useDimensions from 'hooks/dimensions';
import { Parent, Student } from 'models';

import PortalSessionAlertV2 from './PortalSessionAlertV2';
import { NavV2Learner } from './NavV2Layouts';

// New Nav Bar Component :)
// The different layouts for admin, instructor, and learner can be set simply as a prop
//     and the NavV2 component will take care of the rest
// If it's a learner layout, you must include props for orderedStudents, onSelectStudent,
//     and isClubsEnabled
// It makes use of Zustand so that we don't need to prop drill
// The different layouts are specified in NavV2Layouts.tsx and can be built
//     modularly using components found in NavV2Components.tsx
interface NavV2Props {
  layout?: 'default' | 'admin' | 'instructor' | 'learner';
  orderedStudents?: Student[];
  parent: Parent;
  onSelectStudent?: (studentId: string) => void;
  isClubsEnabled?: boolean | null;
  isGuest?: boolean | null;
  idLookup: any;
  isLoadingIdLookup: boolean;
}

const NavV2: FC<NavV2Props> = ({
  layout = 'default',
  orderedStudents,
  parent,
  onSelectStudent = () => null,
  isClubsEnabled,
  isGuest,
  idLookup,
  isLoadingIdLookup,
}) => {
  const set = useNavStore(state => state.set);
  const { width } = useDimensions();
  // When relevant variables change, update the zustand AppStore values
  useEffect(() => {
    set((state: NavStoreType) => {
      state.navWidth = width;
      state.navIsMobile = width < 640;
      state.navLayout = layout;
      state.navOrderedStudents = orderedStudents;
    });
  }, [set, width, layout, orderedStudents]);

  return (
    <div className="flex flex-col sticky top-0" style={{ zIndex: 101 }}>
      <PortalSessionAlertV2
        parent={parent}
        student={orderedStudents && orderedStudents[0]}
      />
      <div className="flex bg-j-purple-600 select-none text-white items-center h-18">
        <NavV2Learner
          onSelectStudent={onSelectStudent}
          isClubsEnabled={isClubsEnabled}
          isGuest={isGuest}
          idLookup={idLookup}
          isLoadingIdLookup={isLoadingIdLookup}
        />
      </div>
    </div>
  );
};

export default NavV2;
