import React, { useState, FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import {
  startOfDay,
  startOfToday,
  differenceInYears,
  format,
  isBefore,
  isAfter,
} from 'date-fns';
import styled from 'styled-components/macro';
import { Formik } from 'formik';

import { useStatus } from 'hooks/status';

import { Button } from 'core-components';
import { InputField, CheckedField, Message } from 'components/ui';

import { useSubmitGuestAccountRegistrationMutation } from 'generated/graphql';
import { userLogin } from 'services/login';
import GuestParentEmailCard from './GuestParentEmailCard';

const MIN_BIRTHDATE = new Date('01/01/1900');

const SignupFormRow = styled.div.attrs({ className: 'flex flex-col w-full pt-3' })`
  > :last-child {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 920px) {
    flex-direction: row;

    > :last-child {
      margin-left: 1rem;
      margin-top: 0;
    }
  }
  > * {
    flex: 1;
  }
`;

interface GuestUser {
  birthDate: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  guestInvitationId: string;
}

const validationSchema = yup.object({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  username: yup.string().required('Please create a username'),
  // TODO: Update password requirements
  password: yup.string().required('Please create a password'),
  // TODO: Update err messages
  birthDate: yup
    .date()
    .min(MIN_BIRTHDATE, 'Please enter a birth year greater than 1900')
    .max(startOfToday(), 'Birthdate cannot be a future date')
    .required('Please enter your birthdate'),
});

interface GuestSignupFormProps {
  history: RouteComponentProps['history'];
  showRegistration: boolean;
  studentName?: null | string;
  studentEmail?: null | string;
  guestInvitationId: null | string;
  isParentInvite: undefined | null | boolean;
  loadUserData: () => void;
}

const GuestSignupForm: FC<GuestSignupFormProps> = ({
  history,
  showRegistration,
  studentName,
  studentEmail,
  guestInvitationId,
  isParentInvite,
  loadUserData,
}) => {
  const [submitGuestRegistration] = useSubmitGuestAccountRegistrationMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [status, { setError }] = useStatus();

  const [firstName, lastName] = studentName?.split(' ') || [];

  const initialValues = {
    birthDate: '',
    firstName: firstName || '',
    lastName: lastName || '',
    email: studentEmail || '',
    username: '',
    password: '',
    guestInvitationId: guestInvitationId || '',
  };

  const handleSubmit = async (values: GuestUser) => {
    try {
      const response = await submitGuestRegistration({
        variables: { input: values },
      });
      if (response?.data?.submitGuestAccountRegistration?.success) {
        await userLogin(values.email, values.password, 'parent');
        loadUserData();
        history.push('/learner');
      }
    } catch (e) {
      setError(e.message);
      console.log('e', e);
    }
  };

  const { pathname } = history?.location;

  return (
    <>
      {status && <Message status={status.status}>{status.message}</Message>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        showPassword={showPassword}
        validationSchema={validationSchema}
      >
        {props => {
          const { birthDate } = props.values;
          const dateToday = startOfToday();
          const bday = startOfDay(new Date(birthDate));
          const age = dateToday && bday ? differenceInYears(dateToday, bday) : 0;
          const invalidBday =
            !isBefore(bday, dateToday) || !isAfter(bday, MIN_BIRTHDATE);

          // if birthDate isn't saved
          if (showRegistration && birthDate.length < 1) {
            history.push(pathname);
          }
          return (
            <form
              onSubmit={props.handleSubmit}
              className="ui-vertical-spacing text-left"
            >
              {showRegistration ? (
                age < 13 ? (
                  <GuestParentEmailCard
                    guestInvitationId={guestInvitationId}
                    studentName={studentName}
                  />
                ) : (
                  <>
                    <SignupFormRow>
                      <InputField.Formik
                        name="firstName"
                        label="Student's First Name"
                      />
                      <InputField.Formik
                        name="lastName"
                        label="Student's Last Name"
                      />
                    </SignupFormRow>
                    <InputField.Formik name="email" label="Email" />
                    <InputField.Formik name="username" label="Username" />
                    <InputField.Formik
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <CheckedField
                      name="showPassword"
                      label="Show Password"
                      type="checkbox"
                      onChange={() => setShowPassword(!showPassword)}
                    />
                    <div className="text-blue-gray-500">
                      By continuing, you are agreeing to our{' '}
                      <a
                        href="https://junilearning.com/docs/Juni_Learning_Terms_of_Use.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-gray-500"
                      >
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://junilearning.com/docs/Juni_Learning_Privacy_Policy.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-gray-500"
                      >
                        Privacy Policy
                      </a>
                    </div>
                    <Button
                      data-cy="guest-registration-submit"
                      className="w-full"
                      type="submit"
                      hasArrowIcon
                    >
                      Create My Account
                    </Button>
                  </>
                )
              ) : (
                <>
                  <InputField.Formik
                    name="birthDate"
                    label="Birthdate"
                    type="date"
                    min="1900-01-01"
                    max={format(startOfToday(), 'yyyy-MM-dd')}
                  />
                  {isParentInvite && (
                    <div>
                      If you are a parent creating an account on behalf of your
                      student, make sure to enter your (the adult’s) birthdate!
                    </div>
                  )}
                  <Button
                    data-cy="guest-registration-birthdate-submit"
                    disabled={birthDate.length < 1 || invalidBday}
                    onClick={() => history.push(`${pathname}?register`)}
                    className="w-full"
                    hasArrowIcon
                  >
                    Next
                  </Button>
                </>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default GuestSignupForm;
