import React, { ReactNode } from 'react';
import { HelpCenterContent } from 'generated/graphql';
import { RouteComponentProps } from 'react-router-dom';
import { NewCard } from 'core-components';
import { HighlightedName, HighlightedText } from './SearchResults';
import useFuse from './useFuse';
import Breadcrumbs from '../Breadcrumbs';
import { StyledHeader } from '../styles';

interface Props extends RouteComponentProps {
  helpCenterContent: HelpCenterContent;
  baseUrl: string;
  searchHeader: ReactNode;
  searchInput: string;
}

const Search: React.FC<Props> = ({
  helpCenterContent,
  history,
  baseUrl,
  searchHeader,
  searchInput,
}) => {
  const { categories, sections, articles } = helpCenterContent;
  const searchResults = useFuse(articles, searchInput) || [];
  return (
    <div className="font-graphik">
      {searchHeader}
      <div className="space-y-6 p-10">
        <StyledHeader>
          <Breadcrumbs
            breadcrumbs={[
              { to: baseUrl, name: 'Help Desk' },
              { to: `${baseUrl}/search`, name: 'Search' },
            ]}
          />
        </StyledHeader>

        <div className="text-xl font-semi-bold">
          {searchResults.length ? `Search results (${searchResults.length})` : ''}
        </div>
        {searchResults.map(result => {
          const article = result.item;
          const section = sections.find(
            section => section.id === article.section_id,
          );
          const category = categories.find(
            category => category.id === section?.category_id,
          );

          return (
            <div
              key={article.id}
              className="space-y-6 cursor-pointer"
              onClick={() => history.push(`${baseUrl}/articles/${article.id}`)}
            >
              <NewCard>
                <div>
                  <div className="flex flex-col items-start leading-normal">
                    <div className="font-semi-bold no-underline text-j-green-700 bg-j-green-100 rounded-lg p-2">
                      {category?.name.toUpperCase()}
                    </div>
                    <div className="text-lg font-semi-bold mt-4 text-juni-dark-700">
                      <HighlightedName
                        result={result}
                        inputLength={searchInput.length}
                      />
                    </div>
                  </div>
                  <div className="pt-4 font-normal break-words leading-normal text-j-dark-400">
                    <HighlightedText
                      result={result}
                      inputLength={searchInput.length}
                    />
                  </div>
                </div>
              </NewCard>
            </div>
          );
        })}
        {searchResults.length === 0 && (
          <div>
            <div className="flex flex-col items-center justify-center">
              <div className="w-24 h-24 rounded-full bg-j-dark-100" />
              {searchInput.length === 0 && (
                <>
                  <div className="pt-6 font-semi-bold text-j-dark-600 text-lg">
                    Use the input above to search for articles
                  </div>
                </>
              )}
              {searchInput.length !== 0 && (
                <>
                  <div className="pt-6 font-semi-bold text-j-dark-600 text-lg">
                    {`We couldn’t find any matching articles for '${searchInput}'`}
                  </div>
                  <div className="pt-3 font-normal text-juni-dark-300 ">
                    Try refining your search and try again
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
