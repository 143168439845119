import React from 'react';
import Card from '../NewCard';
import Image from './Image';
import Content from './Content';

type Statics = {
  Image: typeof Image;
  Content: typeof Content;
};

export type MediaCardProps = React.ComponentProps<typeof Card>;

/**
 * Card with a media banner on top.
 * Could be an image or eventually video, or a video thumbnail.
 */
const MediaCard: React.FC<MediaCardProps> & Statics = ({
  children,
  ...cardProps
}) => (
  <Card {...cardProps} padding="0">
    {children}
  </Card>
);

MediaCard.Image = Image;
MediaCard.Content = Content;

export default MediaCard;
