import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import { NewButton as Button, Card } from 'core-components';
import Line from 'components/ui/Line';

import instructors from 'images/signup_sessions/instructor_collage.png';
import calendar from 'images/signup_sessions/icons/calendar.svg';
import shield from 'images/signup_sessions/icons/shield.svg';
import thumbsUp from 'images/signup_sessions/icons/thumbsUp.svg';
import book from 'images/signup_sessions/icons/book.svg';

import { LEFT_CARD_STYLES, REFERRAL_CODE_MESSAGE } from 'constants/signup_sessions';
import TextBackButton from 'app/signup_session/components/TextBackButton';
import useSignupContext from 'app/signup_session/hooks/useSignupContext';

import findStudentById from 'app/signup_session/lib/findStudentById';
import findSelectionBySubject from 'app/signup_session/lib/findSelectionBySubject';
import updateBundleDataByStudent from 'app/signup_session/lib/updateBundleDataByStudent';
import makePriceBeforeCoupon from 'app/signup_session/lib/makePriceBeforeCoupon';
import sumWeeklyClasses from 'app/signup_session/lib/sumWeeklyClasses';
import formatNumberAsCurrency from 'utils/formatNumberAsCurrency';
import useNavRouter from 'app/signup_session/hooks/useNavRouter';

import InfoBlock from 'app/signup_session/components/InfoBlock';
import userHasValidReferralCode from 'app/signup_session/lib/userHasValidReferralCode';
import { ROUTE_EVENT } from 'app/signup_session/navigation/types';
import navStates from 'app/signup_session/navigation/states';
import { getContentForCampaign } from 'app/signup_session/campaign_content';
import { CourseBundlesMap } from '../../../lib/makeCourseBundlesMap';

import SingleSelect from '../../../components/SingleSelect';
import CalendarIcon from '../../../components/CalendarIcon';

import { SignupSessionProps, SubjectName } from '../../../types';

const COURSE_FREQUENCY_CONTENT = {
  backButton: 'Change subjects',
  title: 'Choose your class frequency',
  subTitle:
    'You can always change this later! Students typically move through courses faster when they take classes more frequently with us.',
  valueProps: [
    {
      icon: shield,
      copy: 'Expert instructors, with rigorous background checks and training',
    },
    {
      icon: calendar,
      copy: 'Flexible monthly subscription you can adjust or cancel any time',
    },
    {
      icon: book,
      copy: 'Curriculum designed by teachers, and powered by discovery',
    },
    {
      icon: thumbsUp,
      copy: 'Community that builds on creativity and teamwork',
    },
  ],
  summarySection: {
    title: 'PLAN SUMMARY',
    continueText: `You’ll be able to place your child into their courses for each subject after
    checkout.`,
  },
  summaryPromo: 'Free today',
  summaryPromoAfter: 'after your 2-week trial.',
};

interface CourseFrequencyProps extends SignupSessionProps {
  bundleData: CourseBundlesMap;
  campaign?: string;
}

const CourseFrequencyForm: FC<CourseFrequencyProps> = ({ bundleData, history }) => {
  const { signupData, setSignupSession, activeStudentId } = useSignupContext();
  const [continueButtonRef, inView] = useInView({
    threshold: 0.5,
  });
  const { getNextPage } = useNavRouter();

  const student = findStudentById(activeStudentId, signupData)!;

  const CONTENT = getContentForCampaign(
    COURSE_FREQUENCY_CONTENT,
    signupData?.campaign,
  );

  const handleSubmit = () => {
    history.push(
      getNextPage(navStates.signup.courseFrequency, ROUTE_EVENT.SUBMIT, {
        signupData,
      }),
    );
  };

  const makeSubjectsList = () => {
    const lookupSelection = (subjectName: SubjectName) =>
      findSelectionBySubject(activeStudentId, subjectName, signupData);

    const makeQuantitySelectionOptions = (activeValue: number) => [
      {
        label: '4 Sessions/Mo.',
        value: 1,
        icon: <CalendarIcon columns="1" filled={activeValue === 1} />,
        className: 'whitespace-nowrap',
      },
      {
        label: '8 Sessions/Mo.',
        value: 2,
        subLabel: (
          <div>
            <span className="line-through">+250</span>{' '}
            <span className="font-bold">+$200/Mo.</span> 20% off
          </div>
        ),
        icon: <CalendarIcon columns="2" filled={activeValue === 2} />,
        className: 'whitespace-nowrap',
      },
    ];

    return bundleData.subjects?.map(subject => {
      const monthlySelectedClasses =
        lookupSelection(subject.subjectName)?.weeklyClasses ?? 1;

      return (
        <div key={subject.subjectName}>
          <Line />
          <div className="flex items-center space-x-4">
            <h3 className="m-0 font-semibold">{subject.subjectName}</h3>
            <div className="bg-blue-gray-50 text-blue-gray-900 font-normal text-xs rounded-lg px-2 py-1 whitespace-nowrap">
              ages {subject.ageRange[0]}-{subject.ageRange[1]}
            </div>
          </div>
          <p className="text-sm">{subject.description}</p>
          <SingleSelect
            options={makeQuantitySelectionOptions(monthlySelectedClasses)}
            onSelect={val => {
              if (
                student.bundle?.selections[subject.subjectName]?.weeklyClasses ===
                val
              ) {
                return;
              }
              setSignupSession(
                updateBundleDataByStudent(
                  activeStudentId,
                  subject.subjectName,
                  {
                    ...student.bundle?.selections[subject.subjectName],
                    weeklyClasses: val as number,
                  },
                  signupData,
                ),
              );
            }}
            value={monthlySelectedClasses}
          />
        </div>
      );
    });
  };

  const makeValuePropsSection = () =>
    (CONTENT.valueProps as Record<string, string>[]).map(prop => (
      <div className="flex mb-4 items-start space-x-4" key={prop.icon}>
        <img className="w-6" src={prop.icon} alt="" />
        <span className="text-sm text-juni-purple-500">{prop.copy}</span>
      </div>
    ));

  const PlanSummary = (
    <div className="p-4 rounded-lg bg-juni-light-100">
      <h5 className="m-0 font-normal">{CONTENT.summarySection.title}</h5>
      <h6 className="text-2xl font-medium mt-4 mb-2 text-juni-dark-900">
        {sumWeeklyClasses(student.bundle?.selections ?? {}) * 4} Sessions/Month
      </h6>
      <p className="text-sm">
        <span className="font-bold text-juni-green-500">{CONTENT.summaryPromo}</span>
        , and then{' '}
        {formatNumberAsCurrency(
          makePriceBeforeCoupon(student.bundle?.selections ?? {}),
        )}
        /month {CONTENT.summaryPromoAfter}
      </p>
      {userHasValidReferralCode(signupData) && (
        <InfoBlock variant="light">{REFERRAL_CODE_MESSAGE}</InfoBlock>
      )}
      <div ref={continueButtonRef}>
        <Button variant="primary" className="w-full" onClick={handleSubmit}>
          Continue
        </Button>
      </div>
      <p className="text-sm text-blue-gray-600">
        {CONTENT.summarySection.continueText}
      </p>
    </div>
  );

  return (
    <div className="grid grid-cols-8 gap-x-6">
      <Card
        borderWidth="0"
        className={`${LEFT_CARD_STYLES.OUTER} md:rounded-lg -mt-0`}
        noRounding
      >
        <TextBackButton
          onClick={() => {
            const nextPage = getNextPage(
              navStates.signup.courseFrequency,
              ROUTE_EVENT.BACK,
              {
                signupData,
              },
            );
            if (nextPage.startsWith('/')) {
              history.push(nextPage);
            } else {
              window.location.href = nextPage;
            }
          }}
          external
        >
          {CONTENT.backButton}
        </TextBackButton>
        <div className={LEFT_CARD_STYLES.INNER}>
          <h2>{CONTENT.title}</h2>
          <p className="text-sm">{CONTENT.subTitle}</p>
          {makeSubjectsList()}
          {PlanSummary}
        </div>
      </Card>
      <Card
        bannerImgSrc={instructors}
        borderWidth="0"
        className="self-start mt-4 mx-4 lg:mx-0 lg:mt-0 col-span-full lg:col-span-3"
      >
        <div className="flex flex-col w-full">{makeValuePropsSection()}</div>
      </Card>
      {!inView && (
        <div className="mt-4 mx-4 col-span-full lg:hidden">
          <Button variant="primary" className="w-full" onClick={handleSubmit}>
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

export default CourseFrequencyForm;
