import React, { FC } from 'react';
import { Icon, NewButton } from 'core-components';

interface Action {
  text: string;
  disabled?: boolean;
  handler: () => void;
}

interface Props {
  actions: {
    back?: Action;
    exit?: Action;
    next?: Action;
  };
}

const OnboardingFooter: FC<Props> = ({ actions }) => (
  <footer className="flex flex-col-reverse sm:flex-row justify-between border-0 border-t border-solid border-j-gray-300 mt-8 pt-8">
    {actions.back && (
      <NewButton
        variant="secondary"
        disabled={actions.back.disabled}
        onClick={actions.back.handler}
        renderIconLeft={() => <Icon.ArrowLeft width="16" />}
      >
        <span className="pl-2">{actions.back.text}</span>
      </NewButton>
    )}
    <div className="flex-1" />
    {actions.exit && (
      <NewButton
        variant="minimal"
        disabled={actions.exit.disabled}
        onClick={actions.exit.handler}
      >
        {actions.exit.text}
      </NewButton>
    )}
    {actions.next && (
      <NewButton
        className="sm:ml-1"
        disabled={actions.next.disabled}
        onClick={actions.next.handler}
      >
        {actions.next.text}
      </NewButton>
    )}
  </footer>
);

export default OnboardingFooter;
