import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import JuniSpinner from 'components/JuniSpinner';
import ModalV2 from 'components/ModalV2';

import {
  JRSViewRecordingsCreateNew,
  JRSViewRecordingsPreviews,
  JRSViewRecordingsDeleteVideoSplash,
  JRSSelectedVideo,
} from './viewRecordingsSubcomponents';

import '../styles/view_recordings.css';
import '../styles/jide_rec_studio.css';

// MAIN COMPONENT
// Modal that serves two functions:
// 1) Starting point for creating a new recording
// 2) Reviewing and editing the recordings made for the current project
export const JRSViewRecordings = ({
  jideUser,
  recorderUploaderInitialized,
  setMode,
  getProjectVideos,
  selectCreateNew,
  updateVideo,
  deleteVideo,
  viewOnlyMode,
}) => {
  // States
  const [isLoading, setIsLoading] = useState(true);
  const [viewRecordingsInitialized, setViewRecordingsInitialized] = useState(false);
  const [projectVideos, setProjectVideos] = useState([]);
  const [selectedVideoIdx, setSelectedVideoIdx] = useState(null);
  const [createNewSelected, setCreateNewSelected] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [deleteVideoMode, setDeleteVideoMode] = useState(false);

  // Utility Methods
  // This method gets all usermedia objects (ie. videos) for the current project
  // and stores it in a state variable array
  const refreshList = useCallback(async () => {
    if (!recorderUploaderInitialized) return;
    setIsLoading(true);
    const projectVids = await getProjectVideos()
      .then(res => {
        if (!res.success || !res.usermedias) return [];
        return res.usermedias;
      })
      .catch(err => {
        console.error(err);
        return [];
      });
    const sortedProjectVids = projectVids.sort((a, b) =>
      moment.utc(a.createdAt) > moment.utc(b.createdAt) ? -1 : 1,
    );
    setProjectVideos(sortedProjectVids);
    setIsLoading(false);
  }, [getProjectVideos, recorderUploaderInitialized]);

  // Build an object with the IDs pertaining to the currently selected
  // video object for easier API calls
  const getCurrentVideoInfo = () => {
    let userType;
    let studentId;
    let teacherUserId;
    if (jideUser.type === 'student') {
      userType = 'learner';
      studentId = jideUser._id;
    } else {
      userType = 'instructor';
      teacherUserId = jideUser._id;
    }
    const usermediaId = projectVideos[selectedVideoIdx]?._id;
    return { userType, studentId, teacherUserId, usermediaId };
  };

  // Start editing video title by populating input element
  const editTitle = () => {
    setTitleValue(projectVideos[selectedVideoIdx]?.title || '');
    setEditTitleMode(true);
  };

  // Ping Juni Server API to update video title and then refresh list of
  // project videos, and finally exit title editing mode
  const updateTitle = async () => {
    setIsLoading(true);
    await updateVideo({ ...getCurrentVideoInfo(), title: titleValue });
    await refreshList(); // isLoading set to false here
    setEditTitleMode(false);
  };

  // Stop editing title and discard changes
  const cancelTitle = () => {
    setEditTitleMode(false);
  };

  const titleChangeHandler = e => {
    setTitleValue(e.target.value);
  };

  // Executed when button in video deletion confirmation dialogue is pressed
  // Pings API to delete the usermedia object (from DB and S3)
  const deleteVideoForRealz = async () => {
    setIsLoading(true);
    await deleteVideo({ ...getCurrentVideoInfo() });
    setDeleteVideoMode(false);
    await refreshList(); // isLoading set to false here
  };

  // Effects
  // Don't attempt to use methods from recorderUploader until
  // it is initialized. Once it is, refresh list
  const onInitialized = useCallback(() => {
    async function onInitializedAsync() {
      if (!viewRecordingsInitialized) {
        await refreshList();
        setCreateNewSelected(!!selectCreateNew);
        setViewRecordingsInitialized(true);
      }
    }
    onInitializedAsync();
  }, [refreshList, selectCreateNew, viewRecordingsInitialized]);
  useEffect(onInitialized, [onInitialized]);
  // Ensure proper element from the left pane is selected, whether that
  // is the "new recording" button or one of the previous recordings
  useEffect(() => {
    if (!viewRecordingsInitialized) return;
    if (createNewSelected) {
      setSelectedVideoIdx(null);
      setEditTitleMode(false);
      setDeleteVideoMode(false);
    } else if (!projectVideos || !projectVideos.length) {
      setCreateNewSelected(true);
    } else if (selectedVideoIdx === null) {
      setSelectedVideoIdx(0);
    } else if (selectedVideoIdx >= projectVideos.length) {
      setSelectedVideoIdx(projectVideos.length - 1);
    }
  }, [
    projectVideos,
    createNewSelected,
    viewRecordingsInitialized,
    selectedVideoIdx,
  ]);

  // Related to Selected Video View
  // recording timestamp
  const createdAt =
    projectVideos &&
    selectedVideoIdx !== null &&
    projectVideos[selectedVideoIdx]?.createdAt;
  const createdAtString = createdAt
    ? moment.utc(createdAt).local().format('MMMM Do, YYYY h:mma')
    : 'unknown date';

  // video length
  const originalLength =
    projectVideos &&
    selectedVideoIdx !== null &&
    projectVideos[selectedVideoIdx]?.originalLength;
  const recordingLength =
    ((originalLength || originalLength === 0) &&
      moment.utc(originalLength * 1000).format('mm:ss')) ||
    '--:--';

  // determine if its appropriate to show the selected video view
  const canShowSelectedVideo = projectVideos.length && selectedVideoIdx !== null;

  return (
    <div className="jrs-modal jrs-viewrecordings">
      {isLoading ? (
        <div className="jrs-modal-spinner-container">
          <div className="jrs-modal-spinner">
            <JuniSpinner />
          </div>
        </div>
      ) : null}

      <div className="jrs-modal-section jrs-row jrs-modal-viewrecordings-header jrs-modal-header">
        <div className="jrs-modal-heading1">Recording Studio</div>
        <div
          className="jrs-modal-x"
          onClick={() => {
            setMode('close');
          }}
        >
          <div className="jrs-modal-x-inner">
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
      </div>

      <div className="jrs-modal-section jrs-row jrs-modal-recordings">
        {projectVideos?.length ? (
          <JRSViewRecordingsPreviews
            projectVideos={projectVideos}
            selectedVideoIdx={selectedVideoIdx}
            setSelectedVideoIdx={setSelectedVideoIdx}
            setCreateNewSelected={setCreateNewSelected}
            setEditTitleMode={setEditTitleMode}
            setDeleteVideoMode={setDeleteVideoMode}
            createNewSelected={createNewSelected}
            viewOnlyMode={viewOnlyMode}
          />
        ) : null}

        {viewRecordingsInitialized ? (
          <div className="jrs-modal-subsection jrs-column jrs-modal-feature jrs-section">
            {createNewSelected && !viewOnlyMode ? (
              <JRSViewRecordingsCreateNew setMode={setMode} />
            ) : canShowSelectedVideo ? (
              <div className="jrs-modal-subsection jrs-column jrs-modal-selectedvideo">
                {deleteVideoMode ? (
                  <JRSViewRecordingsDeleteVideoSplash
                    setDeleteVideoMode={setDeleteVideoMode}
                    deleteVideoForRealz={deleteVideoForRealz}
                  />
                ) : (
                  <JRSSelectedVideo
                    projectVideos={projectVideos}
                    selectedVideoIdx={selectedVideoIdx}
                    editTitleMode={editTitleMode}
                    titleValue={titleValue}
                    titleChangeHandler={titleChangeHandler}
                    updateTitle={updateTitle}
                    cancelTitle={cancelTitle}
                    editTitle={editTitle}
                    setDeleteVideoMode={setDeleteVideoMode}
                    createdAtString={createdAtString}
                    recordingLength={recordingLength}
                    viewOnlyMode={viewOnlyMode}
                  />
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const JRSViewRecordingsExternalWrapper = ({ projectVideos, closeModal }) => (
  <ModalV2 show closeModal={closeModal}>
    <JRSViewRecordings
      recorderUploaderInitialized
      setMode={mode => {
        if (mode === 'close') closeModal();
      }}
      getProjectVideos={async () => ({ success: true, usermedias: projectVideos })}
      viewOnlyMode
    />
  </ModalV2>
);
