import { NewButton as Button } from 'core-components';
import { useLocation } from 'react-router-dom';
import { useStopImpersonationMutation } from 'generated/graphql';
import { useUserContext } from 'modules/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ImpersonationBanner = () => {
  const location = useLocation();
  const portal = location.pathname.split('/').filter(l => l)[0];

  const { user, isImpersonationMode, setAuthenticatedUser } = useUserContext();
  const [stopImpersonationSession, { loading }] = useStopImpersonationMutation();

  const stopImpersonation = async () => {
    try {
      const result = await stopImpersonationSession();
      const token = result.data?.stopImpersonation.token;
      if (!token) throw new Error('Failed to fetch auth token');

      setAuthenticatedUser(token);

      // if this is not admin tab, close it
      if (portal !== 'admin') window.close();
    } catch (e) {
      console.log(e);
      alert('Failed to end the impersonation session, please try again');
    }
  };

  if (isImpersonationMode) {
    return (
      <div
        className="sticky top-0 flex flex-col h-16 bg-j-yellow-400"
        style={{ zIndex: 1001 }}
      >
        <div className="max-w-screen-xl p-2 mx-auto">
          {portal === 'admin' &&
            `Warning: You are currently impersonating a ${user?.userType} in another
              tab and will need to end that impersonation session in order to restore
              your admin privileges`}
          {portal !== 'admin' &&
            `Warning: YOU ARE IN IMPERSONATION MODE. Be careful before making any
              changes.`}
          <Button size="small" onClick={stopImpersonation} disabled={loading}>
            Stop Impersonation
            {loading && <FontAwesomeIcon className="ml-2" icon={faSpinner} spin />}
          </Button>
        </div>
      </div>
    );
  }
  return null;
};

export default ImpersonationBanner;
