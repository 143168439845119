import selectMembership from './assets/select-membership.svg';
import chooseCourses from './assets/choose-courses.svg';
import startFreeTrial from './assets/start-trial.svg';

export const TRIAL_STEPS = [
  {
    icon: selectMembership,
    label: 'Select your membership plan and class frequency.',
  },
  {
    icon: chooseCourses,
    label: 'Choose your courses, schedule, and instructor learning preferences.',
  },
  {
    icon: startFreeTrial,
    label: 'Try your 1st class free!',
  },
];
