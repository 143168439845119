// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as R from 'ramda';
import { BundleData } from '../types';

const countIncompleteCourseSelections = (bundleData: Partial<BundleData>): number =>
  R.pipe(
    R.filter(selection => !selection.courseName),
    R.keys,
    R.length,
  )(bundleData.selections ?? {});

export default countIncompleteCourseSelections;
