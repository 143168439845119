import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import styles from './index.module.css';

interface Props {
  className?: string;
  label?: React.ReactNode;
  error?: string;
  children: React.ReactNode;
}

function CheckedGroup({ className, label, error, children }: Props) {
  return (
    <fieldset
      className={classNames(
        styles.checkedGroup,
        'ui-vertical-spacing-small',
        className,
      )}
    >
      {label && <legend className="ui-field-label">{label}</legend>}
      {error && <div className="ui-field-error">{error}</div>}
      {children}
    </fieldset>
  );
}

interface FormikProps extends Props {
  name: string;
}

const CheckedGroupFormik: React.FC<FormikProps> = ({ name, ...rest }) => {
  const [, meta] = useField(name);
  return <CheckedGroup {...rest} error={meta.touched ? meta.error : undefined} />;
};

CheckedGroup.Formik = CheckedGroupFormik;

export default CheckedGroup;
