import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { PencilIcon } from 'components/Icons';
import { IPlaygroundProject } from 'app/playground/models';
import ScratchEmbed from '../ScratchEmbed';
import { ICustomScratchProject } from '../models';
import ProjectEditor from './ProjectEditor';

const AddedProjectContainer = styled.div`
  margin: 0;
  padding: 1rem;
  text-align: left;
  & .icon svg {
    fill: #00a5ff;
  }
`;

const EditBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  margin: 0 1rem 1rem 0;

  & .display-name {
    margin: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .icon__toggle {
    background: none;
    padding: 0;
    margin: 0;

    &:hover {
      box-shadow: none;
    }
  }

  & .icon {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition-duration: 0.5s;

    & svg {
      transition-duration: 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
  & .display-name {
    margin: 1rem 0 0 1rem;
    font-size: 1.3rem;
  }
`;

const AddedScratchProject: React.FC<{
  editing: boolean;
  initialDisplayName: string;
  onUpdate: ({
    newProjectName,
    newScratchUrl,
  }: {
    newProjectName: string;
    newScratchUrl: string;
  }) => Promise<void>;
  scratchProject: ICustomScratchProject;
  toggleEditing: (editing: boolean) => void;
  playgroundProject?: IPlaygroundProject;
}> = ({ editing, onUpdate, scratchProject, toggleEditing, playgroundProject }) => {
  const projectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnOutsideClick = (e: any) => {
      if (editing && !projectRef?.current?.contains(e.target)) {
        toggleEditing(false);
      }
    };
    window.addEventListener('blur', () => toggleEditing(false));
    document.addEventListener('click', closeOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeOnOutsideClick);
      window.removeEventListener('blur', () => toggleEditing(false));
    };
  }, [editing, playgroundProject, projectRef, toggleEditing]);

  return (
    <AddedProjectContainer>
      <EditBarContainer>
        <h2 className="display-name">{scratchProject.displayName}</h2>
        <ToggleContainer ref={projectRef}>
          <button
            className="icon__toggle"
            onClick={() => {
              toggleEditing(!editing);
            }}
          >
            <PencilIcon />
          </button>
          {editing && (
            <ProjectEditor
              scratchProject={scratchProject}
              onUpdate={onUpdate}
              toggleEditing={toggleEditing}
            />
          )}
        </ToggleContainer>
      </EditBarContainer>
      <ScratchEmbed
        displayName={scratchProject.displayName}
        projectLink={scratchProject.scratchUrl}
      />
    </AddedProjectContainer>
  );
};

export default AddedScratchProject;
