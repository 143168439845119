import {
  CheckoutFlowPaths,
  SubjectSignupData,
  SubjectName,
  BillingPlan,
  OnboardingFlowPath,
  DiscountData,
  CourseBundleName,
  CheckoutFlowLabels,
} from 'app/signup_session/types';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';

export const BASE_PATHS = {
  checkout: 'signup',
  onboarding: 'onboarding',
};

export const CHECKOUT_PATHS = {
  courseFrequency: 'course-frequency',
  parentInfo: 'parent-info',
  studentInfo: 'student-info',
  checkout: 'payment',
  starterCourse: 'starter-course',
};

export const CHECKOUT_FLOW_STEPS: Array<{
  path: CheckoutFlowPaths;
  label: CheckoutFlowLabels;
}> = [
  { path: 'parent-info', label: 'Parent Info' },
  { path: 'starter-course', label: 'Starter Course' },
  { path: 'student-info', label: 'Student Info' },
  { path: 'course-frequency', label: 'Plan Selection' },
  { path: 'payment', label: 'Checkout' },
];

export const ONBOARDING_PATHS: {
  [key: string]: OnboardingFlowPath;
} = {
  createAccount: 'create-account',
  schedulingPreferences: 'scheduling-preferences',
  coursePlacement: 'course-placement',
  studentInfo: 'student-info',
  confirmation: 'confirmation',
};

export const ONBOARDING_FLOW_STEPS: Array<{
  path: OnboardingFlowPath;
  label:
    | 'Create your account'
    | 'Class schedule preferences'
    | 'Course Placement'
    | 'Help us to get to know'
    | 'Confirmation';
}> = [
  { path: 'create-account', label: 'Create your account' },
  { path: 'scheduling-preferences', label: 'Class schedule preferences' },
  { path: 'course-placement', label: 'Course Placement' },
  { path: 'student-info', label: 'Help us to get to know' }, // the student's name will be appended to the end
  { path: 'confirmation', label: 'Confirmation' },
];

export const REFERRAL_COOKIE_NAME = 'juni_referral_code';
export const REFERRER_COOKIE_NAME = 'juni_referrer_name';
export const SIGNUP_SESSION_COOKIE_NAME = 'juni_dcf_session_id';
export const SIGNUP_SESSION_COOKIE_EXPIRATION = 60 * 60 * 24; // 1 day

export const COMPUTER_SCIENCE = SUBJECT_DISPLAY_NAMES[SUBJECTS.COMPUTER_SCIENCE];
export const MATH = SUBJECT_DISPLAY_NAMES[SUBJECTS.MATHEMATICS];
export const ENGLISH = SUBJECT_DISPLAY_NAMES[SUBJECTS.ENGLISH];
export const INVESTING = SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING];
export const SCIENCE = SUBJECT_DISPLAY_NAMES[SUBJECTS.SCIENCE];

export const BUILDER = 'builder';
export const EXPLORER = 'explorer';
export const CREATOR = 'creator';
export const INNOVATOR = 'innovator';

export const BUNDLE_NAMES = [BUILDER, EXPLORER, CREATOR, INNOVATOR] as const;

export const AVAILABLE_SUBJECTS = [COMPUTER_SCIENCE, MATH, ENGLISH, INVESTING];
export const SIGNUP_DATA_BY_SUBJECT: {
  [key in SubjectName]: SubjectSignupData;
} = {
  [COMPUTER_SCIENCE]: {
    subjectName: COMPUTER_SCIENCE,
    description:
      'Build solid coding foundations with creative games and projects. Our full curriculum covers beginning to advanced programming.',
    ageRange: [8, 18],
  },
  [MATH]: {
    subjectName: MATH,
    description:
      'Master key math concepts with real-world applications and meaningful practice. Our curriculum starts from elementary school, through high school AP Calculus.',
    ageRange: [7, 18],
  },
  [ENGLISH]: {
    subjectName: ENGLISH,
    description:
      'Develop strong creative and analytical skills in both reading and writing. Our courses span from elementary to high school levels, including project-based electives.',
    ageRange: [7, 18],
  },
  [INVESTING]: {
    subjectName: INVESTING,
    description:
      'Learn the foundational skills to build a business and achieve financial literacy. Our fun, project-based courses explore entrepreneurship, investing, and personal finance.',
    ageRange: [9, 18],
  },
  // TODO clean up and simplify, pretty sure none of this data is really used anymore except for subjectName
  [SCIENCE]: {
    subjectName: SCIENCE,
    description: '',
    ageRange: [7, 18],
  },
};

export const CALENDLY_LINKS = {
  salesTeam: `https://calendly.com/juni-admissions/orientation?utm_source=checkout`,
  PETeam: `https://calendly.com/juniaccounts/juni-learning-onboarding-and-welcome-call?utm_source=gtmpse&utm_medium=checkout&utm_campaign=freetrials`,
};

export const PRICE_LOOKUP: {
  [weeklyClasses: number]: number;
} = {
  0: 29,
  0.5: 140,
  1: 250,
  2: 450,
  3: 650,
  4: 900,
  5: 1125,
  6: 1350,
};

export const TWO_FREE_SESSIONS = '2fs';
export const ONE_FREE_SESSION = '1fs';
export const TWO_WEEK_TRIAL = '2wt';
export const ON_DEMAND = 'ond';

// keep in sync with BE course formats in server/app/courses/models/course.ts
export const COURSE_FORMAT = {
  private: 'private',
  onDemand: 'on_demand',
  group: 'group',
};

// make sure to keep in sync with the server regex in SignupSession model
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const COUPON_DISALLOW_LIST = [
  'REFERRAL_FIRST_MONTH',
  'REFERRAL_100',
  'SIBLING',
  'SIBLING_DISCOUNT',
  'SIBLINGDISCOUNT',
  'Sl7pjGGi',
];

export const LEFT_CARD_STYLES = {
  OUTER: `col-span-8 lg:col-span-5 mt-4 lg:mt-0`,
  INNER: `container w-full lg:w-4/5 mx-auto`,
};

// TODO (mhang): cleanup unused constants
export const planIds = [
  '6-month-1x-private',
  'plan_FtEAcqqb9V1Rw3',
  'price_1IUELvIsJ8B817wynypiNYqk',
  '6-month-2x-private-450',
  'price_1GuhonIsJ8B817wyEnDpMK7F',
  'price_1IUJIBIsJ8B817wyXIg0wrp3',
  'plan_FrzGkhBq5rKp0D',
  'plan_Gb1ZuNLqwCTHMe',
  'price_1IUGvOIsJ8B817wynZYUkSeU',
  'plan_HC79YC4gnamYqn',
  'price_1HWr6WIsJ8B817wykkqjV2Au',
  'price_1IZ4nbIsJ8B817wywEprcuFl',
  'price_1IUHkvIsJ8B817wyxMwne3jh',
  'price_1IUG1YIsJ8B817wySvlVJltv',
  'price_1IUFa4IsJ8B817wyF5zW43Ma',
  'price_1IZ4rDIsJ8B817wycOYOm1ox',
  'price_1IZ4sgIsJ8B817wyoDRDigV3',
  'price_1IZ4uGIsJ8B817wy62Vc0fR7',
  'price_1IZ4wZIsJ8B817wyjKn7CEbB',
  'price_1IZ4y3IsJ8B817wyvHP7tOGe',
  'price_1IZ4zNIsJ8B817wyXosiCImE',
  'price_1IZ50vIsJ8B817wyZ0zuo6Qm',
  'price_1Itau8IsJ8B817wy1xV9zACw',
  'price_1ItayFIsJ8B817wysftoRoNM',
  'price_1ItayeIsJ8B817wygCWz2ymu',
  'price_1IIe4NIsJ8B817wyeOG4VKVQ',
  'price_1IUGpTIsJ8B817wy2Uyw5git',
  'price_1IUGmiIsJ8B817wy6bNbLp6x',
  'price_1J60yzIsJ8B817wyUHtlsEPb',
  'price_1JKTHRIsJ8B817wy3O5pIyhR',
  'price_1JKTHRIsJ8B817wyshvwrLVC',
  'price_1JKTHRIsJ8B817wygZzmWI7K',
  'price_1JKTHRIsJ8B817wyp1Otxruq',
  'price_1JKTHRIsJ8B817wyvH5e3SGW',
  'price_1JKTHRIsJ8B817wyoxYsbtzv',
  'price_1JKTHRIsJ8B817wyaRRaOzaR',
  'price_1JKTHRIsJ8B817wyQdZ8O9Vy',
  'price_1JKTHQIsJ8B817wyZl3Cw1y9',
  'price_1JKTHQIsJ8B817wyppTjrnO7',
  'price_1JKTHQIsJ8B817wywRW8jIEb',
  'price_1JKTHQIsJ8B817wymEfzh5QE',
  'price_1JKTHQIsJ8B817wyt0MzTigu',
  'price_1JKTHQIsJ8B817wyigEz2LZG',
  'price_1JKTHQIsJ8B817wyKigwwF1B',
  'price_1JKTHQIsJ8B817wyuD7okgNJ',
  'price_1JKTHQIsJ8B817wycX0KfgHb',
  'price_1JKTHQIsJ8B817wyeZ0gjkcX',
  'price_1JKTHQIsJ8B817wytgC0qYH5',
  'price_1JKTHQIsJ8B817wyKrXTtLhs',
  'price_1JKTHQIsJ8B817wyTRbI8uwS',
  'price_1JKTHQIsJ8B817wyrjDP2kVi',
  'price_1JKTHQIsJ8B817wyLnwzNLlJ',
  'price_1JKTHQIsJ8B817wy21JfDka5',
  'price_1JKTHQIsJ8B817wyGXoIjOIo',
  'price_1JKTHQIsJ8B817wySFXyMXW9',
  'price_1JKTHQIsJ8B817wy1yuJxZSb',
  'price_1JKTHQIsJ8B817wyxJsCbVDZ',
  'price_1JKTHPIsJ8B817wyzNrv8tSY',
  'price_1JKTHPIsJ8B817wyAYurmhcA',
  'price_1JKTHPIsJ8B817wynEF1QUR9',
  'price_1JKTHPIsJ8B817wyPvoTexkG',
  'price_1JKTHPIsJ8B817wyvouueV8w',
  'price_1JKTHPIsJ8B817wyzrQi3D5n',
  'price_1JKTHPIsJ8B817wycBbpDvbf',
  'price_1JKTHPIsJ8B817wyBZF9N1Q0',
  'price_1JKTHPIsJ8B817wyN1QP6iON',
  'price_1JKTHPIsJ8B817wyf9jEyrlO',
  'price_1JKTHPIsJ8B817wylC3TXtYR',
  'price_1JKTHPIsJ8B817wyQARWZkmu',
  'price_1JKTHPIsJ8B817wyKKv4G3Qo',
  'price_1JKTHPIsJ8B817wynMjoMmkS',
  'price_1JKTHPIsJ8B817wyVzt32ro0',
  'price_1JKTHPIsJ8B817wypJlgd9wJ',
  'price_1JKTHPIsJ8B817wyRx523jSA',
  'price_1JKTHPIsJ8B817wytqOidScS',
  'price_1JKTHPIsJ8B817wyRcwHcPey',
  'price_1JKTHPIsJ8B817wyGWyepdw7',
  'price_1JJkZwIsJ8B817wyuPB0nMXc',
  // added manually (maybe to be replaced with script output)
  'price_1JDs3PIsJ8B817wy5sck7qbA',
  'price_1ItfqUIsJ8B817wykwGW3E3P',
  'price_1JDrtgIsJ8B817wy1ovJnYg6',
  'price_1Itfr5IsJ8B817wycPcGb8ft',
  'price_1ItfrlIsJ8B817wyVMceLzAh',
  'price_1ItfozIsJ8B817wyzhbI4HJX',
  'price_1ItfpfIsJ8B817wyvG8GBJ9X',
] as const;

const EXPLORER_PLANS: BillingPlan<typeof EXPLORER>[] = [
  {
    planId: 'price_1Itau8IsJ8B817wy1xV9zACw',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 2,
    },
  },
  {
    planId: 'price_1ItayFIsJ8B817wysftoRoNM',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [MATH]: 2,
    },
  },
  {
    planId: 'price_1ItayeIsJ8B817wygCWz2ymu',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [ENGLISH]: 2,
    },
  },
  {
    planId: '6-month-1x-private',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
    },
  },
  {
    planId: 'plan_FtEAcqqb9V1Rw3',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [MATH]: 4,
    },
  },
  {
    planId: 'price_1IUELvIsJ8B817wynypiNYqk',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [ENGLISH]: 4,
    },
  },
  {
    planId: '6-month-2x-private-450',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
    },
  },
  {
    planId: 'price_1GuhonIsJ8B817wyEnDpMK7F',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [MATH]: 8,
    },
  },
  {
    planId: 'price_1IUJIBIsJ8B817wyXIg0wrp3',
    userFacingBundleName: EXPLORER,
    monthlyClassCount: {
      [ENGLISH]: 8,
    },
  },
];

const BUILDER_PLANS: BillingPlan<typeof BUILDER>[] = [
  {
    planId: 'plan_FrzGkhBq5rKp0D',
    userFacingBundleName: BUILDER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [MATH]: 4,
    },
  },
  {
    planId: 'plan_Gb1ZuNLqwCTHMe',
    userFacingBundleName: BUILDER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [MATH]: 8,
    },
  },
  {
    planId: 'price_1IUGvOIsJ8B817wynZYUkSeU',
    userFacingBundleName: BUILDER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [MATH]: 4,
    },
  },
  {
    planId: 'plan_HC79YC4gnamYqn',
    userFacingBundleName: BUILDER,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [MATH]: 8,
    },
  },
];

const CREATOR_PLANS: BillingPlan<typeof CREATOR>[] = [
  {
    planId: 'price_1HWr6WIsJ8B817wykkqjV2Au',
    userFacingBundleName: CREATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IZ4nbIsJ8B817wywEprcuFl',
    userFacingBundleName: CREATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1IUHkvIsJ8B817wyxMwne3jh',
    userFacingBundleName: CREATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IUG1YIsJ8B817wySvlVJltv',
    userFacingBundleName: CREATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [ENGLISH]: 8,
    },
  },
];

const INNOVATOR_PLANS: BillingPlan<typeof INNOVATOR>[] = [
  {
    planId: 'price_1IUFa4IsJ8B817wyF5zW43Ma',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IZ4rDIsJ8B817wycOYOm1ox',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IZ4sgIsJ8B817wyoDRDigV3',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1IZ4uGIsJ8B817wy62Vc0fR7',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1IZ4wZIsJ8B817wyjKn7CEbB',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IZ4y3IsJ8B817wyvHP7tOGe',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1IZ4zNIsJ8B817wyXosiCImE',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IZ50vIsJ8B817wyZ0zuo6Qm',
    userFacingBundleName: INNOVATOR,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
];

// for plans introduced after we deprecated initial bundle names
const NON_LABELED_PLANS: BillingPlan[] = [
  {
    planId: 'price_1IIe4NIsJ8B817wyeOG4VKVQ',
    monthlyClassCount: {
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IUGpTIsJ8B817wy2Uyw5git',
    monthlyClassCount: {
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1IUGmiIsJ8B817wy6bNbLp6x',
    monthlyClassCount: {
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1J60yzIsJ8B817wyUHtlsEPb',
    monthlyClassCount: {
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
];

const NEW_PLAN = 'temporary placeholder';

const BUSINESS_PLANS = [
  {
    planId: 'price_1JKTHRIsJ8B817wy3O5pIyhR',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 8,
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wyshvwrLVC',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 4,
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wygZzmWI7K',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 4,
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wyp1Otxruq',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 4, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wyvH5e3SGW',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 8,
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wyoxYsbtzv',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 4, [MATH]: 8 },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wyaRRaOzaR',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 8, [MATH]: 8 },
  },
  {
    planId: 'price_1JKTHRIsJ8B817wyQdZ8O9Vy',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 8, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyZl3Cw1y9',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 4, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyppTjrnO7',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 4,
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wywRW8jIEb',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wymEfzh5QE',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 8, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyt0MzTigu',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 8,
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyigEz2LZG',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 4,
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyKigwwF1B',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 4, [MATH]: 4 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyuD7okgNJ',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 8,
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wycX0KfgHb',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 8, [MATH]: 4 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyeZ0gjkcX',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 4,
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wytgC0qYH5',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 4,
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyKrXTtLhs',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 8,
      [MATH]: 8,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyTRbI8uwS',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 8,
      [MATH]: 8,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyrjDP2kVi',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 4, [MATH]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyLnwzNLlJ',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 8, [MATH]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wy21JfDka5',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 4, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyGXoIjOIo',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 8, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wySFXyMXW9',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 8, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wy1yuJxZSb',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 8 },
  },
  {
    planId: 'price_1JKTHQIsJ8B817wyxJsCbVDZ',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 8, [INVESTING]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyzNrv8tSY',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 4, [MATH]: 4, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyAYurmhcA',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [MATH]: 4, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wynEF1QUR9',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 4, [MATH]: 4, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyPvoTexkG',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [MATH]: 4, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyvouueV8w',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [MATH]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyzrQi3D5n',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [MATH]: 8, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wycBbpDvbf',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 4, [MATH]: 8, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyBZF9N1Q0',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 4, [MATH]: 8, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyN1QP6iON',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [MATH]: 8, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyf9jEyrlO',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 4, [MATH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wylC3TXtYR',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [ENGLISH]: 4 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyQARWZkmu',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [MATH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyKKv4G3Qo',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 2 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wynMjoMmkS',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 4, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyVzt32ro0',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [INVESTING]: 8, [ENGLISH]: 8 },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wypJlgd9wJ',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 8,
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyRx523jSA',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 4,
      [MATH]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wytqOidScS',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 8,
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyRcwHcPey',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 4,
      [MATH]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1JKTHPIsJ8B817wyGWyepdw7',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [COMPUTER_SCIENCE]: 4, [INVESTING]: 8, [MATH]: 4 },
  },
  {
    planId: 'price_1JJkZwIsJ8B817wyuPB0nMXc',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: { [ENGLISH]: 4 },
  },

  // added manually (maybe to be replaced with script output)
  {
    planId: 'price_1JDs3PIsJ8B817wy5sck7qbA',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 4,
      [MATH]: 4,
    },
  },
  {
    planId: 'price_1ItfqUIsJ8B817wykwGW3E3P',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 4,
      [INVESTING]: 4,
    },
  },
  {
    planId: 'price_1JDrtgIsJ8B817wy1ovJnYg6',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [COMPUTER_SCIENCE]: 8,
      [INVESTING]: 4,
      [ENGLISH]: 8,
    },
  },
  {
    planId: 'price_1Itfr5IsJ8B817wycPcGb8ft',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [INVESTING]: 4,
      [MATH]: 4,
    },
  },
  {
    planId: 'price_1ItfrlIsJ8B817wyVMceLzAh',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [INVESTING]: 4,
      [ENGLISH]: 4,
    },
  },
  {
    planId: 'price_1ItfozIsJ8B817wyzhbI4HJX',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [INVESTING]: 4,
    },
  },
  {
    planId: 'price_1ItfpfIsJ8B817wyvG8GBJ9X',
    userFacingBundleName: NEW_PLAN,
    monthlyClassCount: {
      [INVESTING]: 8,
    },
  },
];

export const BUNDLE_PLANS = [
  ...EXPLORER_PLANS,
  ...BUILDER_PLANS,
  ...CREATOR_PLANS,
  ...INNOVATOR_PLANS,
  ...NON_LABELED_PLANS,
  ...BUSINESS_PLANS,
] as const;

export const BUNDLE_COMBINATIONS: Array<{
  bundleName: CourseBundleName;
  weeklyClassTotal?: number;
  weeklyClassMin?: number;
}> = [
  {
    bundleName: 'explorer',
    weeklyClassTotal: 0.5,
  },
  {
    bundleName: 'builder',
    weeklyClassTotal: 1,
  },
  {
    bundleName: 'innovator',
    weeklyClassMin: 2,
  },
];

export const REFERRAL_CODE_MESSAGE =
  'Your referral discount will be applied at checkout.';

export const REFERRAL_DISCOUNT_DATA: DiscountData = {
  discount: 5000,
  discountType: 'amount_off',
  isReferral: true,
  isSelected: true,
  duration: 'once',
};
