import { FC, useEffect, useState } from 'react';
import { InstructorUnavailablePreference } from 'generated/graphql';
import { MutationResult } from '@apollo/client';
import { format } from 'date-fns-tz';

import { Icon, NewButton as Button, NewModalWindow } from 'core-components';
import {
  ModalButtonsFooter,
  ModalTextFooter,
} from 'core-components/NewModalWindow/NewModalWindow';
import { ACUITY_TYPE_TO_TEXT } from 'constants/acuity';
import { LearnerClassSchedulerBaseProps } from '../../types';
import { AvailabilityPreferences } from '../types';
import {
  InstructorAvailabilityStep,
  CustomTimePickerStep,
  InstructorUnavailablePreferenceStep,
  AllSetStep,
} from '.';

const CUSTOM_TIME_SELECTIONS = 3;

interface RecurringClassReschedulerModalProps
  extends LearnerClassSchedulerBaseProps {
  onSubmit: (
    acuityAppointmentId: number,
    proposedCustomDatetimes: Date[],
    isCustomTimeSelection: boolean,
    instructorUnavailablePreference: InstructorUnavailablePreference,
    proposedDatetime?: Date,
  ) => Promise<void>;
  modalDescription: string;
  confirmationMessage: string;
  results: MutationResult;
  appointmentDatetime: string;
  acuityAppointmentId: number;
  refreshParentState?: () => void;
}

enum ReschedulingWidgetStep {
  INSTRUCTOR_AVAILABILITY = 'instructor_availability',
  CUSTOM_TIME_PICKER = 'custom_time_picker',
  SELECT_INSTRUCTOR_UNAVAILABLE_PREFERENCE = 'select_instructor_unavailable_preference',
  ALL_SET = 'all_set',
}

const RecurringClassReschedulerModal: FC<RecurringClassReschedulerModalProps> = ({
  acuityAppointmentId,
  appointmentTypeID,
  appointmentDatetime,
  timezone,
  instructorAcuityId,
  studentFirstName,
  parent,
  onSubmit,
  // onFinish,
  refreshParentState,
  isOpen,
  closeModal,
  results,
}) => {
  const [formStep, setFormStep] = useState<ReschedulingWidgetStep>(
    ReschedulingWidgetStep.INSTRUCTOR_AVAILABILITY,
  );
  const [selectedDatetime, setSelectedDatetime] = useState<Date | undefined>(
    undefined,
  );
  const [customAvailabilities, setCustomAvailabilities] = useState<
    AvailabilityPreferences
  >({});
  const [isCustomTimeSelection, setIsCustomTimeSelection] = useState<boolean>(false);
  const [
    instructorUnavailablePreference,
    setInstructorUnavailablePreference,
  ] = useState<InstructorUnavailablePreference | undefined>(undefined);

  const isFormValid =
    instructorUnavailablePreference &&
    ((!isCustomTimeSelection && !!selectedDatetime) ||
      (isCustomTimeSelection &&
        Object.values(customAvailabilities).filter(a => a.startDate).length ===
          CUSTOM_TIME_SELECTIONS));

  const handleSubmit = async () => {
    if (!isFormValid || results.loading) {
      return;
    }
    try {
      await onSubmit(
        acuityAppointmentId,
        Object.values(customAvailabilities).map(c => c.startDate!),
        isCustomTimeSelection,
        instructorUnavailablePreference!,
        selectedDatetime,
      );
    } catch (e) {
      console.log(e);
    } finally {
      if (refreshParentState) refreshParentState();
    }
  };

  const InstructorAvailabilityFooter = (
    <ModalTextFooter
      title="Your Timezone"
      description={`${timezone} (${format(new Date(), 'zz', {
        timeZone: timezone,
      })})`}
    />
  );

  const CustomTimePickerFooter = (
    <ModalButtonsFooter
      primary={
        <Button
          onClick={() =>
            setFormStep(
              ReschedulingWidgetStep.SELECT_INSTRUCTOR_UNAVAILABLE_PREFERENCE,
            )
          }
          disabled={
            Object.values(customAvailabilities).filter(a => a.startDate).length !==
            CUSTOM_TIME_SELECTIONS
          }
        >
          <div className="font-medium">Next</div>
        </Button>
      }
      secondary={
        <Button
          variant="secondary"
          onClick={() => {
            setIsCustomTimeSelection(false);
            setFormStep(ReschedulingWidgetStep.INSTRUCTOR_AVAILABILITY);
          }}
        >
          <Icon.ChevronLeft />
          <div className="font-medium">Back</div>
        </Button>
      }
    />
  );

  const SelectInstructorUnavailablePreferenceFooter = (
    <ModalButtonsFooter
      primary={
        <Button onClick={handleSubmit} disabled={results.loading || !isFormValid}>
          <div className="font-medium">Confirm</div>
        </Button>
      }
      secondary={
        <Button
          variant="secondary"
          onClick={() => {
            if (!isCustomTimeSelection) setSelectedDatetime(undefined);
            setFormStep(
              isCustomTimeSelection
                ? ReschedulingWidgetStep.CUSTOM_TIME_PICKER
                : ReschedulingWidgetStep.INSTRUCTOR_AVAILABILITY,
            );
          }}
        >
          <Icon.ChevronLeft />
          <div className="font-medium">Back</div>
        </Button>
      }
    />
  );

  const AllSetFooter = (
    <ModalButtonsFooter
      primary={
        <Button onClick={closeModal}>
          <div className="font-medium">Close</div>
        </Button>
      }
    />
  );

  // a selection made on instructorAvailability step will move parent to next view automatically
  useEffect(() => {
    if (
      formStep === ReschedulingWidgetStep.INSTRUCTOR_AVAILABILITY &&
      selectedDatetime
    ) {
      setFormStep(ReschedulingWidgetStep.SELECT_INSTRUCTOR_UNAVAILABLE_PREFERENCE);
    }
    if (
      formStep === ReschedulingWidgetStep.INSTRUCTOR_AVAILABILITY &&
      isCustomTimeSelection
    ) {
      setFormStep(ReschedulingWidgetStep.CUSTOM_TIME_PICKER);
    }
  }, [formStep, selectedDatetime, isCustomTimeSelection]);

  // successful submission should move parent to final 'All Set!' view
  useEffect(() => {
    if (
      formStep === ReschedulingWidgetStep.SELECT_INSTRUCTOR_UNAVAILABLE_PREFERENCE &&
      results.data?.CreateParentRecurringRescheduleRequest.success
    ) {
      setFormStep(ReschedulingWidgetStep.ALL_SET);
    }
  }, [formStep, results]);

  const formStepMetadata = {
    [ReschedulingWidgetStep.INSTRUCTOR_AVAILABILITY]: {
      title: `Request a new recurring schedule for ${studentFirstName}'s ${ACUITY_TYPE_TO_TEXT[appointmentTypeID]}s`,
      body: (
        <InstructorAvailabilityStep
          instructorAcuityId={instructorAcuityId}
          appointmentTypeID={appointmentTypeID}
          timezone={timezone}
          selectedDatetime={selectedDatetime}
          setSelectedDatetime={setSelectedDatetime}
          isCustomTimeSelection={isCustomTimeSelection}
          setIsCustomTimeSelection={setIsCustomTimeSelection}
        />
      ),
      footer: InstructorAvailabilityFooter,
    },
    [ReschedulingWidgetStep.CUSTOM_TIME_PICKER]: {
      title: `Request a new recurring schedule for ${studentFirstName}'s ${ACUITY_TYPE_TO_TEXT[appointmentTypeID]}s`,
      body: (
        <CustomTimePickerStep
          timezone={timezone}
          customAvailabilities={customAvailabilities}
          setCustomAvailabilities={setCustomAvailabilities}
        />
      ),
      footer: CustomTimePickerFooter,
    },
    [ReschedulingWidgetStep.SELECT_INSTRUCTOR_UNAVAILABLE_PREFERENCE]: {
      title: 'Confirm Request Details',
      body: (
        <InstructorUnavailablePreferenceStep
          studentFirstName={studentFirstName}
          appointmentDatetime={appointmentDatetime}
          appointmentTypeID={appointmentTypeID}
          selectedDatetime={selectedDatetime}
          isCustomTimeSelection={isCustomTimeSelection}
          customAvailabilities={customAvailabilities}
          results={results}
          timezone={timezone}
          instructorUnavailablePreference={instructorUnavailablePreference}
          setInstructorUnavailablePreference={setInstructorUnavailablePreference}
        />
      ),
      footer: SelectInstructorUnavailablePreferenceFooter,
    },
    [ReschedulingWidgetStep.ALL_SET]: {
      title: "You're all set!",
      body: <AllSetStep parentEmail={parent.email} />,
      footer: AllSetFooter,
    },
  };

  return (
    <NewModalWindow
      isOpen={isOpen}
      closeModal={closeModal}
      title={formStepMetadata[formStep].title}
      renderFooter={() => formStepMetadata[formStep].footer}
    >
      <div className="flex flex-col w-full">{formStepMetadata[formStep].body}</div>
    </NewModalWindow>
  );
};

export default RecurringClassReschedulerModal;
