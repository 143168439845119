import React from 'react';
import styled from 'styled-components';

import RichTextViewer from 'components/RichTextViewer';

import { useLoadPromptByIdQuery } from 'generated/graphql';
import { ErrorableLoading } from 'components/ui';
import { jDark } from 'theme/colors';

// note cannot use ActivityInstructions since the RichTextViewer Editor is needed for rendering (even though its readonly)
const StyledInstructions = styled.div`
  ul {
    color: ${jDark[400]};
  }
`;

interface WritingInstructionsProps {
  writingPromptId: string;
}

const WritingInstructions: React.FC<WritingInstructionsProps> = ({
  writingPromptId,
}) => {
  const { error, data } = useLoadPromptByIdQuery({
    variables: { input: writingPromptId },
  });

  if (!data) {
    return <ErrorableLoading error={error} />;
  }

  const richTextState = data.promptById?.content;

  return (
    <div className="px-6 py-4 font-graphik text-j-dark-600 overflow-scroll-y">
      <div className="text-xl leading-8 font-medium">Instructions</div>
      {/* TODO - replace with <Divider /> after #1725 */}
      <hr className="border-0 bg-j-purple-100 h-px mt-4 -mx-6" />
      <StyledInstructions className="text-base">
        {richTextState ? (
          <RichTextViewer richTextState={richTextState} />
        ) : (
          <div className="text-j-pink-700">
            Oops! Sorry, something went wrong while trying to load this Writing
            Prompt.
          </div>
        )}
      </StyledInstructions>
    </div>
  );
};

export default WritingInstructions;
