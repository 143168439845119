class ShapeTool {
  constructor(board, shape) {
    this.board = board;
    this.activeShapeId = '';
    this.lastSendTime = 0;
    this.activeShapeElem = null;
    this.shapeOptions = ['line', 'arrow', 'rect', 'ellipse'];
    this.toolNameOptions = this.shapeOptions.map(shape => `shape_${shape}`);
    this.name = `shape_${shape}`;
    this.shape = this.shapeOptions.includes(shape) ? shape : this.shapeOptions[0];
    this.eventHandlers = {
      mousedown: this.beginDrawingShape,
      mousemove: this.drawShape,
      mouseup: this.finishDrawingShape,
      mouseleave: this.finishDrawingShape,
      touchstart: this.beginDrawingShape,
      touchmove: this.drawShape,
      touchleave: this.finishDrawingShape,
      touchend: this.finishDrawingShape,
      touchcancel: this.finishDrawingShape,
    };
    this.firstTouchCoordinates = null;
  }

  getShapeAttrs = (x, y) => {
    if (this.firstTouchCoordinates.x == null) {
      return;
    }
    const dx = x - this.firstTouchCoordinates.x;
    const dy = y - this.firstTouchCoordinates.y;
    if (['line', 'arrow'].includes(this.shape)) {
      let x2 = x;
      let y2 = y;
      if (this.board.shiftKeyIsPressed) {
        const distX = Math.abs(dx);
        const distY = Math.abs(dy);
        if (distY !== 0 && (distX / distY >= 2 || distX / distY <= 0.5)) {
          if (Math.abs(dx) > Math.abs(dy)) {
            x2 = x;
            y2 = this.firstTouchCoordinates.y;
          } else {
            x2 = this.firstTouchCoordinates.x;
            y2 = y;
          }
        } else {
          // find the endpoints (x2, y2) of the vector projection of the user's line (with the firstTouchCoordinates as the origin)
          // onto the nearest line intersecting the origin at an angle that is a multiple of 45 degrees, but not 90 degrees
          // ie. 45, 135, 225, & 315 degree angles
          const sameDir = dx * dy > 0;
          const u = Math.sqrt(2) / 2;
          const mag = Math.sqrt(distX * distX + distY * distY);
          const dxIsPositive = dx > 0;
          const visualQuadrantIndex = sameDir
            ? dxIsPositive
              ? 3
              : 1
            : dxIsPositive
            ? 0
            : 2;
          const radiansToQuadrant = visualQuadrantIndex * 0.5 * Math.PI;
          const atan = sameDir ? Math.atan(distX / distY) : Math.atan(distY / distX);
          const radians = atan + radiansToQuadrant;
          const theta =
            -1 * (radians - Math.atan(1) * (visualQuadrantIndex * 2 + 1));
          const projScalar = mag * Math.cos(theta);
          const dx2 = dxIsPositive ? u * projScalar : -1 * u * projScalar;
          const dy2 = sameDir ? dx2 : -1 * dx2;
          x2 = this.firstTouchCoordinates.x + dx2;
          y2 = this.firstTouchCoordinates.y + dy2;
        }
      }

      const shapeAttrs = {
        x1: this.firstTouchCoordinates.x,
        y1: this.firstTouchCoordinates.y,
        x2,
        y2,
        stroke: this.board.color,
      };

      if (this.shape === 'arrow') {
        shapeAttrs['marker-end'] = `url(#triangle-${this.board.color})`;
      }

      return shapeAttrs;
    }
    let width = Math.abs(dx);
    let height = Math.abs(dy);
    const size = Math.min(width, height);
    if (this.board.shiftKeyIsPressed) {
      width = size;
      height = size;
    }
    const shapeX =
      dx < 0 ? this.firstTouchCoordinates.x - width : this.firstTouchCoordinates.x;
    const shapeY =
      dy < 0 ? this.firstTouchCoordinates.y - height : this.firstTouchCoordinates.y;
    switch (this.shape) {
      case 'rect':
        return {
          x: shapeX,
          y: shapeY,
          width,
          height,
          stroke: this.board.color,
          fill: 'none',
        };
      case 'ellipse':
        return {
          cx: shapeX + width / 2,
          cy: shapeY + height / 2,
          rx: width / 2,
          ry: height / 2,
          stroke: this.board.color,
          fill: 'none',
        };
      default:
        return {};
    }
  };

  handleWsMsg = data => {
    if (this.toolNameOptions.includes(data.type)) {
      const shapeId = data.id;
      const shapeToUpdate = this.board.canvas.getElementById(shapeId);
      const shapeData = { ...data };
      delete shapeData.tool;
      delete shapeData.id;
      delete shapeData.type;
      if (shapeToUpdate) {
        this.updateShape(shapeToUpdate, shapeData, true);
      } else {
        this.newSVGShape(shapeId, shapeData, true);
      }
    }
  };

  beginDrawingShape = (e, x, y) => {
    e.preventDefault();
    this.activeShapeId = this.board.generateUniqueID(`shape_${this.shape}`);
    this.firstTouchCoordinates = { x, y };
  };

  drawShape = (e, x, y) => {
    const msSinceLastSend = performance.now() - this.lastSendTime;
    if (msSinceLastSend > 60 && this.activeShapeId) {
      let shape = null;
      if (this.activeShapeElem && this.activeShapeId === this.activeShapeElem.id) {
        shape = this.activeShapeElem;
      } else {
        shape = this.board.canvas.getElementById(this.activeShapeId);
      }
      this.lastSendTime = performance.now();
      const attrs = this.getShapeAttrs(x, y);
      if (!shape) {
        if (
          Math.abs(x - this.firstTouchCoordinates.x) > 0 ||
          Math.abs(y - this.firstTouchCoordinates.y) > 0
        ) {
          this.newSVGShape(this.activeShapeId, attrs);
        }
      } else {
        this.updateShape(shape, attrs);
      }
    }
  };

  finishDrawingShape = (e, x, y) => {
    this.drawShape(e, x, y);
    if (this.activeShapeId) {
      this.board.appendToEditHistory({
        type: 'add',
        elem: this.board.canvas.getElementById(this.activeShapeId),
      });
    }
    this.activeShapeId = '';
    this.firstTouchCoordinates = null;
  };

  newSVGShape = (id, props, noBroadcast) => {
    const attrs = {
      id,
      ...props,
    };

    const childName = this.shape === 'arrow' ? 'line' : this.shape;
    const shape = this.board.newSVGChild(childName, attrs);
    this.board.appendChild(shape);

    if (!noBroadcast) {
      this.board.broadcast({
        type: `shape_${this.shape}`,
        ...attrs,
        tool: `shape_${this.shape}`,
      });
    }
    return shape;
  };

  updateShape = (shape, attrs, noBroadcast) => {
    if (!shape) return;
    const attrNames = Object.keys(attrs);
    for (const name of attrNames) {
      shape.setAttribute(name, attrs[name]);
    }

    if (!noBroadcast) {
      this.board.broadcast({
        type: `shape_${this.shape}`,
        id: shape.id,
        ...attrs,
        tool: `shape_${this.shape}`,
      });
    }
  };
}

export default ShapeTool;
