import { MouseOrTouchEvent } from 'react-select/src/Select';
import wbImageDeleteBtnImg from 'images/wb_grid_delete_button.svg';
import _ from 'lodash';
import {
  isHandleBelowMinComponentSize,
  getImageDataFromElement,
  getContainerDataFromElement,
  getIdFromToolTarget,
  getDirectionFromToolTarget,
  getTargetCoordsFromMouseCoords,
  setActiveClass,
  updateWhiteboardElement,
  CORNER_DIRECTIONS,
  EDITING_TOOL_CONFIG,
} from './tool_util';
import { WhiteboardTool, WhiteboardObjectData } from './tool_util_types';

const IMAGE_DELETE_BTN_RADIUS = 8;
const IMAGE_DELETE_BTN_MARGIN = 8;

/** Class names match whiteboard.css. */
const IMAGE_CLASS = 'wb-image';

/** Explicit broadcast values for update requests. */
const LOCAL_CHANGE = true;

interface ImageTool extends WhiteboardTool {
  selectedImage?: HTMLElement;
  selectedImageTransformHandle?: EventTarget | null;
  image: ImageData;
  imageStartPositionX?: number;
  imageStartPositionY?: number;
  imageStartWidth?: number;
  imageStartHeight?: number;
}

interface ImageData extends WhiteboardObjectData {
  href?: string;
  aspectRatio?: number;
}

interface ImageCreateOptions {
  noBroadcast: boolean;
  updateEditHistory?: boolean;
}

interface WSImageMessage extends ImageData {
  type: string;
  tool: string;
}

/**
 * Whiteboard Tool for editing existing image objects.
 *
 * board.js requires eventHandlers and handleWsMsg()
 */
class ImageTool {
  constructor(board: any) {
    this.board = board;
    this.name = 'image';
    this.type = 'image';
    this.imageStartPositionX = 0;
    this.imageStartPositionY = 0;
    this.imageStartWidth = 0;
    this.imageStartHeight = 0;
    this.eventHandlers = {
      mousedown: this.selectImage,
      touchstart: this.selectImage,
    };
  }

  /**
   * Handles all whiteboard clicks.
   *
   * On image click, creates an image editing wrapper for editing tools.
   * Removes the wrapper, when no image is selected.
   */
  selectImage = (event: MouseOrTouchEvent) => {
    const id = getIdFromToolTarget(event.target);
    // Skip image event handlers when clicking delete button
    if (_.startsWith(id, 'delete-')) {
      return;
    }
    const image = document.getElementById(id);
    // Hide editing tools if there's no target.
    if (!image || !image?.id.includes('image')) {
      this.removeAllImageEditingHandles();
      this.selectedImage = undefined;
      return;
    }
    // Render editing tool if one doesnt exist for the clicked image id.
    if (!document.getElementById(`${image.id}_wrapper`)) {
      this.removeAllImageEditingHandles();
      this.selectedImage = image;
      this.createImageEditingHandles(image);
    }
  };

  /**
   * Updates local canvas when data is recieved.
   *
   * Called by board.js.
   */
  handleWsMsg = (data: WSImageMessage) => {
    if (data.type === this.type) {
      const image = this.board.canvas.getElementById(data.id);
      if (image) {
        updateWhiteboardElement(image, data, this, LOCAL_CHANGE);
      } else {
        this.createImage(data, {
          noBroadcast: LOCAL_CHANGE,
          updateEditHistory: true,
        });
      }
    }
  };

  /**
   * Creates an image on canvas and saves.
   *
   * Every update broadcasts(saves) unless flagged(LOCAL_CHANGE).
   * A local change only renders eg: recieving a change from another user.
   */
  createImage = (imageData: ImageData, options: ImageCreateOptions) => {
    const { noBroadcast = false, updateEditHistory = false } = options;
    const imageDeleteButton = this.board.newSVGChild('image', {
      id: `delete-${imageData.id}`,
      class: 'wb-image-delete-btn',
      width: IMAGE_DELETE_BTN_RADIUS * 2,
      height: IMAGE_DELETE_BTN_RADIUS * 2,
      x: (Number(imageData.x) || 0) + IMAGE_DELETE_BTN_MARGIN,
      y: (Number(imageData.y) || 0) + IMAGE_DELETE_BTN_MARGIN,
      href: wbImageDeleteBtnImg,
      title: 'Delete image',
      onClick: () => {
        const imageToDelete = this.board.canvas.getElementById(imageData.id);
        if (imageToDelete) {
          const deleteButton = this.board.canvas.getElementById(
            `delete-${imageData.id}`,
          );
          const imageResizers = this.board.canvas.getElementById(
            `${imageData.id}_wrapper`,
          );
          this.board.canvas.removeChild(deleteButton);
          this.board.canvas.removeChild(imageToDelete);
          if (imageResizers) {
            this.board.canvas.removeChild(imageResizers);
          }
          this.board.broadcast({
            type: 'remove',
            id: imageData.id,
            tool: 'eraser',
          });
          this.board.appendToEditHistory({
            type: 'remove',
            elem: imageToDelete,
          });
        }
      },
    });

    const image = this.board.newSVGChild('image', {
      ...imageData,
      preserveAspectRatio: 'none',
      class: IMAGE_CLASS,
    });
    this.board.appendChild(image);
    this.board.canvas.appendChild(imageDeleteButton);

    if (updateEditHistory) {
      this.board.appendToEditHistory({
        type: 'add',
        elem: image,
      });
    }

    if (!noBroadcast) {
      this.board.broadcast({
        ...imageData,
        type: this.type,
        tool: this.name,
      });
    }
    return image;
  };

  /**
   * Inserts local image editing tools onto canvas.
   *
   * Foreign object required to contain divs on svg.
   * Expected structure
   * canvas
   *  - image (bottom)
   *  - wrapper (top) > resizers > handle | container
   */
  createImageEditingHandles = (image: HTMLElement) => {
    const imageData = getImageDataFromElement(image);
    const offset = EDITING_TOOL_CONFIG.wrapperOffset;
    const ImageEditingHandlesWrapper = this.board.newSVGChild('foreignObject', {
      id: `${image.id}_wrapper`,
      width: `${imageData.width + offset}`,
      height: `${imageData.height + offset}`,
      x: `${imageData.x - offset / 2}`,
      y: `${imageData.y - offset / 2}`,
      class: `${IMAGE_CLASS}-wrapper`,
    });

    this.board.appendChild(ImageEditingHandlesWrapper);

    const resizers = document.createElement('div');
    resizers.id = `${image.id}_resizers`;
    resizers.className = 'resizers';
    ImageEditingHandlesWrapper.appendChild(resizers);

    // Corner handle for resizing.
    for (const direction of CORNER_DIRECTIONS) {
      const resizeHandle = document.createElement('div');
      resizeHandle.id = `${image.id}_${direction}-handle`;
      resizeHandle.className = `corner-handle ${direction}-handle`;
      resizeHandle.addEventListener('mousedown', this.startResizeImage);
      resizeHandle.addEventListener('touchstart', this.startResizeImage);
      resizers.appendChild(resizeHandle);
    }

    // Covers the image for moving.
    const outline = document.createElement('div');
    outline.id = `${image.id}_container`;
    outline.className = 'image-container';
    outline.addEventListener('mousedown', this.startMoveImage);
    outline.addEventListener('touchstart', this.startMoveImage);
    resizers.appendChild(outline);

    setActiveClass(image.id, true);
  };

  /**
   * Removes every instance of ${IMAGE_CLASS}-wrapper from canvas.
   */
  removeAllImageEditingHandles = () => {
    const editingBoxes = document.getElementsByClassName(`${IMAGE_CLASS}-wrapper`);
    while (editingBoxes.length > 0) {
      editingBoxes[0].parentNode?.removeChild(editingBoxes[0]);
    }
    this.selectedImageTransformHandle = undefined;
  };

  saveImageState = (imageData: ImageData) => {
    this.imageStartPositionX = imageData.x;
    this.imageStartPositionY = imageData.y;
    this.imageStartWidth = imageData.width;
    this.imageStartHeight = imageData.height;
  };

  getImageState = () => ({
    x: this.imageStartPositionX,
    y: this.imageStartPositionY,
    width: this.imageStartWidth,
    height: this.imageStartHeight,
  });

  /**
   * Dynamically add and remove mousemove listener when the wrapper is clicked.
   *
   * Attached to target "${id}_container".
   */
  startMoveImage = (event: MouseEvent | TouchEvent) => {
    const id = getIdFromToolTarget(event.target);
    const image = document.getElementById(id);
    if (!image) return;
    const imageData = getImageDataFromElement(image);
    this.saveImageState(imageData);
    window.addEventListener('mousemove', this.moveImageHandler);
    window.addEventListener(
      'mouseup',
      event => {
        window.removeEventListener('mousemove', this.moveImageHandler);
        const id = getIdFromToolTarget(event.target);
        const imageData = this.getImageDataByImageId(id);
        if (!imageData) return;
        this.board.appendToEditHistory({
          type: 'relocate',
          elem: document.getElementById(id),
          oldState: _.pick(this.getImageState(), ['x', 'y', 'height', 'width']),
          newState: _.pick(imageData, ['x', 'y', 'height', 'width']),
        });
      },
      { once: true },
    );
  };

  /*
   * Move image by movementX and movementY units of distance. Also relocates image resizers and deletion button if they exist.
   */
  moveImage = (id: string, movementX: number, movementY: number) => {
    const image = document.getElementById(id);
    if (!image) return;

    const imageEditingHandles = document.getElementById(`${id}_wrapper`);
    if (imageEditingHandles) {
      // truthy if image is selected
      const imageEditingHandlesData = getContainerDataFromElement(
        imageEditingHandles,
      );
      updateWhiteboardElement(
        imageEditingHandles,
        {
          ...imageEditingHandlesData,
          x: imageEditingHandlesData.x + movementX,
          y: imageEditingHandlesData.y + movementY,
        },
        this,
        LOCAL_CHANGE,
      );
    }

    // move image
    const imageData = getImageDataFromElement(image);
    const updatedImageData = {
      ...imageData,
      x: imageData.x + movementX,
      y: imageData.y + movementY,
    };
    updateWhiteboardElement(image, updatedImageData, this);

    // move delete button
    const deleteImageButton = document.getElementById(`delete-${id}`);
    if (deleteImageButton) {
      // truthy if image tool is selected and the image exists
      updateWhiteboardElement(
        deleteImageButton,
        {
          ...updatedImageData,
          x: updatedImageData.x + IMAGE_DELETE_BTN_MARGIN,
          y: updatedImageData.y + IMAGE_DELETE_BTN_MARGIN,
        },
        this,
      );
    }
  };

  /**
   * When image is moved, move both the edit handles and the image.
   * Broadcast these changes to be saved.
   *
   * Attached to target "${id}_container".
   */
  moveImageHandler = (event: MouseEvent) => {
    const id = getIdFromToolTarget(event.target);
    const imageEditingHandles = document.getElementById(`${id}_wrapper`);
    const deleteImageButton = document.getElementById(`delete-${id}`);
    const imageData = this.getImageDataByImageId(id);

    // this check fixes bug with rapid image movement
    if (!imageEditingHandles || !imageData || !deleteImageButton) return;
    this.transformImage(
      id,
      imageData.x + event.movementX,
      imageData.y + event.movementY,
      imageData.height,
      imageData.width,
    );
  };

  /*
   * Transform image (and resizers/deletion button if they exist) to given values.
   */
  transformImage = (
    id: string,
    newX: number,
    newY: number,
    newHeight: number,
    newWidth: number,
  ) => {
    const image = document.getElementById(id);
    if (!image) return;

    const imageEditingHandles = document.getElementById(`${id}_wrapper`);
    if (imageEditingHandles) {
      // truthy if image is selected
      const imageEditingHandlesData = getContainerDataFromElement(
        imageEditingHandles,
      );
      const offset = EDITING_TOOL_CONFIG.wrapperOffset;
      updateWhiteboardElement(
        imageEditingHandles,
        {
          ...imageEditingHandlesData,
          x: newX - offset / 2,
          y: newY - offset / 2,
          height: newHeight + offset,
          width: newWidth + offset,
        },
        this,
        LOCAL_CHANGE,
      );
    }

    // move image
    const imageData = getImageDataFromElement(image);
    const updatedImageData = {
      ...imageData,
      x: newX,
      y: newY,
      height: newHeight,
      width: newWidth,
    };
    updateWhiteboardElement(image, updatedImageData, this);

    // move delete button
    const deleteImageButton = document.getElementById(`delete-${id}`);
    if (deleteImageButton) {
      // truthy if image tool is selected and the image exists
      updateWhiteboardElement(
        deleteImageButton,
        {
          ...updatedImageData,
          x: newX + IMAGE_DELETE_BTN_MARGIN,
          y: newY + IMAGE_DELETE_BTN_MARGIN,
        },
        this,
      );
    }
  };

  getImageDataByImageId = (id: string) => {
    const image = document.getElementById(id);
    return image ? getImageDataFromElement(image) : null;
  };

  /**
   * Dynamically add and remove mousemove listener when the resize handle is clicked.
   *
   * Attached to target "${id}_${direction}-handle".
   */
  startResizeImage = (event: Event) => {
    this.selectedImageTransformHandle = event.target;
    const id = getIdFromToolTarget(event.target);
    const imageData = this.getImageDataByImageId(id);
    if (!imageData) return;
    this.saveImageState(imageData);
    this.board.canvas.addEventListener('mousemove', this.resizeImage);
    window.addEventListener(
      'mouseup',
      () => {
        this.board.canvas.removeEventListener('mousemove', this.resizeImage);
        const imageData = this.getImageDataByImageId(id);
        if (!imageData) return;
        this.board.appendToEditHistory({
          type: 'resize',
          elem: document.getElementById(id),
          oldState: _.pick(this.getImageState(), ['x', 'y', 'height', 'width']),
          newState: _.pick(imageData, ['x', 'y', 'height', 'width']),
        });
      },
      { once: true },
    );
  };

  /**
   * Defines a box by its northwest corner (0,0) and southeast corner (width,height) that scales to the mouse position.
   * Direction is the corner handle that was dragged. Named as: [ne, nw, se, sw].
   * https://www.loom.com/share/d2e35492fdf348d0860229cddeee0d43
   *
   * 1. Sets the coordinates of the southeast corner based on the handle direction.
   * 2. Scales a box by the aspect ratio
   * 3. Moves the box's x/y in addition to scaling when dragging north or east handles.
   * 4. Return the scale values where (width,height) matches the mouse's largest x/y coordinate.
   */
  scaleImageByRatio = (
    movement: { x: number; y: number },
    imageData: ImageData,
    direction: string,
  ) => {
    // X and Y can be 0.
    if (
      imageData.x === undefined ||
      imageData.y === undefined ||
      !imageData.width ||
      !imageData.height ||
      !imageData.aspectRatio
    ) {
      return;
    }
    // 1. Sets the coordinates of the southeast corner based on the handle direction.
    const southEastCorner = {
      x: direction.includes('w') ? imageData.width - movement.x : movement.x,
      y: direction.includes('n') ? imageData.height - movement.y : movement.y,
    };

    // Scale image by aspect ratio.
    const scaleX = {
      x: imageData.x,
      y: imageData.y,
      width: southEastCorner.x,
      height: Number(imageData.aspectRatio) * southEastCorner.x,
    };
    const scaleY = {
      x: imageData.x,
      y: imageData.y,
      width: southEastCorner.y / Number(imageData.aspectRatio),
      height: southEastCorner.y,
    };

    // 3. Moves the box's x/y in addition to scaling when dragging north or east handles.
    if (direction.includes('w')) {
      scaleX.x += movement.x;
      scaleY.x += imageData.width - scaleY.width;
    }
    if (direction.includes('n')) {
      scaleX.y += imageData.height - scaleX.height;
      scaleY.y += movement.y;
    }
    // 4. Return the scale values where (width,height) matches the mouse's largest x/y coordinate.
    return scaleX.height > southEastCorner.y ? scaleX : scaleY;
  };

  /**
   * These functions create/modify the editing tools over an image.
   *
   * Attached to target "${id}_${direction}-handle".
   * 1. Scale the image to match mouseX or mouseY.
   * 2. Update wrapper size to match image + offset.
   * 3. Move delete button to keep it in the top left corner.
   */
  resizeImage = (event: MouseEvent) => {
    event.preventDefault();

    // Get reference to the image and editing handles.
    if (!this.selectedImage || !this.selectedImageTransformHandle) return;
    const imageEditingHandles = document.getElementById(
      `${this.selectedImage.id}_wrapper`,
    );
    const image = this.selectedImage;
    const direction = getDirectionFromToolTarget(this.selectedImageTransformHandle);
    const deleteImageButton = document.getElementById(
      `delete-${this.selectedImage.id}`,
    );

    if (!imageEditingHandles || !image || !direction || !deleteImageButton) return;
    const imageData = getImageDataFromElement(image);
    const movement = getTargetCoordsFromMouseCoords(
      event.clientX,
      event.clientY,
      this.board.canvas.getBoundingClientRect(),
      imageData,
    );

    // 1. Scale image.
    const newImagePositionData = this.scaleImageByRatio(
      movement,
      imageData,
      direction,
    ) as WhiteboardObjectData;

    if (
      !newImagePositionData ||
      isHandleBelowMinComponentSize(newImagePositionData)
    ) {
      return;
    }

    this.transformImage(
      image.id,
      newImagePositionData.x!,
      newImagePositionData.y!,
      newImagePositionData.height!,
      newImagePositionData.width!,
    );
    // updateWhiteboardElement(image, newImagePositionData, this);

    // 2. Scale outline to match image.
    const offset = EDITING_TOOL_CONFIG.wrapperOffset;
    const newHandlePositionData = {
      id: imageEditingHandles.id,
      x: newImagePositionData.x! - offset / 2,
      y: newImagePositionData.y! - offset / 2,
      width: newImagePositionData.width! + offset,
      height: newImagePositionData.height! + offset,
    };

    updateWhiteboardElement(
      imageEditingHandles,
      newHandlePositionData,
      this,
      LOCAL_CHANGE,
    );

    // 3. Move the delete button to match image.
    updateWhiteboardElement(
      deleteImageButton,
      {
        ...newImagePositionData,
        x: newImagePositionData.x! + IMAGE_DELETE_BTN_MARGIN,
        y: newImagePositionData.y! + IMAGE_DELETE_BTN_MARGIN,
      },
      this,
    );
  };
}
export default ImageTool;
