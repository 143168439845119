import juniAxios from 'services/axios';

// TODO: I searched everywhere but could not find an option to just
// get the instructor by instructor ID without the "loads" coming
// along for the ride. Need to add this option on the backend.
const getInstructorById = async id => {
  const res = await juniAxios.get(
    `/api_admin_instructor_db/instructorWithLoads/${id}`,
  );
  return res?.data?.data;
};

const getInstructorByUserId = async userId => {
  const res = await juniAxios.get(`/api_teacher/instructor_by_user_id/${userId}`);

  return res && res.data;
};

export { getInstructorById, getInstructorByUserId };
