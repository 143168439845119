import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { PencilIcon, Arrow } from 'components/Icons';
import { ProjectEditorContainer, Error } from 'components/ui/EditTooltipContainer';
import { InputField } from 'components/ui';
import ActionButton from 'components/ActionButton';
import useDimensions from 'hooks/dimensions';
import {
  IUpdateProjectNameParams,
  IValidationResponse,
} from 'app/playground/models';
import { validateCustomProjectName } from 'utils/validation';

const Container = styled.div`
  display: flex;
  align-items: auto;
  justify-content: flex-start;
  & .icon__toggle {
    background: transparent;
    padding: 0;

    & path {
      fill: #00a5ff;
    }
  }

  & .header {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
  }

  & .ui-input {
    margin: 0.5rem 0;
    border: 2px solid #00a5ff;
  }

  & .ui-input.error {
    border: 2px solid #da0f0f;
    background: #ffe9e8;
  }

  & .save-project__button {
    background: #00a5ff;
    border: 2px solid #4abfff;
    border-radius: 0.5rem;
    padding: 8px;
    padding-left: 24px;
    text-transform: none;
    font-size: 0.875rem;
    letter-spacing: 0;
    font-weight: bold;
    display: flex;
    align-items: center;

    & .save-project {
      width: 100%;
      text-align: center;
    }
    & .icon {
      margin-left: auto;
      background: rgba(255, 255, 255, 0.13);
      border-radius: 0.25rem;
      height: 1rem;
      width: 1rem;
      padding: 0.75rem;
    }
    & path {
      fill: white;
    }
  }
`;

const EditProjectName: React.FC<{
  initialProjectName: string;
  projectId: string;
  updateProjectName: (
    params: IUpdateProjectNameParams,
  ) => Promise<IValidationResponse | undefined>;
}> = ({ updateProjectName, projectId, initialProjectName }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [newProjectName, setNewProjectName] = useState<undefined | string>(
    undefined,
  );
  const [editing, toggleEditing] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const { width } = useDimensions();

  useEffect(() => {
    const closeOnOutsideClick = (e: MouseEvent) => {
      if (
        containerRef?.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        toggleEditing(false);
      }
    };

    document.addEventListener('click', closeOnOutsideClick);
    window.addEventListener('blur', () => toggleEditing(false));

    return () => {
      document.removeEventListener('click', closeOnOutsideClick);
      window.removeEventListener('blur', () => toggleEditing(false));
    };
  });

  const onClick = async () => {
    if (!newProjectName || newProjectName === '') {
      toggleEditing(false);
      return;
    }

    const errorMessage = validateCustomProjectName(newProjectName);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    const res = await updateProjectName({
      projectId,
      desiredProjectName: newProjectName,
    });

    if (!res?.valid) {
      setError(res?.msg);
    } else {
      toggleEditing(false);
    }
  };
  return (
    <Container ref={containerRef}>
      <button
        className="icon__toggle"
        onClick={() => {
          toggleEditing(!editing);
        }}
      >
        <PencilIcon />
      </button>
      {editing && (
        <ProjectEditorContainer
          style={{ marginLeft: width <= 650 ? '1rem' : '-1.5rem' }}
          leftAlignIcon
          negativeSpacing
        >
          <span className="header">Rename this project:</span>
          <InputField
            value={
              newProjectName || newProjectName === ''
                ? newProjectName
                : initialProjectName
            }
            onChange={(e: any) => {
              setError(undefined);
              setNewProjectName(e);
            }}
          />
          {error && <Error>{error}</Error>}
          <ActionButton onClick={onClick} className="save-project__button">
            <span className="save-project">Save this project</span>{' '}
            <Arrow orientation="right" />
          </ActionButton>
        </ProjectEditorContainer>
      )}
    </Container>
  );
};

export default EditProjectName;
