import React, { FC, useState } from 'react';

import CardHeader from 'components/CardHeader';
import { FilterIcon, NotesIcon } from 'components/Icons';
import {
  ORDERED_SUBJECTS,
  SUBJECT_DISPLAY_NAMES,
  SUBJECT_TYPE,
} from 'constants/subjects';
import { FilterDropdownWrapper, SessionTypeList } from './styles';

interface FilterDropdownProps {
  subjects: Array<SUBJECT_TYPE>;
  handleClick: (name?: SUBJECT_TYPE) => void;
}

interface SessionNotesFilterProps extends FilterDropdownProps {
  studentFirstName?: string;
  selectedSubjectName?: SUBJECT_TYPE;
}

const FILTER_OPTIONS = [undefined, ...ORDERED_SUBJECTS] as Array<
  SUBJECT_TYPE | undefined
>;

const FilterDropdown: FC<FilterDropdownProps> = ({ subjects, handleClick }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <FilterDropdownWrapper
      dropdownOpen={dropdownOpen}
      onClick={() => {
        setDropdownOpen(prevDropdownOpen => !prevDropdownOpen);
      }}
    >
      <FilterIcon />
      {dropdownOpen && (
        <SessionTypeList>
          {FILTER_OPTIONS.filter(name => !name || subjects.includes(name)).map(
            name => {
              const displayName = !name
                ? 'All Session Types'
                : `${SUBJECT_DISPLAY_NAMES[name]} Sessions`;
              return (
                <li key={name}>
                  <button onClick={() => handleClick(name)}>{displayName}</button>
                </li>
              );
            },
          )}
        </SessionTypeList>
      )}
    </FilterDropdownWrapper>
  );
};

const SessionNotesFilterHeader: FC<SessionNotesFilterProps> = ({
  subjects,
  handleClick,
  studentFirstName,
  selectedSubjectName,
}) => {
  const title = [
    studentFirstName ? `${studentFirstName}'s past` : 'Past',
    selectedSubjectName ? SUBJECT_DISPLAY_NAMES[selectedSubjectName] : '',
    'session notes',
  ]
    .filter(string => string)
    .join(' ');
  return (
    <CardHeader icon={<NotesIcon />} title={title}>
      {subjects.length > 1 && (
        <FilterDropdown subjects={subjects} handleClick={handleClick} />
      )}
    </CardHeader>
  );
};

export default SessionNotesFilterHeader;
