import React, { useEffect, useState } from 'react';
import scratchProjectsService from 'services/playgroundService';
import { JideEnvEmptyState } from '..';
import { ICustomScratchProject } from './models';
import ScratchEmbed from './ScratchEmbed';

const JuniverseScratchProject: React.FC<{
  userId: string;
  moduleId: string;
  projectId: string;
}> = ({ userId, moduleId, projectId }) => {
  const [project, setProject] = useState<ICustomScratchProject | undefined>(
    undefined,
  );
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        userId,
        moduleId,
        projectId,
      };
      const project = await scratchProjectsService.list(params);

      if (project[0]) {
        setProject(project[0]);
      }
    };

    fetchData();
  }, [moduleId, userId, projectId]);
  return (
    <>
      {project ? (
        <ScratchEmbed
          displayName={project.displayName}
          projectLink={project.scratchUrl}
        />
      ) : (
        <JideEnvEmptyState description="If you're the owner, login to the Juni portal and save the scratch project link to your portfolio, then refresh this page." />
      )}
    </>
  );
};

export default JuniverseScratchProject;
