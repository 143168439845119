import classNames from 'classnames';
import React from 'react';

// TODO - hover, focused, disabled, pressed states(?)
export interface FilterCheckboxProps {
  /**
   * Whether the button is currently selected
   */
  selected: boolean;
  /**
   * The onClick event.  The selected argument is the current selected value.
   */
  onClick: () => void;
  width: React.CSSProperties['width'];
  height: React.CSSProperties['height'];
  className?: string;
  ariaLabel?: string;
}

/**
 * Functions differently than a core component button in styling.
 * Also provides the ability to set a specific height to ensure like buttons
 * can force consistency in size.
 */
const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  selected,
  onClick,
  width,
  height,
  className,
  ariaLabel,
  children,
}) => (
  <button
    onClick={onClick}
    className={classNames(
      className,
      'flex justify-center items-center box-border rounded-lg border-solid border-j-dark-200 text-j-dark-600 text-sm',
      {
        'border bg-white': !selected,
        'border-2 border-j-purple-500 bg-j-purple-100': selected,
      },
    )}
    style={{ width, height }}
    aria-label={ariaLabel}
  >
    <div>{children}</div>
  </button>
);

export default FilterCheckbox;
