import juniAxios from 'services/axios';
import Auth from 'modules/Auth';

const config = {
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
};

export const userLogin = async (
  email: string,
  password: string,
  userType: string,
) => {
  const res = await juniAxios.post(
    `/auth/login`,
    {
      email,
      password,
      userType,
    },
    config,
  );
  const authToken = res?.data?.token;
  Auth.authenticateUser(authToken);
};
