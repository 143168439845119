import juniAxios from 'services/axios';

export async function addProjectToStudentRecentProjects(
  studentId,
  courseId,
  courseType,
  projectId,
) {
  return (
    await juniAxios.post(
      `/learner/student_recent_projects/${studentId}/course/${courseId}/courseType/${courseType}/project/${projectId}`,
      {},
    )
  ).data.data;
}

export async function getStudentRecentProjectsForCourse(studentId, courseId) {
  return (
    await juniAxios.get(
      `/learner/student_recent_projects/${studentId}/course/${courseId}`,
    )
  ).data.data;
}

export async function getStudentRecentProjectsForCourseType(studentId, courseType) {
  return (
    await juniAxios.get(
      `/learner/student_recent_projects/${studentId}/courseType/${courseType}`,
    )
  ).data.data;
}

export async function getAggregatedStudentRecentProjects(studentId) {
  return (
    await juniAxios.get(`/learner/student_recent_projects/${studentId}/aggregated`)
  ).data.data;
}
