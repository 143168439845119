import { useNotesFromHqByDateQuery } from 'generated/graphql';
import { TeacherSession } from 'models';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
  teacherSession: TeacherSession;
}

const NotesFromHQ: FC<Props> = ({ teacherSession }) => {
  const { data } = useNotesFromHqByDateQuery({
    variables: { date: teacherSession.sessionTime },
  });
  const notesfromHqBySessionDate = data?.notesFromHQByDate.items;

  if (!notesfromHqBySessionDate || !notesfromHqBySessionDate.length) {
    return null;
  }
  return (
    <div>
      <div className="font-graphik text-j-dark-600 font-medium text-xl pb-4">
        Notes from HQ
      </div>
      <div className="flex flex-col gap-4">
        {notesfromHqBySessionDate.map(note => (
          <ReactMarkdown
            key={`note-${note._id}`}
            className="font-graphik text-base text-j-dark-600"
            renderers={{
              link: ({ href, children }) => (
                <a
                  href={href}
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {children}
                </a>
              ),
            }}
          >
            {note.text.trim()}
          </ReactMarkdown>
        ))}
      </div>
    </div>
  );
};

export default NotesFromHQ;
