import React, { FC, useState } from 'react';
import {
  NewModalWindow,
  NewButton as Button,
  Input,
  Message,
} from 'core-components';
import { ModalButtonsFooter } from 'core-components/NewModalWindow/NewModalWindow';

import { setUsername as createUsername } from 'services/juniverse';
import { validateUsername } from 'utils/validation';
import { parseError } from 'utils/errors';
import SpinnerV2 from 'components/SpinnerV2';

interface UsernameCreatorProps {
  isOpen: React.ComponentProps<typeof NewModalWindow>['isOpen'];
  closeModal: React.ComponentProps<typeof NewModalWindow>['closeModal'];
  onSuccessCallback: () => void;
  studentId?: string;
  instructorUserId?: string;
}

const UsernameCreator: FC<UsernameCreatorProps> = ({
  isOpen,
  closeModal,
  onSuccessCallback,
  studentId,
  instructorUserId,
}) => {
  const [username, setUsername] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  const loggedInUserId = studentId || instructorUserId;

  if (!loggedInUserId) {
    return null;
  }

  // TODO: Add functionality
  const handleCreateUsername = async () => {
    setIsSubmitting(true);

    const userType = studentId ? 'student' : 'teacher';
    const loggedInUserId = studentId || instructorUserId;

    const validationErr = validateUsername(username);

    if (validationErr) {
      setErrMsg(validationErr);
    }

    const { success, error } = await createUsername(
      loggedInUserId,
      username,
      userType,
    );

    if (!validationErr && error) {
      setErrMsg(parseError(error, `Sorry, that didn't work.`));
    } else if (success) {
      setSuccess(true);
      await onSuccessCallback();
    }
    setIsSubmitting(false);
  };

  const handleInputChange = (e: any) => {
    setErrMsg('');
    setUsername(e.target.value);
  };

  const renderCreateFooter = () => (
    <ModalButtonsFooter
      primary={
        <Button onClick={handleCreateUsername} disabled={isSubmitting}>
          {' '}
          {isSubmitting ? <SpinnerV2 /> : 'Set username'}
        </Button>
      }
      secondary={
        <Button onClick={closeModal} variant="minimal" disabled={isSubmitting}>
          I'll do it later
        </Button>
      }
    />
  );

  const renderSuccessFooter = () => (
    <ModalButtonsFooter
      primary={
        <Button onClick={closeModal} fullWidth>
          Got it.
        </Button>
      }
    />
  );

  return (
    <NewModalWindow
      isOpen={isOpen}
      closeModal={closeModal}
      title="Create your username"
      description="Your username will be used in your Juni Profile and in Juni Clubs! You can do this one time. You must create a username in order to join clubs or publish projects to your juniverse profile."
      renderFooter={success ? renderSuccessFooter : renderCreateFooter}
      maxWidth="512px"
    >
      {success ? (
        <Message status="success">
          Your username <b>{username}</b> has been created successfully!
        </Message>
      ) : (
        <div className="w-full">
          <div className="mb-2 text-j-dark-600">Create a username</div>
          <Input
            size="small"
            fullWidth
            onChange={handleInputChange}
            valid={errMsg.length > 1 ? !errMsg : undefined}
            message={errMsg}
            disabled={isSubmitting}
          />
        </div>
      )}
    </NewModalWindow>
  );
};

export default UsernameCreator;
