import React from 'react';
import classNames from 'classnames';
import { TailwindColor } from 'theme/types';

interface NotificationsBadgeProps {
  backgroundColor?: TailwindColor;
  hasTopRightPositioning?: boolean;
  numNotifications: number;
  showAsDot?: boolean;
}

const NotificationsBadge: React.FC<NotificationsBadgeProps> = ({
  backgroundColor = 'j-pink-600',
  hasTopRightPositioning = false,
  numNotifications,
  showAsDot = false,
}) =>
  numNotifications > 0 ? (
    <div
      className={classNames(
        `bg-${backgroundColor}`,
        'text-xs',
        'text-white',
        'font-bold',
        'flex',
        'items-center',
        'justify-center',
        { 'w-3 h-3 rounded-full': showAsDot },
        { 'w-7 h-5 rounded-xl': !showAsDot },
        { '-top-1 -right-1': showAsDot && hasTopRightPositioning },
        { '-top-2.5 -right-3.5': !showAsDot && hasTopRightPositioning },
        { absolute: hasTopRightPositioning },
      )}
    >
      {!showAsDot && (numNotifications >= 100 ? '99+' : numNotifications)}
    </div>
  ) : null;

export default NotificationsBadge;
