import React, { FC, ChangeEventHandler } from 'react';
import classNames from 'classnames';
import { Icon } from 'core-components';

export interface ToggleLabelProps {
  disabled?: boolean;
  hideOnDesktop?: boolean;
  hideOnMobile?: boolean;
  renderLeft?: boolean;
}

export const ToggleLabel: FC<ToggleLabelProps> = ({
  children,
  disabled,
  hideOnDesktop,
  hideOnMobile,
  renderLeft,
}) => {
  const labelStyles = classNames('leading-6 text-base', {
    'pl-3': renderLeft,
    'pr-3': !renderLeft,
    'md:hidden': hideOnDesktop,
    'hidden md:block': hideOnMobile,
    'text-j-dark-600': !disabled,
    'text-j-purple-300': disabled,
  });
  return <span className={labelStyles}>{children}</span>;
};

export interface ToggleSwitchProps {
  isChecked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({
  isChecked,
  onChange,
  disabled,
}) => {
  const containerStyles = classNames(
    'inline-flex relative rounded-full transition-all border-2 border-solid border-transparent focus-within:border-j-dark-200',
    {
      'hover:border-j-dark-200': !disabled,
    },
  );

  const checkboxStyles = classNames(
    'appearance-none w-8 h-4 border-2 border-solid border-transparent box-content rounded-full m-0 transition-all',
    {
      'bg-j-purple-600': isChecked && !disabled,
      'bg-j-purple-300': !isChecked && !disabled,
      'bg-white': disabled,
      'cursor-pointer': !disabled,
    },
  );

  const thumbStyles = classNames(
    'w-4 h-4 absolute top-0.5 left-0.5 rounded-full pointer-events-none transition-transform flex items-center justify-center',
    {
      'bg-white': !disabled,
      'bg-j-dark-200': disabled,
    },
  );

  const checkIconStyles = classNames('transition-opacity', {
    'opacity-0': !isChecked,
    'opacity-100': isChecked,
    'text-white': disabled,
    'text-j-purple-600': !disabled,
  });

  return (
    <div className={containerStyles} style={{ height: 'min-content' }}>
      <input
        type="checkbox"
        role="switch"
        checked={isChecked}
        disabled={disabled}
        className={checkboxStyles}
        onChange={onChange}
      />
      <div
        className={thumbStyles}
        style={{ transform: isChecked ? 'translateX(16px)' : 'translateX(0)' }}
      >
        <Icon.Check className={checkIconStyles} width="10" />
      </div>
    </div>
  );
};

export interface ToggleProps {
  isChecked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  hideLabelOnDesktop?: boolean;
  hideLabelOnMobile?: boolean;
  label?: string;
  renderLeft?: boolean;
}

const Toggle: FC<ToggleProps> = ({
  isChecked,
  onChange,
  disabled,
  hideLabelOnDesktop,
  hideLabelOnMobile,
  label,
  renderLeft = true,
}) => (
  <label className={classNames('flex', { 'flex-row-reverse': !renderLeft })}>
    <ToggleSwitch disabled={disabled} isChecked={isChecked} onChange={onChange} />
    {label && (
      <ToggleLabel
        disabled={disabled}
        hideOnDesktop={hideLabelOnDesktop}
        hideOnMobile={hideLabelOnMobile}
        renderLeft={renderLeft}
      >
        {label}
      </ToggleLabel>
    )}
  </label>
);

export default Toggle;
