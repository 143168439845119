import React, { InputHTMLAttributes } from 'react';
import { InputSharedProps } from './types';
import InputBase from './InputBase';

export type InputProps = InputSharedProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

const Input = React.forwardRef<HTMLDivElement, InputProps>((props, ref) => (
  <InputBase {...props} ref={ref} inputComponent="input" />
));

export default Input;
