import React, { FC } from 'react';

import {
  XIcon,
  CheckmarkIcon,
  EllipsisIcon,
  SimpleLockIcon,
} from 'components/Icons';

import ActionButton from 'components/ActionButton';

import { TeacherSession } from 'models';

import { Header, TagContainer, Tag } from '../styles';

const getStatus = (isUpcoming: boolean, teacherSession?: TeacherSession) => {
  if (teacherSession?.completed) {
    return teacherSession.wasMissed ? 'missed' : 'complete';
  }
  return isUpcoming ? 'upcoming' : 'incomplete';
};

const StatusIcon: FC<{ status: string }> = ({ status }) => {
  switch (status) {
    case 'complete':
      return <CheckmarkIcon green />;
    case 'incomplete':
      return <XIcon red />;
    default:
      return <EllipsisIcon blue />;
  }
};

const StatusTag: FC<{ status: string }> = ({ status }) => (
  <Tag className={`header-tag-v2 with-right-icon ${status}`}>
    <span>{status}</span>
    <StatusIcon status={status} />
  </Tag>
);

interface NoteHeaderProps {
  deleteSessionNote: () => Promise<void>;
  isUpcoming: boolean;
  teacherMode: boolean;
  teacherSession?: TeacherSession;
  sessionLabel: string;
  sessionType: string;
  userIsAdmin: boolean;
}

const NoteHeader: FC<NoteHeaderProps> = ({
  deleteSessionNote,
  isUpcoming,
  teacherMode,
  teacherSession,
  sessionLabel,
  sessionType,
  userIsAdmin,
}) => (
  <Header>
    <div className="flex flex-col gap-2">
      <div className="font-graphik text-j-dark-600 font-medium text-xl">
        {sessionType}
      </div>
      <div className="text-j-dark-300 font-normal font-graphik text-base">
        {sessionLabel}
      </div>
    </div>
    <TagContainer>
      {teacherMode && <StatusTag status={getStatus(isUpcoming, teacherSession)} />}
      {teacherSession?.wasMissed && userIsAdmin && (
        <div>
          <ActionButton
            className={null}
            onClick={deleteSessionNote}
            icon={<SimpleLockIcon />}
          >
            Delete Missed Log
          </ActionButton>
        </div>
      )}
    </TagContainer>
  </Header>
);

export default NoteHeader;
