import React, { useCallback } from 'react';
import {
  useCreateAndInitializeInvestingProjectMutation,
  useInvestingProjectsQuery,
  CreateAndInitializeInvestingProjectInput,
} from 'generated/graphql';
import { SUBJECTS, SUBJECT_DISPLAY_NAMES } from 'constants/subjects';
import JideReadingWriting from '../JideEnvEnglish/JideReadingWriting';

const FAILED_PROJECT_INITIALIZATION_MSG = `Oops! Something went wrong while trying to initialize your ${
  SUBJECT_DISPLAY_NAMES[SUBJECTS.INVESTING]
} project. Please refresh the page to try again.`;

interface IProps {
  jideUser: { _id: string; type: string; firstName: string; lastName: string };
  tab: any;
  activeNav: any;
  modalViewMode: boolean;
  shiftKeyIsPressed: boolean;
  idLookup: any;
  recordingMode: any;
  setRecordingMode: any;
  juniverseProjectData: any;
  newHorizons: boolean;
  schedulingFormat: string;
}

const JideEnvInvesting: React.FC<IProps> = props => {
  const { idLookup, tab, modalViewMode, jideUser } = props;
  const moduleSection = idLookup[tab.tabNav.project];

  const {
    data: investingProjectsData,
    loading: investingProjectsLoading,
    error: investingProjectsError,
    refetch: refetchInvestingProjects,
  } = useInvestingProjectsQuery({
    variables: {
      studentId:
        jideUser.type === 'public' || (modalViewMode && jideUser.type === 'teacher')
          ? tab.tabNav.student
          : jideUser._id,
      moduleSectionId: moduleSection.id,
    },
    fetchPolicy: 'no-cache',
  });
  const investingProjects = investingProjectsData?.investingProjects.items;

  const [
    createAndInitializeInvestingProject,
    { loading: isCreatingNewProject },
  ] = useCreateAndInitializeInvestingProjectMutation({
    errorPolicy: 'all',
  });

  const getDataForNewProject: (
    referenceTextSetId?: string,
    writingPromptId?: string,
  ) => CreateAndInitializeInvestingProjectInput = useCallback(
    (referenceTextSetId, writingPromptId) => {
      const newInvestingProjectData: CreateAndInitializeInvestingProjectInput = {
        projectType: moduleSection.type,
        studentId: jideUser._id,
        moduleSectionId: moduleSection.id,
      };
      if (referenceTextSetId)
        newInvestingProjectData.referenceTextSetId = referenceTextSetId;
      if (writingPromptId) newInvestingProjectData.writingPromptId = writingPromptId;
      return newInvestingProjectData;
    },
    [jideUser._id, moduleSection.id, moduleSection.type],
  );

  const createProject = async (
    referenceTextSetId?: string,
    writingPromptId?: string,
  ) => {
    const newInvestingProjectData = getDataForNewProject(
      referenceTextSetId,
      writingPromptId,
    );
    const createRes = await createAndInitializeInvestingProject({
      variables: { input: newInvestingProjectData },
    });
    if (createRes.errors) {
      console.log(createRes.errors);
      alert(FAILED_PROJECT_INITIALIZATION_MSG);
      return;
    }
    if (createRes.data?.createAndInitializeInvestingProject.investingProject) {
      await refetchInvestingProjects();
    }
  };

  const createAndInitializeProject = () => {
    const newInvestingProjectData = getDataForNewProject(
      undefined,
      moduleSection?.properties.writingPromptId,
    );
    return createAndInitializeInvestingProject({
      variables: { input: newInvestingProjectData },
    }).then(async createRes => {
      if (createRes.errors) {
        console.log(createRes.errors);
        alert(FAILED_PROJECT_INITIALIZATION_MSG);
        throw createRes.errors;
      }
    });
  };

  return (
    <JideReadingWriting
      {...props}
      projects={investingProjects}
      projectsData={investingProjectsData}
      projectsLoading={investingProjectsLoading}
      projectsError={investingProjectsError}
      refetchProjects={refetchInvestingProjects}
      isCreatingNewProject={isCreatingNewProject}
      createProject={createProject}
      createAndInitializeProject={createAndInitializeProject}
      showInstructionsSideContentHeader
    />
  );
};

export default JideEnvInvesting;
