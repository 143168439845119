import * as R from 'ramda';

export const consolidateModuleSectionInfo = ({
  computerScienceModuleSectionItems,
  nestedStructureCourses,
  teacherMode,
}: any) => {
  const getNestedModuleSectionInfo = teacherMode
    ? getNestedModuleSectionInfoInstructor
    : getNestedModuleSectionInfoLearner;

  const computerScienceSectionInfo = R.indexBy(
    R.prop('_id') as any,
    computerScienceModuleSectionItems,
  );
  const nestedStructureCoursesSectionInfo = getNestedModuleSectionInfo(
    nestedStructureCourses,
  );

  return {
    ...computerScienceSectionInfo,
    ...nestedStructureCoursesSectionInfo,
  };
};

const getNestedModuleSectionInfoInstructor = (courses: any) => {
  const moduleSectionInfo = {} as any;
  if (courses.length > 0) {
    for (let i = 0; i < courses.length; i += 1) {
      const course = courses[i];
      for (let j = 0; j < course.moduleTopics.length; j += 1) {
        const moduleTopic = course.moduleTopics[j];
        for (let k = 0; k < moduleTopic.modules.length; k += 1) {
          const mod = moduleTopic.modules[k];
          for (let l = 0; l < mod.moduleSections.length; l += 1) {
            const moduleSection = mod.moduleSections[l];
            moduleSectionInfo[moduleSection._id] = {
              ...moduleSection,
              moduleName: mod.name,
            };
          }
        }
      }
    }
  }
  return moduleSectionInfo;
};

// handle traversing nested courses differently because the LearnerHome view uses
// the newer /courses/:courseNames endpoint to fetch them in a different format
// than the TeacherStudentProfile view
const getNestedModuleSectionInfoLearner = (courses: any) => {
  const moduleSectionInfo = {} as any;
  if (courses.length > 0) {
    for (let i = 0; i < courses.length; i += 1) {
      const course = courses[i];
      for (let j = 0; j < course.children.length; j += 1) {
        const mod = course.children[j];
        for (let k = 0; k < mod.children.length; k += 1) {
          const moduleSection = mod.children[k];
          moduleSectionInfo[moduleSection.id] = {
            ...moduleSection,
            moduleName: mod.properties?.name,
          };
        }
      }
    }
  }
  return moduleSectionInfo;
};
