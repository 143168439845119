import React from 'react';
import classNames from 'classnames';
import { NestedRubricItem } from 'generated/graphql';
import { RubricProgressState } from 'models';

import Card from '../Card';
import ProgressBadge from './ProgressBadge';

interface CourseRubricProps {
  levelLabels: string[];
  rubricItems: NestedRubricItem[];
  rubricProgress: RubricProgressState;
  recursiveDepth?: number;
}

interface RubricWrapperProps {
  headerText?: string;
}

const ExpandableWrapper: React.FC<RubricWrapperProps> = ({
  headerText = 'Course Learning Targets',
  children,
}) => (
  <div className="my-4">
    <Card headerText={headerText} defaultExpanded>
      {children}
    </Card>
  </div>
);

const NestedWrapper: React.FC<RubricWrapperProps> = ({ headerText, children }) => (
  <div
    className={classNames(
      'm-2',
      'rounded-lg',
      'border',
      'border-solid',
      'border-j-purple-100',
    )}
  >
    <div
      className={classNames(
        'py-3',
        'px-6',
        'text-lg',
        'font-medium',
        'border-0',
        'border-b',
        'border-solid',
        'border-j-purple-100',
      )}
    >
      {headerText}
    </div>
    <div>{children}</div>
  </div>
);

const CourseRubric: React.FC<CourseRubricProps> = ({
  levelLabels,
  rubricItems,
  rubricProgress,
  recursiveDepth = 0,
}) => {
  const getLevelName = (level?: number) =>
    level && level > 0 && level <= levelLabels.length
      ? levelLabels[level - 1]
      : undefined;
  if (rubricItems.length) {
    // Handles the edge case where there is a single flat array of Learning Targets
    // rather than the typical case where there are groups of Learning Targets.
    const FullRubricWrapper =
      recursiveDepth === 0 && !rubricItems[0].subitems
        ? ExpandableWrapper
        : React.Fragment;
    return (
      <FullRubricWrapper>
        {rubricItems.map((item, i) => {
          const RubricWrapper =
            recursiveDepth > 0 ? NestedWrapper : ExpandableWrapper;
          const label = item.subitems
            ? undefined
            : getLevelName(rubricProgress[item._id]);
          return (
            <React.Fragment key={item._id}>
              {item.subitems ? (
                <RubricWrapper headerText={item.name}>
                  <CourseRubric
                    levelLabels={levelLabels}
                    rubricItems={item.subitems}
                    rubricProgress={rubricProgress}
                    recursiveDepth={recursiveDepth + 1}
                  />
                </RubricWrapper>
              ) : (
                <Card.Row hideTopBorder={i === 0}>
                  <span className={classNames('py-1', { 'pr-4': !!label })}>
                    {item.name}
                  </span>
                  {!!label && <ProgressBadge label={label} />}
                </Card.Row>
              )}
            </React.Fragment>
          );
        })}
      </FullRubricWrapper>
    );
  }
  return null;
};

export default CourseRubric;
