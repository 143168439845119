import React from 'react';
import { Status } from 'hooks/status';
import Message from '../Message';

export interface StatusMessageProps {
  /**
   * Status is an object containing a status string and a message string.
   */
  status: Status | null;
}

/**
 * This component seems to just wrap our Message component with a custom interface.
 *
 * Since that's the case I'm not sure if we want/need to keep it in our UI Component library.
 */

const StatusMessage: React.FC<StatusMessageProps> = ({ status }) => {
  if (!status) return null;
  return <Message status={status.status}>{status.message}</Message>;
};

export default StatusMessage;
