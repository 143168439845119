import { useState, FC, useEffect } from 'react';
import { Parent, Student } from 'generated/graphql';
import { Icon, Message, NewButton } from 'core-components';
import classNames from 'classnames';
import useNavStore from 'app/miscellaneous/stores/NavStore';

import juniAxios from 'services/axios';
import makeSignupSessionsService from 'services/signupSessions/signupSessionsService';
import { NavLink } from 'react-router-dom';
import GuestEnrollmentBanner from 'components/PortalLayout/GuestEnrollmentBanner';
import { iconSize } from 'components/NavV2/styles';
import StudentProfilePreview from './StudentProfilePreview';
import CurriculumSummary from './CurriculumSummary';
import HomepageMainFeed from './HomepageMainFeed';
import ClubInvites from './ClubInvites';
import Announcements from './Announcements';
import Popups from './Popups';
import NewProjectPromotion from './NewProjectPromotion';

interface MobileTabButtonProps {
  icon: React.ReactNode;
  iconActive?: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  marginRight?: boolean;
}
const MobileTabButton: FC<MobileTabButtonProps> = ({
  icon,
  iconActive,
  isActive,
  onClick,
  marginRight,
}) => (
  <div
    className={classNames(
      'flex flex-1 rounded-lg cursor-pointer items-center justify-center p-2',
      {
        'bg-j-purple-200 text-j-purple-600': isActive,
        'text-j-dark-600': !isActive,
        'mr-2': marginRight,
      },
    )}
    onClick={onClick}
  >
    {isActive ? iconActive || icon : icon}
  </div>
);

enum Columns {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
}
interface MyHomepageV2Props {
  parent: Parent;
  students: Student[];
  idLookup: any;
  isLoadingIdLookup: boolean;
  isGuest?: boolean | null;
}
const MyHomepageV2: FC<MyHomepageV2Props> = ({
  parent,
  students,
  idLookup,
  isLoadingIdLookup,
  isGuest,
}) => {
  const navIsMobile = useNavStore(state => state.navIsMobile);
  const [activeColumn, setActiveColumn] = useState<Columns>(Columns.LEFT);
  const [signupId, setSignupId] = useState<string | undefined>(undefined);
  const [isReadyToOnboard, setIsReadyToOnboard] = useState<boolean>(true);

  // first element in 'students' array is the active student
  const student = students[0];

  useEffect(() => {
    const getReadyToOnboardStatus = async (currentParent: Parent) => {
      try {
        const onboardingTicketReady = (
          await juniAxios.get(
            `/student_onboardings/ready-to-onboard/${currentParent._id}`,
          )
        )?.data?.readyToOnboard;
        const signupService = makeSignupSessionsService();
        const res = await signupService.getEnrollmentCompleted(currentParent._id);
        // if there is no onboarding data available, leave banner hidden
        if (
          onboardingTicketReady === undefined &&
          res.data.completedEnrollment === undefined
        ) {
          return;
        }
        // if they completed onboarding in the DCF or a CSR marked their onboarding ticket as 'ready_to_onboard'
        // we don't need to direct them to enter course preferences
        const readyToOnboard = onboardingTicketReady || res.data.completedEnrollment;
        setSignupId(res.data._id);
        setIsReadyToOnboard(readyToOnboard);
      } catch (exception) {
        console.error(exception);
      }
    };

    if (parent) getReadyToOnboardStatus(parent);
  }, [parent, student]);

  return (
    <div className="flex flex-col sm:items-center sm:p-5">
      <div className="flex flex-col max-w-7xl w-full">
        {isGuest && (
          <div className="rounded-lg overflow-hidden mb-4">
            <GuestEnrollmentBanner studentId={student._id} />
          </div>
        )}
        <Popups />

        {!isReadyToOnboard && (
          <Message
            status="warning"
            fullWidth={false}
            outline
            className="px-6 sm:mb-4 mt-2 mx-4 sm:mt-0 sm:mx-0 sm:items-center"
          >
            <div className="flex justify-between">
              <div className="flex sm:items-center">
                Finish setting up your classes so we can schedule your first session
              </div>
              <NavLink
                to={`/onboarding/${signupId}`}
                target="_blank"
                className="no-underline"
              >
                <NewButton intent="warning" size="small">
                  Set up now
                </NewButton>
              </NavLink>
            </div>
          </Message>
        )}

        <div className="flex sm:hidden shadow bg-white rounded-lg py-2 px-4 mt-3 mx-4">
          <MobileTabButton
            icon={<Icon.Barchart {...iconSize(20)} />}
            iconActive={<Icon.BarchartSolid {...iconSize(20)} />}
            isActive={activeColumn === Columns.LEFT}
            onClick={() => setActiveColumn(Columns.LEFT)}
            marginRight
          />
          <MobileTabButton
            icon={<Icon.Comment {...iconSize(20)} />}
            iconActive={<Icon.CommentSolid {...iconSize(20)} />}
            isActive={activeColumn === Columns.MIDDLE}
            onClick={() => setActiveColumn(Columns.MIDDLE)}
            marginRight
          />
          <MobileTabButton
            icon={<Icon.Megaphone {...iconSize(20)} />}
            iconActive={<Icon.MegaphoneSolid {...iconSize(20)} />}
            isActive={activeColumn === Columns.RIGHT}
            onClick={() => setActiveColumn(Columns.RIGHT)}
          />
        </div>

        <div className="flex flex-col sm:flex-row p-4 sm:p-0">
          <div
            className={classNames({
              'flex flex-col sm:w-1/3 lg:w-1/4 mr-4': !navIsMobile,
              hidden: navIsMobile && activeColumn !== Columns.LEFT,
              'flex flex-col': navIsMobile && activeColumn === Columns.LEFT,
            })}
          >
            <StudentProfilePreview student={student} />
            <CurriculumSummary
              parent={parent}
              student={student}
              idLookup={idLookup}
              isLoadingIdLookup={isLoadingIdLookup}
            />
          </div>

          <div
            className={classNames({
              'flex flex-col sm:w-2/3 lg:w-1/2 lg:mr-4 lg:-mt-5': !navIsMobile,
              hidden: navIsMobile && activeColumn !== Columns.MIDDLE,
              'flex flex-col -mt-5': navIsMobile && activeColumn === Columns.MIDDLE,
            })}
          >
            <NewProjectPromotion studentId={student._id} />

            <div className="flex-col hidden sm:flex lg:hidden">
              <ClubInvites student={student} />
              <Announcements student={student} />
            </div>
            <HomepageMainFeed student={student} />
          </div>

          <div
            className={classNames({
              'flex-col sm:hidden lg:flex lg:w-1/4': !navIsMobile,
              hidden: navIsMobile && activeColumn !== Columns.RIGHT,
              'flex flex-col': navIsMobile && activeColumn === Columns.RIGHT,
            })}
          >
            <ClubInvites student={student} />
            <Announcements student={student} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyHomepageV2;
