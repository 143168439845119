import React from 'react';
import styled from 'styled-components/macro';
import BackButton from 'components/BackButton';
import PlaygroundIcon from './PlaygroundIcon';
import { EmptyStateContainer } from './PlaygroundEmptyState';

const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  & .playground-link {
    text-decoration: none;
    width: 100%;
    & > button {
      width: 100%;
      margin-left: 0;
    }
  }
  & .empty-state-right__container {
    align-items: center;
    & .heading {
      text-align: center;
    }
    & .playground-description {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 1000px) {
    width: 75%;
  }
  @media (max-width: 650px) {
    width: 85%;

    & .playground-link {
      width: 90%;
    }
  }
`;

const UnauthorizedMessage: React.FC<{
  playgroundLink: string;
}> = props => (
  <UnauthorizedContainer>
    <EmptyStateContainer style={{ minHeight: '15rem' }}>
      <div className="empty-state-left__container">
        <PlaygroundIcon />
      </div>
      <div className="empty-state-right__container">
        <h2 className="heading">Oops! This isn't a valid URL</h2>
        <p className="playground-description">
          Make sure to double check the link! If a friend gave you this link to show
          you their project, ask them to publish their project to the Juniverse and
          give you that link instead.
        </p>
        <a className="playground-link" href={props.playgroundLink}>
          <BackButton>Back to the Playground</BackButton>
        </a>
      </div>
    </EmptyStateContainer>
  </UnauthorizedContainer>
);

export default UnauthorizedMessage;
