import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import JuniSpinner from 'components/JuniSpinner';
import './layout.css';

class Layout extends PureComponent {
  render() {
    const {
      children,
      className,
      style,
      contentStyle,
      isLoading,
      takeFullViewHeight,
    } = this.props;
    return (
      <div
        className={`layout-container ${
          takeFullViewHeight ? 'take-full-height' : ''
        } ${className}`}
        style={style}
      >
        <div className="layout-content" style={contentStyle}>
          {isLoading ? <JuniSpinner /> : children}
        </div>
      </div>
    );
  }
}

Layout.defaultProps = {
  children: [],
  className: '',
  contentStyle: null,
  isLoading: false,
  style: null,
  takeFullViewHeight: false,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  contentStyle: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  style: PropTypes.shape({}),
  takeFullViewHeight: PropTypes.bool,
};

export default Layout;
